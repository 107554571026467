<div class="container-fluid">
    <div class="content">


        <div>
            <app-crm-breadcrumb s_breadcrumb="Manhour Detail Report">
            </app-crm-breadcrumb>

            <div class="d-flex flex-wrap mb-3">
                <div class="mb-3 me-2 " [matMenuTriggerFor]="customermenu" >
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" aria-expanded="falsez"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                         (click)="removeCust()">
                        {{selectcustomername != 'customer' ? selectcustomername : 'Customer' }}
                    </button>
                </div>
                <mat-menu #customermenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="customersearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changecustomer('customer')">-</button>
                    <button class="mat-item" *ngFor="let cus of customers  | crmFilter : customersearch:text1:text2:16;"
                        (click)="changecustomer(cus)">{{cus.customername}}</button>
                </mat-menu>

                <div  class="mb-3 me-2 " [matMenuTriggerFor]="projectmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeProJect()">
                        {{projectname != 'project' ? projectname : 'Project' }}
                    </button>
                </div>
                <mat-menu #projectmenu="matMenu" xPosition="before" class="menuproject">
                    <div class="input-group cl-project-new  mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="projectsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeproject('project')">-</button>
                    <button class="mat-item" *ngFor="let proj of projects | crmFilter : projectsearch:text1:text2:14;"
                        (click)="changeproject(proj)">{{proj.name}}</button>
                </mat-menu>

                <div  class="mb-3 me-2 " [matMenuTriggerFor]="assignedto">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownTogglez
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removePic()">
                        {{picname != 'pic' ? picname : 'Consultant' }}
                    </button>
                </div>
                <mat-menu #assignedto="matMenu" xPosition="before" class="menuproject">
                    <div class="input-group cl-project-new  mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="picsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changepic('pic')">-</button>
                    <button class="mat-item" *ngFor="let pic of picList | crmFilter : picsearch:text1:text2:14;"
                        (click)="changepic(pic)">{{pic.name}}</button>
                </mat-menu>

                <div class="mb-3 me-2">
                    <input type="text" 
                           class="form-control datepicker-readonly" 
                           placeholder="dd/mm/yyyy" 
                           bsDatepicker
                           [bsConfig]="generalDatePickerConfig" 
                           [bsConfig]="{containerClass: 'custom-placement'}" 
                           readonly
                           [(ngModel)]="startdate" 
                           style="min-width: 200px;" 
                           (ngModelChange)="startdateChange() "
                           required>
                </div>


                <div class="mb-3 me-2">
                    <input type="text" 
                           class="form-control datepicker-readonly" 
                           placeholder="dd/mm/yyyy" 
                           bsDatepicker
                           [bsConfig]="generalDatePickerConfig" 
                           [bsConfig]="{containerClass: 'custom-placement'}" 
                           readonly
                           [(ngModel)]="enddate" 
                           style="min-width: 200px;" 
                           [maxDate]="minDate"
                           required>
                </div>
                <!-- <div  class="mb-3 me-2 ">
                    <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
                    [bsConfig]="generalDatePickerConfig" [bsConfig]="{containerClass: 'custom-placement'}" readonly
                    [(ngModel)]="enddate" style="min-width: 200px;" required #enddate="ngModel">
                    </div>
   -->
               
                <div class="me-2">
                    <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Search">
                        Search
                    </button>
                </div>
                <div class="">
                    <button type="button" class="btn btn-custom" (click)="downloadSheet1()"
                        matTooltip="Export Service Monthly Report">
                        <img class="excel-icon" src="../../../assets/images/excel.png">Export
                    </button>

                </div>
            </div>

            <div>
                <div class="card card-body my-card">
                    <div class="table-responsive">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <!-- <td scope="col">Date</td> -->
                                    <td >Consultant</td>
                                    <td style="text-align: right;">Manhour</td>
                                    <!-- <td scope="col">Actual Month</td> -->
                                    <td style="text-align: center ;">Ticket No.</td>
                                    <td  class="col-lg-5 col-md-5" >Description</td>
                                    <td class="col-lg-5 col-md-5" >Activity</td>

                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="7" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="manhourlist.length == 0">
                                    <td class="nodata" colspan="7">Empty</td>
                                </tr>
                                <tr *ngFor="let man of manhourlist">
                                    <!-- <td>
                                            {{man.date}}
                                        </td> -->
                                    <td class="td-data">
                                        <span>{{man.assignedto}}</span>
                                    </td>
                                    <td class="td-data" style="color: var(--main-color); text-align: right;">
                                        <span>{{man.manhour}}</span>
                                    </td>
                                    <!-- <td class="td-data" >
                                            <span >{{man.actualmonth}}</span>
                                        </td> -->
                                    <td class="td-data" style="text-align: center ;">
                                        <span>{{man.ticketno}}</span>
                                    </td>
                                    <td  class="td-data">
                                        <span>
                                            <div id="descTextarea">
                                                {{man.description}}
                                            </div>
                                        </span>
                                    </td>
                                    <td class="td-data">
                                        <span [title]="man.activity">
                                            <div class="descTextarea"> {{man.activity}}</div>
                                           </span>
                                    </td>
                                </tr>
                                <tr *ngIf="manhourlist.length > 0">
                                   
                                    <td class="td-data">
                                        <span style="font-size: 15px; font-weight: bold;">Total</span>
                                    </td>
                                    <td class="td-data" style="color: var(--main-color); text-align: right;">
                                        <span style="font-size: 15px; font-weight: bold;">{{totalmanhour}}</span>
                                    </td>
                                   
                                    <td class="td-data">
                                    </td>
                                    <td  class="td-data">
                                      
                                    </td>
                                    <td class="td-data">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>







    </div>


</div>