<div class="event-detail-wrapper p-0" *ngIf="item">
    <div class="row">
        <ul class="list-group">
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        User ID
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{item.userid}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Name
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{item.username}}
                    </div>
                </div>
            </li>
        </ul>
        <!-- <div class = "table-responsive">
            <table class = "table table-borderless">
                <thead>
                    <tr>
                        <td>Location Name</td>
                        <td>Date</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="item.locations.length == 0">
                        <td colspan="2" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of item.locations; let index = index">
                        <td>{{item.locname}}</td>
                        <td>{{item.startdate}} {{item.starttime}}</td>
                    </tr>
                </tbody>
            </table>
        </div> -->
        <ul class="list-group my-3">
            <div class="list-group-item d-flex justify-content-center align-items-center">
                Locations
            </div>

            <div class="list-group-item py-4 d-flex align-items-center justify-content-center"
                *ngIf="item.locations.length == 0">
                Empty
            </div>

            <div>
                <li class="list-group-item" *ngFor="let item of item.locations">
                    <div class="user-container">
                        <div class="info-container">
                            <div class="username">
                                {{item.locname}}
                            </div>
                            <div class="userid">
                                {{allinoneService.formatDBToShow(item.startdate)}} {{item.starttime}}
                            </div>
                        </div>
                    </div>
                </li>
            </div>
        </ul>
    </div>

    <div class="close-btn">
        <button type="button" class="btn btn-custom " mat-dialog-close>Close</button>
    </div>
</div>