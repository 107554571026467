<div class="custom-web-breadcrumb">
    <!-- <span class="active-breadcrumb" [routerLink]="attendanceView ? ['/attendance/adminconsole']: ['/adminconsole']">{{title}}</span> -->
    <span class="active-breadcrumb" [routerLink]="title == 'Teams' ? ['/teams'] : title == 'Time and Attendance' ? ['/timeandattendance'] : title == 'Request Approval' ? ['/requestapproval'] 
    : title == 'Employee Central' ? ['/employeecentral']  : title == 'References' ? ['/references'] : title == 'Role Management' ? ['/rolemanagement'] :  ['/teams'] ">{{title}}</span>

    <i class="fa fa-angle-right" aria-hidden="true"></i>
    <span [ngClass]="{'active-breadcrumb': t_breadcrumb}" (click)="goToSecond()"> {{s_breadcrumb}}</span>
    <i *ngIf="t_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span *ngIf="t_breadcrumb" [ngClass]="{'active-breadcrumb': f_breadcrumb}" (click)="goToThird()">
        {{t_breadcrumb}}</span>
    <i *ngIf="f_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span *ngIf="t_breadcrumb" [ngClass]="{'active-breadcrumb': fi_breadcrumb}" (click)="goToFourth()">
        {{f_breadcrumb}}</span>
    <i *ngIf="fi_breadcrumb" class="fa fa-angle-right" aria-hidden="true"></i>
    <span *ngIf="fi_breadcrumb"> {{fi_breadcrumb}}</span>
</div>