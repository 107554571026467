import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';

import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { ClasscodeComponent } from '../classcode/classcode.component';
import * as XLSX from 'xlsx';
import { RoleComponent } from '../role/role.component';
import { MemberFilterPipe } from './member-filter.pipe';
import { SubSink } from 'subsink';
import { MembertypesComponent } from '../membertypes/membertypes.component';
import { MemberPositionHistoryComponent } from '../member-position-history/member-position-history.component';
import { UserProfileEditComponent } from '../user-profile-edit/user-profile-edit.component';
import { debounceTime } from 'rxjs/operators';
import { MembersRoleComponent } from '../members-role/members-role.component';
import { PayLevelComponent } from '../pay-level/pay-level.component';
import { Subscription } from 'rxjs';
import { SynchistoryComponent } from '../synchistory/synchistory.component';
import { MemberInfoSyncDialogComponent } from '../member-info-sync-dialog/member-info-sync-dialog.component';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent implements OnInit, OnDestroy {
isFetching: any;

  @HostListener('window:scroll', [])
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (
      window.innerWidth > this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isMobileView = false;
      this.isTabletView = true;
    } else {
      this.isMobileView = false;
      this.isTabletView = false;
    }
  }

  roleListLoading: boolean = false;
  memberAddorNot: boolean = false;
  memberListLoading: boolean = false;
  saveLoading: boolean = false;
  ccSaveLoading: boolean = false;
  roleSaveLoading: boolean = false;
  memberformSubmitted: boolean = false;
  gettingDomainList: boolean = false;
  gettingOrgList: boolean = false;
  isFromOrg: boolean = false;
  isEditing: boolean = false;
  isUserValid: boolean = true;
  checkingMember: boolean = false;
  isPreRegister: boolean = false;
  isMobileView: boolean = false;
  isTabletView: boolean = false;
  hasPositionControl: boolean = false;
  needToFillPositionControl: boolean = false;
  noFile: boolean = false;
  downloading: boolean = false;
  gettingCcList: boolean = false;
  gettingAcademicList: boolean = false;
  gettingMemberTypes: boolean = false;
  gettingNrcCode: boolean = false;
  checkingExcelFile: boolean = false;
  isBulkUpload: boolean = false;
  allok: boolean = false;
  tagmailediting: boolean = false;
  firstTime: boolean = true;
  syncLoading: boolean = false;
  isFocus: boolean = false;
  isMemberID: boolean = false;
  isRoleSelected: boolean = false;
  calenderListLoading: boolean = false;
  templateListLoading: boolean = false;
  gettingLeaveEntitlement: boolean = false;
  isHCM: boolean = false;
  gettingPaylevel: boolean = false;
  showAysncFromNeo: boolean = false;
  gettingProfileImage: boolean = false;

  domains: any = [];
  organizations: any = [];
  members: any = [];
  ccList: any = [];
  employmentTypes: any = [];
  transactionTypes: any = [];
  rawMemberList: any = [];
  errorList: any = [];
  warningList: any = [];
  duplicateList: any = [];
  eidList: any = [];
  excelRoleList: any = [];
  useridList: any = [];
  finalMemberList: any = [];
  newcclist: any = [];
  newrolelist: any = [];
  newEmploymentTypeList: any = [];
  newTransactionTypeList: any = [];
  postList: any = ['All'];
  departmentList: any = ['All'];

  divisionList: any = ['All'];
  sectionList: any = [];
  costcenterList: any = ['All'];
  subdivisionList: any = [];
  paylevelList: any = [];
  gettingRequisition: boolean = false;
  syncMemberInfoLoading: boolean = false;
  requisitionList: any = [];
  LeaveTemplateList: any[] = [];
  branchList: any = [];
  teamidList: any = [];
  roleList: any = [];
  roleIdList: any = [];
  allCalendarList: any = [];
  activeCalendarList: any = [];
  templeteList: any = [];
  fileName: any = '';
  openfilter: boolean = false;
  isAttendanceView: boolean = false;
  fromRequisition: boolean = false;

  breadcrumbName: string = 'Users';
  editBreadcrumbName: any = '';

  nrcregtest = "lakefjafejeaf"

  dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;

  statusList: any = [
    {
      name: 'All',
      id: 'All',
    },
    {
      name: 'Active',
      id: '0',
    },
    {
      name: 'Inactive',
      id: '1',
    },
    {
      name: 'Blacklist',
      id: '13',
    },
  ];

  martialStatus: any = [];
  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];

  ccStart = 0;
  ccEnd = 30;
  mmStart = 0;
  mmEnd = 30;

  roleStart = 0;
  roleEnd = 30;

  publicMailDomain = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'outlook.com',
    'yandex.com',
    'protonmail.com',
    'zohomail.com',
    'icloud.com',
  ];
  role: any;
  excel: any;

  selectedDomainName = '';
  selectedOrgName = '';
  memberIdToEdit = '';
  username = '';
  org_name = '';
  tagEmail = '';
  tempTagEmail = '';
  membershiptype = '';
  searchText = '';
  searchPost = 'All';
  searchDepartment = 'All';
  searchDivision = 'All';
  searchCostCenter = 'All';
  searchStatus = '0';
  searchStatusName = 'Active';
  editimage = '';
  searchUserID: string = '';

  mobileViewWidth: any = 426;
  tabletViewWidth: any = 992;
  itemPerPage: any = 100;
  p: number = 1;

  gettingMemberPosition: boolean = false;
  nrcRequired: boolean = false;

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  academicList: any = [];

  memberForm: any = {
    domain: '',
    org: '',
    name: '',
    userid: '',
    newuserid: '',
    olduserid: '',
    type: '',
    typeid: '',
    eid: '',
    post: '',
    contributor: false,
    postadmin: false,
    hradmin: false,
    transportationadmin: false,
    roleList: [],
    department: '',
    division: '',
    teamid: '',
    joineddate: '',
    effectivedate: '',
    employmenttype: '',
    dateofbirth: '',
    gender: '',
    transactiontype: '',
    profiletype: 'Employment',
    rank: '',
    entity: '',
    exttext1: '',
    exttext2: '',
    exttext3: '',
    exttext4: '',
    exttext5: '',
    filename: '',
    remark: '',
    url: '',
    leavecalendarid: '',
    leavetemplateid: '',
    oldleavecalendarid: '',
    oldleavetemplateid: '',
    attendancerequired: true,
    activityrequired: true,
    imgURL: '',
    section: '',
    costcenter: '',
    subdivision: '',
    requisitionid: '',
    roletype: '',
    nrcregioncode: '',
    nrctownshipcode: '',
    nrctype: '',
    nrcnumber: '',
    martialstatus: '',
    paylevel: '',
    inactive: false,
    mobile: '',
  };
  isAdmin: boolean = false;

  fileSelected: any;
  imageSelected: any;
  filetype: string = '';
  showtransportation: boolean = false;
  userequisition: boolean = false;
  rolerequired: boolean = false;
  isFileChange: boolean = true;
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('excelFile') excelFile: any;
  synchistoryList: any = []

  subscriptions = new SubSink();
  profileImageSubscription!: Subscription;

  constructor(
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    // public dialogRef: MatDialogRef<UserProfileEditComponent>,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private filter: MemberFilterPipe
  ) {
    this.route.data.subscribe((data) => {
      this.breadcrumbName = data.breadcrumb;
      this.fromRequisition = data.requisition === true ? true : false;
      if (this.fromRequisition) {
        this.isEditing = true;
        this.memberAddorNot = true;
        this.searchUserID = this.route.snapshot.params['userid'];
      }
      if (this.breadcrumbName == 'Employee') {
        this.isHCM = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        if (
          !this.allinoneService.isAdminOf('org') &&
          !this.route.snapshot.queryParamMap.get('org')
        ) {
          this.router.navigateByUrl('/home');
        }
      }
    });
    this.isAttendanceView = this.allinoneService.isAttendanceView;
  }
  ngOnDestroy(): void { }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (
      window.innerWidth > this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isMobileView = false;
      this.isTabletView = true;
    }
    // this.role = this.allinoneService.getRole()

    var tempDomain: any = this.allinoneService.getDomain();
    this.memberForm.domain = tempDomain.domainid;
    this.selectedDomainName = tempDomain.description;

    // if (this.role == "100" || this.role == "200") {
    this.memberForm.org = this.route.snapshot.queryParamMap.get('org') || '';
    if (this.memberForm.org != '') {
      this.isFromOrg = true;
      var tempOrg: any = this.allinoneService.getSessionValue('organization');

      this.org_name = tempOrg.name;
      this.tagEmail = tempOrg.tagemail;
      this.membershiptype = tempOrg.membership;
      this.showtransportation = tempOrg.showtransportation;
      this.rolerequired = tempOrg.rolerequired;
      this.userequisition = tempOrg.userequisition;
      this.showAysncFromNeo = tempOrg.neosync;
      // console.log("con 1");

      // console.log(this.showtransportation);
      // console.log(this.rolerequired);
      // console.log(this.userequisition);
    } else {
      this.organizations = this.allinoneService.orgs;

      this.memberForm.org = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;

      const index = this.organizations.findIndex(
        (x: any) => x.orgid == this.memberForm.org
      );
      this.showAysncFromNeo = this.organizations[index].neosync;
      console.log(this.organizations[index]);
      this.tagEmail = this.organizations[index].tagemail;
      this.membershiptype = this.organizations[index].membership;
      this.showtransportation = this.organizations[index].showtransportation;
      this.rolerequired = this.organizations[index].rolerequired;
      this.userequisition = this.organizations[index].userequisition;
    }

    if (this.tagEmail == '') {
      this.tagmailediting = true;
      ('');
      this.firstTime = true;
    } else {
      this.tagmailediting = false;
      this.firstTime = false;
    }

    if (
      // this.memberForm.org == '11346359' ||
      // this.memberForm.org == '15790596' || // c365demo
      // this.memberForm.org == '30770116'
      this.memberForm.org == '31147485'
    ) {
      this.showAysncFromNeo = true;
    }

    this.setDefaultMembershipType();
    this.getMemberList(this.fromRequisition ? this.searchUserID : '');
    this.getCcList();
    this.getMemberTypes();
    this.getMemberPosition();
    this.getRoleList();
    this.getAcademicList();
    this.prefetchLeaveTemplateData();
    // this.getPositionList();
    // this.getRequisitionList(); 
    this.getNrcCode();
    this.getPayLevelList();
    // }
    // if (this.role == "300") {
    //   var tempOrg: any = this.allinoneService.getSessionValue('organization')
    //   this.memberForm.org = tempOrg.orgid;
    //   this.selectedOrgName = tempOrg.name;
    //   this.getMemberList()
    // }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.memberForm.org,
    };
    this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.divisionList = res.divisionlist;
          // this.departmentList = res.departmentlist;
          for (var i = 0; i < res.departmentlist.length; i++) {
            this.departmentList.push(res.departmentlist[i]);
          }
          for (var i = 0; i < res.divisionlist.length; i++) {
            this.divisionList.push(res.divisionlist[i]);
          }
          for (var i = 0; i < res.costcenterlist.length; i++) {
            this.costcenterList.push(res.costcenterlist[i]);
          }
          this.branchList = res.branchlist;
          this.teamidList = res.teamidlist;
          this.sectionList = res.sectionlist;
          // this.costcenterList = res.costcenterlist;
          this.subdivisionList = res.subdivision;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }
  async prefetchLeaveTemplateData(): Promise<void> {
    try {
      if (!this.members || this.members.length === 0) {
        console.warn("Members array is empty or undefined. Skipping prefetch.");
        return;
      }
  
      const leaveTemplatePromises = this.members.map(async (member: any) => {
        const leaveData = {
          orgid: member.orgid,
          domainid: member.domainid,
          memberid: member.userid,
        };
  
        try {
          const leaveInfo = await this.kunyekService.getUserLeaveInfo(leaveData).toPromise();
          if (leaveInfo.returncode === '300' && leaveInfo.returntemplateid) {
            const templateRequestData = {
              orgid: member.orgid,
              domainid: member.domainid,
              calendarid: member.leavecalendarid || '',
              end: '',
            };
  
            const templateRes = await this.kunyekService.getTemplate(templateRequestData).toPromise();
            if (templateRes.templatelist && templateRes.templatelist.length > 0) {
              const matchedTemplate = templateRes.templatelist.find((template: any) => template.templateid === leaveInfo.returntemplateid);
              return {
                userid: member.userid,
                leaveTemplate: matchedTemplate ? matchedTemplate.name : '',
                totalEntitlement: leaveInfo.totaltaken,
              };
            }
          }
          return { userid: member.userid, leaveTemplate: '', totalEntitlement: '' };
        } catch (error) {
          console.error(`Error fetching leave info for member ${member.userid}`, error);
          return { userid: member.userid, leaveTemplate: '', totalEntitlement: '' };
        }
      });
  
      this.LeaveTemplateList = await Promise.all(leaveTemplatePromises);
    } catch (error) {
      console.error("Error during leave template prefetch:", error);
    }
  }
  
  
  // getPositionList() {
  //   this.gettingCcList = true;
  //   const data = {
  //     orgid: this.memberForm.org,
  //     domainid: this.allinoneService.getDomain().domainid,
  //   };
  //   console.log('p data');
  //   console.log(data);

  //   this.kunyekService.getPositionList(data).subscribe(
  //     (res: any) => {
  //       if (res.returncode == '300') {
  //         this.departmentList = ['All'];
  //         this.divisionList = [];

  //         for (var i = 0; i < res.datalist.length; i++) {
  //           this.departmentList.push(res.datalist[i].department);
  //           this.divisionList.push(res.datalist[i].division);
  //         }

  //         this.divisionList = [...new Set(this.divisionList)];
  //         this.departmentList = [...new Set(this.departmentList)];

  //         this.gettingCcList = false;
  //       } else {
  //         this.gettingCcList = false;
  //         this.messageService.openSnackBar(res.message, 'warn');
  //       }
  //     },
  //     (err) => {
  //       this.gettingCcList = false;
  //     }
  //   );
  // }

  getCalendarList() {
    this.calenderListLoading = true;
    let data = {
      orgid: this.memberForm.org,
      domainid: this.memberForm.domain,
      active: '',
    };
    this.subscriptions.sink = this.kunyekService.getCalendar(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.calenderListLoading = false;
          let _yearAll: any = [];
          _yearAll = res['calendarlist'];
          this.allCalendarList = res.calendarlist;
          this.activeCalendarList = res.calendarlist.filter(
            (item: any) => item.valid && item.active
          );
          this.calenderListLoading = false;
        } else {
          this.calenderListLoading = false;
        }
      },
      (err) => {
        this.calenderListLoading = false;
        this.gettingLeaveEntitlement = false;
      }
    );
  }

  calendarChange(event: any) {
    this.getTemplateList();
  }

  getTemplateList() {
    this.templateListLoading = true;
    let data = {
      orgid: this.memberForm.org,
      domainid: this.memberForm.domain,
      calendarid: this.memberForm.leavecalendarid,
      end: '',
    };
    this.subscriptions.sink = this.kunyekService.getTemplate(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.templeteList = res['templatelist'];
          if (this.isEditing) {
            //get leave entitlement
            this.getLeaveEntitlement();
          } else {
            this.gettingLeaveEntitlement = false;
          }
        }
        this.templateListLoading = false;
      },
      (err) => {
        this.templateListLoading = false;
        this.gettingLeaveEntitlement = false;
      }
    );
  }

  getLeaveEntitlement() {
    this.gettingLeaveEntitlement = true;
    let data = {
      orgid: this.memberForm.org,
      domainid: this.memberForm.domain,
      memberid: this.memberForm.userid,
    };
    this.subscriptions.sink = this.kunyekService
      .getUserLeaveInfo(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.memberForm.oldleavecalendarid = res.returncalendarid;
            this.memberForm.oldleavetemplateid = res.returntemplateid;
            var calendarCheck = this.activeCalendarList.find(
              (x: any) => x.calendarid == res.returncalendarid
            );
            var templateCheck = this.templeteList.find(
              (x: any) => x.templateid == res.returntemplateid
            );
            if (calendarCheck && templateCheck) {
              this.memberForm.leavecalendarid = res.returncalendarid;
              this.memberForm.leavetemplateid = res.returntemplateid;
            }
          }
          this.gettingLeaveEntitlement = false;
        },
        (err) => {
          this.gettingLeaveEntitlement = false;
        }
      );
  }

  clear() {
    this.searchText = '';
  }

  departmentChanged(event: any) {
    this.searchDepartment = event.target.value;
    this.p = 1;
  }

  postChanged(event: any) {
    this.searchPost = event.target.value;
    this.p = 1;
  }

  setDefaultMembershipType() {
    if (this.membershiptype != '') {
      if (this.membershiptype == '001') {
        this.memberForm.profiletype = 'Membership';
      } else if (this.membershiptype == '002') {
        this.memberForm.profiletype = 'Employment';
      } else if (this.membershiptype == '003') {
        this.memberForm.profiletype = 'Loyalty';
      }
    }
  }

  tagMailEdit() {
    this.tagmailediting = true;
    this.tempTagEmail = this.tagEmail;
  }

  cancelEdit() {
    this.tagEmail = this.tempTagEmail;
    this.tempTagEmail = '';
    this.tagmailediting = false;
  }

  syncTagEmail() {
    if (this.publicMailDomain.indexOf(this.tagEmail) > -1) {
      this.messageService.openSnackBar(
        'Public email domains are not allowed.',
        'warn'
      );
      return;
    }
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '95vw',
      width: '300px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        cmessage:
          'Synchronise all users of @' +
          this.tagEmail +
          ' as ' +
          this.breadcrumbName.toLowerCase() +
          's?',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.syncLoading = true;
        var data = {
          orgid: this.memberForm.org,
          tagemail: this.tagEmail,
        };
        this.kunyekService.syncMembers(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              if (this.tagEmail == '') {
                this.firstTime = true;
              } else {
                this.tagmailediting = false;
              }
              this.tempTagEmail = '';
              if (this.isFromOrg) {
                var temp = this.allinoneService.getSessionValue('organization');
                temp.tagemail = this.tagEmail;
                this.allinoneService.setCookie(
                  'organization',
                  JSON.stringify(temp) || 'undefined'
                );
              } else {
                const index = this.organizations.findIndex(
                  (x: any) => x.orgid == this.memberForm.org
                );
                this.allinoneService.orgs[index].tagemail = this.tagEmail;
              }
              this.syncLoading = false;
              this.getMemberList();
              this.messageService.openSnackBar(
                'Synced Successfully.',
                'success'
              );
            } else {
              this.syncLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
              this.prefetchLeaveTemplateData();
            }
          },
          (err) => {
            this.syncLoading = false;
          }
        );
      }
    });
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      //  ||
      // this.divisionList.length < 2 ||
      // this.costcenterList < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
    if (!this.openfilter) {
      this.searchDepartment = 'All';
      this.searchDivision = 'All';
      this.searchCostCenter = 'All';
      this.searchPost = 'All';
      this.searchStatus = '0';
      this.searchStatusName = 'Active';
    }
  }

  postChange(data: any) {
    if (this.searchPost != data) {
      this.searchPost = data;
      this.searchDepartment = 'All';
      this.searchDivision = 'All';
      this.searchCostCenter = 'All';
      this.p = 1;
    }
  }

  departmentChange(dep: any) {
    if (this.searchDepartment != dep) {
      this.searchDepartment = dep;
      this.searchDivision = 'All';
      this.searchCostCenter = 'All';
      this.p = 1;
    }
  }

  divisionChange(data: any) {
    if (this.searchDivision != data) {
      this.searchDivision = data;
      this.searchCostCenter = 'All';
      this.p = 1;
    }
  }

  costCenterChange(data: any) {
    if (this.searchCostCenter != data) {
      this.searchCostCenter = data;
      this.p = 1;
    }
  }

  statusChange(data: any) {
    if (this.searchStatus != data.id) {
      this.searchStatus = data.id;
      this.searchStatusName = data.name;
      this.p = 1;
    }
  }

  openBulkUpload() {
    this.isBulkUpload = true;
    this.memberAddorNot = true;
    this.allok = false;
    this.isUserValid = false;
  }

  sortData(sort: Sort) {
    console.log('sorting');
    const data = this.members;
    if (!sort.active || sort.direction === '') {
      this.members = data;
      return;
    }
    console.log('sorting - 2');
    this.members = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'classcode':
          return this.allinoneService.compare(a.type, b.type, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        default:
          return 0;
      }
    });
  }

  searchTextChange(event: any) {
    this.p = 1;
  }

  onExcelFileSelect(event: any) {
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        // this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        this.rawMemberList = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
          raw: false,
        });
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }

  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.newrolelist = [];
    this.newTransactionTypeList = [];
    this.newEmploymentTypeList = [];
    this.eidList = [];
    this.excelRoleList = [];
    this.allok = false;
  }

  checkExcelFile() {
    this.checkingExcelFile = true;
    this.errorList = [];
    this.warningList = [];
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.newrolelist = [];
    this.newEmploymentTypeList = [];
    this.newTransactionTypeList = [];
    this.eidList = [];
    this.excelRoleList = [];
    const tempCcList: any = [];
    const tempRoleExcelData: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '') &&
        (raw[2] == null || raw[2] == undefined || raw[2] == '') &&
        (raw[3] == null || raw[3] == undefined || raw[3] == '') &&
        (raw[4] == null || raw[4] == undefined || raw[4] == '') &&
        (raw[5] == null || raw[5] == undefined || raw[5] == '') &&
        (raw[6] == null || raw[6] == undefined || raw[6] == '') &&
        (raw[7] == null || raw[7] == undefined || raw[7] == '') &&
        (raw[8] == null || raw[8] == undefined || raw[8] == '') &&
        (raw[9] == null || raw[9] == undefined || raw[9] == '') &&
        (raw[10] == null || raw[10] == undefined || raw[10] == '') &&
        (raw[11] == null || raw[11] == undefined || raw[11] == '') &&
        (raw[12] == null || raw[12] == undefined || raw[12] == '') &&
        (raw[13] == null || raw[13] == undefined || raw[13] == '') &&
        (raw[14] == null || raw[14] == undefined || raw[14] == '') &&
        (raw[15] == null || raw[15] == undefined || raw[15] == '') &&
        (raw[16] == null || raw[16] == undefined || raw[16] == '') &&
        (raw[17] == null || raw[17] == undefined || raw[17] == '') &&
        (raw[18] == null || raw[18] == undefined || raw[18] == '') &&
        (raw[19] == null || raw[19] == undefined || raw[19] == '')
      ) {
      } else {
        const userid = raw[0] ? raw[0].toString().trim() : '';
        const username = raw[1] ? raw[1].toString().trim() : '';
        // const email = raw[2] ? raw[2].toString().trim() : '';
        // const mobile = raw[3] ? raw[3].toString().trim() : '';
        // ***
        const dob = raw[2] ? raw[2].toString().trim() : '';
        const gender = raw[3] ? raw[3].toString().trim() : '';

        const nrc = raw[4] ? raw[4].toString().trim() : '';
        const martialstatus = raw[5] ? raw[5].toString().trim() : '';
        const mobile = raw[6] ? raw[6].toString().trim() : '';

        const employeeid = raw[7] ? raw[7].toString().trim() : '';
        const classcode = raw[8] ? raw[8].toString().trim() : '';
        const post = raw[9] ? raw[9].toString().trim() : '';
        const department = raw[10] ? raw[10].toString().trim() : '';
        const division = raw[11] ? raw[11].toString().trim() : '';
        const teamid = raw[12] ? raw[12].toString().trim() : '';

        // ***
        const section = raw[13] ? raw[13].toString().trim() : '';
        const costcenter = raw[14] ? raw[14].toString().trim() : '';
        const subdivision = raw[15] ? raw[15].toString().trim() : '';

        const role = raw[16] ? raw[16].toString().trim() : '';

        const joineddate = raw[17] ? raw[17].toString().trim() : '';
        const effectivedate = raw[18] ? raw[18].toString().trim() : '';
        // ***
        const employmenttype = raw[19] ? raw[19].toString().trim() : '';
        const transactiontype = raw[20] ? raw[20].toString().trim() : '';
        const admin = raw[21] ? raw[21].toString().trim() : '';
        const contributor = raw[22] ? raw[22].toString().trim() : '';

        let nrcregioncode: any,
          nrctownshipcode: any,
          nrctype: any,
          nrcnumber: any = '';
        let maritalstatusid = '';

        if (userid == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "'User ID' is empty.",
          };
          this.errorList.push(tempError);
        } else {
          // check nrc
          if (nrc != '') {
            let nrcvalid = true;
            [nrcregioncode, nrctownshipcode, nrctype, nrcnumber] = nrc.match(
              /(\d+)|(\w+)|(N)|(\d+)/g
            );
            console.log(nrcregioncode, nrctownshipcode, nrctype, nrcnumber);
            if (!nrcregioncode || !nrctownshipcode || !nrctype || !nrcnumber) {
              const tempError = {
                line: (i + 1).toString(),
                error: 'Invalid NRC.',
              };
              this.errorList.push(tempError);
              continue;
            }
            nrctownshipcode = nrctownshipcode.toUpperCase();
            const regioncode = this.nrcRegionCode.find(
              (x: any) => x == nrcregioncode
            );
            if (!regioncode) {
              const tempError = {
                line: (i + 1).toString(),
                error: 'Invalid NRC.',
              };
              this.errorList.push(tempError);
              continue;
            }

            const townshipcode = this.nrcTownshipCode.find(
              (x: any) => x == nrctownshipcode
            );
            if (!townshipcode) {
              const tempError = {
                line: (i + 1).toString(),
                error: 'Invalid NRC.',
              };
              this.errorList.push(tempError);
              continue;
            }

            const type = this.nrcType.find((x: any) => x == nrctype);
            if (!type) {
              const tempError = {
                line: (i + 1).toString(),
                error: 'Invalid NRC.',
              };
              this.errorList.push(tempError);
              continue;
            }
          }
          if (martialstatus != '') {
            maritalstatusid = this.martialStatus.find(
              (x: any) => x.name == martialstatus
            )?.code;
            if (!maritalstatusid) {
              const tempError = {
                line: (i + 1).toString(),
                error: 'Invalid Marital Status.',
              };
              this.errorList.push(tempError);
              continue;
            }
          }
          if (dob != '' && !this.dateRegex.test(dob)) {
            const tempError = {
              line: (i + 1).toString(),
              error: 'Invalid Date Format for Date of Birth.',
            };
            this.errorList.push(tempError);
            continue;
          }
          if (joineddate != '' && !this.dateRegex.test(joineddate)) {
            const tempError = {
              line: (i + 1).toString(),
              error: 'Invalid Date Format for Joined Date.',
            };
            this.errorList.push(tempError);
            continue;
          }

          if (effectivedate != '' && !this.dateRegex.test(effectivedate)) {
            const tempError = {
              line: (i + 1).toString(),
              error: 'Invalid Date Format for Effective Date.',
            };
            this.errorList.push(tempError);
            continue;
          }

          mobile;
          // check marital status
          // check mobile
          if (classcode == '') {
            const tempError = {
              line: (i + 1).toString(),
              error: "'Post' is empty.",
            };
            this.errorList.push(tempError);
          } else {
            // *** check role is empty
            if (this.rolerequired && role == '') {
              const tempError = {
                line: (i + 1).toString(),
                error: "'Role' is empty.",
              };
              this.errorList.push(tempError);
            } else {
              // *** add role data for check duplicate
              if (role != '') {
                var tempRoleValue = {
                  line: (i + 1).toString(),
                  role: role,
                };
                this.excelRoleList.push(tempRoleValue);
              }
              // *** add new role data for add

              const roleindex = this.academicList.findIndex(
                (x: any) => x.name.trim() == role
              );
              if (roleindex == -1 && role != '') {
                if (tempRoleExcelData.indexOf(role) == -1) {
                  const tempR = {
                    name: role,
                    description: '',
                  };
                  this.newrolelist.push(tempR);
                  tempRoleExcelData.push(role);
                }
              } else {
                const eindex = this.eidList.findIndex(
                  (x: any) => x.employeeid.trim() == employeeid
                );
                if (eindex > -1) {
                  const tempError = {
                    line: (i + 1).toString(),
                    error:
                      "Employee ID '" +
                      employeeid.toString().trim() +
                      "' is duplicated.",
                  };
                  this.errorList.push(tempError);
                } else {
                  if (employeeid != '') {
                    var tempEId = {
                      line: (i + 1).toString(),
                      employeeid: employeeid,
                      userid: this.allinoneService.checkUserId(userid),
                    };
                    this.eidList.push(tempEId);
                  }
                  const index = this.ccList.findIndex(
                    (x: any) => x.name.trim() == classcode
                  );
                  if (index == -1) {
                    console.log('con 1 c 1');
                    if (tempCcList.indexOf(classcode) == -1) {
                      const tempCC = {
                        name: classcode,
                        description: '',
                      };
                      this.newcclist.push(tempCC);
                      tempCcList.push(classcode);
                    }
                  } else {
                    console.log('con 2 c 2');

                    const uid = this.allinoneService.checkUserId(userid);
                    if (
                      (effectivedate != '' ||
                        employmenttype != '' ||
                        transactiontype != '') &&
                      (effectivedate == '' ||
                        employmenttype == '' ||
                        transactiontype == '' ||
                        post == '' ||
                        department == '')
                    ) {
                      const tempError = {
                        line: (i + 1).toString(),
                        error:
                          'Branch, Department, Effective Date, Employment Type and Transaction Type cannot be empty.',
                      };
                      this.errorList.push(tempError);
                    } else {
                      var employmentTypeIndex = -1;
                      var transactionTypeIndex = -1;
                      if (employmenttype != '') {
                        employmentTypeIndex = this.employmentTypes.findIndex(
                          (x: any) => x.name.trim() == employmenttype
                        );
                        if (employmentTypeIndex == -1) {
                          if (
                            this.newEmploymentTypeList.indexOf(
                              employmenttype
                            ) == -1
                          ) {
                            this.newEmploymentTypeList.push(employmenttype);
                          }
                        }
                      }
                      if (transactiontype != '') {
                        transactionTypeIndex = this.transactionTypes.findIndex(
                          (x: any) => x.name.trim() == transactiontype
                        );
                        if (transactionTypeIndex == -1) {
                          if (
                            this.newTransactionTypeList.indexOf(
                              transactiontype
                            ) == -1
                          ) {
                            this.newTransactionTypeList.push(transactiontype);
                          }
                        }
                      }
                      if (
                        uid != this.allinoneService.getKunyekUserId() &&
                        this.newEmploymentTypeList.length == 0 &&
                        this.newTransactionTypeList.length == 0
                      ) {
                        if (this.useridList.indexOf(uid) > -1) {
                          if (this.duplicateList.indexOf(uid) == -1) {
                            this.duplicateList.push(uid);
                          }
                        } else {
                          let admin_code = '';
                          if (admin != '' && admin != '1' && admin != '2') {
                            const tempError = {
                              line: (i + 1).toString(),
                              error: "'" + admin + "' is invalid.",
                            };
                            this.warningList.push(tempError);
                          }

                          if (contributor != '' && contributor != '1') {
                            const tempError = {
                              line: (i + 1).toString(),
                              error: "'" + contributor + "' is invalid.",
                            };
                            this.warningList.push(tempError);
                          }
                          this.useridList.push(uid);
                          const tempMember = {
                            name: username,
                            adduser: uid,
                            positionname: classcode,
                            type: classcode,
                            typeid: this.ccList[index].id.trim(),
                            roletype:
                              role == ''
                                ? ''
                                : this.academicList[roleindex].roleid.trim(),
                            employeeid: employeeid.trim(),
                            remark: '',
                            role: admin == '1' ? '300' : '',
                            contributor: contributor == '1' ? '400' : '',
                            hradmin: '',
                            post: post,
                            department: department,
                            division: division,
                            teamid: teamid,
                            profiletype: 'Employment',
                            rank: '',
                            entity: '',
                            exttext1: '',
                            exttext2: '',
                            exttext3: '',
                            exttext4: '',
                            exttext5: '',
                            joineddate: joineddate
                              ? this.allinoneService.formatImportDate(
                                joineddate
                              )
                              : '',
                            effectivedate: effectivedate
                              ? this.allinoneService.formatImportDate(
                                effectivedate
                              )
                              : '',
                            dateofbirth: dob
                              ? this.allinoneService.formatImportDate(dob)
                              : '',
                            gender:
                              gender.toLowerCase() == 'male'
                                ? 'Male'
                                : gender.toLowerCase() == 'female'
                                  ? 'Female'
                                  : '',

                            section: section,
                            costcenter: costcenter,
                            subdivision: subdivision,
                            employmenttype: employmenttype
                              ? this.employmentTypes[employmentTypeIndex].id
                              : '',
                            transactiontype: transactiontype
                              ? this.transactionTypes[transactionTypeIndex].id
                              : '',
                            postadmin: '',
                            nrcregioncode: nrcregioncode,
                            nrctownshipcode: nrctownshipcode,
                            nrctype: nrctype,
                            nrcnumber: nrcnumber,
                            martialstatus: maritalstatusid,
                            mobile: this.allinoneService.checkUserId(mobile),
                          };
                          this.finalMemberList.push(tempMember);
                        }
                        // }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('warning ====> ');
    console.log(this.warningList);
    console.log('new classcode ====> ');
    console.log(this.newcclist);
    console.log('new role list ====> ');
    console.log(this.newrolelist);
    console.log('new Employment Type ====> ');
    console.log(this.newEmploymentTypeList);
    console.log('new Transaction Type ====> ');
    console.log(this.newTransactionTypeList);
    console.log('duplicate ====> ');
    console.log(this.duplicateList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (
      this.errorList.length == 0 &&
      this.newcclist.length == 0 &&
      this.newrolelist.length == 0 &&
      this.newEmploymentTypeList.length == 0 &&
      this.newTransactionTypeList.length == 0 &&
      this.finalMemberList.length > 0
    ) {
      this.checkEidList();
    } else {
      this.checkingExcelFile = false;
    }
  }

  getDomainList(): void {
    this.kunyekService.getDomains().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.domains.map((domain: any) => {
            this.domains.push({
              id: domain.id,
              appid: domain.appid,
              shortcode: domain.shortcode,
              domainid: domain.domainid,
              description: domain.description,
            });
          });
          this.gettingDomainList = false;
        } else {
          this.gettingDomainList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingDomainList = false;
      }
    );
  }

  getOrganization() {
    this.members = [];
    this.gettingOrgList = true;
    this.organizations = [];
    this.kunyekService.getOrgByDomainId(this.memberForm.domain).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((org: any) => {
            this.organizations.push({
              orgid: org.orgid,
              appid: org.appid,
              createdby: org.createdby,
              createddate: org.createddate,
              domainid: org.domainid,
              name: org.name,
              description: org.description,
            });
          });
          this.memberForm.org = this.organizations[0].orgid;
          this.getMemberList();
          this.gettingOrgList = false;
        } else {
          this.gettingOrgList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingOrgList = false;
      }
    );
  }

  async getMemberList(searchUserID: string = '') {
    this.memberListLoading = true;
    this.members = [];
    // this.postList;
    const data = {
      domainid: this.memberForm.domain,
      domain: this.allinoneService.getDomain().shortcode,
      orgid: this.memberForm.org,
      searchuserid: searchUserID,
    };
    this.kunyekService.getMembers(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (res.url != '') {
            this.readJsonFileList(res.url)
          }
          this.synchistoryList = res.historylist
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.memberListLoading = false;
          await this.prefetchLeaveTemplateData();
        }
      },
      (err) => {
        this.memberListLoading = false;
      }
    );
  }

  async addMember() {
    console.log('your add member');

    if (!this.isEditing) {
      if (this.fileSelected) {
        this.memberForm.filename = this.getFileName(this.fileSelected);
      } else {
        this.memberForm.filename = '';
      }
    } else {
      if (this.isFileChange && this.fileSelected) {
        this.memberForm.filename = this.getFileName(this.fileSelected);
      } else {
        if (this.isFileChange) {
          this.memberForm.filename = '';
        }
      }
    }

    var data = {
      domainid: this.memberForm.domain,
      orgid: this.memberForm.org,
      name: this.memberForm.name,
      adduser: this.allinoneService.checkUserId(
        this.memberForm.userid.toString().trim()
      ),
      olduser: this.memberForm.olduserid,
      type: this.memberForm.type,
      typeid: this.memberForm.typeid,
      contributor: this.memberForm.contributor ? '400' : '',
      postadmin: this.memberForm.postadmin ? '900' : '',
      employeeid: this.memberForm.eid,
      post: this.memberForm.post,
      department: this.memberForm.department,
      division: this.memberForm.division,
      teamid: this.memberForm.teamid,
      section: this.memberForm.section,
      costcenter: this.memberForm.costcenter,
      subdivision: this.memberForm.subdivision,
      remark: this.memberForm.remark,
      role: this.isAdmin ? '300' : '',
      hradmin: this.memberForm.hradmin ? '700' : '',
      transportation: this.memberForm.transportationadmin ? '800' : '',
      roleid: '',
      rolelist: this.roleIdList,
      profiletype: this.memberForm.profiletype,
      rank: this.memberForm.rank,
      entity: this.memberForm.entity,
      exttext1: this.memberForm.exttext1,
      exttext2: this.memberForm.exttext2,
      exttext3: this.memberForm.exttext3,
      exttext4: this.memberForm.exttext4,
      exttext5: this.memberForm.exttext5,
      filename: this.memberForm.filename,
      joineddate: this.memberForm.joineddate
        ? this.allinoneService.parseDate(this.memberForm.joineddate)
        : '',
      effectivedate: this.memberForm.effectivedate
        ? this.allinoneService.parseDate(this.memberForm.effectivedate)
        : '',
      dateofbirth: this.memberForm.dateofbirth
        ? this.allinoneService.parseDate(this.memberForm.dateofbirth)
        : '',
      gender: this.memberForm.gender,
      employmenttype: this.memberForm.employmenttype,
      transactiontype: this.memberForm.transactiontype,
      attendance: this.memberForm.attendancerequired,
      activity: this.memberForm.activityrequired,
      imagename: this.fileName ? this.fileName : this.editimage,
      positionname: this.memberForm.type,
      requisitionid: this.memberForm.requisitionid,
      roletype: this.memberForm.roletype,
      nrcregioncode: this.memberForm.nrcregioncode,
      nrctownshipcode: this.memberForm.nrctownshipcode,
      nrctype: this.memberForm.nrctype,
      nrcnumber: this.memberForm.nrcnumber,
      martialstatus: this.memberForm.martialstatus,
      paylevel: this.memberForm.paylevel,
      mobile: this.memberForm.mobile,
    };

    console.log(`Data: ${JSON.stringify(data)}`);

    if (this.fileName != '') {
      if (this.imageSelected.size >= 102400) {
        this.imageSelected = await this.allinoneService.compressImage(
          this.imageSelected,
          0.1
        );
        if (this.imageSelected == false) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.saveLoading = false;
          return;
        }
      }
      const result = await this.allinoneService.newfileUploadToS3(
        this.imageSelected,
        this.fileName,
        'organization/' + this.memberForm.org + '/'
      );
      if (result) {
      } else {
        this.messageService.openSnackBar(
          'Error while uploading attachment.',
          'warn'
        );
      }
    }

    if (this.isEditing) {
      data = Object.assign(data, { id: this.memberIdToEdit });
    }

    // console.log(data);
    // return;

    var fun = this.isEditing
      ? this.kunyekService.updateMember(data)
      : this.kunyekService.addMember(data);

    fun.subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.memberForm.leavecalendarid != '' ||
              this.memberForm.oldleavecalendarid != '') &&
            !this.gettingLeaveEntitlement
          ) {
            console.log('calendar call');

            var addEntitlement = await this.addLeaveEntitlement();
          }

          if (
            (this.memberForm.filename && !this.isEditing) ||
            (this.isEditing &&
              this.isFileChange &&
              this.memberForm.filename &&
              this.fileSelected)
          ) {
            const result = await this.allinoneService.fileUploadToS3(
              this.fileSelected,
              this.memberForm.filename
            );
            if (result) {
              this.saveLoading = false;
              this.cancel();
              this.getMemberList();
            } else {
              this.messageService.openSnackBar(
                'Error while uploading attachment.',
                'warn'
              );
              this.saveLoading = false;
              this.cancel();
              this.getMemberList();
            }
          } else {
            this.saveLoading = false;
            this.cancel();
            this.getMemberList();
          }
        } else {
          this.saveLoading = false;
          this.memberformSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.memberformSubmitted = false;
      }
    );
  }

  async bulkUploadMember() {
    var tempMember: any[] = [];
    for (let i = this.mmStart; i < this.mmEnd; i++) {
      tempMember.push(this.finalMemberList[i]);
    }

    var data = {
      domainid: this.memberForm.domain,
      orgid: this.memberForm.org,
      adduser: '',
      type: '',
      typeid: '',
      employeeid: '',
      post: '',
      department: '',
      division: '',
      remark: '',
      role: '',
      profiletype: '',
      rank: '',
      entity: '',
      exttext1: '',
      exttext2: '',
      exttext3: '',
      exttext4: '',
      exttext5: '',
      filename: '',
      createtype: 'array',
      memberlist: tempMember,
    };

    this.kunyekService.addMember(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          if (this.mmEnd == this.finalMemberList.length) {
            this.saveLoading = false;
            this.cancel();
            this.getMemberList();
          } else {
            this.mmStart = this.mmEnd;
            this.mmEnd = this.mmEnd + 30;
            if (this.mmEnd > this.finalMemberList.length) {
              this.mmEnd = this.finalMemberList.length;
            }
            this.bulkUploadMember();
          }
        } else {
          this.saveLoading = false;
          this.memberformSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.memberformSubmitted = false;
      }
    );
  }

  checkMember() {
    this.checkingMember = true;
    this.isPreRegister = false;
    this.isUserValid = false;
    const data = {
      searchuserid: this.allinoneService.checkUserId(this.memberForm.userid),
      domainid: this.memberForm.domain,
    };
    this.kunyekService.checkMember(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.username = res.username;
          if (!this.isEditing) {
            this.memberForm.name = res.username;
          }
          this.isUserValid = true;
        } else if (res.message == 'User does not exists.') {
          this.isPreRegister = true;
          this.isUserValid = true;
        } else {
          this.isUserValid = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.checkingMember = false;
      },
      (err) => {
        this.checkingMember = false;
        this.isUserValid = false;
      }
    );
  }
  memberAdd() {
    // this.getCalendarList();
    const calendarget = this.getCalendarList();
    const templateget = this.getTemplateList();
    // const leaveget= this.getLeaveEntitlement();
    const a = calendarget;
    const b = templateget;
    this.memberAddorNot = !this.memberAddorNot;
    this.isFileChange = true;
    this.isUserValid = true;
    this.allok = false;
  }

  submitMember() {

    this.roleIdList = this.isRoleSelected
      ? Object.values(this.memberForm.roleList).map(
        (value: any) => value.roleid
      )
      : [];

    if (this.memberAddorNot && !this.isBulkUpload) {
      this.memberformSubmitted = true;

      if (!this.memberForm.userid) {
        this.allinoneService.scrollTo('userid');
        return;
      }

      if (!this.memberForm.typeid) {
        this.allinoneService.scrollTo('typeid');
        return;
      }

      if (this.isRoleSelected && this.roleIdList.length == 0) {
        return;
      }

      // if (
      //   this.memberForm.dateofbirth &&
      //   !this.allinoneService.checkDate(this.memberForm.dateofbirth)
      // ) {
      //   this.messageService.openSnackBar('Invalid Date of Birth.', 'warn');
      //   return;
      // }

      if (
        this.memberForm.nrcregioncode ||
        this.memberForm.nrctownshipcode ||
        this.memberForm.nrctype ||
        this.memberForm.nrcnumber
      ) {
        if (
          this.memberForm.nrcregioncode == '' ||
          this.memberForm.nrctownshipcode == '' ||
          this.memberForm.nrctype == '' ||
          this.memberForm.nrcnumber == ''
        ) {
          this.messageService.openSnackBar('Invalid NRC Number.', 'warn');
          this.nrcRequired = true;
          return;
        }
      }

      if (this.memberForm.nrcnumber && this.memberForm.nrcnumber.length != 6) {
        this.messageService.openSnackBar('Invalid NRC Number.', 'warn');
        return;
      }

      if (
        (this.memberForm.leavecalendarid &&
          this.memberForm.leavetemplateid == '') ||
        (this.memberForm.leavecalendarid == '' &&
          this.memberForm.leavetemplateid)
      ) {
        this.allinoneService.scrollTo('leavecalendar');
        return;
      }

      if (
        this.needToFillPositionControl &&
        (this.memberForm.department == '' ||
          this.memberForm.post == '' ||
          this.memberForm.effectivedate == '' ||
          this.memberForm.employmenttype == '' ||
          this.memberForm.transactiontype == '')
      ) {
        if (this.memberForm.post == '') {
          this.allinoneService.scrollTo('type');
        } else if (this.memberForm.department == '') {
          this.allinoneService.scrollTo('type');
        } else if (this.memberForm.effectivedate == '') {
          this.allinoneService.scrollTo('effectivedate');
        } else {
          this.allinoneService.scrollTo('employmenttype');
        }
        return;
      }

      if (
        !this.memberForm.joineddate || !this.memberForm.effectivedate
      ) {
        if (this.memberForm.effectivedate == '') {
          this.allinoneService.scrollTo('joineddate');
        } else if (this.memberForm.joineddate == '') {
          this.allinoneService.scrollTo('joineddate');
        }
        return;
      }

      // if (
      //   this.memberForm.joineddate &&
      //   !this.allinoneService.checkDate(this.memberForm.joineddate)
      // ) {
      //   this.messageService.openSnackBar('Invalid Joined Date.', 'warn');
      //   return;
      // }

      // if (
      //   this.memberForm.effectivedate &&
      //   !this.allinoneService.checkDate(this.memberForm.effectivedate)
      // ) {
      //   this.messageService.openSnackBar('Invalid Effective Date.', 'warn');
      //   return;
      // }

      if (
        this.memberForm.joineddate &&
        this.memberForm.effectivedate &&
        this.memberForm.joineddate > this.memberForm.effectivedate
      ) {
        return this.messageService.openSnackBar(
          'Invalid Effective Date.',
          'warn'
        );
      }

      if (this.rolerequired && this.memberForm.roletype == '') {
        this.allinoneService.scrollTo('roletype');
        return;
      }

      if (
        (this.memberForm.profiletype == 'Employment' ||
          this.memberForm.profiletype == 'Loyalty' ||
          this.memberForm.profiletype == '') &&
        this.memberForm.domain != '' &&
        this.memberForm.org != '' &&
        this.memberForm.userid != '' &&
        this.memberForm.typeid != ''
        // this.memberForm.eid != ''
      ) {
        if (this.isMemberID == true && this.memberForm.eid == '') {
          return;
        }

        this.saveLoading = true;
        this.addMember();
      } else if (
        this.memberForm.profiletype == 'Membership' &&
        this.memberForm.domain != '' &&
        this.memberForm.org != '' &&
        this.memberForm.userid != '' &&
        this.memberForm.type != ''
        // this.memberForm.rank != ''
        // this.memberForm.entity != ''
        // this.memberForm.eid != ''
      ) {
        if (this.isMemberID == true && this.memberForm.eid == '') {
          return;
        }
        this.saveLoading = true;
        this.addMember();
      }
    } else {
      if (this.memberForm.domain != '' && this.memberForm.org != '') {
        this.saveLoading = true;
        this.mmStart = 0;
        this.mmEnd = 30;
        if (this.mmEnd > this.finalMemberList.length) {
          this.mmEnd = this.finalMemberList.length;
        }
        this.bulkUploadMember();
      }
    }
  }

  // domainChanged(event: any) {
  //   if (this.role == "100") {
  //     this.memberForm.org = ""
  //   }
  //   this.memberForm.domain = event.target.value
  //   this.selectedDomainName = ""
  //   this.selectedOrgName = ""
  //   if (this.memberForm.domain == "") {
  //     this.members = []
  //   } else {
  //     this.getOrganization()
  //     for (let i = 0; i < this.domains.length; i++) {
  //       if (this.memberForm.domain == this.domains[i].domainid) {
  //         this.selectedDomainName = this.domains[i].description
  //       }
  //     }
  //   }
  // }

  orgChanged(event: any) {
    this.searchPost = 'All';
    this.searchDepartment = 'All';
    this.postList = ['All'];
    this.departmentList = ['All'];
    this.divisionList = ['All'];
    this.costcenterList = ['All'];
    this.memberForm.org = event.target.value;
    this.allinoneService.selectedOrg = this.memberForm.org;
    this.allinoneService.setSelectedOrg(this.memberForm.org);
    this.ccList = [];
    this.memberForm.type = '';
    this.memberForm.typeid = '';
    if (this.memberForm.org == '') {
      this.members = [];
      this.selectedOrgName = '';
      this.tagEmail = '';
      this.membershiptype = '';
    } else {
      const index = this.organizations.findIndex(
        (x: any) => x.orgid == this.memberForm.org
      );
      this.tagEmail = this.organizations[index].tagemail;
      this.membershiptype = this.organizations[index].membership;
      this.getMemberList();
      this.getCcList();
      for (let i = 0; i < this.organizations.length; i++) {
        if (this.memberForm.org == this.organizations[i].orgid) {
          this.selectedOrgName = this.organizations[i].name;
        }
      }
    }
    this.setDefaultMembershipType();
    if (this.tagEmail == '') {
      this.tagmailediting = true;
      this.firstTime = true;
    } else {
      this.tagmailediting = false;
      this.firstTime = false;
    }
  }

  deleteMember(id: any, userid: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.memberListLoading = true;
        const data = {
          orgid: this.memberForm.org,
          domainid: this.memberForm.domain,
          id: id,
          adduser: userid,
        };
        this.kunyekService.removeMember(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.getMemberList();
            } else {
              this.memberListLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.memberListLoading = false;
          }
        );
      }
    });
  }

  editMember(item: any) {
    console.log(`Details: ${JSON.stringify(item)}`);
    window.scrollTo(0, 0);
    this.gettingLeaveEntitlement = true;
    this.memberAddorNot = true;
    this.isEditing = true;
    this.memberIdToEdit = item.id;

    // this.username = item.username
    this.isUserValid = true;
    console.log(item);
    if (item.employeeid) {
      this.isMemberID = true;
    } else {
      this.isMemberID = false;
    }

    let selectedRole: any = [];
    for (var j = 0; j < item.rolelist.length; j++) {
      var role = this.roleList.filter(function (role: any) {
        return role.roleid == item.rolelist[j];
      });
      selectedRole.push({
        roleid: role[0]['roleid'],
        name: role[0]['name'],
      });
    }

    console.log(item.nrctownshipcode, 'nrcreg............')

    this.memberForm = {
      domain: this.memberForm.domain,
      org: this.memberForm.org,
      name: item.username,
      userid: item.userid,
      newuserid: item.newuserid,
      olduserid: item.userid,
      type: item.type,
      typeid: item.typeid,
      contributor: item.contributor ? true : false,
      postadmin: item.postadmin ? true : false,
      hradmin: item.hradmin ? true : false,
      transportationadmin: item.transportation ? true : false,
      roleList: selectedRole,
      eid: item.employeeid,
      post: item.post,
      department: item.department,
      division: item.division,
      teamid: item.teamid,
      section: item.section,
      costcenter: item.costcenter,
      subdivision: item.subdivision,
      joineddate: item.joineddate
        ? this.allinoneService.parseDateToShowInEdit(item.joineddate)
        : item.joineddate,
      effectivedate: item.effectivedate
        ? this.allinoneService.parseDateToShowInEdit(item.effectivedate)
        : item.effectivedate,
      dateofbirth: item.dateofbirth
        ? this.allinoneService.parseDateToShowInEdit(item.dateofbirth)
        : '',
      gender: item.gender || '',
      employmenttype: item.employmenttype,
      transactiontype: item.transactiontype,
      profiletype: item.profiletype,
      rank: item.rank,
      entity: item.entity,
      exttext1: item.exttext1,
      exttext2: item.exttext2,
      exttext3: item.exttext3,
      exttext4: item.exttext4,
      exttext5: item.exttext5,
      filename: item.filename,
      remark: item.remark,
      url: item.url,
      leavecalendarid: '',
      leavetemplateid: '',
      oldleavecalendarid: '',
      oldleavetemplateid: '',
      attendancerequired: item.attendance,
      activityrequired: item.activity,
      imgURL: item.imageurl,
      // imgURL: '',
      imageLoaded: item.imageLoaded || false,
      requisitionid: item.requisitionid,
      roletype: item.roletype,
      nrcregioncode: item.nrcregioncode || '',
      nrctownshipcode: item.nrctownshipcode || '',
      nrctype: item.nrctype || '',
      nrcnumber: item.nrcnumber || '',
      martialstatus: item.martialstatus || '',
      paylevel: item.paylevel || '',
      inactive: item.transactiontypestatus != '0',
      mobile: item.mobile || '',
    };
    this.editBreadcrumbName =
      this.memberForm.name == ''
        ? this.memberForm.userid
        : this.memberForm.name;

    this.editimage = item.imagename;
    const calendarget = this.getCalendarList();
    const templateget = this.getTemplateList();
    const a = calendarget;
    const b = templateget;

    if (!this.memberForm.imageLoaded) {
      this.getProfileImage();
    }

    this.positionControlFieldsChange();
    this.setDefaultMembershipType();

    this.isAdmin = item.role == '300' ? true : false;
    // this.isRoleSelected = this.memberForm.roleList.length > 0 ? true : false;
    this.isRoleSelected = item.rolelist.length > 0 ? true : false;
    this.isFileChange = this.memberForm.filename ? false : true;
    this.noFile = this.memberForm.filename ? false : true;

    if (this.memberForm.employmenttype || this.memberForm.transactiontype) {
      this.hasPositionControl = true;
    }

    // this.checkMember();
  }

  getProfileImage() {
    this.gettingProfileImage = true;
    const data = {
      searchuserid: this.memberForm.userid,
      orgid: this.memberForm.org,
    };
    this.profileImageSubscription &&
      this.profileImageSubscription.unsubscribe();
    this.profileImageSubscription = this.kunyekService
      .getMemberProfileImage(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.memberForm.imgURL = res.imageurl;
            this.memberForm.imageLoaded = true;
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
          this.gettingProfileImage = false;
        },
        (err: any) => {
          this.gettingProfileImage = false;
        }
      );
  }

  cancel() {
    if (this.fromRequisition && this.members.length == 0) {
      this.getMemberList();
    }
    if (this.isBulkUpload) {
      this.removeExcel();
      this.isBulkUpload = false;
    } else {
      const memeberIndex = this.members.findIndex(
        (user: any) => user.userid == this.memberForm.userid
      );
      if (memeberIndex > -1) {
        this.members[memeberIndex].imageurl = this.memberForm.imgURL;
        this.members[memeberIndex].imageLoaded = this.memberForm.imageLoaded;
      }
    }
    this.isMemberID = false;
    this.memberAddorNot = false;
    this.memberformSubmitted = false;
    this.nrcRequired = false;
    this.memberIdToEdit = '';
    this.username = '';
    this.isEditing = false;
    this.isUserValid = false;
    this.isAdmin = false;
    this.fileSelected = '';
    this.imageSelected = '';
    this.editimage = '';
    this.fileName = '';
    this.noFile = false;
    this.isFileChange = false;
    this.allok = false;
    this.isRoleSelected = false;
    this.hasPositionControl = false;
    this.needToFillPositionControl = false;
    this.memberForm = {
      domain: this.memberForm.domain,
      org: this.memberForm.org,
      name: '',
      userid: '',
      olduserid: '',
      type: '',
      typeid: '',
      eid: '',
      post: '',
      contributor: false,
      postadmin: false,
      hradmin: false,
      transportationadmin: false,
      roleList: [],
      department: '',
      division: '',
      teamid: '',
      joineddate: '',
      effectivedate: '',
      dateofbirth: '',
      gender: '',
      employmenttype: '',
      transactiontype: '',
      profiletype: 'Employment',
      rank: '',
      entity: '',
      exttext1: '',
      exttext2: '',
      exttext3: '',
      exttext4: '',
      exttext5: '',
      filename: '',
      remark: '',
      url: '',
      leavecalendarid: '',
      leavetemplateid: '',
      oldleavecalendarid: '',
      oldleavetemplateid: '',
      attendancerequired: true,
      activityrequired: true,
      imgURL: '',
      section: '',
      costcenter: '',
      subdivision: '',
      requisitionid: '',
      roletype: '',
      nrcregioncode: '',
      nrctownshipcode: '',
      nrctype: '',
      nrcnumber: '',
      martialstatus: '',
      paylevel: '',
      inactive: false,
    };
    this.setDefaultMembershipType();
  }

  goToOrg() {
    // this.router.navigateByUrl('organizations');
    this.router.navigateByUrl('references/organizations');

  }

  goToOrgEdit() {
    // this.router.navigateByUrl(`organizations?org=${this.memberForm.org}`);
    this.router.navigateByUrl(`references/organizations?org=${this.memberForm.org}`);
  }
  useridChange() {
    this.username = '';
    this.isUserValid = false;
    this.isPreRegister = false;
  }
  profileTypeChange() {
    this.memberformSubmitted = false;
  }
  onFileSelected(event: any) {
    if (this.memberForm.inactive) {
      return;
    }
    this.fileSelected = event.target.files[0];
    const filename = event.target.files[0].name.toString();
    this.filetype = filename.split('.').pop();
  }
  removefileSelected() {
    this.fileSelected = '';
    this.fileInput.nativeElement.value = '';
  }
  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  downloadAttachment() {
    this.downloading = true;
    const filetype = this.memberForm.filename.split('.').pop();
    console.log(filetype);

    if (filetype == 'jpg' || filetype == 'png' || filetype == 'jpeg') {
      this.downloadFile().subscribe((response: any) => {
        saveAs(response, this.memberForm.filename);
        this.downloading = false;
      }),
        (error: any) => {
          this.downloading = false;
          this.messageService.openSnackBar('Download failed.', 'warn');
        };
    } else {
      var link = document.createElement('a');
      link.href = this.memberForm.url;
      link.download = this.memberForm.filename;
      link.target = '_blank';
      link.click();
      link.remove();
      this.downloading = false;
    }
  }

  downloadFile(): any {
    return this.http.get(this.memberForm.url, { responseType: 'blob' });
  }

  findRoleName(id: any) {
    const index = this.academicList.findIndex((x: any) => x.roleid == id);
    if (index > -1) {
      var name = this.academicList[index].name;
      return name;
    }
    return '';
  }

  downloadSheet() {
    this.downloading = true;  // Set downloading to true at the beginning
    this.cdr.detectChanges();

    var membersToExport = this.filter.transform(
      this.members,
      this.searchText,
      this.searchPost,
      this.searchDepartment,
      this.searchDivision,
      this.searchCostCenter,
      this.searchStatus
    );
    var data: any = [];
    var name = 'MembersSheet.xlsx';
  
    // Check if leave template data is prefetched or not
    if (this.LeaveTemplateList.length === 0) {
      console.log("Prefetching leave template data...");
  
      // Fetch leave template data if it is not already prefetched
      this.prefetchLeaveTemplateData().then(() => {
        console.log("Leave template data fetched:", this.LeaveTemplateList);
  
        // After fetching leave template data, proceed with export
        return this.createExportSheet(membersToExport, data, name);
      }).catch((error) => {
        console.error("Error fetching leave template data or exporting sheet:", error);
      }).finally(() => {
        // Ensure downloading is set to false after everything is done (whether success or failure)
        this.downloading = false;
        this.cdr.detectChanges(); 
      });
    } else {
      console.log("Leave template data already prefetched:", this.LeaveTemplateList);
  
      this.createExportSheet(membersToExport, data, name)
        .then(() => {
          console.log("Export completed.");
        })
        .catch((error) => {
          console.error("Error exporting sheet:", error);
        })
        .finally(() => {
          this.downloading = false;
          this.cdr.detectChanges(); 
        });
    }
  }
  
  createExportSheet(membersToExport: any[], data: any[], name: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        console.log("Creating export sheet with members", membersToExport);
  
        membersToExport.forEach((member: any) => {
          const leaveData = this.LeaveTemplateList.find((template) => template.userid === member.userid) || {};
  
          // Prepare the data for each member
          data.push({
            'User ID': member.userid,
            Name: member.username,
            Email: this.allinoneService.isEmail(member.userid) ? member.userid : '',
            Mobile: this.allinoneService.isEmail(member.userid) ? '' : member.userid,
            'Date of Birth': member.dateofbirth ? this.allinoneService.formatDBToShow(member.dateofbirth) : '',
            Gender: member.gender,
            NRC: member.nrcnumber
              ? member.nrcregioncode + '/' + member.nrctownshipcode + '(' + member.nrctype + ')' + member.nrcnumber
              : '',
            'Martial Status': member.martialstatus == ''
              ? ''
              : this.martialStatus.find((x: any) => x.code == member.martialstatus)?.name,
            'Employee ID': member.employeeid,
            'Team ID': member.teamid,
            [this.membershiptype == '003' ? 'Member Type' : 'Post']: member.type,
            Branch: member.post,
            Department: member.department,
            Division: member.division,
            Section: member.section,
            'Cost Center': member.costcenter,
            'Sub Division': member.subdivision,
            'Pay Level': member.paylevel == ''
              ? ''
              : this.paylevelList.find((x: any) => x.paylevelid == member.paylevel)?.name,
            Role: this.findRoleName(member.roletype),
            'Joined Date': member.joineddate ? this.allinoneService.formatDBToShow(member.joineddate) : '',
            'Effective Date': member.effectivedate ? this.allinoneService.formatDBToShow(member.effectivedate) : '',
            'Employment Type': member.employmenttypename,
            'Transaction Type': member.transactiontypename,
            'Leave Template': leaveData.leaveTemplate || '',  // Use the prefetched leave template data
            Admin: member.role == '300' ? '1' : '',
          });
        });
  
        console.log("Export data prepared", data);
        // Export the data to Excel
        this.allinoneService.exportEcecl(data, name);
        resolve();  // Resolve the promise after the export is complete
      } catch (error) {
        reject(error);  // Reject the promise if any error occurs
      }
    });
  }
  
  openMemberTypeForm(type: string) {
    const dialog = this.dialog.open(MembertypesComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        orgid: this.memberForm.org,
        fromOrg: this.isFromOrg,
        org: this.allinoneService.getSessionValue('organization'),
        type: type,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getMemberTypes();
    });
  }

  openClassCodeForm() {
    const dialog = this.dialog.open(ClasscodeComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        fromHCM: this.isHCM,
        orgid: this.memberForm.org,
        fromOrg: this.isFromOrg,
        org: this.allinoneService.getSessionValue('organization'),
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getCcList();
      console.log(this.memberForm);
    });
  }

  openPayLevelForm() {
    const dialog = this.dialog.open(PayLevelComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        fromHCM: this.isHCM,
        orgid: this.memberForm.org,
        fromOrg: this.isFromOrg,
        org: this.allinoneService.getSessionValue('organization'),
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getPayLevelList();
      console.log(this.memberForm);
    });
  }

  openRoleForm() {
    const dialog = this.dialog.open(MembersRoleComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        fromHCM: this.isHCM,
        orgid: this.memberForm.org,
        fromOrg: this.isFromOrg,
        org: this.allinoneService.getSessionValue('organization'),
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getAcademicList();
      console.log(this.memberForm);
    });
  }

  openPositionHistoryForm() {
    const dialog = this.dialog.open(MemberPositionHistoryComponent, {
      width: '95vw',
      height: '500px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        orgid: this.memberForm.org,
        fromOrg: this.isFromOrg,
        org: this.allinoneService.getSessionValue('organization'),
        userid: this.memberForm.userid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => { });
  }

  getCcList() {
    this.gettingCcList = true;
    const data = {
      orgid: this.memberForm.org,
    };
    this.kunyekService.getClassCodeList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;
          this.ccList.sort((a: any, b: any) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();

            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.postList = this.postList.concat(this.postList, this.ccList.map((x: any) => x.name));
          this.postList = [...new Set(this.postList)];


          this.gettingCcList = false;
          if (this.isBulkUpload) {
            this.checkExcelFile();
          }
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }

  getPayLevelList() {
    this.gettingPaylevel = true;
    const data = {
      orgid: this.memberForm.org,
    };
    this.kunyekService.getPaylevel(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.paylevelList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingPaylevel = false;
      },
      (err) => {
        this.gettingPaylevel = false;
      }
    );
  }

  getAcademicList() {
    this.gettingAcademicList = true;
    const data = {
      orgid: this.memberForm.org,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getUserRole(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.academicList = res.datalist;
          this.gettingAcademicList = false;
          if (this.isBulkUpload) {
            this.checkExcelFile();
          }
        } else {
          this.gettingAcademicList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingAcademicList = false;
      }
    );
  }

  getMemberTypes() {
    this.gettingMemberTypes = true;
    const data = {
      orgid: this.memberForm.org,
      type: '',
    };
    this.kunyekService.getMemberTypes(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.transactionTypes = res.transaction;
          this.employmentTypes = res.employment;
          if (this.isBulkUpload) {
            this.checkExcelFile();
          }
          this.gettingMemberTypes = false;
        } else {
          this.gettingMemberTypes = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingMemberTypes = false;
      }
    );
  }

  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
      orgid: this.memberForm.org,
    };
    this.kunyekService.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.nrcCode = res.datalist;
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.martialStatus = res.datalist.martialStatus;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }

  classCodeChange(event: any) {
    this.memberForm.typeid = event.target.value;

    const index = this.ccList.findIndex(
      (x: any) => x.id == this.memberForm.typeid
    );
    if (index > -1) {
      this.memberForm.type = this.ccList[index].name;
    } else {
      this.memberForm.type = '';
    }
    console.log(this.memberForm);
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  trackByPaylevelid(index: number, item: any) {
    return item.paylevelid;
  }

  saveCc() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      panelClass: ['mat-typography'],
      // width: "250px",
      closeOnNavigation: true,
      data: {
        cmessage:
          'Are you sure you want to save these all ' +
          (this.membershiptype == '003' ? 'member types' : 'posts') +
          '?',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.ccStart = 0;
        this.ccEnd = 30;
        if (this.ccEnd > this.newcclist.length) {
          this.ccEnd = this.newcclist.length;
        }
        this.recursiveSaveCc();
      }
    });
  }

  recursiveSaveCc() {
    this.ccSaveLoading = true;
    var tempCc: any[] = [];
    for (let i = this.ccStart; i < this.ccEnd; i++) {
      tempCc.push(this.newcclist[i]);
    }
    var data = {
      orgid: this.memberForm.org,
      name: '',
      description: '',
      // rolelist: tempCc,
      classcodelist: tempCc,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.createClasscode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.ccEnd == this.newcclist.length) {
            this.getCcList();
            this.ccSaveLoading = false;
            this.messageService.openSnackBar('Successful', 'success');
          } else {
            this.ccStart = this.ccEnd;
            this.ccEnd = this.ccEnd + 30;
            if (this.ccEnd > this.newcclist.length) {
              this.ccEnd = this.newcclist.length;
            }
            this.recursiveSaveCc();
          }
        } else {
          this.ccSaveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.ccSaveLoading = false;
      }
    );
  }

  saveNewRole() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      panelClass: ['mat-typography'],
      // width: "250px",
      closeOnNavigation: true,
      data: {
        cmessage: 'Are you sure you want to save these all Roles ?',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.roleStart = 0;
        this.roleEnd = 30;
        if (this.roleEnd > this.newrolelist.length) {
          this.roleEnd = this.newrolelist.length;
        }
        this.saveNewR();
      }
    });
  }

  saveNewR() {
    this.roleSaveLoading = true;
    var tempR: any[] = [];
    for (let i = this.roleStart; i < this.roleEnd; i++) {
      tempR.push(this.newrolelist[i]);
    }
    var data = {
      orgid: this.memberForm.org,
      name: '',
      description: '',
      roleList: tempR,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.addUserRole(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.roleEnd == this.newrolelist.length) {
            this.getAcademicList();
            this.roleSaveLoading = false;
            this.messageService.openSnackBar('Successful', 'success');
          } else {
            this.roleStart = this.roleEnd;
            this.roleEnd = this.roleEnd + 30;
            if (this.roleEnd > this.newrolelist.length) {
              this.roleEnd = this.newrolelist.length;
            }
            this.saveNewRole();
          }
        } else {
          this.roleSaveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.roleSaveLoading = false;
      }
    );
  }

  downloadSampleFile(): any {
    let url = this.allinoneService.bucketUrl;
    if (!this.isAttendanceView) {
      url += 'templates/MemberSampleTemplate.xlsx';
    } else {
      url += 'templates/AttendanceMemberSampleTemplate.xlsx';
    }
    this.http
      .get(url, {
        responseType: 'blob',
      })
      .subscribe((response: any) => {
        saveAs(response, 'MemberSampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  checkEidList() {
    this.allok = false;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.memberForm.org,
      employeeid: this.eidList,
      domain: this.allinoneService.getDomain().shortcode,
      membercount: this.finalMemberList.length.toString(),
    };
    this.kunyekService.checkEmployeeidList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.errorList = res.datalist;
          if (this.errorList.length == 0) {
            this.allok = true;
          }
          this.checkingExcelFile = false;
        } else {
          this.checkingExcelFile = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.checkingExcelFile = false;
      }
    );
  }
  addRole() {
    const dialog = this.dialog.open(RoleComponent, {
      width: '700px',
      height: '555px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        fromCreate: true,
        orgid: this.memberForm.org,
        // orgid: this.isFromOrg ? this.memberForm.org : '',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      // if (dialogResult) {
      this.getRoleList();
      // }
    });
  }

  roleChange($event: any) {
    // if ($event.target.checked) {
    // this.memberForm.contributor = false;
    // this.isAdmin = false;
    // }
  }

  adminContriChanged() {
    // if (this.isAdmin || this.memberForm.contributor) {
    //   this.isRoleSelected = false;
    //   this.memberForm.roleList = [];
    // }
  }

  uploadFile(event: any) {
    // return;
    if (this.memberForm.inactive) {
      return;
    }

    this.imageSelected = event[0];
    // console.log(this.fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.imageSelected);
    reader.onload = (_event) => {
      this.memberForm.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.imageSelected);
  }

  uploadFile1(event: any) {
    // return;
    if (this.memberForm.inactive) {
      return;
    }
    this.imageSelected = event.target.files[0];
    // console.log(fileSelected);

    var reader = new FileReader();
    // this.imagePath = fileSelected;
    reader.readAsDataURL(this.imageSelected);
    reader.onload = (_event) => {
      this.memberForm.imgURL = reader.result;
    };

    this.fileName = this.getFileName(this.imageSelected);
    console.log(this.fileName);
  }

  removeImage() {
    this.fileName = '';
    this.imageSelected = '';
    this.editimage = '';
    this.memberForm.imgURL = '';
    this.fileInput.nativeElement.value = '';
  }

  getRoleList() {
    this.roleListLoading = true;
    let data = {
      orgid: this.memberForm.org,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getRoleList(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.roleListLoading = false;
          this.roleList = [];
          for (var i = 0; i < res.datalist.length; i++) {
            this.roleList.push({
              roleid: res.datalist[i]['roleid'],
              name: res.datalist[i]['name'],
            });
          }
          let selectedRole: any = [];
          for (var j = 0; j < this.memberForm.roleList.length; j++) {
            var role = this.roleList.filter((role: any) => {
              return role.roleid == this.memberForm.roleList[j]['roleid'];
            });
            selectedRole.push({
              roleid: role[0]['roleid'],
              name: role[0]['name'],
            });
          }
          this.memberForm.roleList = selectedRole;
        } else {
          this.roleListLoading = false;
        }
      },
      (err) => {
        this.roleListLoading = false;
      }
    );
  }
  addLeaveEntitlement() {
    return new Promise((resolve) => {
      let data = {
        calendarid: this.memberForm.leavecalendarid
          ? this.memberForm.leavecalendarid
          : this.memberForm.oldleavecalendarid,
        templateid: this.memberForm.leavetemplateid
          ? this.memberForm.leavetemplateid
          : this.memberForm.oldleavetemplateid,
        type: '',
        // userlist: [this.memberForm.userid],
        userlist: [this.allinoneService.checkUserId(this.memberForm.userid)],
        membertype: [],
        orgid: this.memberForm.org,
        domainid: this.memberForm.domain,
        fromweb: true,
        fromwebremove: this.memberForm.leavecalendarid == '',
        joineddate: this.memberForm.joineddate
          ? this.allinoneService.parseDate(this.memberForm.joineddate)
          : '',
      };

      this.subscriptions.sink = this.kunyekService
        .addLeaveEntitlement(data)
        .subscribe(
          (res) => {
            if (res.returncode == 300) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
          (err) => {
            resolve(false);
          }
        );
    });
  }

  removeDate(type: string) {
    if (type == 'join') {
      this.memberForm.joineddate = '';
    } else if (type == 'birth') {
      this.memberForm.dateofbirth = '';
    } else {
      this.memberForm.effectivedate = '';
      this.positionControlFieldsChange();
    }
  }

  positionControlFieldsChange() {
    if (
      this.memberForm.effectivedate != '' ||
      this.memberForm.employmenttype != '' ||
      this.memberForm.transactiontype != ''
    ) {
      this.needToFillPositionControl = true;
    } else {
      this.needToFillPositionControl = false;
    }
  }

  // getRequisitionList() {
  //   this.gettingRequisition = true;
  //   const data = {
  //     orgid: this.memberForm.org,
  //     status: '004', // 004 is completed status in requisition
  //   };
  //   this.subscriptions && this.subscriptions.unsubscribe();
  //   this.kunyekService
  //     .getHrRequisition(data)
  //     .subscribe(
  //       (res: any) => {
  //         if (res.returncode == '300') {
  //           this.requisitionList = [];
  //           res.datalist.map((x: any) => {
  //             var positions = '';
  //             x.positions.map((p: any, index: number) => {
  //               if (index == 0) {
  //                 positions += p.name;
  //               } else {
  //                 positions += ', ' + p.name;
  //               }
  //             });
  //             var temp = {
  //               ...x,
  //               positionlists: positions,
  //               statusname: this.statusList.find(
  //                 (z: any) => z.value == x.hrstatus
  //               )?.name,
  //             };
  //             this.requisitionList.push(temp);
  //           });
  //         } else {
  //           this.messageService.openSnackBar(
  //             res.message || res.status || 'Server Error',
  //             'warn'
  //           );
  //         }
  //         this.gettingRequisition = false;
  //       },
  //       (err) => {
  //         this.gettingRequisition = false;
  //       }
  //     );
  // }

  refresh() {
    this.getCcList();
    this.getMemberTypes();
    this.getMemberList();
    this.getRoleList();
    this.getAcademicList();
  }

  nrcChange() {
    if (
      this.memberForm.nrcregioncode ||
      this.memberForm.nrctownshipcode ||
      this.memberForm.nrctype ||
      this.memberForm.nrcnumber
    ) {
      if (
        this.memberForm.nrcregioncode == '' ||
        this.memberForm.nrctownshipcode == '' ||
        this.memberForm.nrctype == '' ||
        this.memberForm.nrcnumber == ''
      ) {
      } else {
        this.nrcRequired = false;
      }
    } else {
      this.nrcRequired = false;
    }
  }

  gotoRequisition(recruitment: boolean) {
    if (recruitment) {
      this.router.navigateByUrl('/hxm/recruitment');
    } else {
      this.router.navigateByUrl('/hxm/recruitment/requisitions');
    }
  }

  syncFromNeoPayroll() {
    console.log(this.memberForm.org)
    if (this.syncMemberInfoLoading) {
      return;
    }
    const dialog = this.dialog.open(MemberInfoSyncDialogComponent, {
      maxWidth: '95vw',
      width: '450px',
      maxHeight: '95vh',
      closeOnNavigation: true,
      data: {
        orgid: this.memberForm.org,
        cmessage: 'Synchronise employee info from Neo Payroll?',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.syncMemberInfoLoading = true;
        const data = {
          orgid: this.memberForm.org,
        };
        this.kunyekService.memberInfoSyncData(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.messageService.openSnackBar(
                'It can take up to 15 minutes to update the employee info data.',
                'success'
              );
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.syncMemberInfoLoading = false;
          },
          (err) => {
            this.syncMemberInfoLoading = false;
          }
        );
      }
    });
  }

  getSyncHistory() {
    console.log(this.synchistoryList,'histlis')
    const dialog = this.dialog.open(SynchistoryComponent, {
      width: '800px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: { historyList: this.synchistoryList }
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.synchistoryList;
        // console.log(`Member List: ${JSON.stringify(this.synchistoryList)}`);
      }
    });
  }

  async readJsonFileList(url: string) {
    this.http.get(url).subscribe((res: any) => {
      this.members = res      
      console.log(res.historylist)
      if (this.fromRequisition && this.memberAddorNot) {
        this.editMember(this.members[0]);
      } else {
        // this.synchistoryList = res.historylist;
        // this.members = res.datalist;
        this.pg[3].count = this.members.length;
        // for (var _i = 0; _i < this.members.length; _i++) {
        //   if (res.datalist[_i].type) {
        //     if (
        //       !this.postList.includes(res.datalist[_i].type) &&
        //       res.datalist[_i].type.trim() != ''
        //     ) {
        //       this.postList.push(res.datalist[_i].type);
        //     }
        //   } else {
        //     // console.log(res.datalist[_i])
        //   }
        // }
      }
      this.memberListLoading = false;
    })
    
  }
}

