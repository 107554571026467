<div class="title-container mb-3">
  <div class="title">
    Passengers
  </div>
  <div class="close-btn">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<hr>

<!-- <div class="form-group mb-3" *ngIf="type == '001'">
  <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
    [(ngModel)]="customerSearch" name="'contactsearch" id="contactsearch" (ngModelChange)="searchChange()">
</div> -->
<div class="form-group mb-3" >
  <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
    [(ngModel)]="searchText" name="'contactsearch" id="contactsearch">
</div>


<div style="height: 330px; overflow-y: hidden;" *ngIf="type == '001'">
  <div>
    <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
      (scrolled)="onScrolledDown()" [perfectScrollbar]="config" class="chat-container"
      style="height: 390px !important;position: relative;padding-bottom: 100px;" #chatPS>
      <ul class="list-group" *ngIf="customerList.length > 0">
       
        <div class="profile-container card" *ngFor="let user of customerList | filter : searchText:'userdropoff'" style="padding: 10px;" (click)="selectUser($event,user)">
          <div >
            

            <label class="d-flex align-items-center justify-content-center mb-1"  >
              <div class="profile-item-container">
                <div class="profile-item">
                  <div class="username" style="cursor: pointer;">
                    <b>{{user.username || user.userid}}&nbsp;<span style="color: var(--main-color);"
                        *ngIf="user.employeeid">( {{user.employeeid}} )</span></b>

                  </div>
                  <div style="color: grey;
                                  margin-top: 1px;
                                  font-size: 13.5px;" *ngIf="user.username != ''">{{user.userid}}</div>
                </div>
              </div>
              <input type="checkbox" class="form-check-input" [id]="user.primarykey" [checked]="user.checked"
                (change)="selectUser($event,user)">
            </label>
          </div>
          <div *ngIf="trantype == '002'">
          <div class="row">
            <div class="mb-1 col-md-12">
              <label class="form-label" style="margin-bottom: 0px !important;">To Place {{ user.toplace ? '-' : ''}} {{ user.toplace }}</label>
              <label for="" style="margin-left: 5px;"></label>
            </div>

          </div>
          <div class="d-flex">
              <div class="form-check " style="margin-right: 55px;" *ngIf="user.going == true">
                
                <input class="form-check-input " type="checkbox" id="void" name="void" checked="checked" disabled>
                <label class="form-check-label  mb-1 ms-1" for="void">
                  Going
                </label>
              </div>
              <div class="form-check " style="margin-right: 55px;"*ngIf="user.going == false">
                
                <input class="form-check-input " type="checkbox" id="void" name="void"  disabled>
                <label class="form-check-label  mb-1 ms-1" for="void">
                  Going
                </label>
              </div>
        
              <div class="form-check " *ngIf="user.return == true">
                <input class="form-check-input " type="checkbox" id="return" name="return" checked="checked" disabled>
                <label class="form-check-label  mb-1 ms-1" for="return">
                  Return
                </label>
              </div>
              <div class="form-check " *ngIf="user.return == false">
                <input class="form-check-input " type="checkbox" id="return" name="return" disabled>
                <label class="form-check-label  mb-1 ms-1" for="return">
                  Return
                </label>
              </div>
            

          </div>
          <!-- *ngIf="dailyRequest.going && dailyRequest.return" -->
          <div class="row d-flex">
            <div class="col-md-5" *ngIf="user.going == true">
              <label for="date" class="form-label" style="margin-bottom: 0px !important;">Arrive Time - {{user.arrivetime}}</label>
            </div>
            <div class=" col-md-5" *ngIf="user.return == true">
              <label for="date" class="form-label" style="margin-bottom: 0px !important;">Return Time - {{user.returntime}}</label>
            </div>

          </div>
        </div>

        </div>
      </ul>

      <ng-container *ngIf="gettingCustomerList">
        <div *ngFor="let i of [1,2,3,4]" class="d-flex align-items-center flex-row">
          <div class="me-3">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
            'width.px' : 40, 
            'height.px': 40}"></ngx-skeleton-loader>
          </div>
          <div class="d-flex flex-column">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 150, 
            'height.px': 10}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
              'width.px' : 100, 
              'height.px': 10}"></ngx-skeleton-loader>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!gettingCustomerList && customerList.length == 0"
        class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
        <div>
          No Passengers
        </div>
      </div>

    </div>
  </div>
</div>
<div style="height: 380px; overflow-y: hidden; padding-bottom: 100px;" *ngIf="type == '002'">
  <div>
    <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
      (scrolled)="onScrolledDown()" [perfectScrollbar]="config" class="chat-container"
      style="height: 390px !important;position: relative;" #chatPS>
      <ul class="list-group" *ngIf="customerList.length > 0">

        <div class="profile-container card" *ngFor="let user of customerList | filter : searchText:'userdropoff'" style="padding: 10px;" (click)="selectUser($event,user)">
          <div >
            

            <label class="d-flex align-items-center justify-content-center mb-1"  >
              <div class="profile-item-container">
                <div class="profile-item">
                  <div class="username" style="cursor: pointer;">
                    <b>{{user.username || user.userid}}&nbsp;<span style="color: var(--main-color);"
                        *ngIf="user.employeeid">( {{user.employeeid}} )</span></b>

                  </div>
                  <div style="color: grey;
                                  margin-top: 1px;
                                  font-size: 13.5px;" *ngIf="user.username != ''">{{user.userid}}</div>
                </div>
              </div>
              <input type="checkbox" class="form-check-input" [id]="user.primarykey" [checked]="user.checked"
                >
            </label>
          </div>
          <div class="row">
            <div class="mb-1 col-md-12">
              <label class="form-label" style="margin-bottom: 0px !important;">To Place {{ user.toplace ? '-' : ''}} {{ user.toplace }}</label>

            </div>

          </div>
          <div class="d-flex">
            

            <div class="form-check " style="margin-right: 55px;" *ngIf="user.going == true">
                
              <input class="form-check-input " type="checkbox" id="void" name="void" checked="checked" disabled>
              <label class="form-check-label  mb-1 ms-1" for="void">
                Going
              </label>
            </div>
            <div class="form-check " style="margin-right: 55px;"*ngIf="user.going == false">
              
              <input class="form-check-input " type="checkbox" id="void" name="void"  disabled>
              <label class="form-check-label  mb-1 ms-1" for="void">
                Going
              </label>
            </div>
      
            <div class="form-check " *ngIf="user.return == true">
              <input class="form-check-input " type="checkbox" id="return" name="return" checked="checked" disabled>
              <label class="form-check-label  mb-1 ms-1" for="return">
                Return
              </label>
            </div>
            <div class="form-check " *ngIf="user.return == false">
              <input class="form-check-input " type="checkbox" id="return" name="return" disabled>
              <label class="form-check-label  mb-1 ms-1" for="return">
                Return
              </label>
            </div>
          
            

          </div>
          <!-- *ngIf="dailyRequest.going && dailyRequest.return" -->
          <div class="row">
            <div class="mb-3 col-md-5">
              <label for="date" class="form-label" style="margin-bottom: 0px !important;">Arrive Time - {{user.arrivetime}}</label><br>
              <!-- <input type="text" class="form-control" id="arrivetime" name="arrivetime" disabled> -->

            </div>
            <div class="mb-3 col-md-5">
              <label for="date" class="form-label" style="margin-bottom: 0px !important;">Return Time - {{user.returntime}}</label><br>

            </div>

          </div>

        </div>
      </ul>

      <ng-container *ngIf="gettingCustomerList">
        <div *ngFor="let i of [1,2,3,4]" class="d-flex align-items-center flex-row">
          <div class="me-3">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
            'width.px' : 40, 
            'height.px': 40}"></ngx-skeleton-loader>
          </div>
          <div class="d-flex flex-column">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 150, 
            'height.px': 10}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
              'width.px' : 100, 
              'height.px': 10}"></ngx-skeleton-loader>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!gettingCustomerList && customerList.length == 0"
        class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
        <div>
          No Passengers
        </div>
      </div>

    </div>
  </div>
</div>

<div class="close-save-button justify-content-end mt-3">
  <button type="button" class="btn btn-custom me-2" (click)="close('')">
    Cancel
  </button>
  <app-button-loader (click)="done()" [button_text]="'Done'" [load_button_text]="''"></app-button-loader>
</div>