import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-hierarchy-edit-details',
  templateUrl: './hierarchy-edit-details.component.html',
  styleUrls: ['./hierarchy-edit-details.component.css'],
})
export class HierarchyEditDetailsComponent implements OnInit {
  node: any;

  hcFormSubmitted: boolean = false;
  saveLoading: boolean = false;
  gettingPositionList: boolean = false;

  hcForm = {
    cid: '',
    ceid: '',
    orgid: '',
    order: '',
  };

  positions: any = [];

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<HierarchyEditDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.hcForm.orgid = data.orgid;
    this.node = data.node;
    this.hcForm.cid = this.node.positionid;
    this.hcForm.ceid = this.node.employeeid;
    this.hcForm.order = this.node.order;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.getPositions();
    // var index = this.positions.map((x: any)=>x.positionid).indexOf(this.node.positionid);
    // this.hcForm.cid = index.toString()
  }

  close() {
    this.dialogRef.close(this.node);
  }

  submitHC() {
    this.hcFormSubmitted = true;
    if (
      (this.node.htype == '1' &&
        parseInt(this.hcForm.order) >= 0 &&
        this.hcForm.cid) ||
      (this.node.htype != '1' && this.hcForm.cid)
    ) {
      this.saveLoading = true;
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.hcForm.orgid,
        cid: this.hcForm.cid,
        ceid: this.hcForm.ceid,
        type: this.node.htype,
        order: parseInt(this.hcForm.order),
        id: this.node.id,
      };
      this.kunyekService.editHierarchy(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.saveLoading = false;
            var index = this.positions
              .map((x: any) => x.positionid)
              .indexOf(this.hcForm.cid);
            var temp = this.positions[index];
            this.node.positionid = temp.positionid;
            this.node.positionname = temp.name;
            this.node.department = temp.department;
            this.node.division = temp.division;
            this.node.order = parseInt(this.hcForm.order);
            this.dialogRef.close(this.node);
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
    }
  }
  getPositions() {
    this.gettingPositionList = true;
    const data = {
      orgid: this.hcForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getPositionList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.positions = res.datalist;
          this.positions.sort((a: any, b: any) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.gettingPositionList = false;
        } else {
          this.gettingPositionList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingPositionList = false;
      }
    );
  }
}
