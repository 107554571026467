import {
  Component,
  HostListener,
  Inject,
  Injector,
  OnInit,
  Optional,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { differenceBy } from 'lodash';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ProjectStatusComponent } from '../project-status/project-status.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  projAddOrNot: boolean = false;
  projListLoading: boolean = false;
  projFormSubmitted: boolean = false;
  memberform: boolean = false;
  isEditing: boolean = false;
  gettingOrgList: boolean = false;
  isMobileView: boolean = false;
  saveLoading: boolean = false;
  isFocus: boolean = false;
  isFocus1: boolean = false;
  gettingMembers: boolean = false;

  itemPerPage = 100;
  itemPerPageMember = 100;
  callType: any = '';
  searchText: any = '';
  searchText1: any = '';
  memberSearchText: any = '';
  domainid: string = '';

  projects: any[] = [];
  organizations: any[] = [];
  members: any[] = [];
  selectedMember: any[] = [];
  finalSelectedMember: any[] = [];
  oldMemberList: any[] = [];

  statusList = ['New', 'Open', 'WIP', 'Completed'];

  statusListForNewProj = ['New', 'Open', 'WIP'];

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  // currentDate = this.allinoneService.formatDBToShowInEdit(
  //   this.allinoneService.getCurrentDateToDB()
  // );
  currentDate = new Date(Date.now())

  memberStatus = [
    {
      id: '',
      name: 'All',
    },
    {
      id: '1',
      name: 'Members',
    },
  ];

  statusUiList : any = [
    {
      psid: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
    },
  ];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];
  pgMember = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];
  p: number = 1;
  q: number = 1;
  mobileViewWidth: any = 426;

  gettingStatusList: boolean = false;
  projectStatusList: any = [];
  testList: any = [
    {
      name: '1',
      psid: '1id',
    },
    {
      name: '2',
      psid: '2id',
    },
  ];

  projForm : any = {
    projectid: '',
    domain: '',
    org: '',
    name: '',
    startdate: '',
    enddate: '',
    status: 'New',
    description: '',
    memberstatus: '',
    shortcode: '',
    completed: false,
  };
  checkModeal: boolean = false;
  routesarch: any = '';
  routeBol: Boolean = false;
  private dialogRef: any;
  private dialogData: any;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public router: Router,
    private route: ActivatedRoute,
    private injector: Injector,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute // private dialogRef: MatDialogRef<ProjectsComponent>, // @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    // this.activatedRoute.data.subscribe((data) => {
    //   if (data.type == 'org') {
    //     this.callType = '300';
    //     if (!this.allinoneService.isAdminOf('org')) {
    //       this.router.navigateByUrl('/home');
    //     }
    //   } else {
    //     if (!this.allinoneService.isAdminOf('contributor')) {
    //       this.router.navigateByUrl('/home');
    //     }
    //     this.callType = '400';
    //   }
    // });
    if (this.dialogData) {
      console.log(this.dialogData);
      this.checkModeal = this.dialogData.check;
      this.projForm.org = this.dialogData.orgid;
      console.log(this.dialogData.picList);
      console.log('Pass Candi>>');
      this.projForm.domain = this.allinoneService.getDomain().domainid;
      // this.deleteList = JSON.parse(JSON.stringify(data.deletelist));
      console.log('--AA--');
      this.callType = '300';
    } else {
      this.activatedRoute.data.subscribe((data) => {
        if (data.type == 'org') {
          this.callType = '300';
          if (!this.allinoneService.isAdminOf('org')) {
            this.router.navigateByUrl('/home');
          }
        } else {
          if (!this.allinoneService.isAdminOf('contributor')) {
            this.router.navigateByUrl('/home');
          }
          this.callType = '400';
        }
      });
      if (this.callType == '300') {
        this.organizations = this.allinoneService.orgs;
        this.projForm.org = this.allinoneService.selectedOrg
          ? this.allinoneService.selectedOrg
          : this.organizations[0].orgid;
      } else {
        this.organizations = this.allinoneService.contributorOrgs;
        this.projForm.org = this.allinoneService.selectedConOrg
          ? this.allinoneService.selectedConOrg
          : this.organizations[0].orgid;
      }

      this.projForm.domain = this.allinoneService.getDomain().domainid;
      this.routesarch = this.route.snapshot.queryParamMap.get('q') || '';
    }
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }

    this.domainid = this.allinoneService.getDomain().domainid;
    this.getProjectList();
    this.getMemberList();
    this.getStatusList();
  }

  addNewStatus() {
    this.statusUiList.push({
      psid: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
    });
  }

  removeSubmitConfig(index: number) {
    if (this.statusUiList.length > 1) {
      this.statusUiList.splice(index, 1);
    }
  }

  Close() {
    this.dialogRef.close();
  }
  generateShortCode() {
    this.projForm.shortcode = '';
    let pj_name = this.projForm.name;
    if (pj_name == '') {
      this.projForm.shortcode = '';
      return;
    }
    let pj_name_arr = pj_name.split(' ');
    console.log(pj_name_arr);
    for (var i = 0; i < pj_name_arr.length; i++) {
      if (pj_name_arr[i].length > 0) {
        if (this.isLetter(pj_name_arr[i][0])) {
          this.projForm.shortcode += pj_name_arr[i][0].toUpperCase();
        } else if (
          pj_name_arr[i].length > 1 &&
          this.isLetter(pj_name_arr[i][1])
        ) {
          this.projForm.shortcode += pj_name_arr[i][1].toUpperCase();
        } else {
          continue;
        }
      } else {
        continue;
      }
    }
    if (this.projForm.shortcode.length > 5) {
      this.projForm.shortcode = this.projForm.shortcode.slice(0, 5);
    }
  }
  isLetter(c: string) {
    return c.toString().toLowerCase() != c.toString().toUpperCase();
  }
  sortMemberData(sort: Sort) {
    const data = this.selectedMember;
    if (!sort.active || sort.direction === '') {
      this.selectedMember = data;
      return;
    }

    this.selectedMember = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        default:
          return 0;
      }
    });
  }
  cancels() {
    // if(this.form == 'deal'){
    this.dialogRef.close();
    // }
    // this.productAddOrNot = false;
    // this.clean();
  }

  sortData(sort: Sort) {
    const data = this.projects;
    if (!sort.active || sort.direction === '') {
      this.projects = data;
      return;
    }

    this.projects = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'projectshortcode':
          return this.allinoneService.compare(
            a.projectshortcode,
            b.projectshortcode,
            isAsc
          );
        case 'desc':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(a.startdate, b.startdate, isAsc);
        case 'enddate':
          return this.allinoneService.compare(a.enddate, b.enddate, isAsc);
        case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  test() {
    for (var a = 0; a < this.statusUiList.length; a++) {
      for (var b = 0; b < this.statusUiList.length; b++) {
        if (this.statusUiList[a].psid != this.statusUiList[b].psid) {
          if (
            (this.statusUiList[b].startdate >= this.statusUiList[a].startdate && this.statusUiList[b].startdate <= this.statusUiList[a].enddate) ||
            (this.statusUiList[b].enddate >= this.statusUiList[a].startdate && this.statusUiList[b].enddate <= this.statusUiList[a].enddate)
          ) {
            // console.log('test');
            console.log('s');

            console.log(this.statusUiList[a].startdate);
            console.log(this.statusUiList[a].enddate);
            console.log(this.statusUiList[b].startdate);

            console.log('e');

            console.log('invalid start date');

          }
        }
      }
    }
  }

  startDateChange() {
    if (this.projForm.enddate < this.projForm.startdate) {
      this.projForm.enddate = this.projForm.startdate;
    }
  }

  statusStartDateChange() { }

  submitProject() {
    this.projFormSubmitted = true;
    if (
      this.projForm.name == '' ||
      this.projForm.org == '' ||
      this.projForm.domain == '' ||
      this.projForm.startdate == '' ||
      this.projForm.enddate == '' ||
      this.projForm.status == '' ||
      this.projForm.shortcode.length < 3 ||
      this.projForm.shortcode.length > 5
    ) {
      return;
    } else if (this.projForm.startdate > this.projForm.enddate) {
      this.messageService.openSnackBar(
        'Please select the valid end date.',
        'warn'
      );
      return;
    } else if (
      this.projForm.memberstatus == '1' &&
      this.finalSelectedMember.length == 0
    ) {
      this.messageService.openSnackBar(
        'Please select at least one member.',
        'warn'
      );
      return;
    } else if (this.statusUiList.length > 0) {
      for (var i = 0; i < this.statusUiList.length; i++) {
        if (
          this.statusUiList[i].psid == '' ||
          this.statusUiList[i].psid == null
        ) {
          // console.log('Please enter status.');
          this.messageService.openSnackBar('Please select the status.', 'warn');
          return;
        }
        if (this.statusUiList[i].enddate < this.statusUiList[i].startdate) {
          this.messageService.openSnackBar('Invalid Status Date.', 'warn');
          return;
        }
        if (
          !this.statusUiList[i].startdate ||
          !this.statusUiList[i].enddate
        ) {
          // console.log('Please enter status date.');
          this.messageService.openSnackBar(
            'Please select the status date.',
            'warn'
          );
          return;
        }
        if (
          this.statusUiList[i].startdate > this.projForm.enddate ||
          this.statusUiList[i].enddate > this.projForm.enddate ||
          this.statusUiList[i].startdate < this.projForm.startdate ||
          this.statusUiList[i].enddate < this.projForm.startdate
        ) {
          // console.log('Invalid status date.');
          this.messageService.openSnackBar(
            'Status dates should be in project date range.',
            'warn'
          );
          return;
        }
      }
    }
    // this.statusUiList
    //  check duplicated status in statusUiList
    if (this.statusUiList.length > 1) {
      for (var i = 0; i < this.statusUiList.length; i++) {
        for (var j = i + 1; j < this.statusUiList.length; j++) {
          if (
            this.statusUiList[i].psid == this.statusUiList[j].psid &&
            this.statusUiList[i].psid != ''
          ) {
            this.messageService.openSnackBar(
              'Duplicated status.',
              'warn'
            );
            return;
          }
          // if (this.statusUiList[i].startdate < this.statusUiList[j].enddate && this.statusUiList[j].startdate < this.statusUiList[i].enddate) {
          //   // The date ranges overlap
          //   this.messageService.openSnackBar('Overlapping status date range.', 'warn');
          //   return;
          // }
        }
      }

      for (var a = 0; a < this.statusUiList.length; a++) {
        for (var b = 0; b < this.statusUiList.length; b++) {
          if (this.statusUiList[a].psid != this.statusUiList[b].psid) {
            if (
              (this.statusUiList[b].startdate >= this.statusUiList[a].startdate && this.statusUiList[b].startdate <= this.statusUiList[a].enddate) ||
              (this.statusUiList[b].enddate >= this.statusUiList[a].startdate && this.statusUiList[b].enddate <= this.statusUiList[a].enddate)
            ) {
              this.messageService.openSnackBar('Overlapping status date range.', 'warn');
              return;
            }
          }
        }
      }
    }

    // console.log('success');

    // else {

    // }
    this.saveProject();
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  changePageCountMember(event: any) {
    this.itemPerPageMember = event.target.value;
    this.q = 1;
  }

  saveProject() {
    this.saveLoading = true;
    var members: any = [];
    var deleteMember: any = [];
    var data;
    console.log(this.statusUiList);
    var formattedStatusList: any = [];
    for (var i = 0; i < this.statusUiList.length; i++) {
      formattedStatusList.push({
        psid: this.statusUiList[i].psid,
        startdate: this.allinoneService.parseDate(
          this.statusUiList[i].startdate
        ),
        enddate: this.allinoneService.parseDate(this.statusUiList[i].enddate),
      });
    }

    if (this.isEditing) {
      if (this.projForm.memberstatus == '1') {
        this.oldMemberList.map((item: any) => {
          var index = this.finalSelectedMember
            .map((x) => x.userid)
            .indexOf(item.userid);
          if (index == -1) {
            var tempid = {
              id: item.id,
            };
            deleteMember.push(tempid);
          }
        });
        var tempFinalSelectedMember = differenceBy(
          this.finalSelectedMember,
          this.oldMemberList,
          'id'
        );
        tempFinalSelectedMember.map((item: any) => {
          var temp = {
            userid: item.userid,
          };
          members.push(temp);
        });
      }
      data = {
        projectid: this.projForm.projectid,
        orgid: this.projForm.org,
        domainid: this.domainid,
        name: this.projForm.name,
        description: this.projForm.description,
        startdate: this.allinoneService.parseDate(this.projForm.startdate),
        enddate: this.allinoneService.parseDate(this.projForm.enddate),
        status: this.projForm.status,
        memberstatus: this.projForm.memberstatus,
        member: members,
        projectshortcode: 'P-' + this.projForm.shortcode,
        deletemember: deleteMember,
        completed: this.projForm.completed,
        statuslist: formattedStatusList
      };
    } else {
      if (this.projForm.memberstatus == '1') {
        this.finalSelectedMember.map((item: any) => {
          var temp = {
            userid: item.userid,
          };
          members.push(temp);
        });
      }
      data = {
        orgid: this.projForm.org,
        domainid: this.domainid,
        name: this.projForm.name,
        description: this.projForm.description,
        startdate: this.allinoneService.parseDate(this.projForm.startdate),
        enddate: this.allinoneService.parseDate(this.projForm.enddate),
        // status: this.projForm.status,
        status: '',
        memberstatus: this.projForm.memberstatus,
        member: members,
        projectshortcode: 'P-' + this.projForm.shortcode,
        statuslist: formattedStatusList,
        completed: false,
      };
    }
    // console.log(data);
    // return;
    var fun = this.isEditing
      ? this.kunyekService.updateProject(data)
      : this.kunyekService.addProject(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.cancel();
          this.getProjectList();
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  editProject(item: any) {
    this.isEditing = true;
    this.projAddOrNot = true;
    this.projForm.projectid = item.projectid;
    this.projForm.name = item.name;
    this.projForm.description = item.description;
    this.projForm.startdate = this.allinoneService.parseDateToShowInEdit(
      item.startdate
    );
    this.projForm.enddate = this.allinoneService.parseDateToShowInEdit(
      item.enddate
    );
    this.projForm.status = item.status;
    // Check Here
    this.projForm.memberstatus = item.memberstatus;
    this.finalSelectedMember = item.memberlist;
    this.oldMemberList = item.memberlist;
    this.projForm.shortcode = item.projectshortcode.slice(2);
    this.projForm.completed = item.completed == true;
    this.statusUiList = [];
    item.statuslist.map((x: any) => {
      var temp = {
        psid: x.psid,
        startdate: this.allinoneService.parseDateToShowInEdit(x.startdate),
        enddate: this.allinoneService.parseDateToShowInEdit(x.enddate),
      };
      this.statusUiList.push(temp);
    });
  }

  deleteProject(item: any) { }

  orgChanged(event: any) {
    this.projForm.org = event.target.value;
    if (this.projForm.org == '') {
      this.projects = [];
    } else {
      if (this.callType == '300') {
        this.allinoneService.selectedOrg = this.projForm.org;
        this.allinoneService.setSelectedOrg(this.projForm.org);
      } else {
        this.allinoneService.selectedConOrg = this.projForm.org;
        this.allinoneService.setSelectedConOrg(this.projForm.org);
      }
      this.getProjectList();
      this.getMemberList();
    }
  }

  refresh() {
    this.getProjectList();
  }

  getStatusList() {
    this.gettingStatusList = true;
    const data = {
      orgid: this.projForm.org,
      domainid: this.domainid,
    };
    this.kunyekService.getProjectStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.projectStatusList = res.datalist;
          this.gettingStatusList = false;
        } else {
          this.gettingStatusList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStatusList = false;
      }
    );
  }

  getProjectList() {
    this.projListLoading = true;
    const data = {
      orgid: this.projForm.org,
      domainid: this.domainid,
      role: this.callType,
    };
    this.kunyekService.getAllProjects(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.projListLoading = false;
          this.projects = res.data;
          // this.projects = this.projects.map(
          //   (item) => ({
          //     ...item,
          //     statusid : item.status == 'New' ? 0 : item.status == 'Open' ? 2: item.status == 'WIP' ? 3 : 4
          //   })
          // );
          // this.projects = this.projects.sort((a: any, b: any) => {
          //   var o1 = a.startdate.toLowerCase();
          //   var o2 = b.startdate.toLowerCase();

          //   var p1 = a.statusid;
          //   var p2 = b.statusid;

          //   if (p1 < p2) return -1;
          //   if (p1 > p2) return 1;
          //   if (o1 < o2) return 1;
          //   if (o1 > o2) return -1;
          //   return 0;
          // })
          this.pg[3].count = this.projects.length;
        } else {
          this.projListLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.projListLoading = false;
      }
    );
  }

  cancel() {
    this.isEditing = false;
    this.projAddOrNot = false;
    this.projFormSubmitted = false;
    this.projForm = {
      projectid: '',
      domain: this.projForm.domain,
      org: this.projForm.org,
      name: '',
      startdate: '',
      enddate: '',
      status: 'New',
      description: '',
      memberstatus: '',
      shortcode: '',
      completed: false,
    };
    this.statusUiList = [
      {
        psid: '',
        startdate: this.currentDate,
        enddate: this.currentDate,
      },
    ];
    this.saveLoading = false;
    this.cancelMember();
    this.finalSelectedMember = [];
    this.oldMemberList = [];
  }

  openMember() {
    this.memberform = true;
    this.selectedMember = JSON.parse(JSON.stringify(this.finalSelectedMember));
    this.pgMember[3].count = this.selectedMember.length;
  }

  cancelMember() {
    this.memberform = false;
    this.selectedMember = [];
  }

  saveMember() {
    if (this.selectedMember.length == 0) {
      this.messageService.openSnackBar(
        'Please select at least one member.',
        'warn'
      );
      return;
    }
    this.finalSelectedMember = this.selectedMember;
    this.memberform = false;
    this.selectedMember = [];
  }

  removeMember(index: number) {
    this.selectedMember.splice(index, 1);
  }

  clear() {
    if (this.memberform) {
      this.searchText1 = '';
    } else {
      this.searchText = '';
    }
  }

  getMemberList() {
    this.members = [];
    this.gettingMembers = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      domain: this.allinoneService.getDomain().shortcode,
      orgid: this.projForm.org,
    };
    this.kunyekService.getMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;
          this.gettingMembers = false;
        } else {
          this.gettingMembers = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingMembers = false;
      }
    );
  }

  selectMember(member: any, trigger: MatAutocompleteTrigger) {
    var temp = this.selectedMember.filter(
      (x: any) => x.userid == member.userid
    );
    if (temp.length == 0) {
      this.selectedMember.push(member);
    }
    this.pgMember[3].count = this.selectedMember.length;
    console.log(this.selectedMember.length);
    console.log('---');
    console.log(this.itemPerPageMember);
    if (
      this.itemPerPageMember != 100 &&
      this.itemPerPageMember != 300 &&
      this.itemPerPageMember != 500
    ) {
      console.log('withing if');
      this.itemPerPageMember = this.selectedMember.length;
    }
    console.log(this.itemPerPageMember);
    // trigger.openPanel();
  }

  cancelFunction() {
    this.router.navigateByUrl('org/crm/services');
  }

  openStatusForm() {
    const dialog = this.dialog.open(ProjectStatusComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        orgid: this.projForm.org,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getStatusList();
    });
  }
}
