<section class="container-fluid">
  <div class="content">
    <ng-container *ngIf="isAdmin">
      <app-breadcrumb [s_breadcrumb]="'Office'">
      </app-breadcrumb>
      <div class="row g-0">
        <ng-container *ngFor="let item of menu; let index = index">

          <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-12 p-2 ps-0"> -->
          <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card"
            (click)="goTo(item)">
            <div class="d-flex flex-column">
              <div class="mb-2">
                <img [src]="item.icon" width="30px">
              </div>
              <div class="name">
                {{item.name}}
              </div>
            </div>
            <div *ngIf="hrRequestCount > 0 && item.name == 'HR Request'">
              <div class="bg-danger custom-badge">
                {{hrRequestCount}}
              </div>
            </div>
            <div *ngIf="hrApprovalCount > 0 && item.name == 'HR Approval'">
              <div class="bg-danger custom-badge">
                {{hrApprovalCount}}
              </div>
            </div>
            <div *ngIf="approvalCount > 0 && item.name == 'Approval'">
              <div class="bg-danger custom-badge">
                {{approvalCount}}
              </div>
            </div>
            <div *ngIf="requestCount > 0 && item.name == 'Requests'">
              <div class="bg-danger custom-badge">
                {{requestCount}}
              </div>
            </div>
            <div *ngIf="lateCount > 0 && item.name == 'Late'">
              <div class="bg-danger custom-badge">
                {{lateCount}}
              </div>
            </div>
            <div *ngIf="earlyOutCount > 0 && item.name == 'Early Out'">
              <div class="bg-danger custom-badge">
                {{earlyOutCount}}
              </div>
            </div>
            <div *ngIf="workFromHomeCount > 0 && item.name == 'Work From Home'">
              <div class="bg-danger custom-badge">
                {{workFromHomeCount}}
              </div>
            </div>
            <div *ngIf="offInLieuCount > 0 && item.name == 'Off In Lieu'">
              <div class="bg-danger custom-badge">
                {{offInLieuCount}}
              </div>
            </div>
            <div *ngIf="claimCount > 0 && item.name == 'Claim'">
              <div class="bg-danger custom-badge">
                {{claimCount}}
              </div>
            </div>
            <div *ngIf="requisitionApprovalCount > 0 && item.name == 'Requisition Approval'">
              <div class="bg-danger custom-badge">
                {{requisitionApprovalCount}}
              </div>
            </div>
            <div *ngIf="requisitionRequestCount > 0 && item.name == 'Requisition Request'">
              <div class="bg-danger custom-badge">
                {{requisitionRequestCount}}
              </div>
            </div>
          </div>
          <!-- </div> -->
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <div style="max-width: 300px;" *ngIf="roleList.length > 1">
        <select class="form-select" [(ngModel)]="currentRole" (ngModelChange)="changeRole()">
          <option *ngFor="let role of roleList" [value]="role.roleid">
            {{role.name}}
          </option>
        </select>
      </div>
      <div class="admin-title my-3">
        Office
      </div>
      <div class="row g-0">
        <ng-container *ngFor="let item of menu; let index = index">
          <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-12 p-2 ps-0" *ngIf="officePermissionList.includes(item.id)"> -->
          <!-- <div class="card d-flex flex-row justify-content-between p-2 dashboard-custom-card" (click)="checkPermission(item)"
            [ngClass]="{'disabled-card' : !officePermissionList.includes(item.id)}"> -->
          <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card"
            (click)="checkPermission(item)" *ngIf="officePermissionList.includes(item.id)">
            <div class="d-flex flex-column">
              <div class="mb-2">
                <img [src]="item.icon" width="30px">
              </div>
              <div class="name" (click)="t()">
                {{item.name}} 
              </div>
            </div>
            <div *ngIf="hrRequestCount > 0 && item.name == 'HR Request'">
              <div class="bg-danger custom-badge">
                {{hrRequestCount}}
              </div>
            </div>
            <div *ngIf="hrApprovalCount > 0 && item.name == 'HR Approval'">
              <div class="bg-danger custom-badge">
                {{hrApprovalCount}}
              </div>
            </div>
            <div *ngIf="approvalCount > 0 && item.name == 'Approval'">
              <div class="bg-danger custom-badge">
                {{approvalCount}}
              </div>
            </div>
            <div *ngIf="requestCount > 0 && item.name == 'Requests'">
              <div class="bg-danger custom-badge">
                {{requestCount}}
              </div>
            </div>

            <div *ngIf="lateCount > 0 && item.name == 'Late'">
              <div class="bg-danger custom-badge">
                {{lateCount}}
              </div>
            </div>
            <div *ngIf="earlyOutCount > 0 && item.name == 'Early Out'">
              <div class="bg-danger custom-badge">
                {{earlyOutCount}}
              </div>
            </div>
            <div *ngIf="workFromHomeCount > 0 && item.name == 'Work From Home'">
              <div class="bg-danger custom-badge">
                {{workFromHomeCount}}
              </div>
            </div>
            <div *ngIf="offInLieuCount > 0 && item.name == 'Off In Lieu'">
              <div class="bg-danger custom-badge">
                {{offInLieuCount}}
              </div>
            </div>
            <div *ngIf="claimCount > 0 && item.name == 'Claim'">
              <div class="bg-danger custom-badge">
                {{claimCount}}
              </div>
            </div>

            <div *ngIf="requisitionApprovalCount > 0 && item.name == 'Requisition Approval'">
              <div class="bg-danger custom-badge">
                {{requisitionApprovalCount}}
              </div>
            </div>
            <div *ngIf="requisitionRequestCount > 0 && item.name == 'Employee Requisition'">
              <div class="bg-danger custom-badge">
                {{requisitionRequestCount}}
              </div>
            </div>
          </div>
          <!-- </div> -->
        </ng-container>
      </div>
    </ng-container>
  </div>
</section>