<div class="container-fluid">
    <div class="content">
        <ng-container *ngIf="isAdmin">
            <!-- <app-hcm-breadcrumb *ngIf="!addEm" s_breadcrumb="Attendance ID">
            </app-hcm-breadcrumb> -->
            <!-- <app-hcm-breadcrumb *ngIf="addEm" s_breadcrumb="Attendance ID"
                [t_breadcrumb]="isEditing ? selectedMemberName == '' ? emForm.userid : selectedMemberName  : 'New Attendance ID'" (emit)="cancel()">
            </app-hcm-breadcrumb> -->

            <app-team-breadcrumb *ngIf="!addEm" s_breadcrumb="Attendance ID"></app-team-breadcrumb>
            <app-team-breadcrumb *ngIf="addEm" s_breadcrumb="Attendance ID"
                [t_breadcrumb]="isEditing ? selectedMemberName == '' ? emForm.userid : selectedMemberName  : 'New Attendance ID'" (emit)="cancel()">
            </app-team-breadcrumb>
            
        </ng-container>
        <ng-container *ngIf="!isAdmin">
            <app-office-breadcrumb *ngIf="!addEm" [s_breadcrumb]="'Attendance ID'">
            </app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="addEm" [s_breadcrumb]="'Attendance ID'"
                [t_breadcrumb]="isEditing ? selectedMemberName == '' ? emForm.userid : selectedMemberName : 'New Attendance ID'" (emit)="cancel()">
            </app-office-breadcrumb>
        </ng-container>
          <ng-container *ngIf="addEm;else listView">
            <div class="d-flex align-items-center">
                <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader class="mb-3" (click)="submitEm()" [button_text]="'Save'"
                    [load_button_text]="'Saving'" [isLoading]="saveLoading">
                </app-button-loader>
            </div>
            <div class="row">
                <div class="mb-3 col-md-6">

                    <label for="userid" class="form-label">User ID {{memberSelected ? '('+selectedMemberName+' - '+
                      selectedMemberEID+')' : ''}}
                    </label>
                    <input type="text" class="form-control" id="userid" name="userid"
                      placeholder="john@domain.com, +959********" [(ngModel)]="emForm.userid" required #userid="ngModel"
                      [ngClass]="{ 'is-invalid': emFormSubmitted && userid.invalid }" [disabled]="isEditing"
                      [matAutocomplete]="memberAutoComplete" (ngModelChange)="changeUserid()">
                    <mat-autocomplete #memberAutoComplete="matAutocomplete">
                      <ng-container *ngFor="let member of memberList">
                        <mat-option [value]="member.userid" (click)="selectMember(member)">
                          {{member.userid}}{{member.username ? ' - ' + member.username : ''}}
                        </mat-option>
                      </ng-container>
        
                    </mat-autocomplete>
        
                    <div class="invalid-feedback"
                      *ngIf="userid.invalid || (userid.dirty || userid.touched) && emFormSubmitted">
                      <div *ngIf="userid.errors?.required">
                        {{allinoneService.input_err_msg}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label" for="attendanceid">Attendance ID</label>
                    <input type="text" name="attendanceid" id="attendanceid" class="form-control" [(ngModel)]="emForm.attendanceid" #attendanceid="ngModel" required
                    [ngClass]="{'is-invalid' : emFormSubmitted && attendanceid.invalid }"
                    >
                    <div class="invalid-feedback" *ngIf="attendanceid.invalid || ((attendanceid.dirty || attendanceid.touched) && emFormSubmitted) " >
                        <div *ngIf="attendanceid.errors?.required">
                            {{ allinoneService.input_err_msg }}
                        </div>
                    </div>
                  </div>
            </div>
          </ng-container>
          <ng-template #listView>
            <div class="d-flex align-items-center">
                <div class="mb-3 me-3">
                    <button class="btn btn-custom me-auto" (click)="newEm()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>
                </div>
                <div class="mb-3">
                    <button type="button" class="btn btn-custom" (click)="exportSheet()" title="Export To Excel"
                        [disabled]="emList.length == 0">
                        <img class="excel-icon" src="../../../assets/images/excel.png" />
                        Export
                    </button>
                </div>
            </div>
            <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                    <input class="form-control" type="text" placeholder="&#xf002; Search by IDs/User Name"
                        aria-label="Search by User ID/User Name" (focus)="isFocus = true" (blur)="isFocus = false"
                        [(ngModel)]="searchText" (ngModelChange)="searchTextChange()"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu" />
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <div c style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">
                                {{page.name}}
                            </label>
                        </li>
                    </ul>
                </div>

                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>

                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{ (emList | filter : searchText : 'attendanceId').length }}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input class="form-control" type="text" placeholder="&#xf002; Search by IDs/User Name"
                        aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                        (ngModelChange)="searchTextChange()" />
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>

                <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
                    *ngIf="emList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
                </pagination-controls>
            </div>
            <div class="table-responsive" matSort (matSortChange)="sortData($event)" >
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-md-3" mat-sort-header="userid">User ID</td>
                            <td scope="col" class="col-md-3" mat-sort-header="username">User Name</td>
                            <td scope="col" class="col-md-3" mat-sort-header="employeeid">Employee ID</td>
                            <td scope="col" class="col-md-3" mat-sort-header="attendanceid">Attendance ID</td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>

                    <tbody *ngIf="emListLoading">
                        <tr>
                            <td colspan="7" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!emListLoading">
                        <tr *ngIf="emList.length == 0">
                            <td colspan="7" class="nodata">Empty</td>
                        </tr>

                        <tr *ngFor="let em of emList | filter : searchText : 'attendanceId' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage}; let index = index">
                            <td class="td-data td-edit" (click)="editEm(em)">
                                <span [title]="em.userid">{{em.userid}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="em.username">{{em.username}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="em.employeeid">{{em.employeeid}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="em.attendanceid">{{em.attendanceid}}</span>
                            </td>
                            <td class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary me-2" (click)="editEm(em)">
                                    <i class='fa fa-edit'></i>
                                </button>
                                <button class="btn btn-outline-danger" (click)="deleteEm(em.userid, index, em.id)">
                                    <i class='fa fa-trash-o'></i>
                                </button>
                            </td>
                        </tr>

                    </tbody>

                </table>
            </div>
            <pagination-controls class="paginate" (pageChange)="currentPage = $event"
                *ngIf="emList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
          </ng-template>

    </div>
</div>