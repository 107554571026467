<div class="container-fluid">
    <div class="content">
        <app-breadcrumb [s_breadcrumb]="'Logistics'" [t_breadcrumb]="'Assign'" (emit)="goToLogistics()">
        </app-breadcrumb>

        <ng-container *ngIf="!isAssignNew">
            <div class="d-flex justify-content-between">
                <div class="mb-3">
                    <button class="btn btn-custom me-auto" (click)="newAssign()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>
                </div>
                <div class="mb-3">
                    <div class="align-items-center" [matMenuTriggerFor]="statusmenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                            mdbDropdownToggle style="
                  background-color: #87929d;
                  border-color: #87929d;
                  color: white;
                  min-width: 100px;
                ">
                            Status&nbsp;:&nbsp;{{ statusname }}
                        </button>
                    </div>
                    <mat-menu #statusmenu="matMenu" id="service-menu" xPosition="before">
                        <button mat-menu-item *ngFor="let stu of statusList" (click)="statusChange(stu)">
                            {{ stu.name }}
                        </button>
                    </mat-menu>
                </div>
            </div>
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex flex-wrap justify-content-start align-items-center flex-wrap">
                    <div class="input-group mb-3" style="width: 300px !important">
                        <input class="form-control" type="text" placeholder="&#xf002; Search with ID"
                            (ngModelChange)="searchTextChange()" aria-label="Search" (focus)="isFocus = true"
                            (blur)="isFocus = false" [(ngModel)]="searchText"
                            [ngClass]="{ 'c-search-input-with-clear': searchText }"
                            style="font-family: FontAwesome, Ubuntu" />
                        <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                    </div>
                    <div class="ms-2 mb-3">
                        <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                    <div class="mb-3">
                        <div class="line"></div>
                    </div>
                    <div class="d-flex align-items-center mb-3">
                        <span class="total-count-text">Total:&nbsp;</span>
                        <span class="total-count-num">{{ assigns.length }}</span>
                    </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                    <pagination-template *ngIf="assigns.length > 0" #p="paginationApi"
                        (pageChange)="pageChange($event)">
                        <div class="d-flex align-items-center justify-content-center">
                            <div [class]="
                    p.isFirstPage()
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.previous()">
                                <span class="me-1">&laquo;</span>&nbsp;Prev
                            </div>
                            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                <span class="page" (click)="p.setCurrent(page.value)"
                                    *ngIf="p.getCurrent() !== page.value">
                                    <span>{{ page.label }}</span>
                                </span>
                                <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                    <span>{{ page.label }}</span>
                                </div>
                            </div>
                            <div [class]="
                    nextDisabled
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.next()">
                                Next&nbsp;<span class="ms-1">&raquo;</span>
                            </div>
                        </div>
                    </pagination-template>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isAssignNew">
            <div class="d-flex align-items-center">
                <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!isLoading">
                    Cancel
                </button>
                <app-button-loader *ngIf="!isEditing" class="mb-3" (click)="submitAssignment()" [button_text]="'Save'"
                    [load_button_text]="'Saving'" [isLoading]="isLoading">
                </app-button-loader>

                <button *ngIf="isEditing && !isLoading" (click)="submitAssignment()" type="button"
                    [disabled]="gettingAssigment && isEditing" class="btn btn-custom me-2 mb-3">
                    Save
                </button>

                <app-button-loader *ngIf="isEditing && isLoading" class="mb-3" (click)="submitAssignment()" [button_text]="'Save'"
                    [load_button_text]="'Saving'" [isLoading]="isLoading">
                </app-button-loader>
            </div>

            <button type="button" [disabled]="gettingAssigment && isEditing" class="btn btn-custom me-2 mt-3 mb-3"
                (click)="addBatchAndOrders()" *ngIf="!isLoading">
                Choose Order / Batch
            </button>
            <!-- Orders -->
            <div class="row">
                <div class="mb-3 col-md-6 col-sm-12 col-12" *ngFor="let od of orderList;">
                    <div class="d-flex  p-2 justify-content-between" style="background-color: rgb(229, 229, 229);">
                        <div class="order-image m-2 td-edit" (click)="viewOrder(od)">
                            <div *ngIf="od.image.imgurl.length>0">
                                <img [src]="od.image.imgurl[0]" alt="Order Image">
                            </div>
                            <div *ngIf="od.image.imgurl.length==0">
                                <img src="../../../assets/images/order_box.png" alt="Order Image">
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-center flex-fill td-edit"
                            (click)="viewOrder(od)">
                            <div class="mb-1">
                                ID : <span style="color: rgb(50, 120, 232);">
                                    {{od.orderid}}
                                </span>
                            </div>
                            <div class="mb-1">
                                <strong>
                                    {{od.desc}}
                                </strong>
                            </div>
                            <div class="mb-1">
                                Customer: {{od.cuname || "-"}}
                            </div>

                        </div>
                        <label class="d-flex align-items-center justify-content-center" style="width: 80px;">
                            <input type="checkbox" class="form-check-input" [id]="od.orderid" [checked]="od.checked"
                                [(ngModel)]="od.checked">
                        </label>

                    </div>

                </div>
            </div>

            <!-- Batches -->
            <div class="row">
                <div class="mb-3 col-md-6 col-sm-12 col-12" *ngFor="let bat of batchList; let batchindex = index">
                    <div class="mb-3 accordion" id="accordionContainer">
                        <div class="accordion-item">
                            <h3 class="accordion-header" [id]="'heading' + batchindex">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse' + batchindex" aria-expanded="true"
                                    [attr.aria-controls]="'collapse' + batchindex">
                                    ID: {{ bat.batchid }}

                                    <div> Count: {{bat.orderlist.length}}</div>
                                </button>
                                <label style="    margin-left: -40px;
                                padding-top: 35px;
                                z-index: 10;
                                font-size: 13px;">
                                    <input type="checkbox" class="form-check-input" [id]="bat.batchid"
                                        [checked]="bat.checked"  [(ngModel)]="bat.checked">
                                </label>
                            </h3>
                            <div [id]="'collapse' + batchindex" class="accordion-collapse collapse show"
                                [attr.aria-labelledby]="'heading' + batchindex">
                                <div class="accordion-body">
                                    <div class="mb-3" *ngFor="let odd of bat.orderlist; let orderindex = index;">
                                        <div class="d-flex p-2 justify-content-between"
                                            style="background-color: rgb(229, 229, 229);">
                                            <div class="order-image td-edit m-2" (click)="viewOrderDeailsInBatch(odd)">
                                                <div *ngIf="odd.image.imgurl.length>0">
                                                    <img [src]="odd.image.imgurl[0]" alt="Order Image">
                                                </div>
                                                <div *ngIf="odd.image.imgurl.length==0">
                                                    <img src="../../../assets/images/order_box.png" alt="Order Image">
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column justify-content-center flex-fill td-edit"
                                                (click)="viewOrderDeailsInBatch(odd)">
                                                <div class="mb-1">
                                                    ID : <span style="color: rgb(50, 120, 232);cursor: pointer;">
                                                        {{odd.orderid}}
                                                    </span>

                                                </div>
                                                <div class="mb-1">
                                                    <strong>
                                                        {{odd.desc}}
                                                    </strong>
                                                </div>
                                                <div class="mb-1">
                                                    Customer: {{odd.cuname || "-"}}
                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="gettingAssigment && isEditing">
                <div class="row mt-3 mb-1">
                    <div *ngFor="let i of [1,2]" class="mb-3 col-md-6 col-sm-12 col-12">
                        <div class="me-3">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      'width.px' : 80, 
      'height.px': 80}"></ngx-skeleton-loader>
                        </div>
                        <div class="d-flex flex-column">
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
      'width.px' : 250, 
      'height.px': 10}"></ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
        'width.px' : 200, 
        'height.px': 10}"></ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          'width.px' : 180, 
          'height.px': 10}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="row">
                <div class="mb-3 col-md-12">
                    <label for="assignid" class="form-label"> Assign ID </label>
                    <input type="text" class="form-control" id="assignid" name="assignid"
                        [(ngModel)]="assignForm.assignid" />
                </div>

                <div class="mb-3 col-md-6">
                    <label for="startdate" class="form-label"> Start Date </label>
                    <input type="date" class="form-control" id="startdate" name="startdate"
                        [(ngModel)]="assignForm.startdate" />
                </div>

                <div class="mb-3 col-md-6">
                    <label for="enddate" class="form-label"> End Date </label>
                    <input type="date" class="form-control" id="enddate" name="enddate"
                        [(ngModel)]="assignForm.enddate" />
                </div>

                <div class="mb-3 col-md-6">
                    <label for="starttime" class="form-label"> Start Time </label>
                    <input type="time" class="form-control" id="starttime" name="starttime"
                        [(ngModel)]="assignForm.starttime" />
                </div>

                <div class="mb-3 col-md-6">
                    <label for="endtime" class="form-label"> End Time </label>
                    <input type="time" class="form-control" id="endtime" name="endtime"
                        [(ngModel)]="assignForm.endtime" />
                </div>

                <div class="mb-3 col-md-12" *ngIf="isEditing">
                    <label for="status" class="form-label"> Status </label>
                    <select class="form-select" [(ngModel)]="assignForm.status">
                        <ng-container *ngFor="let item of statusList">
                            <option *ngIf="item.value != ''" [value]="item.value">{{item.name}}</option>
                        </ng-container>
                    </select>
                </div>

                <div class="mb-3 col-md-12">
                    <label for="users" class="form-label"> User ID (<span class="cc-label" (click)="chooseUser()">Choose
                            User</span>) </label>
                    <div *ngFor="let field of userList; let i = index">
                        <div class="mb-3">
                            <div class="cus-input-group">
                                <input type="text" class="form-control" [(ngModel)]="field.phno" #name="ngModel"
                                    name="field{{i}}" value="{{field}}" (keydown.enter)="addUser()"
                                    style="border-radius: 5px 0px 0px 5px !important;">
                                <button type="button" class="btn btn-custom" (click)="removeUser(i)"
                                    style="width: 10% !important;border-radius: 0px 5px 5px 0px; background-color: rgb(200, 21, 21) !important;">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 mt-3 col-md-12">
                        <button class="btn btn-custom" (click)="addUser()">
                            <i class="fas fa-plus-circle me-2"></i> Add
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>

        <div *ngIf="!isAssignNew">
            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="id">
                                User ID
                            </td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="description">
                                Count
                            </td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="customer">
                                Status
                            </td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="status">
                                Date
                            </td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="gettingAssigment">
                        <tr>
                            <td colspan="6" class="nodata">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="gettingAssigment == false">
                        <tr *ngIf="assigns.length == 0">
                            <td colspan="6" class="nodata">Empty</td>
                        </tr>
                        <tr *ngFor="
                  let item of assigns
                    | paginate
                      : { itemsPerPage: itemPerPage, currentPage: currentPage };
                  let index = index
                ">
                            <td class="td-data td-edit" (click)="editAssign(item)">
                                <span [title]='item.userlist.join(", ")'>{{ item.userlist.join(", ") }}</span>
                            </td>

                            <td class="td-data td-edit" (click)="editAssign(item)">
                                <span [title]="getCount(item.orderlist,item.batchlist)">{{
                                    getCount(item.orderlist,item.batchlist) }}</span>
                            </td>
                            <td class="td-data">
                                <ng-container *ngIf="
                                item.status == '001'">
                                    <span [title]="item.statusName" class="status status-green">{{
                                        item.statusName
                                        }}</span>
                                </ng-container>
                                <ng-container *ngIf="item.status == '002' || item.status == '003'">
                                    <span [title]="item.statusName" class="status status-red">{{
                                        item.statusName
                                        }}</span>
                                </ng-container>

                            </td>
                            <td class="td-data">
                                <span [title]="showDate(item.startdate,item.enddate)">{{
                                    showDate(item.startdate,item.enddate)
                                    }}</span>

                            </td>
                            <td>
                                <button class="btn btn-outline-primary me-2" (click)="editAssign(item)" title="Edit">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-outline-danger" (click)="deleteAssign(item)" title="Delete">
                                    <i class='fa fa-trash-o'></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>