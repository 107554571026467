import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared/services/message.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { DatePipe } from '@angular/common';
import { error } from 'console';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-member-guest-register-form',
  templateUrl: './member-guest-register-form.component.html',
  styleUrls: ['./member-guest-register-form.component.scss']
})
export class MemberGuestRegisterFormComponent implements OnInit {

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };

  memberForm!: FormGroup;

  memberTypeList: any[] = [];

  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];
  selectedProfileImage?: File;
  tempProfileImage: any;
  profileUrl: any;
  selectedAttachments: any[] = [];
  tempfilename: any = "";
  deletedprofilename = "";
  filelist: Array<any> = [];
  deletedfilelist: Array<any> = [];
  requestBodyData: any
  status: string = ''
  requiredMessage = 'This field may not be blank';

  currentMember: any

  saveLoading: boolean = false;

  gettingNrcCode: boolean = false;
  submitted: boolean = false;

  reader = new FileReader();

  @ViewChild('fileInput1') fileInput1: any;
  @ViewChild('fileInput2') fileInput2: any;
  @ViewChild('fileInput3') fileInput3: any;
  @ViewChild('fileInput4') fileInput4: any;
  @ViewChild('fileInput5') fileInput5: any;

  attachment1Selected: boolean = false;
  attachment2Selected: boolean = false;
  attachment3Selected: boolean = false;
  attachment4Selected: boolean = false;
  attachment5Selected: boolean = false;

  attachment1: any;
  attachment2: any;
  attachment3: any;
  attachment4: any;
  attachemtn5: any;

  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  constructor(
    private kunyekService: KunyekService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
    public datepipe: DatePipe,
  ) {
    this.memberForm = this.fb.group({
      name: ["", Validators.required],
      subname: [""],
      birthplace: [""],
      birthdate: [""],
      fathername: [""],
      mothername: [""],
      education: [""],
      currentjob: [""],
      department: [""],
      retiredjob: [""],
      address: [""],
      current_address: [""],
      religion: [""],
      nationality: [""],
      job: [""],
      membertypeid: ["", Validators.required],
      relations: [[]],
      phone: this.allInOneservice.getUserId(),
      nrcregioncode: ["", Validators.required],
      nrctownshipcode: ["", Validators.required],
      nrctype: ["", Validators.required],
      nrcnumber: ["", Validators.required],
      region: ['',],
      town: ['',],
      ward: ['',]
    })
  }

  ngOnInit(): void {
    this.getMemberTypes();
    this.getNrcCode()
    this.phone.disable();
    this.checkUserExits()
    this.getRegion()
  }

  get name() {
    return this.memberForm.get("name")!
  }

  get phone() {
    return this.memberForm.get("phone")!
  }

  get birthdate() {
    return this.memberForm.get("birthdate")
  }

  get membertype() {
    return this.memberForm.get("membertypeid")!
  }

  get nrcregioncode() {
    return this.memberForm.get('nrcregioncode')!
  }

  get nrctownshipcode() {
    return this.memberForm.get('nrctownshipcode')!
  }

  get nrctype() {
    return this.memberForm.get('nrctype')!
  }

  get nrcnumber() {
    return this.memberForm.get('nrcnumber')!
  }

  get region() {
    return this.memberForm.get('region');
  }
  get town() {
    return this.memberForm.get('town');
  }
  get ward() {
    return this.memberForm.get('ward');
  }

  getMemberTypes() {
    this.kunyekService.getKMemberTypesList({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.memberTypeList = res.datalist;
        }
      },
      error: (err) => {
        console.log(err)

      }
    })
  }

  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
    };
    this.kunyekService.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.nrcCode = res.datalist;
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }


  getRegion() {
    const data = {};
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  closeForm() {
    this.memberForm.reset();
    this.submitted = false;
  }

  handleSaveMember() {
    // this.submitted = true
    this.saveLoading = true;
    this.requestBodyData = this.memberForm.value

    if (this.memberForm.valid) {
      let nrc: string;
      nrc = this.memberForm.get('nrcregioncode')?.value + '/' + this.memberForm.get('nrctownshipcode')?.value +
        '(' + this.memberForm.get('nrctype')?.value + ')' +
        this.memberForm.get('nrcnumber')?.value

      this.uploadFiles();
      const formattedBirthDate = this.birthdate?.value?.formatted || this.birthdate?.value;
      this.requestBodyData = {
        ...this.requestBodyData,
        relations: [],
        postcode: this.requestBodyData?.ward,
        nrc: nrc,
        birthdate: this.allInOneservice.convertDate(formattedBirthDate),
        tempfilename: this.tempfilename,
        profileimage: this.selectedProfileImage?.name,
        filelist: this.selectedAttachments
          .filter(file => !file.attachments_id)
          .map(file => file.filename),
        deletedfilelist: this.deletedfilelist
      }

      console.log(this.currentMember, this.requestBodyData)
      if (this.currentMember.syskey) {
        this.requestBodyData = { ...this.requestBodyData, syskey: this.currentMember.syskey }
        // this.kunyekService.editKMember(this.requestBodyData).subscribe(
        //   (res: any) => {
        //     this.saveLoading = false;
        //     if (res.returncode == '300') {
        //       console.log(res)
        //       this.messageService.openSnackBar(res.message, 'success');
        //       this.closeForm()
        //     } else {
        //       this.saveLoading = false;
        //       this.messageService.openSnackBar(res.message, 'warn');
        //     }
        //   },
        //   (err) => {
        //     this.saveLoading = false;
        //     this.messageService.openSnackBar(err, 'warn');
        //   }
        // )
      } else {
        this.currentMember = { syskey: '', relations: [] }
        this.kunyekService.addKMember(this.requestBodyData).subscribe(
          (res: any) => {
            this.saveLoading = false;
            if (res.returncode == '300') {
              console.log(res)
              this.messageService.openSnackBar(res.message, 'success');
              this.closeForm()
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.messageService.openSnackBar(err, 'warn');
          }
        )
      }
    } else {
      this.submitted = true;
      this.saveLoading = false;
      this.messageService.openSnackBar('Invalid Form', 'warn')
    }
  }

  onFileSelected(event: Event) {
    if (this.profileUrl) {
      this.deletedprofilename = JSON.parse(this.currentMember.profileimage).filename;
    }

    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedProfileImage = input.files[0];
      this.reader.readAsDataURL(this.selectedProfileImage);
      this.reader.onload = (event) => {
        this.profileUrl = this.reader.result;
      }
    }
  }

  // attachement1Selected(event: Event) {

  //   const input = event.target as HTMLInputElement;
  //   if (input.files && input.files.length > 0) {
  //     this.attachment1 = input.files[0];
  //     console.log(this.attachment1,'attachment')
  //     this.reader.readAsDataURL(this.attachment1);
  //     this.reader.onload = (event) => {
  //       this.attachment1 = this.reader.result;
  //     }
  //   }
  // }

  onAttachment1Selected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.attachment1Selected = true
      const att1 = input.files[0];
      const timestamp = Date.now();
      this.reader.readAsDataURL(att1);
      this.reader.onload = (event) => {
        console.log(this.reader.result, att1, 'hereeeee')
        this.selectedAttachments.push({
          attachment1: {
            fileurl: this.reader.result,
            filename: `${timestamp}_${att1.name}`,
            file: att1
          }
        });
      }
    }
  }

  async uploadFiles() {

    const defaultpath = 'kokaimember/members';

    if (this.tempfilename == '') {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(
        date,
        'yyyyMMddhhmmss' + millisecond,
      );
    }

    //upload member profile iamge
    if (this.selectedProfileImage) {
      let profilepath = this.status != 'Edit' ?
        `${defaultpath}/${this.tempfilename}/${this.selectedProfileImage.name}`
        : `${defaultpath}/${this.currentMember.syskey}/${this.selectedProfileImage.name}`
      const upload_aws = await this.allInOneservice.uploadAnswerFile(
        this.selectedProfileImage,
        profilepath,
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn',
        );
        return;
      }
      this.tempProfileImage = profilepath;
    }

    //upload member attachements
    if (this.selectedAttachments.length > 0) {
      console.log(this.selectedAttachments)
      for (let i = 0; i < this.selectedAttachments.length; i++) {

        //upload only 
        if (!this.selectedAttachments[i].attachments_id) {
          let path = this.status != 'Edit' ?
            `kokaimember/attachments/${this.tempfilename}/${this.selectedAttachments[i].filename}`
            : `kokaimember/attachments/${this.currentMember.syskey}/${this.selectedAttachments[i].filename}`
          const upload_aws = await this.allInOneservice.uploadAnswerFile(
            this.selectedAttachments[i].file,
            path,
          );
          if (!upload_aws) {
            this.messageService.openSnackBar(
              'Unknown error occured while uploading your files.',
              'warn',
            );
            return;
          }
        }

      }
    }

  }

  removeAttachMent(index: number) {
    if (this.selectedAttachments[index].attachments_id) {
      this.deletedfilelist.push(this.selectedAttachments[index].filename);
    }
    this.selectedAttachments.splice(index, 1);
  }

  checkUserExits() {
    this.kunyekService.getMemberInfo({}).subscribe(
      (res: any) => {
        this.currentMember = res.data
        if (this.currentMember.name) {
          this.memberForm.setValue({
            name: this.currentMember.name,
            subname: this.currentMember.subname,
            birthplace: this.currentMember.birthplace,
            birthdate: this.allInOneservice.changeStringToDateobject(this.currentMember.birthdate),
            nrcregioncode: this.currentMember.nrcregioncode,
            nrctownshipcode: this.currentMember.nrctownshipcode,
            nrctype: this.currentMember.nrctype,
            nrcnumber: this.currentMember.nrcnumber,
            fathername: this.currentMember.fathername,
            mothername: this.currentMember.mothername,
            education: this.currentMember.education,
            currentjob: this.currentMember.currentjob,
            department: this.currentMember.department,
            retiredjob: this.currentMember.retiredjob,
            address: this.currentMember.address,
            current_address: this.currentMember.current_address,
            religion: this.currentMember.religion,
            // userid: this.currentMember.userid? this.currentMember.userid : '',
            nationality: this.currentMember.nationality,
            job: this.currentMember.job,
            membertypeid: this.currentMember.memberType ? this.currentMember.memberType.membertypeid : "",
            relations: this.currentMember.relations,
            region: this.currentMember.directory?.region || '',
            town: this.currentMember.directory?.town || '',
            ward: this.currentMember.directory?.postcode || ''
          })

          if (this.currentMember && this.currentMember.directory) {
            this.onRegionChange({ target: { value: this.currentMember?.directory?.region } });
            this.onTownChange({ target: { value: this.currentMember?.directory?.town } });
          }

          let piamge = JSON.parse(this.currentMember.profileimage);
          this.profileUrl = piamge ? piamge.fileurl : ""
          console.log(this.selectedAttachments,)
          this.selectedAttachments = this.currentMember.filelist;
          this.fileInput1.nativeElement.value = this.selectedAttachments[0].filename;
          this.fileInput2.nativeElement.value = this.selectedAttachments[1].filename;
          this.fileInput3.nativeElement.value = this.selectedAttachments[2].filename;
          this.fileInput4.nativeElement.value = this.selectedAttachments[3].filename;
          this.fileInput5.nativeElement.value = this.selectedAttachments[4].filename;
        }
      },
      (error) => {
        this.currentMember = {}
      }
    )


  }

  formatDate(value: string): string {
    if (value === "") return "";
    const pattern = /(\d{4})(\d{2})(\d{2})/;
    return value.replace(pattern, '$1-$2-$3');
  }

  removefileSelected() {
    this.attachment1 = '';
    this.fileInput1.nativeElement.value = '';
  }

}
