<h2 class="">App Restriction</h2>
<div class="row">
  <div class="col-md-12">
    <label for="type" class="form-label"> Type </label>
    <select class="form-select mb-3" id="type" name="type" [(ngModel)]="appRestrictionSetupForm.typeid" #type="ngModel" [disabled]="isEditing">
        <option *ngFor="let ty of appRestrictionType" [value]="ty.id" [selected]="ty.id == appRestrictionSetupForm.typeid">
          {{ ty.name }}
        </option>
    </select>
  </div>
  <ng-container *ngIf="appRestrictionSetupForm.typeid == '001'">
    <div class="col-md-12 d-flex align-items-center">
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="forcedSignoutType" id="userid" value="001"
          [(ngModel)]="appRestrictionSetupForm.forcedsignouttype" />
        <label class="form-check-label" for="userid"> User ID </label>
      </div>
      <div class="form-check mb-3">
        <input class="form-check-input" type="radio" name="forcedSignoutType" id="organization" value="002"
          [(ngModel)]="appRestrictionSetupForm.forcedsignouttype" />
        <label class="form-check-label" for="organization">
          Organization
        </label>
      </div>
    </div>
    <ng-container *ngIf="appRestrictionSetupForm.forcedsignouttype == '001'">
      <div class="col-md-12 mb-3">
        <label for="userid" class="form-label">User ID</label>
        <input type="text" class="form-control" id="userid" name="userid"
          [(ngModel)]="appRestrictionSetupForm.signoutUserid"
          [ngClass]="{'is-invalid' : submitAppRestrictionSetupForm && ( appRestrictionSetupForm.signoutUserid == '' || appRestrictionSetupForm.signoutUserid == null ) }" />
      </div>
    </ng-container>

    <ng-container *ngIf="appRestrictionSetupForm.forcedsignouttype == '002'">
      <div class="col-md-12 mb-3">
        <label for="org" class="form-label"> Organization </label>
        <select class="form-select" id="org" name="org"
          [(ngModel)]="appRestrictionSetupForm.signoutOrganization"
          [ngClass]="{'is-invalid' : submitAppRestrictionSetupForm && ( appRestrictionSetupForm.signoutOrganization == '' || appRestrictionSetupForm.signoutOrganization == null )}">
          <ng-container *ngFor="let org of orgList">
            <option [value]="org.orgid">{{ org.name }}</option>
          </ng-container>
        </select>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="appRestrictionSetupForm.typeid == '002'">
    <div class="col-md-12 d-flex align-items-center">
      <div class="form-check mb-3 me-5">
        <input class="form-check-input" type="radio" name="updatetype" id="forced" [value]="true"
          [(ngModel)]="appRestrictionSetupForm.updatetype" />
        <label class="form-check-label" for="forced"> Forced </label>
      </div>
      <div class="form-check mb-3">
        <input class="form-check-input" type="radio" name="updatetype" id="warn" [value]="false"
          [(ngModel)]="appRestrictionSetupForm.updatetype" />
        <label class="form-check-label" for="warn"> Warn </label>
      </div>
    </div>
    <div class="col-md-12">
      <label for="updateversion" class="form-label">Version</label>
      <input type="text" class="form-control mb-3" id="updateversion" name="updateversion"
        [(ngModel)]="appRestrictionSetupForm.updateversion"
        [ngClass]="{'is-invalid' : submitAppRestrictionSetupForm && ( appRestrictionSetupForm.updateversion == '' || appRestrictionSetupForm.updateversion == null  )}" />
    </div>
    <div class="mb-3 col-md-12">
      <input class="form-check-input" type="checkbox" id="updatelowerversion"
        [(ngModel)]="appRestrictionSetupForm.updatelowerversion" name="updatelowerversion" />
      <label class="form-check-label ms-2" for="updatelowerversion">Includes lower version</label>
    </div>
    <div class="col-md-12 mb-3">
      <label for="forcedupdateos" class="form-label"> OS </label>
      <select class="form-select" id="forcedupdateos" name="forcedupdateos"
        [(ngModel)]="appRestrictionSetupForm.forcedupdateos">
        <ng-container *ngFor="let os of osList">
          <option value="{{ os.id }}">{{ os.name }}</option>
        </ng-container>
      </select>
    </div>
    <div class="col-md-12 mb-3">
      <label for="message" class="form-label"> Message </label>
      <textarea class="form-control" name="remark" id="remark" rows="3"
        [(ngModel)]="appRestrictionSetupForm.updatemessage">
      </textarea>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions align="end">
  <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
    Cancel
  </button>
  <!-- <button type="button" class="btn btn-custom ">
      Save
    </button> -->
  <app-button-loader (click)="submitAppRestrictionForm()" [button_text]=" 'Save'" [load_button_text]=" 'Saving'"
    [isLoading]="appRestrictionSetupLoading"></app-button-loader>
</div>
<!-- <button class="btn btn-custom" (click)="submitForm()">Test</button> -->