<div *ngIf="!react && !commentreact" >


    <div class="title-container mb-3">
        <div class="back-btn" *ngIf="viewType == '1'">
            <button mat-icon-button (click)="goBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <!-- <div class="title">
            Likes
        </div> -->
        <div class="title" *ngIf="(!react || !commentreact) && !isFromMessage && !isFromVote && !isFromResponse && viewType == '0' && !isFromMessageSeen">
            Tags
        </div>
        <div class="title" *ngIf="isFromMessage && viewType == '0' && !isFromVote && !isFromResponse && !isFromMessageSeen">
            Reactions
        </div>
        <div class="title" *ngIf="isFromVote && viewType == '0' && !isFromMessage && !isFromResponse && !isFromMessageSeen">
            Votes
        </div>
        <div class="title" *ngIf="!isFromVote && viewType == '0' && !isFromMessage && isFromResponse && !isFromMessageSeen">
            {{title}}
        </div>
        <div class="title" *ngIf="!isFromVote && viewType == '0' && !isFromMessage && !isFromResponse && isFromMessageSeen">
            Read by
        </div>
        <div class="title" *ngIf="viewType == '1'">
            Profile
        </div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <hr>
    <div class="list-group" *ngIf="!gettingReact && viewType == '0'">
        <div class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let user of userList;">
            <div style="display: flex;cursor: pointer;" (click)="viewProfile(user.userid )">
                <div>
                    <img class="profile-image " src="{{user.imagename}}">

                </div>

                <div class="name ms-3 me-auto">

                    <div>
                        <b>{{user.username == "" ? user.userid : user.username}}</b>
                    </div>

                    <div *ngIf="user.username != ''">
                        <small class="col-12">{{user.userid}}</small>
                    </div>

                </div>

            </div>

            <span >
                <img *ngIf="user.type == 'like'" id="react-img"  class=" ms-2 me-1" src="../../../assets/images/like.svg" width="15">
                <img *ngIf="user.type == '002'" id="react-img"  class=" ms-2 me-1" src="../../../assets/images/love.svg" width="15">
                <img *ngIf="user.type == '003'" id="react-img"  class=" ms-2 me-1" src="../../../assets/images/haha.svg" width="15">
                <img *ngIf="user.type == '004'" id="react-img"  class=" ms-2 me-1" src="../../../assets/images/sad.svg" width="15">
                <img *ngIf="user.type == '005'" id="react-img"  class=" ms-2 me-1" src="../../../assets/images/angry.svg" width="15">
            </span>
        </div>

    </div>
</div>

<div *ngIf="(react || commentreact) && !gettingReact && viewType == '0'">
    <div style="position: relative;">
        <div class="close-btn-react">
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <div>
                    <svg height="20" width="20">
                        <text x="0" y="15">All</text>
                    </svg>
                </div>

                <div class="ps-2" *ngIf="userlistAll.length>0">
                    {{userlistAll.length}}
                </div>
            </ng-template>
            <div class="list-group" *ngIf="!gettingReact && viewType == '0'">
                <div class="list-group-item d-flex justify-content-between" *ngFor="let user of userlistAll;">
                    <div style="display: flex;cursor: pointer;" (click)="viewProfile(user.userid)">
                        <div>
                            <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="user.imagename ? user.imagename : '../../../assets/images/profile.png' ">
                            <div style="position: relative;"
                                *ngIf="(react || commentreact) && !isFromMessage && !isFromVote && !isFromResponse && viewType == '0'">
                                <div class="react-icon">
                                    <div *ngIf="user.id=='4'">
                                        <img src="../../../assets/images/like.svg" width="20">

                                    </div>
                                    <div *ngIf="user.id=='002'">
                                        <img src="../../../assets/images/love.svg" width="20">
                                    </div>
                                    <div *ngIf="user.id=='003'">
                                        <img src="../../../assets/images/haha.svg" width="20">
                                    </div>
                                    <div *ngIf="user.id=='004'">
                                        <img src="../../../assets/images/sad.svg" width="20">
                                    </div>
                                    <div *ngIf="user.id=='005'">
                                        <img src="../../../assets/images/angry.svg" width="20">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="name ms-3">
                            <div>
                                <b>{{user.username == "" ? user.userid : user.username}}</b>
                            </div>

                            <div *ngIf="user.username != ''">
                                <small class="col-12">{{user.userid}}</small>
                            </div>

                        </div>

                    </div>
                </div>

            </div>

        </p-tabPanel>

        <p-tabPanel *ngFor="let react of userList;">
            <ng-template pTemplate="header">

                <div *ngIf="react.id=='4'">
                    <img src="../../../assets/images/like.svg" width="20">

                </div>
                <div *ngIf="react.id=='002'">
                    <img src="../../../assets/images/love.svg" width="20">
                </div>
                <div *ngIf="react.id=='003'">
                    <img src="../../../assets/images/haha.svg" width="20">
                </div>
                <div *ngIf="react.id=='004'">
                    <img src="../../../assets/images/sad.svg" width="20">
                </div>
                <div *ngIf="react.id=='005'">
                    <img src="../../../assets/images/angry.svg" width="20">
                </div>

                <div class="ps-2">
                    {{react.count}}
                </div>
            </ng-template>

            <div class="list-group" *ngIf="!gettingReact && viewType == '0'">
                <div class="list-group-item d-flex justify-content-between" *ngFor="let user of react.userlist;">
                    <div style="display: flex;cursor: pointer;" (click)="viewProfile(user.userid)">
                        <div>
                            <img class="profile-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="user.imagename ? user.imagename : '../../../assets/images/profile.png' ">
                            <div style="position: relative;"
                                *ngIf="react && !isFromMessage && !isFromVote && !isFromResponse && viewType == '0'">
                                <div class="react-icon">
                                    <div *ngIf="react.id=='4'">
                                        <img src="../../../assets/images/like.svg" width="20">

                                    </div>
                                    <div *ngIf="react.id=='002'">
                                        <img src="../../../assets/images/love.svg" width="20">
                                    </div>
                                    <div *ngIf="react.id=='003'">
                                        <img src="../../../assets/images/haha.svg" width="20">
                                    </div>
                                    <div *ngIf="react.id=='004'">
                                        <img src="../../../assets/images/sad.svg" width="20">
                                    </div>
                                    <div *ngIf="react.id=='005'">
                                        <img src="../../../assets/images/angry.svg" width="20">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="name ms-3">
                            <div>
                                <b>{{user.username == "" ? user.userid : user.username}}</b>
                            </div>

                            <div *ngIf="user.username != ''">
                                <small class="col-12">{{user.userid}}</small>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </p-tabPanel>



    </p-tabView>

</div>

<div class="list-group" *ngIf="!gettingReact && viewType == '1'">
    <div *ngIf="!gettingReact && error">
        <!-- <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div> -->
        <div style="text-align: center;
        margin-top: 50px;
        color: red;">
            <mat-icon>info_outline</mat-icon>
        </div>
        <div style="text-align: center;
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;">Oops! Something went wrong..</div>
        <div style="margin-top: 10px;
        margin-left: 170px;">
            <button mat-icon-button style="width: 100px; color: var(--main-color) !important;"
                (click)="viewProfile(uid)">
                <mat-icon>refresh</mat-icon> <span> Refresh</span>
            </button>
        </div>
    </div>

    <div *ngIf="!gettingReact && !error">
        <div class="profile-img-container mt-3">
            <div class="conversation-summary-avatar conversation-summary-avatar-group profile-img mb-2"
                data-number-of-thumbnails="1" (click)="imageViewer(user)">
                <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member-wrapper"
                        [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="user.imagename ? user.imagename : '../../../assets/images/profile.png' ">
                </div>
            </div>
            <div>
                <b>{{user.username}}</b>
            </div>
        </div>
        <mat-list>
            <mat-list-item class="list-item" *ngIf="allinoneService.isPhone(user.userid)">
                <mat-icon mat-list-icon class="icon">phone</mat-icon>
                <div mat-line class="text">{{user.userid}}</div>
            </mat-list-item>
            <mat-list-item class="list-item" *ngIf="!allinoneService.isPhone(user.userid)">
                <mat-icon mat-list-icon class="icon">email</mat-icon>
                <div mat-line class="text">{{user.userid}}</div>
            </mat-list-item>
            <div *ngIf="allinoneService.getKunyekUserId() != user.userid">
                <mat-list-item class="list-item add-friend" *ngIf="user.friend != 'true'" (click)="addContact()">
                    <mat-icon mat-list-icon class="icon">person_add</mat-icon>
                    <div mat-line class="text">Add Friend</div>
                </mat-list-item>
            </div>
            <div
                *ngIf="allinoneService.getKunyekUserId() != user.userid && user.block != 'true' && user.friend == 'true'">
                <mat-list-item class="list-item block" (click)="blockUser()" *ngIf="!isBlocking">
                    <mat-icon mat-list-icon class="icon">block</mat-icon>
                    <div mat-line class="text">Block this contact</div>
                </mat-list-item>
            </div>
            <mat-list-item class="list-item" *ngIf="isBlocking">
                <div mat-list-icon class="icon">
                    <span class="spinner-border spinner-border-sm" style="margin-bottom: 2px;" role="status"
                        aria-hidden="true"></span>
                </div>
                <div mat-line class="text">Blocking this contact</div>
            </mat-list-item>
        </mat-list>
    </div>

</div>
<div *ngIf="gettingReact" class="loader">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div class="modal" id="profile-image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-more-container">
              <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="downloadImage()">
                <mat-icon>download</mat-icon>
              </button>
              <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="CloseImageViewer()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <img [src]="imagename" alt="" class="image-view">
            </div>
    
          </div>
        </div>
      </div>
  </div>