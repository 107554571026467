import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BookType } from 'xlsx';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidatesComponent } from '../candidates/candidates.component';
import { ProxyComponent } from '../proxy/proxy.component';

@Component({
  selector: 'app-voters',
  templateUrl: './voters.component.html',
  styleUrls: ['./voters.component.css'],
})
export class VotersComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  constructor(
    private KunyekService: KunyekService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    const temp_cid = this.route.snapshot.paramMap.get('camid');
    if (temp_cid) {
      this.cid = decodeURIComponent(temp_cid);
    }
  }

  round: any[] = [];
  members: any[] = [];

  user_id: string = '';
  password: string = '';
  editpassword: string = '';
  edittest: string = '';
  username: string = '';
  remark: string = '';
  cid: string = '';
  camdesc: string = '';
  addvoting: boolean = false;
  fieldTextType: boolean = false;
  @Input() voteEdit: any = [{}];

  excel: any;
  loading: boolean = false;
  @ViewChild('excelFile') excelFile: any;
  checkingExcelFile: boolean = false;
  allok: boolean = false;
  isAdd: boolean = false;
  isImport: boolean = false;
  rawMemberList: any[] = [];
  isEditing: boolean = false;
  editVote: boolean = false;
  isError: boolean = false;
  isMobileView: boolean = false;
  noCampaignError: boolean = false;
  list: boolean = true;
  description: string = '';
  votecount: string = '0';
  mobileViewWidth: number = 426;
  isExcel: boolean = false;
  errorList: any = [];
  warningList: any = [];
  duplicateList: any = [];
  uidList: any = [];
  useridList: any = [];
  finalMemberList: any = [];
  newcclist: any = [];
  excelerror: boolean = false;
  voterscount: any = '0';
  searchText: string = '';
  isFocus: boolean = false;
  deleteList: any[] = [];
  itemPerPage: any = 100;
  p: number = 1;
  passwordDisabel: boolean = false;
  vStart: number = 0;
  vEnd: number = 30;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  ngOnInit(): void {
    this.getVoter();
  }

  back() {
    const camid = encodeURIComponent(this.cid);
    this.router.navigate([`/admin/campaign/${camid}`]);
  }
  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  downloadSheet() {
    var data: any = [];
    var name = 'MembersSheet.xlsx';
    this.round.map((member: any) => {
      data.push({
        ID: member.userid,
        Name: member.name,
      });
    });
    this.allinoneService.exportEcecl(data, name);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  proxyList: any[] = [];

  openProxy() {
    const dialog = this.dialog.open(ProxyComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: false,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        proxyList: JSON.parse(JSON.stringify(this.proxyList)),
        deletelist:
          this.deleteList.length == 0
            ? []
            : JSON.parse(JSON.stringify(this.deleteList)),
        // voteLimit: this.voteLimit,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
        this.proxyList = dialogResult[0]['proxylist'];
        this.deleteList = dialogResult[0]['deletelist'];
        console.log(this.deleteList);
        this.votecount = this.proxyList.length.toString();
      }
    });
  }

  deleteVoter(dedata: any) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {});

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
        var userid = this.allinoneService.getKunyekUserId();
        const atoken = this.allinoneService.getAtoken();
        const data = {
          userid: userid,
          atoken: atoken,
          voterid: dedata.voterid,
        };
        this.KunyekService.deleteVoter(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.addvoting = false;
              this.list = true;
              this.editVote = false;
              this.isAdd = false;
              this.round = [];
              this.getVoter();
            } else {
              this.addvoting = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.isError = true;
            this.addvoting = false;
          }
        );
      }
    });
  }

  addVote() {
    if (this.password.toString().trim() != '' && this.password.length < 3) {
      this.messageService.openSnackBar(
        'Password should have at least 3 characters in length.',
        'warn'
      );

      return;
    }
    this.addvoting = true;

    const data = {
      camid: this.cid,
      voterlist: [
        {
          userid: this.allinoneService.checkUserId(
            this.user_id.toString().trim()
          ),
          remark: this.remark,
          votecount: this.votecount,
          name: this.username,
          password: this.password.toString().trim(),
          proxylist: this.proxyList,
        },
      ],
      orgid: this.allinoneService.selectedOrg,
      domainid: this.allinoneService.getDomain().domainid
    };
    this.KunyekService.setupvoterimmport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.proxyList = [];
          this.addvoting = false;
          this.list = true;
          this.editVote = false;
          this.isAdd = false;
          this.isExcel = false;

          this.getVoter();
        } else {
          this.addvoting = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        this.addvoting = false;
      }
    );
  }

  reload() {
    window.location.reload();
  }

  addvoter() {
    this.proxyList = [];
    this.password = '';
    this.fieldTextType = false;
    this.isAdd = true;
    this.editVote = false;
    this.list = false;
    this.isExcel = false;
    this.remark = '';
    this.user_id = '';
    this.username = '';
    this.votecount = '0';
  }

  deltepassword() {
    this.fieldTextType = false;
    this.passwordDisabel = false;
    this.editpassword = '';
  }

  // goEdit() {
  //   this.editVote = true;
  //   this.list = false;
  //   this.isAdd = false;

  // }

  govotinglist() {
    this.passwordDisabel = false;
    this.fieldTextType = false;
    this.isAdd = false;
    this.list = true;
    this.editVote = false;
    this.allok = false;
    this.excelerror = false;
    this.isExcel = false;
  }
  govotingEditlist() {
    this.passwordDisabel = false;
    this.fieldTextType = false;
    this.isAdd = false;
    this.list = true;
    this.editVote = false;
    this.isExcel = false;
    this.allok = false;
    this.excelerror = false;
    this.excel = false;
    this.excelFile.nativeElement.value = '';
    this.warningList = [];
    this.errorList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
  }

  editVoteter(data: any) {
    if (data.password == '') {
      this.fieldTextType = false;
      this.passwordDisabel = false;
      this.editpassword = data.password;
    } else {
      this.fieldTextType = true;
      this.passwordDisabel = true;
      this.editpassword = data.password;
      this.edittest = 'Password is encrypted';
    }

    this.proxyList = data.proxylist;
    this.votecount = data.votecount;
    this.excelerror = false;
    this.voteEdit = data;
    this.editVote = true;
    this.list = false;
    this.isAdd = false;
    this.isExcel = false;
    this.excel = false;
  }

  upload() {
    this.isExcel = true;
    this.editVote = false;
    this.list = false;
    this.isAdd = false;
  }

  updateVoter() {
    if (
      this.editpassword.toString().trim() != '' &&
      this.editpassword.length < 3
    ) {
      this.messageService.openSnackBar(
        'Password should have at least 3 characters in length.',
        'warn'
      );
      return;
    }

    this.addvoting = true;
    const data = {
      name: this.voteEdit.name,
      remark: this.voteEdit.remark,
      votecount: this.votecount,
      voterid: this.voteEdit.voterid,
      password: this.editpassword,
      proxylist: this.proxyList,
      deleteproxylist: this.deleteList,
    };
    console.log(data);
    this.KunyekService.updateVoter(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.proxyList = [];
          this.addvoting = false;
          this.list = true;
          this.editVote = false;
          this.isAdd = false;
          this.isExcel = false;
          this.round = [];
          this.getVoter();
        } else {
          this.addvoting = false;
          this.isExcel = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        this.addvoting = false;
        this.isExcel = false;
      }
    );
  }

  getVoter() {
    this.loading = true;
    var userid = this.allinoneService.getKunyekUserId();
    // const atoken = this.allinoneService.getAtoken();

    const data = {
      orgid: this.allinoneService.selectedOrg,
      domainid: this.allinoneService.getDomain().domainid,
      camid: this.cid,
    };
    this.KunyekService.getVoter(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.round = res.datalist;
          this.voterscount = this.round.length.toString();
          this.pg[3].count = this.round.length;
          this.camdesc = res.description;
          this.loading = false;
        } else {
          this.noCampaignError = true;
          this.loading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        this.loading = false;
      }
    );
  }


  onExcelFileSelect(event: any) {
    this.excelerror = false;
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }
  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
  }

  checkExcelFile() {
    this.checkingExcelFile = true;

    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.uidList = [];

    const tempCcList: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '') &&
        (raw[2] == null || raw[2] == undefined || raw[2] == '') &&
        (raw[3] == null || raw[3] == undefined || raw[3] == '') &&
        (raw[4] == null || raw[4] == undefined || raw[4] == '')
      ) {
      } else {
        if (raw[0] == null || raw[0] == undefined || raw[0] == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "'User ID' is empty.",
          };
          this.errorList.push(tempError);
        } else {
          if (raw[1] == null || raw[1] == undefined || raw[1] == '') {
            const tempError = {
              line: (i + 1).toString(),
              error: "'Name' is empty.",
            };
            this.errorList.push(tempError);
          } else {
            const uidindex = this.uidList.findIndex((x: any) =>
              x.userid.trim() == raw[0] ? raw[0] : ''.toString().trim()
            );
            console.log(uidindex);
            if (uidindex > -1) {
              const tempError = {
                line: (i + 1).toString(),
                error:
                  "User id '" + raw[0].toString().trim() + "' is duplicated.",
              };
              this.warningList.push(tempError);
            } else {
              if (raw[0] ? raw[0] : ''.toString().trim() != '') {
                var tempEId = {
                  line: (i + 1).toString(),
                  userid: raw[0].toString().trim(),
                };
                this.uidList.push(tempEId);
              }

              const uid = this.allinoneService.checkUserId(raw[0].toString());
              // if (uid != this.allinoneService.getKunyekUserId()) {
              const name = raw[1] ? raw[1].toString() : '';
              const userid = uid;
              const remark = raw[2] ? raw[2].toString() : '';
              const string = raw[3] ? raw[3].toString() : '';
              const passwrod = raw[4] ? raw[4].toString() : '';
              var plist = [];
              if (raw[3] == '') {
              } else {
                var array = string.split(',');

                for (let _i = 0; _i < array.length; _i++) {
                  if (array[_i].toString().trim() != '') {
                    const ptist = plist.filter((x: any) => x.proxyname == array[_i].toString().trim())
                    if (ptist.length > 0) {
                      const tempError = {
                        line: (i + 1).toString(),
                        error:
                          "Proxy '" + array[_i].toString().trim() + "' is duplicated.",
                      };
                      this.warningList.push(tempError);
                    }
                    else {
                      plist.push({ proxyid: '', proxyname: array[_i].toString().trim() });
                    }
                  }
                }
              }

              if (this.useridList.indexOf(uid) > -1) {
                if (this.duplicateList.indexOf(uid) == -1) {
                  this.duplicateList.push(uid);
                }
              } else {
                this.useridList.push(uid);
                const tempMember = {
                  name: name,
                  userid: userid,
                  votecount: plist.length.toString(),
                  proxylist: plist,
                  password: passwrod,
                  remark: remark,
                };
                this.finalMemberList.push(tempMember);
                this.checkingExcelFile = false;
                this.allok = true;
              }
              // }
              // }
            }
          }
        }
      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('duplicate ====> ');
    console.log(this.duplicateList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (
      this.errorList.length == 0 &&
      this.newcclist.length == 0 &&
      this.finalMemberList.length > 0
    ) {
      // this.checkEidList()
    } else {
      this.checkingExcelFile = false;
    }
  }

  submitExcelVote() {
    if (this.finalMemberList.length > 0) {
      if (this.vEnd > this.finalMemberList.length) {
        this.vEnd = this.finalMemberList.length;
      }
      this.AddExeclVote();
    }
    else {
      this.messageService.openSnackBar('Your excel file is empty', 'warn')
    }
  }

  submitOrgVoter(data: any) {
    if (data.length > 0) {
      if (this.vEnd > data.length) {
        this.vEnd = data.length;
      }
      this.AddOrgMemberAsVoter(data);
    }
    else {
      this.messageService.openSnackBar('Your excel file is empty', 'warn')
    }
  }

  AddExeclVote() {

    var tempvoter: any[] = [];
    for (let i = this.vStart; i < this.vEnd; i++) {
      tempvoter.push(this.finalMemberList[i]);
    }
    this.addvoting = true;
    this.excelerror = false;
    const data = {
      voterlist: tempvoter,
      camid: this.cid,
      orgid: this.allinoneService.selectedOrg,
      domainid: this.allinoneService.getDomain().domainid
    };
    console.log(data);
    this.KunyekService.setupvoterimmport(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        if (this.vEnd == this.finalMemberList.length) {
          this.addvoting = false;
          this.list = true;
          this.editVote = false;
          this.isAdd = false;
          this.isExcel = false;
          this.excel = false;
          this.allok = false;
          this.removeExcel();
          this.getVoter();
          this.vStart = 0;
          this.vEnd = 30;
        } else {
          this.vStart = this.vEnd;
          this.vEnd = this.vEnd + 30;
          if (this.vEnd > this.finalMemberList.length) {
            this.vEnd = this.finalMemberList.length;
          }
          this.AddExeclVote();
        }
      } else {
        this.addvoting = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });

  }

  sortData(sort: any) {
    const data = this.round;
    if (!sort.active || sort.direction === '') {
      this.round = data;
      return;
    }

    this.round = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        case 'votecount':
          return this.allinoneService.compare(a.votecount, b.votecount, isAsc);

        default:
          return 0;
      }
    });
  }

  gotohome() {
    this.router.navigateByUrl(`home`);
  }

  gotocampaings() {
    this.router.navigateByUrl(`admin/campaign`);
  }
  Open() {
    var org_id = this.allinoneService.selectedOrg;
    var orgs = this.allinoneService.orgs;
    var orgname = '';
    var orgRes = orgs.filter((x: any) => x.orgid == org_id);
    if (orgRes.length > 0) {
      orgname = orgRes[0]['name'];
    }

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        cmessage:
          'Are you sure to import ' +
          orgname +
          "'s members as voter?",
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
        this.ImportOrgMemberToVoter(org_id);
      }
    });
  }

  ImportOrgMemberToVoter(org_id: any) {
    this.allinoneService.isLoading = true;

    this.members = [];
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      domain: this.allinoneService.getDomain().shortcode,
      orgid: org_id,
    };
    this.KunyekService.getMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;
          console.log(this.members);
          const membervoterList: any = [];
          for (var _i = 0; _i < this.members.length; _i++) {
            var membervoter = {
              userid: this.members[_i].userid,
              name: this.members[_i].username,
              remark: '',
              votecount: '0',
              password: '',
              proxylist: [],
            };
            membervoterList.push(membervoter);
          }
          console.log(membervoterList);
          this.submitOrgVoter(membervoterList)
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.allinoneService.isLoading = false;
        }
      },
      (err) => {
        this.allinoneService.isLoading = false;
      }
    );
  }

  AddOrgMemberAsVoter(OrgMemberData: any) {
    if (OrgMemberData.length > 0) {
      var tempvoter: any[] = [];
      for (let i = this.vStart; i < this.vEnd; i++) {
        tempvoter.push(OrgMemberData[i]);
      }
      const data = {
        voterlist: tempvoter,
        camid: this.cid,
        orgid: this.allinoneService.selectedOrg,
        domainid: this.allinoneService.getDomain().domainid

      };
      this.KunyekService.setupvoterimmport(data).subscribe((res: any) => {
        if (res.returncode == '300') {
          if (this.vEnd == OrgMemberData.length) {
            this.allinoneService.isLoading = false;
            this.getVoter();
            this.vStart = 0;
            this.vEnd = 30;
          } else {
            this.vStart = this.vEnd;
            this.vEnd = this.vEnd + 30;
            if (this.vEnd > OrgMemberData.length) {
              this.vEnd = OrgMemberData.length;
            }
            this.AddOrgMemberAsVoter(OrgMemberData);
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.allinoneService.isLoading = false;
        }
      }, err => {
        this.allinoneService.isLoading = false;
      });
    }
  }

  downloadSampleFile(): any {
    this.http
      .get(
        this.allinoneService.bucketUrl + 'templates/VoterTemplate.xlsx',
        { responseType: 'blob' }
      )
      .subscribe((response: any) => {
        saveAs(response, 'SampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }
}
