<div class="container-fluid">
  <div class="content">
    <app-office-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="isLateRequest ? 'Late Request' : 'Early Out Request'"
      [t_breadcrumb]="isLateRequest ? 'Late List' : 'Early Out List'" (emit)="goToFPage()"></app-office-breadcrumb>

    <app-office-breadcrumb *ngIf="isAdding" [s_breadcrumb]="isLateRequest ? 'Late Request' : 'Early Out Request'"
      [t_breadcrumb]="isLateRequest ? 'Late List' : 'Early Out List'" [f_breadcrumb]="'New Request'" (emit)="goToFPage()" (emit1)="goToTableView()"></app-office-breadcrumb>

    <div *ngIf="!isAdding">
      <div class="d-flex flex-wrap align-items-start justify-content-between">
        <div class="mb-3 d-flex gap-2 flex-wrap justify-content-start align-items-center">
          <div class=""> <input type="date" class="form-control" id="startdate" name="startdate" max="9999-12-31"
              [(ngModel)]="submitForm.startdate" (change)="setLastDat()"> </div>
          <div class=""> <input type="date" class="form-control" id="enddate" name="enddate"
              [(ngModel)]="submitForm.enddate" [min]="submitForm.startdate" [max]="minDate" (change)="getDataList()"> </div>

          <div class="line g-0"></div>
          <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
            <input type="text" class="form-control" placeholder="&#xf002; Search"
              aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
              (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
              [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
            <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
              [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>
          <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-1' : !isMobileView }">
            <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
              aria-expanded="false" title="Page Size">
              <mat-icon>filter_list</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
              <li>
                <div style="text-align: center;">Page size</div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li class="dropdown-item" *ngFor="let page of pg">
                <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                  id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
              </li>
            </ul>
          </div>

          <!-- <app-button-loader class="" [button_text]="'Search'" [load_button_text]="'Searching'"
            [isLoading]="searchLoading" (click)="search()">
          </app-button-loader> -->
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>

          <div class="line g-0"></div>
          <div class="d-flex align-items-center">
            <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{ (dataList |
              filter: searchText: tablefilter ).length}}</span>
          </div>

          <div class="input-group mt-2" *ngIf="isMobileView">
            <input type="text" class="form-control" placeholder="&#xf002; Search"
              aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
              [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
              (ngModelChange)="searchTextChange()">
            <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
              [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>

          <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
        *ngIf="dataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next"
        >
        </pagination-controls>

        </div>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-3" mat-sort-header="date">Date</td>
              <td scope="col" class="col-md-3" mat-sort-header="shift">Shift</td>
              <td scope="col" class="col-md-3" mat-sort-header="{{isLateRequest ? 'actual_timein' : 'actual_timeout'}}">{{isLateRequest ? 'Actual Time In' : 'Actual Time Out'}}</td>
              <td scope="col" class="col-md-3" mat-sort-header="{{isLateRequest ? 'late_time' : 'earlyout_time'}}">{{isLateRequest ? 'Late Time' : 'Early Out Time'}}</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingData">
            <tr>
              <td colspan="10" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingData">
            <tr *ngIf="dataList.length==0">
              <td colspan="10" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of dataList | filter: searchText: tablefilter  | paginate: { itemsPerPage: itemPerPage ,currentPage: currentPage }; let index=index" (click)="editbackdate(item)">
              <td class="td-edit">
                <span [title]="allinoneService.formatDBToShow(item.date)">{{ allinoneService.formatDBToShow(item.date)}} </span>
              </td>
              <td class="td-edit">
                <span [title]="item.shift">{{item.shift}} </span>
              </td>
              <td class="td-edit">
                <span [title]="isLateRequest ? item.actual_timein : item.actual_timeout">{{isLateRequest ? allinoneService.formatTimeToShow(item.actual_timein) : allinoneService.formatTimeToShow(item.actual_timeout)}} </span>
              </td>

              <td class="td-edit">
                <span [title]="isLateRequest ? item.late_time_format : item.earlyout_time_format">{{isLateRequest ? item.late_time_format : item.earlyout_time_format }} </span>
              </td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="isAdding">
      <div class="d-flex align-items-center">
        <button
          type="button"
          class="btn btn-custom me-2 mb-3"
          (click)="cancel()"
        >
          Cancel
        </button>
        <app-button-loader
          class="mb-3"
          (click)="save()"
          [button_text]="'Save'"
          [load_button_text]="'Saving'"
          [isLoading]="saveLoading"
        >
        </app-button-loader>
      </div>
      <app-late-earlyout-request #lateEarlyOut [requestData]="currentData" [isEditing]="false" [backdateRequest]="true"
            [orgid]="submitForm.orgid" [isEarlyout]="!isLateRequest" [approverList]="approverList"
            (save)="submitLateEarlyOut($event)"></app-late-earlyout-request>
    </div>

  </div>
</div>