<!-- <perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true"
    fxFlex="auto" #perfectScroll> -->

<div class="container-fluid">
    <div class="content">


        <div>
            <app-crm-breadcrumb *ngIf="!checkcustomer" s_breadcrumb="Service Monthly Report">
            </app-crm-breadcrumb>
            <app-crm-breadcrumb *ngIf="checkcustomer" s_breadcrumb="Self Service"
                [t_breadcrumb]="'Service Monthly Report'" (emit)="goCrm()">
            </app-crm-breadcrumb>

            <div class="d-flex align-items-center mb-3">
                <div class="form-check  me-5" *ngFor="let item of typeList">
                    <label class="radio-inline">
                        <input class="form-check-input" type="radio" name="typereport" [id]="item.key"
                            [(ngModel)]="typereport" [value]="item.key" [checked]="item.key == typereport"
                            (change)="selected($event)">
                        {{item.name}}
                    </label>
                </div>
            </div>



            <div class="d-flex flex-wrap mb-3" *ngIf="typereport == '1'">
                <div class="date me-2">
                    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="startdate">
                </div>
                <div class="date me-2">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate">
                </div>
                <div class="me-2">
                    <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Search">
                        Search
                    </button>
                </div>
                <div class="">
                    <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                        matTooltip="Export Service Monthly Report">
                        <img class="excel-icon" src="../../../assets/images/excel.png">Export
                    </button>

                </div>
            </div>
            <div class="d-flex flex-wrap mb-3" *ngIf="typereport == '2'">
                <div class="me-2">
                    <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Search">
                        Search
                    </button>
                </div>
                <div class="">
                    <button type="button" class="btn btn-custom" (click)="downloadSheet1()"
                        matTooltip="Export Service Monthly Report">
                        <img class="excel-icon" src="../../../assets/images/excel.png">Export
                    </button>

                </div>
            </div>




            <div *ngIf="typereport == '1'">
                <div class="card card-body my-card" *ngIf="gettingData" style="flex-direction: column;">
                    <div class="d-flex justify-content-center align-items-center" style="height: 300px;">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="card card-body my-card">

                    <div class="table-responsive" *ngIf="!gettingData">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="" style="width: 100px;" *ngFor="let item of monthnamelist">
                                        {{item.name}}
                                    </td>

                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <!-- <tr>
                                <td colspan="9" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr> -->
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="serviceMonthlyList.length == 0">
                                    <td class="nodata">Empty</td>
                                </tr>
                                <tr *ngFor="let item of serviceMonthlyList">
                                    <td>
                                        <span>{{item.productname}}</span>
                                    </td>
                                    <td id="td-data" *ngFor="let month of item.monthcountlist "
                                        (click)="Open(month,item.productid)">
                                        <span style="color:var(--main-color)">{{month.count}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
            <div *ngIf="typereport == '2'">
                <div class="card card-body my-card" *ngIf="gettingDataEarlier" style="flex-direction: column;">
                    <div class="d-flex justify-content-center align-items-center" style="height: 300px;">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="card card-body my-card" *ngIf="!gettingDataEarlier">
                    <div class="table-responsive">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col"></td>
                                    <td scope="col">{{monthname}}</td>
                                    <td scope="col">Earlier Month</td>
                                    <td scope="col">Total</td>

                                </tr>
                            </thead>
                            <tbody *ngIf="gettingDataEarlier">
                                <tr>
                                    <td colspan="4" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingDataEarlier== false">
                                <tr *ngIf="earlierreportList.length == 0">
                                    <td class="nodata" colspan="4">Empty</td>
                                </tr>
                                <tr *ngFor="let month of earlierreportList">
                                    <td>
                                        {{month.productname}}
                                    </td>
                                    <td id="td-data"(click)="Open1(month,month.productid,'monthcount')">
                                        <span style="color:var(--main-color)">{{month.currentmonthcount}}</span>
                                    </td >
                                    <td  id="td-data"(click)="Open1(month,month.productid,'earlier')">
                                        <span style="color:var(--main-color)">{{month.earilermonthcount}}</span>
                                    </td>
                                    <td id="td-data" (click)="Open1(month,month.productid,'total')">
                                        <span style="color:var(--main-color)">{{month.total}}</span>
                                    </td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>






        <!-- <div *ngIf="summaryCheck"> -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- <app-crm-summary [checkOrg]="checkOrgB" [orgid]="crmForm.orgid" (crmcancel)="cancel()" (statussummary)="summaryBackStatus($event)">
                    </app-crm-summary> -->
        <!-- app-crm-summary -->
        <!-- </div> -->
    </div>


</div>
<!-- </perfect-scrollbar> -->