<nav aria-label="Page navigation" class="paginate">
    <ul class="pagination justify-content-center">
        <li class="page-item" [class.disabled]="currentPage===1">
            <button (click)="previous()" class="page-link" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </button>
        </li>
        <li class="page-item" [class.active]="false" [hidden]="previousPage === 0">
            <button (click)="previous()" class="page-link" href="#">{{previousPage}}</button>
        </li>
        <li class="page-item" [class.active]="true">
            <a class="page-link">{{currentPage}}</a>
        </li>
        <li class="page-item" [hidden]="currentPage >= totalPages" [class.active]="false">
            <button (click)="next()" class="page-link">{{nextPage}}</button>
        </li>
        <!-- Add more pages as needed -->
        <li class="page-item" [class.disabled]="currentPage >= totalPages">
            <button class="page-link" (click)="next()" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </button>
        </li>
    </ul>
</nav>