<div class="title-container mb-3">

    <div class="title">
        Add Contact
    </div>
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr>

<div mat-dialog-content>
    <!-- <p>Are you sure?</p> -->
    <label for="userid" class="form-label">User ID</label>
    <input type="text" class="form-control" required [(ngModel)]="userid" id="userid" name="userid"
        placeholder="john@domain.com, +959********" (keyup.enter)="onConfirm()" #uid="ngModel"
        [ngClass]="{ 'is-invalid': formSubmitted && uid.invalid }">

    <div class="invalid-feedback"
        *ngIf="uid.invalid || (uid.dirty || uid.touched) && formSubmitted">
        <div *ngIf="uid.errors?.required">
            {{allinoneService.input_err_msg}}
        </div>
    </div>

</div>

<div mat-dialog-actions align="end">
    <app-button-loader (click)="onConfirm()" [button_text]="'Add'" [load_button_text]="'Adding'"
        [isLoading]="loading"></app-button-loader>
</div>