import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-recruitments',
  templateUrl: './recruitments.component.html',
  styleUrls: ['./recruitments.component.scss'],
})
export class RecruitmentsComponent implements OnInit {
  isHRAdmin: boolean = false;
  gettingRequisitionCount: boolean = false;
  menus: any[] = [
    {
      name: 'Recruitment',
      list: [
        {
          icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/applicants.png',
          name: 'Applicants',
          path: '/hxm/recruitment/applicants',
          open: '1',
          disabled: true,
        },
        {
          icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/requisitions.png',
          name: 'Requsitions',
          // path: '/hxm/recruitment/requisitions',
          path: '/teams/recruitment/requisitions',
          open: '1',
          disabled: false,
        }
      ],
    },
    {
      name: 'Settings',
      list: [{
        icon: 'https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/reasons.png',
        name: 'Reasons',
        // path: '/hxm/recruitment/reasons',
        path: '/teams/recruitment/reasons',
        open: '1',
        disabled: false,
      },]
    }
  ];

  requisitionCount: number = 0;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.isHRAdmin = this.allinoneService.isAdminOf('hr');
  }

  ngOnInit(): void {
    if (this.allinoneService.isAdminOf('org')) {
      // this.getApprovalCount();
      this.getRequisitionCount();
    }
  }

  getRequisitionCount() {
    this.gettingRequisitionCount = true;
    var data = {
      orgid: this.allinoneService.selectedOrg,
      role: '700',
    };
    this.kunyekService.getRequisitionCount(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.requisitionCount = res.requestcounts;
        this.gettingRequisitionCount = false;
      }
    });
  }

  goTo(item: any){
    if(item.disabled){
      this.messageService.openSnackBar('This feature is not available yet.', 'warn');
    }
    else{
      this.router.navigateByUrl(item.path);
    }
  }
}
