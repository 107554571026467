<!-- <perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true"
    fxFlex="auto" #perfectScroll> -->

<div class="container-fluid" [ngClass]="{'scroll':checkModeal}">
    <div class="content">
        <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="checkModeal">
            <div class="label title" *ngIf="!customerAdd" style="font-size: 17px;">Customers</div>
            <div class="label title" *ngIf="customerAdd" style="font-size: 17px;">Add Customer</div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close (click)="close()">
                        <mat-icon>close</mat-icon>
                    </button>
            </div>
        </div>

        <div>
            <app-crm-breadcrumb s_breadcrumb="Pipelines">
            </app-crm-breadcrumb>



            <div class="row">
                <div class="mb-2 col-md-3">
                    <label for="stage" class="form-label">Stage</label>
                    <ng-select class="role-select" [items]="stages" bindLabel="name" placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="stageID" (change)="stageChange($event)">

                    </ng-select>
                    <!-- <select class="form-select" aria-label="Select stage" id="stage" name="stage" [(ngModel)]="crmForm.stageid" [disabled]="gettingStages">
                            <option selected value=""> All </option>
                            <option *ngFor="let item of stages" value="{{item.stageid}}">{{item.name}}</option>
                        </select> -->
                    <mat-progress-bar mode="indeterminate" *ngIf="gettingStages"></mat-progress-bar>
                </div>
                <div class="mb-2 col-md-3">
                    <label for="status" class="form-label">Status</label>
                    <!-- <select class="form-select" aria-label="Select statua" id="status" name="status" [(ngModel)]="crmForm.status">
                            <option selected value=""> All </option>
                            <option *ngFor="let item of dealStatus" value="{{item.key}}">{{item.value}}</option>
                        </select> -->
                    <ng-select class="role-select" [items]="dealStatus" bindLabel="value" placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="statusID" (change)="changeStatus($event)">

                    </ng-select>
                </div>
                <div class="col-md-3 mb-2">
                    <label for="startdate" class="form-label">Start Date</label>
                    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="crmForm.startdate" required #startdate="ngModel">
                </div>
                <div class="col-md-3 mb-2">
                    <label for="enddate" class="form-label">End Date</label>
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="crmForm.enddate" required #enddate="ngModel">
                </div>
            </div>


            <div class=" d-flex flex-wrap " *ngIf="!checkModeal">
                <div class="w-100 d-flex flex-wrap ">


                    <div class=" w-100 d-flex flex-wrap me-auto ">
                        <div class="me-3 mb-2">
                            <button class="btn btn-custom" (click)="openTotalamountForm()" title="Totalamount" [disabled]="!crmForm.orgid">
                                    Total Amount
                                </button>
                        </div>
                        <div class="input-group me-2 mb-2" style="width: 300px !important;" *ngIf="!isMobileView && crmForm.type != '005'">
                            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;"
                                (keyup.enter)="onSearch()">
                            <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                                    <mat-icon class="c-icon">close</mat-icon>
                                </button>

                            <button class="input-group-text btn btn-custom" *ngIf="crmForm.type == '002'" id="btnGroupAddon2" (click)="onSearch()">
                                    <mat-icon class="c-icon">search</mat-icon>
                                </button>

                        </div>
                        <div class="dropdown mb-2 me-2">
                            <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" matTooltip="Page Size">
                                    <mat-icon>filter_list</mat-icon>
                                </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div c style="text-align: center;">Page size</div>
                                </li>
                                <li>
                                    <hr class="dropdown-divider">
                                </li>
                                <li class="dropdown-item" *ngFor="let page of pg">
                                    <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==itemPerPage">
                                    <label for="pg{{page.name}}" class="col-10 p-1">
                                            {{page.name}}
                                        </label>
                                </li>
                            </ul>
                        </div>

                        <!-- me-2 -->
                        <div class=" mb-2 ">
                            <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                                    <mat-icon>refresh</mat-icon>
                                </button>
                        </div>


                        <div class="line mb-2"></div>


                        <div class="mb-2">
                            <button type="button" class="btn btn-custom" (click)="downloadSheetPipeline()" matTooltip="Export Pipeline To Excel">
                                    <img class="excel-icon" src="../../../assets/images/excel.png">
                                    Export
                                </button>

                        </div>
                        <div class="line mb-2"></div>
                        <div class="d-flex align-items-center me-auto mb-2" *ngIf="crmForm.type != '005'">
                            <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{total}}</span>
                        </div>

                        <div *ngIf="!gettingData || crmForm.type != '005' && !checkModeal" class="d-flex align-items-center mb-2   ">
                            <pagination-template *ngIf="data.length > 0 && crmForm.type != '005'" #p="paginationApi" (pageChange)="pageChange($event)">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
                                        <span class="me-1">&laquo;</span>&nbsp;Prev
                                    </div>
                                    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                        <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                                <span>{{ page.label }}</span>
                                        </span>
                                        <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                            <span>{{ page.label }}</span>
                                        </div>
                                    </div>
                                    <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
                                        Next&nbsp;<span class="ms-1">&raquo;</span>
                                    </div>
                                </div>
                            </pagination-template>
                        </div>

                        <div>

                        </div>
                    </div>
                </div>

            </div>


            <div>
                <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                    <table class="table table-responsive table-borderless">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="pipelinename">Customer
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="pipelinetargetdate">
                                    Target Date
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="pipelineclosingdate">
                                    Closing Date
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="stagename">Stage
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="status">
                                    Status
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="pipelineamount">Amount
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="pipelinecurrency">
                                    Currency
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="pipelinecreatedby">
                                    Created By
                                </td>
                                <td scope="col" class="d-flex justify-content-center">Action</td>
                            </tr>
                        </thead>
                        <tbody *ngIf="gettingData">
                            <tr>
                                <td colspan="9" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="gettingData== false">
                            <tr *ngIf="data.length == 0">
                                <td colspan="9" class="nodata" *ngIf="!pipelineview">Empty</td>
                                <td style="color: red;font-size: 14px;" colspan="9" class="nodata" *ngIf="pipelineview">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}
                                </td>
                            </tr>
                            <tr *ngFor="let item of data |filter:searchText:20| paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index">
                                <td class="td-data td-edit gocus">
                                    <!-- (click)="goPipeline(item)" -->
                                    <span [title]="item.customername">{{item.customername}}</span>
                                </td>
                                <td class="td-data">
                                    <span [title]="allinoneService.formatDBToShow(item.targetdate)">{{allinoneService.formatDBToShow(item.targetdate)}}</span>
                                </td>
                                <td class="td-data">


                                    <span [title]="item.closingdate ? allinoneService.formatDBToShow(item.closingdate) : ''">{{item.closingdate
                                            ? allinoneService.formatDBToShow(item.closingdate) : ''}}</span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.amount">{{item.stagename}}</span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.amount" class="open-status" [ngClass]="{'closed-status-deal': item.status != '001' }">{{item.status == '001'
                                            ?
                                            'Open' : item.status == '002' ? 'Closed - Won' : 'Closed - Lost'}}</span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.amount">{{item.amount}}</span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.currencyname">{{item.currencyname}}</span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.createdbyname ? item.createdbyname : item.createdby">{{item.createdbyname
                                            ? item.createdbyname : item.createdby}}</span>
                                </td>
                                <td class="d-flex justify-content-center">
                                    <button class="btn btn-outline-info me-2" (click)="viewDeal(item)" title="View Deal Info">
                                            <i class='fa fa-info'></i>
                                        </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div *ngIf="!gettingData  && !checkModeal ">
                <pagination-template *ngIf="data.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
                    <div class="d-flex align-items-center justify-content-center">
                        <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
                            <span class="me-1">&laquo;</span>&nbsp;Prev
                        </div>
                        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    <span>{{ page.label }}</span>
                            </span>
                            <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
                            Next&nbsp;<span class="ms-1">&raquo;</span>
                        </div>
                    </div>
                </pagination-template>

            </div>
        </div>






        <!-- <div *ngIf="summaryCheck"> -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- <app-crm-summary [checkOrg]="checkOrgB" [orgid]="crmForm.orgid" (crmcancel)="cancel()" (statussummary)="summaryBackStatus($event)">
                </app-crm-summary> -->
        <!-- app-crm-summary -->
        <!-- </div> -->
    </div>


</div>
<!-- </perfect-scrollbar> -->