<app-loading *ngIf="saveLoading"></app-loading>
<div class="group">
  <!-- <perfect-scrollbar class = "group" [config]="config"
    [scrollIndicators]="true"> -->
  <div class="group-image" *ngIf="(orgid && setting == '003') || orgid == ''">
    <div class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="3">
      <div class="conversation-summary-avatar-group-member-wrapper">
        <img class="conversation-summary-avatar-group-member-wrapper" [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="allinoneService.getProfileImage()">
      </div>

      <div class="conversation-summary-avatar-group-member-wrapper">
        <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
          [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="membersList[0].imagename ? membersList[0].imagename : '../../../assets/images/profile.png' ">
      </div>

      <div class="conversation-summary-avatar-group-member-wrapper">
        <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
          [defaultImage]="'../../../assets/images/bg.jpg'"
          [lazyLoad]="membersList[1].imagename ? membersList[1].imagename : '../../../assets/images/profile.png' ">
      </div>
    </div>
  </div>
  <div class="group-image" *ngIf="orgid && setting != '003'">
    <div class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="3">
      <div class="conversation-summary-avatar-group-member-wrapper">
        <img class="conversation-summary-avatar-group-member-wrapper" [src]="'../../../assets/images/profile.png'">
      </div>

      <div class="conversation-summary-avatar-group-member-wrapper">
        <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
          [src]="'../../../assets/images/profile.png'">
      </div>

      <div class="conversation-summary-avatar-group-member-wrapper">
        <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
          [src]="'../../../assets/images/profile.png'">
      </div>
    </div>
  </div>
  <div class="group-info">

    <input type="text" class="form-control" id="groupname" name="groupname" placeholder="Group Name"
      [(ngModel)]="giForm.groupname" required #gname="ngModel"
      [ngClass]="{ 'is-invalid': giFormSubmitted && gname.invalid }" (keyup.enter)="submitGroupinfo()">

    <div class="invalid-feedback" *ngIf="gname.invalid || (gname.dirty || gname.touched) && giFormSubmitted">
      <div *ngIf="gname.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="mt-3 member">
    <strong class="member-title"><span *ngIf="orgid && setting != '003'">{{orgname}}'s&nbsp;</span>{{(orgid && setting == '003') || orgid == '' ? 'Members' :  setting == '002' ? 'Member Types' : 'All Members'}} </strong>
    <div class="list-group" style="margin-top: 10px;" *ngIf="(orgid && setting == '003') || orgid == ''">
      <div class="list-group-item" *ngFor="let member of membersList">
        <div class="profile-item">
          <img class="chat-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
            [lazyLoad]="member.imagename ? member.imagename : '../../../assets/images/profile.png' ">
          <div class="profile-container">
            {{member.username}}
            <span>{{member.userid}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <mat-chip-list *ngIf="setting == '002'">
        <mat-chip *ngFor="let type of memberType">{{type.name}}</mat-chip>
      </mat-chip-list> 
    </div>
  </div>
</div>
<button mat-mini-fab color="primary" class="fab-btn" (click)="submitGroupinfo()">
  <mat-icon>check</mat-icon>
</button>