import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {
  productAddOrNot: boolean = false;
  saveLoading: boolean = false;
  productFormSubmitted: boolean = false;
  getttingProducts: boolean = false;
  isEdit: boolean = false;
  organizations: any = []

  form: any = ''
  searchText1 = '';
  productForm = {
    name: '',
    skucode: '',
    price: '',
    qty: '',
  };
  productList = [];
  savedProduct: any = [];
  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<ProductsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = data.form
    if (this.form == 'lead') {
      this.savedProduct = data.products;
    }
    else if (this.form == 'deal') {
      this.searchText1 = data.editData.skucode;
      this.productForm = {
        name: data.editData.name,
        skucode: data.editData.skucode,
        price: data.editData.price,
        qty: data.editData.qty,
      };
    }
  }

  ngOnInit(): void {
    this.getProducts();
    if (this.savedProduct.length == 0 || this.form == 'deal') {
      this.productAddOrNot = true;
    }
  }
  getProducts() {
    this.getttingProducts = true;
    this.organizations = this.allinoneService.orgs;

    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid
    };
    this.kunyekService.getProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productList = res.list;

          this.getttingProducts = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getttingProducts = false;
      }
    );
  }
  editProducts(product: any) {
    console.log(product);
    this.productAddOrNot = true;
    this.productForm.name = product.name;
    this.productForm.price = product.price;
    this.productForm.qty = product.qty;
    this.productForm.skucode = product.skucode;
    this.searchText1 = product.skucode;
    this.isEdit = true;
  }
  submitProduct() {
    this.productFormSubmitted = true;
    this.productForm.skucode = this.searchText1;
    if (
      this.productForm.name == '' ||
      this.productForm.price == '' ||
      this.productForm.skucode == '' ||
      this.productForm.qty == ''
    ) {
      console.log(this.productForm);

      return;
    }

    if (this.form == 'deal') {
      this.dialogRef.close(this.productForm)
    }

    var index = this.savedProduct
      .map((x: any) => x.skucode)
      .indexOf(this.productForm.skucode);
    console.log(index);
    if (this.isEdit && index != -1) {
      this.savedProduct[index].name = this.productForm.name.toString();
      this.savedProduct[index].price = this.productForm.price.toString();

      this.savedProduct[index].qty = this.productForm.qty.toString();
      this.savedProduct[index].amount =
        parseInt(this.savedProduct[index].qty) *
        parseFloat(this.savedProduct[index].price);
    } else {
      if (index == -1) {
        this.savedProduct.push({
          id: this.productForm.skucode,
          skucode: this.productForm.skucode,

          name: this.productForm.name,
          price: this.productForm.price.toString(),
          qty: this.productForm.qty.toString(),
          amount:
            parseFloat(this.productForm.price) *
            parseInt(this.productForm.qty),
        });
      } else if (index != -1) {
        this.savedProduct[index].qty =
          parseInt(this.savedProduct[index].qty) +
          parseInt(this.productForm.qty);
        this.savedProduct[index].amount =
          parseInt(this.savedProduct[index].qty) *
          parseFloat(this.savedProduct[index].price);
      }
    }

    this.clean();
  }
  cancel() {
    if (this.form == 'deal') {
      this.dialogRef.close()
    }
    this.productAddOrNot = false;
    this.clean();
  }
  skucodeChange() {
    this.productForm = {
      name: '',
      price: '',
      qty: '',
      skucode: '',
    };
  }
  clean() {
    this.searchText1 = '';
    this.productAddOrNot = false;
    this.isEdit = false;
    this.productForm = {
      name: '',
      price: '',
      qty: '',
      skucode: '',
    };
    this.productFormSubmitted = false;
  }
  productChange(product: any) {
    this.productForm.name = product.name;
    // this.productForm.price = product.price;
    this.productForm.skucode = this.searchText1;
  }

  Close() {
    this.dialogRef.close({ datalist: this.savedProduct });
  }
  delete(index: any) {
    this.savedProduct.splice(index, 1)
  }
}
