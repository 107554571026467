import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getuserpofile',
  pure:false
})
export class GetuserpofilePipe implements PipeTransform {

  transform(userid: any, openedChat : any): any{
    // console.warn('Profile - ' + userid)
    var user: any;
    if (openedChat.type != "3") {
      user = openedChat.userlist.find((u: any) => u.userid == userid);
      if (!user) {
        user = { userid: "", username: "Unknown", imagename: "", usersyskey: "", position: "", department: "" }
      }
    }
    else {
      user = openedChat;
    }

    return user.username ? user.username : user.userid;
  }

}
