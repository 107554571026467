<div [ngClass]="{'customerservice':  customercare ,'customer': !customercare }">
    <!-- !customercare class="event-container"-->
    <perfect-scrollbar style="padding: 20px 20px 0px 20px;" [ngClass]="{'event-container': !customercare}" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <!-- advansearch -->
        <div class="label title" style="font-size: 17px;padding: 0px 0px 15px 0px;font-weight: 500;">Advanced Search
        </div>
        <div class="row">
            <div class="col-sm-12" *ngIf="!customercare">
                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Description</label>
                        <input type="text" aria-label="description" [(ngModel)]="description" matInput class="form-control" name="description" id="description" autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-check mb-3">

                    <input class="form-check-input" type="checkbox" [(ngModel)]="recrring" id="flexCheckDisabled1" (change)="checkRecurring($event)">
                    <label class="form-check-label" for="flexCheckDisabled1">
                        Recurring
                    </label>
                </div>
            </div>
            <!-- *ngIf="!customercare" -->
            <div class="col-sm-12" *ngIf="!customercare">
                <div class="form-check mb-3">

                    <input class="form-check-input" type="checkbox" [(ngModel)]="assignedtome" id="flexCheckDisabled" (change)="checkassigned($event)">
                    <label class="form-check-label" for="flexCheckDisabled">
                        Assigned To Me
                    </label>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="!customercare">
                <!--  *ngIf="!checkedBol && !customercare"-->
                <div class="mb-3 styled-select">
                    <label class="form-label">Assigned To</label>
                    <!-- [disabled]="checkedBol && !customercare"&& !customercare -->
                    <ng-select class="role-select" [items]="picdatalist" bindLabel="picname" placeholder="-" appendTo="body" (change)="picIDget($event)" [multiple]="false" [(ngModel)]="picID" [disabled]="assignedtome ">

                    </ng-select>
                    <mat-progress-bar mode="indeterminate" *ngIf="getPic"></mat-progress-bar>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="!customercare">
                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Detail</label>
                        <input type="text" aria-label="Detail" [(ngModel)]="detail" matInput class="form-control" name="Detail" id="Detail" autocomplete="off">
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="  mb-3">
                    <label class="form-label">Reported By</label>
                    <input type="text" aria-label="textt" [matAutocomplete]="automember" [(ngModel)]="reportedby" matInput class="form-control" name="reportedby" id="reportedby" placeholder="john@domain.com, +959********">
                    <mat-autocomplete #automember="matAutocomplete">
                        <mat-option *ngFor="let i of members | crmFilter : reportedby:searchNonea:assignedto:13" (onSelectionChange)="selectMember(i.username,i.userid)" (click)="selectMember(i.username,i.userid)">
                            {{i.username != '' ? i.username : ''}}&nbsp;{{i.userid}}
                        </mat-option>

                    </mat-autocomplete>
                </div>
            </div>
        </div>
    </perfect-scrollbar>
    <div mat-dialog-actions align="end" style="padding-right: 20px;">
        <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary me-2">
            Cancel
        </button>
        <button type="button" (click)="search()" class="btn btn-custom ">
            Search
        </button>
    </div>
</div>