import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AppRestrictionComponent } from 'src/app/admin/app-restriction/app-restriction.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-app-restriction-setup',
  templateUrl: './app-restriction-setup.component.html',
  styleUrls: ['./app-restriction-setup.component.scss'],
})
export class AppRestrictionSetupComponent implements OnInit {
  submitAppRestrictionSetupForm: boolean = false;
  appRestrictionSetupLoading: boolean = false;

  isEditing: boolean = false;

  appRestrictionType = [
    {
      name: 'Sign Out',
      id: '001',
    },
    {
      name: 'Update',
      id: '002',
    },
  ];

  orgList: any = [];

  osList = [
    {
      name: 'All',
      id: '003',
    },
    {
      name: 'Andriod',
      id: '001',
    },
    {
      name: 'iOS',
      id: '002',
    },
  ];

  constructor(
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    public dialogRef: MatDialogRef<AppRestrictionSetupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isEditing = data.isEditing;
    this.orgList = data.orgList;
    if (this.isEditing) {
      this.appRestrictionSetupForm = {
        restrictid: data.item.restrictid,
        typeid: data.item.version == '' ? '001' : '002',
        signoutUserid: (data.item.type = '001' ? data.item.userid : ''),
        signoutOrganization: (data.item.type = '002' ? data.item.userid : ''),
        forcedsignouttype: data.item.type,
        updateversion: data.item.version,
        updatelowerversion: !data.item.exactversion,
        forcedupdateos: data.item.os,
        updatetype: data.item.forcedupdate,
        updatemessage: data.item.message,
      };
    }
  }

  ngOnInit(): void {
    if (this.appRestrictionSetupForm.signoutOrganization) {
      this.appRestrictionSetupForm.signoutOrganization = this.orgList[0].orgid;
    }
  }

  appRestrictionSetupForm: any = {
    restrictid: '',
    typeid: '001',
    signoutUserid: '',
    signoutOrganization: '0000',
    forcedsignouttype: '001',
    updateversion: '',
    updatelowerversion: true,
    forcedupdateos: '003',
    updatetype: true,
    updatemessage: '',
  };

  submitAppRestrictionForm() {
    this.submitAppRestrictionSetupForm = true;

    if (this.appRestrictionSetupForm.typeid == '001') {
      if (this.appRestrictionSetupForm.forcedsignouttype == '001') {
        if (
          this.appRestrictionSetupForm.signoutUserid == '' ||
          this.appRestrictionSetupForm.signoutUserid == null
        ) {
          this.messageService.openSnackBar("User ID cann't blank.", 'warn');
          return;
        }
      }

      if (this.appRestrictionSetupForm.forcedsignouttype == '002') {
        if (
          this.appRestrictionSetupForm.signoutOrganization == '' ||
          this.appRestrictionSetupForm.signoutOrganization == null
        ) {
          this.messageService.openSnackBar(
            "Organization cann't blank.",
            'warn'
          );
          return;
        }
      }
    }

    if (this.appRestrictionSetupForm.typeid == '002') {
      if (
        this.appRestrictionSetupForm.updateversion == '' ||
        this.appRestrictionSetupForm.updateversion == null
      ) {
        this.messageService.openSnackBar("Version cann't blank.", 'warn');
        return;
      }
    }

    this.appRestrictionSetupLoading = true;
    this.dialogRef.disableClose = true;

    var data = {
      restrictid: this.appRestrictionSetupForm.restrictid,
      orgid:
        this.appRestrictionSetupForm.typeid == '001' &&
        this.appRestrictionSetupForm.forcedsignouttype == '002'
          ? this.appRestrictionSetupForm.signoutOrganization
          : '',
      version:
        this.appRestrictionSetupForm.typeid == '002'
          ? this.appRestrictionSetupForm.updateversion
          : '',
      //  "exactversion":this.appRestrictionSetupForm.typeid == '002' && this.appRestrictionSetupForm.updatelowerversion ? false : true,
      exactversion:
        this.appRestrictionSetupForm.typeid == '002'
          ? this.appRestrictionSetupForm.updatelowerversion
            ? false
            : true
          : '',
      os:
        this.appRestrictionSetupForm.typeid == '002'
          ? this.appRestrictionSetupForm.forcedupdateos
          : '',
      forcedupdate:
        this.appRestrictionSetupForm.typeid == '002'
          ? this.appRestrictionSetupForm.updatetype
          : '',
      restricttype:
        this.appRestrictionSetupForm.typeid == '001'
          ? this.appRestrictionSetupForm.forcedsignouttype
          : '',
      adduser:
        this.appRestrictionSetupForm.typeid == '001' &&
        this.appRestrictionSetupForm.forcedsignouttype == '001'
          ? this.allinoneService.checkUserId(this.appRestrictionSetupForm.signoutUserid)
          : '',
      message:
        this.appRestrictionSetupForm.typeid == '002'
          ? this.appRestrictionSetupForm.updatemessage
          : '',
    };

    console.log(data);

    var fun = this.isEditing
      ? this.kunyekService.updateAppRestriction(data)
      : this.kunyekService.addAppRestriction(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.appRestrictionSetupLoading = false;
          var orgname = '';
          var org = this.orgList.find(
            (x: any) =>
              x.orgid == this.appRestrictionSetupForm.signoutOrganization
          );
          if (org) {
            orgname = org.name;
          }

          var resData = {
            appid: res.appid,
            createdby: res.createdby,
            createddate: res.createddate,
            domainid: res.domainid,
            exactversion: res.exactversion,
            forcedupdate: res.forcedupdate,
            message: res.message,
            modifiedby: res.modifiedby,
            modifieddate: res.modifieddate,
            os: res.os,
            restrictid: res.restrictid,
            type: res.type,
            userid:
              this.appRestrictionSetupForm.typeid == '001' &&
              this.appRestrictionSetupForm.forcedsignouttype == '002'
                ? this.appRestrictionSetupForm.signoutOrganization
                : res.userid,
            orgname:
              this.appRestrictionSetupForm.typeid == '001' &&
              this.appRestrictionSetupForm.forcedsignouttype == '002'
                ? orgname
                : '',
            version: res.version,
          };
          this.dialogRef.close(resData);
        } else {
          this.dialogRef.disableClose = false;
          this.appRestrictionSetupLoading = false;
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.dialogRef.disableClose = false;
        this.appRestrictionSetupLoading = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );
  }
}
