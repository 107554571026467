import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  getNotiSubscription!: Subscription;
  sendNotiSubscription!: Subscription;

  isFromDomain: boolean = false;
  isMobileView: boolean = false;
  newNoti: boolean = false;
  notiFormSubmitted: boolean = false;
  isFocus: boolean = false;
  gettingNotifications = false;
  saveLoading: boolean = false;

  organizations: any[] = [];
  notifications: any[] = [];

  mobileViewWidth: number = 426;
  p: number = 1;
  itemPerPage: number = 100;
  searchText: string = '';
  orgName: string = '';

  notiForm = {
    domainid: this.allinoneService.getDomain().domainid,
    domain: this.allinoneService.getDomain().shortcode,
    orgid: '',
    title: '',
    description: '',
  };

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  constructor(
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.activatedRoute.data.subscribe((data) => {
      if (data.type == 'domain') {
        if (!this.allinoneService.isAdminOf('domain')) {
          this.router.navigateByUrl('/home');
        }
        this.isFromDomain = true;
      } else {
        if (!this.allinoneService.isAdminOf('org')) {
          this.router.navigateByUrl('/home');
        }
        this.organizations = this.allinoneService.orgs;
        this.notiForm.orgid = this.allinoneService.selectedOrg
          ? this.allinoneService.selectedOrg
          : this.organizations[0].orgid;
        this.orgName = this.organizations.find(
          (org) => org.orgid == this.notiForm.orgid
        ).name;
      }
    });
  }
  ngOnDestroy(): void {
    this.getNotiSubscription && this.getNotiSubscription.unsubscribe();
    this.sendNotiSubscription && this.sendNotiSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.notiForm.title = this.isFromDomain
      ? this.allinoneService.getDomain().description
      : this.orgName;
    this.getNotifications();
  }

  sortData(sort: Sort) {
    const data = this.notifications;
    if (!sort.active || sort.direction === '') {
      this.notifications = data;
      return;
    }

    this.notifications = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title':
          return this.allinoneService.compare(a.title, b.title, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'datetime':
          return this.allinoneService.compare(
            new Date(
              this.allinoneService.formatDBToShowInEdit(a.date, '/') +
                ' ' +
                a.time
            ),
            new Date(
              this.allinoneService.formatDBToShowInEdit(b.date, '/') +
                ' ' +
                b.time
            ),
            isAsc
          );
        case 'createdby':
          return this.allinoneService.compare(a.createdby, b.createdby, isAsc);
        default:
          return 0;
      }
    });
  }

  openNewNotiForm() {
    this.newNoti = true;
    this.notiForm.title = this.isFromDomain
      ? this.allinoneService.getDomain().description
      : this.orgName;
  }

  orgChanged(event: any) {
    this.notiForm.orgid = event.target.value;
    if (this.notiForm.orgid == '') {
      this.notifications = [];
      this.notiForm.title = '';
    } else {
      const temp = this.orgName;
      this.orgName = this.organizations.find(
        (org) => org.orgid == this.notiForm.orgid
      ).name;
      if (temp == this.notiForm.title) {
        this.notiForm.title = this.orgName;
      }
      this.allinoneService.selectedOrg = this.notiForm.orgid;
      this.allinoneService.setSelectedOrg(this.notiForm.orgid);
      this.getNotifications();
    }
  }

  getNotifications() {
    this.gettingNotifications = true;
    this.notifications = [];
    const data = {
      type: this.isFromDomain ? '001' : '002',
      id: this.isFromDomain ? this.notiForm.domainid : this.notiForm.orgid,
      domainid: this.notiForm.domainid,
      orgid: this.notiForm.orgid
    };
    this.getNotiSubscription = this.kunyekService
      .getNotifications(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.gettingNotifications = false;
            this.notifications = res.datalist;
            this.pg[3].count = this.notifications.length;
          } else {
            this.gettingNotifications = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.gettingNotifications = false;
        }
      );
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  refresh() {
    this.getNotifications();
  }

  cancel() {
    this.notiForm = {
      domainid: this.notiForm.domainid,
      domain: this.notiForm.domain,
      orgid: this.notiForm.orgid,
      title: '',
      description: '',
    };
    this.newNoti = false;
    this.notiFormSubmitted = false;
  }

  confirm() {
    this.notiFormSubmitted = true;
    if (this.notiForm.title == '' || this.notiForm.description == '') {
      return;
    }
    const name = this.isFromDomain
      ? this.allinoneService.getDomain().description
      : this.orgName;
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      minWidth: '300px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        cmessage:
          'Are you sure to send notifications to all members of ' + name + '?',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.send();
      }
    });
  }

  send() {
    this.saveLoading = true;
    const data = {
      type: this.isFromDomain ? '001' : '002',
      id: this.isFromDomain ? this.notiForm.domainid : this.notiForm.orgid,
      domain: this.notiForm.domain,
      title: this.notiForm.title,
      description: this.notiForm.description,
      domainid: this.notiForm.domainid,
      orgid: this.notiForm.orgid
    };

    this.sendNotiSubscription = this.kunyekService
      .sendNotifications(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.saveLoading = false;
            this.cancel();
            this.getNotifications();
            this.messageService.openSnackBar(
              'Notifications have been sent successfully.',
              'success'
            );
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
  }
}
