<div class="container-fluid" *ngIf="!isEditting">
    <div class="content">
        <app-hcm-breadcrumb s_breadcrumb="Pay Period"></app-hcm-breadcrumb>


        <div class="mt-4 mb-4">
            <button class="btn btn-custom" (click)="handleNewProid()">
                <i class="fa fa-plus me-1"></i> Period</button>
        </div>

        <div class="table-responsive">
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr scope="row">
                        <td scope="col">Description</td>
                        <td scope="col">Start Date</td>
                        <td scope="col">End Date</td>
                    </tr>
                </thead>
                <tbody *ngIf="isLoading">
                    <tr>
                        <td colspan="4" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!isLoading">
                    <ng-container *ngFor="let period of periods">
                        <tr style="cursor: pointer" (click)="handleEditData(period)">
                            <td class="py-2">{{ period.description }}</td>

                            <td class="py-2">{{ period.startdate }}</td>
                            <td class="py-2">{{ period.enddate }}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

    </div>
</div>


<div *ngIf="isEditting">
    <app-pay-period-form (closeForm)="closeEdit()" [editData]="editData"></app-pay-period-form>
</div>
