import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { OfficeChooseMembersComponent } from '../office-choose-members/office-choose-members.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-off-in-lieu-request',
  templateUrl: './off-in-lieu-request.component.html',
  styleUrls: ['./off-in-lieu-request.component.scss'],
})
export class OffInLieuRequestComponent implements OnInit {
  @Input() requestData: any;
  @Input() isEditing: boolean = false;
  @Input() orgid: string = '';
  @Input() userid: string = '';
  @Input() approverList: any = [];

  @Output() save = new EventEmitter<any>();

  dlDataList: any = [];
  dlList: any = [];

  // current date with format as yyyy-MM-dd
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  submitForm = {
    date: this.currentDate,
    startdate: this.currentDate,
    // enddate: '',
    starttime: '',
    endtime: '',
    remark: '',
    readonly: false,
  };

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.isEditing) {
      this.submitForm = {
        date: this.requestData.date,
        startdate: this.requestData.startdate,
        // enddate: this.requestData.enddate,
        starttime: this.requestData.starttime,
        endtime: this.requestData.endtime,
        remark: this.requestData.remark,
        readonly: this.requestData.readonly,
      };
    }
  }

  submitData() {
    if (this.submitForm.date == '') {
      return this.messageService.openSnackBar(
        'Please choose Request Date.',
        'warn'
      );
    } else if (
      this.submitForm.startdate == '' ||
      // this.submitForm.enddate == '' ||
      this.submitForm.starttime == '' ||
      this.submitForm.endtime == ''
    ) {
      return this.messageService.openSnackBar(
        'Please fill in overtime datetime.',
        'warn'
      );
    }

    if (this.submitForm.starttime > this.submitForm.endtime) {
      return this.messageService.openSnackBar(
        'Start time cannot be later than end time.',
        'warn'
      );
    }
    this.submitForm.remark = this.submitForm.remark.toString().trim();
    if(this.submitForm.remark === '' || this.submitForm.remark === '-' || this.submitForm.remark === '.'){
      return this.messageService.openSnackBar(
        'Please fill Off In Lieu Reason.',
        'warn'
      );
    }
    

    // date cannot be later than end date + 2 months
    const tempEndDate = new Date(this.submitForm.startdate);
    tempEndDate.setMonth(tempEndDate.getMonth() + 2);
    const tempEndDateStr = formatDate(
      tempEndDate,
      'yyyy-MM-dd',
      'en-US'
    ).toString();
    console.log(tempEndDateStr);
    if (this.submitForm.date > tempEndDateStr) {
      return this.messageService.openSnackBar(
        'Request Date cannot be later than 2 months after overtime date.',
        'warn'
      );
    }

    const emitData = {
      requestData: this.submitForm,
      approverList: this.approverList,
    };
    this.save.emit(emitData);
  }

  chooseUser() {
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.approverList,
        isLeaveOpening: false,
        orgid: this.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const tempApprovalList = dialogResult.data.filter(
          (data: { checked: boolean; userid: string }) => {
            return data.checked == true && data.userid != this.userid;
          }
        );
        const oldApproverList = JSON.parse(JSON.stringify(this.approverList));
        this.approverList = [];
        tempApprovalList.map((x: any) => {
          const temp = oldApproverList.find(
            (user: any) => user.userid == x.userid
          );
          this.approverList.push({
            userid: x.userid,
            employeeid: x.employeeid,
            username: x.username,
            from: temp ? temp.from : '',
            to: temp ? temp.to : '',
            checked: true,
          });
        });
      }
    });
  }
}
