<div class="container-fluid">
  <div class="content">
    <div class="m-4">
      <div class="me-auto">
        <!-- <app-kmember-breadcrumb [s_breadcrumb]="'Special'"></app-kmember-breadcrumb> -->
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

      </div>
      <div class="d-flex gap-2 mb-2">
        <button (click)="closeForm()" type="button" class="btn btn-custom">Cancel</button>
        <app-button-loader (click)="onSave()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="isSaving">
        </app-button-loader>
        <!-- <button type="button" class="btn btn-custom">Submit</button> -->
      </div>
      <div class="card">
        <div class="card-body m-2">
          <form [formGroup]="swimForm">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="name" class="form-label">အမည် <font color="red">*</font></label>
                    <input type="text" id="name" class="form-control" name="name" formControlName="name"
                      [ngClass]="{ 'is-invalid': name?.invalid && saved}">
                    <div *ngIf="name?.invalid && (name?.dirty || name?.touched)" class="text-danger">
                      {{ requiredMessage }}
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="fatherName" class="form-label">အဖအမည်</label>
                    <input type="text" id="fatherName" class="form-control" name="fathername"
                      formControlName="fathername">
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="dateOfBirth" class="form-label">မွေးသက္ကရာဇ် <font color="red">*</font></label>
                    <my-date-picker [options]="datePickerOptions" formControlName="dateofbirth"
                      [ngClass]="{ 'is-invalid': dateofbirth?.invalid && saved }" placeholder="DD/MM/YYYY">
                    </my-date-picker>
                    <div *ngIf="dateofbirth?.invalid && (dateofbirth?.dirty || dateofbirth?.touched)"
                      class="text-danger">
                      {{ requiredMessage }}
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="height" class="form-label">အရပ်(cm) <font color="red">*</font></label>
                    <input type="text" id="height" class="form-control" name="height" formControlName="height"
                      [ngClass]="{ 'is-invalid': height?.invalid && saved}">
                    <div *ngIf="height?.invalid && (height?.dirty || height?.touched)" class="text-danger">
                      {{ requiredMessage }}
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="membershipNumber" class="form-label">အသင်းဝင်အမှတ် <font color="red">*</font></label>
                    <input type="text" id="membershipNumber" class="form-control" formControlName="membershipnumber"
                      [ngClass]="{ 'is-invalid': membershipnumber?.invalid && saved}">
                    <div *ngIf="membershipnumber?.invalid && (membershipnumber?.dirty || membershipnumber?.touched)"
                      class="text-danger">
                      {{ requiredMessage }}
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="contactNo" class="form-label">ဆက်သွယ်ရန်ဖုန်းနံပါတ် <font color="red">*</font></label>
                    <input type="text" id="contactNo" class="form-control" formControlName="contactno"
                      [ngClass]="{ 'is-invalid': contactno?.invalid && saved}">
                    <div *ngIf="contactno?.invalid && (contactno?.dirty || contactno?.touched)" class="text-danger">
                      <div *ngIf="contactno?.errors?.required">
                        {{ requiredMessage }}
                      </div>
                      <div *ngIf="contactno?.errors?.pattern">
                        Valid contact number is required
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="teacher" class="form-label">သင်ကြားမည့်ဆရာ <font color="red">*</font></label>
                    <input type="text" id="teacher" class="form-control" formControlName="teacher"
                      [ngClass]="{ 'is-invalid': teacher?.invalid && saved}">
                    <div *ngIf="teacher?.invalid && (teacher?.dirty || teacher?.touched)" class="text-danger">
                      {{ requiredMessage }}
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="studentNumber" class="form-label">သင်တန်းသားအမှတ် <font color="red">*</font></label>
                    <input type="text" id="studentNumber" class="form-control" formControlName="studentnumber"
                      [ngClass]="{ 'is-invalid': studentnumber?.invalid && saved}">
                    <div *ngIf="studentnumber?.invalid && (studentnumber?.dirty || studentnumber?.touched)"
                      class="text-danger">
                      {{ requiredMessage }}
                    </div>
                  </div>

                  <div *ngIf="batchList && batchList.length > 0" class="col-md-12 mb-3">
                    <label for="batch" class="form-label">
                      အပါတ်စဉ်<font color="red">*</font>
                    </label>
                    <select id="batch" class="form-select" formControlName="batch"
                      [ngClass]="{ 'is-invalid': batch?.invalid && saved }">
                      <option value="" disabled>Batch</option>
                      <option *ngFor="let item of batchList" [value]="item.syskey">
                        {{ item.batch_no }} ( {{ allInOneservice.formatDBToShow(item.start_date) }} - {{
                        allInOneservice.formatDBToShow(item.end_date) }} {{ item.time }} )
                      </option>
                    </select>
                    <div class="invalid-feedback" *ngIf="batch?.invalid || ((batch?.dirty || batch?.touched) && saved)">
                      <div *ngIf="batch?.errors?.required">{{ requiredMessage }}</div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="region" class="form-label">ပြည်နယ်/တိုင်းဒေသကြီး</label>
                    <div class="g-2">
                      <div class="row g-2">
                        <div class="col-4">
                          <select class="form-select" id="region" name="region" formControlName="region"
                            (change)="onRegionChange($event)">
                            <option value="" selected disabled>ပြည်နယ်/တိုင်းဒေသကြီး ရွေးပါ</option>
                            <option *ngFor="let regionlist of regionList" [value]="regionlist.region">
                              {{regionlist?.region}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="town" name="town" formControlName="town"
                            (change)="onTownChange($event)">
                            <option value="" selected disabled>မြို့ ရွေးပါ</option>
                            <option *ngFor="let townlist of filteredTowns" [value]="townlist.town">{{townlist?.town}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="ward" name="ward" formControlName="ward">
                            <option value="" selected disabled>ရပ်ကွက်/ရွာ ရွေးပါ</option>
                            <option *ngFor="let wardlist of filteredWards" [value]="wardlist.postcode">
                              {{wardlist?.ward}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="address" class="form-label">နေရပ်လိပ်စာ</label>
                    <textarea type="text" id="address" class="form-control" formControlName="address"></textarea>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-4 text-center mt-2">
                <!-- <div class="profile-img-container cursor-pointer" (click)="onProfileClick()">
                  <div class="uploadfilecontainer">
                    <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="profileUrl ? profileUrl : '../../../assets/images/uploadphoto.png'" />
                  </div>
                </div> -->
                <div class="profile-img-container">
                  <div style="position: relative">
                    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop>
                      <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="profileUrl ? profileUrl : '../../../assets/images/uploadphoto.png'" />
                      <input hidden type="file" #fileInput accept="image/*" (change)="onFileSelected($event)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>