import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  autoid: any = ""

  taskList : any[] = []
  typelist : any[] = []
  // typelist = ["New", "WIP", "TEST", "HI", "B"]

  currentCount = 20

  gettingTaskList : boolean = false
  gettingMoreTaskList : boolean = false

  constructor(
    private kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TasksComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.autoid = data.taskid
    this.typelist = data.list
  }  

  ngOnInit(): void {
    this.getTaskList()
  }

  onScrollDown(){
    if(this.taskList.length == this.currentCount){
      this.currentCount += 10
      this.getMoreTaskList()
    }
  }

  getTaskList(){
    this.gettingTaskList = true
    const data = {
      "domain":this.allinoneService.getDomain().shortcode,
      "tasksid":"",
      "startdate":"",
      "enddate":"",
      "description":"",
      "count":this.currentCount,
      "target":0,
      "actual":0,
      "type":"checkin"
    }
    this.kunyekService.getTaskList(data).subscribe((res: any) => {
      if(res.returncode == '300'){
        this.gettingTaskList = false
        this.taskList = res.list
        if(this.autoid != ""){
          const index = this.taskList.findIndex((x: any) => x.syskey == this.autoid)
          if(index > -1){
            this.taskList[index].selected = true
          }
        }
        this.taskList.map((item : any) => {
          const index = this.typelist.findIndex((x: any) => x.id == item.tasksid)
          item.status = this.typelist[index].name ? this.typelist[index].name : ""
        })
      }
      else{
        this.gettingTaskList = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
    }, 
    err => {
      this.gettingTaskList = false
    }
    )
  }


  getMoreTaskList(){
    this.gettingMoreTaskList = true
    const data = {
      "domain":this.allinoneService.getDomain().shortcode,
      "tasksid":"",
      "startdate":"",
      "enddate":"",
      "description":"",
      "count": this.currentCount,
      "target":0,
      "actual":0,
      "type":"checkin"
    }
    this.kunyekService.getTaskList(data).subscribe((res: any) => {
      if(res.returncode == '300'){
        for(let i = this.taskList.length ; i < res.list.length ; i++){
          if(this.autoid != ""){
            if(res.list[i].syskey == this.autoid){
              res.list[i].selected = true
            }
          }
          res.list[i].tasksid = parseInt(res.list[i].tasksid)
          this.taskList.push(res.list[i])
        }
        this.gettingMoreTaskList = false
      }
      else{
        this.gettingMoreTaskList = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
    }, 
    err => {
      this.gettingMoreTaskList = false
    }
    )
  }

  selectTask(task: any){
    const data = {
      'autoid' : task.autoid,
      'taskid' : task.syskey,
      'taskdescription' : task.description,
    }
    this.dialogRef.close(data)
  }

}
