// import { Injectable, Pipe, PipeTransform } from '@angular/core';

// @Injectable({
//   providedIn: 'root',
// })
// @Pipe({
//   name: 'memberFilter',
//   pure: false
// })
// export class MemberFilterPipe implements PipeTransform {
//   transform(
//     items: any[],
//     searchText: string,
//     searchPost: string,
//     searchDepartment: string,
//     searchDivision: string,
//     searchCostCenter: string,
//     searchStatus: string
//   ) {
//     if (!items) {
//       return [];
//     }
//     if (!searchText) {      
//       searchPost = searchPost.toLocaleLowerCase();
//       searchDepartment = searchDepartment.toLocaleLowerCase();
//       searchDivision = searchDivision.toLocaleLowerCase();
//       searchCostCenter = searchCostCenter.toLocaleLowerCase();
//       searchStatus = searchStatus.toLocaleLowerCase();
//       var returnItems: any[];

//       returnItems = items.filter((it) => {
//         return (
//           (searchPost == 'all'
//             ? true
//             : it.type.toString().toLocaleLowerCase() == searchPost) &&
//           (searchDepartment == 'all'
//             ? true
//             : it.department.toString().toLocaleLowerCase() ==
//             searchDepartment) &&
//           (searchDivision == 'all'
//             ? true
//             : it.division.toString().toLocaleLowerCase() ==
//             searchDivision) &&
//           (searchCostCenter == 'all'
//             ? true
//             : it.costcenter.toString().toLocaleLowerCase() ==
//             searchCostCenter) &&
//           (searchStatus == 'all'
//             ? true
//             : it.transactiontypestatus?.toString().toLocaleLowerCase() ==
//             searchStatus)
//         );
//       });
//       return returnItems;
//     } else {
//       searchText = searchText.toLocaleLowerCase();
//       searchPost = searchPost.toLocaleLowerCase();
//       searchDepartment = searchDepartment.toLocaleLowerCase();
//       searchDivision = searchDivision.toLocaleLowerCase();
//       searchCostCenter = searchCostCenter.toLocaleLowerCase();
//       searchStatus = searchStatus.toLocaleLowerCase();
//       var returnItems: any[];

//       returnItems = items.filter((it) => {
//         return (
//           (it.username.toString().toLocaleLowerCase().includes(searchText) ||
//             it.userid.toString().toLocaleLowerCase().includes(searchText) ||
//             it.employeeid.toString().toLocaleLowerCase().includes(searchText) ||
//             it.division?.toString().toLocaleLowerCase().includes(searchText)) &&
//           (searchPost == 'all'
//             ? true
//             : it.type?.toString().toLocaleLowerCase() == searchPost) &&
//           (searchDepartment == 'all'
//             ? true
//             : it.department?.toString().toLocaleLowerCase() ==
//             searchDepartment) &&
//           (searchDivision == 'all'
//             ? true
//             : it.division?.toString().toLocaleLowerCase() ==
//             searchDivision) &&
//           (searchCostCenter == 'all'
//             ? true
//             : it.costcenter?.toString().toLocaleLowerCase() ==
//             searchCostCenter) &&
//           (searchStatus == 'all'
//             ? true
//             : it.transactiontypestatus?.toString().toLocaleLowerCase() ==
//             searchStatus)
//         );
//       });
//       return returnItems;
//     }
//   }
// }


import { formatDate, DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'memberFilter',
  pure: false,
})
export class MemberFilterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(
    items: any[],
    searchText: string,
    searchPost: string,
    searchDepartment: string,
    searchDivision: string,
    searchCostCenter: string,
    searchStatus: string,
    searchBranch: string,
    searchPaylevel: string,
    searchEmploymentType: string,
    searchTransactionType: string,
    searchTeamid: string,
    searchSection: string,
    searchSubDivision: string,
    firstJoineddate: string,
    secondJoineddate: string,
    joineddateopt: string,
    firstEffectivedate: string,
    secondEffectivedate: string,
    effectivedateopt: string,
  ) {
    if (!items) {
      return [];
    }

    searchText = searchText?.toLocaleLowerCase();
    searchPost = searchPost?.toLocaleLowerCase();
    searchDepartment = searchDepartment?.toLocaleLowerCase();
    searchDivision = searchDivision?.toLocaleLowerCase();
    searchCostCenter = searchCostCenter?.toLocaleLowerCase();
    searchStatus = searchStatus?.toLocaleLowerCase();
    searchBranch = searchBranch?.toLocaleLowerCase();
    searchPaylevel = searchPaylevel?.toLocaleLowerCase();
    searchEmploymentType = searchEmploymentType?.toLocaleLowerCase();
    searchTransactionType = searchTransactionType?.toLocaleLowerCase();
    searchTeamid = searchTeamid?.toLocaleLowerCase();
    searchSection = searchSection?.toLocaleLowerCase();
    searchSubDivision = searchSubDivision?.toLocaleLowerCase();

    var todayDate = formatDate(new Date(), 'yyyyMMdd', 'en-US');
    const parsedTodayDate = parseInt(todayDate, 10);

    return items.filter((it) => {
      // Parse effective date to a comparable format (assuming the date is in YYYYMMDD format)
      const itemJoinedDate  = it.joineddate ? parseInt(it.joineddate, 10) : null;
      const itemEffectiveDate = it.effectivedate ? parseInt(it.effectivedate, 10) : null;

      const formatISODate = (dateString: string | undefined): number | null => { //Same as previous answer
        if (!dateString) return null;

        try {
          const date = new Date(dateString);
          const formattedDate = this.datePipe.transform(date, 'yyyyMMdd');
          return formattedDate ? parseInt(formattedDate, 10) : null;
        } catch (error) {
          console.error("Error parsing date:", dateString, error);
          return null;
        }
      };


      // Date Filters
      let matchesJoinedDate = true;
      let matchesEffectiveDate = true;

      const parsedFirstJoinedDate = formatISODate(firstJoineddate);
      const parsedSecondJoinedDate = secondJoineddate ? formatISODate(secondJoineddate) : null;
      const parsedFirstEffectiveDate = formatISODate(firstEffectivedate);
      const parsedSecondEffectiveDate = secondEffectivedate ? formatISODate(secondEffectivedate) : null;

      // Handle joined date filtering
      if (joineddateopt  && parsedFirstJoinedDate !== null) {
        switch (joineddateopt) {
          case '1':
            matchesJoinedDate = itemJoinedDate === parsedFirstJoinedDate;
            break;
          case '2':
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate > parsedFirstJoinedDate;
            break;
          case '3':
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate < parsedFirstJoinedDate;
            break;
          case '4':
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate >= parsedFirstJoinedDate;
            break;
          case '5':
            matchesJoinedDate = itemJoinedDate !== null && itemJoinedDate <= parsedFirstJoinedDate;
            break;
          case '6':
            if (parsedSecondJoinedDate) {
              matchesJoinedDate =
                itemJoinedDate !== null &&
                itemJoinedDate >= parsedFirstJoinedDate &&
                itemJoinedDate <= parsedSecondJoinedDate;
            }
            break;
        }
      }

      // Handle effective date filtering
      if (effectivedateopt  && parsedFirstEffectiveDate !== null) {
        switch (effectivedateopt) {
          case '1':
            matchesEffectiveDate = itemEffectiveDate === parsedFirstEffectiveDate;
            break;
          case '2':
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate > parsedFirstEffectiveDate;
            break;
          case '3':
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate < parsedFirstEffectiveDate;
            break;
          case '4':
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate >= parsedFirstEffectiveDate;
            break;
          case '5':
            matchesEffectiveDate = itemEffectiveDate !== null && itemEffectiveDate >= parsedFirstEffectiveDate;
            break;
          case '6':
            if (parsedSecondEffectiveDate) {
              matchesEffectiveDate =
                itemEffectiveDate !== null &&
                itemEffectiveDate >= parsedFirstEffectiveDate &&
                itemEffectiveDate <= parsedSecondEffectiveDate;
            }
            break;
        }
      }

      const matchesText =
        !searchText ||
        it.username?.toLocaleLowerCase().includes(searchText) ||
        it.userid?.toLocaleLowerCase().includes(searchText) ||
        it.newuserid?.toLocaleLowerCase().includes(searchText) ||
        it.employeeid?.toLocaleLowerCase().includes(searchText) ||
        it.division?.toLocaleLowerCase().includes(searchText);

      const matchesPost = searchPost === 'all' || it.type?.toLocaleLowerCase() === searchPost;
      const matchesDepartment = searchDepartment === 'all' || it.department?.toLocaleLowerCase() === searchDepartment;
      const matchesDivision = searchDivision === 'all' || it.division?.toLocaleLowerCase() === searchDivision;
      const matchesCostCenter = searchCostCenter === 'all' || it.costcenter?.toLocaleLowerCase() === searchCostCenter;
      const matchesBranch = searchBranch === 'all' || it.post?.toLocaleLowerCase() === searchBranch;
      const matchesPaylevel = searchPaylevel === 'all' || it.paylevel?.toLocaleLowerCase() === searchPaylevel;
      const matchesEmploymentType = searchEmploymentType === 'all' || it.employmenttypename?.toLocaleLowerCase() === searchEmploymentType;
      const matchesTransactionType = searchTransactionType === 'all' || it.transactiontypename?.toLocaleLowerCase() === searchTransactionType;
      const matchesTeamid = searchTeamid === 'all' || it.teamid?.toLocaleLowerCase() === searchTeamid;
      const matchesSection = searchSection === 'all' || it.section?.toLocaleLowerCase() === searchSection;
      const matchesSubDivision = searchSubDivision === 'all' || it.subdivision?.toLocaleLowerCase() === searchSubDivision;

      let matchesStatus = true;
      if (searchStatus === 'all') {
        matchesStatus = true;
      } else if (searchStatus === '0') {
        matchesStatus = it.transactiontypestatus === '1' && itemEffectiveDate! >= parsedTodayDate || it.transactiontypestatus?.toString().toLocaleLowerCase() === searchStatus;
      } else if (searchStatus === '1') {
        matchesStatus = it.transactiontypestatus === '1' && itemEffectiveDate! < parsedTodayDate;
      } else {
        matchesStatus = it.transactiontypestatus?.toString().toLocaleLowerCase() === searchStatus;
      }

      return matchesText && matchesPost && matchesDepartment && matchesDivision && matchesCostCenter && matchesStatus && matchesBranch && matchesPaylevel && matchesEmploymentType && matchesTransactionType && matchesTeamid && matchesSection && matchesSubDivision && matchesJoinedDate && matchesEffectiveDate;
    });
  }
}
