import { Component, OnInit, ViewChild } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// import * as fernet from 'fernet';
declare var fernet: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isError: boolean = false;
  isgettingUser: boolean = false;
  user: any = {
    imagename: "",
  }; //personal type

  selectedDomain: string = "";

  appname : string = this.allinoneService.appname;

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
  constructor(public allinoneService: AllInOneService,
    private kunyekService: KunyekService) { }

  ngOnInit(): void {
    console.log("portal dashboard")
    this.isgettingUser = true;
    this.getProfileDetial();

    const domain = this.allinoneService.getDomain();
    this.selectedDomain = domain.domainid;
  }

  getProfileDetial() {
    this.user = this.allinoneService.profile;
    setTimeout(() => {
      this.isgettingUser = false;
    }, 1000);
  }

  domainChanged(event: any) {
    this.allinoneService.setCookie("domain", JSON.stringify(this.allinoneService.domains.find((el: any) => el.domainid == event.target.value)) || "{}");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
