import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../shared/services/message.service';
import { CookieService } from 'ngx-cookie-service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { FormBuilder, Validators } from '@angular/forms';
import { KunyekService } from '../../shared/services/kunyek.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  otp: string = '';
  nextroute: string = '';
  verified: boolean = false;
  showOtpComponent: boolean = true;
  isStillVerifying: boolean = false;
  isStillResend: boolean = false;

  userid: string = '';

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
  };

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.verify();
    }
  }

  constructor(
    private router: Router,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allinOneService: AllInOneService,
    private route: ActivatedRoute,
    private formBuider: FormBuilder,
    private cookieService: CookieService
  ) {
    if (
      !this.allinOneService.getUserId() ||
      !this.allinOneService.getSession()
    ) {
      this.router.navigateByUrl('/login');
    }
    this.userid = this.allinOneService.getUserId();
  }

  ngOnInit(): void {
    this.nextroute = this.route.snapshot.queryParamMap.get('next') || '';
    if (this.allinOneService.getAtoken()) {
      this.router.navigateByUrl('/');
    }
  }
  onOtpChange(otp: any) {
    this.otp = otp;

    this.verify();
  }

  verify() {
    if (this.otp.length == 6 && !this.isStillVerifying) {
      this.isStillVerifying = true;
      this.kunyekService.verify(this.otp).subscribe(
        (res: any) => {
          console.log(`Res Data: ${JSON.stringify(res)}`);

          if (res.returncode == '300') {
            this.verified = true;


            if (res.olduserid) {
              this.allinOneService.setCookie(
                'kunyek_userid',
                this.allinOneService.checkUserId(res.olduserid)
              );
            }
            if (res.userid) {
              this.allinOneService.setCookie(
                'userid',
                this.allinOneService.checkUserId(res.userid)
              );
            }

            this.allinOneService.setCookie('atoken', res.atoken);
            this.allinOneService.setCookie('role', res.type);
            this.allinOneService.setCookie(
              'domain',
              JSON.stringify(res.domain) || 'undefined'
            );
            this.allinOneService.setCookie(
              'organization',
              JSON.stringify(res.organization) || 'undefined'
            );
            this.allinOneService.setCookie(
              'organizations',
              JSON.stringify(res.organizations) || 'undefined'
            );

            this.nextPage();
          } else {
            if (res.returncode == '202') {
              this.messageService.openSnackBar(
                'Invalid OTP. Please try again.',
                'warn'
              );
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.verified = false;
          }
          this.isStillVerifying = false;
        },
        (err) => {
          this.verified = false;
          this.isStillVerifying = false;
        }
      );
    }
  }

  resend() {
    if (!this.isStillResend) {
      this.isStillResend = true;
      const userid = this.allinOneService.getKunyekUserId();
      this.kunyekService.signin(userid).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinOneService.setSession('session', res.session);
            this.isStillResend = false;
          } else {
            this.isStillResend = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.isStillResend = false;
        }
      );
    }
  }

  nextPage() {
    if (this.nextroute != '') {
      const routes = this.nextroute.split(',');
      if (
        routes[2] &&
        routes[2] != '' &&
        routes[3] != '' &&
        routes[4] != '' &&
        routes[5] != ''
      ) {
        window.location.replace(
          `${routes[0]}/${routes[1]}?v=${routes[2]}&customerid=${routes[3]}&ticketno=${routes[4]}`
        );
      } else {
        window.location.replace(`${this.nextroute.replace(',', '/')}`);
        // window.location.replace(`${routes[0]}/${routes[1]}`);
      }
    } else {
      window.location.reload();
    }
  }

}
