<div class="d-flex align-items-center justify-content-between mb-3">
  <div>
    <h3 class="mb-0">Filter</h3>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="row">
  <div class="mb-3 col-md-6">
    <label for="startdate" class="form-label">Start Date</label>
    <input class="form-control" type="date" id="startdate" [(ngModel)]="searchForm.startdate" max="9999-12-31" name="startdate" />
  </div>
  <div class="mb-3 col-md-6">
    <label for="enddate" class="form-label">End Date</label>
    <input class="form-control" type="date" id="enddate" [(ngModel)]="searchForm.enddate" max="9999-12-31" name="enddate" />
  </div>
  <div class="mb-3 col-md-6">
    <label for="requesttype" class="form-label">Request Type</label>
    <select class="form-select" [(ngModel)]="searchForm.requesttype" id="requesttype" name="requesttype" (ngModelChange)="requestTypeChange()">
      <option value="">All</option>
      <option *ngFor="let req of requestTypeList" [value]="req.requesttypeid">{{req.requesttypename}}</option>
    </select>
  </div>
  <div class="mb-3 col-md-6" *ngIf="searchForm.requesttype == 'NCMLEIWHRNVIE' || searchForm.requesttype == 'WOEIEPPLMXNW'">
    <label for="type" class="form-label">{{searchForm.requesttype == 'NCMLEIWHRNVIE' ? 'Leave Type' : 'Claim Type'}}</label>
    <select class="form-select" [(ngModel)]="searchForm.requestsubtype" id="type" name="type">
      <option value="">All</option>
      <option *ngFor="let rsub of requestSubTypeList" [value]="rsub.requesttypeid">{{rsub.requesttypename}}</option>
    </select>
  </div>
  <div class="mb-3 col-md-6">
    <label for="type" class="form-label">Type</label>
    <select class="form-select" [(ngModel)]="searchForm.teammember" id="type" name="type">
      <option *ngFor="let type of teamMember" [value]="type.value">{{type.name}}</option>
    </select>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
    Cancel
  </button>
  <button type="button" (click)="search()" class="btn btn-custom ">
    Search
  </button>
</div>