import { data } from 'jquery';
import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-export-detail-list',
  templateUrl: './export-detail-list.component.html',
  styleUrls: ['./export-detail-list.component.scss']
})
export class ExportDetailListComponent implements OnInit {

  @HostListener('window:resize', ['$event'])

  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {

      this.isMobileView = true;

      this.isTabletView = false;

    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {

      this.isMobileView = false;

      this.isTabletView = true;

    } else {

      this.isMobileView = false;

      this.isTabletView = false;

    }

  }
  isTabletView: boolean = false;
  isMobileView: boolean = false;
  isView: boolean = false;
  gettingData: boolean = false;
  gettingStages: boolean = false;

  dataForEdit: any = ''
  mobileViewWidth: number = 426;
  viewWidth: number = 992;
  itemPerPage: number = 100;
  p: number = 1;
  callType: any = '';
  servcietypelist: any = [];
  statusLists: any = [];
  searchStatusLead: any = '';
  searchStatusCustomer: any = '';
  searchstagedeal: any = '';
  searchStatusdeal: any = '';
  priority: any = [];
  filterstartdate: any = '';
  filterenddate: any = '';
  organizationsList: any = [];
  gettingOrgList: boolean = false;
  orgtype: any = '';

  totalopencount: any = '0';
  totalclosedcount: any = '0';
  isFocus: boolean = false;
  searchText: any = "";
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];


  //Lead Status



  //Customer Status
  customerStatus = [
    {
      key: '001',
      value: 'Prospect',
    },
    {
      key: '002',
      value: 'Customer',
    },
  ];


  stages: any[] = [];

  formatToShow = 'yyyy-MM-dd';
  now = Date.now();
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() - 14);
  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.oneWeekBefore),
    this.formatToShow,
    'en-US'
  ).toString();



  organizations: any[] = [];
  detailListdata: any[] = [];
  @Input() width!: string;

  tabletViewWidth: any = 992;

  @Input() t_breadcrumb!: string;
  startdatetime: string = '';
  enddatetime: string = '';
  nextDisabled: boolean = false;
  lastPage: any = '';
  currentPage: any = 1;
  total: any = '0';
  sortby: any = '';
  pagnation: any = [];
  isAdmin: boolean = false;
  leadview: boolean = false;
  permission: any = [];

  leadcreate: boolean = false;
  leaddelete: boolean = false;
  leadedit: boolean = false;
  text1: any = "";
  text2: any = "";




  private subs = new SubSink();
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  // pagesize:any = 0;

  branchlist: any = [
    {
      "branchid": "1234",
      "branchname": "Nyi"
    }
  ];

  projectListdata: any = [
    {
      "projectid": "292",
      "name": "CRM"
    }
  ]
  productListdata: any = [
    {
      "productid": "9393",
      "name": "CRM Product"
    }
  ]

  deparmentListdata: any = [];
  modulelist: any = [];


  branchsearch: any = "";
  projectsearch: any = "";
  productsearch: any = "";
  deparmentsearch: any = "";
  divisionsearch: any = "";
  modulesearch: any = "";

  divisionlistdata: any = [];




  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private injector: Injector,

  ) {


    this.isAdmin = (this.allinoneService.crmOrgAdmin === 'true');

  }
  awaitDomain: any = ''
  // ngOnDestroy(): void {
  //   this.subs.unsubscribe();
  //   console.log(this.data);

  //   console.log(`SubSinkComponent  destroyed`);
  //   this.allinoneService.activeroute = "/sales";


  // }

  orgList: any = [];
  customercare: boolean = false;
  customernamecare: any = "";
  getCustomercaredata: any = [];
  customeridcare: any = "";

  orgid: any = "";
  async ngOnInit(): Promise<void> {
    this.allinoneService.activeroute = "/sales";
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {
      this.isMobileView = false;
      this.isTabletView = true;
    }
    this.permission = this.allinoneService.getCRMpermission();
    if (this.allinoneService.crmBlocked == 'true') {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      // if (this.isAdmin == false) {

      //   var type = this.permission.map((x: any) => x).indexOf('005');
      //   if (type == -1) {
      //     this.router.navigateByUrl('sales')
      //   }
      //   var view = this.permission.map((x: any) => x).indexOf('0051');
      //   var create = this.permission.map((x: any) => x).indexOf('0052');
      //   var edit = this.permission.map((x: any) => x).indexOf('0053');
      //   var delet = this.permission.map((x: any) => x).indexOf('0054');
      //   console.log("view" + view);
      //   console.log(this.permission)
      //   if (view == -1) {
      //     this.leadview = true;
      //   }
      //   if (create == -1) {
      //     this.leadcreate = true;
      //   }

      //   if (edit == -1) {
      //     this.leadedit = true;
      //   }

      //   if (delet == -1) {
      //     this.leaddelete = true;
      //   }

      // }
    }


    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;



    // this.getData();
    // this.getStages();


    this.startdatetime = formatDate(
      new Date(),
      this.formatToShow,
      'en-US'
    ).toString();
    var futureDate = new Date();

    futureDate.setDate(futureDate.getDate() + 7);
    this.enddatetime = formatDate(
      futureDate,
      this.formatToShow,
      'en-US'
    ).toString();
    console.log(this.enddatetime)
    if (this.width == null) {
      this.width = "auto";
    }


    this.getProduct();
    this.getProjects();
    this.getMemberPosition();
    this.getData();




  }






  sortData(sort: Sort) {
    console.log(this.detailListdata);
    const data = this.detailListdata;
    if (!sort.active || sort.direction === '') {
      this.detailListdata = data;
      return;
    }

    this.detailListdata = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        //Lead
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid.toLocaleLowerCase(),
            b.employeeid.toLocaleLowerCase(),
            isAsc
          );
        case 'username':
          return this.allinoneService.compare(a.username.toLocaleLowerCase(), b.username.toLocaleLowerCase(), isAsc);
        case 'rank':
          return this.allinoneService.compare(a.rank.toLocaleLowerCase(), b.rank.toLocaleLowerCase(), isAsc);
        case 'project':
          return this.allinoneService.compare(a.project.toLocaleLowerCase(), b.project.toLocaleLowerCase(), isAsc);
        case 'product':
          return this.allinoneService.compare(a.product.toLocaleLowerCase(), b.product.toLocaleLowerCase(), isAsc);
        case 'module':
          return this.allinoneService.compare(a.module.toLocaleLowerCase(), b.module.toLocaleLowerCase(), isAsc);
        case 'right':
          return this.allinoneService.compare(a.right.toLocaleLowerCase(), b.right.toLocaleLowerCase(), isAsc);
        case 'department':
          return this.allinoneService.compare(a.department.toLocaleLowerCase(), b.department.toLocaleLowerCase(), isAsc);
        case 'division':
          return this.allinoneService.compare(a.division.toLocaleLowerCase(), b.division.toLocaleLowerCase(), isAsc);
        case 'branch':
          return this.allinoneService.compare(a.branch.toLocaleLowerCase(), b.branch.toLocaleLowerCase(), isAsc);
        case 'employmenttype':
          return this.allinoneService.compare(a.employmenttype.toLocaleLowerCase(), b.employmenttype.toLocaleLowerCase(), isAsc);



        default:
          return 0;
      }
    });


  }





  getStages() {
    this.gettingStages = true;
    const data = {
      orgid: '',
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getPipelineStage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.stages = res.list;
          // this.crmForm.stageid = this.stages[0].stageid;
          this.gettingStages = false;
        } else {
          this.gettingStages = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStages = false;
      }
    );
  }

  getData() {
    this.gettingData = true;
    this.detailListdata = [];
    this.total = '0';
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      deaparment: this.deparmentid,
      division: this.divisionid,
      branch: this.branchid,
      startdate:this.allinoneService.formatDate(this.startdatetime),
      enddate:this.allinoneService.formatDate(this.enddatetime),
      projectid:this.projectid,
      productid:this.productid,
      moduleid:this.moduleid,
      status:this.statusid
    };
    this.subs.sink = this.kunyekService.getExportDetial(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.detailListdata = res.datalist;
          this.total = this.detailListdata.length;
          console.log(this.sortby);
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }





  onActivate() {
    window.scrollTo(0, 0);
  }




  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();

  }

  clear() {
    this.searchText = '';
    // this.searchTextService = '';
  }


  refreshgetData() {
    this.total = 0;
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    this.getData();
  }









  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.lastPage);
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;

        if (event > (this.detailListdata.length / this.itemPerPage)) {

          console.log("nnn>>>>>>getData")
          // this.getData();

        }
      }

    }
  }


  serviceBack() {

  }






  downloadSheetLead() {
    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Employee_' + time + '.xlsx';
    var start = formatDate(
      new Date(this.startdatetime),
      'dd/MM/yyyy',
      'en-US'
    ).toString();
    var end = formatDate(
      new Date(this.enddatetime),
      'dd/MM/yyyy',
      'en-US'
    ).toString();
    this.detailListdata.map((detail: any) => {
      data.push({

        "Employee ID": detail.employeeid,
        "Name": detail.username,
        "Post/Rank": detail.rank,
        "Project": detail.project,
        "Product": detail.product,
        "Module": detail.module,
        "Right": detail.right,
        "Start Date": start,
        "End Date": end,
        "Department": detail.department,
        "Division": detail.division,
        "Branch": detail.branch,
        "Employeement Type": detail.employmenttypename,
        "Transaction Type": detail.transactiontypename,
        "Remark": "",
      });
    });
    this.allinoneService.exportEcecl(data, name);
  }

  cancelFunction() {
    console.log(data);


  }

  loadProject: boolean = false;
  getProjects() {
    this.loadProject = true;


    return new Promise((resolve: any) => {
      this.projectListdata = [];
      const data = {
        orgid: this.allinoneService.selectedOrgCRM
          ? this.allinoneService.selectedOrgCRM
          : this.organizations[0].orgid,
        domainid: this.allinoneService.getDomain().domainid,

      };
      this.kunyekService.getAllCRMproject(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {

            this.projectListdata = res.data;
            this.loadProject = false;
          } else {
            this.loadProject = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.loadProject = false;
          resolve(false);
        }
      );
    });
  }
  loadProduct: boolean = false;
  getProduct() {

    this.loadProduct = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,

    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productListdata = res.list;
          this.loadProduct = false
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.loadProduct = false

        }
      },
      (err) => {
        this.loadProduct = false
      }
    );

  }



  getMemberPosition() {

    this.loadProduct = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,
    }
    this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.branchlist = res.branchlist;
          this.deparmentListdata = res.departmentlist;
          this.divisionlistdata = res.divisionlist;
          this.loadProduct = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.loadProduct = false;

        }
      },
      (err) => {
        this.loadProduct = false;
      }
    );

  }










  selectbranchname: any = 'branch';
  branchid: any = "";
  changeBranch(data: any) {
    if (data == 'branch') {
      this.branchid = '';
      this.selectbranchname = 'branch';
      this.getData();
    } else {
      console.log(data);
      this.branchid = data;
      this.selectbranchname = data;
      this.getData();
    }
  }



  projectname: any = 'project';
  projectid: any = "";
  changeproject(data: any) {
    console.log(data);
    if (data == 'project') {
      this.projectid = '';
      this.projectname = 'project';
      this.getData();
    } else {
      this.projectid = data.projectid;
      this.projectname = data.name;
      this.getData();
    }



  }

  productname: any = 'product';
  productid: any = "";
  modulecheck: boolean = false;
  changeproduct(data: any) {
    if (data == 'product') {
      this.productid = '';
      this.productname = 'product';
      this.modulecheck = false;
      this.getData();
    } else {

      if (data.modulelist.length > 0) {
        this.modulelist = data.modulelist;
        this.modulecheck = true;
      } else {
        this.modulelist = [];
        this.modulecheck = false;
      }
      this.productid = data.productid;
      this.productname = data.name;
      this.getData();
    }
  }


  modulename: any = 'module';
  moduleid: any = "";
  changemodule(data: any) {
    console.log(data);
    if (data == 'module') {
      this.moduleid = '';
      this.modulename = 'module';
      this.getData();
    } else {
      this.moduleid = data.moduleid;
      this.modulename = data.description;
      this.getData();
    }
  }

  deparmentname: any = 'deparment';
  deparmentid: any = "";
  changedeparment(data: any) {
    if (data == 'deparment') {
      this.deparmentid = '';
      this.deparmentname = 'deparment';
      this.getData();
    } else {
      this.deparmentid = data;
      this.deparmentname = data;
      this.getData();
    }
  }


  divisionname: any = 'division';
  divisionid: any = "";
  changedivision(data: any) {
    console.log(data);
    if (data == 'division') {
      this.divisionid = '';
      this.divisionname = 'division';
      this.getData();
    } else {
      this.divisionid = data;
      this.divisionname = data;
      this.getData();
    }
  }

  statusname: any = "All";
  statusid: any = "";
  changestatus(data: any) {
    if (data == 'all') {
      this.statusname = "All";
      this.statusid = "";
    } else if (data == 'active') {
      this.statusname = "Active";
      this.statusid = "001";
    } else if (data == 'inactive') {
      this.statusname = "Inactive";
      this.statusid = "002";
    } else if (data == 'blacklist') {
      this.statusname = "Blacklist";
      this.statusid = "003";
    }

    this.getData();

  }


  removeBranch() {
    this.branchsearch = '';
  }

  removeProJect() {
    this.projectsearch = '';

  }
  removeProduct() {

    this.productsearch = '';
  }

  removeDeparment() {

    this.deparmentsearch = '';
  }

  removeDivision() {

    this.divisionsearch = '';
  }

}


