import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transportation-breadcrumb',
  templateUrl: './transportation-breadcrumb.component.html',
  styleUrls: ['./transportation-breadcrumb.component.scss']
})
export class TransportationBreadcrumbComponent implements OnInit {


  @Input() s_breadcrumb!: string;
  @Input() t_breadcrumb!: string;
  @Input() f_breadcrumb!: string;
  @Input() fi_breadcrumb!: string;


  @Output('emit')
  change: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit1')
  change1: EventEmitter<any> = new EventEmitter<any>();


  @Output('emit2')
  change2: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  goToSecond() {
    if (this.t_breadcrumb) {
      this.change.emit(false);
    }
  }
  goToThird() {
    if (this.f_breadcrumb) {
      this.change1.emit(false);
    }
  }
  goToFourth() {
    if (this.fi_breadcrumb) {
      this.change2.emit(false);
    }
  }

}
