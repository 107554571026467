import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-accessories-request-history',
  templateUrl: './accessories-request-history.component.html',
  styleUrls: ['./accessories-request-history.component.scss']
})
export class AccessoriesRequestHistoryComponent implements OnInit {

  historyList : any = [];

  tableShowStatus = [
    {id : '001', name : 'Submit'},
    {id : '002', name : 'RO Approved'},
    {id : '003', name : 'HR Approved'},
    {id : '004', name : 'Approved'},
    {id : '005', name : 'Rejected'},
  ]

  getTableStatus(d:any) {
    var i = this.tableShowStatus.findIndex((x:any) => x.id == d);
    if(d > -1) {
      return this.tableShowStatus[i].name;
    } else {
      return '-';
    }
  }

  constructor(
    public allinoneService: AllInOneService,
    public dialogRef: MatDialogRef<AccessoriesRequestHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('d data');
    console.log(data);
    this.historyList = data.history;
   }

  ngOnInit(): void {
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
