import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  title?: string;
  message?: string;
  cc : boolean = false
  cmessage: string = "Are you sure?"
  isbold: boolean = false
  rulename: any


  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      if(data){
        this.isbold = data.isbold ? data.isbold : false
        this.rulename = data.rulename ? data.rulename : ''
        this.cc = data.cc ? data.cc : false
        this.cmessage = data.cmessage ? data.cmessage : "Are you sure?"
      }
    }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }


}
