import { DatePipe, formatDate, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  HostListener,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { SubSink } from 'subsink';
import { CrmServiceFilterbydateComponent } from '../../crm-service-filterbydate/crm-service-filterbydate.component';
import { MessageService } from 'src/app/shared/services/message.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-service-tickets',
  templateUrl: './service-tickets.component.html',
  styleUrls: ['./service-tickets.component.scss'],
})
export class Cs365ServiceTicketsComponent implements OnInit {
  isTabletView: boolean = false;
  isMobileView: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (
      window.innerWidth > this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isMobileView = false;
      this.isTabletView = true;
    } else {
      this.isMobileView = false;
      this.isTabletView = false;
    }
  }

  isView: boolean = false;
  gettingData: boolean = true;
  gettingServicData: boolean = false;
  leadAdd: boolean = false;
  customerAdd: boolean = false;
  dealAdd: boolean = false;
  serviceAdd: boolean = false;
  summaryCheck: boolean = false;
  historyshow: boolean = false;
  isFocus: boolean = false;
  gettingStages: boolean = false;

  dataForEdit: any = '';
  mobileViewWidth: number = 426;
  viewWidth: number = 992;
  itemPerPage: number = 100;
  p: number = 1;
  callType: any = '';
  servcietypelist: any = [];
  statusLists: any = [];
  searchStatusLead: any = '';
  searchStatusCustomer: any = '';
  searchstagedeal: any = '';
  searchStatusdeal: any = '';
  priority: any = [];
  filterstartdate: any = '';
  filterenddate: any = '';
  filterstartRdate: any = '';
  filterenddateshow: any = '';
  filterstartdateshow: any = '';
  checkdate: boolean = false;

  organizationsList: any = [];
  gettingOrgList: boolean = false;
  orgtype: any = '';

  totalopencount: any = '0';
  totalclosedcount: any = '0';

  gettingCustomers: boolean = false;
  selfcustomer: any = [];
  customers: any = [];
  text1: any = '';
  text2: any = '';
  checkcustomer: boolean = false;
  statussearch: any = '';
  servicesearch: any = '';
  statussearchcus: any = '';
  statusListsCustomer = [
    { code: '0010', name: 'New', check: true },
    { code: '0020', name: 'WIP', check: false },
    { code: '0060', name: 'UAT', check: false },
    { code: '0061', name: 'UAT - Failed', check: false },
    { code: '0062', name: 'UAT - Passed', check: false },
    { code: '0040', name: 'Completed', check: false },
    { code: '00900', name: 'Closed', check: false },
  ];

  netnew: any = '';

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  types: any[] = [
    {
      id: '001',
      name: 'Leads',
    },
    {
      id: '002',
      name: 'Customers',
    },
    {
      id: '003',
      name: 'Deals',
    },
    {
      id: '004',
      name: 'Pipelines',
    },
    {
      id: '005',
      name: 'Services',
    },
  ];

  //Lead Status
  leadStatus = [
    {
      key: '001',
      value: 'Closed',
    },
    {
      key: '002',
      value: 'Open',
    },
  ];

  //Customer Status
  customerStatus = [
    {
      key: '001',
      value: 'Prospect',
    },
    {
      key: '002',
      value: 'Customer',
    },
  ];

  //Deal Status
  dealStatus = [
    {
      key: '001',
      value: 'Open',
    },
    {
      key: '002',
      value: 'Closed - Won',
    },
    {
      key: '003',
      value: 'Closed - Lost',
    },
  ];

  //Business Type
  businessType = [
    {
      key: '001',
      value: 'Organization',
    },
    {
      key: '002',
      value: 'Person',
    },
  ];

  //Currency Type
  currencyType = [
    {
      key: '001',
      currency: 'Kyat',
      code: 'MMK',
      country: 'Myanmar',
    },
    {
      key: '025',
      currency: 'dollar',
      code: 'USD',
      country: 'United States',
    },
    {
      key: '002',
      currency: 'Singapore dollar',
      code: 'SGD',
      country: 'Singapore',
    },
    {
      key: '003',
      currency: 'đồngr',
      code: 'VND',
      country: 'Vietnam',
    },
    {
      key: '004',
      currency: 'UAE dirham',
      code: 'AED',
      country: 'United Arab Emirates',
    },
    {
      key: '005',
      currency: 'Turkish lira',
      code: 'TRY',
      country: 'Turkey',
    },
    {
      key: '006',
      currency: 'Baht',
      code: 'THB',
      country: 'Thailand',
    },
    {
      key: '007',
      currency: 'Rupiah',
      code: 'IDR',
      country: 'Indonesia',
    },
    {
      key: '008',
      currency: 'New Taiwan dollar',
      code: 'TWD',
      country: 'Taiwan',
    },
    {
      key: '009',
      currency: 'South Korean won',
      code: 'KRW',
      country: 'South Korea',
    },
    {
      key: '010',
      currency: 'Yen',
      code: 'JPY',
      country: 'Japan',
    },
    {
      key: '011',
      currency: 'Russian Ruble',
      code: 'RUB',
      country: 'Russia',
    },
    {
      key: '012',
      currency: 'Philippine peso',
      code: 'PHP',
      country: 'Philippines',
    },
    {
      key: '013',
      currency: 'Pakistani Rupee',
      code: 'PKR',
      country: 'Pakistan',
    },
    {
      key: '014',
      currency: 'Nepalese rupee',
      code: 'NPR',
      country: 'Nepal',
    },
    {
      key: '015',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '016',
      currency: 'Jordanian dinar',
      code: 'JOD',
      country: 'Jordan',
    },
    {
      key: '017',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '018',
      currency: 'Indian Rupee',
      code: 'INR',
      country: 'India',
    },
    {
      key: '019',
      currency: 'Hong Kong dollar',
      code: 'HKD',
      country: 'Hong Kong',
    },
    {
      key: '020',
      currency: 'Renminbi (yuan)',
      code: 'CNY',
      country: 'China',
    },
    {
      key: '021',
      currency: 'Cambodian riel',
      code: 'KHR',
      country: 'Cambodia',
    },
    {
      key: '022',
      currency: 'Brunei dollar',
      code: 'BND',
      country: 'Brunei',
    },
    {
      key: '023',
      currency: 'Bhutanese ngultrum',
      code: 'BTN',
      country: 'Bhutan',
    },
    {
      key: '024',
      currency: 'Taka',
      code: 'BDT',
      country: 'Bangladesh',
    },
  ];

  stages: any[] = [];
  stageID: any = [];
  statusID: any = [];

  formatToShow = this.allinoneService.crmdateformat;
  now = Date.now();
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() + 30);
  currentEndDate = formatDate(
    this.oneWeekBefore,
    this.formatToShow,
    'en-US',
  ).toString();
  currentStartDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US',
  ).toString();

  crmForm = {
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    type: '',
    stageid: '',
    status: '',
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
  };

  organizations: any[] = [];
  data: any[] = [];
  @Input() width!: string;

  searchText: any = '';
  searchTOrgext: any = '';
  searchTextService: any = '';
  tabletViewWidth: any = 992;
  statusFilter: any = '';
  serviceFilter: any = '';

  routeType: any = '';
  @Input() t_breadcrumb!: string;
  projectList: any = '';
  gettingProjectList: boolean = false;
  projectid: any = '';
  startdatetime: string = '';
  enddatetime: string = '';
  searchLoading: boolean = false;
  nextDisabled: boolean = false;
  lastPage: any = '';
  currentPage: any = 1;
  total: any = '0';
  sortby: any = '';
  getServiceType: boolean = true;
  getSatatusType: boolean = true;

  statuscode: any = ['0010'];
  statusname: any = 'Status';

  statuscodeList: any = [];
  statusnameList: any = ['New'];

  servicecode: string = '';
  viewopenservice: boolean = false;
  servicetype: any = '';
  goCustomer: any = '';
  goCusBol: boolean = false;
  goDealBol: boolean = false;
  gopipBol: boolean = false;
  checkOrgB: boolean = false;
  orgname: any = '';
  routevalueQ: any = '';
  routevalueP: any = '';

  routevalueAll: any = '';
  pagnation: any = [];
  Days: any;
  endDays: any = '';
  productadmin: boolean = false;
  filtergetdata: any = [];
  isAdmin: boolean = false;
  customername: any = '';
  awaitDomain: any = '';
  orgList: any = [];
  customercare: boolean = false;
  customernamecare: any = '';
  getCustomercaredata: any = [];
  customeridcare: any = '';
  customerbranchidcare: any = '';
  contactid: any = '';
  contactadmin: boolean = false;
  customerData: any = [];
  customergroupList: any = [];

  private subs = new SubSink();
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  // pagesize:any = 0;
  private dialogRef: any;
  private dialogData: any;
  checkModeal: boolean = false;
  isExcel: boolean = false;
  downloaddata: any = [];
  excelexportLoading: boolean = false;
  permission: any = [];
  leadview: boolean = false;
  leadcreate: boolean = false;
  leadedit: boolean = false;
  leaddelete: boolean = false;

  dealview: boolean = false;
  dealcreate: boolean = false;
  dealedit: boolean = false;
  dealdelete: boolean = false;

  pipelineview: boolean = false;
  pipelinecreate: boolean = false;
  pipelineedit: boolean = false;
  pipelinedelete: boolean = false;

  customerview: boolean = false;
  customercreate: boolean = false;
  customeredit: boolean = false;
  customerdelete: boolean = false;

  view: boolean = false;
  create: boolean = false;
  edit: boolean = false;
  delete: boolean = false;
  customerid: any = '';
  ticketno: any = '';
  customerValue: any = '';
  checkselfservice: boolean = false;
  projectsmid: any = '';
  productsmid: any = '';
  customersmid: any = '';
  cusgroupname: any = '';
  smbranchname: any = '';
  smbranchid: any = '';
  prioritysmid: any = '';
  customergroupid: any = '';
  internal: boolean = false;
  viewby: any = '';
  branchlist: any = [];
  checkserviceMonthly: any = '';
  checkserviceOverage: any = '';
  checkserviceMonthlyage: any = '';
  checkserviceM: boolean = false;
  checkserviceO: boolean = false;
  internalList: any = [
    { name: 'Internal', value: '001' },
    { name: 'Exclude Internal', value: '002' },
  ];

  viewearilermonth: any = '';
  viewearilercheck: boolean = false;
  ableToUpdate: boolean = false;
  newpath: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private injector: Injector,
    private location: Location,
    private http: HttpClient,
    private titleService: Title,
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.titleService.setTitle('CS365');
    this.allinoneService.changeFavicon('assets/images/cslogo.ico');

    if (this.dialogData) {
      console.log(this.dialogData);
      this.checkModeal = this.dialogData.check;
      // this.projForm.org = this.dialogData.orgid;
      this.crmForm.orgid = this.dialogData.orgid;
      console.log(this.dialogData.picList);
      console.log('Pass Candi>>');
      // this.deleteList = JSON.parse(JSON.stringify(data.deletelist));
      console.log('--AA--');
    } else {
      console.log('Indexindex');
      console.log(this.allinoneService.getindex());

      if (window.innerWidth < this.mobileViewWidth) {
        this.isMobileView = true;
        this.itemPerPage = 20;
        this.isTabletView = false;
      } else if (
        window.innerWidth > this.mobileViewWidth &&
        window.innerWidth < this.tabletViewWidth
      ) {
        this.isMobileView = false;
        this.isTabletView = true;
      }
      this.organizations = this.allinoneService.orgsCRm;
      this.isAdmin = this.allinoneService.crmOrgAdmin === 'true';
      this.routevalueQ = this.route.snapshot.queryParamMap.get('q') || '';
      this.routevalueP = this.route.snapshot.queryParamMap.get('p') || '';
      this.routevalueAll = this.route.snapshot.queryParamMap.get('type') || '';
      // this.filtergetdata.productid = this.route.snapshot.queryParamMap.get('productid') || "";
      this.productsmid =
        this.route.snapshot.queryParamMap.get('productid') || '';
      this.checkserviceMonthly =
        this.route.snapshot.queryParamMap.get('checkservice') || '';
      this.checkserviceMonthlyage =
        this.route.snapshot.queryParamMap.get('age') || '';
      this.checkserviceOverage =
        this.route.snapshot.queryParamMap.get('checkoverage') || '';
      this.viewearilermonth =
        this.route.snapshot.queryParamMap.get('viewearilermonth') || '';

      // checkserviceO

      // this.filtergetdata.projectID = this.route.snapshot.queryParamMap.get('projectid') || "";
      this.projectsmid =
        this.route.snapshot.queryParamMap.get('projectid') || '';
      // this.filtergetdata.customerid = this.route.snapshot.queryParamMap.get('customerid') || "";
      this.customersmid =
        this.route.snapshot.queryParamMap.get('customersmid') || '';
      this.cusgroupname =
        this.route.snapshot.queryParamMap.get('cgroupname') || '';
      this.customergroupid =
        this.route.snapshot.queryParamMap.get('customergroupsmid') || '';

      this.smbranchname =
        this.route.snapshot.queryParamMap.get('cbranchname') || '';
      this.smbranchid =
        this.route.snapshot.queryParamMap.get('customerbranchid') || '';

      var tmpstartdate =
        this.route.snapshot.queryParamMap.get('startdate') || '';
      this.prioritysmid =
        this.route.snapshot.queryParamMap.get('priorityid') || '';
      //

      if (tmpstartdate != '') {
        this.filterstartRdate = tmpstartdate;
        this.filterstartdateshow = formatDate(
          tmpstartdate,
          this.formatToShow,
          'en-US',
        ).toString();
      }

      console.log('okwoefjwoiejfoilljjjjjjj');
      console.log(this.customersmid);
      var tmpEndate = this.route.snapshot.queryParamMap.get('enddate') || '';
      if (tmpEndate != '') {
        this.filterenddate = tmpEndate;
        this.filterenddateshow = formatDate(
          tmpEndate,
          this.formatToShow,
          'en-US',
        ).toString();
      }

      var futureDate = new Date();
      this.customerid =
        this.route.snapshot.queryParamMap.get('customerid') || '';

      // this.filtergetdata.productName = this.route.snapshot.queryParamMap.get('pname') || "";
      var productname = this.route.snapshot.queryParamMap.get('pname') || '';
      // this.filtergetdata.projectName = this.route.snapshot.queryParamMap.get('pjname') || "";
      var projectname = this.route.snapshot.queryParamMap.get('pjname') || '';
      // this.filtergetdata.customerName = this.route.snapshot.queryParamMap.get('cname') || "";
      var customername = this.route.snapshot.queryParamMap.get('cname') || '';
      var desc = this.route.snapshot.queryParamMap.get('description') || '';

      var excludeinternal =
        this.route.snapshot.queryParamMap.get('excludeinternal') || '';
      var checkinternal =
        this.route.snapshot.queryParamMap.get('checkinternal') || '';
      if (checkinternal) {
        if (checkinternal == '001') {
          console.log('1111111111..........');

          this.internalvalue = '001';
          this.internalname = 'Internal';

          this.internal = true;

          this.excludeinternal = false;
        } else if (checkinternal == '002') {
          console.log('1111111111..........');

          this.internalvalue = '002';
          this.internalname = 'Customers';

          this.internal = true;

          this.excludeinternal = false;
        } else {
          console.log('222222222222222..........');

          this.internalvalue = '';

          this.internal = false;

          this.internalname = 'All';
          this.excludeinternal = false;
        }
      }

      if (desc != 'undefined') {
        desc = desc;
      } else {
        desc = '';
      }
      var recurring = this.route.snapshot.queryParamMap.get('recurring') || '';

      if (recurring != 'undefined') {
        if (recurring) {
          console.log('22222222222');
          if (recurring == 'false') {
            console.log('333333333333');
            recurring = '';
          } else {
            console.log('4444444444');
            recurring = 'true';
          }
        }
      }
      var reb = this.route.snapshot.queryParamMap.get('reportedby') || '';
      var rebid = this.route.snapshot.queryParamMap.get('reportedbyid') || '';
      if (reb != 'undefined') {
        reb = reb;
        rebid = rebid;
      } else {
        reb = '';
        rebid = '';
      }
      var deta = this.route.snapshot.queryParamMap.get('detail') || '';

      if (deta != 'undefined') {
        deta = deta;
      } else {
        deta = '';
      }

      var assignedtome =
        this.route.snapshot.queryParamMap.get('assignedtome') || '';
      if (assignedtome != 'false' && assignedtome != 'undefined') {
        assignedtome = assignedtome;
      } else {
        assignedtome = '';
      }
      var picname = this.route.snapshot.queryParamMap.get('picName') || '';
      if (picname != 'undefined') {
        picname = picname;
      } else {
        picname = '';
      }

      var picid = this.route.snapshot.queryParamMap.get('picid') || '';
      if (picid != 'undefined') {
        picid = picid;
      } else {
        picid = '';
      }

      this.filtergetdata = {
        description: desc,
        productid: this.productsmid,
        projectID: this.projectsmid,
        customerid: this.customersmid,
        cusgroupid: this.customergroupid,
        groupname: this.cusgroupname,
        productName: productname,
        projectName: projectname,
        customerName: customername,
        recurring: recurring == 'true' ? true : '',
        reportedby: reb,
        reportedbyid: rebid,
        detail: deta,
        assignedtome: assignedtome ? true : false,
        picName: picname,
        picid: picid,
        branchid: this.smbranchid,
        branchname: this.smbranchname,
      };
      console.log('................jaejae');
      // console.log(priorityname);
      console.log(this.filtergetdata);
      // cname
      // pjname
      // this.filtergetdata.customerid = this.route.snapshot.queryParamMap.get('customerid') || "";
      this.customername =
        this.route.snapshot.queryParamMap.get('customername') || '';
      this.ticketno = this.route.snapshot.queryParamMap.get('ticketno') || '';
      this.customerValue = this.route.snapshot.queryParamMap.get('v') || '';
      this.viewby = this.route.snapshot.queryParamMap.get('viewby') || '';
      console.log('jweifjwiejfiwjefiowjeifjwioejfiwef');
      console.log(this.viewby);

      if (this.customerValue != '') {
        this.allinoneService.isCustomerCare = true;
      } else {
        this.allinoneService.isCustomerCare = false;
      }
      if (this.routevalueQ) {
        this.servicecode = this.routevalueQ;
      }
      console.log(this.routevalueQ);
      console.log('okefjwjjjjjjjjjjjjj1111111111');
      if (this.filterstartRdate) {
        this.checkdate = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.allinoneService.activeroute = '/sales';
    clearInterval(this.getInterval);
  }

  async ngOnInit(): Promise<void> {
    this.getOrgTheme();
    const format = 'dd/MM/yyyy hh:mm aa';

    var tempStartDate = formatDate(
      new Date('2023-02-09 02:47:10.479+00'),
      format,
      'en-US',
      'UTC',
    ).toString();
    console.log('fordateformat');
    console.log(tempStartDate);
    // this.router.navigateByUrl('service');
    var cdata = this.allinoneService.getCustomer();
    if (cdata == '') {
      console.log('.........................33333333333333');
      this.allinoneService.isCustomerCare = false;
      this.organizations = this.allinoneService.orgsCRm;
      if (this.organizations.length > 0) {
        this.crmForm.orgid = this.allinoneService.selectedOrgCRM
          ? this.allinoneService.selectedOrgCRM
          : this.organizations[0].orgid;
      }
    } else {
      this.allinoneService.isCustomerCare = true;
    }
    console.log('.............1111');

    console.log('allinoneservice');
    console.log(this.allinoneService.selectedOrgCRM);
    if (this.viewearilermonth == 'true') {
      this.viewearilercheck = true;
    }

    if (
      this.customerValue &&
      this.customerValue != '' &&
      this.customerid != '' &&
      this.customerid &&
      this.ticketno &&
      this.ticketno != ''
    ) {
      await this.getC();

      this.statuscode = [];
      this.statusname = 'Status';
      this.customercare = true;
      // this.filtergetdata.ticketNo = this.ticketno;
      var type = this.selfcustomer
        .map((x: any) => x.customerid)
        .indexOf(this.customerid);
      if (type != -1) {
        if (this.selfcustomer.length > 0) {
          console.log(this.selfcustomer[type]);

          this.allinoneService.setSelectedCRM(this.selfcustomer[type].orgid);
          this.allinoneService.setCookie(
            'customercrm',
            JSON.stringify(this.selfcustomer[type]) || 'undefined',
          );
          this.allinoneService.selectedOrgCRM = this.selfcustomer[type].orgid;
          var temp = this.organizations.find(
            (x: any) => x.orgid == this.selfcustomer[type].orgid,
          );
          if (this.selfcustomer[type].contacts.length > 0) {
            this.checkselfservice =
              this.selfcustomer[type].contacts[0].selfservice == null ||
              this.selfcustomer[type].contacts[0].selfservice == 'true'
                ? true
                : false;
          } else {
            this.checkselfservice = false;
            this.messageService.openSnackBar(
              this.allinoneService.unauthorizedViewMessage,
              'warn',
            );
            setTimeout(() => {
              this.router.navigateByUrl('service');
            }, 3000);
          }
          if (temp != undefined) {
            this.allinoneService.crmOrgAdmin = temp.admin;
          }
        }
      } else {
        this.allinoneService.setCookie('customercrm', JSON.stringify(''));
        this.messageService.openSnackBar(
          this.allinoneService.unauthorizedViewMessage,
          'warn',
        );

        setTimeout(() => {
          this.router.navigateByUrl('service');
        }, 3000);
        return;
      }
    }

    this.getCustomercaredata = this.allinoneService.getCustomer();
    if (this.getCustomercaredata != '') {
      console.log('.....1');
      this.customercare = true;
      this.customeridcare = this.getCustomercaredata.customerid;
      this.customerbranchidcare = this.getCustomercaredata.branchid;
      this.customernamecare = this.getCustomercaredata.customername;
      this.crmForm.orgid = this.getCustomercaredata.orgid;
      if (this.getCustomercaredata.contacts.length > 0) {
        console.log(this.getCustomercaredata.contacts[0].role);
        if (this.getCustomercaredata.contacts[0].role == '300') {
          this.contactadmin = true;
        }
        this.contactid = this.getCustomercaredata.contacts[0].contactid;
      }
      if (this.getCustomercaredata.contacts.length > 0) {
        if (this.getCustomercaredata.contacts[0].selfservice == 'false') {
          this.router.navigateByUrl('service');
        }
      }

      // if (this.allinoneService.crmOrgAdmin == 'true') {
      //   console.log("1")
      // } else {
      //     this.router.navigateByUrl('sales')

      // }
    } else {
      if (this.allinoneService.crmOrgAdmin == 'true') {
        console.log('1');
      } else {
        console.log('1');
        this.permission = this.allinoneService.getCRMpermission();

        if (this.permission.length > 0) {
          var type = this.permission.map((x: any) => x).indexOf('001');
          if (type == -1) {
            this.router.navigateByUrl('service');
          }
          var view = this.permission.map((x: any) => x).indexOf('011');
          var create = this.permission.map((x: any) => x).indexOf('0012');
          var edit = this.permission.map((x: any) => x).indexOf('0013');
          var delet = this.permission.map((x: any) => x).indexOf('0014');
          if (view == -1) {
            this.view = true;
          }
          if (create == -1) {
            this.create = true;
          }

          if (edit == -1) {
            this.edit = true;
          }

          if (delet == -1) {
            this.delete = true;
          }
        } else {
          this.router.navigateByUrl('sales');
        }
      }
    }

    if (this.checkserviceMonthly == 'true') {
      this.checkserviceM = true;
      this.statusname = 'Status';
      this.statuscode = [];
    }
    console.log(this.viewearilermonth == 'true');

    this.startdatetime = formatDate(
      new Date(),
      this.formatToShow,
      'en-US',
    ).toString();
    var futureDate = new Date();

    futureDate.setDate(futureDate.getDate() + 7);
    this.enddatetime = formatDate(
      futureDate,
      this.formatToShow,
      'en-US',
    ).toString();
    console.log(this.enddatetime);
    if (this.width == null) {
      this.width = 'auto';
    }

    await this.getServiceTypes();
    await this.getStatusList();
    await this.getPriorityList();

    this.getAllProjects();

    this.getProduct();
    this.getCustomers();
    this.getGroupCustomer();
    if (this.viewearilermonth == 'true') {
      console.log('Jaejae1111');
      this.viewearilercheck = true;
      console.log(this.filterenddate && !this.viewearilercheck);

      this.statusname = 'Status';
      this.statuscode = [];
    }
    if (this.checkserviceOverage == 'true') {
      console.log('jjjjjjjjjjj');
      this.checkserviceO = true;
      this.statusname = 'Status';
      this.statuscode = [];
      this.filtergetdata.age = this.checkserviceMonthlyage;
      this.filtergetdata.showname = this.checkserviceMonthlyage + ' ' + '>';
      this.filtergetdata.operator = '>';
    }

    var type = this.priority.map((x: any) => x.code).indexOf(this.prioritysmid);
    console.log('........');
    console.log(this.prioritysmid);
    console.log(this.priority);
    console.log('>>>>>>>>>>>.jfeijfie' + type);
    if (type != -1) {
      var priorityid = this.priority[type]['code'];
      var priorityname = this.priority[type]['name'];
    }

    this.filtergetdata.priorityid = priorityid;
    this.filtergetdata.priorityName = priorityname;
    // 'priorityName': priorityname,
    // 'priorityid': priority

    // if (priority == "0010") {
    //   priorityname = "Low"
    // }
    // if (priority == "00500") {
    //   priorityname = "High"
    // }
    // if (priority == "00900") {
    //   priorityname = "Critical"
    // }

    console.log(this.statusname);

    if (this.routevalueQ != '' && this.checkserviceOverage == 'true') {
    } else if (this.routevalueQ != '' && !this.checkserviceOverage) {
      var type = this.servcietypelist
        .map((x: { code: any }) => x.code)
        .indexOf(this.routevalueQ);
      this.typename = this.servcietypelist[type].name;
    }
    console.log(this.statusname);

    if (this.routevalueP != '') {
      console.log('iiiiiiiiiiiiiiiiiii');
      this.statuscode = [];
      this.statusnameList = [];
      this.statusname = '';
      var type = this.statusLists
        .map((x: { code: any }) => x.code)
        .indexOf('0010');
      if (type > -1) {
        this.statusListsCustomer[type].check = false;
        this.statusLists[type].check = false;
      }
      if (this.routevalueP == 'openingbalance') {
        // this.statuscode.push('0010')
        // this.statusname = "New";
        // this.statusnameList = ["New"]
        // var type = this.statusLists.map((x: { code: any; }) => x.code).indexOf('0010');
        // var type1 = this.statusListsCustomer.map((x: { code: any; }) => x.code).indexOf('0010');
        // if (type > -1) {
        //   this.statusLists[type].check = true
        //   this.statusListsCustomer[type1].check = true;
        // }
        if (!this.customercare) {
          for (let i = 0; i < this.statusLists.length; i++) {
            if (this.statusLists[i].code != '00900') {
              this.statusLists[i].check = true;
              this.statusnameList.push(this.statusLists[i].name);
              this.statusname = this.statusnameList[0];
              this.statuscode.push(this.statusLists[i].code);
            } else {
              this.statusLists[i].check = false;
            }
          }
        } else {
          for (let i = 0; i < this.statusLists.length; i++) {
            if (this.statusLists[i].code != '00900') {
              this.statuscode.push(this.statusLists[i].code);
            } else {
            }
          }

          for (let i = 0; i < this.statusListsCustomer.length; i++) {
            if (this.statusListsCustomer[i].code != '00900') {
              this.statusListsCustomer[i].check = true;
              this.statusnameList.push(this.statusListsCustomer[i].name);
              this.statusname = this.statusnameList[0];
            } else {
              this.statusListsCustomer[i].check = false;
            }
          }

          console.log('customerlist');
          console.log(this.statusListsCustomer);
        }
      } else if (this.routevalueP == 'wip') {
        this.statuscode.push('0020');
        this.statusname = 'WIP';
        this.statusnameList = ['WIP'];
        var type = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('0020');
        if (type > -1) {
          this.statusLists[type].check = true;
          // this.statusListsCustomer[type].check = true;
        }
      } else if (this.routevalueP == 'netnew') {
        var cdata = this.allinoneService.getCustomer();

        if (cdata != '') {
          this.statusname = 'Status';
          this.netnew = 'netnew';
        } else {
          // this.statuscode.push('0030');
          this.statusname = 'Status';
          // this.statusnameList = ["Pending"]
          // var type = this.statusLists.map((x: { code: any; }) => x.code).indexOf('0030');
          // if (type > -1) {
          //   this.statusLists[type].check = true
          //   // this.statusListsCustomer[type].check = true;
          // }
          this.netnew = 'netnew';
        }
      } else if (this.routevalueP == 'closed') {
        this.statuscode.push('00900');

        this.statusname = 'Closed';
        this.statusnameList = ['Closed'];
        var type = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('00900');

        var type1 = this.statusListsCustomer
          .map((x: { code: any }) => x.code)
          .indexOf('00900');

        if (type > -1) {
          this.statusLists[type].check = true;
        }
        if (type1 > -1) {
          this.statusListsCustomer[type1].check = true;
        }
      } else if (this.routevalueP == 'total') {
        this.statuscode = [];
        this.statusname = 'Status';

        console.log('iejfijiklkpPPPPPPPPPPPPP');
      } else if (this.routevalueP == 'Completed') {
        this.statuscode.push('0040');
        this.statusname = 'Completed';
        this.statusnameList = ['Completed'];
        var type = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('0040');
        // var typeqc = this.statusLists.map((x: { code: any; }) => x.code).indexOf('0052');
        // var typeuat = this.statusLists.map((x: { code: any; }) => x.code).indexOf('0062');

        var type1 = this.statusListsCustomer
          .map((x: { code: any }) => x.code)
          .indexOf('0040');
        // var typecustuat = this.statusListsCustomer.map((x: { code: any; }) => x.code).indexOf('0062');
        console.log('type' + 'jae' + type);
        if (type > -1) {
          this.statusLists[type].check = true;
          // this.statusLists[typeqc].check = true
          // this.statusLists[typeuat].check = true
        }
        if (type1 > -1) {
          this.statusListsCustomer[type1].check = true;
          // this.statusListsCustomer[typecustuat].check = true;
        }
      } else if (this.routevalueP == 'wipplus') {
        // this.statuscode.push('wipplus');
        console.log('jjjjjjjjjjjjjjjjjjjjjjjjj');
        // this.statusname = "Wip+";
        var cdata = this.allinoneService.getCustomer();

        console.log(cdata);
        if (cdata != '') {
          console.log('OKOKOKOK111111111111111111111');
          for (let i = 0; i < this.statusLists.length; i++) {
            if (
              this.statusLists[i].code != '0050' &&
              this.statusLists[i].code != '0052' &&
              this.statusLists[i].code != '0060' &&
              this.statusLists[i].code != '0062' &&
              this.statusLists[i].code != '0040' &&
              this.statusLists[i].code != '00900'
            ) {
              this.statuscode.push(this.statusLists[i].code);
            } else {
              this.statusLists[i].check = false;
            }
          }

          var newindex = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0010');
          if (newindex > -1) {
            this.statusListsCustomer[newindex].check = true;
          }
          var wipindex = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0020');
          if (wipindex > -1) {
            this.statusListsCustomer[wipindex].check = true;
          }

          var uatFailt = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0061');
          if (uatFailt > -1) {
            this.statusListsCustomer[uatFailt].check = true;
          }

          this.statusnameList.push(this.statusListsCustomer[newindex].name);
          this.statusnameList.push(this.statusListsCustomer[wipindex].name);
          this.statusnameList.push(this.statusListsCustomer[uatFailt].name);

          this.statusname = this.statusnameList[0];
          console.log(this.statuscode);
        } else {
          console.log('OKOKOKOK22222222222222222222');
          for (let i = 0; i < this.statusLists.length; i++) {
            if (
              this.statusLists[i].code != '0040' &&
              this.statusLists[i].code != '00900' &&
              this.statusLists[i].code != '0052' &&
              this.statusLists[i].code != '0062' &&
              this.statusLists[i].code != '0050' &&
              this.statusLists[i].code != '0060'
            ) {
              this.statusLists[i].check = true;
              this.statusnameList.push(this.statusLists[i].name);
              var lengthS = this.statusnameList.length - 1;
              this.statusname = this.statusnameList[0];
              // this.statusname = this.statusnameList.join();
              this.statuscode.push(this.statusLists[i].code);
            } else {
              this.statusLists[i].check = false;
            }
          }
          console.log(this.statuscode);
        }
      } else if (this.routevalueP == 'open') {
        // this.statuscode.push('wipplus');
        // this.statusname = "Wip+";
        var cdata = this.allinoneService.getCustomer();
        if (cdata != '') {
          for (let i = 0; i < this.statusLists.length; i++) {
            if (
              this.statusLists[i].code != '0010' &&
              this.statusLists[i].code != '0040' &&
              this.statusLists[i].code != '00900'
            ) {
              this.statuscode.push(this.statusLists[i].code);
            } else {
              this.statusLists[i].check = false;
            }
          }
          var wipindex = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0020');
          if (wipindex > -1) {
            this.statusListsCustomer[wipindex].check = true;
          }

          var newindex = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0010');
          if (newindex > -1) {
            this.statusListsCustomer[newindex].check = true;
          }
          var pendinindex = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0030');
          if (pendinindex > -1) {
            this.statusListsCustomer[pendinindex].check = true;
          }
          var uat = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0060');
          if (uat > -1) {
            this.statusListsCustomer[uat].check = true;
          }
          var uatFailt = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0061');
          if (uatFailt > -1) {
            this.statusListsCustomer[uatFailt].check = true;
          }
          var uatPass = this.statusListsCustomer
            .map((x: { code: any }) => x.code)
            .indexOf('0062');
          if (uatPass > -1) {
            this.statusListsCustomer[uatPass].check = true;
          }

          this.statusnameList.push('WIP');
          this.statusnameList.push('UAT');
          this.statusnameList.push('UAT - Failed');
          this.statusnameList.push('UAT - Passed');
          this.statusname = this.statusnameList[0];
          console.log(this.statuscode);
        } else {
          console.log('OKOKOKOK222222222222222');
          for (let i = 0; i < this.statusLists.length; i++) {
            if (
              this.statusLists[i].code != '0040' &&
              this.statusLists[i].code != '00900'
            ) {
              this.statusLists[i].check = true;
              this.statusnameList.push(this.statusLists[i].name);
              var lengthS = this.statusnameList.length - 1;
              this.statusname = this.statusnameList[0];
              // this.statusname = this.statusnameList.join();
              this.statuscode.push(this.statusLists[i].code);
            } else {
              this.statusLists[i].check = false;
            }
          }
          console.log(this.statuscode);
        }
      } else if (this.routevalueP == 'completedplus') {
        this.statuscode.push('0040');
        this.statuscode.push('00900');
        this.statuscode.push('0052');
        this.statuscode.push('0060');
        this.statuscode.push('0050');
        this.statuscode.push('0062');

        var type = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('0040');
        var typeorg = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('00900');

        var qcpassed = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('0052');
        var uatpassed = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('0062');

        var prerelesed = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('0050');
        var uatnew = this.statusLists
          .map((x: { code: any }) => x.code)
          .indexOf('0060');

        var typecusto = this.statusListsCustomer
          .map((x: { code: any }) => x.code)
          .indexOf('0040');
        var type1f = this.statusListsCustomer
          .map((x: { code: any }) => x.code)
          .indexOf('00900');
        var customeruatpassed = this.statusListsCustomer
          .map((x: { code: any }) => x.code)
          .indexOf('0062');
        var uatcustomer = this.statusListsCustomer
          .map((x: { code: any }) => x.code)
          .indexOf('0060');

        if (!this.customercare) {
          this.statusnameList.push(this.statusLists[type].name);
          this.statusnameList.push(this.statusLists[typeorg].name);
          this.statusnameList.push(this.statusLists[qcpassed].name);
          this.statusnameList.push(this.statusLists[uatpassed].name);
          this.statusnameList.push(this.statusLists[prerelesed].name);
          this.statusnameList.push(this.statusLists[uatnew].name);
        }
        if (this.customercare) {
          this.statusnameList.push(this.statusListsCustomer[typecusto].name);
          this.statusnameList.push(this.statusListsCustomer[type1f].name);
          this.statusnameList.push(
            this.statusListsCustomer[customeruatpassed].name,
          );
          this.statusnameList.push(this.statusListsCustomer[uatcustomer].name);
        }

        console.log('type' + 'jae' + type);
        console.log(this.statusListsCustomer);
        console.log(this.statusLists);
        if (this.statusnameList.length > 0) {
          this.statusname = this.statusnameList[0];
        }
        if (
          type > -1 &&
          typeorg > -1 &&
          qcpassed > -1 &&
          uatpassed > -1 &&
          prerelesed > -1
        ) {
          this.statusLists[type].check = true;
          this.statusLists[typeorg].check = true;
          this.statusLists[qcpassed].check = true;
          this.statusLists[uatpassed].check = true;
          this.statusLists[prerelesed].check = true;
          this.statusLists[uatnew].check = true;
        }

        if (typecusto > -1 && type1f > -1 && customeruatpassed > -1) {
          this.statusListsCustomer[type1f].check = true;
          this.statusListsCustomer[typecusto].check = true;
          this.statusListsCustomer[customeruatpassed].check = true;
          this.statusListsCustomer[uatcustomer].check = true;
        }
      }
      // else {
      //   this.viewopenservice = true;
      //   this.statusname = "Others"

      // }
    } else if (this.routevalueAll == 'all') {
      this.statuscode = [];
      this.statusnameList = [];
      if (this.statusLists.length > 0) {
        for (let i = 0; i < this.statusLists.length; i++) {
          this.statusLists[i].check = false;
        }
      }

      if (this.statusListsCustomer.length > 0) {
        for (let j = 0; j < this.statusListsCustomer.length; j++) {
          this.statusListsCustomer[j].check = false;
        }
      }

      // var type = this.statusLists.map((x: { code: any; }) => x.code).indexOf('0010');
      // if (type > -1) {

      //   this.statusListsCustomer[type].check = false;
      //   this.statusLists[type].check = false
      // }
      // var typeall = this.statusListsCustomer.map((x: any) => x.code).indexOf("0010");
      // this.statusListsCustomer[typeall].check = false;
      this.statusname = 'Status';
    }
    console.log(this.routevalueP);
    console.log(this.statusname);
    this.getServiceData('');

    console.log(this.statuscode);
    console.log(this.statusname);
    console.log(this.viewearilercheck);
    console.log('jjae');

    console.log(this.servcietypelist, 'servicetype-->');
  }

  sortDataService(sort: Sort) {
    console.log(this.data);
    const data = this.data;
    if (!sort.active || sort.direction === '') {
      this.data = data;
    }

    this.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ticketno':
          return this.allinoneService.compare(
            a.ticketno.toLocaleLowerCase(),
            b.ticketno.toLocaleLowerCase(),
            isAsc,
          );
        case 'customername':
          return this.allinoneService.compare(
            a.name.toLocaleLowerCase(),
            b.name.toLocaleLowerCase(),
            isAsc,
          );
        case 'type':
          return this.allinoneService.compare(
            a.type.toLocaleLowerCase(),
            b.type.toLocaleLowerCase(),
            isAsc,
          );
        case 'status':
          return this.allinoneService.compare(
            a.status.toLocaleLowerCase(),
            b.status.toLocaleLowerCase(),
            isAsc,
          );
        case 'assignedto':
          return this.allinoneService.compare(
            a.assignedtoname.toLocaleLowerCase(),
            b.assignedtoname.toLocaleLowerCase(),
            isAsc,
          );
        case 'reportedby':
          return this.allinoneService.compare(
            a.reportedbyname.toLocaleLowerCase(),
            b.reportedbyname.toLocaleLowerCase(),
            isAsc,
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdatetoshow.toLocaleLowerCase(),
            b.startdatetoshow.toLocaleLowerCase(),
            isAsc,
          );

        case 'targetdatetime':
          return this.allinoneService.compare(
            a.targetdatetime.toLocaleLowerCase(),
            b.targetdatetime.toLocaleLowerCase(),
            isAsc,
          );

        case 'ageday':
          return this.allinoneService.compare(
            a.ageday.toString().toLocaleLowerCase(),
            b.ageday.toString().toLocaleLowerCase(),
            isAsc,
          );
        case 'description':
          return this.allinoneService.compare(
            a.editservciedesc.toLocaleLowerCase(),
            b.editservciedesc.toLocaleLowerCase(),
            isAsc,
          );

        default:
          return 0;
      }
    });
  }

  sortData(sort: Sort) {
    const data = this.data;
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        //Lead
        case 'leadname':
          return this.allinoneService.compare(
            a.name.toLocaleLowerCase(),
            b.name.toLocaleLowerCase(),
            isAsc,
          );
        case 'leadphone':
          return this.allinoneService.compare(a.mobile, b.mobile, isAsc);
        case 'leadstatus':
          return this.allinoneService.compare(
            a.statusname,
            b.statusname,
            isAsc,
          );
        case 'leadcreatedby':
          return this.allinoneService.compare(
            a.createdbyname.toLocaleLowerCase(),
            b.createdbyname.toLocaleLowerCase(),
            isAsc,
          );

        //Customer
        case 'customerid':
          return this.allinoneService.compare(
            a.customerid.toLocaleLowerCase(),
            b.customerid.toLocaleLowerCase(),
            isAsc,
          );
        case 'customername':
          return this.allinoneService.compare(
            a.customername.toLocaleLowerCase(),
            b.customername.toLocaleLowerCase(),
            isAsc,
          );
        case 'customerphone':
          return this.allinoneService.compare(
            a.customermobile,
            b.customermobile,
            isAsc,
          );
        case 'customeremail':
          return this.allinoneService.compare(
            a.customeremail,
            b.customeremail,
            isAsc,
          );

        case 'customertype':
          return this.allinoneService.compare(a.typename, b.typename, isAsc);
        case 'customerstatus':
          return this.allinoneService.compare(
            a.statusname,
            b.statusname,
            isAsc,
          );
        case 'customercreatedby':
          return this.allinoneService.compare(
            a.createdbyname.toLocaleLowerCase(),
            b.createdbyname.toLocaleLowerCase(),
            isAsc,
          );

        //Deal
        case 'dealname':
          return this.allinoneService.compare(
            a.customername.toLocaleLowerCase(),
            b.customername.toLocaleLowerCase(),
            isAsc,
          );
        case 'dealstage':
          return this.allinoneService.compare(a.stagename, b.stagename, isAsc);
        case 'dealstatus':
          return this.allinoneService.compare(
            a.statusname,
            b.statusname,
            isAsc,
          );
        case 'dealtargetdate':
          return this.allinoneService.compare(
            a.targetdate,
            b.targetdate,
            isAsc,
          );
        case 'dealclosingdate':
          return this.allinoneService.compare(
            a.closingdate,
            b.closingdates,
            isAsc,
          );
        case 'dealcreatedby':
          return this.allinoneService.compare(
            a.createdbyname.toLocaleLowerCase(),
            b.createdbyname.toLocaleLowerCase(),
            isAsc,
          );

        //Pipeline
        case 'pipelinename':
          return this.allinoneService.compare(
            a.customername.toLocaleLowerCase(),
            b.customername.toLocaleLowerCase(),
            isAsc,
          );
        case 'pipelinetargetdate':
          return this.allinoneService.compare(
            a.targetdate,
            b.targetdate,
            isAsc,
          );
        case 'pipelineclosingdate':
          return this.allinoneService.compare(
            a.closingdate,
            b.closingdate,
            isAsc,
          );
        case 'stage':
          return this.allinoneService.compare(a.stage, b.stage, isAsc);
        case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        case 'pipelineamount':
          return this.allinoneService.compare(a.amount, b.amount, isAsc);
        case 'pipelinecurrency':
          return this.allinoneService.compare(
            a.currencyname,
            b.currencyname,
            isAsc,
          );
        case 'pipelinecreatedby':
          return this.allinoneService.compare(
            a.createdbyname.toLocaleLowerCase(),
            b.createdbyname.toLocaleLowerCase(),
            isAsc,
          );
        default:
          return 0;
      }
    });
  }

  statusChanges(data: any, index: any) {
    console.log(data);
    console.log(data == 'status');
    // this.statuscode = data.;

    if (data == 'status') {
      this.statuscode = [];

      this.statusname = 'Status';
      this.statusnameList = [];
      this.viewopenservice = false;
      for (let i = 0; i < this.statusListsCustomer.length; i++) {
        this.statusListsCustomer[i].check = false;
      }
      for (let i = 0; i < this.statusLists.length; i++) {
        this.statusLists[i].check = false;
      }

      console.log(this.statusListsCustomer);

      console.log(this.statusListsCustomer);

      this.refresh();
    } else {
      var type = this.statuscode.map((x: any) => x).indexOf(data.code);
      var type1 = this.statusnameList.map((x: any) => x).indexOf(data.name);
      var type2 = this.statusLists.map((x: any) => x.code).indexOf(data.code);
      if (type == -1 && type1 == -1) {
        this.statuscode.push(data.code);
        this.statusnameList.push(data.name);
        if (this.statusnameList.length > 0) {
          this.statusname = this.statusnameList[0];
        }
      } else {
        this.statuscode.splice(type, 1);
        this.statusnameList.splice(type1, 1);
        if (this.statusnameList.length > 0) {
          this.statusname = this.statusnameList[0];
        }
      }
      if (type2 > -1) {
        if (this.statusLists[type2].check == true) {
          this.statusLists[type2].check = false;
        } else {
          this.statusLists[index].check = true;
        }
      } else {
        console.log('22222222222');
        this.statusLists[index].check = true;
      }
      this.viewopenservice = false;
      this.refresh();
    }
    if (this.statuscode.length == 0) {
      this.statusname = 'Status';
    }
  }
  cancelFunction(data: any) {
    console.log(data);
    if (data == 1) {
      console.log('1>>>>>>');

      this.goCusBol = false;
      this.gopipBol = false;
      this.goDealBol = false;
      this.total = 0;
      this.data = [];
      this.sortby = '';
      this.nextDisabled = false;
      this.currentPage = 1;
      this.lastPage = '';
      this.goCustomer = '';
      this.getServiceData(this.projectid);
    }
  }

  statusChangeCustomers(data: any, index: any) {
    if (data.code == '0020') {
      this.viewopenservice = false;
      var type = this.statuscode.map((x: any) => x).indexOf('0020');
      console.log('11' + type);
      if (type == -1) {
        for (let i = 0; i < this.statusLists.length; i++) {
          if (
            this.statusLists[i].code != '0010' &&
            this.statusLists[i].code != '0040' &&
            this.statusLists[i].code != '00900' &&
            this.statusLists[i].code != '0060' &&
            this.statusLists[i].code != '0061' &&
            this.statusLists[i].code != '0062'
          ) {
            // var lengthS = this.statusnameList.length - 1
            this.statuscode.push(this.statusLists[i].code);
          } else {
            this.statusLists[i].check = false;
          }
        }

        this.statusnameList.push('WIP');
        // var typewip = this.statusListsCustomer.map((x: any) => x).indexOf("0020");
        var typewip = this.statusListsCustomer
          .map((x: any) => x.code)
          .indexOf('0020');
        console.log('typewip' + typewip);
        if (typewip > -1) {
          this.statusListsCustomer[typewip].check = true;
        }
        if (this.statusnameList.length > 0) {
          this.statusname = this.statusnameList[0];
        }
        console.log(this.statusListsCustomer);
      } else {
        console.log(this.statuscode);

        var type9 = this.statuscode.map((x: any) => x).indexOf('0020');
        this.statuscode.splice(type9, 1);

        var type7 = this.statuscode.map((x: any) => x).indexOf('0030');
        this.statuscode.splice(type7, 1);

        var type8 = this.statuscode.map((x: any) => x).indexOf('0050');
        this.statuscode.splice(type8, 1);

        var type3 = this.statuscode.map((x: any) => x).indexOf('0051');
        this.statuscode.splice(type3, 1);

        var type4 = this.statuscode.map((x: any) => x).indexOf('0052');
        this.statuscode.splice(type4, 1);

        // var type5 = this.statuscode.map((x: any) => x).indexOf("0061");
        // this.statuscode.splice(type5, 1)

        // var type6 = this.statuscode.map((x: any) => x).indexOf("0062");
        // this.statuscode.splice(type6, 1)

        var type10 = this.statuscode
          .map((x: any) => x)
          .indexOf('Not Completed');
        this.statuscode.splice(type10, 1);

        var name = this.statusnameList.map((x: any) => x).indexOf('WIP');
        this.statusnameList.splice(name, 1);
        console.log(this.statuscode);
        if (this.statusnameList.length > 0) {
          this.statusname = this.statusnameList[0];
        }

        console.log(this.statusnameList);
      }

      this.refresh();
    } else {
      var type = this.statuscode.map((x: any) => x).indexOf(data.code);
      var type1 = this.statusnameList.map((x: any) => x).indexOf(data.name);
      var type2 = this.statusListsCustomer
        .map((x: any) => x.code)
        .indexOf(data.code);
      console.log(type);
      console.log(type1);
      console.log(this.statuscode);
      if (type == -1 && type1 == -1) {
        this.statuscode.push(data.code);
        this.statusnameList.push(data.name);
        if (this.statusnameList.length > 0) {
          this.statusname = this.statusnameList[0];
        }

        // this.statusname = this.statusnameList.join();
      } else {
        this.statuscode.splice(type, 1);
        this.statusnameList.splice(type1, 1);
        // if (this.routevalueP == 'wipplus') {
        //   this.statusname = "WIP+"
        // } else {
        if (this.statusnameList.length > 0) {
          this.statusname = this.statusnameList[0];
        }
        // this.statusname = this.statusnameList.join();

        // }
      }

      if (type2 > -1) {
        console.log('1111111111');
        if (this.statusListsCustomer[type2].check == true) {
          this.statusListsCustomer[type2].check = false;
        } else {
          this.statusListsCustomer[index].check = true;
        }
      } else {
        console.log('22222222222');
        this.statusListsCustomer[index].check = true;
      }
      console.log(this.statusLists);
      this.viewopenservice = false;
      this.refresh();
      console.log(data);
    }
    console.log(this.statuscode);
    if (this.statuscode.length == 0) {
      this.statusname = 'Status';
    }
  }
  json = JSON;

  typename: any = 'Type';
  seviceChanges(data: any) {
    console.log(data);
    if (data == 'type') {
      this.servicecode = '';
      this.typename = 'Type';
      this.refresh();
      console.log('1');
    } else {
      this.servicecode = data.code;
      this.typename = data.name;
      console.log('2');
      this.refresh();
    }
    // var type = this.servcietypelist.map((x) => x.code).indexOf(event.target.value);
    console.log(data);

    // this.servicecode = event.target.value;
  }

  seviceChange(event?: any) {
    // var type = this.servcietypelist.map((x) => x.code).indexOf(event.target.value);
    this.servicecode = event.target.value;
    this.refresh();
  }

  getStages() {
    this.gettingStages = true;
    const data = {
      orgid: '',
      domainid: this.crmForm.domainid,
    };
    this.kunyekService.getPipelineStage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.stages = res.list;
          // this.crmForm.stageid = this.stages[0].stageid;
          this.gettingStages = false;
        } else {
          this.gettingStages = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStages = false;
      },
    );
  }
  industryList: any = [];

  leadsourceList: any = [];

  editService(item: any) {
    // if (this.edit == true) {
    //   this.messageService.openSnackBar(this.allinoneService.unauthorizedViewMessage, 'warn');
    // } else {
    console.log(item);
    this.dataForEdit = JSON.parse(JSON.stringify(item));

    this.serviceAdd = true;
    this.onActivate();
    // }
  }
  onActivate() {
    window.scrollTo(0, 0);
  }

  history(item: any) {
    this.newpath = item.newpath ? item.newpath : false;
    this.dataForEdit = JSON.parse(JSON.stringify(item));
    this.historyshow = true;
    console.log('history show', item, this.newpath);
  }

  deleteService(datas: any) {
    // if(data.sta
    console.log(datas);
    // if(datas.status == '0010'){

    // }

    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingData = true;
        const data = {
          autoid: datas.autoid,
        };
        this.kunyekService.deleteService(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.refresh();
            } else {
              this.gettingData = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.gettingData = false;
          },
        );
      }
    });
  }

  changePageCount(event: any) {
    console.log(this.itemPerPage);
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage);
    this.currentPage = 1;

    this.refresh();
    // this.getServiceData(this.projectid);
  }

  clear(data: any) {
    if (data == 1) {
      this.searchbool = false;
      this.searchText = '';
      this.searchTextService = '';
      this.ticketno = '';
      this.filtergetdata.customerid = '';
      if (this.checkcustomer == true) {
        this.refresh();
        this.checkcustomer = false;
      }
    } else if (data == 2) {
      this.searchbool = false;
      this.searchText = '';
      this.searchTextService = '';
    }
  }

  complete() {
    this.projectid = '';
    this.orgtype = this.crmForm.orgid;

    this.refresh();

    this.cancel();
  }
  backCustomer() {
    this.router.navigateByUrl('sales/customers');
  }
  cancelservice(data: any) {
    this.dataForEdit = '';
    this.leadAdd = false;
    this.customerAdd = false;
    this.dealAdd = false;
    this.summaryCheck = false;
    this.serviceAdd = false;
    this.historyshow = false;
    this.orgtype = this.crmForm.orgid;
    console.log(data);
    console.log('>...');
    if (data) {
      this.refresh();
    }
  }
  cancel() {
    this.dataForEdit = '';
    this.leadAdd = false;
    this.customerAdd = false;
    this.dealAdd = false;
    this.summaryCheck = false;
    this.serviceAdd = false;
    this.historyshow = false;
    this.isExcel = false;
    this.orgtype = this.crmForm.orgid;
  }

  summary() {
    console.log('condole.log(summar');

    // this.summaryCheck = true;
    // this.router.navigateByUrl('/sales/services/summary');
  }

  new() {
    this.cancel();
    this.serviceAdd = true;
  }

  getAllProjects() {
    this.gettingProjectList = true;
    this.projectList = [];
    const data = {
      orgid: this.crmForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
      // role: this.callType,
    };

    console.log(data);
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var userid = this.allinoneService.getKunyekUserId();
          if (this.allinoneService.crmOrgAdmin == 'false') {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i]['memberstatus'] == '') {
                this.projectList.push(res.data[i]);
              }
              if (res.data[i]['memberstatus'] == '1') {
                for (let j = 0; j < res.data[i]['memberlist'].length; j++) {
                  console.log(res.data[i]['memberlist'][j]['userid'] == userid);
                  if (userid == res.data[i]['memberlist'][j]['userid']) {
                    this.projectList.push(res.data[i]);
                  }
                }
              }
            }
          } else {
            this.projectList = res.data;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingProjectList = false;
      },
    );
  }

  close() {
    const data = {
      status: true,
    };

    this.dialogRef.close(data);
  }

  getServiceData(projectid: any) {
    console.log();

    const customerid =
      this.route.snapshot.queryParamMap.get('customerid') || '';
    this.total = '0';
    this.gettingData = true;
    console.log('customerid');
    console.log(this.filtergetdata.customerid);
    const data = {
      orgid: this.crmForm.orgid,
      domainid: this.crmForm.domainid,
      productadmin: this.productadmin,
      admin: this.customercare ? false : this.isAdmin,
      pagesize: this.itemPerPage,
      sortby: this.sortby == '' ? '' : this.sortby,
      status: this.statuscode == '' ? '' : this.statuscode,
      servicetype: this.servicecode == '' ? '' : this.servicecode,
      viewotherstatus: this.viewopenservice,
      startdate: this.checkdate ? this.filterstartRdate : '',
      enddate: this.filterenddate ? this.filterenddate : '',
      productid: this.filtergetdata.productid
        ? this.filtergetdata.productid
        : '',
      picid: this.filtergetdata.picid ? this.filtergetdata.picid : '',
      customerid:
        this.customeridcare != ''
          ? this.customeridcare
          : customerid == ''
            ? this.filtergetdata.customerid == undefined
              ? ''
              : this.filtergetdata.customerid
            : customerid,
      priority: this.filtergetdata.priorityid
        ? this.filtergetdata.priorityid
        : '',
      ticketno: this.ticketno ? this.ticketno : '',
      assignedtome: this.filtergetdata.assignedtome
        ? this.filtergetdata.assignedtome
        : false,
      projectid: this.filtergetdata.projectID
        ? this.filtergetdata.projectID
        : projectid
          ? projectid
          : '',
      reportedby: this.filtergetdata.reportedbyid
        ? this.filtergetdata.reportedbyid
        : '',
      age: this.filtergetdata.age ? this.filtergetdata.age : '',
      ageoperator: this.filtergetdata.operator
        ? this.filtergetdata.operator
        : '',
      customerview: this.customercare == true ? true : false,
      contactid: this.contactid,
      contactadmin: this.contactadmin == true ? true : false,
      moduleid: this.filtergetdata.moduleid ? this.filtergetdata.moduleid : '',
      refno: this.filtergetdata.refno ? this.filtergetdata.refno : '',
      description: this.filtergetdata.description
        ? this.filtergetdata.description
        : '',
      detail: this.filtergetdata.detail ? this.filtergetdata.detail : '',
      recurring: this.filtergetdata.recurring,
      viewinternalstatus: this.internalvalue,
      netnew: this.netnew ? this.netnew : '',
      viewby: this.viewby ? this.viewby : '',
      customergroupid: this.filtergetdata.cusgroupid
        ? this.filtergetdata.cusgroupid
        : '',
      branchid:
        this.customerbranchidcare != ''
          ? this.customerbranchidcare
          : this.filtergetdata.branchid != ''
            ? this.filtergetdata.branchid
            : '',
      viewearilermonth: this.viewearilermonth == 'true' ? true : false,
      viewoverage: this.checkserviceOverage == 'true' ? true : false,
    };
    console.log(data);
    this.subs.sink = this.kunyekService.getService(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          const format = 'dd/MM/yyyy hh:mm aa';

          res.list.map((service: any) => {
            var tempStartDate =
              service.startdatetime != ''
                ? formatDate(
                    new Date(service.startdatetime),
                    this.formatToShow,
                    'en-US',
                    'UTC',
                  ).toString()
                : '';
            var tempEndDate =
              service.enddatetime != '' && service.enddatetime != null
                ? formatDate(
                    new Date(service.enddatetime),
                    this.formatToShow,
                    'en-US',
                    'UTC',
                  ).toString()
                : '';
            var targetDate =
              service.targetdatetime != '' && service.targetdatetime != null
                ? formatDate(
                    new Date(service.targetdatetime),
                    this.formatToShow,
                    'en-US',
                  ).toString()
                : '';

            // var agedaystart = formatDate(new Date(service.startdatetime), ageformat, 'en-US', 'UTC').toString();
            // var agedayend = formatDate(Date.now(), ageformat, 'en-US').toString();
            // var agedayst = new Date(agedaystart)
            // var agedatend = new Date(agedayend);
            // var Time = agedatend.getTime() - agedayst.getTime();
            // this.Days = Time / (1000 * 3600 * 24);
            // if (service.status == "00900") {

            //   this.endDays = formatDate(new Date(service.enddatetime), ageformat, 'en-US', 'UTC').toString();

            //   var agedayst = new Date(agedaystart)
            //   this.endDays = new Date(this.endDays);
            //   var Time = this.endDays.getTime() - agedayst.getTime();
            //   this.endDays = Time / (1000 * 3600 * 24);
            // } else {
            //   var agedayst = new Date(agedaystart)
            //   var agedatend = new Date(agedayend);
            //   var Time = agedatend.getTime() - agedayst.getTime();
            //   this.Days = Time / (1000 * 3600 * 24);

            // }

            var lastdate = formatDate(
              new Date(service.updatedAt),
              format,
              'en-US',
            ).toString();
            // == null ? '' : service.CRMCustomer.name
            var serviceType = this.servcietypelist.filter(function (type: any) {
              return type.code == service.type;
            });
            var producttypelist = this.productListdata.filter(function (
              type: any,
            ) {
              return type.productid == service.productid;
            });
            // var customertypelist = this.customers.filter(function (type: any) { return type.customerid == service.customerid })

            var status = this.statusLists.filter(function (status: any) {
              return status.code == service.status;
            });
            var module = this.productListdata.filter(function (pro: any) {
              return pro.productid == service.productid;
            });
            // var module =  productListdata
            this.data.push({
              // "ageday": service.status == "00900" ? this.endDays : this.Days,
              ageday: service.age,
              autoid: service.autoid,
              name: service.CRMCustomer.name,
              CRMCustomer: service.CRMCustomer,
              CRMServiceStatus: service.CRMServiceStatus,
              assignedto: service.assignedto,
              assignedtoname: service.assignedtoname,
              assignedtouserid: service.assignedtouserid,
              domainid: service.domainid,
              enddatetime: tempEndDate,
              startdatetime: tempStartDate,
              startdatetoshow: service.startdatetime,
              enddatetoshow: service.enddatetime,
              filelist: service.filelist,
              filename: service.filename,
              orgid: service.orgid,
              // "priority": service.priority,
              // "priorityname": service.CRMServicePriority.name,
              // "projectid": service.projectid,
              remark: service.remark,
              reportedby: service.reportedby,
              reportedbyname: service.reportedbyname,
              servicedesc: service.servicedesc.replace('\n', ''),
              editservciedesc: service.servicedesc,
              sortby: service.sortby,
              statusname:
                service.CRMServiceStatus == null
                  ? ''
                  : service.CRMServiceStatus.name,
              statuslist: status.length > 0 ? status[0] : [],
              status: service.status,
              ticketno: service.ticketno,

              servcietypelist: serviceType.length > 0 ? serviceType[0] : [],
              producttypelist:
                producttypelist.length > 0 ? producttypelist[0] : [],
              // "customertypelist":customertypelist.length > 0 ? customertypelist[0] :[],
              typename: service.CRMServiceType.name
                ? service.CRMServiceType.name
                : '',
              longtypename: service.CRMServiceType.longname
                ? service.CRMServiceType.longname
                : '',
              type: service.type,
              customerphone: service.customerphone,
              customeremail: service.customeremail,
              serviceid: service.serviceid,
              project: service.projectname,
              projectid: this.filtergetdata.projectID
                ? this.filtergetdata.projectID
                : service.projectid
                  ? service.projectid
                  : '',
              details: service.details,
              solution: service.solution,
              // "lastupdatename":service.lastupdatedby,
              companyname: service.companyname,
              lastupdatedby: service.lastupdatedby,
              lastupdatename:
                service.lastupdatedby == '' || service.lastupdatedby == null
                  ? service.reportedbyname
                  : service.lastupdatedbyname,
              lastupdatetime:
                service.updatedAt == '' || service.updatedAt == null
                  ? ''
                  : lastdate,
              customerdefault: service.customerdefault,
              product: service.product.name == null ? '' : service.product.name,
              productid: service.productid == null ? '' : service.productid,
              // "productid": this.filtergetdata.productid ? this.filtergetdata.productid : service.productid,
              // "customerid": this.filtergetdata.customerid ? this.filtergetdata.customerid : service.customerid,
              priority: this.filtergetdata.priorityid
                ? this.filtergetdata.priorityid
                : service.priority,
              priorityname: service.CRMServicePriority.name
                ? service.CRMServicePriority.name
                : '',
              customerid: service.customerid,
              picid: service.picid != '' ? service.picid : '',
              // "picid": this.filtergetdata.picid ? this.filtergetdata.picid : "",
              pic: '',
              refno: service.refno,
              contacts: service.CRMContact,
              picdata:
                service.picdata == null || service.picdata == ''
                  ? ''
                  : service.picdata,
              version: service.version,
              mer: service.mer,
              moduleid: service.moduleid,
              modulelist:
                module.length > 0
                  ? module[0].modulelist.length > 0
                    ? module[0].modulelist
                    : ''
                  : '',
              modulename: service.modulename,
              reopen: service.reopen,
              reticketno: service.reticketno,
              targetdatetime: service.targetdatetime,
              targetdatetimeshow: targetDate,
              duedatetime: service.duedatetime,
              isinternal: service.isinternal,
              voidticket: service.voidticket == 'true' ? true : false,
              branchid: service.branchid == null ? '' : service.branchid,
              answers: service.CRMAnswers,
              ttid: service.ttid,
              siteid: service.siteid,
              tttype: service.tttype,
              rca: service.rca,
              rcadetail: service.rcadetail,
              fromsite: service.fromsite,
              tosite: service.tosite,
              completionsnap: service.completionsnap,
              question: service.question,
              newpath: service.newpath ? service.newpath : false,
              // "completedatetime":service.completedatetime,
            });
          });

          this.totalopencount = res.totalopencount;
          this.totalclosedcount = res.totalclosedcount;
          // 20
          var sort = this.data.length - 1;
          this.total = res.total;

          // 50
          if (parseInt(this.total) == this.data.length) {
            console.log('getTrue');
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.data[sort].sortby;
          } else {
            console.log('getfalse');
            this.sortby = this.sortby = sort > -1 ? this.data[sort].sortby : '';
          }
          console.log(this.filtergetdata.length);

          this.searchLoading = false;
          this.gettingData = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingData = false;
        this.searchLoading = false;
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingData = false;
        this.searchLoading = false;
        this.gettingProjectList = false;
      },
    );
  }
  getServiceDataDownload(projectid: any) {
    this.excelexportLoading = true;

    return new Promise((resolve) => {
      const customerid =
        this.route.snapshot.queryParamMap.get('customerid') || '';
      const data = {
        orgid: this.crmForm.orgid,
        domainid: this.crmForm.domainid,
        productadmin: this.productadmin,
        admin: this.customercare ? false : this.isAdmin,
        pagesize: this.total,
        sortby: this.sortby == '' ? '' : this.sortby,
        status: this.statuscode == '' ? '' : this.statuscode,
        servicetype: this.servicecode == '' ? '' : this.servicecode,
        viewotherstatus: this.viewopenservice,
        startdate: this.checkdate ? this.filterstartRdate : '',
        enddate: this.filterenddate ? this.filterenddate : '',
        productid: this.filtergetdata.productid
          ? this.filtergetdata.productid
          : '',
        picid: this.filtergetdata.picid ? this.filtergetdata.picid : '',
        customerid:
          this.customeridcare != ''
            ? this.customeridcare
            : customerid == ''
              ? this.filtergetdata.customerid == undefined
                ? ''
                : this.filtergetdata.customerid
              : customerid,
        priority: this.filtergetdata.priorityid
          ? this.filtergetdata.priorityid
          : '',
        ticketno: this.ticketno ? this.ticketno : '',
        assignedtome: this.filtergetdata.assignedtome
          ? this.filtergetdata.assignedtome
          : false,
        projectid: this.filtergetdata.projectID
          ? this.filtergetdata.projectID
          : projectid
            ? projectid
            : '',
        reportedby: this.filtergetdata.reportedbyid
          ? this.filtergetdata.reportedbyid
          : '',
        age: this.filtergetdata.age ? this.filtergetdata.age : '',
        ageoperator: this.filtergetdata.operator
          ? this.filtergetdata.operator
          : '',
        customerview: this.customercare == true ? true : false,
        contactid: this.contactid,
        contactadmin: this.contactadmin == true ? true : false,
        moduleid: this.filtergetdata.moduleid
          ? this.filtergetdata.moduleid
          : '',
        refno: this.filtergetdata.refno ? this.filtergetdata.refno : '',
        description: this.filtergetdata.description
          ? this.filtergetdata.description
          : '',
        detail: this.filtergetdata.detail ? this.filtergetdata.detail : '',
        recurring: this.filtergetdata.recurring,
        viewinternalstatus: this.internalvalue,
        netnew: this.netnew ? this.netnew : '',
        isexport: true,
        viewby: this.viewby ? this.viewby : '',
      };
      this.subs.sink = this.kunyekService.getService(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            const format = 'MM/dd/yyyy hh:mm aa';
            const ageformat = 'MM/dd/yyyy';
            // 94232622668354
            this.exportid = res.id;
            resolve('true');
          } else {
            this.excelexportLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('false');
          }
        },
        (err) => {
          this.excelexportLoading = false;
          resolve('false');
        },
      );
    });
  }

  closeviewopen() {
    this.viewopenservice = false;
    this.statuscode = [];
    this.servicecode = '';
    this.refresh();
  }

  refresh() {
    console.log(this.filtergetdata.ticketNo);
    console.log(this.ticketno);
    if (this.filtergetdata.ticketNo) {
      console.log('ookoko');
      this.ticketno = this.filtergetdata.ticketNo;
    }
    console.log(this.ticketno);
    this.subs.unsubscribe();
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    this.goCustomer = '';
    this.subs.unsubscribe();
    this.getServiceData(this.projectid);
  }

  projectChange(event: any) {
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';

    const project = event.target.value;
    this.getServiceData(project);
  }
  searchDate() {
    this.searchLoading = true;
    this.data = [];
    console.log(this.projectid);
    this.getServiceData(this.projectid);
  }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log('true');
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log('false');
      if (event == this.lastPage && this.lastPage != '') {
        console.log('yes');
        console.log(this.currentPage);
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log('no');
        this.currentPage = event;

        if (event > this.data.length / this.itemPerPage) {
          this.getServiceData(this.projectid);
        }
      }
    }
  }

  getServiceTypes() {
    this.getServiceType = true;
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.crmForm.orgid,
        newpath: true,
      };
      this.kunyekService.getServiceType(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.data = res.list;
            this.servcietypelist = res.list;
            // if (this.servcietypelist.length > 0) {
            //   this.typename = this.servcietypelist[0].name;
            // }
            this.getServiceType = false;
            resolve('true');
          } else {
            this.getServiceType = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('true');
          }
        },
        (err) => {
          this.getServiceType = false;
          resolve('err');
        },
      );
    });
  }

  getStatusList() {
    return new Promise((resolve) => {
      this.getSatatusType = true;
      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.crmForm.orgid,
      };
      this.kunyekService.getStatusType(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.statusLists = res.list;
            if (this.customercare) {
              this.statusListsCustomer = [];
              for (let i = 0; i < this.statusLists.length; i++) {
                if (
                  this.statusLists[i].code != '0030' &&
                  this.statusLists[i].code != '0051' &&
                  this.statusLists[i].code != '0052' &&
                  this.statusLists[i].code != '0050'
                ) {
                  this.statusListsCustomer.push({
                    code: this.statusLists[i].code,
                    name: this.statusLists[i].name,
                    check: false,
                  });
                }
              }
              var type = this.statusListsCustomer
                .map((x: any) => x.code)
                .indexOf('0010');
              this.statusListsCustomer[type].check = true;
            }

            // res.list.map((stu: any) => {
            //   this.statusLists.push({
            //     "autoid": stu.autoid,
            //     "code": stu.code,
            //     "name": stu.name,
            //     "orgid": stu.orgid,
            //   });
            // });
            this.statusLists.push({
              autoid: '',
              code: 'Not Completed',
              name: 'Not Completed',
              orgid: '[]',
            });

            if (this.statusLists.length > 0) {
              this.statusname = this.statusLists[0].name;
            }
            for (let i = 0; i < this.statusLists.length; i++) {
              if (this.statusLists[i].code == '0010') {
                this.statusLists[i].check = true;
              } else {
                this.statusLists[i].check = false;
              }
            }
            console.log('.......statuslist>>>>');
            console.log(this.statusLists);
            this.getSatatusType = false;
            resolve('true');
          } else {
            resolve('false');
            this.getSatatusType = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false');
          this.getSatatusType = false;
        },
      );
    });
  }

  getPriorityList() {
    return new Promise((resolve) => {
      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.crmForm.orgid,
      };
      this.kunyekService.getPriority(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.priority = res.list;
            resolve('true');
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('false');
          }
        },
        (err) => {
          resolve('false');
        },
      );
    });
  }

  itempercount: any = '';
  exportid: any = '';
  getInterval: any;
  exportstatus: any = '';
  originfileExport: any = '';
  exportinterval() {
    const data = {
      orgid: this.crmForm.orgid,
      id: this.exportid,
      type: '003',
    };
    this.kunyekService.exportcrmservice(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.exportstatus = res.status;
          if (this.exportstatus == '001') {
            this.originfileExport = res.originfile;
          } else if (this.exportstatus == '004' || this.exportstatus == '005') {
            this.excelexportLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
            clearInterval(this.getInterval);
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.excelexportLoading = false;
          clearInterval(this.getInterval);
        }
      },
      (err) => {
        this.excelexportLoading = false;
        clearInterval(this.getInterval);
      },
    );
  }

  async downloadSheet() {
    this.downloaddata = JSON.parse(JSON.stringify(this.data));
    var date = new Date();

    var time = this.datepipe.transform(date, 'yyyy-MM-dd');

    var name = 'Service_' + time + '.xlsx';

    // if (this.itemPerPage > this.total) {
    //   this.itempercount = this.itemPerPage;
    // } else {

    //   this.itempercount = this.total - this.data.length;
    //   this.excelexportLoading = true;
    await this.getServiceDataDownload(this.projectid);
    // }
    this.getInterval = setInterval(() => {
      if (
        this.exportstatus == '001' ||
        this.exportstatus == '004' ||
        this.exportstatus == '005'
      ) {
        clearInterval(this.getInterval);
        this.http
          .get(this.originfileExport, { responseType: 'blob' })
          .subscribe((response: any) => {
            saveAs(response, name);
          }),
          (error: any) => {
            this.messageService.openSnackBar('Download failed.', 'warn');
          };
        this.excelexportLoading = false;
        this.exportstatus = '';
        this.exportid = '';
      } else {
        // this.excelexportLoading = false;
        this.exportinterval();
      }
    }, 3000);

    console.log('Okone');
    var data: any = [];

    console.log(this.downloaddata);
    // this.downloaddata.map((service: any) => {

    //   var futureDate = new Date();

    //   if (this.customercare == true) {
    //     data.push({
    //       "Ticket No.": service.ticketno,
    //       "Ref No.": service.refno,
    //       "Description": service.servicedesc,
    //       "Project": service.project,
    //       "Product": service.product,
    //       "Customer": service.name,
    //       "Priority": service.priorityname,
    //       "Type": service.longtypename,
    //       "Status": service.status == '0010' ? 'New' : service.status == '0040' ? 'Completed' : service.status == '00900' ? 'Closed' : 'WIP',
    //       "Start Date": service.startdatetime,
    //       "End Date": service.status == '0040' || service.status == '00900' ? service.enddatetime : '',
    //       "Target Date": service.targetdatetimeshow,
    //       "Due Date": service.duedatetimeshow,
    //       "Version": service.version,
    //       "Age (Days)": service.ageday,
    //       "Assigned To": service.assignedtoname,
    //       "Reportedby": service.reportedbyname,
    //     });
    //   } else {
    //     data.push({
    //       "Ticket No.": service.ticketno,
    //       "Ref No.": service.refno,
    //       "Description": service.servicedesc,
    //       "Project": service.project,
    //       "Product": service.product,
    //       "Customer": service.name,
    //       "Priority": service.priorityname,
    //       "Type": service.longtypename,
    //       "Status": service.statusname,
    //       "Start Date": service.startdatetime,
    //       "End Date": service.status == '0040' || service.status == '00900' ? service.enddatetime : '',
    //       "Target Date": service.targetdatetimeshow,
    //       "Due Date": service.duedatetimeshow,
    //       "Version": service.version,
    //       "Age (Days)": service.ageday,
    //       "Assigned To": service.assignedtoname,
    //       "Reportedby": service.reportedbyname,
    //     });
    //   }

    // });
    // this.allinoneService.exportEcecl(data, name);
  }

  goCrm() {
    this.router.navigateByUrl('service');
  }

  removeDate() {
    this.filterstartdate = '';
    this.filterenddate = '';
    this.filterstartdate = '';
    this.filterstartRdate = '';
    this.checkdate = false;
    this.filtergetdata = [];
    this.ticketno = '';
    const customerid = this.route.snapshot.queryParamMap.get('id') || '';
    // this.filtergetdata.customerid = customerid ? customerid : '';
    // this.filtergetdata.customerName = '';
    this.productadmin = false;
    console.log('oooo');
    console.log(this.filtergetdata);
    this.refresh();
  }

  searchbool: any = false;

  // .

  filterByDate() {
    if (this.gettingData == false) {
      const customerid =
        this.route.snapshot.queryParamMap.get('customerid') || '';
      console.log(this.filtergetdata);
      var type = this.customers
        .map((x: any) => x.customerid)
        .indexOf(this.customersmid);
      if (type > -1) {
        this.filtergetdata.customerValList = this.customers[type];
      }
      if (this.filtergetdata.productid) {
        var type2 = this.productListdata
          .map((x: any) => x.productid)
          .indexOf(this.filtergetdata.productid);
        console.log('typei111' + type2);
        if (type2 > -1) {
          this.filtergetdata.productIDList = this.productListdata[type2];
        }
      } else {
        var type1 = this.productListdata
          .map((x: any) => x.productid)
          .indexOf(this.productsmid);
        console.log('typei111' + type1);
        if (type1 > -1) {
          this.filtergetdata.productIDList = this.productListdata[type1];
        }
      }

      if (this.filtergetdata.cusgroupid) {
        var groupid = this.customergroupList
          .map((x: any) => x.groupid)
          .indexOf(this.filtergetdata.cusgroupid);
        console.log('typei111' + type2);
        if (groupid > -1) {
          this.filtergetdata.customergroupidlist =
            this.customergroupList[groupid];
        }
      }

      if (this.customercare) {
        if (this.filtergetdata.branchid) {
          console.log('11111111111');
          var branchtypecus = this.customers
            .map((x: any) => x.customerid)
            .indexOf(this.customeridcare);
          console.log(branchtypecus);

          console.log(this.customers[branchtypecus]);
          if (this.customers[branchtypecus]['branchlist'].length > 0) {
            var branchid = this.customers[branchtypecus]['branchlist']
              .map((x: any) => x.branchid)
              .indexOf(this.filtergetdata.branchid);
            if (branchid > -1) {
              this.filtergetdata.customerbranchlist =
                this.customers[branchtypecus]['branchlist'][branchid];
            }
          }
        }
      } else {
        if (this.filtergetdata.branchid) {
          console.log('11111111111');
          var branchtypecus = this.customers
            .map((x: any) => x.customerid)
            .indexOf(this.filtergetdata.customerid);
          console.log(branchtypecus);

          console.log(this.customers[branchtypecus]);
          if (this.customers[branchtypecus]['branchlist'].length > 0) {
            var branchid = this.customers[branchtypecus]['branchlist']
              .map((x: any) => x.branchid)
              .indexOf(this.filtergetdata.branchid);
            if (branchid > -1) {
              this.filtergetdata.customerbranchlist =
                this.customers[branchtypecus]['branchlist'][branchid];
            }
          }
        }
      }

      var type2 = this.projectList
        .map((x: any) => x.projectid)
        .indexOf(this.projectsmid);
      if (type2 > -1) {
        this.filtergetdata.projectIDList = this.projectList[type2];
      }

      console.log(this.filtergetdata);
      if (this.customercare) {
        const dialog = this.dialog.open(CrmServiceFilterbydateComponent, {
          height: 'auto',
          // maxWidth: '850px',
          //  minWidth: '250px',
          // maxWidth: this.isMobileView ? '250px' : "850",
          // minWidth: this.isMobileView ? '50px' : "850",
          closeOnNavigation: true,
          panelClass: [
            'mat-typography',
            'dialog-container-advansearch-customer',
          ],
          data: {
            startdate: this.filterstartdate,
            enddate: this.filterenddate,
            rangestartdate: this.filterstartRdate,
            checkdate: this.checkdate,
            data: this.filtergetdata,
            check: customerid == '' ? false : true,
            customercare: this.customercare ? this.customercare : false,
          },
        });
        dialog.afterClosed().subscribe((dialogResult: any) => {
          if (dialogResult) {
            this.filtergetdata = dialogResult.data;
            this.filterenddate = dialogResult.enddate;

            console.log(this.filtergetdata);
            console.log('okokko');
            this.filterstartRdate = dialogResult.rangestartdate;
            this.checkdate = dialogResult.checkdate;
            this.ticketno = this.filtergetdata.ticketNo;
            this.searchTextService = '';
            this.filterstartdateshow = dialogResult.startdateshow;
            this.filterenddateshow = dialogResult.enddateshow;
            // this.filtergetdata.productid

            this.goCusBol = false;
            this.total = 0;
            this.data = [];
            this.sortby = '';
            this.nextDisabled = false;
            this.currentPage = 1;
            this.lastPage = '';
            this.goCustomer = '';
            if (this.isAdmin == false) {
              for (let i = 0; i < this.productListdata.length; i++) {
                if (
                  this.productListdata[i].productid ==
                  this.filtergetdata.productid
                ) {
                  if (this.productListdata[i].productadmins.length > 0) {
                    this.productadmin = true;
                  } else {
                    this.productadmin = false;
                  }
                }
              }
            }
            console.log('>..');
            console.log(this.productadmin);

            console.log(this.statuscode);

            this.getServiceData('');
          }
        });
      } else {
        const dialog = this.dialog.open(CrmServiceFilterbydateComponent, {
          height: 'auto',
          // maxWidth: '850px',
          //  minWidth: '250px',
          // maxWidth: this.isMobileView ? '250px' : "850",
          // minWidth: this.isMobileView ? '50px' : "850",
          closeOnNavigation: true,
          panelClass: ['mat-typography', 'dialog-container-advansearch'],
          data: {
            startdate: this.filterstartdate,
            enddate: this.filterenddate,
            rangestartdate: this.filterstartRdate,
            checkdate: this.checkdate,
            data: this.filtergetdata,
            check: customerid == '' ? false : true,
            customercare: this.customercare ? this.customercare : false,
          },
        });
        dialog.afterClosed().subscribe((dialogResult: any) => {
          if (dialogResult) {
            this.filtergetdata = dialogResult.data;
            this.filterenddate = dialogResult.enddate;

            console.log(this.filtergetdata);
            console.log('okokko');
            this.filterstartRdate = dialogResult.rangestartdate;
            this.checkdate = dialogResult.checkdate;
            console.log('test');
            console.log(this.filtergetdata);
            this.ticketno = this.filtergetdata.ticketNo;
            this.searchTextService = '';
            this.filterstartdateshow = dialogResult.startdateshow;
            this.filterenddateshow = dialogResult.enddateshow;
            // this.filtergetdata.productid

            this.goCusBol = false;
            this.total = 0;
            this.data = [];
            this.sortby = '';
            this.nextDisabled = false;
            this.currentPage = 1;
            this.lastPage = '';
            this.goCustomer = '';
            if (this.isAdmin == false) {
              for (let i = 0; i < this.productListdata.length; i++) {
                if (
                  this.productListdata[i].productid ==
                  this.filtergetdata.productid
                ) {
                  if (this.productListdata[i].productadmins.length > 0) {
                    this.productadmin = true;
                  } else {
                    this.productadmin = false;
                  }
                }
              }
            }
            console.log('>..');
            console.log(this.productadmin);

            console.log(this.statuscode);
            this.getServiceData('');
          }
        });
      }
    }
  }

  productListdata: any = [];
  getProduct() {
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.crmForm.orgid,
    };
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productListdata = res.list;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {},
    );
  }

  goSummary() {
    this.location.back();
    // this.router.navigateByUrl('service/summary')
  }
  goSummarySelfservice() {
    this.router.navigateByUrl('service');
  }
  backCustomerbreadcrumb() {
    // /services
    if (
      this.router.url.startsWith('/service') ||
      this.router.url.startsWith('/services')
    ) {
      this.router.navigateByUrl('service/customers');
    } else {
      this.router.navigateByUrl('sales/customers');
    }
  }
  backCustomerbreadcrumbedit() {
    const customerid =
      this.route.snapshot.queryParamMap.get('customerid') || '';
    if (
      this.router.url.startsWith('/service') ||
      this.router.url.startsWith('/services')
    ) {
      this.router.navigateByUrl(`service/customers?id=${customerid}`);
    } else {
      this.router.navigateByUrl(`sales/customers?id=${customerid}`);
    }
  }

  goassignedTo(item: any) {
    console.log(item);
    const assignedid = item.assignedto;
    console.log('>>>>>>' + item.picid);
    console.log('>>>>>>' + assignedid);
    // ;

    this.router.navigateByUrl(`sales/service/pic?assignedto=${assignedid}`);
  }
  importCustomer() {
    this.customerAdd = true;
    this.isExcel = true;
  }
  isExcelLead: boolean = false;
  importLead() {
    this.isExcelLead = true;
    this.leadAdd = true;
  }
  closeAdvanSearch(data: any) {
    if (data == 1) {
      this.filtergetdata.ticketNo = '';
      this.ticketno = '';
    } else if (data == 2) {
      this.filtergetdata.assignedtome = false;
    } else if (data == 3) {
      this.filtergetdata.picid = '';
      this.filtergetdata.picName = '';
    } else if (data == 4) {
      this.filtergetdata.priorityid = '';
      this.filtergetdata.priorityName = '';
    } else if (data == 5) {
      this.filtergetdata.projectName = '';
      this.filtergetdata.projectID = '';
    } else if (data == 6) {
      this.filtergetdata.productName = '';
      this.filtergetdata.productid = '';
      this.productadmin = false;
    } else if (data == 7) {
      this.filtergetdata.moduleid = '';
      this.filtergetdata.modulename = '';
      this.filtergetdata.modulelist = [];
    } else if (data == 8) {
      this.filtergetdata.customerid = '';
      this.filtergetdata.customerName = '';
    } else if (data == 9) {
      this.checkdate = false;
      this.filterenddate = '';
    } else if (data == 10) {
      this.checkdate = false;
      this.filterstartRdate = '';
    } else if (data == 11) {
      this.filtergetdata.reportedby = '';
      this.filtergetdata.reportedbyid = '';
    } else if (data == 12) {
      this.filtergetdata.age = '';
    } else if (data == 13) {
      this.filtergetdata.refno = '';
    } else if (data == 14) {
      this.filtergetdata.description = '';
    } else if (data == 15) {
      this.filtergetdata.detail = '';
    } else if (data == 16) {
      this.filtergetdata.recurring = '';
    } else if (data == 17) {
      this.filtergetdata.cusgroupid = '';
      this.filtergetdata.groupname = '';
    } else if (data == 18) {
      this.filtergetdata.branchid = '';
      this.filtergetdata.branchname = '';
    }

    console.log(this.filtergetdata);
    this.refresh();
  }

  getC() {
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        // orgid: this.allinoneService.selectedOrgCRM
        //   ? this.allinoneService.selectedOrgCRM
        //   : this.organizations[0].orgid,
      };
      this.subs.sink = this.kunyekService.getCustomercare(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.selfcustomer = res.list;
            resolve('true');
          } else {
            resolve('false');
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false');
          this.messageService.openSnackBar(err, 'warn');
        },
      );
    });
  }
  getCustomers() {
    this.gettingCustomers = true;
    const data = {
      domainid: this.crmForm.domainid,
      orgid: this.crmForm.orgid,
    };
    this.kunyekService.getCrmCustomers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.customers = res.list;
          console.log('customer re get');
          this.customers = res.list;
          // if (res.list[i]["customerid"] == this.serviceForm.customerid) {
          //     this.contactlist = res.list[i]["contactlist"]
          //   }
          // var currnetCustomer = res.list.filter((ct: any) => ct.customerid == this.serviceForm.customerid)
          // if (currnetCustomer.length > 0) {
          //   this.contactlist = currnetCustomer[0].contactlist
          // }
          // resolve('true')
          this.gettingCustomers = false;
        } else {
          this.gettingCustomers = false;
          this.messageService.openSnackBar(res.message, 'warn');
          // resolve('false');
        }
      },
      (err) => {
        // resolve('false');
        this.gettingCustomers = false;
      },
    );
    // });
  }
  customerSelect(customer: any) {
    this.ticketno = '';
    if (customer) {
      this.filtergetdata.customerid = customer.customerid;
      this.searchTextService = customer.customername;
      this.checkcustomer = true;
      this.filtergetdata.customername = '';
    } else {
      this.ticketno = this.searchTextService;
    }

    this.refresh();
    // this.filtergetdata.customerid
  }

  removeStatus() {
    this.statussearchcus = '';
    this.statussearch = '';
  }

  removeService() {
    this.servicesearch = '';
  }

  stageChange(data: any) {
    if (data != undefined && !Array.isArray(data)) {
      this.crmForm.stageid = data.stageid;
    } else {
      this.crmForm.stageid = '';
    }
  }
  changeStatus(data: any) {
    if (data != undefined && !Array.isArray(data)) {
      this.crmForm.status = data.key;
    } else {
      this.crmForm.status = '';
    }
  }

  searchWithTicketCustomerChange(event: KeyboardEvent) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      console.log('Searchwith');
      this.ticketno = '';
      this.filtergetdata.customerid = '';
    }
  }

  // searchTicket(data: any) {
  //   this.ticketno = data
  //   this.checkcustomer = true;
  //   this.refresh()

  // }

  searchTicket(data: any) {
    console.log(data);
    var type = this.customers.map((x: any) => x.customername).indexOf(data);
    if (type > -1) {
      this.ticketno = '';
    } else {
      this.ticketno = data;
    }
    this.checkcustomer = true;
    this.refresh();
  }
  internalvalue: any = '';
  excludeinternal: boolean = false;
  internalname: any = 'All';
  checkInternal(data: any) {
    if (data == 'all') {
      this.internal = true;
      this.excludeinternal = false;
      this.internalname = 'All';
      this.internalvalue = '';
      this.refresh();
    }
    if (data == 'internal') {
      this.internal = true;
      this.excludeinternal = false;
      this.internalname = 'Internal';
      this.internalvalue = '001';
      this.refresh();
    } else if (data == 'exclude') {
      this.internal = false;
      this.excludeinternal = false;
      this.internalname = 'Customers';
      this.internalvalue = '002';
      this.refresh();
    }
  }

  checkExcludeInternal(data: any) {
    console.log(data.target.checked);
    if (data.target.checked == true) {
      this.internal = false;
      this.excludeinternal = true;
      this.internalvalue = '002';
      this.refresh();
    } else {
      this.internal = false;
      this.excludeinternal = false;
      this.internalvalue = '';
      this.refresh();
    }
  }
  getGroupCustomer() {
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.crmForm.orgid,
    };
    this.kunyekService.getcustomergroup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.customergroupList = [];
          res.list.map((item: any) => {
            this.customergroupList.push({
              groupid: item.groupid,
              name: item.name == '' ? item.customerlist : item.name,
              customerlist: item.customerlist,
              cusotmernames: item.cusotmernames,
            });
          });
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      },
    );
  }

  backservicmonthly() {
    this.location.back();
  }

  getOrgTheme() {
    const domain = this.allinoneService.getDomain();
    const data = {
      orgid: this.allinoneService.selectedOrgCRM,
      domainid: this.allinoneService.getDomain().domainid,
      appid: domain.appid,
      userid: this.allinoneService.getKunyekUserId(),
      appidno: 'com.nirvasoft.app058',
    };
    this.kunyekService.getTheme(data).subscribe({
      next: (res) => {
        if (res.returncode === '300') {
          this.allinoneService.gallery = res.list.Gallery;
          this.allinoneService.attachments = res.list.Attachements;
        }
      },
    });
  }
}
