<div class="fix">
    <div class="title-container mb-3">
        <div class="title">
            Profile
        </div>
        <div class="close-btn">
            <!-- <button mat-icon-button mat-dialog-close [disabled]="saveLoading"> -->
            <button mat-icon-button mat-dialog-close [disabled]="isBlocking" (click) = "close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <div *ngIf="loading && !error" class="loader">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div *ngIf="!loading && error">
        <!-- <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div> -->
        <div style="text-align: center;
        margin-top: 50px;
        color: red;">
            <mat-icon>info_outline</mat-icon>
        </div>
        <div style="text-align: center;
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;">Oops! Something went wrong..</div>
        <div style="margin-top: 10px;
        margin-left: 120px;">
            <button mat-icon-button style="width: 100px; color: var(--main-color) !important;"
                (click)="getContactList(uid)">
                <mat-icon>refresh</mat-icon> <span> Refresh</span>
            </button>
        </div>
    </div>

    <div *ngIf="!loading && !error">
        <div class="profile-img-container mt-3">
            <div class="conversation-summary-avatar conversation-summary-avatar-group profile-img mb-2"
                data-number-of-thumbnails="1" (click)="imageViewer()">
                <div class="conversation-summary-avatar-group-member-wrapper" (click)="imageViewer()">
                    <img class="conversation-summary-avatar-group-member-wrapper" style="object-fit: cover;"
                        [defaultImage]="'../../../assets/images/bg.jpg'"
                        [lazyLoad]="user.photo ? user.photo : '../../../assets/images/profile.png' ">
                </div>
            </div>
            <div>
                <b>{{user.username}}</b>
            </div>
        </div>
        <mat-list>
            <ng-container *ngIf="user.newuserid != ''">
                <mat-list-item class="list-item" *ngIf="allinoneService.isPhone(user.newuserid)">
                    <mat-icon mat-list-icon class="icon">phone</mat-icon>
                    <div mat-line class="text">{{user.newuserid}}</div>
                </mat-list-item>
                <mat-list-item class="list-item" *ngIf="!allinoneService.isPhone(user.newuserid)">
                    <mat-icon mat-list-icon class="icon">email</mat-icon>
                    <div mat-line class="text">{{user.newuserid}}</div>
                </mat-list-item>
            </ng-container>
            <ng-container *ngIf="!user.newuserid">
                <mat-list-item class="list-item" *ngIf="allinoneService.isPhone(user.userid)">
                    <mat-icon mat-list-icon class="icon">phone</mat-icon>
                    <div mat-line class="text">{{user.userid}}</div>
                </mat-list-item>
                <mat-list-item class="list-item" *ngIf="!allinoneService.isPhone(user.userid)">
                    <mat-icon mat-list-icon class="icon">email</mat-icon>
                    <div mat-line class="text">{{user.userid}}</div>
                </mat-list-item>
            </ng-container>
            
            <div *ngIf="allinoneService.getKunyekUserId() != user.userid">
                <mat-list-item class="list-item add-friend" *ngIf="user.friend != 'true'" (click)="addContact()">
                    <mat-icon mat-list-icon class="icon">person_add</mat-icon>
                    <div mat-line class="text">Add Friend</div>
                </mat-list-item>
            </div>
            <div *ngIf="allinoneService.getKunyekUserId() != user.userid && user.block != 'true' && user.friend == 'true'">
                <mat-list-item class="list-item block" (click)="blockUser()" *ngIf="!isBlocking">
                    <mat-icon mat-list-icon class="icon">block</mat-icon>
                    <div mat-line class="text">Block this contact</div>
                </mat-list-item>
            </div>
            <mat-list-item class="list-item" *ngIf="isBlocking">
                <div mat-list-icon class="icon">
                    <span class="spinner-border spinner-border-sm" style="margin-bottom: 2px;" role="status"
                        aria-hidden="true"></span>
                </div>
                <div mat-line class="text">Blocking this contact</div>
            </mat-list-item>
        </mat-list>
    </div>
</div>


<div class="modal" id="profile-image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-more-container">
              <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="downloadImage(imagename)">
                <mat-icon>download</mat-icon>
              </button>
              <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close" (click)="CloseImageViewer()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
          <div class="modal-body">
            <div>
              <img [src]="imagename" alt="" class="image-view">
            </div>
    
          </div>
        </div>
      </div>
  </div>