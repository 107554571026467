<!-- checkModeal -->
<div class="" [ngClass]="{'container-fluid':!checkModeal}">
    <div [ngClass]="{'content':!checkModeal}">

        <!-- <div >
    <div > -->
        <app-crm-breadcrumb *ngIf="assignedto == '' && !checkModeal && !addPerson && !editPerson && !isExcel" [s_breadcrumb]="'Person in Charge'">
        </app-crm-breadcrumb>
        <app-crm-breadcrumb *ngIf="assignedto == '' && !checkModeal && addPerson && !isExcel && !isExcel" [s_breadcrumb]="'Person in Charge'" [t_breadcrumb]="'New'" (emit)="cancelFunction()">
        </app-crm-breadcrumb>
        <app-crm-breadcrumb *ngIf="assignedto == '' && !checkModeal && !addPerson && isExcel" [s_breadcrumb]="'Person in Charge'" [t_breadcrumb]="'New'" (emit)="cancelFunction()">
        </app-crm-breadcrumb>
        <app-crm-breadcrumb *ngIf="assignedto == '' && !checkModeal && editPerson && !isExcel" [s_breadcrumb]="'Person in Charge'" [t_breadcrumb]="'Edit'" (emit)="cancelFunction()">
        </app-crm-breadcrumb>

        <app-crm-breadcrumb *ngIf="assignedto != ''  && !checkModeal && !isExcel" [s_breadcrumb]="'Service Tickets'" [t_breadcrumb]="'Person in Charge'" (emit)="goService()">
        </app-crm-breadcrumb>

        <!-- editPerson -->
        <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="checkModeal" [ngClass]="{'paddingleft':checkModeal}">
            <div class="label title" style="font-size: 17px;">Person in Charge</div>
            <div class="close-btn" *ngIf="checkModeal">
                <button mat-icon-button (click)="close()">
                        <mat-icon>close</mat-icon>
                    </button>
            </div>
        </div>



        <div class="d-flex flex-wrap" *ngIf="!addPerson && !editPerson && !isExcel">
            <div class="showbtn me-auto mb-2">
                <button class="btn btn-custom" (click)="newPic()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>
            </div>
            <div *ngIf="checkModeal" class="mb-2">
                <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" [(ngModel)]="searchpipe" style="font-family: FontAwesome,Ubuntu;">
                <!-- <pagination-template *ngIf="picListdata.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
                    <div class="d-flex align-items-center justify-content-center">
                        <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
                            <span class="me-1">&laquo;</span>&nbsp;Prev
                        </div>
                        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </span>
                            <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
                            Next&nbsp;<span class="ms-1">&raquo;</span>
                        </div>
                    </div>
                </pagination-template> -->
            </div>
        </div>
        <perfect-scrollbar [ngClass]="{'event-container' : checkModeal }" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
            <div class="close-save-button mb-2" *ngIf="addPerson || editPerson">
                <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader *ngIf="this.personForm.userid || this.personForm.username" (click)="submitPerson()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
                </app-button-loader>
                <button *ngIf="!this.personForm.userid && !this.personForm.username" class="btn btn-custom" type="button" disabled>
                    Save
                </button>
                <!--  -->
            </div>

            <div *ngIf="isExcel ">
                <div class="mb-2 d-flex justify-content-start header">
                    <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" [disabled]="addcustomer">
                        Cancel
                    </button>

                    <div class="close-save-button ">
                        <app-button-loader class="btn-custom" (click)="submitExcel()" style="border-radius: 5px;" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="addcustomer" *ngIf="finalMemberList.length > 0">
                        </app-button-loader>
                        <button *ngIf="finalMemberList.length == 0" class="btn btn-custom" disabled>
                            Save
                        </button>
                    </div>

                </div>

                <div class="mb-2">
                    <label for="file" class="form-label">Excel Template (<span class="cc-label"
                            (click)="downloadSampleFile()">Download Sample Template</span>)</label>
                    <div class="input-group">
                        <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx" id="formFile" #excelFile [disabled]="excel">
                        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="excel" (click)="removeExcel()">Remove</button>
                        <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;" id="btnGroupAddon2" [disabled]="!excel || checkingExcelFile" (click)="checkExcelFile()">
                            <span *ngIf="!checkingExcelFile">
                                Check
                            </span>
                            <span *ngIf="checkingExcelFile">
                                <span class="spinner-border spinner-border-sm button-spinner" role="status"
                                    aria-hidden="true"></span>
                                <span class="mx-1">Checking</span>
                            </span>
                        </button>
                    </div>
                    <div role="alert" *ngIf="excelerror" style="color: red;padding: 6px;">
                        {{allinoneService.input_err_msg}}
                    </div>

                </div>
                <div style="color : green" *ngIf="allok">
                    <i class="fa fa-check me-2"></i> Template is correct.
                </div>
                <div class="row">
                    <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0">
                        <div class="d-flex align-items-center">
                            <mat-icon class="me-2">
                                error
                            </mat-icon>
                            <div>
                                Error
                            </div>
                        </div>
                        <hr>
                        <ul style="padding-left: 1rem;">
                            <li *ngFor="let e of errorList;">
                                In line no. {{e.line}}, {{e.error}}
                            </li>
                        </ul>
                    </div>
                    <div class="alert alert-warning custom-box" *ngIf="duplicateList.length > 0 || warningList.length >0">
                        <div class="d-flex align-items-center">
                            <mat-icon class="me-2">
                                warning
                            </mat-icon>
                            <div>
                                Warning
                            </div>
                        </div>
                        <hr>
                        <ul style="padding-left: 1rem;">
                            <li *ngFor="let du of duplicateList;">
                                "{{du}}" is duplicated.
                            </li>
                            <li *ngFor="let e of warningList;">
                                In line no. {{e.line}}, {{e.error}}
                            </li>
                        </ul>


                    </div>
                </div>

            </div>

            <div class="row" *ngIf="addPerson || editPerson && !isExcel">
                <div class="mb-3 col-md-6">
                    <label for="name" class="form-label">Name</label>

                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="personForm.username">
                    <div class="invalid-feedback-length" *ngIf="personForm.username.length > 255">
                        {{allinoneService.lengthCheck("Name",255)}}
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="userid" class="form-label">User ID</label>
                    <input type="text" [matAutocomplete]="automember" class="form-control" id="userid" name="userid" [(ngModel)]="personForm.userid" #userid="ngModel" [disabled]="editPerson && checkuseridDisabled">
                    <div class="invalid-feedback-length" *ngIf="personForm.userid.length > 50">
                        {{allinoneService.lengthCheck("User ID",50)}}
                    </div>
                    <mat-autocomplete #automember="matAutocomplete">
                        <mat-option *ngFor="let i of members | crmFilter : personForm.userid:searchNonea:assignedto:13" (onSelectionChange)="selectMember(i.username,i.userid)" (click)="selectMember(i.username,i.userid)">
                            {{i.username != '' ? i.username : ''}}&nbsp;{{i.userid}}
                        </mat-option>

                    </mat-autocomplete>
                </div>

            </div>


            <div *ngIf="!addPerson && !editPerson && !isExcel">

                <div class=" w-100  justify-content-start align-items-center d-flex flex-wrap" *ngIf="!checkModeal">
                    <!-- <div class="input-group  me-2" style="width: 300px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchtext" [ngClass]="{'c-search-input-with-clear' : searchtext }" style="font-family: FontAwesome,Ubuntu;">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchtext" (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                    </div> -->
                    <div class="input-group me-2 mb-2 " style="width: 300px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchtexts" [ngClass]="{'c-search-input-with-clear' : searchtexts }" style="font-family: FontAwesome,Ubuntu;"
                            (keyup.enter)="onSearch()">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchtexts" (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" (click)="onSearch()">
                            <mat-icon class="c-icon">search</mat-icon>
                        </button>

                    </div>

                    <div class="dropdown  me-2 mb-2 ">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==itemsPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class=" mb-2">
                        <button class="btn btn-custom" (click)="refresh()" matTooltip="Refresh">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                    <div class="line mb-2"></div>
                    <!-- <button class="btn btn-custom me-2 mb-2" (click)="exportpic()" matTooltip="Export Person In Charge To Excel">
                        <img class="excel-icon" src="../../../assets/images/excel.png">
                        Export
                    </button> -->
                    <div class="mb-2 me-2">
                        <button type="button " class="btn btn-custom " (click)="exportpic() " matTooltip="Export Person In Charge To Excel" [disabled]="excelexportLoading ">
                            <img *ngIf="!excelexportLoading " class="excel-icon "
                                src="../../../assets/images/excel.png ">
                            <span *ngIf="excelexportLoading " style="margin-right: 10px; "
                                class="spinner-border spinner-border-sm button-spinner " role="status "
                                aria-hidden="true "></span>
                            Export
                        </button>
                    </div>
                    <button class="btn btn-custom me-2 mb-2" (click)="importpic()" matTooltip="Export Person In Charge To Excel">
                        <img class="excel-icon" src="../../../assets/images/excel.png">
                        Import
                    </button>


                    <div class="line mb-2 "></div>
                    <div class="d-flex align-items-center mb-2 me-auto">
                        <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{total}}</span>
                    </div>
                    <div *ngIf="!gettingData" class="mb-2">
                        <pagination-template *ngIf="picListdata.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
                            <div class="d-flex align-items-center justify-content-center">
                                <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
                                    <span class="me-1">&laquo;</span>&nbsp;Prev
                                </div>
                                <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                    <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                        <span>{{ page.label }}</span>
                                    </span>
                                    <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                        <span>{{ page.label }}</span>
                                    </div>
                                </div>
                                <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
                                    Next&nbsp;<span class="ms-1">&raquo;</span>
                                </div>
                            </div>
                        </pagination-template>
                    </div>
                </div>






                <!-- matSort (matSortChange)="sortData($event)" -->
                <div>
                    <table class="table  table-borderless">
                        <thead class="table-header" style="position: sticky;top: 0;z-index:  2;">
                            <tr>
                                <!--  mat-sort-header="type" -->

                                <td scope="col" class="col-lg-6 col-md-6">Name</td>
                                <td scope="col" class="col-lg-6 col-md-6">User ID</td>
                                <td scope="col" class="col-lg-2 col-md-2">Action</td>

                            </tr>
                        </thead>
                        <tbody *ngIf="gettingData">
                            <tr>
                                <td colspan="3" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="gettingData== false" style="cursor: pointer;">
                            <tr *ngIf="picListdata.length == 0">

                                <td colspan="3" class="nodata" *ngIf="!view">Empty</td>
                                <td style="color: red;font-size: 14px;" colspan="3" class="nodata" *ngIf="view"><i class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}</td>
                            </tr>
                            <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
                            <!-- |  | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index" -->
                            <tr *ngFor="let item of picListdata | crmFilter :searchpipe:searchNonea:assignedto:7 | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage };;">



                                <td class="" (click)="editPIC(item)">
                                    <span>{{item.picname}}</span>
                                </td>
                                <td class="td-data" (click)="editPIC(item)">
                                    <span>{{item.userid}}</span>
                                </td>
                                <td class="d-flex justify-content-center">
                                    <button class="btn btn-outline-primary me-2" (click)="editPIC(item)">
                                        <i class='fa fa-edit'></i>
                                    </button>
                                    <button class="btn btn-outline-danger " (click)="deletePic(item.autoid)">
                                        <i class='fa fa-trash-o'></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!gettingData  && !addPerson && !editPerson">
                    <pagination-template *ngIf="picListdata.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
                        <div class="d-flex align-items-center justify-content-center">
                            <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
                                <span class="me-1">&laquo;</span>&nbsp;Prev
                            </div>
                            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                    <span>{{ page.label }}</span>
                                </span>
                                <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                    <span>{{ page.label }}</span>
                                </div>
                            </div>
                            <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
                                Next&nbsp;<span class="ms-1">&raquo;</span>
                            </div>
                        </div>
                    </pagination-template>
                </div>
            </div>

        </perfect-scrollbar>


    </div>
</div>