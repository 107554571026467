<!-- <h2 class="">Rules and Regulation</h2>
<div class="row">
    <div class="mb-3 col-md-12">
        <label for="name" class="form-label">Name</label>
        <input
          type="text"
          class="form-control"
          id="name"
          [(ngModel)]="submitForm.name"
          name="name"
          #name="ngModel"
          [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }"
          autocomplete="off"
          required
        />
        <div
          class="invalid-feedback"
          *ngIf="
            name.invalid ||
            ((name.dirty || name.touched) && formSubmitted)
          "
        >
        </div>
      </div>
    <div class="col-md-12">
        <label for="type" class="form-label"> Type </label>
        <select class="form-select mb-3" id="type" name="type" [(ngModel)]="submitForm.typeid" #type="ngModel">
            <option *ngFor="let ty of typeList" [value]="ty.id" [selected]="ty.id == submitForm.typeid">
            {{ ty.name }}
            </option>
        </select>
    </div>
    <div class="mb-3 col-md-12">
      <label for="description" class="form-label">
        Description
      </label>
      <textarea type="text" class="form-control" id="description" name="description" placeholder="Enter Description"
        [(ngModel)]="submitForm.description" rows="3"></textarea>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="file">Attachment</label>
      <div class="dragdrop form-control p-0 position-relative" name="file" id="file" [ngClass]="{
          'is-invalid':
            formSubmitted &&
            ((!isEditing && !fileSelected) ||
              (isEditing && !submitForm.filename))
        }">
        <ng-container *ngIf="
            fileSelected != '' ||
              (isEditing && submitForm.filename);
            else uploadTemplate
          ">
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3 td-edit"
            appDragDrop (onFileDropped)="uploadFile($event)" (click)="choosefile.click()">
            <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
            <div>
              <img [src]="
                  '../../../assets/images/' +
                  (submitForm.filetype == 'pdf'
                    ? 'pdf'
                    : 'image') +
                  '.png'
                " width="40px" />
            </div>
            <div class="ms-2">
              <strong>{{ submitForm.filename }}</strong>
            </div>
          </div>
          <button type="button" mat-icon-button class="remove-attachment" (click)="removeAttachment()">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </ng-container>
        <ng-template #uploadTemplate>
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
            (onFileDropped)="uploadFile($event)">
            <div class="me-3">Drag and drop file here</div>
            <div class="me-3">or</div>
            <div>
              <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
              <button type="button" class="btn btn-custom" (click)="choosefile.click()">
                Choose File
              </button>
            </div>
          </div>
        </ng-template>
      </div>
      <small>Only .pdf is allowed.</small>
    </div>
</div>
<div mat-dialog-actions>
  <div class="mb-3 w-100">
    <div class="d-flex align-items-center flex-row flex-wrap justify-content-end">
      <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
        Cancel
      </button>
      <app-button-loader (click)="submit()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
      </app-button-loader>
    </div>
  </div>
</div> -->
<!-- <button (click)="test()" >test</button> -->
<div class="d-flex align-items-center justify-content-between mb-3">
  <h3 class="m-0">Rules and Regulations</h3>
  <mat-icon class="td-edit" (click)="onDismiss()">close</mat-icon>
</div>
<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="row">
    <div class="col-md-12">
      <div class="form-group mb-3">
        <label class="form-label" for="name"> Name </label>
        <input type="text" class="form-control" placeholder="Enter Name" formControlName="name" id="name"
          name="name" [ngClass]="{
            'is-invalid':
              submitted &&
              formControl.name.invalid
          }" />
      </div>
    </div>
    <div class="col-md-12" id="form-container-anchor">
      <div class="form-group mb-3">
        <label class="form-label" for="type"> Type </label>
        <select formControlName="type" class="form-select" (change)="checkFile($event)" id="type" name="type">
          <option *ngFor="let item of typeList" [value]="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="mb-3 col-md-12">
      <label for="description" class="form-label">
        Description
      </label>
      <textarea type="text" class="form-control" id="description" name="description" placeholder="Enter Description"
        formControlName="description" rows="3"></textarea>
    </div>
    <div class="form-group mb-3">
      <label class="form-label" for="file"> Attachment </label>
      <!-- <i class="ms-1 fa fa-question-circle" matTooltip="Updating to different file type will also update the QR code." *ngIf="isEditing"></i> -->
      <div class="dragdrop form-control p-0  position-relative" name="file" id="file" [ngClass]="{
          'is-invalid':
            submitted &&
            ((!isEditing && !fileSelected) ||
              (isEditing && !formControl.filename.value))
        }">
        <ng-container *ngIf="
            fileSelected != '' ||
              (isEditing && formControl.filename.value);
            else uploadTemplate
          ">
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-start ps-3 td-edit"
            appDragDrop (onFileDropped)="uploadFile($event)" (click)="choosefile.click()">
            <!-- <div class="ms-2"> -->
            <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
            <div>
              <img [src]="
                  '../../../assets/images/' +
                  (formControl.filetype.value == 'pdf'
                    ? 'pdf'
                    : 'docx') +
                  '.png'
                " width="40px" />
            </div>
            <div class="ms-2">
              <strong>{{ formControl.filename.value }}</strong>
            </div>
            <!-- </div> -->

          </div>
          <button type="button" mat-icon-button class="remove-attachment" (click)="removeAttachment()">
            <mat-icon>remove_circle</mat-icon>
          </button>
        </ng-container>
        <ng-template #uploadTemplate>
          <div class="h-100 w-100 d-flex flex-row align-items-center justify-content-center" appDragDrop
            (onFileDropped)="uploadFile($event)">
            <div class="me-3">Drag and drop file here</div>
            <div class="me-3">or</div>
            <div>
              <input type="file" (change)="onFileSelected($event)" class="inputfile" #choosefile hidden />
              <button type="button" class="btn btn-custom" (click)="choosefile.click()">
                Choose File
              </button>
            </div>
          </div>

        </ng-template>
      </div>
      <small>Only .pdf is allowed.</small>
      <!-- <small *ngIf="isEditing"
        >Only .{{ allowedFileType }} is allowed.</small
      > -->
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="mb-3 w-100">
      <div class="d-flex align-items-center flex-row flex-wrap justify-content-end">
        <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
        </app-button-loader>
      </div>
    </div>
  </div>
</form>