import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cs365-breadcrumb',
  templateUrl: './cs365-breadcrumb.component.html',
  styleUrls: ['./cs365-breadcrumb.component.css']
})
export class Cs365BreadcrumbComponent implements OnInit {

  @Input() s_breadcrumb!: string;
  @Input() t_breadcrumb!: string;
  @Input() f_breadcrumb!: string;
  @Input() fi_breadcrumb!: string;


  @Output('emit')
  change: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit1')
  change1: EventEmitter<any> = new EventEmitter<any>();


  @Output('emit2')
  change2: EventEmitter<any> = new EventEmitter<any>();

  routeName: any = "";
  routeLink: any = "";
  constructor(private router: Router) {
    if (this.router.url.startsWith('/cs365')) {
      this.routeName = "Services" 
      this.routeLink = "/cs365";
    }
  }

  ngOnInit(): void {
  }
  goToSecond() {
    if (this.t_breadcrumb) {
      this.change.emit(false);
    }
  }
  goToThird() {
    if (this.f_breadcrumb) {
      this.change1.emit(false);
    }
  }
  goToFourth() {
    if (this.fi_breadcrumb) {
      this.change2.emit(false);
    }
  }

  goRoute() {

  }
}
