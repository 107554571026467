<div class="container-fluid">
  <div class="content">
    <!-- <app-hcm-breadcrumb *ngIf="!isAdding" s_breadcrumb="Recruitment" t_breadcrumb="Reasons" (emit)="goToRecruitment()">
    </app-hcm-breadcrumb>
    <app-hcm-breadcrumb *ngIf="isAdding" s_breadcrumb="Recruitment" t_breadcrumb="Reasons"
      [f_breadcrumb]="isEditing ? 'Edit Reason' :'New Reason'  " (emit)="goToRecruitment()"
      (emit1)="cancel()"></app-hcm-breadcrumb> -->


      <app-team-breadcrumb *ngIf="!isAdding" s_breadcrumb="Recruitment" t_breadcrumb="Reasons" (emit)="goToRecruitment()">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="isAdding" s_breadcrumb="Recruitment" t_breadcrumb="Reasons"
        [f_breadcrumb]="isEditing ? 'Edit Reason' :'New Reason'  " (emit)="goToRecruitment()"
        (emit1)="cancel()"></app-team-breadcrumb>

    <!-- Reason add form -->
    <ng-container *ngIf="isAdding">
      <div class="close-save-button mb-3">
        <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader (click)="submitReason()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="description" class="form-label">Description</label>
          <textarea class="form-control" id="description" [(ngModel)]="submitForm.description" name="description"
            rows="3" #description="ngModel" [ngClass]="{'is-invalid' : formSubmitted && description.invalid}"
            required></textarea>
        </div>
        <div class="col-md-12 mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="other" name="other" [(ngModel)]="submitForm.other" />
            <label class="form-check-label" for="other"> Other Reason </label>
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <div>
            <label for="description" class="form-label">Reason Type</label>
          </div>

          <div class="form-check form-check-inline me-5">
            <input [disabled]="isEditing" class="form-check-input" [(ngModel)]="submitForm.reasontype" type="radio"
              name="reasontype" id="request" value="001">
            <label class="form-check-label" for="request">Request</label>
          </div>
          <div class="form-check form-check-inline">
            <input [disabled]="isEditing" class="form-check-input" [(ngModel)]="submitForm.reasontype" type="radio"
              name="reasontype" id="reject" value="002">
            <label class="form-check-label" for="reject">Reject</label>
          </div>

        </div>
      </div>
    </ng-container>
    <!-- Reason Table -->
    <ng-container *ngIf="!isAdding">
      <button type="button" class="btn btn-custom mb-3" (click)="addReason()">
        <i class="fa fa-plus me-1"></i>Add
      </button>

      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Description"
            aria-label="Search by Description" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
          <div class="filtter-btn align-items-center" [matMenuTriggerFor]="typefiltermenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              Type&nbsp;:&nbsp;{{ typefiltername }}
              <mat-menu #typefiltermenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let i of typefilterlist" (click)="typefilterChange(i)">
                  {{ i.name }}
                </button>
              </mat-menu>
              <!-- <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span> -->
            </button>
          </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ ( reasonList | filter : searchText: 'requisitionreason' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Description" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="reasonList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>

      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-6" mat-sort-header="text">Description</td>
              <td scope="col" class="col-md-6" mat-sort-header="reasontype">Reason Type</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingReasonList">
            <tr>
              <td colspan="3" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingReasonList">
            <tr *ngIf="reasonList.length == 0">
              <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let data of reasonList | filter : searchText : 'requisitionreason' | paginate: { itemsPerPage: itemPerPage , currentPage : currentPage } ; let index = index ">
              <td class="td-data td-edit" (click)="editReason(data)">
                <span [title]="data.text">{{ data.text }}</span>
              </td>
              <td class="td-data td-edit" (click)="editReason(data)">
                <span [title]="data.reasontype">{{ data.reasontype == '001' ? 'Request' : 'Reject' }}</span>
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editReason(data)">
                  <i class="fa fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>