<div class="container-fluid">
    <div class="content">


        <div>

            <app-transportation-breadcrumb *ngIf="!addVehicle && !isEditing" s_breadcrumb="Vehicles">
            </app-transportation-breadcrumb>
            <app-transportation-breadcrumb *ngIf="addVehicle || isEditing" s_breadcrumb="Vehicles"
                [t_breadcrumb]=" isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()">
            </app-transportation-breadcrumb>



            <!--  (emit1)="cancels(2)"  -->
            <div *ngIf="!addVehicle && !isEditing">

                <div class="d-flex flex-wrap">
                    <div class="showbtn me-auto  mb-3">
                        <button class="btn btn-custom" (click)="new()">
                            <i class="fa fa-plus me-1"></i> New
                        </button>
                    </div>
                </div>
                <div class=" w-100 d-flex flex-wrap me-auto ">

                    <div class="input-group me-2 mb-2" style="width: 300px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            [(ngModel)]="searchText" style="font-family: FontAwesome,Ubuntu;">
                    </div>
                    <div class="dropdown mb-2 me-2">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==itemPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>

                    <!-- me-2 -->
                    <div class="me-2 mb-2 ">
                        <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>

                    <div class="line mb-2 "></div>
                    <div class="mb-2">
                        <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                            matTooltip="Export Vehicles">
                            <img class="excel-icon" src="../../../assets/images/excel.png">Export
                        </button>

                    </div>
                    <div class="line mb-2 "></div>
                    <div class="d-flex align-items-center me-auto mb-2 ">
                        <span class="total-count-text ">Total:&nbsp;</span> <span
                            class="total-count-num ">{{vehicleList.length}}</span>
                    </div>

                    <div>

                    </div>
                </div>


                <div>
                    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="carno">Car No.</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="carname">Name</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="maxpassenger">Max Passenger</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="type">Type</td>
                                    <td scope="col" class="d-flex justify-content-end">Action</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="5" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="vehicleList.length == 0">
                                    <td colspan="5" class="nodata">Empty</td>
                                </tr>
                                <tr *ngFor="let item of vehicleList | filter : searchText:'vehicle'">

                                    <td class="td-data" (click)="vehicleEdit(item)" style="cursor: pointer;">
                                        <span [title]="item.carno">{{item.carno}}</span>
                                    </td>
                                    <td class="td-data" (click)="vehicleEdit(item)" style="cursor: pointer;">
                                        <span [title]="item.carname">{{item.carname}}</span>
                                    </td>
                                    <td class="td-data" (click)="vehicleEdit(item)" style="cursor: pointer;">
                                        <span [title]="item.maxpassenger">{{item.maxpassenger}}</span>
                                    </td>
                                    <td class="td-data" (click)="vehicleEdit(item)" style="cursor: pointer;">
                                        <span>{{item.type == "001" ? "Car" : "Taxi"}}</span>
                                    </td>
                                    <td class="d-flex justify-content-end">
                                        <button matTooltip="Edit" (click)="vehicleEdit(item)"
                                            class="btn btn-outline-primary">
                                            <i class='fa fa-edit'></i>
                                        </button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="addVehicle || isEditing">

                <div>
                    <div class="close-save-button mb-3">
                        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()"
                            *ngIf="!saveLoading">
                            Cancel
                        </button>
                        <app-button-loader (click)="submitVehicle()" [button_text]="'Save'"
                            [load_button_text]="'Saving'" [isLoading]="saveLoading">
                        </app-button-loader>
                    </div>
                </div>


                <div class="card card-body my-card mb-3">
                    
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="carno" class="form-label">Type</label>
                            <select class="form-select" id="type" name="type" [(ngModel)]="cartype"
                                (change)="selectcartype($event)">

                                <option *ngFor="let type of cartypelist" [value]="type.value">
                                    {{ type.name }}
                                </option>
                            </select>

                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="carno" class="form-label">Name</label>
                            <input type="text" class="form-control" id="carno" name="carno"
                                [(ngModel)]="carForm.carname" #carname="ngModel"
                                [ngClass]="{'is-invalid': carFormSave && carname.invalid}" required>

                            <div class="invalid-feedback"
                                *ngIf=" carname.invalid || (carname.dirty || carname.touched) && carFormSave">
                                <div *ngIf="carname.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>
                        </div>
                       

                    </div>
                    <div class="row" *ngIf="cartype == '001'">
                        <div class="mb-3 col-md-6">
                            <label for="carno" class="form-label">Car No</label>
                            <input type="text" class="form-control" id="carno" name="carno" [(ngModel)]="carForm.carno"
                                #carno="ngModel" [ngClass]="{'is-invalid': carFormSave && carno.invalid}" required>

                            <div class="invalid-feedback"
                                *ngIf=" carno.invalid || (carno.dirty || carno.touched) && carFormSave">
                                <div *ngIf="carno.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="driverphone" class="form-label">Max Passenger</label>
                            <input type="number" class="form-control" id="driverphone" name="driverphone"
                                [(ngModel)]="carForm.maxpassenger" #maspass="ngModel"
                                [ngClass]="{'is-invalid': carFormSave && maspass.invalid}" required
                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)">

                            <div class="invalid-feedback"
                                *ngIf="maspass.invalid || (maspass.dirty || maspass.touched) && carFormSave">
                                <div *ngIf="maspass.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>




            </div>

        </div>




    </div>


</div>