<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title">{{membershiptype == '003' ? 'Member Type' : 'Post'}}</div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close [disabled]="saveLoading">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="mb-2s d-flex header justify-content-between">
        <div>
            <button *ngIf="!classCodeAddOrNot" type="button" class="btn btn-custom" (click)="classCodeAddOrNot = true">
                <i class="fa fa-plus me-1"></i>Add {{membershiptype == '003' ? 'Member Type' : 'Post'}}
            </button>

            <div *ngIf="classCodeAddOrNot" class="close-save-button">
                <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="submitCc()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
                </app-button-loader>
            </div>
        </div>
        <div *ngIf="!classCodeAddOrNot">
            <div class="input-group" style="width: 300px !important;">
                <input class="form-control" type="text" placeholder="&#xf002; Search with Name" aria-label="Search"
                    (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                    [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <form class="my-3">
        <div *ngIf="classCodeAddOrNot">
            <div class="mb-3">
                <label for="newcc" class="form-label">{{membershiptype == '003' ? 'Member Type' : 'Post'}} Name</label>

                <input type="text" class="form-control" id="newcc" name="newcc" [(ngModel)]="ccForm.classcode" required
                    placeholder="Enter new {{membershiptype == '003' ? 'Member Type' : 'Post'}}" #cc="ngModel"
                    [ngClass]="{ 'is-invalid': ccFormSubmitted && cc.invalid }" (keyup.enter)="submitCc()">

                <div class="invalid-feedback" *ngIf="cc.invalid || (cc.dirty || cc.touched) && ccFormSubmitted">
                    <div *ngIf="cc.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="desc" class="form-label">Description</label>

                <textarea class="form-control" id="desc" name="desc" [(ngModel)]="ccForm.desc"
                    placeholder="Enter Description" rows="3"></textarea>
            </div>
        </div>
    </form>
    <div *ngIf="!classCodeAddOrNot" class="table-responsive mt-2" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless table-fixed">
            <thead class="table-header">
                <tr>
                    <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4" mat-sort-header="classcode">Name
                    </td>
                    <td scope="col" class="col-lg-6 col-md-6 col-sm-6 col-6" mat-sort-header="description">Description
                    </td>
                    <td scope="col" class="d-flex justify-content-center">Action</td>
                </tr>
            </thead>
            <tbody *ngIf="gettingCcList">
                <tr>
                    <td colspan="3" class="nodata">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="!gettingCcList" #tbody>
                <tr *ngIf="ccList.length == 0">
                    <td colspan="3" class="nodata">Empty</td>
                </tr>

                <tr *ngFor="let cc of ccList| filter:searchText:'postList'; let i = index;">
                    <td scope="col" class="col-lg-4 col-md-4 col-sm-4 col-4 td-edit" (click)="editClasscode(cc)"
                        title="{{cc.name}}">{{cc.name}}
                    </td>
                    <td scope="col"
                        class="{{checkOverflow(tbody) ? 'col-lg-7 col-md-7 col-sm-7 col-7' : 'col-lg-6 col-md-6 col-sm-6 col-6'}} td-data"
                        title="{{cc.description}}">
                        {{cc.description}}
                    </td>
                    <td class=" {{checkOverflow(tbody) ? 'custom-pad' :''}} d-flex justify-content-center">
                        <button class="btn btn-outline-primary" (click)="editClasscode(cc)">
                            <i class='fa fa-edit'></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>