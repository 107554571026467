<div class="container-fluid">
    <div class="content">
        <!-- <ng-container *ngIf="isAdmin">
            <app-team-breadcrumb *ngIf="!addCalendar" s_breadcrumb="Calendar">
            </app-team-breadcrumb>
            <app-team-breadcrumb *ngIf="addCalendar" s_breadcrumb="Calendar"
                [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar' " (emit)="cancel()"></app-team-breadcrumb>
        </ng-container>
        <ng-container *ngIf="!isAdmin">
            <app-office-breadcrumb *ngIf="!addCalendar" [s_breadcrumb]="'Calendar'"></app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="addCalendar" [s_breadcrumb]="'Calendar'"
                [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar'" (emit)="cancel()"></app-office-breadcrumb>
        </ng-container> -->
        <app-team-breadcrumb *ngIf="!addCalendar" s_breadcrumb="Calendar">
        </app-team-breadcrumb>
        <app-team-breadcrumb *ngIf="addCalendar" s_breadcrumb="Calendar"
            [t_breadcrumb]="isEditing ? 'Edit Calendar' : 'New Calendar' " (emit)="cancel()"></app-team-breadcrumb>

        <ng-container *ngIf="addCalendar; else listView">
            <div class="d-flex align-items-center">
                <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()">
                    Cancel
                </button>
                <app-button-loader class="mb-3" (click)="submitCalendar()" [button_text]="'Save'"
                    [load_button_text]="'Saving'" [isLoading]="saveLoading">
                </app-button-loader>
            </div>
            <div class="card my-card card-body">
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" class="form-control" id="name" [(ngModel)]="calendarForm.name" name="name"
                            #name="ngModel" [ngClass]="{ 'is-invalid': calendarFormSubmitted && name.invalid }"
                            required />
                        <div class="invalid-feedback"
                            *ngIf=" name.invalid || ((name.dirty || name.touched) && calendarFormSubmitted)">
                            <div *ngIf="name.errors?.required">
                                {{ allinoneService.input_err_msg }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="description" class="form-label">Description</label>
                        <input class="form-control" id="description" [(ngModel)]="calendarForm.description"
                            name="description" />
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="startdate" class="form-label">Start Date</label>
                        <!-- <input type="date" class="form-control" id="startdate" [(ngModel)]="calendarForm.startdate"
                            name="startdate" (ngModelChange)="dateChange(true)" [disabled]="isEditing"/> -->
                        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy"
                            bsDatepicker [bsConfig]="generalDatePickerConfig" readonly
                            [(ngModel)]="calendarForm.startdate" style="min-width: 200px;" required #startdate="ngModel"
                            (ngModelChange)="dateChange(true)" [disabled]="isEditing">
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="enddate" class="form-label">End Date</label>
                        <!-- <input type="date" class="form-control" id="enddate" [(ngModel)]="calendarForm.enddate"
                            name="enddate" (ngModelChange)="dateChange(false)" [min]="calendarForm.startdate"  [max]="maxdate" [disabled]="isEditing"/> -->
                        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy"
                            bsDatepicker [bsConfig]="generalDatePickerConfig" readonly
                            [(ngModel)]="calendarForm.enddate" [minDate]="calendarForm.startdate" [maxDate]="maxdate"
                            (ngModelChange)="dateChange(false)" style="min-width: 200px;" required #enddate="ngModel"
                            [disabled]="isEditing">
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="d-flex align-items-center">
                            <div class="form-check me-5">
                                <input class="form-check-input" type="radio" id="manual" value="002" name="default"
                                    [(ngModel)]="calendarForm.type" />
                                <label class="form-check-label" for="manual"> Manual </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="auto" value="001" name="default"
                                    [(ngModel)]="calendarForm.type" />
                                <label class="form-check-label" for="auto"> Auto </label>
                            </div>

                        </div>
                    </div>
                    <!-- if type - auto, choose roster -->
                    <div class="mb-3 col-md-6" *ngIf="calendarForm.type == '001'">
                        <label for="roster" class="form-label">Roster</label>
                        <select class="form-select" name="roster" id="roster" [(ngModel)]="calendarForm.rosterid"
                            (ngModelChange)="rosterChange()">
                            <option value="">-</option>
                            <option *ngFor="let roster of rosterList" [value]="roster.rosterid">{{roster.name}}</option>
                        </select>
                        <mat-progress-bar *ngIf="rosterListLoading" mode="indeterminate"></mat-progress-bar>
                    </div>
                </div>
            </div>
            <div class="card my-card card-body mt-3">
                <app-office-calender-table #calendarTable
                    [startdate]="this.allinoneService.formatDBToShow(this.allinoneService.parseDate(this.calendarForm.startdate))"
                    [enddate]="this.allinoneService.formatDBToShow(this.allinoneService.parseDate(this.calendarForm.enddate))"
                    [weeks]="weeks" [shifts]="shiftList" [roster]="selectedRoster"
                    (getData)="updateShiftData($event)"></app-office-calender-table>
            </div>


        </ng-container>
        <ng-template #listView>
            <div class="d-flex align-items-center mb-3">
                <button class="btn btn-custom me-auto" (click)="newCalendar()">
                    <i class="fa fa-plus me-1"></i> New
                </button>
            </div>

            <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description"
                        aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false"
                        (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
                        <li>
                            <div style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
                        </li>
                    </ul>
                </div>
                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{ (calendarList | filter : searchText: 'merchant' ).length }}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description"
                        aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                        (ngModelChange)="searchTextChange()">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
                    *ngIf="calendarList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
                </pagination-controls>
            </div>


            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-md-3" mat-sort-header="name">Name</td>
                            <td scope="col" class="col-md-3" mat-sort-header="description">Description</td>
                            <td scope="col" class="col-md-3" mat-sort-header="startdate">Start Date</td>
                            <td scope="col" class="col-md-3" mat-sort-header="enddate">End Date</td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="calendarListLoading">
                        <tr>
                            <td colspan="5" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!calendarListLoading">
                        <tr *ngIf="calendarList.length == 0">
                            <td colspan="5" class="nodata">Empty</td>
                        </tr>
                        <tr
                            *ngFor="let calendar of calendarList | filter : searchText: 'merchant' | paginate: { itemsPerPage: itemPerPage , currentPage : currentPage } ; let index = index">
                            <td class="td-data td-edit" (click)="editCalendar(calendar)">
                                <span [title]="calendar.name">{{ calendar.name }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="calendar.description">{{calendar.description}}</span>
                            </td>
                            <td class="td-data">
                                <span
                                    [title]="allinoneService.formatDBToShow(calendar.startdate)">{{allinoneService.formatDBToShow(calendar.startdate)}}</span>
                            </td>
                            <td class="td-data">
                                <span
                                    [title]="allinoneService.formatDBToShow(calendar.enddate)">{{allinoneService.formatDBToShow(calendar.enddate)}}</span>
                            </td>
                            <td class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary me-2" (click)="editCalendar(calendar)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button class="btn btn-outline-danger"
                                    (click)="deleteCalendar(calendar.calendarid, index)">
                                    <i class="fa fa-trash-o"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination-controls class="paginate" (pageChange)="currentPage = $event"
                *ngIf="calendarList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </ng-template>
    </div>
</div>