import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { LeaveCountReportDetailsComponent } from '../leave-count-report-details/leave-count-report-details.component';

@Component({
  selector: 'app-leave-count-report',
  templateUrl: './leave-count-report.component.html',
  styleUrls: ['./leave-count-report.component.scss'],
})
export class LeaveCountReportComponent implements OnInit, OnDestroy {
  mobileViewWidth: any = 426;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  itemPerPage: number = 100;
  currentPage: number = 1;
  searchText: string = '';
  searchLoading: boolean = false;
  orgid: string = '';
  getList!: Subscription;

  dataList: any = [];
  organizations: any = [];

  gettingDataList: boolean = false;
  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];

  openfilter: boolean = false;
  getDataSub!: Subscription;
  gettingMemberPosition: boolean = false;
  subscriptions = new SubSink();
  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  leaveCountList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Top 10 Employees',
      value: '10',
    },
    {
      name: 'Top 30 Employees',
      value: '30',
    },
    {
      name: 'Top 50 Employees',
      value: '50',
    },
  ];

  topleavecount = '10';
  topleavecountName = 'Top 10 Employees';

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  // date
  // currentDate = this.allinoneService.formatDBToShowInEdit(
  //   this.allinoneService.getCurrentDateToDB()
  // );

  // formatToShow = 'yyyy-MM-dd';
  // dateRangeStartDate: any = '';
  // minDate = '';
  // date = new Date();
  // firstDay = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
  // lastDay = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);

  // currentStartDate : any = this.datePipe.transform(this.firstDay, 'yyyy-MM-dd');
  // currentEndDate : any = this.datePipe.transform(this.lastDay, 'yyyy-MM-dd');

  // date 2
  minDate = '';
  piedateFormat = 'dd/MM/yyyy';
  formatToShow = 'yyyy-MM-dd';
  d = new Date();
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 31);

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  // currentStartDate = formatDate(
  //   new Date(this.aweekBefore),
  //   this.formatToShow,
  //   'en-US'
  // ).toString();
  // tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();
  currentStartDate = 
    new Date(this.aweekBefore)
  tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  submitForm = {
    // date : this.currentDate,
    // enddate : this.lastDay,
    startdate: this.currentStartDate,
    enddate: new Date(Date.now()),
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status : '001'
  };

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    // date
    var addDays = 35;
    var date = new Date(this.submitForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();

    this.search();
  }

  ngOnDestroy(): void {}

  setLastDat() {
    var currentEndDate = formatDate(
      this.submitForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.submitForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    if (
      this.submitForm.enddate > date ||
      this.submitForm.enddate < this.submitForm.startdate
    ) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
  }

  leaveCountChange(c: any) {
    console.log(c);
    this.topleavecountName = c.name;
    this.topleavecount = c.value;
    this.getLeaveCountReport();
  }

  search() {
    this.getLeaveCountReport();
  }

  getLeaveCountReport() {
    // if (this.submitForm.startdate == '' || this.submitForm.startdate == null) {
    //   return this.messageService.openSnackBar(
    //     'Start Date cannot be blank.',
    //     'warn'
    //   );
    // }
    // if (this.submitForm.enddate == '' || this.submitForm.enddate == null) {
    //   return this.messageService.openSnackBar(
    //     'End Date cannot be blank.',
    //     'warn'
    //   );
    // }
    if (!this.submitForm.startdate) {
      return this.messageService.openSnackBar(
        'Start Date cannot be blank.',
        'warn'
      );
    }
    if (
      !this.submitForm.enddate) {
      return this.messageService.openSnackBar(
        'End Date cannot be blank.',
        'warn'
      );
    }
    this.searchLoading = true;
    var data = {
      // startdate: this.allinoneService.formatDate(this.submitForm.startdate),
      // enddate: this.allinoneService.formatDate(this.submitForm.enddate),
      startdate : this.allinoneService.parseDate(this.submitForm.startdate),
      enddate : this.allinoneService.parseDate(this.submitForm.enddate),
      orgid: this.orgid,
      department:
        !this.openfilter || this.submitForm.department == 'All'
          ? ''
          : this.submitForm.department,
      division:
        !this.openfilter || this.submitForm.division == 'All'
          ? ''
          : this.submitForm.division,
      teamid:
        !this.openfilter || this.submitForm.teamid == 'All'
          ? ''
          : this.submitForm.teamid,
      costcenter:
        !this.openfilter || this.submitForm.costcenter == 'All'
          ? ''
          : this.submitForm.costcenter,
      subdivision:
        !this.openfilter || this.submitForm.subdivision == 'All'
          ? ''
          : this.submitForm.subdivision,
      count: this.topleavecount,
      activestatus: this.openfilter ? this.submitForm.status : '001',
    };
    console.log(data);
    // return;
    this.getDataSub && this.getDataSub.unsubscribe();
    this.getDataSub = this.kunyekService.getLeaveCountReport(data).subscribe(
      (res: any) => {
        console.log(res);

        if (res.returncode == '300') {
          this.dataList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.searchLoading = false;
      },
      (err) => {
        this.searchLoading = false;
      }
    );
  }

  departmentChange(dep: any) {
    if (this.submitForm.department != dep) {
      this.submitForm.department = dep;
      this.submitForm.division = 'All';
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.submitForm.division != divi) {
      this.submitForm.division = divi;
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.submitForm.teamid != teamid) {
      this.submitForm.teamid = teamid;
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.submitForm.costcenter != costcenter) {
      this.submitForm.costcenter = costcenter;
      this.submitForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.submitForm.subdivision != subdivision) {
      this.submitForm.subdivision = subdivision;
    }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.subscriptions.sink = this.kunyekService
      .getMemberPosition(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.departmentList = res.departmentlist;
            // this.divisionList = res.divisionlist;
            // this.teamidList = res.teamidlist;
            for (var i = 0; i < res.departmentlist.length; i++) {
              this.departmentList.push(res.departmentlist[i]);
            }
            for (var i = 0; i < res.divisionlist.length; i++) {
              this.divisionList.push(res.divisionlist[i]);
            }
            for (var i = 0; i < res.teamidlist.length; i++) {
              this.teamidList.push(res.teamidlist[i]);
            }
            for (var i = 0; i < res.costcenterlist.length; i++) {
              this.costCenterList.push(res.costcenterlist[i]);
            }
            for (var i = 0; i < res.subdivision.length; i++) {
              this.subDivisionList.push(res.subdivision[i]);
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingMemberPosition = false;
        },
        (err) => {
          this.gettingMemberPosition = false;
        }
      );
  }

  viewDetails(data: any) {
    const dialog = this.dialog.open(LeaveCountReportDetailsComponent, {
      maxWidth: '95vw',
      width: '1200px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        data: data,
        orgid: this.orgid,
        // startdate: this.allinoneService.formatDate(this.submitForm.startdate),
        // enddate: this.allinoneService.formatDate(this.submitForm.enddate),
        startdate : this.allinoneService.parseDate(this.submitForm.startdate),
      enddate : this.allinoneService.parseDate(this.submitForm.enddate),
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  sortData(sort: Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'userid') {
        return this.allinoneService.compare(a.userid, b.userid, isAsc);
      } else if (sort.active == 'employeeid') {
        return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
      } else if (sort.active == 'username') {
        return this.allinoneService.compare(a.username, b.username, isAsc);
      } else if (sort.active == 'department') {
        return this.allinoneService.compare(a.department, b.department, isAsc);
      } else if (sort.active == 'division') {
        return this.allinoneService.compare(a.division, b.division, isAsc);
      } else if (sort.active == 'teamid') {
        return this.allinoneService.compare(a.teamid, b.teamid, isAsc);
      } else if (sort.active == 'duration') {
        return this.allinoneService.compare(a.duration, b.duration, isAsc);
      }
      return 0;
    });
  }

  downloadSheet() {
    var exdata: any = [];
    var name = 'Leave Count Report.xlsx';

    var filteredData = this.filterPipe.transform(
      this.dataList,
      this.searchText,
      'timecardreport'
    );

    filteredData.map((item: any) => {
      var temp = {
        'Employee ID': item.employeeid,
        'User ID': item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        'Team ID': item.teamid,
        'Cost Center': item.costcenter,
        'Total Leave': item.duration,
      };
      exdata.push(temp);
    });

    this.allinoneService.exportEcecl(exdata, name);
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      //  ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }
}
