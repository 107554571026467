<div class="me-auto px-5 mt-3">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
</div>

<div class="d-flex flex-column align-items-center gap-2 mb-5">
    <div class="d-flex ms-auto btn-action">
        <button class="btn btn-custom me-2" (click)="printPage()">Print</button>
        <button class="btn btn-custom" (click)="exportPDF()">Export PDF</button>
    </div>
    <div class="card px-5 py-3" id="printableCard" style="width: 780px;">
        <div class="d-flex flex-column gap-1 align-items-center mt-3">
            <h2>KOKINE SWIMMING CLUB</h2>
            <h3>Phone: 01-542749, 401467, 09-73224059</h3>
        </div>
        <div class="d-flex flex-column align-items-end p-2 gap-3">
            <div class="d-flex gap-3">
                <span class="text-end">Payment Date:</span>
                <span style="width: 130px;" class="dashed-line text-end">{{
                    allInOneService.formatDBToShow(payment?.paymentdate) }}</span>
            </div>
            <div class="d-flex gap-2">
                <span class="text-end">Voucher No:</span>
                <span style="width: 130px;" class="dashed-line text-end">{{ payment?.autoid }}</span>
            </div>
        </div>
        <div class="p-3 mb-3 mt-3" style=" background-color: white; border: solid 1px #ddd; border-radius: 6px;">
            <div>
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td style="width: 200px;">Sender: </td>
                            <td class="dashed-line">{{ payment?.sender }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Fee Type: </td>
                            <td class="dashed-line">{{ payment?.feetype }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Payment Type: </td>
                            <td class="dashed-line">{{ payment?.paymenttype }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Receiver: </td>
                            <td class="dashed-line">{{ payment?.receiver }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Amount: </td>
                            <td class="text-end dashed-line">{{ payment?.amount | number }} &nbsp;{{ currency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <hr class="mt-5">
            <table style="width: 100%; border-collapse: collapse;">
                <tbody>
                    <tr>
                        <td>Total Amount</td>
                        <td class="text-end">{{ payment?.amount | number }} &nbsp;{{ currency }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>