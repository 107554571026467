import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-special-swim-detail',
  templateUrl: './special-swim-detail.component.html',
  styleUrls: ['./special-swim-detail.component.scss']
})
export class SpecialSwimDetailComponent implements OnInit {
  @Input() memberData: any
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy'
  };

  id!: string;
  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  isSaving: boolean = false;
  saved: boolean = false;
  swimForm!: FormGroup;
  requiredMessage = 'This field may not be blank';
  selectedProfileImage?: File;
  tempProfileImage: any;
  profileUrl: any;
  requestBodyData: any;
  tempfilename: any = "";
  deletedprofilename = "";
  reader = new FileReader();
  batchList: any = [];
  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Special Swimmer', url: 'members/specialswim', isActive: true },
    { name: '', url: '', isActive: false }
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '');
    this.initializeForm();
    if (this.id) {
      this.getOneSpecialSwimmer();
    }
    this.getBatch();
    this.getRegion();
  }

  getBatch() {
    const data = {
      "searchName": "Special Swim"
    };
    this.kunyekService.getBatch().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.batchList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getRegion() {
    const data = {};
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  initializeForm() {
    this.swimForm = this.fb.group({
      syskey: ['', Validators.required],
      name: ['', Validators.required],
      fathername: [''],
      dateofbirth: ['', Validators.required],
      height: ['', Validators.required],
      membershipnumber: ['', Validators.required],
      contactno: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      batch: ['', Validators.required],
      teacher: ['', Validators.required],
      studentnumber: ['', Validators.required],
      address: [''],
      region: ['',],
      town: ['',],
      ward: ['',]
    });
  }

  getOneSpecialSwimmer() {
    this.kunyekService.specialMember().get({ id: this.id }).subscribe({
      next: (res: any) => {
        if (res.returncode === '300') {
          this.memberData = res.data;
          if (this.memberData.profileimage) {
            let piamge = JSON.parse(this.memberData.profileimage);
            this.profileUrl = piamge ? piamge.fileurl : "";
          }
          this.swimForm.patchValue({
            syskey: this.memberData.syskey,
            name: this.memberData.name,
            fathername: this.memberData.fathername,
            dateofbirth: this.allInOneservice.changeStringToDateobject(this.memberData.birthdate),
            height: this.memberData.height.replace('cm', ''),
            membershipnumber: this.memberData.membershipnumber,
            contactno: this.memberData.phone,
            batch: this.memberData.batchsyskey,
            teacher: this.memberData.teacher,
            studentnumber: this.memberData.studentnumber,
            address: this.memberData.address,
            region: this.memberData.directory?.region,
            town: this.memberData.directory?.town,
            ward: this.memberData.directory?.postcode,
          });
          this.breadcrumbs[2].name = this.memberData.name
          if (this.memberData.directory?.region && this.memberData.directory?.town) {
            this.onRegionChange({ target: { value: this.memberData?.directory?.region } });
            this.onTownChange({ target: { value: this.memberData?.directory?.town } });
          }
        } else {
          this.messageService.openSnackBar('Failed to fetch member details', 'warn');
        }
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to get member detail', 'warn');
      }
    });
  }

  onProfileClick() {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', (event: Event) => {
      this.onImageChange(event);
    });
    input.click();
  }


  onFileSelected(event: Event) {
    if (this.profileUrl) {
      this.deletedprofilename = JSON.parse(this.memberData.profileimage).filename;
    }

    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedProfileImage = input.files[0];
      this.reader.readAsDataURL(this.selectedProfileImage);
      this.reader.onload = (event) => {
        this.profileUrl = this.reader.result;
      }
    }
  }

  private onImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedProfileImage = file;
      const reader = new FileReader();
      reader.onload = e => this.profileImageSrc = reader.result as string;
      reader.readAsDataURL(file);
    }
  }

  async onSave() {
    this.isSaving = true;
    if (this.swimForm.valid) {
      await this.uploadFiles();
      const formattedBirthDate = this.dateofbirth?.value?.formatted || this.dateofbirth?.value;
      const formData = {
        syskey: this.syskey?.value,
        name: this.name?.value,
        fathername: this.fathername?.value,
        dateofbirth: this.allInOneservice.convertDate(formattedBirthDate),
        height: this.height?.value + 'cm',
        membershipnumber: this.membershipnumber?.value,
        contactno: this.contactno?.value,
        batchsyskey: this.batch?.value,
        teacher: this.teacher?.value,
        studentnumber: this.studentnumber?.value,
        address: this.address?.value,
        tempfilename: this.tempfilename,
        profileimage: this.selectedProfileImage?.name,
        postcode: this.ward?.value
      };

      this.kunyekService.specialMember().update(formData).subscribe(
        (res: any) => {
          this.isSaving = false;
          if (res.returncode === '300') {
            this.messageService.openSnackBar(res.message, 'success');
            this.closeForm();
          } else if (res.returncode === '301') {
            this.messageService.openSnackBar("User already exists with this phone number or email", "warn");
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.messageService.openSnackBar(err, 'warn');
          this.isSaving = false;
        }
      );
    } else {
      this.saved = true;
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false
      this.swimForm.markAllAsTouched();
    }
  }

  closeForm() {
    this.router.navigate(['members', 'specialswim']);
  }

  async uploadFiles() {
    const defaultpath = 'kokaimember/members';

    if (!this.tempfilename) {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    }

    if (this.selectedProfileImage) {
      const profilepath = `${defaultpath}/${this.tempfilename}/${this.selectedProfileImage.name}`;
      const upload_aws = await this.allInOneservice.uploadAnswerFile(this.selectedProfileImage, profilepath);
      if (!upload_aws) {
        this.messageService.openSnackBar('Unknown error occurred while uploading your files.', 'warn');
        return;
      }
      this.tempProfileImage = profilepath;
    }
  }

  get name() { return this.swimForm.get('name'); }
  get fathername() { return this.swimForm.get('fathername'); }
  get dateofbirth() { return this.swimForm.get('dateofbirth'); }
  get height() { return this.swimForm.get('height'); }
  get membershipnumber() { return this.swimForm.get('membershipnumber'); }
  get contactno() { return this.swimForm.get('contactno'); }
  get batch() { return this.swimForm.get('batch'); }
  get teacher() { return this.swimForm.get('teacher'); }
  get studentnumber() { return this.swimForm.get('studentnumber'); }
  get address() { return this.swimForm.get('address'); }
  get syskey() { return this.swimForm.get('syskey'); }
  get region() { return this.swimForm.get('region'); }
  get town() { return this.swimForm.get('town'); }
  get ward() { return this.swimForm.get('ward'); }
}
