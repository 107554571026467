import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { RequisitionReasonComponent } from '../requisition-reason/requisition-reason.component';

@Component({
  selector: 'app-requisition-approval',
  templateUrl: './requisition-approval.component.html',
  styleUrls: ['./requisition-approval.component.scss'],
})
export class RequisitionApprovalComponent implements OnInit, OnDestroy {
  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isDetailView: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  isApproving: boolean = false;
  isRejecting: boolean = false;
  subscriptions!: Subscription;
  getTemplateSubscription!: Subscription;
  isFocus: boolean = false;

  listLoading: boolean = false;
  searchText: string = '';
  orgid: string = '';
  formSubmitted: boolean = false;

  approvalList: any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  templateList: any = [];
  gettingApprovals: boolean = false;
  gettingPositions: boolean = false;

  selectedRequest: any = '';

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  submitForm = {
    name: '',
    requeststatus: '001',
    requeststatusname: 'Submitted',
    people: '',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    // if (!this.allinoneService.isAdminOf('hr')) {
    //   this.router.navigateByUrl('/hxm');
    // }
    this.allinoneService.isOfficeView = true;
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selected_orgcrm
      ? this.allinoneService.selected_orgcrm.orgid
      : this.organizations[0].orgid;
    this.getTemplates();
    this.getApprovalList();
  }

  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
  }

  statusChange(status: any) {
    if (this.submitForm.requeststatus != status.value) {
      this.submitForm.requeststatus = status.value;
      this.submitForm.requeststatusname = status.name;
      this.refresh();
    }
  }

  rejectReason() {
    const dialog = this.dialog.open(RequisitionReasonComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        orgid: this.orgid,
        requisitionid: this.selectedRequest.requisitionid,
        people: this.submitForm.people.toString().trim(),
        hrremark: '',
        role: '',
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.cancel();
        this.refresh();
      }
    });
  }

  getTemplates() {
    this.listLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getTemplateSubscription && this.getTemplateSubscription.unsubscribe();
    this.getTemplateSubscription = this.kunyekService
      .getRequisitionTemplate(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.templateList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.listLoading = false;
        },
        (err) => {
          this.listLoading = false;
        }
      );
  }

  // submit() {
  //   this.formSubmitted = true;
  // if(this.submitForm.people == '' || this.submitForm.people == null) {
  //   this.messageService.openSnackBar('No. of people cannot blank.', 'warn')
  //   return;
  // }
  //   var data = {
  //     orgid : this.orgid,
  //     people : this.submitForm.people
  //   }
  //   // this.kunyekService.updateNumberOfPeople(data).subscribe(
  //   //   (res:any) => {
  //   //     console.log(res);
  //   //     if(res.returncode == '300') {

  //   //     }
  //   //   }
  //   // )
  // }
  submit() {
    this.formSubmitted = true;
    if (this.submitForm.people == '' || this.submitForm.people == null) {
      this.messageService.openSnackBar('No. of people cannot blank.', 'warn');
      return;
    }
    if (Number(this.submitForm.people) < 1) {
      this.messageService.openSnackBar(
        'No. of people cannot less than 1.',
        'warn'
      );
      return;
    }
    this.saveLoading = true;
    var data = {
      orgid: this.orgid,
      people: this.submitForm.people.toString().trim(),
      updatepeople: true,
      requisitionid: this.selectedRequest.requisitionid,
      requisitiondate: '',
      positions: '',
      remark: '',
      priority: '',
      product: '',
      project: '',
      department: '',
      division: '',
      position: '',
      targetdate: '',
      requirements: '',
      descriptions: '',
      role: '',
    };
    this.kunyekService.updateRequisitionRequest(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          var index = this.approvalList.findIndex(
            (x: any) => x.requisitionid == data.requisitionid
          );
          if (index > -1) {
            this.approvalList[index].people = this.submitForm.people;
            // this.isDetailView = false;
            this.messageService.openSnackBar('Successful.', 'success');
          } else {
            this.cancel();
            this.refresh();
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err: any) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    );
  }

  getApprovalList() {
    this.gettingApprovals = true;
    const data = {
      orgid: this.orgid,
      status: this.submitForm.requeststatus,
    };
    this.subscriptions && this.subscriptions.unsubscribe();
    this.subscriptions = this.kunyekService
      .getRequisitionApproval(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.approvalList = [];
            res.datalist.map((x: any) => {
              var positions = '';
              x.positions.map((p: any, index: number) => {
                if (index == 0) {
                  positions += p.name;
                } else {
                  positions += ', ' + p.name;
                }
              });
              var temp = {
                ...x,
                positionlists: positions,
                statusname: this.statusList.find(
                  (z: any) => z.value == x.status
                )?.name,
              };
              this.approvalList.push(temp);
            });
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingApprovals = false;
        },
        (err) => {
          this.gettingApprovals = false;
        }
      );
  }

  submitApproval(status: string) {
    if (status == '002') {
      this.formSubmitted = true;
      if (this.submitForm.people == '' || this.submitForm.people == null) {
        this.messageService.openSnackBar(
          'No. of people cannot blank.',
          'warn'
        );
        return;
      }
      if (Number(this.submitForm.people) < 1) {
        this.messageService.openSnackBar(
          'No. of people cannot less than 1.',
          'warn'
        );
        return;
      }
    }
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        cmessage:
          'Are you sure to ' +
          (status == '002' ? 'approve' : 'reject') +
          ' Ref No. ' +
          this.selectedRequest.refno +
          '?',
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (status == '002') {
          this.isApproving = true;
        } else {
          this.isRejecting = true;
        }
        const data = {
          orgid: this.orgid,
          requisitionid: this.selectedRequest.requisitionid,
          status: status,
          people: this.submitForm.people.toString().trim(),
          role: '',
        };
        this.kunyekService.approveRequisition(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.messageService.openSnackBar('Successful.', 'success');
              this.refresh();
              this.cancel();
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.isApproving = false;
            this.isRejecting = false;
          },
          (err) => {
            this.isApproving = false;
            this.isRejecting = false;
          }
        );
      }
    });
  }

  searchTextChange() {}

  viewApproval(data: any) {
    this.isDetailView = true;
    this.selectedRequest = data;
    this.submitForm.people = this.selectedRequest.people;
  }

  sortData(sort: Sort) {
    const data = this.approvalList;
    if (!sort.active || sort.direction === '') {
      this.approvalList = data;
      return;
    }
    this.approvalList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'refno':
          return this.allinoneService.compare(a.refno, b.refno, isAsc);
        case 'positions':
          return this.allinoneService.compare(
            a.positionname,
            b.positionname,
            isAsc
          );
        case 'people':
          return this.allinoneService.compare(a.people, b.people, isAsc);
        case 'priority':
          return this.allinoneService.compare(a.priority, b.priority, isAsc);
        case 'requisitiondate':
          return this.allinoneService.compare(
            a.requisitiondate,
            b.requisitiondate,
            isAsc
          );
        case 'targetdate':
          return this.allinoneService.compare(
            a.targetdate,
            b.targetdate,
            isAsc
          );
        case 'requestedby':
          return this.allinoneService.compare(
            a.createdbyname,
            b.createdbyname,
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        case 'status':
          return this.allinoneService.compare(
            a.statusname,
            b.statusname,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {}

  refresh() {
    this.currentPage = 1;
    this.getApprovalList();
  }

  cancel() {
    this.isDetailView = false;
  }
}
