import { Component, OnDestroy, OnInit } from '@angular/core';
import { AllInOneService } from '../shared/services/all-in-one.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  constructor(private allinoneService: AllInOneService) {
    
  }
  ngOnDestroy(): void {
    this.allinoneService.sidebar = true;
  }

  ngOnInit(): void {
    this.allinoneService.sidebar = false;
  }
}
