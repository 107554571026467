import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  MapGeocoder,
  MapInfoWindow,
  MapMarker,
  GoogleMap,
} from '@angular/google-maps';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  private map: any;

  markers: any = [];
  lat: number = 16.841025300766102;
  lng: number = 96.17354287356257;
  markerPositions: google.maps.LatLngLiteral[] = [];
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  center!: google.maps.LatLngLiteral;
  // options: google.maps.MapOptions = {
  //   mapTypeId: google.maps.MapTypeId.ROADMAP,
  //   center: { lat: this.lat, lng: this.lng },
  // };
  // @ViewChild(GoogleMap, { static: false })
  // map!: GoogleMap;
  // @ViewChild(MapInfoWindow, { static: false })
  // info!: MapInfoWindow;

  marker: any;

  constructor(
    httpClient: HttpClient,
    geocoder: MapGeocoder,
    public dialogRef: MatDialogRef<MapComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.lat = Number(data.lat);
    this.lng = Number(data.long);

    // if (this.lat && this.lng) {
    //   //picked location
    //   this.markerPositions = [{ lat: this.lat, lng: this.lng }];
    //   this.options.center = { lat: this.lat, lng: this.lng };
    // } else {
    //   //default location OR current Location
    //   this.checkLocation();
    // }
  }

  ngOnInit(): void {
    if (this.lat && this.lng) {
      this.getMap();
    } else {
      this.lat = 16.841025300766102;
      this.lng = 96.17354287356257;
      this.getMap();
      this.checkLocation();
    }
  }

  goToCurrentPosition() {
    this.map.setView([this.lat, this.lng]);
  }

  getMap() {
    this.map = L.map('map', {
      center: [this.lat, this.lng],
      zoom: 15,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 30,
        minZoom: 3,
      }
    );

    var icon = new L.Icon({
      iconUrl: 'assets/images/pin.png',
      iconSize: [40, 40],
      iconAnchor: [21.5, 41],
      popupAnchor: [1, -34],
    });
    this.marker = new L.Marker([this.lat, this.lng], {
      icon: icon,
      draggable: true,
    }).addTo(this.map);

    this.marker.on('dragend', (event: any) => {
      var marker = event.target;
      var position = marker.getLatLng();
      this.lat = position.lat;
      this.lng = position.lng;
      marker.setLatLng(position).update();
    });
    tiles.addTo(this.map);
    this.map.on('click', (e: any) => {
      this.lat = e.latlng.lat;
      this.lng = e.latlng.lng;
      this.marker.setLatLng([this.lat, this.lng]).update();
    });
    // this.map.setView([this.lat, this.lng]);
  }

  async checkLocation() {
    const awaitCurrentPosition = await this.getCurrentPosition(); // current location;

    if (awaitCurrentPosition != 'success') {
      // default location

      this.lat = 16.841025300766102;
      this.lng = 96.17354287356257;
      this.center = {
        lat: this.lat,
        lng: this.lng,
      };
    } else {
      this.map.setView([this.lat, this.lng]);
      this.marker.setLatLng([this.lat, this.lng]).update();
    }

    // console.log(this.lat, this.lng);
    // this.markerPositions = [{ lat: this.lat, lng: this.lng }];
    // this.options.center = { lat: this.lat, lng: this.lng };
  }

  getCurrentPosition() {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          this.lat = Number(position.coords.latitude);
          this.lng = Number(position.coords.longitude);
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          resolve('success');
        },
        (err) => {
          console.log('err', err);
          resolve('error');
        }
      );
    });
  }

  addMarker(event: google.maps.MapMouseEvent) {
    const latLng = event.latLng.toJSON();
    console.log(latLng);

    this.lat = latLng.lat;
    this.lng = latLng.lng;
    var json = [];
    json.push(latLng);
    this.markerPositions = json;
  }

  ok() {
    this.dialogRef.close({ lat: this.lat, long: this.lng });
  }
  openInfo() {
    // this.info.open();
  }
}
