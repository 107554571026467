<div class="container-fluid">
    <div class="content">
        <div class="mt-3" style="font-size: 1rem; font-weight: bold; margin-bottom: 10px;">{{ menuTitle }}</div>
        <div class="row g-0">
            <ng-container *ngFor="let i of memberMenus">
                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card"
                    (click)="goRoute(i, i.path)">
                    <div class='d-flex flex-column'>
                        <div class="mb-2">
                            <img [src]="i.icon" width="30px">
                        </div>
                        <div class="name">
                            {{ i.name }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="content" *ngIf="memberSubMenus && memberSubMenus.length > 0">
        <div style="font-size: 1rem; font-weight: bold; margin-bottom: 10px;">{{ menuReferenceTitle }}
        </div>
        <div class="row g-0">
            <ng-container *ngFor="let submenu of memberSubMenus">
                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card"
                    (click)="goRoute(submenu, submenu.path)">
                    <div class='d-flex flex-column'>
                        <div class="mb-2">
                            <img [src]="submenu.icon" width="30px">
                        </div>
                        <div class="name">
                            {{ submenu.name }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>