import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Pipe,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarDirective,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { DatePipe, DOCUMENT, formatDate } from '@angular/common';
import { CreatePostComponent } from '../create-post/create-post.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SocialPostsDetailComponent } from '../social-posts-detail/social-posts-detail.component';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { BlockUserComponent } from '../block-user/block-user.component';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { ReportsComponent } from '../reports/reports.component';
import { HttpClient } from '@angular/common/http';
import { LikeTagListComponent } from '../like-tag-list/like-tag-list.component';

import { SharePostComponent } from '../share-post/share-post.component';
import { SubSink } from 'subsink';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';
import { Player } from '@vime/angular';

@Component({
  selector: 'app-social-posts',
  templateUrl: './social-posts.component.html',
  styleUrls: ['./social-posts.component.scss'],
})
export class SocialPostsComponent implements OnInit, OnDestroy {
  @ViewChild(QuillEditorComponent, { static: false })
  editor!: QuillEditorComponent;

  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild('hiddenfileinput') hiddenfileinput: any;
  @ViewChildren('player') player!: any;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const verticalOffset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (verticalOffset > 120) {
      this.windowScrolled = true;
    } else {
      this.windowScrolled = false;
    }
  }

  params = {
    autoplay: 1,
    muted: 1,
    controls: 0,
  };

  public config: PerfectScrollbarConfigInterface = {};

  gettingPosts: boolean = false;
  gettingMorePosts: boolean = false;
  gettingNewPosts: boolean = false;
  isAll: boolean = false;
  windowScrolled: boolean = false;
  saveCommentLoading: boolean = false;
  openedPost: boolean = false;
  isAfterBlock: boolean = false;
  isDomainAdmin: boolean = false;
  gettingContactList: boolean = true;
  gettingMoreContact: boolean = false;
  gettingMentionList: boolean = false;
  chatLoaderArray = [1, 2, 3, 4, 5, 6, 7];
  orgLoaderArray = [1, 2, 3, 4, 5];

  test : boolean = true;
  confalse : boolean = false;

  organizations: any[] = [];
  orgList: any[] = [];
  posts: any[] = [];
  groupList: any[] = [];
  contactsList: any[] = [];
  filterType: any;
  org: any;
  domainName: any;
  postsyskey: any;
  allposts: any;
  type: any;
  gettingOrgList = true;

  subscriptions = new SubSink();

  searchContact: boolean = false;
  searchingContact: boolean = false;
  searchContactCancel: boolean = false;

  contactName: any = '';
  userid: string = '';
  username: string = '';
  profileImg: string = '';
  postOrgid: string = '';
  domainAdmin: boolean = false;
  domain: any;

  currentTime =
    this.allinoneService.formatDBToShow(
      this.allinoneService.getCurrentDateToDB()
    ) +
    ', ' +
    this.allinoneService.getCurrentTimeToDB();
  currentCount = 5;
  startdate = '0';
  width: any = 'linear-gradient(90deg, #FFC0CB 50%, white 50%)';
  filter = {
    type: '1',
    orgid: '',
  };

  categories = [
    {
      width: 'Laravel',
      code: 'red',
    },
    {
      width: 'Codenigter',
      color: 'orange',
    },
    {
      width: 'Angular',
      color: 'green',
    },
  ];

  getContactSubscription!: Subscription;

  mentionableList: any[] = [];

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    // 'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'mention',
  ];
  modules = {
    keyboard: {
      bindings: {
        ['list autofill']: {
          prefix: /^\s*?(\d+\.|\*|\[ ?\]|\[x\])$/,
        },
      },
    },
    mention: {
      onOpen: () => {
        // this.isMentioning = true;
      },
      onClose: () => {
        setTimeout(() => {
          // this.isMentioning = false;
        }, 300);
      },
      defaultMenuOrientation: 'top',
      renderItem: (item: any) => {
        var rf: any;

        const img = item.img ? item.img : '../../../assets/images/profile.png';
        const check = item.username == '';
        if (check) {
          rf =
            `
            <div class = "m-row">
              <div>
                  <img class="m-profile-image"
                      src="` +
            img +
            `">
              </div>
              <div class="m-rightside">
                 ` +
            item.userid +
            `
              </div>
            </div>
          `;
          return rf;
        } else {
          rf =
            `
                  <div class = "m-row">
                  <div>
                      <img class="m-profile-image"
                          src="` +
            img +
            `">
                  </div>
                  <div class="m-rightside">
                      <div class="m-name">` +
            item.username +
            `</div>
                      <div class="m-id">` +
            item.userid +
            `</div>
                  </div>
                  </div>
                `;
          return rf;
        }
      },
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      onSelect: (item: any, insertItem: any) => {
        const editor = this.editor.quillEditor;
        if (item.value) {
          insertItem(item);
          // necessary because quill-mention triggers changes as 'api' instead of 'user'
          editor.insertText(editor.getLength() - 1, '', 'user');
        }
      },
      renderLoading: () => {
        const x = `
        
          <i class = "fa fa-spinner fa-spin">
        
    `;
        return x;
      },
      source: async (searchTerm: any, renderList: any) => {
        // const values = this.mentionableList;

        // Get List
        var values: any[] = [];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          values = this.mentionableList;
          var matches: any[] = [];
          values.forEach((entry) => {
            if (
              entry.username.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1 ||
              entry.userid.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1
            ) {
              matches.push(entry);
            }
          });
          if (values.length == 0 || matches.length == 0) {
            var temp = await this.getMentionList(searchTerm.toLowerCase());
            values = this.mentionableList;
            values.forEach((entry) => {
              if (
                entry.username
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) !== -1 ||
                entry.userid.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                  -1
              ) {
                matches.push(entry);
              }
            });
            renderList(values, searchTerm);
          } else {
            renderList(matches, searchTerm);
          }
        }
      },
    },
    toolbar: [],
  };

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public dialog: MatDialog,
    public router: Router,
    public imageCompress: NgxImageCompressService,
    public route: ActivatedRoute,
    public location: Location,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    public datepipe: DatePipe
  ) {
    this.allinoneService.isCRMServiceView = false;
    this.userid = this.allinoneService.getKunyekUserId();
    this.domainAdmin = this.allinoneService.isAdminOf('domain');
    this.profileImg = this.allinoneService.getProfileImage();
    this.domain = this.allinoneService.getDomain();
    this.username = this.allinoneService.getProfile().username;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.allinoneService.activeroute = '/adminconsole';
  }

  async ngOnInit(): Promise<void> {
    this.allinoneService.openedPost = '';
    this.allinoneService.activeroute = '/posts';
    this.postsyskey = this.route.snapshot.queryParamMap.get('id') || '';
    if (this.postsyskey != '') {
      const await_post = await this.getSinglePost();
      if (await_post) {
        this.openDetail(this.allinoneService.openedPost);
      }
    }
    if (this.allinoneService.isAdminOf('domain')) {
      this.domainName = this.allinoneService.admindomain.description;
      this.isDomainAdmin = true;
    } else {
      this.isDomainAdmin = false;
    }
    this.getGroupData();
    this.getPosts();
    this.getOrgs();
    this.getContactList();

    this.orgList = this.orgList;
    this.isDomainAdmin = this.isDomainAdmin;
    this.filterType = {
      type: '1',
    };
    // console.log("this is filter type => " + this.filter.type)
    if (this.filter.type == '1') {
      this.type = '1';
    }
    // else if(this.filter.type == '2'){
    //   this.type = "2"
    // }
    // else{
    //   this.type = this.filter.orgid
    // }
    // this.formatFilter(this.type)
  }

  scrollToTop() {
    this.refreshPosts();
    window.scrollTo(0, 0);
  }
  formatFilter(type: any) {
    window.scrollTo(0, 0);
    if (type == '1' || type == '2') {
      this.filter = {
        type: type,
        orgid: type,
      };
    } else {
      this.filter = {
        type: '3',
        orgid: type,
      };
    }
    this.refreshPosts();
  }

  onScrollDown() {
    if (this.startdate != '' && !this.gettingMorePosts) {
      this.getMorePosts();
    }
  }

  getMentionList(search: string) {
    return new Promise((resolve) => {
      this.gettingMentionList = true;
      const data = {
        contact: [],
        count: 0,
        start: 0,
        end: 10,
        search: search,
        domain: this.domain.shortcode,
        domainid: this.domain.domainid,
        orgid: this.postOrgid,
      };
      this.getContactSubscription && this.getContactSubscription.unsubscribe();
      this.getContactSubscription = this.kunyekService
        .getContactList(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.mentionableList = res.list;
              this.mentionableList = this.mentionableList.map(
                (item, index) => ({
                  ...item,
                  id: item.userid,
                  value: item.username ? item.username : item.userid,
                  userid: item.userid,
                  username: item.username,
                  img: item.imagename,
                })
              );
              this.mentionableList.sort((a: any, b: any) => {
                let fa = a.username.toLowerCase(),
                  fb = b.username.toLowerCase();

                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              });
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.gettingMentionList = false;
            resolve(true);
          },
          (err) => {
            this.gettingMentionList = false;
            resolve(false);
          }
        );
    });
  }

  getVotePercentage(showresult: any, answer: any, totalvote: any, code: any) {
    var percentage = 0;
    var ava = 0;
    // if(post.showcount=="false"){
    //   return ""
    // }
    if (showresult == 'false') {
      var val = answer.filter((x: any) => x.code == code);

      if (val.length > 0) {
        if (val[0].count > 0) {
          percentage = (val[0].count / totalvote) * 100;

          ava = 100 - percentage;
          return (
            'linear-gradient(90deg, #c7c5c5 ' +
            percentage +
            '%, white 0, white ' +
            ava +
            '%)'
          );
        } else {
          return 'linear-gradient(90deg, #98a9f3 0%, white 0, white 0%)';
        }
      } else {
        return 'linear-gradient(90deg, #98a9f3 0%, white 0, white 0%)';
      }
    } else {
      var currentLength = 0;
      var val = answer.filter((x: any) => x.code == code);
      if (val.length > 0) {
        currentLength = val[0].count;
      } else {
        return 'linear-gradient(90deg, #98a9f3 0%, white 0, white 0%)';
      }
      var maxlength = 0;
      var res: any = '';
      for (var i = 0; i < answer.length; i++) {
        if (answer[i].count >= maxlength) {
          maxlength = answer[i].count;
          res = answer[i];
        }
      }
      if (
        (currentLength >= maxlength && currentLength != 0) ||
        this.checkVoted(answer, code)
      ) {
        percentage = (res.count / maxlength) * 100;
        ava = 100 - percentage;
        return (
          'linear-gradient(90deg, rgb(62, 178, 255,45%) ' +
          percentage +
          '%, white 0, white ' +
          ava +
          '%)'
        );
      } else {
        return 'linear-gradient(90deg, rgb(62, 178, 255,45%) 0%, white 0, white 0%)';
      }
    }
  }
  checkVoted(answer: any, code: any) {
    var val = answer.filter((x: any) => x.code == code);
    if (val.length > 0) {
      if (val[0].userlist != undefined || val[0].userlist != null) {
        var res = val[0].userlist.filter(
          (x: any) => x.userid == this.allinoneService.getKunyekUserId()
        );
        if (res.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  checkVoteResult(answer: any, code: any) {
    var currentLength = 0;
    var val = answer.filter((x: any) => x.code == code);
    if (val.length > 0) {
      // currentLength = val[0].userlist.length;
      currentLength = val[0].count;
    } else {
      return '';
    }
    var maxlength = 0;
    var res: any = '';
    for (var i = 0; i < answer.length; i++) {
      if (answer[i].count >= maxlength) {
        maxlength = answer[i].count;
      }
    }
    if (currentLength >= maxlength) {
      return true;
    } else {
      return false;
    }
  }

  checkVotingEndtime(post: any) {
    if (post.meetingtype == '4') {
      if (this.checkVotingStartTime(post.startdate, post.starttime)) {
        if (post.manual == 'true') {
          var formatToShow = 'yyyy/MM/dd hh:mm:ss a';
          var currentDate = formatDate(
            Date.now(),
            formatToShow,
            'en-US'
          ).toString();
          var datetime = post.enddate;
          var yy = datetime.slice(0, 4);
          var mm = datetime.slice(4, 6);
          var dd = datetime.slice(6, 8);
          var finaDate = yy + '/' + mm + '/' + dd + ' ' + post.endtime;
          const d = new Date('2015-03-25 00:00:00');
          if (new Date(currentDate) > new Date(finaDate)) {
            return 'Final result';
          } else {
            let date1 = new Date(currentDate);
            let date2 = new Date(finaDate);
            var diffMin = this.diff_minutes(date1, date2);
            return this.transformMinute(diffMin);
            // let time = date1 - date2;  //msec
            // var hours   = Math.floor(time / 3.6e6);
            // var minutes = Math.floor((time % 3.6e6) / 6e4);
            // if(hours==0){
            // return Math.abs(parseInt(minutes.toFixed(0))).toString + ' minutes left';

            // }
            // return Math.abs(parseInt(hours.toFixed(0))).toString() + ' hours, ' +  Math.abs(parseInt(minutes.toFixed(0))).toString() + ' minutes left';
          }
        } else {
          if (post.voting == 'false') {
            return 'Final result';
          } else {
            return '';
          }
        }
      } else {
        return this.getShowVotingTime(post.startdate, post.starttime);
      }
    } else if (post.meetingtype != '4' && post.meetingtype != '') {
      var format = 'yyyy/MM/dd hh:mm:ss aa';
      var formatToShow = 'yyyy-MM-dd hh:mm:ss aa';

      var currentDate = formatDate(Date.now(), format, 'en-US').toString();
      var datetime = post.meetingtype == '3' ? post.enddate : post.startdate;
      var yy = datetime.slice(0, 4);
      var mm = datetime.slice(4, 6);
      var dd = datetime.slice(6, 8);
      var finalTime = post.meetingtype == '3' ? post.endtime : post.starttime;
      var finaDate = yy + '/' + mm + '/' + dd + ' ' + finalTime;
      const d = new Date('2015-03-25 00:00:00');
      if (
        new Date(currentDate) > new Date(finaDate) &&
        post.meetingtype != '1' &&
        post.meetingtype != '6'
      ) {
        var closeTime = formatDate(
          new Date(finaDate),
          formatToShow,
          'en-US'
        ).toString();

        return 'Final result';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  transformMinute(value: number): string {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    if (hours == 0) {
      return minutes + ' mins left';
    } else {
      return hours + ' hrs, ' + minutes + ' mins left';
    }
  }
  checkVotingColseOrOpenTime(post: any) {
    if (post.meetingtype == '4') {
      if (post.manual == 'false') {
        if (post.voting == 'true') {
          return 'Open';
        } else {
          return 'Closed ';
        }
      } else {
        const format = 'yyyy/MM/dd hh:mm:ss aa';
        const formatToShow = 'yyyy-MM-dd hh:mm:ss aa';

        var currentDate = formatDate(Date.now(), format, 'en-US').toString();
        var datetime = post.manual == 'true' ? post.enddate : post.enddate;
        var yy = datetime.slice(0, 4);
        var mm = datetime.slice(4, 6);
        var dd = datetime.slice(6, 8);
        var finaDate = yy + '/' + mm + '/' + dd + ' ' + post.endtime;
        if (new Date(currentDate) > new Date(finaDate)) {
          // var closeTime = formatDate(new Date(finaDate), formatToShow, 'en-US').toString()

          return 'Closed';
        } else {
          if (this.checkVotingStartTime(post.startdate, post.starttime)) {
            return 'Open';
          } else {
            return '';
          }
        }
      }
    } else if (post.meetingtype != '4' && post.meetingtype != '') {
      var format = 'yyyy/MM/dd hh:mm:ss aa';

      var currentDate = formatDate(Date.now(), format, 'en-US').toString();
      var datetime = post.meetingtype == '3' ? post.enddate : post.startdate;
      var yy = datetime.slice(0, 4);
      var mm = datetime.slice(4, 6);
      var dd = datetime.slice(6, 8);
      var finalTime = post.meetingtype == '3' ? post.endtime : post.starttime;
      var finaDate = yy + '/' + mm + '/' + dd + ' ' + finalTime;
      if (new Date(currentDate) > new Date(finaDate)) {
        return 'Closed';
      } else {
        return 'Open';
      }
    } else {
      return 'Closed';
    }
  }
  checkVotingStartTime(startdate: any, starttime: any) {
    var format = 'yyyy/MM/dd hh:mm:ss aa';
    var currentDate = formatDate(Date.now(), format, 'en-US').toString();
    var datetime = startdate;
    var yy = datetime.slice(0, 4);
    var mm = datetime.slice(4, 6);
    var dd = datetime.slice(6, 8);
    var startDate = yy + '/' + mm + '/' + dd + ' ' + starttime;
    // const d = new Date("2015-03-25 00:00:00");
    if (new Date(currentDate) > new Date(startDate)) {
      return true; //can vote
    } else {
      return false; //close vote
      // let date1 = new Date(currentDate).getTime();
      // let date2 = new Date(startDate).getTime();
      // let time = date1 - date2;  //msec
      // let hoursDiff = time / (3600 * 1000);
      // return Math.abs(parseInt(hoursDiff.toFixed(0))).toString()+" hours left";
    }
  }

  getShowVotingTime(startdate: any, starttime: any) {
    if (this.checkVotingStartTime(startdate, starttime)) {
      return '';
    } else {
      var datetime = startdate;
      var yy = datetime.slice(0, 4);
      var mm = datetime.slice(4, 6);
      var dd = datetime.slice(6, 8);
      var startDate = yy + '/' + mm + '/' + dd + ' ' + starttime;
      // console.log(post)
      if (startDate == '// ') {
        return '';
      }

      var formatToShow = 'yyyy-MM-dd hh:mm:ss a';

      var votingstarttime = formatDate(
        new Date(startDate),
        formatToShow,
        'en-US'
      ).toString();
      return 'Voting start at ' + votingstarttime;
    }
  }
  VoteOnChange(event: any, name: string, code: string, syskey: string) {
    console.log(event.target.checked);
    if (event.target.checked) {
      this.posts.map((post: any) => {
        if (post.syskey == syskey) {
          if (this.checkVoteLimit(post.answer) >= post.maxoption) {
            this.messageService.openSnackBar(
              'Select only ' + post.maxoption,
              'success'
            );

            return;
          }

          if (!this.checkVoted(post.answer, code)) {
            post.like = 'true';
            post.vote = 'true';

            post.totallike += 1;
            post.totalvote += 1;

            var res = post.answer.map((x: any) => x.code).indexOf(code);
            // console.log(res);

            if (res != -1) {
              post.answer[res].userlist.push({
                userid: this.allinoneService.getKunyekUserId(),
              });
              post.answer[res].count += 1;
              // console.log(post)
            }
            if (post.meetingtype == '1') {
              this.VotePost(code, syskey, '', '');
            } else {
              this.VotePost(code, syskey, '', '');
            }
          }
        }
      });
    } else {
      this.posts.map((post: any) => {
        // console.log(this.checkVoteLimit(post))
        if (post.syskey == syskey) {
          if (this.checkVoted(post.answer, code)) {
            post.totallike -= 1;
            post.totalvote -= 1;

            var res = post.answer.map((x: any) => x.code).indexOf(code);
            // console.log(res);

            if (res != -1) {
              var userindex = post.answer[res].userlist
                .map((x: any) => x.userid)
                .indexOf(this.allinoneService.getKunyekUserId());
              post.answer[res].userlist.splice(userindex, 1);
              post.answer[res].count -= 1;
            }
            if (post.meetingtype == '1') {
              this.VotePost(code, syskey, 'delete', '');
            } else {
              this.VotePost(code, syskey, 'delete', '1');
            }

            if (this.checkVoteLimit(post.answer) == 0) {
              post.vote = 'false';

              post.like = 'false';
            }
          }
        }
      });
    }
  }

  VotePost(code: string, syskey: any, action: any, posttype: any) {
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      date: this.allinoneService.getCurrentDateToDB(),
      time: this.allinoneService.getCurrentTimeToDB(),
      postsyskey: syskey,
      commentsyskey: '',
      posttype: posttype,
      action: action,
      type: code,
    };
    this.subscriptions.sink = this.kunyekService.votePost(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }

  checkVoteLimit(answer: any) {
    var votedLimit = 0;
    for (var i = 0; i < answer.length; i++) {
      for (var j = 0; j < answer[i].userlist.length; j++) {
        if (
          answer[i].userlist[j].userid == this.allinoneService.getKunyekUserId()
        ) {
          votedLimit += 1;
        }
      }
    }
    // console.log(votedLimit)

    return votedLimit;
  }

  getPosts() {
    this.gettingPosts = true;
    this.posts = [];
    var data;
    if (this.filter.type == '1') {
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: '0',
        enddate: '',
        viewasadmin: 'false',
        orgid: '',
      };
    } else if (this.filter.type == '2') {
      this.isAfterBlock = true;
      this.currentCount = 5;
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: '0',
        enddate: '',
        viewasadmin: 'true',
        orgid: '',
      };
    } else {
      this.isAfterBlock = true;
      this.currentCount = 5;
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: '0',
        enddate: '',
        viewasadmin: 'false',
        orgid: this.filter.orgid,
      };
    }
    this.subscriptions.sink = this.kunyekService.getPosts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.posts = [...res.meetinglist, ...res.list];
          this.startdate = res.startdate;
          this.posts = this.posts.map((item) => ({
            ...item,
            showMore: false,
            commentboxopen: false,
            commentText: '',
            selectedImage: '',
            imagetype: '',
            saveCommentLoading: false,
            deletingPost: false,
            postDomainAdmin:
              this.allinoneService.checkDomainIsInAdminDomainList(item.domain),
            postOrgAdmin: this.allinoneService.checkOrgIsInAdminOrgList(
              item.orgid
            ),
            postAdmin: this.allinoneService.checkOrgIsInPostAdminOrgList(
              item.orgid
            ),
          }));
          this.posts.map(async (item: any) => {
            item.totalreact.sort(function (a: any, b: any) {
              return b.count - a.count;
            });
            for (var i = 0; i < item.answer.length; i++) {
              var res = item.answerresult.filter(
                (x: any) => x.code == item.answer[i].code
              );
              item.answer[i].userlist = res.length > 0 ? res[0].userlist : [];
              item.answer[i].count = res.length > 0 ? res[0].count : 0;

              console.log(item.answer[i].userlist);
            }

            if (item.meetinglink != '' && item.ics == 'false') {
              var await_meetinglink: any = await this.getPreview(
                item.meetinglink
              );
              if (await_meetinglink == false) {
                item = Object.assign(item, {
                  preview: false,
                });
              } else {
                // console.log(await_meetinglink.image?.image[0].url);

                var checkImage = await_meetinglink.image;
                var imgUrl = '';
                if (checkImage != undefined) {
                  imgUrl = checkImage[0].url;
                }
                console.log('URL>>' + imgUrl.toString());
                item = Object.assign(item, {
                  preview: true,
                  previewTitle: await_meetinglink.title,
                  previewImage: imgUrl,
                });
              }
            }
          });
          console.log(this.posts);
          this.gettingPosts = false;
        } else {
          this.gettingPosts = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingPosts = false;
      }
    );
  }

  onFileSelect(event: any, index: number) {
    console.log(event.target.files[0]);
    var filetypelist = ['image/jpeg', 'image/png', 'image/jpg'];
    if (filetypelist.indexOf(event.target.files[0].type) > -1) {
      this.posts[index].imagetype = event.target.files[0].type
        .toString()
        .split('/')
        .pop();
      this.getBase64(event.target.files[0]).then((data: any) => {
        var filename = event.target.files[0].name;
        this.posts[index].selectedImage = data;
        this.posts[index].scImage = event.target.files[0];

        let name = filename.replace(/\s/g, '');
        name = filename.substring(0, filename.lastIndexOf('.'));
        name = filename.substring(0, filename.lastIndexOf('.'));
        let filetype = filename.split('.').pop();
        var date = new Date();
        var millisecond = date.getMilliseconds();
        var time = this.datepipe.transform(
          date,
          'yyyyMMddhhmmss' + millisecond
        );

        this.posts[index].uploadimage = name + '_' + time + '.' + filetype;
        console.log(this.posts[index].uploadimage);

        this.hiddenfileinput.nativeElement.value = '';
      });
    } else {
      this.hiddenfileinput.nativeElement.value = '';
      this.messageService.openSnackBar(
        'This filetype is not supported.',
        'warn'
      );
    }
  }

  focusEditor(orgid: string) {
    this.postOrgid = orgid;
    console.log(this.postOrgid);
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  closeImage(index: number) {
    this.posts[index].selectedImage = '';
  }

  refreshPosts() {
    this.gettingNewPosts = true;
    var data;
    if (this.filter.type == '1') {
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: '0',
        enddate: '',
        viewasadmin: 'false',
        orgid: '',
      };
    } else if (this.filter.type == '2') {
      this.isAfterBlock = true;
      this.currentCount = 5;
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: '0',
        enddate: '',
        viewasadmin: 'true',
        orgid: '',
      };
    } else {
      this.isAfterBlock = true;
      this.currentCount = 5;
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: '0',
        enddate: '',
        viewasadmin: 'false',
        orgid: this.filter.orgid,
      };
    }
    this.subscriptions.sink = this.kunyekService.getPosts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.posts = [...res.meetinglist, ...res.list];
          this.startdate = res.startdate;
          this.posts = this.posts.map((item) => ({
            ...item,
            showMore: false,
            commentboxopen: false,
            commentText: '',
            selectedImage: '',
            imagetype: '',
            saveCommentLoading: false,
            deletingPost: false,
            postDomainAdmin:
              this.allinoneService.checkDomainIsInAdminDomainList(item.domain),
            postOrgAdmin: this.allinoneService.checkOrgIsInAdminOrgList(
              item.orgid
            ),
            postAdmin: this.allinoneService.checkOrgIsInPostAdminOrgList(
              item.orgid
            ),
          }));
          this.posts.map(async (item: any) => {
            item.totalreact.sort(function (a: any, b: any) {
              return b.count - a.count;
            });
            for (var i = 0; i < item.answer.length; i++) {
              var res = item.answerresult.filter(
                (x: any) => x.code == item.answer[i].code
              );
              item.answer[i].userlist = res.length > 0 ? res[0].userlist : [];
              item.answer[i].count = res.length > 0 ? res[0].count : 0;
            }

            if (item.meetinglink != '' && item.ics == 'false') {
              var await_meetinglink: any = await this.getPreview(
                item.meetinglink
              );
              if (await_meetinglink == false) {
                item = Object.assign(item, {
                  preview: false,
                });
              } else {
                // console.log(await_meetinglink.image?.image[0].url);

                var checkImage = await_meetinglink.image;
                var imgUrl = '';
                if (checkImage != undefined) {
                  imgUrl = checkImage[0].url;
                }
                console.log('URL>>' + imgUrl.toString());
                item = Object.assign(item, {
                  preview: true,
                  previewTitle: await_meetinglink.title,
                  previewImage: imgUrl,
                });
              }
            }
          });
          console.log(this.posts);
          this.gettingNewPosts = false;
        } else {
          this.gettingNewPosts = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNewPosts = false;
      }
    );
  }

  getMorePosts() {
    this.gettingMorePosts = true;
    var data;
    if (this.filter.type == '1') {
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: this.startdate,
        enddate: this.startdate,
        viewasadmin: 'false',
        orgid: '',
      };
    } else if (this.filter.type == '2') {
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: this.startdate,
        enddate: this.startdate,
        viewasadmin: 'true',
        orgid: '',
      };
    } else {
      data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 0,
        startdate: this.startdate,
        enddate: this.startdate,
        viewasadmin: 'false',
        orgid: this.filter.orgid,
      };
    }
    this.subscriptions.sink = this.kunyekService.getPosts(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          var templist = res.list;
          res.list = [...res.meetinglist, ...templist];
          this.startdate = res.startdate;
          res.list = res.list.map((item: any) => ({
            ...item,
            showMore: false,
            commentboxopen: false,
            commentText: '',
            selectedImage: '',
            imagetype: '',
            saveCommentLoading: false,
            deletingPost: false,
            postDomainAdmin:
              this.allinoneService.checkDomainIsInAdminDomainList(item.domain),
            postOrgAdmin: this.allinoneService.checkOrgIsInAdminOrgList(
              item.orgid
            ),
            postAdmin: this.allinoneService.checkOrgIsInPostAdminOrgList(
              item.orgid
            ),
          }));
          // this.posts=[];

          for (let i = 0; i < res.list.length; i++) {
            res.list[i].totalreact.sort(function (a: any, b: any) {
              return b.count - a.count;
            });
            for (var j = 0; j < res.list[i].answer.length; j++) {
              var result = res.list[i].answerresult.filter(
                (x: any) => x.code == res.list[i].answer[j].code
              );

              res.list[i].answer[j].userlist =
                result.length > 0 ? result[0].userlist : [];
              res.list[i].answer[j].count =
                result.length > 0 ? result[0].count : 0;
            }
            if (res.list[i].meetinglink != '' && res.list[i].ics == 'false') {
              var await_meetinglink: any = await this.getPreview(
                res.list[i].meetinglink
              );
              if (await_meetinglink == false) {
                res.list[i] = Object.assign(res.list[i], {
                  preview: false,
                });
              } else {
                // console.log(await_meetinglink.image?.image[0].url);

                var checkImage = await_meetinglink.image;
                console.log(checkImage);
                var imgUrl = '';
                if (checkImage != undefined) {
                  imgUrl = checkImage[0].url;
                }
                console.log('URL>>' + imgUrl.toString());
                res.list[i] = Object.assign(res.list[i], {
                  preview: true,
                  previewTitle: await_meetinglink.title,
                  previewImage: imgUrl,
                });
              }
            }
            this.posts.push(res.list[i]);
            // this.posts=res.list[i]
          }

          this.gettingMorePosts = false;
        } else {
          this.gettingMorePosts = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingMorePosts = false;
      }
    );
  }

  tagPeople(post: any) {
    var user = post.admin ? post.admin : post.adminid;
    var taglist = post.tag;
    if (post.orgtag.length > 0) {
      return (
        '<b>' +
        user +
        '</b>' +
        '&nbsp;is&nbsp;with&nbsp;<b>' +
        post.orgtag[0].orgname +
        '</b>'
      );
    } else {
      if (taglist.length == 0 || taglist == undefined || taglist == null) {
        return '<b>' + user + '</b>';
      } else {
        if (taglist.length == 1) {
          return (
            '<b>' +
            user +
            '</b>' +
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b>'
          );
        } else if (taglist.length == 2) {
          return (
            '<b>' +
            user +
            '</b>' +
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b> and <b>' +
            this.getName(taglist[1]) +
            '</b>'
          );
        } else {
          return (
            '<b>' +
            user +
            '</b>' +
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b>&nbsp;and&nbsp;'
          );
        }
      }
    }
  }

  getName(user: any) {
    return user.username ? user.username : user.userid;
  }

  viewOtherTag(taglist: any) {
    var temp = JSON.parse(JSON.stringify(taglist));
    console.log(temp.splice(0, 1));
  }

  t(d:any) {
    console.log(d);
  }

  viewProfile(userid: any) {
    console.log('UID>>' + userid.toString());

    const dialog = this.dialog.open(UserProfileComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        user: [],
        userid: userid,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.isAfterBlock = true;
        this.scrollToTop();
      }
    });
  }

  createPost() {
    const dialog = this.dialog.open(CreatePostComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        orgList: this.orgList,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop();
        window.scrollTo(0, 0);
        var newPost = {
          ...dialogResult.data,
          showMore: false,
          commentboxopen: false,
          commentText: '',
          selectedImage: '',
          imagetype: '',
          saveCommentLoading: false,
          deletingPost: false,
          postDomainAdmin: this.allinoneService.checkDomainIsInAdminDomainList(
            dialogResult.data.domain
          ),
          postOrgAdmin: this.allinoneService.checkOrgIsInAdminOrgList(
            dialogResult.data.orgid
          ),
          postAdmin: this.allinoneService.checkOrgIsInPostAdminOrgList(
            dialogResult.data.orgid
          ),
        };
        for (var i = 0; i < newPost.answer.length; i++) {
          var res = newPost.answerresult.filter(
            (x: any) => x.code == newPost.answer[i].code
          );
          newPost.answer[i].userlist = res.length > 0 ? res[0].userlist : [];
          newPost.answer[i].count = res.length > 0 ? res[0].count : 0;
        }
        this.posts.unshift(newPost);
      }
    });
  }

  editPost(post: any) {
    const dialog = this.dialog.open(CreatePostComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        post: post,
        orgList: this.orgList,
        duplicate: false,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
        var editedPost = dialogResult.data;
        this.posts.map((item: any) => {
          if (item.syskey == editedPost.syskey) {
            item.admin = editedPost.admin;
            item.adminid = editedPost.adminid;
            item.adminimage = editedPost.adminimage;
            item.comment = editedPost.comment;
            item.domain = editedPost.domain;
            item.domainimage = editedPost.domainimage;
            item.domainurl = editedPost.domainurl;
            item.filename = editedPost.filename;
            item.fileurl = editedPost.fileurl;
            item.image = editedPost.image;
            item.imagename = editedPost.imagename;
            item.like = editedPost.like;
            item.note = editedPost.note;
            item.orgid = editedPost.orgid;
            item.orgimagename = editedPost.orgimagename;
            item.orgimageurl = editedPost.orgimageurl;
            item.orgname = editedPost.orgname;
            item.tag = editedPost.tag;
            item.tagclass = editedPost.tagclass;
            item.totallike = editedPost.totallike;
            item.type = editedPost.type;
            item.meetinglink = editedPost.meetinglink;
            item.startdate = editedPost.startdate;
            item.starttime = editedPost.starttime;
            item.enddate = editedPost.enddate;
            item.endtime = editedPost.endtime;
            item.orgtag = editedPost.orgtag;
            item.answer = editedPost.answer;
            item.answerresult = editedPost.answerresult;
            item.customvote = editedPost.customvote;
            item.defaultvote = editedPost.defaultvote;
            item.ics = editedPost.ics;
            item.mandatory = editedPost.mandatory;
            item.manual = editedPost.manual;
            item.maxoption = editedPost.maxoption;
            item.meetingdesc = editedPost.meetingdesc;
            item.meetingtype = editedPost.meetingtype;
            item.notification = editedPost.notification;
            item.reminder = editedPost.reminder;
            item.showcount = editedPost.showcount;
            item.shownamelist = editedPost.shownamelist;
            item.showresult = editedPost.showresult;
            item.totalvote = editedPost.totalvote;
            item.venue = editedPost.venue;
            item.vote = editedPost.vote;
            item.voting = editedPost.voting;
            item.postsetting = editedPost.postsetting;
            item.channelid = editedPost.channelid;
            item.channelname = editedPost.channelname;
            item.postDomainAdmin =
              this.allinoneService.checkDomainIsInAdminDomainList(
                editedPost.domain
              );
            item.postOrgAdmin = this.allinoneService.checkOrgIsInAdminOrgList(
              editedPost.orgid
            );
            item.postAdmin = this.allinoneService.checkOrgIsInPostAdminOrgList(
              editedPost.orgid
            ),
            item.videopost = editedPost.videopost;
            item.videourl = editedPost.videourl;
            item.hidevideo = true;
            setTimeout(() => {
              item.hidevideo = false;
            }, 300);
            for (var i = 0; i < item.answer.length; i++) {
              var res = item.answerresult.filter(
                (x: any) => x.code == item.answer[i].code
              );
              item.answer[i].userlist = res.length > 0 ? res[0].userlist : [];
              item.answer[i].count = res.length > 0 ? res[0].count : 0;
            }
            console.log('this.post ====> ')
            console.log(item);
          }
        });
      }
    });
  }

  duplicatePost(post: any) {
    const dialog = this.dialog.open(CreatePostComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        post: post,
        orgList: this.orgList,
        duplicate: true,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        window.scrollTo(0, 0);
        var newPost = {
          ...dialogResult.data,
          showMore: false,
          commentboxopen: false,
          commentText: '',
          selectedImage: '',
          imagetype: '',
          saveCommentLoading: false,
          deletingPost: false,
          postDomainAdmin: this.allinoneService.checkDomainIsInAdminDomainList(
            dialogResult.data.domain
          ),
          postOrgAdmin: this.allinoneService.checkOrgIsInAdminOrgList(
            dialogResult.data.orgid
          ),
          postAdmin: this.allinoneService.checkOrgIsInPostAdminOrgList(
            dialogResult.data.orgid
          ),
        };
        for (var i = 0; i < newPost.answer.length; i++) {
          var res = newPost.answerresult.filter(
            (x: any) => x.code == newPost.answer[i].code
          );
          newPost.answer[i].userlist = res.length > 0 ? res[0].userlist : [];
          newPost.answer[i].count = res.length > 0 ? res[0].count : 0;
        }
        this.posts.unshift(newPost);
      }
    });
  }

  deletePost(syskey: any, index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.posts[index].deletingPost = true;
        var data = {
          syskey: syskey,
          domain: this.allinoneService.getDomain().shortcode,
        };
        this.subscriptions.sink = this.kunyekService.deletePost(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.posts[index].deletingPost = false;
              this.posts.splice(index, 1);
              this.currentCount = this.currentCount - 1;
            } else {
              this.posts[index].deletingPost = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.posts[index].deletingPost = false;
          }
        );
      }
    });
  }

  blockUser(post: any) {
    const dialog = this.dialog.open(BlockUserComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      data: {
        post: post,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.isAfterBlock = true;
        this.scrollToTop();
      }
    });
  }

  trimString(text: any, length: any) {
    return text.length > length ? text.substring(0, length) + '...' : text;
  }

  openDetail(post: any) {
    if (this.player._results.length > 0) {
      this.player._results.map((pl: Player) => {
        pl.pause();
      });
    }
    this.allinoneService.openedPost = post;
    // this.openedPost = true

    this.location.replaceState('/posts?id=' + post.syskey.toString());
    const dialog = this.dialog.open(SocialPostsDetailComponent, {
      height: '100vh',
      width: '100vw',
      closeOnNavigation: true,
      panelClass: ['full-screen-modal', 'mat-typography'],
      data: {
        orgList: this.orgList,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      var temp = dialogResult;
      if (temp.block == true) {
        this.isAfterBlock = true;
        this.scrollToTop();
      } else {
        if (temp.delete == true) {
          var index = this.posts.map((x: any) => x.syskey).indexOf(post.syskey);
          this.posts[index].deletingPost = true;
          var data = {
            syskey: post.syskey,
            domain: this.allinoneService.getDomain().shortcode,
          };
          this.subscriptions.sink = this.kunyekService
            .deletePost(data)
            .subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  this.posts[index].deletingPost = false;
                  this.posts.splice(index, 1);
                  this.currentCount = this.currentCount - 1;
                } else {
                  this.posts[index].deletingPost = false;
                  this.messageService.openSnackBar(res.message, 'warn');
                }
              },
              (err) => {
                this.posts[index].deletingPost = false;
              }
            );
        } else {
          this.posts.map((post: any) => {
            if (post.syskey == this.allinoneService.openedPost.syskey) {
              post.admin = this.allinoneService.openedPost.admin;
              post.adminid = this.allinoneService.openedPost.adminid;
              post.adminimage = this.allinoneService.openedPost.adminimage;
              post.comment = this.allinoneService.openedPost.comment;
              post.domain = this.allinoneService.openedPost.domain;
              post.domainimage = this.allinoneService.openedPost.domainimage;
              post.domainurl = this.allinoneService.openedPost.domainurl;
              post.filename = this.allinoneService.openedPost.filename;
              post.fileurl = this.allinoneService.openedPost.fileurl;
              post.image = this.allinoneService.openedPost.image;
              post.imagename = this.allinoneService.openedPost.imagename;
              post.like = this.allinoneService.openedPost.like;
              post.note = this.allinoneService.openedPost.note;
              post.orgid = this.allinoneService.openedPost.orgid;
              post.orgimagename = this.allinoneService.openedPost.orgimagename;
              post.orgimageurl = this.allinoneService.openedPost.orgimageurl;
              post.orgname = this.allinoneService.openedPost.orgname;
              post.tag = this.allinoneService.openedPost.tag;
              post.tagclass = this.allinoneService.openedPost.tagclass;
              post.totallike = this.allinoneService.openedPost.totallike;
              post.type = this.allinoneService.openedPost.type;
              post.meetinglink = this.allinoneService.openedPost.meetinglink;
              post.startdate = this.allinoneService.openedPost.startdate;
              post.starttime = this.allinoneService.openedPost.starttime;
              post.enddate = this.allinoneService.openedPost.enddate;
              post.endtime = this.allinoneService.openedPost.endtime;
              post.orgtag = this.allinoneService.openedPost.orgtag;
              post.answer = this.allinoneService.openedPost.answer;
              post.answerresult = this.allinoneService.openedPost.answerresult;
              post.customvote = this.allinoneService.openedPost.customvote;
              post.defaultvote = this.allinoneService.openedPost.defaultvote;
              post.ics = this.allinoneService.openedPost.ics;
              post.mandatory = this.allinoneService.openedPost.mandatory;
              post.manual = this.allinoneService.openedPost.manual;
              post.maxoption = this.allinoneService.openedPost.maxoption;
              post.meetingdesc = this.allinoneService.openedPost.meetingdesc;
              post.meetingtype = this.allinoneService.openedPost.meetingtype;
              post.notification = this.allinoneService.openedPost.notification;
              post.commentsetting =
                this.allinoneService.openedPost.commentsetting;
              post.reminder = this.allinoneService.openedPost.reminder;
              post.showcount = this.allinoneService.openedPost.showcount;
              post.shownamelist = this.allinoneService.openedPost.shownamelist;
              post.showresult = this.allinoneService.openedPost.showresult;
              post.totalvote = this.allinoneService.openedPost.totalvote;
              post.venue = this.allinoneService.openedPost.venue;
              post.vote = this.allinoneService.openedPost.vote;
              post.voting = this.allinoneService.openedPost.voting;
              post.answer = this.allinoneService.openedPost.answer;
              post.postsetting = this.allinoneService.openedPost.postsetting;
              post.postDomainAdmin =
                this.allinoneService.checkDomainIsInAdminDomainList(
                  this.allinoneService.openedPost.domain
                );
              post.postOrgAdmin = this.allinoneService.checkOrgIsInAdminOrgList(
                this.allinoneService.openedPost.orgid
              );
              post.postAdmin = this.allinoneService.checkOrgIsInPostAdminOrgList(
                this.allinoneService.openedPost.orgid
              ),
              post.videopost = this.allinoneService.openedPost.videopost;
              post.videourl = this.allinoneService.openedPost.videourl;
            }
          });
        }
      }
      this.closeDetail();
    });
  }

  closeDetail() {
    this.allinoneService.openedPost = '';
    this.location.replaceState('/posts');
  }

  like(post: any, type: any) {
    if (type == '') {
      post.like = 'false';
      post.totallike -= 1;
      var index = post.totalreact.map((x: any) => x.id).indexOf(post.react);
      post.totalreact[index].count -= 1;
      if (post.totalreact[index].count == 0) {
        post.totalreact.splice(index, 1);
      }
      console.log(post.totalreact);
    } else {
      if (post.like == 'true') {
        post.like = 'true';
        var index = post.totalreact.map((x: any) => x.id).indexOf(post.react);
        post.totalreact[index].count -= 1;
        if (post.totalreact[index].count <= 0) {
          post.totalreact.splice(index, 1);
        }
        post.react = type;
        var index = post.totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          post.totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          post.totalreact.push(temp);
        }
      } else {
        post.like = 'true';
        post.react = type;
        post.totallike += 1;
        var index = post.totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          post.totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          post.totalreact.push(temp);
        }
      }
    }
    this.likePost(post, type);
  }

  likePost(post: any, type: any) {
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      date: this.allinoneService.getCurrentDateToDB(),
      time: this.allinoneService.getCurrentTimeToDB(),
      postsyskey: post.syskey,
      commentsyskey: '',
      type: type,
      posttype: post.meetingtype == '' ? '1' : '',
      action: '',
    };
    this.subscriptions.sink = this.kunyekService.likePost(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }

  comment(index: number) {
    this.posts[index].commentboxopen = !this.posts[index].commentboxopen;
  }

  getGroupData() {
    let data = {
      domain: this.allinoneService.getDomain().shortcode,
      contact: '',
      count: '',
      channelid: '',
      checkingroup: 'true',
    };
    this.subscriptions.sink = this.kunyekService
      .getGroupList(data)
      .subscribe((res) => {
        if (res.returncode == '300') {
          this.groupList = res.list;
        }
        console.log('RESULT ==>>');
        console.log(res);
      });
  }

  share(result: any, index: any) {
    console.log('REsult =>>');
    console.log(result);
    const dialog = this.dialog.open(SharePostComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        groupList: JSON.parse(JSON.stringify(this.groupList)),
        postsyskey: result.syskey,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  submitComment(event: any, index: number) {
    event.preventDefault();
    event.stopPropagation();
    var tempText = this.posts[index].commentText.ops;
    if (
      tempText == undefined ||
      tempText == null ||
      (tempText.length == 1 &&
        (tempText[0].insert == '\n' || tempText[0].insert == '\n\n') &&
        !this.posts[index].selectedImage)
    ) {
      this.messageService.openSnackBar('Comment cannot be blank!', 'warn');
      return;
    } else {
      this.saveComment(index);
    }
  }

  async saveComment(index: number) {
    this.posts[index].saveCommentLoading = true;
    var tempText = this.posts[index].commentText.ops;
    var mentionList: any[] = [];
    var finalText: string = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        mentionList.push(item.insert.mention.id.toString());
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        if (item.insert.mention.value) {
          temp += '(__' + item.insert.mention.value.toString() + '__)';
        } else {
          temp += '(__' + item.insert.mention.id.toString() + '__)';
        }
        finalText += temp;
      } else {
        finalText += item.insert.toString();
      }
    });

    finalText = finalText.substring(0, finalText.length - 1);

    var imagename = '',
      imagedata = '';
    var data;
    if (this.posts[index].selectedImage && this.posts[index].scImage) {
      if (this.posts[index].scImage.size >= 307200) {
        this.posts[index].scImage = await this.allinoneService.compressImage(
          this.posts[index].scImage
        );
        if (this.posts[index].scImage == false) {
          this.messageService.openSnackBar(
            'Error while uploading your image.',
            'warn'
          );
          this.posts[index].saveCommentLoading = false;
          return;
        }
      }
      imagename = this.posts[index].uploadimage;
      // imagedata = this.posts[index].selectedImage;
      const result = await this.allinoneService.newfileUploadToS3(
        this.posts[index].scImage,
        this.posts[index].uploadimage,
        'post/' + this.posts[index].syskey.toString() + '/'
      );
      if (result) {
        data = {
          type: '1',
          postsyskey: this.posts[index].syskey,
          domain: this.allinoneService.getDomain().shortcode,
          date: this.allinoneService.getCurrentDateToDB(),
          time: this.allinoneService.getCurrentTimeToDB(),
          replysyskey: '',
          comment: finalText,
          imagename: imagename,
          mention: mentionList,
          imagedata: '',
          name: this.posts[index].admin,
        };
        this.subscriptions.sink = this.kunyekService
          .commentPost(data)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.posts[index].saveCommentLoading = false;
                this.posts[index].commentText = '';
                this.posts[index].selectedImage = '';
                this.posts[index].imagetype = '';
                console.log(this.posts[index].comment);
                this.posts[index].comment += 1;
              } else {
                this.posts[index].saveCommentLoading = false;
                this.messageService.openSnackBar(res.message, 'warn');
              }
            },
            (err) => {
              this.posts[index].saveCommentLoading = false;
            }
          );
      } else {
        this.messageService.openSnackBar(
          'Error while uploading image.',
          'warn'
        );
      }
    } else {
      data = {
        type: '1',
        postsyskey: this.posts[index].syskey,
        domain: this.allinoneService.getDomain().shortcode,
        date: this.allinoneService.getCurrentDateToDB(),
        time: this.allinoneService.getCurrentTimeToDB(),
        replysyskey: '',
        comment: finalText,
        mention: mentionList,
        imagename: imagename,
        imagedata:
          imagedata != ''
            ? imagedata.toString().replace(/^data:image\/\w+;base64,/, '')
            : '',
        name: this.userid,
      };
      this.subscriptions.sink = this.kunyekService.commentPost(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.posts[index].saveCommentLoading = false;
            this.posts[index].commentText = '';
            this.posts[index].selectedImage = '';
            this.posts[index].imagetype = '';
            console.log(this.posts[index].comment);
            this.posts[index].comment += 1;
          } else {
            this.posts[index].saveCommentLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.posts[index].saveCommentLoading = false;
        }
      );
    }
  }

  async compressFile(index: number) {
    if (
      this.imageCompress.byteCount(this.posts[index].selectedImage) > 1048576
    ) {
      await this.imageCompress
        .compressFile(this.posts[index].selectedImage, 1, 50, 50)
        .then((result: any) => {
          this.posts[index].selectedImage = result;
          console.warn(
            'Size in bytes is now:',
            this.imageCompress.byteCount(this.posts[index].selectedImage)
          );
          if (this.imageCompress.byteCount(result) > 1048576) {
            this.posts[index].compressFile(index);
          }
        });
    }
  }

  getOrgs() {
    this.orgList = [];
    this.gettingOrgList = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.subscriptions.sink = this.kunyekService.getOrgs(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.orgList = res.list;
          this.gettingOrgList = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.gettingOrgList = false;
        }
      },
      (err) => {}
    );
  }

  openFilterMenu() {
    const dialog = this.dialog.open(FilterDialogComponent, {
      closeOnNavigation: true,
      panelClass: ['filter', 'mat-typography'],
      data: {
        isDomainAdmin: this.isDomainAdmin,
        orgList: this.orgList,
        select: this.filter,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.filter = dialogResult.data;
        if (this.filter.type == '1' || this.filter.type == '2') {
          this.type = this.filter.type;
        } else {
          this.type = this.filter.orgid;
        }
        this.refreshPosts();
      }
    });
  }

  reportForm(syskey: any) {
    const dialog = this.dialog.open(ReportsComponent, {
      height: '415px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        syskey: syskey,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
      }
    });
  }

  selectUrl: any = '';

  clickDownloadPDF(data: any, file: any, index: any) {
    var fileUrl = [];
    fileUrl = data.fileurl;
    this.selectUrl = fileUrl[index];
    console.log('CLICKED DOWNLOAD>>>' + this.selectUrl.toString());

    this.downloadFile().subscribe((response: any) => {
      saveAs(response, file);
      // this.downloading = false
    }),
      (error: any) => {
        // this.downloading = false
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  downloadFile(): any {
    return this.http.get(this.selectUrl, { responseType: 'blob' });
  }

  clickMetting(url: any) {
    window.open(url, '_blank');
  }

  LikeAndTagForm(syskey: any, type: any, list: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        syskey: syskey,
        type: type,
        list: list,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
        this.isAfterBlock = true;
        this.scrollToTop();
      }
    });
  }

  VoteListForm(syskey: any, code: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        isFromVote: true,
        syskey: syskey,
        code: code,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
        this.isAfterBlock = true;
        this.scrollToTop();
      }
    });
  }

  ResponseListForm(poll: any, syskey: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        isFromResponse: true,
        name: poll.name,
        code: poll.code,
        syskey: syskey,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
        this.isAfterBlock = true;
        this.scrollToTop();
      }
    });
  }

  getSinglePost() {
    return new Promise((resolve) => {
      const data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        date: '',
        time: '',
        note: '',
        tag: [],
        imagename: [],
        type: '',
        count: 1,
        viewasadmin: '',
        postsyskey: this.postsyskey,
      };
      this.subscriptions.sink = this.kunyekService.getPosts(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.list = res.list.map((item: any) => ({
              ...item,
              showMore: false,
              commentboxopen: false,
              commentText: '',
              selectedImage: '',
              imagetype: '',
              saveCommentLoading: false,
              deletingPost: false,
              postDomainAdmin:
                this.allinoneService.checkDomainIsInAdminDomainList(
                  item.domain
                ),
              postOrgAdmin: this.allinoneService.checkOrgIsInAdminOrgList(
                item.orgid
              ),
              postAdmin: this.allinoneService.checkOrgIsInPostAdminOrgList(
                item.orgid
              ),
            }));
            res.list.map(async (item: any) => {
              item.totalreact.sort(function (a: any, b: any) {
                return b.count - a.count;
              });
              for (var i = 0; i < item.answer.length; i++) {
                var res = item.answerresult.filter(
                  (x: any) => x.code == item.answer[i].code
                );
                item.answer[i].userlist = res.length > 0 ? res[0].userlist : [];
                item.answer[i].count = res.length > 0 ? res[0].count : 0;
              }
              if (item.meetinglink != '' && item.ics == 'false') {
                var await_meetinglink: any = await this.getPreview(
                  item.meetinglink
                );
                if (await_meetinglink == false) {
                  item = Object.assign(item, {
                    preview: false,
                  });
                } else {
                  // console.log(await_meetinglink.image?.image[0].url);

                  var checkImage = await_meetinglink.image;
                  console.log(checkImage);
                  var imgUrl = '';
                  if (checkImage != undefined) {
                    imgUrl = checkImage[0].url;
                  }
                  console.log('URL>>' + imgUrl.toString());
                  item = Object.assign(item, {
                    preview: true,
                    previewTitle: await_meetinglink.title,
                    previewImage: imgUrl,
                  });
                }
              }
            });
            this.allinoneService.openedPost = res.list[0];
            resolve(true);
          } else {
            resolve(false);
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  getPreview(meetinglink: string) {
    return new Promise((resolve) => {
      this.kunyekService.getLinkPreviewData(meetinglink).subscribe(
        (res: any) => {
          resolve(res);
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }

  trackByItems(index: number, item: any): number {
    return item.id;
  }

  getContactList() {
    this.gettingContactList = true;
    this.contactsList = [];
    const data = {
      contact: [],
      count: 15,
      start: 0,
      end: 15,
      search: '',
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      newdesign : true
    };
    this.subscriptions.sink = this.kunyekService.getContactList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.contactsList.push.apply(this.contactsList, res.list);
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingContactList = false;
      },
      (err) => {
        this.gettingContactList = false;
      }
    );
  }

  goToMessage(user: any) {
    const tempItem = Object.assign(user, {
      type: '3',
    });
    localStorage.setItem('user', JSON.stringify(tempItem));
    this.router.navigateByUrl(`/message?d=1`);
  }

  ContactSearchChange() {
    if (this.searchContactCancel) {
      this.searchContactCancel = false;
    }
  }
  homesearchContact() {
    if (this.contactName == '') {
      return;
    }
    if (this.searchContactCancel) {
      this.searchContactCancel = false;
      this.contactName = '';
      this.gettingContactList = true;
      const data = {
        contact: [],
        count: 15,
        start: 0,
        end: 15,
        search: this.contactName,
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
      };
      this.subscriptions.sink = this.kunyekService
        .getContactList(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.contactsList = res.list;
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.gettingContactList = false;
          },
          (err) => {
            this.gettingContactList = false;
          }
        );
    } else {
      this.searchContactCancel = false;
      this.searchingContact = true;
      this.contactsList = [];
      this.gettingContactList = true;
      const data = {
        contact: [],
        count: 15,
        start: 0,
        end: 15,
        search: this.contactName,
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
      };
      this.subscriptions.sink = this.kunyekService
        .getContactList(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.contactsList = res.list;
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.gettingContactList = false;
            this.searchingContact = false;
            this.searchContactCancel = true;
          },
          (err) => {
            this.gettingContactList = false;
            this.searchingContact = false;
            this.searchContactCancel = true;
          }
        );
    }
  }

  handleVideoPlayEvent(event: any) {
    console.log(event);
  }
}
