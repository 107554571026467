import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  @Input() membersList: any[] = [];
  @Input() memberType: any[] = [];
  @Input() orgid: string = '';
  @Input() setting: string = '';
  @Output('createGroup')
  createGroup: EventEmitter<any> = new EventEmitter<any>();
  giForm = {
    "groupname" : ""
  }

  giFormSubmitted : boolean = false
  saveLoading : boolean = false

  userlist : any = []
  membertypelist: any[] = []

  orgname: string = '';

  constructor(
    public allinoneService : AllInOneService,
    public kunyekService : KunyekService,
    public messageService : MessageService,
    // private changeDetectorRef : ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    for(let i = 0; i < this.membersList.length; i++){
      this.userlist.push(this.membersList[i].userid)
    }
    this.memberType.map((item: any) => {
      this.membertypelist.push(item.name);
    });
    this.orgname = this.allinoneService.orgs.find((x: any) => x.orgid == this.orgid).name;
  }


  submitGroupinfo(){
    this.giFormSubmitted = true
    if(this.giForm.groupname == ""){
      this.messageService.openSnackBar('Group Name cannot be empty!', 'warn')
      return;
    }
    else{
      this.allinoneService.isLoading = true;
      const data = {
        "domain": this.allinoneService.getDomain().shortcode,
        "contact": this.orgid && this.setting !='003' ? []: this.userlist,
        "channelname": this.giForm.groupname,
        "date": this.allinoneService.getCurrentDateToDB(),
        "payroll":"false",
        "hidden":"false",
        "class":"false",
        "lat":"",
        "long":"",
        "location":"",
        "orgid":"",
        "memberorgid": this.orgid,
        "membertype": this.orgid == '' || (this.orgid &&  this.setting == '003')? [] : this.membertypelist
      }
      //this.changeDetectorRef.detectChanges()
      this.kunyekService.createGroup(data).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.allinoneService.isLoading = false
          this.giFormSubmitted = false
          this.emitCreateGroup(res.channelid)
          //this.changeDetectorRef.detectChanges()
        }
        else {
          this.giFormSubmitted = false
          this.allinoneService.isLoading = false
          //this.changeDetectorRef.detectChanges()
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      err => {
        this.allinoneService.isLoading = false
        this.giFormSubmitted = false
        //this.changeDetectorRef.detectChanges()
        this.messageService.openSnackBar(err, 'warn');
      }
      )
    }
  }

  emitCreateGroup(channelid : string){
    this.createGroup.emit(channelid)
  }

}
