import { formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { LeaveAbsenceDetailViewComponent } from '../../leave-absence-detail-view/leave-absence-detail-view.component';
import { Sort } from '@angular/material/sort';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-earlyout-report',
  templateUrl: './earlyout-report.component.html',
  styleUrls: ['./earlyout-report.component.scss'],
})
export class EarlyoutReportComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;
  gettingMemberPosition: boolean = false;
  orgid: string = '';
  organizations: any = [];
  subscriptions = new SubSink();
  searchLoading: boolean = false;
  getEarlyoutReportSubscription!: Subscription;
  getMemberSub!: Subscription;

  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];

  earlyoutReportList: any = [];
  earlyoutReportLoading: boolean = false;

  searchText: string = '';
  isFocus: boolean = false;
  openfilter: boolean = false;

  d = new Date();
  dateRangeStartDate: any = '';
  formatToShow = 'yyyy-MM-dd';
  // minDate = '';
  minDate = new Date();
  tempweekbefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBefore = new Date(this.tempweekbefore);
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  piedateFormat = 'dd/MM/yyyy';

  // websockect
  connectSocketLoading: boolean = true;
  private socket: WebSocket | undefined;
  public socketConnectionStatus: string = 'disconnected';
  public socketReceivedMessage: string = '';
  connectionId: string = '';
  drsocketfileurl: any = '';
  updateCId: boolean = false;
  socketConnectTime: any;

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  earlyoutForm = {
    startdate: this.aweekBefore,
    enddate: new Date(Date.now()),
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status: '001',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private http: HttpClient
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.dateRangeStartDate = this.currentStartDate;
    var addDays = 35;
    var date = new Date(this.dateRangeStartDate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
  }

  ngOnDestroy(): void {
    this.socket?.close(3001);
    this.getEarlyoutReportSubscription &&
      this.getEarlyoutReportSubscription.unsubscribe();
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.subscriptions.unsubscribe();
    clearTimeout(this.socketConnectTime);
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      //  ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  departmentChange(dep: any) {
    if (this.earlyoutForm.department != dep) {
      this.earlyoutForm.department = dep;
      this.earlyoutForm.division = 'All';
      this.earlyoutForm.teamid = 'All';
      this.earlyoutForm.costcenter = 'All';
      this.earlyoutForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.earlyoutForm.division != divi) {
      this.earlyoutForm.division = divi;
      this.earlyoutForm.teamid = 'All';
      this.earlyoutForm.costcenter = 'All';
      this.earlyoutForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.earlyoutForm.teamid != teamid) {
      this.earlyoutForm.teamid = teamid;
      this.earlyoutForm.costcenter = 'All';
      this.earlyoutForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.earlyoutForm.costcenter != costcenter) {
      this.earlyoutForm.costcenter = costcenter;
      this.earlyoutForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.earlyoutForm.subdivision != subdivision) {
      this.earlyoutForm.subdivision = subdivision;
    }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.getMemberSub = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.departmentList = res.departmentlist;
          // this.divisionList = res.divisionlist;
          // this.teamidList = res.teamidlist;
          for (var i = 0; i < res.departmentlist.length; i++) {
            this.departmentList.push(res.departmentlist[i]);
          }
          for (var i = 0; i < res.divisionlist.length; i++) {
            this.divisionList.push(res.divisionlist[i]);
          }
          for (var i = 0; i < res.teamidlist.length; i++) {
            this.teamidList.push(res.teamidlist[i]);
          }
          for (var i = 0; i < res.costcenterlist.length; i++) {
            this.costCenterList.push(res.costcenterlist[i]);
          }
          for (var i = 0; i < res.subdivision.length; i++) {
            this.subDivisionList.push(res.subdivision[i]);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  search() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '003',
    };
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.searchLoading = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);
      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;

        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.getEarlyoutReport();
      } else {
        this.drsocketfileurl = tempData.fileurl;

        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            console.log('Your get data res');
            console.log(res);
            this.earlyoutReportList = res;
            this.searchLoading = false;
            this.connectionId = '';
            this.socket?.close(3001);
            clearTimeout(this.socketConnectTime);
          });
        }
      }
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoading = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  getEarlyoutReport() {
    this.searchLoading = true;
    var startd = this.allinoneService.parseDate(this.earlyoutForm.startdate);
    var endd = this.allinoneService.parseDate(this.earlyoutForm.enddate);
    var data = {
      orgid: this.orgid,
      startdate: startd,
      enddate: endd,
      department:
        !this.openfilter || this.earlyoutForm.department == 'All'
          ? ''
          : this.earlyoutForm.department,
      division:
        !this.openfilter || this.earlyoutForm.division == 'All'
          ? ''
          : this.earlyoutForm.division,
      teamid:
        !this.openfilter || this.earlyoutForm.teamid == 'All'
          ? ''
          : this.earlyoutForm.teamid,
      costcenter:
        !this.openfilter || this.earlyoutForm.costcenter == 'All'
          ? ''
          : this.earlyoutForm.costcenter,
      subdivision:
        !this.openfilter || this.earlyoutForm.subdivision == 'All'
          ? ''
          : this.earlyoutForm.subdivision,
      activestatus: this.openfilter ? this.earlyoutForm.status : '001',
      connectionid: this.connectionId,
    };
    console.log(data);
    this.getEarlyoutReportSubscription &&
      this.getEarlyoutReportSubscription.unsubscribe();
    this.getEarlyoutReportSubscription = this.kunyekService
      .getEarlyoutReport(data)
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res.returncode == '300') {
            // this.earlyoutReportList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            this.searchLoading = false;
          }
        },
        (err) => {
          this.searchLoading = false;
        }
      );
  }

  earlyoutTimeDetails(type: String, data: any, datelistname: string) {
    console.log(data);
    const dialog = this.dialog.open(LeaveAbsenceDetailViewComponent, {
      maxWidth: '95vw',
      width: '800px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        type: type,
        list: data,
        datelistname: datelistname,
        from: 'earlyout',
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  export() {
    var exdata: any = [];
    const startdate = this.allinoneService.formatDBToShow(
      this.allinoneService.parseDate(this.earlyoutForm.startdate)
    );
    const enddate = this.allinoneService.formatDBToShow(
      this.allinoneService.parseDate(this.earlyoutForm.enddate)
    );
    var name = 'Early Out Report (' + startdate + ' - ' + enddate + ').xlsx';
    var filteredData = this.filterPipe.transform(
      this.earlyoutReportList,
      this.searchText,
      'lateandearlyoutreport'
    );

    filteredData.map((item: any) => {
      var temp = {
        'Employee ID': item.employeeid,
        'User ID': item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        'Team ID': item.teamid,
        'Cost Center': item.costcenter,
        'Early Out Time 1': item.earlyouttime1,
        'Early Out Time 2': item.earlyouttime2,
        'Early Out Time 3': item.earlyouttime3,
        'Early Out Time 4': item.earlyouttime4,
        'Half Day Absence': item.halfdayabsent,
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  sortData(sort: Sort) {
    const data = this.earlyoutReportList;
    if (!sort.active || sort.direction === '') {
      this.earlyoutReportList = data;
      return;
    }
    this.earlyoutReportList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'userid') {
        return this.allinoneService.compare(a.userid, b.userid, isAsc);
      } else if (sort.active == 'username') {
        return this.allinoneService.compare(a.username, b.username, isAsc);
      } else if (sort.active == 'employeeid') {
        return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
      } else if (sort.active == 'earlyouttime1') {
        return this.allinoneService.compare(
          a.earlyouttime1,
          b.earlyouttime1,
          isAsc
        );
      } else if (sort.active == 'earlyouttime2') {
        return this.allinoneService.compare(
          a.earlyouttime2,
          b.earlyouttime2,
          isAsc
        );
      } else if (sort.active == 'earlyouttime3') {
        return this.allinoneService.compare(
          a.earlyouttime3,
          b.earlyouttime3,
          isAsc
        );
      } else if (sort.active == 'earlyouttime4') {
        return this.allinoneService.compare(
          a.earlyouttime4,
          b.earlyouttime4,
          isAsc
        );
      } else if (sort.active == 'halfdayabsent') {
        return this.allinoneService.compare(
          a.halfdayabsent,
          b.halfdayabsent,
          isAsc
        );
      }
      return 0;
    });
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  refresh() {
    this.currentPage = 1;
    this.getEarlyoutReport();
  }

  startdateChange() {
    var currentEndDate = formatDate(
      this.earlyoutForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.earlyoutForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.earlyoutForm.enddate > this.minDate ||
      this.earlyoutForm.enddate < this.earlyoutForm.startdate
    ) {
      this.earlyoutForm.enddate = this.earlyoutForm.startdate;
    }
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }
}
