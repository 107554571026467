import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-crm-person-in-charge',
  templateUrl: './crm-person-in-charge.component.html',
  styleUrls: ['./crm-person-in-charge.component.css']
})
export class CrmPersonInChargeComponent implements OnInit {
  gettingData: boolean = false;
  itemsPerPage = 100;
  p = 1;
  saveLoading: boolean = false;
  serviceFormSubmitted: boolean = false;
  editPerson: boolean = false;
  addPerson: boolean = false;
  checkEdit: boolean = false;
  orgid: any = '';
  checkModeal: boolean = false;
  picListdata: any = [];
  searchtext: any = '';
  searchtexts: any = '';
  isFocus: boolean = false;
  statusFilter: any = '';
  serviceFilter: any = '';
  currentPage = 1;
  FormSubmitted: boolean = false;
  searchNonea: any = "";
  searchNones: any = "";
  lastPage: any = '';
  members: any = [];
  text1: any = '';
  text2: any = '';
  searchpipe:any = "";
  personForm = {
    "userid": "",
    "username": "",
    "autoid": ""
  }
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 500,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];
  organizations: any = [];
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  private dialogRef: any;
  private dialogData: any;
  sortby: any = '';
  total: any = 0;
  assignedto: any = '';

  nextDisabled: boolean = false;
  // var dia = dialogRef: MatDialogRef<CrmProductComponent>;
  searchbool: any = false;
  permission: any = [];
  view: boolean = false;
  create: boolean = false;
  edit: boolean = false;
  delete: boolean = false;
  isdomainAdmin: boolean = false;
  checkuseridDisabled:boolean  = false;
  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public datepipe: DatePipe,

    // @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.assignedto = this.route.snapshot.queryParamMap.get('assignedto') || "";
    this.dialogRef = this.injector.get(MatDialogRef, null);
    
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    if (this.dialogData) {
      console.log(this.dialogData);
      this.checkModeal = this.dialogData.check;
      this.orgid = this.dialogData.orgid;
      console.log(this.dialogData.picList);
      console.log('Pass Candi>>');
      // this.deleteList = JSON.parse(JSON.stringify(data.deletelist));
      console.log('--AA--');
    } else {
      if(this.allinoneService.crmBlocked == 'true'){
        this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
      }else{
      if (this.allinoneService.crmOrgAdmin == 'true') {
      } else {

        if (this.permission.length > 0) {
          var type = this.permission.map((x: any) => x).indexOf('0011');
          if (type == -1) {
            this.router.navigateByUrl('sales')
          }

          var view = this.permission.map((x: any) => x).indexOf('00111');
          var create = this.permission.map((x: any) => x).indexOf('00112');
          var edit = this.permission.map((x: any) => x).indexOf('00113');
          var delet = this.permission.map((x: any) => x).indexOf('00114');
          if (view == -1) {
            this.view = true;
          }
          if (create == -1) {
            this.create = true;
          }

          if (edit == -1) {
            this.edit = true;
          }

          if (delet == -1) {
            this.delete = true;
          }
        }
      }
    }

      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid;
    }
  }
  ngOnInit(): void {
    if (this.view == true) {
    } else {
      this.getPIC();
      this.getMembers();
    }
  }
  selectMember(name: any, id: any) {
    // this.personForm.username= "";
    // this.personForm.userid = "";
    this.personForm.username = name;
    this.personForm.userid = id;
  }
  pageChange(event: any) {
    console.log(event);
    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.lastPage);
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;
        if (event > (this.picListdata.length / this.itemsPerPage)) {
          this.getPIC()
        }
      }
    }
  }
  deletePic(id: any) {
    if (this.delete == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');

    } else {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        width: "250px",
        panelClass: ['mat-typography'],
        closeOnNavigation: true
      })
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.gettingData = true;
          const data = {
            autoid: id
          }
          this.kunyekService.deletePic(data).subscribe(
            (res: any) => {
              // console.log(res);
              if (res.returncode == '300') {
                this.refresh();
                // this.gettingData = false;
              } else {
                this.gettingData = false;
                this.messageService.openSnackBar(res.message, 'warn');
              }
            },
            (err) => {
              this.gettingData = false;
              this.messageService.openSnackBar(err, 'warn');
            });
        }
      });
    }
  }
  newPic() {
    if (this.create == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {
      this.addPerson = true;
      this.editPerson = false;
      this.FormSubmitted = false;
      this.personForm.autoid = "";
      this.personForm.userid = "";
      this.personForm.username = "";
    }
  }

  submitPerson() {

    if(this.personForm.userid.length > 50 || this.personForm.username.length > 255){
      return;
    }

    this.FormSubmitted = true;
    // if (this.personForm.userid != '') {
    //   if ((/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    //     this.personForm.userid
    //   ) == false)) {
    //     if (this.personForm.userid != '') {
    //       if ((/([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g).test(this.allinoneService.checkUserId(this.personForm.userid.toString().trim())) == false) {
    //         //  if(this.leadForm.phone == '' || this.leadForm.phone.substring(0, 2) != '09' || this.leadForm.phone.substring(0, 4) != '+959' || this.leadForm.phone.toString().length > 12){
    //         this.messageService.openSnackBar("Please enter valid phone number.", 'warn');
    //         return;
    //       }
    //     }
    //   }

    // }
    // if (!this.personForm.userid && !this.personForm.username) {
    //   this.messageService.openSnackBar("You need to add at least one item.", 'warn');
    //   return;
    // }
    this.saveLoading = true;
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "picname": this.personForm.username,
      "picid": this.personForm.userid == '' ? '' : this.allinoneService.checkUserId(this.personForm.userid.toString().trim()),
    }

    console.log(this.editPerson);
    console.log(this.personForm.autoid);
    if (this.editPerson) {
      data = Object.assign(data,
        {
          autoid: this.personForm.autoid,
        }
      );
    }
    this.kunyekService.addPerson(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.addPerson = false;
          this.editPerson = false;
          this.FormSubmitted = false;
          this.refresh();
        } else {
          this.saveLoading = false;
          this.FormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.addPerson = false;
        this.FormSubmitted = false;
        this.editPerson = false;
        this.saveLoading = false;
      }
    );
  }
  editPIC(data: any) {
    if (this.edit == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');

    } else {
      this.addPerson = false;
      this.personForm.userid = data.userid;
      this.personForm.username = data.picname;
      this.personForm.autoid = data.autoid
      if(this.personForm.userid != ''){
        this.checkuseridDisabled = true;
      }
      this.editPerson = true;
      console.log(this.personForm);
    }
  }

  cancel() {
    this.addPerson = false;
    this.editPerson = false;
    this.personForm.username = '';
    this.personForm.userid = '';
  }
  getMembers() {
    this.members = [];
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getMembersCRM(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );

  }

  getPIC() {

    this.gettingData = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "pagesize": this.itemsPerPage,
      "sortby": this.sortby == '' ? '' : this.sortby,
      "query": this.searchbool == true ? this.searchtexts : ''
    }
    this.kunyekService.getCrmPIC(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res.list);
          res.list.map((pic: any) => {
            this.picListdata.push({
              "autoid": pic.autoid,
              "picid": pic.picid,
              "picname": pic.picname,
              "userid": pic.userid,
              "sortby": pic.sortby,
            });
          });
          this.total = res.total;

          var sort = this.picListdata.length - 1;
          if (parseInt(this.total) == this.picListdata.length) {
            console.log("getTrue")
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.data[sort].sortby;
          } else {
            console.log("getfalse");
            this.sortby = this.sortby = sort > -1 ? this.picListdata[sort].sortby : '';
          }
          this.gettingData = false;
          this.isExcel = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );
  }

  
  getPICExport() {
    
    return new Promise((resolve) => {
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "pagesize": this.itempercount,
      "sortby": this.sortby == '' ? '' : this.sortby,
      "query": this.searchbool == true ? this.searchtexts : ''
    }
    this.kunyekService.getCrmPIC(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res.list);
          res.list.map((pic: any) => {
            this.downloaddata.push({
              "autoid": pic.autoid,
              "picid": pic.picid,
              "picname": pic.picname,
              "userid": pic.userid,
              "sortby": pic.sortby,
            });
          });
          resolve('true')
        
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          resolve('false')
        }
      },
      (err) => {
        resolve('false')
      }
    );
  });
  }
  cancelFunction() {
    this.isExcel = false;
    this.addPerson = false;
    this.editPerson = false;
  }

  onSearch() {
    if (this.searchtexts != '') {
      this.searchbool = true;
      this.refresh()
    }
  }

  clear() {
    this.searchbool = false;
    this.searchtexts = '';
    this.refresh();
  }
  changePageCount(event: any) {
    this.itemsPerPage = event.target.value;
    this.currentPage = 1;
    this.getPIC();
  }
  refresh() {
    this.total = 0;
    this.picListdata = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    if (this.view == true) {

    } else {
      this.getPIC();

    }
  }
  goProduct(data: any) {
    this.router.navigateByUrl(`sales/service/products?q=${data.productid}`);
  }
  close() {
    console.log("ok")
    const data = {
      'data': this.picListdata,
      'status': true,
    }
    this.dialogRef.close(data);
  }

  goService() {
    this.router.navigateByUrl('sales/services')
  }

  // Excel
  errorList: any = []
  // excel:boolean = false;

  checkingExcelFile: boolean = false;
  allok: boolean = false;
  duplicateList: any = [];
  warningList: any = [];
  excelerror: boolean = false;
  addcustomer: boolean = false;
  finalMemberList: any = [];
  useridList: any = [];
  newcclist: any = [];
  uidList: any = [];
  excel: any = '';
  rawMemberList: any[] = [];
  @ViewChild('excelFile') excelFile: any;
  Start: any = 0;
  End: any = 30;
  mobileList: any = [];
  descriptionList: any = [];
  descriptinoList1: any = [];
  isExcel: boolean = false;
  downloadSampleFile(): any {
    this.http
      .get(
        this.allinoneService.bucketUrl + 'templates/CRMPersoninChargeSampleTemplate.xlsx',
        { responseType: 'blob' }
      )
      .subscribe((response: any) => {
        saveAs(response, 'CRMPersoninChargeSampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }


  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
    this.errorList = [];
    this.mobileList = [];
  }

  onExcelFileSelect(event: any) {
    this.excelerror = false;
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        console.log(this.rawMemberList);
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }


  importpic() {
    if (this.create == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {
      this.isExcel = true;
      this.excel = '';
      this.errorList = [];
      this.warningList = [];
      this.excelerror = false;
      this.duplicateList = [];
      this.finalMemberList = [];
      this.useridList = [];
      this.newcclist = [];
      this.uidList = [];
      this.allok = false;
      this.errorList = [];
      this.mobileList = [];
    }
  }


  checkExcelFile() {
    this.checkingExcelFile = true;

    this.descriptinoList1 = [];
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.uidList = [];
    this.mobileList = [];

    const tempCcList: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '')
      ) {
      } else {

        const einde = this.mobileList.findIndex((x: any) =>
          x.name.trim() == raw[0] ? raw[0] : ''.toString().trim()
        );
        if (einde > -1) {
          if (raw[1] == null || raw[1] == undefined || raw[1] == '') {
            const tempError = {
              line: (i + 1).toString(),
              error:
                "Name 1'" +
                raw[0].toString().trim() +
                "' is duplicated.",
            };
            this.errorList.push(tempError);
          } else {
            const uindex = this.descriptionList.findIndex((x: any) =>
              x.userid.trim() == raw[1] ? raw[1] : ''.toString().trim()
            );
            console.log(uindex)
            if (uindex > -1) {

              const tempError = {
                line: (i + 1).toString(),
                error: "Name 2'" +
                  raw[0].toString().trim() +
                  "' is duplicated.",
              };
              this.errorList.push(tempError);

            } else {
              if (raw[1] ? raw[1] : ''.toString().trim() != '') {
                var tempEId1 = {
                  line: (i + 1).toString(),
                  userid: raw[1].toString().trim(),
                };
                this.descriptionList.push(tempEId1);
              }

              const picname = raw[0] ? raw[0].toString() : '';
              const picid = raw[1] ? raw[1].toString() : '';


              const tempMember = {
                picname: picname,
                picid: picid,
              };
              this.finalMemberList.push(tempMember);
              this.checkingExcelFile = false;
            }
          }

        } else {
          if (raw[0] ? raw[0] : ''.toString().trim() != '') {
            var tempEId = {
              line: (i + 1).toString(),
              name: raw[0].toString().trim(),
            };
            this.mobileList.push(tempEId);
          }
          const uindex = this.descriptinoList1.findIndex((x: any) =>
            x.userid.trim() == raw[1] ? raw[1] : ''.toString().trim()
          );
          // console.log(uindex)
          if (raw[1] == null || raw[1] == undefined || raw[1] == '') {
            const picname = raw[0] ? raw[0].toString() : '';
            const picid = raw[1] ? raw[1].toString() : '';


            const tempMember = {
              picname: picname,
              picid: picid,
            };
            this.finalMemberList.push(tempMember);
            this.checkingExcelFile = false;
          } else {

            if (uindex > -1) {

              const tempError = {
                line: (i + 1).toString(),
                error: "User ID '" +
                  raw[1].toString().trim() +
                  "' is duplicated.",
              };
              this.errorList.push(tempError);

            }
            else {
              console.log("ok5")
              if (raw[1] ? raw[1] : ''.toString().trim() != '') {
                var tempEId1 = {
                  line: (i + 1).toString(),
                  userid: raw[1].toString().trim(),
                };
                this.descriptinoList1.push(tempEId1);
              }

              const picname = raw[0] ? raw[0].toString() : '';
              const picid = raw[1] ? raw[1].toString() : '';


              const tempMember = {
                picname: picname,
                picid: this.allinoneService.checkUserId(picid),
              };
              this.finalMemberList.push(tempMember);
            this.allok = true;

              this.checkingExcelFile = false;
            }
          }





        }


      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('duplicate ====> ');
    console.log(this.duplicateList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (
      this.errorList.length == 0 &&
      this.newcclist.length == 0 &&
      this.finalMemberList.length > 0
    ) {
      // this.checkEidList()
    } else {
      this.checkingExcelFile = false;
    }
  }




  submitExcel() {
    console.log("11")

    if (this.finalMemberList.length > 0) {
      if (this.End > this.finalMemberList.length) {
        this.End = this.finalMemberList.length;
      }
      this.AddExeclPIC();
    }
    else {
      this.messageService.openSnackBar('Your excel file is empty', 'warn')
    }
  }




  AddExeclPIC() {

    console.log(22)


    var tempcuostmer: any[] = [];
    for (let i = this.Start; i < this.End; i++) {
      tempcuostmer.push(this.finalMemberList[i]);
    }
    this.addcustomer = true;
    this.excelerror = false;
    const data = {
      "piclist": tempcuostmer,
      "createtype": 'array',
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "picname": '',
      "picid": '',
    };

    this.kunyekService.addPerson(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        if (this.End == this.finalMemberList.length) {
          this.saveLoading = false;
          this.addPerson = false;
          this.editPerson = false;
          this.FormSubmitted = false;
          this.refresh();
          this.Start = 0;
          this.End = 30;
        } else {
          this.Start = this.End;
          this.End = this.End + 30;
          if (this.End > this.finalMemberList.length) {
            this.End = this.finalMemberList.length;
          }
          this.AddExeclPIC();
        }
      } else {
        this.saveLoading = false;
        this.addPerson = false;
        this.editPerson = false;
        this.FormSubmitted = false;
        this.refresh();
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });

  }
  downloaddata:any = [];
  itempercount: any = '';
  excelexportLoading:boolean = false;
  async exportpic()  {
    this.excelexportLoading = true;
    this.downloaddata = JSON.parse(JSON.stringify(this.picListdata))
    if (this.itemsPerPage > this.total) {
      this.itempercount = this.itemsPerPage;
    } else {
      this.itempercount = this.total - this.picListdata.length
      this.excelexportLoading = true;
      await this.getPICExport();
    }

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Person_in_Charge_' + time + '.xlsx';
    this.downloaddata.map((pic: any) => {
      data.push({
        "Name": pic.picname,
        "User ID": pic.userid,
      });
    });
    this.excelexportLoading = false;

    this.allinoneService.exportEcecl(data, name);
  }



}