<div class="container-fluid">
  <div class="content">
    <!-- <app-office-breadcrumb *ngIf="!isAdding" s_breadcrumb="Requisition Request">
    </app-office-breadcrumb>
    <app-office-breadcrumb *ngIf="isAdding" s_breadcrumb="Requisition Request"
      [t_breadcrumb]="isEditing ? 'Edit' : 'New' " (emit)="cancel()"></app-office-breadcrumb> -->
    <app-office-breadcrumb *ngIf="!isAdding" s_breadcrumb="Employee Requisition">
    </app-office-breadcrumb>
    <app-office-breadcrumb *ngIf="isAdding" s_breadcrumb="Employee Requisition"
      [t_breadcrumb]="isEditing ? 'Ref No: ' + submitForm.refno : 'New' " (emit)="cancel()"></app-office-breadcrumb>
    <ng-container *ngIf="isAdding; else listView">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()">
            Cancel
          </button>
          <ng-container *ngIf="submitForm.status != '002'">
            <ng-container *ngIf="submitForm.status != '003'">
              <ng-container *ngIf="submitForm.status != '004'">
            <app-button-loader class="mb-3" (click)="submit()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading">
          </app-button-loader>
          </ng-container>
        </ng-container>
      </ng-container>
          
        </div>
        <div class="d-flex align-items-center ms-auto mb-3"
            *ngIf=" submitForm.status != '001' || (submitForm.status == '001')">
            <span [title]="submitForm.status" class="status" [ngClass]="{
                        'status-green': submitForm.status == '002',
                        'status-red': submitForm.status == '003',
                        'status-gray': submitForm.status == '001',
                        'status-main': submitForm.status == '004'}">
              <span *ngIf="submitForm.status == '001' || submitForm.status == '002' || submitForm.status == '003' || submitForm.status == '004'">
                {{ submitForm.status == '001' ? 'Submitted' : submitForm.status == '002' ? 'Approved' : submitForm.status == '003' ? 'Rejected' : 'Completed' }}
              </span>
            </span>
          </div>
      </div>
      <div class="card card-body my-card mb-3">
        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="refno" class="form-label">Ref No.</label>
            <input class="form-control" id="refno" [(ngModel)]="submitForm.refno" name="refno" #refno="ngModel"
              [disabled]="refNoDisabled" placeholder="TBA" />
          </div>
          <div class="mb-3 col-md-6">
            <label for="date" class="form-label">Requisition Date</label>
            <input class="form-control" type="date" id="date" [(ngModel)]="submitForm.date" name="date" max="9999-12-31"
              [disabled]="isEditing && submitForm.readonly" />
          </div>
          <!-- <div class="mb-3 col-md-12">
            <div class="form-check form-check-inline me-5">
              <input class="form-check-input" type="radio" name="formpriority" id="critical" value="00900"
                [(ngModel)]="submitForm.priority" [disabled]="isEditing && submitForm.readonly" />
              <label class="form-check-label" for="critical"> High </label>
            </div>
            <div class="form-check form-check-inline me-5">
              <input class="form-check-input inline" type="radio" name="formpriority" id="high" value="00500"
                [(ngModel)]="submitForm.priority" [disabled]="isEditing && submitForm.readonly" />
              <label class="form-check-label" for="high">
                Medium
              </label>
            </div>
            <div class="form-check form-check-inline me-5">
              <input class="form-check-input inline" type="radio" name="formpriority" id="low" value="0010"
                [(ngModel)]="submitForm.priority" [disabled]="isEditing && submitForm.readonly" />
              <label class="form-check-label" for="low">
                Low
              </label>
            </div>
          </div> -->
          <div class="mb-3 col-md-12">
            <label for="urgency" class="form-label">Urgency</label>
            <select
              class="form-select"
              name="urgency"
              id="urgency"
              [(ngModel)]="submitForm.priority"
              [disabled]="isEditing && submitForm.readonly"
            >
              <option value="00900">High</option>
              <option value="00500">Medium</option>
              <option value="0010">Low</option>
              <!-- <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
                {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
              </option> -->
            </select>
          </div>
          
          <div class="mb-3 col-md-6">
            <label for="department" class="form-label">
              Department
            </label>
            <input type="text" class="form-control" id="department" name="department" placeholder=""
              [(ngModel)]="submitForm.department" [matAutocomplete]="departmentAutocomplele"
              required #department="ngModel" [disabled]="isEditing && submitForm.readonly" >
            <mat-autocomplete #departmentAutocomplele="matAutocomplete">
              <ng-container
                *ngFor="let dep of departmentList | filter : submitForm.department:'memberDepartmentAutocomplete'">
                <mat-option [value]="dep" *ngIf="dep != 'All'">
                  {{dep}}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <!-- <div class="invalid-feedback"
              *ngIf="department.invalid || (department.dirty || department.touched) && memberformSubmitted && needToFillPositionControl">
              <div *ngIf="department.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div> -->
          </div>

          <div class="mb-3 col-md-6">
            <label for="division" class="form-label">
              Division
            </label>
            <input type="text" class="form-control" id="division" name="division" placeholder=""
              [(ngModel)]="submitForm.division" [matAutocomplete]="divisionAutoComplete"
              [disabled]="isEditing && submitForm.readonly" >
            <mat-autocomplete #divisionAutoComplete="matAutocomplete">
              <ng-container
                *ngFor="let division of divisionList | filter : submitForm.division:'memberDepartmentAutocomplete'">
                <mat-option [value]="division">
                  {{division}}
                </mat-option>
              </ng-container>

            </mat-autocomplete>
          </div>
          <ng-container *ngIf="isEditing && submitForm.readonly;else inputTemplate">
            <!-- <div class="mb-3 col-md-6">
              <label for="product" class="form-label">Product</label>
              <input class="form-control" name="product" id="product" [(ngModel)]="productValue" disabled/>
            </div> -->
            <div class="mb-3 col-md-6">
              <label for="project" class="form-label">Project</label>
              <input class="form-control" name="project" id="project" [(ngModel)]="projectValue" disabled/>
            </div>
          </ng-container>
          <ng-template #inputTemplate>
            <!-- <div class="mb-3 col-md-6">
              <label for="product" class="form-label">Product</label>
              <ng-select class="role-select" [items]="productListdata" bindLabel="name" bindValue="productid"
                placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="submitForm.product">
              </ng-select>
            </div> -->
            <div class="mb-3 col-md-6">
              <label for="Project" class="form-label">Project</label>
              <ng-select class="role-select" [items]="projectList" bindLabel="name" bindValue="projectid"
                placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="submitForm.project">
              </ng-select>
              <mat-progress-bar mode="indeterminate" *ngIf="gettingProjectList"></mat-progress-bar>
            </div>
          </ng-template>
          <div class="mb-3 col-md-6">
            <label for="startdate" class="form-label">Recruitment Date</label>
            <input class="form-control" type="date" id="startdate" [(ngModel)]="submitForm.targetdate"
              name="targetdate" max="9999-12-31" [disabled]="isEditing && submitForm.readonly" />
          </div>

          <div class="mb-3 col-md-12">
            <label for="requestreason" class="form-label">Reason</label>
            <select class="form-select" name="requestreason" id="requestreason" [(ngModel)]="submitForm.requestreason" #requestreason="ngModel"
              required [disabled]="gettingReasonList || (isEditing && submitForm.readonly)"
              [ngClass]="{'is-invalid' : formSubmitted && requestreason.invalid}">
              <option value="">-</option>
              <option *ngFor="let i of reasonList" [value]="i.reasonid">
                {{ i.text }}
              </option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="getTemplateLoading"></mat-progress-bar>
            
          </div>

          <div class="mb-3 col-md-12">
            <label for="remark" class="form-label">Remark</label>
            <textarea class="form-control" [(ngModel)]="submitForm.remark" rows="3" #remark="ngModel"
              [disabled]="isEditing && submitForm.readonly" required [ngClass]="{'is-invalid' : formSubmitted && remark.invalid}" ></textarea>
          </div>
          
        </div>
      </div>
      <!-- <ng-container *ngFor="let data of positionList;let i = index; let first=first">
        <div class="card card-body my-card mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <div class="info mb-1 me-5" style="font-size: 16px;">Position</div>
            <button *ngIf="!submitForm.readonly"  class="btn btn-custom instant-remove" style="background-color: rgb(200, 21, 21) !important"
              (click)="removePositionList(i)">
              <i class="fa fa-trash-o" aria-hidden="true"></i> &nbsp; Remove
            </button>
          </div>
          <div class="row">
            <div class="mb-3 col-md-6">
              <label [attr.for]="'position'+i" class="form-label">No. of People</label>
              <input type="number" class="form-control" [attr.id]="'position'+i" [attr.name]="'position'+i" #people="ngModel"
                [(ngModel)]="data.people" [disabled]="isEditing && submitForm.readonly" min="0" [ngClass]="{'is-invalid' : formSubmitted && people.invalid}" required>
            </div>
            <div class="mb-3 col-md-6">
              <label [attr.for]="'position'+i" class="form-label">Position</label>
              <select class="form-select" name="'position'+i" id="position'+i" [(ngModel)]="data.position" #name="ngModel" required
                [disabled]="getTemplateLoading || (isEditing && submitForm.readonly)" [ngClass]="{'is-invalid' : formSubmitted && name.invalid}"
                (ngModelChange)="positionChange(i)"
                >
                <option value="">-</option>
                <option *ngFor="let i of templateList" [value]="i.reqtempid">
                  {{ i.name }}
                </option>
              </select>
              <mat-progress-bar mode="indeterminate" *ngIf="getTemplateLoading"></mat-progress-bar>
            </div>
            
            <div class="mb-3 col-md-6">
              <label [attr.for]="'position'+i" class="form-label">Job Requirements</label>
              <input type="text" class="form-control" [attr.id]="'position'+i" [attr.name]="'position'+i"
                  [(ngModel)]="data.requirements">
              <textarea class="form-control" [attr.id]="'position'+i" [attr.name]="'position'+i" #requirements="ngModel"
                [(ngModel)]="data.requirements" rows="5" [disabled]="isEditing && submitForm.readonly" [ngClass]="{'is-invalid' : formSubmitted && requirements.invalid}" required></textarea>
            </div>
            <div class="mb-3 col-md-6">
              <label [attr.for]="'position'+i" class="form-label">Job Description</label>
              <input type="text" class="form-control" [attr.id]="'position'+i" [attr.name]="'position'+i"
                  [(ngModel)]="data.descriptions">
              <textarea class="form-control" [attr.id]="'position'+i" [attr.name]="'position'+i" #description="ngModel"
                [(ngModel)]="data.descriptions" rows="5" [disabled]="isEditing && submitForm.readonly" [ngClass]="{'is-invalid' : formSubmitted && description.invalid}" required></textarea>
            </div>
            <div class="mb-3" style="width:5%">
                <button class="btn btn-custom prize-btn" style="width:50px !important;" (click)="removePositionList(i)">
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </div>
          </div>
        </div>
      </ng-container> -->
      <div class="card card-body my-card mb-3">
        <div class="info mb-3 me-5" style="font-size: 16px;">Position</div>
        <div class="row">
          <div class="mb-3 col-md-6">
            <label for="people" class="form-label">No of Opening</label>
            <input type="number" class="form-control" id="people" name="people" #people="ngModel"
              [(ngModel)]="submitForm.people" [disabled]="isEditing && submitForm.readonly"
              [ngClass]="{'is-invalid' : formSubmitted && people.invalid}" required>
          </div>
          <div class="mb-3 col-md-6">
            <label for="position" class="form-label">Position</label>
            <select class="form-select" name="position" id="position" [(ngModel)]="submitForm.position" #name="ngModel"
              required [disabled]="getTemplateLoading || (isEditing && submitForm.readonly)"
              [ngClass]="{'is-invalid' : formSubmitted && name.invalid}" (ngModelChange)="positionChange(0)">
              <option value="">-</option>
              <option *ngFor="let i of templateList" [value]="i.reqtempid">
                {{ i.name }}
              </option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="getTemplateLoading"></mat-progress-bar>
          </div>
          <div class="mb-3 col-md-6">
            <label for="requirements" class="form-label">Job Requirements</label>
            <!-- <input type="text" class="form-control" id="requirements" name="requirements"
                  [(ngModel)]="submitForm.requirements"> -->
            <textarea class="form-control" id="requirements" name="requirements" #requirements="ngModel"
              [(ngModel)]="submitForm.requirements" rows="5" [disabled]="isEditing && submitForm.readonly"
              [ngClass]="{'is-invalid' : formSubmitted && requirements.invalid}" required></textarea>
          </div>
          <div class="mb-3 col-md-6">
            <label for="descriptions" class="form-label">Job Description</label>
            <!-- <input type="text" class="form-control" [attr.id]="'position'+i" [attr.name]="'position'+i"
                  [(ngModel)]="data.descriptions"> -->
            <textarea class="form-control" id="descriptions" name="descriptions" #description="ngModel"
              [(ngModel)]="submitForm.descriptions" rows="5" [disabled]="isEditing && submitForm.readonly"
              [ngClass]="{'is-invalid' : formSubmitted && description.invalid}" required></textarea>
          </div>
          <!-- <div class="mb-3 col-md-12">
            <label for="remark" class="form-label">Remark</label>
            <textarea class="form-control" [(ngModel)]="submitForm.remark" rows="3"
              [disabled]="isEditing && submitForm.readonly"></textarea>
          </div> -->
        </div>
      </div>

      <div *ngIf="isEditing && (this.submitForm.status == '003' || this.submitForm.status == '004')" class="card card-body my-card mb-3">
        <div class="row">
          <div *ngIf="this.submitForm.status == '003'" class="mb-3 col-md-12">
            <label for="reason" class="form-label">Reason</label>
            <textarea id="reason" name="reason" class="form-control" [(ngModel)]="submitForm.reason" rows="3"
              disabled></textarea>
          </div>
          <div *ngIf="this.submitForm.status == '004'" class="mb-3 col-md-12">
            <label for="comment" class="form-label">Comment</label>
            <textarea id="comment" name="comment" class="form-control" [(ngModel)]="submitForm.hrremark" rows="3"
              disabled></textarea>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!submitForm.readonly" class="row">
        <div class="">
          <button class="btn btn-custom" (click)="addPositionList()">
            <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span>
          </button>
        </div>
      </div> -->

      <!-- </div> -->
    </ng-container>
    <ng-template #listView>
      <div class="d-flex align-items-center mb-3">
        <button class="btn btn-custom me-3" (click)="new()">
          <i class="fa fa-plus me-1"></i> New
        </button>
        <div class="me-2" [matMenuTriggerFor]="statusmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ submitForm.requeststatusname }}
            <mat-menu #statusmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                {{ status.name }}
              </button>
            </mat-menu>
          </button>
        </div>
      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search By Ref No/Positions"
            aria-label="Search By Ref No/Positions" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (dataList | filter : searchText: 'requisitionapproval' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search By Ref No/Positions" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="dataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-1" mat-sort-header="refno">Ref No.</td>
              <td scope="col" class="col-md-2" mat-sort-header="positionlists">Position</td>
              <td scope="col" class="col-md-1" mat-sort-header="people">No of Opening</td>
              <td scope="col" class="col-md-1" mat-sort-header="priority">Priority</td>
              <td scope="col" class="col-md-1" mat-sort-header="requisitiondate">Requisition Date</td>
              <td scope="col" class="col-md-1" mat-sort-header="targetdate">Recruitment Date</td>
              <td scope="col" class="col-md-2" mat-sort-header="status">Status</td>
              <td scope="col" class="col-md-3" mat-sort-header="remark">Remark</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="tableLoading">
            <tr>
              <td colspan="8" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!tableLoading">
            <tr *ngIf="dataList.length == 0">
              <td colspan="8" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let data of dataList | filter: searchText : 'requisitionapproval' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
              <td class="td-data td-edit" (click)="editRequest(data)">
                <span [title]="data.refno">{{ data.refno }}</span>
              </td>
              <!-- <td class="td-data">
                <span [title]="data.positionlists">{{ data.positionlists }}</span>
              </td> -->
              <td class="td-data">
                <span [title]="data.positionname">{{ data.positionname }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.people">{{ data.people }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.priority" class="priority" [ngClass]="{'priorityprimary': data.priority == '00500' ,'priorityred':data.priority == '00900' }">{{ data.priority == '0010' ? 'Low' : data.priority == '00500' ? 'Medium' :
                  'High'}}</span>
              </td>
              <td class="td-data">
                <span [title]="allinoneService.formatDBToShow(data.requisitiondate)">{{
                  allinoneService.formatDBToShow(data.requisitiondate)
                  }}</span>
              </td>
              <td class="td-data">
                <span [title]="allinoneService.formatDBToShow(data.targetdate)">{{
                  allinoneService.formatDBToShow(data.targetdate)
                  }}</span>
              </td>
              <!-- <td class="td-data">
                <span [title]="data.status">{{
                  data.status
                  }}</span>
              </td> -->
              <td class="td-data td-edit">
                <span [title]="data.status" class="status" [ngClass]="{
                    'status-green': data.status == '002',
                    'status-red': data.status == '003',
                    'status-gray': data.status == '001',
                    'status-main': data.status == '004'
                  }">{{ data.status == '001' ? 'Submitted' : data.status == '002' ? 'Approved' : data.status == '003' ?
                  'Rejected' : 'Completed' }}</span>
                <!-- <span class="status ms-2" *ngIf="
                    request.requeststatus == '002' &&
                    request.requesttype == 'WOEIEPPLMXNW'
                  " [ngClass]="{
                    'status-green': request.paymentstatus,
                    'status-red': !request.paymentstatus
                  }">{{ request.paymentstatus ? "Paid" : "Unpaid" }}</span> -->
              </td>
              <td class="td-data">
                <span [title]="data.remark">{{
                  data.remark
                  }}</span>
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editRequest(data)">
                  <i class="fa fa-edit"></i>
                </button>
                <button [disabled]="data.readonly" class="btn btn-outline-danger"
                  (click)="deleteRequest(data.requisitionid, index)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="dataList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>