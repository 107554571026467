import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { OfficeLeaveOpeningFormComponent } from '../office-leave-opening-form/office-leave-opening-form.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-travel',
  templateUrl: './select-travel.component.html',
  styleUrls: ['./select-travel.component.scss']
})
export class SelectTravelComponent implements OnInit {

  @ViewChild('chatPS') chatPS!: ElementRef;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  kunyekusername = this.allinoneService.getKunyekUserName()

  isLeaveOpening: boolean = false;
  _travelList: any = [];
  travelList: any = [];
  selectedTravelList: any = [];
  gettingTravelList: boolean = false;
  isAllTravel: boolean = false;
  _isEnd: boolean = false;
  isAdmin: boolean = false;

  domainid: string = '';
  orgid: string = '';
  templateid: string = '';
  calendarid: string = '';

  startCount: any = 0;
  endCount: any = 30;

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;
  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<SelectTravelComponent>,
    private injector: Injector,
    // private dialog: MatDialog,
    private router: Router,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.isLeaveOpening = this.dialogData.isLeaveOpening;
    this.domainid = this.dialogData.domainid;
    this.orgid = this.dialogData.orgid;
    if (this.isLeaveOpening) {
      this.isAdmin = this.dialogData.isAdmin;
      this.templateid = this.dialogData.templateid;
      this.calendarid = this.dialogData.calendarid;
    } else {
      this.selectedTravelList = JSON.parse(
        JSON.stringify(this.dialogData.travellist)
      );
    }
  }

  ngOnInit(): void {
    this.intialSearchTravel();
  }

  intialSearchTravel() {
    // this.travelList = [];
    this.gettingTravelList = true;
    const data = {
      requesttype: "BCNDUWO85SCGQW",
      requeststatus: "002",
      startdate: "",
      enddate: "",
      end: "",
      orgid: this.orgid
    };
    
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getOfficeRequest(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.gettingTravelList = false;
          if (res.requestlist.length < 30) {
            this.isAllTravel = true;
          }
          if (res.requestlist.length == 0) {
            this._isEnd = true;
          }
          if (this.startCount == 0 && this.endCount == 30) {
            this._travelList = [];
          }

          this._travelList = res.requestlist
          this.travelList = this._travelList          
          // if (this.selectedTravelList.length > 0) {
          //   // for (var a = 0; a < this.selectedUserList.length; a++) {
          //   //   for (var b = 0; b < arrayContactList.length; b++) {
          //   //     if (this.selectedUserList[a]['userid'] ==
          //   //       arrayContactList[b]['userid']) {
          //   //       arrayContactList[b]['select'] = true;
          //   //     }
          //   //   }
          //   // }
          // }
          this.travelList.forEach((travel:any)=>{
            travel.username = this.kunyekusername
            if(this.selectedTravelList.length>0){
              if(travel.requestid==this.selectedTravelList[0].requestid){
                travel.selected = true
              }else{
                travel.selected = false
              }
            }
          })
          console.log(this.travelList,'........................')
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingTravelList = false;
      },
      (err) => {
        this.gettingTravelList = false;
      }
    );
  }

  onScrolledDown() {
    if (!this.isAllTravel && !this.gettingTravelList) {
      this.startCount = this.startCount + 30;
      this.endCount = this.endCount + 30;
      this.intialSearchTravel();
    }
  }

  // selectTravel(event: any, travel: any) {
  //   var index = this.travelList.findIndex(
  //     (trvl: any) => trvl.requestid == travel.requestid
  //   );
  //   console.log(index);
  //   if (index != -1) {
  //     this.travelList[index].checked = !this.travelList[index].checked;
  //   }
  //   if (event.target.checked) {
  //     var index = this.selectedTravelList.findIndex(
  //       (seltravel: any) => seltravel.requestid == travel.requestid
  //     );
  //     if (index == -1) {
  //       travel.checked = true;
  //       this.selectedTravelList.push(travel);
  //     } else {
  //       this.selectedTravelList[index].checked = true;
  //     }
  //   } else {
  //     var index = this.selectedTravelList.findIndex(
  //       (seluser: any) => seluser.userid == travel.userid
  //     );
  //     if (index != -1) {
  //       // this.selectedTravelList[index].checked = false;
  //       this.selectedTravelList.splice(index,1);
  //     }
  //   }
  //   this.dialogRef.close({ data: this.selectedTravelList });
  // }

  selectTravel(travel:any){
    this.selectedTravelList[0] = travel
    this.selectedTravelList[0].travelrequestid = this.selectedTravelList[0].requestid
    this.dialogRef.close({ data: this.selectedTravelList });
  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    let finalList = [];
    finalList = this.selectedTravelList.filter((user: { checked: boolean }) => {
      return user.checked == true;
    });
    console.log('fl');
    console.log(finalList);
    
    this.dialogRef.close({ data: finalList });
  }

  openLeaveOpeningForm(member: any) {
    let tempData = {
      userid: member.userid,
      username: member.username,
      orgid: this.orgid,
      openingid: '',
      templateid: this.templateid,
      calendarid: this.calendarid,
      isnew: true,
    };
    // let user = this.allinoneService.replaceAll(
    //   this.allinoneService.encrypt(JSON.stringify(tempData)),
    //   '/',
    //   '###'
    // );

    let user = encodeURIComponent(this.allinoneService.encrypt(JSON.stringify(tempData)));

    let url = (this.isAdmin ? this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/hxm' : 'office/hxm') +
    '/leaveopening/form/' + user
    console.log(url);
    this.dialogRef.close();
    this.router.navigateByUrl(url);
    // const dialog = this.dialog.open(OfficeLeaveOpeningFormComponent, {
    //   maxWidth: '95vw',
    //   width: '550px',
    //   minHeight: '606px',
    //   maxHeight: '95vh',
    //   panelClass: ['mat-typography', 'mat-dialog-height-transition'],
    //   closeOnNavigation: true,
    //   autoFocus: false,
    //   data: {
    //     user: member,
    //     domainid: this.domainid,
    //     orgid: this.orgid,
    //     templateid: this.templateid,
    //     calendarid: this.calendarid,
    //     isnew: true,
    //   },
    // });

    // dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  // returnTravel(data:any) {
  //   var value = "";
  //   if (data["requeststatus"] == "001") {
  //     value = data["duration"].toString() +
  //         " days of " +
  //         data['remark'] +
  //         " travel submitted.";
  //   } else if (data["requeststatus"] == "002") {
  //     value = data["duration"].toString() +
  //         " days of " +
  //         data['remark'] +
  //         " travel approved by " +
  //         data['personname'] +
  //         " (" +
  //         Date.parse(data["startdate"])
  //         ")";
  //   } else if (data["requeststatus"] == "003") {
  //     value = data["duration"].toString() +
  //         " days of " +
  //         data['remark'] +
  //         " travel rejected by " +
  //         data['personname'] +
  //         " (" +
  //         Date.parse(data["startdate"]) +
  //         ")";
  //   } else if (data["requeststatus"] == "004") {
  //     value = data["duration"].toString() +
  //         " days of " +
  //         data['remark'] +
  //         " travel completed";
  //   }
  // }
}