import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-label-setup',
  templateUrl: './label-setup.component.html',
  styleUrls: ['./label-setup.component.css']
})
export class LabelSetupComponent implements OnInit {

  isEdit = false;
  submitLoading = false;
  removelabel = new Set();

  labelForm = [{
    "id": "ticketno",
    "name": "Ticket No.",
    "subname": "",
    "type": "textfield",
    "mandatory": false
  }, {
    "id": "refno",
    "name": "Ref No.",
    "subname": "",
    "type": "textfield",
    "mandatory": false
  }, {
    "id": "desc",
    "name": "Description",
    "subname": "",
    "type": "textarea",
    "mandatory": false
  }, {
    "id": "type",
    "name": "Service Type",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "tickettypeid",
    "name": "Service Sub Type",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "ttid",
    "name": "TT ID",
    "subname": "",
    "type": "textfield",
    "mandatory": false
  }, {
    "id": "siteid",
    "name": "Site ID",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "completionsnap",
    "name": "Completion Snap",
    "subname": "",
    "type": "textfield",
    "mandatory": false
  }, {
    "id": "rca",
    "name": "RCA",
    "subname": "",
    "type": "textfield",
    "mandatory": false
  }, {
    "id": "rcadetail",
    "name": "RCA Detail",
    "subname": "",
    "type": "textarea",
    "mandatory": false
  }, {
    "id": "fromsite",
    "name": "From Site",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "tosite",
    "name": "To Site",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "question",
    "name": "Question",
    "subname": "",
    "type": "button",
    "mandatory": false
  }, {
    "id": "status",
    "name": "Status",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "voidticket",
    "name": "Void",
    "subname": "",
    "type": "checkbox",
    "mandatory": false
  }, {
    "id": "priority",
    "name": "Priority",
    "subname": "",
    "type": "radio",
    "mandatory": false
  }, {
    "id": "productid",
    "name": "Product",
    "subname": "",
    "type": "selectform",
    "mandatory": false
  }, {
    "id": "projectid",
    "name": "Project",
    "subname": "",
    "type": "selectform",
    "mandatory": false
  }, {
    "id": "moduleid",
    "name": "Module",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "assignedto",
    "name": "Assigned To",
    "subname": "",
    "type": "selectform",
    "mandatory": false
  }, {
    "id": "version",
    "name": "Version",
    "subname": "",
    "type": "textfield",
    "mandatory": false
  }, {
    "id": "mer",
    "name": "MER",
    "subname": "",
    "type": "textnofield",
    "mandatory": false
  }, {
    "id": "startdatetime",
    "name": "Start Date",
    "subname": "Time",
    "type": "datetimefield",
    "mandatory": false
  }, {
    "id": "enddatetime",
    "name": "End Date",
    "subname": "Time",
    "type": "datetimefield",
    "mandatory": false
  }, {
    "id": "targetdatetime",
    "name": "Target Date",
    "subname": "Time",
    "type": "datetimefield",
    "mandatory": false
  }, {
    "id": "duedatetime",
    "name": "Due Date",
    "subname": "Time",
    "type": "datetimefield",
    "mandatory": false
  }, {
    "id": "customerid",
    "name": "Customer",
    "subname": "",
    "type": "selectform",
    "mandatory": false
  }, {
    "id": "branchid",
    "name": "Branch",
    "subname": "",
    "type": "selectbox",
    "mandatory": false
  }, {
    "id": "isinternal",
    "name": "Internal",
    "subname": "",
    "type": "checkbox",
    "mandatory": false
  }, {
    "id": "contactid",
    "name": "Contact",
    "subname": "",
    "type": "textfieldselectbox",
    "mandatory": false
  }, {
    "id": "customerphone",
    "name": "Phone",
    "subname": "",
    "type": "textnofield",
    "mandatory": false
  }, {
    "id": "customeremail",
    "name": "Email",
    "subname": "",
    "type": "textfield",
    "mandatory": false
  }, {
    "id": "reopen",
    "name": "Recurring",
    "subname": "",
    "type": "checkbox",
    "mandatory": false
  }, {
    "id": "reticketno",
    "name": "Re Ticket No",
    "subname": "",
    "type": "searchtextfield",
    "mandatory": false
  }, {
    "id": "details",
    "name": "Detail",
    "subname": "",
    "type": "textarea",
    "mandatory": false
  }, {
    "id": "solution",
    "name": "Solution",
    "subname": "",
    "type": "textarea",
    "mandatory": false
  }, {
    "id": "remark",
    "name": "Remark",
    "subname": "",
    "type": "textarea",
    "mandatory": false
  }];


  constructor(
    private allInOneService: AllInOneService,
    private kunyekservice: KunyekService,
    private messageService: MessageService
  ) { }



  isRemoval(obj: any) {
    return this.removelabel.has(obj.id);
  }


  isBlank(name: string) {
    return name === "" || !name;
  }

  ngOnInit(): void {
    this.getLabels();
  }

  getLabels() {
    const domain = this.allInOneService.getDomain();
    const data = {
      "orgid": this.allInOneService.selectedOrgCRM,
      "domainid": this.allInOneService.getDomain().domainid,
      "appid": domain.appid,
      "userid": this.allInOneService.getKunyekUserId(),
      "domain": domain.shortcode,
    }
    this.kunyekservice.getLabels(data).subscribe({
      next: (res) => {
        const list = res.customlabel;
        for (let i = 0; i < list.length; i++) {
          const { id } = list[i];
          if (id === this.labelForm[i].id) {
            this.labelForm[i].subname = list[i].subname;
            this.labelForm[i].mandatory = list[i].mandatory;
          }
        }

        for (let i = 0; i < res.removelabel.length; i++) {
          this.removelabel.add(res.removelabel[i].id);
        }
      },
      error: (err) => {
        this.messageService.openSnackBar("Server Error", "error");
        console.log(err, "error")
      }
    });
  }

  handleSave() {
    const list = Object.values(this.labelForm);
    const domain = this.allInOneService.getDomain();
    const data = {
      orgid: this.allInOneService.selectedOrgCRM,
      domainid: this.allInOneService.getDomain().domainid,
      appid: domain.appid,
      userid: this.allInOneService.getKunyekUserId(),
      domain: domain.shortcode,
      data: list,
      removelabel: this.getRemoveLableObj()
    }
    this.submitLoading = true;
    this.kunyekservice.updateLabel(data).subscribe({
      next: (res) => {
        this.submitLoading = false;
        if (res.returncode === "300") {
          this.messageService.openSnackBar("Update Successfully", "success")
          this.isEdit = false;
        } else {
          this.messageService.openSnackBar("Server Error", "error");
        }
      },
      error: (err) => {
        this.submitLoading = false;
        this.messageService.openSnackBar("Server Error", "error");
        console.log(err, "error")
      }
    });
  }

  getRemoveLableObj() {
    const list = Array.from(this.removelabel);
    return list.map(x => {
      const obj = this.labelForm.find(label => label.id === x);
      return obj;
    })
  }

  handleEdit() {
    this.isEdit = true;
  }

  handleCancel() {
    this.isEdit = false;
  }

  handleRemoveLabel(obj: any) {
    if (this.isRemoval(obj)) {
      this.removelabel.delete(obj.id)
    } else {
      this.removelabel.add(obj.id)
    }
  }

  handleSelectMandatoryLabel(obj: any) {
    this.labelForm = this.labelForm.map(label => {
      if (label.id === obj.id) {
        const mandatory = obj.mandatory !== undefined ? obj.mandatory : false;
        return {
          ...label,
          mandatory: !mandatory
        }
      }
      return label
    });
  }


}
