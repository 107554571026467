<div class="gallery" *ngIf="images.length > 0">
    <div *ngIf="images.length != 3" class="row">
        <div class="gallery_item col" *ngFor="let img of images | slice:0:4;let i = index;"
            [ngClass]="{'col-6': images.length > 1, 'col-12': images.length == 1 , 'one_gallery': images.length == 1,'two_gallery': images.length == 2 }">
            <div class="gallery_item_wrapper">
                <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="img" class="gallery_img">
                <!-- <img [src]="img" class = "gallery_img" style="background-color: lightgray;"> -->

                <div class="img-more-overlay" *ngIf="images.length > 4 && i == 3">
                    + {{images.length - 4}}
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="images.length == 3" class="three_gallery">
        <div class="gallery_item" *ngFor="let img of images;let i = index;">
          <!-- <img [src]="img" class = "gallery_img" style="background-color: lightgray;"> -->
            <img [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="img" class="gallery_img">
        </div>
    </div>
</div>