<div mat-dialog-content class="position-relative">

  <!-- <google-map height="300px"   [center]="center" width="100%" [options]="options"  (mapClick)="addMarker($event)">
        <map-marker *ngFor="let markerPosition of markerPositions" [position]="markerPosition" [options]="markerOptions"
            (mapClick)="openInfo()">

        </map-marker>

    </google-map> -->
  <div id="map" style="height: 300px;">

  </div>
  <div class="gotocurrent" title="Go to current location" (click)="goToCurrentPosition()">
    <i class="fas fa-location-arrow"></i>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button type="button" class="btn btn-custom" (click)="ok()" cdkFocusInitial>
    Ok
  </button>

</div>