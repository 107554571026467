<app-crm-breadcrumb s_breadcrumb="Leads" [t_breadcrumb]="isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()">
</app-crm-breadcrumb>


<div *ngIf="!isExcel">


    <div class="close-save-button mb-3">
        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
        Cancel
    </button>
        <app-button-loader (click)="submitLead()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
        </app-button-loader>
    </div>
    <div class="mb-3">
        <label for="name" class="form-label">Name</label>

        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="leadForm.name" required #name="ngModel" [ngClass]="{ 'is-invalid': leadFormSubmitted && name.invalid }">

        <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && leadFormSubmitted">
            <div *ngIf="name.errors?.required">
                {{allinoneService.input_err_msg}}
            </div>
        </div>
        <div class="invalid-feedback-length" *ngIf="leadForm.name.length > 255">
            {{allinoneService.lengthCheck("Name",255)}}
        </div>


    </div>
    <div class="d-flex align-items-center mb-3">
        <div class="form-check  me-5" *ngFor="let item of businessType">
            <label class="radio-inline">
            <input class="form-check-input" type="radio" name="businesstype" [id]="item.key" #leadtype="ngModel"
                [(ngModel)]="leadForm.businesstype" [value]="item.key" [checked]="item.key == leadForm.businesstype">
            {{item.value}}
        </label>
        </div>
    </div>
    <div class="row">
        <div class="mb-3  col-md-6">
            <label for="post" class="form-label">Post (eg. Director, Manager)</label>

            <input type="text" class="form-control" id="post" name="post" [(ngModel)]="leadForm.post">
            <div class="invalid-feedback-length" *ngIf="leadForm.post.length > 255">
                {{allinoneService.lengthCheck("Post",255)}}
            </div>
        </div>
        <div class="mb-3 col-md-6">
            <label for="org" class="form-label">Company</label>

            <input type="text" class="form-control" id="org" name="org" [(ngModel)]="leadForm.org" #org="ngModel" required [ngClass]="{ 'is-invalid': leadFormSubmitted && org.invalid }">
            <div class="invalid-feedback" *ngIf="org.invalid || (org.dirty || org.touched) && leadFormSubmitted">
                <div *ngIf="org.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="mb-3 col-md-6">
            <label for="phone" class="form-label">Phone</label>

            <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="leadForm.phone">
            <div class="invalid-feedback-length" *ngIf="leadForm.phone.length > 255">
                {{allinoneService.lengthCheck("Phone",255)}}
            </div>
            <!-- <div class="invalid-feedback" *ngIf="phone.invalid || (phone.dirty || phone.touched) && leadFormSubmitted">
            <div *ngIf="phone.errors?.required">
                {{allinoneService.input_err_msg}}
            </div>
        </div> -->
        </div>
        <div class="mb-3 col-md-6">
            <label for="email" class="form-label">Email</label>

            <input type="text" class="form-control" id="email" name="email" [(ngModel)]="leadForm.email">
            <div class="invalid-feedback-length" *ngIf="leadForm.email.length > 255">
                {{allinoneService.lengthCheck("Email",255)}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="mb-3  col-md-12">
            <label for="address1" class="form-label">Address</label>

            <input type="text" class="form-control" id="address1" name="address1" [(ngModel)]="leadForm.address1">

        </div>
        <div class="mb-3 col-md-12">

            <input type="text" class="form-control" id="address2" name="address2" [(ngModel)]="leadForm.address2">
            <div class="invalid-feedback-length" *ngIf="leadForm.address1.length > 255 && leadForm.address2.length > 255">
                {{allinoneService.lengthCheck("Address",255)}}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="mb-3 col-md-6">
            <label for="phone" class="form-label">Industry Type</label>

            <input type="text" class="form-control" id="industrytype" name="industrytype" [(ngModel)]="leadForm.industrytype" [matAutocomplete]="autoindustry">
            <!-- <input type="text" aria-label="Number" class="form-control" [(ngModel)]="productValue" (ngModelChange)="productChange()" (keyup.enter)="productChange()" [matAutocomplete]="autoproduct" required #product="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && product.invalid }"> -->
            <mat-autocomplete #autoindustry="matAutocomplete">
                <mat-option *ngFor="let i of industrytypelist | crmFilter :leadForm.industrytype:text1:text2:20" [value]="i.name" (optionSelected)="industrySelect(i)" (onSelectionChange)="industrySelect(i)" (click)="industrySelect(i)">
                    {{i.name}}
                </mat-option>
            </mat-autocomplete>
        </div>
        <div class="mb-3 col-md-6">
            <label for="email" class="form-label">Lead Source</label>

            <input type="text" class="form-control" id="leadsource" name="leadsource" [(ngModel)]="leadForm.leadsource" [matAutocomplete]="autoleadSourse">
            <mat-autocomplete #autoleadSourse="matAutocomplete">
                <mat-option *ngFor="let i of leadSourceList | crmFilter :leadForm.leadsource:text1:text2:20" [value]="i.name" (optionSelected)="leadSelect(i)" (onSelectionChange)="leadSelect(i)" (click)="leadSelect(i)">
                    {{i.name}}
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>

    <div class="row">
        <div class="mb-3 col-md-6">
            <label for="currencycode" class="form-label">Currency Code</label>

            <div>
                <select class="form-select" aria-label="Select Currency" id="currencycode" name="currencycode" [(ngModel)]="leadForm.currencycode" required>
                
                <option *ngFor="let currency of currencyType" value="{{currency.key}}">
                    {{currency.code}} ({{currency.country}})</option>
            </select>
            </div>
        </div>
        <div class="mb-3 col-md-6">
            <label for="status" class="form-label">Status</label>

            <div>
                <select class="form-select" aria-label="Select status" id="status" name="status" [(ngModel)]="leadForm.status" required [disabled]="desiableStatus">
                <option *ngFor="let status of statusList" value="{{status.key}}">
                    {{status.value}}</option>
            </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="mb-3  col-md-6">
            <label for="amount" class="form-label">Amount</label>

            <input type="number" class="form-control" id="amount" name="amount" [(ngModel)]="leadForm.amount" (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
        </div>
        <div class="mb-3 col-md-6 d-flex align-items-end">
            <button class="btn btn-custom" (click)="openProductForm()">
            <i class="fas fa-box-open me-2"></i> {{leadForm.products.length}} Product(s)
        </button>
        </div>
    </div>
    <div class="mb-3">
        <label for="remark" class="form-label">Remark</label>downloadFi

        <textarea class="form-control" id="remark" name="remark" [(ngModel)]="leadForm.remark" rows="3"></textarea>
    </div>
    <input style="display: none" type="file" (change)="onFileSelect($event)" #hiddenpdffileinput multiple>
    <button class="btn btn-outline-primary custom-btn" (click)="hiddenpdffileinput.click()">
    <span>Choose Attachments</span>
</button>
</div>


<div class="attachmentList mb-3">
    <div *ngFor="let file of attachmentList; let index = index">
        <div class="pdf-container">
            <div class="pdf-filename">
                {{file.filename}}
            </div>
            <div class="pdf-action d-flex align-items-center">
                <button mat-icon-button class="icon-button" (click)="removeAttachment(index)">
                    <mat-icon class="delete-icon">delete</mat-icon>
                </button>
                <button mat-icon-button class="ms-2 icon-button" (click)="downloadFile(file.fileurl, file.filename)" *ngIf="file.fileurl">
                    <mat-icon class="download-icon">file_download</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="mb-3 col-md-6 d-flex align-items-end" *ngIf="leadForm.status == '002' && isEditing">
    <button class="btn btn-custom" (click)="convert()">
        <i class="fas fa-sync-alt me-2"></i> Convert
    </button>
</div>

<div *ngIf="isExcel">

    <div class="mb-3 d-flex justify-content-start header mt-3">
        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" [disabled]="addlead">
            Cancel
        </button>

        <div class="close-save-button ">
            <app-button-loader class="btn-custom" (click)="submitExcelLead()" style="border-radius: 5px;" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="addlead" *ngIf="finalMemberList.length > 0">
            </app-button-loader>
            <button *ngIf="finalMemberList.length == 0" class="btn btn-custom" disabled>
                Save
            </button>
        </div>

    </div>
    <div class="mb-3">
        <label for="file" class="form-label">Excel Template (<span class="cc-label"
                (click)="downloadSampleFile()">Download Sample Template</span>)</label>
        <div class="input-group">
            <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx" id="formFile" #excelFile [disabled]="excel">
            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="excel" (click)="removeExcel()">Remove</button>
            <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;" id="btnGroupAddon2" [disabled]="!excel || checkingExcelFile" (click)="checkExcelFile()">
                <span *ngIf="!checkingExcelFile">
                    Check
                </span>
                <span *ngIf="checkingExcelFile">
                    <span class="spinner-border spinner-border-sm button-spinner" role="status"
                        aria-hidden="true"></span>
                    <span class="mx-1">Checking</span>
                </span>
            </button>
        </div>
        <div role="alert" *ngIf="excelerror" style="color: red;padding: 6px;">
            {{allinoneService.input_err_msg}}
        </div>

    </div>
    <div style="color : green" *ngIf="allok">
        <i class="fa fa-check me-2"></i> Template is correct.
    </div>
    <div class="row">
        <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0">
            <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                    error
                </mat-icon>
                <div>
                    Error
                </div>
            </div>
            <hr>
            <ul style="padding-left: 1rem;">
                <li *ngFor="let e of errorList;">
                    In line no. {{e.line}}, {{e.error}}
                </li>
            </ul>
        </div>
        <div class="alert alert-warning custom-box" *ngIf="duplicateList.length > 0 || warningList.length >0">
            <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                    warning
                </mat-icon>
                <div>
                    Warning
                </div>
            </div>
            <hr>
            <ul style="padding-left: 1rem;">
                <li *ngFor="let du of duplicateList;">
                    "{{du}}" is duplicated.
                </li>
                <li *ngFor="let e of warningList;">
                    In line no. {{e.line}}, {{e.error}}
                </li>
            </ul>


        </div>
    </div>

</div>