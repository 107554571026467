<h3>Reject</h3>
<div>
    <div class="mb-2 col-md-12">
        <label for="reason" class="form-label">Reason</label>
        <select class="form-select" name="reason" id="reason"
        [disabled]="callingReason" [(ngModel)]="reasonForm.reason"
        (change)="reasonChange()"
        >
          <!-- <option value="">-</option> -->
          <option *ngFor="let reason of reasonList" [value]="reason.text">
            {{ reason.text }}
          </option>
        </select>
        <mat-progress-bar *ngIf="callingReason" mode="indeterminate" color="primary"></mat-progress-bar>
      </div>
      <div *ngIf="reasonForm.reasontype == '002'" class="col-md-12">
        <textarea class="form-control" name="customreason" id="customreason" rows="3"
        [(ngModel)]="reasonForm.customreason">
        </textarea>
      </div>
</div>
<div mat-dialog-actions align="end">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary mx-2">
        Cancel
    </button>
    <!-- <button type="button" (click)="onConfirm()" class="btn btn-custom" cdkFocusInitial>
        Ok
    </button> -->
    <app-button-loader [disabled]="callingReason" (click)="onConfirm()" [button_text]="'Ok'" [load_button_text]="'Ok'"
          [isLoading]="saveLoading">
        </app-button-loader>

</div>