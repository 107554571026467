import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar/lib/perfect-scrollbar.interfaces';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-check-in-ticket',
  templateUrl: './check-in-ticket.component.html',
  styleUrls: ['./check-in-ticket.component.scss']
})
export class CheckInTicketComponent implements OnInit {
  getPic: boolean = false;
  picdatalist: any = [];
  customersList: any = [];
  productList: any = [];
  projectList: any = [];
  closedTicket: any = [];
  orgid: any = '';
  public config: PerfectScrollbarConfigInterface = {};

  currentCount: any = 20;
  assignedtouserid: any = '';
  customerid: any = "";
  ticketno: any = "";
  customerVal: any = "";
  projectid: any = "";
  productid: any = "";
  getcustomer: boolean = false;
  getproduct: boolean = false;
  getproject: boolean = false;
  isFocus: boolean = false;
  isLoading: boolean = false;
  ticketList: any = [];
  ticketnoList: any = [];
  itemPerPage: any = 20;
  total: any = "";
  sortby: any = "";
  TicketListForInfiniteScroll:any  = [];


  ticketSubscription! : Subscription;



  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CheckInTicketComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    console.log(data);
    this.ticketnoList = JSON.parse(JSON.stringify(data.ticketlist));
    this.orgid = data.orgid;

  }

  async ngOnInit() {
    this.getCustomerList();
    this.getProductList();
    this.getProjectList();
    await this.getTicketList();
    if (this.ticketList.length > 0 && this.ticketnoList.length > 0) {
      for (let i = 0; i < this.ticketList.length; i++) {
        for (let j = 0; j < this.ticketnoList.length; j++) {
          if (this.ticketList[i].ticketno == this.ticketnoList[j]) {
            this.ticketList[i].select = true;
          }

        }

      }
    }



  }

  // Persion in charge
  searchassignedto() {
    this.ticketList = [];
    if (this.assignedtouserid != "") {
      this.refresh();

    } else {
      this.refresh();

    }
  }
  //Ticket 
  searchicket() {
    this.ticketList = [];
    if (this.ticketno != "") {
      this.refresh();

    } else {
      this.refresh();
    }


  }

  // Customer
  getCustomerName(data: any) {
    console.log(data);
    // if
    if (data) {
      this.customerid = data.customerid;
    } else {
      this.customerid = ""
    }
    this.refresh();

    // this.customerValList = this.customerVal


  }


  getCustomerList() {
    this.getcustomer = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    };
    this.kunyekService.getCrmCustomers(data).subscribe(
      (res: any) => {
        this.getcustomer = false;

        if (res.returncode == '300') {
          this.customersList = res.list;
          this.getcustomer = false;
        } else {
          this.getcustomer = false;
        }
      },
      (err) => {
        this.getcustomer = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );

  }


  // Product 
  productChange(data: any) {
    console.log(data);
    if (data) {
      this.productid = data.productid;
    } else {
      this.productid = "";
    }
    this.refresh();
  }


  getProductList() {
    this.getproduct = true;
    this.productList = []
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid": '',
      "customerview": false
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productList = res.list;
          // for (let i of res.list) {
          //   this.products.push({
          //     name: i.name,
          //     pic: i.piclist,
          //     id: i.productid,
          //     modulelist: i.modulelist
          //   }
          //   );
          // }

          console.log(">>>>")
          this.getproduct = false;
        } else {
          this.getproduct = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getproduct = false;
        // this.messageService.openSnackBar(err.message, 'warn');
      }
    );
  }


  // Project

  getProjectName(data: any) {
    console.log(data);
    if (data) {
      this.projectid = data.projectid;
    } else {
      this.projectid = "";
    }
    this.refresh();

  }


  getProjectList() {
    this.projectList = [];
    this.getproject = true;
    const data = {
      orgid: this.orgid,
      "domainid": this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.projectList = res.data;
          this.getproject = false;
        } else {
          this.getproject = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getproject = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }



  search() {
    var data = {
      "ticketnolist": this.ticketnoList
    }
    this.dialogRef.close({
      data: data,
    });
  }

  checkTicket(data: any, value: any) {
    console.log(value);
    if (value.select == false) {
      this.ticketnoList.push(value.ticketno);
      var type = this.ticketList.map((x: any) => x.ticketno).indexOf(value.ticketno);
      this.ticketList[type].select = true;
    } else if (value.select == true) {
      var type = this.ticketnoList.map((x: any) => x).indexOf(value.ticketno);
      var type1 = this.ticketList.map((x: any) => x.ticketno).indexOf(value.ticketno);
      this.ticketList[type1].select = false;
      this.ticketnoList.splice(type, 1);
    }
  }


  // getTicket
  getTicketList() {
    this.isLoading = true;
    return new Promise((resolve) => {

      var data = {
        "orgid": this.orgid,
        "domainid": this.allinoneService.getDomain().domainid,
        "productid": this.productid,
        "customerid": this.customerid,
        "ticketno": this.ticketno,
        "projectid": this.projectid,
        "pagesize": this.itemPerPage,
        "sortby": this.sortby,
        "assignedto": this.assignedtouserid,
        "ticketlist":this.ticketnoList
      }
      this.ticketSubscription && this.ticketSubscription.unsubscribe();
      this.ticketSubscription = this.kunyekService.getTicketForActivity(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.list.map((service: any) => {
              this.ticketList.push({
                "statusname": service.statusname,
                "ticketno": service.ticketno,
                "status": service.status,
                "sortby": service.sortby,
                "select": service.select,
                "projectid": service.projectid,
                "projectname": service.projectname,
                "product": service.productname,
                "productid": service.productid,
                "customername": service.customername,
                "customerid": service.customerid,
              });
            });
            this.closedTicket = [];
            res.closedlist.map((service: any) => {
              this.closedTicket.push({
                "statusname": service.statusname,
                "ticketno": service.ticketno,
                "status": service.status,
                "sortby": service.sortby,
                "select": service.select,
                "projectid": service.projectid,
                "projectname": service.projectname,
                "product": service.productname,
                "productid": service.productid,
                "customername": service.customername,
                "customerid": service.customerid,
              });
            });

            this.total = res.total;

            if (this.ticketList.length > 0 && this.ticketnoList.length > 0) {
              for (let i = 0; i < this.ticketList.length; i++) {
                for (let j = 0; j < this.ticketnoList.length; j++) {
                  if (this.ticketList[i].ticketno == this.ticketnoList[j]) {
                    this.ticketList[i].select = true;
                  }
        
                }
        
              }
            }
            var sort = this.ticketList.length - 1;
            if (parseInt(this.total) == this.ticketList.length) {
              console.log("getTrue")
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
              // this.sortby =   this.sortby = this.data[sort].sortby;
            } else {
              console.log("getfalse");
              this.sortby = this.sortby = sort > -1 ? this.ticketList[sort].sortby : '';
            }
            // if (this.ticketList.length > 0) {
            //   this.sortby = this.sortby = sort > -1 ? this.ticketList[sort].sortby : '';
            // }
      
            this.isLoading = false;
            resolve('true')

          } else {

            this.isLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('false')
          }
        },
        (err) => {
          this.isLoading = false;
          this.messageService.openSnackBar(err, 'warn');
          resolve('false')
        }
      );
    });

  }

  refresh(){
    this.total = 0;
    this.ticketList = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    this.getTicketList();
  }

  onScrollDown() {
    // this.ticketList = JSON.parse(JSON.stringify(this.ticketList));

      // if(this.ticketList.length < this.total){
      // this.getTicketList();

      // }

    // console.log(this.ticketList.length);

    // if (this.currentCount > this.total) {
    //   this.currentCount = this.currentCount;
    // } else {

    //   this.currentCount = this.total - this.data.length;
    // }
  }

  nextDisabled:boolean = false;
  lastPage:any = "";
  currentPage: any = 1;
  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;
   


        if (event > (this.ticketList.length / this.itemPerPage)) {
          console.log("1111");
          this.getTicketList();
        }
      }

    }
  }

  close(){
    this.dialogRef.close();
  }

}

