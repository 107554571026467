<div class="container-fluid">
    <div class="content" *ngIf="!isOpenForm">
        <div class="me-auto">
            <app-kmember-breadcrumb s_breadcrumb="Request">
            </app-kmember-breadcrumb>
        </div>
        <div class="d-flex">
            <div class="input-group mb-2 me-2" style="width: 300px !important">
                <input class="form-control" type="text" placeholder="&#xf002; Search with Name" aria-label="Search"
                    style="font-family: FontAwesome, Ubuntu" [(ngModel)]="searchName" (keyup.enter)="searchByName()">
                <button class="btn btn-custom me-2" matTooltip="Refresh" (click)="searchByName()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <button class="btn btn-custom mb-2 me-2" (click)="newMember()">New</button>
            <div class="align-items-center ms-2" [matMenuTriggerFor]="statusmenu">
                <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button"
                    id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle
                    style="color: white; padding: 6px; min-width: 100px">
                    Status&nbsp;:&nbsp; {{ selecteFormstatusName }}
                    <mat-menu #statusmenu="matMenu" xPosition="before">
                        <button mat-menu-item *ngFor="let status of formStatus" (click)="handleFormSelected(status)">
                            {{ status.name }}
                        </button>
                    </mat-menu>
                </button>
            </div>
        </div>
        <table class="table table-responsive table-borderless">
            <thead class="table-header">
                <tr>
                    <th>အမည်</th>
                    <th>မှတ်ပုံတင်အမှတ်</th>
                    <th>ကဒ်အမျိုးအစား</th>
                    <th>မွေးနေ့</th>
                    <th>နေရပ်လိပ်စာ</th>
                    <th>ဖုန်းနံပါတ်</th>
                    <th>Status</th>
                    <th>Payment Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngIf="!gettingMemberList && memberList.length>0">
                <tr *ngFor="let member of memberList " class="py-4">
                    <td (click)="editMember(member)">
                        {{member.name}}
                    </td>
                    <td (click)="editMember(member)">
                        {{member.nrc}}
                    </td>
                    <td (click)="editMember(member)">
                        {{member.memberType?.name}}
                    </td>
                    <td (click)="editMember(member)">
                        {{allInOneService.formatDBToShow(member.birthdate)}}
                    </td>
                    <td (click)="editMember(member)">
                        {{member.current_address}}
                    </td>
                    <td (click)="editMember(member)">
                        {{member.phone}}
                    </td>
                    <td class="td-edit td-data-2" (click)="editMember(member)">
                        <!-- <span class="status" [ngClass]="{
                            'status-green': member.formstatus == '002',
                            'status-red': member.formstatus == '003',
                            'status-gray': member.formstatus == '001',
                            'status-main': member.formstatus == '004'
                          }">
                            {{member.formstatus=="001"? "Submitted": "Approved"}}
                        </span>                         -->
                        <span *ngIf="member.formstatus==004" class="status status-gray">
                            {{"Saved"}}
                        </span>

                        <span *ngIf="member.formstatus==006" class="status status-gray">
                            {{"Submitted"}}
                        </span>

                        <span *ngIf="member.formstatus==001" class="status status-gray">
                            {{"Approved By Step 1"}}
                        </span>

                        <span *ngIf="member.formstatus==005" class="status status-gray">
                            {{"Approved By Step 2"}}
                        </span>

                        <span *ngIf="member.formstatus==002" class="status status-green">
                            {{"Approved" }}
                        </span>


                        <span *ngIf="member.formstatus==003" class="status status-red">
                            {{"Rejected"}}
                        </span>

                    </td>
                    <td class="td-edit td-data-2" (click)="editMember(member)">
                        <span class="status" [ngClass]="{
                            'status-green': member.paymentstatus == true,
                            'status-red': member.paymentstatus == false
                        }">
                            {{member.paymentstatus==true? "Paid": "Unpaid"}}
                        </span>
                    </td>
                    <td class="justify-contents-end">
                        <button class="btn btn-outline-primary me-2" (click)="editMember(member)">
                            <i class="fa fa-edit"></i>
                        </button>
                    </td>

                </tr>
            </tbody>
            <tbody *ngIf="!gettingMemberList && memberList.length == 0">
                <tr>
                    <td colspan="9" class="nodata">
                        Empty
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="gettingMemberList">
                <tr>
                    <td colspan="9" class="nodata">
                        <div class="spinner-border" entitle="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="content" *ngIf="isOpenForm">
        <app-member-register [currentMember]="currentMember" [status]="action"
            (closeFormHandler)="closeMemberForm()"></app-member-register>
    </div>
</div>