import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-guest-swimmer-preview',
  templateUrl: './temp-swimmer-preview.component.html',
  styleUrls: ['./temp-swimmer-preview.component.scss']
})
export class TempSwimmerPreviewComponent implements OnInit {
  guestswimmer: any;
  guestName: any;
  id!: string;
  todaydate: any;
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Temporary Swimmer', url: 'members/templist', isActive: true },
    { name: '', url: '', isActive: false }
  ];
  constructor(
    private router: Router,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public allInOneService: AllInOneService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.todaydate = this.datePipe.transform(new Date(), 'dd-MM-yyyy hh:mm a');
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '')
    this.getGuestSwimmerByID();
  }

  getGuestSwimmerByID() {
    this.kunyek.tempMember().get({ id: this.id }).subscribe({
      next: (res) => {
        this.guestswimmer = res.data;
        this.guestName = res.data.name;
        this.breadcrumbs[2].name = this.guestName;
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to get guest swimmer preview', 'warn');
      }
    });
  }

  closeForm() {
    this.router.navigate(['members', 'guestlist']);
  }

  goBackToList() {
    this.router.navigateByUrl('members/guestlist');
  }

  printPage() {
    const printContent = document.getElementById('printableCard');
    if (printContent) {
      const printClone = printContent.cloneNode(true);
      const printContainer = document.createElement('div');
      printContainer.style.position = 'absolute';
      printContainer.style.left = '-9999px';
      printContainer.appendChild(printClone);
      document.body.appendChild(printContainer);
      const style = document.createElement('style');
      style.innerHTML = `
        @media print {
          .me-auto, .navbar, .side-nav, .side-nav-expand, .btn-action {
            display: none !important;
          }
          #print-container, #print-container * {
            visibility: visible;
          }
          #print-container {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `;
      document.head.appendChild(style);
      printContainer.id = 'print-container';
      window.print();
      document.body.removeChild(printContainer);
      document.head.removeChild(style);
    } else {
      console.error('printableCard element not found');
    }
  }



  exportPDF() {
    const name = this.guestName + "-" || '';
    const data = document.getElementById('printableCard');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${name}-guest-form.pdf`);

      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
    } else {
      console.error('printableCard element not found');
    }
  }
}
