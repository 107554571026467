import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
@Component({
  selector: 'app-crm-service-form',
  templateUrl: './crm-service-form.component.html',
  styleUrls: ['./crm-service-form.component.css'],
})
export class CrmServiceFormComponent implements OnInit {
  gettingData: boolean = false;
  itemsPerPage = 100;
  p = 1;
  saveLoading: boolean = false;

  servicetype: any = '';
  serviceFormSubmitted: boolean = false;
  addtype: boolean = false;
  servicedata: any = [];
  orgid: any = '';
  servicetypeonOff: any[] = [];
  statusLoading: boolean = false;
  serviceedit: boolean = false;
  notcalculateage: any = 'false';

  organizations: any = [];
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  private dialogRef: any;
  private dialogData: any;
  checktype: boolean = false;
  permission: any = [];
  serviceview: boolean = false;
  constructor(
    private kunyekService: KunyekService,

    public allinoneService: AllInOneService,

    private messageService: MessageService,

    private changeDetectorRef: ChangeDetectorRef,

    public dialog: MatDialog,

    private injector: Injector,
    private router: Router,

    // @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    if (this.dialogRef || this.dialogRef != null) {
      this.checktype = true;
    }
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    console.log(this.notcalculateage);
    console.log('jaejae');

    if (this.allinoneService.crmOrgAdmin == 'true') {
      // this.getServcieType();
    } else {
      this.permission = this.allinoneService.getCRMpermission();
      var type = this.permission.map((x: any) => x).indexOf('003');
      if (type == -1) {
        this.router.navigateByUrl('service');
      }

      var type2 = this.permission.map((x: any) => x).indexOf('0031');

      if (type2 == -1) {
        this.serviceview = true;
      }
      var type1 = this.permission.map((x: any) => x).indexOf('0033');

      if (type1 == -1) {
        this.serviceedit = true;
      }
    }

    if (this.serviceview == true) {
      this.gettingData = false;
    } else {
      this.getServcieType();
    }
  }
  submitService() {
    this.serviceFormSubmitted = true;
    if (this.servicetype == '') {
      return;
    }

    this.saveLoading = true;
    this.servicedata.push({
      name: this.servicetype,
    });

    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      name: this.servicetype,
    };

    this.kunyekService.createServiceType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.addtype = false;
        } else {
          this.saveLoading = false;
          this.addtype = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.addtype = false;
        this.saveLoading = false;
      },
    );
  }

  close() {
    console.log('ok');
    const data = {
      data: this.servicedata,
      status: true,
    };

    this.dialogRef.close(data);
  }

  new() {
    this.addtype = true;
    this.servicetype = '';
  }

  cancel() {
    this.addtype = false;
  }

  getServcieType() {
    this.gettingData = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      getall: true,
    };
    this.kunyekService.getServiceType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.data = res.list;
          this.servicetypeonOff = res.alllist;
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      },
    );
  }

  indexs: any = '';
  onoffstatus(item: any, check: any) {
    if (this.serviceedit == true) {
      this.messageService.openSnackBar(
        'You are not authorised to access.',
        'warn',
      );
    } else {
      // alert(check)
      for (let i of this.servicetypeonOff) {
        if (i.code == item.code) {
          i.serviceon = check;
        }
      }
      this.indexs = item.code;
      this.statusLoading = true;
      var data = {
        code: item.code,
        servicetypeon: check,
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.orgid,
      };
      this.kunyekService.serviceTypeOnOff(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.indexs = '';
            for (let i of this.servicetypeonOff) {
              if (i.code == item.code) {
                i.serviceon = check;
              }
            }
            console.log(this.indexs);

            this.statusLoading = false;
          } else {
            this.statusLoading = false;
            this.indexs = '';
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.indexs = '';
          this.statusLoading = false;
        },
      );
    }
  }

  editname: boolean = false;
  editingname: boolean = false;
  indexss: any;
  nameLoading: boolean = false;
  descservice: any = '';
  shortcode: any = '';
  code: any = '';
  nameFormSubmitted: boolean = false;
  goNameEdit(data: any, index: any) {
    if (this.serviceedit == true) {
      this.messageService.openSnackBar(
        'You are not authorised to access.',
        'warn',
      );
    } else {
      this.editname = true;
      this.indexss = index.toString();
      this.descservice = data.name;
      this.code = data.code;
      this.shortcode = data.shortcode;
      this.notcalculateage =
        data.notcalculateage != undefined ? data.notcalculateage : 'false';
    }
  }

  updateName() {}

  cancelname() {
    this.editname = false;
    this.indexss = '';
  }

  savename() {
    this.nameFormSubmitted = true;
    if (this.descservice == '' || this.shortcode == '' || this.code == '') {
      return;
    } else {
      this.editingname = true;
      this.nameLoading = true;

      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.orgid,
        name: this.descservice,
        shortcode: this.shortcode,
        code: this.code,
        notcalculateage: this.notcalculateage,
      };
      this.kunyekService.createServiceType(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.editingname = false;
            this.editname = false;
            this.nameLoading = false;
            this.getServcieType();
          } else {
            this.editingname = false;
            this.editname = false;
            this.nameLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.editname = false;
          this.nameLoading = false;
        },
      );
    }
  }
  cancelFunction() {
    this.editname = false;
  }

  checkcalculate(data: any, index: any) {
    if (index == 1) {
      this.notcalculateage = 'true';
    } else if (index == 1) {
      this.notcalculateage = 'false';
    }
  }
}
