import { formatDate } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-crm-service-filterbydate',
  templateUrl: './crm-service-filterbydate.component.html',
  styleUrls: ['./crm-service-filterbydate.component.css']
})
export class CrmServiceFilterbydateComponent implements OnInit {
  isTabletView: boolean = false;
  mobileViewWidth: number = 575;
  isMobileView: boolean = false;
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    } else {
      this.isMobileView = false;
    }

  }
  formatToShowCRM = 'dd/MM/yyyy';

  formatToShow = 'yyyy-MM-dd';
  //for filter date
  aweekBefore = new Date().setDate(new Date().getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);
  defaultenddate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  defaultstartdate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  startdate: any = '';
  productVal: any = "";
  customerVal: any = [];
  customerValList: any = [];
  enddate: any = "";
  products: any = [];
  customers: any = [];
  orgcustomer: any = [];
  picList: any = [];
  prioritys: any = []
  picVal: any = "";
  priorityVal: any = '';
  customerName: any = '';
  productID: any = [];

  branchlist: any = [];
  ngbranchlist: any = [];
  pics: any = []
  picID: any = [];
  priorityName: any = '';
  getData: any = [];
  ticketNo: any = '';
  projectList: any = []
  check: boolean = false;
  picdatalist: any = [];
  checkedBol: boolean = false;
  organizations: any = [];
  modulelist: any = [];
  orgid: any = '';
  projectid: any = [];
  projectname: any = '';
  getproduct: boolean = false;
  getPic: boolean = false;
  getCustomer: boolean = false;
  getProject: boolean = false;
  customercare: boolean = false;
  moduleid: any = [];
  modulename: any = "";
  checkmodule: boolean = false;
  checkdate: boolean = false;
  rangestartdate: any = "";
  reportedby: any = "";
  reportedbyid: any = '';
  refNo: any = "";
  age: any;
  startage: any;
  description: any = '';
  detail: any = '';
  recrring: boolean = false;
  productIDList: any = [];
  projectIDList: any = [];
  moduleIDList: any = [];
  assignedtList: any = [];
  checkinternal: boolean = false;
  customergroupList: any = [];
  cusgroupid: any = [];
  searchgroupid: any = "";
  searchbranchid: any = "";
  branchname: any = "";
  groupname: any = "";
  gettingCustomerGroup: boolean = false;
  cusbranchid: any = "";

  ageid: any = '1';

  ageList = [
    { "code": "1", "name": "=" },
    { "code": "2", "name": ">" },
    { "code": "3", "name": "<" },
    { "code": "4", "name": ">=" },
    { "code": "5", "name": "<=" },
    { "code": "6", "name": ">= <=" },
  ]

  getCustomercaredata: any = [];

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CrmServiceFilterbydateComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
    console.log(">>>>>>>>>>>");
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    }

    // this.startdate = data.startdate ? data.startdate : this.defaultstartdate
    // this.startage = data.startdate ? formatDate(
    //   new Date(data.startdate),
    //   this.formatToShow,
    //   'en-US'
    // ).toString() : this.defaultenddate;

    this.enddate = data.enddate ? formatDate(
      new Date(data.enddate),
      this.formatToShow,
      'en-US'
    ).toString() : this.defaultenddate;
    this.rangestartdate = data.rangestartdate ? formatDate(
      new Date(data.rangestartdate),
      this.formatToShow,
      'en-US'
    ).toString() : this.defaultenddate


    this.getData = data.data ? data.data : '';
    this.checkdate = data.checkdate;
    this.check = data.check;
    this.customercare = data.customercare;

    if (this.getData.length != '') {
      console.log("1");
      var splitted = this.getData.age ? this.getData.age.split("-", 2) : '';
      this.ticketNo = this.getData.ticketNo,
        this.productVal = this.getData.productName,
        this.productID = this.getData.productName ? this.getData.productIDList : [],
        this.productIDList = this.getData.productIDList;


      this.customerName = this.getData.customerName,
        this.customerVal = this.getData.customerName ? this.getData.customerValList : [],
        this.picVal = this.getData.picName,
        // this.picID = this.getData.picid,
        this.assignedtoname = this.getData.picName,

        this.picID = this.getData.picName ? this.getData.assignedtList : [],
        this.assignedtList = this.getData.assignedtList,
        this.priorityVal = this.getData.priorityid,
        this.checkedBol = this.getData.assignedtome,
        this.projectname = this.getData.projectName,

        this.projectid = this.getData.projectName ? this.getData.projectIDList : [],
        this.cusgroupid = this.getData.groupname ? this.getData.customergroupidlist : [],
        this.ngbranchlist = this.getData.branchname ? this.getData.customerbranchlist : [],
        this.searchbranchid = this.getData.branchid;
      this.branchname = this.getData.branchname;


      this.moduleid = this.getData.modulename ? this.getData.moduleIDList : [],
        this.moduleIDList = this.getData.modulename ? this.getData.moduleIDList : [],
        this.modulename = this.getData.modulename,

        this.projectIDList = this.getData.projectIDList,

        this.modulelist = this.getData.modulelist,
        this.searchgroupid = this.getData.cusgroupid,
        this.groupname = this.getData.groupname,


        this.reportedby = this.getData.reportedby,
        this.reportedbyid = this.getData.reportedbyid;
      this.refNo = this.getData.refno,
        this.description = this.getData.description,
        this.detail = this.getData.detail,
        this.recrring = this.getData.recurring ? true : false;
      this.customerid = this.getData.customerid


      console.log(this.getData)
      console.log("....LLLLLLLL>")
      this.startage = this.getData.age ? splitted[0] ? splitted[0] : '' : '';
      this.age = this.getData.age ? splitted[1] ? splitted[1] : '' : '';
      if (this.age != '') {
        this.checkage = true;
      } else {
        this.checkage = false;
      }
      if (this.getData.operator) {

        var type = this.ageList.map((x: any) => x.name).indexOf(this.getData.operator);
        this.ageid = this.ageList[type].code

      }




    } else {
      console.log("2");
      this.productVal = '';
      this.customerVal = [];
      this.startage = '';
    }
    console.log(this.getData);


  }

  customerid: any = '';
  ngOnInit(): void {
    // this.getService();

    this.organizations = this.allinoneService.orgsCRm;
    this.getCustomercaredata = this.allinoneService.getCustomer();
    console.log(this.getCustomercaredata)
    if (this.getCustomercaredata != '') {
      console.log(this.getCustomercaredata)
      this.orgid = this.getCustomercaredata.orgid;
      this.branchlist = this.getCustomercaredata.branchlist;
      this.cusbranchid = this.getCustomercaredata.branchid;
      this.customerid = this.getCustomercaredata.customerid;
    } else {
      this.orgid = this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid;
    }
    this.getMembers();

    this.getProduct();
    if (this.customerid) {
      this.getProjectsByCustomer();
    } else {
      this.getAllProjects();
    }

    this.getCustomers();
    this.getPriorityList();
    this.getPIC();
    this.getGroupCustomer();


  }
  getProjectName() {
    if (Array.isArray(this.projectid) || this.projectid == null) {
      this.projectname = ""

    } else {

      this.projectIDList = this.projectid;
      for (let i of this.projectList) {
        if (i.projectid == this.projectid.projectid) {
          this.projectname = i.name
        }
      }
    }

  }

  getCustomerGroup(data: any) {
    console.log(data);

    if (data != undefined) {

      this.searchgroupid = data.groupid;
      this.groupname = data.name;
      let cust = [];
      for (let i = 0; i < data.customerlist.length; i++) {
        for (let j = 0; j < this.orgcustomer.length; j++) {
          if (data.customerlist[i] == this.orgcustomer[j]['customerid']) {
            cust.push(this.orgcustomer[j]);
          }

        }
      }
      this.customers = cust;
      console.log(cust);


    } else {
      this.searchgroupid = "";
      this.groupname = "";
      this.customers = this.orgcustomer;
    }


  }

  getCustomerBranch(data: any) {
    console.log(data);
    if (data != undefined) {
      this.searchbranchid = data.branchid;
      this.branchname = data.branchname;
    } else {
      this.searchbranchid = "";
      this.branchname = "";
    }

  }




  getGroupCustomer() {
    this.gettingCustomerGroup = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getcustomergroup(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.customergroupList = [];
          res.list.map((item: any) => {
            this.customergroupList.push({
              "groupid": item.groupid,
              "name": item.name == "" ? item.customerlist : item.name,
              "customerlist": item.customerlist,
              "cusotmernames": item.cusotmernames,

            })
          });
          this.gettingCustomerGroup = false;
        } else {

          this.gettingCustomerGroup = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingCustomerGroup = false;
      }
    );
  }







  getAllProjects() {
    this.projectList = [];
    this.getProject = true;
    const data = {
      orgid: this.orgid,
      "domainid": this.allinoneService.getDomain().domainid,
      "customerid": this.customerid ? this.customerid : '',
      "customerview": this.customercare == true ? true : false
    };
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // if (this.allinoneService.crmOrgAdmin == 'false') {
          //   for (let i = 0; i < res.data.length; i++) {
          //     if (res.data[i]['memberstatus'] == '') {
          //       this.projectList.push(res.data[i]);
          //     }
          //   }
          //   this.getProject = false;
          // } else {
          this.getProject = false;
          this.projectList = res.data;
          // }
        } else {
          this.getProject = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getProject = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }


  getProjectsByCustomer() {
    this.projectList = [];
    this.getProject = true;
    const data = {
      orgid: this.orgid,
      "domainid": this.allinoneService.getDomain().domainid,
      "customerid": this.customerid ? this.customerid : '',
      "customerview": true,
    };
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getProject = false;
          this.projectList = res.data;
        } else {
          this.getProject = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getProject = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  onDismiss() {
    this.dialogRef.close()
  }
  getCustomerName() {
    console.log("ijefiji")
    console.log(this.customerVal)
    this.customerValList = this.customerVal
    if (Array.isArray(this.customerVal) || this.customerVal == null) {
      this.customerName = ""
      this.branchlist = []
      this.getAllProjects();
    } else {
      for (let i of this.customers) {
        if (i.customerid == this.customerVal.customerid) {
          this.customerName = i.customername
          this.branchlist = i.branchlist
          this.customerid = i.customerid;
        }
      }

      this.getProjectsByCustomer();
    }
  }

  getpriorityName() {
    for (let i of this.prioritys) {
      if (i.code == this.priorityVal) {
        this.priorityName = i.name
      }
    }
  }


  getProduct() {
    this.getproduct = true;
    this.products = []
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid": this.customerid ? this.customerid : '',
      "customerview": this.customercare == true ? true : false
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.products = res.list;
          // for (let i of res.list) {
          //   this.products.push({
          //     name: i.name,
          //     pic: i.piclist,
          //     id: i.productid,
          //     modulelist: i.modulelist
          //   }
          //   );
          // }
          console.log(this.products)
          console.log(">>>>")
          this.getproduct = false;
        } else {
          this.getproduct = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getproduct = false;
        // this.messageService.openSnackBar(err.message, 'warn');
      }
    );
  }


  getPIC() {
    this.getPic = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    }
    this.kunyekService.getCrmPIC(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.picdatalist = res.list;
          this.getPic = false;

        } else {
          this.getPic = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getPic = false;
        // this.messageService.openSnackBar(err.status, 'warn');
      }
    );

  }

  getPriorityList() {
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    };
    this.kunyekService.getPriority(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          this.prioritys = res.list;
          console.log(this.prioritys);

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );

  }


  getCustomers() {
    this.getCustomer = true;
    return new Promise((resolve: any) => {

      const data = {
        "domainid": this.allinoneService.getDomain().domainid,

        "orgid": this.orgid,
      };
      this.kunyekService.getCrmCustomers(data).subscribe(
        (res: any) => {
          console.log(res);
          this.getCustomer = false;

          if (res.returncode == '300') {
            this.orgcustomer = res.list;
            let cust = [];
            if (this.getData.customergroupidlist) {
              console.log("11111111111111111");
              if (this.getData.customergroupidlist['customerlist'].length > 0) {

                for (let i = 0; i < this.getData.customergroupidlist['customerlist'].length; i++) {
                  for (let j = 0; j < this.orgcustomer.length; j++) {
                    if (this.getData.customergroupidlist['customerlist'][i] == this.orgcustomer[j]['customerid']) {
                      cust.push(this.orgcustomer[j]);
                    }

                  }
                }
                this.customers = cust;
              }


            } else {
              console.log("22222222222222222222222222222");
              this.customers = res.list;

            }

            this.getCustomer = false;
          } else {
            this.getCustomer = false;
          }
        },
        (err) => {
          this.getCustomer = false;
          this.messageService.openSnackBar(err.status, 'warn');
        }
      );
    });
  }

  checkassigned(data: any) {
    console.log(data.target.checked);
    if (data.target.checked == true) {
      this.checkedBol = true;
      this.picVal = '';
      this.assignedtoname = '';
      this.picID = [];
    } else {
      this.checkedBol = false;
    }
    // this.checkedBol = data.target
  }

  checkRecurring(data: any) {
    if (data.target.checked == true) {
      this.recrring = true;
    } else {
      this.recrring = false;
    }

  }
  assignedtoname: any = '';
  picIDget(data: any) {
    console.log(data);
    this.picID = data;

    if (Array.isArray(this.picID) || this.picID == null) {
      this.assignedtoname = ''
    } else {
      this.assignedtList = data;
      for (let i = 0; i < this.picdatalist.length; i++) {
        if (this.picdatalist[i].picid == this.picID.picid) {
          console.log(this.picdatalist[i].picname)
          this.assignedtoname = this.picdatalist[i].picname;
        }
      }
    }
  }


  productChange(data: any) {
    this.productID = data
    if (Array.isArray(this.productID) || this.productID == null) {
      this.productVal = ''
      this.modulelist = [];
      this.modulename = '';
      this.moduleid = [];
    } else {
      this.productIDList = data;
      console.log(data);
      var type = this.products.map((x: any) => x.productid).indexOf(data.productid);
      console.log(type)
      console.log(this.products)
      if (type != -1) {
        this.productVal = this.products[type].name
        // this.products[type].module
        this.modulelist = this.products[type].modulelist;
        if (this.products[type].modulelist.length > 0) {
          this.checkmodule = true;
        } else {
          this.checkmodule = false;
          this.modulename = '';
          this.moduleid = [];
        }
      }
    }

  }
  moduleChange(data: any) {
    this.moduleid = data;
    console.log(this.modulelist)
    if (!Array.isArray(this.moduleid) && this.moduleid != null) {
      this.moduleIDList = data;
      var type = this.modulelist.map((x: any) => x.moduleid).indexOf(data.moduleid);
      // var type = this.modulelist.map((x: any) => x.moduleid).indexof(data.target.value);
      if (type > -1) {
        this.modulename = this.modulelist[type].description;
      }
    }


  }


  search() {

    var seage = ''
    console.log(this.checkage)
    console.log(this.startage)
    console.log(this.age)
    if (this.checkage) {

      console.log("1111111111111")
      if (this.ageid == 6) {
        console.log("22222222222222")
        console.log(this.age == '')
        // || this.age == '' || this.startage == ''
        // if (this.age == null || this.startage == null) {
        //   console.log(this.age)
        //   console.log(this.startage)
        //   console.log("3333333333333333333")
        //   this.messageService.openSnackBar('Please fill age.', 'warn')
        //   return;

        // } else {
        // if (this.age == null || this.startage == null) {
        if (this.startage > this.age) {
          this.messageService.openSnackBar('Invaild age.', 'warn')
          return;
        } else {
          console.log("okok>>>")
          console.log(this.startage)
          console.log(this.age)
          if (this.startage == '' && this.age == '') {
            seage = ''
            console.log("koko1111")
          } else {
            console.log("koko22222")
            if (this.startage == null && this.age == null) {
              seage = ''
            } else {
              seage = this.startage + '-' + this.age
            }

          }

          // }



        }
      }

    } else {

      if (this.startage == null) {
        seage = ''
      } else {
        if (this.startage == '') {


        } else {
          seage = this.startage

        }

      }

      console.log(this.startage)
      console.log(seage)
    }
    console.log(this.ageid)
    var showname = '';
    var operator = '';
    if (this.ageid == 1) {
      operator = '='
      showname = this.startage + ' ' + '=';
    }
    else if (this.ageid == 2) {
      operator = '>'
      showname = this.startage + ' ' + '>';
    }
    else if (this.ageid == 3) {
      operator = '<'
      showname = this.startage + ' ' + '<';
    }
    else if (this.ageid == 4) {
      operator = '>='
      showname = this.startage + ' ' + '>=';
    }
    else if (this.ageid == 5) {
      operator = '<='
      showname = this.startage + ' ' + '<=';
    }

    else if (this.ageid == 6) {
      operator = '>= <='
      showname = this.startage + ' ' + '>=' + ' ' + this.age + ' ' + '<=';
    }
    console.log(seage)
    console.log("oro" + this.productID)
    const data = {
      "ticketNo": this.ticketNo,
      "productName": this.productVal,
      "productid": this.productID != undefined ? this.productID ? this.productID.productid : '' : '',
      "picName": this.assignedtoname,
      "picid": this.picID != undefined ? this.picID ? this.picID.picid : '' : '',
      "customerName": this.customerName,
      "customerid": this.customerVal != undefined ? this.customerVal ? this.customerVal.customerid : '' : '',
      "priorityid": this.priorityVal != undefined ? this.priorityVal ? this.priorityVal : "" : '',
      "priorityName": this.priorityName ? this.priorityName : "",
      "projectName": this.projectname ? this.projectname : "",
      "projectID": this.projectid != undefined ? this.projectid ? this.projectid.projectid : "" : '',
      "assignedtome": this.checkedBol,
      "moduleid": this.moduleid != undefined ? this.moduleid ? this.moduleid.moduleid : '' : '',
      "modulename": this.modulename,
      "modulelist": this.modulelist,
      "reportedby": this.reportedby,
      "reportedbyid": this.reportedbyid,
      "refno": this.refNo,
      "age": seage.toString(),
      "showname": showname,
      "operator": seage ? operator : '',
      "description": this.description,
      "detail": this.detail,
      "recurring": this.recrring,
      "customerValList": this.customerValList,
      "productIDList": this.productIDList != undefined ? this.productIDList : '',
      "projectIDList": this.projectIDList != undefined ? this.projectIDList : '',
      "moduleIDList": this.moduleIDList != undefined ? this.moduleIDList : '',
      "assignedtList": this.assignedtList,
      "cusgroupid": this.searchgroupid,
      "groupname": this.groupname,
      "branchid": this.searchbranchid,
      "branchname": this.branchname,




    }
    console.log(">>>.")
    console.log(typeof (data));
    console.log(this.rangestartdate);
    console.log(this.enddate);

    if (this.checkdate) {
      if (this.rangestartdate > this.enddate) {
        this.messageService.openSnackBar('Please select a valid Date.', 'warn')
        return;
      }
    }



    if (this.searchbranchid) {
      if (!this.customerVal) {
        this.messageService.openSnackBar('Please select a customer.', 'warn')
        return;
      }
    }

    // console.log(data);
    var defaultenddate = formatDate(
      new Date(this.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    var defalutstartdate = formatDate(
      new Date(this.rangestartdate),
      this.formatToShow,
      'en-US'
    ).toString();
    var defaultenddateshow = formatDate(
      new Date(this.enddate),
      this.formatToShowCRM,
      'en-US'
    ).toString();
    var defalutstartdateshow = formatDate(
      new Date(this.rangestartdate),
      this.formatToShowCRM,
      'en-US'
    ).toString();

    console.log(defaultenddate)
    console.log(data)
    this.dialogRef.close({
      data: data,
      enddate: defaultenddate,
      rangestartdate: defalutstartdate,
      enddateshow: defaultenddateshow,
      startdateshow: defalutstartdateshow,
      checkdate: this.checkdate

    });

  }
  checkChange2(event: any) {
    if (event.target.checked == true) {
      this.checkdate = true;

    }
  }

  checkChange1(event: any) {
    if (event.target.checked == true) {
      this.checkdate = false;
    }
  }
  checkage: boolean = false;
  toolname: any = "Equals"
  betweenvalue: any = "";
  ageChange(data: any) {
    if (data.target.value == '6') {
      console.log(data.target.value)
      this.checkage = true;
      console.log("1");

    } else {
      this.age = '';
      this.checkage = false;
    }
    if (data.target.value == '1') {
      this.toolname = "Equals"
    } else if (data.target.value == '2') {
      this.toolname = "Greater than"
    }
    else if (data.target.value == '3') {
      this.toolname = "Less than"

    }
    else if (data.target.value == '4') {
      this.toolname = "Greater than or Equal"

    }
    else if (data.target.value == '5') {
      this.toolname = "Less than or Equal"

    }
    else if (data.target.value == '6') {
      this.toolname = "Between"

    }

    console.log("2");


  }

  members: any = [];
  searchNonea: any = '';
  assignedto: any = '';
  getMembers() {
    this.members = [];
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getMembersCRM(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {


          // this.members = res.datalist;
          this.members = [];
          for (let i of res.datalist) {
            this.members.push({
              username: i.username + ' ( ' + i.userid + ' ) ',
              userid: i.userid
            }
            );
          }

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );

  }
  selectMember(data: any) {
    console.log(data);

    if (data != undefined) {
      this.reportedby = data.username;
      this.reportedbyid = data.userid;
    } else {
      this.reportedby = "";
      this.reportedbyid = "";
    }
    //
  }


  checkInternal(data: any) {
    if (data.target.checked == true) {
      this.checkinternal = true;

    } else {
      this.checkinternal = false;
    }

  }
}
