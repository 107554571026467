import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Quill from 'quill';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-chat-edit',
  templateUrl: './chat-edit.component.html',
  styleUrls: ['./chat-edit.component.scss'],
})
export class ChatEditComponent implements OnInit, AfterViewInit {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  mentionableList: any[] = [];
  finLst: any[] = [];
  selectedImageList: any = [];
  removedImageList: any = [];

  editLoading: boolean = false;

  msg: any;
  quill: any;

  editText: string = '';

  subscriptions = new SubSink();

  formats = ['mention'];

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<ChatEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    (this.mentionableList = data.mentionableList),
      (this.msg = JSON.parse(JSON.stringify(data.msg)));
  }
  ngAfterViewInit(): void {
    this.openEditMessage();
  }

  ngOnInit(): void {
    this.msg.imagelist.map((img: any) => {
      var temp = {
        file: '',
        previewurl: img.imageurl,
        filename: img.imagename,
      };
      this.selectedImageList.push(temp);
    });
  }

  async openEditMessage() {
    this.editText = this.msg.message;
    this.finLst = this.getEditFormat(this.msg.message);

    this.quill = new Quill('#editor-container', {
      modules: {
        mention: {
          defaultMenuOrientation: 'top',
          spaceAfterInsert: false,
          renderItem: (item: any) => {
            const img = item.img
              ? item.img
              : '../../../assets/images/profile.png';
            const check = item.username == '';
            if (check) {
              var rf =
                `
                <div class = "m-row">
                  <div>
                      <img class="m-profile-image" 
                          src="` +
                img +
                `">
                  </div>
                  <div class="m-rightside">
                     ` +
                item.userid +
                `
                  </div>
                </div>
              `;
            } else {
              var rf =
                `
                      <div class = "m-row">
                      <div>
                          <img class="m-profile-image" 
                              src="` +
                img +
                `">
                      </div>
                      <div class="m-rightside">
                          <div class="m-name">` +
                item.username +
                `</div>
                          <div class="m-id">` +
                item.userid +
                `</div>
                      </div>
                      </div>
                    `;
            }
            return rf;
          },
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          onSelect: (item: any, insertItem: any) => {
            insertItem(item);
            // necessary because quill-mention triggers changes as 'api' instead of 'user'
            this.quill.insertText(this.quill.getLength() - 1, '', 'user');
            console.log(item);
          },
          source: (searchTerm: any, renderList: any) => {
            const values = this.mentionableList;
            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches: any[] = [];
              values.forEach((entry) => {
                if (
                  entry.username
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase()) !== -1 ||
                  entry.userid
                    .toLowerCase()
                    .indexOf(searchTerm.toLowerCase()) !== -1
                ) {
                  matches.push(entry);
                }
              });
              renderList(matches, searchTerm);
            }
          },
        },
        toolbar: [],
      },
      formats: this.formats,
    });
    const mentionModule = this.quill.getModule('mention');
    console.log(this.finLst);
    this.finLst.map((item: any) => {
      if (item.userid.toString().trim() == '') {
        this.quill.insertText(this.quill.getLength() - 1, item.text);
      } else {
        var temp = {
          denotationChar: '@',
          index: item.index,
          id: item.userid,
          value: item.text,
        };
        mentionModule.cursorPos = this.quill.getLength() - 1;
        mentionModule.insertItem(temp, true);
      }
    });
  }

  getEditFormat(text: string) {
    var splitarr = text.split('@[');
    var final_arr = [];
    for (var i = 0; i < splitarr.length; i++) {
      var res = splitarr[i].match(/(\__(.*?)__\]\(__(.*?)__\))/gi);
      if (res) {
        var tem = splitarr[i].split(res[0]);
        let mention = res[0].split('__');
        var temObj = {
          userid: mention[1],
          text: mention[3],
        };
        final_arr.push(temObj);
        const tempObj2 = {
          userid: '',
          text: tem[1],
        };
        final_arr.push(tempObj2);
      } else {
        const tempObj2 = {
          userid: '',
          text: splitarr[i],
        };
        final_arr.push(tempObj2);
      }
    }
    return final_arr;
  }

  closeImage(index: any) {
    this.removedImageList.push(this.selectedImageList[index]);
    this.selectedImageList.splice(index, 1);
  }

  onMultipleFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.getFileName(file),
            };
            this.selectedImageList.push(temp);
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }

  getFileName(file: any) {
    console.log(file);
    var name = file.name.trim();
    var splitName = name.split('.');
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  submitEditMessage() {
    const tempText = this.quill.getContents().ops;
    var finalText = '';
    var finalNotiMessage = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        var temp2 = item.insert.mention.value
          ? item.insert.mention.value
          : item.insert.mention.id;

        temp += '(__' + temp2.toString() + '__)';
        finalText += temp;
        finalNotiMessage += temp2;
      } else {
        finalNotiMessage += item.insert.toString();
        finalText += item.insert.toString();
      }
    });
    this.editText = finalText.substring(0, finalText.length - 1);
    finalNotiMessage = finalNotiMessage.substring(
      0,
      finalNotiMessage.length - 1
    );
    if (this.editText.endsWith('\n')) {
      this.editText = this.editText.substring(0, this.editText.length - 1);
    }
    if (finalNotiMessage.endsWith('\n')) {
      finalNotiMessage = finalNotiMessage.substring(
        0,
        finalNotiMessage.length - 1
      );
    }

    if (this.editText.trim() == '' && this.selectedImageList.length == 0) {
      this.messageService.openSnackBar('Message cannot be blank!', 'warn');
    } else {
      this.saveEditMessage(finalNotiMessage);
    }
  }

  async saveEditMessage(notimessage: string) {
    this.editLoading = true;
    this.dialogRef.disableClose = true;
    var deleteimagelist: string[] = [];
    var finalSelectedImageList: any[] = [];
    var imgList: string[] = [];
    this.selectedImageList.map((img: any) => {
      if (img.file == '') {
        deleteimagelist.push(img.filename);
      } else {
        imgList.push(img.filename);
        finalSelectedImageList.push(img);
      }
    });
    if (finalSelectedImageList && finalSelectedImageList.length > 0) {
      for (let i = 0; i < finalSelectedImageList.length; i++) {
        if (finalSelectedImageList[i].file.size >= 307200) {
          finalSelectedImageList[i].file =
            await this.allinoneService.compressImage(
              finalSelectedImageList[i].file
            );
        }
      }
      const result = await this.allinoneService.multipleUploadToAWS(
        finalSelectedImageList,
        this.msg.channelid
      );
    }
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      syskey: this.msg.syskey,
      message: this.editText,
      notimessage: notimessage,
      domainid: this.allinoneService.getDomain().domainid,
      mention: [],
      imagelist: imgList,
      deleteimagelist: deleteimagelist,
    };
    //this.changeDetectorRef.detectChanges();
    this.subscriptions.sink = this.kunyekService.editMessage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.editLoading = false;
          var temp = {
            message: this.editText,
            notimessage: notimessage,
            imagelist: res.imagelist || [],
          };
          this.dialogRef.close(temp);
        } else {
          this.editLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.dialogRef.disableClose = false;
        this.editLoading = false;
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }

  async onPaste(e: any) {
    const items = (e.clipboardData || e.originalEvent.clipboardData).files;
    console.log(items);
    let blob = null;
    for (const item of items) {
      if (
        item.type == 'image/jpeg' ||
        item.type == 'image/jpg' ||
        item.type == 'image/png'
      ) {
        // blob = item.getAsFile();
        blob = item;

        var temp = {
          file: blob,
          previewurl: '',
          filename: this.getFileName(blob),
        };

        await this.getBase64(blob).then((data: any) => {
          temp.previewurl = data;
          this.selectedImageList.push(temp);
          //this.changeDetectorRef.detectChanges()
        });
      }
    }
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
