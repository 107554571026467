import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-crm-customer-advansearch',
  templateUrl: './crm-customer-advansearch.component.html',
  styleUrls: ['./crm-customer-advansearch.component.scss']
})
export class CrmCustomerAdvansearchComponent implements OnInit {

  phone:any = '';
  name:any = '';
  product:any = '';
  getData:any =[];
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CrmCustomerAdvansearchComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 

    this.getData = data.data;
    this.phone = this.getData.phone;
    this.name = this.getData.name;
    this.product = this.getData.product;

  }

  orgid:any = '';
  organizations:any = [];

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM

        ? this.allinoneService.selectedOrgCRM

        : this.organizations[0].orgid;
        this.getProduct()
  }

  onDismiss() {
    this.dialogRef.close()
  }

  search() {
    var data = {
      'phone': this.phone,
      'name': this.name,
      'productid': this.product ? this.product.productid : '',
      'productname':this.product ? this.productname : '',
      
    }
    console.log(data);
    this.dialogRef.close({
      data: data,

      // checkdate: this.checkdate

    });
  }
  getProduct() {
    this.getproduct = true;
    this.products = []
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,

    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.products = res.list;
          this.getproduct = false;
        } else {
          this.getproduct = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getproduct = false;
        // this.messageService.openSnackBar(err.message, 'warn');
      }
    );
  }
  productAList:any = [];
  productname:any = '';
  products:any = [];
  getproduct:boolean = false;
  productChange(data:any){
    this.product = data
    console.log(data)
    if (Array.isArray(this.product) || this.product == null) {
      this.productname = ''
    } else {
      this.productAList = data;
      console.log(data);
      var type = this.products.map((x: any) => x.productid).indexOf(data.productid);
      console.log(type)
      console.log(this.products)
      if (type != -1) {
        this.productname = this.products[type].name
        // this.products[type].module
       
      }
    }


  }
}
