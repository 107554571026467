import { MessageService } from './../../shared/services/message.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HierarchyAddComponent } from 'src/app/admin/hierarchy-add/hierarchy-add.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
// import { HierarchyAddComponent } from '../hierarchy-add/hierarchy-add.component';

@Component({
  selector: 'app-customer-contacts',
  templateUrl: './customer-contacts.component.html',
  styleUrls: ['./customer-contacts.component.css']
})
export class CustomerContactsComponent implements OnInit {
  contactAddOrNot: boolean = false;
  saveLoading: boolean = false;
  isEdit: boolean = false;
  cFormSubmitted: boolean = false;
  savedContact: any = []
  deleteContact: any = []
  isAdmin: boolean = false;
  selfservices: boolean = true;
  emailaccess: boolean = true;
  branchid: any = "";
  ngbranchlist: any = [];
  orgid: any = "";

  branchlist: any = [];

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  cForm = {
    contactid: "",
    contactname: "",
    contactmobile: "",
    contactemail: "",
    contactpost: "",
    contactaddress1: "",
    contactaddress2: "",
    type: "",
    branchid: "",
    cindex: "",
    productlist: [],
  }
  index: any = '';
  customerid: any = '';
  constructor(private dialogRef: MatDialogRef<CustomerContactsComponent>,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private kunyekService: KunyekService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.savedContact = data.contactlist;
    this.orgid = data.orgid;
    this.customerid = data.customerid;
    this.branchlist = [];
    this.branchlist.push({
      "branchaddress1": "",
      "branchaddress2": "",
      "branchemail": "",
      "branchid": "001",
      "branchmobile": "",
      "branchname": "HO",
      "branchindustrytype": "",
    })
    console.log(data.branchlist)
    if (data.branchlist.length > 0) {
      for (let i = 0; i < data.branchlist.length; i++) {
        console.log(data.branchlist)
        this.branchlist.push({
          "branchaddress1": data.branchlist[i]['branchaddress1'],
          "branchaddress2": data.branchlist[i]['branchaddress2'],
          "branchemail": data.branchlist[i]['branchemail'],
          "branchid": data.branchlist[i]['branchid'] == '' ? data.branchlist[i]['branchname'] : data.branchlist[i]['branchid'],
          "branchmobile": data.branchlist[i]['branchmobile'],
          "branchname": data.branchlist[i]['branchname'],
          "branchindustrytype": data.branchlist[i]['branchindustrytype']
        })
      }
    }
    if (this.branchlist.length > 0) {
      this.ngbranchlist = this.branchlist[0];
    }

    console.log(this.savedContact);
    console.log(this.ngproductlist);
    console.log("....")
    this.deleteContact = data.deletelist;
  }

  ngOnInit(): void {
    if (this.savedContact.length == 0) {
      this.contactAddOrNot = true;
    }
    this.getProduct();
  }


  cancel() {
    this.contactAddOrNot = false;
    this.clean();
  }
  skucodeChange() {
    this.cForm = {
      contactid: "",
      contactname: "",
      contactmobile: "",
      contactemail: "",
      contactpost: "",
      contactaddress1: "",
      contactaddress2: "",
      type: "",
      branchid: "",
      cindex: "",
      productlist: [],
    };
  }
  clean() {
    this.contactAddOrNot = false;
    this.isEdit = false;
    this.cForm = {
      contactid: "",
      contactname: "",
      contactmobile: "",
      contactemail: "",
      contactpost: "",
      contactaddress1: "",
      contactaddress2: "",
      type: "",
      branchid: "",
      cindex: "",
      productlist: [],
    }
    this.ngbranchlist = [];
    this.ngproductlist = [];
    this.cFormSubmitted = false;
  }
  editContact(contact: any, index: any) {
    console.log(contact);
    this.contactAddOrNot = true;
    this.cForm.contactid = contact.contactid,
      this.cForm.contactname = contact.contactname,
      this.cForm.contactmobile = contact.contactmobile,
      this.cForm.contactemail = contact.contactemail,
      this.cForm.contactpost = contact.contactpost,
      this.cForm.contactaddress1 = contact.contactaddress1,
      this.cForm.contactaddress2 = contact.contactaddress2,
      this.selfservices = contact.selfservice == null || contact.selfservice == '' || contact.selfservice == 'true' ? true : false
    this.emailaccess = contact.emailaccess == null || contact.emailaccess == '' || contact.emailaccess == 'true' ? true : false;
    this.cForm.branchid = contact.branchid;
    this.cForm.productlist = contact.productlist;
    this.cForm.cindex = index;

    var index = this.branchlist.map((x: any) => x.branchid).indexOf(contact.branchid);
    console.log(this.ngproductlist);

    console.log(this.branchlist[index]);
    if (index > -1) {
      this.ngbranchlist = this.branchlist[index];
    }

    this.index = index
    if (contact.role == "300") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }


    this.isEdit = true;
    var productlist: any = this.cForm.productlist;
    this.ngproductlist = [];
    for (let i = 0; i < productlist.length; i++) {
      for (let j = 0; j < this.productList.length; j++) {

        if (productlist[i] == this.productList[j]['productid']) {
          this.ngproductlist.push(this.productList[j]);
        }
      }

    }
  }
  submitContact() {
    this.cFormSubmitted = true;
    // if (this.cForm.contactmobile != '') {
    //   if ((/([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g).test(this.allinoneService.checkUserId(this.cForm.contactmobile.toString().trim())) == false) {
    //     //  if(this.leadForm.phone == '' || this.leadForm.phone.substring(0, 2) != '09' || this.leadForm.phone.substring(0, 4) != '+959' || this.leadForm.phone.toString().length > 12){
    //     this.messageService.openSnackBar("Please enter valid phone number.", 'warn');
    //     return;
    //   }
    // }

    if (this.cForm.contactname == '') {
      console.log(this.cForm);
      return;
    }



    var index = -1;
    console.log(this.cForm.contactid);
    if (this.savedContact.length != 0) {
      if (this.cForm.contactid != "") {
        index = this.savedContact.map((x: any) => x.contactid).indexOf(this.cForm.contactid);
        console.log("1111111")
      } else {
        index = parseInt(this.cForm.cindex);
        console.log("222222")
      }

      console.log(index);
      console.log("Jaejae");
    }
    console.log(index + "Index>>>>>");
    console.log(this.cForm.contactemail);
    console.log(this.savedContact);
    if (this.isEdit) {

      for (let i = 0; i < this.savedContact.length; i++) {
        console.log(i);
        console.log(index)
        if (this.cForm.contactemail != "") {
          if (this.savedContact[i].contactemail == this.cForm.contactemail && i != index) {
            this.messageService.openSnackBar("Contact already exists.", 'warn');
            return;
          }
        }

      }
      console.log("1>>>")
      this.savedContact[index].contactname = this.cForm.contactname.toString();
      this.savedContact[index].contactmobile = this.cForm.contactmobile == '' ? '' : this.allinoneService.checkUserId(this.cForm.contactmobile.toString().trim());
      this.savedContact[index].contactemail = this.cForm.contactemail.toString();
      this.savedContact[index].contactpost = this.cForm.contactpost.toString();
      this.savedContact[index].contactaddress1 = this.cForm.contactaddress1.toString();
      this.savedContact[index].contactaddress2 = this.cForm.contactaddress2.toString();
      this.savedContact[index].contactaddress2 = this.cForm.contactaddress2.toString();
      this.savedContact[index].role = this.isAdmin == true ? "300" : ""
      this.savedContact[index].selfservice = this.selfservices == true ? 'true' : 'false',
        this.savedContact[index].emailaccess = this.emailaccess == true ? 'true' : 'false',
        this.savedContact[index].branchid = this.cForm.branchid,
        this.savedContact[index].productlist = this.cForm.productlist

    } else {
      if (this.cForm.contactemail != '') {

        var type = this.savedContact.map((x: any) => x.contactemail).indexOf(this.cForm.contactemail);
        if (type > -1) {
          this.messageService.openSnackBar("Contact already exists.", 'warn');
          return;
        }
      }

      // if (index == -1) {
      console.log("2>>>")
      this.savedContact.push({
        contactid: this.cForm.contactid,
        contactname: this.cForm.contactname,
        contactmobile: this.cForm.contactmobile == '' ? '' : this.allinoneService.checkUserId(this.cForm.contactmobile.toString().trim()),
        contactemail: this.cForm.contactemail,
        contactpost: this.cForm.contactpost,
        contactaddress1: this.cForm.contactaddress1,
        contactaddress2: this.cForm.contactaddress2,
        selfservice: this.selfservices == true ? 'true' : 'false',
        role: this.isAdmin == true ? "300" : "",
        emailaccess: this.emailaccess == true ? 'true' : 'false',
        branchid: this.cForm.branchid,
        productlist: this.cForm.productlist,
      });
    }
    console.log(this.savedContact)

    this.clean();
  }

  delete(index: any) {
    if (this.savedContact[index].contactid) {
      this.deleteContact.push(this.savedContact[index])
    }
    this.savedContact.splice(index, 1)
  }
  Close() {
    this.dialogRef.close({ datalist: this.savedContact, deletelist: this.deleteContact });
  }

  changeAdmin(event: any) {

    if (event.target.checked == true) {
      this.isAdmin = true;

    } else {
      this.isAdmin = false;
    }

  }
  selfservice(event: any) {
    if (event.target.checked == true) {
      this.selfservices = true;
    } else {
      this.selfservices = false;
    }

  }
  emailAccess(event: any) {
    if (event.target.checked == true) {
      this.emailaccess = true;
    } else {
      this.emailaccess = false;
    }

  }

  newcontact() {
    console.log("ksjd;flkj;")
    this.contactAddOrNot = true
    this.isAdmin = false;
    this.selfservices = true;
    this.cForm.branchid = "001";
    var index = this.branchlist.map((x: any) => x.branchid).indexOf("001");
    if (index > -1) {
      this.ngbranchlist = this.branchlist[index];
    }

  }


  customerbranchSelect(data: any) {
    console.log(data);
    console.log(this.ngbranchlist);
    if (data != undefined) {

      this.cForm.branchid = data.branchid == "" ? data.branchname : data.branchid
    }


  }

  ngproductlist: any = [];
  assignedtolist: any = [];
  customerproductselect(data: any) {
    this.assignedtolist = [];
    console.log(this.ngproductlist);
    for (let i = 0; i < this.ngproductlist.length; i++) {
      this.assignedtolist.push(this.ngproductlist[i]['productid']);
    }
    this.cForm.productlist = this.assignedtolist;



  }

  productList: any = [];
  getProduct() {
    this.productList = [];


    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid": this.customerid,
      "customerview": true,
      "showallproduct": false,
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          res.list.map((product: any) => {
            var picname = "";
            var picid = "";
            var picuserid = ""
            if (product.piclist.length > 0) {
              if (product.piclist[0].picuser != null) {
                picname = product.piclist[0].picuser.picname
                picid = product.piclist[0].picuser.picid
                picuserid = product.piclist[0].picuser.userid
              }
            }

            console.log(picname)

            this.productList.push({
              "autoid": product.autoid,
              "skucode": product.skucode,
              "name": product.name,
              "picname": picname,
              "picuserid": picuserid,
              "picid": picid,
              "productid": product.productid,
              "sortby": product.sortby,
              "modulelist": product.modulelist,
              "contactperson": product.contactperson,
              "check": false,
              "contactid": ''
            });



          });





        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
      }
    );

  }
}
