<div *ngIf="!areMembersChosen">
  <div class="form-group m-2" *ngIf="!isAddMember && !isGroupChatCreate">
    <select class="form-select" [(ngModel)]="selectedOrgid" (ngModelChange)="orgChanged()">
      <option value="">-</option>
      <option *ngFor="let org of orgs" [value]="org.orgid"> {{org.name}} </option>
    </select>
  </div>
  <ng-container *ngIf="isGroupChatCreate">
    <div class="form-group m-2">
      <select class="form-select" [(ngModel)]="selectedOrgid" (ngModelChange)="orgChanged()">
        <option value="">-</option>
        <option *ngFor="let org of orgs" [value]="org.orgid"> {{org.name}} </option>
      </select>
    </div>
    <div class="form-group m-2" *ngIf="selectedOrgid">
      <select class="form-select" [(ngModel)]="selectedSettings" (ngModelChange)="settingChanged()">
        <option *ngFor="let setting of settings" [value]="setting.id"> {{setting.name}} </option>
      </select>
    </div>
  </ng-container>
  <ng-container *ngIf="((isGroupChatCreate && selectedSettings == '003') || !isGroupChatCreate)">
    <ul class="list-group">
      <perfect-scrollbar *ngIf="chooseMembers.length > 0" style="position: relative;" fxFlex="auto" [config]="config"
        [scrollIndicators]="true">

        <div class="list-group-item member-setup">
          <div class="member-wrapper" *ngFor="let c of chooseMembers;let i = index;" [ngClass]="'member-wrapper-' + i">
            <div class="image-container">
              <img class="chat-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="c.imagename ? c.imagename : '../../../assets/images/profile.png' ">
              <span class="member-close" (click)="removeMember(c)"
                [ngClass]="{ 'hide' : isAddMember && this.membersAlreadyInGroup.indexOf(c.userid) > -1}">
                <mat-icon>close</mat-icon>
              </span>
            </div>
            <div class="info-container">
              <span *ngIf="c.username"> {{splitUserName(c.username)}}</span>
              <span *ngIf="c.username == ''">{{c.userid}}</span>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </ul>
    <ul class="list-group" *ngIf="!gettingContactList">

      <!-- <perfect-scrollbar style="position: relative;" fxFlex="auto" [config]="config" [scrollIndicators]="true"> -->

      <div style="position: relative;" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScrollDown()" [scrollWindow]="false" [perfectScrollbar]="config">

        <div [ngClass]="{'contacts-wrapper':  chooseMembers.length > 0}"
          [ngClass]="{
            'setting-container' : selectedOrgid && (isGroupChatCreate || isAddMember), 
            'no-org-member-selected-container' : chooseMembers.length> 0 && !selectedOrgid,
            'org-container' : (!selectedOrgid && (isGroupChatCreate || isAddMember)) || (!isAddMember && !isGroupChatCreate),
            'member-selected-container' : chooseMembers.length > 0 && selectedOrgid
          }"
            >

          <div *ngIf="contactsListToShow.length == 0" class="nodata d-flex align-items-center justify-content-center"
            style="font-size: 1rem;">
            No result found.
          </div>
          <div
            *ngFor="let item of contactsListToShow | filter:searchData:type; let index = index; trackBy: trackByUserId"
            [ngClass]="{'noborder': index == 0}">

            <div class="list-group-header">
              {{item.key}}
            </div>


            <li class="list-group-item" *ngFor="let c of item.contacts; let index = index">
              <div class="profile-item" (click)="clickChat(c)">
                <img class="chat-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                  [lazyLoad]="c.imagename ? c.imagename : '../../../assets/images/profile.png' ">

                <div class="profile-container">
                  {{c.username}}
                  <span>{{c.userid}}</span>
                  <span *ngIf="c.newuserid">{{c.newuserid}}</span>
                </div>

                <!-- <mat-checkbox color="primary" [(ngModel)]="c.checked"></mat-checkbox> -->
                <div class="checkbox-container" *ngIf="isGroupChatCreate">
                  <input class="form-check-input" type="checkbox" [(ngModel)]="c.checked"
                    [disabled]="isAddMember && this.membersAlreadyInGroup.indexOf(c.userid) > -1" id="flexCheckDefault">
                </div>
              </div>
              <div class="unblock" *ngIf="c.block == 'enable' && !isGroupChatCreate">
                <button mat-icon-button title="Unblock" (click)="unblockUser(c)">
                  <mat-icon class="block">block</mat-icon>
                </button>
              </div>
            </li>
          </div>
          <ul class="list-group" *ngIf="gettingMoreContact">
            <li class="list-group-item" *ngFor="let item of [1,2]">
              <div class="profile-item">
                <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                                  'height.px': 40}"></ngx-skeleton-loader>
                <div class="profile-container" style="margin-top: auto; width: 100%;">
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- </perfect-scrollbar> -->
      </div>
    </ul>
    <ul class="list-group" *ngIf="gettingContactList">
      <li class="list-group-item" *ngFor="let item of chatLoaderArray">
        <div class="profile-item">
          <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                    'height.px': 40}"></ngx-skeleton-loader>
          <div class="profile-container" style="margin-top: auto; width: 100%;">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="selectedSettings == '002'">
    <div class="m-2" *ngIf="!gettingClassCode">
      <perfect-scrollbar *ngIf="classCodeList.length > 0" class="classcodelist setting-container" fxFlex="auto" [config]="config"
        [scrollIndicators]="true">
        <div *ngIf="!gettingClassCode">
          <div class="class-code-container" (click)="checkAll()">
            <div>
              <b>{{isCheckAll ? 'Unselect All' : 'Select All'}}</b>
            </div>
            <div>
              <input class="form-check-input" type="checkbox" id="checkbox" [(ngModel)]="isCheckAll">
            </div>
          </div>
          <mat-divider></mat-divider>
          <div *ngFor="let classcode of classCodeList">
            <div class="class-code-container" (click)="addToClassCode(classcode)">
              <div>
                {{classcode.name}}
              </div>
              <div>
                <input class="form-check-input" type="checkbox" id="checkbox" [(ngModel)]="classcode.checked">
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div *ngIf="classCodeList.length == 0 && !gettingClassCode"
      class="nodata d-flex align-items-center justify-content-center" style="font-size: 1rem;">
      Empty.
    </div>
    <ul class="list-group" *ngIf="gettingClassCode">
      <li class="list-group-item" *ngFor="let item of chatLoaderArray">
        <div class="profile-item">
          <div class="profile-container" style="margin-top: auto; width: 100%;">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="selectedSettings == '001'">
    <span class="m-2">{{classCodeList.length}}&nbsp;Member Types</span>
  </ng-container>
</div>
<div *ngIf="areMembersChosen">
  <perfect-scrollbar style="position: relative;" fxFlex="auto" [config]="config" [scrollIndicators]="true">
    <app-create-group [membersList]="chooseMembers" [setting]="selectedSettings" [memberType]="selectedSettings == '001' ? classCodeList: chooseClassCode " [orgid]="selectedOrgid" (createGroup)="createGroup2($event)">
    </app-create-group>
  </perfect-scrollbar>
</div>



<button mat-mini-fab class="fab-btn"
  *ngIf="isGroupChatCreate && !gettingContactList && !areMembersChosen && !isAddMember" (click)="next()">
  <mat-icon>arrow_forward_ios</mat-icon>
</button>
<button mat-mini-fab class="fab-btn" *ngIf="isGroupChatCreate && !gettingContactList && isAddMember"
  (click)="addMemberDone()">
  <mat-icon>check</mat-icon>
</button>
<button mat-mini-fab *ngIf="!isGroupChatCreate" color="primary" class="fab-btn" (click)="addContact()">
  <mat-icon>person_add</mat-icon>
</button>