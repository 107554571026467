<section>
    <ng-container *ngIf="!isError;else errorPage">
        <div class="container-fluid">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="gettingSummary">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="content" *ngIf="!gettingSummary">
                <div class="voting-container">
                    <div class="fix-header">
                        <button class="back" mat-icon-button (click)="back()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <div class="campaign-name mb-3">{{summary.camdescription}}</div>
                        <div class="campaign-d mb-3" *ngIf="summary.rounddescription">{{summary.rounddescription}}</div>
                        <div class="campaign-d mb-3" *ngIf="summary.roundremark">{{summary.roundremark}}</div>
                        <div class="w-100 d-flex justify-content-between align-items-center flex-wrap">
                            <div class="mb-3">
                                <span class="title">Voters</span> (<span class="vote-limit">
                                    Total - {{summary.datalist.length}} | <span
                                        class="{{summary.roundstatus == '001' ? 'green' : 'red'}}">{{summary.roundstatus
                                        == '001' ? 'Open' : 'Closed'}}</span>
                                </span>)
                            </div>
                            <div class="mb-3 d-flex">
                                <select class="form-select" [(ngModel)]="filterType" (change)="filterTypeChange($event)"
                                    style="width: 280px;">
                                    <option value="" [selected]="filterType == ''">All
                                    </option>
                                    <option value="1" [selected]="filterType == '1'">Voted at least 1
                                    </option>
                                    <option value="2" [selected]="filterType == '2'">Not voted yet
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="userid">User ID</td>
                                    <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="username">Name</td>
                                    <td scope="col" class="text-center" mat-sort-header="votecount"
                                        style="width: 100px;">Vote Count</td>
                                    <td scope="col" class="text-center" mat-sort-header="voted" style="width: 100px;">
                                        Voted</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let voter of list | filter:filterType:23; let index = index">
                                    <td>{{voter.userid}}</td>
                                    <td>{{voter.name}}</td>
                                    <td class="text-center">{{voter.votecount}}</td>
                                    <td class="text-center">{{voter.voted}}</td>
                                </tr>
                                <tr *ngIf="list.length == 0">
                                    <td colspan="4" class="nodata">Empty</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #errorPage>
        <div class="c-container m-5 error" *ngIf="!noRoundError">
            <span class="mb-3">Something went wrong!</span>
            <button class="btn btn-secondary" (click)="reload()">Reload</button>
        </div>
        <div class="c-container m-5 error" *ngIf="noRoundError">
            <span class="noround">This round of voting does not exist.</span>
        </div>
    </ng-template>

</section>