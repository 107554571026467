<div class="container-fluid">
  <div class="content">
    <div class="m-4">
      <div class="me-auto">
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      </div>
      <div class="d-flex gap-2 mb-2">
        <button type="button" (click)="closeForm()" class="btn btn-custom">Cancel</button>
        <app-button-loader (click)="approve()" [button_text]="leaveIsApproved ? 'Approved':'Approve'"
          [disabled]="leaveIsApproved" [load_button_text]="'Approving'" [isLoading]="isApproving">
        </app-button-loader>
        <app-button-loader (click)="reject()" [button_text]="leaveIsRejected ? 'Rejected':'Reject'"
          [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true">
        </app-button-loader>
      </div>
      <div class="card">
        <div class="card-body m-2">
          <form [formGroup]="leaveForm">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="name" class="form-label">Name</label>
                    <input type="text" id="name" class="form-control" formControlName="name" readonly>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="type" class="form-label">Type</label>
                    <input type="text" id="type" class="form-control" formControlName="type" readonly>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="from_date" class="form-label">From Date</label>
                    <my-date-picker [options]="datePickerOptions" class="datepicker" formControlName="from_date"
                      placeholder="DD/MM/YYYY" readonly>
                    </my-date-picker>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="to_date" class="form-label">To Date</label>
                    <my-date-picker [options]="datePickerOptions" class="datepicker" formControlName="to_date"
                      placeholder="DD/MM/YYYY" readonly>
                    </my-date-picker>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="duration" class="form-label">Duration</label>
                    <input type="text" formControlName="duration" id="duration" class="form-control" readonly>
                  </div>
                  
                  <div class="col-md-12 mb-3" *ngIf="attachment">
                    <div class="d-flex flex-column">
                      <label class="form-label">Leave Attachment</label>
                    <img [src]="attachment.fileurl" class="gap-2 mb-2" (click)="viewAttachment()" alt="leave attachment" width="150px" height="150px">
                   
                    </div>
                     <div class="d-flex gap-2">
                      <button type="button" class="btn btn-custom" (click)="viewAttachment()">View</button>
                      <button type="button" class="btn btn-custom" (click)="downloadAttachment()">Download</button>
                    </div>
                  </div>
                  
                  <div class="col-md-12 mb-3">
                    <label for="reason" class="form-label">Reason</label>
                    <textarea type="text" id="reason" class="form-control" formControlName="reason" readonly></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
