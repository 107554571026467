import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-device-id',
  templateUrl: './device-id.component.html',
  styleUrls: ['./device-id.component.scss'],
})
export class DeviceIdComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isMobileView: boolean = false;
  mobileViewWidth: number = 426;
  listLoading: boolean = false;
  itemPerPage: number = 100;
  currentPage: number = 1;
  isFocus: boolean = false;
  openfilter: boolean = false;
  searchText: string = '';

  orgid: string = '';

  getDeviceInfoSubscription!: Subscription;
  getMemberSub!: Subscription;
  deviceInfoList: any = [];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];
  gettingMemberPosition: boolean = false;
  subscriptions = new SubSink();

  searchForm = {
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private datePipe: DatePipe
  ) {
    if (!this.allinoneService.isAdminOf('org')) {
      this.router.navigateByUrl('/home');
    }
    this.orgid = this.allinoneService.selectedOrg;
  }

  ngOnInit(): void {
    this.getDeviceInfos();
  }

  refresh() {
    this.currentPage = 1;
    this.getDeviceInfos();
  }

  getDeviceInfos() {
    this.listLoading = true;
    const data = {
      orgid: this.orgid,
      department:
        this.searchForm.department == 'All' ? '' : this.searchForm.department,
      division:
        this.searchForm.division == 'All' ? '' : this.searchForm.division,
      teamid: this.searchForm.teamid == 'All' ? '' : this.searchForm.teamid,
      costcenter:
        this.searchForm.costcenter == 'All' ? '' : this.searchForm.costcenter,
      subdivision:
        this.searchForm.subdivision == 'All' ? '' : this.searchForm.subdivision,
    };
    this.getDeviceInfoSubscription &&
      this.getDeviceInfoSubscription.unsubscribe();
    this.getDeviceInfoSubscription = this.kunyekService
      .getDeviceInfo(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.deviceInfoList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.listLoading = false;
        },
        (err) => {
          this.listLoading = false;
        }
      );
  }

  sortData(sort: Sort) {
    const data = this.deviceInfoList;
    if (!sort.active || sort.direction === '') {
      this.deviceInfoList = data;
      return;
    }
    this.deviceInfoList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'mobile':
          return this.allinoneService.compare(a.mobile, b.mobile, isAsc);
        case 'appname':
          return this.allinoneService.compare(a.appname, b.appname, isAsc);
        case 'devicename':
          return this.allinoneService.compare(
            a.devicename,
            b.devicename,
            isAsc
          );
        case 'uuid':
          return this.allinoneService.compare(a.uuid, b.uuid, isAsc);
        case 'date':
          return this.allinoneService.compare(
            a.createddate,
            b.createddate,
            isAsc
          );
        case 'version':
          return this.allinoneService.compare(a.version, b.version, isAsc);
        case 'appversion':
          return this.allinoneService.compare(
            a.appversion,
            b.appversion,
            isAsc
          );

        default:
          return 0;
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.currentPage = 1;
    this.itemPerPage = event.value;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      //  ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  departmentChange(dep: any) {
    if (this.searchForm.department != dep) {
      this.searchForm.department = dep;
      this.searchForm.division = 'All';
      this.searchForm.teamid = 'All';
      this.searchForm.costcenter = 'All';
      this.searchForm.subdivision = 'All';
    }
    this.refresh();
  }

  divisionChange(divi: any) {
    if (this.searchForm.division != divi) {
      this.searchForm.division = divi;
      this.searchForm.teamid = 'All';
      this.searchForm.costcenter = 'All';
      this.searchForm.subdivision = 'All';
    }
    this.refresh();
  }

  teamidChange(teamid: any) {
    if (this.searchForm.teamid != teamid) {
      this.searchForm.teamid = teamid;
      this.searchForm.costcenter = 'All';
      this.searchForm.subdivision = 'All';
    }
    this.refresh();
  }

  costCenterChange(costcenter: any) {
    if (this.searchForm.costcenter != costcenter) {
      this.searchForm.costcenter = costcenter;
      this.searchForm.subdivision = 'All';
    }
    this.refresh();
  }

  subDivisionChange(subdivision: any) {
    if (this.searchForm.subdivision != subdivision) {
      this.searchForm.subdivision = subdivision;
    }
    this.refresh();
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.getMemberSub = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.departmentList = res.departmentlist;
          // this.divisionList = res.divisionlist;
          // this.teamidList = res.teamidlist;
          for (var i = 0; i < res.departmentlist.length; i++) {
            this.departmentList.push(res.departmentlist[i]);
          }
          for (var i = 0; i < res.divisionlist.length; i++) {
            this.divisionList.push(res.divisionlist[i]);
          }
          for (var i = 0; i < res.teamidlist.length; i++) {
            this.teamidList.push(res.teamidlist[i]);
          }
          for (var i = 0; i < res.costcenterlist.length; i++) {
            this.costCenterList.push(res.costcenterlist[i]);
          }
          for (var i = 0; i < res.subdivision.length; i++) {
            this.subDivisionList.push(res.subdivision[i]);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  downloadSheet() {
    var exdata: any = [];

    // var name = 'AbsentReport.xlsx';
    var name = 'Device Infos.xlsx';
    var filteredData = this.filterPipe.transform(
      this.deviceInfoList,
      this.searchText,
      'deviceinfo'
    );
    filteredData.map((item: any) => {
      var temp = {
        'Employee ID': item.employeeid,
        'User ID': item.userid,
        Name: item.username,
        Mobile: item.mobile,
        'Device Name': item.devicename,
        Date: this.datePipe.transform(item.createddate, 'dd/MM/yyyy'),
        UUID: item.uuid,
        'Connect Version': item.appversion,
        App: item.appname,
        // Department: item.department,
        // Division: item.division,
        // 'Team ID': item.teamid,
        // 'Cost Center': item.costcenter,
        // 'Sub Division': item.subdivision,

        'Device Version': item.version,

      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }
}
