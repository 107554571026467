<div class="container-fluid">
    <div class="content">

        <app-breadcrumb [s_breadcrumb]="'Events'" [t_breadcrumb]="event_name" [f_breadcrumb]="'Attendance'"
            (emit)="cancel()" (emit1)="goToEventEdit()">
        </app-breadcrumb>
        <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
            <!-- *ngIf="!memberAddorNot && !isBulkUpload"  *ngIf="!isMobileView"-->
            <div class="input-group" style="width: 300px !important;">
                <input class="form-control" type="text" placeholder="&#xf002; Search with User ID/Name/Employee ID"
                    aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                    [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;" (ngModelChange)="p = 1">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
            </div>
            <div class="dropdown ms-2">
                <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false" title="Page Size">
                    <mat-icon>filter_list</mat-icon>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <div c style="text-align: center;">Page size</div>
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li class="dropdown-item" *ngFor="let page of pg">
                        <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                            id="pg{{page.name}}" class="p-1" [checked]="page.count==itemsPerPage">
                        <label for="pg{{page.name}}" class="col-10 p-1">
                            {{page.name}}
                        </label>
                    </li>
                </ul>
            </div>
            <div class="ms-2">
                <button class="btn btn-custom" (click)="getAttendance()" title="Refresh">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <div class="line"></div>
            <div class="filtter-btn align-items-center" [matMenuTriggerFor]="filterMenu">
                <button
                    class="d-flex align-items-center justify-content-center btn btn-secondary dropdown-toggle advanced-filter-btn"
                    type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle
                    style="color: white; min-width: 100px">
                    <!-- Type&nbsp;:&nbsp;{{filterTypeName}} -->
                    {{filterTypeName}}

                    <mat-menu #filterMenu="matMenu" xPosition="before">
                        <button mat-menu-item *ngFor="let item of filterMenuList" (click)="filterChange(item)">
                            {{ item.name }}
                        </button>
                    </mat-menu>
                </button>

            </div>
            <div class="line"></div>
            <div>
                <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export Members To Excel">
                    <img class="excel-icon" src="../../../assets/images/excel.png">
                    Export
                </button>
            </div>
            <div class="line"></div>
            <div class="d-flex align-items-center me-auto">
                <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{filterType == '001' ?
                    (attendanceList | filter: searchText:gtype).length: (notAttendList | filter:
                    searchText:gtype).length}}</span>
            </div>
        </div>


        <ng-container *ngIf="filterType == '001'; else notAttend">
            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="userid">User ID</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="name">Name</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="employeeid">Employee ID</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="member">Organization Member</td>

                        </tr>
                    </thead>
                    <tbody *ngIf="gettingAttendance">
                        <tr>
                            <td colspan="6" class="nodata">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="gettingAttendance == false">
                        <tr *ngIf="attendanceList.length == 0">
                            <td colspan="6" class="nodata">Empty</td>
                        </tr>
                        <tr
                            *ngFor="let item of attendanceList |  filter: searchText:gtype | paginate: { itemsPerPage: itemsPerPage, currentPage: p } ; let index = index">
                            <td class="td-data td-edit">
                                <span [title]="item.userid">{{item.userid}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.username">{{item.username}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.username">{{item.employeeid}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.member ? 'Yes' : 'No' ">{{item.member ? 'Yes' : 'No' }}</span>
                            </td>

                            <!-- <td>{{item.description}}</td> -->

                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-template #notAttend>
            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="userid">User ID</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="name">Name</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="employeeid">Employee ID</td>

                        </tr>
                    </thead>
                    <tbody *ngIf="gettingAttendance">
                        <tr>
                            <td colspan="6" class="nodata">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="gettingAttendance == false">
                        <tr *ngIf="notAttendList.length == 0">
                            <td colspan="6" class="nodata">Empty</td>
                        </tr>
                        <tr
                            *ngFor="let item of notAttendList | filter: searchText:gtype | paginate: { itemsPerPage: itemsPerPage, currentPage: p } ; let index = index">
                            <td class="td-data td-edit">
                                <span [title]="item.userid">{{item.userid}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.username">{{item.username}}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="item.username">{{item.employeeid}}</span>
                            </td>

                            <!-- <td>{{item.description}}</td> -->

                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-template>

        <pagination-controls class="paginate" (pageChange)="p = $event"
            *ngIf="attendanceList.length > itemsPerPage && !gettingAttendance && filterType == '001'"
            previousLabel="Prev" nextLabel="Next"></pagination-controls>
        <pagination-controls class="paginate" (pageChange)="p = $event"
            *ngIf="notAttendList.length > itemsPerPage && !gettingAttendance && filterType == '002'"
            previousLabel="Prev" nextLabel="Next"></pagination-controls>
    </div>

</div>