import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-hierarchy-children',
  templateUrl: './hierarchy-children.component.html',
  styleUrls: ['./hierarchy-children.component.css']
})
export class HierarchyChildrenComponent implements OnInit {

  list: any = []
  selectedList: any = []
  selectedChildren: any = []

  isSelectedAll: boolean = false
  isFocus : boolean = false


  searchText : string = ""

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<HierarchyChildrenComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.list = data.list
    this.selectedList = data.selectedList
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true
    console.log(this.list)
    console.log(this.selectedList)
    this.list.map((pitem: any) => {
      pitem.checked = false
      this.selectedList.map((citem: any) => {
        if (pitem.id == citem.id) {
          pitem.checked = true
        }
      })
    })
    var tempList = this.selectedChildren = this.list.filter((item: any) => {
      return item?.checked
    });

    this.isSelectedAll = tempList.length == this.list.length
  }

  clear(){
    this.searchText = ""
  }

  close(confirm: boolean) {
    if (confirm) {
      this.selectedChildren = this.list.filter((item: any) => {
        return item?.checked
      });
      this.dialogRef.close({
        confirm: true,
        list: this.selectedChildren
      })
    }
    else {
      this.dialogRef.close({
        confirm: false,
        list: []
      })
    }
  }

  check(index: number) {
    this.list[index].checked = !this.list[index].checked
    var tempList = this.selectedChildren = this.list.filter((item: any) => {
      return item?.checked
    });

    this.isSelectedAll = tempList.length == this.list.length

  }

  selectAll(){
    if(this.isSelectedAll){
      this.list.map((item: any) => {
        item.checked = false
      })
    }
    else{
      this.list.map((item: any) => {
        item.checked = true
      })
    }
    this.isSelectedAll = !this.isSelectedAll
  }

}
