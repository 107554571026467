import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-rules-and-regulation-setup',
  templateUrl: './rules-and-regulation-setup.component.html',
  styleUrls: ['./rules-and-regulation-setup.component.scss']
})
export class RulesAndRegulationSetupComponent implements OnInit {
  @ViewChild('choosefile') choosefile: any;
  @ViewChild('contactfile') contactfile: any;
  @ViewChild('pfFileInput') pfFileInput: any;

  submitted : boolean = false;
  isEditing : boolean = false;
  saveLoading: boolean = false;
  orgid :string = ''

  fileSelected: any = '';

  filetypeList: any = {
    pdf: '90',
    // jpg: '80',
    // jpeg: '81',
    // png: '82',
    // docx : '91'
  };

  typeList = [
    {
      name: 'Document',
      id: '001',
    },
    {
      name: 'Other',
      id: '002',
    },
  ];

  form = this.formBuilder.group({
    name : ['', Validators.required],
    description : [''],
    type : ['001'],
    uploadfilename: ['', Validators.required],
    filename : [''],
    // filepath: ['', Validators.required],
    filetype: [''],
    ruleid : ['']
  })

  get formControl() {
    return this.form.controls;
  }

  constructor(
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private datepipe: DatePipe,
    private formBuilder : FormBuilder,
    public dialogRef: MatDialogRef<RulesAndRegulationSetupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isEditing = data.isEditing;
    this.orgid = data.orgid;
    console.log(data);
    
    if(this.isEditing) {
      var temp = data.rulesdata;
      this.form.setValue({
        ruleid : temp.ruleid,
        name : temp.name,
        description : temp.description,
        type : temp.type,
        filename : temp.filename,
        filetype : temp.filename.split('.').pop(),
        uploadfilename : temp.uploadfilename,
      })
    }
   }

  ngOnInit(): void {
  
  }

  async submitForm() {
    this.submitted = true;
    if (this.formControl.name.invalid) {
      return this.messageService.openSnackBar('Please enter Name.', 'warn');
    }
    if(this.formControl.uploadfilename.invalid) {
      return this.messageService.openSnackBar('Please choose a file.', 'warn');
    }
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var type = this.formControl.type.value.toString().trim();
    const name = this.formControl.name.value.toString().trim();
    const description = this.formControl.description.value.toString().trim();
    const filename = this.formControl.filename.value.toString().trim();
    const filetype = this.formControl.filetype.value.toString().trim();
    // const path = this.formControl.filepath.value.toString().trim();
    const uploadfilename = this.formControl.uploadfilename.value;
    const ruleid = this.formControl.ruleid.value;
    
    if(this.fileSelected) {
      const result = await this.allinoneService.newfileUploadToS3(
        this.fileSelected,
        uploadfilename,
        this.orgid + '/rules/'
      );
      if(!result) {  
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        return this.messageService.openSnackBar(
          'There was an error uploading your file.',
          'warn'
        );
      }
    }
    // if(this.fileSelected) {
    //   console.log(this.fileSelected);
    //   console.log(filetype);
    //   console.log(this.orgid + '/rules/' + uploadfilename);
    // }
    const data = {
      orgid : this.orgid,
      ruleid : ruleid,
      name : name,
      description : description,
      type : type,
      filename : filename,
      filetype : filetype,
      uploadfilename : uploadfilename,
      useridlist : []
    }

    console.log(data);
    // return;
    var fun = this.isEditing ? 
    this.kunyekService.updateRules(data)
    : this.kunyekService.addRules(data);

    fun.subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          var temp = {
            ruleid : res.ruleid,
            type : type,
            description : description,
            name : name,
            filename : filename,
            filetype : filetype,
            uploadfilename : uploadfilename,
            fileurl : res.fileurl
          };
          this.dialogRef.close(temp);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error',
          'warn'
        );
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    )
    
  }

  checkFile(event: any) {
    if (!this.isEditing) {
      this.submitted = false;
      this.fileSelected = '';
      this.form.patchValue({
        filename: '',
        // filepath: '',
        filetype: '',
        uploadfilename : '',
      });
    }
  }

  getType(id:any) {
    for(var i = 0; i < this.typeList.length; i++) {
      if(this.typeList[i].id == id) {
        return this.typeList[i].name;
      }
    }
    return '';
  }

  uploadFile(event: any) {
    var filesize = event[0].size / 1000000;
    if (filesize > 200) {
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    var filetype = event[0].name.split('.').pop();

    var temp = event[0].name.split('.')
    var name = temp[0]

    if (this.filetypeList[filetype] == undefined) {
      this.messageService.openSnackBar('Not supported file type.', 'warn');
      return;
    }
    const isFiletypesame = this.formControl.filetype.value == filetype;
    this.fileSelected = event[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      uploadfilename : name + this.getUploadFileName() + filetype,
      filetype: filetype,
    });
  }

  onDismiss() {
    this.dialogRef.close();
  }

  test() {
    console.log(this.fileSelected);
    
    console.log(this.formControl.filename.value);
    console.log(this.formControl.filetype.value);
    
    console.log(this.formControl.uploadfilename.value);

    // var a = 'N5 Grammer Note ( Student ).pdf';
    // var name = a.split('.')
    // console.log(name[0]);
    
  }

  onFileSelected(event: any) {
    var filesize = event.target.files[0].size / 1000000;
    if (filesize > 200) {
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    var filetype = event.target.files[0].name.split('.').pop();
    var temp = event.target.files[0].name.split('.')
    var name = temp[0]
    if (this.filetypeList[filetype] == undefined) {
      this.messageService.openSnackBar('Not supported file type.', 'warn');
      return;
    }
    const isFiletypesame = this.formControl.filetype.value == filetype;
    this.fileSelected = event.target.files[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      uploadfilename : name + this.getUploadFileName() + "." + filetype,
      filetype: filetype,
    });
    this.choosefile.nativeElement.value = '';
  }

  getUploadFileName() {
    var currentdatetime = this.datepipe.transform(new Date(), 'yyyyMMddhhmmss');
    var shuffled = currentdatetime!
      .split('')
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join('');
    console.log(shuffled);
    return shuffled;
  }

  removeAttachment(){
    this.form.patchValue({
      filename: '',
      filetype: '',
      uploadfilename : ''
    });
    this.fileSelected = '';   
  }

  cancel() {
    this.dialogRef.close();
  }

}
