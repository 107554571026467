import { Component, Inject, OnInit, Optional } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-leave-opening-form',
  templateUrl: './office-leave-opening-form.component.html',
  styleUrls: ['./office-leave-opening-form.component.scss'],
})
export class OfficeLeaveOpeningFormComponent implements OnInit {
  member: any;
  userid: string = '';
  username: string = '';
  domainid: string = '';
  orgid: string = '';
  templateid: string = '';
  openingid: string = '';
  templateList: any[] = [];

  gettingLeaveOpening: boolean = false;
  isLoading: boolean = false;
  isNew: boolean = false;
  isAdmin: boolean = false;
  calendarid: any = '';

  subs = new SubSink();

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    // private dialogRef: MatDialogRef<OfficeLeaveOpeningFormComponent>,
    private route: ActivatedRoute,
    private router: Router // @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    try{
      var tempData = JSON.parse(
        this.allinoneService.decrypt(
          decodeURIComponent(this.route.snapshot.params['data'])
        )
      );
    }
    catch {
      this.goToLeaveOpening();
    }
    
    this.isNew = tempData.isnew;
    this.userid = tempData.userid;
    this.username = tempData.username;
    this.openingid = tempData.openingid;
    this.templateid = tempData.templateid;
    this.calendarid = tempData.calendarid;
    // if (this.isNew) {
    //   this.member = data.user;
    //   this.templateid = data.templateid;
    //   this.calendarid = data.calendarid;
    // } else {

    if (this.isAdmin) {
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.allinoneService.orgs.orgid;
    } else {
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.allinoneService.orgsCRm.orgid;
    }
    // if(this.isNew){
    //   this.getLeaveOpening();
    // }
    this.getLeaveOpening();
  }

  getLeaveOpening() {
    this.gettingLeaveOpening = true;
    var data = {
      orgid: this.orgid,
      memberid: this.userid,
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subs.sink = this.kunyekService.getLeaveOpening(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.openingid = res.openingid;
          this.templateid = res.templateid;
          this.calendarid = res.calendarid;
          this.templateList = res.templatelist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingLeaveOpening = false;
      },
      (err) => {
        this.gettingLeaveOpening = false;
      }
    );
  }

  save() {
    
    if(this.isLoading || this.templateList.length == 0){
      return;
    }
    this.isLoading = true;
    this.templateList.map((item: any) => {
      if (item.unlimited) {
        item.opening = '0';
        item.broughtforward = '0';
      } else {
        // if (item.noofdays == '0') {
        //   item.opening = '0';
        // }
        if (item.opening == null) {
          item.opening = '';
        }
        if (
          // item.noofdays == '0' ||
          item.broughtforward == null ||
          item.broughtforward == ''
        ) {
          item.broughtforward = '0';
        }
      }

      item.opening = item.opening.toString();
      item.broughtforward = item.broughtforward.toString();
    });

    var data = {
      orgid: this.orgid,
      templateid: this.templateid,
      memberid: this.userid,
      openingid: this.openingid,
      calendarid: this.calendarid,
      requesttypelist: this.templateList,
      roleid: this.allinoneService.getSelectedRole(),
    };
    // console.log(data);
    // return;
    this.subs.sink = this.kunyekService.addLeaveOpening(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Saved successfully.', 'success');
          // this.dialogRef.close();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  validate(index: any) {
    index = parseFloat(index);

    if (
      this.templateList[index].opening > this.templateList[index].noofdays ||
      this.templateList[index].opening < 0
    ) {
      this.templateList[index].opening = 0;
    }
  }

  goToLeaveOpening() {
    let url =
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/requestapproval'
        : '/office') + '/leaveopening';

    console.log(url);
    this.router.navigateByUrl(url);    
  }
}
