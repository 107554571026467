<div style="height: 496px;" *ngIf="!customercare">

    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <!-- advansearch -->
        <div class="label title" style="font-size: 17px;padding: 0px 0px 15px 0px;font-weight: 500;">Advanced Search
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="mb-3">
                    <label class="form-label">Ticket No.</label>
                    <input type="text" aria-label="Number" [(ngModel)]="ticketNo" matInput class="form-control"
                        name="customer" id="customer" autocomplete="off">

                </div>
            </div>
            <div class="col-sm-4">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Ref No.</label>

                        <input type="text" aria-label="refNo" [(ngModel)]="refNo" matInput class="form-control"
                            name="refNo" id="refNo" autocomplete="off">
                    </div>
                </div>

            </div>

            <!-- Project -->
            <div class="col-sm-4 styled-select" *ngIf="!customercare">
                <label for="proj" class="form-label">Project</label>


                <ng-select class="role-select" [items]="projectList" bindLabel="name" placeholder="-" appendTo="body"
                    [multiple]="false" [(ngModel)]="projectid" (change)="getProjectName()">

                </ng-select>
                <mat-progress-bar mode="indeterminate" *ngIf="getProject"></mat-progress-bar>
            </div>
            <!--  -->



        </div>

        <div class="row">
            <div class="col-sm-4" *ngIf="!customercare">
                <div class="form-check mb-3">

                    <input class="form-check-input cus" type="checkbox" [(ngModel)]="checkedBol" id="flexCheckDisabled"
                        (change)="checkassigned($event)">
                    <label class="form-check-label cus" for="flexCheckDisabled">
                        Assigned To Me
                    </label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="mb-3 " style="display: flex;">

                    <div class="form-check" *ngFor="let item of prioritys" style="margin-right: 0.5rem;">
                        <label class="radio-inline" [id]="item.code">
                            <input class="form-check-input" type="radio" [(ngModel)]="priorityVal" [value]="item.code"
                                (change)="getpriorityName()">
                            {{item.name}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 d-flex">
                <div class="form-check mb-3">

                    <input class="form-check-input cus" type="checkbox" [(ngModel)]="recrring" id="flexCheckDisabled1"
                        (change)="checkRecurring($event)">
                    <label class="form-check-label cus" for="flexCheckDisabled1">
                        Recurring
                    </label>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4" *ngIf="!customercare">
                <!--  *ngIf="!checkedBol && !customercare"-->
                <div class="mb-3 styled-select">
                    <label class="form-label">Assigned To</label>
                    <!-- <select class="form-select" aria-label="Select customer" [(ngModel)]="picID" (change)="picIDget($event)" [disabled]="checkedBol && !customercare">
                        <option [ngClass]="{'select-small':  isMobileView}" selected value=''>-</option>
                        <option [ngClass]="{'select-small':  isMobileView}" *ngFor="let i of picdatalist"
                            [value]="i.picid">{{i.picname == "" ? i.userid :
                            i.picname}}
                        </option>
                    </select> -->
                    <ng-select class="role-select" [items]="picdatalist" bindLabel="picname" placeholder="-"
                        appendTo="body" (change)="picIDget($event)" [multiple]="false" [(ngModel)]="picID"
                        [disabled]="checkedBol && !customercare">
                        <ng-template ng-label-tmp let-item="item">
                            {{item.picname ? item.picname : item.userid}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            {{item.picname ? item.picname : item.userid}}
                        </ng-template>
                    </ng-select>
                    <mat-progress-bar mode="indeterminate" *ngIf="getPic"></mat-progress-bar>

                </div>


            </div>
            <div class="col-sm-4">
                <div class="mb-3 styled-select">
                    <label for="proj" class="form-label">Product</label>
                    <!-- <select class="form-select" aria-label="Select Product" [(ngModel)]="productID" (change)="productChange($event)" [disabled]="getproduct">
                        <option [ngClass]="{'select-small':  isMobileView}" selected value=''>-</option>
                        <option [ngClass]="{'select-small':  isMobileView}" *ngFor="let i of products" [value]="i.id">
                            {{i.name}}
                        </option>
                    </select> -->
                    <ng-select class="role-select" [items]="products" bindLabel="name" placeholder="-" appendTo="body"
                        [multiple]="false" [(ngModel)]="productID" (change)="productChange($event)">

                    </ng-select>
                    <mat-progress-bar mode="indeterminate" *ngIf="getproduct"></mat-progress-bar>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="mb-3 styled-select">


                    <label for="proj" class="form-label">Module</label>
                    <ng-select class="role-select" [items]="modulelist" bindLabel="description" placeholder="-"
                        appendTo="body" [multiple]="false" [(ngModel)]="moduleid" (change)="moduleChange($event)">

                    </ng-select>
                    <!-- <select class="form-select" aria-label="Select Product" [(ngModel)]="moduleid" (change)="moduleChange($event)">
                        <option [ngClass]="{'select-small':  isMobileView}" selected value=''>-</option>
                        <option [ngClass]="{'select-small':  isMobileView}" *ngFor="let i of modulelist"
                            [value]="i.moduleid">{{i.description}}
                        </option>
                    </select> -->
                </div>

            </div>
        </div>

        <div class="row">
            <!-- Project -->
            <!-- <div class="col-sm-4 styled-select" *ngIf="!customercare">
                <label for="proj" class="form-label">Project</label>


                <ng-select class="role-select" [items]="projectList" bindLabel="name" placeholder="-" appendTo="body"
                    [multiple]="false" [(ngModel)]="projectid" (change)="getProjectName()">

                </ng-select>
                <mat-progress-bar mode="indeterminate" *ngIf="getProject"></mat-progress-bar>
            </div> -->
            <!--  -->

            <div class="col-sm-4 styled-select" *ngIf="!customercare">
                <label for="proj" class="form-label">Customer Group</label>


                <ng-select class="role-select" [items]="customergroupList" bindLabel="name" placeholder="-"
                    appendTo="body" [multiple]="false" [(ngModel)]="cusgroupid" (change)="getCustomerGroup($event)">

                </ng-select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingCustomerGroup"></mat-progress-bar>
            </div>
            <!-- Customer -->
            <div class="col-sm-4">
                <div class="mb-3 styled-select" *ngIf="!check && !customercare">
                    <label for="proj" class="form-label">Customer</label>
                    <!-- [ngClass]="{ 'role-select-invalid': memberForm.roleList.length == 0 && isRoleSelected && memberformSubmitted }" -->
                    <ng-select class="role-select" [items]="customers" bindLabel="customername" placeholder="-"
                        appendTo="body" [multiple]="false" [(ngModel)]="customerVal" (change)="getCustomerName()">

                    </ng-select>
                    <!-- *ngIf="memberForm.roleList.length == 0 && isRoleSelected && memberformSubmitted" -->
                    <!-- <div class="invalid-ngselect"
                    >
                    <div>
                        {{allinoneService.input_err_msg}}
                    </div>
                </div> -->
                    <!-- <select class="form-select" aria-label="Select customer" [(ngModel)]="customerVal"
                    (change)="getCustomerName()" [disabled]="getCustomer">
                    <option [ngClass]="{'select-small':  isMobileView}" selected value=''>-</option>
                    <option [ngClass]="{'select-small':  isMobileView}" *ngFor="let i of customers "
                        [value]="i.customerid">{{i.customername}}
                    </option>
                </select> -->
                    <mat-progress-bar mode="indeterminate" *ngIf="getCustomer"></mat-progress-bar>
                </div>
            </div>
            <!--  -->

            <!-- Branch -->
            <div class="col-sm-4">
                <div class="mb-3 styled-select">
                    <label for="proj" class="form-label">Branch</label>
                    <!-- [ngClass]="{ 'role-select-invalid': memberForm.roleList.length == 0 && isRoleSelected && memberformSubmitted }" -->
                    <ng-select class="role-select" [items]="branchlist" bindLabel="branchname" placeholder="-"
                        appendTo="body" [multiple]="false" [(ngModel)]="ngbranchlist"
                        (change)="getCustomerBranch($event)">
                    </ng-select>
                </div>
            </div>



            <!-- Description -->
            <!-- -->
            <!--  -->
        </div>

        <div class="row">
            <div class="col-sm-4">
                <div class=" d-flex mb-2">
                    <div class="form-check me-4">
                        <label class="radio-inline">
                            <input type="radio" [checked]="!checkdate" id="aa" class="form-check-input "
                                (change)="checkChange1($event)">Date
                        </label>
                    </div>
                    <div class="form-check">
                        <label class="radio-inline">
                            <input type="radio" [checked]="checkdate" id="cus" class="form-check-input "
                                (change)="checkChange2($event)">
                            Between
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-sm-4" *ngIf="!isMobileView && checkdate">
            </div>
            <div class="col-sm-4" *ngIf="!isMobileView">
                Age
            </div>
            <div class="col-sm-4" *ngIf="!isMobileView && !checkdate">
                <label class="form-label">Reported By</label>

            </div>
            <div *ngIf="!isMobileView && checkdate" class="col-sm-4">

            </div>


        </div>

        <div class="row">

            <div class="col-sm-4">
                <div class="mb-3" *ngIf="!checkdate">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate" step="1">
                </div>
                <div class="mb-3" *ngIf="checkdate">
                    <input type="date" class="form-control" id="rangestartdate" name="rangestartdate"
                        [(ngModel)]="rangestartdate" step="1">
                </div>

            </div>
            <div class="col-sm-4" *ngIf="checkdate">
                <div class="mb-3">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate" step="1">
                </div>
            </div>


            <div class="col-sm-4">
                <label class="form-label" *ngIf="isMobileView">
                    Age
                </label>
                <div class="input-group  mb-3">

                    <input type="number" *ngIf="checkage" aria-label="test" [(ngModel)]="startage" matInput
                        class="form-control" name="age" id="age" autocomplete="off" style="width: 30%;">
                    <input type="number" *ngIf="!checkage" aria-label="test" [(ngModel)]="startage" matInput
                        class="form-control" name="age" id="age" autocomplete="off" style="width: 60%;">

                    <input type="number" *ngIf="checkage" aria-label="test" [(ngModel)]="age" matInput
                        class="form-control" name="age" id="age" autocomplete="off" style="width: 30%;">


                    <select class="form-select" aria-label="Select status" [(ngModel)]="ageid"
                        (change)="ageChange($event)" matTooltip="{{toolname}}">
                        <option *ngFor="let i of ageList" value="{{i.code}}">{{i.name}}</option>
                    </select>

                </div>
            </div>
            <div class="col-sm-4">

                <div class="  mb-3">
                    <label class="form-label" *ngIf="isMobileView">Reported By</label>
                    <label class="form-label" *ngIf="!isMobileView && checkdate">Reported By</label>
                    <ng-select class="role-select" [items]="members" bindLabel="username" placeholder="-"
                    appendTo="body" [multiple]="false" [(ngModel)]="reportedby"
                    (change)="selectMember($event)">
                </ng-select>
                    <!-- <input type="text" aria-label="textt" [matAutocomplete]="automember" [(ngModel)]="reportedby"
                        matInput class="form-control" name="reportedby" id="reportedby"
                        placeholder="john@domain.com, +959********">
                    <mat-autocomplete #automember="matAutocomplete">
                        <mat-option *ngFor="let i of members | crmFilter : reportedby:searchNonea:assignedto:13"
                            (onSelectionChange)="selectMember(i.username,i.userid)"
                            (click)="selectMember(i.username,i.userid)">
                            {{i.username != '' ? i.username : ''}}&nbsp;{{i.userid}}
                        </mat-option>

                    </mat-autocomplete> -->
                </div>
                <!-- -->

            </div>
            <div class="col-sm-4" *ngIf="checkdate">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Detail</label>

                        <input type="text" aria-label="Detail" [(ngModel)]="detail" matInput class="form-control"
                            name="Detail" id="Detail" autocomplete="off">
                    </div>
                </div>

            </div>
            <div class="col-sm-4" *ngIf="checkdate">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Description</label>

                        <input type="text" aria-label="description" [(ngModel)]="description" matInput
                            class="form-control" name="description" id="description" autocomplete="off">
                    </div>
                </div>

            </div>

        </div>

        <div class="row" *ngIf="!checkdate">
            <div class="col-sm-4">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Detail</label>

                        <input type="text" aria-label="Detail" [(ngModel)]="detail" matInput class="form-control"
                            name="Detail" id="Detail" autocomplete="off">
                    </div>
                </div>

            </div>
            <div class="col-sm-4">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Description</label>

                        <input type="text" aria-label="description" [(ngModel)]="description" matInput
                            class="form-control" name="description" id="description" autocomplete="off">
                    </div>
                </div>

            </div>


        </div>

    </perfect-scrollbar>
    <div mat-dialog-actions align="end" style="padding-right: 20px;">
        <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary me-2">
            Cancel
        </button>
        <button type="button" (click)="search()" class="btn btn-custom ">
            Search
        </button>
    </div>
</div>
<div style="height: 496px;" *ngIf="customercare">

    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <!-- advansearch -->
        <div class="label title" style="font-size: 17px;padding: 0px 0px 15px 0px;font-weight: 500;">Advanced Search
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="mb-3">
                    <label class="form-label">Ticket No.</label>
                    <input type="text" aria-label="Number" [(ngModel)]="ticketNo" matInput class="form-control"
                        name="customer" id="customer" autocomplete="off">

                </div>
            </div>
            <div class="col-sm-6">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Ref No.</label>

                        <input type="text" aria-label="refNo" [(ngModel)]="refNo" matInput class="form-control"
                            name="refNo" id="refNo" autocomplete="off">
                    </div>
                </div>

            </div>



        </div>

        <div class="row">

            <div class="col-sm-6">
                <div class="mb-3 " style="display: flex;">

                    <div class="form-check" *ngFor="let item of prioritys" style="margin-right: 0.5rem;">
                        <label class="radio-inline" [id]="item.code">
                            <input class="form-check-input" type="radio" [(ngModel)]="priorityVal" [value]="item.code"
                                (change)="getpriorityName()">
                            {{item.name}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-check mb-3">

                    <input class="form-check-input cus" type="checkbox" [(ngModel)]="recrring" id="flexCheckDisabled1"
                        (change)="checkRecurring($event)">
                    <label class="form-check-label cus" for="flexCheckDisabled1">
                        Recurring
                    </label>
                </div>
            </div>

        </div>
        <div class="row">

            <div class="col-sm-6">
                <div class="mb-3 styled-select">
                    <label for="proj" class="form-label">Product</label>
                    <!-- <select class="form-select" aria-label="Select Product" [(ngModel)]="productID" (change)="productChange($event)" [disabled]="getproduct">
                        <option [ngClass]="{'select-small':  isMobileView}" selected value=''>-</option>
                        <option [ngClass]="{'select-small':  isMobileView}" *ngFor="let i of products" [value]="i.id">
                            {{i.name}}
                        </option>
                    </select> -->
                    <ng-select class="role-select" [items]="products" bindLabel="name" placeholder="-" appendTo="body"
                        [multiple]="false" [(ngModel)]="productID" (change)="productChange($event)">

                    </ng-select>
                    <mat-progress-bar mode="indeterminate" *ngIf="getproduct"></mat-progress-bar>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="mb-3 styled-select">


                    <label for="proj" class="form-label">Module</label>
                    <ng-select class="role-select" [items]="modulelist" bindLabel="description" placeholder="-"
                        appendTo="body" [multiple]="false" [(ngModel)]="moduleid" (change)="moduleChange($event)">

                    </ng-select>
                    <!-- <select class="form-select" aria-label="Select Product" [(ngModel)]="moduleid" (change)="moduleChange($event)">
                        <option [ngClass]="{'select-small':  isMobileView}" selected value=''>-</option>
                        <option [ngClass]="{'select-small':  isMobileView}" *ngFor="let i of modulelist"
                            [value]="i.moduleid">{{i.description}}
                        </option>
                    </select> -->
                </div>

            </div>

        </div>

        <div class="row">
            <div class="col-sm-6 styled-select">
                <label for="proj" class="form-label">Project</label>


                <ng-select class="role-select" [items]="projectList" bindLabel="name" placeholder="-" appendTo="body"
                    [multiple]="false" [(ngModel)]="projectid" (change)="getProjectName()">

                </ng-select>
                <mat-progress-bar mode="indeterminate" *ngIf="getProject"></mat-progress-bar>
            </div>


            <div class="col-sm-6" *ngIf="cusbranchid == ''">
                <div class="mb-3 styled-select">
                    <label for="proj" class="form-label">Branch</label>
                    <!-- [ngClass]="{ 'role-select-invalid': memberForm.roleList.length == 0 && isRoleSelected && memberformSubmitted }" -->
                    <ng-select class="role-select" [items]="branchlist" bindLabel="branchname" placeholder="-"
                        appendTo="body" [multiple]="false" [(ngModel)]="ngbranchlist"
                        (change)="getCustomerBranch($event)">
                    </ng-select>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="cusbranchid != '' ">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Detail</label>

                        <input type="text" aria-label="Detail" [(ngModel)]="detail" matInput class="form-control"
                            name="Detail" id="Detail" autocomplete="off">
                    </div>
                </div>

            </div>




        </div>
        <div class="row">
            <div class="col-sm-6">

                <div class=" d-flex mb-2">

                    <div class="form-check me-4">
                        <label class="radio-inline">
                            <input type="radio" [checked]="!checkdate" id="aa" class="form-check-input "
                                (change)="checkChange1($event)">Date
                        </label>
                    </div>
                    <div class="form-check">
                        <label class="radio-inline">
                            <input type="radio" [checked]="checkdate" id="cus" class="form-check-input "
                                (change)="checkChange2($event)">
                            Between
                        </label>
                    </div>


                </div>

            </div>
            <div class="col-sm-6" *ngIf="!checkdate">
                Age
            </div>

        </div>







        <div class="row">

            <div class="col-sm-6">
                <div class="mb-3" *ngIf="!checkdate">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate" step="1">
                </div>
                <div class="mb-3" *ngIf="checkdate">
                    <input type="date" class="form-control" id="rangestartdate" name="rangestartdate"
                        [(ngModel)]="rangestartdate" step="1">
                </div>

            </div>
            <div class="col-sm-6" *ngIf="checkdate">
                <div class="mb-3">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate" step="1">
                </div>
            </div>


            <div class="col-sm-6">

                <label class="form-label" *ngIf="checkdate">Age</label>

                <div class="input-group ">
                    <input type="number" *ngIf="checkage" aria-label="test" [(ngModel)]="startage" matInput
                        class="form-control" name="age" id="age" autocomplete="off" style="width: 30%;">
                    <input type="number" *ngIf="!checkage" aria-label="test" [(ngModel)]="startage" matInput
                        class="form-control" name="age" id="age" autocomplete="off" style="width: 60%;">

                    <input type="number" *ngIf="checkage" aria-label="test" [(ngModel)]="age" matInput
                        class="form-control" name="age" id="age" autocomplete="off" style="width: 30%;">


                    <select class="form-select" aria-label="Select status" [(ngModel)]="ageid"
                        (change)="ageChange($event)" matTooltip="{{toolname}}">
                        <option *ngFor="let i of ageList" value="{{i.code}}">{{i.name}}</option>
                    </select>

                </div>
            </div>
            <div class="col-sm-6">

                <div class="  mb-3">
                    <label class="form-label" *ngIf="isMobileView">Reported By</label>
                    <label class="form-label" *ngIf="!isMobileView && checkdate">Reported By</label>
                    <label *ngIf="!isMobileView && !checkdate" class="form-label">Reported By</label>
                    <ng-select class="role-select" [items]="members" bindLabel="username" placeholder="-"
                        appendTo="body" [multiple]="false" [(ngModel)]="reportedby"
                        (change)="selectMember($event)">
                    </ng-select>
                    
                    <!-- <input type="text" aria-label="textt" [matAutocomplete]="automember" [(ngModel)]="reportedby"
                        matInput class="form-control" name="reportedby" id="reportedby"
                        placeholder="john@domain.com, +959********"> -->
                    <!-- <mat-autocomplete #automember="matAutocomplete">
                        <mat-option *ngFor="let i of members | crmFilter : reportedby:searchNonea:assignedto:13"
                            (onSelectionChange)="selectMember(i.username,i.userid)"
                            (click)="selectMember(i.username,i.userid)">
                            {{i.username != '' ? i.username : ''}}&nbsp;{{i.userid}}
                        </mat-option>

                    </mat-autocomplete> -->
                </div>


            </div>
            <div class="col-sm-6" *ngIf="cusbranchid == '' ">
                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Detail</label>

                        <input type="text" aria-label="Detail" [(ngModel)]="detail" matInput class="form-control"
                            name="Detail" id="Detail" autocomplete="off">
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Description</label>

                        <input type="text" aria-label="description" [(ngModel)]="description" matInput
                            class="form-control" name="description" id="description" autocomplete="off">
                    </div>
                </div>

            </div>

        </div>





    </perfect-scrollbar>
    <div mat-dialog-actions align="end" style="padding-right: 20px;">
        <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary me-2">
            Cancel
        </button>
        <button type="button" (click)="search()" class="btn btn-custom ">
            Search
        </button>
    </div>
</div>