import { formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AbsentreportDetailsComponent } from '../absentreport-details/absentreport-details.component';
import { SubSink } from 'subsink';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-absentreport',
  templateUrl: './absentreport.component.html',
  styleUrls: ['./absentreport.component.scss'],
})
export class AbsentreportComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: any = 426;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  itemPerPage: number = 100;
  currentPage: number = 1;
  searchText: string = '';
  searchLoading: boolean = false;
  orgid: string = '';
  getMethod!: Subscription;
  getList!: Subscription;
  getMemberSub!: Subscription;

  absentList: any = [];
  organizations: any = [];
  d = new Date();
  tempweekbefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBefore = new Date(this.tempweekbefore);
  formatToShow = 'yyyy-MM-dd';
  piedateFormat = 'dd/MM/yyyy';
  maxdate: any;
  // minDate = '';
  minDate = new Date();

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  departmentList: string[] = ['All'];
  divisionList: string[] = ['All'];
  teamidList: string[] = ['All'];
  costCenterList: string[] = ['All'];
  subDivisionList: string[] = ['All'];
  gettingMemberPosition: boolean = false;
  subscriptions = new SubSink();
  openfilter: boolean = false;

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();
  tdyDate = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  method = [
    ['0', '0', '1'],
    ['1', '0', '0'],
    ['1', '0', '0'],
  ];

  activity = true;
  timeinout = true;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  // Socket
  socketConnectTime: any;
  public socketConnectionStatus: string = 'disconnected';
  private socket: WebSocket | undefined;
  connectionId: string = '';
  connectSocketLoading: boolean = true;
  public socketReceivedMessage: string = '';
  updateCId: boolean = false;
  drsocketfileurl: any = '';

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private http: HttpClient,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;

    this.maxdate = this.submitForm.enddate;
    this.setLastDat();
  }

  ngOnDestroy(): void {
    this.getMethod && this.getMethod.unsubscribe();
    this.getList && this.getList.unsubscribe();
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  toggleFilter() {
    if (
      this.departmentList.length < 2
      //  ||
      // this.divisionList.length < 2 ||
      // this.teamidList.length < 2 ||
      // this.costCenterList.length < 2 ||
      // this.subDivisionList.length < 2
    ) {
      this.getMemberPosition();
    }
    this.openfilter = !this.openfilter;
  }

  departmentChange(dep: any) {
    if (this.submitForm.department != dep) {
      this.submitForm.department = dep;
      this.submitForm.division = 'All';
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  divisionChange(divi: any) {
    if (this.submitForm.division != divi) {
      this.submitForm.division = divi;
      this.submitForm.teamid = 'All';
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  teamidChange(teamid: any) {
    if (this.submitForm.teamid != teamid) {
      this.submitForm.teamid = teamid;
      this.submitForm.costcenter = 'All';
      this.submitForm.subdivision = 'All';
    }
  }

  costCenterChange(costcenter: any) {
    if (this.submitForm.costcenter != costcenter) {
      this.submitForm.costcenter = costcenter;
      this.submitForm.subdivision = 'All';
    }
  }

  subDivisionChange(subdivision: any) {
    if (this.submitForm.subdivision != subdivision) {
      this.submitForm.subdivision = subdivision;
    }
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.getMemberSub && this.getMemberSub.unsubscribe();
    this.getMemberSub = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.departmentList = res.departmentlist;
          // this.divisionList = res.divisionlist;
          // this.teamidList = res.teamidlist;
          for (var i = 0; i < res.departmentlist.length; i++) {
            this.departmentList.push(res.departmentlist[i]);
          }
          for (var i = 0; i < res.divisionlist.length; i++) {
            this.divisionList.push(res.divisionlist[i]);
          }
          for (var i = 0; i < res.teamidlist.length; i++) {
            this.teamidList.push(res.teamidlist[i]);
          }
          for (var i = 0; i < res.costcenterlist.length; i++) {
            this.costCenterList.push(res.costcenterlist[i]);
          }
          for (var i = 0; i < res.subdivision.length; i++) {
            this.subDivisionList.push(res.subdivision[i]);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  refresh() {}

  clear() {
    this.searchText = '';
  }

  sortData(sort: Sort) {
    const data = this.absentList;
    if (!sort.active || sort.direction === '') {
      this.absentList = data;
      return;
    }
    this.absentList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      if (sort.active == 'userid') {
        return this.allinoneService.compare(a.userid, b.userid, isAsc);
      } else if (sort.active == 'employeeid') {
        return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
      } else if (sort.active == 'username') {
        return this.allinoneService.compare(a.username, b.username, isAsc);
      } else if (sort.active == 'department') {
        return this.allinoneService.compare(a.department, b.department, isAsc);
      } else if (sort.active == 'division') {
        return this.allinoneService.compare(a.division, b.division, isAsc);
      } else if (sort.active == 'teamid') {
        return this.allinoneService.compare(a.teamid, b.teamid, isAsc);
      } else if (sort.active == 'count') {
        return this.allinoneService.compare(
          Number(a.count),
          Number(b.count),
          isAsc
        );
      } else if (sort.active == 'missattendancecount') {
        return this.allinoneService.compare(
          Number(a.missattendancecount),
          Number(b.missattendancecount),
          isAsc
        );
      } else if (sort.active == 'missactivitycount') {
        return this.allinoneService.compare(
          Number(a.missactivitycount),
          Number(b.missactivitycount),
          isAsc
        );
      } else if (sort.active == 'leavecount') {
        return this.allinoneService.compare(
          Number(a.leavecount),
          Number(b.leavecount),
          isAsc
        );
      } else if (sort.active == 'strdate') {
        return this.allinoneService.compare(
          Number(a.strdate),
          Number(b.strdate),
          isAsc
        );
      }
      return 0;
    });
  }

  downloadSheet() {
    var exdata: any = [];
    var startd = formatDate(
      new Date(this.submitForm.startdate),
      this.formatToShow,
      'en-Us'
    ).toString();
    var endd = formatDate(
      new Date(this.submitForm.enddate),
      this.formatToShow,
      'en-US'
    ).toString();
    // var name = 'AbsentReport.xlsx';
    var name = 'Absent Report' + '(' + startd + '-' + endd + ').xlsx';
    var filteredData = this.filterPipe.transform(
      this.absentList,
      this.searchText,
      'absentreport'
    );
    filteredData.map((item: any) => {
      var temp = {
        'Employee ID': item.employeeid,
        'User ID': item.userid,
        Name: item.username,
        Department: item.department,
        Division: item.division,
        'Team ID': item.teamid,
        'Cost Center': item.costcenter,
        'Total Absent Days': item.count,
        'No Time In': item.missattendancecount,
        'No Activity': item.missactivitycount,
        Leave: item.leavecount,
        'Absent Date': item.strdate,
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  // setLastDat() {
  //   var date = this.submitForm.startdate;
  //   var datearr = date.split('-');
  //   var y = parseInt(datearr[0]);
  //   var month = parseInt(datearr[1]);
  //   var nd = new Date(y, month, 0);
  //   console.log(nd);
  //   const format = 'yyyy-MM-dd';
  //   this.submitForm.enddate = formatDate(
  //     new Date(nd),
  //     format,
  //     'en-US'
  //   ).toString();
  //   this.maxdate = this.submitForm.enddate;
  // }

  setLastDat() {
    var currentEndDate = formatDate(
      this.submitForm.startdate,
      this.piedateFormat,
      'en-US'
    ).toString();
    var addDays = 35;
    var date = new Date(this.submitForm.startdate);
    date.setTime(date.getTime() + addDays * 24 * 60 * 60 * 1000);
    // this.minDate = formatDate(date, this.formatToShow, 'en-US').toString();
    this.minDate = date;
    if (
      this.submitForm.enddate > this.minDate ||
      this.submitForm.enddate < this.submitForm.startdate
    ) {
      this.submitForm.enddate = this.submitForm.startdate;
    }
  }

  // Socket
  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.searchLoading = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '0003',
    };
    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.searchLoading = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      console.log('Changed data');
      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;

        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.getAbsentReportWithSocket();
      } else {
        this.drsocketfileurl = tempData.fileurl;
        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            this.absentList = [];
            res.datalist.map((x: any) => {
              var strdate = '';
              x.date.map((d: any, index: number) => {
                if (index == 0) {
                  strdate += this.allinoneService.formatDBToShowMY(d);
                } else {
                  strdate += ', ' + this.allinoneService.formatDBToShowMY(d);
                }
              });
              var temp = {
                ...x,
                strdate: strdate,
              };
              this.absentList.push(temp);
            });

            this.searchLoading = false;
            this.connectionId = '';
            this.socket?.close(3001);
            clearTimeout(this.socketConnectTime);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionId);
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  getAbsentReportWithSocket() {
    var data = {
      startdate: this.allinoneService.parseDate(this.submitForm.startdate),
      enddate: this.allinoneService.parseDate(this.submitForm.enddate),
      orgid: this.orgid,
      accesskey: 'a3VueWVrQHMzcnZpYzMtS0VZZjByYWNjZXNza2V5',
      employeelist: [],
      domain: '',
      unpaid: '',
      type: '003',
      employeeid: '',
      activity: this.activity,
      path: '',
      fromweb: true,
      timeinout: this.timeinout,
      // method: this.method,
      method: [],
      department:
        !this.openfilter || this.submitForm.department == 'All'
          ? ''
          : this.submitForm.department,
      division:
        !this.openfilter || this.submitForm.division == 'All'
          ? ''
          : this.submitForm.division,
      teamid:
        !this.openfilter || this.submitForm.teamid == 'All'
          ? ''
          : this.submitForm.teamid,
      costcenter:
        !this.openfilter || this.submitForm.costcenter == 'All'
          ? ''
          : this.submitForm.costcenter,
      subdivision:
        !this.openfilter || this.submitForm.subdivision == 'All'
          ? ''
          : this.submitForm.subdivision,
      activestatus: this.openfilter ? this.submitForm.status : '001',
      connectionid: this.connectionId,
    };
    this.getList && this.getList.unsubscribe();
    this.getList = this.kunyekService.getAbsentReport(data).subscribe(
      (res: any) => {
        // console.log(res);
        // var fileurl = res.url;
        // this.http.get(fileurl).subscribe((res: any) => {
        //   // console.log(res);
        //   // this.absentList = [];
        //   // res.datalist.map((x: any) => {
        //   //   var strdate = '';
        //   //   x.date.map((d: any, index: number) => {
        //   //     if (index == 0) {
        //   //       strdate += this.allinoneService.formatDBToShowMY(d)
        //   //     } else {
        //   //       strdate += ', ' + this.allinoneService.formatDBToShowMY(d)
        //   //     }
        //   //   });
        //   //   var temp = {
        //   //     ...x, strdate: strdate
        //   //   };
        //   //   this.absentList.push(temp)
        //   // });
        //   // console.log(this.absentList);
        //   // this.searchLoading = false;
        // });
      },
      (err: any) => {
        this.searchLoading = false;
      }
    );
  }

  search() {
    this.searchLoading = true;

    var methodData = {
      type: 'absence',
      fromdate: this.allinoneService.parseDate(this.submitForm.startdate),
      todate: this.allinoneService.parseDate(this.submitForm.enddate),
      key: 'DA8n3pB5DC39w1lF5B7B52dA2C68D',
      domain: this.orgid,
    };

    this.kunyekService.getAbsentMethod(methodData).subscribe(
      (res: any) => {
        if (res.statuscode == '300') {
          this.method = res.data;
          this.activity = res.activity;
          this.timeinout = res.timeinout;
          this.connectWebSocket();
        } else {
          this.searchLoading = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.searchLoading = false;
      }
    );
  }

  // search() {
  //   this.searchLoading = true;

  //   var methodData = {
  //     type: 'absence',
  //     fromdate: this.allinoneService.formatDate(this.submitForm.startdate),
  //     todate: this.allinoneService.formatDate(this.submitForm.enddate),
  //     key: 'DA8n3pB5DC39w1lF5B7B52dA2C68D',
  //     domain : this.orgid
  //   };

  //   this.kunyekService.getAbsentMethod(methodData).subscribe(
  //     (res: any) => {
  //       if (res.statuscode == '300') {
  //         this.method = res.data;
  //         var activity = res.activity || true;
  //         var timeinout = res.timeinout || true;
  //         var data = {
  //           startdate: this.allinoneService.formatDate(
  //             this.submitForm.startdate
  //           ),
  //           enddate: this.allinoneService.formatDate(this.submitForm.enddate),
  //           orgid: this.orgid,
  //           accesskey: 'a3VueWVrQHMzcnZpYzMtS0VZZjByYWNjZXNza2V5',
  //           employeelist: [],
  //           domain: '',
  //           unpaid: '',
  //           type: '003',
  //           employeeid: '',
  //           activity: activity,
  //           path : '',
  //           fromweb:true,
  //           timeinout: timeinout,
  //           method: this.method,
  //           department : this.submitForm.department == 'All' ? '' : this.submitForm.department ,
  //           division : this.submitForm.division == 'All' ? '' : this.submitForm.division,
  //           teamid : this.submitForm.teamid == 'All' ? '' : this.submitForm.teamid,
  //           costcenter : this.submitForm.costcenter == 'All' ? '' : this.submitForm.costcenter,
  //         };

  //         this.getList && this.getList.unsubscribe();
  //         this.getList = this.kunyekService.getAbsentReport(data).subscribe(
  //           (res: any) => {
  //             console.log(res);
  //             var fileurl = res.url;
  //             this.http.get(fileurl).subscribe(
  //               (res:any) => {
  //                 console.log(res);
  //                 this.absentList = [];
  //               res.datalist.map((x:any) => {
  //                 var strdate = '';
  //                 x.date.map((d : any, index : number) => {
  //                   if(index == 0) {
  //                     strdate += this.allinoneService.formatDBToShowMY(d)
  //                   } else {
  //                     strdate += ', ' + this.allinoneService.formatDBToShowMY(d)
  //                   }
  //                 });
  //                 var temp = {
  //                   ...x, strdate : strdate
  //                 };
  //                 this.absentList.push(temp)
  //               });
  //               console.log(this.absentList);
  //               this.searchLoading = false;
  //               }
  //             )
  //           },
  //           (err: any) => {
  //             this.searchLoading = false;
  //           }
  //         );
  //       } else {
  //         this.searchLoading = false;
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //     },
  //     (err) => {
  //       this.searchLoading = false;
  //     }
  //   );
  // // search2() {
  // //   this.searchLoading = true;

  // //   var methodData = {
  // //     type: 'absence',
  // //     fromdate: this.allinoneService.formatDate(this.submitForm.startdate),
  // //     todate: this.allinoneService.formatDate(this.submitForm.enddate),
  // //     key: 'DA8n3pB5DC39w1lF5B7B52dA2C68D',
  // //   };

  // //   this.kunyekService.getAbsentMethod(methodData).subscribe(
  // //     (res: any) => {
  // //       if (res.statuscode == '300') {
  // //         this.method = res.data;
  // //         var data = {
  // //           startdate: this.allinoneService.formatDate(
  // //             this.submitForm.startdate
  // //           ),
  // //           enddate: this.allinoneService.formatDate(this.submitForm.enddate),
  // //           orgid: this.orgid,
  // //           accesskey: 'a3VueWVrQHMzcnZpYzMtS0VZZjByYWNjZXNza2V5',
  // //           employeelist: [],
  // //           domain: 'mit001',
  // //           unpaid: '',
  // //           type: '002',
  // //           employeeid: '',
  // //           method: this.method,
  // //           department : this.submitForm.department == 'All' ? '' : this.submitForm.department ,
  // //           division : this.submitForm.division == 'All' ? '' : this.submitForm.division,
  // //           teamid : this.submitForm.teamid == 'All' ? '' : this.submitForm.teamid
  // //         };

  // //         this.getList && this.getList.unsubscribe();
  // //         this.getList = this.kunyekService.getAbsentReport(data).subscribe(
  // //           (res: any) => {
  // //             console.log(res);
  // //             if (res.returncode == '300') {
  // //               this.absentList = [];
  // //               res.datalist.map((x:any) => {
  // //                 var strdate = '';
  // //                 x.date.map((d : any, index : number) => {
  // //                   if(index == 0) {
  // //                     strdate += this.allinoneService.formatDBToShowMY(d)
  // //                   } else {
  // //                     strdate += ', ' + this.allinoneService.formatDBToShowMY(d)
  // //                   }
  // //                 });
  // //                 var temp = {
  // //                   ...x, strdate : strdate
  // //                 };
  // //                 this.absentList.push(temp)
  // //               });
  // //               console.log(this.absentList);

  // //             } else {
  // //             }
  // //             this.searchLoading = false;
  // //           },
  // //           (err: any) => {
  // //             this.searchLoading = false;
  // //           }
  // //         );
  // //       } else {
  // //         this.searchLoading = false;
  // //         this.messageService.openSnackBar(
  // //           res.message || res.status || 'Server Error',
  // //           'warn'
  // //         );
  // //       }
  // //     },
  // //     (err) => {
  // //       this.searchLoading = false;
  // //     }
  // //   );

  //   // var data = {
  //   //   startdate: this.allinoneService.formatDate(this.submitForm.startdate),
  //   //   enddate: this.allinoneService.formatDate(this.submitForm.enddate),
  //   //   orgid: this.orgid,
  //   //   accesskey: "a3VueWVrQHMzcnZpYzMtS0VZZjByYWNjZXNza2V5",
  //   //   employeelist: [],
  //   //   domain: "mit001",
  //   //   unpaid: "",
  //   //   "type": "002",
  //   //   "employeeid": "",
  //   //   "method": this.method
  //   // }
  //   // console.log(data);

  //   // this.kunyekService.getAbsentReport(data).subscribe(
  //   //   (res: any) => {
  //   //     console.log(res);
  //   //     if(res.returncode == '300') {

  //   //     } else {

  //   //     }
  //   //     this.searchLoading = false;
  //   //   },
  //   //   (err :any) => {
  //   //     this.searchLoading = false;
  //   //   }
  //   // )
  // }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  userStatusFilterList = this.allinoneService.userStatusFilterList;

  submitForm = {
    startdate: this.aweekBefore,
    enddate: new Date(Date.now()),
    department: 'All',
    division: 'All',
    teamid: 'All',
    costcenter: 'All',
    subdivision: 'All',
    status: '001',
  };

  viewDetails(data: any) {
    const dialog = this.dialog.open(AbsentreportDetailsComponent, {
      maxWidth: '95vw',
      width: '800px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        data: data,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  // viewDetails(data:any) {
  //   console.log(data);

  //   this.router.navigateByUrl("/hxm/absentreportdetails/" + data.userid)
  // }
}
