import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';

import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { iif } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-service-overage-report',
  templateUrl: './service-overage-report.component.html',
  styleUrls: ['./service-overage-report.component.css']
})
export class ServiceOverageReportComponent implements OnInit {
  rowlist: any = [

  ];
  columnlist: any = [

  ];
  gettingData: boolean = false;
  enddate: any = "";
  startdate: any = "";
  formatToShow = 'yyyy-MM-dd';
  orgid: any = "";
  organizations: any = [];
  productid: any = "";
  formatToShowServcie = "MM/dd/yyyy";

  productListdata: any = [];
  productname: any = 'product'
  productsearch: any = '';


  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    var threemonthdate = new Date();

    threemonthdate.setMonth(threemonthdate.getMonth() - 2);
    this.startdate = formatDate(threemonthdate, this.formatToShow, 'en-US').toString();

    this.enddate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.getServiceOverage();
  }

  refreshgetData() {
    this.getServiceOverage();

  }

  removeProduct() {

    this.productsearch = '';
  }

  // changeproduct(data: any) {
  //   if (data == 'product') {
  //     this.productid = '';
  //     this.productname = 'product';
      
  //     this.getServiceOverage();
  //   } else {
  //     this.productid = data.productid
  //     this.productsmid = data.productid

  //     this.router.navigate([], {
  //       relativeTo: this.route,
  //       queryParams: {
  //         'productid': this.productid,
  //       },
  //       queryParamsHandling: 'merge',
  //     });
  //     this.productname = data.name;
  //     this.getSummary();
  //   }
  // }


  getServiceOverage() {
    this.gettingData = true;
    this.columnlist = [];
    this.rowlist = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    console.log(data)
    this.kunyekService.getServiceOveragereport(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.columnlist = res.columnlist;
          this.rowlist = res.rowlist;
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  
  downloadSheet() {



    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Service_Overage_Report' + time + '.xlsx';

    console.log(this.rowlist); 
    for (let j = 0; j < this.rowlist.length; j++) {
      var tmpdata: any = {
        "Product Name": this.rowlist[j]['productname']
      };
      for (let k = 0; k < this.rowlist[j]['countlist'].length; k++) {
          var columnname = this.rowlist[j]['countlist'][k]['columnname'];
          tmpdata[columnname] = this.rowlist[j]['countlist'][k]['count'];
      }
      data.push(tmpdata)
    }

    console.log(data);
    // this.serviceMonthlyList.map((car: any) => {
    //   data.push({
    //     "Product Name": car.productname,
    //     "Product": car.count,
    //   });
    // });

    this.allinoneService.exportEcecl(data, name);

  }
  Open(data: any, productid: any) {
    this.productid = productid;
    var typecode = data.type;
    var priorityid = data.priority;
    var count = data.count;
    var age = data.age;
    console.log(data.count);
    if (data.count > 0) {
      if (data.total == true) {
        this.router.navigateByUrl(`service/service-tickets?productid=${this.productid}&q=${typecode}&priorityid=${priorityid}&checkoverage=true&age=${age}`);
      } else {
       
        this.router.navigateByUrl(`service/service-tickets?productid=${this.productid}&q=${typecode}&priorityid=${priorityid}&checkoverage=true&age=${age}`);
      }


    }


  }



}
