import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

type PeriodForm = {
  description: string;
  startdate: any;
  enddate: any;
  month: string;
  year: string;
};
@Component({
  selector: 'app-pay-period-form',
  templateUrl: './pay-period-form.component.html',
  styleUrls: ['./pay-period-form.component.css'],
})
export class PayPeriodFormComponent implements OnInit {
  submitted: boolean = false;
  generalDatePickerConfig: any = this.allInOneService.datePickerConfig;
  @Output('closeForm') clsoeForm = new EventEmitter<any>();
  @Input() editData: PeriodForm | null = null;
  saveLoading: boolean = false;
  periodForm: PeriodForm = {
    description: '',
    month: '',
    year: '',
    startdate: '',
    enddate: '',
  };

  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(
    private kunyekService: KunyekService,
    private allInOneService: AllInOneService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    if (this.editData) {
      this.periodForm = { ...this.editData }; //copy editdata obj
      this.periodForm.startdate = new Date(
        this.formatDataForInput(this.editData.startdate)
      );
      this.periodForm.enddate = new Date(
        this.formatDataForInput(this.editData.enddate)
      );
      this.periodForm.month = this.getMonth(this.editData.month);
    } else {
      this.bindDefaultData();
    }
  }

  handleSave() {
    if (this.saveLoading) return;
    this.submitted = true;
    if (
      this.periodForm.description == '' ||
      this.periodForm.enddate == '' ||
      this.periodForm.month == '' ||
      this.periodForm.startdate == '' ||
      this.periodForm.year == ''
    )
      return;
    const data = {
      orgid: this.allInOneService.getSelectedOrg(),
      ...this.periodForm,
    };
    data.year = this.getYear(data.year);
    data.month = this.getMonthByString(data.month);
    data.startdate = this.fomratDate(data.startdate);
    data.enddate = this.fomratDate(data.enddate);
    console.log(data);
    this.saveLoading = true;
    if (this.editData) {
      this.kunyekService.updatePeroid(data).subscribe({
        next: (res) => {
          if (res.returncode == '300') {
            this.saveLoading = false;
            this.messageService.openSnackBar('Update Scueessfully', 'success');
            this.closeEdit();
          } else {
            throw res.message;
          }
        },
        error: (error) => {
          this.saveLoading = false;
          this.messageService.openSnackBar('Server Error', 'warn');
          console.log(error);
        },
      });
    } else {
      this.kunyekService.addPeroid(data).subscribe({
        next: (res) => {
          if (res.returncode == '300') {
            this.saveLoading = false;
            this.messageService.openSnackBar('Create Scueessfully', 'success');
            this.closeEdit();
          } else {
            throw res.message;
          }
        },
        error: (error) => {
          this.saveLoading = false;
          this.messageService.openSnackBar('Server Error', 'warn');
          console.log(error);
        },
      });
    }
  }

  bindDefaultData() {
    const today = new Date();
    const year = today.getFullYear();
    const month = moment(today).format('MMMM');
    this.periodForm.year = year.toString();
    this.periodForm.month = month;
    console.log('year', year, month);
  }

  closeEdit() {
    console.log('hello');
    this.clsoeForm.emit();
  }

  getMonth(value: string) {
    if (value == '') return;
    const mapping: any = {
      '10': 'October',
      '11': 'November',
      '12': 'December',
      '01': 'January',
      '02': 'February',
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July',
      '08': 'August',
      '09': 'September',
    };
    return mapping[value] ? mapping[value] : '';
  }

  getMonthByString(value: string) {
    if (value == '') return;
    const mapping: any = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };
    return mapping[value] ? mapping[value] : '';
  }

  fomratDate(value: string): string {
    if (value === '') return '';
    const pattern = 'yyyyMMdd';
    const date = formatDate(new Date(value), pattern, 'en-US');
    return date;
  }

  getYear(value: string) {
    if (value == '') return '';
    const date = new Date(value);
    return date.getFullYear().toString();
  }

  formatDataForInput(value: string) {
    if (value === '') return '';
    var parts = value.split('/');
    var day = parts[0];
    var month = parts[1];
    var year = parts[2];
    return `${year}-${month}-${day}`;
  }
}
