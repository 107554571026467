import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  menus: any[] = [];
  gettingApprovalCount: boolean = false;

  approvalCount: number = 0;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService
  ) {
    this.allinoneService.isCRMServiceView = false;
    this.allinoneService.isCustomerCare = false;
  }

  ngOnInit(): void {
    if (this.allinoneService.menus) {
      this.menus = this.allinoneService.menus.admins;
      console.log(this.menus);
    }
    // if(this.allinoneService.isAdminOf('org')){
    //   this.getApprovalCount();
    // }
  }
  callFromWhere(role: any) {
    sessionStorage.removeItem('callType')
    sessionStorage.setItem('callType', role)
  }

  isArrayFun(data:any){
    return Array.isArray(data)
  }
  getApprovalCount() {
    this.gettingApprovalCount = true;
    var data = {
      orgid: this.allinoneService.selectedOrg,
      roleid: '',
      byadmin: true
    };
    this.kunyekService.getApprovalCount(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.approvalCount = res.approval;
        this.gettingApprovalCount = false;
      }
    });
  }

}
