import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { IMyDpOptions } from 'mydatepicker';


function numericStringValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isValid = typeof value === 'string' && /^[0-9]*$/.test(value);
    return isValid ? null : { numericString: { value: control.value } };
  };
}
@Component({
  selector: 'app-summer-swimmer-detail',
  templateUrl: './summer-swimmer-detail.component.html',
  styleUrls: ['./summer-swimmer-detail.component.scss']
})
export class SummerSwimmerDetailComponent implements OnInit {

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy'
  };

  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  isSaving: boolean = false;
  swimForm!: FormGroup;
  saved = false
  id!: string;
  requiredMessage = 'This field must not be empty'
  gettingNrcCode = false

  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];
  batchList: any = [];
  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];
  breadcrumbs: any[] = [{ name: 'Swim', url: 'swim', isActive: true }, { name: 'Summer Swimmer', url: 'members/summerswimmer', isActive: true }, { name: '', url: '', isActive: false }];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '')
    this.swimForm = this.fb.group({
      syskey: ['', Validators.required],
      batch: ['', Validators.required],
      myanName: ['', Validators.required],
      engName: ['',],
      kscNo: [''],
      dateOfBirth: ['', Validators.required],
      height: ['', [Validators.required, numericStringValidator()]],
      nrcregioncode: ["", Validators.required],
      nrctownshipcode: ["", Validators.required],
      nrctype: ["", Validators.required],
      nrcnumber: ["", Validators.required],
      race: ['',],
      religion: [''],
      education: ['',],
      occupation: ['',],
      fatherName: ['', Validators.required],
      motherName: ['', Validators.required],
      address: [''],
      region: ['',],
      town: ['',],
      ward: ['',],
      contactNo: ['', [Validators.required, numericStringValidator()]]
    });
    this.getOneSummerSwimmer()
    this.getNrcCode()
    this.getBatch()
    this.getRegion()
  }
  getOneSummerSwimmer() {
    this.kunyek.summerSwimMember().get({ id: this.id }).subscribe({
      next: (res) => {
        const data = res.data;
        if (data) {
          this.swimForm.patchValue({
            syskey: data.syskey,
            batch: data.batchsyskey,
            myanName: data.nameinburmese,
            engName: data.nameinenglish,
            kscNo: data.kscno,
            dateOfBirth: this.allInOneService.changeStringToDateobject(data.birthdate),
            height: data.height.replace('cm', ''),
            nrcregioncode: data.nrcregioncode,
            nrctownshipcode: data.nrctownshipcode,
            nrctype: data.nrctype,
            nrcnumber: data.nrcnumber,
            race: data.race,
            religion: data.religion,
            education: data.education,
            occupation: data.occupation,
            fatherName: data.fathername,
            motherName: data.mothername,
            address: data.address,
            contactNo: data.phone,
            region: data.directory?.region,
            town: data.directory?.town,
            ward: data.directory?.postcode,
          });

          this.profileImageSrc = data.profileimage || this.profileImageSrc;
          this.breadcrumbs[2].name = data.nameinburmese
          if (data.directory?.region && data.directory?.town) {
            this.onRegionChange({ target: { value: data?.directory?.region } });
            this.onTownChange({ target: { value: data?.directory?.town } });
          }
        }
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to get member detail', 'warn');
      }
    });
  }



  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
    };
    this.kunyek.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }

  getBatch() {
    const data = {
      "searchName": "Summer Swim"
    };
    this.kunyek.getBatch().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.batchList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getRegion() {
    const data = {};
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  onProfileClick() {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', (event: Event) => {
      this.onImageChange(event);
    });
    input.click();
  }

  private onImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => this.profileImageSrc = reader.result as string;
      reader.readAsDataURL(file);
    }
  }
  onSaved() {
    this.isSaving = true;
    this.saved = true;
    const formattedBirthDate = this.dateOfBirth?.value?.formatted || this.dateOfBirth?.value;
    const formData = {
      syskey: this.syskey?.value,
      batchsyskey: this.batch?.value,
      nameinburmese: this.myanName?.value,
      nameinenglish: this.engName?.value,
      kscno: this.kscNo?.value,
      birthdate: this.allInOneService.convertDate(formattedBirthDate),
      height: this.height?.value + 'cm',
      nrcregioncode: this.nrcregioncode?.value,
      nrctownshipcode: this.nrctownshipcode?.value,
      nrctype: this.nrctype?.value,
      nrcnumber: this.nrcnumber?.value,
      race: this.race?.value,
      religion: this.religion?.value,
      education: this.education?.value,
      occupation: this.occupation?.value,
      fathername: this.fatherName?.value,
      mothername: this.motherName?.value,
      address: this.address?.value,
      phone: this.contactNo?.value,
      nrc: this.nrcregioncode?.value + '/' + this.nrctownshipcode?.value +
        '(' + this.nrctype?.value + ')' +
        this.nrcnumber?.value,
      postcode: this.ward?.value
    };




    if (this.swimForm.valid) {
      this.kunyek.summerSwimMember().update(formData).subscribe({
        next: (res) => {
          switch (res.returncode) {
            case "301":
              this.isSaving = false
              this.messageService.openSnackBar(res.message, 'warn')
              break;
            default:
              this.isSaving = false;
              this.messageService.openSnackBar('Success!', 'success')
              this.closeForm();
          }
        },
        error: (err) => {
          console.error('Error saving member:', err);
          this.messageService.openSnackBar('Failed to save member', 'warn');
          this.isSaving = false;
        }
      });
    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false;
    }
  }

  closeForm() {
    this.router.navigate(['members', 'summerswimmer']);
  }

  goBackToList() {
    this.router.navigateByUrl('members/swimmerswimmer');
  }

  get batch() { return this.swimForm.get('batch'); }
  get myanName() { return this.swimForm.get('myanName'); }
  get engName() { return this.swimForm.get('engName'); }
  get kscNo() { return this.swimForm.get('kscNo'); }
  get dateOfBirth() { return this.swimForm.get('dateOfBirth'); }
  get height() { return this.swimForm.get('height'); }
  get race() { return this.swimForm.get('race'); }
  get religion() { return this.swimForm.get('race'); }
  get education() { return this.swimForm.get('education'); }
  get occupation() { return this.swimForm.get('occupation'); }
  get fatherName() { return this.swimForm.get('fatherName'); }
  get motherName() { return this.swimForm.get('motherName'); }
  get address() { return this.swimForm.get('address'); }
  get contactNo() { return this.swimForm.get('contactNo'); }
  get nrcregioncode() { return this.swimForm.get('nrcregioncode'); }
  get nrctownshipcode() { return this.swimForm.get('nrctownshipcode'); }
  get nrctype() { return this.swimForm.get('nrctype'); }
  get nrcnumber() { return this.swimForm.get('nrcnumber'); }
  get syskey() { return this.swimForm.get('syskey'); }
  get region() { return this.swimForm.get('region'); }
  get town() { return this.swimForm.get('town'); }
  get ward() { return this.swimForm.get('ward'); }

}
