<div class="container-fluid">
    <div class = "content card">
        <div class = "titlep">
            <div style="border-radius: 50%;">
                <img src="../../../assets/images/neovision.png" width="80px" >
            </div>
            <div class = "titlediv ms-1">
                <div class = "title">
                    PRIVACY POLICY
                </div>
            </div>
        </div>
        <div class="eula-body">
           
            <div class = "mb-2 subtitle">
                Welcome to Neo Vision!
            </div>
            <p>
                Neo Vision mission is to help those who are disabilities in vision. Our app can listen voice command and can read email. Protecting your privacy is fundamental to our mission and business. The following summarize our promises to you.
            </p>

            <ul>
                <li class = "sublist">We never sell your data or student data. </li>
                <li class = "sublist">We never advertise in Neo Vision. </li>
                <li class = "sublist">We are transparent about our practices and will notify you if things change. </li>                
            </ul>
           
            <div class = "mt-3 mb-2 subtitle">
                Security
            </div>
            <p>
                We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
            </p>
            <div class = "mt-3 mb-2 subtitle">
                Links to Other Sites
            </div>
            <p>
                This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. we have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
            </p>
            <div class = "mt-3 mb-2 subtitle">
                Children’s Privacy
            </div>
            <p>
                These Services do not address anyone under the age of 13. we do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
            </p>
            <div class = "mt-3 mb-2 subtitle">
                Changes to This Privacy Policy
            </div>
            <p>
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. we will notify you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
                This policy is effective as of Aug 20, 2021.
            </p>
            <div class = "mt-3 mb-2 subtitle">
                Contact Us
            </div>
            <p>
                By using Neo Vision, you agree to this Privacy Policy. If you don’t agree, please don’t use Neo Vision. You can contact us anytime with questions about this policy at <a href="">neovision@nirvasoft.com</a>.
            </p>
        </div>
    </div>
</div>
