<div class="qronly">
    <div *ngIf = "qrLoading" class="m-auto">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <ngx-qrcode *ngIf = "!qrLoading"  qrc-element-type="canvas" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="qrvalue"
        cssClass="bshadow"></ngx-qrcode>
    <button  *ngIf = "!qrLoading" type = "button" class = "btn btn-link" (click)="downloadQRCode()">Save QR</button>
</div>

<div class="close-btn">
    <button type="button" class="btn btn-custom " mat-dialog-close>Close</button>
</div>