import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';


@Component({
  selector: 'app-special-swim-form',
  templateUrl: './special-swim-form.component.html',
  styleUrls: ['./special-swim-form.component.scss']
})
export class SpecialSwimFormComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  isSaving: boolean = false;
  saved: boolean = false;
  swimForm!: FormGroup;
  requiredMessage = 'This field must not be empty';
  selectedProfileImage?: File;
  tempProfileImage: any;
  profileUrl: any;
  requestBodyData: any;
  tempfilename: any = "";
  breadcrumbs: any[] = [];
  batchList: any = [];
  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.swimForm = this.fb.group({
      name: ['', Validators.required],
      fathername: [''],
      dateofbirth: ['', Validators.required],
      height: ['', Validators.required],
      membershipnumber: ['', Validators.required],
      contactno: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      batch: ['', Validators.required],
      teacher: ['', Validators.required],
      studentnumber: ['', Validators.required],
      address: [''],
      region: ['',],
      town: ['',],
      ward: ['',]
    });
    this.setBreadcrumbs()
    this.getBatch()
    this.getRegion()
  }
  setBreadcrumbs() {
    if (this.router.url === '/members/specialswim/new') {
      this.breadcrumbs[0] = { name: 'Swim', url: 'swim', isActive: true }
      this.breadcrumbs[1] = { name: 'Special Swimmer', url: 'members/specialswim', isActive: true }
      this.breadcrumbs[2] = { name: 'New', url: '', isActive: false }
    } else {
      this.breadcrumbs[0] = { name: 'Members', url: '', isActive: true }
      this.breadcrumbs[1] = { name: 'Special Swimmmer', url: '', isActive: false }
    }
  }

  getBatch() {
    const data = {
      "searchName": "Special Swim"
    };
    this.kunyekService.getBatch().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.batchList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  getRegion() {
    const data = {};
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  onProfileClick() {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', (event: Event) => {
      this.onImageChange(event);
    });
    input.click();
  }

  private onImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedProfileImage = file;
      const reader = new FileReader();
      reader.onload = e => this.profileImageSrc = reader.result as string;
      reader.readAsDataURL(file);
    }
  }

  async onSave() {
    this.isSaving = true;
    if (this.swimForm.valid) {
      await this.uploadFiles();
      const formattedBirthDate = this.dateofbirth?.value?.formatted;
      const formData = {
        name: this.swimForm.value.name,
        fathername: this.swimForm.value.fathername,
        dateofbirth: this.allInOneservice.convertDate(formattedBirthDate),
        height: this.swimForm.value.height + 'cm',
        membershipnumber: this.swimForm.value.membershipnumber,
        contactno: this.swimForm.value.contactno,
        batchsyskey: this.swimForm.value.batch,
        teacher: this.swimForm.value.teacher,
        studentnumber: this.swimForm.value.studentnumber,
        address: this.swimForm.value.address,
        postcode: this.swimForm.value.ward,
        tempfilename: this.tempfilename,
        profileimage: this.selectedProfileImage?.name,
      };
      this.kunyekService.createSpecialSwimmer(formData).subscribe(
        (res: any) => {
          this.isSaving = false;
          if (res.returncode === '300') {
            this.messageService.openSnackBar(res.message, 'success');
            this.closeForm();
          } else if (res.returncode === '301') {
            this.messageService.openSnackBar("User already exists with this phone number or email", "warn");
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.messageService.openSnackBar(err, 'warn');
          this.isSaving = false;
        }
      );
    } else {
      this.saved = true;
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false
      this.swimForm.markAllAsTouched();
    }
  }

  closeForm() {
    if (this.router.url === '/members/special-swim-form') {
      this.router.navigate(['members'])
    } else {

      this.router.navigate(['members', 'specialswim']);
    }
  }

  async uploadFiles() {
    const defaultpath = 'kokaimember/members';

    if (!this.tempfilename) {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    }

    if (this.selectedProfileImage) {
      const profilepath = `${defaultpath}/${this.tempfilename}/${this.selectedProfileImage.name}`;
      const upload_aws = await this.allInOneservice.uploadAnswerFile(this.selectedProfileImage, profilepath);
      if (!upload_aws) {
        this.messageService.openSnackBar('Unknown error occurred while uploading your files.', 'warn');
        return;
      }
      this.tempProfileImage = profilepath;
    }
  }

  get name() { return this.swimForm.get('name'); }
  get fathername() { return this.swimForm.get('fathername'); }
  get dateofbirth() { return this.swimForm.get('dateofbirth'); }
  get height() { return this.swimForm.get('height'); }
  get membershipnumber() { return this.swimForm.get('membershipnumber'); }
  get contactno() { return this.swimForm.get('contactno'); }
  get batch() { return this.swimForm.get('batch'); }
  get teacher() { return this.swimForm.get('teacher'); }
  get studentnumber() { return this.swimForm.get('studentnumber'); }
  get address() { return this.swimForm.get('address'); }
  get region() { return this.swimForm.get('region'); }
  get town() { return this.swimForm.get('town'); }
  get ward() { return this.swimForm.get('ward'); }
}
