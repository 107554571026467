import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-calcuate-brought-forward',
  templateUrl: './calcuate-brought-forward.component.html',
  styleUrls: ['./calcuate-brought-forward.component.scss'],
})
export class CalcuateBroughtForwardComponent implements OnInit {
  calendarList: any[] = [];

  calendarid: string = '';
  orgid: string = '';
  currentYear: any;

  isCalculating: boolean = false;

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CalcuateBroughtForwardComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.calendarList = data.calendarList;
    this.currentYear = data.currentYear;
    this.orgid = data.orgid;
  }

  ngOnInit(): void {
    this.calendarList = this.calendarList.filter(
      (cal: any) => !cal.active || !cal.valid
    );
    if (this.calendarList.length > 0) {
      this.calendarid = this.calendarList[0].calendarid;
    }
  }

  calculate() {
    if (this.isCalculating) {
      return;
    }
    if (this.calendarid == '') {
      return this.messageService.openSnackBar(
        'Please select a calendar year.',
        'warn'
      );
    }
    if (!this.currentYear.year) {
      return this.messageService.openSnackBar(
        'Invalid current calendar year.',
        'warn'
      );
    }

    const data = {
      orgid: this.orgid,
      oldcalendarid: this.calendarid,
      currentcalendarid: this.currentYear.year,
    };
    console.log(data);
    this.dialogRef.disableClose = true;
    this.isCalculating = true;
    this.kunyekService.calculateBroughtForward(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dialogRef.close();
          this.messageService.openSnackBar(
            'Brought forward calculated successfully.',
            'success'
          );
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.dialogRef.disableClose = false;
        this.isCalculating = false;
      },
      (err) => {
        this.dialogRef.disableClose = false;
        this.isCalculating = false;
      }
    );
  }
}
