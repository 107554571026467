import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-voting',
  templateUrl: './voting.component.html',
  styleUrls: ['./voting.component.css'],
})
export class VotingComponent implements OnInit {
  cid: string = '';
  roundData: any = '';
  username: any = '';

  votelimit: number = 0;
  currentVote: number = 0;
  votertype: string = '';

  gettingVR: boolean = false;
  isError: boolean = false;
  noRoundError: boolean = false;
  reachedVoteLimit: boolean = false;
  savingVotes: boolean = false;
  isFinish: boolean = false;
  isProxy : boolean = false;
  candidateList: any[] = [];
  selectedProxy = {
    'proxyid' : '',
    'proxyname' : '',
  }

  testList = [
    { userid: '1', username: 'Htet Wai Yan', checked: false },
    { userid: '2', username: 'Htoo Wai Yan', checked: false },
    { userid: '3', username: 'Min Han Kyaw', checked: false },
    { userid: '4', username: 'Chit Oo Naung', checked: false },
    { userid: '5', username: 'Zay Min Khant', checked: false },
    { userid: '6', username: 'Pyae Sone Aung', checked: false },
  ];

  
  curRound = 0;

  roundList: any[] = [];
  proxyList: any[] = [];
  camdesc = '';

  constructor(
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    const temp_cid = this.route.snapshot.paramMap.get('roundid');
    if (temp_cid) {
      this.cid = decodeURIComponent(temp_cid);
    }
    if (!this.allinoneService.getVotingToken()) {
      const camid = encodeURIComponent(this.cid);
      this.router.navigateByUrl(`/voting/${camid}`);
    }
    else{
      this.proxyList = this.allinoneService.getVotingProxyList()
    }

    this.allinoneService.navbar = false;
  }

  ngOnDestroy() {
    this.allinoneService.navbar = true;
  }

  ngOnInit(): void {
    const tempUsername = this.allinoneService.getVotingUsername();
    this.username = tempUsername
      ? tempUsername
      : this.allinoneService.getVotingUserid();
    this.selectedProxy.proxyname = this.username
    this.getVotingRound();
  }

  changeVal(event:any,val:any){
    this.selectedProxy = {
      'proxyid' : val.proxyid,
      'proxyname' : val.proxyname
    }
    console.log(this.selectedProxy)
  }
  newgetVotingRound() {
    var res = this.roundList[this.curRound];
    this.roundData = res;
    this.votelimit = parseInt(res.votelimit);
    this.votertype = res.votertype;
    this.candidateList = res.candidates.map((item: any) => ({
      ...item,
      checked: false,
    }));
    this.gettingVR = false;
  }

  nextRound() {
    var lgh = this.roundList.length - 1;

    if (this.curRound < lgh) {
      this.curRound += 1;
      if(this.isProxy){
        this.selectedProxy = {
          'proxyid': this.proxyList[0].proxyid,
          'proxyname' : this.proxyList[0].proxyname
        }
      }
    }

    this.votelimit = 0;
    this.currentVote = 0;
    this.savingVotes = false;
    this.newgetVotingRound();
  }

  previousRound() {
    var lgh = this.roundList.length;
    // console.log("P::" + this.curRound + "::" + lgh);
    if (this.curRound < lgh && this.curRound != 0) {
      
      if(this.isProxy){
        this.selectedProxy = {
          'proxyid': this.proxyList[0].proxyid,
          'proxyname' : this.proxyList[0].proxyname
        }
      }

      this.curRound -= 1;
      this.votelimit = 0;
      this.currentVote = 0;
      this.savingVotes = false;
      this.newgetVotingRound();
    }
  }

  getVotingRound() {
    this.gettingVR = true;
    const data = {
      token: this.allinoneService.getVotingToken(),
      userid: this.allinoneService.getVotingUserid(),
      camid: this.cid,
    };
    this.kunyekService.getVotingRound(data).subscribe(
      (response: any) => {
        if (response.returncode == '300') {
          this.camdesc = response.camdesc;
          this.roundList = response.datalist;
          var res = this.roundList[this.curRound];
          this.roundData = res;
          this.votelimit = parseInt(res.votelimit);
          this.votertype = res.votertype;
          this.candidateList = res.candidates.map((item: any) => ({
            ...item,
            checked: false,
          }));
          
          if ( this.proxyList.length ==  0){
            this.isProxy = false;
          }else{
            
            this.proxyList.splice(0, 0, {'proxyid':'','proxyname':this.username});
            this.isProxy = true;
          }


          this.gettingVR = false;
        } else {
          this.isError = true;
          this.gettingVR = false;
          if (response.returncode == '240') {
            this.noRoundError = true;
          }
        }
      },
      (err) => {
        this.isError = true;
        this.gettingVR = false;
      }
    );
  }

  reload() {
    window.location.reload();
  }

  vote(index: number) {
    if (!this.candidateList[index].checked) {
      if (this.currentVote < this.votelimit) {
        this.candidateList[index].checked = true;
        this.currentVote += 1;
      } else {
        this.messageService.openSnackBar(
          'You have reached the vote limit.',
          'warn'
        );
      }
    } else {
      this.currentVote -= 1;
      this.candidateList[index].checked = false;
    }
  }

  confirmDialog() {
    if (this.currentVote < this.votelimit) {
      return;
    }
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      minWidth: '300px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        cmessage: 'Vote for ' + this.roundData.rounddesc + '?',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.saveVote();
      }
    });
  }
  saveVote() {
    this.savingVotes = true;

    var votelist: any[] = [];
    this.candidateList.map((item: any) => {
      if (item.checked) {
        votelist.push(item.candidateid);
      }
    });
    const data = {
      userid: this.allinoneService.getVotingUserid(),
      token: this.allinoneService.getVotingToken(),
      roundid: this.roundData.roundid,
      votelist: votelist,
      username: this.allinoneService.getVotingUsername(),
      proxyid : this.selectedProxy.proxyid
    };
    this.kunyekService.saveVotes(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar(
            this.selectedProxy.proxyname + ' has successfully voted.',
            'vote-success'
          );

          this.savingVotes = false;
          if(this.isProxy){
            this.nextProxy()
          }else{
            this.nextRound()
          }
          this.candidateList.map((item: any) => {
            item.checked = false
          })
          this.currentVote = 0
        } else {
          this.savingVotes = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.savingVotes = false;
        this.isError = true;
      }
    );
  }

  back() {
    this.allinoneService.setCookie('voting_userid', '');
    this.allinoneService.setCookie('voting_username', '');
    this.allinoneService.setCookie('voting_atoken', '');
    this.allinoneService.setCookie('voting_proxy' , '')
    const camid = encodeURIComponent(this.cid);
    this.router.navigateByUrl(`/voting/${camid}`);
  }

  nextProxy(){
    const currentIndex = this.proxyList.findIndex(x => x.proxyid ===this.selectedProxy.proxyid)
    if(currentIndex == (this.proxyList.length -1 )){
      this.nextRound()
    }else{
      this.selectedProxy = {
        'proxyid': this.proxyList[currentIndex + 1].proxyid,
        'proxyname' : this.proxyList[currentIndex + 1].proxyname
      }
    }
  }
}
