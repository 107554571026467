import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-office-calender-table',
  templateUrl: './office-calender-table.component.html',
  styleUrls: ['./office-calender-table.component.scss'],
})
export class OfficeCalenderTableComponent implements OnInit, OnChanges {
  @Output() getData: EventEmitter<any> = new EventEmitter();

  @Input() startdate: string = '';
  @Input() enddate: string = '';

  @Input() weeks: any[] = [];
  @Input() shifts: any[] = [];
  @Input() roster: any;

  daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  constructor(private allinoneService: AllInOneService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.roster && !changes.roster.firstChange) {
      this.roster = changes.roster.currentValue;
      this.addShiftByRoster();
    }
  }

  ngOnInit(): void {
    console.log(this.weeks);
    // this.addShiftByRoster();
  }

  addShiftByRoster() {
    this.weeks.map((week: any) => {
      week.map((day: any) => {
        if (day.date != '') {
          var tempDate = this.allinoneService.formatDBToShowInEdit(day.date);
          const dayOfWeekName = new Date(tempDate).toLocaleString('default', {
            weekday: 'long',
          }).toLowerCase();
          day.shift = this.roster[dayOfWeekName];
        }
      });
    });
  }

  sendDataToParent(){
    this.getData.emit(this.weeks);
  }
}
