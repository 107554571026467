<div class="fix">
    <div class="title-container mb-2">
        <div class="title">
            Tickets
        </div>
        <div class="close-btn">
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <!-- -->
    <div class="mt-2">
        <div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="mb-2">
                        <label for="proj" class="form-label">Assigned To</label>
                        <div class="input-group">
                            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                                (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="assignedtouserid"
                                style="font-family: FontAwesome,Ubuntu;">
                            <button class=" input-group-text btn btn-custom " type="button"
                                (click)="searchassignedto()">
                                <mat-icon class="c-icon">search</mat-icon>
                            </button>
                        </div>
                    </div>


                </div>
                <div class="col-sm-6">
                    <div class="mb-2">
                        <label for="tick" class="form-label">Ticket</label>
                        <div class="input-group me-2 ">
                            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                                (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="ticketno"
                                [ngClass]="{'c-search-input-with-clear' : ticketno }"
                                style="font-family: FontAwesome,Ubuntu;">
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" (click)="searchicket()">
                                <mat-icon class="c-icon">search</mat-icon>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="mb-2 styled-select">
                        <label for="proj" class="form-label">Customer</label>
                        <ng-select class="role-select" [items]="customersList" bindLabel="customername" placeholder="-"
                            appendTo="body" [multiple]="false" (change)="getCustomerName($event)">

                        </ng-select>
                        <mat-progress-bar mode="indeterminate" *ngIf="getcustomer"></mat-progress-bar>
                    </div>


                </div>
                <div class="col-sm-4">
                    <div class="mb-2 styled-select">
                        <label for="proj" class="form-label">Product</label>
                        <ng-select class="role-select" [items]="productList" bindLabel="name" placeholder="-"
                            appendTo="body" [multiple]="false" (change)="productChange($event)">

                        </ng-select>
                        <mat-progress-bar mode="indeterminate" *ngIf="getproduct"></mat-progress-bar>
                    </div>
                </div>
                <div class="col-sm-4 styled-select">
                    <label for="proj" class="form-label">Project</label>


                    <ng-select class="role-select" [items]="projectList" bindLabel="name" placeholder="-"
                        appendTo="body" [multiple]="false" (change)="getProjectName($event)">

                    </ng-select>
                    <mat-progress-bar mode="indeterminate" *ngIf="getproject"></mat-progress-bar>
                </div>
            </div>
            <div class="mb-2">

                <mat-divider></mat-divider>
            </div>

        </div>
        <div class="w-100 d-flex flex-wrap">
            <div class="me-auto">

            </div>
            <div class="d-flex align-items-center">
                <pagination-template *ngIf="ticketList.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
                    <div class="d-flex align-items-center justify-content-center">
                        <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.previous()">
                            <span class="me-1">&laquo;</span>&nbsp;Prev
                        </div>
                        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </span>
                            <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.next()">
                            Next&nbsp;<span class="ms-1">&raquo;</span>
                        </div>
                    </div>
                </pagination-template>
            </div>
        </div>
        <div>
            <div class="loading-container" *ngIf="isLoading">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div *ngIf="!isLoading" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                (scrolled)="onScrollDown()" [scrollWindow]="false" [perfectScrollbar]="config"
                class="task-container-new">
                <div>
                    <div class="card card-body my-card-new disabled-card" *ngFor="let data of closedTicket;">
                        <div class="row">
                            <div class="col-10">
                                <div class="flex-row word" *ngIf="data.ticketno">
                                    Ticket: {{data.ticketno}}
                                </div>
                                <div class="flex-row word" *ngIf="data.customername">
                                    Customer : {{data.customername}}
                                </div>
                                <div class="flex-row word" *ngIf="data.productname">
                                    Product : {{data.productname}}
                                </div>
    
                                <div class="flex-row word" *ngIf="data.projectname">
                                    Project : {{data.projectname}}
                                </div>
                                <div class="flex-row word" *ngIf="data.statusname">
                                    Status : {{data.statusname}}
                                </div>
                            </div>
                            <div class="col-1 ms-4 d-flex align-items-center justify-content-end">
                                <input class="form-check-input cus" type="checkbox" id="ticketcheckbox"
                                    name="ticketcheckbox" checked="checked" disabled>
                            </div>
                        </div>
    
                    </div>
                    <div class="card card-body my-card-new"
                        *ngFor="let data of ticketList |  paginate: { itemsPerPage: itemPerPage, currentPage: currentPage };"
                        (click)="checkTicket($event,data)">
                        <div class="row">
                            <div class="col-10">
                                <div class="flex-row word" *ngIf="data.ticketno">
                                    Ticket: {{data.ticketno}}
                                </div>
                                <div class="flex-row word" *ngIf="data.customername">
                                    Customer : {{data.customername}}
                                </div>
                                <div class="flex-row word" *ngIf="data.productname">
                                    Product : {{data.productname}}
                                </div>

                                <div class="flex-row word" *ngIf="data.projectname">
                                    Project : {{data.projectname}}
                                </div>
                                <div class="flex-row word" *ngIf="data.statusname">
                                    Status : {{data.statusname}}
                                </div>
                            </div>
                            <div class="col-1 ms-4 d-flex align-items-center justify-content-end">
                                <input *ngIf="data.select == false" class="form-check-input cus" type="checkbox"
                                    id="ticketcheckbox" name="ticketcheckbox">
                                <input *ngIf="data.select == true" class="form-check-input cus" type="checkbox"
                                    id="ticketcheckbox" name="ticketcheckbox" checked="checked">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="close-save-button justify-content-end mt-3">
            <button type="button" class="btn btn-custom me-2" (click)="close()">
              Cancel
            </button>
            <app-button-loader (click)="search()" [button_text]="'Done'" [load_button_text]="''"></app-button-loader>
          </div>
    </div>
</div>