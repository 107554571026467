import { MatDialog } from '@angular/material/dialog';
import { ChooseMemberComponent } from '../choose-member/choose-member.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared/services/message.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { DatePipe } from '@angular/common';
import { IMyDpOptions } from 'mydatepicker';


@Component({
  selector: 'app-b-type-member-register',
  templateUrl: './b-type-member-register.component.html',
  styleUrls: ['./b-type-member-register.component.scss']
})
export class BTypeMemberRegisterComponent implements OnInit {

  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };

  @Output('closeFormHandler') closeFormHandler = new EventEmitter();
  @Input() status: string = 'Add User'
  @Input() currentMember: any = {}
  @Input() relativeInfo: any = {}
  @Input() isAdmingView: boolean = false;

  saveLoading: boolean = false;
  relations: any[] = [];
  memberList: any[] = []
  relativeMemberForm!: FormGroup;
  requiredMessage = 'This field may not be blank';
  submitted = false;
  requestBodyData: any;
  memberTypeList: any[] = [];
  relativetypesList: any[] = []
  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];

  //images and file to upload
  selectedProfileImage?: File;
  tempProfileImage: any;
  profileUrl: any;
  // selectedAttachments: any[] = [];
  tempfilename: any = "";
  deletedprofilename = "";
  filelist: Array<any> = [];
  deletedfilelist: Array<any> = [];
  isApproving: boolean = false;
  isNotApproved: boolean = false;
  isPaid: boolean = false;
  isMemberSaved: boolean = false;
  reader = new FileReader();
  gettingNrcCode: boolean = false;
  subscription!: Subscription;
  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  constructor(
    private kunyekService: KunyekService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private messageService: MessageService,
    public allInOneservice: AllInOneService,
    public datepipe: DatePipe,
  ) {
    this.relativeMemberForm = this.fb.group({
      name: ["", Validators.required],
      subname: [""],
      birthplace: [""],
      birthdate: [""],
      fathername: [""],
      mothername: [""],
      education: [""],
      currentjob: [""],
      department: [""],
      retiredjob: [""],
      address: [""],
      current_address: [""],
      religion: [""],
      nationality: [""],
      job: [""],
      membertypeid: "FASDKLFJ",
      phone: ["", Validators.required],
      nrcregioncode: ["", Validators.required],
      nrctownshipcode: ["", Validators.required],
      nrctype: ["", Validators.required],
      nrcnumber: ["", Validators.required],
      relationship: ["", Validators.required],
      region: ['',],
      town: ['',],
      ward: ['',]
    })
  }

  ngOnInit(): void {
    this.membertype.disable()
    this.membertype.setValue("FASDKLFJ")
    this.getMemberTypes()
    this.getNrcCode()
    this.getRelativeTypes()
    this.checkCurrentStatus()
    this.getRegion()
  }

  get name() {
    return this.relativeMemberForm.get("name")!
  }

  get phone() {
    return this.relativeMemberForm.get("phone")!
  }

  get birthdate() {
    return this.relativeMemberForm.get("birthdate")!
  }

  get membertype() {
    return this.relativeMemberForm.get("membertypeid")!
  }

  get nrcregioncode() {
    return this.relativeMemberForm.get('nrcregioncode')!
  }

  get nrctownshipcode() {
    return this.relativeMemberForm.get('nrctownshipcode')!
  }

  get nrctype() {
    return this.relativeMemberForm.get('nrctype')!
  }

  get nrcnumber() {
    return this.relativeMemberForm.get('nrcnumber')!
  }

  get relationship() {
    return this.relativeMemberForm.get('relationship')!
  }

  get region() {
    return this.relativeMemberForm.get('region');
  }
  get town() {
    return this.relativeMemberForm.get('town');
  }
  get ward() {
    return this.relativeMemberForm.get('ward');
  }

  getMemberTypes() {
    this.kunyekService.getKMemberTypesList({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.memberTypeList = res.datalist;
        }
      },
      error: (err) => {
        console.log(err)

      }
    })
  }

  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
    };
    this.kunyekService.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }

  getRegion() {
    const data = {};
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyekService.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  add() {
    let member = {
      syskey: '',
      relativetype: '',
      birthdate: '',
      currentjob: '',
      cardtype: '',
    }
    this.relations.push(member)
  }

  removeTableRow(person: any, i: number) {
    // this.memberList = this.memberList.map((p) =>
    //   p.syskey==person.syskey ? { ...p, checked: false } : p
    // );

  }

  closeForm() {
    this.submitted = false;
    this.closeFormHandler.emit();
  }

  handleSaveMember() {
    this.saveLoading = true;
    this.requestBodyData = this.relativeMemberForm.value
    if (this.relativeMemberForm.valid) {
      let nrc: string;
      nrc = this.relativeMemberForm.get('nrcregioncode')?.value + '/' + this.relativeMemberForm.get('nrctownshipcode')?.value +
        '(' + this.relativeMemberForm.get('nrctype')?.value + ')' +
        this.relativeMemberForm.get('nrcnumber')?.value
      this.uploadFiles()
      const formattedBirthDate = this.birthdate?.value?.formatted || this.birthdate?.value;
      this.requestBodyData = {
        ...this.requestBodyData,
        postcode: this.requestBodyData?.ward,
        nrc: nrc,
        parentid: this.currentMember.syskey,
        membertypeid: "FASDKLFJ",
        birthdate: this.allInOneservice.convertDate(formattedBirthDate),
        tempfilename: this.tempfilename,
        profileimage: this.selectedProfileImage?.name,
        formstatus: "004"
      }

      this.requestBodyData["membertypeid"] = 'FASDKLFJ'
      this.requestBodyData['parentid'] = this.currentMember.syskey

      if (this.status == 'Edit Relative') {
        this.requestBodyData = {
          ...this.requestBodyData,
          ...this.relativeInfo,
          profileimage: this.selectedProfileImage?.name,
          tempfilename: "",
          formstatus: "004"
        }
        this.kunyekService.editKMember(this.requestBodyData).subscribe(
          (res: any) => {
            if (res.returncode == 300) {
              this.messageService.openSnackBar(res.message, 'success')
              this.isMemberSaved = true;
            } else if (res.returncode == 301) {
              console.log(res)
              this.messageService.openSnackBar("User already exits with this phone number or email", "warn")
            } else {
              this.messageService.openSnackBar("error", 'warn')
            }
            this.saveLoading = false;
          },
          (error: any) => {
            console.log(error, 'error')
            this.messageService.openSnackBar(error, 'warn')
            this.saveLoading = false;
          }
        )
      } else {
        this.kunyekService.addKMember(this.requestBodyData).subscribe(
          (res: any) => {
            if (res.returncode == 300) {
              this.messageService.openSnackBar(res.message, 'success')
              this.isMemberSaved = true;
              this.status = 'Edit Relative'
            } else if (res.returncode == 301) {
              console.log(res)
              this.messageService.openSnackBar("User already exits with this phone number or email", "warn")
            } else {
              this.messageService.openSnackBar("error", 'warn')
            }
            this.saveLoading = false;
          },
          (error: any) => {
            console.log(error, 'error')
            this.messageService.openSnackBar(error, 'warn')
            this.saveLoading = false;
          }
        )
      }

    } else {
      this.submitted = true;
      this.saveLoading = false;
      this.messageService.openSnackBar('Invalid Form', 'warn')
    }
  }


  fomratDate(value: string): string {
    if (value === "") return "";
    const pattern = /(\d{4})(\d{2})(\d{2})/;
    return value.replace(pattern, '$1-$2-$3');
  }

  onFileSelected(event: Event) {
    if (this.profileUrl) {
      this.deletedprofilename = JSON.parse(this.currentMember.profileimage).filename;
    }

    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedProfileImage = input.files[0];
      this.reader.readAsDataURL(this.selectedProfileImage);
      this.reader.onload = (event) => {
        this.profileUrl = this.reader.result;
      }
    }
  }


  getRelativeTypes() {
    this.kunyekService.getRelativeTypes({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.relativetypesList = res.datalist;
        }
      },
      error: (err) => {
        console.log(err)

      }
    })
  }

  addNewRelative() {
    this.submitted = false;
    this.isMemberSaved = false;
    this.relativeMemberForm.reset()
  }

  checkCurrentStatus() {
    if (this.status == 'Edit Relative') {
      if (this.relativeInfo.profileimage) {
        let piamge = JSON.parse(this.relativeInfo.profileimage);
        this.profileUrl = piamge ? piamge.fileurl : ""
      }
      this.relativeMemberForm.setValue({
        name: this.relativeInfo.name,
        subname: this.relativeInfo.subname,
        birthplace: this.relativeInfo.birthplace,
        birthdate: this.allInOneservice.changeStringToDateobject(this.relativeInfo.birthdate),
        nrcregioncode: this.relativeInfo.nrcregioncode,
        nrctownshipcode: this.relativeInfo.nrctownshipcode,
        nrctype: this.relativeInfo.nrctype,
        nrcnumber: this.relativeInfo.nrcnumber,
        fathername: this.relativeInfo.fathername,
        mothername: this.relativeInfo.mothername,
        education: this.relativeInfo.education,
        currentjob: this.relativeInfo.currentjob,
        department: this.relativeInfo.department,
        retiredjob: this.relativeInfo.retiredjob,
        address: this.relativeInfo.address,
        current_address: this.relativeInfo.current_address,
        religion: this.relativeInfo.religion,
        nationality: this.relativeInfo.nationality,
        job: this.relativeInfo.job,
        membertypeid: this.relativeInfo.memberType ? this.relativeInfo.memberType.membertypeid : "",
        phone: this.relativeInfo.phone,
        relationship: this.relativeInfo.relationship,
        region: this.relativeInfo.directory?.region || '',
        town: this.relativeInfo.directory?.town || '',
        ward: this.relativeInfo.directory?.postcode || ''
      })
      if (this.relativeInfo && this.relativeInfo.directory) {
        this.onRegionChange({ target: { value: this.relativeInfo?.directory?.region } });
        this.onTownChange({ target: { value: this.relativeInfo?.directory?.town } });
      }

    }
    if (this.relativeInfo.formstatus == '001' || this.relativeInfo.formstatus == '002' && !this.isAdmingView) {
      this.relativeMemberForm.disable()
    }
  }

  async uploadFiles() {

    const defaultpath = 'kokaimember/members';

    if (this.tempfilename == '') {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(
        date,
        'yyyyMMddhhmmss' + millisecond,
      );
    }

    //upload member profile iamge
    if (this.selectedProfileImage) {
      let profilepath = this.status != 'Edit Relative' ?
        `${defaultpath}/${this.tempfilename}/${this.selectedProfileImage.name}`
        : `${defaultpath}/${this.relativeInfo.syskey}/${this.selectedProfileImage.name}`
      const upload_aws = await this.allInOneservice.uploadAnswerFile(
        this.selectedProfileImage,
        profilepath,
      );
      console.log(profilepath, this.status)
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn',
        );
        return;
      }
      this.tempProfileImage = profilepath;
    }

    //upload member attachements

  }

}
