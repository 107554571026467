import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from './../../shared/services/message.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HierarchyAddComponent } from 'src/app/admin/hierarchy-add/hierarchy-add.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
@Component({
  selector: 'app-crm-product-module',
  templateUrl: './crm-product-module.component.html',
  styleUrls: ['./crm-product-module.component.css']
})
export class CrmProductModuleComponent implements OnInit {

  moduleAddOrNot: boolean = false;
  saveLoading: boolean = false;
  isEdit: boolean = false;
  cFormSubmitted: boolean = false;
  modulelist: any = []
  deletemodule: any = []
  isAdmin: boolean = false;
  selfservices: boolean = true;
  piclist: any = [];
  orgid: any = ''


  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  cForm = {
    description: "",
    picname: "",
    picid: "",
    moduleid: ""

  }
  constructor(private dialogRef: MatDialogRef<CrmProductModuleComponent>,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private kunyekService: KunyekService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modulelist = data.modulelistdata;

    this.orgid = data.orgid
    console.log(this.modulelist)
    console.log(this.cForm)
  }

  ngOnInit(): void {
    this.getPIC();
    // if (this.modulelist.length == 0) {
    //   this.moduleAddOrNot = true;
    // }
  }


  cancel() {
    this.moduleAddOrNot = false;
    this.clean();
  }

  clean() {
    this.moduleAddOrNot = false;
    this.isEdit = false;
    this.cForm = {
      description: "",
      picid: "",
      picname: "",
      moduleid: "",
    }
    this.cFormSubmitted = false;
  }
  editmodule(module: any) {
    console.log(module);
    this.moduleAddOrNot = true;
    this.cForm.description = module.description,
      this.cForm.picid = module.picid,
      this.cForm.picname = module.picname,
      this.cForm.moduleid = module.moduleid,
      this.isEdit = true;
  }
  submitmodule() {
    this.cFormSubmitted = true;
  

    if (
      this.cForm.description == ''
    ) {
      console.log(this.cForm);

      return;
    }
    var index = -1;
    if (this.modulelist.length != 0) {
      index = this.modulelist
        .map((x: any) => x.moduleid)
        .indexOf(this.cForm.moduleid);
    }
    console.log(index + "Index>>>>>")
    if (this.isEdit) {
      console.log("1>>>")
      this.modulelist[index].description = this.cForm.description.toString();
      this.modulelist[index].moduleid = this.cForm.moduleid.toString();
      this.modulelist[index].picid = this.cForm.picid
      this.modulelist[index].picname = this.cForm.picname
    } else {
      console.log("2>>>")
      this.modulelist.push({
        description: this.cForm.description,
        picname: this.cForm.picname,
        picid: this.cForm.picid,
        moduleid:this.cForm.moduleid,

      });
    }
    console.log(this.modulelist)

    this.clean();
  }

  delete(index: any) {
    if (this.modulelist[index].moduleid) {
      this.deletemodule.push(this.modulelist[index])
    }
    this.modulelist.splice(index, 1)
  }
  Close() {
    this.dialogRef.close({ datalist: this.modulelist, deletelist: this.deletemodule });
  }


  newmodule() {
    console.log("ksjd;flkj;")
    this.moduleAddOrNot = true
    this.isAdmin = false;
  }

  getPIC() {


    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    }
    this.kunyekService.getCrmPIC(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.piclist = res.list;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err.message, 'warn');
      }
    );

  }

  selectpic(data: any) {

    console.log(data.target.value)
    if (data.target.value != '') {

      var index = this.piclist
        .map((x: any) => x.picid)
        .indexOf(data.target.value);
      if (index > -1) {
        this.cForm.picid = data.target.value;
        this.cForm.picname = this.piclist[index].picname
      }
    }

  }

}
