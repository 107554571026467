import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-crm-summary-advansearch',
  templateUrl: './crm-summary-advansearch.component.html',
  styleUrls: ['./crm-summary-advansearch.component.scss']
})
export class CrmSummaryAdvansearchComponent implements OnInit {

  description: any = '';
  recrring: boolean = false;
  detail: any = '';
  members: any = [];
  searchNonea: any = '';
  assignedto: any = '';
  reportedby: any = '';
  reportedbyid: any = '';
  orgid: any = '';
  getData: any = [];
  assignedtome: boolean = false;

  assignedtoname: any = '';
  picVal = '';
  picID: any = [];
  assignedtList: any = [];
  picdatalist: any = [];
  getPic: boolean = false;
  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CrmSummaryAdvansearchComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.getData = data.data
    if (this.getData.length != '') {
      this.description = this.getData.description
      this.recrring = this.getData.recurring ? true : false
      this.detail = this.getData.detail
      this.reportedby = this.getData.reportedby
      this.reportedbyid = this.getData.reportedbyid
      this.assignedtome = this.getData.assignedtome
      this.picVal = this.getData.picName
      this.picID = this.getData.picName ? this.getData.assignedtList : []
    }
  }

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  organizations: any = [];
  getCustomercaredata: any = [];
  customercare:boolean = false;
  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    this.getCustomercaredata = this.allinoneService.getCustomer();

    if (this.getCustomercaredata != '') {

      this.orgid = this.getCustomercaredata.orgid;
      this.customercare = true;

    } else {
      this.customercare = false;
      this.orgid = this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid;
    

    }
    this.getMembers();
    this.getPIC();
  }
  onDismiss() {
    this.dialogRef.close()
  }

  search() {
    var data = {
      'description': this.description,
      'recurring': this.recrring,
      'detail': this.detail,
      'reportedby': this.reportedby,
      'reportedbyid': this.reportedbyid,
      'assignedtome': this.assignedtome,
      "picName": this.assignedtoname,
      "picid": this.picID ? this.picID.picid : '',
      "assignedtList": this.assignedtList
    }
    console.log(data);
    this.dialogRef.close({
      data: data,

      // checkdate: this.checkdate

    });
  }


  checkRecurring(data: any) {
    if (data.target.checked == true) {
      this.recrring = true;
    } else {
      this.recrring = false;
    }

  }
  selectMember(name: any, id: any) {

    this.reportedby = name;
    this.reportedbyid = id;
  }

  getMembers() {
    this.members = [];
    const data = {
      domainid: this.allinoneService.getDomain().filterdomainid,
      orgid: this.orgid,
    };
    this.kunyekService.getMembersCRM(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );

  }


  checkassigned(data: any) {
    console.log(data.target.checked);
    if (data.target.checked == true) {
      this.assignedtome = true;
      this.picVal = '';
      this.assignedtoname = '';
      this.picID = [];
    } else {
      this.assignedtome = false;
    }
  }

  picIDget(data: any) {
    console.log(data);
    this.picID = data;

    if (Array.isArray(this.picID) || this.picID == null) {
      this.assignedtoname = ''
    } else {
      this.assignedtList = data;
      for (let i = 0; i < this.picdatalist.length; i++) {
        if (this.picdatalist[i].picid == this.picID.picid) {
          console.log(this.picdatalist[i].picname)
          this.assignedtoname = this.picdatalist[i].picname;
        }
      }
    }
  }

  getPIC() {
    this.getPic = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    }
    this.kunyekService.getCrmPIC(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.picdatalist = res.list
          this.getPic = false;

        } else {
          this.getPic = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getPic = false;
        // this.messageService.openSnackBar(err.status, 'warn');
      }
    );

  }
}
