import { formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-leave-calendar',
  templateUrl: './office-leave-calendar.component.html',
  styleUrls: ['./office-leave-calendar.component.scss'],
})
export class OfficeLeaveCalendarComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: any = 490;
  itemPerPage: any = 10;
  currentPage: number = 1;

  addCalendar: boolean = false;
  isEditing: boolean = false;
  isFocus: boolean = false;
  gettingCalendar: boolean = false;
  isMobileView: boolean = false;
  calendarListLoading: boolean = false;
  nextDisabled: boolean = false;
  isLoading: boolean = false;
  formSubmitted: boolean = false;
  isAdmin: boolean = false;

  searchText: string = '';
  end: string = '';
  lastPage: any = '';

  statusList = ['All', 'Active', 'Inactive'];
  selectedStatus = 'All';

  calendarList: any[] = [];
  organizations: any[] = [];

  formatToShow = 'yyyy-MM-dd';
  dateFormat = 'yyyyMMdd';
  // currentdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
  currentdate = new Date(Date.now());
  currentYear = new Date().getFullYear().toString();

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  calendarForm : any = {
    calendarid: '',
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    year: this.currentYear,
    startdate: this.currentdate,
    enddate: this.currentdate,
    active: true,
  };

  subscriptions = new SubSink();

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.calendarForm.domainid = this.allinoneService.getDomain().domainid;
    if (this.isAdmin) {
      // this.organizations = this.allinoneService.orgs;
      // this.calendarForm.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;
      this.organizations = this.allinoneService.orgs;
      this.calendarForm.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.calendarForm.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }

    this.getCalendars();
  }

  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
    
    this.subscriptions.unsubscribe();
  }

  getCalendars() {
    this.calendarListLoading = true;
    let data = {
      orgid: this.calendarForm.orgid,
      domainid: this.calendarForm.domainid,
      end: this.end,
      active:
        this.selectedStatus == 'All'
          ? ''
          : this.selectedStatus == 'Active'
          ? true
          : false,
          roleid: this.allinoneService.getSelectedRole()
    };
    this.subscriptions.sink = this.kunyekService.getCalendar(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.calendarList = res.calendarlist;
          // if (res.calendarlist.length > 0) {
          //   this.nextDisabled = false;
          //   this.calendarList.push.apply(
          //     this.calendarList,
          //     res.calendarlist
          //   );
          //   this.end = res.end;

          //   if (res.calendarlist.length < 10) {
          //     this.nextDisabled = true;
          //     this.lastPage = this.currentPage;
          //   }
          // } else {
          //   this.currentPage = this.currentPage - 1;
          //   this.nextDisabled = true;
          //   this.lastPage = this.currentPage;
          // }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.calendarListLoading = false;
      },
      (err) => {
        this.calendarListLoading = false;
      }
    );
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  newCalendar() {
    this.addCalendar = true;
  }

  cancel() {
    this.addCalendar = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.calendarForm = {
      calendarid: '',
      domainid: this.calendarForm.domainid,
      orgid: this.calendarForm.orgid,
      year: this.currentYear,
      startdate: this.currentdate,
      enddate: this.currentdate,
      active: true,
    };
  }

  statusChange(status: string) {
    this.selectedStatus = status;
    this.refresh();
  }

  clear() {
    this.searchText = '';
  }

  refresh() {
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.calendarList = [];
    this.getCalendars();
  }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;

        if (event > this.calendarList.length / this.itemPerPage) {
          this.getCalendars();
        }
      }
    }
  }

  sortData(sort: Sort) {
    const data = this.calendarList;
    if (!sort.active || sort.direction === '') {
      this.calendarList = data;
      return;
    }
    this.calendarList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'year':
          return this.allinoneService.compare(a.year, b.year, isAsc);
        case 'startdate':
          return this.allinoneService.compare(a.startdate, b.startdate, isAsc);
        case 'enddate':
          return this.allinoneService.compare(a.enddate, b.enddate, isAsc);
        case 'status':
          return this.allinoneService.compare(a.active, b.active, isAsc);
        default:
          return 0;
      }
    });
  }

  editCalendar(cal: any) {
    this.addCalendar = true;
    this.isEditing = true;
    this.calendarForm = {
      calendarid: cal.calendarid,
      domainid: this.calendarForm.domainid,
      orgid: this.calendarForm.orgid,
      year: cal.year,
      startdate: this.allinoneService.parseDateToShowInEdit(cal.startdate),
      enddate: this.allinoneService.parseDateToShowInEdit(cal.enddate),
      active: cal.active,
    };
  }

  submitCalendar() {
    this.formSubmitted = true;
    if (this.calendarForm.year == '') {
      return;
    }
    if (this.calendarForm.startdate > this.calendarForm.enddate) {
      return this.messageService.openSnackBar(
        'Please select a valid end date.',
        'warn'
      );
    }
    this.isLoading = true;
    var startd = formatDate(
      new Date(this.calendarForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.calendarForm.enddate),
      this.dateFormat,
      'en-US'
    ).toString();
    var data = {
      calendarid: this.calendarForm.calendarid,
      startdate: startd,
      enddate: endd,
      active: this.calendarForm.active,
      orgid: this.calendarForm.orgid,
      domainid: this.calendarForm.domainid,
      year: this.calendarForm.year,
      roleid: this.allinoneService.getSelectedRole()
    };
    var fun = this.isEditing
      ? this.kunyekService.editCalendar(data)
      : this.kunyekService.createCalendar(data);
    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  activeStatusChange() {
    this.calendarForm.active = !this.calendarForm.active;
  }
}
