<div class="container-fluid">
    <div class="content">


        <div>

            <app-transportation-breadcrumb *ngIf="!isEditing && !allDaily" s_breadcrumb="Daily Requests">
            </app-transportation-breadcrumb>
            <!-- <app-transportation-breadcrumb *ngIf=" isEditing" s_breadcrumb="Day car way"
                [t_breadcrumb]="'Ref No: 2' + dailyRequest.refno" (emit)="cancelFunction()">
            </app-transportation-breadcrumb> -->
            <app-transportation-breadcrumb *ngIf="!isEditing && allDaily" s_breadcrumb="Daily Requests"
                [t_breadcrumb]="'Ref No: ' + dailyRequest.refno  " (emit)="cancelFunction()">
            </app-transportation-breadcrumb>
            <app-transportation-breadcrumb *ngIf="isEditing && !allDaily" s_breadcrumb="Daily Requests"
                [t_breadcrumb]="'Ref No: ' + dailyRequest.refno  " [f_breadcrumb]="'Detail'" (emit)="cancelFunction()"
                (emit1)="cancelFunction1()">
            </app-transportation-breadcrumb>



            <!--  (emit1)="cancels(2)"  -->
            <div *ngIf="!isEditing && !allDaily">





                <div class=" w-100 d-flex flex-wrap me-auto ">

                    <div class="input-group me-2 mb-2" style="width: 300px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            [(ngModel)]="searchText" style="font-family: FontAwesome,Ubuntu;">
                    </div>
                    <div class="dropdown mb-2 me-2">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==itemPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>

                    <!-- me-2 -->
                    <div class="me-2 mb-2 ">
                        <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>

                    <div class="mb-2 me-2 col-md-2">
                        <input type="date" class="form-control" id="startdatetime" [(ngModel)]="startdate"
                            name="startdate" (change)="searchdateApi()">
                    </div>

                    <!-- <div class="d-flex flex-wrap mb-2">
                        <div class=" " [matMenuTriggerFor]="statusmen">
                            <button class="btn btn-secondary dropdown-toggle" return="button" id="dropdownMenuButton"
                                aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
                                Status&nbsp;:&nbsp;{{ statusname }}
                            </button>
                            <mat-menu #statusmen="matMenu" xPosition="before">
                                <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                                    {{ status.name }}
                                </button>
                            </mat-menu>
                        </div>
                    </div> -->



                    <div class="line mb-2 "></div>
                    <div class="mb-2">
                        <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                            matTooltip="Export Vehicles">
                            <img class="excel-icon" src="../../../assets/images/excel.png">Export
                        </button>

                    </div>
                    <div class="line mb-2 "></div>
                    <div class="d-flex align-items-center me-auto mb-2 ">
                        <span class="total-count-text ">Total:&nbsp;</span> <span
                            class="total-count-num ">{{dailyRequestList.length}}</span>
                    </div>

                    <div>

                    </div>
                </div>


                <div>
                    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="requestrefno">Ref No.
                                    </td>
                                    <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="employeeid">Employee ID
                                    </td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">Name</td>

                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="startdate">Date</td>
                                    <!-- <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="status">Status</td> -->
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="remark">Remark</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="weekday">Week Day</td>

                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="6" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="dailyRequestList.length == 0">
                                    <td colspan="6" class="nodata">Empty</td>
                                </tr>
                                <tr
                                    *ngFor="let item of dailyRequestList | filter : searchText:'dailyRequest';let i = index;">
                                    <td class="td-data" (click)="goAll(item,i)" style="cursor: pointer;">
                                        <span [title]="item.requestrefno">{{item.requestrefno}}</span>
                                    </td>
                                    <td class="td-data" (click)="goAll(item,i)" style="cursor: pointer;">
                                        <span [title]="item.employeeid">{{item.employeeid}}</span>
                                    </td>
                                    <td class="td-data" (click)="goAll(item,i)" style="cursor: pointer;">
                                        <span [title]="item.username">{{item.username}}</span>
                                    </td>


                                    <td class="td-data" style="cursor: pointer;" (click)="goAll(item,i)">
                                        <span>{{allinoneService.formatDBToShow(allinoneService.formatDate(item.startdate))}}
                                            -
                                            {{allinoneService.formatDBToShow(allinoneService.formatDate(item.enddate))}}</span>
                                    </td>
                                    <!-- <td class="td-data" (click)="goAll(item,i)" style="cursor: pointer;">
                                        <span  class="status" [ngClass]="{
                                            'status-green': item.requeststatus == '002',
                                            'status-red': item.requeststatus == '003',
                                            'status-gray': item.requeststatus == '001',
                                            'status-main': item.requeststatus == '004'
                                          }">{{item.requeststatus == '001' ? 'Submitted' :
                                            item.requeststatus == '002' ? 'Approved' : item.requeststatus == '003' ?
                                            'Rejected' : item.requeststatus == '004' ? 'Completed' : ''}}</span>

                                    </td> -->
                                    <td class="td-data" style="cursor: pointer;" (click)="goAll(item,i)">
                                        <span [title]="item.remark">{{item.remark}}</span>
                                    </td>

                                    <td class="td-data" style="cursor: pointer;" (click)="goAll(item,i)">
                                        <span [title]="item.weekday">{{item.weekday}}</span>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="!isEditing && allDaily">





                <div class=" w-100 d-flex flex-wrap me-auto ">

                    <div class="input-group me-2 mb-2" style="width: 300px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            [(ngModel)]="searchTextAll" style="font-family: FontAwesome,Ubuntu;">
                    </div>
                    <div class="dropdown mb-2 me-2">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==itemPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>

                    <!-- me-2 -->
                    <div class="me-2 mb-2 ">
                        <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                    <div class="line mb-2 "></div>
                    <!-- <label for="" class=" mb-3 col-md-1">Date</label> -->

                    <div class="d-flex flex-wrap mb-2">
                        <div class=" " [matMenuTriggerFor]="statusmen">
                            <button class="btn btn-secondary dropdown-toggle" return="button" id="dropdownMenuButton"
                                aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
                                Status&nbsp;:&nbsp;{{ statusname }}
                            </button>
                            <mat-menu #statusmen="matMenu" xPosition="before">
                                <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                                    {{ status.name }}
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                    <div *ngIf="approveCount > 0 || rejectCount > 0" class="line"></div>

                    <app-button-loader *ngIf="approveCount > 0" class="{{ rejectCount > 0 ? 'me-3' : 'me-0' }}"
                        (click)="submitApprove()" [button_text]="'Approve (' + this.approveCount + ')'"
                        [load_button_text]="'Updating'" [isLoading]="approveLoading" [disabled]="rejectLoading">
                    </app-button-loader>
                    <app-button-loader *ngIf="rejectCount > 0" [outlinedanger]="true" class="{{  'me-0' }}"
                        (click)="submitRejected()" [button_text]="'Reject (' + this.rejectCount + ')'"
                        [load_button_text]="'Updating'" [isLoading]="rejectLoading" [disabled]="approveLoading">
                    </app-button-loader>



                    <div class="line mb-2 "></div>
                    <div class="mb-2">
                        <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                            matTooltip="Export Vehicles">
                            <img class="excel-icon" src="../../../assets/images/excel.png">Export
                        </button>

                    </div>
                    <div class="line mb-2 "></div>
                    <div class="d-flex align-items-center me-auto mb-2 ">
                        <span class="total-count-text ">Total:&nbsp;</span> <span
                            class="total-count-num ">{{alldailyRequestList.length}}</span>
                    </div>

                    <div>

                    </div>
                </div>


                <div>
                    <div class="table-responsive" matSort (matSortChange)="sortDataAll($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col">
                                        <div class="form-check">
                                            <input class="form-check-input td-edit" type="checkbox" id="primary"
                                                [(ngModel)]="currentPageAllSelected"
                                                (ngModelChange)="currentPageSelectAll()" />
                                        </div>
                                    </td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="employeeid">User ID
                                    </td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="username">Name</td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="startdate">Date</td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="status">Status</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="remark">Remark</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="6" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="allgetting">
                                <tr>
                                    <td colspan="6" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="allgetting== false">
                                <tr *ngIf="alldailyRequestList.length == 0">
                                    <td colspan="6" class="nodata">Empty</td>
                                </tr>
                                <tr
                                    *ngFor="let item of alldailyRequestList | filter : searchTextAll:'alldailyRequest';let i = index;">

                                    <td style="vertical-align: top;
                                    padding-top: 10px !important;">
                                        <div class="form-check">
                                            <input class="form-check-input td-edit" type="checkbox" id="primary"
                                                [(ngModel)]="item.isChecked" (ngModelChange)="selectRequest(item)" />
                                        </div>
                                    </td>


                                    <td class="td-data" (click)="EmployeeEdit(item,i)" style="cursor: pointer;">
                                        <span [title]="item.employeeid">{{item.userid}}</span>
                                    </td>
                                    <td class="td-data" (click)="EmployeeEdit(item,i)" style="cursor: pointer;">
                                        <span [title]="item.username">{{item.username}}</span>
                                    </td>
                                    <td class="td-data" style="cursor: pointer;" (click)="EmployeeEdit(item,i)">
                                        <span>{{allinoneService.formatDBToShow(allinoneService.formatDate(item.date))}}
                                        </span>
                                    </td>
                                    <td class="td-data" (click)="EmployeeEdit(item,i)" style="cursor: pointer;">
                                        <span class="status" [ngClass]="{
                                            'status-green': item.requeststatus == '002',
                                            'status-red': item.requeststatus == '003',
                                            'status-gray': item.requeststatus == '001',
                                            'status-main': item.requeststatus == '004'
                                          }">{{item.requeststatus == '001' ? 'Submitted' :
                                            item.requeststatus == '002' ? 'Approved' : item.requeststatus == '003' ?
                                            'Rejected' : item.requeststatus == '004' ? 'Completed' : ''}}</span>

                                    </td>
                                    <td class="td-data" style="cursor: pointer;" (click)="EmployeeEdit(item,i)">
                                        <span [title]="item.remark">{{item.remark}}</span>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf=" isEditing && !allDaily">

                <div class="d-flex flex-wrap">
                    <div class="close-save-button mb-3 ">
                        <button type="button" class=" btn btn-custom me-2" (click)="cancelFunction1()">
                            Cancel
                        </button>

                    </div>
                    <div class="mb-3">
                        <div class="line"></div>
                    </div>
                    <app-button-loader class="mb-3 me-2" *ngIf="dailyRequest.status != '003'"
                        (click)="submitApproval('003')" [button_text]="'Reject'" [load_button_text]="'Rejecting'"
                        [isLoading]="isRejecting" [outlinedanger]="true">
                    </app-button-loader>

                    <app-button-loader class="mb-3 me-2" *ngIf="dailyRequest.status != '002'"
                        (click)="submitApproval('002')" [button_text]="'Approve'" [load_button_text]="'Approving'"
                        [isLoading]="isApproving">
                    </app-button-loader>
                    <div class="d-flex align-items-center ms-auto "
                            *ngIf=" dailyRequest.status != '001' || (dailyRequest.status == '001')">
                            <span [title]="dailyRequest.status" class="status" [ngClass]="{
                        'status-green': dailyRequest.status == '002',
                        'status-red': dailyRequest.status == '003',
                        'status-gray': dailyRequest.status == '001',
                        'status-main': dailyRequest.status == '004'}">
                                <span
                                    *ngIf="dailyRequest.status == '001' || dailyRequest.status == '002' || dailyRequest.status == '003' || dailyRequest.status == '004'">
                                    {{ dailyRequest.status == '001' ? 'Submitted' : dailyRequest.status == '002' ?
                                    'Approved' : dailyRequest.status
                                    == '003' ? 'Rejected' : 'Completed' }}
                                </span>
                            </span>
                        </div>
                </div>
                <div class="row">


                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Name ( {{dailyRequest.userid}} )</label>
                        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="dailyRequest.name"
                            disabled>


                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="employee" class="form-label">Employee ID</label>
                        <input type="text" class="form-control" id="emplyoee" name="employee"
                            [(ngModel)]="dailyRequest.employeeid" disabled>


                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="date" class="form-label">Date</label>
                        <input type="text" class="form-control" id="date" name="date" [(ngModel)]="dailyRequest.date"
                            disabled>

                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="status" class="form-label">Status</label>
                        <input type="text" class="form-control" id="status" name="status"
                            [(ngModel)]="dailyRequest.statusname" disabled>

                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="date" class="form-label">To Place</label>
                        <input type="text" class="form-control" id="date" name="date" [(ngModel)]="dailyRequest.toplace"
                            disabled>

                    </div>

                </div>
                <div class="row">
                    <div class="mb-3 col-md-6">
                        <div class="flex-wrap d-flex">

                            <div class="form-check cus me-5" *ngIf="dailyRequest.going">
                                <input class="form-check-input cus" type="checkbox" id="void" name="void"
                                    checked="checked" disabled>
                                <label class="form-check-label cus mb-1 ms-1" for="void">
                                    Going
                                </label>
                            </div>
                            <div class="form-check cus me-5" *ngIf="!dailyRequest.going">
                                <input class="form-check-input cus" type="checkbox" id="void" name="void" disabled>
                                <label class="form-check-label cus mb-1 ms-1" for="void">
                                    Going
                                </label>
                            </div>
                            <div class="form-check cus" *ngIf="dailyRequest.return">
                                <input class="form-check-input cus" type="checkbox" id="return" name="return"
                                    checked="checked" disabled>
                                <label class="form-check-label cus mb-1 ms-1" for="return">
                                    Return
                                </label>
                            </div>
                            <div class="form-check cus" *ngIf="!dailyRequest.return">
                                <input class="form-check-input cus" type="checkbox" id="return" name="return" disabled>
                                <label class="form-check-label cus mb-1 ms-1" for="return">
                                    Return
                                </label>
                            </div>
                        </div>





                    </div>


                </div>

                <div class="row" *ngIf="dailyRequest.going && dailyRequest.return">
                    <div class="mb-3 col-md-6">
                        <label for="date" class="form-label">Arrive Time</label>
                        <input type="text" class="form-control" id="arrivetime" name="arrivetime"
                            [(ngModel)]="dailyRequest.arrivetime" disabled>

                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="date" class="form-label">Return Time</label>
                        <input type="text" class="form-control" id="returntime" name="returntime"
                            [(ngModel)]="dailyRequest.returntime" disabled>

                    </div>

                </div>
                <div class="row" *ngIf="!dailyRequest.going || !dailyRequest.return">
                    <div class="mb-3 col-md-12" *ngIf="dailyRequest.going">
                        <label for="date" class="form-label">Arrive Time</label>
                        <input type="text" class="form-control" id="arrivetime" name="arrivetime"
                            [(ngModel)]="dailyRequest.arrivetime" disabled>

                    </div>
                    <div class="mb-3 col-md-12" *ngIf="dailyRequest.return">
                        <label for="date" class="form-label">Return Time</label>
                        <input type="text" class="form-control" id="returntime" name="returntime"
                            [(ngModel)]="dailyRequest.returntime" disabled>

                    </div>

                </div>


                <div class="row">
                    <div class="mb-3 col-md-6">
                        <label for="date" class="form-label">Pick Up Place</label>
                        <input type="text" class="form-control" id="date" name="date"
                            [(ngModel)]="dailyRequest.userfrom" disabled>

                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="status" class="form-label">Drop Off Place</label>
                        <input type="text" class="form-control" id="status" name="status"
                            [(ngModel)]="dailyRequest.userto" disabled>

                    </div>
                </div>


                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="remark" class="form-label">Remark</label>
                        <textarea type="text" style="height: 100px;" class="form-control" id="remark" name="remark"
                            [(ngModel)]="dailyRequest.remark" disabled></textarea>

                    </div>

                </div>

                <ng-container>
                </ng-container>

            </div>

        </div>




    </div>


</div>