import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { OfficeWorkpolicyComponent } from '../office-workpolicy/office-workpolicy.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { MessageService } from 'src/app/shared/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-late-choose-post',
  templateUrl: './late-choose-post.component.html',
  styleUrls: ['./late-choose-post.component.scss']
})
export class LateChoosePostComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isFocus: boolean = false;
  isMobileView: boolean = false;
  mobileViewWidth: number = 426;
  itemPerPage: number = 10;
  currentPage: number = 1;

  searchText: string = '';

  postList: any = [];
  selectedPostList: any = [];
  postListLoading: boolean = false;
  orgid: string = ""
  isAdmin: boolean = false;
  organizations: any = [];
  selectedPosts = 0;
  saveLoading: boolean = false;

  currentPageAllSelected: boolean = false;
  searchPageAllSelected: boolean = false;

  filteredUserid : any = [];

  selectedEmployeeList: any = [];

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private filterPipe: FilterPipe,
    private route: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialogRef<OfficeWorkpolicyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.postList = JSON.parse(JSON.stringify(data.posts))
    this.selectedPostList = JSON.parse(JSON.stringify(data.selectedCount))
    this.orgid = data.orgid;
    this.onResize(event);
    console.log("Your selected count")
    console.log(this.selectedPostList)
  }

  ngOnInit(): void {
    if (this.postList.length == 0) {
      this.getPostList();
    }
    else {
      this.postList.map((x: any) => {
        if (this.selectedPostList.includes(x.id)) {
          x.isChecked = true;
        }
        else {
          x.isChecked = false;
        }
      })
    }
  }

  getPostList() {
    this.postListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.kunyekService.getClassCodeList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.postList = res.datalist;
          this.postListLoading = false;
        } else {
          this.postListLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.postListLoading = false;
      }
    );
  }

  

  sortData(sort: Sort) {
    const data = this.postList;
    if (!sort.active || sort.direction === '') {
      this.postList = data;
      return;
    }
    this.postList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(a.description, b.description, isAsc);
        default:
          return 0;
      }
    })

  }

  currentPageSelectAll() {
    if (this.searchText != "") {
      var filteredData = this.filterPipe.transform(
        this.postList,
        this.searchText,
        'postList'
      );

      

      if(this.currentPageAllSelected == true) {
        
        for(var i = 0 ; i < filteredData.length ; i++) {
          this.filteredUserid.push(filteredData[i]['userid'])
        }
        for(var i = 0; i < this.filteredUserid.length; i++) {
          for(var x = 0 ; x < this.postList.length; x++) {
            if(this.filteredUserid[i] == this.postList[x]['userid']) {
              this.postList[x]['isChecked'] = true;
            }
          }
        }
      } else {
        
        for(var i = 0; i < this.filteredUserid.length; i++) {
          for(var x = 0 ; x < this.postList.length; x++) {
            if(this.filteredUserid[i] == this.postList[x]['userid']) {
              this.postList[x]['isChecked'] = false;              
            }
          }
        }
        // filteredUserid = [];
      }
    } else {
      if (this.currentPageAllSelected == true) {
        for (var i = 0; i < this.postList.length; i++) {
          this.postList[i].isChecked = true;
        }
      } else {
        for (var i = 0; i < this.postList.length; i++) {
          this.postList[i].isChecked = false;
        } 
      }
    }
  }

  selectPost(post: any) {
    if (post.isChecked) {
      
    } else {
      
      this.currentPageAllSelected = false;
    }
  }

  addPost() {
    this.selectedEmployeeList = [];
    for (var i = 0; i < this.postList.length; i++) {
      if (this.postList[i]['isChecked']) {
        this.selectedEmployeeList.push(this.postList[i])
      }
    }
    
    this.dialogRef.close(this.selectedEmployeeList)
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.currentPageAllSelected = false;
    this.searchText = "";
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }


}
