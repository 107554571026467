<div class="container-fluid">
  <div class="content">
    <div class="d-flex justify-content-between align-items-center">
      <app-hcm-breadcrumb *ngIf="allinoneService.isAttendanceView" s_breadcrumb="Time In/Out Report">
      </app-hcm-breadcrumb>
      <app-team-breadcrumb *ngIf="!allinoneService.isAttendanceView" s_breadcrumb="Time In/Out"></app-team-breadcrumb>
      <pagination-controls class="paginate" (pageChange)="currentPage=$event" *ngIf="inoutList.length> itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>
    <div class="d-flex flex-wrap align-items-start justify-content-between">
      <div class="mb-3 d-flex gap-2 flex-wrap justify-content-start align-items-center">

        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
          [bsConfig]="generalDatePickerConfig" [bsConfig]="{containerClass: 'custom-placement'}" readonly
          [(ngModel)]="submitForm.startdate" style="min-width: 200px;" required #startdate="ngModel"
          (ngModelChange)="setLastDat()">

        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
          [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="submitForm.enddate"
          [minDate]="submitForm.startdate" [maxDate]="minDate" style="min-width: 200px;" required #enddate="ngModel">
        <div class="line g-0"></div>
        <!-- <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                <input class="form-control" (ngModelChange)="filterReport()" (keyup)="blank()"
                  type="text" placeholder="&#xf002; Search with Employee ID/User ID/Name" aria-label="Search"
                  (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                  [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
  
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                  [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                  <mat-icon class="c-icon">close</mat-icon>
                </button>
              </div> -->
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search with Employee ID/User ID/Name"
            aria-label="Search with Employee ID/User ID/Name" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-1' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <button type="button" class="btn btn-custom" id="togglefilter" (click)="toggleFilter()">
          <mat-icon>{{openfilter ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </button>
        <!-- <div class="ms-2"> -->

        <!-- <div>
                <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                  <mat-icon>refresh</mat-icon>
                </button>
              </div> -->


        <div class="line g-0"></div>
        <!-- <a class="" [href]="inoutfileurl" download="time-in-out-report.xlsx"><button type="button"
                class="btn btn-custom" title="Export To Excel">
                <img class="excel-icon" src="../../../assets/images/excel.png">
                Export
              </button></a> -->
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
        <app-button-loader class="" [button_text]="'Search'" [load_button_text]="'Searching'"
          [isLoading]="searchLoading" (click)="search()">
        </app-button-loader>

        <div class="line g-0"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{ (inoutList |
            filter: searchText:15).length}}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search with Employee ID/User ID/Name"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
      </div>

    </div>
    <div *ngIf="openfilter" class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="departmentmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Department&nbsp;:&nbsp;{{ submitForm.department.length > 20 ? (submitForm.department |
          slice:0:20)+'...' : submitForm.department}}
          <mat-menu #departmentmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let department of departmentList" (click)="departmentChange(department)">
              {{ department }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>
      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="divisionmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Division&nbsp;:&nbsp;{{ submitForm.division.length > 22 ? (submitForm.division |
          slice:0:22)+'...' : submitForm.division}}
          <mat-menu #divisionmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let division of divisionList" (click)="divisionChange(division)">
              {{ division }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>
      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="teamidmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Team ID&nbsp;:&nbsp;{{ submitForm.teamid.length > 22 ? (submitForm.teamid |
          slice:0:22)+'...' : submitForm.teamid}}
          <mat-menu #teamidmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let teamid of teamidList" (click)="teamidChange(teamid)">
              {{ teamid }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>

      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="costcenterMenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Cost Center&nbsp;:&nbsp;{{ submitForm.costcenter.length > 22 ? (submitForm.costcenter |
          slice:0:22)+'...' : submitForm.costcenter}}
          <mat-menu #costcenterMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let costcenter of costCenterList" (click)="costCenterChange(costcenter)">
              {{ costcenter }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>

      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="subdivisionMenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Sub Division&nbsp;:&nbsp;{{ submitForm.subdivision.length > 22 ? (submitForm.subdivision |
          slice:0:22)+'...' : submitForm.subdivision}}
          <mat-menu #subdivisionMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let subdivision of subDivisionList" (click)="subDivisionChange(subdivision)">
              {{ subdivision }}
            </button>
          </mat-menu>
          <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>

      <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="statusMenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Status&nbsp;:&nbsp;{{ submitForm.status == '001' ? 'Active' : 'Inactive' }}
          <mat-menu #statusMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let status of userStatusFilterList" (click)="submitForm.status = status.id">
              {{ status.name }}
            </button>
          </mat-menu>
        </button>
      </div>

    </div>

    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-md-1" mat-sort-header="employeeid">Employee ID</td>
            <td scope="col" class="col-md-1" mat-sort-header="userid">User ID</td>
            <td scope="col" class="col-md-1" mat-sort-header="username">Name</td>
            <!-- <td scope="col" class="col-md-1" mat-sort-header="department">Department</td>
                  <td scope="col" class="col-md-1" mat-sort-header="division">Division</td> -->
            <td scope="col" class="col-md-1" mat-sort-header="showdate">Date</td>
            <td scope="col" class="col-md-1">Time In</td>
            <td scope="col" class="col-md-2">Time In Location</td>
            <td scope="col" class="col-md-1">Time Out</td>
            <td scope="col" class="col-md-2">Time Out Location</td>
            <td scope="col" class="col-md-4">Activity</td>
          </tr>
        </thead>
        <tbody *ngIf="gettingData">
          <tr>
            <td colspan="10" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!gettingData">
          <tr *ngIf="inoutList.length==0">
            <td colspan="10" class="nodata">Empty</td>
          </tr>
          <tr
            *ngFor="let item of inoutList | filter: searchText:15 | paginate: { itemsPerPage: itemPerPage ,currentPage: currentPage }; let index=index">
            <td class="td-edit">
              <span [title]="item.employeeid">{{item.employeeid}} </span>
            </td>
            <td class="td-edit">
              <span [title]="item.userid">{{item.userid}} </span>
            </td>
            <td class="td-edit">
              <span [title]="item.username">{{item.username}} </span>
            </td>

            <!-- <td class="td-edit">
                    <span [title]="item.department">{{item.department}} </span>
                  </td>
                  <td class="td-edit">
                    <span [title]="item.division">{{item.division}} </span>
                  </td> -->
            <td class="td-edit">
              <span [title]="item.showdate">{{item.showdate}}
              </span>
            </td>
            <td class="td-edit">
              <span [title]="item.showintime">{{item.showintime}}
              </span>
            </td>
            <!-- <td class="td-edit">
                    <span [title]="item.location">{{item.location}}
                    </span>
                  </td> -->
            <td class="td-edit">
              <a *ngIf="item.location != 'Unknown Location'"
                href="https://www.google.com/maps/search/?api=1&query={{item.latlong}}" target="_blank"
                [ngClass]="{'red' : !item.orglocation}">
                <span class="d-flex flex-column loc">
                  <div *ngIf="item.location">
                    {{item.location}}
                  </div>
                  <div class="td-location">
                    {{item.latlong}}
                  </div>
                </span>
              </a>
              <ng-container *ngIf="item.location == 'Unknown Location'">
                <span class="gray">{{ item.location }}</span>
              </ng-container>
            </td>
            <td class="td-edit">
              <span [title]="item.showouttime">{{item.showouttime}}
              </span>
            </td>
            <!-- <td class="td-edit">
                    <span [title]="item.outlocation">{{item.outlocation}}
                    </span>
                  </td> -->
            <td class="td-edit">
              <a *ngIf="item.outlocation != 'Unknown Location'"
                href="https://www.google.com/maps/search/?api=1&query={{item.outlatlong}}" target="_blank"
                [ngClass]="{'red' : !item.orglocation}">
                <span class="d-flex flex-column loc">
                  <div *ngIf="item.outlocation">
                    {{item.outlocation}}
                  </div>
                  <div class="td-location">
                    {{item.outlatlong}}
                  </div>
                </span>
              </a>
              <ng-container *ngIf="item.outlocation == 'Unknown Location'">
                <span class="gray">{{ item.outlocation }}</span>
              </ng-container>
            </td>
            <td class="td-edit">
              <span class="activity" [title]="item.activitydescription">{{item.activitydescription}}
              </span>
            </td>
            <!-- </td>
                  <td class="td-edit">
                    <span [title]="item.showtype">{{ item.showtype }}</span>
                  </td> -->
            <!-- <td class="td-edit">
                    <span [title]="item.showdate+item.showtime">{{item.showdate}} {{item.showtime}}
                    </span>
                  </td>
                  <td class="td-edit">
                    <a *ngIf="item.location != 'Unknown Location'"
                      href="https://www.google.com/maps/search/?api=1&query={{item.latlong}}" target="_blank"
                      [ngClass]="{'red' : !item.orglocation}">
                      <span class="d-flex flex-column loc">
                        <div *ngIf="item.location">
                          {{item.location}}
                        </div>
                        <div class="td-location">
                          {{item.latlong}}
                        </div>
                      </span>
                    </a>
                    <ng-container *ngIf="item.location == 'Unknown Location'">
                      <span class="gray">{{ item.location }}</span>
                    </ng-container>
                  </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>