// import { Injectable, Pipe, PipeTransform } from '@angular/core';

// @Injectable({
//   providedIn: 'root',
// })
// @Pipe({
//   name: 'memberFilter',
//   pure: false
// })
// export class MemberFilterPipe implements PipeTransform {
//   transform(
//     items: any[],
//     searchText: string,
//     searchPost: string,
//     searchDepartment: string,
//     searchDivision: string,
//     searchCostCenter: string,
//     searchStatus: string
//   ) {
//     if (!items) {
//       return [];
//     }
//     if (!searchText) {      
//       searchPost = searchPost.toLocaleLowerCase();
//       searchDepartment = searchDepartment.toLocaleLowerCase();
//       searchDivision = searchDivision.toLocaleLowerCase();
//       searchCostCenter = searchCostCenter.toLocaleLowerCase();
//       searchStatus = searchStatus.toLocaleLowerCase();
//       var returnItems: any[];

//       returnItems = items.filter((it) => {
//         return (
//           (searchPost == 'all'
//             ? true
//             : it.type.toString().toLocaleLowerCase() == searchPost) &&
//           (searchDepartment == 'all'
//             ? true
//             : it.department.toString().toLocaleLowerCase() ==
//             searchDepartment) &&
//           (searchDivision == 'all'
//             ? true
//             : it.division.toString().toLocaleLowerCase() ==
//             searchDivision) &&
//           (searchCostCenter == 'all'
//             ? true
//             : it.costcenter.toString().toLocaleLowerCase() ==
//             searchCostCenter) &&
//           (searchStatus == 'all'
//             ? true
//             : it.transactiontypestatus?.toString().toLocaleLowerCase() ==
//             searchStatus)
//         );
//       });
//       return returnItems;
//     } else {
//       searchText = searchText.toLocaleLowerCase();
//       searchPost = searchPost.toLocaleLowerCase();
//       searchDepartment = searchDepartment.toLocaleLowerCase();
//       searchDivision = searchDivision.toLocaleLowerCase();
//       searchCostCenter = searchCostCenter.toLocaleLowerCase();
//       searchStatus = searchStatus.toLocaleLowerCase();
//       var returnItems: any[];

//       returnItems = items.filter((it) => {
//         return (
//           (it.username.toString().toLocaleLowerCase().includes(searchText) ||
//             it.userid.toString().toLocaleLowerCase().includes(searchText) ||
//             it.employeeid.toString().toLocaleLowerCase().includes(searchText) ||
//             it.division?.toString().toLocaleLowerCase().includes(searchText)) &&
//           (searchPost == 'all'
//             ? true
//             : it.type?.toString().toLocaleLowerCase() == searchPost) &&
//           (searchDepartment == 'all'
//             ? true
//             : it.department?.toString().toLocaleLowerCase() ==
//             searchDepartment) &&
//           (searchDivision == 'all'
//             ? true
//             : it.division?.toString().toLocaleLowerCase() ==
//             searchDivision) &&
//           (searchCostCenter == 'all'
//             ? true
//             : it.costcenter?.toString().toLocaleLowerCase() ==
//             searchCostCenter) &&
//           (searchStatus == 'all'
//             ? true
//             : it.transactiontypestatus?.toString().toLocaleLowerCase() ==
//             searchStatus)
//         );
//       });
//       return returnItems;
//     }
//   }
// }


import { formatDate } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'memberFilter',
  pure: false,
})
export class MemberFilterPipe implements PipeTransform {
  transform(
    items: any[],
    searchText: string,
    searchPost: string,
    searchDepartment: string,
    searchDivision: string,
    searchCostCenter: string,
    searchStatus: string,
  ) {
    if (!items) {
      return [];
    }

    searchText = searchText?.toLocaleLowerCase();
    searchPost = searchPost?.toLocaleLowerCase();
    searchDepartment = searchDepartment?.toLocaleLowerCase();
    searchDivision = searchDivision?.toLocaleLowerCase();
    searchCostCenter = searchCostCenter?.toLocaleLowerCase();
    searchStatus = searchStatus?.toLocaleLowerCase();

    var todayDate = formatDate(new Date(), 'yyyyMMdd', 'en-US');
    const parsedTodayDate = parseInt(todayDate, 10);

    return items.filter((it) => {
      // Parse effective date to a comparable format (assuming the date is in YYYYMMDD format)
      const itemEffectiveDate = it.effectivedate ? parseInt(it.effectivedate, 10) : null;

      const matchesText =
        !searchText ||
        it.username?.toLocaleLowerCase().includes(searchText) ||
        it.userid?.toLocaleLowerCase().includes(searchText) ||
        it.newuserid?.toLocaleLowerCase().includes(searchText) ||
        it.employeeid?.toLocaleLowerCase().includes(searchText) ||
        it.division?.toLocaleLowerCase().includes(searchText);

      const matchesPost = searchPost === 'all' || it.type?.toLocaleLowerCase() === searchPost;
      const matchesDepartment = searchDepartment === 'all' || it.department?.toLocaleLowerCase() === searchDepartment;
      const matchesDivision = searchDivision === 'all' || it.division?.toLocaleLowerCase() === searchDivision;
      const matchesCostCenter = searchCostCenter === 'all' || it.costcenter?.toLocaleLowerCase() === searchCostCenter;

      let matchesStatus = true;
      if (searchStatus === 'all') {
        matchesStatus = true;
      } else if (searchStatus === '0') {
        matchesStatus = it.transactiontypestatus === '1' && itemEffectiveDate! >= parsedTodayDate || it.transactiontypestatus?.toString().toLocaleLowerCase() === searchStatus;
      } else if (searchStatus === '1') {
        matchesStatus = it.transactiontypestatus === '1' && itemEffectiveDate! < parsedTodayDate;
      } else {
        matchesStatus = it.transactiontypestatus?.toString().toLocaleLowerCase() === searchStatus;
      }

      return matchesText && matchesPost && matchesDepartment && matchesDivision && matchesCostCenter && matchesStatus;
    });
  }
}
