<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb *ngIf="!isAdding" s_breadcrumb="HR Request">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="isAdding" s_breadcrumb="HR Request" [t_breadcrumb]="isEditing ? 'Edit' : 'New' "
        (emit)="cancel()"></app-hcm-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="'HR Request'"></app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'HR Request'" [t_breadcrumb]="isEditing ? 'Edit' : 'New'"
        (emit)="cancel()"></app-office-breadcrumb>
    </ng-container> -->
    <div *ngIf="isFromOffice">
      <app-office-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="'HR Request'"></app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'HR Request'" [t_breadcrumb]="isEditing ? 'Edit' : 'New'"
        (emit)="cancel()"></app-office-breadcrumb>
    </div>

    <div *ngIf="!isFromOffice">
      <app-team-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="'HR Request'"></app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'HR Request'" [t_breadcrumb]="isEditing ? 'Edit' : 'New'"
        (emit)="cancel()"></app-team-breadcrumb>
    </div>

    <ng-container *ngIf="isAdding; else listView">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-custom me-2" (click)="cancel()">
            Cancel
          </button>
          <!-- (submitForm.status != '001' && isEditing) -->
          <app-button-loader *ngIf="submitForm.status == '001' || submitForm.status == ''" class="" (click)="submit()"
            [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
          </app-button-loader>
        </div>
        <div *ngIf="isEditing">
          <span [title]="submitForm.status" class="status" [ngClass]="{
          'status-gray': submitForm.status == '001',
          'status-main': submitForm.status == '002' || submitForm.status.status == '003', 
          'status-green': submitForm.status == '004',
          'status-red': submitForm.status == '005'
        }">{{
            getTableStatus(submitForm.status)
            }}</span>
        </div>
      </div>
      <div class="row">
        <!-- <div class="mb-3 col-md-6">
          <label for="name" class="form-label">Name</label>
          <input type="text" class="form-control" id="name" [(ngModel)]="submitForm.name" name="name" #name="ngModel"
            [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" required />
          <div class="invalid-feedback" *ngIf="
                        name.invalid ||
                        ((name.dirty || name.touched) && formSubmitted)
                      ">
            <div *ngIf="name.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div> -->
        <div class="mb-3 col-md-6">
          <label for="monday" class="form-label">Request Type</label>
          <select class="form-select" name="monday" id="monday" [(ngModel)]="submitForm.requesttype"
            [disabled]="gettingRequestType || (submitForm.status != '001' && isEditing)">
            <option *ngFor="let d of requestTypeList" [value]="d.id">
              {{ d.name }}
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingRequestType"></mat-progress-bar>
        </div>

        <div class="mb-3 col-md-6">
          <label for="requestdate" class="form-label">
            Request Date
          </label>
          <div class="input-group">
            <input type="text" id='requestdate' name='requestdate' class="form-control datepicker-readonly"
              placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig"
              [bsValue]="submitForm.requestdate" [(ngModel)]="submitForm.requestdate" readonly
              [disabled]="submitForm.status != '001' && isEditing ">
          </div>
        </div>

        <div class="mb-3 col-md-12">
          <label for="description" class="form-label">
            Description
          </label>
          <textarea type="text" class="form-control" id="description" name="description" placeholder="Enter Description"
            [(ngModel)]="submitForm.description" rows="3"
            [disabled]="submitForm.status != '001' && isEditing"></textarea>
        </div>

      </div>
      <div class="col-md-12 row mt-3">
        <div class="img-container col-lg-3 col-md-4 col-sm-4 col-6" *ngFor="let item of imageList; let index = index">
          <div class="img-border card">
            <img [src]="item.previewurl" (click)="allinoneService.imageViewer(imageList, index)" />
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="d-flex align-items-center mb-3">
        <button class="btn btn-custom me-auto" (click)="new()">
          <i class="fa fa-plus me-1"></i> New
        </button>


      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by User ID"
            aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="" [matMenuTriggerFor]="statusmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ filterStatusName }}
            <mat-menu #statusmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                {{ status.name }}
              </button>
            </mat-menu>
          </button>
        </div>

        <div *ngIf="filterSD && filterED"
                            class="ms-2 btn-group d-flex align-items-center" role="group">
                            <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
                                Date: {{ filterSD | date : "dd/MM/yyyy" }} -
                                {{ filterED | date : "dd/MM/yyyy" }}
                            </button>
                            <button (click)="clearDate()" type="button" class="btn btn-secondary advanced-filter-btn">
                                <img src="assets/images/icon.png" alt=""
                                    style="width: 11px; height: 11px; margin-top: -3px" />
                            </button>
                        </div>

                <div class="ms-2">
                    <button class="btn btn-custom" (click)="filter()" title="Filter"
                        [disabled]="gettingRequestType">
                        <i class="fa fa-filter"></i>
                    </button>
                </div>

        <!-- <input style="width: 200px;" class="form-control datepicker-readonly ms-3" type="text" id='requestdatefilter' name='requestdatefilter'
          placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" [bsConfig]="{showClearButton: true}" [matTooltip]="'Request Date Filter'"
          [(ngModel)]="requestDateFilter" (ngModelChange)="refresh()" readonly> -->

        <!-- <div class="line"></div>
                <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel"
                    [disabled]="dataList.length == 0">
                    <img class="excel-icon" src="../../../assets/images/excel.png" />
                    Export
                </button> -->
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (dataList | filter : searchText: 'merchant' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="dataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-2" mat-sort-header="userid">User ID</td>
              <!-- <td scope="col" class="col-md-3" mat-sort-header="requesttype">Request Type</td> -->
              <td scope="col" class="col-md-3" mat-sort-header="requesttype">Request Type</td>
              <td scope="col" class="col-md-2" mat-sort-header="requestdate">Request Date</td>
              <td scope="col" class="col-md-2" mat-sort-header="status">Status</td>
              <td scope="col" class="col-md-3" mat-sort-header="description">Description</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="tableLoading">
            <tr>
              <td colspan="5" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!tableLoading">
            <tr *ngIf="dataList.length == 0">
              <td colspan="5" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let data of dataList | filter:searchText:'userid' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
              <td class="td-data td-edit" (click)="editData(data)">
                <span [title]="data.userid">{{ data.userid }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.typeid">{{ getRequestTypeName(data.typeid) }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.requestdate">{{
                  allinoneService.formatDBToShow(data.requestdate)
                  }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.status" class="status" [ngClass]="{
                  'status-gray': data.status == '001',
                  'status-main': data.status == '002' || data.status == '003', 
                  'status-green': data.status == '004',
                  'status-red': data.status == '005'
                }">{{
                  getTableStatus(data.status)
                  }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.description">{{
                  data.description
                  }}</span>
              </td>
              <td class="d-flex justify-content-center">
                <button class="btn btn-outline-primary me-1" (click)="openHistory(data)" title="History">
                  <i class='fa fa-history'></i>
                </button>
                <button class="btn btn-outline-primary" (click)="editData(data)" title="Edit">
                  <i class='fa fa-edit'></i>
                </button>
                <!-- <button class="btn btn-outline-danger" (click)="deleteLocation(item.locid)" title="Delete">
                                  <i class='fa fa-trash-o'></i>
                              </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="dataList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>