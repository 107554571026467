import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EncrDecrServiceService } from 'projects/payroll/src/app/EncrDecrService/encr-decr-service.service';
import { HierarchyAddComponent } from 'src/app/admin/hierarchy-add/hierarchy-add.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';

@Component({
  selector: 'app-priority-form',
  templateUrl: './crm-priority-form.component.html',
  styleUrls: ['./crm-priority-form.component.css']
})
export class CrmPriorityFormComponent implements OnInit {
  organizations:any=[];
  serviceFormSubmitted:boolean = false;
  orgid:any="";
  addtype: boolean = false;
  loading:boolean =true;
  saveLoading:boolean = false;
  priorityData:any=[];
  priorityType:any="";

  constructor( private dialogRef: MatDialogRef<HierarchyAddComponent>,
    public allinoneService: AllInOneService, private encr_decr: EncrDecrServiceService,
    private kunyekService: KunyekService,) { }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
      this.getPriorityList();
  }
    getPriorityList() {
    var data = {
      domainid: this.allinoneService.getDomain().domainid,	
      orgid: this.orgid,
    }
    this.kunyekService.getPriority(data).subscribe(
      (res: any) => {
        console.log(res.list);

        if (res.returncode == '300') {
          this.priorityData = res.list;
          this.loading = false;
        }
      });

  }
  close() {
    console.log("ok")
      const data = {
        'status':true,
      }
    
    this.dialogRef.close(data);
  }
  AddNewpriority() {
    this.addtype = true;
  }

  submitService() {
    
    this.serviceFormSubmitted = true;
    if (this.priorityType == '') {
      return
    }
    this.saveLoading = true;
    var data = {
      name: this.priorityType,
      domainid: this.allinoneService.getDomain().domainid,	
      orgid: this.orgid,	
    }
    this.kunyekService.createPriorityType(data).subscribe(
      (res: any) => {
        console.log(res);
    
        if (res.returncode == '300') {
          this.priorityData.push({autoid: '', code: '', name: this.priorityType});
          this.priorityType = "";
          this.addtype = false;
          this.saveLoading = false;
        }
      },
      (err) => {
        this.addtype = false;
        this.saveLoading = false;
      });
  }
  cancel() {
    this.addtype = false;
  }
}
