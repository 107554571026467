<div class="container-fluid">
  <div class="content">
    <ng-container>
      <!-- <app-breadcrumb *ngIf="!isAdding" s_breadcrumb="User ID  Mapping">
      </app-breadcrumb>
      <app-breadcrumb *ngIf="isAdding" s_breadcrumb="User ID  Mapping" [t_breadcrumb]="isEditing ? 'Edit' : 'New' "
        (emit)="cancel()"></app-breadcrumb> -->
        <app-team-breadcrumb *ngIf="!isAdding" s_breadcrumb="User ID  Mapping">
        </app-team-breadcrumb>
        <app-team-breadcrumb *ngIf="isAdding" s_breadcrumb="User ID  Mapping" [t_breadcrumb]="isEditing ? 'Edit' : 'New' "
          (emit)="cancel()"></app-team-breadcrumb>
    </ng-container>
    <ng-container *ngIf="isAdding; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submit()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="userid" class="form-label">New User ID</label>
          <input type="text" class="form-control" id="userid" [(ngModel)]="submitForm.userid" name="userid" placeholder="+959********"
            #userid="ngModel" [ngClass]="{ 'is-invalid': formSubmitted && userid.invalid }" [disabled]="isEditing"
            required />
          <div class="invalid-feedback" *ngIf="
                      userid.invalid ||
                        ((userid.dirty || userid.touched) && formSubmitted)
                      ">
            <div *ngIf="userid.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="parentuserid" class="form-label">Old User ID</label>
          <input type="text" class="form-control" id="parentuserid" [(ngModel)]="submitForm.parentuserid"
            name="parentuserid" #parentuserid="ngModel" placeholder="+959********"
            [ngClass]="{ 'is-invalid': formSubmitted && parentuserid.invalid }" required />
          <div class="invalid-feedback" *ngIf="
                      parentuserid.invalid ||
                        ((parentuserid.dirty || parentuserid.touched) && formSubmitted)
                      ">
            <div *ngIf="parentuserid.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="d-flex align-items-center mb-3">
        <button class="btn btn-custom me-auto" (click)="new()">
          <i class="fa fa-plus me-1"></i> New
        </button>
      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by New User ID/Old User ID"
            aria-label="Search by New User ID/Old User ID" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (dataList | filter : searchText: 'useridmapping' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by New User ID/Old User ID"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="dataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-6" mat-sort-header="userid">New User ID</td>
              <td scope="col" class="col-md-6" mat-sort-header="parentid">Old User ID</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="tableLoading">
            <tr>
              <td colspan="3" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!tableLoading">
            <tr *ngIf="dataList.length == 0">
              <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let data of dataList | filter : searchText: 'useridmapping' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
              <td class="td-data td-edit" (click)="editData(data)">
                <span [title]="data.userid">{{ data.userid }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.parentid">{{
                  data.parentid
                  }}</span>
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editData(data)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deleteData(data.id, data.userid)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="dataList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>