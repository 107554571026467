<div class='container-fluid'>
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb s_breadcrumb="Leave Opening">
      </app-team-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb [s_breadcrumb]="'Leave Opening'">
      </app-office-breadcrumb>
    </ng-container> -->
    <app-office-breadcrumb *ngIf="allinoneService.appView == '001' && !isAdmin" [s_breadcrumb]="'Leave Opening'">
    </app-office-breadcrumb>
    <app-hcm-breadcrumb *ngIf="allinoneService.appView == '001' && allinoneService.isAttendanceView && isAdmin" s_breadcrumb="Leave Opening">
    </app-hcm-breadcrumb>
    <app-team-breadcrumb *ngIf="allinoneService.appView == '002' && !allinoneService.isAttendanceView" s_breadcrumb="Leave Opening">
    </app-team-breadcrumb>

    <!-- <div class="row" [ngClass]="{'row-custom' : !isMobileView }">
    
      <div class="mb-3 col-md-12 col-sm-12 col-lg-6" [ngClass]="{'row row-custom' : !isMobileView}">
      <label for="post" class="col-form-label ps-0" [ngClass]="{'custom-label' : !isMobileView}">
        Year</label>

      <div [ngClass]="{'w-75': !isMobileView , 'w-100' : isMobileView}">
        <select class="form-select" aria-label="Select Year" id="year" name="year" (change)="yearChanged2($event)"
          [(ngModel)]="selectedYear" required [disabled]="calenderListLoading">
          <option value="">
            -</option>

          <option *ngFor="let calendar of activeCalendarList;let index=index;" value="{{calendar.calendarid}}">
            {{calendar.year}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="calenderListLoading"></mat-progress-bar>
      </div>
    </div> -->
    <!-- <div class="mb-3 col-md-12 col-sm-12 col-lg-6" [ngClass]="{'row row-custom' : !isMobileView}"
      >
      <label for="department" class="col-form-label" [ngClass]="{'custom-label' : !isMobileView, 'ps-0' : isTabletView}"
        >
        Template</label>

      <div [ngClass]="{'w-75': !isMobileView , 'w-100' : isMobileView}">
        <select class="form-select" aria-label="Select Template" id="template" name="template"
          (change)="templateChange($event)" [(ngModel)]="selectedTemplate"
          [disabled]="templateListLoading || !isCalendarActive">
          <option value="">
            -</option>
          <option *ngFor="let template of templateList;let index=index;" value="{{template.templateid}}">
            {{template.name}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="templateListLoading"></mat-progress-bar>
      </div>
    </div> -->
    <!-- </div> -->
    <div class="d-flex mb-3 flex-wrap">
      <button class="btn btn-custom me-2" (click)="newLeaveOpening()">
        <i class="fa fa-plus me-1"></i> New
      </button>
      <button type="button" class="btn btn-custom me-2" (click)="importOpenings()" title="Export To Excel"
        *ngIf="importStatus != '002' && importStatus != '003' && importStatus != '004'">
        <img class="excel-icon" src="../../../assets/images/excel.png">
        Import
      </button>
      <div class="d-flex align-items-center me-2" *ngIf="importStatus == '002' || importStatus == '003'">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-outline-black" disabled>
            <i class="fas fa-circle-notch fa-spin me-2" *ngIf="importStatus == '002'"></i>
            <img src="../../../assets/images/upload.gif" width="20px" class="me-2" *ngIf="importStatus == '003'">

            {{importStatus == '002' ? 'Checking' : 'Uploading'}}
          </button>
          <button type="button" class="btn btn-outline-black" (click)="getImportStatus()">
            <i class="fas fa-sync-alt" [ngClass]="{'fa-spin' : refreshingImportStatus}"></i>
          </button>
        </div>
      </div>
      <button type="button" class="btn btn-outline-danger me-2" (click)="importOpenings(true)"
        *ngIf="importStatus == '004'" style="width: auto;">
        <i class="fas fa-exclamation-circle me-2"></i>
        Import Error
      </button>

      <div class="filtter-btn align-items-center" [matMenuTriggerFor]="calendarmenu">
        <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
          aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
          Year&nbsp;:&nbsp;{{selectedYearName}}
          <mat-menu #calendarmenu="matMenu" xPosition="before">
            <button mat-menu-item *ngFor="let calendar of activeCalendarList" (click)="yearChanged(calendar)">
              <span [style.color]="calendar.year == '-' || (calendar.active && calendar.valid) ? 'black' : 'red'">
                {{ calendar.year }}
              </span>
            </button>
          </mat-menu>
          <span *ngIf="calenderListLoading" class="spinner-border spinner-border-sm button-spinner mx-1" role="status"
            aria-hidden="true"></span>
        </button>
      </div>
    </div>
    <div class="d-flex mb-3 flex-wrap justify-content-between align-items-center">
      <div class="d-flex flex-wrap justify-content-start align-items-center ">
        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by User ID/Name" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="line "></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{memberList.length}}</span>
        </div>

        <div class="input-group mt-2 mb-1" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by User ID/Name" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <!-- <div>
          <pagination-template class="ms-auto" *ngIf="memberList.length > 0" #p="paginationApi"
          (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="
                    p.isFirstPage()
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="
                    nextDisabled
                      ? 'custom-pagination-disabled'
                      : 'custom-pagination'
                  " (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>
        </div> -->
        <!-- <div *ngIf="selectedTemplate!='' && selectedYear!=''"> -->

        <!-- </div> -->



      </div>
      <pagination-controls class="paginate" [ngClass]="isMobileView ? 'mt-2' : '' " (pageChange)="currentPage = $event"
        *ngIf="memberList.length > itemPerPage && !memberListLoading" previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>

    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="userid">User ID</td>
            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="username">Name</td>
            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="employeeid">Employee ID</td>
            <td scope="col" class="d-flex justify-content-center">Action</td>
          </tr>
        </thead>

        <tbody *ngIf="memberListLoading">
          <tr>
            <td colspan="3" class="nodata">
              <div class="spinner-border" user="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="!memberListLoading">
          <tr *ngIf="memberList.length == 0">
            <td colspan="3" class="nodata">Empty</td>
          </tr>

          <tr
            *ngFor="let user of memberList| filter:searchText:'leaveopening' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage}; let index = index">
            <td class="td-data td-edit" (click)="openLeaveOpeningForm(user)">
              <span *ngIf="!user.newuserid" [title]="user.userid">{{user.userid}}</span>
              <!-- <span *ngIf="user.newuserid" [title]="user.userid">{{user.userid}}, {{user.newuserid}}</span> -->
              <div *ngIf="user.newuserid" class="d-flex flex-column">
                <span [title]="user.userid">{{user.userid}}</span>
                <span [title]="user.newuserid">{{user.newuserid}}</span>
              </div>
            </td>

            <td class="td-data td-edit">
              <span [title]='user.username'>{{user.username}}</span>
            </td>

            <td class="td-data">
              <span [title]='user.employeeid'>
                {{user.employeeid ? user.employeeid: '-'}}
              </span>
            </td>

            <td class="d-flex justify-content-center align-items-center">
              <button class="btn btn-outline-info me-2" (click)="openHistory(user)" tooltip="History">
                <i class='fas fa-list-ul'></i>
              </button>
              <button class="btn btn-outline-primary" (click)="openLeaveOpeningForm(user)" tooltip="Edit">
                <i class='fa fa-edit'></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>