<perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>

    <div class="container-fluid">
        <div class="content">
            <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="checkModeal">
                <div class="label title" *ngIf="!customerAdd" style="font-size: 17px;">Customers</div>
                <div class="label title" *ngIf="customerAdd" style="font-size: 17px;">Add Customer</div>
                <div class="close-btn">
                    <button mat-icon-button mat-dialog-close (click)="close()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>

            <div *ngIf="!dealAdd">



                <app-crm-breadcrumb [s_breadcrumb]="'Deals'" (emit)="cancelFunction()">
                </app-crm-breadcrumb>
                <!--  (emit1)="cancels(2)"  -->
                <div class="d-flex flex-wrap">
                    <div class="showbtn me-auto  mb-3">
                        <button class="btn btn-custom" (click)="new()">
                            <i class="fa fa-plus me-1"></i> New
                        </button>


                    </div>
                    <div class="mb-2 me-2 d-flex flex-wrap align-items-center" [matMenuTriggerFor]="dealstatusMenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                            {{dealstatusname != 'Status' ? 'Status : ' : '' }} {{dealstatusname}}
                        </button>
                    </div>
                    <mat-menu #dealstatusMenu="matMenu" id="service-menu" xPosition="before">
                        <button class="mat-item" (click)="ChangeDealStatus('status')">-</button>
                        <button class="mat-item" *ngFor="let item of dealStatus" (click)="ChangeDealStatus(item)">{{item.value}}</button>
                    </mat-menu>


                    <div class="mb-2 me-2 d-flex flex-wrap align-items-center" [matMenuTriggerFor]="dealstageMenu">
                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                            {{dealstagename != 'Stage' ? 'Stage : ' : '' }} {{dealstagename}}
                        </button>
                    </div>
                    <mat-menu #dealstageMenu="matMenu" id="service-menu" xPosition="before">
                        <button class="mat-item" (click)="ChangeDealStage('stage')">-</button>
                        <button class="mat-item" *ngFor="let item of stages" (click)="ChangeDealStage(item)">{{item.name}}</button>
                    </mat-menu>



                </div>










                <div class=" d-flex flex-wrap " *ngIf="crmForm.type != '005' && !checkModeal">
                    <div class="w-100 d-flex flex-wrap ">


                        <div class=" w-100 d-flex flex-wrap me-auto ">

                            <div class="input-group me-2 mb-3" style="width: 300px !important;">
                                <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                                        <mat-icon class="c-icon">close</mat-icon>
                                    </button>
                            </div>
                            <div class="dropdown mb-3 me-2">
                                <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
                                        <mat-icon>filter_list</mat-icon>
                                    </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <div c style="text-align: center;">Page size</div>
                                    </li>
                                    <li>
                                        <hr class="dropdown-divider">
                                    </li>
                                    <li class="dropdown-item" *ngFor="let page of pg">
                                        <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==itemPerPage">
                                        <label for="pg{{page.name}}" class="col-10 p-1">
                                                {{page.name}}
                                            </label>
                                    </li>
                                </ul>
                            </div>

                            <!--  -->
                            <div class=" mb-3">
                                <button class="btn btn-custom" (click)="refreshgetData()" title="Refresh">
                                        <mat-icon>refresh</mat-icon>
                                    </button>
                            </div>


                            <div class="line mb-3"></div>
                            <div class="mb-3">
                                <button type="button" class="btn btn-custom" (click)="downloadSheetDeal()" title="Export Leads To Excel">
                                        <img class="excel-icon" src="../../../assets/images/excel.png">
                                        Export
                                    </button>
                            </div>

                            <div class="line mb-3 "></div>
                            <div class="d-flex align-items-center me-auto mb-3">
                                <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{total}}</span>
                            </div>

                            <div *ngIf="!gettingData " class="d-flex align-items-center mb-3   ">
                                <pagination-template *ngIf="data.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
                                            <span class="me-1">&laquo;</span>&nbsp;Prev
                                        </div>
                                        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                                    <span>{{ page.label }}</span>
                                            </span>
                                            <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                                <span>{{ page.label }}</span>
                                            </div>
                                        </div>
                                        <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
                                            Next&nbsp;<span class="ms-1">&raquo;</span>
                                        </div>
                                    </div>
                                </pagination-template>
                            </div>

                            <div>

                            </div>
                        </div>
                    </div>

                </div>

                <!-- <perfect-scrollbar *ngIf="checkModeal && crmForm.type == '002' " class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll> -->

                <!-- </perfect-scrollbar> -->
                <div>
                    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="dealname">Customer</td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="dealstage">Stage</td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="dealstatus">Status</td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="dealtargetdate">Target Date
                                    </td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="dealclosingdate">Closing Date
                                    </td>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="dealcreatedby">Created By
                                    </td>
                                    <td scope="col" class="d-flex justify-content-center">Action</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="7" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="data.length == 0">
                                    <td colspan="7" class="nodata" *ngIf="!dealview">Empty</td>
                                    <td style="color: red;font-size: 14px;" colspan="7" class="nodata" *ngIf="dealview"><i class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}</td>
                                </tr>
                                <tr *ngFor="let item of data | crmFilter :searchText:searchStatusdeal:searchstagedeal:4 | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index">
                                    <td class="td-data td-edit gocus" (click)="editDeal(item)">
                                        <span [title]="item.customername">{{item.customername}}</span>
                                    </td>
                                    <td class="td-data" (click)="editDeal(item)">
                                        <span [title]="item.stagename">{{item.stagename}}</span>
                                    </td>
                                    <td class="td-data" (click)="editDeal(item)">
                                        <span [title]="item.statusname" class="open-status" [ngClass]="{'closed-status-deal': item.status == '002' || item.status == '003' }">{{item.statusname}}</span>
                                    </td>
                                    <td class="td-data" (click)="editDeal(item)">
                                        <span [title]="allinoneService.formatDBToShow(item.targetdate)">{{allinoneService.formatDBToShow(item.targetdate)}}</span>
                                    </td>
                                    <td class="td-data" (click)="editDeal(item)">
                                        <span [title]="item.closingdate ? allinoneService.formatDBToShow(item.closingdate) : ''">{{item.closingdate
                                            ? allinoneService.formatDBToShow(item.closingdate) : ''}}</span>
                                    </td>
                                    <td class="td-data" (click)="editDeal(item)">
                                        <span [title]="item.createdbyname ? item.createdbyname : item.createdby">{{item.createdbyname
                                            ? item.createdbyname : item.createdby}}</span>
                                    </td>
                                    <td class="d-flex justify-content-center">
                                        <button title="Edit" class="btn btn-outline-primary" (click)="editDeal(item)">
                                            <i class='fa fa-edit'></i>
                                        </button>
                                        <button title="Delete" class="ms-2 btn btn-outline-danger" (click)="deleteDeal(item.dealid)">
                                            <i class='fa fa-trash-o'></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="data.length < itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls> -->
                <div *ngIf="!gettingData  && !checkModeal">
                    <pagination-template *ngIf="data.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
                        <div class="d-flex align-items-center justify-content-center">
                            <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
                                <span class="me-1">&laquo;</span>&nbsp;Prev
                            </div>
                            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                </span>
                                <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                    <span>{{ page.label }}</span>
                                </div>
                            </div>
                            <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
                                Next&nbsp;<span class="ms-1">&raquo;</span>
                            </div>
                        </div>
                    </pagination-template>

                </div>
            </div>






            <div *ngIf="dealAdd">
                <app-crm-deal-add [orgid]="crmForm.orgid" [stageList]="stages" [currencyType]="currencyType" [fromCustomer]="false" [fromPipeline]="false" [editData]="dataForEdit" [statusList]="dealStatus" [customerStatus]="customerStatus" (save)="complete()" (crmcancel)="cancel()"></app-crm-deal-add>
            </div>


            <!-- <div *ngIf="summaryCheck"> -->
            <!-- (summary)="summaryBack($event)" -->
            <!-- (summary)="summaryBack($event)" -->
            <!-- <app-crm-summary [checkOrg]="checkOrgB" [orgid]="crmForm.orgid" (crmcancel)="cancel()" (statussummary)="summaryBackStatus($event)">
            </app-crm-summary> -->
            <!-- app-crm-summary -->
            <!-- </div> -->
        </div>


    </div>
</perfect-scrollbar>