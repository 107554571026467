<div class="title-container mb-3">
  <div class="title">
    Members
  </div>
  <div class="close-btn">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<hr>

<mat-dialog-content>
  <div class="d-flex gap-3 mb-3">
    <div style="width: 80%">
      <input type="text" class="form-control" placeholder="Search with Name/ID" aria-label="Search"
        aria-describedby="button-addon2" [(ngModel)]="customerSearch" name="'contactsearch" id="contactsearch"
        (ngModelChange)="searchChange()">
    </div>
    <div class="col-auto">
      <div class="d-flex gap-1">
        <button type="button" class="btn btn-custom ms-2" id="togglefilter" (click)="toggleFilter()">
          <mat-icon>{{openfilter ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </button>
        <button type="button" class="btn btn-custom ms-2" (click)="search()">
          <mat-icon>{{'search'}}</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!--  advanced search component -->
  <div class="d-flex flex-column mb-3" style="gap: 10px;" *ngIf="openfilter">
    <ng-container *ngFor="let item of advancedSearchOptions; let last = last; let i = index;" style="width: 140px;">
      <div class="d-flex gap-3 flex-wrap">
        <ng-select [(ngModel)]="item.name" [items]="remaingSearchOptions" style="width: 140px;" [clearable]="false"
          (ngModelChange)="onSearchOptionChange($event, i)"></ng-select>
        <div *ngIf="item.name == 'Division'">
          <ng-select [items]="divisionList" style="width: 140px;" [(ngModel)]="item.value"></ng-select>
        </div>
        <div *ngIf="item.name == 'Status'">
          <ng-select [items]="statusList" style="width: 140px;" bindLabel="name" bindValue="id"
            [(ngModel)]="item.value"></ng-select>
        </div>
        <div *ngIf="item.name == 'Service Year'">

          <div class="d-flex flex-wrap gap-3" style="row-gap: 0 !important; align-items: center;">
            <select class="form-select" aria-label="Select status" [(ngModel)]="item.year.operator"
              style="font-size: 14px;" [style.width.px]="widthMap[item.year.operator]">
              <option *ngFor="let i of operatorList" value="{{i.code}}">{{i.name}}</option>
            </select>
            <div>
              <div class="input-group">
                <label class="input-group-text form-floating" style="width: 60px;">Year</label>
                <input type="number" aria-label="test" [(ngModel)]="item.year.start" class="form-control" name="age"
                  id="age" autocomplete="off" style="width: 60px;" onlynumber>
                <input type="number" *ngIf="item.year.operator == '6'" aria-label="test" [(ngModel)]="item.year.end"
                  class="form-control" name="age" id="age" autocomplete="off" style="width: 60px;" onlynumber>

              </div>
            </div>
            <div>

              <div class="input-group">
                <label class="input-group-text form-floating" style="width: 60px;">Month</label>
                <input type="number" aria-label="test" [(ngModel)]="item.month.start" class="form-control" name="age"
                  id="age" autocomplete="off" style="width: 60px;" onlynumber>
                <input type="number" *ngIf="item.year.operator == '6'" aria-label="test" [(ngModel)]="item.month.end"
                  class="form-control" name="age" id="age" autocomplete="off" style="width: 60px;" onlynumber>

              </div>
            </div>
          </div>
        </div>


        <div class="d-flex gap-1">
          <button class="btn btn-custom" (click)="removeAdvancedSearch(item.name)">
            <mat-icon>{{'delete'}}</mat-icon>
          </button>
          <button class="btn btn-custom" *ngIf="last" (click)="addAdvacedSearch(item.name)">
            <mat-icon>{{'add'}}</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <!--- advanced search component -->


  <div *ngIf="!isLeaveOpening">
    <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
      (scrolled)="onScrolledDown()" [perfectScrollbar]="config" class="chat-container"
      style="height: 390px !important;position: relative;" #chatPS>
      <ul class="list-group" *ngIf="customerList.length > 0">
        <div class="profile-container" *ngFor="let user of customerList">
          <span>
            <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
              [lazyLoad]="user.photo || '../../../assets/images/profile.png'">
          </span>

          <label class="d-flex align-items-center justify-content-center">
            <div class="profile-item-container">
              <div class="profile-item">
                <div class="username" style="cursor: pointer;">
                  <b>{{user.username ? user.username : user.newuserid ? user.userid + ', ' + user.newuserid :
                    user.userid}}&nbsp;<span style="color: var(--main-color);" *ngIf="user.employeeid">(
                      {{user.employeeid}} )</span></b>

                </div>
                <div style="color: grey;
                                margin-top: 1px;
                                font-size: 13.5px;" *ngIf="user.username != ''">{{user.userid}}{{user.newuserid ? ', '
                  + user.newuserid : ''}}</div>
              </div>
            </div>
            <input type="checkbox" class="form-check-input" [id]="user.primarykey" [checked]="user.checked"
              (change)="selectUser($event,user)">
          </label>

        </div>
      </ul>

      <ng-container *ngIf="gettingCustomerList">
        <div *ngFor="let i of [1,2,3,4]" class="d-flex align-items-center flex-row">
          <div class="me-3">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
          'width.px' : 40, 
          'height.px': 40}"></ngx-skeleton-loader>
          </div>
          <div class="d-flex flex-column">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          'width.px' : 150, 
          'height.px': 10}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 100, 
            'height.px': 10}"></ngx-skeleton-loader>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!gettingCustomerList && customerList.length == 0"
        class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
        <div>
          No Contacts
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="isLeaveOpening">
    <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
      (scrolled)="onScrolledDown()" [perfectScrollbar]="config" class="chat-container"
      style="height: 390px !important;position: relative;" #chatPS>
      <ul class="list-group" *ngIf="customerList.length > 0">
        <div class="profile-container" *ngFor="let user of customerList" (click)="openLeaveOpeningForm(user)">
          <span>
            <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
              [lazyLoad]="user.photo || '../../../assets/images/profile.png'">
          </span>

          <label class="d-flex align-items-center justify-content-center" style="cursor: pointer;">
            <div class="profile-item-container">
              <div class="profile-item">
                <div class="username">
                  <b>{{user.username ? user.username : user.newuserid ? user.userid + ', ' + user.newuserid :
                    user.userid}}&nbsp;<span style="color: var(--main-color);" *ngIf="user.employeeid">(
                      {{user.employeeid}} )</span></b>

                </div>
                <div style="color: grey;
                                margin-top: 1px;
                                font-size: 13.5px;" *ngIf="user.username != ''">{{user.userid}}{{user.newuserid ? ', '
                  + user.newuserid : ''}}</div>
              </div>
            </div>
          </label>

        </div>
      </ul>

      <ng-container *ngIf="gettingCustomerList">
        <div *ngFor="let i of [1,2,3,4]" class="d-flex align-items-center flex-row">
          <div class="me-3">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
          'width.px' : 40, 
          'height.px': 40}"></ngx-skeleton-loader>
          </div>
          <div class="d-flex flex-column">
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
          'width.px' : 150, 
          'height.px': 10}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 100, 
            'height.px': 10}"></ngx-skeleton-loader>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!gettingCustomerList && customerList.length == 0"
        class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
        <div>
          No Contacts
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" class="btn btn-custom me-2" (click)="close('')">
    Cancel
  </button>
  <app-button-loader (click)="done()" [button_text]="'Done'" [load_button_text]="''"></app-button-loader>
</mat-dialog-actions>