import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/shared/services/message.service';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { AttendanceQrSetupComponent } from '../attendance-qr-setup/attendance-qr-setup.component';
import { Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-attendance-qr',
  templateUrl: './attendance-qr.component.html',
  styleUrls: ['./attendance-qr.component.scss']
})
export class AttendanceQrComponent implements OnInit {
  isMobileView: boolean = false;

  orgid: string = '';
  searchText: string = '';
  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  p: number = 1;
  isAdmin: boolean = false;
  organizations: any = [];
  isFocus: boolean = false;

  attQrListLoading = false;
  getAttListSub! : Subscription;
  attQrList :any = [];
  locations : any = [];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    // {
    //   name: 'All items',
    //   count: 0,
    // },
  ];

  constructor(
    public allinoneService : AllInOneService,
    private kunyekService : KunyekService,
    private messageService: MessageService,
    private route : ActivatedRoute,
    private router : Router,
    private dialog : MatDialog
  ) { 
    this.route.data.subscribe((data) => {
      if (!this.allinoneService.isAdminOf('org')) {
        this.router.navigateByUrl('/home');
      }
    })
   }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg ? this.allinoneService.selectedOrg : this.organizations[0].orgid;
    this.getAttendanceQrList();
    this.getLocation();
  }

  openSetupForm(data? : any) {
    const dialog = this.dialog.open(AttendanceQrSetupComponent, {
      width : '500px',
      maxHeight : '95vh',
      maxWidth : '95vw',
      closeOnNavigation : true,
      autoFocus : false,
      panelClass: ['mat-typography'],
      data : {
        orgid : this.orgid,
        isEditing : data ? true : false,
        locations : this.locations,
        attQrData : data,
      }
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      if(dialogResult) {        
        if(data) {
          data.name = dialogResult.name,
          data.locid = dialogResult.locid,
          data.qrid = dialogResult.qrid,
          data.locname = dialogResult.locname,
          data.showhistory = dialogResult.showhistory,
          data.defaulttype = dialogResult.defaulttype
        } else {
          this.attQrList.push(dialogResult);
          // this.pg[3].count = this.attQrList.length;
        }
        
      }
    })
  }

  getAttendanceQrList() {
    this.attQrListLoading = true;
    const data = {
      orgid : this.orgid,
      qrid : ''
    }
    
    this.getAttListSub && this.getAttListSub.unsubscribe();
    this.getAttListSub = this.kunyekService.getAttendanceQr(data).subscribe(
      (res : any) => {
        if(res.returncode == '300') {
          this.attQrList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.attQrListLoading = false;
      },
      (err) => {
        this.attQrListLoading = false;
      }
    )
  }

  deleteAttQr(id : string, index : number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      if(dialogResult) {
        this.attQrListLoading = true;
        const data = {
          qrid: id,
          orgid : this.orgid,
        };
        this.kunyekService.deleteAttendanceQr(data).subscribe(
          (res : any) => {
            if(res.returncode == '300') {
              // this.attQrList.splice(index, 1);
              var i = this.attQrList.findIndex(
                (x: any) => x.qrid == id
              );
              this.attQrList.splice(i, 1);
              // this.pg[3].count -= 1;
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.attQrListLoading = false;
          },
          (err) => {
            this.attQrListLoading = false;
          }
        )
      }
    })
  }

  goToQR(item: any) {    
    console.log(item.qrid);
    console.log(this.orgid);

    const eqrid = this.allinoneService.encrypt(item.qrid);
    const eorgid = this.allinoneService.encrypt(this.orgid);
    const euserid = this.allinoneService.encrypt(this.allinoneService.getKunyekUserId());
    const eatoken = this.allinoneService.encrypt(this.allinoneService.getAtoken());
    const edomainid = this.allinoneService.encrypt(this.allinoneService.getDomain().domainid);
    
    // this.router.navigateByUrl('attendanceqr/' + item.qrid);    
    const qrdata = encodeURIComponent(eqrid);
    const orgid = encodeURIComponent(eorgid);
    const userid = encodeURIComponent(euserid);
    const atoken = encodeURIComponent(eatoken);
    const domainid = encodeURIComponent(edomainid);

    window.open(`attendanceqr/${domainid}/${orgid}/${qrdata}`, '_blank')
  }

  getLocation() {
    // data = {
    //   "global": "",
    //   "domainid": this.eventForm.domain,
    //   "orgid": [this.eventForm.org],
    //   "userid": ""
    // }
    // if (this.role == "300" || this.eventForm.org != "") {
    var data = {
        "global": "",
        "domainid": "",
        "orgid": [this.orgid],
        "personal": ""
      }
    // }
    this.kunyekService.getLocations(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((loc: any) => {
          this.locations.push({
            locid: loc.locid,
            lat: loc.lat,
            lng: loc.lng,
            name: loc.name,
            range: loc.range,
            type: loc.type,
            typeid: loc.typeid,
            description: loc.description
          })
        }
        )
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }

  clear() {
    this.searchText = '';
  }

  sortData(sort : Sort) {
    const data = this.attQrList;
    if (!sort.active || sort.direction === '') {
      this.attQrList = data;
      return;
    }

    this.attQrList = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(
            a.name,
            b.name,
            isAsc
          )
        default:
          return 0;
      }
    })

  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

}
