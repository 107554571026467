import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { IMyDpOptions } from 'mydatepicker';

@Component({
  selector: 'app-batch-detail',
  templateUrl: './batch-detail.component.html',
  styleUrls: ['./batch-detail.component.scss']
})
export class BatchDetailComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy'
  };

  isSaving: boolean = false;
  batchForm!: FormGroup;
  saved = false
  id!: string;
  requiredMessage = 'This field must not be empty'

  breadcrumbs: any[] = [{ name: 'Swim', url: 'swim', isActive: true }, { name: 'Batch', url: 'members/batchlist', isActive: true }, { name: '', url: '', isActive: false }];


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '');
    this.batchForm = this.fb.group({
      syskey: ['', Validators.required],
      batch_no: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      time: ["", Validators.required],
      batch_type: ["", Validators.required],
      remark: [''],
    });
    this.getBatchById();
  }


  getBatchById() {
    this.kunyek.getBatch().get({ id: this.id }).subscribe({
      next: (res) => {
        const data = res.data;
        if (data) {
          this.batchForm.patchValue({
            syskey: data.syskey,
            batch_no: data.batch_no,
            start_date: this.allInOneService.changeStringToDateobject(data.start_date),
            end_date: this.allInOneService.changeStringToDateobject(data.end_date),
            time: data.time,
            batch_type: data.batch_type,
            remark: data.remark
          });
          this.breadcrumbs[2].name = data.batch_no;
        }
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to get batch detail', 'warn');
      }
    });
  }

  onSaved() {
    this.isSaving = true;
    this.saved = true;
    const formattedStartDate = this.start_date?.value?.formatted || this.start_date?.value;
    const formattedEndDate = this.end_date?.value?.formatted || this.end_date?.value;
    const formData = {
      syskey: this.syskey?.value,
      batch_no: this.batch_no?.value,
      start_date: this.allInOneService.convertDate(formattedStartDate),
      end_date: this.allInOneService.convertDate(formattedEndDate),
      time: this.time?.value,
      batch_type: this.batch_type?.value,
      remark: this.remark?.value
    };




    if (this.batchForm.valid) {
      this.kunyek.getBatch().update(formData).subscribe({
        next: (res) => {
          switch (res.returncode) {
            case "301":
              this.isSaving = false
              this.messageService.openSnackBar(res.message, 'warn')
              break;
            default:
              this.isSaving = false;
              this.messageService.openSnackBar('Success!', 'success')
              this.closeForm();
          }
        },
        error: (err) => {
          console.error('Error saving member:', err);
          this.messageService.openSnackBar('Failed to save batch', 'warn');
          this.isSaving = false;
        }
      });
    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false;
    }
  }

  closeForm() {
    this.router.navigate(['members', 'batchlist']);
  }

  get batch_no() { return this.batchForm.get('batch_no'); }
  get start_date() { return this.batchForm.get('start_date'); }
  get end_date() { return this.batchForm.get('end_date'); }
  get time() { return this.batchForm.get('time'); }
  get batch_type() { return this.batchForm.get('batch_type'); }
  get remark() { return this.batchForm.get('remark'); }
  get syskey() { return this.batchForm.get('syskey'); }
}
