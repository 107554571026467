import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoCarwayComponent } from '../user-info-carway/user-info-carway.component';
import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { iif } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-car-way-schedule',
  templateUrl: './car-way-schedule.component.html',
  styleUrls: ['./car-way-schedule.component.scss'],
})
export class CarWayScheduleComponent implements OnInit {
  gettingData: boolean = false;
  vehicleList: any = [];
  pickupList: any = [];
  dropList: any = [];
  carwayList: any = [];
  carwayscheduleList: any = [];
  addcarway: boolean = false;
  saveLoading: boolean = false;
  gettingVehicle: boolean = false;
  carwayFormSave: boolean = false;
  isEditing: boolean = false;
  getCarwayschedule: boolean = false;
  orgid: any = '';
  organizations: any = [];
  searchText: any = '';
  removeidlist: any = [];

  itemPerPage: any = '';
  currentPage: any = 1;
  text1: any = '';
  text2: any = '';
  searchdate: any = "";

  passengercount: any = 0;

  maxpassenger: any = 0;
  dropoffpassengercount: any = 0;
  checkgoing: boolean = false;
  checkreturn: boolean = false;
  getvehicle: boolean = false;
  getpickuppoint: boolean = false;

  getttingoutstanding: boolean = false;
  allpassengerRequestList: any = [];
  allpassengerUserList: any = [];
  typelist = [
    {
      key: '001',
      value: 'Ferry',
    },
    {
      key: '002',
      value: 'Day Car Way',
    },
  ];

  typerequest: any = "001";



  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  carwayForm = {
    name: '',
    description: '',
    vehicleid: '',
    startdate: '',
    enddate: '',
    startdatetime: '',
    enddatetime: '',
    carwayid: '',
    remark: '',
    driverid: '',
    date: '',
  };
  // formatToShow = 'yyyy-MM-dd HH:mm:ss';
  // this.allinoneService.formatDBToShow(member.joineddate)
  formatToShow = 'yyyy-MM-dd';
  formatsearch = 'yyyyMMdd';
  apitimeFormat = 'hh:mm:ss aa';
  format: any = 'HH:mm';
  sendformat: any = 'hh:mm aa';
  getdroppoint: boolean = false;
  typename: any = "All";
  trantype: any = "";
  pickupid: any = "";
  pickupvalue: any = "";

  dropoffid: any = "";
  dropoffvalue: any = "";


  pickuptimelist: any = [
    {
      pickuppoint: '',
      name: '',
      time: formatDate(Date.now(), this.format, 'en-US').toString(),
      userlist: [],
      reqidlist: [],
    },
  ];

  dropoffpointtimelist: any = [];
  driverList: any = [];
  checkdriver: boolean = false;



  istransoprationAdmin: boolean = false;
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.istransoprationAdmin =
      this.allinoneService.isAdminOf('transportation');
    if (this.istransoprationAdmin == false) {
      this.router.navigateByUrl(`transportation`);
    }

    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;


  }

  ngOnInit(): void {

    this.searchdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.carwayForm.startdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.carwayForm.enddate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.getCarWay();
    this.getVehicle();
    this.getPickupPoint();
    this.getDropoffpoint();
    this.getDriver();
  }

  new() {
    this.addcarway = true;
    this.carwayForm.carwayid = '';
    this.carwayForm.name = '';
    this.carwayForm.description = '';
    this.carwayForm.vehicleid = '';
    this.carwayForm.enddatetime = '';
    this.checkgoing = false;
    this.checkreturn = false;
    this.carwayForm.driverid = "";
    this.carwayFormSave = false;
    this.carwayForm.remark = "";
    this.pickuptimelist = [];
    this.dropoffpointtimelist = [];
    this.maxpassenger = 0;
    this.passengercount = 0;
    this.dropoffpassengercount = 0;
    this.removeidlist = [];
    this.typerequest = "001";
    console.log(Date.now());
    this.pickuptimelist.push({
      pickuppoint: '',
      name: '',
      userlist: [],
      time: formatDate(Date.now(), this.format, 'en-US').toString(),
      reqidlist: []
    });
    this.carwayForm.startdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.carwayForm.enddate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.carwayForm.startdatetime = formatDate(
      Date.now(),
      this.format,
      'en-US'
    ).toString();
    console.log(this.carwayForm.startdatetime);
    this.carwayForm.enddatetime = formatDate(
      Date.now(),
      this.format,
      'en-US'
    ).toString();
    this.getoutstandingrequestList();
  }

  cancelFunction() {
    this.addcarway = false;
    this.isEditing = false;
    this.carwayFormSave = false;
  }



  submitCarWay() {
    this.carwayFormSave = true;
    console.log(this.carwayForm.name);
    console.log(this.carwayForm.description);
    console.log(this.carwayForm.vehicleid);
    if (
      this.carwayForm.name == '' ||
      this.carwayForm.description == '' ||
      this.carwayForm.vehicleid == ''
    ) {
      if (this.checkdriver) {
        if (this.carwayForm.driverid == '') {
          return;
        }
      }
      return;
    } else {

      var startdate = formatDate(
        new Date(this.carwayForm.startdate),
        this.formatsearch,
        'en-US'
      ).toString();

      var enddate = formatDate(
        new Date(this.carwayForm.enddate),
        this.formatsearch,
        'en-US'
      ).toString();



      if (startdate > enddate) {
        this.messageService.openSnackBar("Please select the valid end date!", 'warn');
        return;
      }
      var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      var stD = new Date(atFormDate + 'T' + this.carwayForm.startdatetime);
      var etD = new Date(atFormDate + 'T' + this.carwayForm.enddatetime);

      var start = formatDate(stD, this.sendformat, 'en-US').toString();
      var end = formatDate(etD, this.sendformat, 'en-US').toString();

      if (stD > etD) {
        this.saveLoading = false;
        this.messageService.openSnackBar(
          'Please select the valid end time!',
          'warn'
        );
        return;
      }


      var senArry = [];
      var sendropoff: any = [];
      console.log(this.pickuptimelist);
      for (let i = 0; i < this.pickuptimelist.length; i++) {
        var time = new Date(atFormDate + 'T' + this.pickuptimelist[i]['time']);
        var sendT = formatDate(time, this.sendformat, 'en-US').toString();
        var useridlistpick = [];
        for (let j = 0; j < this.pickuptimelist[i]['userlist'].length; j++) {
          useridlistpick.push(this.pickuptimelist[i]['userlist'][j]['userid']);
        }
        // [ 
        // {
        // "reqeuestid": "1234"
        // "userid": "1234"
        // }
        // {
        // "reqeuestid": "1234"
        // "userid": "1234"
        // }
        // ]

        var arr2: any = {};
        var reqidlist = this.pickuptimelist[i]['reqidlist'];
        console.log(reqidlist);
        if (this.typerequest == "001") {
          reqidlist.forEach((opt: { requestid: any; userid: any; }) => {
            const objj: any = {};
            objj[opt.requestid] = [opt.userid];
            Object.assign(arr2, objj);
          });
        } else {
          var req = this.pickuptimelist[i]['reqidlist'];
          var value = "";
          var userlist = [];
          for (let i = 0; i < req.length; i++) {
            if (req[i]['requestid'] == value) {
              // userlist.push(req[i]['userid'])
              arr2[req[i]['requestid']].push(req[i]['userid']);
            } else {
              console.log("222222")
              var userlist1 = [];
              userlist1.push(req[i]['userid'])
              arr2[req[i]['requestid']] = userlist1
            }

            value = req[i]['requestid'];
            console.log(value + 123);
          }
        }

        senArry.push({
          time: sendT,
          pickuppointid: this.pickuptimelist[i]['pickuppoint'],
          useridlist: useridlistpick,
          pointtype: "001",
          name: this.pickuptimelist[i]['name'],
          id: this.pickuptimelist[i]['id'] != undefined ? this.pickuptimelist[i]['id'] : ''
          // requestidlist: arr2
        });
      }

      console.log(senArry);






      if (this.dropoffpointtimelist.length > 0) {
        for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
          var time = new Date(atFormDate + 'T' + this.dropoffpointtimelist[i]['time']);
          var sendT = formatDate(time, this.sendformat, 'en-US').toString();
          var useridlistdrop = [];
          for (let j = 0; j < this.dropoffpointtimelist[i]['userlist'].length; j++) {
            useridlistdrop.push(this.dropoffpointtimelist[i]['userlist'][j]['userid']);
          }

          var arr2: any = {};
          var reqidlist = this.dropoffpointtimelist[i]['reqidlist'];
          if (this.typerequest == "001") {
            reqidlist.forEach((opt: { requestid: any; userid: any; }) => {
              const objj: any = {};
              objj[opt.requestid] = [opt.userid];
              Object.assign(arr2, objj);
            });
          } else {
            var req = this.dropoffpointtimelist[i]['reqidlist'];
            var value = "";
            var userlist = [];
            for (let i = 0; i < req.length; i++) {
              if (req[i]['requestid'] == value) {
                // userlist.push(req[i]['userid'])
                arr2[req[i]['requestid']].push(req[i]['userid']);
              } else {
                console.log("222222")
                var userlist1 = [];
                userlist1.push(req[i]['userid'])
                arr2[req[i]['requestid']] = userlist1
              }

              value = req[i]['requestid'];
              console.log(value + 123);
            }
          }
          senArry.push({
            time: sendT,
            pickuppointid: this.dropoffpointtimelist[i]['dropoffpoint'],
            useridlist: useridlistdrop,
            pointtype: "002",
            // requestidlist: arr2,
            name: this.dropoffpointtimelist[i]['name'],
            id: this.dropoffpointtimelist[i]['id'] != undefined ? this.dropoffpointtimelist[i]['id'] : ''
          });
        }
      }

      console.log(this.dropoffpointtimelist);
      if (senArry.length == 0) {
        this.messageService.openSnackBar(
          'Please add at least one Pickup Point.',
          'warn'
        );
      }

      console.log(senArry)
      console.log("lllllll..........");
      if (senArry.length > 0) {
        for (let i = 0; i < senArry.length; i++) {
          if (senArry[i]['pickuppointid'] == '' && senArry[i]['name'] == '') {
            this.messageService.openSnackBar(
              'Please fill all Points.',
              'warn'
            );
            return;
          }
        }

      }
      if (senArry.length > 0) {
        for (let i = 0; i < senArry.length; i++) {
          if (senArry[i]['useridlist'].length == 0) {
            this.messageService.openSnackBar(
              'Please fill at least one passenger for each pickup point.',
              'warn'
            );
            return;
          }
        }
      }
      this.saveLoading = true;
      var data = {
        orgid: this.orgid,
        domainid: this.allinoneService.getDomain().domainid,
        name: this.carwayForm.name,
        description: this.carwayForm.description,
        vehicleid: this.carwayForm.vehicleid,
        startdate: this.isEditing ? "" : startdate,
        enddate: this.isEditing ? "" : enddate,
        starttime: start,
        endtime: end,
        pickuppointlist: senArry,
        dropoffpointlist: sendropoff,
        trantype: this.typerequest,
        remark: this.carwayForm.remark,
        driverid: this.checkdriver == true ? this.carwayForm.driverid : "",
        going: this.checkgoing,
        return: this.checkreturn

      };
      console.log("okok>>>>>>>>>>");
      console.log(data);

      if (this.isEditing) {
        data = Object.assign(data, {
          carwayid: this.carwayForm.carwayid,
          removeidlist: this.removeidlist,
        });
      }

      if (this.isEditing == true) {
        this.kunyekService.updatecarway(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.addcarway = false;
              this.saveLoading = false;
              this.isEditing = false;
              // this.getCarWay();
              this.refreshgetData();

            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      } else {
        this.kunyekService.addcarway(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.addcarway = false;
              this.isEditing = false;
              this.saveLoading = false;
              // this.getCarWay();
              this.refreshgetData();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      }
    }
  }
  getVehicle() {
    this.getvehicle = true;
    this.vehicleList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getVehicle(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.vehicleList.push({
              carid: item.vehicleid,
              carno: item.vehicleno == "" ? item.name : item.vehicleno,
              maxpassenger: item.maxpassenger,
              vehicletype: item.vehicletype,
              name: item.name,
            });
          });
          this.getvehicle = false;
        } else {
          this.getvehicle = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getvehicle = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }
  getDriver() {
    this.gettingData = true;
    this.driverList = [];
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getDriver(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.driverList.push({
              "driverid": item.driverid,
              "name": item.name,
              "mobile": item.mobile,
            });
          });
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  getCarWay() {
    this.gettingData = true;
    this.carwayList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      searchdate: formatDate(
        new Date(this.searchdate),
        this.formatsearch,
        'en-US'
      ).toString(),
      trantype: this.trantype,
    };
    console.log("okok");
    console.log(data)
    this.kunyekService.getcarway(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res);
          res.datalist.map((item: any) => {
            this.carwayList.push({
              name: item.name,
              description: item.description,
              vehicleno: item.vehicleno,
              vehiclename: item.vehiclename,
              vehicletype: item.vehicletype,
              carwayid: item.carwayid,
              vehicleid: item.vehicleid,
              starttime: item.starttime,
              endtime: item.endtime,
              createddate: item.createddate,
              drivername: item.drivername,
              driverphone: item.driverphone,
              startdate: item.startdate,
              enddate: item.enddate,
              trantype: item.trantype,
              going: item.going,
              return: item.return,
              driverid: item.driverid,
              remark: item.remark,
              date: item.date
            });
          });
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  getCarWayschedule(carwayid: any) {
    this.getCarwayschedule = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      carwayid: carwayid,
    };
    console.log(data);
    this.kunyekService.getcarwayshcedule(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.pickuptimelist = [];
          this.removeidlist = [];
          this.dropoffpointtimelist = [];
          console.log(res.datalist);
          for (let i = 0; i < res.datalist.length; i++) {

            if (res.datalist[i]['pointtype'] == '001') {


              for (let j = 0; j < this.pickupList.length; j++) {
                if (
                  this.pickupList[j]['pickuppointid'] ==
                  res.datalist[i]['pickuppointid']
                ) {

                  let atFormDate = this.allinoneService.formatDBToShowInEdit(this.allinoneService.getCurrentDateToDB(), '/');
                  var stD = new Date(atFormDate + ' ' + res.datalist[i]['time']);
                  var start = formatDate(stD, this.format, 'en-US').toString();
                  this.pickuptimelist.push({
                    name: this.pickupList[j]['name'],
                    pickuppoint: res.datalist[i]['pickuppointid'],
                    time: start,
                    user: '',
                    userlist: res.datalist[i]['userdatalist'],
                    reqidlist: res.datalist[i]['userdatalist'],
                    id: res.datalist[i]['id']

                  });
                  this.passengercount += res.datalist[i]['userdatalist'].length;
                }
              }
            } else if (res.datalist[i]['pointtype'] == '002') {
              // this.typerequest = "002";

              for (let j = 0; j < this.dropList.length; j++) {
                if (
                  this.dropList[j]['dropoffpointid'] ==
                  res.datalist[i]['pickuppointid']
                ) {
                  console.log("jiwejfiwejfi" + this.typerequest);
                  let atFormDate = this.allinoneService.formatDBToShowInEdit(this.allinoneService.getCurrentDateToDB(), '/');
                  var stD = new Date(atFormDate + ' ' + res.datalist[i]['time']);
                  var start = formatDate(stD, this.format, 'en-US').toString();
                  this.dropoffpointtimelist.push({
                    name: this.dropList[j]['name'],
                    dropoffpoint: res.datalist[i]['pickuppointid'],
                    time: start,
                    user: '',
                    userlist: res.datalist[i]['userdatalist'],
                    reqidlist: res.datalist[i]['userdatalist'],
                    id: res.datalist[i]['id']

                  });
                  this.dropoffpassengercount += res.datalist[i]['userdatalist'].length;
                }
              }
            }


          }
          console.log(this.pickuptimelist);
          console.log(this.dropoffpointtimelist);
          console.log("jiwjefiwjejjjjjjjjjjjjjjj");
          this.getCarwayschedule = false;
        } else {
          this.getCarwayschedule = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.getCarwayschedule = false;
      }
    );
  }



  getPickupPoint() {
    this.getpickuppoint = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      type: "001",
    };
    this.kunyekService.getPickup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.pickupList = [];
          res.datalist.map((item: any) => {
            this.pickupList.push({
              name: item.name,
              user: '',
              usercounts: item.usercounts,
              pickuppointid: item.pickuppointid,
            });
          });
          this.getpickuppoint = false;
        } else {
          this.getpickuppoint = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getpickuppoint = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }
  getDropoffpoint() {
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      type: "002",
    };
    this.kunyekService.getPickup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dropList = [];
          res.datalist.map((item: any) => {
            this.dropList.push({
              name: item.name,
              user: '',
              usercounts: item.usercounts,
              dropoffpointid: item.pickuppointid,
            });
          });
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  customerSelect(data: any) {
    console.log(data);
  }

  carwayedit(data: any) {
    console.log(data);
    this.isEditing = true;

    this.maxpassenger = 0;
    this.passengercount = 0;
    this.dropoffpassengercount = 0;
    var formatToShow = 'dd/MM/YYYY';
    // formatToShow = 'yyyy-MM-dd';


    var format = 'HH:mm';
    var sendformat = 'hh:mm aa';



    let atFormDate = this.allinoneService.formatDBToShowInEdit(this.allinoneService.getCurrentDateToDB(), '/');
    let StartTime = new Date(atFormDate + ' ' + data.starttime);
    let EndTime = new Date(atFormDate + ' ' + data.endtime);
    this.carwayForm.startdatetime = formatDate(
      StartTime,
      format,
      'en-US'
    ).toString();
    this.carwayForm.enddatetime = formatDate(
      EndTime,
      format,
      'en-US'
    ).toString();



    // this.carwayForm.startdate = this.allinoneService.formatDBToShowInEdit(data.startdate);
    // this.carwayForm.enddate = this.allinoneService.formatDBToShowInEdit(data.enddate);
    console.log(data.date);
    this.carwayForm.date = this.allinoneService.formatDBToShowInEdit(data.date);
    this.carwayForm.startdate = this.allinoneService.formatDBToShowInEdit(data.date);
    this.carwayForm.enddate = this.allinoneService.formatDBToShowInEdit(data.date);
    if (data.trantype == "001") {
      this.typerequest = "001";
    } else if (data.trantype == "002") {
      this.typerequest = "002";
    }
    console.log(data.vehicleid);
    console.log(">>>>>>>>>>>>>>");
    console.log(this.vehicleList);

    var type = this.vehicleList.map((x: any) => x.carid).indexOf(data.vehicleid);
    console.log(type);
    console.log(this.vehicleList);
    if (type > -1) {
      if (this.vehicleList[type]['vehicletype'] == "001") {
        this.checkdriver = true;
      } else {
        this.checkdriver = false;
      }

    }


    this.carwayForm.name = data.name;
    this.carwayForm.driverid = data.driverid;
    this.checkgoing = data.going;
    this.checkreturn = data.return;
    this.carwayForm.description = data.description;
    this.carwayForm.vehicleid = data.vehicleid;
    this.carwayForm.carwayid = data.carwayid;
    this.carwayForm.remark = data.remark;

    for (var i = 0; i < this.vehicleList.length; i++) {
      if (this.carwayForm.vehicleid == this.vehicleList[i]['carid']) {
        this.maxpassenger = this.vehicleList[i]['maxpassenger'];
      }
    }
    this.pickuptimelist = [];
    this.getoutstandingrequestList();

    this.getCarWayschedule(data.carwayid);
  }

  deletecarway(data: any) {
    var carwayid = data.carwayid;

    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingData = true;
        var data: any = {
          "domainid": this.allinoneService.getDomain().domainid,
          "orgid": this.orgid,
          "carwayid": carwayid,
        };

        this.kunyekService.deletecarway(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.addcarway = false;
              this.isEditing = false;
              // this.getCarWay();
              this.refreshgetData();

            } else {
              this.messageService.openSnackBar(res.message, 'warn');
              this.gettingData = false;
            }
          }, (err) => {
            this.gettingData = false;
          }

        );
      }
    });


  }

  refreshgetData() {
    this.getCarWay();
    this.getPickupPoint();
    this.getDropoffpoint();
    this.getVehicle();

  }
  startdatechange() {
    this.carwayForm.enddate = formatDate(this.carwayForm.startdate, this.formatToShow, 'en-US').toString();
    this.getoutstandingrequestList();
  }

  enddatechange() {
    this.getoutstandingrequestList();
  }



  changePageCount(event: any) {
    console.log(this.itemPerPage);
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage);
    this.currentPage = 1;
    this.refreshgetData();
  }

  vehicleSelect(data: any) {
    console.log(data);

    if (data != undefined) {
      this.carwayForm.vehicleid = data.carid;
      if (data.vehicletype == "001") {
        this.checkdriver = true;
      } else {
        this.checkdriver = false;
      }
    } else {
      this.carwayForm.vehicleid = '';

    }

    this.maxpassenger = parseInt(data.maxpassenger);
  }
  removePickup(index: any) {
    if (this.pickuptimelist.length > 1) {
      if (this.pickuptimelist[index]['userlist'] != undefined) {
        var arr = JSON.parse(JSON.stringify(this.pickuptimelist[index]['userlist']));
        var droparr = [];
        for (let j = 0; j < this.dropoffpointtimelist.length; j++) {
          for (let i = 0; i < this.dropoffpointtimelist[j]['userlist'].length; i++) {
            droparr.push(this.dropoffpointtimelist[j]['userlist'][i]);
          }
        }
        console.log(droparr);
        for (let i = 0; i < arr.length; i++) {
          var item = droparr.filter((el: any) => el.userid == arr[i]['userid']).length;
          if (item > 0) {
            this.messageService.openSnackBar("Passengers already exist at Drop Off Point", 'warn');
            return;
          }
        }
        this.passengercount =
          this.passengercount - this.pickuptimelist[index]['userlist'].length;
      }
      console.log(this.pickuptimelist[index]);
      if (this.pickuptimelist[index] != undefined) {
        this.removeidlist.push(this.pickuptimelist[index]['id']);
      }


      this.pickuptimelist.splice(index, 1);

    }
    console.log(this.passengercount);
    console.log(this.removeidlist);
  }

  removedropup(index: any) {
    console.log(this.dropoffpointtimelist[index]);
    if (this.dropoffpointtimelist.length > 1) {
      if (this.dropoffpointtimelist[index]['userlist'] != undefined) {
        this.dropoffpassengercount = this.dropoffpassengercount - this.dropoffpointtimelist[index]['userlist'].length;
      }
      console.log(this.dropoffpointtimelist[index]);
      if (this.dropoffpointtimelist[index] != undefined) {
        this.removeidlist.push(this.dropoffpointtimelist[index]['id']);

      }
      this.dropoffpointtimelist.splice(index, 1);

    }
    console.log(this.dropoffpassengercount);
    console.log(this.removeidlist);
  }


  addpickupdata() {
    console.log(this.pickuptimelist);
    this.pickuptimelist.push({
      pickuppoint: '',
      name: '',
      userlist: [],
      time: formatDate(Date.now(), this.format, 'en-US').toString(),
      reqidlist: []
    });
  }

  adddropoffpointdata() {
    this.dropoffpointtimelist.push({
      dropoffpoint: '',
      name: '',
      userlist: [],
      time: formatDate(Date.now(), this.format, 'en-US').toString(),
      reqidlist: []
    });
  }



  pickupselect(data: any, index: any) {
    this.pickupid = data.pickuppointid;
    this.pickupvalue = data.name;
    for (let j = 0; j < this.pickuptimelist.length; j++) {
      console.log(this.pickupid);
      console.log(this.pickuptimelist[j]['pickuppoint']);

      if (this.pickupid == this.pickuptimelist[j]['pickuppoint']) {
        this.messageService.openSnackBar("Pick up point already exists", 'warn');
        return;
      }
    }

    console.log(data);

    var type = this.pickupList.map((x: any) => x.pickuppointid).indexOf(this.pickupid);
    if (type > -1) {
      this.pickuptimelist[index]['pickuppoint'] = this.pickupList[type]['pickuppointid'];
      this.pickuptimelist[index]['name'] = this.pickupList[type]['name'];
      // this.pickuptimelist[i]['usercounts'] = this.pickupList[type]['usercounts'];
    }
    var count = 0;
    console.log(this.allpassengerRequestList);
    console.log(this.pickuptimelist);

    this.pickuptimelist[index]['userlist'] = [];
    console.log("Okok")
    console.log(this.pickuptimelist);
    console.log(this.pickuptimelist[index]['pickuppoint']);

    for (let i = 0; i < this.allpassengerRequestList.length; i++) {
      console.log(this.pickuptimelist[index]['pickuppoint'] == this.allpassengerRequestList[i]['pickuppointid']);
      if (this.pickuptimelist[index]['pickuppoint'] == this.allpassengerRequestList[i]['pickuppointid']) {
        console.log("111")
        this.pickuptimelist[index]['userlist'].push(this.allpassengerRequestList[i])
      }
    }
    console.log(this.allpassengerRequestList);
    console.log(this.pickuptimelist);




    this.passengercount = count;


  }
  dropOffselect(data: any, i: any) {
    console.log(data);
    this.dropoffid = data.dropoffpointid;
    this.dropoffvalue = data.name;
    var type = this.dropList.map((x: any) => x.dropoffpointid).indexOf(this.dropoffid);
    console.log(type);
    if (type > -1) {
      this.dropoffpointtimelist[i]['dropoffpoint'] = this.dropList[type]['dropoffpointid'];
      this.dropoffpointtimelist[i]['name'] = this.dropList[type]['name'];
      this.dropoffpointtimelist[i]['usercounts'] = this.dropList[type]['usercounts'];
    }

    var count = 0;
    console.log(count);
    console.log(this.pickuptimelist);
    // for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
    //   if (this.dropoffpointtimelist[i]['usercounts'] != "") {
    //     count += parseInt(this.dropoffpointtimelist[i]['usercounts']);

    //   }
    // }

    this.dropoffpassengercount = count;

    // this.passengercount += this.pickupList[type]['usercounts'];
  }



  pickupuserList: any = [];
  dropoffuseridlist: any = [];
  checktype: any = "002";
  sendmainList: any = [];
  showuserlist(user1: any, index: any, typerequest: any) {
    var senduserlist = [];

    var sendtype = "";
    this.pickupuserList = [];
    var userarry = JSON.parse(
      JSON.stringify(this.pickuptimelist)
    );
    this.sendmainList = JSON.parse(JSON.stringify(this.allpassengerRequestList))

    for (let i = 0; i < userarry.length; i++) {
      if (userarry[i]['userlist'].length > 0) {
        for (let j = 0; j < userarry[i]['userlist'].length; j++) {
          userarry[i]['userlist'][j]['checked'] = true;
          this.pickupuserList.push(userarry[i]['userlist'][j]);
        }
      }
    }

    console.log(this.pickupuserList);

    if (typerequest == "001") {

      console.log(this.pickuptimelist);
      console.log(this.sendmainList);
      if (this.pickuptimelist[index]['userlist'].length > 0) {
        var arrnew: any = [];
        for (let i = 0; i < this.pickuptimelist[index]['userlist'].length; i++) {
          arrnew.push(this.pickuptimelist[index]['userlist'][i]['userid']);
        }
        console.log("arrnew")
        console.log(arrnew);

        var useridarr = [];
        for (let i = 0; i < this.pickuptimelist.length; i++) {
          for (let j = 0; j < this.pickuptimelist[i]['userlist'].length; j++) {
            useridarr.push(this.pickuptimelist[i]['userlist'][j]['userid'])
          }
        }
        console.log(useridarr);
        var realAarr = [];
        console.log(this.sendmainList);

        this.checktype = "002";
        for (let j = 0; j < this.sendmainList.length; j++) {
          var typeq = useridarr.map((x: any) => x).indexOf(this.sendmainList[j]['userid']);
          console.log(typeq)
          if (typeq == -1) {
            realAarr.push(this.sendmainList[j]);
          }
        }
        console.log("all")
        console.log(this.sendmainList);
        for (let j = 0; j < this.sendmainList.length; j++) {
          var newtype = arrnew.map((x: any) => x).indexOf(this.sendmainList[j]['userid']);
          console.log("type" + newtype);
          if (newtype > -1) {
            realAarr.push(this.sendmainList[j]);
          }
        }


        console.log(realAarr)


        senduserlist = this.pickuptimelist[index]['userlist'];
        this.sendmainList = JSON.parse(JSON.stringify(realAarr));


      } else {

        var useridarr = [];
        for (let i = 0; i < this.pickuptimelist.length; i++) {
          for (let j = 0; j < this.pickuptimelist[i]['userlist'].length; j++) {
            useridarr.push(this.pickuptimelist[i]['userlist'][j]['userid'])
          }
        }
        console.log(useridarr.length);
        console.log(this.sendmainList.length);
        if (useridarr.length == this.sendmainList.length) {
          this.checktype = "001";
          // this.sendmainList = ;
          console.log("1111");
          this.sendmainList = [];
        } else {
          console.log("2222")
          var realAarr = [];
          console.log(this.sendmainList);
          for (let j = 0; j < this.sendmainList.length; j++) {
            var typeq = useridarr.map((x: any) => x).indexOf(this.sendmainList[j]['userid']);
            console.log(typeq)
            if (typeq == -1) {
              realAarr.push(this.sendmainList[j]);
            }
          }
          console.log(realAarr)
          senduserlist = this.pickuptimelist[index]['userlist'];
          this.sendmainList = JSON.parse(JSON.stringify(realAarr));
        }
      }


      sendtype = "001";
    } else if (typerequest == "002") {

      if (this.checkgoing == false && this.checkreturn == true) {
        senduserlist = this.dropoffpointtimelist[index]['userlist'];
        sendtype = "001";
      } else {
        sendtype = "002";

        var arr: any = [];
        var isArry: any = [];
        console.log(this.pickuptimelist);
        for (let i = 0; i < this.pickuptimelist.length; i++) {
          if (this.pickuptimelist[i]['userlist'].length > 0) {
            for (let j = 0; j < this.pickuptimelist[i]['userlist'].length; j++) {
              arr.push(this.pickuptimelist[i]['userlist'][j]);
            }
          }
        }



        if (this.dropoffpointtimelist[index]['userlist'].length > 0) {
          console.log("true");
          console.log(this.dropoffpointtimelist[index]['userlist']);
          console.log(this.dropoffpointtimelist);
          console.log(index);

          for (let i = 0; i < arr.length; i++) {
            for (let ii = 0; ii < this.dropoffpointtimelist.length; ii++) {
              if (index != ii) {
                var item = this.dropoffpointtimelist[ii]['userlist'].filter((el: any) => el.userid == arr[i]['userid']).length;
                // console.log(item);
                if (item > 0) {
                  isArry.push(arr[i]);
                }
              }
            }
            console.log(i);

          }
          console.log(isArry);

        } else {
          console.log("tfalse");
          for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
            if (this.dropoffpointtimelist[i]['userlist'].length > 0) {
              for (let j = 0; j < this.dropoffpointtimelist[i]['userlist'].length; j++) {
                isArry.push(this.dropoffpointtimelist[i]['userlist'][j]);


              }
            }
          }

        }
        var realArr: any = [];


        // var arr = JSON.parse(JSON.stringify(this.pickuptimelist[index]['userlist']));
        console.log(arr);
        console.log(isArry);
        console.log(realArr);
        console.log(".....<<<<<<<");

        if (this.dropoffpointtimelist[index]['userlist'].length > 0) {

          for (let i = 0; i < arr.length; i++) {
            if (this.dropoffpointtimelist[index]['userlist'].length > 0) {

              var item = this.dropoffpointtimelist[index]['userlist'].filter((el: any) => el.userid == arr[i]['userid']).length;



              console.log(item);
              if (item > 0) {
                arr[i]['checked'] = true;
              } else {
                arr[i]['checked'] = false;
              }
            } else {
              arr[i]['checked'] = false;
            }

          }
          console.log(arr);
          for (let j = 0; j < isArry.length; j++) {
            var type = arr.map((x: any) => x.userid).indexOf(isArry[j]['userid']);
            arr.splice(type, 1);
          }

          senduserlist = JSON.parse(JSON.stringify(arr));
          console.log("Okokok>>>>>11111111");
          console.log(senduserlist);

        } else {
          console.log(arr); // 4
          console.log(realArr);
          console.log(isArry); // 2
          if (isArry.length > 0) {
            for (let j = 0; j < isArry.length; j++) {
              // 2
              var type = arr.map((x: any) => x.userid).indexOf(isArry[j]['userid']);
              arr.splice(type, 1);
              // for (let i = 0; i < isArry.length; i++) {
              //   if (arr[j]['userid'] != isArry[i]['userid']) {
              //     realArr.push(arr[i]);
              //   }
              // }
            }
            for (let i = 0; i < arr.length; i++) {
              realArr.push(arr[i]);
            }
            console.log("jjjjjjj");
          } else {
            for (let i = 0; i < arr.length; i++) {
              realArr.push(arr[i]);
            }
            console.log("aaaaaaa");
          }

          for (let i = 0; i < realArr.length; i++) {
            realArr[i]['checked'] = false;
          }
          console.log(realArr);

          // }
          senduserlist = JSON.parse(JSON.stringify(realArr));
          console.log("Okokok>>>>>2222222222");
          console.log(senduserlist);

        }
        // for dropoff new 
        for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
          for (let j = 0; j < this.dropoffpointtimelist[i]['userlist'].length; j++) {
            if (index != i) {
              this.dropoffuseridlist.push(this.dropoffpointtimelist[i]['userlist'][j])
            }
          }
        }

        console.log("userlistlistlist");
        console.log(this.dropoffuseridlist);


      }

    }
    var startdate = formatDate(
      new Date(this.carwayForm.startdate),
      this.formatsearch,
      'en-US'
    ).toString();
    var enddate = formatDate(
      new Date(this.carwayForm.enddate),
      this.formatsearch,
      'en-US'
    ).toString();

    // senduserlist =  this.pickuptimelist[index]['userlist'];
    console.log(senduserlist);
    console.log(this.dropoffpointtimelist);
    const dialog = this.dialog.open(UserInfoCarwayComponent, {
      height: '536px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        type: sendtype,
        userlist: senduserlist,
        orgid: this.orgid,
        domainid: this.allinoneService.getDomain().domainid,
        trantype: this.typerequest,
        startdate: startdate,
        enddate: enddate,
        carwayid: this.carwayForm.carwayid,
        index: index,
        pickupuserList: this.pickupuserList,
        selecteddropoffpointpassenger: typerequest == '002' ? this.dropoffpointtimelist[index]['userlist'] : [],
        dropoffuseidlist: this.typerequest == "002" ? this.dropoffuseridlist : [],
        mainlist: this.sendmainList.length > 0 ? this.sendmainList : [],
        checktype: this.checktype

      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
        if (dialogResult) {
          if (dialogResult.data.type == '001') {

            console.log(this.dropoffpointtimelist);

            if (this.checkgoing == false && this.checkreturn == true) {

              // this.dropoffpointtimelist[index]['userlist'] = dialogResult.data.userlist;
              this.dropoffpointtimelist[index]['userlist'] = JSON.parse(
                JSON.stringify(dialogResult.data.userlist)
              );

              this.dropoffpointtimelist[index]['reqidlist'] = dialogResult.data.data;
              var count = [];
              for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
                for (let j = 0; j < this.dropoffpointtimelist[i]['userlist'].length; j++) {
                  count.push(this.dropoffpointtimelist[i]['userlist'][j]);
                }
              }
              this.dropoffpassengercount = count.length;

            } else {

              var arr = dialogResult.data.userlist;
              var userid = [];
              for (let i = 0; i < this.pickuptimelist.length; i++) {
                if (index != i) {
                  for (let j = 0; j < this.pickuptimelist[i]['userlist'].length; j++) {
                    userid.push(this.pickuptimelist[i]['userlist'][j]);
                  }
                }

              }
              var checkarr = [];
              for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < userid.length; j++) {
                  if (arr[i]['userid'] == userid[j]['userid']) {
                    checkarr.push(userid[j])
                  }

                }
              }
              if (checkarr.length > 0) {
                for (let j = 0; j < checkarr.length; j++) {
                  var type = arr.map((x: any) => x.userid).indexOf(checkarr[j]['userid']);
                  arr.splice(type, 1);
                }

                this.pickuptimelist[index]['userlist'] = arr;
                this.messageService.openSnackBar("Passengers already exist at another Pickup Point", "warn");

              }
              else {
                this.pickuptimelist[index]['reqidlist'] = dialogResult.data.data;
                // this.pickuptimelist[index]['userlist'] = dialogResult.data.userlist;
                this.pickupuserList = JSON.parse(
                  JSON.stringify(dialogResult.data.userlist)
                );
                this.pickuptimelist[index]['userlist'] = JSON.parse(
                  JSON.stringify(dialogResult.data.userlist)
                );
                console.log(this.pickuptimelist[index])

                var count = [];
                for (let i = 0; i < this.pickuptimelist.length; i++) {
                  for (let j = 0; j < this.pickuptimelist[i]['userlist'].length; j++) {
                    count.push(this.pickuptimelist[i]['userlist'][j]);
                  }
                }


                this.passengercount = count.length;
              }

            }
            var pickuser: any = [];
            for (let i = 0; i < this.pickuptimelist.length; i++) {
              for (let j = 0; j < this.pickuptimelist[i]['userlist'].length; j++) {
                pickuser.push(this.pickuptimelist[i]['userlist'][j]['userid']);
              }
            }
            console.log("1111")
            console.log(pickuser);
            console.log(this.dropoffpointtimelist);
            if (this.dropoffpointtimelist.length > 0) {
              for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
                for (let j = 0; j < this.dropoffpointtimelist[i]['userlist'].length; j++) {
                  console.log("2222")
                  var type = pickuser.map((x: any) => x).indexOf(this.dropoffpointtimelist[i]['userlist'][j]['userid']);
                  console.log(type);
                  if (type == -1) {
                    console.log("3333")
                    this.dropoffpointtimelist[i]['userlist'].splice(j, 1);

                  }


                }
              }


            }
            var count = [];
            for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
              for (let j = 0; j < this.dropoffpointtimelist[i]['userlist'].length; j++) {
                count.push(this.dropoffpointtimelist[i]['userlist'][j]);
              }
            }

            this.dropoffpassengercount = count.length;


            console.log(this.pickuptimelist);
            console.log(this.dropoffpointtimelist);
            console.log("Jjjajaeje");

          } else if (dialogResult.data.type == '002') {
            console.log(dialogResult.data.data);
            // this.dropoffpointtimelist[index]['userlist'] = dialogResult.data.userlist;
            this.dropoffpointtimelist[index]['userlist'] = JSON.parse(
              JSON.stringify(dialogResult.data.userlist)
            );
            this.dropoffpointtimelist[index]['reqidlist'] = dialogResult.data.data;
            var count = [];
            for (let i = 0; i < this.dropoffpointtimelist.length; i++) {
              for (let j = 0; j < this.dropoffpointtimelist[i]['userlist'].length; j++) {
                count.push(this.dropoffpointtimelist[i]['userlist'][j]);
              }
            }

            this.dropoffpassengercount = count.length;
          }



          console.log(this.passengercount);
          console.log(this.pickuptimelist);
          console.log(this.dropoffpointtimelist)
        }

      }

    });
  }



  sortData(sort: Sort) {
    const data = this.carwayList;
    if (!sort.active || sort.direction === '') {
      this.carwayList = data;
    }

    this.carwayList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(
            a.name.toLocaleLowerCase(),
            b.name.toLocaleLowerCase(),
            isAsc
          );
        case 'description':
          return this.allinoneService.compare(
            a.description.toLocaleLowerCase(),
            b.description.toLocaleLowerCase(),
            isAsc
          );
        case 'vehicleno':
          return this.allinoneService.compare(
            a.vehicleno.toLocaleLowerCase(),
            b.vehicleno.toLocaleLowerCase(),
            isAsc
          );
        case 'drivername':
          return this.allinoneService.compare(
            a.drivername.toLocaleLowerCase(),
            b.drivername.toLocaleLowerCase(),
            isAsc
          );
        case 'driverphone':
          return this.allinoneService.compare(
            a.driverphone.toLocaleLowerCase(),
            b.driverphone.toLocaleLowerCase(),
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdate.toLocaleLowerCase(),
            b.startdate.toLocaleLowerCase(),
            isAsc
          );
        case 'enddate':
          return this.allinoneService.compare(
            a.enddate.toLocaleLowerCase(),
            b.enddate.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }

    });

  }


  downloadSheet() {

    var carwayexport = this.filter.transform(
      this.carwayList,
      this.searchText,
      "carway"
    );

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Car_Way_Schedule' + time + '.xlsx';
    var searchdate = formatDate(this.searchdate, this.formatToShow, 'en-US').toString();
    carwayexport.map((car: any) => {
      data.push({
        "Name": car.name,
        "Description": car.description,
        "Vehicle": car.vehicleno,
        "Driver Name": car.drivername,
        "Driver Phone": car.driverphone,
        "Date": searchdate,
        "Start Time": car.starttime,
        "End Time": car.endtime,
      });
    });

    this.allinoneService.exportEcecl(data, name);

  }



  downloadSheet1() {

    var carwayexport = this.filter.transform(
      this.carwayList,
      this.searchText,
      "carway"
    );

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Way1' + time + '.xlsx';
    carwayexport.map((car: any) => {
      data.push({
        "Name": car.name,
        "Phone No": car.description,
        "Pickup Point": car.vehicleno,
        "Drop Off Point": car.drivername,
      });
    });

    this.allinoneService.exportEcecl(data, name);

  }

  goDetail(data: any) {
    var carwayid = data.carwayid;
    this.router.navigateByUrl(`transportation/carwayschedules/${carwayid}`);
  }

  changevalue(event: any) {
    console.log(event.target.value);
    console.log(this.typerequest);

    if (this.typerequest == "001") {
      this.dropoffpointtimelist = [];
      this.checkgoing = false;
      this.checkreturn = false;
      console.log("111");
      this.pickuptimelist = [];
      this.addpickupdata();
      this.getoutstandingrequestList();
    } else {
      this.checkgoing = true;
      this.checkreturn = true;
      this.dropoffpointtimelist = [];
      this.pickuptimelist = [];
      this.adddropoffpointdata();
      this.addpickupdata();
      this.getoutstandingrequestList();
      console.log("222");
    }

  }
  searchdateApi() {
    console.log("okok");
    this.refreshgetData();
  }

  statusChange(data: any) {
    if (data == "all") {
      this.typename = "All";
      this.trantype = "";
    } else if (data == "001") {
      this.typename = "Ferry";
      this.trantype = "001";
    } else if (data == "002") {
      this.typename = "Daily";
      this.trantype = "002";
    }
    this.refreshgetData();

  }

  pickupchange(index: any) {
    console.log(this.pickuptimelist[index]);
    this.pickuptimelist[index]['pickuppoint'] = "";
    this.pickupvalue = '';
    this.pickupid = '';
  }

  dropoffchanget(index: any) {
    this.dropoffpointtimelist[index]['dropoffpoint'] = "";
    this.dropoffid = "";
    this.dropoffvalue = false;
  }

  goingdis: boolean = false;
  returndis: boolean = false;

  checkGoing(data: any) {
    if (data.target.checked == true) {
      // this.checkgoing = true;
      this.returndis = false;
      this.addpickupdata();
      if (this.checkreturn == true) {
        this.dropoffpointtimelist = [];
        this.dropoffpassengercount = 0;
        this.adddropoffpointdata();
      }



    } else {
      // this.checkgoing = false;
      this.returndis = true;
      this.pickuptimelist = [];
      this.passengercount = 0;
      // if (this.checkreturn == false) {
      //   this.goingdis = true;
      // }
    }
    console.log(this.checkgoing);

  }
  checkReturn(data: any) {
    if (data.target.checked == true) {
      // this.checkreturn = true;
      this.goingdis = false;
      // this.pickuptimelist = [];
      this.adddropoffpointdata();
    } else {
      // this.checkreturn = false;
      // if (this.checkgoing == false) {
      this.goingdis = true;
      this.dropoffpointtimelist = [];
      this.dropoffpassengercount = 0;
      // } 
    }

  }

  selectcartype(data: any) {
    console.log(data.target.value);
    this.carwayForm.driverid = data.target.value;


  }

  getoutstandingrequestList() {
    this.getttingoutstanding = true;
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "trantype": this.typerequest,
      "requeststatus": "002",
      "startdate": formatDate(
        new Date(this.carwayForm.startdate),
        this.formatsearch,
        'en-US'
      ).toString(),
      "enddate": formatDate(
        new Date(this.carwayForm.enddate),
        this.formatsearch,
        'en-US'
      ).toString(),
      "carwayid": this.carwayForm.carwayid,
      "type": "001"
    };
    this.kunyekService.getOutstandingRequest(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {


          this.allpassengerRequestList = [];
          for (let i = 0; i < res['datalist'].length; i++) {
            if (res['datalist'][i]['requestlist'].length > 0) {
              // for (let j = 0; j < res['datalist'][i]['requestlist'].length; j++) {
              this.allpassengerRequestList.push({
                "transportationid": res['datalist'][i]['requestlist'][0]['transportationid'],
                "approvedby": res['datalist'][i]['requestlist'][0]['approvedby'],
                "approvedtime": res['datalist'][i]['requestlist'][0]['approvedtime'],
                "date": res['datalist'][i]['requestlist'][0]['date'],
                "arrivetime": res['datalist'][i]['requestlist'][0]['arrivetime'],
                "going": res['datalist'][i]['requestlist'][0]['going'],
                "fromplace": res['datalist'][i]['requestlist'][0]['fromplace'],
                "remark": res['datalist'][i]['requestlist'][0]['remark'],
                "requeststatus": res['datalist'][i]['requestlist'][0]['requeststatus'],
                "return": res['datalist'][i]['requestlist'][0]['return'],
                "returntime": res['datalist'][i]['requestlist'][0]['returntime'],
                "sort": res['datalist'][i]['requestlist'][0]['sort'],
                "toplace": res['datalist'][i]['requestlist'][0]['toplace'],
                "userid": res['datalist'][i]['userid'],
                "userfrom": res['datalist'][i]['requestlist'][0]['userfrom'],
                "username": res['datalist'][i]['username'],
                "employeeid": res['datalist'][i]['requestlist'][0]['employeeid'],
                "userto": res['datalist'][i]['requestlist'][0]['userto'],
                "pickuppointid": res['datalist'][i]['requestlist'][0]['pickuppointid']
              });
              // }
            }
          }


          this.getttingoutstanding = false;
        } else {

          this.getttingoutstanding = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.getttingoutstanding = false;
      }
    );
  }
}

