import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from '../../services/all-in-one.service';

@Component({
  selector: 'app-team-breadcrumb',
  templateUrl: './team-breadcrumb.component.html',
  styleUrls: ['./team-breadcrumb.component.scss'],
})
export class TeamBreadcrumbComponent implements OnInit {
  attendanceView: boolean = false;

  title: string = '';

  @Input() s_breadcrumb!: string;
  @Input() t_breadcrumb!: string;
  @Input() f_breadcrumb!: string;
  @Input() fi_breadcrumb!: string;

  @Output('emit')
  change: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit1')
  change1: EventEmitter<any> = new EventEmitter<any>();

  @Output('emit2')
  change2: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private allinoneService: AllInOneService,
  ) {}

  ngOnInit(): void {
    this.attendanceView = this.allinoneService.isAttendanceView;
    this.getTitle();
  }

  getTitle() {
    console.log('call title fun');
    var r = this.router.url;
    if (r.startsWith('/timeandattendance')) {
      this.title = 'Time and Attendance';
    } else if (r.startsWith('/requestapproval')) {
      this.title = 'Request Approval';
    } else if (r.startsWith('/employeecentral')) {
      this.title = 'Employee Central';
    } else if (r.startsWith('/references')) {
      this.title = 'References';
    } else if (r.startsWith('/rolemanagement')) {
      this.title = 'Role Management';
    } else {
      this.title = 'Teams';
    }
  }

  // getTitle() {
  //   console.log('call title fun');
  //   var r = this.router.url;
  //   if (r == '/timeandattendance') {
  //     this.title = 'Time and Attendance'
  //   } else if (r == '/requestapproval') {
  //     this.title = 'Request Approval'
  //   } else if (r == '/employeecentral') {
  //     this.title = 'Employee Central'
  //   } else if (r == '/references') {
  //     this.title = 'References'
  //   } else if (r == '/rolemanagement') {
  //     this.title = 'Role Management'
  //   }
  //   else {
  //     this.title = 'Teams'
  //   }
  // }

  goToSecond() {
    if (this.t_breadcrumb) {
      this.change.emit(false);
    }
  }
  goToThird() {
    if (this.f_breadcrumb) {
      this.change1.emit(false);
    }
  }
  goToFourth() {
    if (this.fi_breadcrumb) {
      this.change2.emit(false);
    }
  }
}
