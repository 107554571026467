<div class="container-fluid">
    <div class="content">
        <div class="d-flex justify-content-between align-items-center">
            <!-- <ng-container *ngIf="!isAdmin">
                <app-breadcrumb [s_breadcrumb]="'Late Report'">
                </app-breadcrumb>
            </ng-container>
            <ng-container *ngIf="isAdmin">
                <app-hcm-breadcrumb [s_breadcrumb]="'Late Report'">
                </app-hcm-breadcrumb>
            </ng-container> -->
            <app-team-breadcrumb [s_breadcrumb]="'Late'"></app-team-breadcrumb>
            <pagination-controls (pageChange)="currentPage = $event" *ngIf="lateReportList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>
        </div>
        <!-- <div class="mb-3 d-flex align-items-center">
      <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoading"
        (click)="getLateReport()">
      </app-button-loader>
      <div class="line">
      </div>
      <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel"
        [disabled]="lateReportList.length == 0">
        <img class="excel-icon" src="../../../assets/images/excel.png" />
        Export
      </button>
    </div> -->

        <!-- <div class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
      <div class="mb-3">
        <label for="startdate" class="form-label">Start Date
        </label>
        <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="leoutreportForm.startdate"
          required #startdate="ngModel" (ngModelChange)="startdateChange()" step="1" />

        <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched)">
          <div *ngIf="startdate.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="enddate" class="form-label">End Date</label>
        <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="leoutreportForm.enddate"
          required #enddate="ngModel" [min]="leoutreportForm.startdate" step="1" max="{{minDate}}" />

        <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched)">
          <div *ngIf="enddate.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label" for="department">Department</label>
        <select class="form-select" [(ngModel)]="leoutreportForm.department" [disabled]="gettingMemberPosition">
          <option value="">All</option>
          <option *ngFor="let department of departmentList" [value]="department">{{department}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberPosition"></mat-progress-bar>
      </div>
      <div class="mb-3">
        <label class="form-label" for="division">Division</label>
        <select class="form-select" [(ngModel)]="leoutreportForm.division" [disabled]="gettingMemberPosition">
          <option value="">All</option>
          <option *ngFor="let division of divisionList" [value]="division">{{division}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberPosition"></mat-progress-bar>
      </div>
      <div class="mb-3">
        <label class="form-label" for="teamid">Team ID</label>
        <select class="form-select" [(ngModel)]="leoutreportForm.teamid" [disabled]="gettingMemberPosition">
          <option value="">All</option>
          <option *ngFor="let teamid of teamidList" [value]="teamid">{{teamid}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberPosition"></mat-progress-bar>
      </div>
    </div> -->

        <div class="row">
            <div class="d-flex flex-wrap align-items-start justify-content-between">
                <div class="mb-3 d-flex gap-2 flex-wrap justify-content-start align-items-center">
                    <div class="">
                        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" [bsConfig]="{containerClass: 'custom-placement'}" readonly [(ngModel)]="leoutreportForm.startdate" style="min-width: 200px;"
                            required #startdate="ngModel" (ngModelChange)="startdateChange()">

                        <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched)">
                            <div *ngIf="startdate.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="leoutreportForm.enddate" [minDate]="leoutreportForm.startdate" [maxDate]="minDate" style="min-width: 200px;"
                            required #enddate="ngModel">

                        <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched)">
                            <div *ngIf="enddate.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="line g-0"></div>
                    <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                        <input type="text" class="form-control" placeholder="&#xf002; Search by User ID/Name/Employee ID" aria-label="Search by User ID/Name/Employee ID" (focus)="isFocus = true" (blur)="isFocus = false" (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
                            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
                        <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
                    </div>
                    <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-1' : !isMobileView }">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
              <mat-icon>filter_list</mat-icon>
            </button>
                        <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
                            <li>
                                <div style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                                <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
                            </li>
                        </ul>
                    </div>
                    <button type="button" class="btn btn-custom" id="togglefilter" (click)="toggleFilter()">
            <mat-icon>{{openfilter ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
          </button>
                    <!-- <div class="ms-2"> -->

                    <!-- <div>
            <button class="btn btn-custom" (click)="refresh()" title="Refresh">
              <mat-icon>refresh</mat-icon>
            </button>
          </div> -->


                    <div class="line g-0"></div>
                    <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel" [disabled]="lateReportList.length == 0">
            <img class="excel-icon" src="../../../assets/images/excel.png" />
            Export
          </button>
                    <app-button-loader [button_text]="'Search'" [load_button_text]="'Searching'" [isLoading]="searchLoading" (click)="search()">
                    </app-button-loader>

                    <div class="line g-0"></div>
                    <div class="d-flex align-items-start flex-wrap">
                        <span class="total-count-text">Total:&nbsp;</span>
                        <span class="total-count-num">{{ ( lateReportList | filter : searchText: 'lateandearlyoutreport' ).length
              }}</span>
                    </div>

                    <div class="input-group mt-2" *ngIf="isMobileView">
                        <input type="text" class="form-control" placeholder="&#xf002; Search by User ID/Name/Employee ID" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                            (ngModelChange)="searchTextChange()">
                        <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
                    </div>
                </div>
                <!-- <div class="mb-3 d-flex flex-wrap align-items-center gap-2">
          <pagination-controls (pageChange)="currentPage = $event" *ngIf="lateReportList.length > itemPerPage"
            previousLabel="Prev" nextLabel="Next">
          </pagination-controls>
        </div> -->

            </div>

            <div *ngIf="openfilter" class="d-flex gap-3 flex-wrap" style="row-gap: 0 !important">
                <!-- <div class="mb-3">
          <label class="form-label" for="department">Department</label>
          <select class="form-select" [(ngModel)]="leoutreportForm.department" [disabled]="gettingMemberPosition">
            <option value="">All</option>
            <option *ngFor="let department of departmentList" [value]="department">{{department}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberPosition"></mat-progress-bar>
        </div>
        <div class="mb-3">
          <label class="form-label" for="division">Division</label>
          <select class="form-select" [(ngModel)]="leoutreportForm.division" [disabled]="gettingMemberPosition">
            <option value="">All</option>
            <option *ngFor="let division of divisionList" [value]="division">{{division}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberPosition"></mat-progress-bar>
        </div>
        <div class="mb-3">
          <label class="form-label" for="teamid">Team ID</label>
          <select class="form-select" [(ngModel)]="leoutreportForm.teamid" [disabled]="gettingMemberPosition">
            <option value="">All</option>
            <option *ngFor="let teamid of teamidList" [value]="teamid">{{teamid}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingMemberPosition"></mat-progress-bar>
        </div> -->
                <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="departmentmenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Department&nbsp;:&nbsp;{{ leoutreportForm.department.length > 20 ? (leoutreportForm.department |
            slice:0:20)+'...' : leoutreportForm.department}}
            <mat-menu #departmentmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let department of departmentList" (click)="departmentChange(department)">
                {{ department }}
              </button>
                    </mat-menu>
                    <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                    </button>
                </div>
                <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="divisionmenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Division&nbsp;:&nbsp;{{ leoutreportForm.division.length > 22 ? (leoutreportForm.division |
            slice:0:22)+'...' : leoutreportForm.division}}
            <mat-menu #divisionmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let division of divisionList" (click)="divisionChange(division)">
                {{ division }} 
              </button>
                    </mat-menu>
                    <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                    </button>
                </div>
                <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="teamidmenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Team ID&nbsp;:&nbsp;{{ leoutreportForm.teamid.length > 22 ? (leoutreportForm.teamid |
            slice:0:22)+'...' : leoutreportForm.teamid}}
            <mat-menu #teamidmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let teamid of teamidList" (click)="teamidChange(teamid)">
                {{ teamid }}
              </button>
                    </mat-menu>
                    <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                    </button>
                </div>

                <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="costcenterMenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Cost Center&nbsp;:&nbsp;{{ leoutreportForm.costcenter.length > 22 ? (leoutreportForm.costcenter |
            slice:0:22)+'...' : leoutreportForm.costcenter}}
            <mat-menu #costcenterMenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let costcenter of costCenterList" (click)="costCenterChange(costcenter)">
                {{ costcenter }}
              </button>
                    </mat-menu>
                    <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                    </button>
                </div>

                <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="subdivisionMenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Sub Division&nbsp;:&nbsp;{{ leoutreportForm.subdivision.length > 22 ? (leoutreportForm.subdivision |
            slice:0:22)+'...' : leoutreportForm.subdivision}}
            <mat-menu #subdivisionMenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let subdivision of subDivisionList" (click)="subDivisionChange(subdivision)">
                {{ subdivision }}
              </button>
                    </mat-menu>
                    <span *ngIf="gettingMemberPosition" class="spinner-border spinner-border-sm button-spinner mx-1" role="status" aria-hidden="true"></span>
                    </button>
                </div>

                <div class="filtter-btn align-items-center mb-3" [matMenuTriggerFor]="statusMenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ leoutreportForm.status == '001' ? 'Active' : 'Inactive' }}
            <mat-menu #statusMenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let status of userStatusFilterList" (click)="leoutreportForm.status = status.id">
                {{ status.name }}
              </button>
                    </mat-menu>
                    </button>
                </div>

            </div>

            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-md-3" mat-sort-header="userid">User ID</td>
                            <td scope="col" class="col-md-2" mat-sort-header="username">Name</td>
                            <td scope="col" class="col-md-2" mat-sort-header="employeeid">Employee ID</td>
                            <td scope="col" class="col-md-1" mat-sort-header="latetime1">Late Time 1</td>
                            <td scope="col" class="col-md-1" mat-sort-header="latetime2">Late Time 2</td>
                            <td scope="col" class="col-md-1" mat-sort-header="latetime3">Late Time 3</td>
                            <td scope="col" class="col-md-1" mat-sort-header="latetime4">Late Time 4</td>
                            <td scope="col" class="col-md-1" mat-sort-header="halfdayabsent">Half Day Absence</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="lateReportLoading">
                        <tr>
                            <td colspan="8" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!lateReportLoading">
                        <tr *ngIf="lateReportList.length == 0">
                            <td colspan="8" class="nodata">Empty</td>
                        </tr>
                        <tr *ngFor="let data of lateReportList | filter : searchText : 'lateandearlyoutreport' | paginate: { itemsPerPage: itemPerPage , currentPage : currentPage } ; let index = index ">
                            <td class="td-data">
                                <span [title]="data.userid">{{ data.userid }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="data.username">{{ data.username }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="data.employeeid">{{ data.employeeid }}</span>
                            </td>
                            <td class="td-data td-edit" (click)="lateTimeDetails('Late time 1', data, 'latetime1datelist')">
                                <span [title]="data.latetime1">{{ data.latetime1 }}</span>
                            </td>
                            <td class="td-data td-edit" (click)="lateTimeDetails('Late time 2', data, 'latetime2datelist')">
                                <span [title]="data.latetime2">{{ data.latetime2 }}</span>
                            </td>
                            <td class="td-data td-edit" (click)="lateTimeDetails('Late time 3', data, 'latetime3datelist')">
                                <span [title]="data.latetime4">{{ data.latetime3 }}</span>
                            </td>
                            <td class="td-data td-edit" (click)="lateTimeDetails('Late time 4', data, 'latetime4datelist')">
                                <span [title]="data.latetime4">{{ data.latetime4 }}</span>
                            </td>
                            <td class="td-data td-edit" (click)="lateTimeDetails('Half Day Absence', data, 'halfdayabsentlist')">
                                <span [title]="data.halfdayabsent">{{ data.halfdayabsent }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>