import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AppRestrictionSetupComponent } from '../app-restriction-setup/app-restriction-setup.component';

@Component({
  selector: 'app-app-restriction',
  templateUrl: './app-restriction.component.html',
  styleUrls: ['./app-restriction.component.scss'],
})
export class AppRestrictionComponent implements OnInit {
  gettingData: boolean = false;
  restrictionList: any = [];
  orgList: any = [];

  constructor(
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getOrgList();
    this.getAppRestrictions();
  }

  getAppRestrictions() {
    this.gettingData = true;
    this.kunyekService.getAppRestrictions().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.restrictionList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingData = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
      }
    );
  }

  sortData(sort: Sort) {
    const data = this.restrictionList;
    if (!sort.active || sort.direction === '') {
      this.restrictionList = data;
      return;
    }

    this.restrictionList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        default:
          return 0;
      }
    });
  }

  openSetupForm(item?: any) {
    if (item && item.version == '') {
      return;
    }
    const dialog = this.dialog.open(AppRestrictionSetupComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        isEditing: item ? true : false,
        item: item,
        orgList: this.orgList,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (item) {
          item.forcedupdate = dialogResult.forcedupdate;
          item.version = dialogResult.version;
          item.exactversion = dialogResult.exactversion;
          item.os = dialogResult.os;
          item.message = dialogResult.message;
        } else {
          this.restrictionList.push(dialogResult);
        }
      }
    });
  }

  deleteRestriction(item: any, index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingData = true;
        const data = {
          restrictid : item.restrictid,
          restricttype : item.version == "" || item.version == null ? item.type : "",
          deleteuser : item.type == "001" ? item.userid : "",
          orgid : item.type == "002" ? item.userid : ""
        };
        this.kunyekService.deleteAppRestriction(data).subscribe(
          (res : any) => {
            if(res.returncode == '300') {
              this.gettingData = false;
              this.restrictionList.splice(index, 1);
            }else {
              this.gettingData = false;
              this.messageService.openSnackBar(
                res.message || res.status || "Server Error", 'warn'
              );
            }
          },
          (err) => {
            this.gettingData = false;
            this.messageService.openSnackBar(
              err.status, 'wran'
            );
          }
        )
      }
    });
  }

  getOrgList() {
    this.kunyekService
      .getOrgByDomainId(this.allinoneService.getDomain().domainid)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.orgList = res.datalist;
          }
        },
        (err) => {}
      );
  }
}
