import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  orgList: any = [];
  choosedFilter: any = "event";
  isDomainAdmin: boolean = false;
  type : any ;
  filter : any;
  
  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.orgList = this.data.orgList;
    this.isDomainAdmin = this.data.isDomainAdmin;
    this.filter = this.data.select;
    console.log("this is filter type => " + this.filter.type)
    if(this.filter.type == '1'){
      this.type = "1"
    }
    else if(this.filter.type == '2'){
      this.type = "2"
    }
    else{
      this.type = this.filter.orgid
    }
    this.formatFilter()
  }

  ngOnInit(): void {
  }

  formatFilter() {
    if(this.type == '1' || this.type == '2'){
      this.filter = {
        type : this.type
      }
    }
    else{
      this.filter = {
        type : '3',
        orgid : this.type
      }
    }
    console.log(this.filter)
  }

  onConfirm(): void {
    this.formatFilter()
    this.dialogRef.close({data : this.filter});
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
