<div class="container-fluid">
  <div class="content" *ngIf="!backFromAttLoading">

    <app-team-breadcrumb *ngIf="isFromOrg" [s_breadcrumb]="'Organizations'" [t_breadcrumb]="org_name"
      [f_breadcrumb]="'Events'" [fi_breadcrumb]="eventAddorNot ? isEditing? eventForm.eventname:  'New Event': ''"
      (emit)="goToOrg()" (emit1)="goToOrgEdit()" (emit2)="cancel()">
    </app-team-breadcrumb>


    <app-team-breadcrumb *ngIf="!isFromOrg" [s_breadcrumb]="'Events'"
      [t_breadcrumb]="eventAddorNot ? isEditing? eventForm.eventname : 'New Event': ''" (emit)="cancel()">
    </app-team-breadcrumb>
    <div class="d-flex justify-content-between header">
      <div class="mb-3">
        <button *ngIf="!eventAddorNot" type="button" class="btn btn-custom" (click)="eventAddorNot = !eventAddorNot">
          <i class="fa fa-plus me-1"></i>New Event
        </button>
        <div *ngIf="eventAddorNot" class="close-save-button">
          <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
          </button>
          <app-button-loader (click)="submitEvent()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading"></app-button-loader>

          <div class="line" *ngIf="isEditing"></div>
          <button type="button" class="btn btn-custom me-2" (click)="viewAttendance(eventIdToEdit)"
            *ngIf="!saveLoading && isEditing">
            View Attendance
          </button><button type="button" class="btn btn-custom me-2" (click)="viewQR()"
            *ngIf="!saveLoading && isEditing">
            QR
          </button>
        </div>
      </div>
      <div class="mb-3" *ngIf="!isView && !isMobileView && callType == '300' && !eventAddorNot"
        [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !eventAddorNot && !isMobileView}">
        <label for="channel" class="col-form-label"
          [ngClass]="{'pad': !eventAddorNot && !isMobileView && !isView && callType != '300', 'no-pad' : (!eventAddorNot && !isMobileView && isView) || callType == '300'}">Group</label>
        <div [ngClass]="{'input-container': !eventAddorNot && !isMobileView}">
          <select class="form-select" aria-label="Select Group" id="channel" name="channel"
            (change)="channelChanged($event)" [(ngModel)]="eventForm.channel" required #channel="ngModel"
            [disabled]="gettingChannelList">
            <option selected value="">{{eventAddorNot ? 'Select Group' : '-'}}</option>
            <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.channelname}}
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingChannelList"></mat-progress-bar>
        </div>
      </div>
    </div>
    <form [ngClass]="{'row row-custom': !eventAddorNot && !isMobileView}">
      <div *ngIf="eventAddorNot">
        <div class="mb-3">
          <label for="eventName" class="form-label">Event Name</label>
          <input type="text" class="form-control" id="eventName" name="eventName" placeholder="Enter Event Name"
            [(ngModel)]="eventForm.eventname" required #eventName="ngModel"
            [ngClass]="{ 'is-invalid': eventFormSubmitted && eventName.invalid }">

          <div class="invalid-feedback"
            *ngIf="eventName.invalid || (eventName.dirty || eventName.touched) && eventFormSubmitted">
            <div *ngIf="eventName.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="row g-3 mb-3">
          <div class="col-md-6">
            <label for="startdatetime" class="form-label">Start Date Time</label>
            <div class="input-group">
              <input type="datetime-local" class="form-control" id="startdatetime" name="startdatetime"
                placeholder="Enter startdatetime" [(ngModel)]="eventForm.startdatetime" required
                #startdatetime="ngModel"
                [ngClass]="{ 'is-invalid': eventFormSubmitted && startdatetime.invalid && (startdatetime.invalid && enddatetime.valid) }">
              <button class="btn btn-custom" type="button" id="button-addon2" (click)="EnableDate(true,false)"><i
                  class="fa fa-times" aria-hidden="true"></i></button>
            </div>

            <div class="invalid-feedback"
              *ngIf="startdatetime.invalid || (startdatetime.dirty || startdatetime.touched) && eventFormSubmitted && (startdatetime.invalid && enddatetime.valid)">
              <div *ngIf="startdatetime.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
          <div class="col-md-6 ">
            <label for="enddatetime" class="form-label">End Date Time</label>
            <div class="input-group">
              <input type="datetime-local" class="form-control" id="enddatetime" name="enddatetime"
                placeholder="Enter enddatetime" [(ngModel)]="eventForm.enddatetime" required #enddatetime="ngModel"
                [min]="eventForm.enddatetime"
                [ngClass]="{ 'is-invalid': eventFormSubmitted && enddatetime.invalid && (startdatetime.valid && enddatetime.invalid) }">
              <button class="btn btn-custom" type="button" id="button-addon2" (click)="EnableDate(false,true)"><i
                  class="fa fa-times" aria-hidden="true"></i></button>

            </div>


            <div class="invalid-feedback"
              *ngIf=" enddatetime.invalid || (enddatetime.dirty || enddatetime.touched) && eventFormSubmitted && (startdatetime.valid && enddatetime.invalid)">
              <div *ngIf="enddatetime.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
          <!-- <div class="col-md-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="enableDate" name="enableDate" (change)="EnableDate()">
                            <label class="form-check-label" for="enableDate">Enable date</label>
                          </div>
                    </div> -->


        </div>
      </div>
      <div class="mb-3" [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !eventAddorNot && !isMobileView}"
        *ngIf="eventForm.domain?.length && callType == '400'">
        <label for="organization" class="col-form-label"
          [ngClass]="{'no-pad': !eventAddorNot && !isMobileView}">Organization</label>
        <div [ngClass]="{'input-container': !eventAddorNot && !isMobileView}">
          <select class="form-select" aria-label="Select Organization" id="organization" name="organization"
            (change)="orgChanged($event)" [(ngModel)]="eventForm.org" [disabled]="gettingOrgList">
            <option selected value="">{{eventAddorNot ? 'Select Organization' : '-'}}</option>
            <option *ngFor="let org of organizations" [value]="org.orgid">{{org.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
        </div>
      </div>
      <div class="mb-3"
        *ngIf="eventForm.org?.length && (((isView || isMobileView) && callType == '300') || (callType == '400') || eventAddorNot)"
        [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !eventAddorNot && !isMobileView}">
        <label for="channel" class="col-form-label"
          [ngClass]="{'pad': !eventAddorNot && !isMobileView && !isView && callType != '300', 'no-pad' : (!eventAddorNot && !isMobileView && isView) || callType == '300'}">Group</label>
        <div [ngClass]="{'input-container': !eventAddorNot && !isMobileView}">
          <select class="form-select" aria-label="Select Group" id="channel" name="channel"
            (change)="channelChanged($event)" [(ngModel)]="eventForm.channel" required #channel="ngModel"
            [disabled]="gettingChannelList">
            <option selected value="">{{eventAddorNot ? 'Select Group' : '-'}}</option>
            <option *ngFor="let channel of channels" [value]="channel.channelid">{{channel.channelname}}
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingChannelList"></mat-progress-bar>
        </div>
      </div>
      <div *ngIf="eventAddorNot">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class=" d-flex">
              <label for="location" class="form-label">Location </label>
              <span style="margin-left: 0.2rem;"> ( </span>
              <a (click)="newLocation()" class="btn-link mx-1">New Location</a> )
            </div>
            <select class="form-select" aria-label="Select Location" id="location" name="location"
              (change)="locChanged($event)" [(ngModel)]="eventForm.locid" required #loc="ngModel"
              [disabled]="gettingLocList" [ngClass]="{ 'is-invalid': eventFormSubmitted && loc.invalid }">
              <option selected value="">Select Location</option>
              <option *ngFor="let loc of locations" [value]="loc.locid">{{loc.name}}</option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingLocList"></mat-progress-bar>

            <div class="invalid-feedback" *ngIf="loc.invalid || (loc.dirty || loc.touched) && eventFormSubmitted">
              <div *ngIf="loc.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <label for="occurrence" class="form-label">Occurrence</label>
            <select class="form-select" aria-label="Select Occurrence" id="occurrence" name="occurrence"
              [(ngModel)]="eventForm.occurrence" required #occurrence="ngModel"
              [ngClass]="{ 'is-invalid': eventFormSubmitted && occurrence.invalid }">
              <option *ngFor="let occurrence of occurrenceList" [value]="occurrence">{{occurrence}}
              </option>
            </select>

            <div class="invalid-feedback"
              *ngIf="occurrence.invalid || (occurrence.dirty || occurrence.touched) && eventFormSubmitted">
              <div *ngIf="occurrence.errors?.required">
                {{allinoneService.input_err_msg}}
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <label for="eventDesc" class="form-label">Description</label>
          <textarea class="form-control" id="eventDesc" name="eventDesc" rows="5" [(ngModel)]="eventForm.description"
            placeholder="Enter Description for Your Event"></textarea>
        </div>
      </div>
    </form>
    <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center" *ngIf="!eventAddorNot">
      <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with Name" aria-label="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false" title="Page Size">
          <mat-icon>filter_list</mat-icon>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <div c style="text-align: center;">Page size</div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li class="dropdown-item" *ngFor="let page of pg">
            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
              id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
            <label for="pg{{page.name}}" class="col-10 p-1">
              {{page.name}}
            </label>
          </li>
        </ul>
      </div>
      <div class="ms-2">
        <button class="btn btn-custom" (click)="getEventList()" title="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="line"></div>
      <div class="d-flex align-items-center">
        <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{events.length}}</span>
      </div>
    </div>
    <div class="input-group mb-2" *ngIf="isMobileView && !eventAddorNot">
      <input class="form-control" type="text" placeholder="&#xf002; Search with Name" aria-label="Search"
        (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
        [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
      <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
        [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
        <mat-icon class="c-icon">close</mat-icon>
      </button>
    </div>
    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-borderless" *ngIf="eventAddorNot == false">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="name">Name</td>
            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="startdate">Start Date</td>
            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="enddate">End Date</td>
            <!-- <td scope="col" class="col-lg-3 col-md-3">Description</td> -->
            <td scope="col" class="d-flex justify-content-center">Action</td>
          </tr>
        </thead>
        <tbody *ngIf="eventListLoading">
          <tr>
            <td colspan="6" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="eventListLoading == false">
          <tr *ngIf="events.length == 0">
            <td colspan="6" class="nodata">Empty</td>
          </tr>
          <tr
            *ngFor="let item of events | filter:searchText:searchType | paginate: { itemsPerPage: itemPerPage, currentPage: p } ; let index = index">
            <td class="td-data td-edit" (click)="openEditForm(item)">
              <span [title]="item.name">{{item.name}}</span>
            </td>
            <td class="td-data">
              <span [title]="item.startdatetoshow">{{item.startdatetoshow}}</span>
            </td>
            <td class="td-data">
              <span [title]="item.enddatetoshow">{{item.enddatetoshow}}</span>
            </td>
            <!-- <td>{{item.description}}</td> -->
            <td class="d-flex justify-content-center">
              <button class="btn btn-link me-2" (click)="viewAttendanceForm(item)" title="View Attendance">
                View Attendance
              </button>
              <button class="btn btn-outline-info me-2" (click)="viewEventData(item,false)" title="View Event Info">
                <i class='fa fa-info'></i>
              </button>
              <!-- Need to add me-3 to the following button's class when delete button is enabled-->
              <button class="btn btn-outline-primary" (click)="openEditForm(item)" title="Edit">
                <i class='fa fa-edit'></i>
              </button>
              <!-- <button class="btn btn-outline-danger" (click)="deleteEvent(item.eventid)" title="Delete">
                                <i class='fa fa-trash-o'></i>
                            </button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination-controls class="paginate" (pageChange)="p = $event"
      *ngIf="events.length > itemPerPage && !eventAddorNot" previousLabel="Prev" nextLabel="Next"></pagination-controls>
  </div>
  <div class="backFromAttLoader" *ngIf="backFromAttLoading">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>