import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-office-choose-ranks',
  templateUrl: './office-choose-ranks.component.html',
  styleUrls: ['./office-choose-ranks.component.scss']
})
export class OfficeChooseRanksComponent implements OnInit {

  @ViewChild('chatPS') chatPS!: ElementRef;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };


  searchingCustomer: boolean = false;
  searchCustomerCancel: boolean = false;
  typeSearch = '';
  domainid: string = '';
  orgid: string = '';
  _contactList: any = [];
  typeList: any = [];
  _selectClass: any = [];
  gettingTypeList: boolean = false;
  isFocus: boolean = false;
  isSelectAll: boolean = false;

  _classList: any = [];

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;
  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<OfficeChooseRanksComponent>,
    private injector: Injector,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this._selectClass = JSON.parse(JSON.stringify(this.dialogData.typelist));
    this.domainid = this.dialogData.domainid;
    this.orgid = this.dialogData.orgid;
  }

  ngOnInit(): void {
    this.homesearchMemberType();
  }

  searchChange() {
    this.chatPS.nativeElement.scrollTop = 0;
    // clearTimeout(this.mytimeout);
    // this.mytimeout = setTimeout(() => {
    //   this.chatPS.nativeElement.scrollTop = 0;
    //   this.homesearchMemberType();
    // }, 500);
  }

  homesearchMemberType() {
    this.typeList = [];
    this.gettingTypeList = true;
    const data = {
      orgid: this.orgid,
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getClassCodeList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.gettingTypeList = false;

          this.typeList = res.datalist;
          this.typeList.map((type: any) => {
            var temp = this._selectClass.find((x: any) => x.id == type.id)
            if(temp){
              type.checked = true;
            }
            else{
              type.checed = false;
            }
          });
          this._selectClass.map((x: any) => {
            x.checked = true;
          })

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingTypeList = false;
      },
      (err) => {
        this.gettingTypeList = false;
      }
    );
  }
  selectAll() {
    this.isSelectAll = !this.isSelectAll

    if (this.isSelectAll) {
      this._selectClass = []
      for (var i = 0; i < this.typeList.length; i++) {
        this.typeList[i].checked = true;
        this._selectClass.push(this.typeList[i])
      }
      for (var j = 0; j < this._selectClass.length; j++) {
        this._selectClass[j].checked = true;
      }
    } else {
      this._selectClass = []
      for (var i = 0; i < this.typeList.length; i++) {
        this.typeList[i].checked = false;
      }
    }
    console.log(this._selectClass)
  }


  selectType(event: any, type: any) {

    var index = this.typeList.findIndex((cus: any) => cus.id == type.id);
    console.log(index)
    if (index != -1) {
      this.typeList[index].checked = !this.typeList[index].checked;
    }
    if (event.target.checked) {
      var index = this._selectClass.findIndex(
        (seluser: any) => seluser.id == type.id
      );
      if (index == -1) {
        type.checked = true;
        this._selectClass.push(type);
      } else {
        this._selectClass[index].checked = true;
      }
    } else {
      var index = this._selectClass.findIndex(
        (seluser: any) => seluser.id == type.id
      );
      if (index != -1) {
        this._selectClass[index].checked = false;
      }
    }
  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    let finalList = []
    finalList = this._selectClass.filter((type: { checked: boolean; }) => {
      return type.checked == true;
    });
    this.dialogRef.close({ data: finalList });
  }

}
