<div class="container-fluid" [ngClass]="{ scroll: checkModeal }">
  <div class="content">
    <div class="d-flex justify-content-between align-items-center mb-2" *ngIf="checkModeal">
      <div class="label title" *ngIf="!customerAdd" style="font-size: 17px">
        Customers
      </div>
      <div class="label title" *ngIf="customerAdd" style="font-size: 17px">
        Add Customer
      </div>
      <div class="close-btn">
        <button mat-icon-button mat-dialog-close (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="
        !leadAdd &&
        !customerAdd &&
        !dealAdd &&
        !serviceAdd &&
        !historyshow &&
        !summaryCheck
      ">
      <div class="d-flex" *ngIf="
          !goCusBol &&
          !goDealBol &&
          !gopipBol &&
          routevalueP == '' &&
          routevalueQ == '' &&
          routevalueAll == '' &&
          customername == '' &&
          !checkModeal &&
          !checkserviceM &&
          !checkserviceO
        ">
        <div class="me-auto">
          <app-cs365-breadcrumb [s_breadcrumb]="'Service Warranty'" [t_breadcrumb]="'Service Tickets'"
            (emit)="closeServiceTicket()">
          </app-cs365-breadcrumb>
        </div>

        <!--  -->
      </div>
      <app-crm-breadcrumb *ngIf="goCusBol && !checkModeal" s_breadcrumb="Service" [t_breadcrumb]="'Service Tickets'"
        (emit)="cancelFunction(1)">
      </app-crm-breadcrumb>

      <div>
        <div matSort (matSortChange)="sortDataService($event)" class="table-responsive"
          style="max-width: calc(100vw - 50px);" *ngIf="!isMobileView">
          <table class="table table-responsive table-borderless servicetable">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="ticketno" style="width: 80px">
                  Ticket
                </td>
                <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="description">
                  Description
                </td>
                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="customername">
                  Customer / Contact
                </td>
                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="status">
                  Status
                </td>
                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="targetdatetime">
                  Target Date
                </td>
                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="ageday">
                  Age
                </td>
                <!-- targetdatetimeshow -->
                <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="reportedby">
                  Reported By
                </td>
                <!-- <td scope="col" class="col-lg-1 col-md-1">Version
                                        </td> -->
                <td scope="col" class="d-flex justify-content-center">
                  Action
                </td>
              </tr>
            </thead>
            <tbody *ngIf="gettingData">
              <tr>
                <td colspan="9" class="nodata">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="gettingData == false">
              <tr *ngIf="data.length == 0">
                <td colspan="9" class="nodata">Empty</td>
              </tr>
              <tr *ngFor="
                  let item of data
                    | paginate
                      : { itemsPerPage: itemPerPage, currentPage: currentPage };
                  let index = index
                ">
                <td (click)="editService(item)" style="padding-top: 5px; cursor: pointer">
                  <span>
                    {{ item.ticketno }}
                  </span>
                  <span *ngIf="item.reopen == 'true'">
                    <!-- <span> -->
                    <i class="fa fa-repeat" aria-hidden="true" style="color: red; font-size: 10px"
                      matTooltip="Recurring"></i>
                  </span>
                  <!-- *ngIf="item.reticketno != ''" -->
                </td>
                <td class="td-datas" (click)="editService(item)" style="cursor: pointer">
                  <span>
                    <div id="descTextarea">{{ item.servicedesc }}</div>
                  </span>
                  <div class="d-flex" style="margin-top: 5px">
                    <div *ngIf="item.priority != ''" class="priority" [ngClass]="{
                        priorityprimary: item.priority == '00500',
                        priorityred: item.priority == '00900'
                      }">
                      {{
                      item.priority == "0010"
                      ? "Low"
                      : item.priority == "00500"
                      ? "High"
                      : "Critical"
                      }}
                    </div>
                    <div *ngIf="item.project != ''" class="priority" matTooltip="Project">
                      {{ item.project }}
                    </div>
                    <!-- <div *ngIf="item.type != ''" class="priority" matTooltip="Service Type">
                                                    {{item.type}}
                                                </div> -->
                    <div *ngIf="item.product != ''" class="priority" matTooltip="Product">
                      {{ item.product }}
                    </div>
                    <div *ngIf="item.version" class="priority" matTooltip="Version">
                      {{ item.version }}
                    </div>
                  </div>
                </td>

                <td *ngIf="!item.customerdefault && !isAdmin" (click)="editService(item)" style="cursor: pointer">
                  <span class="d-flex">
                    <span *ngIf="item.isinternal == 'true'" matTooltip="Internal">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 32 32"
                        style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                        <g>
                          <g id="Layer_16" data-name="Layer 16">
                            <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z" fill="#87929d" data-original="#87929d"
                              class=""></path>
                            <path d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z" fill="#87929d"
                              data-original="#87929d" class=""></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span *ngIf="item.name == '' && item.isinternal == 'true'">
                      <div id="descTextarea">Internal</div>
                    </span>
                    <span *ngIf="item.name">
                      <div id="descTextarea">{{ item.name }}</div>
                    </span>
                  </span>

                  <div *ngIf="item.contacts != null">
                    <span style="font-size: 13px; color: grey">
                      {{ item.contacts.name }}</span>

                    <br />
                  </div>
                </td>
                <td *ngIf="!item.customerdefault && isAdmin" class="gocus" (click)="editService(item)"
                  style="cursor: pointer">
                  <span class="d-flex">
                    <span *ngIf="item.isinternal == 'true'" matTooltip="Internal">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 32 32"
                        style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                        <g>
                          <g id="Layer_16" data-name="Layer 16">
                            <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z" fill="#87929d" data-original="#87929d"
                              class=""></path>
                            <path d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z" fill="#87929d"
                              data-original="#87929d" class=""></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span *ngIf="item.name == '' && item.isinternal == 'true'">
                      <div id="descTextarea">Internal</div>
                    </span>
                    <span *ngIf="item.name">
                      <div id="descTextarea">{{ item.name }}</div>
                    </span>
                  </span>

                  <div *ngIf="item.contacts != null">
                    <span style="font-size: 13px; color: grey">
                      {{ item.contacts.name }}</span>
                    <br />
                  </div>
                </td>
                <td *ngIf="item.customerdefault" class="" (click)="editService(item)" style="cursor: pointer">
                  <div class="d-flex">
                    <span *ngIf="item.isinternal == 'true'" matTooltip="Internal">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 32 32"
                        style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                        <g>
                          <g id="Layer_16" data-name="Layer 16">
                            <path d="m22.88 23.12h-14v-14h9v-2h-11v18h18v-11h-2z" fill="#87929d" data-original="#87929d"
                              class=""></path>
                            <path d="m12.88 14.12h-2v7h7v-2h-3.59l10.83-10.83-1.41-1.41-10.83 10.83z" fill="#87929d"
                              data-original="#87929d" class=""></path>
                          </g>
                        </g>
                      </svg>
                      <!-- <img  class="" style="width: 10px;
                                                height: auto;
                                                margin-right: 10px;" src="../../../assets/images/internal.png"> -->
                    </span>
                    <!-- *ngIf="" -->
                    <span *ngIf="item.name == '' && item.isinternal == 'true'">
                      <div id="descTextarea">Internal</div>
                    </span>
                    <span>
                      <div id="descTextarea">{{ item.name }}</div>
                    </span>
                  </div>
                </td>

                <td class="" (click)="editService(item)" style="cursor: pointer">
                  <div class="d-flex">
                    <span *ngIf="item.type == '0010'" class="typeservcie" matTooltip="{{ item.longtypename }}">{{
                      item.typename }}</span>
                    <span *ngIf="item.type != '0010'" class="servcietype" matTooltip="{{ item.longtypename }}">{{
                      item.typename }}</span>
                    <span *ngIf="customercare" class="open-status" [ngClass]="{
                        'closed-status-deal': item.status == '00900'
                      }">{{
                      item.status == "0010"
                      ? "New"
                      : item.status == "0040"
                      ? "Completed"
                      : item.status == "00900"
                      ? "Closed"
                      : item.status == "0060"
                      ? "UAT"
                      : item.status == "0061"
                      ? "UAT - Failed"
                      : item.status == "0062"
                      ? "UAT - Passed"
                      : "WIP"
                      }}</span>
                    <span *ngIf="!customercare" class="open-status" [ngClass]="{
                        'closed-status-deal': item.status == '00900'
                      }">{{ item.statusname }}</span>
                  </div>

                  <span>{{ item.startdatetime }} </span><span *ngIf="item.status == '00900' || item.status == '0040'">-
                    {{ item.enddatetime }}</span>
                </td>
                <td style="cursor: pointer">
                  {{ item.targetdatetimeshow }}
                </td>

                <td style="cursor: pointer">
                  <span> {{ item.ageday }} day(s) </span>
                </td>

                <td style="cursor: pointer" class="td-data">
                  <span>{{ item.reportedbyname }}</span>
                </td>
                <!-- <td class="" (click)="editService(item)"><span>{{item.version}}</span></td> -->

                <td class="d-flex justify-content-center">
                  <!-- <button matTooltip="Edit" class="btn btn-outline-primary" (click)="editService(item)">
                    <i class="fa fa-edit"></i>
                  </button> -->
                  <button matTooltip="History" class="ms-2 btn btn-outline-primary" (click)="history(item)">
                    <i class="fa fa-history"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="!gettingData && !checkModeal">
        <pagination-template *ngIf="data.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="
                p.isFirstPage()
                  ? 'custom-pagination-disabled'
                  : 'custom-pagination'
              " (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="
                nextDisabled
                  ? 'custom-pagination-disabled'
                  : 'custom-pagination'
              " (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>
      </div>
    </div>

    <div *ngIf="historyshow && !newpath">
      <app-crm-ticket-history [orgid]="crmForm.orgid" [productlist]="productListdata" [statuslist]="servcietypelist"
        [historydata]="dataForEdit" (save)="complete()" (crmcancel)="cancel()" [customercare]="customercare"
        [newpath]="true">
      </app-crm-ticket-history>
    </div>

    <div *ngIf="historyshow && newpath">
      <app-service-tickets-history [data]="dataForEdit" [orgid]="crmForm.orgid" (cancelfun)="cancel()" [warranty]="true"
        (serviceWarrantyCancel)="closeServiceTicket()"></app-service-tickets-history>
    </div>

    <div *ngIf="serviceAdd">
      <app-warranty-tickets-details (serviceadd)="backCustomer()" [checkOrg]="checkOrgB" [customercare]="customercare"
        [customerid]="customeridcare" [customercaredata]="getCustomercaredata" [orgid]="crmForm.orgid"
        [currencyType]="currencyType" [editData]="dataForEdit" [statuslist]="statusLists" [priority]="priority"
        [projectList]="projectList" (save)="complete()" (servicecancel)="cancelservice($event)">
      </app-warranty-tickets-details>
    </div>

  </div>
</div>