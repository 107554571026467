import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-desks',
  templateUrl: './office-desks.component.html',
  styleUrls: ['./office-desks.component.scss']
})
export class OfficeDesksComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isAdding : boolean = false;
  isEditing : boolean = false;
  saveLoading: boolean = false;
  subscriptions = new SubSink();
  isFocus : boolean = false;

  listLoading : boolean = false;
  searchText : string = '';
  orgid : string = '';
  formSubmitted : boolean = false;

  deskList : any = [];
  gettingDesks : boolean = false;

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  tableLoading : boolean = false;
  getList!: Subscription;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  submitForm = {
    floorname : '',
    workstationid : '',
    noofdesks : '',
  }

  constructor(
    public allinoneService : AllInOneService,
    private kunyekService : KunyekService,
    private messageService : MessageService,
    private route : ActivatedRoute,
    private router : Router,
    private dialog : MatDialog,
    private filterPipe : FilterPipe
  ) { 
    this.onResize(event);
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
   }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.getDesk()
  }

  ngOnDestroy(): void {
    this.getList && this.getList.unsubscribe();
  }

  getDesk() {
    this.gettingDesks = true;
    this.tableLoading = true;
    const data = {
      orgid : this.orgid,
    }
    this.subscriptions.sink = this.kunyekService.getDesks(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          // this.deskList = res.datalist
          // this.dataList = [];
          // res.datalist.map((x:any) => {
          //   var positions = '';
          //   x.positions.map((p:any, index :number) => {
          //     if(index == 0) {
          //       positions += p.name;
          //     } else {
          //       positions += ', ' + p.name;
          //     }
          //   });
          //   var temp = {
          //     ...x, positionlists : positions,

          //   };
          //   this.dataList.push(temp)
          // })

          this.deskList = [];
          res.datalist.map((x:any) => {
            var strDesks = '';
            x.desks.map((d:any, index:number) => {
              if(index == 0) {
                strDesks += d;
              } else {
                strDesks += ', ' + d
              }
            });
            var temp = {
              ...x, strDesk: strDesks,
            };
            this.deskList.push(temp)
          });          
          
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingDesks = false;
        this.tableLoading = false;
      },
      (err:any) => {
        this.gettingDesks = false;
        this.tableLoading = false;
        this.messageService.openSnackBar(
          'Server Error',
          'warn'
        );
      }
    )
  }

  submit() {
    this.formSubmitted = true;
    if(this.submitForm.floorname == '' || this.submitForm.floorname == null ) {
      this.messageService.openSnackBar('Floor cannot be blank.', 'warn');
      return
    }

    // if(this.submitForm.noofdesks == '0' ) {
    //   this.messageService.openSnackBar('Max No. Of Desk cannot be 0.', 'warn');
    //   return
    // }
    if( Number(this.submitForm.noofdesks) <= 0 ) {
      this.messageService.openSnackBar('No. of Desks cannot be less than 1.', 'warn');
      return
    }
    
    if(this.submitForm.noofdesks == '' || this.submitForm.noofdesks == null ) {
      this.messageService.openSnackBar('No. of Desks cannot be blank.', 'warn');
      return
    }
    this.saveLoading = true;
    var data = {
      workstationid : this.submitForm.workstationid,
      orgid : this.orgid,
      floorname : this.submitForm.floorname,
      noofdesks : this.submitForm.noofdesks
    }
    var fun = this.isEditing ?
    this.kunyekService.updateDesks(data) :
    this.kunyekService.addDesks(data)

    this.subscriptions.sink = fun.subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          if(this.isEditing) {
            var index = this.deskList.findIndex(
              (x:any) => x.workstationid == this.submitForm.workstationid
            );
            if(index > -1) {
              this.deskList[index].floorname = this.submitForm.floorname;
              this.deskList[index].noofdesks = this.submitForm.noofdesks;
              // this.deskList[index].desks = res.desks.toString();

              var strDesks = '';
              res.desks.map((d:any, index:number) => {
                if(index == 0) {
                  strDesks += d;
                } else {
                  strDesks += ', ' +d
                }
              })
              this.deskList[index].strDesk = strDesks;
            } else {
              this.refresh();
            }
            this.cancel();
          } else {
            var strDesks = '';
              res.desks.map((d:any, index:number) => {
                if(index == 0) {
                  strDesks += d;
                } else {
                  strDesks += ', ' +d
                }
              })
            var tempList = {...data, workstationid : res.workstationid, strDesk : strDesks};
            this.deskList.push(tempList);
            this.cancel();
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err:any) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
  }

  editDesk(data:any) {
    this.isEditing = true;
    this.isAdding = true;
    this.submitForm.workstationid = data.workstationid;
    this.submitForm.floorname = data.floorname;
    this.submitForm.noofdesks = data.noofdesks;
  }

  deleteDesk(id: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe(( dialogResult: any ) => {
      if(dialogResult) {
        this.tableLoading = true;
        const data = {
          orgid : this.orgid,
          workstationid : id
        }
        this.kunyekService.deleteDesks(data).subscribe(
          (res:any) => {
            if(res.returncode == '300') {
              var i = this.deskList.findIndex(
                (x:any) => x.workstationid == id
              );
              this.deskList.splice(i, 1);
              if (
                Math.round(this.deskList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.tableLoading = false;
          },
          (err) => {
            this.messageService.openSnackBar('Server Error', 'warn');
            this.tableLoading = false;
          }
        )
      }
    })
  }

  new() {
    this.isAdding = true;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  sortData(sort : Sort) {
    const data = this.deskList;
    if (!sort.active || sort.direction === '') {
      this.deskList = data;
      return;
    }
    this.deskList = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active == 'floorname') {
        return this.allinoneService.compare(a.floorname, b.floorname, isAsc);
      } else if(sort.active == 'maxpeople') {
        return this.allinoneService.compare(a.maxpeople, b.maxpeople, isAsc);
      } else if(sort.active == 'strDesk') {
        return this.allinoneService.compare(a.strDesk, b.strDesk, isAsc);
      }
      return 0;
    })
  }

  downloadSheet() {
    var exdata: any = [];
    var name = 'Desks.xlsx';
    var filteredData = this.filterPipe.transform(
      this.deskList,
      this.searchText,
      'desk'
    );
    filteredData.map((item: any) => {
      var temp = {
        Floor: item.floorname,
        'Total Number of Desks' : item.noofdesks,
        Desks : item.strDesk
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event:any) {

  }

  refresh() {
    this.currentPage = 1;
    this.getDesk();
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.submitForm = {
      floorname : '',
      workstationid : '',
      noofdesks : '',
    }

  }

}
