<body class="bg-light vh-100">
    <div class="container vh-100">
        <div class="row  py-3">
            <div class="col-lg-5 mx-auto">
                <div *ngIf="domains != null && domains.length > 0" class="bg-white p-2 rounded shadow-sm">
                    <form [formGroup]="form">
                        <div class="form-group header d-flex justify-content-between">
                            <b>Choose domain</b>

                            <button type="submit" class="btn btn-custom" (click)="ok()">OK</button>
                        </div>
                        <div class="form-group body ">
                            <div class="form-group custom-radio p-2" *ngFor="let domain of domains;"
                                (click)="chooseDomain(domain)">
                                <input formControlName="domains" type="radio" name="domains" [id]="domain.shortcode"
                                    [value]="domain" [ngModel]="selectedDomain">

                                <label [attr.for]="domain.shortcode">{{domain.description}}</label>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</body>