<ng-container *ngIf="!isError; else errorPage">
    <div class="container-fluid">
        <div class="content">

            <div class="custom-web-breadcrumb" *ngIf="list && loading == false">
                <span class="active-breadcrumb" (click)="gotohome()">Home</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span class="active-breadcrumb" (click)="gotocampaings()">Campaigns </span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span class="active-breadcrumb" (click)="back()">{{camdesc}}</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span>Voters</span>
            </div>
            <div class="custom-web-breadcrumb" *ngIf="isAdd || editVote || isExcel">
                <span class="active-breadcrumb" (click)="gotohome()">Home</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span class="active-breadcrumb" (click)="gotocampaings()">Campaigns </span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span class="active-breadcrumb" (click)="back()">{{camdesc}}</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span class="active-breadcrumb" (click)="govotinglist()"> Voters</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <span>{{isAdd || isExcel ? 'Add Voter' : 'Edit Voter'}}</span>
            </div>


            <div *ngIf="isExcel">

                <div class="mb-3 d-flex justify-content-start header mt-3">
                    <button type="button" class="btn btn-custom me-2" (click)="govotingEditlist()">
                        Cancel
                    </button>

                    <div class="close-save-button ">
                        <app-button-loader class="btn-custom" (click)="submitExcelVote()" style="border-radius: 5px;" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="addvoting" *ngIf = "errorList.length == 0">
                        </app-button-loader>
                        <button *ngIf = "errorList.length > 0" class = "btn btn-custom" disabled>
                            Save
                        </button>
                    </div>

                </div>
                <div class="mb-3">
                    <label for="file" class="form-label">Excel Template  (<span class="cc-label"
                        (click)="downloadSampleFile()">Download Sample Template</span>)</label>
                    <div class="input-group">
                        <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx" id="formFile" #excelFile [disabled]="excel">
                        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="excel" (click)="removeExcel()">Remove</button>
                        <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;" id="btnGroupAddon2" [disabled]="!excel || checkingExcelFile" (click)="checkExcelFile()">
                            <span *ngIf="!checkingExcelFile">
                                Check
                            </span>
                            <span *ngIf="checkingExcelFile">
                                <span class="spinner-border spinner-border-sm button-spinner" role="status"
                                    aria-hidden="true"></span>
                                <span class="mx-1">Checking</span>
                            </span>
                        </button>
                    </div>
                    <div role="alert" *ngIf="excelerror" style="color: red;padding: 6px;">
                        {{allinoneService.input_err_msg}}
                    </div>

                </div>
                <div style="color : green" *ngIf="allok">
                    <i class="fa fa-check me-2"></i> Template is correct.
                </div>
                <div class = "row">
                    <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0">
                        <div class="d-flex align-items-center">
                            <mat-icon class="me-2">
                                error
                            </mat-icon>
                            <div>
                                Error
                            </div>
                        </div>
                        <hr>
                        <ul style="padding-left: 1rem;">
                            <li *ngFor="let e of errorList;">
                                In line no. {{e.line}}, {{e.error}}
                            </li>
                        </ul>
                    </div>
                    <div class="alert alert-warning custom-box" *ngIf="duplicateList.length > 0 || warningList.length >0">
    
                        <div class="d-flex align-items-center">
                            <mat-icon class="me-2">
                                warning
                            </mat-icon>
                            <div>
                                Warning
                            </div>
                        </div>
                        <hr>
    
                        <ul style="padding-left: 1rem;">
                            <li *ngFor="let du of duplicateList;">
                                "{{du}}" is duplicated.
                            </li>
                            <li *ngFor="let e of warningList;">
                                In line no. {{e.line}}, {{e.error}}
                            </li>
                        </ul>
    
    
                    </div>
                </div>

            </div>

            <div *ngIf="isAdd">

                <form action="" #f="ngForm" (ngSubmit)="f.form.valid && addVote()" name="form" novalidate>

                    <div class="d-flex header flex-wrap">
                        <div class="d-flex mb-3">
                            <button *ngIf="!addvoting" class="btn me-2 btn-custom" (click)="govotinglist()">
                                Cancel
                            </button>
                            <app-button-loader class="btn-custom" style="border-radius: 5px;" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="addvoting">
                            </app-button-loader>
                        </div>




                    </div>

                    <div class="row">

                        <div class="mb-3 col-md-6">

                            <label for="userid" class="form-label">User ID

                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="userid" name="userid" placeholder="john@domain.com, +959********" [(ngModel)]="user_id" required #userid="ngModel" [ngClass]="{ 'is-invalid': f.submitted && userid.invalid }">
                            </div>
                            <div role="alert" *ngIf="userid.errors?.required && f.submitted" style="color: red;padding: 6px;">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="name" class="form-label">
                                Name
                            </label>
                            <input type="text" class="form-control" id="name" name="name" placeholder="Enter Name" [(ngModel)]="username" required #name="ngModel" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }">
                            <div role="alert" *ngIf="name.errors?.required && f.submitted" style="color: red;padding: 6px;">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="name" class="form-label proxy" (click)="openProxy()">
                                Proxy
                            </label>
                            <!-- <input type="text" class="form-control" id="votecount" name="votecount" placeholder="Enter vote count" [(ngModel)]="votecount" (keypress)="($event.charCode >= 48 && $event.charCode < 58)"> -->
                            <div class="input-group">
                                <input type="text" class="form-control" id="votecount" name="votecount" [(ngModel)]="votecount" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" disabled>
                                <div class="input-group-append">
                                    <span class="input-group-text" style="padding:10px;cursor: pointer;" (click)="openProxy()">
                                        <i class="fa fa-list-ul" id="globeicon"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="name" class="form-label">
                                Password
                            </label>
                            <div class="input-group">
                                <input [type]="fieldTextType ? 'text' : 'password'" name="password" [(ngModel)]="password" class="form-control" autofocus placeholder="Password">
                                <div class="input-group-append">
                                    <span class="input-group-text" style="padding:10px;">
                                        <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType,
                                                                   'fa-eye': fieldTextType}"
                                            (click)="toggleFieldTextType()"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="mb-3 col-md-12">
                            <label for="name" class="form-label">
                                Remark
                            </label>
                            <textarea type="text" class="form-control" id="remark" name="remark" [(ngModel)]="remark"></textarea>
                        </div>
                    </div>
                </form>
            </div>

            <div *ngIf="list">

                <div class="d-flex flex-wrap justify-content-between header mt-3">
                    <div class="mb-3 d-flex flex-wrap align-items-center">
                        <button type="button" class="btn btn-custom me-2" (click)="addvoter()">
                            <i class="fa fa-plus me-1"></i>Add
                        </button>

                        <div class="close-save-button me-2">
                            <button type="button" class="btn btn-custom ms-2" title="Bulk Upload" (click)="upload()">
                                <img class="excel-icon" src="../../../assets/images/excel.png"> Import
                            </button>
                        </div>
                        <div class="close-save-button">
                            <button type="button" class="btn btn-custom ms-2" title="Bulk Upload" (click)="downloadSheet()">
                                <img class="excel-icon" src="../../../assets/images/excel.png"> Export
                            </button>
                        </div>
                        <div class="close-save-button">

                            <button type="button" class="btn btn-custom ms-2" title="Bulk Upload" (click)="Open()">

                                 Import from Organization

                            </button>

                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex flex-wrap justify-content-end align-items-center">
                            <div class="d-flex align-items-center">
                                <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{voterscount}}</span>
                            </div>
                            <div class="line"></div>
                            <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                                <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                                    <mat-icon class="c-icon">close</mat-icon>
                                </button>
                            </div>
                            <div class="dropdown ms-2">
                                <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
                                    <mat-icon>filter_list</mat-icon>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <div c style="text-align: center;">Page size</div>
                                    </li>
                                    <li>
                                        <hr class="dropdown-divider">
                                    </li>
                                    <li class="dropdown-item" *ngFor="let page of pg">
                                        <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                                        <label for="pg{{page.name}}" class="col-10 p-1">
                                            {{page.name}}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div class="ms-2">
                                <button class="btn btn-custom" (click)="getVoter()" title="Refresh">
                                    <mat-icon>refresh</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 input-group" style="width: 300px !important;" *ngIf="isMobileView">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText" [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                            <mat-icon class="c-icon">close</mat-icon>
                        </button>
                    </div>

                </div>
                <!-- <div class="mb-3 d-flex justify-content-end header mt-3">
                </div> -->

                <div class="table-responsive">
                    <table class="table table-responsive table-borderless" matSort (matSortChange)="sortData($event)">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="userid">User ID</td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="username">Name</td>
                                <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="remark">Remark</td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="votecount">Vote Count</td>
                                <td scope="col" class="d-flex justify-content-center">Actions</td>
                            </tr>
                        </thead>
                        <tbody *ngIf="loading">
                            <tr>
                                <td colspan="7" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="loading == false">

                            <tr *ngIf="round.length == 0">
                                <td colspan="7" class="nodata">Empty</td>
                            </tr>
                            <tr *ngFor="let data of round | voterFilter:searchText | paginate: { itemsPerPage: itemPerPage, currentPage: p };">
                                <td class="td-data" (click)="editVoteter(data)" style="cursor: pointer;">
                                    {{data.userid}}
                                </td>
                                <td class="td-data">
                                    <span>{{data.name}}</span>
                                </td>
                                <td class="td-data">
                                    <span>{{data.remark}}</span>
                                </td>
                                <td class="td-data">
                                    <span>{{data.votecount}}</span>
                                </td>
                                <td class="d-flex justify-content-center">
                                    <button class="btn btn-outline-primary me-3" (click)="editVoteter(data)">
                                        <i class='fa fa-edit'></i>
                                    </button>
                                    <button class="btn btn-outline-danger" (click)="deleteVoter(data)">
                                        <i class='fa fa-trash-o'></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="round.length > itemPerPage && list" previousLabel="Prev" nextLabel="Next">
                </pagination-controls>
            </div>



            <div *ngIf="editVote">
                <form action="" #f="ngForm" (ngSubmit)="f.form.valid && updateVoter()" name="form" novalidate>

                    <div class="d-flex header flex-wrap">
                        <div class="d-flex mb-3">
                            <button *ngIf="!addvoting" class="btn me-2 btn-custom" (click)="govotingEditlist()">
                                Cancel
                            </button>
                            <app-button-loader class="btn-custom" style="border-radius: 5px;" [button_text]="'Update'" [load_button_text]="'Updating'" [isLoading]="addvoting">
                            </app-button-loader>
                        </div>



                    </div>

                    <div class="row">
                        <!-- <div *ngIf="memberAddorNot && !isBulkUpload"> -->
                        <div class="mb-3 col-md-6">

                            <label for="userid" class="form-label">User ID
                                <!-- <span *ngIf = "isUserValid && !isPreRegister">(Name - {{username}})</span> -->
                                <!-- <span *ngIf="isPreRegister"> ( Pre Register ) </span> -->
                            </label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="userid" name="userid" placeholder="john@domain.com, +959********" [(ngModel)]="voteEdit.userid" required #userid="ngModel" [ngClass]="{ 'is-invalid': f.submitted && userid.invalid }" disabled>
                            </div>
                            <div role="alert" *ngIf="userid.errors?.required && f.submitted" style="color: red;padding: 6px;">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="name" class="form-label">
                                Name
                            </label>
                            <input type="text" class="form-control" id="name" name="name" placeholder="Enter Name" [(ngModel)]="voteEdit.name">
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="name" class="form-label proxy" (click)="openProxy()">
                                Proxy
                            </label>
                            <!-- <input type="text" class="form-control" id="votecount" name="votecount" placeholder="Enter vote count" [(ngModel)]="votecount" (keypress)="($event.charCode >= 48 && $event.charCode < 58)"> -->
                            <div class="input-group">
                                <input type="text" class="form-control" id="votecount" name="votecount" [(ngModel)]="votecount" (keypress)="($event.charCode >= 48 && $event.charCode < 58)" disabled>
                                <div class="input-group-append">
                                    <span class="input-group-text" style="padding:10px;cursor: pointer;" (click)="openProxy()">
                                        <i class="fa fa-list-ul" id="globeicon"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="name" class="form-label">
                                Password
                            </label>
                            <div class="input-group">
                                <!--[disabled]="passwordDisabel" <input [type]="fieldTextType ? 'text' : 'password'" name="password" [(ngModel)]="editpassword" class="form-control" autofocus placeholder="Password" [disabled]="passwordDisabel"> -->
                                <input *ngIf="passwordDisabel" type="text" name="passwords" [(ngModel)]="edittest" class="form-control" autofocus placeholder="Password" [disabled]="passwordDisabel">
                                <input *ngIf="!passwordDisabel" [type]="fieldTextType ? 'text' : 'password'" name="password" [(ngModel)]="editpassword" class="form-control" autofocus placeholder="Password">
                                <div class="input-group-append d-flex">
                                    <span class="input-group-text" style="padding:10px;" *ngIf="!passwordDisabel">
                                        <i id="icon" class="fa fa-exchange-alt" [ngClass]="{'fa-eye-slash': !fieldTextType,
                                        'fa-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
                                    </span>
                                    <span class="input-group-text" id="deleteicon" *ngIf="passwordDisabel">
                                        <i class="far fa-trash-alt" (click)="deltepassword()"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mb-3 col-md-12">
                        <label for="name" class="form-label">
                            Remark
                        </label>
                        <textarea type="text" class="form-control" id="remark" name="remark" [(ngModel)]="voteEdit.remark"></textarea>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #errorPage>
    <div class="d-flex justify-content-center align-items-center" style="height: 100vh; width: 100vh;">
        <span class="mb-3">Something went wrong!</span>
        <button class="btn btn-secondary" (click)="reload()">Reload</button>
    </div>
    <div class="d-flex justify-content-center align-items-center" style="height: 100vh; width: 100vh;">
        <span class="noround">This campaign does not exist.</span>
    </div>
</ng-template>