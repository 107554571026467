import { data } from 'jquery';
import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { CrmCustomerDealComponent } from '../crm-customer-deal/crm-customer-deal.component';
import { CrmServiceFilterbydateComponent } from '../crm-service-filterbydate/crm-service-filterbydate.component';
import { PipelineTotalamountComponent } from '../pipeline-totalamount/pipeline-totalamount.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CrmCustomerAdvansearchComponent } from '../crm-customer-advansearch/crm-customer-advansearch.component';


@Component({
  selector: 'app-crm-customer',
  templateUrl: './crm-customer.component.html',
  styleUrls: ['./crm-customer.component.scss']
})
export class CrmCustomerComponent implements OnInit {

  isTabletView: boolean = false;

  isMobileView: boolean = false;
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {
      this.isMobileView = false;
      this.isTabletView = true;
    } else {
      this.isMobileView = false;
      this.isTabletView = false;

    }

  }

  isView: boolean = false;
  gettingData: boolean = true;
  gettingServicData: boolean = false;
  customerAdd: boolean = false;
  isFocus: boolean = false;
  gettingStages: boolean = false;

  dataForEdit: any = ''
  mobileViewWidth: number = 426;
  viewWidth: number = 992;
  itemPerPage: number = 100;
  p: number = 1;
  callType: any = '';
  servcietypelist: any = [];
  statusLists: any = [];
  searchStatusLead: any = '';
  searchStatusCustomer: any = '';
  searchstagedeal: any = '';
  searchStatusdeal: any = '';
  priority: any = [];
  filterstartdate: any = '';
  filterenddate: any = '';
  filterstartRdate: any = '';
  checkdate: boolean = false;
  searchpipe:any = "";

  organizationsList: any = [];
  gettingOrgList: boolean = false;
  orgtype: any = '';

  totalopencount: any = '0';
  totalclosedcount: any = '0';


  gettingCustomers: boolean = false;
  selfcustomer: any = [];
  customers: any = [];
  text1: any = '';
  text2: any = '';
  checkcustomer: boolean = false;
  statussearch: any = '';
  servicesearch: any = '';
  statussearchcus: any = '';
  cancelFunction() {
    console.log(data);
    this.customerAdd = false;


  }





  // (change)="selectProject($event)"
  leadstatusname: any = 'Status'
  // ChangeLeadStatus1(event: any) {
  //   this.searchStatusLead = event.target.value;
  //   console.log(this.searchStatusLead)

  // }
  ChangeLeadStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.leadstatusname = 'Status';
      this.searchStatusLead = '';
    } else {
      this.searchStatusLead = data.value;
      this.leadstatusname = data.value;
    }
  }

  customerstatusname: any = 'Status';

  // ChangeCustomerStatus(event: any) {
  //   this.searchStatusCustomer = event.target.value;
  //   console.log(this.searchStatusCustomer)
  // }
  customerstatus: any = '';
  ChangeCustomerStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.customerstatusname = 'Status';
      // this.searchStatusCustomer = '';
      this.customerstatus = '';
      this.refreshgetData();
    } else {
      this.customerstatusname = data.value;
      // this.searchStatusCustomer = data.value;
      this.customerstatus = data.key;
      this.refreshgetData();
    }
  }




  // ChangeDealStatus(event: any) {
  //   this.searchStatusdeal = event.target.value;
  //   console.log(this.searchStatusdeal)

  // }
  dealstatusname: any = 'Status';
  ChangeDealStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.dealstatusname = 'Status';
      this.searchStatusdeal = '';
    } else {
      this.dealstatusname = data.value;
      this.searchStatusdeal = data.value;
    }
  }




  dealstagename: any = "Stage"
  // ChangeDealStage(event: any) {
  //   this.searchstagedeal = event.target.value;
  //   console.log(this.searchstagedeal)
  // }
  ChangeDealStage(data: any) {
    console.log(data)
    if (data == 'stage') {
      this.dealstagename = 'Stage';
      this.searchstagedeal = '';
    } else {
      this.dealstagename = data.name;
      this.searchstagedeal = data.name;
    }
  }



  // servicetypes = [
  //   { "type": "System Error", "code": "001" },
  //   { "type": "User Error", "code": "002" },
  //   { "type": "Requirement - New Req.", "code": "003" },
  //   { "type": "Requirement - Change Req.", "code": "004" },
  //   { "type": "Mixed Issue (Gray)", "code": "005" },
  //   { "type": "Data Change/Script", "code": "006" },
  //   { "type": "Service Request", "code": "007" },
  //   { "type": "Hardware Related", "code": "008" },
  //   { "type": "Networking Related", "code": "009" },
  //   { "type": "Performance Issues", "code": "0010" },
  //   { "type": "Enquiry", "code": "0011" },
  //   { "type": "Implementation", "code": "0012" },
  //   { "type": "Training Request", "code": "0013" },
  //   { "type": "License Request", "code": "0014" },
  //   { "type": "New Installation", "code": "0015" },
  //   { "type": "Others", "code": "0016" },
  // ];

  // servicesdata: any = [
  //   // { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "Closed",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "Closed",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  // ];
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  types: any[] = [
    {
      id: '001',
      name: 'Leads',
    },
    {
      id: '002',
      name: 'Customers',
    },
    {
      id: '003',
      name: 'Deals',
    },
    {
      id: '004',
      name: 'Pipelines',
    },
    {
      id: '005',
      name: 'Services',
    },
  ];

  //Lead Status
  leadStatus = [
    {
      key: '001',
      value: 'Closed',
    },
    {
      key: '002',
      value: 'Open',
    },
  ];


  //Customer Status
  customerStatus = [
    {
      key: '001',
      value: 'Prospect',
    },
    {
      key: '002',
      value: 'Customer',
    },
  ];

  //Deal Status
  dealStatus = [
    {
      key: '001',
      value: 'Open',
    },
    {
      key: '002',
      value: 'Closed - Won',
    },
    {
      key: '003',
      value: 'Closed - Lost',
    },
  ];

  //Business Type
  businessType = [
    {
      key: '001',
      value: 'Organization',
    },
    {
      key: '002',
      value: 'Person',
    },
  ];

  //Currency Type
  currencyType = [
    {
      key: '001',
      currency: 'Kyat',
      code: 'MMK',
      country: 'Myanmar',
    },

    {
      key: '025',
      currency: 'dollar',
      code: 'USD',
      country: 'United States',
    },
    {
      key: '002',
      currency: 'Singapore dollar',
      code: 'SGD',
      country: 'Singapore',
    },
    {
      key: '003',
      currency: 'đồngr',
      code: 'VND',
      country: 'Vietnam',
    },
    {
      key: '004',
      currency: 'UAE dirham',
      code: 'AED',
      country: 'United Arab Emirates',
    },
    {
      key: '005',
      currency: 'Turkish lira',
      code: 'TRY',
      country: 'Turkey',
    },
    {
      key: '006',
      currency: 'Baht',
      code: 'THB',
      country: 'Thailand',
    },
    {
      key: '007',
      currency: 'Rupiah',
      code: 'IDR',
      country: 'Indonesia',
    },
    {
      key: '008',
      currency: 'New Taiwan dollar',
      code: 'TWD',
      country: 'Taiwan',
    },
    {
      key: '009',
      currency: 'South Korean won',
      code: 'KRW',
      country: 'South Korea',
    },
    {
      key: '010',
      currency: 'Yen',
      code: 'JPY',
      country: 'Japan',
    },
    {
      key: '011',
      currency: 'Russian Ruble',
      code: 'RUB',
      country: 'Russia',
    },
    {
      key: '012',
      currency: 'Philippine peso',
      code: 'PHP',
      country: 'Philippines',
    },
    {
      key: '013',
      currency: 'Pakistani Rupee',
      code: 'PKR',
      country: 'Pakistan',
    },
    {
      key: '014',
      currency: 'Nepalese rupee',
      code: 'NPR',
      country: 'Nepal',
    },
    {
      key: '015',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '016',
      currency: 'Jordanian dinar',
      code: 'JOD',
      country: 'Jordan',
    },
    {
      key: '017',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '018',
      currency: 'Indian Rupee',
      code: 'INR',
      country: 'India',
    },
    {
      key: '019',
      currency: 'Hong Kong dollar',
      code: 'HKD',
      country: 'Hong Kong',
    },
    {
      key: '020',
      currency: 'Renminbi (yuan)',
      code: 'CNY',
      country: 'China',
    },
    {
      key: '021',
      currency: 'Cambodian riel',
      code: 'KHR',
      country: 'Cambodia',
    },
    {
      key: '022',
      currency: 'Brunei dollar',
      code: 'BND',
      country: 'Brunei',
    },
    {
      key: '023',
      currency: 'Bhutanese ngultrum',
      code: 'BTN',
      country: 'Bhutan',
    },
    {
      key: '024',
      currency: 'Taka',
      code: 'BDT',
      country: 'Bangladesh',
    },
  ];

  stages: any[] = [];


  stageID: any = [];
  statusID: any = [];

  formatToShow = 'yyyy-MM-dd';
  now = Date.now();
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() + 30);
  currentEndDate = formatDate(
    this.oneWeekBefore,
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();

  crmForm = {
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    type: '',
    stageid: '',
    status: '',
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
  };

  organizations: any[] = [];
  data: any[] = [];
  @Input() width!: string;

  searchText: any = '';
  searchTOrgext: any = '';
  searchTextService: any = '';
  tabletViewWidth: any = 992;
  statusFilter: any = '';
  serviceFilter: any = '';

  routeType: any = '';
  allrouteType: any = '';
  @Input() t_breadcrumb!: string;
  projectList: any = '';
  gettingProjectList: boolean = false;
  projectid: any = '';
  startdatetime: string = '';
  enddatetime: string = '';
  searchLoading: boolean = false;
  nextDisabled: boolean = false;
  lastPage: any = '';
  currentPage: any = 1;
  total: any = '0';
  sortby: any = '';
  getServiceType: boolean = true;
  getSatatusType: boolean = true;
  statuscode: any = ['0010'];
  statusname: any = "Status";

  statuscodeList: any = [];
  statusnameList: any = ['New'];

  servicecode: string = '';
  viewopenservice: boolean = false;
  servicetype: any = '';
  goCustomer: any = '';
  goCusBol: boolean = false;
  goDealBol: boolean = false;
  gopipBol: boolean = false;
  checkOrgB: boolean = false;
  orgname: any = '';
  routevalueQ: any = '';
  routevalueP: any = '';


  routevalueAll: any = '';
  pagnation: any = [];
  Days: any;
  endDays: any = ''
  productadmin: boolean = false
  filtergetdata: any = [];
  isAdmin: boolean = false;
  customername: any = '';
  awaitDomain: any = '';
  orgList: any = [];
  customercare: boolean = false;
  customernamecare: any = "";
  getCustomercaredata: any = [];
  customeridcare: any = "";
  contactid: any = '';
  contactadmin: boolean = false;
  customerData: any = [];


  private subs = new SubSink();
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  // pagesize:any = 0;
  private dialogRef: any;
  private dialogData: any;
  checkModeal: boolean = false;
  isExcel: boolean = false;
  downloaddata: any = [];
  excelexportLoading: boolean = false;
  permission: any = [];

  customerview: boolean = false;
  customercreate: boolean = false;
  customeredit: boolean = false;
  customerdelete: boolean = false;

  view: boolean = false;
  create: boolean = false;
  edit: boolean = false;
  delete: boolean = false;
  customerid: any = '';
  ticketno: any = '';
  customerValue: any = '';
  checkselfservice: boolean = false;
  projectsmid: any = '';
  productsmid: any = '';
  customersmid: any = '';
  service: boolean = false;



  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private injector: Injector,

  ) {

    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);

    // if (this.router.url.startsWith('/service'))

    if (this.dialogData) {
      console.log(this.dialogData);
      this.checkModeal = this.dialogData.check;

      this.crmForm.type = '002';
      this.crmForm.orgid = this.dialogData.orgid;
      console.log(this.dialogData.picList);
      console.log('Pass Candi>>');
      // this.deleteList = JSON.parse(JSON.stringify(data.deletelist));
      console.log('--AA--');
    } else {


      console.log(this.allinoneService.getindex())

      if (window.innerWidth < this.mobileViewWidth) {
        this.isMobileView = true;
        this.itemPerPage = 20
        this.isTabletView = false;
      } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {
        this.isMobileView = false;
        this.isTabletView = true;
      }
      this.organizations = this.allinoneService.orgsCRm;





    }
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.allinoneService.activeroute = "/sales";

  }


  async ngOnInit(): Promise<void> {
    // this.router.navigateByUrl('service');

    // if (this.router.url.startsWith('/service')) {
    //   this.service = true;

    // } else {
    //   this.service = false;
    // }

    // this.organizations = this.allinoneService.orgsCRm;
    if (this.organizations.length > 0) {
      this.crmForm.orgid = this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid;
    }

    this.getCustomercaredata = this.allinoneService.getCustomer();
    if (this.getCustomercaredata != '') {

    }
    else {
      if (
        this.router.url.startsWith('/service')


      ) {
        if (this.allinoneService.crmBlocked == 'true') {
          this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
        } else {
        }

        // var orgID = this.allinoneService.selectedOrgCRM;
        // await this.getPermission(orgID);
        // if (orgID) {
        //   await this.getPermission(orgID);
        // } else {
        //   if (this.orgListcrm.length > 0) {
        //     await this.getPermission(this.orgListcrm[0].orgid);
        //   }
        // }
      } else {
        if (this.allinoneService.crmOrgAdmin == 'true') {
          console.log("1")
        } else {
          console.log("1");
          this.permission = this.allinoneService.getCRMpermission();

          if (this.permission.length > 0) {
            var type = this.permission.map((x: any) => x).indexOf('008');
            if (type == -1) {
              this.router.navigateByUrl('sales')
            }
            var view = this.permission.map((x: any) => x).indexOf('0081');
            var create = this.permission.map((x: any) => x).indexOf('0082');
            var edit = this.permission.map((x: any) => x).indexOf('0083');
            var delet = this.permission.map((x: any) => x).indexOf('0084');
            if (view == -1) {
              this.customerview = true;
            }
            if (create == -1) {
              this.customercreate = true;
            }

            if (edit == -1) {
              this.customeredit = true;
            }

            if (delet == -1) {
              this.customerdelete = true;
            }


          } else {
            this.router.navigateByUrl('sales')
          }

        }
      }


    }







    if (this.customerview == true) {
      this.gettingData = false;
    } else {
      this.getData();
      this.getStages();
    }



    this.startdatetime = formatDate(
      new Date(),
      this.formatToShow,
      'en-US'
    ).toString();
    var futureDate = new Date();

    futureDate.setDate(futureDate.getDate() + 7);
    this.enddatetime = formatDate(
      futureDate,
      this.formatToShow,
      'en-US'
    ).toString();
    console.log(this.enddatetime)
    if (this.width == null) {
      this.width = "auto";
    }








  }




  sortData(sort: Sort) {
    const data = this.data;
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {

        //Customer
        case 'customerid':
          return this.allinoneService.compare(
            a.customerid.toLocaleLowerCase(),
            b.customerid.toLocaleLowerCase(),
            isAsc
          );
        case 'customername':
          return this.allinoneService.compare(
            a.customername.toLocaleLowerCase(),
            b.customername.toLocaleLowerCase(),
            isAsc
          );
        case 'customerphone':
          return this.allinoneService.compare(
            a.customermobile,
            b.customermobile,
            isAsc
          );
        case 'customeremail':
          return this.allinoneService.compare(
            a.customeremail,
            b.customeremail,
            isAsc
          );

        case 'customertype':
          return this.allinoneService.compare(a.typename, b.typename, isAsc);
        case 'customerstatus':
          return this.allinoneService.compare(
            a.statusname,
            b.statusname,
            isAsc
          );
        case 'customercreatedby':
          return this.allinoneService.compare(
            a.createdbyname.toLocaleLowerCase(),
            b.createdbyname.toLocaleLowerCase(),
            isAsc
          );


        default:
          return 0;
      }
    });

  }







  orgChanged(event: any) {
    this.crmForm.orgid = event.target.value;
    if (this.crmForm.orgid == '') {
      this.data = [];
    } else {
      if (this.callType == '300') {
        this.allinoneService.selectedOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedOrg(this.crmForm.orgid);
      } else {
        this.allinoneService.selectedConOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedConOrg(this.crmForm.orgid);
      }
      this.getData();
    }
  }

  typeChanged(event: any) {
    this.crmForm.type = event.target.value;
    if (this.crmForm.orgid) {
      this.getData();
    }
  }

  getStages() {
    this.gettingStages = true;
    const data = {
      orgid: '',
      domainid: this.crmForm.domainid,
    };
    this.kunyekService.getPipelineStage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.stages = res.list;
          // this.crmForm.stageid = this.stages[0].stageid;
          this.gettingStages = false;
        } else {
          this.gettingStages = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStages = false;
      }
    );
  }
  industryList: any = [];


  getData() {
    this.gettingData = true;
    // this.data = [];
    // this.p = 1;
    this.total = '0';

    //Lead

    //Customer
    const data = {
      domainid: this.crmForm.domainid,
      orgid: this.crmForm.orgid,
      pagesize: this.itemPerPage,
      sortby: this.sortby,
      query: this.searchbool == true ? this.searchText : '',
      status: this.customerstatus,
      contactname: this.customerData.name ? this.customerData.name.toString().trim() : '',
      productname: this.customerData.productid ? this.customerData.productid : '',
      phone: this.customerData.phone ? this.allinoneService.checkUserId(this.customerData.phone) : ''


    };
    this.subs.sink = this.kunyekService.getCrmCustomers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.data.push(res.list);
          // this.itemPerPage = 100;
          // this.pg[3].count = this.data.length;
          res.list.map((item: any) => {
            var statusIndex = this.customerStatus
              .map((x) => x.key)
              .indexOf(item.customerstatus);
            var typeIndex = this.businessType
              .map((x) => x.key)
              .indexOf(item.customertype);
            this.data.push({
              "contactlist": item.contactlist,
              'createby': item.createby,
              'createdbyname': item.createdbyname,
              'customeraddress1': item.customeraddress1,
              'customeraddress2': item.customeraddress2,
              'customeremail': item.customeremail,
              'customerid': item.customerid,
              'customermobile': item.customermobile,
              'customername': item.customername,
              'customerstatus': item.customerstatus,
              'statusname': statusIndex > -1 ? this.customerStatus[statusIndex].value : '',
              'typename': typeIndex > -1 ? this.businessType[typeIndex].value : '',
              'customertype': item.customertype,
              'deallist': item.deallist,
              'filelist': item.filelist,
              'filename': item.filelist,
              'fileurl': item.fileurl,
              'industrytype': item.industrytype,
              'sort': item.sort,
              'productlist': item.productlist,
              'projectlist': item.projectlist,
              'customershortcode': item.customershortcode,
              'branchlist': item.branchlist,
            })
          });

          var sort = this.data.length - 1;
          this.total = res.total;

          const customerid = this.route.snapshot.queryParamMap.get('id') || '';

          // }
          console.log(this.data);
          const result = this.data.filter((obj) => {
            return obj.customerid == customerid;
          }
          );

          console.log("......");
          console.log(result);
          console.log(customerid);
          if (customerid) {
            this.editCustomer(result[0])

          }


          if (parseInt(this.total) == this.data.length) {
            console.log("getTrue")
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.data[sort].sortby;
          } else {
            console.log("getfalse");
            this.sortby = this.sortby = sort > -1 ? this.data[sort].sort : '';
          }


          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }

  getExportCustomerData() {
    this.excelexportLoading = true;
    console.log("1111111111111111111111111111iiiiiiiiiiiiii");
    return new Promise((resolve) => {

      const data = {
        domainid: this.crmForm.domainid,
        orgid: this.crmForm.orgid,
        pagesize: this.itempercount,
        sortby: this.sortby,
        query: this.searchbool == true ? this.searchText : '',
      };
      this.subs.sink = this.kunyekService.getCrmCustomers(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            res.list.map((item: any) => {
              var statusIndex = this.customerStatus
                .map((x) => x.key)
                .indexOf(item.customerstatus);
              var typeIndex = this.businessType
                .map((x) => x.key)
                .indexOf(item.customertype);
              this.downloaddata.push({
                "contactlist": item.contactlist,
                'createby': item.createby,
                'createdbyname': item.createdbyname,
                'customeraddress1': item.customeraddress1,
                'customeraddress2': item.customeraddress2,
                'customeremail': item.customeremail,
                'customerid': item.customerid,
                'customermobile': item.customermobile,
                'customername': item.customername,
                'customerstatus': item.customerstatus,
                'statusname': statusIndex > -1 ? this.customerStatus[statusIndex].value : '',
                'typename': typeIndex > -1 ? this.businessType[typeIndex].value : '',
                'customertype': item.customertype,
                'deallist': item.deallist,
                'filelist': item.filelist,
                'filename': item.filelist,
                'fileurl': item.fileurl,
                'industrytype': item.industrytype,
                'sort': item.sort,
                'productlist': item.productlist,
                'projectlist': item.projectlist,
                'customershortcode': item.customershortcode,
                'branchlist': item.branchlist,
              })
            });

            // var sort = this.data.length - 1;
            this.total = res.total;

            // const customerid = this.route.snapshot.queryParamMap.get('id') || '';
            console.log("Ok")


            resolve('true')
          } else {
            this.excelexportLoading = false;
            resolve('false')
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false')
          this.excelexportLoading = false;
        }
      );

    });
  }


  editCustomer(item: any) {
    if (this.customeredit == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      this.dataForEdit = JSON.parse(JSON.stringify(item))
      this.customerAdd = true
    }
  }

  onActivate() {
    window.scrollTo(0, 0);
  }

  deleteCustomer(id: any) {
    if (this.customerdelete == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        width: "250px",
        panelClass: ['mat-typography'],
        closeOnNavigation: true
      })

      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.gettingData = true;
          const data = {
            "customerid": id,
            "orgid": this.crmForm.orgid,
            "domainid": this.crmForm.domainid,
            "domain": this.allinoneService.getDomain().shortcode
          }
          this.kunyekService.deleteCrmCustomer(data).subscribe((res: any) => {
            if (res.returncode == "300") {
              this.refreshgetData();
            }
            else {
              this.gettingData = false;
              this.messageService.openSnackBar(res.status, 'warn');
            }
          },
            err => {
              this.gettingData = false;
            }
          )
        }
      });
    }

  }

  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    // if (this.crmForm.type != '005') {
    console.log("okiejfi")
    this.refreshgetData();

  }

  clear(data: any) {
    if (data == 2) {
      this.searchbool = false;
      this.searchText = '';
      this.refreshgetData()


    }

  }

  complete() {
    this.projectid = '';
    this.orgtype = this.crmForm.orgid;
    this.refreshgetData();
    this.cancel()
  }
  backCustomer() {
    this.router.navigateByUrl('sales/customers')
  }

  cancel() {
    this.dataForEdit = ''
    this.isExcel = false;
    this.customerAdd = false;
    this.orgtype = this.crmForm.orgid;
  }

  summary() {
    console.log("condole.log(summar");

    // this.summaryCheck = true;
    // this.router.navigateByUrl('/sales/services/summary');
  }



  new() {
    this.cancel()


    if (this.customercreate == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedViewMessage, 'warn');
    } else {
      this.isExcel = false;
      this.customerAdd = true
    }

  }

  goToSecond() {

  }

  getAllProjects() {
    this.gettingProjectList = true;
    this.projectList = [];
    const data = {
      orgid: this.crmForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
      // role: this.callType,
    };

    console.log(data);
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          var userid = this.allinoneService.getKunyekUserId();
          if (this.allinoneService.crmOrgAdmin == 'false') {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i]['memberstatus'] == '') {
                this.projectList.push(res.data[i]);
              }
              if (res.data[i]['memberstatus'] == '1') {
                for (let j = 0; j < res.data[i]['memberlist'].length; j++) {
                  console.log(res.data[i]['memberlist'][j]['userid'] == userid);
                  if (userid == res.data[i]['memberlist'][j]['userid']) {
                    this.projectList.push(res.data[i])
                  }
                }
              }
            }
          } else {
            this.projectList = res.data;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;

      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  close() {
    const data = {
      'status': true,
    }

    this.dialogRef.close(data);
  }



  refreshgetData() {
    this.subs.unsubscribe();
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';

    if (this.customerview == true) {
      this.gettingData = false;
    } else {
      this.getData();
      // this.getStages();
    }

  }









  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.lastPage);
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;

        if (event > (this.data.length / this.itemPerPage)) {

          console.log("nnn>>>>>>getData")
          this.getData();

        }
      }

    }
  }



  getStatusList() {
    return new Promise((resolve) => {

      this.getSatatusType = true;
      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.crmForm.orgid,
      }
      this.kunyekService.getStatusType(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.statusLists = res.list;

            this.statusLists.push({
              "autoid": '',
              "code": "Not Completed",
              "name": "Not Completed",
              "orgid": "[]",
            })

            if (this.statusLists.length > 0) {
              this.statusname = this.statusLists[0].name;
            }
            for (let i = 0; i < this.statusLists.length; i++) {
              if (this.statusLists[i].code == '0010') {
                this.statusLists[i].check = true;
              } else {
                this.statusLists[i].check = false;
              }

            }
            console.log(".......statuslist>>>>")
            console.log(this.statusLists)
            this.getSatatusType = false;
            resolve('true')
          } else {
            resolve('false')
            this.getSatatusType = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false')
          this.getSatatusType = false;
        }
      );
    });

  }




  itempercount: any = '';






  async downloadSheetCustomer() {
    this.downloaddata = JSON.parse(JSON.stringify(this.data))
    if (this.itemPerPage > this.total) {
      this.itempercount = this.itemPerPage;
      console.log('okokok')
    } else {
      console.log('kokok')
      this.itempercount = this.total - this.data.length
      this.excelexportLoading = true;
      await this.getExportCustomerData();
    }
    var data: any = [];
    var conatactdata: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Customers_' + time + '.xlsx';
    var realdata: any = [
      {
        name: "Customer",
        data: [],
      },
      {
        name: "Contacts",
        data: [],
      },
      {
        name: "Related Products",
        data: [],
      },

      {
        name: "Related Projects",
        data: [],
      },
      {
        name: "Branches",
        data: [],
      },
    ];
    console.log(realdata[0])
    console.log(realdata[1])
    console.log(this.downloaddata[0])
    console.log(this.downloaddata[0].productlist)
    // this.downloaddata[i].productlist.length
    for (let i = 0; i < this.downloaddata.length; i++) {
      // if (this.downloaddata[i].contactlist.length == 0) {
      realdata[0].data.push({
        'Customer ID': this.downloaddata[i].customerid,
        'Name': this.downloaddata[i].customername,
        'Phone': this.downloaddata[i].customermobile,
        'Email': this.downloaddata[i].customeremail,
        'Address': this.downloaddata[i].customeraddress1 + "" + this.downloaddata[i].customeraddress2,
        'Type': this.downloaddata[i].typename,
        'Status': this.downloaddata[i].statusname,
      });
      for (let j = 0; j < this.downloaddata[i].contactlist.length; j++) {
        realdata[1].data.push({
          "Customer Name": this.downloaddata[i].customername,
          "Contact Name": this.downloaddata[i].contactlist[j].contactname,
          "Contact Email": this.downloaddata[i].contactlist[j].contactemail,
          "Contact Mobile": this.downloaddata[i].contactlist[j].contactmobile,
          "Contact Post": this.downloaddata[i].contactlist[j].contactpost,
          "Email Access": this.downloaddata[i].contactlist[j].emailaccess,
          "Self Service": this.downloaddata[i].contactlist[j].selfservice,
          "Address": this.downloaddata[i].contactlist[j].contactaddress1 + "" + this.downloaddata[i].contactlist[j].contactaddress2
        })
      }

      for (let l = 0; l < this.downloaddata[i].productlist.length; l++) {
        realdata[2].data.push({
          "Customer Name": this.downloaddata[i].customername,
          "Product": this.downloaddata[i].productlist[l].productname,
          "Person In Change": this.downloaddata[i].productlist[l].personincharge,
          "Customer Contact Person": this.downloaddata[i].productlist[l].contactperson,
          "Customer Contact Email": this.downloaddata[i].productlist[l].contactpersonemail,
          "Customer Contact Mobile": this.downloaddata[i].productlist[l].contactpersonmobile,

        })
      }

      for (let l = 0; l < this.downloaddata[i].projectlist.length; l++) {
        realdata[3].data.push({
          "Customer Name": this.downloaddata[i].customername,
          "Project": this.downloaddata[i].projectlist[l].projectname,
          "Customer Contact Person": this.downloaddata[i].projectlist[l].contactperson,
          "Customer Contact Email": this.downloaddata[i].projectlist[l].contactpersonemail,
          "Customer Contact Mobile": this.downloaddata[i].projectlist[l].contactpersonmobile,

        })
      }


      for (let l = 0; l < this.downloaddata[i].branchlist.length; l++) {
        realdata[4].data.push({
          "Customer Name": this.downloaddata[i].customername,
          "Branch Name": this.downloaddata[i].branchlist[l].branchname,
          "Branch Email": this.downloaddata[i].branchlist[l].branchemail,
          "Branch Mobile": this.downloaddata[i].branchlist[l].branchmobile,
          "Branch industry type": this.downloaddata[i].branchlist[l].branchindustrytype ? this.downloaddata[i].branchlist[l].branchindustrytype : "",
          "Branch Address": this.downloaddata[i].branchlist[l].branchaddress1 + this.downloaddata[i].branchlist[l].branchaddress2,

        })
      }

    }
    console.log(realdata);
    this.excelexportLoading = false;
    this.allinoneService.exportExcelMultipleSheets(realdata, name);
  }





  goCrm() {
    this.router.navigateByUrl('service')
  }



  searchbool: any = false;

  onSearch() {
    if (this.searchText != '') {
      this.searchbool = true;
      this.refreshgetData()
    }


  }
  // .






  goSummary() {
    this.router.navigateByUrl('service/summary')
  }
  goSummarySelfservice() {
    this.router.navigateByUrl('service')
  }
  backCustomerbreadcrumb() {
    this.router.navigateByUrl('sales/customers')

  }
  backCustomerbreadcrumbedit() {
    const customerid = this.route.snapshot.queryParamMap.get('customerid') || "";
    // ;
    this.router.navigateByUrl(`sales/customers?id=${customerid}`)
  }

  importCustomer() {
    this.customerAdd = true
    this.isExcel = true;

  }
  isExcelLead: boolean = false;
  importLead() {
    this.isExcelLead = true;

  }




  advanSearchCustomer() {

    const dialog = this.dialog.open(CrmCustomerAdvansearchComponent, {
      height: 'auto',
      width: '400px',
      //  minWidth: '250px',
      // maxWidth: this.isMobileView ? '250px' : "850",
      // minWidth: this.isMobileView ? '50px' : "850",
      closeOnNavigation: true,

      panelClass: ['mat-typography', 'dialog-container-advansearch'],
      data: {
        // startdate: this.filterstartdate,
        // enddate: this.filterenddate,
        // rangestartdate: this.filterstartRdate,
        // checkdate: this.checkdate,
        data: this.customerData,
        // customercare: this.customercare ? this.customercare : false
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.customerData = dialogResult.data;
        this.refreshgetData();

      }
    });
  }
  closeAdvanSearchCusotemr(data: any) {
    if (data == 1) {
      this.customerData.phone = '';
    } else if (data == 2) {
      this.customerData.name = '';

    } else if (data == 3) {
      this.customerData.productid = '';
      this.customerData.productname = '';
    }
    this.refreshgetData();

  }



}
