import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-general-payment-detail',
  templateUrl: './general-payment-detail.component.html',
  styleUrls: ['./general-payment-detail.component.scss']
})
export class GeneralPaymentDetailComponent implements OnInit {
  payment: any;
  id!: string;
  senderName?: string;
  currency: string = "MMK";
  breadcrumbs: any[] = [{ isActive: true, url: 'payment', name: 'Payment' }, { isActive: true, url: 'members/paymentinvoice', name: 'General Payment' }, { isActive: false, url: '', name: '' }]

  constructor(
    private kunyekService: KunyekService,
    private route: ActivatedRoute,
    public allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => { this.id = value.get('paymentid') || '' })
    this.kunyekService.getPaymentInvoice().get({ id: this.id }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.senderName = res.data.sender;
          this.payment = res.data;
          this.breadcrumbs[2].name = this.senderName;
        }
      }
    })
  }


  printPage() {
    const printContent = document.getElementById('printableCard');
    if (printContent) {
      const printClone = printContent.cloneNode(true);
      const printContainer = document.createElement('div');
      printContainer.style.position = 'absolute';
      printContainer.style.left = '-9999px';
      printContainer.appendChild(printClone);
      document.body.appendChild(printContainer);
      const style = document.createElement('style');
      style.innerHTML = `
        @media print {
          .me-auto, .navbar, .side-nav, .side-nav-expand, .btn-action {
            display: none !important;
          }
          #print-container, #print-container * {
            visibility: visible;
          }
          #print-container {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `;
      document.head.appendChild(style);
      printContainer.id = 'print-container';
      window.print();
      document.body.removeChild(printContainer);
      document.head.removeChild(style);
    } else {
      console.error('printableCard element not found');
    }
  }



  exportPDF() {
    const name = this.senderName + "-" || '';
    const data = document.getElementById('printableCard');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${name}-general-payment.pdf`);

      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
    } else {
      console.error('printableCard element not found');
    }
  }
}
