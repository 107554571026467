<div class="container-fluid">
  <div class="content">
    <app-office-breadcrumb *ngIf="!isAdding"
      [s_breadcrumb]="isLateRequest ? 'Late' : 'Earlyout'"></app-office-breadcrumb>
    <app-office-breadcrumb *ngIf="isAdding" [s_breadcrumb]="isLateRequest ? 'Late' : 'Earlyout'"
      [t_breadcrumb]="isEditing ? isLateRequest ? 'Edit Late' : 'Edit Earlyout' : isLateRequest ? 'New Late' : ' New Earlyout'"
      (emit)="cancel()"></app-office-breadcrumb>
    <ng-container *ngIf="!isAdding">
      <div class="d-flex">
        <button return="button" class="btn btn-custom mb-3 me-2" (click)="newRequest()">
          New Request
        </button>
        <button return="button" class="btn btn-custom mb-3 me-2" (click)="details()">
          Details
        </button>
      </div>
      
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Ref No./Type/Status"
            aria-label="Search by name" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            (ngModelChange)="searchTextChange()" [ngClass]="{ 'c-search-input-with-clear': searchText }"
            style="font-family: FontAwesome, Ubuntu" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (dataList | filter : searchText : 'requestList').length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Ref No./Type/Status"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <pagination-template class="ms-auto" *ngIf="dataList.length > 0" #p="paginationApi"
          (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="
          p.isFirstPage()
            ? 'custom-pagination-disabled'
            : 'custom-pagination'
        " (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="
          nextDisabled
            ? 'custom-pagination-disabled'
            : 'custom-pagination'
        " (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>

      </div>

      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" mat-sort-header="name">Ref No.</td>
              <td scope="col" class="col-md-2" mat-sort-header="date">Date</td>
              <td scope="col" class="col-md-2" mat-sort-header="type">Type</td>
              <td scope="col" class="col-md-2" mat-sort-header="status">Status</td>
              <td scope="col" class="col-md-6" mat-sort-header="remark"></td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingData">
            <tr>
              <td colspan="7" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingData">
            <tr *ngIf="dataList.length == 0">
              <td colspan="7" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="let data of dataList | filter : searchText: 'merchant' | paginate: { itemsPerPage: itemPerPage , currentPage : currentPage } ; let index = index">
              <td class="td-data td-edit" (click)="editData(data)">
                <span [title]="data.name">{{ data.name }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.description">{{
                  data.description
                }}</span>
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button
                  class="btn btn-outline-primary me-2"
                  (click)="editData(data)"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  class="btn btn-outline-danger"
                  (click)="deleteData(data)"
                >
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </ng-container>

    <ng-container *ngIf="isAdding">
      <div class="d-flex align-items-center">
        <button
          type="button"
          class="btn btn-custom me-2 mb-3"
          (click)="cancel()"
        >
          Cancel
        </button>
        <app-button-loader
          class="mb-3"
          (click)="save()"
          [button_text]="'Save'"
          [load_button_text]="'Saving'"
          [isLoading]="saveLoading"
        >
        </app-button-loader>
      </div>
    </ng-container>

  </div>
</div>