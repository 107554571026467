
import { DatePipe, formatDate, } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,

} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestPickupComponent } from '../request-pickup/request-pickup.component';
import { Sort } from '@angular/material/sort';
import { CrmFilterPipe } from 'src/app/crm/crm/crm-filter.pipe';
import { FilterPipe } from 'src/app/shared/filter.pipe';


@Component({
  selector: 'app-requests',
  templateUrl: './outstanding-requests.component.html',
  styleUrls: ['./outstanding-requests.component.scss']
})
export class OutstandingRequestsComponent implements OnInit {

  gettingData: boolean = false;
  outstandingrequestList: any = [];
  addrequest: boolean = false;
  saveLoading: boolean = false;
  gettingVehicle: boolean = false;
  carFormSave: boolean = false;
  isEditing: boolean = false;
  orgid: any = "";
  organizations: any = [];
  searchText: any = "";

  itemPerPage: any = "";
  currentPage: any = 1;
  text1: any = "";
  text2: any = "";
  oldpickuppointid: any = "";
  startdate: any = "";
  weekDay = [
    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  memberList: any = [];
  format: any = "hh:mm aa";
  outstandingRequests = {
    "name": "",
    "employeeid": "",
    "startdate": "",
    "enddate": "",
    "requestid": "",
    "weekday": "",
    "remark": "",
    "userid": "",
    "refno": "",
    "date": "",
    "status":"",
    "index": "",
    "statusname": "",
    "going": false,
    "toplace": "",
    "arrivetime": "",
    "return": false,
    "returntime": "",
    "userfrom": "",
    "userto": "",
    "pickuppointname": ""
  }
  requeststatus: any = "";
  statusList = [
    { "name": "All", "code": "" },
    { "name": "Submitted", "code": "001" },
    { "name": "Approved", "code": "002" },
    { "name": "Rejected", "code": "003" },
    { "name": "Completed", "code": "004" }
  ];

  typelist :any = [
    {'name':"Ferry",'type':'001'},
    {'name':"Day Car Way",'type':'002'}
  ]
  typename:any = "Ferry";
  typerequest:any = "001";


  requestData: any = [];
  istransoprationAdmin: boolean = false;
  formatToShow = 'yyyy-MM-dd';
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.istransoprationAdmin = this.allinoneService.isAdminOf("transportation");
    if (this.istransoprationAdmin == false) {
      this.router.navigateByUrl(`transportation`);
    }
    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    this.startdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();

    this.getoutstandingrequestList();
  }



  cancelFunction() {
    this.addrequest = false;
    this.isEditing = false;
  }


  getoutstandingrequestList() {
    this.gettingData = true;
    this.outstandingrequestList = [];
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "trantype": this.typerequest,
      "requeststatus": "001",
      "startdate": formatDate(
        new Date(this.startdate),
        'yyyyMMdd',
        'en-US'
      ).toString(),
      "enddate": formatDate(
        new Date(this.startdate),
        'yyyyMMdd',
        'en-US'
      ).toString(),
      "type":"002"
      // "date":this.allinoneService.formatDBToShow(this.startdate)
    };
    this.kunyekService.getOutstandingRequest(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.outstandingrequestList = res.datalist;

          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }


  customerSelect(data: any) {
    console.log(data);


  }

  async EmployeeEdit(data: any, index: any) {
    console.log(data);
    this.requestData = data;
    this.isEditing = true;
    this.outstandingRequests.name = data.username;
    this.outstandingRequests.employeeid = data.employeeid;
    this.outstandingRequests.startdate = data.startdate;
    this.outstandingRequests.enddate = data.enddate;
    this.outstandingRequests.date = this.allinoneService.formatDBToShow(this.allinoneService.formatDate(data.date)) ;

    this.outstandingRequests.userid = data.userid;
    this.outstandingRequests.weekday = data.weekday;

    this.outstandingRequests.refno = data.requestrefno;
    this.oldpickuppointid = data.pickuppointid;

    this.outstandingRequests.index = index;
    this.outstandingRequests.status = data.requeststatus
    this.outstandingRequests.enddate = data.enddate;
    this.outstandingRequests.requestid = data.requestid;
    this.outstandingRequests.status = data.requeststatus;
    this.outstandingRequests.statusname = data.requeststatus == '001' ? 'Submitted' : data.requeststatus == '002' ? 'Approved' : data.requeststatus == '003' ? 'Rejected' : data.requeststatus == '004' ? 'Completed' : '';
    this.outstandingRequests.remark = data.remark;
    this.outstandingRequests.refno = data.requestrefno;
    this.oldpickuppointid = data.pickuppointid;
    this.outstandingRequests.index = index;
    this.requestData = data;
    this.outstandingRequests.userfrom = data.userfrom;
    this.outstandingRequests.userto = data.userto;
    this.outstandingRequests.going = data.going;
    this.outstandingRequests.return = data.return;
    this.outstandingRequests.pickuppointname = data.pickuppointname;

    if (data.arrivetime != "") {
      // var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      // var stD = new Date(atFormDate + ' ' +  data.arrivetime);
      // var start = formatDate(stD, this.format, 'en-US').toString();

      var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      var stD = new Date(atFormDate + 'T' + data.arrivetime);

      var start = formatDate(stD, this.format, 'en-US').toString();
      this.outstandingRequests.arrivetime = start;
    } else {
      this.outstandingRequests.arrivetime = "";
    }


    if (data.returntime != "") {

      // var stD = new Date(atFormDate + 'T' + data.arrivetime);
      var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      var retD = new Date(atFormDate + 'T' + data.returntime);
      var returntime = formatDate(retD, this.format, 'en-US').toString();

      this.outstandingRequests.returntime = returntime;

    } else {
      this.outstandingRequests.returntime = "";
    }

    this.outstandingRequests.toplace = data.toplace;


    // if (data.userfromto.length > 0) {
    //   this.memberList = data.userfromto;
    // }

  }

  refreshgetData() {
    this.getoutstandingrequestList();
  }


  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
  }
  showpickup() {
    const dialog = this.dialog.open(RequestPickupComponent, {
      height: '470px',
      width: '470px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orgid: this.orgid,
        list: this.requestData,
        oldpickuppointid: this.oldpickuppointid,
        type: "001"
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.oldpickuppointid = dialogResult.data['newpickupointid'];
        this.outstandingrequestList[this.outstandingRequests.index].pickuppointid = dialogResult.data['newpickupointid'];

      }
    });
  }



  sortData(sort: Sort) {
    const data = this.outstandingrequestList;
    if (!sort.active || sort.direction === '') {
      this.outstandingrequestList = data;
    }

    this.outstandingrequestList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(
            a.username.toLocaleLowerCase(),
            b.username.toLocaleLowerCase(),
            isAsc
          );
        case 'employeeid':
          return this.allinoneService.compare(
            parseInt(a.employeeid),
            parseInt(b.employeeid),
            isAsc
          );
        case 'requestrefno':
          return this.allinoneService.compare(
            parseInt(a.requestrefno),
            parseInt(b.requestrefno),
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdate.toLocaleLowerCase(),
            b.startdate.toLocaleLowerCase(),
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(
            a.remark.toLocaleLowerCase(),
            b.remark.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }

    });

  }



  downloadSheet() {
    var ferryExport = this.filter.transform(
      this.outstandingrequestList,
      this.searchText,
      "requests"
    );
    console.log(ferryExport);

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Outstanding_Requests' + time + '.xlsx';
    ferryExport.map((car: any) => {
      data.push({
        "Employee ID": car.employeeid,
        "Name": car.username,
        "Date": this.allinoneService.formatDBToShow(this.allinoneService.formatDate(car.date)),
        "Status": car.requeststatus == '001' ? 'Submitted' :
        car.requeststatus == '002' ? 'Approved' : car.requeststatus == '003' ?
        'Rejected' : car.requeststatus == '004' ? 'Completed' : '',
        "Remark": car.remark,
      });
    });

    this.allinoneService.exportEcecl(data, name);

  }
  searchdateApi() {
    this.refreshgetData();
  }


  statusname: any = "All";
  statusChange(status: any) {
    if (status.code) {
      this.requeststatus = status.code;
      this.statusname = status.name;
      this.refreshgetData();
    } else {
      this.statusname = "All";
      this.requeststatus = "";
      this.refreshgetData();
    }
  }

  isApproving:boolean = false;
  isRejecting:boolean = false;
  submitApprove(loading: any) {
    if (loading == "002") {
      this.isApproving = true;

    }

    if (loading == "003") {
      this.isRejecting = true;

    }
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "requestid": this.outstandingRequests.requestid,
      "requeststatus": loading,
      "roleid": this.allinoneService.getSelectedRole(),
      "paymentstatus": false,
      "byadmin": true,
    };
    this.kunyekService.addApprovalList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          this.isEditing = false;
          this.refreshgetData();

        } else {

          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isApproving = false;
        this.isRejecting = false;
      },
      (err) => {
        this.isApproving = false;
        this.isRejecting = false;
      }
    );
  }

  selecttrantype(data: any){
    this.typerequest = data.type;
    this.typename = data.name;
    console.log(">>>>>>>>>>>>>>>>> type");
    console.log(data);
    this.getoutstandingrequestList();
  }
}
