<div class="d-flex align-items-center justify-content-between mb-3">
  <div>
    <h3 class="mb-0">Import</h3>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="row">
  <div class="mb-3 col-md-12">
    <div class="mb-3">
      <label for="file" class="form-label">Excel Template (<span class="cc-label"
          (click)="downloadSampleFile()">Download Sample Template</span>)</label>
      <div class="input-group">
        <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx" id="formFile"
          #fileInput [disabled]="fileSelected">
        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="fileSelected"
          (click)="removeExcel()">Remove</button>
        <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;" id="btnGroupAddon2"
          [disabled]="!fileSelected || checkingExcelFile" (click)="checkExcelFile()">
          <span *ngIf="!checkingExcelFile">
            Check
          </span>
          <span *ngIf="checkingExcelFile">
            <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
            <span class="mx-1">Checking</span>
          </span>
        </button>
      </div>
    </div>
    <div style="color : green" *ngIf="isTemplateOk">
      <i class="fa fa-check me-2"></i> Template is correct.
    </div>
    <div class="d-flex flex-wrap mt-3" *ngIf="!checkingExcelFile">
      <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0">
        <div class="d-flex align-items-center">
          <mat-icon class="me-2">
            error
          </mat-icon>
          <div>
            Error
          </div>
        </div>
        <hr>
        <ul style="padding-left: 1rem;">
          <li *ngFor="let e of errorList;">
            <span *ngIf="e.line">In line no. {{e.line}},&nbsp;</span>{{e.error}}
          </li>
        </ul>
      </div>
      <div style="color : red" *ngIf="fileSelected && errorList.length == 0 && finalMemberList.length == 0">
        <i class="fa fa-warning me-2"></i> Template is empty.
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
      Cancel
    </button>
    <button type="button" (click)="import()" class="btn btn-custom " [disabled]="!isTemplateOk">
      Import
    </button>
  </div>