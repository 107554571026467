import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alreadyLiked',
  pure: false
})
export class AlreadyLikedPipe implements PipeTransform {

  transform(reactlist : any, userid : any){
    var check = false
    for(let i = 0 ; i < reactlist.length; i++){
      if(reactlist[i].userid == userid){
        check = true
        break;
      }
    }
    return check;
  }

}
