<div class="container-fluid">
    <div class="content">
        <div class="m-4">
            <div class="me-auto">
                <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
            </div>
            <div class="d-flex gap-2 mb-2">
                <button (click)="closeForm()" type="button" class="btn btn-custom">Cancel</button>
                <app-button-loader (click)="onSaved()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="isSaving">
                </app-button-loader>
            </div>
            <div class="card">
                <div class="card-body m-2">
                    <form [formGroup]="paymentInvoiceForm" (submit)="onSaved()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label for="feetype" class="form-label">Fee Type<font color="red">*</font>
                                        </label>
                                        <select id="feetype" class="form-select" formControlName="feetype"
                                            [ngClass]="{ 'is-invalid': feetype?.invalid && saved }"
                                            (change)="onFeeTypeChange($event)">
                                            <option value="">Select Fee Type</option>
                                            <option value="Member Monthly Fee">Member Monthly Fee</option>
                                            <option value="Summer Swim Fee">Summer Swim Fee</option>
                                            <option value="Special Swim Fee">Special Swim Fee</option>
                                            <option value="Guest Swim Fee">Guest Swim Fee</option>
                                            <option value="Gym Member Fee">Gym Member Fee</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="feetype?.invalid || ((feetype?.dirty || feetype?.touched) && saved)">
                                            <div *ngIf="feetype?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="sender" class="form-label">Sender<font color="red">*</font></label>
                                        <div class="input-group">
                                            <input type="text" id="sender" [readonly]="true" class="form-control"
                                                formControlName="sender"
                                                [ngClass]="{ 'is-invalid': sender?.invalid && saved }">
                                            <div class="btn btn-custom me-2"
                                                (click)="checkAndOpenMemberModal(feetype?.value)"
                                                matTooltip="Add Sender">
                                                <mat-icon>add</mat-icon>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="sender?.invalid || ((sender?.dirty || sender?.touched) && saved)">
                                            <div *ngIf="sender?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="amount" class="form-label">Amount <font color="red">*</font></label>
                                        <input type="text" id="amount" class="form-control" formControlName="amount"
                                            [ngClass]="{ 'is-invalid': amount?.invalid && saved}">
                                        <div class="invalid-feedback"
                                            *ngIf="amount?.invalid || ((amount?.dirty || amount?.touched) && saved)">
                                            <div *ngIf="amount?.errors?.required">{{requiredMessage}}</div>
                                            <div *ngIf="amount?.errors?.pattern">Amount must be number</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="paymentdate" class="form-label">Payment Date<font color="red">*
                                            </font></label>
                                        <my-date-picker [options]="datePickerOptions" formControlName="paymentdate"
                                            [ngClass]="{ 'is-invalid': paymentdate?.invalid && saved }"
                                            placeholder="DD/MM/YYYY">
                                        </my-date-picker>
                                        <div class="invalid-feedback"
                                            *ngIf="paymentdate?.invalid || ((paymentdate?.dirty || paymentdate?.touched) && saved)">
                                            <div *ngIf="paymentdate?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="receiver" class="form-label">Receiver<font color="red">*</font>
                                        </label>
                                        <input type="text" id="receiver" class="form-control" formControlName="receiver"
                                            [ngClass]="{ 'is-invalid': receiver?.invalid && saved}">
                                        <div class="invalid-feedback"
                                            *ngIf="receiver?.invalid || ((receiver?.dirty || receiver?.touched) && saved)">
                                            <div *ngIf="receiver?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label for="paymenttype" class="form-label">Payment Type <font color="red">*
                                            </font></label>
                                        <select id="paymenttype" class="form-select" formControlName="paymenttype"
                                            [ngClass]="{ 'is-invalid': paymenttype?.invalid && saved}">
                                            <option value="" disabled>Select Payment Type</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Kpay">Kpay</option>
                                            <option value="Bank">Bank</option>
                                        </select>
                                        <div class="invalid-feedback"
                                            *ngIf="paymenttype?.invalid || ((paymenttype?.dirty || paymenttype?.touched) && saved)">
                                            <div *ngIf="paymenttype?.errors?.required">{{requiredMessage}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>