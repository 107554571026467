import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-office-user-request-filter',
  templateUrl: './office-user-request-filter.component.html',
  styleUrls: ['./office-user-request-filter.component.scss'],
})
export class OfficeUserRequestFilterComponent implements OnInit {
  requestTypeList: any[] = [];
  leaveForm:boolean = false;
  hideRequest: boolean = false;

  // currentDate = this.allinoneService.formatDBToShowInEdit(
  //   this.allinoneService.getCurrentDateToDB()
  // );
  currentDate = new Date(Date.now());

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  searchForm: any = {
    startdate: this.currentDate,
    enddate: this.currentDate,
    requeststatus: '',
    requesttype: '',
    istran : false
  };

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<OfficeUserRequestFilterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    
    this.searchForm.istran = data.istran;
    this.requestTypeList = data.requestList;
    this.searchForm.requeststatus = data.requeststatus;
    this.searchForm.requesttype = data.requesttype;
    this.hideRequest = data.hideRequest;
    if(data.startdate){
      this.searchForm.startdate = allinoneService.parseDateToShowInEdit(allinoneService.formatDate(data.startdate));
      this.searchForm.enddate = allinoneService.parseDateToShowInEdit(allinoneService.formatDate(data.enddate));
    }
  }

  ngOnInit(): void {}

  startdateChange() {
    if (
      this.searchForm.enddate < this.searchForm.startdate
    ) {
      this.searchForm.enddate = this.searchForm.startdate;
    }
  }

  search(){
    if(this.searchForm.enddate < this.searchForm.startdate ) {
      this.messageService.openSnackBar('Invalid Date.', 'warn');
      return;
    }
    this.searchForm.startdate = this.allinoneService.formatDBToShowInEdit(this.allinoneService.parseDate(this.searchForm.startdate));
    this.searchForm.enddate = this.allinoneService.formatDBToShowInEdit(this.allinoneService.parseDate(this.searchForm.enddate));
    this.dialogRef.close(this.searchForm);
  }
}
