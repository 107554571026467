import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-hierarchy-info',
  templateUrl: './hierarchy-info.component.html',
  styleUrls: ['./hierarchy-info.component.css']
})
export class HierarchyInfoComponent implements OnInit {

  user: any;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.user = data.user
  }

  ngOnInit(): void {
  }

}
