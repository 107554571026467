import { KunyekService } from './../../shared/services/kunyek.service';
import { AllInOneService } from './../../shared/services/all-in-one.service';
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from '../../shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { TasksComponent } from '../tasks/tasks.component';
import { CheckInShareComponent } from '../check-in-share/check-in-share.component';
import * as moment from 'moment-timezone';
import { CheckInTicketComponent } from '../check-in-ticket/check-in-ticket.component';
import { CheckinTicketDetailsComponent } from '../checkin-ticket-details/checkin-ticket-details.component';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent implements OnInit, OnDestroy {
  dateArray = [''];
  dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  dataList: any;
  returnInOutList: any;
  holidayList: any = [];
  holidayLoading: boolean = false;
  isNewProfile = true;
  checkinAddorNot = false;
  checkinFormSubmitted = false;
  atFormSubmitted = false;
  gettingType = false;
  searchLoading = false;
  saveLoading = false;
  typelist: any = [];
  activityView = false;
  gettingOrgList = false;
  gettingGroupList = false;
  orgList: any;
  tasksTypes: any;
  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;
  datePickerConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false,
  };

  sortedArray: any = [];
  groupList: any = [];
  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = '' + this.d.getFullYear() + this.month + this.day;
  //for filter date
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);
  checkInStartDate =
    this.aweekBeforeDate.getFullYear() +
    (this.aweekBeforeDate.getMonth() + 1).toString().padStart(2, '0') +
    this.aweekBeforeDate.getDate().toString().padStart(2, '0') +
    '';
  checkInEndDate = this.d.getFullYear() + this.month + this.day + '';
  //show today date
  showTodayDate =
    this.day +
    '/' +
    this.month +
    '/' +
    this.d.getFullYear() +
    ' ' +
    this.dayNames[this.d.getDay()];

  formatToShow = 'yyyy-MM-dd';
  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm';
  apitimeFormat = 'hh:mm:ss aa';

  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
  atFormStartTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();
  atFormEndTime = formatDate(
    new Date(this.d).setHours(new Date(this.d).getHours() + 1),
    this.timeFormat,
    'en-US'
  ).toString();

  currentOrgid: string = '';

  atForm = {
    syskey: '',
    usersk: this.allinoneService.getProfile().syskey,
    date: new Date(Date.now()),
    enddate: this.atFormDate,
    typesk: '',
    type: 'activity',
    desc: '',
    lat: '',
    long: '',
    location: '',
    starttime: '09:00:00',
    endtime: '17:30:00',
    sort: '',
    domain: '',
    locationid: '',
    orgid: '',
    orgname: '',
    taskid: '',
    taskdescription: '',
    channelid: '',
    taskautoid: '',
    activitytime: '',
    ticketno: [],
  };

  getcheckinForm = {
    syskey: '',
    usersk: this.allinoneService.getProfile().syskey,
    startdate: this.aweekBeforeDate,
    enddate: new Date(Date.now()),
    domain: '',
    domainid: '',
    userid: '',
    atoken: '',
    appid: 'kunyek',
  };

  selectedSyskey = '';

  activityTimeArray = [
    {
      id: '1',
      name: '09:00 AM - 12:00 PM',
      starttime: '09:00:00',
      endtime: '12:00:00',
    },
    {
      id: '2',
      name: '01:00 PM - 05:30 PM',
      starttime: '13:00:00',
      endtime: '17:30:00',
    },
    {
      id: '3',
      name: '09:00 AM - 05:30 PM',
      starttime: '09:00:00',
      endtime: '17:30:00',
    },
  ];

  constructor(
    private service: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {
    this.allinoneService.isCRMServiceView = false;
  }
  ngOnDestroy(): void {
    this.allinoneService.activeroute = '/adminconsole';
  }

  ngOnInit(): void {
    this.allinoneService.activeroute = '/checkin';

    this.getType();
    this.getOrgList();
    this.GetTaskType();
    this.getHoliday();
    this.getGroupList();
    // this.getCheckInList();
  }

  async getData() {
    await this.getHoliday();

  }

  t() {
    console.log(this.holidayList);
    for (var i = 0; i < this.holidayList.length; i++) {
      console.log(this.holidayList[i].startdate.toString());
    }
  }

  hide(x: any) {
    var index = x;
    this.sortedArray[index].show = this.sortedArray[index].show ? false : true;
  }

  changeActivityTime(event: any) {
    this.atForm.activitytime = event.target.value;
    if (this.atForm.activitytime == '') {
      this.atForm.starttime = '09:00:00';
      this.atForm.endtime = '17:30:00';
    } else {
      var res = this.activityTimeArray.find(
        (x: any) => x.id == this.atForm.activitytime
      );
      this.atForm.starttime = res!.starttime;
      this.atForm.endtime = res!.endtime;
    }
  }

  changeType(event: any) {
    this.atForm.typesk = event.target.value;
    var res = this.typelist.find((x: any) => x.syskey == this.atForm.typesk);
    if (res.syskey == '6106406749') {
      if (this.atForm.activitytime == '') {
        this.atForm.starttime = '09:00:00';
        this.atForm.endtime = '17:30:00';
      } else {
        var atres = this.activityTimeArray.find(
          (x: any) => x.id == this.atForm.activitytime
        );
        this.atForm.starttime = atres!.starttime;
        this.atForm.endtime = atres!.endtime;
      }
    } else {
      this.atFormStartTime = formatDate(
        Date.now(),
        this.timeFormat,
        'en-US'
      ).toString();
      this.atFormEndTime = formatDate(
        new Date(this.d).setHours(new Date(this.d).getHours() + 1),
        this.timeFormat,
        'en-US'
      ).toString();
      this.atForm.starttime = this.atFormStartTime;
      this.atForm.endtime = this.atFormEndTime;
    }
  }

  // get checkInList original function
  // getCheckInList1() {
  //   this.searchLoading = true;

  //   var domain = this.allinoneService.getDomain();

  //   var startd = formatDate(
  //     new Date(this.getcheckinForm.startdate),
  //     this.dateFormat,
  //     'en-US'
  //   ).toString();
  //   var endd = formatDate(
  //     new Date(this.getcheckinForm.enddate),
  //     this.dateFormat,
  //     'en-US'
  //   ).toString();
  //   if (
  //     new Date(this.getcheckinForm.startdate) >
  //     new Date(this.getcheckinForm.enddate)
  //   ) {
  //     this.messageService.openSnackBar(
  //       'Please select the valid end date!',
  //       'warn'
  //     );
  //     this.searchLoading = false;
  //     return;
  //   }
  //   this.sortedArray = [];
  //   this.dateArray = [];
  //   var data = {
  //     syskey: '',
  //     usersk: this.getcheckinForm.usersk,
  //     startdate: startd,
  //     enddate: endd,
  //     domain: domain.shortcode,
  //     domainid: domain.domainid,
  //     // newdesign : true,
  //   };
  //   this.service.getCheckInList(data).subscribe(
  //     (res) => {
  //       var d: any = res;
  //       if (d.returncode == '300') {
  //         this.dataList = d.list.reverse();

  //         if (this.dataList.length == 0) {
  //           var dObj = {
  //             date: this.todayDate,
  //             showdate: this.showTodayDate + ' - (Today)',
  //             data: [],
  //             show: true,
  //           };
  //           this.sortedArray.push(dObj);
  //           this.searchLoading = false;
  //         }

  //         for (var i = 0; i < this.dataList.length; i++) {
  //           var resc: any = this.dateArray.filter(
  //             (x) => x == this.dataList[i].date
  //           );

  //           if (resc.length == 0) {
  //             this.dateArray.push(this.dataList[i].date);
  //             var ddate = this.dataList[i].date;
  //             var y = ddate.slice(0, 4);
  //             var m = ddate.slice(4, 6);
  //             var day = ddate.slice(6, 9);
  //             var sd = new Date(y + '-' + m + '-' + day);
  //             var cdata: any = this.dataList.filter(
  //               (a: any) => a.date == this.dataList[i].date
  //             );
  //             var obj = {
  //               date: this.dataList[i].date,
  //               showdate:
  //                 day + '/' + m + '/' + y + ' - ' + this.dayNames[sd.getDay()],
  //               data: cdata,
  //               show: true,
  //               isoffday: sd.getDay() == 0 || sd.getDay() == 6,
  //             };
  //             this.sortedArray.push(obj);
  //             this.sortedArray.sort(
  //               // (a:any,b:any)=>parseInt(a.date)<parseInt(b.date)
  //               (a: any, b: any) => {
  //                 {
  //                   let value = parseInt(b.date) - parseInt(a.date);
  //                   return value;
  //                 }
  //               }
  //             );
  //           }
  //           this.searchLoading = false;
  //         }
  //         // alert(this.dataList+"");
  //       } else {
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //         this.searchLoading = false;
  //         this.atFormSubmitted = false;
  //       }
  //     },
  //     (err) => {
  //       this.searchLoading = false;
  //       this.atFormSubmitted = false;
  //     }
  //   );
  // }

  getHoliday() {
    this.holidayLoading = true;
    this.searchLoading = true;
    var domain = this.allinoneService.getDomain();
    var data = {
      domainid: domain.domainid,
      orgid: this.atForm.orgid,
      roleid: this.allinoneService.getSelectedRole()
    };
    this.service.getHolidayList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.holidayList = res.holidaylist;
          this.holidayLoading = false;
          this.searchLoading = false;

        } else {
          this.holidayLoading = false;
          this.searchLoading = false;
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.getCheckInList();
      },
      (err) => {
        this.getCheckInList();
        this.holidayLoading = false;
        this.searchLoading = false;
      }

    );
  }


  getCheckInList() {
    this.searchLoading = true;

    var domain = this.allinoneService.getDomain();

    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.dateFormat,
      'en-US'
    ).toString();
    if (
      new Date(this.getcheckinForm.startdate) >
      new Date(this.getcheckinForm.enddate)
    ) {
      this.messageService.openSnackBar(
        'Please select the valid end date!',
        'warn'
      );
      this.searchLoading = false;
      return;
    }
    this.sortedArray = [];
    this.dateArray = [];
    var data = {
      syskey: '',
      usersk: this.getcheckinForm.usersk,
      startdate: this.allinoneService.parseDate(this.getcheckinForm.startdate),
      enddate: this.allinoneService.parseDate(this.getcheckinForm.enddate),
      // domain: domain.shortcode,
      // domainid: domain.domainid,
      newdesign: true,
    };
    this.service.getCheckInList(data).subscribe(
      (res) => {
        var d: any = res;
        if (d.returncode == '300') {
          this.dataList = d.list.reverse();
          this.returnInOutList = d.returninoutlist.reverse();

          if (this.returnInOutList.length == 0) {
            var dObj = {
              date: this.todayDate,
              showdate: this.showTodayDate + ' - (Today)',
              data: [],
              show: true,
            };
            this.sortedArray.push(dObj);
            this.searchLoading = false;
          }

          console.log('checkin holiday list');
          console.log(this.holidayList);


          for (var i = 0; i < this.returnInOutList.length; i++) {
            var resc: any = this.dataList.filter(
              (x: any) => x.date == this.returnInOutList[i].name
            )


            // var holiday: any = this.holidayList.filter(
            //   (x: any) => {
            //     // console.log('your type');
            //     // console.log(typeof (x.startdate))
            //     // console.log(typeof (this.returnInOutList[i].name))
            //     parseInt(this.returnInOutList[i].name) === parseInt(x.startdate) || parseInt(this.returnInOutList[i].name) === parseInt(x.enddate) || (parseInt(this.returnInOutList[i].name) > parseInt(x.startdate) && parseInt(this.returnInOutList[i].name) < parseInt(x.enddate))
            //   }
            // )

            var holiday: any = this.holidayList.filter(
              (x: any) =>
                this.returnInOutList[i].name == x.startdate || this.returnInOutList[i].name == x.enddate || (this.returnInOutList[i].name > x.startdate && this.returnInOutList[i].name < x.enddate)
            )

            console.log('error');

            console.log(holiday);


            // if(resc.length != 0) {
            var ddate = this.returnInOutList[i].name;
            var y = ddate.slice(0, 4);
            var m = ddate.slice(4, 6);
            var day = ddate.slice(6, 9);
            var sd = new Date(y + '-' + m + '-' + day);
            var obj = {
              date: this.returnInOutList[i].name,
              showdate: day + '/' + m + '/' + y + ' - ' + this.dayNames[sd.getDay()],
              data: resc,
              show: resc.length != 0,
              isoffday: sd.getDay() == 0 || sd.getDay() == 6 || holiday.length != 0,
              holiday: holiday.length != 0 ? holiday[0].name : ''
            };
            this.sortedArray.push(obj);
            this.sortedArray.sort(
              (a: any, b: any) => {
                {
                  let value = parseInt(b.date) - parseInt(a.date);
                  return value;
                }
              }
            )
            // }
          }
          console.log(this.sortedArray);
          this.searchLoading = false;
          // alert(this.dataList+"");
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.searchLoading = false;
          this.atFormSubmitted = false;
        }
      },
      (err) => {
        this.searchLoading = false;
        this.atFormSubmitted = false;
      }
    );
  }

  getType() {
    this.gettingType = true;
    var startd = formatDate(
      new Date(this.getcheckinForm.startdate),
      this.dateFormat,
      'en-US'
    ).toString();
    var endd = formatDate(
      new Date(this.getcheckinForm.enddate),
      this.dateFormat,
      'en-US'
    ).toString();
    var data = {
      domain: this.allinoneService.getDomain().domainid,
      syskey: '',
      recordstatus: '3',
    };
    this.service.getType(data).subscribe(
      (res) => {
        var d: any = res;

        if (d.returncode == '300') {
          this.typelist = d.list.filter((x: any) => x.type == '3');
          this.selectedSyskey = this.typelist.filter(
            (x: any) => x.syskey == '6106406749'
          )[0].syskey;
          this.atForm.typesk = this.selectedSyskey;
          this.gettingType = false;

          // alert(this.dataList+"");
        } else {
          this.gettingType = false;

          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.atFormSubmitted = false;
        }
      },
      (err) => {
        this.gettingType = false;
      }
    );
  }
  add() {
    this.checkinAddorNot = this.checkinAddorNot ? false : true;
    this.getType();
  }
  search() {
    // const a = this.allinoneService.parseDateToShowInEdit("20230224");
    // console.log(a);
    this.searchLoading = true;
    this.getCheckInList();
  }

  cancel() {
    this.checkinAddorNot = false;
    this.activityView = false;
    this.atForm = {
      syskey: '',
      usersk: this.allinoneService.getProfile().syskey,
      // date: this.atFormDate,
      // ***
      date: new Date(Date.now()),
      enddate: this.atFormDate,
      typesk: '',
      type: 'activity',
      desc: '',
      lat: '',
      long: '',
      location: '',
      starttime: '09:00:00',
      endtime: '17:30:00',
      sort: '',
      domain: '',
      locationid: '',
      orgid: this.atForm.orgid,
      orgname: this.atForm.orgname,
      taskid: '',
      taskdescription: '',
      channelid: '',
      taskautoid: '',
      activitytime: '',
      ticketno: []
    };
  }
  submitActivity() {
    var tempDesc = this.atForm.desc.toString().trim();
    if (tempDesc === '' || tempDesc === '-' || /^\s+$/.test(tempDesc) || tempDesc.length < 2) {
      // Invalid input
      this.messageService.openSnackBar('Please enter a proper description.', 'warn');
      return;
    }
    this.atFormSubmitted = true;
    this.saveLoading = true;

    // console.log('1');
    // console.log(this.atForm.date);
    // console.log(this.allinoneService.parseDate(this.atForm.date));
    // console.log(this.allinoneService.formatDBToShowInEdit(this.allinoneService.parseDate(this.atForm.date)));

    var dateForT = this.allinoneService.formatDBToShowInEdit(this.allinoneService.parseDate(this.atForm.date))

    var stD = new Date(dateForT + 'T' + this.atForm.starttime);
    var etD = new Date(dateForT + 'T' + this.atForm.endtime);
    var startTime = formatDate(stD, this.apitimeFormat, 'en-US').toString();
    var endTime = formatDate(etD, this.apitimeFormat, 'en-US').toString();
    if (stD > etD) {
      this.saveLoading = false;
      this.messageService.openSnackBar(
        'Please select the valid end date!',
        'warn'
      );
      return;
    }
    // var date = formatDate(
    //   new Date(this.atForm.date),
    //   this.dateFormat,
    //   'en-US'
    // ).toString();
    var date = this.allinoneService.parseDate(this.atForm.date);


    // console.log('Your data');
    // console.log(this.atForm.date);
    // console.log(stD);
    // console.log(etD);
    // console.log(startTime);
    // console.log(endTime);
    // console.log(date);


    //   return

    var data = {
      syskey: this.atForm.syskey,
      usersk: this.atForm.usersk,
      date: date,
      // ***
      // enddate: this.allinoneService.formatDate(this.atForm.date),
      enddate: this.allinoneService.parseDate(this.atForm.date),
      typesk: this.atForm.typesk,
      type: 'activity',
      desc: this.atForm.desc.toString().trim(),
      lat: '',
      long: '',
      location: '',
      starttime: startTime,
      endtime: endTime,
      sort: '',
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      locationid: '',
      orgid: this.atForm.orgid,
      taskid: this.atForm.taskid,
      channelid: this.atForm.channelid,
      uikey: '',
      locationtype: '',
      parentid: '',
      assignmentid: '',
      assignmentstatus: '',
      qrfrom: '',
      backdate: false,
      timezone: moment.tz.guess(),
      ticketno: this.atForm.ticketno,
      detail: {
        subtypename: this.typelist.find((x: any) => x.syskey == this.atForm.typesk).name,
        taskno: this.atForm.taskid,
        taskdesc: this.atForm.taskdescription,
        orgname: this.atForm.orgname,
        eventorgid: '',
        eventorgname: '',
        eventname: '',
        eventdesc: '',
        eventstartdate: '',
        eventenddate: '',
        eventchannelname: '',
        eventlat: '',
        eventlong: '',
        eventlocation: '',
        eventlocationid: '',
      },
    };
    if (this.activityView) {
      this.service.updateCheckIn(data).subscribe(
        (res) => {
          var d: any = res;
          if (d.returncode == '300') {
            this.saveLoading = false;
            this.checkinAddorNot = false;
            this.searchLoading = true;
            this.getCheckInList();
            this.atFormSubmitted = false;
            this.cancel();
            // alert(this.dataList+"");
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            this.atFormSubmitted = false;
            this.saveLoading = false;
          }
        },
        (err) => {
          this.atFormSubmitted = false;
          this.saveLoading = false;
        }
      );
    } else {
      this.service.addCheckIn(data).subscribe(
        (res) => {
          var d: any = res;
          if (d.returncode == '300') {
            this.saveLoading = false;
            this.checkinAddorNot = false;
            this.searchLoading = true;
            this.getCheckInList();
            this.cancel();
            this.atFormSubmitted = false;
            // alert(this.dataList+"");
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            this.atFormSubmitted = false;
            this.saveLoading = false;
          }
        },
        (er) => {
          this.atFormSubmitted = false;
          this.saveLoading = false;
        }
      );
    }
  }

  showActivity(data: any, type: any) {
    console.log('s a fun');

    if (type != 'activity') {
      return;
    }
    this.atFormSubmitted = false;
    console.log(data);
    console.log(this.allinoneService.parseDateToShowInEdit(data.date));


    var domain = this.allinoneService.getDomain();
    this.activityView = true;
    this.checkinAddorNot = true;
    var ddate = data.date;
    var y = ddate.slice(0, 4);
    var m = ddate.slice(4, 6);
    var day = ddate.slice(6, 9);
    var st = this.changeFormat(data.starttime);
    var et = this.changeFormat(data.endtime);
    var sd = new Date(y + '-' + m + '-' + day + 'T' + st);
    var ed = new Date(y + '-' + m + '-' + day + 'T' + et);

    // var startTime = formatDate(sd, this.apitimeFormat, 'en-US').toString()
    var startTime =
      sd.getHours().toString().padStart(2, '0') +
      ':' +
      sd.getMinutes().toString().padStart(2, '0') +
      ':' +
      sd.getSeconds().toString().padStart(2, '0');
    var endTime =
      ed.getHours().toString().padStart(2, '0') +
      ':' +
      ed.getMinutes().toString().padStart(2, '0') +
      ':' +
      ed.getSeconds().toString().padStart(2, '0');
    // var endTime = formatDate(ed, this.apitimeFormat, 'en-US').toString()
    // var date = formatDate(sd, this.formatToShow, 'en-US').toString();
    var date = this.allinoneService.parseDateToShowInEdit(data.date);
    console.log(startTime);
    console.log(endTime);

    this.atForm = {
      syskey: data.syskey,
      usersk: data.usersk,
      // ***
      // date: date,
      date: sd,
      enddate: this.allinoneService.formatDBToShowInEdit(data.enddate),
      typesk: data.typesk,
      type: 'activity',
      desc: data.description,
      lat: '',
      long: '',
      location: '',
      starttime: startTime,
      endtime: endTime,
      sort: data.sort,
      domain: domain.shortcode,
      locationid: '',
      orgid: data.orgid,
      orgname: this.orgList.find((x: any) => x.orgid == data.orgid)?.name,
      taskid: data.taskid,
      taskdescription: data.taskdescription,
      channelid: data.channelid,
      taskautoid: data.autoid,
      activitytime: '',
      ticketno: data.ticketno
    };
  }

  changeFormat(timeStr: any) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes, second] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}:${second}`;
  }
  deleteCheckin(id: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        var data = {
          syskey: id,
          domain: this.allinoneService.getDomain().shortcode,
          domainid: this.allinoneService.getDomain().domainid,
        };
        this.allinoneService.isLoading = true;
        this.service.deleteCheckin(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.allinoneService.isLoading = false;
              this.messageService.openSnackBar(
                'Deleted Successfully.',
                'success'
              );
              this.getCheckInList();
            } else {
              this.allinoneService.isLoading = false;
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
          },
          (err) => {
            this.allinoneService.isLoading = false;
          }
        );
      }
    });
  }

  calEndTime(date: any, starttime: any, childdate: any, childstarttime: any) {
    var format = 'yyyy/MM/dd hh:mm:ss a';
    var datetime = date;
    var yy = datetime.slice(0, 4);
    var mm = datetime.slice(4, 6);
    var dd = datetime.slice(6, 8);
    var checkinD = yy + '/' + mm + '/' + dd + ' ' + this.allinoneService.formatTimeToShow(starttime);

    var cdatetime = childdate;
    var cyy = cdatetime.slice(0, 4);
    var cmm = cdatetime.slice(4, 6);
    var cdd = cdatetime.slice(6, 8);
    var childD = cyy + '/' + cmm + '/' + cdd + ' ' + this.allinoneService.formatTimeToShow(childstarttime);

    let date1 = new Date(checkinD);
    let date2 = new Date(childD);
    var diffMin = this.diff_minutes(date1, date2);
    return this.transformMinute(diffMin);
  }

  calDuration(datetime: any) {
    var durationArray = datetime.split('-');
    var day = parseInt(durationArray[0]);
    var hour = parseInt(durationArray[1]);
    var min = parseInt(durationArray[2]);

    if (day == 0) {
      if (hour == 0) {
        if (min == 0) {
          return '1 minute ';
        }
        return min + ' minutes ';
      }
      if (hour == 1) {
        if (min == 1) {
          return durationArray[1] + ' hour ' + durationArray[2] + ' minute ';
        }
        return durationArray[1] + ' hour ' + durationArray[2] + ' minutes ';
      }
      return durationArray[1] + ' hours ' + durationArray[2] + ' minutes ';
    } else {
      if (hour == 0) {
        if (day == 1) {
          if (min == 1) {
            return durationArray[0] + ' day ' + durationArray[2] + ' minute ';
          }
          return durationArray[0] + ' day ' + durationArray[2] + ' minutes ';
        }
        return durationArray[0] + ' days ' + durationArray[2] + ' minutes ';
      } else if (hour == 1) {
        if (day == 1) {
          if (min == 1) {
            return (
              durationArray[0] +
              ' day ' +
              durationArray[1] +
              ' hour ' +
              durationArray[2] +
              ' min'
            );
          }
          return (
            durationArray[0] +
            ' day ' +
            durationArray[1] +
            ' hour ' +
            durationArray[2] +
            ' minutes '
          );
        }
        return (
          durationArray[0] +
          ' days ' +
          durationArray[1] +
          ' hour ' +
          durationArray[2] +
          ' minutes '
        );
      }

      return (
        durationArray[0] +
        ' day ' +
        durationArray[1] +
        ' hour ' +
        durationArray[2] +
        ' minutes '
      );
    }
  }

  diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  transformMinute(value: number): string {
    var hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    var day = 0;
    if (hours == 0) {
      return minutes + ' minutes';
    } else if (hours == 1) {
      return hours + ' hour ' + minutes + ' minutes ';
    } else if (hours == 24) {
      day = Math.floor(hours / 24);
      hours = Math.floor(hours % 24);
      if (hours == 0) {
        return day + ' days ' + minutes + ' minutes ';
      }
      return day + ' day ' + hours + ' hour ' + minutes + ' minutes ';
    } else if (hours > 24) {
      day = Math.floor(hours / 24);
      hours = Math.floor(hours % 24);
      if (hours == 0) {
        return day + ' days ' + minutes + ' minutes ';
      }
      return day + ' days ' + hours + ' hour ' + minutes + ' minutes ';
    } else {
      return hours + ' hours ' + minutes + ' minutes ';
    }
  }

  getOrgList() {
    var temp = this.allinoneService.orgs;
    this.currentOrgid = this.allinoneService.selectedOrg ? this.allinoneService.selectedOrg : temp.length > 0 ? temp[0].orgid : '';
    this.orgList = this.allinoneService.orgsCRm;
    this.atForm.orgid = this.allinoneService.selectedOrgCRM;
    var temp = this.orgList.find((x: any) => x.orgid == this.atForm.orgid);
    if (temp) {
      this.atForm.orgname = temp.name;
    }
    else {
      if (this.orgList.length > 0) {
        this.atForm.orgid = this.orgList[0].orgid;
        this.atForm.orgname = this.orgList[0].orgname;
      }
      else {
        this.atForm.orgid = '';
        this.atForm.orgname = '';
      }
    }

    // this.gettingOrgList = true;
    // const data = {
    //   domainid: this.allinoneService.getDomain().domainid,
    // };
    // this.service.getOrgs(data).subscribe(
    //   (res: any) => {
    //     if (res.returncode == '300') {
    //       this.orgList = res.list;
    //       this.gettingOrgList = false;
    //     } else {
    //       this.gettingOrgList = false;
    //       this.messageService.openSnackBar(
    //         res.message || res.status || 'Server Error',
    //         'warn'
    //       );
    //     }
    //   },
    //   (err) => {
    //     this.gettingOrgList = false;
    //   }
    // );
  }

  changeOrg(event: any) {
    this.atForm.orgid = event.target.value;
    this.atForm.orgname = this.orgList.find((x: any) => x.orgid == this.atForm.orgid)?.name;
    this.atForm.ticketno = [];
  }
  GetTaskType() {
    this.tasksTypes = [];
    var data = {
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.service.getTaskType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.tasksTypes = res.list;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => { }
    );
  }
  removeTask() {
    this.atForm.taskid = '';
    this.atForm.taskdescription = '';
    this.atForm.taskautoid = '';
  }
  selectTask() {
    const dialog = this.dialog.open(TasksComponent, {
      height: '450px',
      width: '450px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        taskid: this.atForm.taskid,
        list: this.tasksTypes,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        var data = dialogResult;
        this.atForm.taskid = data.taskid;
        this.atForm.taskautoid = data.autoid;

        this.atForm.taskdescription = data.taskdescription;
      }
    });
  }

  getGroupList() {
    this.gettingGroupList = true;
    const data = {
      contact: '',
      count: '10',
      channelid: '',
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      checkingroup: 'true',
    };
    this.service.getChatList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.groupList = res.list.reverse()
          this.groupList = res.list;
          this.gettingGroupList = false;
        } else {
          this.gettingGroupList = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.gettingGroupList = false;
      }
    );
  }

  changeGroup(event: any) {
    this.atForm.channelid = event.target.value;
  }

  share(data: any) {
    const dialog = this.dialog.open(CheckInShareComponent, {
      width: '500px',
      height: 'auto',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        groups: this.groupList,
        syskey: data.syskey,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }

  chooseTickets() {
    const dialog = this.dialog.open(CheckInTicketComponent, {
      width: '700px',
      height: '600px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        "ticketlist": this.atForm.ticketno,
        "orgid": this.atForm.orgid
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
        this.atForm.ticketno = dialogResult.data.ticketnolist
      }
    });
  }

  openTicketDetails(ticket: any) {
    const dialog = this.dialog.open(CheckinTicketDetailsComponent, {
      width: '700px',
      height: '600px',
      maxHeight: '95vw',
      maxWidth: '95vw',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        orgid: this.atForm.orgid,
        ticketno: ticket,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }
}
