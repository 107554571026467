import { data } from 'jquery';
import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { CrmCustomerDealComponent } from '../crm-customer-deal/crm-customer-deal.component';
import { CrmServiceFilterbydateComponent } from '../crm-service-filterbydate/crm-service-filterbydate.component';
import { PipelineTotalamountComponent } from '../pipeline-totalamount/pipeline-totalamount.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss']
})
export class LeadComponent implements OnInit {
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {

      this.isMobileView = true;

      this.isTabletView = false;

    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {

      this.isMobileView = false;

      this.isTabletView = true;

    } else {

      this.isMobileView = false;

      this.isTabletView = false;

    }

  }
  isTabletView: boolean = false;
  isMobileView: boolean = false;
  isView: boolean = false;
  gettingData: boolean = true;
  gettingServicData: boolean = false;
  leadAdd: boolean = false;
  customerAdd: boolean = false;
  dealAdd: boolean = false;
  serviceAdd: boolean = false;
  summaryCheck: boolean = false;
  historyshow: boolean = false;
  isFocus: boolean = false;
  gettingStages: boolean = false;

  dataForEdit: any = ''
  mobileViewWidth: number = 426;
  viewWidth: number = 992;
  itemPerPage: number = 100;
  p: number = 1;
  callType: any = '';
  servcietypelist: any = [];
  statusLists: any = [];
  searchStatusLead: any = '';
  searchStatusCustomer: any = '';
  searchstagedeal: any = '';
  searchStatusdeal: any = '';
  priority: any = [];
  filterstartdate: any = '';
  filterenddate: any = '';
  organizationsList: any = [];
  gettingOrgList: boolean = false;
  orgtype: any = '';

  totalopencount: any = '0';
  totalclosedcount: any = '0';

  statusListsCustomer = [
    { "code": "0010", "name": "New" },
    { "code": "0020", "name": "WIP" },
    { "code": "0040", "name": "Completed" },
    { "code": "00900", "name": "Closed" }
  ]





  // (change)="selectProject($event)"

  ChangeCustomerStatus(event: any) {
    this.searchStatusCustomer = event.target.value;
    console.log(this.searchStatusCustomer)

  }
  ChangeDealStatus(event: any) {
    this.searchStatusdeal = event.target.value;
    console.log(this.searchStatusdeal)

  }

  ChangeDealStage(event: any) {
    this.searchstagedeal = event.target.value;
    console.log(this.searchstagedeal)
  }


  // servicetypes = [
  //   { "type": "System Error", "code": "001" },
  //   { "type": "User Error", "code": "002" },
  //   { "type": "Requirement - New Req.", "code": "003" },
  //   { "type": "Requirement - Change Req.", "code": "004" },
  //   { "type": "Mixed Issue (Gray)", "code": "005" },
  //   { "type": "Data Change/Script", "code": "006" },
  //   { "type": "Service Request", "code": "007" },
  //   { "type": "Hardware Related", "code": "008" },
  //   { "type": "Networking Related", "code": "009" },
  //   { "type": "Performance Issues", "code": "0010" },
  //   { "type": "Enquiry", "code": "0011" },
  //   { "type": "Implementation", "code": "0012" },
  //   { "type": "Training Request", "code": "0013" },
  //   { "type": "License Request", "code": "0014" },
  //   { "type": "New Installation", "code": "0015" },
  //   { "type": "Others", "code": "0016" },
  // ];

  // servicesdata: any = [
  //   // { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "Closed",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "Closed",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  // ];
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  types: any[] = [
    {
      id: '001',
      name: 'Leads',
    },
    {
      id: '002',
      name: 'Customers',
    },
    {
      id: '003',
      name: 'Deals',
    },
    {
      id: '004',
      name: 'Pipelines',
    },
    {
      id: '005',
      name: 'Services',
    },
  ];

  //Lead Status
  leadStatus = [
    {
      key: '001',
      value: 'Closed',
    },
    {
      key: '002',
      value: 'Open',
    },
  ];


  //Customer Status
  customerStatus = [
    {
      key: '001',
      value: 'Prospect',
    },
    {
      key: '002',
      value: 'Customer',
    },
  ];

  //Deal Status
  dealStatus = [
    {
      key: '001',
      value: 'Open',
    },
    {
      key: '002',
      value: 'Closed - Won',
    },
    {
      key: '003',
      value: 'Closed - Lost',
    },
  ];

  //Business Type
  businessType = [
    {
      key: '001',
      value: 'Organization',
    },
    {
      key: '002',
      value: 'Person',
    },
  ];

  //Currency Type
  currencyType = [
    {
      key: '001',
      currency: 'Kyat',
      code: 'MMK',
      country: 'Myanmar',
    },
    {
      key: '025',
      currency: 'dollar',
      code: 'USD',
      country: 'United States',
    },
    {
      key: '002',
      currency: 'Singapore dollar',
      code: 'SGD',
      country: 'Singapore',
    },
    {
      key: '003',
      currency: 'đồngr',
      code: 'VND',
      country: 'Vietnam',
    },
    {
      key: '004',
      currency: 'UAE dirham',
      code: 'AED',
      country: 'United Arab Emirates',
    },
    {
      key: '005',
      currency: 'Turkish lira',
      code: 'TRY',
      country: 'Turkey',
    },
    {
      key: '006',
      currency: 'Baht',
      code: 'THB',
      country: 'Thailand',
    },
    {
      key: '007',
      currency: 'Rupiah',
      code: 'IDR',
      country: 'Indonesia',
    },
    {
      key: '008',
      currency: 'New Taiwan dollar',
      code: 'TWD',
      country: 'Taiwan',
    },
    {
      key: '009',
      currency: 'South Korean won',
      code: 'KRW',
      country: 'South Korea',
    },
    {
      key: '010',
      currency: 'Yen',
      code: 'JPY',
      country: 'Japan',
    },
    {
      key: '011',
      currency: 'Russian Ruble',
      code: 'RUB',
      country: 'Russia',
    },
    {
      key: '012',
      currency: 'Philippine peso',
      code: 'PHP',
      country: 'Philippines',
    },
    {
      key: '013',
      currency: 'Pakistani Rupee',
      code: 'PKR',
      country: 'Pakistan',
    },
    {
      key: '014',
      currency: 'Nepalese rupee',
      code: 'NPR',
      country: 'Nepal',
    },
    {
      key: '015',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '016',
      currency: 'Jordanian dinar',
      code: 'JOD',
      country: 'Jordan',
    },
    {
      key: '017',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '018',
      currency: 'Indian Rupee',
      code: 'INR',
      country: 'India',
    },
    {
      key: '019',
      currency: 'Hong Kong dollar',
      code: 'HKD',
      country: 'Hong Kong',
    },
    {
      key: '020',
      currency: 'Renminbi (yuan)',
      code: 'CNY',
      country: 'China',
    },
    {
      key: '021',
      currency: 'Cambodian riel',
      code: 'KHR',
      country: 'Cambodia',
    },
    {
      key: '022',
      currency: 'Brunei dollar',
      code: 'BND',
      country: 'Brunei',
    },
    {
      key: '023',
      currency: 'Bhutanese ngultrum',
      code: 'BTN',
      country: 'Bhutan',
    },
    {
      key: '024',
      currency: 'Taka',
      code: 'BDT',
      country: 'Bangladesh',
    },
  ];

  stages: any[] = [];

  formatToShow = 'yyyy-MM-dd';
  now = Date.now();
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() - 14);
  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.oneWeekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  crmForm = {
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    type: '',
    stageid: '',
    status: '',
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
  };

  organizations: any[] = [];
  data: any[] = [];
  @Input() width!: string;

  searchText: any = '';
  searchTextService: any = '';
  tabletViewWidth: any = 992;
  statusFilter: any = '';
  serviceFilter: any = '';

  routeType: any = '';
  allrouteType: any = '';
  @Input() t_breadcrumb!: string;
  projectList: any = '';
  gettingProjectList: boolean = false;
  projectid: any = '';
  startdatetime: string = '';
  enddatetime: string = '';
  searchLoading: boolean = false;
  nextDisabled: boolean = false;
  lastPage: any = '';
  currentPage: any = 1;
  total: any = '0';
  sortby: any = '';
  getServiceType: boolean = true;
  getSatatusType: boolean = true;
  statuscode: string = '0010';
  servicecode: string = '';
  viewopenservice: boolean = false;
  servicetype: any = '';
  goCustomer: any = '';
  goCusBol: boolean = false;
  goDealBol: boolean = false;
  gopipBol: boolean = false;
  checkOrgB: boolean = false;
  orgname: any = '';
  routevalueQ: any = '';
  routevalueP: any = '';
  routevalueAll: any = '';
  pagnation: any = [];
  Days: any;
  endDays: any = ''
  productadmin: boolean = false
  filtergetdata: any = [];
  isAdmin: boolean = false;
  customername: any = '';
  leadview: boolean = false;
  permission: any = [];

  leadcreate: boolean = false;
  leaddelete: boolean = false;
  leadedit: boolean = false;


  private subs = new SubSink();
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  // pagesize:any = 0;
  private dialogRef: any;
  private dialogData: any;
  checkModeal: boolean = false;
  isExcel: boolean = false;
  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private injector: Injector,

  ) {


    this.isAdmin = (this.allinoneService.crmOrgAdmin === 'true');

  }
  awaitDomain: any = ''
  // ngOnDestroy(): void {
  //   this.subs.unsubscribe();
  //   console.log(this.data);

  //   console.log(`SubSinkComponent  destroyed`);
  //   this.allinoneService.activeroute = "/sales";


  // }

  orgList: any = [];
  customercare: boolean = false;
  customernamecare: any = "";
  getCustomercaredata: any = [];
  customeridcare: any = "";
  contactid: any = '';
  contactadmin: boolean = false;
  async ngOnInit(): Promise<void> {
    this.allinoneService.activeroute = "/sales";


    if (window.innerWidth < this.mobileViewWidth) {

      this.isMobileView = true;

      this.isTabletView = false;

    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {

      this.isMobileView = false;

      this.isTabletView = true;

    }
    this.permission = this.allinoneService.getCRMpermission();
    if (this.allinoneService.crmBlocked == 'true') {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      if (this.isAdmin == false) {

        var type = this.permission.map((x: any) => x).indexOf('005');
        if (type == -1) {
          this.router.navigateByUrl('sales')
        }
        var view = this.permission.map((x: any) => x).indexOf('0051');
        var create = this.permission.map((x: any) => x).indexOf('0052');
        var edit = this.permission.map((x: any) => x).indexOf('0053');
        var delet = this.permission.map((x: any) => x).indexOf('0054');
        console.log("view" + view);
        console.log(this.permission)
        if (view == -1) {
          this.leadview = true;
        }
        if (create == -1) {
          this.leadcreate = true;
        }

        if (edit == -1) {
          this.leadedit = true;
        }

        if (delet == -1) {
          this.leaddelete = true;
        }

      }
    }


    this.organizations = this.allinoneService.orgsCRm;
    this.crmForm.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
    if (this.leadview == true) {
      this.gettingData = false;
    } else {
      this.getData();
      this.getStages();
    }

    this.startdatetime = formatDate(
      new Date(),
      this.formatToShow,
      'en-US'
    ).toString();
    var futureDate = new Date();

    futureDate.setDate(futureDate.getDate() + 7);
    this.enddatetime = formatDate(
      futureDate,
      this.formatToShow,
      'en-US'
    ).toString();
    console.log(this.enddatetime)
    if (this.width == null) {
      this.width = "auto";
    }




  }






  sortData(sort: Sort) {
    console.log(this.data);
    const data = this.data;
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        //Lead
        case 'leadname':
          return this.allinoneService.compare(
            a.name.toLocaleLowerCase(),
            b.name.toLocaleLowerCase(),
            isAsc
          );
        case 'company':
          return this.allinoneService.compare(a.organization, b.organization, isAsc);
        case 'leadphone':
          return this.allinoneService.compare(a.mobile, b.mobile, isAsc);
        case 'email':
          return this.allinoneService.compare(a.email, b.email, isAsc);
        case 'leadstatus':
          return this.allinoneService.compare(a.statusname, b.statusname, isAsc);

        case 'leadcreatedby':
          return this.allinoneService.compare(
            a.createbyname.toLocaleLowerCase(),
            b.createbyname.toLocaleLowerCase(),
            isAsc
          );

        default:
          return 0;
      }
    });
    console.log(this.data);

  }


  orgChanged(event: any) {
    this.crmForm.orgid = event.target.value;
    if (this.crmForm.orgid == '') {
      this.data = [];
    } else {
      if (this.callType == '300') {
        this.allinoneService.selectedOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedOrg(this.crmForm.orgid);
      } else {
        this.allinoneService.selectedConOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedConOrg(this.crmForm.orgid);
      }
      this.getData();
    }
  }

  typeChanged(event: any) {
    this.crmForm.type = event.target.value;
    if (this.crmForm.orgid) {
      this.getData();
    }
  }

  getStages() {
    this.gettingStages = true;
    const data = {
      orgid: '',
      domainid: this.crmForm.domainid,
    };
    this.kunyekService.getPipelineStage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.stages = res.list;
          // this.crmForm.stageid = this.stages[0].stageid;
          this.gettingStages = false;
        } else {
          this.gettingStages = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStages = false;
      }
    );
  }

  leadsourceList: any = [];
  industryList: any = [];
  getData() {
    this.gettingData = true;
    this.data = [];
    // this.p = 1;
    this.total = '0';
    //Lead
    const data = {
      domainid: this.crmForm.domainid,
      orgid: this.crmForm.orgid,
      pagesize: this.itemPerPage,
      sortby: this.sortby,

    };
    this.subs.sink = this.kunyekService.getCrmLeads(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          // this.data = res.list;
          // this.pg[3].count = this.data.length;
          // this.itemPerPage = 100;
          this.industryList = [];
          this.leadsourceList = [];
          // test
          res.list.map((item: any) => {
            // if(this.industryList.length > 0){
            var index = this.industryList.map((x: any) => x.name).indexOf(item.industrytype);
            var index1 = this.leadsourceList.map((x: any) => x.name).indexOf(item.leadsource);
            // }

            if (item.industrytype != '' && index == -1) {
              this.industryList.push({ "name": item.industrytype })

            }
            if (item.leadsource != '' && index1 == -1) {
              this.leadsourceList.push({ "name": item.leadsource })
            }


            var statusIndex = this.leadStatus.map((x) => x.key).indexOf(item.status);
            var typeIndex = this.businessType.map((x) => x.key).indexOf(item.leadtype);
            this.data.push({
              'address1': item.address1,
              'address2': item.address2,
              'amount': item.amount,
              'createdby': item.createdby,
              'createbyname': item.createdbyname,
              'currency': item.currency,
              'date': item.date,
              'email': item.email,
              'filelist': item.filelist,
              'filename': item.filename,
              'fileurl': item.fileurl,
              'industrytype': item.industrytype,
              'leadid': item.leadid,
              'leadsource': item.leadsource,
              'leadtype': item.leadtype,
              'mobile': item.mobile,
              'name': item.name,
              'note': item.note,
              'organization': item.organization,
              'orgid': item.orgid,
              'post': item.post,
              'product': item.product,
              'sort': item.sort,
              'status': item.status,
              'statusname': statusIndex > -1 ? this.leadStatus[statusIndex].value : '',
              'typename': typeIndex > -1 ? this.businessType[typeIndex].value : '',

            })
          });
          console.log(this.industryList);
          console.log(">>>>>>>>>>>>>>>>>>");
          // console.log(this.data);
          var sort = this.data.length - 1;
          // console.log(typeof (this.data.length));
          // console.log(this.data.length);
          // console.log(typeof (this.total));
          // console.log(this.total);
          this.total = res.total;


          if (parseInt(this.total) == this.data.length) {
            console.log("getTrue")
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.data[sort].sortby;
          } else {
            console.log("getfalse");
            this.sortby = this.sortby = sort > -1 ? this.data[sort].sort : '';
          }


          console.log(this.sortby);
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }



  editLead(item: any) {
    if (this.leadedit == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      this.dataForEdit = JSON.parse(JSON.stringify(item))
      this.leadAdd = true
    }
  }

  onActivate() {
    window.scrollTo(0, 0);
  }

  history(item: any) {
    this.dataForEdit = JSON.parse(JSON.stringify(item));
    console.log(this.dataForEdit);
    this.historyshow = true;
  }

  deleteLead(id: any) {
    if (this.leaddelete == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        width: "250px",
        panelClass: ['mat-typography'],
        closeOnNavigation: true
      })

      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.gettingData = true;
          const data = {
            "leadid": id,
            "orgid": this.crmForm.orgid,
            "domainid": this.crmForm.domainid,
            "domain": this.allinoneService.getDomain().shortcode
          }
          this.kunyekService.deleteCrmLead(data).subscribe((res: any) => {
            if (res.returncode == "300") {
              this.refreshgetData();
            }
            else {
              this.gettingData = false;
              this.messageService.openSnackBar(res.status, 'warn');
            }
          },
            err => {
              this.gettingData = false;
            }
          )
        }
      });
    }
  }


  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
    // this.getData();
  }

  clear() {
    this.searchText = '';
    this.searchTextService = '';
  }

  complete() {
    this.projectid = '';
    this.orgtype = this.crmForm.orgid;
    this.refreshgetData();
    this.cancel();


  }
  backCustomer() {
    this.router.navigateByUrl('sales/customers')
  }

  cancel() {
    this.dataForEdit = ''
    this.leadAdd = false
    this.orgtype = this.crmForm.orgid;
  }

  summary() {
    console.log("condole.log(summar");

    // this.summaryCheck = true;
    // this.router.navigateByUrl('/sales/services/summary');
  }








  new() {
    if (this.leadcreate == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedViewMessage, 'warn');
    } else {
      this.leadAdd = true;

    }
  }

  openTotalamountForm() {
    const dialog = this.dialog.open(PipelineTotalamountComponent, {
      width: '500px',
      height: '350px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        form: 'pipeline',
        datalist: this.data
      },
    });
    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    });

  }

  goToSecond() {

  }


  close() {
    const data = {
      'status': true,
    }

    this.dialogRef.close(data);
  }


  refreshgetData() {
    this.total = 0;
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    this.getData();
  }









  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.lastPage);
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;

        if (event > (this.data.length / this.itemPerPage)) {

          console.log("nnn>>>>>>getData")
          this.getData();

        }
      }

    }
  }


  serviceBack() {

  }


  getStatusList() {
    this.getSatatusType = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.crmForm.orgid,
    }
    this.kunyekService.getStatusType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.statusLists = res.list;
          this.getSatatusType = false;
        } else {
          this.getSatatusType = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getSatatusType = false;
      }
    );

  }





  downloadSheetLead() {
    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Leads_' + time + '.xlsx';
    this.data.map((lead: any) => {
      data.push({
        Name: lead.name,
        Phone: lead.mobile,
        Email: lead.email,
        Address: lead.address2,
        Organization: lead.organization,
        Status: lead.status == '001' ? 'Closed' : 'Open',
      });
    });
    this.allinoneService.exportEcecl(data, name);
  }

  cancelFunction() {
    console.log(data);
    this.leadAdd = false;


  }


  goCrm() {
    this.router.navigateByUrl('sales')
  }








  backCustomerbreadcrumb() {
    this.router.navigateByUrl('sales/customers')

  }
  backCustomerbreadcrumbedit() {
    const customerid = this.route.snapshot.queryParamMap.get('customerid') || "";
    // ;
    this.router.navigateByUrl(`sales/customers?id=${customerid}`)
  }

  goassignedTo(item: any) {
    console.log(item);
    const assignedid = item.assignedto;
    console.log(">>>>>>" + item.picid);
    console.log(">>>>>>" + assignedid);
    // ;

    this.router.navigateByUrl(`sales/service/pic?assignedto=${assignedid}`)
  }
  isExcelLead: boolean = false;
  importLead() {
    this.isExcelLead = true;
    this.leadAdd = true;
  }

  leadstatusname: any = 'Status'
  // ChangeLeadStatus1(event: any) {
  //   this.searchStatusLead = event.target.value;
  //   console.log(this.searchStatusLead)

  // }
  ChangeLeadStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.leadstatusname = 'Status';
      this.searchStatusLead = '';
    } else {
      this.searchStatusLead = data.value;
      this.leadstatusname = data.value;
    }
  }
}
