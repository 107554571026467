<div class="title-container mb-3">
  <div class="title">
    Type
  </div>
  <div class="close-btn">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<hr>
<div class="form-group mb-3">
  <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search" [(ngModel)]="typeSearch"
      style="font-family: FontAwesome,Ubuntu;">
</div>
<div style="height: 350px;overflow: auto;">
  <ng-container *ngIf="!gettingType">
    <ul class="list-group">

      <div *ngFor="let item of typeList | filter : typeSearch:'leaveTemplateTypeList'" class="d-flex justify-content-between align-items-center flex-row td-edit type-list-container"
        style="border-bottom: solid 0.5px #ececec;" (click)="selectType(item)">
        <div>
          {{item.requesttypename}}
        </div>
        <div>
          <input type="checkbox" class="form-check-input td-edit" [id]="item.classname" [checked]="item.checked">
        </div>
      </div>
    </ul>
  </ng-container>
  <ng-container *ngIf="gettingType">
    <div class="d-flex align-items-center justify-content-center" style="height: 200px;">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!gettingType && typeList.length == 0" class="d-flex align-items-center justify-content-center"
    style="height: 200px; width: 100%;">
    <div>
      Empty
    </div>
  </div>
</div>

<div class="close-save-button justify-content-end mt-3">
  <button type="button" class="btn btn-custom me-2" (click)="close()">
      Cancel
  </button>
  <app-button-loader (click)="done()" [button_text]="'Done'" [load_button_text]="''"></app-button-loader>
</div>