<div *ngIf="!showOrderDetails" class="d-flex flex-column justify-content-between">
  <div class="title-container">
    <div class="title">
      Orders
    </div>
    <div class="close-btn">
      <button mat-icon-button mat-dialog-close (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr>

  <div class="form-group mb-3">
    <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
      [(ngModel)]="orderSearch" name="'contactsearch" id="contactsearch" (ngModelChange)="searchChange()">
  </div>
  <!-- <div *ngIf="gettingOrder" class="d-flex justify-content-center" style="margin-top: 30%;">
    <div class="spinner-border mt-3" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div> -->

  <!-- <div style="overflow-y: auto;"> -->
  <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
    (scrolled)="onScrolledDown()" [perfectScrollbar]="config" class="chat-container"
    style="height: 390px !important;position: relative;">
    <ng-container *ngIf="!gettingOrder">
      <div class="mb-3" *ngFor="let od of orders;let index = index;">
        <div class="d-flex p-2 justify-content-between" style="background-color: rgb(229, 229, 229);">
          <div class="order-image td-edit m-2" (click)="viewOrderDeails(index)">
            <div *ngIf="od.image.imgurl.length>0">
              <img [src]="od.image.imgurl[0]" alt="Order Image">
            </div>
            <div *ngIf="od.image.imgurl.length==0">
              <img src="../../../assets/images/order_box.png" alt="Order Image">
            </div>
          </div>
          <div class="d-flex flex-column justify-content-center flex-fill td-edit" (click)="viewOrderDeails(index)">
            <div class="mb-1">
              ID : <span style="color: rgb(50, 120, 232);cursor: pointer;">
                {{od.orderno}}
              </span>

            </div>
            <div class="mb-1">
              <strong>
                {{od.desc}}
              </strong>
            </div>
            <div class="mb-1">
              Customer: {{od.cuname || "-"}}
            </div>

          </div>

          <label class="d-flex align-items-center justify-content-center" style="width: 80px;">
            <input type="checkbox" class="form-check-input" [id]="od.orderno" [checked]="od.checked"
              (change)="selectOrder($event,od)">
          </label>
        </div>

      </div>
    </ng-container>
    <ng-container *ngIf="gettingOrder">
      <div *ngFor="let i of [1,2,3]" class="d-flex align-items-center flex-row">
        <div class="me-3">
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 80, 
            'height.px': 80}"></ngx-skeleton-loader>
        </div>
        <div class="d-flex flex-column">
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{
            'width.px' : 150, 
            'height.px': 10}"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{
              'width.px' : 100, 
              'height.px': 10}"></ngx-skeleton-loader>
          <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                'width.px' : 80, 
                'height.px': 10}"></ngx-skeleton-loader>
        </div>
      </div>
    </ng-container>
    <div *ngIf="!gettingOrder && orders.length == 0" class="d-flex align-items-center justify-content-center"
      style="height: 200px; width: 100%;">
      <div>
        No orders found.
      </div>
    </div>
  </div>
  <div style="margin-top: 15px;">
    <button class="btn btn-custom w-100" (click)="save()">Save</button>
  </div>
</div>

<div *ngIf="showOrderDetails">
  <div class="title-container">
    <div class="back-btn" *ngIf="!showDetails">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="title">
      Orders Details
    </div>
    <div class="close-btn">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr>

  <perfect-scrollbar [config]="config" [scrollIndicators]="true" fxFlex="auto" style="height: 490px;">
    <div class="d-flex">
      <div class="d-flex flex-column justify-content-center" *ngIf="order.image.imgurl.length>1">
        <button mat-icon-button (click)="previousImage(order.image.imgurl)">
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>
      <div style="width: 350px;
      margin: auto;">
        <div *ngIf="order.image.imgurl.length==0">
          <div style="width: 150px;">
            <img src="../../../assets/images/order_box.png" alt="..." style="    width: 350px;
          height: 200px;
          object-fit: contain;">
          </div>
        </div>
        <div *ngIf="order.image.imgurl.length>0">
          <!-- <div *ngFor="let ordimage of order.image.imgurl;let i = index"> -->
          <img [src]="order.image.imgurl[selectedImageIndex]" style="    width: 350px;
            height: 200px;
            object-fit: contain;" alt="...">
          <!-- </div> -->
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center" *ngIf="order.image.imgurl.length>1">
        <button mat-icon-button (click)="nextImage(order.image.imgurl)">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center order-desc">
      {{order.desc}}
    </div>
    <div class="d-flex justify-content-around">

      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="order-title">
          ORDER ID
        </div>
        <div>
          {{order.orderno}}
        </div>
        <br>
        <div class="order-title">
          CUSTOMER
        </div>
        <div>
          {{order.cuname || '-'}}
        </div>
        <div>
          {{order.cumobile || '-'}}
        </div>
        <br>
        <div class="order-title">
          FROM
        </div>
        <div>
          <i class="fad fa-calendar me-2"></i>{{order.startdate ? allinoneService.formatDBToShow(order.startdate) :
          '-'}}
        </div>
        <div>
          <a href="https://www.google.com/maps/search/?api=1&query={{order.fromlat}},{{order.fromlong}}" target="_blank"
            *ngIf="order.fromlat != '' && order.fromlong != ''">
            {{order.fromaddress ? order.fromaddress : order.fromlat + ', ' + order.fromlong}}
          </a>
          <span *ngIf="order.fromlat == '' || order.fromlong == ''">
            {{order.fromaddress || '-'}}
          </span>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="order-title">
          STATUS
        </div>
        <div>
          <ng-container *ngIf="
                    order.status == '001' ||
                    order.status == '005' ||
                    order.status == '006'
                  ">
            <span [title]="order.statusName" class="status status-green">{{
              order.statusName
              }}</span>
          </ng-container>
          <ng-container *ngIf="order.status == '002'">
            <span [title]="order.statusName" class="status status-gray">{{
              order.statusName
              }}</span>
          </ng-container>
          <ng-container *ngIf="order.status == '003' || order.status == '004'">
            <span [title]="order.statusName" class="status status-main">{{
              order.statusName
              }}</span>
          </ng-container>
        </div>
        <br>
        <div class="order-title">
          RECIPIENT
        </div>
        <div>
          {{order.rename || '-'}}
        </div>
        <div>
          {{order.remobile || '-'}}
        </div>
        <br>
        <div class="order-title">
          TO
        </div>
        <div>
          <i class="fad fa-calendar me-2"></i>{{order.enddate ? allinoneService.formatDBToShow(order.enddate) : '-'}}
        </div>
        <div>
          <a href="https://www.google.com/maps/search/?api=1&query={{order.tolat}},{{order.tolong}}" target="_blank"
            *ngIf="order.tolat != '' && order.tolong != ''">
            {{order.toaddress ? order.toaddress : order.tolat + ', ' + order.tolong}}
          </a>
          <span *ngIf="order.tolat == '' || order.tolong == ''">
            {{order.toaddress || '-'}}
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="order.transporterlist.length > 0">
      <div class="order-title transporter-title">
        TRANSPORTERS
      </div>
      <div class="d-flex flex-row align-items-center justify-content-between" *ngFor="let tr of order.transporterlist"
        style="width: 300px;">
        <div>
          {{tr.userid}}
        </div>
        <div>
          {{tr.username}}
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>