<!-- checkModeal -->
<div [ngClass]="{'container-fluid':!checkModeal}">
  <div [ngClass]="{'content':!checkModeal}">
    <!-- <div >
    <div > -->
    <app-crm-breadcrumb
      *ngIf="!allinoneService.isCs365View && !checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && !productadmin && !isExeclProuct "
      [s_breadcrumb]="'Products'">
    </app-crm-breadcrumb>
    <app-crm-breadcrumb
      *ngIf=" !allinoneService.isCs365View && !checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && !productadmin && isExeclProuct "
      s_breadcrumb="Products" [t_breadcrumb]="'New'" (emit)="cancelFunction()">
    </app-crm-breadcrumb>



    <app-crm-breadcrumb
      *ngIf=" !allinoneService.isCs365View && !checkModeal && addProduct && !AdminAddorNot && !editProductcheck && !productadmin"
      s_breadcrumb="Products" [t_breadcrumb]="'New'" (emit)="cancelFunction()">
    </app-crm-breadcrumb>

    <app-crm-breadcrumb
      *ngIf="!allinoneService.isCs365View && !checkModeal && editProductcheck  && !addProduct && !AdminAddorNot && !productadmin"
      s_breadcrumb="Products" [t_breadcrumb]="'Edit'" (emit)="cancelFunction()">
    </app-crm-breadcrumb>

    <app-crm-breadcrumb
      *ngIf="!allinoneService.isCs365View && !checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && productadmin"
      [s_breadcrumb]="'Products'" [t_breadcrumb]="productForm.name != '' ? productForm.name : ''"
      [f_breadcrumb]="'Admins'" (emit)="cancelFunction()" (emit1)="cancels(2)" (emit2)="cancels(1)">
    </app-crm-breadcrumb>
    <app-crm-breadcrumb
      *ngIf="!allinoneService.isCs365View && !checkModeal && !addProduct && !editProductcheck && AdminAddorNot && !productadmin"
      [s_breadcrumb]="'Products'" [t_breadcrumb]="productForm.name != '' ? productForm.name : ''"
      [f_breadcrumb]="'Admins'" [fi_breadcrumb]="'Add'" (emit)="cancelFunction()" (emit1)="cancels(2)"
      (emit2)="cancels(1)">
    </app-crm-breadcrumb>



    <app-cs365-breadcrumb
      *ngIf="allinoneService.isCs365View && !checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && !productadmin && !isExeclProuct "
      [s_breadcrumb]="'Products'">
    </app-cs365-breadcrumb>
    <app-cs365-breadcrumb
      *ngIf="allinoneService.isCs365View && checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && !productadmin && isExeclProuct "
      s_breadcrumb="Products" [t_breadcrumb]="'New'" (emit)="cancelFunction()">
    </app-cs365-breadcrumb>



    <app-cs365-breadcrumb
      *ngIf="allinoneService.isCs365View && !checkModeal && addProduct && !AdminAddorNot && !editProductcheck && !productadmin"
      s_breadcrumb="Products" [t_breadcrumb]="'New'" (emit)="cancelFunction()">
    </app-cs365-breadcrumb>

    <app-cs365-breadcrumb
      *ngIf="allinoneService.isCs365View && !checkModeal && editProductcheck  && !addProduct && !AdminAddorNot && !productadmin"
      s_breadcrumb="Products" [t_breadcrumb]="'Edit'" (emit)="cancelFunction()">
    </app-cs365-breadcrumb>

    <app-cs365-breadcrumb
      *ngIf="allinoneService.isCs365View && !checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && productadmin"
      [s_breadcrumb]="'Products'" [t_breadcrumb]="productForm.name != '' ? productForm.name : ''"
      [f_breadcrumb]="'Admins'" (emit)="cancelFunction()" (emit1)="cancels(2)" (emit2)="cancels(1)">
    </app-cs365-breadcrumb>
    <app-cs365-breadcrumb
      *ngIf="allinoneService.isCs365View && !checkModeal && !addProduct && !editProductcheck && AdminAddorNot && !productadmin"
      [s_breadcrumb]="'Products'" [t_breadcrumb]="productForm.name != '' ? productForm.name : ''"
      [f_breadcrumb]="'Admins'" [fi_breadcrumb]="'Add'" (emit)="cancelFunction()" (emit1)="cancels(2)"
      (emit2)="cancels(1)">
    </app-cs365-breadcrumb>



    <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="checkModeal"
      [ngClass]="{'paddingleft':checkModeal}">
      <div class="label title" style="font-size: 17px;">Product</div>
      <div class="close-btn" *ngIf="checkModeal">
        <button mat-icon-button (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="mb-2" *ngIf="!addProduct && !editProductcheck && !AdminAddorNot && !productadmin && !isExeclProuct"
      [ngClass]="{'paddingleft':checkModeal}">
      <button class="btn btn-custom" (click)="new()" *ngIf="!checkModeal">
        <i class="fa fa-plus me-1"></i> New
      </button>
      <div class="d-flex" *ngIf="checkModeal">
        <button class="btn btn-custom me-auto" (click)="new()">
          <i class="fa fa-plus me-1"></i> New
        </button>
        <div class="input-group me-2 mb-2" style="width: 300px !important;">
          <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
            [(ngModel)]="searchtextpipe" style="font-family: FontAwesome,Ubuntu;">

        </div>
        <!-- <div *ngIf="!gettingData" class="mb-2">
                    <pagination-template *ngIf="productListdata.length > 0 " #p="paginationApi"
                        (pageChange)="pageChange($event)">
                        <div class="d-flex align-items-center justify-content-center">
                            <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'"
                                (click)="p.previous()">
                                <span class="me-1">&laquo;</span>&nbsp;Prev
                            </div>
                            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                <span class="page" (click)="p.setCurrent(page.value)"
                                    *ngIf="p.getCurrent() !== page.value">
                                    <span>{{ page.label }}</span>
                                </span>
                                <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                    <span>{{ page.label }}</span>
                                </div>
                            </div>
                            <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'"
                                (click)="p.next()">
                                Next&nbsp;<span class="ms-1">&raquo;</span>
                            </div>
                        </div>
                    </pagination-template>

                </div> -->
      </div>



    </div>
    <div class="mb-2 d-flex" *ngIf="addProduct || editProductcheck" [ngClass]="{'paddingleft':checkModeal}">
      <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
        Cancel
      </button>
      <app-button-loader *ngIf="addProduct " (click)="submitProduct()" [button_text]="'Save'"
        [load_button_text]="'Saving'" [isLoading]="saveLoading">
      </app-button-loader>
      <app-button-loader *ngIf="editProductcheck " (click)="updateProduct()" [button_text]="'Save'"
        [load_button_text]="'Saving'" [isLoading]="saveLoading">
      </app-button-loader>
      <div class="line " *ngIf="editProductcheck"></div>
      <button *ngIf="!AdminAddorNot && editProductcheck" type="button" class="btn btn-custom" (click)="addAdmin()">
        Admins
      </button>
    </div>
    <div class="mb-2 d-flex" *ngIf="AdminAddorNot && !isExecl" [ngClass]="{'paddingleft':checkModeal}">

      <button type="button" class="btn btn-custom me-2 " (click)="cancels(1)" *ngIf="!saveLoading">
        Cancel
      </button>
      <app-button-loader *ngIf="isadminValid" (click)="submitadmin()" [button_text]="'Save'"
        [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>
      <button *ngIf="!isadminValid" class="btn btn-custom " type="button" disabled>
        Save
      </button>
      <!-- checkingMember -->
      <!-- (click)="submitRootadmin()"  -->
    </div>

    <div *ngIf="AdminAddorNot || productadmin" class="admin row" [ngClass]="{'paddingleft':checkModeal}">



      <div *ngIf="AdminAddorNot && !isExecl">
        <div class="row mb-3">
          <label for="rootadmin" class="form-label">
            Admin
            <span *ngIf="isadminValid">( Name - {{username}} )</span>
          </label>
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="userid" id="rootadmin" name="rootadmin"
              placeholder="john@domain.com, +959********" required #rootadmin="ngModel"
              (ngModelChange)="useridChange()">
            <app-button-loader *ngIf="userid.length != 0" (click)="checkMember()" [button_text]="'Check'"
              [load_button_text]="'Checking'" [isLoading]="checkingMember">
            </app-button-loader>
            <button *ngIf="userid.length == 0" type="button" class="btn btn-custom" disabled>Check</button>
          </div>
        </div>
      </div>
      <perfect-scrollbar [ngClass]="{'event-container' : checkModeal }" *ngIf=" isExecl" class="event-container"
        [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <div *ngIf="AdminAddorNot && isExecl">
          <div class="mb-2 d-flex justify-content-start header">
            <button type="button" class="btn btn-custom me-2" (click)="cancels(1)" [disabled]="addcustomer">
              Cancel
            </button>

            <div class="close-save-button ">
              <app-button-loader class="btn-custom" (click)="submitExcel()" style="border-radius: 5px;"
                [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="addcustomer"
                *ngIf="finalMemberList.length > 0">
              </app-button-loader>
              <button *ngIf="finalMemberList.length == 0" class="btn btn-custom" disabled>
                Save
              </button>
            </div>

          </div>

          <div class="mb-2">
            <label for="file" class="form-label">Excel Template (<span class="cc-label"
                (click)="downloadSampleFile()">Download Sample Template</span>)</label>
            <div class="input-group">
              <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx" id="formFile"
                #excelFile [disabled]="excel">
              <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="excel"
                (click)="removeExcel()">Remove</button>
              <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;" id="btnGroupAddon2"
                [disabled]="!excel || checkingExcelFile" (click)="checkExcelFile()">
                <span *ngIf="!checkingExcelFile">
                  Check
                </span>
                <span *ngIf="checkingExcelFile">
                  <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
                  <span class="mx-1">Checking</span>
                </span>
              </button>
            </div>
            <div role="alert" *ngIf="excelerror" style="color: red;padding: 6px;">
              {{allinoneService.input_err_msg}}
            </div>

          </div>
          <div style="color : green" *ngIf="allok">
            <i class="fa fa-check me-2"></i> Template is correct.
          </div>
          <div class="row">
            <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0">
              <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                  error
                </mat-icon>
                <div>
                  Error
                </div>
              </div>
              <hr>
              <ul style="padding-left: 1rem;">
                <li *ngFor="let e of errorList;">
                  In line no. {{e.line}}, {{e.error}}
                </li>
              </ul>
            </div>
            <div class="alert alert-warning custom-box" *ngIf="duplicateList.length > 0 || warningList.length >0">
              <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                  warning
                </mat-icon>
                <div>
                  Warning
                </div>
              </div>
              <hr>
              <ul style="padding-left: 1rem;">
                <li *ngFor="let du of duplicateList;">
                  "{{du}}" is duplicated.
                </li>
                <li *ngFor="let e of warningList;">
                  In line no. {{e.line}}, {{e.error}}
                </li>
              </ul>


            </div>
          </div>

        </div>
      </perfect-scrollbar>

      <!-- productadmin -->

      <div class="table-responsive" *ngIf="productadmin">
        <div class="d-flex mb-2">
          <button type="button" class="btn btn-custom me-2 " (click)="cancels(2)" *ngIf="!saveLoading">
            Cancel
          </button>
          <button class="btn btn-custom me-2" (click)="addnewAdmin()">
            <i class="fa fa-plus me-1"></i> New
          </button>

          <button class="btn btn-custom me-2" (click)="importAdmin()" matTooltip="Export Product Admin To Excel">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Import
          </button>
          <button class="btn btn-custom me-2" (click)="exportAdmin()" matTooltip="Export Product Admin To Excel">
            <img class="excel-icon" src="../../../assets/images/excel.png">
            Export
          </button>
          <div class="line "></div>
          <div class="d-flex align-items-center me-auto ">
            <span class="total-count-text">Total:&nbsp;</span> <span
              class="total-count-num">{{productAdminList.length}}</span>
          </div>



        </div>


        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-5 col-md-5">User ID</td>
              <td scope="col" class="col-lg-5 col-md-5">Name</td>
              <td scope="col" class="col-lg-2 col-md-2 " style="text-align: center;">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="getAdmin">
            <tr>
              <td colspan="3" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="getAdmin== false" style="cursor: pointer;">
            <tr *ngIf="productAdminList.length == 0">
              <td colspan="3" class="nodata" *ngIf="!view">Empty</td>
              <td style="color: red;font-size: 14px;" colspan="3" class="nodata" *ngIf="view"><i
                  class="fa fa-exclamation-triangle" aria-hidden="true"
                  style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}</td>
            </tr>
            <tr *ngFor="let item of productAdminList;let index = index;">
              <td class="td-data">
                <span [title]="item.name">{{item.adminid}}</span>
              </td>
              <td class="td-data">
                <span [title]="item.name">{{item.adminname}}</span>
              </td>
              <td class="td-data " style="text-align: center;">
                <button class="ms-3 btn btn-outline-danger" (click)="deleteAdmin(item.autoid)">
                  <i class='fa fa-trash-o'></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <perfect-scrollbar *ngIf="checkModeal" class="event-container" [config]="config" [scrollIndicators]="true"
      fxFlex="auto" #perfectScroll>
      <div class="row" *ngIf="addProduct">
        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">SKU </label>
          <input type="text" class="form-control" id="sku" maxlength="20" name="sku" [(ngModel)]="productForm.sku"
            #sku="ngModel">
          <div class="invalid-feedback" *ngIf="productForm.sku.length > 20">
            SKU cannot be longer than 20 characters.
          </div>
        </div>

        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">Description</label>
          <input type="text" class="form-control" id="name" name="name" required [(ngModel)]="productForm.name"
            #name="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && name.invalid }">

          <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && serviceFormSubmitted">
            <div *ngIf="name.errors?.required">

              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>


        <div class="mb-3 col-md-12">
          <label for="ref" class="form-label pic" (click)="backPerson()">Person in Charge</label>
          <select class="form-select" aria-label="Select type" id="picid" name="picid" [(ngModel)]="productForm.pic">
            <!-- <option value=''>-</option> -->
            <!-- selected="pic.picid == productForm.pic" -->
            <option *ngFor="let pic of piclist;trackBy: trackByPicilist;" value="{{pic.picid}}">
              {{pic.picname == "" ? pic.userid : pic.picname}}</option>
          </select>
        </div>
      </div>

      <div class="row" *ngIf="editProductcheck">
        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">SKU </label>
          <input type="text" class="form-control" id="sku" maxlength="20" name="sku" [(ngModel)]="productForm.sku"
            #sku="ngModel">
          <div class="invalid-feedback" *ngIf="productForm.sku.length > 20">
            SKU cannot be longer than 20 characters.
          </div>
        </div>

        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">Description</label>
          <input type="text" class="form-control" id="name" name="name" required [(ngModel)]="productForm.name"
            #name="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && name.invalid }">

          <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && serviceFormSubmitted">
            <div *ngIf="name.errors?.required">

              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-12" *ngIf="allinoneService.isCs365View">
          <label for="ref" class="form-label">Description</label>
        </div>

        <div class="mb-3 col-md-12">
          <label for="ref" class="form-label pic" (click)="backPerson()">Person in Charge</label>
          <select class="form-select" aria-label="Select type" id="pic" name="pic" [(ngModel)]="productForm.pic">
            <option value=''>-</option>
            <option *ngFor="let pic of piclist;trackBy: trackByPicilist;" value="{{pic.picid}}">
              {{pic.picname == "" ? pic.userid : pic.picname}}</option>
          </select>

        </div>
        <div class="mb-3 d-flex align-items-end">
          <button class="btn btn-custom" (click)="openModuleForm()">
            <i class="fa fa-cube me-2" aria-hidden="true"></i> {{modulelist.length}} Module (s)
          </button>
        </div>


      </div>

      <div *ngIf="!addProduct && !editProductcheck && !AdminAddorNot && !productadmin && !isExeclProuct">
        <div class="table-responsive" matSort (matSortChange)="sortData($event)">

          <table class="table table-responsive  table-borderless">
            <thead class="table-header" style="position: sticky;top: 0;z-index:  2;">
              <tr>
                <td scope="col" class="col-lg-2 " mat-sort-header="sku">SKU1</td>
                <td scope="col" class="col-lg-4 " mat-sort-header="description">Description</td>
                <td scope="col" class="col-lg-2 " mat-sort-header="pic">Person in Charge</td>
                <td scope="col" class="col-lg-2 " mat-sort-header="createbyname">Created By</td>
                <td scope="col" class="col-lg-2 " style="text-align: center;">
                  Action</td>

              </tr>
            </thead>
            <tbody *ngIf="gettingData">
              <tr>
                <td colspan="5" class="nodata">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="gettingData== false" style="cursor: pointer;">
              <tr *ngIf="productListdata.length == 0">
                <td colspan="5" class="nodata" *ngIf="!view">Empty</td>
                <td style="color: red;font-size: 14px;" colspan="5" class="nodata" *ngIf="view"><i
                    class="fa fa-exclamation-triangle" aria-hidden="true"
                    style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}</td>

              </tr>
              <tr *ngFor="let item of productListdata | crmFilter : searchtextpipe:text1:text2:28;let index = index;">


                <td class="td-data" (click)="editProduct(item)">
                  <span title="">{{item.skucode}}</span>
                </td>
                <td class="td-data " (click)="editProduct(item)">
                  <span title="">{{item.name}}</span>
                </td>
                <td class="td-data" (click)="editProduct(item)">
                  <span>{{item.picname == '' ? item.picuserid : item.picname}}</span>
                </td>
                <td class="td-data" (click)="editProduct(item)">
                  <span>{{item.createdbyname}}</span>
                </td>
                <td class="d-flex " style="text-align: center;">
                  <button class="btn btn-outline-primary me-2" (click)="editProduct(item)">
                    <i class='fa fa-edit'></i>
                  </button>
                  <button class="btn btn-outline-danger" (click)="deleteProduct(item,index)">
                    <i class='fa fa-trash-o'></i>
                  </button>
                </td>



              </tr>
            </tbody>
          </table>
        </div>


      </div>
      <pagination-template *ngIf="productListdata.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
        <div class="d-flex align-items-center justify-content-center">
          <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
            <span class="me-1">&laquo;</span>&nbsp;Prev
          </div>
          <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
              <span>{{ page.label }}</span>
            </span>
            <div class="active-page" *ngIf="p.getCurrent() === page.value">
              <span>{{ page.label }}</span>
            </div>
          </div>
          <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
            Next&nbsp;<span class="ms-1">&raquo;</span>
          </div>
        </div>
      </pagination-template>


    </perfect-scrollbar>

    <div class="  w-100  justify-content-start align-items-center d-flex flex-wrap"
      *ngIf="!checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && !productadmin && !isExeclProuct">
      <div class="input-group me-2 mb-2" style="width: 300px !important;">
        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchtexts"
          [ngClass]="{'c-search-input-with-clear' : searchtexts }" style="font-family: FontAwesome,Ubuntu;"
          (keyup.enter)="onSearch()">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchtexts" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" (click)="onSearch()">
          <mat-icon class="c-icon">search</mat-icon>
        </button>

      </div>

      <div class="dropdown me-2 mb-2 ">
        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false" matTooltip="Page Size">
          <mat-icon>filter_list</mat-icon>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <div c style="text-align: center;">Page size</div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li class="dropdown-item" *ngFor="let page of pg">
            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
              id="pg{{page.name}}" class="p-1" [checked]="page.count==itemPerPage">
            <label for="pg{{page.name}}" class="col-10 p-1">
              {{page.name}}
            </label>
          </li>
        </ul>
      </div>
      <div class="mb-2">
        <button class="btn btn-custom" (click)="refresh()" matTooltip="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="line mb-3 "></div>
      <button type="button" class="btn btn-custom me-2 mb-2" (click)="downloadSheetProduct()"
        matTooltip="Export Product To Excel">
        <img class="excel-icon" src="../../../assets/images/excel.png">
        Export
      </button>
      <button class="btn btn-custom mb-2" (click)="importProduct()" title="Bulk Upload">
        <img class="excel-icon" src="../../../assets/images/excel.png">
        Import
      </button>

      <div class="line mb-3 ">

      </div>
      <div class="d-flex align-items-center me-auto mb-2">
        <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{total}}</span>
      </div>
      <div *ngIf="!gettingData " class="mb-2">
        <pagination-template *ngIf="productListdata.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>

      </div>
    </div>


    <div *ngIf="!checkModeal">
      <div *ngIf="!addProduct && isExeclProuct">
        <div class="mb-2 d-flex justify-content-start header">
          <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" [disabled]="addcustomer">
            Cancel
          </button>

          <div class="close-save-button ">
            <app-button-loader class="btn-custom" (click)="submitExcelProduct()" style="border-radius: 5px;"
              [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="addcustomer"
              *ngIf="finalMemberList.length > 0">
            </app-button-loader>
            <button *ngIf="finalMemberList.length == 0" class="btn btn-custom" disabled>
              Save
            </button>
          </div>

        </div>

        <div class="mb-2">
          <label for="file" class="form-label">Excel Template (<span class="cc-label"
              (click)="downloadSampleFileProduct()">Download Sample Template</span>)</label>
          <div class="input-group">
            <input class="form-control" type="file" (change)="onExcelFileSelectProduct($event)" accept=".xlsx"
              id="formFile" #excelFile [disabled]="excel">
            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="excel"
              (click)="removeExcel()">Remove</button>
            <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;" id="btnGroupAddon2"
              [disabled]="!excel || checkingExcelFile" (click)="checkExcelFileProduct()">
              <span *ngIf="!checkingExcelFile">
                Check
              </span>
              <span *ngIf="checkingExcelFile">
                <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
                <span class="mx-1">Checking</span>
              </span>
            </button>
          </div>
          <div role="alert" *ngIf="excelerror" style="color: red;padding: 6px;">
            {{allinoneService.input_err_msg}}
          </div>

        </div>
        <div style="color : green" *ngIf="allok">
          <i class="fa fa-check me-2"></i> Template is correct.
        </div>
        <div class="row">
          <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0">
            <div class="d-flex align-items-center">
              <mat-icon class="me-2">
                error
              </mat-icon>
              <div>
                Error
              </div>
            </div>
            <hr>
            <ul style="padding-left: 1rem;">
              <li *ngFor="let e of errorList;">
                In line no. {{e.line}}, {{e.error}}
              </li>
            </ul>
          </div>
          <div class="alert alert-warning custom-box" *ngIf="duplicateList.length > 0 || warningList.length >0">
            <div class="d-flex align-items-center">
              <mat-icon class="me-2">
                warning
              </mat-icon>
              <div>
                Warning
              </div>
            </div>
            <hr>
            <ul style="padding-left: 1rem;">
              <li *ngFor="let du of duplicateList;">
                "{{du}}" is duplicated.
              </li>
              <li *ngFor="let e of warningList;">
                In line no. {{e.line}}, {{e.error}}
              </li>
            </ul>


          </div>
        </div>

      </div>

      <div class="row" *ngIf="addProduct && !isExeclProuct">
        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">SKU </label>
          <input type="text" class="form-control" id="sku" name="sku" [(ngModel)]="productForm.sku" #sku="ngModel"
            [ngClass]="{ 'is-invalid': productForm.sku.length > 20 }">
          <div class="invalid-feedback" *ngIf="productForm.sku.length > 20">
            SKU cannot be longer than 20 characters.
          </div>
        </div>

        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">Description</label>
          <input type="text" class="form-control" id="name" name="name" required [(ngModel)]="productForm.name"
            #name="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && name.invalid }">

          <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && serviceFormSubmitted">
            <div *ngIf="name.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-12" *ngIf="allinoneService.isCs365View">
          <label for="warranty" class="form-label">Warranty</label>
          <select class="form-select" [(ngModel)]="selectWarranty">
            <option value=''>-</option>
            <option *ngFor="let warranty of warranties" [value]="warranty.warrantyid">{{ warranty.warrantycode }}
            </option>
          </select>
        </div>
        <div class="mb-3 col-md-12">
          <label for="ref" class="form-label pic" (click)="backPerson()">Person in Charge</label>
          <select class="form-select" aria-label="Select type" id="pic" name="pic" [(ngModel)]="productForm.pic">
            <option value=''>-</option>
            <option *ngFor="let pic of piclist;trackBy: trackByPicilist;" value="{{pic.picid}}">
              {{pic.picname == "" ? pic.userid : pic.picname}}</option>
          </select>

        </div>
        <!-- <div class="mb-3 d-flex align-items-end">
                    <button class="btn btn-custom" (click)="openModuleForm()">
                        <i class="fa fa-cube me-2" aria-hidden="true"></i> Modules  {{modulelist.length}}
                    </button>
                </div> -->


      </div>

      <div class="row" *ngIf="editProductcheck && !isExeclProuct">
        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">SKU</label>
          <input type="text" class="form-control" id="sku" maxlength="20" name="sku" [(ngModel)]="productForm.sku"
            #sku="ngModel">
          <div class="invalid-feedback" *ngIf="productForm.sku.length > 20">
            SKU cannot be longer than 20 characters.
          </div>
        </div>

        <div class="mb-3  col-md-12">
          <label for="ref" class="form-label">Description</label>
          <input type="text" class="form-control" id="name" name="name" required [(ngModel)]="productForm.name"
            #name="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && name.invalid }">

          <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && serviceFormSubmitted">
            <div *ngIf="name.errors?.required">

              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>

        <div class="mb-3 col-md-12" *ngIf="allinoneService.isCs365View">
          <label for="warranty" class="form-label">Warranty</label>
          <select class="form-select" [(ngModel)]="selectWarranty">
            <option value=''>-</option>
            <option *ngFor="let warranty of warranties" [value]="warranty.warrantyid">{{ warranty.warrantycode }}
            </option>
          </select>
        </div>
        <div class="mb-3 col-md-12">
          <label for="ref" class="form-label pic" (click)="backPerson()">Person in Charge</label>
          <select class="form-select" aria-label="Select type" id="pic" name="pic" [(ngModel)]="productForm.pic">
            <option value=''>-</option>
            <option *ngFor="let pic of piclist;trackBy: trackByPicilist;" value="{{pic.picid}}">
              {{pic.picname == "" ? pic.userid : pic.picname}}</option>
          </select>

        </div>
        <div class="mb-3 d-flex align-items-end">
          <button class="btn btn-custom" (click)="openModuleForm()">
            <i class="fa fa-cube me-2" aria-hidden="true"></i> {{modulelist.length}} Module (s)
          </button>
        </div>



      </div>

      <div class="table-responsive"
        *ngIf="!addProduct && !editProductcheck && !AdminAddorNot && !productadmin && !isExeclProuct">
        <div matSort (matSortChange)="sortData($event)">

          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>

                <td scope="col" class="col-lg-2 " mat-sort-header="sku">SKU2</td>
                <td scope="col" class="col-lg-4 " mat-sort-header="description">Description</td>
                <td scope="col" class="col-lg-2 " mat-sort-header="pic">Person in Charge</td>
                <td scope="col" class="col-lg-2 " mat-sort-header="createbyname">Created By</td>
                <td scope="col" class="col-lg-2 " style="text-align: center;">
                  Action</td>

              </tr>
            </thead>
            <tbody *ngIf="gettingData">
              <tr>
                <td colspan="5" class="nodata">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="gettingData == false" style="cursor: pointer;">
              <tr *ngIf="productListdata.length == 0">
                <td colspan="5" class="nodata" *ngIf="!view">Empty</td>
                <td style="color: red;font-size: 14px;" colspan="5" class="nodata" *ngIf="view"><i
                    class="fa fa-exclamation-triangle" aria-hidden="true"
                    style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}</td>
              </tr>
              <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
              <!--  -->
              <tr
                *ngFor="let item of productListdata | crmFilter :searchtext:searchNonea:searchNones:6 | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index;">
                <td class="td-data" (click)="editProduct(item)">
                  <span title="">{{item.skucode}}</span>
                </td>
                <td class="td-data " (click)="editProduct(item)">
                  <span title="">{{item.name}}</span>
                </td>
                <td class="td-data" (click)="editProduct(item)">
                  <!-- <span>1</span> -->
                  <span>{{item.picname == '' ? item.picuserid : item.picname}}</span>
                </td>
                <td class="td-data" (click)="editProduct(item)">
                  <!-- <span>1</span> -->
                  <span>{{item.createdbyname}}</span>
                </td>

                <td class="d-flex ">
                  <button class="btn btn-outline-primary me-2" (click)="editProduct(item)">
                    <i class='fa fa-edit'></i>
                  </button>
                  <button class="btn btn-outline-danger" (click)="deleteProduct(item,index)">
                    <i class='fa fa-trash-o'></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div
        *ngIf="!gettingData  && !checkModeal && !addProduct && !editProductcheck && !AdminAddorNot && !productadmin && !isExeclProuct ">
        <pagination-template *ngIf="productListdata.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'" (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>

      </div>
    </div>










  </div>
</div>