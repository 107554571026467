<div class="container-fluid">
  <div class="content">
    <app-team-breadcrumb s_breadcrumb="Rules and Regulations">
    </app-team-breadcrumb>
    <ng-container>
      <div class="d-flex align-items-center mb-3">
        <button class="btn btn-custom me-auto" (click)="openSetupForm()">
          <i class="fa fa-plus me-1"></i> New
        </button>
      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description"
            aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (dataList | filter : searchText: 'rules' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="dataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-3" mat-sort-header="name">Name</td>
              <td scope="col" class="col-md-3" mat-sort-header="type">Type</td>
              <td scope="col" class="col-md-3" mat-sort-header="description">Description</td>
              <td scope="col" class="col-md-3" mat-sort-header="filename">File Name</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="tableLoading">
            <tr>
              <td colspan="5" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!tableLoading">
            <tr *ngIf="dataList.length == 0">
              <td colspan="5" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let data of dataList | filter : searchText: 'rules' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
              <td class="td-data td-edit" (click)="openSetupForm(data)">
                <span [title]="data.name">{{ data.name }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.type">{{
                  getType(data.type)
                  }}</span>
              </td>
              <td class="td-data">
                <span [title]="data.description">{{
                  data.description
                  }}</span>
              </td>
              <td class="td-data">
                <!-- <a [title]="data.filename" [href]="data.fileurl" target="_blank" [download]="data.filename" ><span >{{
                          data.filename
                        }}</span></a> -->
                <a [href]="data.fileurl" target="_blank" [download]="data.filename">{{
                  data.filename
                  }}</a>
                <!-- <span [title]="data.filename">{{
                          data.filename
                        }}</span> -->
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-info me-2" (click)="viewFile(data)" matTooltip="View File"
                  [disabled]="data.filetype.toLowerCase() != 'pdf'">
                  <i class="fa fa-file-text-o"></i>
                </button>
                <button class="btn btn-outline-primary me-2" (click)="openSetupForm(data)" matTooltip="Edit">
                  <i class="fa fa-edit"></i>
                </button>
                <button [disabled]="data.readonly" class="btn btn-outline-danger" (click)="deleteRules(data.ruleid)"
                  matTooltip="Delete">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="dataList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-container>
  </div>
</div>