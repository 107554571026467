import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HierarchyAddComponent } from 'src/app/admin/hierarchy-add/hierarchy-add.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-pipeline-totalamount',
  templateUrl: './pipeline-totalamount.component.html',
  styleUrls: ['./pipeline-totalamount.component.css'],
})
export class PipelineTotalamountComponent implements OnInit {
  pipelinedata: any[] = [];
  totalamount: any[] = [];
  userlist: any[] = [];

  cuserid: any = '';

  constructor(
    private dialogRef: MatDialogRef<HierarchyAddComponent>,
    public allinoneService: AllInOneService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.pipelinedata = data.datalist;
  }

  ngOnInit(): void {
    this.getUserList();
    this.calAmount('');
    console.log(this.totalamount);
  }
  getUserList() {
    for (var i = 0; i < this.pipelinedata.length; i++) {
      var res = this.userlist.filter(
        (x: any) => x.userid == this.pipelinedata[i].createdby
      ).length;
      if (res == 0) {
        this.userlist.push({
          userid: this.pipelinedata[i].createdby,
          username: this.pipelinedata[i].createdbyname,
        });
      }
    }
  }
  calAmount(userid: any) {
    this.totalamount = []
    if (userid == '') {
      for (var i = 0; i < this.pipelinedata.length; i++) {
        if (
          this.pipelinedata[i].currency != '' &&
          this.pipelinedata[i].amount != ''
        ) {
          // var res=this.pipelinedata.filter((x:any)=>x.currency==this.pipelinedata[i].currency).length
          var index = this.totalamount
            .map((x: any) => x.currency)
            .indexOf(this.pipelinedata[i].currency);
          if (index == -1) {
            this.totalamount.push({
              currency: this.pipelinedata[i].currency,
              currencyname: this.pipelinedata[i].currencyname,
              total: parseFloat(this.pipelinedata[i].amount),
            });
          } else {
            this.totalamount[index].total += parseFloat(
              this.pipelinedata[i].amount
            );
          }
        }
      }
    } else {
      var list: any = this.pipelinedata.filter(
        (x: any) => x.createdby == userid
      );
      console.log(list);

      for (var i = 0; i < list.length; i++) {
        if (list[i].currency != '' && list[i].amount != '') {
          console.log("it reached here")
          var index = this.totalamount
            .map((x: any) => x.currency)
            .indexOf(list[i].currency);
          console.log(index)
          if (index == -1) {
            this.totalamount.push({
              currency: list[i].currency,
              currencyname: list[i].currencyname,
              total: parseFloat(list[i].amount),
            });
          } else {
            this.totalamount[index].total += parseFloat(list[i].amount);
          }
        }
      }
    }
  }
  textChange() {
    this.totalamount = [];
    this.calAmount('');
  }
  userchange(event: any) {
    this.calAmount(event.target.value);
  }
}
