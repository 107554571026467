import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-project-report-detail',
  templateUrl: './project-report-detail.component.html',
  styleUrls: ['./project-report-detail.component.scss']
})
export class ProjectReportDetailComponent implements OnInit {

  gettingDetails: boolean = false
  isFocus: boolean = false

  searchText: string = ""
  p: number = 1
  type: number = 6

  detailForm = {
    'projectname': '',
    'type': '',
    'orgid': '',
    'orgname': '',
    'userid': '',
    'username': '',
    'hours': '',
  }

  domain: any = ""
  detailList: any = []
  tempListToCalculateTime = []
  totalTime: any = '0 hr 0 min'


  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private filter: FilterPipe
  ) { 
    if(!this.allinoneService.isAdminOf('contributor')){
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    this.domain = this.allinoneService.getDomain()
    var tempType = this.route.snapshot.params['type']
    var tempUser = JSON.parse(this.allinoneService.decrypt(this.allinoneService.replaceAll(this.route.snapshot.params['userdata'], '###', '/')))
    this.detailForm.userid = tempUser.userid
    this.detailForm.username = tempUser.username
    this.detailForm.hours = tempUser.hours
    this.detailForm.projectname = tempUser.projectname
    this.detailForm.orgid = this.route.snapshot.params['orgid']
    if (tempType == 'project' || tempType == 'job') {
      this.detailForm.type = tempType
    }
    else {
      this.router.navigate(['/home'])
    }

    const index = this.allinoneService.contributorOrgs.findIndex((x: any) => (x.orgid == this.detailForm.orgid))
    if (index == -1) {
      this.router.navigate(['/home'])
    }
    else {
      this.detailForm.orgname = this.allinoneService.contributorOrgs[index].name
    }

    this.detailList = sessionStorage.getItem('projdetails') ? JSON.parse(sessionStorage.getItem('projdetails')!) : []
    this.detailList.map((item: any) => {
      item.hours = this.calEndTime(item.date, item.starttime, item.child[0].date, item.child[0].starttime)
      item.hourstoshow = this.transformMinute(item.hours)
    })
    this.search()
  }

  downloadSheet() {
    var exdata: any = [];
    var name = this.detailForm.username + "-" + this.detailForm.projectname + ".xlsx"
    var filteredDetailedList = this.filter.transform(this.detailList, this.searchText, this.type);
    filteredDetailedList.map((item: any) => {
      var temp = {
        "Job Name" : item.jobname,
        "Date": this.allinoneService.formatDBToShow(item.date) + " " + item.starttime,
        "Task ID": item.autoid,
        "Task Name": item.taskdescription,
        "Hours": item.hourstoshow
      }
      exdata.push(temp)
    })
    var temp1 = {
      "Job Name" : "",
      "Date": "",
      "Task ID": "",
      "Task Name": "",
      "Hours": ""
    }
    exdata.push(temp1)
    var temp2 = {
      "Job Name" : "",
      "Date": "",
      "Task ID": "",
      "Task Name": "Total",
      "Hours": this.detailForm.hours
    }
    exdata.push(temp2)
    this.allinoneService.exportEcecl(exdata, name)
  }

  backToCIA() {
    this.router.navigate(['/contributor/project-report'])
  }

  clear() {
    this.searchText = ""
    this.search()
  }

  sortData(sort: Sort) {
    const data = this.detailList
    if (!sort.active || sort.direction === '') {
      this.detailList = data
      return;
    }

    this.detailList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'jobname': return this.allinoneService.compare(a.jobname, b.jobname, isAsc);
        case 'date': return this.allinoneService.compare((a.date + a.starttime), (b.date + b.starttime), isAsc);
        case 'autoid': return this.allinoneService.compare(a.autoid, b.autoid, isAsc);
        case 'desc': return this.allinoneService.compare(a.taskdescription, b.taskdescription, isAsc);
        case 'hours': return this.allinoneService.compare(a.hours, b.hours, isAsc);
        default: return 0;
      }
    });
  }

  calEndTime(date: any, starttime: any, childdate: any, childstarttime: any) {
    var format = "yyyy/MM/dd hh:mm:ss a";
    var datetime = date
    var yy = datetime.slice(0, 4)
    var mm = datetime.slice(4, 6)
    var dd = datetime.slice(6, 8)
    var checkinD = yy + "/" + mm + "/" + dd + " " + starttime;

    var cdatetime = childdate
    var cyy = cdatetime.slice(0, 4)
    var cmm = cdatetime.slice(4, 6)
    var cdd = cdatetime.slice(6, 8)
    var childD = cyy + "/" + cmm + "/" + cdd + " " + childstarttime;
    let date1 = new Date(checkinD);
    let date2 = new Date(childD);
    date1.setSeconds(0)
    date2.setSeconds(0)
    console.log(date1, date2)
    var diffMin = this.diff_minutes(date1, date2);
    return diffMin;
  }

  diff_minutes(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) * -1
    var minutes = Math.floor(diff / 60000);
    return Math.abs(minutes);
  }

  transformMinute(value: number): string {
    var hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    var day = 0;
    if (hours == 0) {
      return minutes + ' mins';

    }
    else if (hours == 1) {
      return hours + ' hr ' + minutes + ' mins ';

    }
    else {
      return hours + ' hrs ' + minutes + ' mins ';

    }
  }

  search(){
    this.tempListToCalculateTime = this.detailList.filter((it:any) => {
      return (
        it.jobname.toString().toLocaleLowerCase().includes(this.searchText) ||
        it.autoid.toString().toLocaleLowerCase().includes(this.searchText) ||
        it.taskdescription
          ?.toString()
          .toLocaleLowerCase()
          .includes(this.searchText) ||
        it.hours.toString().toLocaleLowerCase().includes(this.searchText) ||
        (this.allinoneService.formatDBToShow(it.date)+" "+it.starttime).toString().toLocaleLowerCase().includes(this.searchText)
      );
    });

    var totalMins = 0
    this.tempListToCalculateTime.map((item: any) => {
      totalMins += item.hours
    })
    this.totalTime = this.transformMinute(totalMins)
  }

}
