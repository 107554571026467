import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CrmLeadConvertComponent } from '../crm-lead-convert/crm-lead-convert.component';
import { ProductsComponent } from '../products/products.component';
import * as XLSX from 'xlsx';
import { Sort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-service-overage-setup',
  templateUrl: './service-overage-setup.component.html',
  styleUrls: ['./service-overage-setup.component.css']
})
export class ServiceOverageSetupComponent implements OnInit {
  isEditing: boolean = false;
  addpriority: boolean = false;
  gettingData: boolean = false;
  saveLoading: boolean = false;



  data: any = [];
  priorityFormSave: boolean = false;
  priorityForm = {
    "priority": "",
    "type": "",
    "day": "",
    "overageid": "",
    "name":"",
  }
  priority: any = [];
  typelist: any = [];
  servicetypelist: any = [];
  getServiceType: boolean = false;
  organizations: any = [];
  orgid: any = "";
  servicetype: any = [];

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private http: HttpClient,
    private dialog: MatDialog,
  ) {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    this.getOverageList();
    this.getpriority();
    this.getServiceTypes();

  }

  cancelFunction() {
    this.addpriority = false;
    this.isEditing = false;
    this.priorityForm = {
      "priority": "",
      "type": "",
      "day": "",
      "overageid": "",
      "name":"",

    }
    this.servicetype = [];
    this.servicetypelist = [];
  }



  sortData(sort: Sort) {
    console.log(this.data);
    const data = this.data;
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        //Lead
        case 'description':
          return this.allinoneService.compare(
            a.description.toLocaleLowerCase(),
            b.description.toLocaleLowerCase(),
            isAsc
          );
        case 'priorityname':
          return this.allinoneService.compare(
            a.priorityname.toLocaleLowerCase(),
            b.priorityname.toLocaleLowerCase(),
            isAsc
          );
        case 'typename':
          return this.allinoneService.compare(
            a.typename.toLocaleLowerCase(),
            b.typename.toLocaleLowerCase(),
            isAsc
          ); 
          case 'days':
          return this.allinoneService.compare(
            parseInt( a.days),
            parseInt(b.days),
            isAsc
          );
        default:
          return 0;
      }
    });
    // console.log(this.data);

  }

  new() {
    this.isEditing = false;
    this.addpriority = true;

  }

  editpriority(item: any) {
    this.isEditing = true;
    this.addpriority = false;
    console.log(item.type);
    console.log(this.typelist);
    for(let i = 0 ; i < this.typelist.length;i++){
      for(let j = 0; j  < item.type.length;j++){
        if(item.type[j] == this.typelist[i]['code']){
          this.servicetypelist.push(this.typelist[i]);
        }
      }
    }
    
    this.servicetype = item.type;
    this.priorityForm.day = item.days;
    this.priorityForm.priority = item.priority;
    this.priorityForm.type = item.type;
    this.priorityForm.overageid = item.overageid
    this.priorityForm.name = item.name;

    console.log(this.servicetypelist);

  }

  submitpriority() {
    this.priorityFormSave = true;
    if (this.priorityForm.day == '' || this.priorityForm.name == '') {
      return;
    }
    this.saveLoading = true;

    var data = {
      "days": this.priorityForm.day,
      "priority": this.priorityForm.priority,
      "type": this.servicetype,
      "orgid": this.orgid,
      "name":this.priorityForm.name

    }

    console.log(data);

    if (this.isEditing) {
      data = Object.assign(data, {
        overageid: this.priorityForm.overageid,
        isUpdate: true
      });
    }

    if (this.isEditing == true) {
      this.kunyekService.addoveragesetup(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.addpriority = false;
            this.saveLoading = false;
            this.isEditing = false;
            // this.getCarWay();
            this.refreshgetData();

          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
    } else {
      this.kunyekService.addoveragesetup(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.addpriority = false;
            this.isEditing = false;
            this.saveLoading = false;
            // this.getCarWay();
            this.refreshgetData();
          } else {
            this.saveLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.saveLoading = false;
        }
      );
    }
  }

  delete(id: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingData = true;
        const data = {
          "overageid": id,
          "isDelete": true,
          "orgid": this.orgid
        }
        this.kunyekService.addoveragesetup(data).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.refreshgetData()
          }
          else {
            this.gettingData = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
          err => {
            this.gettingData = false;
          }
        )
      }
    });
  }

  refreshgetData() {
    this.priorityFormSave = false;
    this.cancelFunction();
    this.data = [];
    this.getOverageList();
  }


  getOverageList() {

    this.gettingData = true;
    this.data = [];
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "isView": true
      // "date":this.allinoneService.formatDBToShow(this.startdate)
    };
    this.kunyekService.addoveragesetup(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.data = res.list;
          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }


  getpriority() {

    this.priority = [];
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,

      // "date":this.allinoneService.formatDBToShow(this.startdate)
    };
    this.kunyekService.getPriority(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.priority = res.list;
          this.priority.unshift({
            "autoid": 0,
            "code": "",
            "name": "All"
          })

        } else {

          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  getServiceTypes() {
    this.getServiceType = true;
    return new Promise((resolve) => {
      const data = {
        "domainid": this.allinoneService.getDomain().domainid,
        "orgid": this.orgid,
      }
      this.kunyekService.getServiceType(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.data = res.list;
            this.typelist = res.list;
            // if (this.servicetypelist.length > 0) {
            //   this.typename = this.servicetypelist[0].name;
            // }
            this.getServiceType = false;
            resolve('true')
          } else {
            this.getServiceType = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('true')
          }
        },
        (err) => {
          this.getServiceType = false;
          resolve('err');
        }
      );
    });
  }


  serviceTypechange(data: any) {
    console.log(data);
    if (data != undefined) {
      this.servicetype =[];
      for(let i = 0 ;i < this.servicetypelist.length;i++){
        this.servicetype.push(this.servicetypelist[i]['code']);
      }

    } else {
      this.servicetypelist = []
      this.servicetype = [];
    }
  }


}
