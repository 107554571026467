import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { ActivatedRoute } from '@angular/router';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-checkin-qr',
  templateUrl: './checkin-qr.component.html',
  styleUrls: ['./checkin-qr.component.scss'],
})
export class CheckinQrComponent implements OnInit {
  qrLoading: boolean = false;
  showRefresh: boolean = false;
  qrError: boolean = false;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;

  checkQrInterval: any;

  value: any = '';
  qrdata: string = '';

  count = 0;

  constructor(
    public allinoneService: AllInOneService,
    private route: ActivatedRoute,
    private kunyekService: KunyekService
  ) {
    this.allinoneService.navbar = false;

    const temp_qr = this.route.snapshot.paramMap.get('qrdata');

    if (temp_qr) {
      this.qrdata = decodeURIComponent(temp_qr); 
      const temp = {
        "qrdata" : this.qrdata,
        "type": 4
      }
      this.value = JSON.stringify(temp);
    }
  }

  ngOnInit(): void {
    this.refreshQr();
  }

  ngOnDestroy(): void {
    clearInterval(this.checkQrInterval);
  }

  async refreshQr() {
    const a = await this.checkQr();
    this.showRefresh = false;
    this.checkQrInterval = setInterval(async () => {
      await this.checkQr();
      this.count += 1;
      console.log('checking qr');
      if (this.count == 60) {
        // if (this.count == 6) {
        clearInterval(this.checkQrInterval);
        this.showRefresh = true;
        this.count = 0;
      }
    }, 5000);
  }

  checkQr() {
    return new Promise((resolve) => {
      this.kunyekService.checkLocationQR(this.qrdata).subscribe(
        (res: any) => {
          if (res.returncode == '200') {
            this.qrError = true;
          } else if (res.returncode == '300' && res.change == true) {
            this.qrdata = res.qrdata;
            const temp = {
              "qrdata" : this.qrdata,
              "type": 4
            }
            this.value = JSON.stringify(temp);
          }
          resolve(true);
        },
        (err) => {
          resolve(true);
        }
      );
    });
  }
}
