
<div class="event-detail-wrapper" *ngIf="event">
    <div class="row">
        <div class="label title mb-3">{{event.name}}</div>
        <ul class="list-group">
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Location
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{event.locname}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Occurrence
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{event.occurrence}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Start Date
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{event.startdatetoshow}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        End Date
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{event.enddatetoshow}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Description
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text">
                        {{event.description}}
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                        Attendance
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-7 mr-text" (click)="viewAttendance(event.eventid)" style="cursor: pointer;">
                        {{attendanceList.length}}/{{attendanceList.length}}
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="close-btn">
        <button type="button" class="btn btn-custom " (click) = "close()">Close</button>
    </div>
</div>