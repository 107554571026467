<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title" *ngIf="!productAddOrNot">Products</div>
        <div class="label title" *ngIf="productAddOrNot">Add Product</div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close [disabled]="saveLoading" (click)="Close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="mb-2 d-flex header">
        <div>
            <button *ngIf="!productAddOrNot" type="button" class="btn btn-custom" (click)="productAddOrNot = true">
                <i class="fa fa-plus me-1"></i>Add
            </button>

            <div *ngIf="productAddOrNot" class="close-save-button">
                <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="submitProduct()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
                </app-button-loader>
            </div>
        </div>
    </div>
    <div *ngIf="!productAddOrNot">
        <div *ngIf="savedProduct.length == 0" class="nodata d-flex align-items-center justify-content-center">
            Empty
        </div>
        <div class="d-flex flex-column products-card p-1 my-2" *ngFor="let product of savedProduct; let i = index;">
            <div class="d-flex flex-column" (click)="editProducts(product)">
                <div class="d-flex justify-content-between">
                    <div class="p-1 product-name ">
                        {{product.skucode}} {{product.name}}
                    </div>
                    <div (click)="delete(i)">
                        <i class="fas fa-times delete-icon"></i>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="p-1">
                        {{product.price | number:'2.'}} x {{product.qty | number:'2.'}}
                    </div>
                    <div class="p-1">
                        Amount: {{product.amount | number:'2.'}}
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div *ngIf="productAddOrNot">
        <div class="my-3">
            <label for="skucode" class="form-label">SKU</label>
            <input type="text" placeholder="0001" aria-label="Number" matInput class="form-control" (ngModelChange)="skucodeChange()" [matAutocomplete]="auto1" [(ngModel)]="searchText1" name="skucode" id="skucode" #skucode="ngModel" [ngClass]="{ 'is-invalid': productFormSubmitted && skucode.invalid }"
                [disabled]="getttingProducts" required>
            <mat-progress-bar mode="indeterminate" *ngIf="getttingProducts"></mat-progress-bar>
            <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let product of productList | filter : searchText1:21 " [value]="product.skucode" (onSelectionChange)="productChange(product)" (click)="productChange(product)">
                    {{product.skucode}} - {{product.name}}
                    <!-- ({{product.price}}) -->
                </mat-option>
            </mat-autocomplete>

            <div class="invalid-feedback" *ngIf="skucode.invalid || (skucode.dirty || skucode.touched) && productFormSubmitted">
                <div *ngIf="skucode.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>

        </div>
        <div class="mb-3">
            <label for="pname" class="form-label">
            Name
        </label>
            <input type="text" class="form-control" id="pname" name="pname" [(ngModel)]="productForm.name" required #pname="ngModel" [ngClass]="{ 'is-invalid': productFormSubmitted && pname.invalid }">

            <div class="invalid-feedback" *ngIf="pname.invalid || (pname.dirty || pname.touched) && productFormSubmitted">
                <div *ngIf="pname.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <label for="quantity" class="form-label">
            Quantity
        </label>
            <input type="number" class="form-control" id="quantity" name="quantity" [(ngModel)]="productForm.qty" required #quantity="ngModel" [ngClass]="{ 'is-invalid': productFormSubmitted && quantity.invalid }">

            <div class="invalid-feedback" *ngIf="quantity.invalid || (quantity.dirty || quantity.touched) && productFormSubmitted">
                <div *ngIf="quantity.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <label for="price" class="form-label">
            Price
        </label>
            <input type="number" class="form-control" id="price" name="price" [(ngModel)]="productForm.price" required #price="ngModel" [ngClass]="{ 'is-invalid': productFormSubmitted && price.invalid }">

            <div class="invalid-feedback" *ngIf="price.invalid || (price.dirty || price.touched) && productFormSubmitted">
                <div *ngIf="price.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
    </div>

</div>
