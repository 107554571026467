import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { KunyekService } from './shared/services/kunyek.service';
import { MessageService } from './shared/services/message.service';
import { AllInOneService } from './shared/services/all-in-one.service';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AlertBoxComponent } from './shared/components/alert-box/alert-box.component';
import { MatDialog } from '@angular/material/dialog';
import { SeoService } from './shared/services/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'kunyek';
  checkedToken: boolean = false;
  isError: boolean = false;
  errorMsg: string = 'Something went wrong';

  selectedDomain: any;
  isChooseDomain: boolean = false;
  domains: any;
  hideNavbar: boolean = false;
  sidebarmenus: any[] = [];
  isMemberAdmin: boolean = false;


  adminsidebarMenuList = [
    {
      "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/members.png",
      "name": "Member",
      "open": "1",
      "path": "members"
    },
    {
      "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/entitlement.png",
      "name": "Payment",
      "open": "1",
      "path": "payment"
    },
    {
      "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/vacations.png",
      "name": "Swim",
      "open": "1",
      "path": "swim"
    },
    {
      "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/gym.png",
      "name": "Gym",
      "open": "1",
      "path": "gym"
    },
    {
      "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/officeicons/approval.png",
      "name": "Leave",
      "open": "1",
      "path": "leave"
    }
  ];


  noContactTracingOrgArray = ['92039411', '22666667', '64651545'];

  onlineEvent!: Observable<Event>;
  offlineEvent!: Observable<Event>;
  subscriptions: Subscription[] = [];

  organizations: any = [];
  customerdata: any = [];
  nextroute: string = '';

  isMobileView: boolean = false;

  mobileViewWidth: number = 426;

  checkservice: boolean = false;

  orgListBlankError: boolean = false;
  orgListBlankErrorMsg: string = 'Invalid user or account.';

  constructor(
    public router: Router,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    private cookieService: CookieService,
    private cdref: ChangeDetectorRef,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private seoService: SeoService
  ) {
    const hostname = window.location.hostname;
    this.allinoneService.currentDomain = hostname;
    // console.warn(this.allinoneService.currentDomain);


    if (this.allinoneService.currentDomain == 'www.qr360.org') {
      this.allinoneService.isQrModule = true;
      this.allinoneService.appname = environment.qrappname;
      this.seoService.updateTitle('QR 360');
    } else if (this.allinoneService.currentDomain == 'teams.connectportal.cloud') {
      // this.allinoneService.appname = environment.neoteamsappname;
      this.seoService.updateTitle('Neo Teams');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.startsWith("/cs365")) {
            this.allinoneService.isCs365View = true;
            console.log('isCS365')
          }
        }
      })
    }
    else if (this.allinoneService.currentDomain == 'member.connectportal.cloud') {
      this.allinoneService.isMemberModule = true;
      this.seoService.updateTitle('Member Portal');
    }
    else {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.startsWith('/transportation')) {
            this.allinoneService.isTransportionView = true;
            this.allinoneService.isAttendanceView = false;
            this.seoService.updateTitle('Transportation');
          } else if (
            event.url.startsWith('/attendance') &&
            !event.url.startsWith('/attendanceqr')
          ) {
            this.allinoneService.isTransportionView = false;
            this.allinoneService.isAttendanceView = true;
            this.seoService.updateTitle('A365');
          } else if (event.url.startsWith("/cs365")) {
            this.allinoneService.isCs365View = true;
            console.log('isCS365')
          }
          else {
            this.seoService.updateTitle('Connect Portal');
            this.allinoneService.isTransportionView = false;
            this.allinoneService.isAttendanceView = false;
          }
        }
      });
    }

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this.allinoneService.isonline = true;
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.allinoneService.isonline = false;
      })
    );

    var checkcrmmodule = window.location.pathname.includes('org/crm');
    if (checkcrmmodule) {
      this.allinoneService.isCRMServiceView = true;
    }

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    }

    let queryParams = new URLSearchParams(window.location.search);
    let mobileview = queryParams.get('iswebview') || '';
    if (mobileview == 'true') {
      this.checkservice = true;
      //service web view
      this.servicewebinit();
    } else {
      if (this.allinoneService.isMemberModule && this.allinoneService.getUserId()) {
        this.addMemberToDefault('75617739');
      }
      this.init();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  init() {
    let queryParams = new URLSearchParams(window.location.search);
    let mobileview = queryParams.get('iswebview') || '';
    if (mobileview == 'true') {
      this.checkservice = true;
    }
    // let appid = 'kunyek';
    let appid = queryParams.get('appid') || 'kunyek';
    let userid = queryParams.get('userid') || '';
    let atoken = queryParams.get('atoken') || '';
    let orgidmobile = queryParams.get('orgid') || '';
    let hcmdomain = queryParams.get('hcmdomain') || '';


    if (atoken != '' && userid != '') {
      userid = this.checkUserId(userid);
      let data = {
        userid: userid,
        appid: appid,
        atoken: atoken,
        hcmdomain: hcmdomain
      };
      this.kunyekService.exchangeToken(data).subscribe(async (res: any) => {
        if (res.returncode == '300') {
          this.cookieService.deleteAll('/');
          localStorage.clear();
          sessionStorage.clear();
          // Success
          this.allinoneService.setCookie('kunyek_userid', res.userid);
          this.allinoneService.setCookie('kunyek_username', res.username);
          this.allinoneService.setCookie('atoken', res.atoken);
          this.allinoneService.setCookie('role', res.type);
          this.allinoneService.setCookie(
            'domain',
            JSON.stringify(res.domain) || 'undefined'
          );

          // console.log('1');
          // console.log(orgidmobile);


          if (appid == 'neopayroll') {

            orgidmobile = res.orgid ? res.orgid : orgidmobile;
          }

          // console.log('api res orgid');




          // if(orgidmobile) {
          //   this.allinoneService.setSelectedTranOrg(orgidmobile);
          //   this.allinoneService.selectedOrgCRM = orgidmobile;
          //   this.allinoneService.setSelectedCRM(
          //     orgidmobile
          //   )
          // }

          this.allinoneService.setCookie(
            'organization',
            JSON.stringify(res.organization) || 'undefined'
          );
          this.allinoneService.setCookie(
            'organizations',
            JSON.stringify(res.organizations) || 'undefined'
          );
          this.allinoneService.currentUserid =
            this.allinoneService.getKunyekUserId();

          //get domain
          var awaitDomain: any = '';
          if (this.allinoneService.getRole() == '100') {
            awaitDomain = await this.getAllDomain();
          } else {
            awaitDomain = await this.getDomain();
          }
          // console.log("awaitdomian" + awaitDomain)

          if (awaitDomain != 'err') {
            const savedDomain = this.allinoneService.getDomain();

            this.isChooseDomain = false;
            if (!savedDomain.domainid) {
              this.allinoneService.setCookie(
                'domain',
                awaitDomain.length > 0
                  ? JSON.stringify(awaitDomain[0])
                  : 'undefined'
              );
              if (awaitDomain.length > 0) {
                this.isChooseDomain = true;
                this.selectedDomain = awaitDomain[0];
              }
              await this.checkDomain();

            } else {
              const index = awaitDomain.findIndex(
                (x: any) => x.domainid == savedDomain.domainid
              );
              this.allinoneService.setCookie(
                'domain',
                awaitDomain.length > 0
                  ? JSON.stringify(awaitDomain[index])
                  : 'undefined'
              );
              this.selectedDomain = awaitDomain[index];
              // console.log("selected domain")
              await this.checkDomain();
            }
          }

          // console.log(orgidmobile, 'orgidmobile')

          if (orgidmobile) {
            if (this.allinoneService.appView == '001') {
              var orglist = this.allinoneService.orgsCRm.find((x: any) =>
                x.orgid == orgidmobile
              );
            } else {
              var orglist = this.allinoneService.orgs.find((x: any) =>
                x.orgid == orgidmobile
              );
            }
            // console.log("this is org list" + orglist)

            if (orglist) {
              this.allinoneService.selectedOrg = orglist.orgid;
              this.allinoneService.setSelectedOrg(orglist.orgid);
              this.allinoneService.selected_orgcrm = orglist;
              this.allinoneService.isCRMServiceView = false;
              this.allinoneService.selectedOrgCRM =
                this.allinoneService.selected_orgcrm.orgid;
              this.allinoneService.setSelectedCRM(
                this.allinoneService.selected_orgcrm.orgid
              );
            } else {
              console.log("org crms list" + this.allinoneService.orgsCRm + ", orgs " + this.allinoneService.orgs)
              if (this.allinoneService.appView == '001' && this.allinoneService.orgsCRm.length > 0) {
                this.allinoneService.selectedOrg = this.allinoneService.orgsCRm[0].orgid;
                this.allinoneService.setSelectedOrg(this.allinoneService.orgsCRm[0].orgid);
                this.allinoneService.selected_orgcrm = this.allinoneService.orgsCRm[0];
                this.allinoneService.isCRMServiceView = false;
                this.allinoneService.selectedOrgCRM =
                  this.allinoneService.selected_orgcrm.orgid;
                this.allinoneService.setSelectedCRM(
                  this.allinoneService.selected_orgcrm.orgid
                );
              } else if (this.allinoneService.appView == '002' && this.allinoneService.orgs.length > 0) {
                this.allinoneService.selectedOrg = this.allinoneService.orgs[0].orgid;
                this.allinoneService.setSelectedOrg(this.allinoneService.orgs[0].orgid);
                this.allinoneService.selected_orgcrm = this.allinoneService.orgs[0];
                this.allinoneService.isCRMServiceView = false;
                this.allinoneService.selectedOrgCRM =
                  this.allinoneService.selected_orgcrm.orgid;
                this.allinoneService.setSelectedCRM(
                  this.allinoneService.selected_orgcrm.orgid
                );
              } else if (this.allinoneService.appView == '003' && this.allinoneService.orgsCRm.length > 0) {
                console.log(this.allinoneService.orgsCRm)
              }
              else {
                console.log("empty")
                this.allinoneService.TeamsPermisson = false;
              }
            }
          }
          this.checkCurrentSession();

          this.nextroute = this.route.snapshot.queryParamMap.get('next') || '';
          // this.allinoneService.setOrgMobile(orgidmobile);
          // this.allinoneService.setWebView('true');
          if (this.nextroute != '') {
            this.router.navigateByUrl(`${this.nextroute.replace(',', '/')}`);
            // const routes = this.nextroute.split(',');
            // this.router.navigateByUrl(`${routes[0]}/${routes[1]}`);
          }
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        } else {
          this.allinoneService.logout();
        }
      });
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      if (this.allinoneService.getAtoken()) {
        this.kunyekService.checkToken().subscribe(
          async (res: any) => {
            if (res.returncode == '1' || res.returncode == '3') {
              const appid = this.allinoneService.getAppId();
              var userid = this.allinoneService.getKunyekUserId();
              const atoken = this.allinoneService.getAtoken();
              this.allinoneService.currentUserid = userid;
              if (appid == '' || userid == '' || atoken == '') {
                this.allinoneService.logout();
              } else {
                // this.allinoneService.logout();
                this.kunyekService.renewToken().subscribe(async (res: any) => {
                  if (res.returncode == '300') {
                    this.allinoneService.setCookie('atoken', res.atoken);

                    //get domain
                    var awaitDomain: any = '';
                    if (this.allinoneService.getRole() == '100') {
                      awaitDomain = await this.getAllDomain();
                    } else {
                      awaitDomain = await this.getDomain();
                    }

                    if (awaitDomain != 'err') {
                      const savedDomain = this.allinoneService.getDomain();

                      this.isChooseDomain = false;
                      if (!savedDomain.domainid) {
                        this.allinoneService.setCookie(
                          'domain',
                          awaitDomain.length > 0
                            ? JSON.stringify(awaitDomain[0])
                            : 'undefined'
                        );
                        if (awaitDomain.length > 0) {
                          this.isChooseDomain = true;
                          this.selectedDomain = awaitDomain[0];
                        }
                        await this.checkDomain();

                      } else {
                        const index = awaitDomain.findIndex(
                          (x: any) => x.domainid == savedDomain.domainid
                        );
                        this.allinoneService.setCookie(
                          'domain',
                          awaitDomain.length > 0
                            ? JSON.stringify(awaitDomain[index])
                            : 'undefined'
                        );
                        this.selectedDomain = awaitDomain[index];
                        this.checkDomain();
                      }
                    }
                    this.checkCurrentSession();
                  }
                });
              }
            } else {
              this.allinoneService.currentUserid =
                this.allinoneService.getKunyekUserId();
              //get domain
              var awaitDomain: any = '';
              if (this.allinoneService.getRole() == '100') {
                awaitDomain = await this.getAllDomain();
              } else {
                awaitDomain = await this.getDomain();
              }
              // console.log('awaitDomain', awaitDomain)
              if (awaitDomain != 'err') {
                const savedDomain = this.allinoneService.getDomain();

                this.isChooseDomain = false;
                if (!savedDomain.domainid) {
                  this.allinoneService.setCookie(
                    'domain',
                    awaitDomain.length > 0
                      ? JSON.stringify(awaitDomain[0])
                      : 'undefined'
                  );
                  if (awaitDomain.length > 0) {
                    this.isChooseDomain = true;
                    this.selectedDomain = awaitDomain[0];
                  }
                  this.checkDomain();
                } else {
                  const index = awaitDomain.findIndex(
                    (x: any) => x.domainid == savedDomain.domainid
                  );
                  this.allinoneService.setCookie(
                    'domain',
                    awaitDomain.length > 0
                      ? JSON.stringify(awaitDomain[index])
                      : 'undefined'
                  );
                  this.selectedDomain = awaitDomain[index];
                  this.checkDomain();
                }
              }

              this.checkCurrentSession();
            }
          },
          (err) => {
            this.checkedToken = false;
            this.isError = true;
          }
        );
      } else {
        this.checkedToken = true;
        this.allinoneService.navbar = false;
        this.checkCurrentSession();
      }
    }

    if (this.allinoneService.menus?.sidebar.length > 0) {
      // check attendance view or not for dynamic side bar menus
      // if(this.allinoneService.isAttendanceView){
      //   this.sidebarmenus = []
      // }
      // else{
      this.sidebarmenus = this.allinoneService.menus.sidebar;
      // }
    }

  }

  servicewebinit() {
    this.allinoneService.navbar = false;
    let queryParams = new URLSearchParams(window.location.search);
    let mobileview = queryParams.get('iswebview') || '';
    if (mobileview == 'true') {
      this.checkservice = true;
    }
    let appid = 'kunyek';
    let userid = queryParams.get('userid') || '';
    let atoken = queryParams.get('atoken') || '';
    let orgidmobile = queryParams.get('orgid') || '';
    if (atoken != '' && userid != '') {
      userid = this.checkUserId(userid);
      let data = {
        userid: userid,
        appid: appid,
        atoken: atoken,
      };
      this.kunyekService.exchangeToken(data).subscribe(async (res: any) => {
        if (res.returncode == '300') {
          this.cookieService.deleteAll('/');
          localStorage.clear();
          sessionStorage.clear();
          // Success
          this.allinoneService.setCookie('kunyek_userid', res.userid);
          this.allinoneService.setCookie('kunyek_username', res.username);
          this.allinoneService.setCookie('atoken', res.atoken);
          this.allinoneService.setCookie('role', res.type);
          const domain = {
            id: '8030221706',
            appid: 'kunyek',
            shortcode: 'demo',
            domainid: '002',
            description: 'Town Hall',
            imageurl:
              'https://kunyekbucket.s3.amazonaws.com/domain/202108222127312731.jpg?AWSAccessKeyId=AKIA6JY5OJGATYY73G53&Signature=e3ji35wanha1PClkIPu%2BLP6kt84%3D&Expires=1670524939',
            imagename: '202108222127312731.jpg',
          };
          this.allinoneService.setCookie(
            'domain',
            JSON.stringify(domain) || 'undefined'
          );
          this.allinoneService.setCookie(
            'organization',
            JSON.stringify(res.organization) || 'undefined'
          );
          this.allinoneService.setCookie(
            'organizations',
            JSON.stringify(res.organizations) || 'undefined'
          );
          this.allinoneService.currentUserid =
            this.allinoneService.getKunyekUserId();
          //get domain
          console.log('domainokoko');
          console.log(this.allinoneService.getDomain());
          const getcrmorg = await this.getOrgCRM();
          if (getcrmorg == 'success') {
            this.checkedToken = true;
          } else {
            this.isError = true;
          }
          // this.checkedToken = true;
          this.nextroute = this.route.snapshot.queryParamMap.get('next') || '';
          // this.allinoneService.setOrgMobile(orgidmobile);
          // this.allinoneService.setWebView('true');
          if (this.nextroute != '') {
            const routes = this.nextroute.split(',');
            this.router.navigateByUrl(`${routes[0]}/${routes[1]}`);
          }
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          var type = this.allinoneService.orgsCRm
            .map((x: any) => x.orgid)
            .indexOf(orgidmobile);
          this.allinoneService.crmOrgAdmin =
            this.allinoneService.orgsCRm[type].admin;
          this.allinoneService.crmBlocked =
            this.allinoneService.orgsCRm[type].crmblocked;
          this.allinoneService.selected_orgcrm =
            this.allinoneService.orgsCRm[type];
          this.allinoneService.isCRMServiceView = true;
          this.allinoneService.selectedOrgCRM =
            this.allinoneService.orgsCRm[type].orgid;
          this.allinoneService.setSelectedCRM(
            this.allinoneService.orgsCRm[type].orgid
          );
          if (this.allinoneService.orgsCRm[type].admin == 'true') {
            this.allinoneService.selectedOrg =
              this.allinoneService.orgsCRm[type].orgid;
            this.allinoneService.setSelectedOrg(
              this.allinoneService.orgsCRm[type].orgid
            );
          }
        } else {
          this.allinoneService.logout();
        }
      });
    }
    if (this.allinoneService.menus?.sidebar.length > 0) {
      this.sidebarmenus = this.allinoneService.menus.sidebar;
    }
  }

  openapp(url: string) {
    if (url != '/hxm' && url != '/transportation') {
      const uid = this.allinoneService.profile.userid;
      url =
        url +
        '/?userid=' +
        (uid.startsWith('+') ? uid.substring(1, uid.length) : uid) +
        '&username=' +
        this.allinoneService.profile.username +
        '&orgid=' +
        this.allinoneService.selectedOrg +
        // '&domain=' +
        // this.allinoneService.getDomain().shortcode +
        '&domain=' +
        this.allinoneService.selectedOrg +
        '&atoken=' +
        this.allinoneService.getAtoken();
    }
    window.open(url, '_blank');
  }
  reload() {
    window.location.reload();
  }
  login() {
    this.checkedToken = true;
    this.allinoneService.logout();
  }

  isPayroll() {
    return window.location.pathname.includes('payroll');
  }
  getDomain() {
    return new Promise((resolve) => {
      this.kunyekService.getDomain().subscribe(
        (domains: any) => {
          if (domains.returncode == '300') {
            this.domains = domains.domains;
            this.allinoneService.domains = domains.domains;
            resolve(domains.domains);
          } else {
            this.errorMsg = 'Something went wrong';
            this.isError = true;
            resolve('err');
          }
        },
        (err) => {
          this.isError = true;
          resolve('err');
        }
      );
    });
  }

  getAllDomain() {
    return new Promise((resolve) => {
      this.kunyekService.getDomains().subscribe(
        (domains: any) => {
          if (domains.returncode == '300') {
            this.domains = domains.domains;
            this.allinoneService.domains = domains.domains;
            resolve(domains.domains);
          } else {
            this.errorMsg = 'Something went wrong';
            this.isError = true;
            resolve('err');
          }
        },
        (err) => {
          this.isError = true;
          resolve('err');
        }
      );
    });
  }

  getOrgCRM() {
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
      };
      this.kunyekService.getCRMOrgs(data).subscribe(
        async (res: any) => {
          if (res.returncode == '300') {
            if (res.list.length == 0) {
              // this.orgListBlankError = true;
              // resolve('err');
              // this.orgListBlankError = false;
              resolve('success');
            } else {
              this.allinoneService.orgsCRm = res.list;

              this.allinoneService.selectedOrgCRM =
                this.allinoneService.getSelectedCRMOrg();

              if (this.allinoneService.selectedOrgCRM == '') {
                if (this.allinoneService.appView == '002') {
                  if (this.allinoneService.orgs.length > 0) {
                    this.allinoneService.selectedOrgCRM =
                      this.allinoneService.orgs[0].orgid;
                    this.allinoneService.setSelectedCRM(
                      this.allinoneService.orgs[0].orgid
                    );
                  }
                } else {
                  if (this.allinoneService.orgsCRm.length > 0) {
                    this.allinoneService.selectedOrgCRM =
                      this.allinoneService.orgsCRm[0].orgid;
                    this.allinoneService.setSelectedCRM(
                      this.allinoneService.orgsCRm[0].orgid
                    );
                  }
                }
              }

              this.allinoneService.selectedOrg =
                this.allinoneService.selectedOrgCRM;

              if (this.allinoneService.orgsCRm.length > 0) {
                await this.getPermission(
                  this.allinoneService.selectedOrgCRM ||
                  this.allinoneService.orgsCRm[0].orgid
                );
              }

              // this.orgaizationsList = res.list;
              resolve('success');
            }
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
            resolve('err');
          }
        },
        (err) => {
          resolve('err');
        }
      );
    });
  }

  chooseDomain(event: any) {
    this.selectedDomain = event;
    this.isChooseDomain = false;
    this.allinoneService.setCookie(
      'domain',
      JSON.stringify(event) || 'undefined'
    );
    this.checkDomain();
  }

  checkDomain() {
    return new Promise((resolve) => {

      // console.log("<call checkdomain>")
      this.kunyekService.checkDomain(this.selectedDomain.domainid).subscribe(
        async (res: any) => {
          if (res.returncode == '300') {
            let keys = atob(res.wak);
            [this.allinoneService.akid, this.allinoneService.skid] =
              keys.split(',');
            //orgs
            this.allinoneService.orgs = res.organizations;
            this.allinoneService.hrOrgs = res.hradmin;
            this.allinoneService.admindomain = res.domain;
            this.allinoneService.tranOrgs = res.transportation;
            this.allinoneService.postOrgs = res.postadmin;

            //contributors
            this.allinoneService.contributorOrgs = res.contributor;

            // this.allinoneService.orgs.map((org: any) => {
            //   var temp = this.noContactTracingOrgArray.filter(
            //     (x) => x == org.orgid
            //   );
            //   if (temp.length > 0) {
            //     this.allinoneService.removeContactTracing = true;
            //   }
            // });
            this.allinoneService.removeContactTracing = res.contacttracing;

            // this.allinoneService.selectedOrg =
            //   this.allinoneService.getSelectedOrg();
            this.allinoneService.selectedConOrg =
              this.allinoneService.getSelectedConOrg();
            this.allinoneService.selectedHrOrg =
              this.allinoneService.getSelectedHrOrg();
            this.allinoneService.selectedTranOrg =
              this.allinoneService.getSelectedTranOrg();

            //admins
            this.allinoneService.admins = [];
            if (res.type == 100) {
              this.allinoneService.admins.push('root');
            }
            if (res.domain.domainid) {
              this.allinoneService.admins.push('domain');
            }
            if (res.organizations.length > 0) {
              this.allinoneService.admins.push('org');
              this.allinoneService.TeamsPermisson = true;
            } else if (this.allinoneService.appView == "002" && res.organizations.length == 0) {
              this.allinoneService.TeamsPermisson = false;
              console.log("change team permissionn")
            }
            if (res.contributor.length > 0) {
              this.allinoneService.admins.push('contributor');
            }
            if (res.hradmin.length > 0) {
              if (environment.bucketname == "kunyekbucket") {
                this.isMemberAdmin = true;
              }
              if (this.allinoneService.getSelectedHrOrg() == '') {
                this.allinoneService.setSelectedHrOrg(res.hradmin[0].orgid);
              }
              this.allinoneService.admins.push('hr');
              // console.log(
              //   'hr org ---> ',
              //   this.allinoneService.getSelectedHrOrg()
              // );
              // await this.getHrPolicy(this.allinoneService.getSelectedHrOrg());
            }
            if (res.transportation.length > 0) {
              if (this.allinoneService.getSelectedTranOrg() == '') {
                this.allinoneService.setSelectedTranOrg(
                  res.transportation[0].orgid
                );
              }
              this.allinoneService.admins.push('transportation');
            }
            const getcrmorg = await this.getOrgCRM();


            if (getcrmorg == 'success') {
              // this.checkedToken = true;
              this.getPolicy('0');
              resolve("success")
            } else {
              this.isError = true;
            }

            if (this.allinoneService.isMemberModule) {

              const memberORg = this.allinoneService.orgsCRm.find((i: any) => i.orgid == '75617739');
              if (memberORg) {
                this.allinoneService.selectedOrg = memberORg.orgid;
                this.allinoneService.setSelectedOrg(memberORg.orgid);
                this.allinoneService.selected_orgcrm = memberORg;
                this.allinoneService.isCRMServiceView = false;
                this.allinoneService.selectedOrgCRM =
                  this.allinoneService.selected_orgcrm.orgid;
                this.allinoneService.setSelectedCRM(
                  this.allinoneService.selected_orgcrm.orgid
                );
              }
            }

          } else {
            this.errorMsg = 'Something went wrong';
            this.isError = true;
            resolve("error")

          }
        },
        (err) => {
          this.isError = true;
          resolve("error")

        }
      );
    })
  }

  getPolicy(type: string) {
    this.kunyekService.getPolicy(this.selectedDomain.shortcode, '0').subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // res = {
          //   "returncode": "300",
          //   "message": "Successful",
          //   "list": {
          //     "console": {
          //       "apps": [
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/learning.svg",
          //           "name": "Learn",
          //           "path": "https://www.khub.cloud",
          //           "open": "0",
          //           "syskey": "012121212",
          //           "order": "6"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/hcm.svg",
          //           "name": "Employee",
          //           "path": "/hxm",
          //           "open": "0",
          //           "syskey": "214123113",
          //           "order": "9"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/payroll.svg",
          //           "name": "Neo Payroll",
          //           "path": "https://neopayroll.mitcloud.com/redirect",
          //           "open": "0",
          //           "syskey": "09304958012384",
          //           "order": "10"
          //         }
          //       ],
          //       "sidebar": [
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/home.svg",
          //           "name": "Home",
          //           "path": "/posts",
          //           "open": "1",
          //           "syskey": "214123111",
          //           "order": "0"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/location.svg",
          //           "name": "Check In",
          //           "path": "/checkin",
          //           "open": "1",
          //           "syskey": "214123123",
          //           "order": "2"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/message.svg",
          //           "name": "Message",
          //           "path": "/message",
          //           "open": "1",
          //           "syskey": "134123121",
          //           "order": "3"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/selfservice.svg",
          //           "name": "Office",
          //           "path": "/office",
          //           "open": "1",
          //           "syskey": "013131315",
          //           "order": "4"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/crm.svg",
          //           "name": "Sales",
          //           "path": "/sales",
          //           "open": "1",
          //           "syskey": "013131314",
          //           "order": "7"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/service.svg",
          //           "name": "Service",
          //           "path": "/service",
          //           "open": "1",
          //           "syskey": "013131314",
          //           "order": "8"
          //         },
          //         {
          //           "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/admin.svg",
          //           "name": "Admin",
          //           "path": "/adminconsole",
          //           "open": "1",
          //           "syskey": "214123113",
          //           "order": "9"
          //         }
          //       ],
          //       "admins": [
          //         {
          //           "name": "Organization Admin",
          //           "role": "300",
          //           "list": [
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/members.png",
          //               "name": "Users",
          //               "path": "/users",
          //               "open": "1",
          //               "syskey": "234123123",
          //               "order": "1"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/attendance.png",
          //               "name": "Attendance",
          //               "path": "/checkinattendances",
          //               "open": "1",
          //               "syskey": "222123125",
          //               "order": "11"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/projects.png",
          //               "name": "Projects",
          //               "path": "/org/projects",
          //               "open": "1",
          //               "syskey": "234123126",
          //               "order": "7"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/jobs.png",
          //               "name": "Jobs",
          //               "path": "/org/jobs",
          //               "open": "1",
          //               "syskey": "234123127",
          //               "order": "8"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/locations.png",
          //               "name": "Locations",
          //               "path": "/locations",
          //               "open": "1",
          //               "syskey": "234123122",
          //               "order": "5"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/attendanceqr.png",
          //               "name": "Attendance QR",
          //               "path": "/attendanceqr",
          //               "open": "1",
          //               "syskey": "234123122",
          //               "order": "6"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/notification.png",
          //               "name": "Push Notification",
          //               "path": "/org/notification",
          //               "open": "1",
          //               "syskey": "234123133",
          //               "order": "3"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/events.png",
          //               "name": "Events",
          //               "path": "/events",
          //               "open": "1",
          //               "syskey": "234123124",
          //               "order": "4"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/voting.png",
          //               "name": "Voting",
          //               "path": "/admin/campaign",
          //               "open": "1",
          //               "syskey": "234121111",
          //               "order": "9"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/usermapping.png",
          //               "name": "User Mapping",
          //               "path": "/usermapping",
          //               "open": "1",
          //               "syskey": "234123173",
          //               "order": "12"
          //             }
          //           ],
          //           "open": "1",
          //           "syskey": "214123123",
          //           "order": "22"
          //         },
          //         {
          //           "name": "Domain Admin",
          //           "role": "200",
          //           "list": [
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/organizations.png",
          //               "name": "Organizations",
          //               "path": "/organizations",
          //               "open": "1",
          //               "syskey": "234123121",
          //               "order": "1"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/notification.png",
          //               "name": "Push Notification",
          //               "path": "/domain/notification",
          //               "open": "1",
          //               "syskey": "234123122",
          //               "order": "2"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/apprestriction.png",
          //               "name": "App Restriction",
          //               "path": "/apprestriction",
          //               "open": "1",
          //               "syskey": "234123125",
          //               "order": "3"
          //             }
          //           ],
          //           "open": "1",
          //           "syskey": "214123123",
          //           "order": "21"
          //         },
          //         {
          //           "name": "Root Admin",
          //           "role": "100",
          //           "list": [
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/rootadmins.png",
          //               "name": "Root Admins",
          //               "path": "/rootadmins",
          //               "open": "1",
          //               "syskey": "234123101",
          //               "order": "1"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/domains.png",
          //               "name": "Domains",
          //               "path": "/domains",
          //               "open": "1",
          //               "syskey": "234123100",
          //               "order": "2"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/counts.png",
          //               "name": "Counts",
          //               "path": "/counts",
          //               "open": "1",
          //               "syskey": "234123103",
          //               "order": "3"
          //             },
          //             {
          //               "icon": "https://kunyekbucket.s3.ap-southeast-1.amazonaws.com/policyicons/usermapping.png",
          //               "name": "User Mapping",
          //               "path": "/useridmapping",
          //               "open": "1",
          //               "syskey": "234123103",
          //               "order": "4"
          //             }
          //           ],
          //           "open": "1",
          //           "syskey": "214123123",
          //           "order": "20"
          //         }
          //       ],
          //       "hradmin": []
          //     },
          //     "mobile": [
          //       {
          //         "namemm": "မူလစာမျက်နှာ",
          //         "domain": "demo",
          //         "name": "Home",
          //         "syskey": "0700521270",
          //         "url": "assets/images/home.png",
          //         "order": "1",
          //         "pagename": "HomePage()"
          //       },
          //       {
          //         "namemm": "ဆွေးနွေးခြင်း",
          //         "domain": "demo",
          //         "name": "Message",
          //         "syskey": "8893020501",
          //         "url": "assets/images/chat.png",
          //         "order": "2",
          //         "pagename": "MessagesPage()"
          //       },
          //       {
          //         "namemm": "ဝင်/ထွက်",
          //         "domain": "demo",
          //         "name": "Check In",
          //         "syskey": "0700521270",
          //         "url": "assets/images/tabcheck.png",
          //         "order": "3",
          //         "pagename": "InOutListPage()"
          //       },
          //       {
          //         "namemm": "တာဝန်များ",
          //         "domain": "demo",
          //         "name": "Task",
          //         "syskey": "8893020501",
          //         "url": "assets/images/task.png",
          //         "order": "4",
          //         "pagename": "TasksListPage()"
          //       },
          //       {
          //         "namemm": "ပို၍",
          //         "domain": "demo",
          //         "name": "More",
          //         "syskey": "6001945065",
          //         "url": "assets/images/moreapp.png",
          //         "order": "5",
          //         "pagename": "MoreApp()"
          //       }
          //     ]
          //   },
          //   "json": [
          //     {
          //       "namemm": "မူလစာမျက်နှာ",
          //       "domain": "demo",
          //       "name": "Home",
          //       "syskey": "0700521270",
          //       "url": "assets/images/home.png",
          //       "order": "1",
          //       "pagename": "HomePage()"
          //     },
          //     {
          //       "namemm": "ဆွေးနွေးခြင်း",
          //       "domain": "demo",
          //       "name": "Message",
          //       "syskey": "8893020501",
          //       "url": "assets/images/chat.png",
          //       "order": "2",
          //       "pagename": "MessagesPage()"
          //     },
          //     {
          //       "namemm": "ဝင်/ထွက်",
          //       "domain": "demo",
          //       "name": "Check In",
          //       "syskey": "0700521270",
          //       "url": "assets/images/tabcheck.png",
          //       "order": "3",
          //       "pagename": "InOutListPage()"
          //     },
          //     {
          //       "namemm": "တာဝန်များ",
          //       "domain": "demo",
          //       "name": "Task",
          //       "syskey": "8893020501",
          //       "url": "assets/images/task.png",
          //       "order": "4",
          //       "pagename": "TasksListPage()"
          //     },
          //     {
          //       "namemm": "ပို၍",
          //       "domain": "demo",
          //       "name": "More",
          //       "syskey": "6001945065",
          //       "url": "assets/images/moreapp.png",
          //       "order": "5",
          //       "pagename": "MoreApp()"
          //     }
          //   ]
          // }
          // res = {}
          // this.allinoneService.setMenus(res.list.console);
          // this.sidebarmenus = this.allinoneService.menus.sidebar;

          if (this.allinoneService.appView == '002') {
            this.allinoneService.setMenus(res.list.sidebar);
            this.sidebarmenus = this.allinoneService.menus;
            // console.log(`Dashboard Data: ${JSON.stringify(this.sidebarmenus)}`);
            this.allinoneService.settingmenus = res.list.settings;
            this.allinoneService.appmenus = res.list.apps;
          } else if (this.allinoneService.appView == '001' && this.allinoneService.isAttendanceView) {
            this.allinoneService.setMenus(res.list.console);
            this.sidebarmenus = this.allinoneService.menus.sidebar;
            // this.allinoneService.appmenus = res.list.apps;
          }
          else {
            this.allinoneService.setMenus(res.list);
            this.sidebarmenus = this.allinoneService.menus;
            this.allinoneService.appmenus = res.apps;
          }



          this.kunyekService.getUser().subscribe(async (res: any) => {
            if (res.returncode == '300') {
              // this.allinoneService.profile = res;
              this.allinoneService.profile = Object.assign(res, {
                showuserid: this.allinoneService.getUserId(),
              });
              this.checkedToken = true;
            }
          });
        } else {
          this.errorMsg = res.message;
          this.isError = true;
        }
      },
      (err) => {
        this.isError = true;
      }
    );
  }

  checkURL() { }

  checkUserId(userid: string) {
    const checkmail = this.validateMail(userid);
    if (!checkmail && !userid.includes('@')) {
      if (userid.startsWith('09')) {
        userid = '+959' + userid.slice(2, userid.length);
      } else if (userid.startsWith('+959')) {
        userid = userid;
      } else if (userid.startsWith('959')) {
        userid = '+959' + userid.slice(3, userid.length);
      } else {
        userid = '+959' + userid;
      }
    } else {
      userid = userid.toLowerCase();
    }

    return userid;
  }

  validateMail(mail: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  sidecustomer(data: any) {
    console.log('side customer fun');
    console.log(this.allinoneService.orgsCRm);

    this.organizations = this.allinoneService.orgsCRm;
    if (data.path != '/org/service') {
      // this.customercare = false;
      // this.customercarecheck = false;
      // this.customerAry = "";
      this.customerdata = this.allinoneService.getCustomer();
      console.log('customer data');
      console.log(this.customerdata);

      if (this.customerdata && this.customerdata != '') {
        var i = this.allinoneService.getindex();

        if (i && i != '') {
          if (parseInt(i) > -1) {
            this.allinoneService.selected_orgcrm = [];
            this.allinoneService.selected_orgcrm = this.organizations[i];
            this.allinoneService.setSelectedCRM(this.organizations[i].orgid);
            this.allinoneService.selectedOrgCRM = this.organizations[i].orgid;
            this.allinoneService.crmOrgAdmin = this.organizations[i].admin;
          }
        }
        this.allinoneService.setCookie('customercrm', JSON.stringify(''));
      }
    }
  }

  goRoute(menu: any, path: string): void {
    this.router.navigate([path]);
  }

  checkCurrentSession() {
    this.allinoneService.currentUserCheckInterval = setInterval(() => {
      var uid = this.allinoneService.getKunyekUserId();
      var cuid = this.allinoneService.currentUserid;
      if (
        uid != cuid &&
        !this.allinoneService.currentUserChanged &&
        ((uid != '' && this.allinoneService.getAtoken() != '') || uid == '')
      ) {
        this.allinoneService.currentUserChanged = true;
        if (cuid != '') {
          const dialog = this.dialog.open(AlertBoxComponent, {
            width: '400px',
            panelClass: ['mat-typography'],
            closeOnNavigation: true,
            disableClose: true,
            data: {
              text: 'You were signed out of your account. Please press ‘Reload’.',
              buttonAction: () => {
                window.location.reload();
              },
              buttonName: 'Reload',
            },
          });

          dialog.afterClosed().subscribe((dialogResult: any) => { });
        } else {
          window.location.reload();
        }
      }
    }, 5000);
  }

  getPermission(orgid: any) {
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: orgid,
      };
      this.kunyekService.getPermissionCRM(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinoneService.setCookie(
              'getPermissionCRM',
              JSON.stringify(res.list) || 'undefined'
            );
            this.allinoneService.officePermissionList = res.officelist;
            this.allinoneService.requisitionList = res.requisition;
            this.allinoneService.officePolicies = res.office;
            this.allinoneService.hrPolicies = res.hradmin;
            resolve('success');
          } else {
            // this.messageService.openSnackBar(res.status, 'warn');
            resolve('err');
          }
        },
        (err) => {
          resolve('err');
        }
      );
    });
  }

  getHrPolicy(orgid: any) {
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: orgid,
      };
      this.kunyekService.getPermissionCRM(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinoneService.hrPolicies = res.hradmin;
            this.allinoneService.officePolicies = res.office;
            resolve('success');
          } else {
            // this.messageService.openSnackBar(res.status, 'warn');
            resolve('err');
          }
        },
        (err) => {
          resolve('err');
        }
      );
    });
  }

  addMemberToDefault(orgid: string) {
    const data = {
      domainid: '002',
      orgid: orgid,
      name: '',
      adduser: this.allinoneService.getUserId(),
      olduser: '',
      type: '',
      typeid: '',
      contributor: '',
      postadmin: '',
      employeeid: '',
      post: '',
      department: '',
      division: '',
      teamid: '',
      section: '',
      costcenter: '',
      subdivision: '',
      remark: '',
      role: '',
      hradmin: '',
      transportation: '',
      roleid: '',
      rolelist: '',
      profiletype: '',
      rank: '',
      entity: '',
      exttext1: '',
      exttext2: '',
      exttext3: '',
      exttext4: '',
      exttext5: '',
      filename: '',
      joineddate: '',
      effectivedate: '',
      dateofbirth: '',
      gender: '',
      employmenttype: '',
      transactiontype: '',
      attendance: '',
      activity: '',
      imagename: '',
      positionname: '',
      requisitionid: '',
      roletype: '',
      nrcregioncode: '',
      nrctownshipcode: '',
      nrctype: '',
      nrcnumber: '',
      martialstatus: '',
      paylevel: '',
      mobile: '',
    }
    this.kunyekService.addDefulatOrgMember(data).subscribe((res) => {
      console.log(res);
    });
  }

}
