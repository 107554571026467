import {
  Component,
  HostListener,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { ActivatedRoute } from '@angular/router';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective,
} from 'ngx-perfect-scrollbar';
import { NgxImageCompressService } from 'ngx-image-compress';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { LikeTagListComponent } from '../like-tag-list/like-tag-list.component';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
// import { UserProfileComponent } from '../user-profile/user-profile.component';
import { DatePipe, formatDate } from '@angular/common';
import { ReportsComponent } from '../reports/reports.component';
import { BlockUserComponent } from '../block-user/block-user.component';
import { CreatePostComponent } from '../create-post/create-post.component';
import { SharePostComponent } from '../share-post/share-post.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs/internal/Subscription';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { SocialPostsImageDetailComponent } from '../social-posts-image-detail/social-posts-image-detail.component';

declare var $: any;

@Component({
  selector: 'app-social-posts-detail',
  templateUrl: './social-posts-detail.component.html',
  styleUrls: ['./social-posts-detail.component.scss'],
})
export class SocialPostsDetailComponent implements OnInit {
  // @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  @ViewChild(QuillEditorComponent, { static: false })
  editor!: QuillEditorComponent;

  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  @ViewChild('rightSideScroll', { static: false })
  rightSideScrollbar!: PerfectScrollbarDirective;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  quill: any;
  post: any;
  comments: any[] = [];
  orgList: any[] = [];
  groupList: any[] = [];

  userid: string = '';
  username: string = '';
  profileimg: string = '';
  domaindesc: string = '';
  appname: string = this.allinoneService.appname;
  domain: any;
  domainAdmin: boolean = false;
  postDomainAdmin: boolean = false;
  orgAdmin: boolean = false;
  postAdmin: boolean = false;

  gettingComments: boolean = false;
  gettingMoreComments: boolean = false;
  isMobileView: boolean = false;
  gettingMentionList: boolean = false;

  nextBtn: boolean = false;
  previousBtn: boolean = false;
  bothDisable: boolean = false;
  deletingComment: boolean = false;
  block: boolean = false;

  mobileViewWidth: any = 768;
  imageIndex: any = 0;
  imagename: string = '';
  postid: any = '';

  commentSyskey: string = '';

  editText: any;
  editLoading: boolean = false;

  returnFormat = {
    block: false,
    delete: false,
  };

  getContactSubscription!: Subscription;

  mentionableList: any[] = [];

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    // 'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'mention',
  ];
  modules = {
    keyboard: {
      bindings: {
        ['list autofill']: {
          prefix: /^\s*?(\d+\.|\*|\[ ?\]|\[x\])$/,
        },
      },
    },
    mention: {
      onOpen: () => {
        // this.isMentioning = true;
      },
      onClose: () => {
        setTimeout(() => {
          // this.isMentioning = false;
        }, 300);
      },
      // defaultMenuOrientation: 'top',
      renderItem: (item: any) => {
        const img = item.img ? item.img : '../../../assets/images/profile.png';
        const check = item.username == '';
        if (check) {
          var rf =
            `
          <div class = "m-row">
            <div>
                <img class="m-profile-image"
                    src="` +
            img +
            `">
            </div>
            <div class="m-rightside">
               ` +
            item.userid +
            `
            </div>
          </div>
        `;
        } else {
          var rf =
            `
                <div class = "m-row">
                <div>
                    <img class="m-profile-image"
                        src="` +
            img +
            `">
                </div>
                <div class="m-rightside">
                    <div class="m-name">` +
            item.username +
            `</div>
                    <div class="m-id">` +
            item.userid +
            `</div>
                </div>
                </div>
              `;
        }
        return rf;
      },
      renderLoading: () => {
        const x = `
        
          <i class = "fa fa-spinner fa-spin">
        
    `;
        return x;
      },
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      onSelect: (item: any, insertItem: any) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
        console.log(item);
      },
      source: async (searchTerm: any, renderList: any) => {
        // const values = this.mentionableList;

        // Get List
        var values: any[] = [];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          values = this.mentionableList;
          var matches: any[] = [];
          values.forEach((entry) => {
            if (
              entry.username.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1 ||
              entry.userid.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1
            ) {
              matches.push(entry);
            }
          });
          if (values.length == 0 || matches.length == 0) {
            var temp = await this.getMentionList(searchTerm.toLowerCase());
            values = this.mentionableList;
            values.forEach((entry) => {
              if (
                entry.username
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) !== -1 ||
                entry.userid.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                  -1
              ) {
                matches.push(entry);
              }
            });
          }
          renderList(matches, searchTerm);
        }
      },
    },
    toolbar: [],
  };

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public imageCompress: NgxImageCompressService,
    private http: HttpClient,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<SocialPostsDetailComponent>,
    public datepipe: DatePipe,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orgList = data.orgList;
    this.dialogRef.disableClose = true;
    this.userid = this.allinoneService.getKunyekUserId();
    this.username = this.allinoneService.getKunyekUserName();
    this.profileimg = this.allinoneService.getProfileImage();
    this.domainAdmin = this.allinoneService.isAdminOf('domain');
    this.domain = this.allinoneService.getDomain();
  }

  ngOnDestroy(): void {
    // this.allinoneService.openedPost = '';
  }

  ngOnInit(): void {
    this.postid = this.route.snapshot.queryParams.id;
    console.log('this is ah thoon' + this.allinoneService.getKunyekUserId());
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    this.post = this.allinoneService.openedPost;
    this.orgAdmin = this.allinoneService.checkOrgIsInAdminOrgList(
      this.post.orgid
    );
    this.postAdmin = this.allinoneService.checkOrgIsInPostAdminOrgList(
      this.post.orgid
    );
    this.postDomainAdmin = this.allinoneService.checkDomainIsInAdminDomainList(
      this.post.domain
    );
    this.domaindesc = this.allinoneService.getDomainDesc(this.post.domain);

    if (this.post == undefined || this.post == '') {
      console.log('Nothing>>>');
    } else {
      if (this.post.image.length == 0 || this.post.image.length == 1) {
        this.bothDisable = true;
      }
      console.log(this.post);
    }
    this.getGroupData();
    // this.gettingComments= true
    this.getComments();
  }

  getMentionList(search: string) {
    return new Promise((resolve) => {
      this.gettingMentionList = true;
      const data = {
        contact: [],
        count: 0,
        start: 0,
        end: 10,
        search: search,
        domain: this.domain.shortcode,
        domainid: this.domain.domainid,
        orgid: this.post.orgid,
      };
      this.getContactSubscription && this.getContactSubscription.unsubscribe();
      this.getContactSubscription = this.kunyekService
        .getContactList(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.mentionableList = res.list;
              this.mentionableList = this.mentionableList.map(
                (item, index) => ({
                  ...item,
                  id: item.userid,
                  value: item.username ? item.username : item.userid,
                  userid: item.userid,
                  username: item.username,
                  img: item.imagename,
                })
              );
              this.mentionableList.sort((a: any, b: any) => {
                let fa = a.username.toLowerCase(),
                  fb = b.username.toLowerCase();

                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              });
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.gettingMentionList = false;
            resolve(true);
          },
          (err) => {
            this.gettingMentionList = false;
            resolve(false);
          }
        );
    });
  }

  getComments() {
    this.comments = [];
    this.gettingComments = true;

    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
    };

    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.comments = res.list;
          // this.post.comment = this.comments.length;
          this.post.end = res.end;
          this.gettingComments = false;
          this.post.viewmorecomments = res.count == 10;
        } else {
          this.gettingComments = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingComments = false;
      }
    );
  }
  getMoreComments() {
    this.gettingMoreComments = true;

    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
      end: this.post.end,
    };

    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        // console.log(res);
        if (res.returncode == '300') {
          this.comments.push(...res.list);
          this.post.end = res.end;
          this.gettingMoreComments = false;
          this.post.viewmorecomments = res.count == 10;
        } else {
          this.gettingMoreComments = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingMoreComments = false;
      }
    );
  }

  getGroupData() {
    let data = {
      domain: this.domain.shortcode,
      contact: '',
      count: '',
      channelid: '',
      checkingroup: 'true',
    };
    this.kunyekService.getGroupList(data).subscribe((res) => {
      if (res.returncode == '300') {
        this.groupList = res.list;
      }
      console.log('RESULT ==>>');
      console.log(res);
    });
  }

  share() {
    const dialog = this.dialog.open(SharePostComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        groupList: JSON.parse(JSON.stringify(this.groupList)),
        postsyskey: this.postid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  editCommetConfirm(comment: any, idx: any) {
    var finLst: any[] = [];
    this.comments.map((item: any) => {
      if (item.syskey == comment.syskey) {
        item.edit = true;
        this.editText = item.comment;
        finLst = this.getEditFormat(this.editText);
        setTimeout(() => {
          this.quill = new Quill('#editor-container', {
            modules: {
              mention: {
                // defaultMenuOrientation: 'top',
                spaceAfterInsert: false,
                renderItem: (item: any) => {
                  const img = item.img
                    ? item.img
                    : '../../../assets/images/profile.png';
                  const check = item.username == '';
                  if (check) {
                    var rf =
                      `
                <div class = "m-row">
                  <div>
                      <img class="m-profile-image" 
                          src="` +
                      img +
                      `">
                  </div>
                  <div class="m-rightside">
                     ` +
                      item.userid +
                      `
                  </div>
                </div>
              `;
                  } else {
                    var rf =
                      `
                      <div class = "m-row">
                      <div>
                          <img class="m-profile-image" 
                              src="` +
                      img +
                      `">
                      </div>
                      <div class="m-rightside">
                          <div class="m-name">` +
                      item.username +
                      `</div>
                          <div class="m-id">` +
                      item.userid +
                      `</div>
                      </div>
                      </div>
                    `;
                  }
                  return rf;
                },
                renderLoading: () => {
                  const x = `
                  
                    <i class = "fa fa-spinner fa-spin">
                  
              `;
                  return x;
                },
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                onSelect: (item: any, insertItem: any) => {
                  insertItem(item);
                  // necessary because quill-mention triggers changes as 'api' instead of 'user'
                  this.quill.insertText(this.quill.getLength() - 1, '', 'user');
                  console.log(item);
                },
                source: async (searchTerm: any, renderList: any) => {
                  // const values = this.mentionableList;

                  // Get List
                  var values: any[] = [];

                  if (searchTerm.length === 0) {
                    renderList(values, searchTerm);
                  } else {
                    values = this.mentionableList;
                    var matches: any[] = [];
                    values.forEach((entry) => {
                      if (
                        entry.username
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase()) !== -1 ||
                        entry.userid
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase()) !== -1
                      ) {
                        matches.push(entry);
                      }
                    });
                    if (values.length == 0 || matches.length == 0) {
                      var temp = await this.getMentionList(
                        searchTerm.toLowerCase()
                      );
                      values = this.mentionableList;
                      values.forEach((entry) => {
                        if (
                          entry.username
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase()) !== -1 ||
                          entry.userid
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase()) !== -1
                        ) {
                          matches.push(entry);
                        }
                      });
                    }
                    renderList(matches, searchTerm);
                  }
                },
              },
              toolbar: [],
            },
            formats: this.formats,
          });
          const mentionModule = this.quill.getModule('mention');
          finLst.map((item: any) => {
            if (item.userid.toString().trim() == '') {
              this.quill.insertText(this.quill.getLength() - 1, item.text);
            } else {
              var temp = {
                denotationChar: '@',
                index: item.index,
                id: item.userid,
                value: item.text,
              };
              mentionModule.cursorPos = this.quill.getLength() - 1;
              mentionModule.insertItem(temp, true);
            }
          });
        }, 10);
      } else {
        item.edit = false;
        item.children.map((child: any) => {
          if (child.syskey == comment.syskey) {
            child.edit = true;
            this.editText = child.comment;
            finLst = this.getEditFormat(this.editText);
            setTimeout(() => {
              this.quill = new Quill('#editor-container', {
                modules: {
                  mention: {
                    // defaultMenuOrientation: 'top',
                    spaceAfterInsert: false,
                    renderItem: (item: any) => {
                      const img = item.img
                        ? item.img
                        : '../../../assets/images/profile.png';
                      const check = item.username == '';
                      if (check) {
                        var rf =
                          `
                <div class = "m-row">
                  <div>
                      <img class="m-profile-image" 
                          src="` +
                          img +
                          `">
                  </div>
                  <div class="m-rightside">
                     ` +
                          item.userid +
                          `
                  </div>
                </div>
              `;
                      } else {
                        var rf =
                          `
                      <div class = "m-row">
                      <div>
                          <img class="m-profile-image" 
                              src="` +
                          img +
                          `">
                      </div>
                      <div class="m-rightside">
                          <div class="m-name">` +
                          item.username +
                          `</div>
                          <div class="m-id">` +
                          item.userid +
                          `</div>
                      </div>
                      </div>
                    `;
                      }
                      return rf;
                    },
                    renderLoading: () => {
                      const x = `
                      
                        <i class = "fa fa-spinner fa-spin">
                      
                  `;
                      return x;
                    },
                    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    onSelect: (item: any, insertItem: any) => {
                      insertItem(item);
                      // necessary because quill-mention triggers changes as 'api' instead of 'user'
                      this.quill.insertText(
                        this.quill.getLength() - 1,
                        '',
                        'user'
                      );
                      console.log(item);
                    },
                    source: async (searchTerm: any, renderList: any) => {
                      // const values = this.mentionableList;

                      // Get List
                      var values: any[] = [];

                      if (searchTerm.length === 0) {
                        renderList(values, searchTerm);
                      } else {
                        values = this.mentionableList;
                        var matches: any[] = [];
                        values.forEach((entry) => {
                          if (
                            entry.username
                              .toLowerCase()
                              .indexOf(searchTerm.toLowerCase()) !== -1 ||
                            entry.userid
                              .toLowerCase()
                              .indexOf(searchTerm.toLowerCase()) !== -1
                          ) {
                            matches.push(entry);
                          }
                        });
                        if (values.length == 0 || matches.length == 0) {
                          var temp = await this.getMentionList(
                            searchTerm.toLowerCase()
                          );
                          values = this.mentionableList;
                          values.forEach((entry) => {
                            if (
                              entry.username
                                .toLowerCase()
                                .indexOf(searchTerm.toLowerCase()) !== -1 ||
                              entry.userid
                                .toLowerCase()
                                .indexOf(searchTerm.toLowerCase()) !== -1
                            ) {
                              matches.push(entry);
                            }
                          });
                        }
                        renderList(matches, searchTerm);
                      }
                    },
                  },
                  toolbar: [],
                },
                formats: this.formats,
              });
              const mentionModule = this.quill.getModule('mention');
              finLst.map((item: any) => {
                if (item.userid.toString().trim() == '') {
                  this.quill.insertText(this.quill.getLength() - 1, item.text);
                } else {
                  var temp = {
                    denotationChar: '@',
                    index: item.index,
                    id: item.userid,
                    value: item.text,
                  };
                  mentionModule.cursorPos = this.quill.getLength() - 1;
                  mentionModule.insertItem(temp, true);
                }
              });
            }, 10);
          } else {
            child.edit = false;
          }
        });
      }
    });
  }

  submitEditComment(comment: any) {
    if (comment.imagename == '') {
      var tempText = this.quill.getContents().ops;
      console.log(tempText);
      if (
        tempText == undefined ||
        tempText == null ||
        (tempText.length == 1 &&
          (tempText[0].insert == '\n' || tempText[0].insert == '\n\n'))
      ) {
        this.messageService.openSnackBar('Comment cannot be blank!', 'warn');
      } else {
        this.saveEditComment(comment);
      }
    } else {
      this.saveEditComment(comment);
    }
  }

  closeEditComment(index: number, replyindex?: number) {
    console.log(index, replyindex);
    if (replyindex != undefined) {
      console.log('here');
      this.comments[index].children[replyindex].edit = false;
    } else {
      console.log('a mharrrr');
      this.comments[index].edit = false;
    }
    this.editText = '';
  }

  saveEditComment(comment: any) {
    this.editLoading = true;

    var tempText = this.quill.getContents().ops;
    var mentionList: any[] = [];
    var finalText: string = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        mentionList.push(item.insert.mention.id.toString());
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        if (item.insert.mention.value) {
          temp += '(__' + item.insert.mention.value.toString() + '__)';
        } else {
          temp += '(__' + item.insert.mention.id.toString() + '__)';
        }
        finalText += temp;
      } else {
        finalText += item.insert.toString();
      }
    });

    finalText = finalText.substring(0, finalText.length - 1);
    const data = {
      domain: this.domain.shortcode,
      syskey: comment.syskey,
      comment: finalText,
    };
    this.kunyekService.editComment(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.editLoading = false;
          comment.comment = finalText;
          this.editText = '';
          comment.edit = false;
        } else {
          this.editLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.editLoading = false;
      }
    );
  }

  ResponseListForm(poll: any, syskey: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        isFromResponse: true,
        name: poll.name,
        code: poll.code,
        syskey: syskey,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
      }
    });
  }
  deleteCommetConfirm(syskey: any, commentindex: number, replyindex?: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.commentSyskey = syskey;
        this.deleteComment(commentindex, replyindex);
      }
    });
  }
  deleteComment(commentindex: number, replyindex?: number) {
    var data = {
      domain: this.domain.shortcode,
      syskey: this.commentSyskey,
    };
    this.kunyekService.deleteComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.commentSyskey = '';

          this.post.comment -= 1;
          this.messageService.openSnackBar(
            'Comment deleted successfully',
            'success'
          );
          // this.getComments();
          if (replyindex != undefined) {
            this.comments[commentindex].children.splice(replyindex, 1);
            if (this.comments[commentindex].children.length == 0) {
              this.comments[commentindex].childlist = [];
            }
          } else {
            this.comments.splice(commentindex, 1);
          }
        } else {
          this.commentSyskey = '';

          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }
  imageViewer(msg: any, i: any) {
    this.imageIndex = i;

    this.imagename = msg;
    $('#post-image-viewer').appendTo('body').modal('show');
    console.log(msg);
  }

  Next() {
    if (this.imageIndex == this.post.image.length - 1) {
      this.imageIndex = 0;
    } else {
      this.imageIndex++;
    }

    this.imagename = this.post.image[this.imageIndex];

    console.log(this.imageIndex);
    console.log(this.imagename);
  }
  Previous() {
    if (this.imageIndex == 0) {
      this.imageIndex = this.post.image.length - 1;
    } else {
      this.imageIndex--;
    }
    this.imagename = this.post.image[this.imageIndex];
    console.log(this.imageIndex);
    console.log(this.imagename);
  }
  downloadImage() {
    window.open(this.imagename, '_blank');
  }
  getImgSrc(htmlString: any) {
    var element = <HTMLImageElement>htmlString;
    return element.src;
  }
  refreshComment() {
    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
    };

    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var temp = res.list.reverse();
          this.post.comment = temp.length;
          var tempNewPosts: any[] = [];

          temp.map((tcmt: any) => {
            //new chat
            const checknewchat = this.comments.filter(
              (cmt: any) => cmt.sort == tcmt.sort
            );
            if (checknewchat.length == 0) {
              tempNewPosts.push(tcmt);
            }
          });

          tempNewPosts.map((newChat: any) => {
            this.comments.push(newChat);
          });
          this.post.commentText = '';
          this.post.selectedImage = '';
          this.post.imagetype = '';
          // this.rightSideScrollbar.scrollToBottom();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.post.saveCommentLoading = false;
      },
      (err) => {}
    );
  }

  reactComment(reacttype: any, commentsyskey: any, index: any) {
    const reactbox = document.getElementById('reactbox' + index);
    reactbox?.setAttribute('style', 'opacity: 0');
    if (this.comments[index].userreact == '') {
      var resCheck = this.comments[index].totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        this.comments[index].totalreact[resCheck].count += 1;
      } else {
        this.comments[index].totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      this.comments[index].userreact = reacttype;
    } else if (
      this.comments[index].userreact != '' &&
      this.comments[index].userreact != reacttype
    ) {
      var resCheck = this.comments[index].totalreact.findIndex(
        (x: any) => x.id == this.comments[index].userreact
      );
      if (
        resCheck != -1 &&
        this.comments[index].totalreact[resCheck].count > 1
      ) {
        this.comments[index].totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        this.comments[index].totalreact[resCheck].count == 1
      ) {
        this.comments[index].totalreact.splice(resCheck, 1);
      }
      this.comments[index].userreact = '';

      var resCheck = this.comments[index].totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        this.comments[index].totalreact[resCheck].count += 1;
      } else {
        this.comments[index].totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      this.comments[index].userreact = reacttype;
    } else {
      var resCheck = this.comments[index].totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (
        resCheck != -1 &&
        this.comments[index].totalreact[resCheck].count > 1
      ) {
        this.comments[index].totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        this.comments[index].totalreact[resCheck].count == 1
      ) {
        this.comments[index].totalreact.splice(resCheck, 1);
      }
      this.comments[index].userreact = '';
    }
    var data = {
      postsyskey: this.post.syskey,
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      date: '',
      time: '',
      commentsyskey: commentsyskey,
      type: reacttype,
      posttype: '',
      action: '',
    };
    this.kunyekService.reactComment(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.refreshComment();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
    setTimeout(() => {
      reactbox?.removeAttribute('style');
    }, 1000);
  }

  getTotalReactCount(comment: any) {
    let total = 0;
    for (var i = 0; i < comment.totalreact.length; i++) {
      total += comment.totalreact[i].count;
    }
    return total;
  }
  deletePost() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.returnFormat.block = false;
        this.returnFormat.delete = true;
        this.dialogRef.close(this.returnFormat);
      }
    });
  }

  clickcmt(event: any) {
    const userid = event.target.id;
    if (userid) {
      this.viewProfile(userid);
    }
  }
  editPost() {
    const dialog = this.dialog.open(CreatePostComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        post: this.post,
        orgList: this.orgList,
        duplicate: false,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
        var editedPost = dialogResult.data;

        this.post.admin = editedPost.admin;
        this.post.adminid = editedPost.adminid;
        this.post.adminimage = editedPost.adminimage;
        this.post.comment = editedPost.comment;
        this.post.domain = editedPost.domain;
        this.post.domainimage = editedPost.domainimage;
        this.post.domainurl = editedPost.domainurl;
        this.post.filename = editedPost.filename;
        this.post.fileurl = editedPost.fileurl;
        this.post.image = editedPost.image;
        this.post.imagename = editedPost.imagename;
        this.post.like = editedPost.like;
        this.post.note = editedPost.note;
        this.post.orgid = editedPost.orgid;
        this.post.orgimagename = editedPost.orgimagename;
        this.post.orgimageurl = editedPost.orgimageurl;
        this.post.orgname = editedPost.orgname;
        this.post.tag = editedPost.tag;
        this.post.tagclass = editedPost.tagclass;
        this.post.totallike = editedPost.totallike;
        this.post.type = editedPost.type;
        this.post.meetinglink = editedPost.meetinglink;
        this.post.startdate = editedPost.startdate;
        this.post.starttime = editedPost.starttime;
        this.post.enddate = editedPost.enddate;
        this.post.endtime = editedPost.endtime;
        this.post.orgtag = editedPost.orgtag;
        this.post.answer = editedPost.answer;
        this.post.answerresult = editedPost.answerresult;
        this.post.customvote = editedPost.customvote;
        this.post.defaultvote = editedPost.defaultvote;
        this.post.ics = editedPost.ics;
        this.post.mandatory = editedPost.mandatory;
        this.post.manual = editedPost.manual;
        this.post.maxoption = editedPost.maxoption;
        this.post.meetingdesc = editedPost.meetingdesc;
        this.post.meetingtype = editedPost.meetingtype;
        this.post.notification = editedPost.notification;
        this.post.commentsetting = editedPost.commentsetting;
        this.post.reminder = editedPost.reminder;
        this.post.showcount = editedPost.showcount;
        this.post.shownamelist = editedPost.shownamelist;
        this.post.showresult = editedPost.showresult;
        this.post.totalvote = editedPost.totalvote;
        this.post.venue = editedPost.venue;
        this.post.vote = editedPost.vote;
        this.post.voting = editedPost.voting;
        this.post.postsetting = editedPost.postsetting;
        this.post.channelid = editedPost.channelid;
        this.post.channelname = editedPost.channelname;
        this.post.videopost = editedPost.videopost;
        this.post.videourl = editedPost.videourl;
        this.post.hidevideo = true;
        setTimeout(() => {
          this.post.hidevideo = false;
        }, 300);
        for (var i = 0; i < this.post.answer.length; i++) {
          var res = this.post.answerresult.filter(
            (x: any) => x.code == this.post.answer[i].code
          );
          this.post.answer[i].userlist = res.length > 0 ? res[0].userlist : [];
          this.post.answer[i].count = res.length > 0 ? res[0].count : 0;
        }
      }
    });
  }

  duplicatePost() {
    const dialog = this.dialog.open(CreatePostComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        post: this.post,
        orgList: this.orgList,
        duplicate: true,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.returnFormat.block = true;
        this.returnFormat.delete = false;
        this.dialogRef.close(this.returnFormat);
      }
    });
  }

  blockUser() {
    const dialog = this.dialog.open(BlockUserComponent, {
      maxWidth: '400px',
      width: '250px',
      closeOnNavigation: true,
      data: {
        post: this.post,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.returnFormat.block = true;
        this.returnFormat.delete = false;
        this.dialogRef.close(this.returnFormat);
      }
    });
  }

  reportForm(syskey: any) {
    const dialog = this.dialog.open(ReportsComponent, {
      height: '415px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        syskey: syskey,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
      }
    });
  }

  tagPeople(post: any) {
    var user = post.admin ? post.admin : post.adminid;
    var taglist = post.tag;
    if (post.orgtag.length > 0) {
      return (
        '<b>' +
        user +
        '</b>' +
        '&nbsp;is&nbsp;with&nbsp;<b>' +
        post.orgtag[0].orgname +
        '</b>'
      );
    } else {
      if (taglist.length == 0 || taglist == undefined || taglist == null) {
        return '<b>' + user + '</b>';
      } else {
        if (taglist.length == 1) {
          return (
            '<b>' +
            user +
            '</b>' +
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b>'
          );
        } else if (taglist.length == 2) {
          return (
            '<b>' +
            user +
            '</b>' +
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b> and <b>' +
            this.getName(taglist[1]) +
            '</b>'
          );
        } else {
          return (
            '<b>' +
            user +
            '</b>' +
            '&nbsp;is&nbsp;with&nbsp;<b>' +
            this.getName(taglist[0]) +
            '</b>&nbsp;and&nbsp;'
          );
        }
      }
    }
  }

  getName(user: any) {
    return user.username ? user.username : user.userid;
  }

  viewOtherTag(taglist: any) {
    var temp = JSON.parse(JSON.stringify(taglist));
    console.log(temp.splice(0, 1));
  }

  trimString(text: any, length: any) {
    return text.length > length ? text.substring(0, length) + '...' : text;
  }

  like(type: any) {
    console.log('click count');
    if (type == '') {
      this.post.like = 'false';
      this.post.totallike -= 1;
      var index = this.post.totalreact
        .map((x: any) => x.id)
        .indexOf(this.post.react);
      this.post.totalreact[index].count -= 1;
      if (this.post.totalreact[index].count == 0) {
        this.post.totalreact.splice(index, 1);
      }
    } else {
      if (this.post.like == 'true') {
        this.post.like = 'true';
        var index = this.post.totalreact
          .map((x: any) => x.id)
          .indexOf(this.post.react);
        this.post.totalreact[index].count -= 1;
        if (this.post.totalreact[index].count <= 0) {
          this.post.totalreact.splice(index, 1);
        }
        this.post.react = type;
        var index = this.post.totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          this.post.totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          this.post.totalreact.push(temp);
        }
      } else {
        this.post.like = 'true';
        this.post.react = type;
        this.post.totallike += 1;
        var index = this.post.totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          this.post.totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          this.post.totalreact.push(temp);
        }
      }
    }
    this.likePost(type,"");
  }

  likePost(type: any,imagename:string) {
    const data = {
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      date: this.allinoneService.getCurrentDateToDB(),
      time: this.allinoneService.getCurrentTimeToDB(),
      postsyskey: this.post.syskey,
      commentsyskey: '',
      type: type,
      posttype: this.post.meetingtype == '' ? '1' : '',
      action: '',
      imagename:imagename
    };
    this.kunyekService.likePost(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }

  likeSingleImage(type: any,ind:number) {
    console.log('click count');
    if (type == '') {
      this.post.imagelist[ind].like = false;
      this.post.imagelist[ind].totallike -= 1;
      var index = this.post.imagelist[ind].totalreact
        .map((x: any) => x.id)
        .indexOf(this.post.imagelist[ind].react);
      this.post.imagelist[ind].totalreact[index].count -= 1;
      if (this.post.imagelist[ind].totalreact[index].count == 0) {
        this.post.imagelist[ind].totalreact.splice(index, 1);
      }
    } else {
      if (this.post.imagelist[ind].like) {
        this.post.imagelist[ind].like = true;
        var index = this.post.imagelist[ind].totalreact
          .map((x: any) => x.id)
          .indexOf(this.post.imagelist[ind].react);
        this.post.imagelist[ind].totalreact[index].count -= 1;
        if (this.post.imagelist[ind].totalreact[index].count <= 0) {
          this.post.imagelist[ind].totalreact.splice(index, 1);
        }
        this.post.imagelist[ind].react = type;
        var index = this.post.imagelist[ind].totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          this.post.imagelist[ind].totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          this.post.imagelist[ind].totalreact.push(temp);
        }
      } else {
        this.post.imagelist[ind].like = true;
        this.post.imagelist[ind].react = type;
        this.post.imagelist[ind].totallike += 1;
        var index = this.post.imagelist[ind].totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          this.post.imagelist[ind].totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          this.post.imagelist[ind].totalreact.push(temp);
        }
      }
    }
    this.likePost(type,this.post.imagelist[ind].imagename);
  }

  imageDetailView(post: any, index: number){
    if(this.post.imagelist.length == 1){
      this.imageViewer(post.imagelist[index].imageurl, index);
    }
    else{
      const dialog = this.dialog.open(SocialPostsImageDetailComponent, {
        height: '100vh',
        width: '100vw',
        closeOnNavigation: true,
        autoFocus: false,
        panelClass: ['full-screen-modal', 'mat-typography', 'image-detail'],
        data: {
          orgList: this.orgList,
          post: post,
          index: index
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        this.post.imagelist = dialogResult.imagelist;
      });
    }
  }

  onFileSelect(event: any) {
    console.log(event.target.files[0]);
    var filetypelist = ['image/jpeg', 'image/png', 'image/jpg'];
    if (filetypelist.indexOf(event.target.files[0].type) > -1) {
      this.post.imagetype = event.target.files[0].type
        .toString()
        .split('/')
        .pop();
      this.getBase64(event.target.files[0]).then((data: any) => {
        var filename = event.target.files[0].name;
        this.post.selectedImage = data;
        this.post.scImage = event.target.files[0];

        let name = filename.replace(/\s/g, '');
        name = filename.substring(0, filename.lastIndexOf('.'));
        name = filename.substring(0, filename.lastIndexOf('.'));
        let filetype = filename.split('.').pop();
        var date = new Date();
        var millisecond = date.getMilliseconds();
        var time = this.datepipe.transform(
          date,
          'yyyyMMddhhmmss' + millisecond
        );

        this.post.uploadimage = name + '_' + time + '.' + filetype;
        console.log(this.post.uploadimage);

        this.hiddenfileinput.nativeElement.value = '';
      });
    } else {
      this.hiddenfileinput.nativeElement.value = '';
      this.messageService.openSnackBar(
        'This filetype is not supported.',
        'warn'
      );
    }
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  closeImage(comment?: any) {
    if (comment) {
      comment.selectedImage = '';
    } else {
      this.post.selectedImage = '';
    }
  }

  comment() {
    this.post.commentboxopen = !this.post.commentboxopen;
  }

  submitComment(event: any) {
    // event.preventDefault();

    var tempText = this.post.commentText.ops;
    if (
      tempText == undefined ||
      tempText == null ||
      (tempText.length == 1 &&
        (tempText[0].insert == '\n' || tempText[0].insert == '\n\n') &&
        !this.post.selectedImage)
    ) {
      this.messageService.openSnackBar('Comment cannot be blank!', 'warn');
      return;
    } else {
      this.saveComment();
    }
  }

  async saveComment() {
    this.post.saveCommentLoading = true;

    var tempText = this.post.commentText.ops;
    var mentionList: any[] = [];
    var finalText: string = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        mentionList.push(item.insert.mention.id.toString());
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        if (item.insert.mention.value) {
          temp += '(__' + item.insert.mention.value.toString() + '__)';
        } else {
          temp += '(__' + item.insert.mention.id.toString() + '__)';
        }
        finalText += temp;
      } else {
        finalText += item.insert.toString();
      }
    });

    finalText = finalText.substring(0, finalText.length - 1);
    var imagename = '',
      imagedata = '';
    var data;
    if (this.post.selectedImage && this.post.scImage) {
      if (this.post.scImage.size >= 307200) {
        this.post.scImage = await this.allinoneService.compressImage(
          this.post.scImage
        );
        if (this.post.scImage == false) {
          this.messageService.openSnackBar(
            'Error while uploading your image.',
            'warn'
          );
          this.post.saveCommentLoading = false;
          return;
        }
      }
      imagename = this.post.uploadimage;
      imagedata = this.post.selectedImage;
      const result = await this.allinoneService.newfileUploadToS3(
        this.post.scImage,
        this.post.uploadimage,
        'post/' + this.post.syskey.toString() + '/'
      );
      if (result) {
        data = {
          type: '1',
          postsyskey: this.post.syskey,
          domain: this.domain.shortcode,
          date: this.allinoneService.getCurrentDateToDB(),
          time: this.allinoneService.getCurrentTimeToDB(),
          replysyskey: '',
          comment: finalText,
          mention: mentionList,
          imagename: imagename,
          imagedata: '',
          name: this.username || this.userid,
        };
        this.kunyekService.commentPost(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.comments.unshift(res.data);
              this.post.commentText = '';
              this.post.selectedImage = '';
              this.post.imagetype = '';
              this.post.comment += 1;
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.post.saveCommentLoading = false;
          },
          (err) => {
            this.post.saveCommentLoading = false;
          }
        );
      } else {
        this.messageService.openSnackBar(
          'Error while uploading image.',
          'warn'
        );
      }
    } else {
      data = {
        type: '1',
        postsyskey: this.post.syskey,
        domain: this.domain.shortcode,
        date: this.allinoneService.getCurrentDateToDB(),
        time: this.allinoneService.getCurrentTimeToDB(),
        replysyskey: '',
        comment: finalText,
        mention: mentionList,
        imagename: imagename,
        imagedata:
          imagedata != ''
            ? imagedata.toString().replace(/^data:image\/\w+;base64,/, '')
            : '',
        name: this.username || this.userid,
      };
      this.kunyekService.commentPost(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.comments.unshift(res.data);
            this.post.commentText = '';
            this.post.selectedImage = '';
            this.post.imagetype = '';
            this.post.comment += 1;
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
          this.post.saveCommentLoading = false;
        },
        (err) => {
          this.post.saveCommentLoading = false;
        }
      );
    }
  }

  async compressFile() {
    if (this.imageCompress.byteCount(this.post.selectedImage) > 1048576) {
      await this.imageCompress
        .compressFile(this.post.selectedImage, 1, 50, 50)
        .then((result: any) => {
          this.post.selectedImage = result;
          console.warn(
            'Size in bytes is now:',
            this.imageCompress.byteCount(this.post.selectedImage)
          );
          if (this.imageCompress.byteCount(result) > 1048576) {
            this.post.compressFile();
          }
        });
    }
  }

  selectUrl: any = '';

  clickDownloadPDF(data: any, file: any, index: any) {
    var fileUrl = [];
    fileUrl = data.fileurl;
    this.selectUrl = fileUrl[index];
    console.log('CLICKED DOWNLOAD>>>' + this.selectUrl.toString());

    this.downloadFile().subscribe((response: any) => {
      saveAs(response, file);
      // this.downloading = false
    }),
      (error: any) => {
        // this.downloading = false
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  downloadFile(): any {
    return this.http.get(this.selectUrl, { responseType: 'blob' });
  }

  clickMetting(url: any) {
    window.open(url, '_blank');
  }

  LikeAndTagForm(syskey: any, type: any, list: any, imagename: string = '') {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        syskey: syskey,
        type: type,
        list: list,
        imagename: imagename
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.block = true;
      }
    });
  }

  viewProfile(userid: any) {
    console.log('UID>>' + userid.toString());

    const dialog = this.dialog.open(UserProfileComponent, {
      height: '400px',
      width: '400px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        user: [],
        userid: userid,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (userid == this.post.adminid) {
          this.returnFormat.block = true;
          this.returnFormat.delete = false;
          this.dialogRef.close(this.returnFormat);
        } else {
          this.returnFormat.block = true;
        }
      }
    });
  }

  back() {
    this.dialogRef.close(this.returnFormat);
  }

  VoteListForm(syskey: any, code: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        isFromVote: true,
        syskey: syskey,
        code: code,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
      }
    });
  }

  // checkVoteLength(post:any,poll:any){
  //   return post.answerresult.filter((x:any)=>x.code==poll.code).length>0
  // }
  // getVoteCount(post:any,poll:any){
  //   var res= post.answerresult.filter((x:any)=>x.code==poll.code)
  //   if (res.length>0){
  //    return res[0].userlist.length

  //   }else{
  //     return 0
  //   }

  // }
  // getVotedList(post:any,code:any){
  //   var res= post.answerresult.filter((x:any)=>x.code==code)
  //   console.log(res);
  //   if (res.length>0){
  //     this.VoteListForm(res[0].userlist)

  //   }else{
  //     this.VoteListForm([])

  //   }

  // }
  getVotePercentage(showresult: any, answer: any, totalvote: any, code: any) {
    var percentage = 0;
    var ava = 0;
    // if(post.showcount=="false"){
    //   return ""
    // }
    if (showresult == 'false') {
      var val = answer.filter((x: any) => x.code == code);

      if (val.length > 0) {
        if (val[0].count > 0) {
          percentage = (val[0].count / totalvote) * 100;

          ava = 100 - percentage;
          return (
            'linear-gradient(90deg, #c7c5c5 ' +
            percentage +
            '%, white 0, white ' +
            ava +
            '%)'
          );
        } else {
          return 'linear-gradient(90deg, #98a9f3 0%, white 0, white 0%)';
        }
      } else {
        return 'linear-gradient(90deg, #98a9f3 0%, white 0, white 0%)';
      }
    } else {
      var currentLength = 0;
      var val = answer.filter((x: any) => x.code == code);
      if (val.length > 0) {
        currentLength = val[0].count;
      } else {
        return 'linear-gradient(90deg, #98a9f3 0%, white 0, white 0%)';
      }
      var maxlength = 0;
      var res: any = '';
      for (var i = 0; i < answer.length; i++) {
        if (answer[i].count >= maxlength) {
          maxlength = answer[i].count;
          res = answer[i];
        }
      }
      if (
        (currentLength >= maxlength && currentLength != 0) ||
        this.checkVoted(answer, code)
      ) {
        percentage = (res.count / maxlength) * 100;
        ava = 100 - percentage;
        return (
          'linear-gradient(90deg, rgb(62, 178, 255,45%) ' +
          percentage +
          '%, white 0, white ' +
          ava +
          '%)'
        );
      } else {
        return 'linear-gradient(90deg, rgb(62, 178, 255,45%) 0%, white 0, white 0%)';
      }
    }
  }
  checkVoted(answer: any, code: any) {
    var val = answer.filter((x: any) => x.code == code);
    if (val.length > 0) {
      if (val[0].userlist != undefined || val[0].userlist != null) {
        var res = val[0].userlist.filter(
          (x: any) => x.userid == this.allinoneService.getKunyekUserId()
        );
        if (res.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  checkVoteResult(answer: any, code: any) {
    var currentLength = 0;
    var val = answer.filter((x: any) => x.code == code);
    if (val.length > 0) {
      currentLength = val[0].userlist.length;
    } else {
      return '';
    }
    var maxlength = 0;
    var res: any = '';
    for (var i = 0; i < answer.length; i++) {
      if (answer[i].userlist.length >= maxlength) {
        maxlength = answer[i].userlist.length;
      }
    }
    if (currentLength >= maxlength) {
      return true;
    } else {
      return false;
    }
  }

  checkVotingEndtime(post: any) {
    if (post.meetingtype == '4') {
      if (this.checkVotingStartTime(post.startdate, post.starttime)) {
        if (post.manual == 'true') {
          var formatToShow = 'yyyy/MM/dd hh:mm:ss a';
          var currentDate = formatDate(
            Date.now(),
            formatToShow,
            'en-US'
          ).toString();
          var datetime = post.enddate;
          var yy = datetime.slice(0, 4);
          var mm = datetime.slice(4, 6);
          var dd = datetime.slice(6, 8);
          var finaDate = yy + '/' + mm + '/' + dd + ' ' + post.endtime;
          const d = new Date('2015-03-25 00:00:00');
          if (new Date(currentDate) > new Date(finaDate)) {
            return 'Final result';
          } else {
            let date1 = new Date(currentDate);
            let date2 = new Date(finaDate);
            var diffMin = this.diff_minutes(date1, date2);
            return this.transformMinute(diffMin);
            // let time = date1 - date2;  //msec
            // var hours   = Math.floor(time / 3.6e6);
            // var minutes = Math.floor((time % 3.6e6) / 6e4);
            // if(hours==0){
            // return Math.abs(parseInt(minutes.toFixed(0))).toString + ' minutes left';

            // }
            // return Math.abs(parseInt(hours.toFixed(0))).toString() + ' hours, ' +  Math.abs(parseInt(minutes.toFixed(0))).toString() + ' minutes left';
          }
        } else {
          if (post.voting == 'false') {
            return 'Final result';
          } else {
            return '';
          }
        }
      } else {
        return this.getShowVotingTime(post.startdate, post.starttime);
      }
    } else if (post.meetingtype != '4' && post.meetingtype != '') {
      var format = 'yyyy/MM/dd hh:mm:ss aa';
      var formatToShow = 'yyyy-MM-dd hh:mm:ss aa';

      var currentDate = formatDate(Date.now(), format, 'en-US').toString();
      var datetime = post.meetingtype == '3' ? post.enddate : post.startdate;
      var yy = datetime.slice(0, 4);
      var mm = datetime.slice(4, 6);
      var dd = datetime.slice(6, 8);
      var finalTime = post.meetingtype == '3' ? post.endtime : post.starttime;
      var finaDate = yy + '/' + mm + '/' + dd + ' ' + finalTime;
      const d = new Date('2015-03-25 00:00:00');
      if (
        new Date(currentDate) > new Date(finaDate) &&
        post.meetingtype != '1' &&
        post.meetingtype != '6'
      ) {
        var closeTime = formatDate(
          new Date(finaDate),
          formatToShow,
          'en-US'
        ).toString();

        return 'Final result';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  transformMinute(value: number): string {
    let hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    if (hours == 0) {
      return minutes + ' mins left';
    } else {
      return hours + ' hrs, ' + minutes + ' mins left';
    }
  }
  checkVotingColseOrOpenTime(post: any) {
    if (post.meetingtype == '4') {
      if (post.manual == 'false') {
        if (post.voting == 'true') {
          return 'Open';
        } else {
          return 'Closed ';
        }
      } else {
        const format = 'yyyy/MM/dd hh:mm:ss aa';
        const formatToShow = 'yyyy-MM-dd hh:mm:ss aa';

        var currentDate = formatDate(Date.now(), format, 'en-US').toString();
        var datetime = post.manual == 'true' ? post.enddate : post.enddate;
        var yy = datetime.slice(0, 4);
        var mm = datetime.slice(4, 6);
        var dd = datetime.slice(6, 8);
        var finaDate = yy + '/' + mm + '/' + dd + ' ' + post.endtime;
        if (new Date(currentDate) > new Date(finaDate)) {
          // var closeTime = formatDate(new Date(finaDate), formatToShow, 'en-US').toString()

          return 'Closed';
        } else {
          if (this.checkVotingStartTime(post.startdate, post.starttime)) {
            return 'Open';
          } else {
            return '';
          }
        }
      }
    } else if (post.meetingtype != '4' && post.meetingtype != '') {
      var format = 'yyyy/MM/dd hh:mm:ss aa';

      var currentDate = formatDate(Date.now(), format, 'en-US').toString();
      var datetime = post.meetingtype == '3' ? post.enddate : post.startdate;
      var yy = datetime.slice(0, 4);
      var mm = datetime.slice(4, 6);
      var dd = datetime.slice(6, 8);
      var finalTime = post.meetingtype == '3' ? post.endtime : post.starttime;
      var finaDate = yy + '/' + mm + '/' + dd + ' ' + finalTime;
      if (new Date(currentDate) > new Date(finaDate)) {
        return 'Closed';
      } else {
        return 'Open';
      }
    } else {
      return 'Closed';
    }
  }
  checkVotingStartTime(startdate: any, starttime: any) {
    var format = 'yyyy/MM/dd hh:mm:ss aa';
    var currentDate = formatDate(Date.now(), format, 'en-US').toString();
    var datetime = startdate;
    var yy = datetime.slice(0, 4);
    var mm = datetime.slice(4, 6);
    var dd = datetime.slice(6, 8);
    var startDate = yy + '/' + mm + '/' + dd + ' ' + starttime;
    // const d = new Date("2015-03-25 00:00:00");
    if (new Date(currentDate) > new Date(startDate)) {
      return true; //can vote
    } else {
      return false; //close vote
      // let date1 = new Date(currentDate).getTime();
      // let date2 = new Date(startDate).getTime();
      // let time = date1 - date2;  //msec
      // let hoursDiff = time / (3600 * 1000);
      // return Math.abs(parseInt(hoursDiff.toFixed(0))).toString()+" hours left";
    }
  }

  getShowVotingTime(startdate: any, starttime: any) {
    if (this.checkVotingStartTime(startdate, starttime)) {
      return '';
    } else {
      var datetime = startdate;
      var yy = datetime.slice(0, 4);
      var mm = datetime.slice(4, 6);
      var dd = datetime.slice(6, 8);
      var startDate = yy + '/' + mm + '/' + dd + ' ' + starttime;
      // console.log(post)
      if (startDate == '// ') {
        return '';
      }

      var formatToShow = 'yyyy-MM-dd hh:mm:ss a';

      var votingstarttime = formatDate(
        new Date(startDate),
        formatToShow,
        'en-US'
      ).toString();
      return 'Voting start at ' + votingstarttime;
    }
  }
  VoteOnChange(event: any, name: string, code: string, syskey: string) {
    console.log(event.target.checked);
    console.log('MT>>' + this.post.meetingtype);

    if (event.target.checked) {
      if (this.post.syskey == syskey) {
        if (this.checkVoteLimit(this.post.answer) >= this.post.maxoption) {
          this.messageService.openSnackBar(
            'Select only ' + this.post.maxoption,
            'success'
          );

          return;
        }

        if (!this.checkVoted(this.post.answer, code)) {
          this.post.like = 'true';
          this.post.vote = 'true';

          this.post.totallike += 1;
          this.post.totalvote += 1;

          var res = this.post.answer.map((x: any) => x.code).indexOf(code);
          // console.log(res);

          if (res != -1) {
            this.post.answer[res].userlist.push({
              userid: this.allinoneService.getKunyekUserId(),
            });
            this.post.answer[res].count += 1;

            // console.log(post)
          }
          if (this.post.meetingtype == '') {
            this.VotePost(code, syskey, '', '1');
          } else {
            this.VotePost(code, syskey, '', '');
          }
        }
      }
    } else {
      // console.log(this.checkVoteLimit(post))
      if (this.post.syskey == syskey) {
        if (this.checkVoted(this.post.answer, code)) {
          this.post.totallike -= 1;
          this.post.totalvote -= 1;

          var res = this.post.answer.map((x: any) => x.code).indexOf(code);
          // console.log(res);

          if (res != -1) {
            var userindex = this.post.answer[res].userlist
              .map((x: any) => x.userid)
              .indexOf(this.allinoneService.getKunyekUserId());
            this.post.answer[res].userlist.splice(userindex, 1);
            this.post.answer[res].count -= 1;
          }

          if (this.post.meetingtype == '') {
            this.VotePost(code, syskey, 'delete', '1');
          } else {
            this.VotePost(code, syskey, 'delete', '');
          }

          if (this.checkVoteLimit(this.post.answer) == 0) {
            this.post.vote = 'false';

            this.post.like = 'false';
          }
        }
      }
    }
  }

  VotePost(code: string, syskey: any, action: any, posttype: any) {
    const data = {
      domain: this.domain.shortcode,
      date: this.allinoneService.getCurrentDateToDB(),
      time: this.allinoneService.getCurrentTimeToDB(),
      postsyskey: syskey,
      commentsyskey: '',
      posttype: posttype,
      action: action,
      type: code,
    };
    this.kunyekService.votePost(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }

  checkVoteLimit(answer: any) {
    var votedLimit = 0;
    for (var i = 0; i < answer.length; i++) {
      for (var j = 0; j < answer[i].userlist.length; j++) {
        if (
          answer[i].userlist[j].userid == this.allinoneService.getKunyekUserId()
        ) {
          votedLimit += 1;
        }
      }
    }
    // console.log(votedLimit)

    return votedLimit;
  }
  trackByItems(index: number, item: any): number {
    return item.id;
  }

  CommentReactForm(syskey: any, commentsyskey: any, type: any, list: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        syskey: syskey,
        commentsyskey: commentsyskey,
        type: type,
        list: list,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.block = true;
      }
    });
  }

  getReply(syskey: any, index: any, newcomment?: any) {
    this.comments[index].gettingreply = true;
    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
      parentid: syskey,
    };
    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.comments[index].children = res.list;
          if (newcomment) {
            var temp = this.comments[index].children.find(
              (x: any) => x.syskey == newcomment.syskey
            );
            if (!temp) {
              this.comments[index].children.push(newcomment);
            }
          }
          this.comments[index].gettingreply = false;
          this.comments[index].end = res.end;
          this.comments[index].viewmorecomments = res.count == 10;
        } else {
          this.comments[index].gettingreply = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.comments[index].gettingreply = false;
      }
    );
  }
  getMoreReply(syskey: any, index: any) {
    this.comments[index].gettingmorereply = true;
    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
      parentid: syskey,
      end: this.comments[index].end,
    };
    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // Check the previous data
          var newComments: any[] = [];
          console.log(res.list);
          res.list.map((cmt: any) => {
            console.log(cmt);
            var check = this.comments[index].children.find(
              (x: any) => x.syskey == cmt.syskey
            );
            console.log(check);
            if (!check) {
              newComments.push(cmt);
            }
          });
          this.comments[index].end = res.end;
          this.comments[index].children.push(...newComments);
          this.comments[index].gettingmorereply = false;
          this.comments[index].end = res.end;
          this.comments[index].viewmorecomments = res.count == 10;
        } else {
          this.comments[index].gettingmorereply = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.comments[index].gettingmorereply = false;
      }
    );
  }

  submitReplyComment(event: any, index: any) {
    event.preventDefault();
    var tempText = this.comments[index].commentText.ops;
    if (
      tempText == undefined ||
      tempText == null ||
      (tempText.length == 1 &&
        (tempText[0].insert == '\n' || tempText[0].insert == '\n\n') &&
        !this.comments[index].selectedImage)
    ) {
      this.messageService.openSnackBar('Comment cannot be blank!', 'warn');
      return;
    } else {
      this.saveReplyComment(index);
    }
  }

  async saveReplyComment(index: any) {
    var parentComment = this.comments[index];
    parentComment.saveCommentLoading = true;

    var tempText = parentComment.commentText.ops;
    var mentionList: any[] = [];
    var finalText: string = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        mentionList.push(item.insert.mention.id.toString());
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        if (item.insert.mention.value) {
          temp += '(__' + item.insert.mention.value.toString() + '__)';
        } else {
          temp += '(__' + item.insert.mention.id.toString() + '__)';
        }
        finalText += temp;
      } else {
        finalText += item.insert.toString();
      }
    });

    finalText = finalText.substring(0, finalText.length - 1);
    var imagename = '',
      imagedata = '';
    var data;
    if (parentComment.selectedImage && parentComment.scImage) {
      if (parentComment.scImage.size >= 307200) {
        this.post.scImage = await this.allinoneService.compressImage(
          parentComment.scImage
        );
        if (this.post.scImage == false) {
          this.messageService.openSnackBar(
            'Error while uploading your image.',
            'warn'
          );
          this.post.saveCommentLoading = false;
          return;
        }
      }
      imagename = parentComment.uploadimage;
      imagedata = parentComment.selectedImage;
      const result = await this.allinoneService.newfileUploadToS3(
        parentComment.scImage,
        parentComment.uploadimage,
        'post/' + this.post.syskey.toString() + '/'
      );
      if (result) {
        data = {
          type: '1',
          postsyskey: this.post.syskey,
          domain: this.domain.shortcode,
          date: this.allinoneService.getCurrentDateToDB(),
          time: this.allinoneService.getCurrentTimeToDB(),
          replysyskey: this.comments[index].syskey,
          comment: finalText,
          mention: mentionList,
          imagename: imagename,
          imagedata: '',
          name: this.username || this.userid,
        };
        this.kunyekService.commentPost(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              if (
                parentComment.children.length > 0 ||
                (parentComment.children.length == 0 &&
                  parentComment.childlist == 0)
              ) {
                parentComment.children.push(res.data);
              } else {
                this.getReply(parentComment.syskey, index, res.data);
              }
              parentComment.saveCommentLoading = false;
              parentComment.commentText = '';
              parentComment.selectedImage = '';
              parentComment.imagetype = '';
              this.post.comment += 1;
              parentComment.replyboxopen = false;
            } else {
              parentComment.saveCommentLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            parentComment.saveCommentLoading = false;
          }
        );
      } else {
        this.messageService.openSnackBar(
          'Error while uploading image.',
          'warn'
        );
      }
    } else {
      data = {
        type: '1',
        postsyskey: this.post.syskey,
        domain: this.domain.shortcode,
        date: this.allinoneService.getCurrentDateToDB(),
        time: this.allinoneService.getCurrentTimeToDB(),
        replysyskey: parentComment.syskey,
        comment: finalText,
        mention: mentionList,
        imagename: imagename,
        imagedata:
          imagedata != ''
            ? imagedata.toString().replace(/^data:image\/\w+;base64,/, '')
            : '',
        name: this.username || this.userid,
      };
      this.kunyekService.commentPost(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (
              parentComment.children.length > 0 ||
              (parentComment.children.length == 0 &&
                parentComment.childlist == 0)
            ) {
              parentComment.children.push(res.data);
            } else {
              this.getReply(parentComment.syskey, index, res.data);
            }
            parentComment.saveCommentLoading = false;
            parentComment.commentText = '';
            parentComment.selectedImage = '';
            parentComment.imagetype = '';
            parentComment.replyboxopen = false;
            this.post.comment += 1;
          } else {
            parentComment.saveCommentLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          parentComment.saveCommentLoading = false;
        }
      );
    }
  }
  onReplyFileSelect(event: any, index: any) {
    var parentComment = this.comments[index];
    console.log(event.target.files[0]);
    var filetypelist = ['image/jpeg', 'image/png', 'image/jpg'];
    if (filetypelist.indexOf(event.target.files[0].type) > -1) {
      parentComment.imagetype = event.target.files[0].type
        .toString()
        .split('/')
        .pop();
      this.getBase64(event.target.files[0]).then((data: any) => {
        var filename = event.target.files[0].name;
        parentComment.selectedImage = data;
        parentComment.scImage = event.target.files[0];
        let name = filename.replace(/\s/g, '');
        name = filename.substring(0, filename.lastIndexOf('.'));
        name = filename.substring(0, filename.lastIndexOf('.'));
        let filetype = filename.split('.').pop();
        var date = new Date();
        var millisecond = date.getMilliseconds();
        var time = this.datepipe.transform(
          date,
          'yyyyMMddhhmmss' + millisecond
        );
        parentComment.uploadimage = name + '_' + time + '.' + filetype;
        console.log(parentComment.uploadimage);
        this.hiddenfileinput.nativeElement.value = '';
      });
    } else {
      this.hiddenfileinput.nativeElement.value = '';
      this.messageService.openSnackBar(
        'This filetype is not supported.',
        'warn'
      );
    }
  }
  reactReplyComment(
    reacttype: any,
    commentsyskey: any,
    index: any,
    replyindex: any
  ) {
    var replycomment = this.comments[index].children[replyindex];
    const reactbox = document.getElementById('replyreactbox' + index);
    reactbox?.setAttribute('style', 'opacity: 0');
    if (replycomment.userreact == '') {
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        replycomment.totalreact[resCheck].count += 1;
      } else {
        replycomment.totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      replycomment.userreact = reacttype;
    } else if (
      replycomment.userreact != '' &&
      replycomment.userreact != reacttype
    ) {
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == replycomment.userreact
      );
      if (resCheck != -1 && replycomment.totalreact[resCheck].count > 1) {
        replycomment.totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        replycomment.totalreact[resCheck].count == 1
      ) {
        replycomment.totalreact.splice(resCheck, 1);
      }
      replycomment.userreact = '';
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        replycomment.totalreact[resCheck].count += 1;
      } else {
        replycomment.totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      replycomment.userreact = reacttype;
    } else {
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1 && replycomment.totalreact[resCheck].count > 1) {
        replycomment.totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        replycomment.totalreact[resCheck].count == 1
      ) {
        replycomment.totalreact.splice(resCheck, 1);
      }
      replycomment.userreact = '';
    }
    var data = {
      postsyskey: this.post.syskey,
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      date: '',
      time: '',
      commentsyskey: commentsyskey,
      type: reacttype,
      posttype: '',
      action: '',
    };
    this.kunyekService.reactComment(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.refreshComment();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
    setTimeout(() => {
      reactbox?.removeAttribute('style');
    }, 1000);
  }

  getEditFormat(text: string) {
    var splitarr = text.split('@[');
    var final_arr = [];
    for (var i = 0; i < splitarr.length; i++) {
      var res = splitarr[i].match(/(\__(.*?)__\]\(__(.*?)__\))/gi);
      if (res) {
        var tem = splitarr[i].split(res[0]);
        let mention = res[0].split('__');
        var temObj = {
          userid: mention[1],
          text: mention[3],
        };
        final_arr.push(temObj);
        const tempObj2 = {
          userid: '',
          text: tem[1],
        };
        final_arr.push(tempObj2);
      } else {
        const tempObj2 = {
          userid: '',
          text: splitarr[i],
        };
        final_arr.push(tempObj2);
      }
    }
    return final_arr;
  }
}
