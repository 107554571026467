import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getVideoId'
})
export class GetVideoIdPipe implements PipeTransform {

  transform(url: any): unknown {
    var id : any= '';
    if(url.toString().startsWith('https://youtu.be/')){
      id = url.split('/').pop().replace('t=', 'start=');
      id = id.split('?')[0]
    }
    else if (url.toString().startsWith('https://www.youtube.com') || url.toString().startsWith('https://m.youtube.com')){
       id = url.split('v=')
       .pop()
       ?.split('&')[0]
       
      //  replace('&', '?')
      //  .replace('t=', 'start=');
    }
    console.log('id ====> ' + id)
    return id;
  }
}
