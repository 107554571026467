import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-request-user-list',
  templateUrl: './request-user-list.component.html',
  styleUrls: ['./request-user-list.component.scss']
})
export class RequestUserListComponent implements OnInit {

  gettingData: boolean = false;
  isLoading = true;
  public config: PerfectScrollbarConfigInterface = {};
  formatToShow = 'yyyy-MM-dd';
  format: any = "hh:mm aa";

  searchText:any = "";
  userList: any = [

  ];
  private dialogData: any;
  orgid: any = "";

  passengerList: any = [];
  dialogUserData: any = [];
  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<RequestUserListComponent>,
    private injector: Injector,
    private dialog: MatDialog
  ) {

    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.orgid = this.dialogData.orgid;
    this.dialogUserData = JSON.parse(JSON.stringify(this.dialogData.passengerList));

    console.log(this.dialogUserData);
    console.log("Init Modal");
  }

  async ngOnInit() {
    await this.getPassengerList();
    if (this.dialogUserData.length > 0 && this.userList.length > 0) {
      console.log("11111")
      console.log(this.userList);
      var checkhasRquest = false;
      for (let j = 0; j < this.dialogUserData.length; j++) {
        this.passengerList.push(this.dialogUserData[j]);
        for (let i = 0; i < this.userList.length; i++) {
          if (this.dialogUserData[j]['requestid'] == this.userList[i]['requestid']) {
            this.userList[i].check = true;
            checkhasRquest = true;
          } else {
            checkhasRquest = false;
          }
        }

        // if(!checkhasRquest){
          
        // }
      }
    }

    console.log(this.passengerList);
  }



  getPassengerList() {

    this.isLoading = true;
    this.userList = [];
    return new Promise((resolve) => {

      var data = {
        "domainid": this.allinoneService.getDomain().domainid,
        "orgid": this.orgid,
        "requesttype": "001",
        "trantype": "",
        "requeststatus": ""
      };
      this.kunyekService.getRequest(data).subscribe(
        (res: any) => {

          if (res.returncode == '300') {
            res.datalist.map((item: any) => {
              var user = "";
              var returntime = "";
              var arrivetime = "";
              if (item.trantype == '002') {
                for (let i = 0; i < item.userlist.length; i++) {
                  user = item.userlist.toString();
                }
                var atFormDate = this.allinoneService.formatDBToShowInEdit(this.allinoneService.getCurrentDateToDB(), '/');;
                var retD = new Date(atFormDate + ' ' +  item.returntime);
                var arrive = new Date(atFormDate + ' ' +  item.arrivetime);

                returntime = formatDate(retD, this.format, 'en-US').toString();
                arrivetime = formatDate(arrive, this.format, 'en-US').toString();
            
                
              }
              this.userList.push({
                "userid": item.userid,
                "username": item.username,
                // "phone": item.phone,
                "trantype": item.trantype,
                "requestid": item.requestid,
                "startdate": item.startdate,
                "enddate": item.startdate,
                "user": user,
                "weekday": item.weekday.toString(),
                "userlist": item.userlist,
                "check": false,
                "remark":item.remark,
                "toplace":item.toplace,
                "going":item.going,
                "return":item.return,
                "requestrefno":item.requestrefno,
                "returntime":returntime,
                "arrivetime":arrivetime,
                "type":item.trantype == "001" ? "Ferry" : "Daily"

              })
            });
            this.isLoading = false;
            console.log("userlist");
            console.log(this.userList)
            resolve("true")
          } else {

            this.isLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
            resolve("false")
          }
        },
        (err) => {
          resolve("false")
          this.isLoading = false;

          this.messageService.openSnackBar(err, 'warn');
        }

      );

    });



  }


  close() {
    this.dialogRef.close();
  }
  done() {
    var finalList: any = [];
    // finalList = this.selectedUserList.filter((user: { checked: boolean }) => {
    //   return user.checked == true;
    // });
    console.log(this.passengerList);
    console.log("Modal data");
    this.dialogRef.close({ data: this.passengerList });
  }

  onScrollDown() {

  }


  checkPassenger(value: any) {
    console.log(value);
    if (value.check == false) {
      console.log("11")
      this.passengerList.push(value);
      var type = this.userList.map((x: any) => x.requestid).indexOf(value.requestid);
      console.log(type);
      this.userList[type].check = true;
    } else if (value.check == true) {
      console.log("22")
      var type = this.passengerList.map((x: any) => x.requestid).indexOf(value.requestid);
      var type1 = this.userList.map((x: any) => x.requestid).indexOf(value.requestid);
      this.userList[type1].check = false;
      this.passengerList.splice(type, 1);
    }
    console.log(this.passengerList);

  }
}
