<div [ngClass]="{'container-fluid':!checktype}">
    <div [ngClass]="{'content':!checktype}">

        <div *ngIf="!checktype">
            <app-crm-breadcrumb s_breadcrumb="Service Type">
            </app-crm-breadcrumb>
        </div>
        <div *ngIf="checktype" class="d-flex justify-content-between align-items-center mb-2">
            <div class="label title" style="font-size: 18px;margin-left: 0.5rem;">Servcie Type</div>
            <div class="close-btn">
                <button mat-icon-button (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <!-- *ngIf="addPerson || editPerson" -->
        <div *ngIf="editname" class="close-save-button mb-3">
            <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!nameLoading">
                Cancel
            </button>
            <app-button-loader (click)="savename()" [button_text]="'Save'" [load_button_text]="'Saving'"
                [isLoading]="nameLoading">
            </app-button-loader>

        </div>
        <div class="row" *ngIf="editname">
            <div class="mb-3 col-md-6">
                <label for="name" class="form-label">Description</label>

                <input type="text" class="form-control" id="des" name="des" [(ngModel)]="descservice" #des="ngModel"
                    [ngClass]="{ 'is-invalid': nameFormSubmitted && des.invalid }" required>
                <div class="invalid-feedback" *ngIf="des.invalid || (des.dirty || des.touched) && nameFormSubmitted">
                    <div *ngIf="des.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <label for="userid" class="form-label">Short Code</label>
                <input type="text" class="form-control" id="userid" name="userid" [(ngModel)]="shortcode"
                    #scode="ngModel" [ngClass]="{ 'is-invalid': nameFormSubmitted && scode.invalid }" required>
                <div class="invalid-feedback"
                    *ngIf="scode.invalid || (scode.dirty || scode.touched) && nameFormSubmitted">
                    <div *ngIf="scode.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
            <div class="mb-3 col-md-6">
                <div class="d-flex">
                    <div *ngIf="notcalculateage == 'true'">
                        <input class="form-check-input me-2" type="checkbox" id="cal" name="des" checked
                            (click)="checkcalculate($event,1)">
                            <label for="cal" class="form-label me-2">Not Calculate Age</label>

                    </div>
                    <div *ngIf="notcalculateage == 'false'">
                        <input type="checkbox" class="form-check-input me-2" id="cal" name="des"
                            (click)="checkcalculate($event,2)">
                            <label for="cal" class="form-label me-2">Not Calculate Age</label>

                    </div>
                </div>


            </div>
        </div>


        <!-- <div class="d-flex justify-content-between align-items-center mb-2 " [ngClass]="{'paddingleft':checkModeal}">
            <div class="label title" style="font-size: 17px;" *ngIf="checkModeal">Product</div>
            <div class="close-btn" *ngIf="checkModeal">
                <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
            </div>
        </div> -->
        <!-- <div class="mb-2" *ngIf="!addtype" style="padding-left: 10px;">
        <button class="btn btn-custom" (click)="new()">
            <i class="fa fa-plus me-1"></i> New Type
        </button>
    </div> -->
        <!-- <div class="mb-2 d-flex" *ngIf="addtype" style="padding-left: 10px;">
            <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
        </button>
            <app-button-loader (click)="submitService()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
            </app-button-loader>
        </div> -->



        <!-- <div class="row" *ngIf="addtype" style="padding-left: 10px;">
            <div class="mb-3  col-md-12">
                <label for="ref" class="form-label">Service Type</label>
                <input type="text" class="form-control" id="type" name="type" [(ngModel)]="servicetype" placeholder="Enter Service Type" #type="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && type.invalid }" required>

                <div class="invalid-feedback" *ngIf="type.invalid || (type.dirty || type.touched) && serviceFormSubmitted">
                    <div *ngIf="type.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>

            </div>

        </div> -->

        <!-- matSort (matSortChange)="sortData($event)" -->
        <perfect-scrollbar *ngIf="checktype && !editname" class="event-container" [config]="config"
            [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
            <div class="table-responsive" *ngIf="!addtype">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <!--  mat-sort-header="type" -->

                            <td scope="col" class="col-lg-3 col-md-3">Description</td>
                            <td scope="col" class="col-lg-3 col-md-3">Short Code</td>
                            <td scope="col" class="col-lg-3 col-md-3">Ticket</td>
                            <td scope="col" class="col-lg-1 col-md-1 ">Active</td>

                        </tr>
                    </thead>
                    <tbody *ngIf="gettingData">
                        <tr>
                            <td colspan="4" class="nodata">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="gettingData== false">
                        <tr *ngIf="servicetypeonOff.length == 0">
                            <td colspan="4" class="nodata" *ngIf="!serviceview">Empty</td>
                            <td style="color: red;font-size: 14px;" colspan="4" class="nodata" *ngIf="serviceview"><i
                                    class="fa fa-exclamation-triangle" aria-hidden="true"
                                    style="margin-right: 7px;"></i>You are not authorised to view.</td>
                        </tr>
                        <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
                        <tr *ngFor="let item of servicetypeonOff;let i = index;" style="cursor: pointer;">

                            <td class="td-data" (click)="goNameEdit(item,i)">
                                <!-- <span [title]="item.name">{{item.name}}
                                    <i class="fa fa-pencil"  aria-hidden="true"
                                        style="font-size: 12px;margin-top: 5px;margin-left: 4px;"></i>
                                </span> -->
                                <div class="d-flex">

                                    <div>{{item.name}}
                                    </div>
                                    <span class="title span-edit" (click)="goNameEdit(item,i)">
                                        <mat-icon class="iconedit">edit</mat-icon>
                                    </span>
                                </div>

                            </td>
                            <td class="td-data " (click)="goNameEdit(item,i)">
                                <span [title]="item.name">{{item.shortcode}}</span>
                            </td>


                            <td class="td-data " (click)="goNameEdit(item,i)">
                                <span [title]="item.ticketcount">{{item.ticketcount}}</span>
                            </td>
                            <td class="d-flex justify-content-center">
                                <div style="padding:10px;">
                                    <div class="spinner-borders" role="status"
                                        *ngIf="statusLoading  && indexs == item.code ">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>

                                <button class="btn btn-primary" (click)="onoffstatus(item ,false)"
                                    [disabled]="item.name == 'New'|| item.name == 'Closed' || item.ticketcount > 0"
                                    *ngIf="item.serviceon && indexs != item.code">
                                    <i class="fa fa-toggle-on"></i>
                                </button>
                                <button class="btn btn-primary" (click)="onoffstatus(item ,true)"
                                    [disabled]="item.name == 'New'|| item.name == 'Closed'"
                                    *ngIf="!item.serviceon && indexs != item.code ">
                                    <i class="fa fa-toggle-off"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </perfect-scrollbar>
        <div class="table-responsive" *ngIf="!addtype && !checktype && !editname">
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr>
                        <!--  mat-sort-header="type" -->

                        <td scope="col" class="col-lg-3 col-md-3">Description</td>
                        <td scope="col" class="col-lg-3 col-md-3">Short Code</td>
                        <td scope="col" class="col-lg-3 col-md-3">Ticket </td>
                        <td scope="col" class="col-lg-1 col-md-1 ">Active</td>

                    </tr>
                </thead>
                <tbody *ngIf="gettingData">
                    <tr>
                        <td colspan="4" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="gettingData== false">
                    <tr *ngIf="servicetypeonOff.length == 0">
                        <td colspan="4" class="nodata" *ngIf="!serviceview">Empty</td>
                        <td style="color: red;font-size: 14px;" colspan="4" class="nodata" *ngIf="serviceview"><i
                                class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 7px;"></i>You
                            are not authorised to view.</td>
                    </tr>
                    <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
                    <tr *ngFor="let item of servicetypeonOff;let i = index;" style="cursor: pointer;">


                        <td class="td-data " (click)="goNameEdit(item,i)">
                            <div class="d-flex">

                                <div>{{item.name}}
                                </div>
                                <span class="title span-edit" (click)="goNameEdit(item,i)">
                                    <mat-icon class="iconedit">edit</mat-icon>
                                </span>
                            </div>
                            <!-- <span [title]="item.name">{{item.name}}
                                <i class="fa fa-pencil" (click)="goNameEdit(item,i)" aria-hidden="true"
                                    style="font-size: 12px;margin-top: 5px;margin-left: 4px;"></i>
                            </span> -->
                        </td>
                        <td class="td-data " (click)="goNameEdit(item,i)">
                            <span [title]="item.name">{{item.shortcode}}</span>
                        </td>
                        <!-- <td *ngIf="indexss != i || indexss == ''">
                            <div class="d-flex">
                                {{item.name}}
                                <i class="fa fa-pencil" (click)="goNameEdit(item,i)" aria-hidden="true" style="font-size: 12px;margin-top: 5px;margin-left: 4px;"></i>
                            </div>
                        </td>
                        <td *ngIf="indexss == i && indexss != ''">
                            <div class="d-flex">
                                <div>
                                    <input type="text" class="form-control" id="des" name="des" [(ngModel)]="desname" (keyup.enter)="updateName()">
                                </div>
                                <div style="display: flex; align-items: center;" *ngIf="!editingname">
                                    <mat-icon class="close-domain" title="Cancel" (click)="cancelname()">close
                                    </mat-icon>
                                    <mat-icon class="close-domain" title="Save" (click)="savename()">check
                                    </mat-icon>
                                </div>
                                <div style="display: flex; align-items: center; margin-left: 10px;" *ngIf="editingname">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </div>
                            </div>
                        </td> -->
                        <td class="td-data " (click)="goNameEdit(item,i)">
                            <span [title]="item.ticketcount">{{item.ticketcount}}</span>
                        </td>
                        <td class="d-flex">
                            <!--  -->
                            <!-- -->
                            <!--  -->
                            <div style="padding:10px;" *ngIf="statusLoading  && indexs == item.code ">
                                <div class="spinner-borders" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            <button class="btn btn-primary" (click)="onoffstatus(item ,false)"
                                [disabled]="item.name == 'New'|| item.name == 'Closed' || item.ticketcount > 0"
                                *ngIf="item.serviceon && indexs != item.code">
                                <i class="fa fa-toggle-on"></i>
                            </button>
                            <button class="btn btn-primary" (click)="onoffstatus(item ,true)"
                                [disabled]="item.name == 'New'|| item.name == 'Closed'"
                                *ngIf="!item.serviceon && indexs != item.code ">
                                <i class="fa fa-toggle-off"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>

<!-- <div *ngIf="!editGroupName" style="display: flex;">
    <div class="chatname">
        {{openedChat.channelname}}
    </div>
    <div *ngIf="!isInfo && (openedChat.type == '2' && (allinoneService.isAdmin(user.userid, openedChat.adminlist) || user.userid == openedChat.admin))">
        <mat-icon class="edit-icon" (click)="groupNameEdit()" title="Edit Group Name">edit
        </mat-icon>
    </div>
</div>
<div *ngIf="editGroupName" style="display: flex;">
    <div>
        <input type="text" class="form-control" required [(ngModel)]="editName" id="channelname" name="channelname" (keyup.enter)="updateGroupName()" #cname="ngModel" [ngClass]="{ 'is-invalid': cname.invalid }" [disabled]="editingGroupName">
    </div>
    <div style="display: flex; align-items: center;" *ngIf="!editingGroupName">
        <mat-icon class="close-domain" (click)="cancelGroupNameEdit()" title="Cancel">close
        </mat-icon>
        <mat-icon *ngIf="editName.trim() != ''" class="close-domain" (click)="updateGroupName()" title="Save">
            check</mat-icon>
    </div>
    <div style="display: flex; align-items: center; margin-left: 10px;" *ngIf="editingGroupName">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>
</div> -->