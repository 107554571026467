import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-member-requestlist',
  templateUrl: './member_requestlist.component.html',
  styleUrls: ['./member_requestlist.component.scss']
})
export class MemberRequestlistComponent implements OnInit {

  isOpenForm: boolean = false;
  isEditing: boolean = false;

  memberList: any[] = [];
  searchName: string = '';

  currentMember: any

  action: string = '';

  subscription!: Subscription;
  gettingMemberList: boolean = true;

  formStatus = [
    { name: "All", value: "" },
    { name: "Submitted", value: "006" },
    { name: "Approved By Step 1", value: "001" },
    { name: "Approved By Step 2", value: "005" },
    { name: "Approved", value: "002" },
  ];

  selecteFormstatus = "006";
  selecteFormstatusName = "Submitted";

  constructor(
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.getMemberList();
  }

  newMember() {
    this.isOpenForm = true;
    this.action = 'Add User'
  }

  closeMemberForm() {
    this.isOpenForm = false;
    this.searchByName()
  }

  getMemberList() {
    const data = {
      searchName:this.searchName,
      formstatus: this.selecteFormstatus,
      membertypeid: 'ASDLFKJ'
    }
    this.subscription = this.kunyekService.getKMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.memberList = res.datalist
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );
  }

  searchByName() {
    this.gettingMemberList = true
    this.getMemberList();
  }

  editMember(member: any) {
    this.currentMember = member
    this.action = 'Edit'
    this.isOpenForm = true
    console.log(member)
  }

  viewMember(member: any) {
    this.currentMember = member
    this.action = 'View'
    this.isOpenForm = true
  }

  deleteMember(id: any) {
    this.kunyekService.deleteKMember({ userid: id }).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res)
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );
  }

  closeAddForm() {
    this.action = ''
  }

  handleReresh() {
    this.memberList = [];
    this.getMemberList();
  }


  handleFormSelected(status: any) {
    this.selecteFormstatus = status.value;
    this.selecteFormstatusName = status.name;
    this.handleReresh();
  }

}
