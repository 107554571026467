import { CrmProductModuleComponent } from './../crm-product-module/crm-product-module.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CrmPersonInChargeComponent } from '../crm-person-in-charge/crm-person-in-charge.component';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { CustomerContactsComponent } from '../customer-contacts/customer-contacts.component';
import { DatePipe } from '@angular/common';
import { Sort } from '@angular/material/sort';
@Component({
  selector: 'app-crm-product',
  templateUrl: './crm-product.component.html',
  styleUrls: ['./crm-product.component.css']
})
export class CrmProductComponent implements OnInit {

  gettingData: boolean = false;

  p = 1;
  saveLoading: boolean = false;

  serviceFormSubmitted: boolean = false;

  addProduct: boolean = false;
  editProductcheck: boolean = false;
  customFields: any = [];
  maxOptionCount: any[] = [];
  customFieldsOrigin: any = [];
  cusFieldCount: string = "";
  deleteList: any = [];
  checkEdit: boolean = false;
  productListdata: any[] = [];
  orgid: any = '';
  checkModeal: boolean = false;
  searchtext: string = '';
  isFocus: boolean = false;
  statusFilter: any = '';
  serviceFilter: any = '';
  currentPage = 1;
  searchNonea: any = "";
  searchNones: any = "";
  itemPerPage: number = 100;
  sortby: any = "";
  searchtexts: string = "";
  searchbool: boolean = false;
  searchtextpipe: any = "";
  text1: any = "";
  text2: any = "";

  private dialogRef: any;
  private dialogData: any;
  routevalue: any = '';
  productid: any = '';
  AdminAddorNot: boolean = false;
  productadmin: boolean = false;
  userid: any = '';
  id: any = '';
  lastPage: any = ""
  nextDisabled: boolean = false;
  total: any = 0;
  modulelist: any = [];
  moduledeletelist: any = []

  isExecl: boolean = false;

  saveexportingloading: boolean = false;
  itempercount: any = ''
  piclist: any = [
    { 'picid': '001', 'picname': 'Nyi Ye Lwin' },
    { 'picid': '001', 'picname': 'Nyi Ye Lwin' }
  ]


  productForm = {
    "sku": "",
    "name": "",
    "pic": ''
  }
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 500,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];
  organizations: any = [];
  permission: any = [];
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  view: boolean = false;
  create: boolean = false;
  edit: boolean = false;
  delete: boolean = false;
  isdomainAdmin: boolean = false;
  warranties: any = []
  selectWarranty = '';


  // var dia = dialogRef: MatDialogRef<CrmProductComponent>;

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public datepipe: DatePipe,
    // @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.isdomainAdmin = (this.allinoneService.crmOrgAdmin === 'true');
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.routevalue = this.route.snapshot.queryParamMap.get('q') || "";
    if (this.routevalue) {
      this.statusFilter = this.routevalue
    }
    // this.customFieldsOrigin = data.picList;
    if (this.dialogData) {
      console.log(this.dialogData);
      this.checkModeal = this.dialogData.check;
      this.orgid = this.dialogData.orgid;
      console.log(this.dialogData.picList);
      console.log('Pass Candi>>');
      console.log(this.customFieldsOrigin);
      this.cusFieldCount = "";
      this.maxOptionCount = [];
      this.customFields = JSON.parse(JSON.stringify(this.customFieldsOrigin));
      // this.deleteList = JSON.parse(JSON.stringify(data.deletelist));
      console.log('--AA--');
      console.log(this.customFields);

    } else {
      console.log("nyi");
      if (this.allinoneService.crmBlocked == 'true') {
        this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
      } else {
        if (this.allinoneService.crmOrgAdmin != 'true') {
          console.log("truetrue>>>>>>>>..")
          console.log(this.create)
          console.log(this.edit)
          console.log(this.delete)
        } else {
          this.permission = this.allinoneService.getCRMpermission();
          console.log("1", this.permission)
          if (this.isdomainAdmin == false) {


            if (this.permission.length > 0) {
              console.log("2")
              var type = this.permission.map((x: any) => x).indexOf('0010');
              console.log(this.permission)
              console.log(".....LL>>>>")
              console.log(type)
              if (type == -1) {
                this.router.navigateByUrl('sales')
              }

              var view = this.permission.map((x: any) => x).indexOf('00101');
              var create = this.permission.map((x: any) => x).indexOf('00102');
              var edit = this.permission.map((x: any) => x).indexOf('00103');
              var delet = this.permission.map((x: any) => x).indexOf('00104');

              console.log(view)
              console.log(create)
              console.log(edit)
              console.log(delet)

              if (view == -1) {
                this.view = true;
              }
              if (create == -1) {
                this.create = true;
              }

              if (edit == -1) {
                this.edit = true;
              }

              if (delet == -1) {
                this.delete = true;
                console.log("2")
              }

            }
          }

        }
      }
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid;
    }

    // if (this.customFieldsOrigin.length == 0) {
    //   console.log("aa");
    // } else {
    //   this.checkEdit = true;
    //   for (let i = 0; i < this.customFields.length; i++) {
    //     this.maxOptionCount.push(i + 1);
    //   }
    // }


  }

  ngOnInit(): void {

    // if (this.view == true) {
    // } else {
    this.getProduct();
    this.getPIC();
    // }
    this.getWarranty()
  }

  submitProduct() {
    this.serviceFormSubmitted = true;



    console.log(this.productForm.pic);

    if (this.productForm.sku.length > 20) {
      return;
    }

    if (this.productForm.name == '' && this.productForm.sku == '') {
      return;
    }
    this.saveLoading = true;


    var sendPic: any = [
      {
        "autoid": "",
        "picid": this.productForm.pic,
      }

    ];

    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "name": this.productForm.name,
      "skucode": this.productForm.sku,
      "piclist": sendPic,
      "modulelist": this.modulelist,
      "deletemodule": this.moduledeletelist,
      "warrantyid": this.selectWarranty

    }
    console.log(data)

    this.kunyekService.addProductCrm(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.addProduct = false;
          this.editProductcheck = false;
          this.total = 0;
          this.productListdata = [];
          this.sortby = '';
          this.nextDisabled = false;
          this.currentPage = 1;
          this.lastPage = '';
          this.getProduct();
          this.serviceFormSubmitted = false;

        } else {
          this.serviceFormSubmitted = false;
          this.saveLoading = false;
          this.addProduct = false;
          this.editProductcheck = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.serviceFormSubmitted = false;
        this.addProduct = false;
        this.editProductcheck = false;
        this.saveLoading = false;
      }
    );
  }

  updateProduct() {
    this.serviceFormSubmitted = true;
    this.saveLoading = true;

    console.log(this.productForm.pic);
    if (this.productForm.name == '' && this.productForm.sku == '') {
      return;
    }

    var autoid = "";
    for (var i = 0; i < this.piclist.length; i++) {
      if (this.piclist[i]["picid"] == this.productForm.pic) {
        console.log(this.piclist[i]["picid"] == this.productForm.pic)
        autoid = this.piclist[i]['autoid'];

      }
    }
    var sendPic: any = [
      {
        "autoid": autoid,
        "picid": this.productForm.pic,
      }
    ];
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "name": this.productForm.name,
      "skucode": this.productForm.sku,
      "piclist": sendPic,
      "modulelist": this.modulelist,
      "deletemodule": this.moduledeletelist,
      "warrantyid": this.selectWarranty
    }

    console.log(this.warranties, data)

    this.kunyekService.addProductCrm(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.addProduct = false;
          this.editProductcheck = false;
          this.customFields = [];
          this.total = 0;
          this.productListdata = [];
          this.sortby = '';
          this.nextDisabled = false;
          this.currentPage = 1;
          this.lastPage = '';
          this.getProduct();
          this.serviceFormSubmitted = false;
        } else {
          this.saveLoading = false;
          this.editProductcheck = false;
          this.addProduct = false;
          this.serviceFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.serviceFormSubmitted = false;
        this.addProduct = false;
        this.editProductcheck = false;
        this.saveLoading = false;
      }
    );

  }

  close() {
    console.log("ok")
    const data = {
      'data': this.productListdata,
      'status': true,
    }

    this.dialogRef.close(data);
  }


  new() {
    if (this.create == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {
      this.addProduct = true;
      this.editProductcheck = false;
      this.productForm.name = '';
      this.productForm.sku = '';
      this.customFields = [];
      this.productForm.pic = '';
      this.addCusField();
      this.isExecl = false;
      this.modulelist = [];
    }
  }

  editProduct(data: any) {
    if (this.edit == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {

      this.editProductcheck = true;
      this.addProduct = false;
      this.productForm.name = data.name;
      this.productid = data.productid;
      this.productForm.sku = data.skucode;
      console.log("...iefl>")
      console.log(this.productForm)
      // productid
      this.modulelist = []
      if (data.modulelist.length > 0) {
        var picid = "";
        var picname = "";
        for (let i = 0; i < data.modulelist.length; i++) {
          if (data.modulelist[i].piclist.length > 0) {
            if (data.modulelist[i].piclist[0].modulepicuser.length > 0) {
              picid = data.modulelist[i].piclist[0].modulepicuser[0].picid
              picname = data.modulelist[i].piclist[0].modulepicuser[0].picname
            }
          }
          this.modulelist.push({
            "description": data.modulelist.length > 0 ? data.modulelist[i].description : '',
            "moduleid": data.modulelist.length > 0 ? data.modulelist[i].moduleid : '',
            "picid": picid ? picid : "",
            "picname": picname ? picname : "",
          })
          picid = ""
          picname = ""
        }
      }
      console.log(this.modulelist);


      // this.modulelist[0].piclist[0].modulepicuser[0].picname
      // data.modulelist ? data.modulelist : []
      this.productForm.pic = data.picid;
      const warranty = this.warranties.find((x: any) => x.warrantyid == data.warrantyid);
      this.selectWarranty = warranty ? warranty.warrantyid : '';

      console.log('4')
    }


  }


  cancel() {
    this.addProduct = false;
    this.editProductcheck = false;
    this.customFields = [];
    this.deleteList = [];
    this.productForm.name = '';
    this.productForm.sku = '';
  }


  getProduct() {
    this.gettingData = true;



    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "sortby": this.sortby == '' ? '' : this.sortby,
      "pagesize": this.itemPerPage,
      "query": this.searchbool == true ? this.searchtexts : '',
      "showcreatedbyname": true,
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          res.list.map((product: any) => {
            var picname = "";
            var picid = "";
            var picuserid = ""
            if (product.piclist.length > 0) {
              if (product.piclist[0].picuser != null) {
                picname = product.piclist[0].picuser.picname
                picid = product.piclist[0].picuser.picid
                picuserid = product.piclist[0].picuser.userid
              }
            }


            this.productListdata.push({
              "autoid": product.autoid,
              "skucode": product.skucode,
              "name": product.name,
              "picname": picname,
              "picuserid": picuserid,
              "picid": picid,
              "productid": product.productid,
              "sortby": product.sortby,
              "modulelist": product.modulelist,
              "createdbyname": product.createdbyname,
              "warrantyid": product.warrantyid
            });
          });

          console.log(this.productListdata);


          var sort = this.productListdata.length - 1;
          this.total = res.total;


          if (parseInt(this.total) == this.productListdata.length) {
            console.log("getTrue")
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.productListdata[sort].sortby;
          } else {
            console.log("getfalse");
            console.log(this.productListdata[sort])
            this.sortby = this.sortby = sort > -1 ? this.productListdata[sort].autoid : '';
          }
          console.log(">>>>" + this.sortby);

          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }
  getdonwloadProduct() {
    this.saveexportingloading = true;



    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "sortby": this.sortby == '' ? '' : this.sortby,
      "pagesize": this.itempercount,
      "query": this.searchbool == true ? this.searchtexts : ''
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          res.list.map((product: any) => {
            var picname = "";
            var picid = "";
            var picuserid = ""
            if (product.piclist.length > 0) {
              if (product.piclist[0].picuser != null) {
                picname = product.piclist[0].picuser.picname
                picid = product.piclist[0].picuser.picid
                picuserid = product.piclist[0].picuser.userid
              }
            }

            console.log(picname)

            this.downloaddata.push({
              "autoid": product.autoid,
              "skucode": product.skucode,
              "name": product.name,
              "picname": picname,
              "picuserid": picuserid,
              "picid": picid,
              "productid": product.productid,
              "sortby": product.sortby,
              "modulelist": product.modulelist
            });
          });

          console.log(this.productListdata);


          this.total = res.total;

          this.saveexportingloading = false;
        } else {
          this.saveexportingloading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveexportingloading = false;
      }
    );

  }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.lastPage);
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;

        if (event > (this.productListdata.length / this.itemPerPage)) {
          this.getProduct();
        }
      }
    }
  }





  removeCusField(idx: any) {
    this.customFields.splice(idx, 1);
    console.log(this.customFields.splice(idx, 1));
    this.maxOptionCount.pop();
  }

  removeCusFields(idx: any) {

    if (this.customFields[idx].picid != "") {
      this.deleteList.push(this.customFields[idx]);
    }

    console.log(this.deleteList);

    this.customFields.splice(idx, 1);
    console.log(this.customFields);

    this.maxOptionCount.pop();
  }


  addCusField() {
    console.log("11");
    this.customFields.push({ name: '', picid: '' });
    this.maxOptionCount.push(this.customFields.length);
  }

  bgcolor: boolean = false;
  selectRow(event: any, item: any) {
    console.log(item);
    const data = {
      'data': item,
      'status': true,
    }

    this.dialogRef.close(data);

  }

  gotoService(event: any, data: any) {
    console.log(">>>>");
    console.log(data);

  }

  cancelFunction() {
    this.addProduct = false;
    this.editProductcheck = false;
    this.productadmin = false;
    this.AdminAddorNot = false;
    this.isExeclProuct = false;
  }

  cancels(data: any) {
    if (data == 1) {
      this.AdminAddorNot = false;
      this.addProduct = false;
      this.editProductcheck = false;
      this.productadmin = true;
      this.isadminValid = false;



    } else {
      this.AdminAddorNot = false;
      this.addProduct = false;
      this.editProductcheck = true;
      this.productadmin = false;

    }

  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
    this.getProduct();

  }


  refresh() {
    this.statusFilter = '';
    this.total = 0;
    this.productListdata = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    if (this.view == true) {

    } else {
      this.getProduct();

    }

  }
  username: any = '';
  isadminValid: boolean = false;
  useridChange() {
    this.username = ""
    this.isadminValid = false
  }
  checkingMember: boolean = false;
  checkMember() {
    this.checkingMember = true;
    const data = {
      "searchuserid": this.allinoneService.checkUserId(this.userid.toString().trim()),
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,

      // "shortcode": this.allinoneService.getDomain().shortcode
    }
    this.kunyekService.getMembersCRM(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.username = res.username
        this.id = res.id
        this.checkingMember = false
        this.isadminValid = true
      }
      else {
        this.checkingMember = false
        this.isadminValid = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.checkingMember = false
      this.isadminValid = false
    }
    )
  }

  addnewAdmin() {

    this.AdminAddorNot = true;
    this.isExecl = false;
    this.productadmin = false;
    this.addProduct = false;
    this.editProductcheck = false;
    this.userid = ''
    this.removeExcel();
  }

  openModuleForm() {
    const dialog = this.dialog.open(CrmProductModuleComponent, {
      width: '700px',
      height: "550px",
      closeOnNavigation: true,
      // panelClass: ['event-modal', 'event-detail','mat-typography'],
      panelClass: ['mat-typography',],
      data: {
        'modulelistdata': this.modulelist,
        'orgid': this.orgid
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.modulelist = dialogResult.datalist
        this.moduledeletelist = dialogResult.deletelist
        console.log(dialogResult);
        console.log("contactform")
        console.log(this.modulelist)
      }



    });
  }

  addAdmin() {
    this.getProductAdmin();
    this.productadmin = true;
    this.AdminAddorNot = false;
    this.addProduct = false;
    this.editProductcheck = false;
  }


  submitadmin() {
    if (this.id != "" && this.userid != "") {
      this.saveAdmin();
    }
  }
  saveAdmin() {
    this.saveLoading = true;
    const data = {
      "productid": this.productid,
      "adduser": this.allinoneService.checkUserId(this.userid),
      "addusername": this.username.toString().trim(),
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    }

    this.kunyekService.addAdminProduct(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        // this.cancel()
        this.saveLoading = false;
        this.AdminAddorNot = false;
        this.addProduct = false;
        this.editProductcheck = false;
        this.isadminValid = false
        this.productadmin = true;
        this.username = '';
        this.userid = '';

        this.getProductAdmin();
      }
      else {
        this.saveLoading = false
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.saveLoading = false
    }
    )

  }
  productAdminList: any = [];
  getAdmin: boolean = false;
  getProductAdmin() {
    this.getAdmin = true;

    const data = {
      "productid": this.productid,
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    }
    this.kunyekService.getProductAdmin(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          this.productAdminList = res.list;
          this.getAdmin = false;

        } else {
          this.getAdmin = false;
          this.messageService.openSnackBar(res.message, 'warn');

        }

      },
      (err) => {
        this.getAdmin = false;
        this.messageService.openSnackBar(err.message, 'warn');
      }

    );

  }
  deleteAdmin(id: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.getAdmin = true;
        const data = {
          "autoid": id,
        }
        this.kunyekService.deleteProductAdmin(data).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.productadmin = true;
            this.AdminAddorNot = false;
            this.getProductAdmin();

          }
          else {
            this.productadmin = true;
            this.AdminAddorNot = false;

            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
          err => {
            this.productadmin = true;
            this.AdminAddorNot = false;
          }
        )
      }
    });
  }
  data: any = []


  deleteProduct(eventdata: any, index: any) {

    if (this.delete == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {

      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        width: "250px",
        panelClass: ['mat-typography'],
        closeOnNavigation: true
      })

      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.gettingData = true;
          const data = {
            "productid": eventdata.productid,
            "orgid": this.orgid
          }
          this.kunyekService.deleteProduct(data).subscribe((res: any) => {
            if (res.returncode == "300") {
              this.productadmin = false;
              this.AdminAddorNot = false;
              this.total = 0;
              this.gettingData = true;
              this.productListdata = [];
              this.sortby = '';
              this.nextDisabled = false;
              this.currentPage = 1;
              this.lastPage = '';
              this.getProduct();
              // this.refresh();

            }
            else {
              this.gettingData = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
            err => {
              this.gettingData = false;
              this.messageService.openSnackBar(err, 'warn');
            }
          )
        }
      });
    }


  }


  getPIC() {
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
    }
    this.kunyekService.getCrmPIC(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.piclist = res.list;

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        // this.messageService.openSnackBar(err, 'warn');
      }
    );

  }
  backPerson() {
    const dialog = this.dialog.open(CrmPersonInChargeComponent, {
      maxWidth: "600px",
      width: "600px",
      height: "500px",
      panelClass: ['mat-typography', 'crm-service-status-modal'],
      closeOnNavigation: true,
      data: {
        // picList: JSON.parse(JSON.stringify(this.picList)),
        orgid: this.orgid,
        check: true,
      },
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult != null && dialogResult.status == true) {
        console.log(dialogResult.data)

        this.getPIC();
        console.log(this.productForm.pic)
        // this.serviceForm.sku = dialogResult.data.skucode;
        // this.serviceForm.productid = dialogResult.data.productid;
        // this.serviceForm.product =dialogResult.data.name;
        // this.picList= dialogResult.data.piclist;
      }
    });


  }



  // Excel
  errorList: any = []
  // excel:boolean = false;
  isExeclProuct: boolean = false;
  checkingExcelFile: boolean = false;
  allok: boolean = false;
  duplicateList: any = [];
  warningList: any = [];
  excelerror: boolean = false;
  addcustomer: boolean = false;
  finalMemberList: any = [];
  useridList: any = [];
  newcclist: any = [];
  uidList: any = [];
  excel: any = '';
  rawMemberList: any[] = [];
  @ViewChild('excelFile') excelFile: any;
  Start: any = 0;
  End: any = 30;
  mobileList: any = [];
  descriptionList: any = [];
  // isExcel: boolean = false;
  downloadSampleFile(): any {
    this.http
      .get(
        this.allinoneService.bucketUrl + 'templates/CRMProductAdminSampleTemplate.xlsx',
        { responseType: 'blob' }
      )
      .subscribe((response: any) => {
        saveAs(response, 'CRMProductAdminSampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  downloadSampleFileProduct(): any {
    this.http
      .get(
        this.allinoneService.bucketUrl + 'templates/CRMProductSampleTemplate.xlsx',
        { responseType: 'blob' }
      )
      .subscribe((response: any) => {
        saveAs(response, 'CRMProductSampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }


  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
    this.errorList = [];
    this.mobileList = [];
  }

  onExcelFileSelect(event: any) {
    this.excelerror = false;
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        console.log(this.rawMemberList);
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }
  onExcelFileSelectProduct(event: any) {
    this.excelerror = false;
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });

        this.checkExcelFileProduct();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }

  importAdmin() {
    console.log("..we.fweljfljf")
    this.isExecl = true;

    this.AdminAddorNot = true;
    this.productadmin = false;
    this.addProduct = false;
    this.editProductcheck = false;
    this.excel = '';
    this.errorList = [];
    this.warningList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
    this.errorList = [];
    this.mobileList = [];

  }
  importProduct() {
    if (this.create == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {
      this.isExecl = false;
      this.isExeclProuct = true;
      this.productadmin = false;
      this.addProduct = false;
      this.editProductcheck = false;
      this.excel = '';
      this.errorList = [];
      this.warningList = [];
      this.excelerror = false;
      this.duplicateList = [];
      this.finalMemberList = [];
      this.useridList = [];
      this.newcclist = [];
      this.uidList = [];
      this.allok = false;
      this.errorList = [];
      this.mobileList = [];
    }

  }
  trackByPicilist(index: any, item: any) {
    return item.picid;
  }

  checkExcelFile() {
    this.checkingExcelFile = true;

    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.uidList = [];
    this.mobileList = [];

    const tempCcList: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '')
      ) {
      } else {
        if (raw[0] == null || raw[0] == undefined || raw[0] == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "User ID is empty.",
          };
          this.errorList.push(tempError);
        } else {
          const einde = this.mobileList.findIndex((x: any) =>
            x.userid.trim() == raw[1] ? raw[1] : ''.toString().trim()
          );
          console.log(">>>>")
          console.log(einde)
          if (einde > -1) {
            const tempError = {
              line: (i + 1).toString(),
              error:
                "User ID '" +
                raw[1].toString().trim() +
                "' is duplicated.",
            };
            this.errorList.push(tempError);
          } else {
            if (raw[1] ? raw[1] : ''.toString().trim() != '') {
              var tempEId = {
                line: (i + 1).toString(),
                userid: raw[1].toString().trim(),
              };
              this.mobileList.push(tempEId);
            }


          }



          const mobile = raw[0] ? raw[0].toString() : '';
          const name = raw[1] ? raw[1].toString() : '';


          const tempMember = {
            addusername: name,
            adduser: mobile ? this.allinoneService.checkUserId(mobile) : '',
          };
          this.finalMemberList.push(tempMember);
          this.checkingExcelFile = false;
          this.allok = true;
        }




      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('duplicate ====> ');
    console.log(this.duplicateList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (
      this.errorList.length == 0 &&
      this.newcclist.length == 0 &&
      this.finalMemberList.length > 0
    ) {
      // this.checkEidList()
    } else {
      this.checkingExcelFile = false;
    }
  }


  checkExcelFileProduct() {
    this.checkingExcelFile = true;

    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.uidList = [];
    this.mobileList = [];
    this.errorList = [];

    const tempCcList: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '')
      ) {
      } else {
        if (raw[1] == null || raw[1] == undefined || raw[1] == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "Description is empty.",
          };
          this.errorList.push(tempError);
        } else {
          if (raw[0].length > 20) {
            const tempError = {
              line: (i + 1).toString(),
              error: "SKU cannot be longer than 20 characters.",
            };
            this.errorList.push(tempError);
          } else {
            const einde = this.mobileList.findIndex((x: any) =>
              x.skucode.trim() == raw[0] ? raw[0] : ''.toString().trim()
            );
            console.log(">>>>")
            console.log(einde)
            if (einde > -1) {
              const tempError = {
                line: (i + 1).toString(),
                error:
                  "SKU code '" +
                  raw[0].toString().trim() +
                  "' is duplicated.",
              };
              this.errorList.push(tempError);
            } else {
              if (raw[0] ? raw[0] : ''.toString().trim() != '') {
                var tempEId = {
                  line: (i + 1).toString(),
                  skucode: raw[0].toString().trim(),
                };
                this.mobileList.push(tempEId);
              }




              const sku = raw[0] ? raw[0].toString() : '';
              const description = raw[1] ? raw[1].toString() : '';


              const tempMember = {
                skucode: sku,
                name: description,
              };
              this.finalMemberList.push(tempMember);
              this.checkingExcelFile = false;
              this.allok = true;
            }

          }
        }




      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('duplicate ====> ');
    console.log(this.duplicateList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (
      this.errorList.length == 0 &&
      this.newcclist.length == 0 &&
      this.finalMemberList.length > 0
    ) {
      // this.checkEidList()
    } else {
      this.checkingExcelFile = false;
    }
  }

  submitExcel() {
    console.log("11")

    if (this.finalMemberList.length > 0) {
      if (this.End > this.finalMemberList.length) {
        this.End = this.finalMemberList.length;
      }
      this.AddExeclproductAdmin();
    }
    else {
      this.messageService.openSnackBar('Your excel file is empty', 'warn')
    }
  }




  AddExeclproductAdmin() {

    console.log(22)


    var tempcuostmer: any[] = [];
    for (let i = this.Start; i < this.End; i++) {
      tempcuostmer.push(this.finalMemberList[i]);
    }
    this.addcustomer = true;
    this.excelerror = false;
    const data = {
      productadminlist: tempcuostmer,
      createtype: 'array',
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      productid: this.productid,
      adduser: "",
      addusername: "",
    };
    console.log(data);

    this.kunyekService.addAdminProduct(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        if (this.End == this.finalMemberList.length) {
          this.addcustomer = false;
          this.isExecl = false;
          this.excel = false;
          this.allok = false;
          this.productadmin = true;
          this.AdminAddorNot = false;
          this.getProductAdmin();
          this.Start = 0;
          this.End = 30;
        } else {
          this.Start = this.End;
          this.End = this.End + 30;
          if (this.End > this.finalMemberList.length) {
            this.End = this.finalMemberList.length;
          }
          this.AddExeclproductAdmin();
        }
      } else {
        this.addcustomer = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });

  }
  submitExcelProduct() {
    if (this.finalMemberList.length > 0) {
      if (this.End > this.finalMemberList.length) {
        this.End = this.finalMemberList.length;
      }
      this.AddExeclproduct();

    } else {
      this.messageService.openSnackBar('Your excel file is empty', 'warn')
    }

  }


  AddExeclproduct() {

    console.log(22)


    var tempcuostmer: any[] = [];
    for (let i = this.Start; i < this.End; i++) {
      tempcuostmer.push(this.finalMemberList[i]);
    }
    this.addcustomer = true;
    this.excelerror = false;
    const data = {
      productlist: tempcuostmer,
      createtype: 'array',
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      skucode: "",
      name: "",
    };
    console.log(data);

    this.kunyekService.addProductCrm(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        if (this.End == this.finalMemberList.length) {
          this.addcustomer = false;
          this.isExecl = false;
          this.excel = false;
          this.allok = false;
          this.productadmin = false;
          this.isExeclProuct = false;
          this.AdminAddorNot = false;
          this.refresh();
          this.Start = 0;
          this.End = 30;
        } else {
          this.Start = this.End;
          this.End = this.End + 30;
          if (this.End > this.finalMemberList.length) {
            this.End = this.finalMemberList.length;
          }
          this.AddExeclproduct();
        }
      } else {
        this.addcustomer = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });

  }

  onSearch() {
    if (this.searchtexts != '') {
      this.searchbool = true;
      this.refresh()
    }
  }

  clear() {
    this.searchbool = false;
    this.searchtexts = '';
    this.refresh();
  }



  downloaddata: any = [];
  async downloadSheetProduct() {
    this.downloaddata = JSON.parse(JSON.stringify(this.productListdata))

    if (this.itemPerPage > this.total) {
      this.itempercount = this.itemPerPage;
    } else {

      this.itempercount = this.total - this.data.length
      this.saveexportingloading = true;
      await this.getdonwloadProduct();
    }
    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Product_' + time + '.xlsx';
    console.log(this.downloaddata);
    this.downloaddata.map((product: any) => {
      data.push({
        "SKU": product.skucode,
        "Description": product.name,
        "Person in Charge": product.picname,
      });
    });
    this.allinoneService.exportEcecl(data, name);
  }

  exportAdmin() {
    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Product_Admins_of_' + this.productForm.name + time + '.xlsx';
    this.productAdminList.map((admin: any) => {
      data.push({
        'User ID': admin.adminid,
        'User Name': admin.adminname,

      });
    });
    console.log(data)
    this.allinoneService.exportEcecl(data, name);
  }



  sortData(sort: Sort) {
    const data = this.productListdata;
    console.log(this.data);
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.productListdata = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sku':
          return this.allinoneService.compare(
            a.skucode.toLocaleLowerCase(),
            b.skucode.toLocaleLowerCase(),
            isAsc
          );
        case 'description':
          return this.allinoneService.compare(
            a.name.toLocaleLowerCase(),
            b.name.toLocaleLowerCase(),
            isAsc
          );
        case 'pic':
          return this.allinoneService.compare(
            a.picname,
            b.picname,
            isAsc
          );
        case 'createbyname':
          return this.allinoneService.compare(
            a.createbyname,
            b.createbyname,
            isAsc
          );
        default:
          return 0;
      }
    });

  }

  getWarranty() {
    const data = {
      orgid: this.allinoneService.selectedOrg,
      userid: this.allinoneService.getUserId(),
      domainid: this.allinoneService.getDomain(),
    };
    this.kunyekService.getWarranty(data).subscribe({
      next: (res) => {
        if (res.returncode == '300') {
          this.warranties = res.list;
        }
      }
    })
  }

}
