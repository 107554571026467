
import { DatePipe, } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,

} from '@angular/core';


import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';



@Component({
  selector: 'app-crm-customer-group',
  templateUrl: './crm-customer-group.component.html',
  styleUrls: ['./crm-customer-group.component.scss']
})
export class CrmCustomerGroupComponent implements OnInit {
  gettingData: boolean = false;
  customergroupdata: any = [
    // { "groupid": "9923023099", "name": "City Mark Group", "customer": "City Mark, Internal" }

  ];
  addgroup: boolean = false;
  saveLoading: boolean = false;
  gettingCustomers: boolean = false;
  groupformSave: boolean = false;
  isEditing: boolean = false;
  orgid: any = "";
  organizations: any = [];
  customersList: any = [];
  ngcustomerlist: any = [];
  customeridList: any = [];
  searchText: any = "";

  itemPerPage: any = "";
  currentPage: any = 1;
  text1: any = "";
  text2: any = "";

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  groupForm = {
    "name": "",
    "groupid": "",
    "customer": ""
  }
  permission: any = [];
  isAdmin: boolean = false;
  custoemrgroupcreate: boolean = false;
  custoemrgroupview: boolean = false;
  custoemrgroupedit: boolean = false;
  custoemrgroupdelete: boolean = false;
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.isAdmin = (this.allinoneService.crmOrgAdmin === 'true');

  }

  ngOnInit(): void {

    this.permission = this.allinoneService.getCRMpermission();
    if (this.allinoneService.crmBlocked == 'true') {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      if (this.isAdmin == false) {

        var type = this.permission.map((x: any) => x).indexOf('00080');
        if (type == -1) {
          this.router.navigateByUrl('sales')
        }
        var view = this.permission.map((x: any) => x).indexOf('00081');
        var create = this.permission.map((x: any) => x).indexOf('00082');
        var edit = this.permission.map((x: any) => x).indexOf('00083');
        var delet = this.permission.map((x: any) => x).indexOf('00084');
        console.log("view" + view);
        console.log(this.permission)
        if (view == -1) {
          this.custoemrgroupview = true;
        }
        if (create == -1) {
          this.custoemrgroupcreate = true;
        }

        if (edit == -1) {
          this.custoemrgroupedit = true;
        }

        if (delet == -1) {
          this.custoemrgroupdelete = true;
        }

      }
    }
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
    if (this.custoemrgroupview == true) {
      this.gettingData = false;
    } else {
      this.getGroupCustomer();

    }
  }


  new() {
    if (this.custoemrgroupcreate == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedViewMessage, 'warn');
    } else {
      this.addgroup = true;
      this.customeridList = [];
      this.ngcustomerlist = [];
      this.groupForm.name = "";
      this.getCustomers();

    }

  }

  cancelFunction() {
    this.addgroup = false;
    this.isEditing = false;
  }

  submitCustomerGroup() {

    this.groupformSave = true;
    console.log(this.customeridList)
    if (this.customeridList.length > 0) {
      this.saveLoading = true;
      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.orgid,
        cusotmerlist: this.customeridList,
        name: this.groupForm.name
      };
      if (this.isEditing) {
        data = Object.assign(data,
          {
            groupid: this.groupForm.groupid,
          }
        );
      }






      if (this.isEditing == true) {
        this.kunyekService.editcustomergroup(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.addgroup = false;
              this.groupformSave = false;
              this.saveLoading = false;
              this.isEditing = false;
              this.getGroupCustomer();
            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      } else {
        this.kunyekService.addcustomergroup(data).subscribe(
          (res: any) => {

            if (res.returncode == '300') {
              this.addgroup = false;
              this.isEditing = false;
              this.groupformSave = false;
              this.saveLoading = false;
              this.getGroupCustomer();

            } else {
              this.saveLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.saveLoading = false;
          }
        );
      }


    } else {


    }

  }
  getGroupCustomer() {
    this.gettingData = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    };
    this.kunyekService.getcustomergroup(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.customergroupdata = [];
          res.list.map((item: any) => {
            this.customergroupdata.push({
              "groupid": item.groupid,
              "name": item.name,
              "customerlist": item.customerlist,
              "cusotmernames": item.cusotmernames,

            })
          });
          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }

  getCustomers() {

    this.gettingCustomers = true;
    return new Promise((resolve) => {
      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.orgid,
      };



      this.kunyekService.getCrmCustomers(data).subscribe(
        (res: any) => {

          if (res.returncode == '300') {
            this.customersList = [];
            res.list.map((item: any) => {
              this.customersList.push({
                "customerid": item.customerid,
                "name": item.customername,

              })
            });

            resolve('true')
            this.gettingCustomers = false;
          } else {

            resolve('false')
            this.gettingCustomers = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          resolve('false')
          this.messageService.openSnackBar(err, 'warn');
          this.gettingCustomers = false;
        }
      );

    });
    // })

  }

  customerSelect(data: any) {
    console.log(data);
    this.customeridList = [];
    for (let i = 0; i < data.length; i++) {
      this.customeridList.push(data[i]['customerid'])

    }
    console.log(this.customeridList);


  }

  async editCustomerEdit(data: any) {
    if (this.custoemrgroupedit == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      this.isEditing = true;
      this.groupForm.name = data.name;
      this.groupForm.groupid = data.groupid;
      this.customeridList = data.customerlist;
      this.ngcustomerlist = [];
      await this.getCustomers();
      console.log(this.customersList);
      console.log(this.customeridList);
      var selectCustomer = []
      for (var j = 0; j < this.customersList.length; j++) {
        for (var i = 0; i < this.customeridList.length; i++) {
          if (this.customersList[j]['customerid'] == this.customeridList[i]) {
            selectCustomer.push({
              "customerid": this.customersList[j]['customerid'],
              "name": this.customersList[j]['name']
            })
          }
        }
      }
      this.ngcustomerlist = selectCustomer;

    }

  }

  refreshgetData() {
    this.getGroupCustomer();
  }


  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
  }

  excelexportLoading: boolean = false;
  exportCustoemrGroup() {
    this.excelexportLoading = true;


    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Customer_Group' + time + '.xlsx';
    this.customergroupdata.map((cgroup: any) => {
      data.push({
        "Group ID": cgroup.groupid,
        "Name": cgroup.name,
        "Customer": cgroup.cusotmernames,
      });
    });
    this.excelexportLoading = false;

    this.allinoneService.exportEcecl(data, name);
  }
  deleteCustomerGroup(groupid: any) {
    if (this.custoemrgroupdelete == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        width: "250px",
        panelClass: ['mat-typography'],
        closeOnNavigation: true
      })

      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.gettingData = true;
          const data = {
            "groupid": groupid,
            "domainid": this.allinoneService.getDomain().domainid,
            "orgid": this.orgid,
          }
          this.kunyekService.deleteCustomergroup(data).subscribe((res: any) => {
            if (res.returncode == "300") {
              this.refreshgetData();
            }
            else {
              this.messageService.openSnackBar(res.status, 'warn');
            }
          },
            err => {
            }
          )
        }
      });

    }
  }
}
