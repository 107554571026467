<div class="title-container mb-3">
    <div class="title">
        Passengers
    </div>
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr>


<input type="text" class="form-control mb-2" [(ngModel)]="searchText" placeholder="Search">

<div class="loading-container" *ngIf="isLoading">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div class="loading-container" *ngIf="!isLoading && userList.length == 0">
    <div >
        <span>Empty</span>
    </div>
</div>
<div *ngIf="!isLoading && userList.length != 0" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
(scrolled)="onScrollDown()" [scrollWindow]="false" [perfectScrollbar]="config" class="task-container-new">

<div class="card card-body my-card-new" *ngFor="let data of userList | filter : searchText:'searhrequest'" style="margin-bottom: 3px;" (click)="checkPassenger(data)">
    <div class="row">
        <div class="col-10">
            <div class="flex-row word">
                {{data.username}} ({{data.userid}}) - <span style="color: gray;"> Ref no: {{data.requestrefno}} </span>
            </div>
            <div>
                {{data.trantype == "001" ? "Ferry" : "Daily"}}
            </div>
            <div>
                {{data.user}}
            </div>
            
            <div>
                {{allinoneService.formatDBToShow(allinoneService.formatDate(data.startdate))}} - {{allinoneService.formatDBToShow(allinoneService.formatDate(data.enddate))}} 
            </div>
            <div>
                {{data.weekday}}
            </div>
            <div>
                {{ data.trantype == "001" ? data.remark : data.toplace }}
            </div>
            <div *ngIf="data.trantype == '002' && data.going && data.return">
                Going : {{data.returntime}} , Return : {{data.arrivetime}}
            </div>
            <div *ngIf="data.trantype == '002' && data.going && !data.return">
                Going : {{data.returntime}}
            </div>
            <div *ngIf="data.trantype == '002' && data.return && !data.going">
                Return : {{data.arrivetime}}
            </div>
        </div>
        <div class="col-1 ms-4 d-flex  justify-content-end">
            <input *ngIf="!data.check" class="form-check-input cus" type="checkbox" id="ticketcheckbox" 
                name="ticketcheckbox">
            <input *ngIf="data.check"  class="form-check-input cus" type="checkbox" id="ticketcheckbox" 
                name="ticketcheckbox" checked="checked">
        </div>
    </div>
</div>
</div>







<div class="close-save-button justify-content-end mt-3" *ngIf="!isLoading && userList.length != 0">
    <button type="button" class="btn btn-custom me-2" (click)="close()">
        Cancel
    </button>
    <app-button-loader (click)="done()" [button_text]="'Done'" [load_button_text]="''"></app-button-loader>
</div>