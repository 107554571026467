<section class="container-fluid">


    <div class="content">
        <div *ngFor="let list of CrmList">
            <div class="mt-3" style="font-size: 1rem;
        font-weight: bold;
        margin-bottom: 10px;
    ">{{list.title}}</div>


            <div class="row g-0">
                <ng-container *ngFor="let i of list.card">
                    <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" (click)="goRout(i,i.routname)">
                    <div class='d-flex flex-column'
                        >
                        <div class="mb-2">
                            <img src="{{i.icon}}" width="30px">
                        </div>
                        <div class="name">
                            {{i.appname}}
                        </div>
                    </div>
                    </div>

                </ng-container>
            </div>
        </div>
    </div>



</section>