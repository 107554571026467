<div class='container-fluid'>
  <div class="content">
    <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb s_breadcrumb="Leave Opening" [t_breadcrumb]="username ? username + ' (' +userid+')' : userid"
        (emit)="goToLeaveOpening()">
      </app-team-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb [s_breadcrumb]="'Leave Opening'"
        [t_breadcrumb]="username ? username + ' (' +userid+')' : userid" (emit)="goToLeaveOpening()">
      </app-office-breadcrumb>
    </ng-container>


    <ng-container *ngIf="gettingLeaveOpening; else elseTemplate;">
      <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </ng-container>

    <ng-template #elseTemplate>
      <div class="close-save-button my-3">
        <button type="button" class="btn btn-custom me-2" (click)="goToLeaveOpening()">
          Cancel
        </button>
        <app-button-loader (click)="save()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="isLoading"
          [disabled]="templateList.length == 0">
        </app-button-loader>
      </div>
      <!-- class="card card-body my-card p-4" -->
      <div>
        <div *ngIf="templateList.length == 0">
          <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
            Empty
          </div>
        </div>
        <ng-container *ngIf="templateList.length > 0">
          <div class="row">
            <div *ngFor="let template of templateList; let index = index" class="col-md-6">
              <div class="card card-body my-card mb-3">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    {{template.requesttypename}}
                  </div>
                  <div style="color: var(--main-color);">
                    {{template.noofdays}}
                  </div>

                </div>
                <mat-divider></mat-divider>
                <div class="row mt-3">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">
                      Brought Forward
                    </label>
                    <input class="form-control" type="number" [(ngModel)]="template.broughtforward" [min]="0"
                      (keypress)="$event.charCode != 45" [disabled]="template.unlimited">
                    <!-- <input class="form-control" type="number" [(ngModel)]="template.broughtforward"
                      (keypress)="($event.charCode >= 48 && $event.charCode < 58)"> -->
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">
                      Opening
                    </label>
                    <!-- Leave Opening Latest  -->
                    <input class="form-control" type="number" [(ngModel)]="template.opening"
                      [disabled]="template.unlimited">

                    <!-- <input class="form-control" type="number" [(ngModel)]="template.opening"  [min]="0"
                    (keypress)="$event.charCode != 45" [disabled]="template.unlimited"> -->

                    <!-- (ngModelChange)="validate(index)" -->
                    <!-- <input class="form-control" type="number" [(ngModel)]="template.opening"
                      (keypress)="($event.charCode >= 48 && $event.charCode < 58)"> -->
                  </div>
                </div>
              </div>
            </div>

          </div>

        </ng-container>
      </div>
    </ng-template>
  </div>