<div>
  <app-cs365-breadcrumb *ngIf="!isWarranty" [s_breadcrumb]="'Service Tickets'"
    [t_breadcrumb]="'History (#' + data.autoid + ')'" (emit)="cancelFunction()">
  </app-cs365-breadcrumb>
  <app-cs365-breadcrumb *ngIf="isWarranty" [s_breadcrumb]="'Service Warranty'"
    [f_breadcrumb]="'History (#' + data.autoid + ')'" [t_breadcrumb]="'Service Tickets'" (emit1)="cancelFunction()"
    (emit)="goToServiceWarranty()">
  </app-cs365-breadcrumb>
  <div class="table-responsive">
    <table class="table table-responsive table-borderless">
      <thead class="table-header">
        <tr scope="row">
          <td scope="col">ID</td>
          <td scope="col">Description</td>
          <td scope="col">Status</td>
          <td scope="col">Reported By</td>
          <td scope="col">Actions</td>
        </tr>
      </thead>
      <tbody *ngIf="gettingData">
        <tr>
          <td colspan="5" class="nodata">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody *ngIf="!gettingData">
        <ng-container *ngFor="let history of histories">
          <tr style="cursor: pointer" (click)="openHistoryDetail(history)">
            <td>{{ history.autoid }}</td>
            <td>
              <div id="descTextarea">{{ history.servicedesc }}</div>
              <div class="d-flex gap-2 mt-2">
                <div class="item" *ngIf="history.priority !== ''" [ngClass]="getPriorityClass(history.priority)"
                  matTooltip="Priority">
                  {{ getPriorityLabel(history.priority) }}
                </div>
                <div class="item" *ngIf="history.product.name !== ''" matTooltip="Product">
                  {{ history.product.name }}
                </div>
                <div class="item" *ngIf="history.projectname !== ''" matTooltip="Project">
                  {{ history.projectname }}
                </div>
                <div class="item" *ngIf="history.version !== ''" matTooltip="Version">
                  {{ history.version }}
                </div>
              </div>
            </td>

            <td>
              <div class="d-flex">
                <span class="servicetype" [ngClass]="{'red': history.type=='0010'}">{{ history.type=='0010' ? 'SE'
                  : history.type }}</span>
                <span>{{ mapingStatus(history.status) }}</span>
              </div>
              <span>{{ history.enddatetime | date: "dd/MM/YYYY" }}</span>
            </td>
            <td>
              <span>{{ history.reportedbyname }}</span>
            </td>
            <td>
              <button class="btn btn-outline-primary" matTooltip="History">
                <i class="fa fa-history"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>