<div class="container-fluid">
  <div class="content">
    <div class="m-4">
      <div class="me-auto">
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      </div>
      <div class="d-flex gap-2 mb-2">
        <button type="button" (click)="closeForm()" class="btn btn-custom">Cancel</button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="isSaving"
          (click)="onSaved()"></app-button-loader>
        <!-- <button type="button" class="btn btn-custom">Submit</button> -->
      </div>
      <div class="card">
        <div class="card-body m-2">
          <form [formGroup]="leaveForm" (ngSubmit)="onSaved()">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="type" class="form-label">Type <font color="red">*</font></label>
                    <select id="type" class="form-select" formControlName="type"
                      [ngClass]="{'is-invalid': type?.invalid && saved}">
                      <option value="" selected disabled>Select Type</option>
                      <option value="travel">Travel</option>
                      <option value="medical">Medical</option>
                    </select>
                    <div class="invalid-feedback" *ngIf="type?.invalid && saved">
                      <div *ngIf="type?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="user" class="form-label">Member<font color="red">*</font></label>
                    <div class="input-group">
                      <input type="text" id="user" [readonly]="true" class="form-control" formControlName="user"
                        [ngClass]="{ 'is-invalid': user?.invalid && saved }">
                      <div class="btn btn-custom me-2" (click)="checkAndOpenMemberModal()" style="height: 32.05px"
                        matTooltip="Add user">
                        <mat-icon>add</mat-icon>
                      </div>
                    </div>
                    <div class="invalid-feedback" *ngIf="user?.invalid || ((user?.dirty || user?.touched) && saved)">
                      <div *ngIf="user?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="from_date" class="form-label">From Date <font color="red">*</font></label>
                    <my-date-picker [options]="datePickerOptions" formControlName="from_date"
                      [ngClass]="{ 'is-invalid': from_date?.invalid && saved }" placeholder="DD/MM/YYYY">
                    </my-date-picker>
                    <div class="invalid-feedback" *ngIf="from_date?.invalid && saved">
                      <div *ngIf="from_date?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="to_date" class="form-label">To Date <font color="red">*</font></label>
                    <my-date-picker [options]="datePickerOptions" formControlName="to_date"
                      [ngClass]="{ 'is-invalid': to_date?.invalid && saved }" placeholder="DD/MM/YYYY">
                    </my-date-picker>
                    <div class="invalid-feedback" *ngIf="to_date?.invalid && saved">
                      <div *ngIf="to_date?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="duration" class="form-label">Duration</label>
                    <input type="text" id="duration" class="form-control" [value]="calculateDuration()" readonly>
                  </div>


                  <div class="col-md-12 mb-4">
                    <label for="attachment" class="form-label">Leave Attachment</label>
                    <div class="input-group">
                        <input class="form-control" type="file" (change)="onFileChanged($event)"
                            name="attachment" accept="image/*"
                            id="formFile" #fileInput1>
                    </div>
      

                </div>

                  <div class="col-md-12 mb-3">
                    <label for="reason" class="form-label">Reason <font color="red">*</font></label>
                    <textarea type="text" id="reason" class="form-control" formControlName="reason"
                      [ngClass]="{'is-invalid': reason?.invalid && saved}"></textarea>
                    <div class="invalid-feedback" *ngIf="reason?.invalid && saved">
                      <div *ngIf="reason?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>