<section class="container-fluid">
    <div class="d-flex header" style="margin:10px;">
        <div class="form-check mb-0 me-5" *ngIf="!checkorg " style="margin-top: 7px;">
            <label class="radio-inline">
                <input type="radio" [checked]="!customercare" id="aa" class="form-check-input p-1"
                    (change)="CustomerChange1($event)">{{orgName}}
            </label>
        </div>
        <div class="form-check mb-0 me-5" style="margin-top: 7px;">
            <label class="radio-inline">
                <input type="radio" [checked]="customercare" id="cus" class="form-check-input p-1"
                    (change)="CustomerChange2($event)">
                Self Service
            </label>
        </div>
        <div class=" " *ngIf="customercare && !isMobileView" style="width: 25%;">
            <ng-select class="role-select" [items]="customers" bindLabel="customername" placeholder="-" appendTo="body"
                [multiple]="false" [(ngModel)]="selectCustomerID" (change)="selectCustomer($event)">

            </ng-select>
            <!-- <select class="form-select" aria-label="Select Customer" [(ngModel)]="customerAry" (change)="selectCustomer($event)">
                <option *ngIf="customerAry == ''" value="">-</option>
                <option *ngFor="let cus of customers; let i = index" [value]="cus.customerid">
                    {{cus.customername}}
                </option>
            </select> -->
            <mat-progress-bar mode="indeterminate" *ngIf="loadCustomer"></mat-progress-bar>

        </div>

    </div>
    <div class="selfserviceselece" *ngIf="customercare && isMobileView">
        <div class="col-sm-12 ">
            <ng-select class="role-select" [items]="customers" bindLabel="customername" placeholder="-" appendTo="body"
                [multiple]="false" [(ngModel)]="selectCustomerID" (change)="selectCustomer($event)">

            </ng-select>
            <!-- <select class="form-select" aria-label="Select Customer" [(ngModel)]="customerAry" (change)="selectCustomer($event)">
                <option *ngIf="customerAry == ''" value="">-</option>
                <option *ngFor="let cus of customers; let i = index" [value]="cus.customerid">
                    {{cus.customername}}
                </option>
            </select> -->
            <mat-progress-bar mode="indeterminate" *ngIf="loadCustomer"></mat-progress-bar>

        </div>

    </div>




    <div class="content" *ngIf="this.allinoneService.crmOrgAdmin != 'true' && !customercare">

        <div *ngFor="let list of appList1">
            <div class="mt-3" style="font-size: 1rem;font-weight: bold;margin-bottom: 10px;">{{list.title}}</div>
            <div class="row g-0">
                <ng-container *ngFor="let i of list.card">
                    <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" (click)="goRout(i,i.routname)">
                        <div class='d-flex flex-column' >
                            <div class="mb-2">
                                <img src="{{i.icon}}" width="30px">
                            </div>
                            <div class="name">
                                {{i.appname}}
                            </div>
                        </div>

                    </div>
                </ng-container>
                
                    <div *ngIf="allinoneService.selected_orgcrm.isoveragereportview == 'true'" class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" (click)="goRout(serviceOverage, serviceOverage.routname)">
                        <div class='d-flex flex-column' >
                            <div class="mb-2">
                                <img src="{{serviceOverage.icon}}" width="30px">
                            </div>
                            <div class="name">
                                {{serviceOverage.appname}}
                            </div>
                        </div>
                    </div>
                    
            </div>
        </div>

        

    </div>
    <div class="content" *ngIf="this.allinoneService.crmOrgAdmin == 'true' && !customercare">
        <div *ngFor="let list of CrmList">
            <div class="mt-3" style="font-size: 1rem;
        font-weight: bold;
        margin-bottom: 10px;
    ">{{list.title}}</div>


            <div class="row g-0">
                <ng-container *ngFor="let i of list.card">
                    <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" (click)="goRout(i,i.routname)">
                        <div class='d-flex flex-column' >
                            <div class="mb-2">
                                <img src="{{i.icon}}" width="30px">
                            </div>
                            <div class="name">
                                {{i.appname}}
                            </div>
                        </div>

                    </div>
                </ng-container>
                <!-- <div *ngFor="let i of list.card" class="col-lg-3 col-md-3 col-sm-6 col-12 p-2 ps-0">
                    <div class="card d-flex p-2 custom-card" (click)="goRout(i,i.routname)">
                        <div class="mb-2">
                            <img src="{{i.icon}}" width="30px">
                        </div>
                        <div class="name">
                            {{i.appname}}
                        </div>
                    </div>

                </div> -->
            </div>
        </div>
    </div>

    <div class="content" *ngIf="customercare">
        <div class="row d-flex">
            <!-- <div class=" col-md-3">
                <label for="ustomer" class="form-label">Customer</label>

                <select class="form-select" aria-label="Select Customer" [(ngModel)]="customerAry" (change)="selectCustomer($event)">

                    <option value="" selected>-</option>
                    <option *ngFor="let cus of customers; let i = index" [value]="cus.customerid">
                        {{cus.customername}}
                    </option>
                </select>
                <mat-progress-bar mode="indeterminate" *ngIf="loadCustomer"></mat-progress-bar>

            </div> -->
        </div>
        <div>
            <div class="mt-3" style="font-size: 16px;font-weight: bold;margin-bottom: 10px;">Self Service
            </div>
            <div class="row g-0">

                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" (click)="goCustomerInfo('service/customerinfo')">
                    <div  class='d-flex flex-column' >
                        <div class="mb-2">
                            <img [src]="allinoneService.bucketUrl + 'policyicons/projects.png'" width="30px">
                        </div>
                        <div class="name">
                            Customer Information
                        </div>
                    </div>

                </div>

                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" *ngIf="checkselfservice" (click)="goService('service/service-tickets')">
                    <div  class='d-flex flex-column' >
                        <div class="mb-2">
                            <img src="assets/images/service.png" width="30px">
                        </div>
                        <div class="name">
                            Service Tickets
                        </div>
                    </div>

                </div>
                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" *ngIf="checkselfservice" (click)="goService('service/summary')">
                    <div  class='d-flex flex-column' >
                        <div class="mb-2">
                            <img src="assets/images/servicesummary.png" width="30px">
                        </div>
                        <div class="name">
                            Service Summary
                        </div>
                    </div>
                </div>
                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card" *ngIf="checkselfservice" (click)="goService('service/servicereport')">
                    <div  class='d-flex flex-column' >
                        <div class="mb-2">
                            <img src="assets/images/summaryreport.png" width="30px">
                        </div>
                        <div class="name">
                            Service Monthly Report
                        </div>
                    </div>
                </div>

                

            </div>
        </div>


    </div>

</section>