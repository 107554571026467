import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.css'],
})
export class PositionsComponent implements OnInit {
  @ViewChild('excelFile') excelFile: any;

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('tbody') tbody!: ElementRef;

  ccFormSubmitted: boolean = false;
  editFormSubmitted: boolean = false;
  gettingCcList: boolean = false;
  isEditing: boolean = false;
  disable: boolean = true;
  saveLoading: boolean = false;
  fromOrg: boolean = false;
  classCodeAddOrNot: boolean = false;
  isImporting: boolean = false;
  checkingExcelFile: boolean = false;
  allok: boolean = false;

  ccList: any[] = [];
  organizations: any[] = [];
  rawPositionList: any[] = [];
  finalPositionList: any[] = [];
  errorList: any = [];
  warningList: any = [];

  p: number = 1;

  excel: any;

  searchText : string = '';
  isFocus : boolean = false;

  ccForm = {
    orgid: '',
    positionid: '',
    classcode: '',
    department: '',
    division: '',
    id: '',
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<PositionsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fromOrg = data.fromOrg;
    if (this.fromOrg) {
      this.organizations.push(data.org);
      this.ccForm.orgid = data.org.orgid;
    }
    else{
      this.ccForm.orgid = data.orgid;
    }
  }

  ngOnInit(): void {
    // if (!this.fromOrg) {
    //   this.organizations = this.allinoneService.orgs;
    //   this.ccForm.orgid = this.allinoneService.selectedOrg
    //     ? this.allinoneService.selectedOrg
    //     : this.organizations[0].orgid;
    // }
    this.getCcList();
  }

  sortData(sort: Sort) {
    const data = this.ccList;
    if (!sort.active || sort.direction === '') {
      this.ccList = data;
      return;
    }

    this.ccList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        default:
          return 0;
      }
    });
  }

  cancel() {
    this.isEditing = false;
    this.classCodeAddOrNot = false;
    this.ccFormSubmitted = false;
    if(this.isImporting){
      this.removeExcel();
      this.isImporting = false;
    }
    this.ccForm = {
      classcode: '',
      positionid: '',
      department: '',
      division: '',
      id: '',
      orgid: this.ccForm.orgid,
    };
  }

  clear() {
    this.searchText = '';
  }

  editClasscode(item: any) {
    this.isEditing = true;
    this.classCodeAddOrNot = true;
    console.log(item.positionid);

    this.ccForm = {
      positionid: item.positionid,
      classcode: item.name,
      department: item.department,
      division: item.division,
      id: item.id,
      orgid: this.ccForm.orgid,
    };
  }

  orgChanged(event: any) {
    this.ccForm.orgid = event.target.value;
    this.getCcList();
  }

  submitCc() {
    if (this.isImporting) {
      if (!this.excel) {
        return this.messageService.openSnackBar(
          'Please select an excel file.',
          'warn'
        );
      }
      if (this.errorList.length > 0) {
        return this.messageService.openSnackBar(
          'Please resolve the errors first.',
          'warn'
        );
      }
      this.importCc(0, 150);
    } else {
      this.ccFormSubmitted = true;
      if (this.ccForm.classcode.trim() == '') {
        return;
      } else {
        this.saveCc();
      }
    }
  }

  saveCc() {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.ccForm.orgid,
      // "positionid": this.ccForm.positionid,
      name: this.ccForm.classcode.trim(),
      department: this.ccForm.department.trim(),
      division: this.ccForm.division.trim(),
    };

    if (this.isEditing) {
      data = Object.assign(data, { id: this.ccForm.id });
    }

    console.log(data);

    const fun = this.isEditing
      ? this.kunyekService.updatePosition(data)
      : this.kunyekService.createPosition(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getCcList();
          this.cancel();
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.ccFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        this.ccFormSubmitted = false;
      }
    );
  }

  getCcList() {
    this.gettingCcList = true;
    const data = {
      orgid: this.ccForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.kunyekService.getPositionList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ccList = res.datalist;
          this.gettingCcList = false;
        } else {
          this.gettingCcList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingCcList = false;
      }
    );
  }

  inputChange() {
    if (this.ccForm.classcode.trim() == '') {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  checkOverflow(element: any) {
    return element.offsetHeight < element.scrollHeight;
  }

  importPosition() {
    this.classCodeAddOrNot = true;
    this.isImporting = true;
  }

  onExcelFileSelect(event: any) {
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawPositionList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }

  checkExcelFile() {
    this.checkingExcelFile = true;
    this.errorList = [];
    this.warningList = [];
    this.finalPositionList = [];
    const tempCcList: any = [];
    for (let i = 1; i < this.rawPositionList.length; i++) {
      const raw = this.rawPositionList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '') &&
        (raw[2] == null || raw[2] == undefined || raw[2] == '')
      ) {
      } else {
        if (raw[0] == null || raw[0] == undefined || raw[0] == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "'Post' is empty.",
          };
          this.errorList.push(tempError);
        } else {
          const name = raw[0].toString().trim();
          const department = raw[1] ? raw[1].toString().trim() : '';
          const division = raw[2] ? raw[2].toString().trim(): '';
          var checkduplicate = this.finalPositionList.find(
            (x: any) =>
              x.name == name &&
              x.division == division &&
              x.department == department
          );
          if (checkduplicate) {
            const tempWarning = {
              line: (i + 1).toString(),
              error:
                "'" +
                name +
                ', ' +
                department +
                ', ' +
                division +
                "' is duplicated.",
            };
            this.warningList.push(tempWarning);
          } else {
            this.finalPositionList.push({
              name: name,
              department: department,
              division: division,
            });
          }
        }
      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('warning ====> ');
    console.log(this.warningList);
    console.log('final ====> ');
    console.log(this.finalPositionList);
    if (this.errorList.length == 0 && this.finalPositionList.length > 0) {
      this.allok = true;
    }
    this.checkingExcelFile = false;
  }

  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.finalPositionList = [];
    this.allok = false;
  }

  importCc(start : number, end : number) {
    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    var final : any = [];
    if(end > this.finalPositionList.length) {
      end = this.finalPositionList.length;
    }
    for(var i = start; i < end; i++) {
      final.push(this.finalPositionList[i]);
    }
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.ccForm.orgid,
      // "positionid": this.ccForm.positionid,
      name: '',
      department: '',
      division: '',
      positionlist: final,
    };


    this.kunyekService.createPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.getCcList();
          this.cancel();
          this.saveLoading = false;
          this.dialogRef.disableClose = false;
          this.messageService.openSnackBar('Saved successfully.', 'success');
        } else {
          // this.saveLoading = false;
          // this.dialogRef.disableClose = false;
          // this.messageService.openSnackBar(res.message, 'warn');
          start = end;
          end = end + 150;
          if(end > this.finalPositionList.length) {
            end = this.finalPositionList.length;
          }
          this.importCc(start, end)
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  // importCc() {
  //   this.saveLoading = true;
  //   this.dialogRef.disableClose = true;
  //   var final : any = [];
  //   var start = 0;
  //   var end = 150;
  //   for(var i = start; i < end; i++) {
  //     final.push(this.finalPositionList[i]);
  //   }
  //   var data = {
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.ccForm.orgid,
  //     // "positionid": this.ccForm.positionid,
  //     name: '',
  //     department: '',
  //     division: '',
  //     positionlist: this.finalPositionList,
  //   };

  //   this.kunyekService.createPosition(data).subscribe(
  //     (res: any) => {
  //       if (res.returncode == '300') {
  //         this.getCcList();
  //         this.cancel();
  //         this.saveLoading = false;
  //         this.dialogRef.disableClose = false;
  //         this.messageService.openSnackBar('Saved successfully.', 'success');
  //       } else {
  //         this.saveLoading = false;
  //         this.dialogRef.disableClose = false;
  //         this.messageService.openSnackBar(res.message, 'warn');
  //       }
  //     },
  //     (err) => {
  //       this.saveLoading = false;
  //       this.dialogRef.disableClose = false;
  //     }
  //   );
  // }

  downloadSampleFile() {
    window.open(this.allinoneService.bucketUrl + 'templates/PositionSampleTemplate.xlsx', '_blank');
  }
}
