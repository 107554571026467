import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs';
import { ClasscodeComponent } from 'src/app/admin/classcode/classcode.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-logistics-addbatch',
  templateUrl: './logistics-addbatch.component.html',
  styleUrls: ['./logistics-addbatch.component.scss'],
})
export class LogisticsAddbatchComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  formSubmitted: boolean = false;
  gettingOrder: boolean = false;
  showorder: boolean = false;
  showOrderList: boolean = false;
  showOrderDetails: boolean = false;
  showDetails: boolean = false;
  isAll: boolean = false;
  order: any;
  orderSubscription!: Subscription;

  selectedOrder: any = [];
  end = '';
  orderSearch = '';
  mytimeout: any;
  orderForm = {
    domainid: this.allinoneService.getDomain().domainid,
    orgid: this.allinoneService.selectedOrg,
    status: '',
    searchorder: '',
    end: '',
  };
  batchid = 'BG9593703';
  orders: any = [];

  selectedImageUrl: string = '';
  selectedImageIndex: number = 0;

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'New',
      value: '001',
    },
    {
      name: 'Pending',
      value: '002',
    },
    {
      name: 'On the way',
      value: '003',
    },
    {
      name: 'Transit',
      value: '004',
    },
    {
      name: 'Delivered',
      value: '005',
    },
    {
      name: 'Approved',
      value: '006',
    },
  ];

  constructor(
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public route: ActivatedRoute,
    private dialogRef: MatDialogRef<ClasscodeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedOrder = JSON.parse(JSON.stringify(data.orderlist));
    this.showDetails = data.showDetails;
    this.order = data.order;
  }
  ngOnDestroy(): void {
    this.orderSubscription && this.orderSubscription.unsubscribe();
  }

  ngOnInit(): void {
    // this.selectedOrder = this.selectedOrder.filter(
    //   (order: any) => order.checked
    // );
    if (!this.showDetails) {
      this.getOrder();
    } else {
      this.showOrderDetails = true;
      this.showDetails = true;
      this.order.statusName = this.statusList.find(
        (item: any) => item.value == this.order.status
      )?.name;
      if (this.order.image.imgurl.length > 0) {
        this.selectedImageUrl = this.order.image.imgurl[0];
        this.selectedImageIndex = 0;
      }
    }
  }

  onScrolledDown() {
    if (!this.isAll) {
      this.getOrder();
    }
  }

  goBack() {
    this.showDetails = false;
    this.showOrderDetails = false;
  }
  viewOrderDeails(index: any) {
    this.showOrderDetails = true;
    this.showDetails = false;
    this.order = this.orders[index];
    this.order.statusName = this.statusList.find(
      (item: any) => item.value == this.order.status
    )?.name;
  }
  close() {
    this.dialogRef.close({ status: false, data: [] });
  }
  save() {
    this.dialogRef.close({ status: true, data: this.selectedOrder });
  }
  selectOrder(event: any, order: any) {
    var index = this.orders.findIndex((od: any) => od.orderno == order.orderno);
    if (index != -1) {
      this.orders[index].checked = !this.orders[index].checked;
    }
    if (event.target.checked) {
      var index = this.selectedOrder.findIndex(
        (od: any) => od.orderno == order.orderno
      );
      if (index == -1) {
        order.checked = true;
        this.selectedOrder.push(order);
      } else {
        this.selectedOrder[index].checked = true;
      }
    } else {
      var index = this.selectedOrder.findIndex(
        (od: any) => od.orderno == order.orderno
      );
      if (index != -1) {
        this.selectedOrder[index].checked = false;
      }
    }
  }
  searchChange() {
    this.end = '';
    this.orders = [];
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.getOrder();
    }, 500);
  }

  getOrder() {
    this.gettingOrder = true;
    const data = {
      domainid: this.orderForm.domainid,
      orgid: this.orderForm.orgid,
      status: '',
      searchorder: this.orderSearch,
      end: this.end,
      batch: true,
    };
    this.orderSubscription && this.orderSubscription.unsubscribe();

    this.orderSubscription = this.kunyekService
      .getLogisticsOrders(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            // this.orders
            var temp = res.list;
            if (res.list.length < 10) {
              this.isAll = true;
            }
            this.end = res.end;
            if (this.selectedOrder.length > 0) {
              for (var i = 0; i < temp.length; i++) {
                var res = this.selectedOrder.filter(
                  (x: any) => x.orderno == temp[i].orderno && x.checked
                );
                if (res.length > 0) {
                  temp[i].checked = true;
                }
              }
            } else {
              temp = temp.map((item: any) => ({
                ...item,
                checked: false,
              }));
            }
            this.orders.push.apply(this.orders, temp);
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingOrder = false;
        },
        (err) => {
          this.gettingOrder = false;
        }
      );
  }

  nextImage(imgList: any) {
    if (this.selectedImageIndex < imgList.length - 1) {
      this.selectedImageIndex += 1;
    } else {
      this.selectedImageIndex = 0;
    }
  }
  previousImage(imgList: any) {
    if (this.selectedImageIndex > 0) {
      this.selectedImageIndex -= 1;
    } else {
      this.selectedImageIndex = imgList.length - 1;
    }
  }
}
