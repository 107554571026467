<div class='container-fluid'>
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb s_breadcrumb="Leave Opening"
        [t_breadcrumb]="'History - ' + (username ? username + ' (' +userid+')' : userid)" (emit)="goToLeaveOpening()">
      </app-hcm-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb [s_breadcrumb]="'Leave Opening'"
        [t_breadcrumb]="'History - ' + (username ? username + ' (' +userid+')' : userid)" (emit)="goToLeaveOpening()">
      </app-office-breadcrumb>
    </ng-container> -->

    <app-team-breadcrumb [s_breadcrumb]="'Leave Opening'"
        [t_breadcrumb]="'History - ' + (username ? username + ' (' +userid+')' : userid)" (emit)="goToLeaveOpening()">
      </app-team-breadcrumb>

    <div class="mb-3">
      <div class="d-flex flex-wrap justify-content-start align-items-center ">
        <!-- <div class="me-3">
          <input type="date" class="form-control report-input-box" id="startdate" name="startdate" max="9999-12-31"
            [(ngModel)]="startdate" (change)="setLastDat()">
        </div>
        <div class="">
          <input type="date" class="form-control report-input-box" id="enddate" name="enddate" [(ngModel)]="enddate"
            [min]="startdate" [max]="minDate">
        </div>

        <div class="line g-0"></div> -->

        <div class="input-group" style="width: 300px !important;">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Leave Type/Modified By"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="dropdown ms-2">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="getHistory()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>

        <div class="line "></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{(historyList| filter:searchText:'leaveopeninghistory').length}}</span>
        </div>
      </div>
    </div>

    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-3 col-md-3">Leave Type</td>
            <td scope="col" class="col-lg-3 col-md-3">Brought Forward</td>
            <td scope="col" class="col-lg-3 col-md-3">New Brought Forward</td>
            <td scope="col" class="col-lg-3 col-md-3">Opening</td>
            <td scope="col" class="col-lg-3 col-md-3">New Opening</td>
            <td scope="col" class="col-lg-3 col-md-3">Modified By</td>
            <td scope="col" class="col-lg-3 col-md-3">Modified Date</td>
          </tr>
        </thead>

        <tbody *ngIf="gettingHistory">
          <tr>
            <td colspan="7" class="nodata">
              <div class="spinner-border" user="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="!gettingHistory">
          <tr *ngIf="historyList.length == 0">
            <td colspan="7" class="nodata">Empty</td>
          </tr>

          <tr
            *ngFor="let history of historyList| filter:searchText:'leaveopeninghistory' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage}; let index = index; trackBy: trackByKey">
            <td class="td-data">
              <span [title]='history.leavetype'>{{history.leavetype}}</span>
            </td>
            <td class="td-data">
              <span [title]='history.oldvalue'>{{history.oldbroughtforward}}</span>
            </td>
            <td class="td-data">
              <span [title]='history.oldvalue'>{{history.newbroughtforward}}</span>
            </td>
            <td class="td-data">
              <span [title]='history.oldvalue'>{{history.oldopening}}</span>
            </td>
            <td class="td-data">
              <span [title]='history.oldvalue'>{{history.newopening}}</span>
            </td>
            <td class="td-data">
              <span [title]='history.modifiedby'>{{history.modifiedby}}</span>
            </td>
            <td class="td-data">
              <span [title]='history.modifieddate'>{{history.modifieddate}}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event"
        *ngIf="(historyList | filter:searchText:'leaveopeninghistory').length > itemPerPage" previousLabel="Prev"
        nextLabel="Next">
      </pagination-controls>
    </div>


  </div>