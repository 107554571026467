import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-attendance-qr-view',
  templateUrl: './attendance-qr-view.component.html',
  styleUrls: ['./attendance-qr-view.component.scss'],
})
export class AttendanceQrViewComponent implements OnInit, OnDestroy {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  attQrListLoading: boolean = true;
  connectSocketLoading: boolean = true;
  getQrLoading: boolean = true;
  qrexpired: boolean = false;

  organizations: any = [];
  attendanceQrInfo: any;

  domainid: string = '';
  orgid: string = '';
  qrid: string = '';
  showhistory : boolean = false;
  userid: string = '';
  atoken: string = '';
  qrname: string = '';
  iotype: string = 'time';
  locid: string = '';
  uniquekey: string = '';
  connectionid: any = '';
  statusInterval: any;
  intervalcount: number = 0;

  qrurl: string = '';
  scanned: boolean = false;
  scanusername: string = '';
  scanuserpf: string = '';
  scanuserid: string = '';
  scanemployeeid: string = '';
  scantype: string = '';
  scandate: string = '';
  scantime: string = '';
  scaneid: string = '';
  rank : string = '';
  iserror: boolean = false;
  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = '' + this.d.getFullYear() + this.month + this.day;

  sectionList : any = [];
  currentScreenSize : any = '';

  subscriptions = new SubSink();

  private socket: WebSocket | undefined;
  // socket = io("https://api.socket.connectportal.cloud/")
  public connectionStatus: string = 'disconnected';
  public receivedMessage: string = '';

  private accesskey: string = 'a3VueWVrQHMzcnZpYzMtS0VZZjByYWNjZXNza2V5';

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.allinoneService.navbar = false;
    // this.socket.on("connection", () => {
    //   console.log(this.socket.id); // x8WIv7-mJelg7on_ALbx
    // });
    // this.socket.on("message", (msg) => {
    //   console.log(msg)
    // })
  }
  ngOnDestroy(): void {
    this.socket?.close(3001);
    clearInterval(this.statusInterval);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.currentScreenSize = window.innerWidth;
  }

  ngOnInit(): void {
    // this.qrid = this.allinoneService.decrypt(this.route.snapshot.params['qrid']);
    // this.organizations = this.allinoneService.orgs;
    // this.orgid = this.allinoneService.selectedOrg
    //   ? this.allinoneService.selectedOrg
    //   : this.organizations[0].orgid;
    var tempdomainid = '';
    var tempqrid = '';
    var temporgid = '';
    var tempuserid = '';
    var tempatoken = '';
    this.currentScreenSize = window.innerWidth;


    try {
      tempqrid = this.route.snapshot.params['qrid'];
      temporgid = this.route.snapshot.params['orgid'];
      // tempuserid = this.route.snapshot.params['userid'];
      // tempatoken = this.route.snapshot.params['atoken'];
      tempdomainid = this.route.snapshot.params['domainid'];
      if (tempdomainid != '' || tempdomainid != null) {
        this.domainid = this.allinoneService.decrypt(tempdomainid);
      } else {
        this.iserror = true;
        return;
      }
      if (tempqrid != '' || tempqrid != null) {
        this.qrid = this.allinoneService.decrypt(tempqrid);
      } else {
        this.iserror = true;
        return;
      }
      if (temporgid != '' || temporgid != null) {
        this.orgid = this.allinoneService.decrypt(temporgid);
      } else {
        this.iserror = true;
        return;
      }
      // if (tempuserid != '' || tempuserid != null) {
      //   this.userid = this.allinoneService.decrypt(tempuserid);
      // } else {
      //   this.iserror = true;
      //   return;
      // }
      // if (tempatoken != '' || tempatoken != null) {
      //   this.atoken = this.allinoneService.decrypt(tempatoken);
      // } else {
      //   this.iserror = true;
      //   return;
      // }
    } catch (e) {
      this.iserror = true;
      return;
    }
    // || this.userid == '' || this.atoken == ''
    if (this.domainid == '' || this.orgid == '' || this.qrid == '') {
      this.iserror = true;
      return;
    } else {
      const tempUserData = sessionStorage.getItem(this.allinoneService.encrypt('scannedusers'));
      if(tempUserData){
        this.sectionList = JSON.parse(tempUserData);
        console.log("Scanned user data ====>");
        console.log(this.sectionList);
      }
      this.getAttendanceQrList();
    }
  }

  goToAttQR() {
    this.router.navigateByUrl('/attendanceqr'); 
  }

  connectWebSocket() {
    // this.scanned = false;
    this.connectSocketLoading = true;
    this.qrexpired = false;
    // const webSocketURL = 'wss://<YOUR_API_GATEWAY_ID>.execute-api.<YOUR_REGION>.amazonaws.com/<STAGE_NAME>';
    const webSocketURL = this.allinoneService.socketurl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      this.socket?.send('001');
      this.connectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      console.log('Received message:', event.data);
      this.receivedMessage = event.data;
      console.log(event.data);

      var tempData = JSON.parse(event.data);

      // Open
      console.log('Change data');

      if (tempData.message == '001' || tempData.message == 'Forbidden') {
        console.log('con 1');
        this.connectionid = tempData.connectionId;
        this.getQr();
      } else {
        this.intervalcount = 0;
        clearInterval(this.statusInterval);
        var message =
          tempData.scanusername +
          (tempData.scanuserid ? ' (' + tempData.scanuserid + ')' : '') +
          ' has scanned the QR.';
        this.messageService.openSnackBar(message, 'success');
        this.clearData();
        this.scanned = true;
        this.scanusername = tempData.scanusername ? tempData.scanusername : '';
        this.scanuserid = tempData.scanuserid ? tempData.userid : '';
        this.scaneid = tempData.scanuserid ? tempData.scanuserid : '';
        this.scanuserpf = tempData.image ? tempData.image : '';
        this.scantype = tempData.type;
        this.scandate = tempData.date;
        this.scantime = tempData.time;
        this.rank = tempData.rank;
        var tempSectionData = {
          username : this.scanusername,
          userid : this.scanuserid,
          profile : this.scanuserpf,
          type : this.scantype,
          date : this.scandate,
          time : this.scantime,
          employeeid: this.scaneid,
          rank : this.rank
        }
        this.sectionList.unshift(tempSectionData);
        console.log('Your section list');
        
        console.log(this.sectionList);
        var data = JSON.stringify(this.sectionList);
        sessionStorage.clear();
        sessionStorage.setItem(this.allinoneService.encrypt('scannedusers'), data);
        // this.getScanedUser();
        setTimeout(() => {
          this.scanned = false;
        }, 5000);
        this.socket?.close(3001);
        this.connectWebSocket();
      }
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.connectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.connectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectWebSocket();
      }
    });
  }

  clearData() {
    this.scanusername = '';
    this.scanuserid = '';
    this.scaneid = '';
    this.scanuserpf = '';
    this.scantype = '';
    this.scandate = '';
    this.scantime = '';
  }

  // getScanedUser() {
  //   var data = {
  //     'date' : this.todayDate,
  //     'orgid' : this.orgid
  //   }
  //   console.log(data);
    
  //   this.kunyekService.getScanedUser(data).subscribe(
  //     (res:any) => {
  //       console.log(res);
  //       if(res.returncode == '300') {
  //         this.sectionList = res.list;
  //       } else {
  //         this.messageService.openSnackBar(
  //           res.message || res.status || 'Server Error',
  //           'warn'
  //         );
  //       }
  //     },
  //     (err:any) => {
  //       this.messageService.openSnackBar('Server Error',
  //         'warn'
  //       );
  //     }
  //   )
  // }

  getQr() {
    this.getQrLoading = true;
    const data = {
      qrid: this.qrid,
      type: '001',
      connectionId: this.connectionid,
      orgid: this.orgid,
      domainid: this.domainid,
      accesskey: this.accesskey
    };
    this.subscriptions.sink = this.kunyekService.getSocketQr(data).subscribe(
      (res: any) => {
        console.log('qr getting completed.');
        if (res.returncode == '300') {
          this.uniquekey = res.uniquekey;
          this.qrurl = JSON.stringify({
            type: '5',
            iotype: this.iotype,
            locid: this.locid,
            qrid: this.qrid,
            uniquekey: this.uniquekey,
            orgid: this.orgid,
            connectionid: this.connectionid,
          });
          this.statusInterval = setInterval(() => {
            console.log('interval started');
            if(this.intervalcount == 3){
              this.qrexpired = true;
              clearInterval(this.statusInterval);
              this.socket?.close(3001);
            }
            else{
              this.intervalcount += 1;
              this.renewQr(); 
            }
            // 3 * 60000
          }, 3 * 60000);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          this.iserror = true;
        }
        this.getQrLoading = false;
      },
      (err) => {
        this.iserror = true;
      }
    );
  }

  renewQr() {
    // this.getQrLoading = true;
    const data = {
      connectionId: this.connectionid,
      uniquekey: this.uniquekey,
      qrid: this.qrid,
      orgid: this.orgid,
      domainid: this.domainid,
      accesskey : this.accesskey
    };
    this.subscriptions.sink = this.kunyekService.renewSockerQr(data).subscribe(
      (res: any) => {
        console.log('renew qr res');
        console.log(res);
        if (res.returncode == '300') {
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
          this.iserror = true;
        }
        this.getQrLoading = false;
      },
      (err) => {
        this.iserror = true;
      }
    );
  }

  getAttendanceQrList() {
    this.attQrListLoading = true;
    const data = {
      orgid: this.orgid,
      qrid: this.qrid,
      accesskey : this.accesskey,
      domainid: this.domainid
    };

    this.subscriptions.sink = this.kunyekService
      .getAttendanceQrDetails(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.attendanceQrInfo = res.datalist[0];
            this.qrname = this.attendanceQrInfo.name;
            this.locid = this.attendanceQrInfo.locid;
            this.qrid = this.attendanceQrInfo.qrid;
            this.showhistory = this.attendanceQrInfo.showhistory;
            this.iotype = this.attendanceQrInfo.defaulttype || 'time';
            // this.qrurl = JSON.stringify({'type' : '5', 'iotype' : this.iotype, 'locid' : this.locid, 'qrid' : this.qrid, 'uniquekey' : '', 'orgid' : this.orgid});
            this.connectWebSocket();
          } else {
            this.iserror = true;
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.attQrListLoading = false;
        },
        (err) => {
          this.iserror = true;
          this.attQrListLoading = false;
        }
      );
  }

  changeIotype(type: string) {
    this.iotype = type;
    this.qrurl = JSON.stringify({
      type: '5',
      iotype: this.iotype,
      locid: this.locid,
      qrid: this.qrid,
      uniquekey: this.uniquekey,
      orgid: this.orgid,
      connectionid: this.connectionid,
    });
    this.attQrListLoading = true;
    console.log(this.qrurl);
    setTimeout(() => {
      this.attQrListLoading = false;
    }, 3000);
  }

  refresh(){
    this.intervalcount =0;
    this.connectWebSocket();
  }
}
