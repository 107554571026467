import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-member-paymentform',
  templateUrl: './member-paymentform.component.html',
  styleUrls: ['./member-paymentform.component.scss']
})
export class MemberPaymentformComponent implements OnInit {

  @Input('currentMember') currentMember: any;
  @Output('closeForm') closeForm = new EventEmitter<any>();

  memberinfo: any;
  fees: any;
  bfees: any;
  totalAmount = 0;
  bmembers: Array<any> = [];
  monthlyfee: any;
  bmonthlyfee: any;
  isLoading: boolean = false;
  memberAMonthlyfeeCalculated: number = 0
  memberBMonthlyfeeCalculated: number = 0
  monthlyFeeType: number = 0

  memberATotalFees: number = 0
  memberBTotalFees: number = 0

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  twenveDate = this.allinoneService.formatDBToShowInEdit(
    this.getYearFromNow()
  )


  staffname: string = ''
  loggedStaffName: string = ''

  currency: string = 'MMK'
  paymentmethod: string = ''

  memberForm: any = {
    startdate: this.currentDate,
    enddate: this.twenveDate,
    amount: 0
  }

  startDate: Date | undefined = new Date();
  endDate: Date | undefined;
  monthsDifference: number = 0;

  payLoading: boolean = false

  currencyList: string[] = [
    'MMK',
    'USD'
  ]

  paymentMethods: string[] = [
    'KBZ pay',
    'MPU',
    'Cash'
  ]


  memberAandBtotalAmount: number = 0

  tempfilename: any = "";
  invoiceAttachment?: any;
  tempProfileImage: any;
  reader = new FileReader()
  fileIsSelected: boolean = false;

  breadcrumbs: any[] = [
    { name: 'Payment', url: 'payment', isActive: true },
    { name: 'Member Payment', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public allinoneService: AllInOneService,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.getMemberInfo();
    this.loggedStaffName = this.allinoneService.getKunyekUserName();
  }

  onFileChanged(event: any) {
    const file = event.target?.files[0];
    if (file) {
      this.invoiceAttachment = file;
    }
  }

  async uploadFiles() {
    const defaultpath = 'kokaimember/members';

    if (!this.tempfilename) {
      const date = new Date();
      const millisecond = date.getMilliseconds();
      this.tempfilename = this.datepipe.transform(date, 'yyyyMMddhhmmss' + millisecond);
    }

    if (this.invoiceAttachment) {
      const profilepath = `${defaultpath}/${this.tempfilename}/${this.invoiceAttachment.name}`;
      const upload_aws = await this.allinoneService.uploadAnswerFile(this.invoiceAttachment, profilepath);
      if (!upload_aws) {
        this.messageService.openSnackBar('Unknown error occurred while uploading your files.', 'warn');
        return;
      }
      this.tempProfileImage = profilepath;
    }
  }



  getMemberInfo() {
    const memberId = this.currentMember.syskey;
    this.isLoading = true;
    this.kunyekService.getMemberInfo({ member_id: memberId }).subscribe(res => {
      if (res.returncode !== '300') {
        this.isLoading = false;
        return
      };

      this.isLoading = false;
      const memberData = res.data;
      if (memberData.paymentstatus == false) {
        this.staffname = this.loggedStaffName;
      }
      this.memberinfo = memberData;

      const monthlyFee = memberData.fees[0].monthly_detail.find((fee: any) => fee.feeType.description === 'Monthly Fee');
      const bMonthlyFee = memberData.bfees[0].monthly_detail.find((fee: any) => fee.feeType.description === 'Monthly Fee');

      this.monthlyfee = monthlyFee.amount
      this.bmonthlyfee = bMonthlyFee.amount
      this.fees = memberData.fees.map((fee: any) => {
        return {
          ...fee,
          monthly_detail: fee.monthly_detail.filter((detail: any) => detail.feeType.description !== 'Monthly Fee')
        };
      });

      this.bfees = memberData.bfees.map((fee: any) => {
        return {
          ...fee,
          monthly_detail: fee.monthly_detail.filter((detail: any) => detail.feeType.description !== 'Monthly Fee' && detail.feeType.description !== 'Membership Fee')
        };
      });

      this.bmembers = memberData.bmembers.map((b: any, index: number) => ({
        member_id: b.syskey,
        name: b.name,
        phone: b.phone,
        memberType: b.memberType,
        startdate: this.currentDate,
      }));

      this.endDate = new Date();
      this.endDate.setFullYear(this.endDate.getFullYear() + 1);
      this.updateMonthsDifference()
      this.calculateMonthsBetweenDates(new Date(), this.endDate)
      this.calcMemberATotal()
      this.calcMemberBTotal()

      const bmonthlyFee = memberData.bfees[0].monthly_detail.find((fee: any) => fee.feeType.description === 'Monthly Fee');
    });
  }

  async handlePay() {
    this.payLoading = true;
    await this.uploadFiles()
    const startyear = this.startDate!.getUTCFullYear();
    const startmonth = String(this.startDate!.getUTCMonth() + 1).padStart(2, '0');
    const startday = String(this.startDate!.getUTCDate()).padStart(2, '0');

    // Format as yyyy-mm-dd
    const startformattedDate = `${startyear}${startmonth}${startday}`;

    const endyear = this.endDate!.getUTCFullYear();
    const endmonth = String(this.endDate!.getUTCMonth() + 1).padStart(2, '0');
    const endday = String(this.endDate!.getUTCDate()).padStart(2, '0');

    // Format as yyyy-mm-dd
    const endformattedDate = `${endyear}${endmonth}${endday}`;

    const reqObjBMembers = this.bmembers.map((bmember: any) => ({
      ...bmember,
      startdate: startformattedDate,
      enddate: endformattedDate,
      total_amount: this.memberBTotalFees
    }));

    const reqObj = {
      usersyskey: this.memberinfo.syskey,
      amember: {
        startdate: startformattedDate,
        enddate: endformattedDate,
        total_amount: this.memberATotalFees,
      },
      totalAmount: this.memberAandBtotalAmount,
      bmembers: reqObjBMembers,
      paymentmethod: this.paymentmethod,
      currency: this.currency,
      cashby: this.staffname,
      tempfilename: this.tempfilename,
      attachment: this.invoiceAttachment?.name,
    }

    this.kunyekService.payfee(reqObj).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.messageService.openSnackBar("Successfully", "success");
          this.payLoading = false;
          this.handleCloseForm();
        } else {
          this.messageService.openSnackBar("Server Error", "warn");
        }

      }
    })
  }

  handleCloseForm() {
    this.closeForm.emit();
  }

  getYearFromNow() {
    let today = new Date();
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth();
    let newMonth = currentMonth + 12;
    let futureYear = currentYear + Math.floor(newMonth / 12);
    let futureMonth = newMonth % 12;
    let futureDate = new Date(today);
    futureDate.setFullYear(futureYear);
    futureDate.setMonth(futureMonth);
    let year = futureDate.getFullYear();
    let month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
    let day = futureDate.getDate().toString().padStart(2, '0');
    return `${year}${month}${day}`;
  }

  onStartDateChange(event: any) {
    this.startDate = new Date(event);
    this.updateMonthsDifference();
  }

  onEndDateChange(event: any) {
    this.endDate = new Date(event);
    this.updateMonthsDifference();
  }

  updateMonthsDifference() {
    if (this.startDate && this.endDate) {

      this.monthsDifference = this.calculateMonthsBetweenDates(this.startDate, this.endDate);
    }
    this.calcMemberATotal()
    this.calcMemberBTotal()
    this.memberAandBtotalAmount = this.memberATotalFees + (this.bmembers.length * this.memberBTotalFees)
  }

  calculateMonthsBetweenDates(startDate: Date, endDate: Date): number {
    const start = moment(startDate);
    const end = moment(endDate);

    const yearDiff = end.year() - start.year();
    const monthDiff = end.month() - start.month();
    const totalMonths = yearDiff * 12 + monthDiff;

    if (end.date() < start.date()) {
      return totalMonths - 1;
    }
    this.memberAMonthlyfeeCalculated = this.monthlyfee * totalMonths
    if (this.memberAMonthlyfeeCalculated < 0) {
      this.memberAMonthlyfeeCalculated = 0
    }

    this.memberBMonthlyfeeCalculated = this.bmonthlyfee * totalMonths
    if (this.memberBMonthlyfeeCalculated < 0) {
      this.memberBMonthlyfeeCalculated = 0
    }
    return totalMonths;
  }

  calcMemberATotal() {
    this.memberATotalFees = 0
    this.memberinfo.fees.forEach((i: any) => {
      i.monthly_detail.forEach((j: any) => {
        if (j.feeType.description !== 'Monthly Fee') {
          this.memberATotalFees += parseInt(j.amount)
        }
      })
    })
    this.memberATotalFees += this.memberAMonthlyfeeCalculated
  }

  calcMemberBTotal() {
    this.memberBTotalFees = 0
    this.memberinfo.bfees.forEach((i: any) => {
      i.monthly_detail.forEach((j: any) => {
        if (j.feeType.description !== 'Monthly Fee' && j.feeType.description !== 'Membership Fee') {
          this.memberBTotalFees += parseInt(j.amount)
        }
      })
    })
    this.memberBTotalFees += this.memberBMonthlyfeeCalculated
  }

}

