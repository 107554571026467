<div>
    <div>
        <ng-container *ngIf="!iserror">
            <!-- <div class="container-fluid">
                <div class="content"> -->
            <!-- <app-breadcrumb [s_breadcrumb]="'Attendance QR'" [t_breadcrumb]="qrname" (emit)="goToAttQR()"></app-breadcrumb> -->

            <div *ngIf="!showhistory; else history" class="qr-container">
                <div class="d-flex justify-content-center align-items-center h-100 w-100">
                    <div class="card card-body my-card px-5" style="max-width: max-content;">
                        <div class="title-container mb-3">
                            <div class="info" [matMenuTriggerFor]="menu">
                                {{iotype == 'time' ? 'Time In/Out' : iotype == 'checkin' ? 'Check In': 'Auto'}}
                            </div>
                            <mat-menu #menu="matMenu" class="attendance-menu" xPosition="before">
                                <div class="d-flex flex-column" style="padding: 0.5rem 1rem;
                                row-gap: 0.5rem;">

                                    <div>
                                        <label class="radio-inline">
                                            <input class="form-check-input me-2" type="radio" name="iotype" id="auto"
                                                [(ngModel)]="iotype" value="auto"
                                                (ngModelChange)="changeIotype('auto')">Auto

                                        </label>
                                    </div>
                                    <div>
                                        <label class="radio-inline">
                                            <input class="form-check-input me-2" type="radio" name="iotype" id="checkin"
                                                [(ngModel)]="iotype" value="checkin"
                                                (ngModelChange)="changeIotype('checkin')">Check In

                                        </label>
                                    </div>
                                    <div>
                                        <label class="radio-inline">
                                            <input class="form-check-input me-2" type="radio" name="iotype"
                                                id="timeinout" [(ngModel)]="iotype" value="time"
                                                (ngModelChange)="changeIotype('time')">Time
                                            In/Out
                                        </label>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                        <div *ngIf="!scanned; else scannedQr"
                            class="d-flex flex-column justify-content-center align-items-center">

                            <div class="qr">
                                <ng-container
                                    *ngIf="attQrListLoading || connectSocketLoading || getQrLoading || qrexpired; else qrTemplate">
                                    <div style="height: 300px; width: 300px;"
                                        class="d-flex align-items-center justify-content-center">
                                        <div class="spinner-border" role="status"
                                            *ngIf="attQrListLoading || connectSocketLoading || getQrLoading">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div *ngIf="qrexpired">
                                            <i class="fa fa-refresh cursor-pointer qrexpired" (click)="refresh()"></i>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #qrTemplate>
                                    <!-- <ngx-qrcode qrc-element-type="canvas" [elementType]="elementType"
                                        [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                        cssClass="attendance-qr-code-coustom"></ngx-qrcode> -->
                                    <!-- <ngx-qrcode qrc-element-type="canvas" [elementType]="elementType" [width]="300"
                                                    [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                                    ></ngx-qrcode> -->
                                    <ngx-qrcode *ngIf="currentScreenSize > 768" [margin]="0" [width]="300"
                                        qrc-element-type="canvas" [elementType]="elementType"
                                        [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                        cssClass=""></ngx-qrcode>
                                    <ngx-qrcode *ngIf="currentScreenSize < 769" [margin]="0" [width]="200"
                                        qrc-element-type="canvas" [elementType]="elementType"
                                        [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                        cssClass=""></ngx-qrcode>
                                    <!-- <ngx-qrcode *ngIf="currentScreenSize > 768" qrc-element-type="canvas" [elementType]="elementType" [width]="300"
                                        [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                        ></ngx-qrcode>
                                        <ngx-qrcode *ngIf="currentScreenSize < 768" qrc-element-type="canvas" [elementType]="elementType"
                                        [errorCorrectionLevel]="correctionLevel" [value]="qrurl" 
                                        ></ngx-qrcode> -->
                                </ng-template>
                            </div>
                            <!-- <div class="d-flex flex-column justify-content-center me-3">
                                <button class="btn btn-custom mb-3" (click)="changeIotype('time')">Time In/Out</button>
                                <button class="btn btn-custom mb-3" (click)="changeIotype('checkin')">Check In</button>
                                <button class="btn btn-custom" (click)="changeIotype('auto')">Auto</button>
                            </div> -->
                            <div class="mt-3">
                                <!-- <span *ngIf="!attQrListLoading && !scanned">{{qrname}} ({{attendanceQrInfo.locname}})</span> -->
                                <span *ngIf="!attQrListLoading && !scanned" style="font-size: 15px; font-weight: 600;">
                                    {{attendanceQrInfo.locname}} </span>
                            </div>
                        </div>

                        <ng-template #scannedQr>
                            <div class="user-info-container">
                                <div class="d-flex flex-column align-items-start justify-content-center">
                                    <div class="d-flex align-items-center justify-content-center flex-column">
                                        <div class="mb-3">
                                            <img [defaultImage]="'../../../assets/images/bg.jpg'"
                                                [lazyLoad]="scanuserpf" class="profile">
                                        </div>
                                        <div class="name mb-2">{{ scanusername || scanuserid }} {{scaneid ?
                                            '('+scaneid+')' :
                                            ''}}</div>
                                        <!-- <div class="userid">{{ scanuserid }}</div> -->
                                        <div class="userid mb-2">{{(allinoneService.formatDBToShowInEdit(scandate, '/')
                                            +' '+
                                            scantime) | date:'dd MMMM yyyy hh:mm a' }}</div>
                                        <div class="userid mb-2">
                                            {{attendanceQrInfo.locname}}
                                        </div>
                                        <div style="color: green;">
                                            {{ scantype == 'in' ? 'Time In' : scantype == 'out' ? 'Time Out' : 'Check In' }}
                                            Successfully
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <ng-template #history>
                <div *ngIf="showhistory " class="d-flex flex-wrap">
                    <div class="qr-container"
                        [ngClass]="{'qr-with-history-full' : sectionList.length <=0, 'qr-with-history' : sectionList.length > 0 }">
                        <div class="d-flex justify-content-center align-items-center w-100 h-100">
                            <div class="card card-body my-card px-5" style="max-width: max-content;">
                                <div class="title-container mb-3">
                                    <div class="info" [matMenuTriggerFor]="menu">
                                        {{iotype == 'time' ? 'Time In/Out' : iotype == 'checkin' ? 'Check In': 'Auto'}}
                                    </div>
                                    <mat-menu #menu="matMenu" class="attendance-menu" xPosition="before">
                                        <div class="d-flex flex-column" style="padding: 0.5rem 1rem;
                                        row-gap: 0.5rem;">

                                            <div>
                                                <label class="radio-inline">
                                                    <input class="form-check-input me-2" type="radio" name="iotype"
                                                        id="auto" [(ngModel)]="iotype" value="auto"
                                                        (ngModelChange)="changeIotype('auto')">Auto

                                                </label>
                                            </div>
                                            <div>
                                                <label class="radio-inline">
                                                    <input class="form-check-input me-2" type="radio" name="iotype"
                                                        id="checkin" [(ngModel)]="iotype" value="checkin"
                                                        (ngModelChange)="changeIotype('checkin')">Check In

                                                </label>
                                            </div>
                                            <div>
                                                <label class="radio-inline">
                                                    <input class="form-check-input me-2" type="radio" name="iotype"
                                                        id="timeinout" [(ngModel)]="iotype" value="time"
                                                        (ngModelChange)="changeIotype('time')">Time
                                                    In/Out
                                                </label>
                                            </div>
                                        </div>
                                    </mat-menu>

                                </div>
                                <div *ngIf="!scanned; else scannedQr"
                                    class="d-flex flex-column justify-content-center align-items-center">

                                    <div class="qr">
                                        <ng-container
                                            *ngIf="attQrListLoading || connectSocketLoading || getQrLoading || qrexpired; else qrTemplate">
                                            <div style="height: 300px; width: 300px;"
                                                class="d-flex align-items-center justify-content-center">
                                                <div class="spinner-border" role="status"
                                                    *ngIf="attQrListLoading || connectSocketLoading || getQrLoading">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                                <div *ngIf="qrexpired">
                                                    <i class="fa fa-refresh cursor-pointer qrexpired" (click)="refresh()"></i>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #qrTemplate>
                                            <!-- <ngx-qrcode qrc-element-type="canvas" [elementType]="elementType"
                                                [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                                cssClass="attendance-qr-code-coustom"></ngx-qrcode> -->
                                            <!-- <ngx-qrcode qrc-element-type="canvas" [elementType]="elementType" [width]="300"
                                                    [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                                    ></ngx-qrcode> -->
                                            <ngx-qrcode *ngIf="currentScreenSize > 768" [margin]="0" [width]="300"
                                                qrc-element-type="canvas" [elementType]="elementType"
                                                [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                                cssClass=""></ngx-qrcode>
                                            <ngx-qrcode *ngIf="currentScreenSize < 769" [margin]="0" [width]="200"
                                                qrc-element-type="canvas" [elementType]="elementType"
                                                [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                                cssClass=""></ngx-qrcode>
                                            <!-- <ngx-qrcode *ngIf="currentScreenSize > 768" qrc-element-type="canvas" [elementType]="elementType" [width]="300"
                                                    [errorCorrectionLevel]="correctionLevel" [value]="qrurl"
                                                    ></ngx-qrcode>
                                                    <ngx-qrcode *ngIf="currentScreenSize < 768" qrc-element-type="canvas" [elementType]="elementType"
                                                    [errorCorrectionLevel]="correctionLevel" [value]="qrurl" 
                                                    ></ngx-qrcode> -->
                                        </ng-template>
                                    </div>
                                    <!-- <div class="d-flex flex-column justify-content-center me-3">
                                        <button class="btn btn-custom mb-3" (click)="changeIotype('time')">Time In/Out</button>
                                        <button class="btn btn-custom mb-3" (click)="changeIotype('checkin')">Check In</button>
                                        <button class="btn btn-custom" (click)="changeIotype('auto')">Auto</button>
                                    </div> -->
                                    <div class="mt-3">
                                        <!-- <span *ngIf="!attQrListLoading && !scanned">{{qrname}}
                                            ({{attendanceQrInfo.locname}})</span> -->
                                        <span *ngIf="!attQrListLoading && !scanned"
                                            style="font-size: 15px; font-weight: 600;">
                                            {{attendanceQrInfo.locname}}</span>
                                    </div>
                                </div>
                                <ng-template #scannedQr>
                                    <div class="user-info-container">
                                        <div class="d-flex flex-column align-items-start justify-content-center">
                                            <div class="d-flex align-items-center justify-content-center flex-column">
                                                <div class="mb-3">
                                                    <img [defaultImage]="'../../../assets/images/bg.jpg'"
                                                        [lazyLoad]="scanuserpf" class="profile">
                                                </div>
                                                <div class="name mb-2">{{ scanusername || scanuserid }} {{scaneid ?
                                                    '('+scaneid+')' :
                                                    ''}}</div>
                                                <!-- <div class="userid">{{ scanuserid }}</div> -->
                                                <div class="userid mb-2">
                                                    {{(allinoneService.formatDBToShowInEdit(scandate, '/')
                                                    +' '+
                                                    scantime) | date:'dd MMMM yyyy hh:mm a' }}</div>
                                                <div class="userid mb-2">
                                                    {{attendanceQrInfo.locname}}
                                                </div>
                                                <div style="color: green;">
                                                    {{ scantype == 'in' ? 'Time In' : scantype == 'out' ? 'Time Out' :
                                                    'Check In' }}
                                                    Successfully
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="sectionList.length > 0 "> -->
                    <div class="p-3"
                        [ngClass]="{'history-container' : sectionList.length > 0, 'history-container-zero' :sectionList.length <=0  }">
                        <!-- <div class="title">
                                History
                            </div> -->
                        <div *ngIf="sectionList.length > 0">
                            <ng-container *ngFor="let user of sectionList;">
                                <div class="card card-body my-card mb-2">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img [defaultImage]="'../../../assets/images/bg.jpg'"
                                                [lazyLoad]="user.profile" class="history-profile">
                                        </div>
                                        <div class="d-flex flex-column w-100">
                                            <div
                                                class="d-flex align-items-center flex-wrap justify-content-between w-100">
                                                <div>
                                                    {{user.username ? user.username : user.userid}}
                                                </div>

                                                <div class="timeout" *ngIf="user.type == 'in'">
                                                    Time In
                                                </div>
                                                <div class="timeout" *ngIf="user.type == 'out'">
                                                    Time Out
                                                </div>
                                                <div class="timeout" *ngIf="user.type == 'checkin'">
                                                    Check In
                                                </div>
                                            </div>
                                            <div>
                                                {{user.employeeid}}
                                            </div>
                                            <div *ngIf="user.username != ''" class="userid">
                                                {{user.userid}}
                                            </div>
                                            <div *ngIf="user.rank !=''" class="userid">
                                                {{user.rank}}
                                            </div>
                                            <div>
                                                <!-- <div class="userid">{{(allinoneService.formatDBToShowInEdit(user.date, '/') +'
                                                        '+
                                                        user.time) | date:'dd MMMM yyyy hh:mm a' }}</div> -->
                                                <div class="userid">{{(allinoneService.formatDBToShowInEdit(user.date,
                                                    '/') +'
                                                    '+
                                                    user.time) | date: "hh:mm a 'at' dd MMMM" }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="sectionList.length == 0" class="no-scanned-users">
                            Empty
                        </div>
                    </div>
                    <!-- </div> -->

                </div>
            </ng-template>

        </ng-container>

        <ng-container *ngIf="iserror">
            <!-- <div class="container-fluid">
                <div class="content"> -->
            <div class="qr-container d-flex align-items-center justify-content-center">
                <strong>Invalid URL. Please double check the URL you entered.</strong>
            </div>
            <!-- </div>
            </div> -->
        </ng-container>
    </div>
</div>