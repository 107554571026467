import { Component} from '@angular/core';
import { Router } from '@angular/router';
import ListConfig from 'src/app/shared/interfaces/listconfig';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-special-swim-list',
  templateUrl: './special-swim-list.component.html',
  styleUrls: ['./special-swim-list.component.scss']
})
export class SpecialSwimListComponent {
  configs: ListConfig[] = [
    { title: 'အမည်', property: 'name' },
    { title: 'ဖုန်းနံပါတ်', property: 'phone' },
    { title: 'မွေးသက္ကရာဇ်', property: 'birthdate', type:'date' },
    { title: 'သင်ကြားမည့် ဆရာ', property: 'teacher' },
    { title: 'အသင်းဝင်အမှတ်', property: 'membershipnumber' },
    { title: 'ကျောင်းသားအမှတ်', property: 'studentnumber' },
    { title: 'အရပ်', property: 'height' },
    { title: 'အဖအမည်', property: 'fathername' },
    { title: 'အချိန်', property: 'classtime' }
];

  url = ['members/specialswim']
  searchName: string = '';
  searchText = "Search Special Swimmer"
  cb = this.kunyekService.getSpecialSwimmerList.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Special Swimmer', url: '', isActive: false }
  ];

  constructor(
    private router: Router,
    private kunyekService: KunyekService,
  ) { }

  previewSpecialSwimmer(id: string) {
    this.router.navigate([...this.url,'preview',id])

  }
}
