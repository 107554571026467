<!-- <perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true"
    fxFlex="auto" #perfectScroll> -->

<div class="container-fluid">
    <div class="content">


        <div>
            <app-crm-breadcrumb s_breadcrumb="Service Weekly Report">
            </app-crm-breadcrumb>



            <div class="d-flex flex-wrap mb-3">
                <!-- <div class="mb-3 me-2 " [matMenuTriggerFor]="productmenu">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                                mdbDropdownToggle
                                style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                                (click)="removeProduct()">
                                {{productname != 'product' ? productname : 'Product' }}
                            </button>
                        </div>
        
                        <mat-menu #productmenu="matMenu" xPosition="before">
                            <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                                <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="productsearch"
                                    style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                                    autocomplete="off">
                            </div>
                            <button class="mat-item" (click)="changeproduct('product')">-</button>
                            <button class="mat-item"
                                *ngFor="let proj of productListdata | crmFilter : productsearch:text1:text2:15;"
                                (click)="changeproduct(proj)">{{proj.name}}</button>
                        </mat-menu> -->

                <!-- <div class="date me-2">
                            <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="startdate">
                        </div>
                        <div class="date me-2">
                            <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate">
                        </div> -->
                <div class="me-2  all  mb-3">
                    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="startdate" 
                        step="1" style="border-color: #dcd8d8;min-width: 100px;">
                </div>
                <div class="me-2  all  mb-3">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddate" step="1"
                        style="border-color: #dcd8d8;min-width: 100px;">
                </div>
                <div class="me-2">
                    <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Search">
                        Search
                    </button>
                </div>
                <!-- <div class="">
                    <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                        matTooltip="Export Service Overage Report">
                        <img class="excel-icon" src="../../../assets/images/excel.png">Export
                    </button>

                </div> -->
            </div>




            <div>
                <!-- <div class="card card-body my-card" *ngIf="gettingData" style="flex-direction: column;"> -->
                <!-- <div class="d-flex justify-content-center align-items-center" style="height: 300px;">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div> -->
                <!-- </div> -->
                <div class="card card-body my-card">

                    <!-- <div>Test Data</div> -->

                    <!-- [ngStyle]="{'height': barChartData.length < 5 ? barChartData.length * 50 + 'px' : barChartData.length < 10 ? barChartData.length * 50 + 'px' : barChartData.length * 20 + 'px'}" -->
                    <div style="padding: 20px;border-radius: 20px;width: 400px;;height: 500px;background: white;">
                        <canvas id="myChart"></canvas>
                    </div>

                </div>

            </div>



        </div>






        <!-- <div *ngIf="summaryCheck"> -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- (summary)="summaryBack($event)" -->
        <!-- <app-crm-summary [checkOrg]="checkOrgB" [orgid]="crmForm.orgid" (crmcancel)="cancel()" (statussummary)="summaryBackStatus($event)">
                            </app-crm-summary> -->
        <!-- app-crm-summary -->
        <!-- </div> -->
    </div>


</div>
<!-- </perfect-scrollbar> -->