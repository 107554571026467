<div class="container-fluid">
    <div class="content">


        <div>

            <app-crm-breadcrumb *ngIf="!addgroup && !isEditing" s_breadcrumb="Customer Groups">
            </app-crm-breadcrumb>
            <app-crm-breadcrumb *ngIf="addgroup || isEditing" s_breadcrumb="Customer Groups"
                [t_breadcrumb]=" isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()">
            </app-crm-breadcrumb>



            <!--  (emit1)="cancels(2)"  -->
            <div *ngIf="!addgroup && !isEditing">

                <div class="d-flex flex-wrap">
                    <div class="showbtn me-auto  mb-3">
                        <button class="btn btn-custom" (click)="new()">
                            <i class="fa fa-plus me-1"></i> New
                        </button>
                    </div>
                </div>
                <div class=" w-100 d-flex flex-wrap me-auto ">

                    <div class="input-group me-2 mb-2" style="width: 300px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            [(ngModel)]="searchText" style="font-family: FontAwesome,Ubuntu;">
                    </div>
                    <div class="dropdown mb-2 me-2">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==itemPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>

                    <!-- me-2 -->
                    <div class="me-2 mb-2 ">
                        <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>

                    <div class="line mb-2 "></div>
                    <div class=" mb-2">
                        <button type="button " class="btn btn-custom " (click)="exportCustoemrGroup() "
                            matTooltip="Export Related Product To Excel " [disabled]="excelexportLoading">
                            <img *ngIf="!excelexportLoading" class="excel-icon " src="../../../assets/images/excel.png">
                            <span *ngIf="excelexportLoading" style="margin-right: 10px; "
                                class="spinner-border spinner-border-sm button-spinner " role="status "
                                aria-hidden="true "></span>
                            Export
                        </button>
                    </div>
                    <div class="line mb-2 "></div>
                    <div class="d-flex align-items-center me-auto mb-2 ">
                        <span class="total-count-text ">Total:&nbsp;</span> <span
                            class="total-count-num ">{{customergroupdata.length}}</span>
                    </div>

                    <div>

                    </div>
                </div>


                <div>
                    <div class="table-responsive">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-3 col-md-3">Group ID</td>
                                    <td scope="col" class="col-lg-3 col-md-3">Name</td>
                                    <td scope="col" class="col-lg-4 col-md-4">Customers</td>
                                    <td scope="col" class="d-flex justify-content-end">Action</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="4" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="customergroupdata.length == 0">
                                    <td colspan="4" class="nodata" *ngIf="!custoemrgroupview">Empty</td>
                                    <td style="color: red;font-size: 14px;" colspan="4" class="nodata" *ngIf="custoemrgroupview"><i class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}</td>
                                </tr>
                                <tr *ngFor="let item of customergroupdata | crmFilter :searchText:text1:text2:24">
                                    <td class="td-data">
                                        <span [title]="item.groupid" (click)="editCustomerEdit(item)"
                                            style="cursor: pointer;">{{item.groupid}}</span>
                                    </td>
                                    <td class="td-data">
                                        <span [title]="item.name" (click)="editCustomerEdit(item)"
                                            style="cursor: pointer;">{{item.name}}</span>
                                    </td>
                                    <td class="td-data" (click)="editCustomerEdit(item)" style="cursor: pointer;">
                                        <span [title]="item.cusotmernames">{{item.cusotmernames}}</span>
                                    </td>
                                    <td class="d-flex justify-content-end">
                                        <button matTooltip="Edit" class="btn btn-outline-primary"
                                            (click)="editCustomerEdit(item)">
                                            <i class='fa fa-edit'></i>
                                        </button>
                                        <button title="Delete" class="ms-2 btn btn-outline-danger"
                                            (click)="deleteCustomerGroup(item.groupid)">
                                            <i class='fa fa-trash-o'></i>
                                        </button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="addgroup && !isEditing">

                <div>
                    <div class="close-save-button mb-3">
                        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()"
                            *ngIf="!saveLoading">
                            Cancel
                        </button>
                        <app-button-loader (click)="submitCustomerGroup()" [button_text]="'Save'"
                            [load_button_text]="'Saving'" [isLoading]="saveLoading">
                        </app-button-loader>
                    </div>

                </div>
                <div class="row">


                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Name</label>

                        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="groupForm.name"
                            style="line-height: 1.8;">



                    </div>


                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Customers </label>
                        <ng-select style="min-height: 33px;" [ngModelOptions]="{standalone: true}" class="role-select"
                            [items]="customersList" bindLabel="name" placeholder="-" appendTo="body" [multiple]="true"
                            [(ngModel)]="ngcustomerlist" (change)="customerSelect($event)">

                        </ng-select>

                        <mat-progress-bar mode="indeterminate" *ngIf="gettingCustomers"></mat-progress-bar>
                        <div class="invalid-ngselect" *ngIf="ngcustomerlist.length == 0  && groupformSave">
                            <div>
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>

                    </div>
                </div>





            </div>
            <div *ngIf="!addgroup && isEditing">

                <div>
                    <div class="close-save-button mb-3">
                        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()"
                            *ngIf="!saveLoading">
                            Cancel
                        </button>
                        <app-button-loader (click)="submitCustomerGroup()" [button_text]="'Save'"
                            [load_button_text]="'Saving'" [isLoading]="saveLoading">
                        </app-button-loader>
                    </div>

                </div>
                <div class="row">


                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Name</label>

                        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="groupForm.name"
                            style="line-height: 1.8;">



                    </div>


                    <div class="mb-3 col-md-6">
                        <label for="name" class="form-label">Customer </label>
                        <ng-select [ngModelOptions]="{standalone: true}" class="role-select" [items]="customersList"
                            bindLabel="name" placeholder="-" appendTo="body" [multiple]="true"
                            [(ngModel)]="ngcustomerlist" (change)="customerSelect($event)">

                        </ng-select>

                        <mat-progress-bar mode="indeterminate" *ngIf="gettingCustomers"></mat-progress-bar>
                        <div class="invalid-ngselect" *ngIf="ngcustomerlist.length == 0  && groupformSave">
                            <div>
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>

                    </div>
                </div>





            </div>
        </div>




    </div>


</div>