import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { OfficeLeaveOpeningFormComponent } from '../office-leave-opening-form/office-leave-opening-form.component';
import { Router } from '@angular/router';
import { ChooseMemberComponent } from 'src/app/members/choose-member/choose-member.component';
@Component({
  selector: 'app-office-choose-members',
  templateUrl: './office-choose-members.component.html',
  styleUrls: ['./office-choose-members.component.scss'],
})
export class OfficeChooseMembersComponent implements OnInit {
  @ViewChild('chatPS') chatPS!: ElementRef;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  searchingCustomer: boolean = false;
  searchCustomerCancel: boolean = false;
  isLeaveOpening: boolean = false;
  customerSearch = '';
  _contactList: any = [];
  customerList: any = [];
  selectedUserList: any = [];
  gettingCustomerList: boolean = false;
  isAllUser: boolean = false;
  _isEnd: boolean = false;
  isAdmin: boolean = false;

  domainid: string = '';
  orgid: string = '';
  templateid: string = '';
  calendarid: string = '';

  startCount: any = 0;
  endCount: any = 30;

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;

  divisionList: Array<any> = ["All"];
  statusList: any = [
    {
      name: 'All',
      id: 'All',
    },
    {
      name: 'Active',
      id: '0',
    },
    {
      name: 'Inactive',
      id: '1',
    },
    {
      name: 'Blacklist',
      id: '13',
    },
  ];
  selelctedStatus: string = "0";
  selectedDivision: string = "All";
  selectedStatusName: string = 'Active';
  gettingMemberPosition: boolean = false;
  openfilter: boolean = false;


  operatorList = [
    { "code": "1", "name": "Equals", },
    { "code": "2", "name": "Greater than", },
    { "code": "3", "name": "Less than", },
    { "code": "4", "name": "Greater than or Equal", },
    { "code": "5", "name": "Less than or Equal", },
    { "code": "6", "name": "Between", },
  ];

  widthMap: any = {
    "1": 100,
    "2": 130,
    "3": 130,
    "4": 180,
    "5": 180,
    "6": 100
  }

  defalutAdvancedSearchOptions = [
    "Division",
    "Status",
    "Service Year",
  ];
  advancedSearchOptionsSet = new Set();
  remaingSearchOptions: Array<any> = [...this.defalutAdvancedSearchOptions]; //initial value is default options

  get advancedSearchOptions(): Array<any> {
    return Array.from(this.advancedSearchOptionsSet);
  }








  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<OfficeChooseMembersComponent>,
    private injector: Injector,
    // private dialog: MatDialog,
    private router: Router,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.isLeaveOpening = this.dialogData.isLeaveOpening;
    this.domainid = this.dialogData.domainid;
    this.orgid = this.dialogData.orgid;
    if (this.isLeaveOpening) {
      this.isAdmin = this.dialogData.isAdmin;
      this.templateid = this.dialogData.templateid;
      this.calendarid = this.dialogData.calendarid;
    } else {
      this.selectedUserList = JSON.parse(
        JSON.stringify(this.dialogData.userlist)
      );
    }
  }

  ngOnInit(): void {
    this.getMemberPosition();
    this.homesearchCustomer();
  }

  searchChange() {
    if (this.customerSearch.trim() == '') {
      this.isAllUser = false;
    }
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      // if (this.customerSearch.trim() != '') {
      this.customerList = [];
      this.chatPS.nativeElement.scrollTop = 0;
      this.startCount = 0;
      this.endCount = 30;
      // }
      this.homesearchCustomer();
    }, 500);
  }

  homesearchCustomer() {
    this.gettingCustomerList = true;
    const division = this.advancedSearchOptions.find(x => x.name == "Division");
    const service_year = this.advancedSearchOptions.find(x => x.name == "Service Year");
    const status = this.advancedSearchOptions.find(x => x.name == "Status");

    const data = {
      startcount: this.startCount,
      endcount: this.endCount,
      search: this.customerSearch,
      orgid: this.orgid,
      domainid: this.domainid,
      roleid: this.allinoneService.getSelectedRole(),
      division: division ? (division.value == 'All' ? "" : division.value) : "",
      service_year: service_year ? service_year : "",
      status: status ? (status.value == 'All' ? "" : status.value) : ""
    };

    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getOfficeMember(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.gettingCustomerList = false;
          if (res.list.length < 30) {
            this.isAllUser = true;
          }
          if (res['list'].length == 0) {
            this._isEnd = true;
          }
          if (this.startCount == 0 && this.endCount == 30) {
            this._contactList = [];
          }

          // Filter out items with transactiontypestatus: "1"
          var arrayContactList = res['list'];

          if (this.selectedUserList.length > 0) {
            arrayContactList.map((item: any) => {
              var checkexist = this.selectedUserList.find(
                (x: any) => x.userid == item.userid
              );
              if (checkexist) {
                item.checked = checkexist.checked;
              }
            });
          }
          for (var a = 0; a < arrayContactList.length; a++) {
            var c = 0;
            for (var b = 0; b < this._contactList.length; b++) {
              if (
                arrayContactList[a]['userid'] == this._contactList[b]['userid']
              ) {
                this._contactList[b] = arrayContactList[a];
                c = 1;
              }
            }
            if (c == 0) {
              this._contactList.push(arrayContactList[a]);
            }
          }

          var temp = res.list.filter((item: any) => item.transactiontypestatus !== "1");

          if (this.selectedUserList.length > 0) {
            for (var i = 0; i < temp.length; i++) {
              var res = this.selectedUserList.filter(
                (x: any) => x.primarykey == temp[i].primarykey && x.checked
              );
              if (res.length > 0) {
                temp[i].checked = true;
              }
            }
          } else {
            temp = temp.map((item: any) => ({
              ...item,
              checked: false,
            }));
          }

          this.customerList = this._contactList;
          console.log(this.customerList);

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingCustomerList = false;
      },
      (err) => {
        this.gettingCustomerList = false;
      }
    );

  }

  // homesearchCustomer() {
  //   // this.customerList = [];
  //   this.gettingCustomerList = true;
  //   const data = {
  //     startcount: this.startCount,
  //     endcount: this.endCount,
  //     search: this.customerSearch,
  //     orgid: this.orgid,
  //     domainid: this.domainid,
  //     roleid: this.allinoneService.getSelectedRole()
  //   };
  //   this.subscription && this.subscription.unsubscribe();
  //   this.subscription = this.kunyekService.getOfficeMember(data).subscribe(
  //     (res: any) => {
  //       if (res.returncode == '300') {
  //         this.gettingCustomerList = false;
  //         if (res.list.length < 30) {
  //           this.isAllUser = true;
  //         }
  //         if (res['list'].length == 0) {
  //           this._isEnd = true;
  //         }
  //         if (this.startCount == 0 && this.endCount == 30) {
  //           this._contactList = [];
  //         }
  //         var arrayContactList = res['list'];
  //         if (this.selectedUserList.length > 0) {
  //           // for (var a = 0; a < this.selectedUserList.length; a++) {
  //           //   for (var b = 0; b < arrayContactList.length; b++) {
  //           //     if (this.selectedUserList[a]['userid'] ==
  //           //       arrayContactList[b]['userid']) {
  //           //       arrayContactList[b]['select'] = true;
  //           //     }
  //           //   }
  //           // }

  //           arrayContactList.map((item: any) => {
  //             var checkexist = this.selectedUserList.find(
  //               (x: any) => x.userid == item.userid
  //             );
  //             if (checkexist) {
  //               item.checked = checkexist.checked;
  //             }
  //           });
  //         }
  //         for (var a = 0; a < arrayContactList.length; a++) {
  //           var c = 0;
  //           for (var b = 0; b < this._contactList.length; b++) {
  //             if (
  //               arrayContactList[a]['userid'] == this._contactList[b]['userid']
  //             ) {
  //               this._contactList[b] = arrayContactList[a];
  //               c = 1;
  //             }
  //           }
  //           if (c == 0) {
  //             this._contactList.push(arrayContactList[a]);
  //           }
  //         }

  //         var temp = res.list;
  //         if (this.selectedUserList.length > 0) {
  //           for (var i = 0; i < temp.length; i++) {
  //             var res = this.selectedUserList.filter(
  //               (x: any) => x.primarykey == temp[i].primarykey && x.checked
  //             );
  //             if (res.length > 0) {
  //               temp[i].checked = true;
  //             }
  //           }
  //         } else {
  //           temp = temp.map((item: any) => ({
  //             ...item,
  //             checked: false,
  //           }));
  //         }

  //         this.customerList = this._contactList;
  //         console.log(this.customerList);
  //       } else {
  //         this.messageService.openSnackBar(res.message, 'warn');
  //       }
  //       this.gettingCustomerList = false;
  //     },
  //     (err) => {
  //       this.gettingCustomerList = false;
  //     }
  //   );
  // }

  onScrolledDown() {
    if (!this.isAllUser && !this.gettingCustomerList) {
      this.startCount = this.startCount + 30;
      this.endCount = this.endCount + 30;
      this.homesearchCustomer();
    }
  }

  selectUser(event: any, user: any) {
    var index = this.customerList.findIndex(
      (cus: any) => cus.userid == user.userid
    );
    console.log(index);
    if (index != -1) {
      this.customerList[index].checked = !this.customerList[index].checked;
    }
    if (event.target.checked) {
      var index = this.selectedUserList.findIndex(
        (seluser: any) => seluser.userid == user.userid
      );
      if (index == -1) {
        user.checked = true;
        this.selectedUserList.push(user);
      } else {
        this.selectedUserList[index].checked = true;
      }
    } else {
      var index = this.selectedUserList.findIndex(
        (seluser: any) => seluser.userid == user.userid
      );
      if (index != -1) {
        // this.selectedUserList[index].checked = false;
        this.selectedUserList.splice(index, 1);
      }
    }
    console.log(this.selectedUserList);
  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    let finalList = [];
    finalList = this.selectedUserList.filter((user: { checked: boolean }) => {
      return user.checked == true;
    });
    console.log('fl');
    console.log(finalList);

    this.dialogRef.close({ data: finalList });
  }

  openLeaveOpeningForm(member: any) {
    let tempData = {
      userid: member.userid,
      username: member.username,
      orgid: this.orgid,
      openingid: '',
      templateid: this.templateid,
      calendarid: this.calendarid,
      isnew: true,
    };
    // let user = this.allinoneService.replaceAll(
    //   this.allinoneService.encrypt(JSON.stringify(tempData)),
    //   '/',
    //   '###'
    // );

    let user = encodeURIComponent(this.allinoneService.encrypt(JSON.stringify(tempData)));

    let url = (this.isAdmin ? this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/requestapproval' : 'office/hxm') +
      '/leaveopening/form/' + user
    console.log(url);
    this.dialogRef.close();
    this.router.navigateByUrl(url);
    // const dialog = this.dialog.open(OfficeLeaveOpeningFormComponent, {
    //   maxWidth: '95vw',
    //   width: '550px',
    //   minHeight: '606px',
    //   maxHeight: '95vh',
    //   panelClass: ['mat-typography', 'mat-dialog-height-transition'],
    //   closeOnNavigation: true,
    //   autoFocus: false,
    //   data: {
    //     user: member,
    //     domainid: this.domainid,
    //     orgid: this.orgid,
    //     templateid: this.templateid,
    //     calendarid: this.calendarid,
    //     isnew: true,
    //   },
    // });

    // dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.divisionList = [...this.divisionList, ...res.divisionlist];
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberPosition = false;
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  toggleFilter() {

    if (!this.openfilter) {
      this.addAdvacedSearch();
    } else {
      this.advancedSearchOptionsSet.clear();
      this.getRemaingSearchOptions();
      this.search();
    }

    this.openfilter = !this.openfilter;
  }



  addAdvacedSearch(value?: string) {
    // initalize the array
    if (!value) {
      this.advancedSearchOptionsSet.add({
        name: this.defalutAdvancedSearchOptions[0],
        value: "",
        subvalue: ""
      });
      return;
    }

    const filterdOptions = this.defalutAdvancedSearchOptions
      .filter(x => !Array.from(this.advancedSearchOptionsSet)
        .map((o: any) => o.name).includes(x));

    if (filterdOptions.length == 0) return;

    if (filterdOptions[0] == 'Service Year') {
      this.advancedSearchOptionsSet.add({
        name: filterdOptions[0],
        year: {
          start: "",
          end: "",
          operator: "1"
        },
        month: {
          start: "",
          end: "",
          operator: "1"
        }
      })
    } else {
      this.advancedSearchOptionsSet.add({
        name: filterdOptions[0],
        value: "",
        subvalue: ""
      });
    }


    this.getRemaingSearchOptions();
  }

  removeAdvancedSearch(value: string) {
    const options = this.advancedSearchOptions.find(x => x.name == value);
    console.log(options)
    this.advancedSearchOptionsSet.delete(options);
    if (this.advancedSearchOptions.length == 0) {
      this.openfilter = false;
    }
    this.getRemaingSearchOptions();
  }

  onSearchOptionChange(value: string, index: number) {
    const tem = this.advancedSearchOptions;

    if (value == 'Service Year') {
      tem[index] = {
        name: value,
        year: {
          start: "",
          end: "",
          operator: "1"
        },
        month: {
          start: "",
          end: "",
        }
      }
    } else {
      tem[index] = {
        name: value,
        value: ""
      };
    }
    this.advancedSearchOptionsSet = new Set(tem);
    this.getRemaingSearchOptions();
  }

  getRemaingSearchOptions() {
    this.remaingSearchOptions = this.defalutAdvancedSearchOptions.filter(x => !this.advancedSearchOptions.map((ad) => ad.name).includes(x));
  }

  search() {
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.customerList = [];
      this.chatPS.nativeElement.scrollTop = 0;
      this.startCount = 0;
      this.endCount = 30;
      this.homesearchCustomer();
    }, 500);
  }

}
