<app-crm-breadcrumb *ngIf="!addproject && !isproject && !product && !addproduct && !checkModeal"
    s_breadcrumb="Customers" [t_breadcrumb]="isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()">
</app-crm-breadcrumb>
<app-crm-breadcrumb *ngIf="!addproject && !isproject && product && !addproduct && !checkModeal"
    [s_breadcrumb]="'Customers'" [t_breadcrumb]="cuForm.name != '' ? cuForm.name : ''"
    [f_breadcrumb]="'Related Products'" (emit)="cancelFunction()" (emit1)="cancels(2)">
</app-crm-breadcrumb>
<app-crm-breadcrumb *ngIf="!addproject && isproject && !product && !addproduct && !checkModeal"
    [s_breadcrumb]="'Customers'" [t_breadcrumb]="cuForm.name != '' ? cuForm.name : ''"
    [f_breadcrumb]="'Related Projects'" (emit)="cancelFunction()" (emit1)="cancels(3)">
</app-crm-breadcrumb>
<app-crm-breadcrumb *ngIf="addproject && !isproject && !product && !addproduct && !checkModeal"
    [s_breadcrumb]="'Customers'" [t_breadcrumb]="cuForm.name != '' ? cuForm.name : ''"
    [f_breadcrumb]="'Related Projects'" (emit)="cancelFunction()" (emit1)="cancels(4)">
</app-crm-breadcrumb>
<app-crm-breadcrumb *ngIf="!isproject && !product && addproduct && !checkModeal" [s_breadcrumb]="'Customers'"
    [t_breadcrumb]="cuForm.name != '' ? cuForm.name : ''" [f_breadcrumb]="'Related Products'" [fi_breadcrumb]="'New'"
    (emit)="cancelFunction()" (emit1)="cancels(3)" (emit2)="cancels(1)">
</app-crm-breadcrumb>
<div *ngIf="isExcel == false && product == false && !addproduct && isproject == false && !addproject">


    <div class="close-save-button mb-3">
        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
            Cancel
        </button>
        <app-button-loader *ngIf="saveCustomerCheck" class="me-2" (click)="submitCustomer()" [button_text]="'Save'"
            [load_button_text]="'Saving'" [isLoading]="saveLoading">
        </app-button-loader>
        <button type="button" class="btn btn-custom me-2" *ngIf="!saveCustomerCheck" disabled>
            Save
        </button>


        <div class="line " *ngIf="isEditing  && !checkModeal "></div>
        <button *ngIf="isEditing && !checkModeal" type="button" class="btn btn-custom me-2" (click)="goProduct()">
            Related Products
        </button>

        <div class="line " *ngIf="isEditing  && !checkModeal "></div>
        <button *ngIf="isEditing && !checkModeal" type="button" class="btn btn-custom me-2" (click)="goProject()">
            Related Projects
        </button>

        <button class="me-2" *ngIf="isEditing && customerCheck && !checkModeal && !checkservice" type="button"
            class="btn btn-custom" (click)="goServiceTicket()">
            Service Tickets
        </button>

    </div>
    <div class="row mb-3 ">
        <div class="col-md-6">
            <div class="d-flex ">
                <label for="name " class="form-label me-auto">Name</label>
                <label *ngIf="customernameList.length > 0" for="name" class="form-label" (click)="popupName()"
                    style="color: red;cursor: pointer;font-size: 10px !important;text-decoration: underline;">Similar
                    Customers </label>
            </div>
            <div class="input-group me-2 mb-2">
                <input type="text" class="form-control" id="name" name="name" [(ngModel)]="cuForm.name" required
                    #name="ngModel" [ngClass]="{ 'is-invalid': cuFormSubmitted && name.invalid }"
                    (change)="generateShortCode()" (keyup)="keyUp($event)" (keyup.enter)="onKeyenter($event)">
                <!-- (keypress)="keyPress($event)"  -->
                <button *ngIf="!getCustomer" class="input-group-text btn btn-custom" id="btnGroupAddon2"
                    (click)="onSearch()">
                    <mat-icon class="c-icon">search</mat-icon>
                </button>
                <button class="btn btn-custom" *ngIf="getCustomer">
                    <span style="border-radius: 50%;" class="spinner-border spinner-border-sm button-spinner"
                        role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && cuFormSubmitted">
                <div *ngIf="name.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
            <div class="invalid-feedback-length" *ngIf="cuForm.name.length > 255">
                {{allinoneService.lengthCheck("Name",255)}}
            </div>

        </div>
        <div class="col-md-6">
            <label for="name" class="form-label">Short Code</label>

            <input type="text" class="form-control" id="shortcode" [(ngModel)]="cuForm.shortcode" name="shortcode"
                placeholder="" #shortcode="ngModel" [ngClass]="{ 'is-invalid': cuFormSubmitted && shortcode.invalid }"
                required minlength="3" maxlength="5">

            <div class="invalid-feedback"
                *ngIf="(shortcode.invalid || shortcode.dirty || shortcode.touched) && cuFormSubmitted">
                <div *ngIf="shortcode.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
                <div *ngIf="shortcode.errors?.minlength">
                    Short code should be 3-5 letters.
                </div>
            </div>

        </div>
    </div>

    <div class="d-flex align-items-center mb-3">
        <div class="form-check  me-5" *ngFor="let item of businessType">
            <label class="radio-inline">
                <input class="form-check-input" type="radio" name="businesstype" [id]="item.key" #cutype="ngModel"
                    [(ngModel)]="cuForm.businesstype" [value]="item.key" [checked]="item.key == cuForm.businesstype">
                {{item.value}}
            </label>
        </div>
    </div>

    <div class="row">
        <div class="mb-3 col-md-6">
            <label for="phone" class="form-label">Phone</label>

            <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="cuForm.phone" #phone="ngModel">
            <div class="invalid-feedback-length" *ngIf="cuForm.phone.length > 255">
                {{allinoneService.lengthCheck("Phone",255)}}
            </div>
        </div>
        <div class="mb-3 col-md-6">
            <label for="email" class="form-label">Email</label>

            <input type="text" class="form-control" id="email" name="email" [(ngModel)]="cuForm.email">
            <div class="invalid-feedback-length" *ngIf="cuForm.email.length > 255">
                {{allinoneService.lengthCheck("Email",255)}}
            </div>
        </div>

    </div>

    <div class="row">
        <div class="mb-3  col-md-12">
            <label for="address1" class="form-label">Address</label>

            <input type="text" class="form-control" id="address1" name="address1" [(ngModel)]="cuForm.address1">
        </div>
        <div class="mb-3 col-md-12">

            <input type="text" class="form-control" id="address2" name="address2" [(ngModel)]="cuForm.address2">
        </div>
        <div class="invalid-feedback-length" *ngIf="cuForm.address1.length > 255 && cuForm.address2.length > 255">
            {{allinoneService.lengthCheck("Address",255)}}
        </div>
    </div>


    <div class="row">
        <div class="mb-3 col-md-6">
            <label for="phone" class="form-label">Industry Type</label>

            <input type="text" class="form-control" id="industrytype" name="industrytype"
                [(ngModel)]="cuForm.industrytype">

        </div>
        <div class="mb-3 col-md-6">
            <!-- <div class="mb-3 col-md-6"> -->
            <label for="status" class="form-label">Status</label>

            <div>
                <select class="form-select" aria-label="Select status" id="status" name="status"
                    [(ngModel)]="cuForm.status" required>
                    <option *ngFor="let status of customerStatus" value="{{status.key}}">
                        {{status.value}}</option>
                </select>
            </div>
        </div>

    </div>


    <div class="d-flex" *ngIf="isEditing">
        <div class="mb-3 me-2 d-flex align-items-end">
            <button class="btn btn-custom" (click)="openDealForm()">
                <i class="far fa-handshake me-1"></i> {{cuForm.deallist.length}} Deal(s)
            </button>
        </div>
        <div class="mb-3 mx-2 d-flex align-items-end">
            <button class="btn btn-custom" (click)="openContactForm()">
                <i class="fas fa-address-book me-1"></i> {{cuForm.contactlist.length}} Contact(s)
            </button>
        </div>
        <div class="mb-3 mx-2 d-flex align-items-end">
            <button class="btn btn-custom" (click)="openBranchForm()">
                <i class="fa fa-building" aria-hidden="true"></i>{{cuForm.branchlist.length}} Branch(s)
            </button>
        </div>
    </div>
    <div *ngIf="isEditing && emailaccessList.length > 0" style="padding-top: 10px;
    padding-bottom: 10px;">
        <p>Email access</p>
        <ul *ngFor="let i of emailaccessList">
            <li>{{i.contactemail}}</li>
        </ul>
    </div>
    <div class="mb-3" *ngIf="cuForm.businesstype == '002'">
        <label for="pic" class="form-label">Person in Charge</label>

        <input type="text" class="form-control" id="pic" name="pic" disabled
            value="{{this.allinoneService.getKunyekUserName()}}">
    </div>

    <input style="display: none" type="file" (change)="onFileSelect($event)" #hiddenpdffileinput multiple>
    <button class="btn btn-outline-primary custom-btn" (click)="hiddenpdffileinput.click()">
        <span>Choose Attachments</span>
    </button>

    <div class="attachmentList mb-3">
        <div *ngFor="let file of attachmentList; let index = index">
            <div class="pdf-container">
                <div class="pdf-filename">
                    {{file.filename}}
                </div>
                <div class="pdf-action d-flex align-items-center">
                    <button mat-icon-button class="icon-button" (click)="removeAttachment(index)">
                        <mat-icon class="delete-icon">delete</mat-icon>
                    </button>
                    <button mat-icon-button class="ms-2 icon-button" (click)="downloadFile(file.fileurl, file.filename)"
                        *ngIf="file.fileurl">
                        <mat-icon class="download-icon">file_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>


<div *ngIf="isExcel && !product && !addproduct && isproject == false && !addproject">

    <div class="mb-3 d-flex justify-content-start header mt-3">
        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" [disabled]="addcustomer">
            Cancel
        </button>

        <div class="close-save-button ">
            <app-button-loader class="btn-custom" (click)="submitExcelCustomer()" style="border-radius: 5px;"
                [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="addcustomer"
                *ngIf="finalMemberList.length > 0">
            </app-button-loader>
            <button *ngIf="finalMemberList.length == 0" class="btn btn-custom" disabled>
                Save
            </button>
        </div>

    </div>
    <div class="mb-3">
        <label for="file" class="form-label">Excel Template (<span class="cc-label"
                (click)="downloadSampleFile()">Download Sample Template</span>)</label>
        <div class="input-group">
            <input class="form-control" type="file" (change)="onExcelFileSelect($event)" accept=".xlsx" id="formFile"
                #excelFile [disabled]="excel">
            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="excel"
                (click)="removeExcel()">Remove</button>
            <button class="input-group-text btn btn-custom" style="border-left: 1px solid white;" id="btnGroupAddon2"
                [disabled]="!excel || checkingExcelFile" (click)="checkExcelFile()">
                <span *ngIf="!checkingExcelFile">
                    Check
                </span>
                <span *ngIf="checkingExcelFile">
                    <span class="spinner-border spinner-border-sm button-spinner" role="status"
                        aria-hidden="true"></span>
                    <span class="mx-1">Checking</span>
                </span>
            </button>
        </div>
        <div role="alert" *ngIf="excelerror" style="color: red;padding: 6px;">
            {{allinoneService.input_err_msg}}
        </div>

    </div>
    <div style="color : green" *ngIf="allok">
        <i class="fa fa-check me-2"></i> Template is correct.
    </div>
    <div class="row">
        <div class="alert alert-danger custom-box" role="alert" *ngIf="errorList.length > 0">
            <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                    error
                </mat-icon>
                <div>
                    Error
                </div>
            </div>
            <hr>
            <ul style="padding-left: 1rem;">
                <li *ngFor="let e of errorList;">
                    In line no. {{e.line}}, {{e.error}}
                </li>
            </ul>
        </div>
        <div class="alert alert-warning custom-box" *ngIf="duplicateList.length > 0 || warningList.length >0">
            <div class="d-flex align-items-center">
                <mat-icon class="me-2">
                    warning
                </mat-icon>
                <div>
                    Warning
                </div>
            </div>
            <hr>
            <ul style="padding-left: 1rem;">
                <li *ngFor="let du of duplicateList;">
                    "{{du}}" is duplicated.
                </li>
                <li *ngFor="let e of warningList;">
                    In line no. {{e.line}}, {{e.error}}
                </li>
            </ul>


        </div>
    </div>

</div>


<div class="table-responsive" *ngIf="!isExcel && product && !addproduct && isproject == false && !addproject">
    <div class="close-save-button ">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancels(2)">
            Cancel
        </button>
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="addproductcus()" [disabled]="gettingData">
            Add
        </button>
        <button title="Delete" class="ms-3 btn btn-custom mb-3" (click)="deleteProduct()"
            [disabled]="deleteproductlist.length == 0">
            Delete
        </button>
        <div class="line mb-3"></div>

        <div class="me-3 mb-3">
            <button type="button " class="btn btn-custom " (click)="downloadSheetRelatedProduct() "
                matTooltip="Export Related Product To Excel " [disabled]="excelexportLoading">
                <img *ngIf="!excelexportLoading" class="excel-icon " src="../../../assets/images/excel.png">
                <span *ngIf="excelexportLoading" style="margin-right: 10px; "
                    class="spinner-border spinner-border-sm button-spinner " role="status " aria-hidden="true "></span>
                Export
            </button>
        </div>
        <div class="line mb-3"></div>

        <div class="d-flex align-items-center me-auto mb-3">
            <span class="total-count-text">Total:&nbsp;</span> <span
                class="total-count-num">{{productListCustomer.length}}</span>
        </div>
        <div class="mb-3">
            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                [(ngModel)]="searchcustomer" style="font-family: FontAwesome,Ubuntu;width: 300px;">
        </div>

    </div>
    <table class="table table-responsive table-borderless">
        <thead class="table-header">
            <tr>
                <td scope="col" class="col-lg-2 col-md-2" style="cursor: pointer;">
                    <input class="form-check-input" type="checkbox" name="check" (click)="allcheckProduct($event)"
                        style="cursor: pointer;">
                </td>
                <td scope="col" class="col-lg-4 col-md-4">SKU</td>
                <td scope="col" class="col-lg-4 col-md-4">Description</td>
                <td scope="col" class="col-lg-4 col-md-4">Person in Charge</td>
                <td scope="col" class="col-lg-3 col-md-3">Contact Person</td>

            </tr>
        </thead>
        <tbody *ngIf="gettingData">
            <tr>
                <td colspan="5" class="nodata">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="gettingData == false" style="cursor: pointer;">
            <tr *ngIf="productListCustomer.length == 0">
                <td colspan="5" class="nodata">Empty</td>

            </tr>
            <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
            <!--  -->
            <tr *ngFor="let item of productListCustomer | crmFilter :searchcustomer:text2:text1:21;let index = index">
                <td class="td-data">
                    <div class="form-check " style="cursor: pointer;">
                        <input class="form-check-input" [checked]="false" [checked]="item.check" type="checkbox"
                            id="check{{index}}" name="check" (click)="checkDeleteProduct($event,item,index)"
                            style="cursor: pointer;">
                        <label class="form-check-label mb-1 ms-1" for="check{{index}}">
                            {{item.appname}}
                        </label>
                    </div>
                </td>
                <td class="td-data">
                    <span title="">{{item.skucode}}</span>
                </td>
                <td class="td-data ">
                    <span title="">{{item.name}}</span>
                </td>
                <td class="td-data">
                    <span>{{item.picname == '' ? item.picuserid : item.picname}}</span>
                </td>
                <td class="td-data ">
                    <span title="">{{item.contactperson}}</span>
                </td>


            </tr>
        </tbody>
    </table>
</div>

<div class="table-responsive" *ngIf="!isExcel && !product && addproduct && isproject == false && !addproject">
    <div class="close-save-button mb-3">
        <button type="button" class="btn btn-custom me-2" (click)="addproductcancel()" *ngIf="!productSaveLoading">
            Cancel
        </button>
        <app-button-loader class="me-2" (click)="submitProduct()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="productSaveLoading">
        </app-button-loader>

        <div class="line"></div>
        <div class="d-flex align-items-center me-auto ">
            <span class="total-count-text">Total:&nbsp;</span> <span
                class="total-count-num">{{productList.length}}</span>
        </div>
        <div>
            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                [(ngModel)]="searchproduct" style="font-family: FontAwesome,Ubuntu;width: 300px;">
        </div>


    </div>
    <table class="table table-responsive table-borderless">
        <thead class="table-header">
            <tr>
                <td scope="col" class="col-lg-1 col-md-1" style="cursor: pointer;">
                    <input class="form-check-input" type="checkbox" name="check" (click)="allcheck($event)"
                        style="cursor: pointer;">
                </td>
                <td scope="col" class="col-lg-1 col-md-1">SKU</td>
                <td scope="col" class="col-lg-2 col-md-2">Description</td>
                <td scope="col" class="col-lg-2 col-md-2">Person in Charge</td>
                <td scope="col" class="col-lg-3 col-md-3">Contact Person</td>
            </tr>
        </thead>
        <tbody *ngIf="gettingData">
            <tr>
                <td colspan="5" class="nodata">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="gettingData == false" style="cursor: pointer;">
            <tr *ngIf="productList.length == 0">
                <td colspan="5" class="nodata">Empty</td>
            </tr>
            <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
            <!--*ngFor="let item of productList | crmFilter :text1:text2:searchproduct:21;let index = index"  -->
            <tr *ngFor="let item of productList | crmFilter :searchproduct:text2:text1:21;let index = index">
                <td class="td-data">
                    <div class="form-check " style="cursor: pointer;">
                        <input class="form-check-input" [checked]="item.check" type="checkbox" id="check{{index}}"
                            name="check" (click)="checkProduct($event,item,index)" style="cursor: pointer;">
                        <label class="form-check-label mb-1 ms-1" for="check{{index}}">
                            {{item.appname}}
                        </label>
                    </div>
                </td>
                <td class="td-data">
                    <span title="">{{item.skucode}}</span>
                </td>
                <td class="td-data">
                    <span title="">{{item.name}}</span>
                </td>
                <td class="td-data">
                    <span>{{item.picname == '' ? item.picuserid : item.picname}}</span>
                </td>
                <td>
                    <select class="form-select" (change)="chooseContact($event,index,item)"
                        aria-label="Default select example">
                        <option value="" selected>-</option>
                        <option *ngFor="let item of contactlist" [value]="item.contactid">{{item.contactname}}</option>

                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="table-responsive" *ngIf="!isExcel && !product && !addproduct && isproject == true && !addproject">
    <div class="close-save-button ">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancels(3)">
            Cancel
        </button>
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="addProjectcus()" [disabled]="gettingData">
            Add
        </button>
        <button title="Delete" class="ms-3 btn btn-custom mb-3" (click)="deleteProject()"
            [disabled]="deleteprojectlist.length == 0">
            Delete
        </button>
        <div class="line mb-3"></div>

        <div class="me-3 mb-3">
            <button type="button " class="btn btn-custom " (click)="downloadSheetRelatedProject() "
                matTooltip="Export Related Product To Excel " [disabled]="excelexportLoading">
                <img *ngIf="!excelexportLoading" class="excel-icon " src="../../../assets/images/excel.png">
                <span *ngIf="excelexportLoading" style="margin-right: 10px; "
                    class="spinner-border spinner-border-sm button-spinner " role="status " aria-hidden="true "></span>
                Export
            </button>
        </div>
        <div class="line mb-3"></div>

        <div class="d-flex align-items-center me-auto mb-3">
            <span class="total-count-text">Total:&nbsp;</span> <span
                class="total-count-num">{{projectListCustomer.length}}</span>
        </div>
        <div class="mb-3">
            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                [(ngModel)]="searchcustomer" style="font-family: FontAwesome,Ubuntu;width: 300px;">
        </div>

    </div>
    <table class="table table-responsive table-borderless">
        <thead class="table-header">
            <tr>
                <td scope="col" class="col-lg-2 col-md-2" style="cursor: pointer;">
                    <input class="form-check-input"*ngIf="!deleteprojectcheck"  type="checkbox" name="check" (click)="deleteallcheckproject($event)"
                        style="cursor: pointer;">
                    <input class="form-check-input" checked="checked" *ngIf="deleteprojectcheck" type="checkbox" name="check" (click)="deleteallcheckproject($event)"
                        style="cursor: pointer;">
                </td>
                <td scope="col" class="col-lg-4 col-md-4">Project Name</td>
                <td scope="col" class="col-lg-3 col-md-3">Contact Person</td>
            </tr>
        </thead>
        <tbody *ngIf="gettingData">
            <tr>
                <td colspan="5" class="nodata">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="gettingData == false" style="cursor: pointer;">
            <tr *ngIf="projectListCustomer.length == 0">
                <td colspan="5" class="nodata">Empty</td>

            </tr>
            <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
            <!--  -->
            <tr *ngFor="let item of projectListCustomer | crmFilter :searchcustomer:text2:text1:23;let index = index">
                <td class="td-data">
                    <div class="form-check " style="cursor: pointer;">
                        <input class="form-check-input" [checked]="false" [checked]="item.check" type="checkbox"
                            id="check{{index}}" name="check" (click)="checkDeleteProject($event,item,index)"
                            style="cursor: pointer;">
                        <label class="form-check-label mb-1 ms-1" for="check{{index}}">
                            {{item.appname}}
                        </label>
                    </div>
                </td>

                <td class="td-data ">
                    <span title="">{{item.name}}</span>
                </td>

                <td class="td-data ">
                    <span title="">{{item.contactperson}}</span>
                </td>


            </tr>
        </tbody>
    </table>
</div>


<div class="table-responsive" *ngIf="!isExcel && !product && !addproduct && isproject == false && addproject">
    <div class="close-save-button mb-3">
        <button type="button" class="btn btn-custom me-2" (click)="addprojectcancel()" *ngIf="!projectSaveLoading">
            Cancel
        </button>
        <app-button-loader class="me-2" (click)="submitProject()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="projectSaveLoading">
        </app-button-loader>

        <div class="line"></div>
        <div class="d-flex align-items-center me-auto ">
            <span class="total-count-text">Total:&nbsp;</span> <span
                class="total-count-num">{{projectlist.length}}</span>
        </div>
        <div>
            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                [(ngModel)]="searchproduct" style="font-family: FontAwesome,Ubuntu;width: 300px;">
        </div>


    </div>
    <table class="table table-responsive table-borderless">
        <thead class="table-header">
            <tr>
                <td scope="col" class="col-lg-1 col-md-1" style="cursor: pointer;">
                    <input class="form-check-input" type="checkbox" name="check" (click)="allcheckproject($event)"
                        style="cursor: pointer;">
                </td>
                <td scope="col" class="col-lg-6 col-md-6">Name</td>
                <td scope="col" class="col-lg-3 col-md-3">Contact Person</td>
            </tr>
        </thead>
        <tbody *ngIf="gettingData">
            <tr>
                <td colspan="5" class="nodata">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody *ngIf="gettingData == false" style="cursor: pointer;">
            <tr *ngIf="projectlist.length == 0">
                <td colspan="5" class="nodata">Empty</td>
            </tr>
            <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
            <!--*ngFor="let item of productList | crmFilter :text1:text2:searchproduct:21;let index = index"  -->
            <tr *ngFor="let item of projectlist | crmFilter :searchproduct:text2:text1:23;let index = index">
                <td class="td-data">
                    <div class="form-check " style="cursor: pointer;">
                        <input class="form-check-input" [checked]="item.check" type="checkbox" id="check{{index}}"
                            name="check" (click)="checkProject($event,item,index)" style="cursor: pointer;">
                        <label class="form-check-label mb-1 ms-1" for="check{{index}}">
                            {{item.appname}}
                        </label>
                    </div>
                </td>
                <td class="td-data">
                    <span title="">{{item.name}}</span>
                </td>

                <td>
                    <select class="form-select project-contact-dropdown"
                        (change)="chooseProjectContact($event,index,item)" aria-label="Default select example">
                        <option value="" selected>-</option>
                        <option *ngFor="let item of contactlist" [value]="item.contactid">{{item.contactname}}</option>

                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>