<div class="d-flex justify-content-between align-items-center mb-3">
  <h3 class="mb-0">Position History</h3>
  <div class="close-btn">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="table-responsive">
  <table class="table table-responsive table-borderless">
    <thead class="table-header">
      <tr>
        <td scope="col" class="col-lg-2 col-md-2">User ID</td>
        <td scope="col" class="col-lg-2 col-md-2">Name</td>
        <td scope="col" class="col-lg-2 col-md-2">ID</td>
        <td scope="col" class="col-lg-2 col-md-2">Employment</td>
        <td scope="col" class="col-lg-2 col-md-2">Transaction</td>
        <td scope="col" class="col-lg-2 col-md-2">Effective Date</td>
        <td scope="col" class="col-lg-2 col-md-2">Post</td>
        <td scope="col" class="col-lg-2 col-md-2">Branch</td>
        <td scope="col" class="col-lg-2 col-md-2">Department</td>
      </tr>
    </thead>
    <tbody *ngIf="pcListLoading">
      <tr>
        <td colspan="10" class="nodata">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!pcListLoading">
      <tr *ngIf="historyList.length == 0">
        <td colspan="10" class="nodata">Empty</td>
      </tr>
      <tr *ngFor="let item of historyList; let index = index">
        <td class="td-data" style="max-width: fit-content;">
          <span [title]="item.userid">{{item.userid}} </span>
        </td>
        <td class="td-data">
          <span [title]="item.username">{{item.username}} </span>
        </td>
        <td class="td-data">
          <span [title]="item.employeeid">{{item.employeeid}} </span>
        </td>
        <td class="td-data">
          <span [title]="item.employmenttypename">{{item.employmenttypename}}</span>
        </td>
        <td class="td-data">
          <span [title]="item.transactiontypename">{{item.transactiontypename}}</span>
        </td>
        <td class="td-data">
          <span
            [title]="allinoneService.formatDBToShow(item.effectivedate)">{{allinoneService.formatDBToShow(item.effectivedate)}}</span>
        </td>
        <td class="td-data">
          <span [title]="item.post">{{item.post}}</span>
        </td>
        <td class="td-data">
          <span [title]="item.branch">{{item.branch}}</span>
        </td>
        <td class="td-data">
          <span [title]="item.department">{{item.department}}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>