import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-member-relation-form',
  templateUrl: './member-relation-form.component.html',
  styleUrls: ['./member-relation-form.component.scss']
})
export class MemberRelationFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
