import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import ListConfig, { Filter } from 'src/app/shared/interfaces/listconfig';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-leave-list',
  templateUrl: './leave-list.component.html',
  styleUrls: ['./leave-list.component.scss']
})
export class LeaveListComponent{

  configs: ListConfig[] = [
    { title: 'Name', property: 'member.name' },
    { title: 'Type', property: 'type' },
    { title: 'From Date', property: 'from_date',type:'date' },
    { title: 'To Date', property: 'to_date',type:'date' },
    { title: 'Duration(days)', property: 'duration' },
    { title: 'Status', property: 'status' ,status:{
      id: 'leave',
      list: [
        {text:'Pending',type:'default',value:0},
        {text:'Approved',type:'ok',value:1},
        {text:'Rejected',type:'danger',value:2}
      ],
    }
      
      
      },
      
];
  filter:Filter = {
    text: 'Status',
    property: 'status',
    default: -1,
    choices: [
      {
        name: 'All',
        value: -1, // default
      },
      {
        name:'Pending',
        value: 0,
      },
      {
        name: 'Approved',
        value: 1,
      },
      {
        name: 'Rejected',
        value: 2,
      }
    ]
  }

  url = ['members/leavelist']
  searchName: string = '';
  searchText = "Search Leave"
  cb = this.kunyekService.leave().get.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Leave', url: 'leave', isActive: true },
    { name: 'Leave', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
  ) { }
}
