import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FileUploadComponent } from 'src/app/shared/components/file-upload/file-upload.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { AppRestrictionSetupComponent } from '../../app-restriction-setup/app-restriction-setup.component';
import { LateChoosePostComponent } from '../late-choose-post/late-choose-post.component';
import { OfficeWorkpolicyChooseMembersComponent } from '../office-workpolicy-choose-members/office-workpolicy-choose-members.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-office-workpolicy',
  templateUrl: './office-workpolicy.component.html',
  styleUrls: ['./office-workpolicy.component.scss'],
})
export class OfficeWorkpolicyComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  isAdmin: boolean = false;
  addWP: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  wpFormSubmitted: boolean = false;
  isFocus: boolean = false;
  isMobileView: boolean = false;

  searchText: string = '';
  orgid: string = '';
  membersTest: any = [];
  members: any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  refNoDisabled: boolean = true;

  importStatus: string = '000'; //000- Server Error, 001-Success, 002-Progress, 003-Upload, 004-Error, 005-Cancel
  refreshingImportStatus: boolean = false;
  importData: any;
  statusInterval: any;

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  rosterListLoading: boolean = false;
  rosterList: any = [];

  wpconfigLoading: boolean = false;
  wpconfigList: any = [];

  wpList: any = [];
  wpListLoading: boolean = false;
  employeeList: any = [];

  organizations: any = [];

  shiftListLoading: boolean = false;
  calendarListLoading: boolean = false;

  shiftList: any = [];
  calendarList: any = [];

  weeks: any[][] = [];

  filterType: string = '';

  generalDatePickerConfig: any = this.allinoneService.datePickerConfig;

  subscriptions = new SubSink();
  getWpListSubscription!: Subscription;
  getRosterSubscription!: Subscription;
  getWPConfigSubscription!: Subscription;
  getListSubscription!: Subscription;
  getCalendarSubscription!: Subscription;

  domainid: string = this.allinoneService.getDomain().domainid;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  filterMenu = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Roster',
      value: '001',
    },
    {
      name: 'Calendar',
      value: '002',
    },
  ];

  wpForm: any = {
    refno: '',
    name: '',
    // startdate: this.currentDate,
    // enddate: this.currentDate,
    startdate: new Date(Date.now()),
    enddate: new Date(Date.now()),
    description: '',
    rosterid: '',
    calendarid: '',
    calendarname: '',
    rostername: '',
    userwpid: '',
    wpid: '',
    type: '001',
    table: '001',
    shiftdata: {},
    calendar: {},
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe,
    private http: HttpClient,
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.getWPConfigSubscription && this.getWPConfigSubscription.unsubscribe();
    this.getRosterSubscription && this.getRosterSubscription.unsubscribe();
    this.getWpListSubscription && this.getWpListSubscription.unsubscribe();
    clearInterval(this.statusInterval);
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getWPList();
    this.getRosterList();
    this.getWPConfigList();
    this.getMemberList();
    this.getCalendarList();
    this.getShiftList();
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (
        this.importStatus != '001' &&
        this.importStatus != '005' &&
        this.importStatus != '000'
      ) {
        this.getImportStatus();
      }
    }, 5000);
  }

  getCalendarList() {
    this.calendarListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getCalendarSubscription && this.getCalendarSubscription.unsubscribe();
    this.getCalendarSubscription = this.kunyekService
      .getOfficeCalendar(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.calendarList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.calendarListLoading = false;
        },
        (err) => {
          this.calendarListLoading = false;
        }
      );
  }

  getShiftList() {
    this.shiftListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.kunyekService.getShift(data).subscribe(
      (res: any) => {
        console.log('Roster List');

        console.log(res);

        if (res.returncode == '300') {
          this.shiftList = res.datalist;
          console.log(this.shiftList);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.shiftListLoading = false;
      },
      (err) => {
        this.shiftListLoading = false;
      }
    );
  }

  typeChange(v: any) {
    if (v == '001') {
      this.wpForm.table = '001';
    }
    if (v == '002') {
      this.calendarChange();
    }
  }

  getWPList() {
    this.wpListLoading = true;
    const data = {
      orgid: this.orgid,
      type: this.filterType,
    };
    console.log(data);
    this.getWpListSubscription && this.getWpListSubscription.unsubscribe();
    this.getWpListSubscription = this.kunyekService
      .getWorkPolicy(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.wpList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.wpListLoading = false;
        },
        (err) => {
          this.wpListLoading = false;
        }
      );
  }

  newWP() {
    this.addWP = true;
  }

  editWP(wp: any) {
    this.isEditing = true;
    this.addWP = true;
    this.wpForm = {
      refno: wp.refno,
      name: wp.name,
      startdate: this.allinoneService.parseDateToShowInEdit(wp.startdate),
      enddate: this.allinoneService.parseDateToShowInEdit(wp.enddate),
      // startdate: this.allinoneService.parseDateToShowInEdit(wp.startdate),
      // enddate: this.allinoneService.parseDateToShowInEdit(wp.enddate),
      description: wp.description,
      rosterid: wp.rosterid,
      rostername: wp.rostername,
      calendarid: wp.calendarid,
      calendarname: wp.calendarname,
      userwpid: wp.userwpid,
      wpid: wp.wpid,
      type: wp.type,
      table: '001',
      shiftdata: wp.shiftdata,
      calendar: wp.calendar,
    };
    this.employeeList.push({
      userid: wp.userid,
      newuserid: wp.newuserid,
      username: wp.username,
      employeeid: wp.employeeid,
      type: wp.classcodename,
      department: wp.department,
      division: wp.division,
    });

    if (this.wpForm.type == '002') {
      this.weeks = this.allinoneService.generateCalendar(
        new Date(this.wpForm.startdate),
        new Date(this.wpForm.enddate)
      );
      this.weeks.map((week: any) => {
        week.map((day: any) => {
          day.shift = wp.calendar[day.date] || '';
        });
      });
    }
  }

  compareDate() {
    console.log('cd cd');
    console.log(this.wpForm.startdate);
    console.log(this.wpForm.enddate);


    if (this.allinoneService.parseDate(this.wpForm.startdate) > this.allinoneService.parseDate(this.wpForm.enddate)) {
      console.log('g');

      this.wpForm.enddate = this.wpForm.startdate;
    }
  }

  submitWP() {
    this.wpFormSubmitted = true;
    if (this.wpForm.name == '' || this.wpForm.wpid == '') {
      return;
    }

    if (this.wpForm.startdate == '' || this.wpForm.enddate == '') {
      return;
    }

    if (this.wpForm.startdate == this.wpForm.enddate) {
      return this.messageService.openSnackBar('Start date and end date cannot be the same.', 'warn');
    }


    if (this.wpForm.enddate < this.wpForm.startdate) {
      return this.messageService.openSnackBar('End date must be greather than start date.', 'warn');
    }

    if (this.wpForm.type == '001' && this.wpForm.rosterid == '') {
      return;
    }

    if (this.wpForm.type == '002' && this.wpForm.calendarid == '') {
      return;
    }

    if (this.wpForm.type == '002') {
      var noShift = true;
      this.weeks.map((week: any) => {
        week.map((day: any) => {
          if (day.shift != '') {
            noShift = false;
          }
        });
      });
      if (noShift) {
        return this.messageService.openSnackBar(
          'Please select at least one shift.',
          'warn'
        );
      }
    }

    if (this.employeeList.length <= 0) {
      this.messageService.openSnackBar("Employee can't empty.", 'warn');
      return;
    }
    this.saveWP();
  }

  saveWP() {
    this.saveLoading = true;
    var userListData: any = [];

    var shifts: any = {};
    var shiftData = {};
    if (this.wpForm.type == '002') {
      let shiftDataList: any[] = [];
      this.weeks.map((week: any) => {
        week.map((day: any) => {
          if (day.date) {
            Object.assign(shifts, {
              [day.date]: day.shift,
            });
          }
          shiftDataList.push(day.shift);
        });
      });

      console.log(shiftDataList);

      let uniShiftList = [...new Set(shiftDataList)];

      for (var i = 0; i < uniShiftList.length; i++) {
        for (var x = 0; x < this.shiftList.length; x++) {
          if (uniShiftList[i] == this.shiftList[x]['shiftid']) {
            let a = this.shiftList[x]['shiftid'];
            Object.assign(shiftData, {
              [uniShiftList[i]]: this.shiftList[x],
            });
          }
        }
      }
    }

    for (var i = 0; i < this.employeeList.length; i++) {
      userListData.push({
        userid: this.employeeList[i]['userid'],
        employeeid: this.employeeList[i]['employeeid'],
      });
    }
    const data = !this.isEditing
      ? {
        orgid: this.orgid,
        name: this.wpForm.name,
        description: this.wpForm.description,
        // startdate: this.allinoneService.formatDate(this.wpForm.startdate),
        // enddate: this.allinoneService.formatDate(this.wpForm.enddate),
        startdate: this.allinoneService.parseDate(this.wpForm.startdate),
        enddate: this.allinoneService.parseDate(this.wpForm.enddate),
        wpid: this.wpForm.wpid,
        type: this.wpForm.type,
        rosterid: this.wpForm.type == '001' ? this.wpForm.rosterid : '',
        calendarid: this.wpForm.type == '001' ? '' : this.wpForm.calendarid,
        shiftdata: this.wpForm.type == '001' ? '' : shiftData,
        calendar: this.wpForm.type == '001' ? '' : shifts,
        userlist: userListData,
      }
      : {
        userwpid: this.wpForm.userwpid,
        orgid: this.orgid,
        name: this.wpForm.name,
        description: this.wpForm.description,
        wpid: this.wpForm.wpid,
        type: this.wpForm.type,
        rosterid: this.wpForm.type == '001' ? this.wpForm.rosterid : '',
        calendarid: this.wpForm.type == '001' ? '' : this.wpForm.calendarid,
        shiftdata: this.wpForm.type == '001' ? '' : shiftData,
        calendar: this.wpForm.type == '001' ? '' : shifts,
      };

    console.log(data);
    // return

    var fun = this.isEditing
      ? this.kunyekService.updateWorkPolicy(data)
      : this.kunyekService.createWorkPolicy(data);

    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.isEditing) {
            this.saveLoading = false;
            // this.cancel();
            // this.refresh();

            var index = this.wpList.findIndex(
              (x: any) => x.userwpid == this.wpForm.userwpid
            );
            if (index > -1) {
              var rostername = '';
              var wpconfig = this.wpconfigList.find(
                (x: any) => x.wpid == this.wpForm.wpid
              );
              if (wpconfig) {
                this.wpList[index].wpname = wpconfig.name;
              }
              if (this.wpForm.type == '001') {
                for (var i = 0; i < this.rosterList.length; i++) {
                  if (this.rosterList[i].rosterid == data.rosterid) {
                    rostername = this.rosterList[i].name;
                  }
                }
              }
              let calendarName = '';
              if (this.wpForm.type == '002') {
                for (var i = 0; i < this.calendarList.length; i++) {
                  if (this.calendarList[i].calendar == data.calendarid) {
                    calendarName = this.calendarList[i].name;
                  }
                }
              }
              this.wpList[index].name = data.name;
              this.wpList[index].description = data.description;
              this.wpList[index].rosterid = data.rosterid;
              this.wpList[index].rostername = rostername;
              this.wpList[index].calendarid = data.calendarid;
              this.wpList[index].calendarName = calendarName;
              this.wpList[index].shiftdata = shiftData;
              this.wpList[index].calendar = shifts;
            } else {
              this.refresh();
            }
            this.cancel();
          } else {
            this.saveLoading = false;
            this.cancel();
            this.refresh();
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    );
  }

  employeePopup(item?: any) {
    if (item && item.version == '') {
      return;
    }
    const dialog = this.dialog.open(OfficeWorkpolicyChooseMembersComponent, {
      // const dialog = this.dialog.open(LateChoosePostComponent, {
      maxWidth: '95vw',
      width: '1200px',
      height: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        orgid: this.orgid,
        members: this.members,
        startdate: this.allinoneService.parseDate(this.wpForm.startdate),
        enddate: this.allinoneService.parseDate(this.wpForm.enddate),
        selectedCount: this.employeeList.map((x: any) => {
          return x.userid;
        }),
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (dialogResult.length > 0) {
          this.employeeList = dialogResult;
          for (var i = 0; i < dialogResult.length; i++) {
            for (var x = 0; x < this.members.length; x++) {
              if (dialogResult[i]['userid'] == this.members[x]['userid']) {
                this.members[x]['isChecked'] = dialogResult[i]['isChecked'];
              }
            }
          }
        } else {
          this.employeeList = [];
        }
      }
    });
  }

  cancel() {
    this.wpFormSubmitted = false;
    this.addWP = false;
    this.isEditing = false;
    this.saveLoading = false;
    this.wpForm = {
      refno: 'TBA',
      name: '',
      // startdate: this.currentDate,
      // enddate: this.currentDate,
      startdate: new Date(Date.now()),
      enddate: new Date(Date.now()),
      description: '',
      rosterid: '',
      rostername: '',
      calendarid: '',
      calendarname: '',
      userwpid: '',
      wpid: '',
      type: '001',
      table: '001',
      shiftdata: {},
      calendar: {},
    };
    this.employeeList = [];
    for (var i = 0; i < this.members.length; i++) {
      this.members[i]['isChecked'] = false;
    }
  }

  deletewp(wpid: string, index: number) {
    // don't use index
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.wpListLoading = true;
        const data = {
          orgid: this.orgid,
          userwpid: wpid,
        };
        this.kunyekService.deleteWorkPolicy(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var i = this.wpList.findIndex((x: any) => x.userwpid == wpid);
              this.wpList.splice(i, 1);
              // this.wpList.splice(index, 1);
              if (
                Math.round(this.wpList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.wpListLoading = false;
          },
          (err) => {
            this.wpListLoading = false;
          }
        );
      }
    });
  }

  getRosterList() {
    this.rosterListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getRosterSubscription && this.getRosterSubscription.unsubscribe();
    this.getRosterSubscription = this.kunyekService.getRoster(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.rosterList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.rosterListLoading = false;
      },
      (err) => {
        this.rosterListLoading = false;
      }
    );
  }

  getWPConfigList() {
    this.wpconfigLoading = true;
    const data = {
      orgid: this.orgid,
      wpid: '',
    };
    this.getWPConfigSubscription && this.getWPConfigSubscription.unsubscribe();
    this.getWPConfigSubscription = this.kunyekService
      .getWpConfig(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.wpconfigList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.wpconfigLoading = false;
        },
        (err) => {
          this.wpconfigLoading = false;
        }
      );
  }

  getMemberList(searchUserID: string = '') {
    this.members = [];
    const data = {
      domainid: this.domainid,
      domain: this.allinoneService.getDomain().shortcode,
      orgid: this.orgid,
      searchuserid: searchUserID,
      // active: true,
      // checkrostercalendar: true
    };
    this.kunyekService.getMembers(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (res.url != '') {
            this.readJsonFileList(res.url)
          }
          this.members = this.members.filter((x: any) => x.employeeid != '');
          this.members = this.members.map((item: any) => ({
            ...item,
            isChecked: false,
          }));
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => { }
    );
  }

  deleteEmployee(index: any) {
    for (var i = 0; i < this.members.length; i++) {
      if (this.members[i]['userid'] == this.employeeList[index]['userid']) {
        this.members[i]['isChecked'] = false;
      }
    }
    this.employeeList.splice(index, 1);
  }

  sortData(sort: Sort) {
    const data = this.wpList;
    if (!sort.active || sort.direction === '') {
      this.wpList = data;
      return;
    }
    this.wpList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'refno':
          return this.allinoneService.compare(
            parseInt(a.refno),
            parseInt(b.refno),
            isAsc
          );
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'post':
          return this.allinoneService.compare(a.post, b.post, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'starttime':
          return this.allinoneService.compare(a.starttime, b.starttime, isAsc);
        case 'endtime':
          return this.allinoneService.compare(a.endtime, b.endtime, isAsc);
        case 'roster':
          return this.allinoneService.compare(a.roster, b.roster, isAsc);
        case 'wpname':
          return this.allinoneService.compare(a.wpname, b.wpname, isAsc);
        default:
          return 0;
      }
    });
  }

  sortEmployee(sort: Sort) {
    const data = this.employeeList;
    if (!sort.active || sort.direction === '') {
      this.employeeList = data;
      return;
    }
    this.employeeList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'post':
          return this.allinoneService.compare(a.post, b.post, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        default:
          return 0;
      }
    });
  }

  export() {
    var exdata: any = [];
    var name = 'WP.xlsx';

    var filteredData = this.filterPipe.transform(
      this.wpList,
      this.searchText,
      'wpList'
    );

    filteredData.map((item: any) => {
      var temp = {
        Name: item.username == '' ? item.userid : item.username,
        Description: item.description,
        'Employee ID': item.employeeid,
        'Ref No.': item.refno,
        Post: item.classcodename,
        Department: item.department,
        Division: item.division,
        'Start Date': this.allinoneService.formatDBToShow(item.startdate),
        'End Date': this.allinoneService.formatDBToShow(item.enddate),
        Roster: item.type == '002' ? '-' : item.rostername,
        Calendar: item.type == '001' ? '-' : item.calendarname,
      };
      exdata.push(temp);
    });

    this.allinoneService.exportEcecl(exdata, name);
  }

  importSheet(isError: boolean = false) {
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(FileUploadComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        isError: isError,
        orgid: this.orgid,
        domainid: this.domainid,
        // isHierarchy: false,
        errorData: this.importData,
        type: '001',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.getImportStatus();
      this.statusInterval = setInterval(() => {
        if (
          this.importStatus != '001' &&
          this.importStatus != '005' &&
          this.importStatus != '000'
        ) {
          this.getImportStatus();
        }
      }, 5000);
    });
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      type: '001',
      orgid: this.orgid,
    };
    // 27*** change api
    this.kunyekService.getHierarchyImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            this.refresh();
          }
          this.importStatus = res.status;
          this.importData = res;
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }

  refresh() {
    this.currentPage = 1;
    // this.getRosterList();
    // this.getShiftList();
    // this.getWPConfigList();
    this.getWPList();
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  calendarChange() {
    this.weeks = this.allinoneService.generateCalendar(
      new Date(this.wpForm.startdate),
      new Date(this.wpForm.enddate)
    );
    if (this.wpForm.calendarid != '') {
      var calendar = this.calendarList.find(
        (x: any) => x.calendarid == this.wpForm.calendarid
      );
      if (calendar) {
        this.wpForm.startdate = this.allinoneService.formatDBToShowInEdit(
          calendar.startdate
        );
        this.wpForm.enddate = this.allinoneService.formatDBToShowInEdit(
          calendar.enddate
        );
        this.weeks = this.allinoneService.generateCalendar(
          new Date(this.wpForm.startdate),
          new Date(this.wpForm.enddate)
        );
        this.weeks.map((week: any) => {
          week.map((day: any) => {
            day.shift = calendar.calendar[day.date] || '';
          });
        });
      }
    }
  }

  updateShiftData(event: any) {
    this.weeks = event;
  }

  filterTypeChange(type: string) {
    this.filterType = type;
    this.refresh();
  }

  async readJsonFileList(url: string) {
    this.http.get(url).subscribe((res: any) => {
      this.members = res    
        
    })
   }
}
