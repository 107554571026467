<div class="me-auto px-5 mt-3">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
</div>

<div class="d-flex flex-column align-items-center gap-2 mb-5">
    <div class="d-flex ms-auto btn-action">
        <button class="btn btn-custom me-2" (click)="printPage()">Print</button>
        <button class="btn btn-custom" (click)="exportPDF()">Export PDF</button>
    </div>
    <div class="card px-5 py-3" id="printableCard" style="width: 780px;">
        <div class="d-flex flex-column gap-1 align-items-center mt-3">
            <h2>KOKINE SWIMMING CLUB</h2>
            <h2>TEMPORARY MEMBER APPLICATION FORM</h2>
        </div>
        <div class="d-flex flex-column align-items-end p-2 gap-3">
            <div class="d-flex gap-3">
                <span class="text-end">Date:</span>
                <span style="width: 130px;" class="dashed-line text-end">{{ todaydate }}</span>
            </div>
        </div>
        <div class="p-3 mb-3" style=" background-color: white; border: solid 1px #ddd; border-radius: 6px;">
            <div>
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td></td>
                            <td class="text-end">
                                <img [src]="guestswimmer?.profileimage ? guestswimmer.profileimage : '../../../assets/images/member.png'"
                                    class="img-fluid border border-primary" alt="guestswimmer image" width="120px">
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Name </td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.name }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Nationality</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.nationality }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Country</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.country }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Date of Birth</td>
                            <td class="me-5 dashed-line">{{ allInOneService.formatDBToShow(guestswimmer?.birthdate)
                                }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Gender</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.gender }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Occupation</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.occupation_company }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Company</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.company }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Date of Arrival</td>
                            <td class="me-5 dashed-line">{{
                                allInOneService.formatDBToShow(guestswimmer?.arrivaldate) }}</td>
                        </tr>
                        <tr *ngIf="guestswimmer?.directory">
                            <td style="width: 200px;">State/Division</td>
                            <td class="me-5 dashed-line">{{
                                guestswimmer?.directory?.ward + '၊' }} {{
                                guestswimmer?.directory?.town + '၊' }} {{ guestswimmer?.directory?.region + '၊၊'}}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Address</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.address }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Contact Phone Number</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.phone }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Email</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.email }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">Options</td>
                            <td class="me-5 dashed-line">{{ guestswimmer?.option }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>