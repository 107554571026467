<div class="m-4">
    <div class="me-auto">
        <app-kmember-breadcrumb [s_breadcrumb]="'Register'">
        </app-kmember-breadcrumb>
    </div>
    <div class="d-flex gap-2 mb-2">
        <app-button-loader (click)="closeForm()" [button_text]="'Cancel'"
            [load_button_text]="'Saving'"></app-button-loader>
        <!-- <button class="btn btn-custom" type="submit" (click)="handleSaveMember()">Save</button> -->
        <app-button-loader (click)="handleSaveMember()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading">
        </app-button-loader>
        <div class="">
            <div class="line"></div>
        </div>
    </div>

    <form [formGroup]="memberForm">
        <div class="card">
            <div class="card-body m-2">
                <div class="row">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="name" class="form-label">အမည် <font color="red">*</font></label>
                                <input type="text" id="name" class="form-control" formControlName="name" required
                                    [ngClass]="{ 'is-invalid': submitted && name.invalid }" />
                                <div class="invalid-feedback" *ngIf="
                                    name.invalid || ((name.dirty || name.touched) && submitted)">
                                    <div *ngIf="name.errors?.required">{{ requiredMessage }}</div>
                                </div>
                                <!-- <div class="invalid-feedback" *ngIf="
                        name.invalid || ((name.dirty || name.touched) && submitted)">
                                    <div *ngIf="memberForm.get('name')?.invalid && memberForm.get('name')?.touched">{{ requiredMessage }}</div>
                                </div> -->
                            </div>
                            <!-- <div class="col-md-6 mb-3">
                                <label for="membertype" class="form-label">ကဒ်အမျိုးအစား</label>
                                <input type="text" id="membertype" class="form-control" formControlName="membertype"
                                    required [ngClass]="{ 'is-invalid': submitted && membertype.invalid }" />
                                <div class="invalid-feedback" *ngIf="
                        membertype.invalid || ((membertype.dirty || membertype.touched) && submitted)">
                                    <div *ngIf="membertype.errors?.required">{{ requiredMessage }}</div>
                                </div>
                            </div> -->
                            <div class="col-md-6 mb-3">
                                <label for="membertype" class="form-label">ကဒ်အမျိုးအစား <font color="red">*</font>
                                </label>
                                <!-- <ng-select [items]="memberTypeList" bindValue="membertypeid" class="form-control"
                                    formControlName="membertypeid" bindLabel="name" [clearable]="false"
                                    [ngClass]="{ 'is-invalid': submitted && membertype.invalid }"
                                    class="from-control"></ng-select>
                                <div class="invalid-feedback" *ngIf="
                                    membertype.invalid || ((membertype.dirty || membertype.touched) && submitted)">
                                    <div *ngIf="submitted && membertype.errors?.required">{{ requiredMessage }}</div>
                                </div> -->
                                <select class="form-select" id="membertypeid" name="membertype"
                                    formControlName="membertypeid" required
                                    [ngClass]="{ 'is-invalid': submitted && membertype.invalid }">
                                    <option value="">-</option>
                                    <option *ngFor="let item of memberTypeList" [value]="item.membertypeid">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="
                    membertype.invalid ||
                    ((membertype.dirty || membertype.touched) && submitted)
                  ">
                                    <div *ngIf="submitted && membertype.errors?.required">
                                        {{ requiredMessage }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="subname" class="form-label">အခြားအမည်</label>
                                <input type="text" id="subname" class="form-control" formControlName="subname" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="birthdate" class="form-label">မွေးသက္ကရာဇ်</label>
                                <my-date-picker [options]="datePickerOptions" formControlName="birthdate"
                                    placeholder="DD/MM/YYYY">
                                </my-date-picker>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="nrc" class="form-label">နိုင်ငံသားမှတ်ပုံတင်အမှတ် <font color="red">*</font>
                                </label>
                                <div class="g-2">
                                    <div class="row g-2">
                                        <div class="col-2">
                                            <select class="form-select" id="nrcregioncode" name="nrcregioncode"
                                                formControlName="nrcregioncode" required
                                                [ngClass]="{ 'is-invalid': submitted && nrcregioncode.invalid }">
                                                <option value="">-</option>
                                                <option *ngFor="let item of nrcRegionCode" [value]="item">{{item}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <select class="form-select" id="nrctownshipcode" name="nrctownshipcode"
                                                formControlName="nrctownshipcode" required
                                                [ngClass]="{ 'is-invalid': submitted && nrctownshipcode.invalid }">
                                                <option *ngFor="let item of nrcTownshipCode"
                                                    [value]="item == '-' ? '' : item">{{item}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <select class="form-select" id="nrctype" name="nrctype"
                                                formControlName="nrctype" required
                                                [ngClass]="{ 'is-invalid': submitted && nrctype.invalid }">
                                                <option *ngFor="let item of nrcType" [value]="item == '-' ? '' : item">
                                                    {{item}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" id="nrcnumber" name="nrcnumber"
                                                formControlName="nrcnumber" minlength="6" maxlength="6" required
                                                (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                                                [ngClass]="{ 'is-invalid': submitted && nrcnumber.invalid }" />
                                        </div>
                                    </div>

                                    <div class="invalid"
                                        *ngIf="nrcregioncode.invalid || nrctownshipcode.invalid || nrctype.invalid || nrcnumber.invalid">
                                        <div *ngIf="(nrcregioncode.errors?.required || nrctownshipcode.errors?.required ||
                                    nrctype.errors?.required || nrcnumber.errors?.required) && submitted">{{
                                            requiredMessage }}</div>
                                    </div>


                                </div>

                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="nationality" class="form-label">လူမျိူး</label>
                                <input type="text" class="form-control" name="nationality"
                                    formControlName="nationality" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="fathername" class="form-label">အဘမည်</label>
                                <input type="text" class="form-control" name="fathername"
                                    formControlName="fathername" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="mothername" class="form-label">အမိအမည်</label>
                                <input type="text" class="form-control" name="mothername"
                                    formControlName="mothername" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="religion" class="form-label">ကိုးကွယ်သည့်ဘာသာ</label>
                                <input type="text" class="form-control" name="religion" formControlName="religion" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="education" class="form-label">ပညာအရည်အချင်း</label>
                                <input type="text" class="form-control" name="education" formControlName="education" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="job" class="form-label">အလုပ်အကိုင်</label>
                                <input type="text" class="form-control" name="job" formControlName="job" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="currentjob" class="form-label">လက်ရှိအလုပ်အကိုင်</label>
                                <input type="text" class="form-control" name="currentjob"
                                    formControlName="currentjob" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="retiredjob" class="form-label">အငြိမ်းစားဖြစ်ခဲ့သော်
                                    နောက်ဆုံးလုပ်ကိုင်ခဲ့သည့်
                                    အလုပ်အကိုင်</label>
                                <input type="text" class="form-control" name="retiredjob"
                                    formControlName="retiredjob" />
                            </div>

                            <div class="col-md-12 mb-3">
                                <label for="region" class="form-label">ပြည်နယ်/တိုင်းဒေသကြီး</label>
                                <div class="g-2">
                                    <div class="row g-2">
                                        <div class="col-4">
                                            <select class="form-select" id="region" name="region"
                                                formControlName="region" (change)="onRegionChange($event)">
                                                <option value="" selected disabled>ပြည်နယ်/တိုင်းဒေသကြီး ရွေးပါ
                                                </option>
                                                <option *ngFor="let regionlist of regionList"
                                                    [value]="regionlist.region">
                                                    {{regionlist.region}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <select class="form-select" id="town" name="town" formControlName="town"
                                                (change)="onTownChange($event)">
                                                <option value="" selected disabled>မြို့ ရွေးပါ</option>
                                                <option *ngFor="let townlist of filteredTowns" [value]="townlist.town">
                                                    {{townlist.town}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <select class="form-select" id="ward" name="ward" formControlName="ward">
                                                <option value="" selected disabled>ရပ်ကွက်/ရွာ ရွေးပါ</option>
                                                <option *ngFor="let wardlist of filteredWards"
                                                    [value]="wardlist.postcode">{{wardlist.ward}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="address" class="form-label">နေရပ်လိပ်စာ</label>
                                <textarea id="address" class="form-control" formControlName="address"></textarea>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="current_address" class="form-label">လက်ရှိနေရပ်လိပ်စာ</label>
                                <textarea id="current_address" class="form-control"
                                    formControlName="current_address"></textarea>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="phone" class="form-label">ဖုန်းနံပါတ် <font color="red">*</font></label>
                                <input type="text" class="form-control" name="userid" formControlName="phone"
                                    [ngClass]="{ 'is-invalid': submitted && phone.invalid }" required />
                                <div class="invalid-feedback" *ngIf="
                        phone.invalid || ((phone.dirty || phone.touched) && submitted)">
                                    <div *ngIf="phone.errors?.required">{{ requiredMessage }}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-2">
                        <div class="profile-img-container">
                            <div style="position: relative">
                                <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop>
                                    <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                                        [lazyLoad]="profileUrl ? profileUrl : '../../../assets/images/uploadphoto.png'" />
                                    <input hidden type="file" #fileInput accept="image/*"
                                        (change)="onFileSelected($event)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mb-3">
                    <div class="input-group">
                        <input class="form-control" type="file" (change)="onAttachment1Selected($event)"
                            accept="image/*,.docx,application/pdf,application/vnd.ms-powerpoint" id="formFile"
                            #fileInput1>
                        <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                            (click)="removefileSelected()">Remove</button>
                        <!-- <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                          *ngIf="isFileChange && isEditing && !noFile" (click)="isFileChange = false">Cancel</button> -->
                    </div>


                    <!-- file preview -->
                    <!--     
                      <div class="input-group" *ngIf="!isFileChange && isEditing">
                        <input class="form-control" readonly aria-describedby="btnGroupAddon2"
                          [(ngModel)]="memberForm.filename" [disabled]="memberForm.inactive">
                        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" (click)="isFileChange = true"
                          [disabled]="memberForm.inactive">Change</button>
                        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" style="border-left: 1px solid;"
                          (click)="downloadAttachment()" [disabled]="memberForm.inactive">
                          <span *ngIf="!downloading">Download</span>
                          <span *ngIf="downloading">
                            <div class="spinner-border download" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </span>
                        </button>
                        <button class="input-group-text btn btn-custom" id="btnGroupAddon2" style="border-left: 1px solid"
                          (click)="isFileChange = true" [disabled]="memberForm.inactive">Remove</button>
                      </div> -->
                </div>


                <div class="mt-3">
                    <input style="display: none; cursor: pointer" type="file" (change)="onAttachment1Selected($event)"
                        #imageinput multiple />
                    <button class="btn btn-custom" (click)="imageinput.click()">Attachments</button>
                </div>
                <div *ngIf="selectedAttachments.length > 0" class="mt-3">
                    <h3>Attachment1</h3>
                    <ng-container *ngFor="let attachment of selectedAttachments; let index = index;">
                        <div class="image_card mb-2">
                            <div *ngIf="true">
                                <img width="50" height="50" [src]="attachment.fileurl" data-bs-toggle="modal"
                                    data-bs-target="#comment-image-viewer" e="cursor: pointer" />
                                <span class="ms-3">{{ attachment.filename }}</span>
                            </div>
                            <button mat-icon-button class="icon-button" *ngIf="attachment1Selected"
                                (click)="removeAttachMent(index)">
                                <mat-icon class="delete-icon">delete</mat-icon>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>
</div>