<div class="container-fluid">
    <div class="content">
        <app-breadcrumb *ngIf="isFromDomain" [s_breadcrumb]="'Domains'" [t_breadcrumb]="domain"
            [f_breadcrumb]="'Admins'" [fi_breadcrumb]="adminAddorNot ? isEditing? 'Edit Admin':  'Add Admin': ''"
            (emit)="goToDomain()" (emit1)="goToDomainEdit()" (emit2)="cancel()">
        </app-breadcrumb>

        <app-breadcrumb *ngIf="!isFromDomain" [s_breadcrumb]="'Admins'"
            [t_breadcrumb]="adminAddorNot ? isEditing? 'Edit Admin':  'Add Admin': ''" (emit)="cancel()">
        </app-breadcrumb>


        <div class="mb-3 d-flex justify-content-between header">
            <div>
                <button *ngIf="!adminAddorNot" type="button" class="btn btn-custom"
                    (click)="adminAddorNot = !adminAddorNot">
                    <i class="fa fa-plus me-1"></i>Add Admin
                </button>

                <div *ngIf="adminAddorNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader  *ngIf="isAdminValid && adminForm.userid" (click)="submitAdmin()" [button_text]="'Save'"
                        [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>
                    <button *ngIf="!isAdminValid || !adminForm.userid" class="btn btn-custom" type="button" disabled>
                        Save
                    </button>
                </div>

            </div>
        </div>
        <form class="mb-4">

            <div *ngIf="adminAddorNot">
                <div class="mb-3">

                    <label for="userid" class="form-label">User ID
                        <span *ngIf="isAdminValid">( Name - {{username}} )</span>
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="userid" name="userid"
                            placeholder="john@domain.com, +959********" [(ngModel)]="adminForm.userid" required
                            #userid="ngModel" [ngClass]="{ 'is-invalid': adminFormSubmitted && userid.invalid }"
                            [disabled]="isEditing" (ngModelChange)="useridChange()">
                        <app-button-loader *ngIf="adminForm.userid.length != 0 && !isEditing" (click)="checkMember()"
                            [button_text]="'Check'" [load_button_text]="'Checking'" [isLoading]="checkingMember">
                        </app-button-loader>
                        <button *ngIf="adminForm.userid.length == 0 || isEditing" type="button" class="btn btn-custom"
                            disabled>Check</button>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="userid.invalid || (userid.dirty || userid.touched) && adminFormSubmitted">
                        <div *ngIf="userid.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
            </div>

        </form>
        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless" *ngIf="adminAddorNot == false">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="userid">User ID</td>
                        <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="username">Name</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="adminListLoading">
                    <tr>
                        <td colspan="5" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="adminListLoading == false">
                    <tr *ngIf="admins.length == 0">
                        <td colspan="5" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of admins | paginate: { itemsPerPage: 10, currentPage: p }; let index = index">
                        <td class="td-data" style="max-width: fit-content;">
                            <span tooltip data-bs-toggle="tooltip" data-bs-placement="right" [title]="item.userid">
                                {{item.userid}}
                                <!-- <span *ngIf="item.role == '300'">
                                    <img class="bluemark" src="../../../assets/images/bluemark.png">
                                </span> -->
                            </span>
                        </td>
                        <td class="td-data">
                            <span tooltip data-bs-toggle="tooltip" data-bs-placement="right"
                                [title]="item.username">{{item.username}} </span>
                        </td>
                        <td class="d-flex justify-content-center">
                            <!-- <button class="btn btn-outline-primary me-3" (click)="editAdmin(item)">
                                <i class='fa fa-edit'></i>
                            </button> -->
                            <button class="btn btn-outline-danger" (click)="deleteAdmin(item.id, item.userid)"
                                [disabled]="item.userid == allinoneService.getKunyekUserId()">
                                <i class='fa fa-trash-o'></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="admins.length > 10 && !adminAddorNot"
            previousLabel="Prev" nextLabel="Next"></pagination-controls>
    </div>
</div>