<section class="container-fluid">
    <div class="content">

        <div *ngIf="isSetting" class="admin-title-2 my-3">
            {{ title }}
        </div>

        <div>
            <ng-container *ngFor="let admin of teamsMenu">
                <div class="admin-title mt-3" *ngIf="admin.list">
                    {{admin.name}}
                </div>
                <div class="row g-0">
                    <ng-container *ngFor="let item of admin.list; let index = index">
                        <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card"
                            (click)="goToRoute(item)">
                            <div class="d-flex flex-column">
                                <div class="mb-2">
                                    <img [src]="item.icon" width="30px">
                                </div>
                                <div class="name">
                                    {{item.name}}
                                </div>
                            </div>
                            <div *ngIf="hrRequestCount > 0 && item.name == 'HR Request'">
                                <div class="bg-danger custom-badge">
                                  {{hrRequestCount}}
                                </div>
                              </div>
                              <div *ngIf="hrApprovalCount > 0 && item.name == 'HR Approval'">
                                <div class="bg-danger custom-badge">
                                  {{hrApprovalCount}}
                                </div>
                              </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</section>


<!-- <section class="container-fluid">
    <div class="content">
        <div *ngIf="!isSetting" class="admin-title my-3">
            {{ title }}
        </div>

        <div *ngIf="isSetting" class="admin-title-2 my-3">
            {{ title }}
        </div>

        <div *ngIf="isSetting">
            <ng-container *ngFor="let admin of teamsMenu">
                <div class="admin-title mt-3" *ngIf="admin.list">
                    {{admin.name}}
                </div>
                <div class="row g-0">
                    <ng-container *ngFor="let item of admin.list; let index = index">
                        <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card"
                            (click)="goToRoute(item)">
                            <div class="d-flex flex-column">
                                <div class="mb-2">
                                    <img [src]="item.icon" width="30px">
                                </div>
                                <div class="name">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>

        <div *ngIf="!isSetting" class="row g-0">
            <ng-container *ngFor="let item of teamsMenu; let index = index">
                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card"
                    (click)="goToRoute(item)">
                    <div class="d-flex flex-column">
                        <div class="mb-2">
                            <img [src]="item.icon" width="30px">
                        </div>
                        <div class="name">
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section> -->