import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-crm-customer-deal',
  templateUrl: './crm-customer-deal.component.html',
  styleUrls: ['./crm-customer-deal.component.css'],
})
export class CrmCustomerDealComponent implements OnInit {
  finalDealList: any = [];
  currencyType: any = [];
  statusList: any = [];
  stageList: any = [];
  deleteDealList: any = [];

  dataForEdit: any = '';
  customername: any = '';
  pipelinedeal: any = '';

  saveLoading: boolean = false;
  dealAdd: boolean = false;
  fromPipeline: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CrmCustomerDealComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fromPipeline = data.fromPipeline;
    if (this.fromPipeline) {
      this.pipelinedeal = data.data;
    } else {
      this.finalDealList = data.deals;
    }
    this.currencyType = data.currencyType;
    this.statusList = data.statusList;
    this.stageList = data.stageList;
    this.customername = data.customername;
    console.log("stage");
    console.log(this.statusList);
  }

  ngOnInit(): void {
    if(this.fromPipeline){
      this.editDeal(this.pipelinedeal)
    }
    else{
      if (this.finalDealList.length == 0) {
        this.dealAdd = true;
      } else {
        this.finalDealList.map((item: any) => {
          if (item.currency) {
            var index = this.currencyType
              .map((x: any) => x.key)
              .indexOf(item.currency);
            item.currencycode = this.currencyType[index].code;
          } else {
            item.currency = '001';
            item.currencycode = 'MMK';
          }
        });
      }
    }
  }

  close() {
    this.dialogRef.close({
      deletedeallist: this.deleteDealList,
      finaldeallist: this.finalDealList,
    });
  }

  editDeal(item: any) {
    this.dataForEdit = JSON.parse(JSON.stringify(item));
    console.log(this.dataForEdit)
    console.log("ookokokok")
    this.dealAdd = true;
  }

  delete(index: number) {
    if (this.finalDealList[index].dealid) {
      this.deleteDealList.push(this.finalDealList[index]);
    }
    this.finalDealList.splice(index, 1);
  }

  complete(data: any) {
    if (this.dataForEdit) {
      this.finalDealList.map((item: any) => {
        if (item.dealid == this.dataForEdit.dealid) {
          item.amount = data.amount;
          item.closingdate = data.closingdate;
          item.currency = data.currency;
          item.currencycode = data.currencycode;
          item.product = data.product;
          item.remark = data.remark;
          item.stageid = data.stageid;
          item.stagename = data.stagename;
          item.status = data.status;
          item.targetdate = data.targetdate;
          item.opendate = data.opendate;
          item.salecode = data.salecode;
        }
      });
    } else {
      const temp = {
        dealid: '',
        amount: data.amount,
        closingdate: data.targetdate,
        currency: data.currency,
        currencycode: data.currencycode,
        product: data.product,
        remark: data.remark,
        stageid: data.stageid,
        stagename: data.stagename,
        status: data.status,
        targetdate: data.targetdate,
        opendate: data.opendate,
        salecode: data.salecode,
      };
      this.finalDealList.push(temp);
    }

    console.log(this.finalDealList);
    console.log(">..");
    this.dataForEdit = '';
    this.dealAdd = false;
  }

  cancel() {
    this.dealAdd = false;
    this.dataForEdit = '';
  }
}
