import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-user-info-carway',
  templateUrl: './user-info-carway.component.html',
  styleUrls: ['./user-info-carway.component.scss']
})
export class UserInfoCarwayComponent implements OnInit {

  @ViewChild('chatPS') chatPS!: ElementRef;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  searchingCustomer: boolean = false;
  searchCustomerCancel: boolean = false;
  customerSearch = '';
  searchText: any = '';
  _contactList: any = [];
  customerList: any = [];
  selectedUserList: any = [];
  pickupuserList: any = [];
  selecteddropoffpointpassenger: any = [];
  gettingCustomerList: boolean = false;
  isAllUser: boolean = false;
  _isEnd: boolean = false;

  domainid: string = '';
  orgid: string = '';
  templateid: string = '';
  calendarid: string = '';

  startCount: any = 0;
  endCount: any = 30;

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;
  type: any = "";
  trantype: any = "";
  startdate: any = "";
  enddate: any = "";
  carwayid: any = "";
  index: any = "";
  dropoffuseidlist: any = [];
  mainlist: any = [];

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<UserInfoCarwayComponent>,
    private injector: Injector,
    private dialog: MatDialog
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.domainid = this.dialogData.domainid;
    this.orgid = this.dialogData.orgid;
    this.type = this.dialogData.type;
    this.trantype = this.dialogData.trantype;
    this.startdate = this.dialogData.startdate;
    this.enddate = this.dialogData.enddate;
    this.carwayid = this.dialogData.carwayid;
    this.index = this.dialogData.index;
    this.mainlist = this.dialogData.mainlist;

    console.log(">>>>>>>>>>>");
    console.log(this.dialogData);


  }

  ngOnInit(): void {
    if (this.type == "001") {
      this.selectedUserList = JSON.parse(
        JSON.stringify(this.dialogData.userlist)
      );
      if (this.mainlist.length == 0 && this.dialogData.checktype == "001") {
        console.log("11111")
        this.getoutstandingrequestListFromCarWay();
      } else if (this.mainlist.length > 0) {
        console.log("22222")
        this.getoutstandingrequestListFromCarWay();
      } else {
        console.log("33333")
        this.getoutstandingrequestList();
      }

    } else if (this.type == "002") {
      this.selectedUserList = JSON.parse(
        JSON.stringify(this.dialogData.userlist)
      );
      console.log(this.dialogData.userlist);
      this.pickupuserList = this.dialogData.pickupuserList;
      this.dropoffuseidlist = this.dialogData.dropoffuseidlist;
      this.selecteddropoffpointpassenger = this.dialogData.selecteddropoffpointpassenger;
      // this.selectedUserList = this.dialogData.userlist;
      // for (let i = 0; i < this.customerList.length; i++) {
      //   if (this.customerList[i]['checked'] == true) {
      //     this.selectedUserList.push(this.customerList[i]);
      //   }
      // }
      if (this.mainlist.length > 0) {
        this.getoutstandingrequestListFromCarWay();
      } else {
        this.getoutstandingrequestList();
      }
    }

  }

  searchChange() {
    if (this.type == '001') {

      if (this.customerSearch.trim() == '') {
        this.isAllUser = false;
      }
      clearTimeout(this.mytimeout);
      this.mytimeout = setTimeout(() => {
        // if (this.customerSearch.trim() != '') {
        this.customerList = [];
        this.chatPS.nativeElement.scrollTop = 0;
        this.startCount = 0;
        this.endCount = 30;
        // }
        // this.homesearchCustomer();
      }, 500);
    }

  }

  gettingData: boolean = false;
  outstandingrequestList = [];
  formatToShow = 'yyyy-MM-dd';
  format: any = "hh:mm aa";

  getoutstandingrequestList() {
    this.gettingCustomerList = true;
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "trantype": this.trantype,
      "requeststatus": "002",
      "startdate": this.startdate,
      "enddate": this.enddate,
      "carwayid": this.carwayid,
      "type": "001"
    };
    this.kunyekService.getOutstandingRequest(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          console.log("type" + this.type);
          console.log(this.selectedUserList);
          if (this.type == "001") {

            console.log(this.selectedUserList);
            if (res.datalist.length < 30) {
              this.isAllUser = true;
            }
            if (res['datalist'].length == 0) {
              this._isEnd = true;
            }
            if (this.startCount == 0 && this.endCount == 30) {
              this._contactList = [];
            }

            var data = [];
            for (let i = 0; i < res['datalist'].length; i++) {
              if (res['datalist'][i]['requestlist'].length > 0) {
                data.push({
                  "transportationid": res['datalist'][i]['requestlist'][0]['transportationid'],
                  "approvedby": res['datalist'][i]['requestlist'][0]['approvedby'],
                  "approvedtime": res['datalist'][i]['requestlist'][0]['approvedtime'],
                  "date": res['datalist'][i]['requestlist'][0]['date'],
                  "arrivetime": res['datalist'][i]['requestlist'][0]['arrivetime'],
                  "going": res['datalist'][i]['requestlist'][0]['going'],
                  "fromplace": res['datalist'][i]['requestlist'][0]['fromplace'],
                  "remark": res['datalist'][i]['requestlist'][0]['remark'],
                  "requeststatus": res['datalist'][i]['requestlist'][0]['requeststatus'],
                  "return": res['datalist'][i]['requestlist'][0]['return'],
                  "returntime": res['datalist'][i]['requestlist'][0]['returntime'],
                  "sort": res['datalist'][i]['requestlist'][0]['sort'],
                  "toplace": res['datalist'][i]['requestlist'][0]['toplace'],
                  "userid": res['datalist'][i]['userid'],
                  "userfrom": res['datalist'][i]['requestlist'][0]['userfrom'],
                  "username": res['datalist'][i]['username'],
                  "employeeid": res['datalist'][i]['requestlist'][0]['employeeid'],
                  "userto": res['datalist'][i]['requestlist'][0]['userto'],
                });
              }
            }


            var arrayContactList = data;
            console.log(arrayContactList);

            if (this.selectedUserList.length > 0) {
              arrayContactList.map((item: any) => {
                var checkexist = this.selectedUserList.find(
                  (x: any) => x.userid == item.userid
                );
                console.log(checkexist);
                if (checkexist) {
                  item.checked = true;
                }
              });
            }

            console.log(arrayContactList);
            console.log('contact');
            for (var a = 0; a < arrayContactList.length; a++) {
              var c = 0;
              for (var b = 0; b < this._contactList.length; b++) {
                if (
                  arrayContactList[a]['userid'] == this._contactList[b]['userid']
                ) {
                  this._contactList[b] = arrayContactList[a];
                  c = 1;
                }
              }
              if (c == 0) {
                this._contactList.push(arrayContactList[a]);
              }
            }

            var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
            for (let i = 0; i < this._contactList.length; i++) {
              if (this._contactList[i]['arrivetime'] != "") {
                console.log(atFormDate);
                console.log(this._contactList[i]['arrivetime']);
                var arrD = new Date(atFormDate + 'T' + this._contactList[i]['arrivetime']);
                console.log(arrD);
                console.log("1");

                var arrivetime = formatDate(arrD, this.format, 'en-US').toString();
                this._contactList[i]['arrivetime'] = arrivetime;
              }
              if (this._contactList[i]['returntime'] != "") {
                var retD = new Date(atFormDate + 'T' + this._contactList[i]['returntime']);
                var arrivetime = formatDate(retD, this.format, 'en-US').toString();
                this._contactList[i]['returntime'] = arrivetime;
              }
            }

            this.customerList = this._contactList;
            console.log(this.customerList);

            this.gettingCustomerList = false;
          } else if (this.type == "002") {
            var data = [];
            for (let i = 0; i < res['datalist'].length; i++) {
              if (res['datalist'][i]['requestlist'].length > 0) {
                data.push({
                  "transportationid": res['datalist'][i]['requestlist'][0]['transportationid'],
                  "approvedby": res['datalist'][i]['requestlist'][0]['approvedby'],
                  "approvedtime": res['datalist'][i]['requestlist'][0]['approvedtime'],
                  "date": res['datalist'][i]['requestlist'][0]['date'],
                  "arrivetime": res['datalist'][i]['requestlist'][0]['arrivetime'],
                  "going": res['datalist'][i]['requestlist'][0]['going'],
                  "fromplace": res['datalist'][i]['requestlist'][0]['fromplace'],
                  "remark": res['datalist'][i]['requestlist'][0]['remark'],
                  "requeststatus": res['datalist'][i]['requestlist'][0]['requeststatus'],
                  "return": res['datalist'][i]['requestlist'][0]['return'],
                  "returntime": res['datalist'][i]['requestlist'][0]['returntime'],
                  "sort": res['datalist'][i]['requestlist'][0]['sort'],
                  "toplace": res['datalist'][i]['requestlist'][0]['toplace'],
                  "userid": res['datalist'][i]['userid'],
                  "userfrom": res['datalist'][i]['requestlist'][0]['userfrom'],
                  "username": res['datalist'][i]['username'],
                  "employeeid": res['datalist'][i]['requestlist'][0]['employeeid'],
                  "userto": res['datalist'][i]['requestlist'][0]['userto'],
                });
              }
            }
            var arr = [];
            for (let i = 0; i < this.pickupuserList.length; i++) {
              for (let j = 0; j < data.length; j++) {

                if (this.pickupuserList[i]['userid'] == data[j]['userid'] && this.pickupuserList[i]['checked'] == true) {
                  arr.push(data[j]);
                }
              }
            }
            var arr2 = [];
            if (this.dropoffuseidlist.length > 0) {
              for (let i = 0; i < this.dropoffuseidlist.length; i++) {
                for (let j = 0; j < arr.length; j++) {
                  if (this.dropoffuseidlist[i]['userid'] != arr[j]['userid']) {
                    arr2.push(arr[j])
                  }

                }
              }
            }



            console.log(arr);
            console.log("111");
            var arrayContactList = arr2.length > 0 ? arr2 : arr;
            if (this.selecteddropoffpointpassenger.length > 0) {
              arrayContactList.map((item: any) => {
                var checkexist = this.selecteddropoffpointpassenger.find(
                  (x: any) => x.userid == item.userid
                );
                console.log(checkexist);
                if (checkexist) {
                  item.checked = true;
                }
              });
            }

            for (var a = 0; a < arrayContactList.length; a++) {
              var c = 0;
              for (var b = 0; b < this._contactList.length; b++) {
                if (
                  arrayContactList[a]['userid'] == this._contactList[b]['userid']
                ) {
                  this._contactList[b] = arrayContactList[a];
                  c = 1;
                }
              }
              if (c == 0) {
                this._contactList.push(arrayContactList[a]);
              }
            }
            var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
            for (let i = 0; i < this._contactList.length; i++) {
              if (this._contactList[i]['arrivetime'] != "") {
                console.log(atFormDate);
                console.log(this._contactList[i]['arrivetime']);
                var arrD = new Date(atFormDate + 'T' + this._contactList[i]['arrivetime']);

                console.log("1")
                var arrivetime = formatDate(arrD, this.format, 'en-US').toString();
                this._contactList[i]['arrivetime'] = arrivetime;
              }
              if (this._contactList[i]['returntime'] != "") {
                var retD = new Date(atFormDate + 'T' + this._contactList[i]['returntime']);
                var arrivetime = formatDate(retD, this.format, 'en-US').toString();
                this._contactList[i]['returntime'] = arrivetime;
              }
            }

            this.customerList = this._contactList;

          }
          // }
          console.log(this.customerList);
          this.gettingCustomerList = false;
        } else {

          this.gettingCustomerList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingCustomerList = false;
      }
    );
  }


  getoutstandingrequestListFromCarWay() {
    if (this.type == "001") {

      console.log(this.selectedUserList);
      if (this.mainlist.length < 30) {
        this.isAllUser = true;
      }
      if (this.mainlist.length == 0) {
        this._isEnd = true;
      }
      if (this.startCount == 0 && this.endCount == 30) {
        this._contactList = [];
      }

      var data = [];
      for (let i = 0; i < this.mainlist.length; i++) {
        // if (this.mainlist.length > 0) {
        data.push({
          "transportationid": this.mainlist[i]['transportationid'],
          "approvedby": this.mainlist[i]['approvedby'],
          "approvedtime": this.mainlist[i]['approvedtime'],
          "date": this.mainlist[i]['date'],
          "arrivetime": this.mainlist[i]['arrivetime'],
          "going": this.mainlist[i]['going'],
          "fromplace": this.mainlist[i]['fromplace'],
          "remark": this.mainlist[i]['remark'],
          "requeststatus": this.mainlist[i]['requeststatus'],
          "return": this.mainlist[i]['return'],
          "returntime": this.mainlist[i]['returntime'],
          "sort": this.mainlist[i]['sort'],
          "toplace": this.mainlist[i]['toplace'],
          "userid": this.mainlist[i]['userid'],
          "userfrom": this.mainlist[i]['userfrom'],
          "username": this.mainlist[i]['username'],
          "employeeid": this.mainlist[i]['employeeid'],
          "userto": this.mainlist[i]['userto'],
        });
        // }
      }


      var arrayContactList = data;
      console.log(arrayContactList);

      if (this.selectedUserList.length > 0) {
        arrayContactList.map((item: any) => {
          var checkexist = this.selectedUserList.find(
            (x: any) => x.userid == item.userid
          );
          console.log(checkexist);
          if (checkexist) {
            item.checked = true;
          }
        });
      }

      console.log(arrayContactList);
      console.log('contact');
      for (var a = 0; a < arrayContactList.length; a++) {
        var c = 0;
        for (var b = 0; b < this._contactList.length; b++) {
          if (
            arrayContactList[a]['userid'] == this._contactList[b]['userid']
          ) {
            this._contactList[b] = arrayContactList[a];
            c = 1;
          }
        }
        if (c == 0) {
          this._contactList.push(arrayContactList[a]);
        }
      }

      var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      for (let i = 0; i < this._contactList.length; i++) {
        if (this._contactList[i]['arrivetime'] != "") {
          console.log(atFormDate);
          console.log(this._contactList[i]['arrivetime']);
          var arrD = new Date(atFormDate + 'T' + this._contactList[i]['arrivetime']);
          console.log(arrD);
          console.log("1");

          var arrivetime = formatDate(arrD, this.format, 'en-US').toString();
          this._contactList[i]['arrivetime'] = arrivetime;
        }
        if (this._contactList[i]['returntime'] != "") {
          var retD = new Date(atFormDate + 'T' + this._contactList[i]['returntime']);
          var arrivetime = formatDate(retD, this.format, 'en-US').toString();
          this._contactList[i]['returntime'] = arrivetime;
        }
      }

      this.customerList = this._contactList;
      console.log(this.customerList);

      this.gettingCustomerList = false;
    } else if (this.type == "002") {
      var data = [];
      for (let i = 0; i < this.mainlist.length; i++) {
        // if (this.mainlist[i]['requestlist'].length > 0) {
        data.push({
          "transportationid": this.mainlist[i]['transportationid'],
          "approvedby": this.mainlist[i]['approvedby'],
          "approvedtime": this.mainlist[i]['approvedtime'],
          "date": this.mainlist[i]['date'],
          "arrivetime": this.mainlist[i]['arrivetime'],
          "going": this.mainlist[i]['going'],
          "fromplace": this.mainlist[i]['fromplace'],
          "remark": this.mainlist[i]['remark'],
          "requeststatus": this.mainlist[i]['requeststatus'],
          "return": this.mainlist[i]['return'],
          "returntime": this.mainlist[i]['returntime'],
          "sort": this.mainlist[i]['sort'],
          "toplace": this.mainlist[i]['toplace'],
          "userid": this.mainlist[i]['userid'],
          "userfrom": this.mainlist[i]['userfrom'],
          "username": this.mainlist[i]['username'],
          "employeeid": this.mainlist[i]['employeeid'],
          "userto": this.mainlist[i]['userto'],
        });
        // }
      }
      var arr = [];
      for (let i = 0; i < this.pickupuserList.length; i++) {
        for (let j = 0; j < data.length; j++) {

          if (this.pickupuserList[i]['userid'] == data[j]['userid'] && this.pickupuserList[i]['checked'] == true) {
            arr.push(data[j]);
          }
        }
      }
      var arr2 = [];
      if (this.dropoffuseidlist.length > 0) {
        for (let i = 0; i < this.dropoffuseidlist.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            if (this.dropoffuseidlist[i]['userid'] != arr[j]['userid']) {
              arr2.push(arr[j])
            }

          }
        }
      }



      console.log(arr);
      console.log("111");
      var arrayContactList = arr2.length > 0 ? arr2 : arr;
      if (this.selecteddropoffpointpassenger.length > 0) {
        arrayContactList.map((item: any) => {
          var checkexist = this.selecteddropoffpointpassenger.find(
            (x: any) => x.userid == item.userid
          );
          console.log(checkexist);
          if (checkexist) {
            item.checked = true;
          }
        });
      }

      for (var a = 0; a < arrayContactList.length; a++) {
        var c = 0;
        for (var b = 0; b < this._contactList.length; b++) {
          if (
            arrayContactList[a]['userid'] == this._contactList[b]['userid']
          ) {
            this._contactList[b] = arrayContactList[a];
            c = 1;
          }
        }
        if (c == 0) {
          this._contactList.push(arrayContactList[a]);
        }
      }
      var atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
      for (let i = 0; i < this._contactList.length; i++) {
        if (this._contactList[i]['arrivetime'] != "") {
          console.log(atFormDate);
          console.log(this._contactList[i]['arrivetime']);
          var arrD = new Date(atFormDate + 'T' + this._contactList[i]['arrivetime']);

          console.log("1")
          var arrivetime = formatDate(arrD, this.format, 'en-US').toString();
          this._contactList[i]['arrivetime'] = arrivetime;
        }
        if (this._contactList[i]['returntime'] != "") {
          var retD = new Date(atFormDate + 'T' + this._contactList[i]['returntime']);
          var arrivetime = formatDate(retD, this.format, 'en-US').toString();
          this._contactList[i]['returntime'] = arrivetime;
        }
      }

      this.customerList = this._contactList;

    }
  }

  onScrolledDown() {
    if (this.type == "001") {
      if (!this.isAllUser && !this.gettingCustomerList) {
        this.startCount = this.startCount + 30;
        this.endCount = this.endCount + 30;
        // this.homesearchCustomer();
      }
    }

  }

  selectUser(event: any, user: any) {
    // var index = this.customerList.findIndex(
    //   (cus: any) => cus.userid == user.userid
    // );
    console.log(index);
    console.log(user);
    // if (index != -1) {
    //   this.customerList[index].checked = !this.customerList[index].checked;
    // }
    console.log(event.target.checked);
    if (event.target.checked) {
      var index = this.selectedUserList.findIndex(
        (seluser: any) => seluser.userid == user.userid
      );
      if (index == -1) {
        user.checked = true;
        this.selectedUserList.push(user);
        // for (let i = 0; i < this.selectedUserList.length; i++) {
        //   this.selectedUserList = Object.assign(this.selectedUserList[i], {
        //     checked: true
        //   });
        // }

      } else {
        this.selectedUserList[index].checked = true;
      }
      console.log(this.selectedUserList);
    } else {
      var index = this.selectedUserList.findIndex(
        (seluser: any) => seluser.userid == user.userid
      );
      if (index != -1) {
        // this.selectedUserList[index].checked = false;
        this.selectedUserList.splice(index, 1);
      }
    }
    console.log(this.selectedUserList);
  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    let finalList = [];
    // finalList = this.selectedUserList.filter((user: { checked: boolean }) => {
    //   return user.checked == true;
    // });
    console.log(this.selectedUserList);
    let data = [];
    for (let i = 0; i < this.selectedUserList.length; i++) {
      this.selectedUserList[i]['checked'] = true;
    }
    console.log(this.selectedUserList)
    console.log("okko");
    let userlist = [];

    for (let i = 0; i < this.selectedUserList.length; i++) {
      console.log("jaejae");
      userlist.push(this.selectedUserList[i]);

      data.push({
        "requestid": this.selectedUserList[i]['requestid'],
        "userid": this.selectedUserList[i]['userid'],
      });
      console.log(userlist);
    }
    console.log(userlist);





    var senddata = {
      "data": data,
      "userlist": userlist,
      "type": this.dialogData.type,
      "index": this.index,

    }
    console.log(senddata);

    this.dialogRef.close({ data: senddata });
  }



}
