import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ProductsComponent } from '../products/products.component';

@Component({
  selector: 'app-crm-deal-add',
  templateUrl: './crm-deal-add.component.html',
  styleUrls: ['./crm-deal-add.component.css'],
})

export class CrmDealAddComponent implements OnInit {
  @Input() fromCustomer: boolean = false;
  @Input() fromPipeline: boolean = false;
  @Input() orgid: any;
  @Input() currencyType: any = [];
  @Input() statusList: any = [];
  @Input() stageList: any = [];
  stageSList: any = [];

  @Input() customerStatus: any = [];
  @Input() editData: any;
  @Output('save')
  save: EventEmitter<any> = new EventEmitter<any>();
  @Output('crmcancel')
  cancel: EventEmitter<any> = new EventEmitter<any>();
  dealFormSubmitted: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  gettingCustomers: boolean = false;
  customers: any[] = [];
  productList: any[] = [];
  searchText: string = '';

  //date format - yyyy-mm-dd
  //for filter date
  aweekAfter = new Date().setDate(new Date().getDate() + 10);
  formatToShow = 'yyyy-MM-dd';
  checkclosingdate: boolean = false;
  closingdate: any;

  defaultOpenDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();

  defaultTargetDate = formatDate(
    new Date(this.aweekAfter),
    this.formatToShow,
    'en-US'
  ).toString();

  dealForm = {
    dealid: '',
    customerid: '',
    customername: '',
    product: [],
    targetdate: this.defaultTargetDate,
    // closingdate: '',
    salecode: '',
    opendate: this.defaultOpenDate,
    currency: '001',
    currencycode: 'MMK',
    amount: '',
    status: '001',
    stageid: '',
    stagename: '',
    orgid: '',
    domainid: '',
    remark: '',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) { }

  async ngOnInit(): Promise<void> {
    this.dealForm.orgid = this.orgid;
    this.dealForm.domainid = this.allinoneService.getDomain().domainid;

    //
    // this.closingdate = formatDate(
    //   Date.now(),
    //   this.formatToShow,
    //   'en-US'
    // ).toString();
    console.log(";lwkj;flkj;alwkjefk11");
    this.getStages();
    if (!this.fromCustomer && !this.editData) {

      this.getCustomers();
    } else {
      this.dealForm.customername = this.dealForm.orgid;
      this.searchText = this.dealForm.customername;
    }
    console.log(`002>> ${this.dealForm.customername}`);


    if (this.editData) {
      if (this.editData.status != '001') {
        this.checkclosingdate = true;
        this.closingdate = this.allinoneService.formatDBToShowInEdit(
          this.editData.closingdate
        )
      }
      this.isEditing = true;

      this.dealForm = {
        dealid: this.editData.dealid,
        customerid: this.editData.customerid,
        customername: this.fromCustomer
          ? this.orgid
          : this.editData.customername,
        product: [],
        targetdate: this.allinoneService.formatDBToShowInEdit(
          this.editData.targetdate
        ),
        // closingdate: '',
        salecode: this.editData.salecode,
        opendate: this.editData.opendate ? this.allinoneService.formatDBToShowInEdit(
          this.editData.opendate
        ) : this.defaultOpenDate,
        currency: this.editData.currency ? this.editData.currency : '001',
        currencycode: '',
        amount: this.editData.amount,
        status: this.editData.status,
        stageid: this.editData.stageid,
        stagename: this.editData.stagename,
        orgid: this.dealForm.orgid,
        domainid: this.dealForm.domainid,
        remark: this.editData.remark,


      };
      console.log("Ojief")
      console.log(this.dealForm)
      // console.log(this.edi)
      if (!this.fromCustomer) {
        this.searchText = this.editData.customername;
      }





      if (this.dealForm.currency) {
        var index = this.currencyType
          .map((x: any) => x.key)
          .indexOf(this.dealForm.currency);
        this.dealForm.currencycode = this.currencyType[index].code;
      }
      // if (!this.fromCustomer) {
      //   var cindex = this.customers
      //     .map((x: any) => x.customerid)
      //     .indexOf(this.dealForm.customerid);
      //   this.searchText = this.customers[cindex].customername;
      // }
      this.editData.product.map((item: any) => {
        const temp = {
          id: item.id,
          skucode: item.skucode,
          name: item.name,
          price: item.price,
          qty: item.qty,
          amount: parseFloat(item.price) * parseInt(item.qty),
        };
        this.productList.push(temp);
      });
    }
  }

  cancelFunction() {
    this.cancel.emit();
  }

  gettingStages: boolean = false;
  getStages() {
    this.gettingStages = true;
    const data = {
      orgid: '',
      domainid: this.dealForm.domainid,
    };
    this.kunyekService.getPipelineStage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.stageSList = res.list;
          if (this.stageSList.length > 0) {
            if (!this.isEditing) {
              this.dealForm.stageid = this.stageSList[0].stageid;
              this.dealForm.stagename = this.stageSList[0].name;
            }

          }
          console.log(this.stageList);
          // this.crmForm.stageid = this.stages[0].stageid;
          this.gettingStages = false;
        } else {
          this.gettingStages = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStages = false;
      }
    );
  }

  onChange($event: any) {

    if (this.dealForm.status != '001') {
      this.closingdate = formatDate(
        Date.now(),
        this.formatToShow,
        'en-US'
      ).toString();
      this.checkclosingdate = true;
    }
    else {
      this.checkclosingdate = false;
    }
  }
  submitDeal() {
    this.dealFormSubmitted = true;
    if (this.dealForm.targetdate < this.dealForm.opendate) {
      this.messageService.openSnackBar(
        'Please select a valid target date.',
        'warn'
      );
    } else if (this.fromCustomer) {
      var tempProdList: any[] = [];
      this.productList.map((item: any) => {
        const temp = {
          id: item.id,
          skucode: item.skucode,
          name: item.name,
          price: item.price.toString(),
          qty: item.qty.toString(),
        };
        tempProdList.push(temp);
      });
      var data = {
        product: tempProdList,
        targetdate: this.allinoneService.formatDate(this.dealForm.targetdate),
        closingdate: this.dealForm.status == '001' ? '' : this.closingdate
          ? this.allinoneService.formatDate(this.closingdate) : '',
        salecode: this.dealForm.salecode,
        opendate: this.allinoneService.formatDate(this.dealForm.opendate),
        currency: this.dealForm.currency,
        amount: this.dealForm.amount ? this.dealForm.amount.toString() : '0',
        status: this.dealForm.status,
        stageid: this.dealForm.stageid,
        stagename: this.dealForm.stagename,
        remark: this.dealForm.remark,
        currencycode: this.dealForm.currencycode
      };
      console.log(data);

      this.save.emit(data);
    } else {
      if (this.dealForm.customerid == '') {
        this.messageService.openSnackBar('Please select customer.', 'warn');
        return;
      } else {
        this.saveDeal();
      }
    }
  }

  saveDeal() {
    if (this.dealForm.amount.length > 50 || this.dealForm.salecode.length > 50) {
      return;
    }

    this.saveLoading = true;
    var tempProdList: any[] = [];
    this.productList.map((item: any) => {
      const temp = {
        id: item.id,
        skucode: item.skucode,
        name: item.name,
        price: item.price.toString(),
        qty: item.qty.toString(),
      };
      tempProdList.push(temp);
    });
    var data = {
      customerid: this.dealForm.customerid,
      product: tempProdList,
      targetdate: this.dealForm.targetdate,
      closingdate: this.dealForm.status == '001' ? '' : this.closingdate
        ? this.allinoneService.formatDate(this.closingdate) : '',
      salecode: this.dealForm.salecode,
      opendate: this.dealForm.opendate,
      currency: this.dealForm.currency,
      amount: this.dealForm.amount ? this.dealForm.amount.toString() : '0',
      status: this.dealForm.status,
      stageid: this.dealForm.stageid,
      orgid: this.dealForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
      remark: this.dealForm.remark,
    };

    if (this.isEditing) {
      data = Object.assign(data, {
        dealid: this.dealForm.dealid,
      });
    }
    console.log(">>dataa");
    console.log(data);

    var fun = this.isEditing
      ? this.kunyekService.editCrmDeal(data)
      : this.kunyekService.createCrmDeal(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.save.emit();
        } else {
          this.saveLoading = false;
          this.dealFormSubmitted = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.dealFormSubmitted = false;
      }
    );
  }

  getCustomers() {
    return new Promise((resolve: any) => {
      this.gettingCustomers = true;
      const data = {
        domainid: this.dealForm.domainid,
        orgid: this.dealForm.orgid,
      };
      this.kunyekService.getCrmCustomers(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.customers = res.list;
            this.customers.map((item: any) => {
              var index = this.customerStatus
                .map((x: any) => x.key)
                .indexOf(item.customerstatus);
              item.statusname = this.customerStatus[index].value;
            });
            this.gettingCustomers = false;
            resolve(true);
          } else {
            this.gettingCustomers = false;
            this.messageService.openSnackBar(res.status, 'warn');
            resolve(false);
          }
        },
        (err) => {
          resolve(false);
          this.gettingCustomers = false;
        }
      );
    });
  }

  customerChange() {
    this.dealForm.customerid = '';
    this.dealForm.customername = '';
  }

  customerSelect(customer: any) {
    this.dealForm.customerid = customer.customerid;
    this.dealForm.customername = customer.customername;
    this.searchText = customer.customername;
  }

  dealStageChange(event: any) {
    this.dealForm.stageid = event.target.value;
    var index = this.stageSList
      .map((x: any) => x.stageid)
      .indexOf(this.dealForm.stageid);
    this.dealForm.stagename = this.stageSList[index].name;
  }

  currencyChange(event: any) {
    this.dealForm.currency = event.target.value;
    if (this.dealForm.currency == '') {
      this.dealForm.currencycode = '';
    } else {
      var index = this.currencyType
        .map((x: any) => x.key)
        .indexOf(this.dealForm.currency);
      this.dealForm.currencycode = this.currencyType[index].code;
    }
  }

  addItem(item?: any) {
    if (this.fromPipeline) {
      return;
    }
    const dialog = this.dialog.open(ProductsComponent, {
      width: '700px',
      height: '550px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        form: 'deal',
        editData: item ? item : '',
      },
    });

    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
        var index = this.productList
          .map((x: any) => x.skucode)
          .indexOf(res.skucode);
        if (item && index != -1) {
          this.productList[index].name = res.name.toString();
          this.productList[index].price = res.price.toString();

          this.productList[index].qty = res.qty.toString();
          this.productList[index].amount =
            parseInt(this.productList[index].qty) *
            parseFloat(this.productList[index].price);
        } else {
          if (index == -1) {
            this.productList.push({
              id: res.skucode,
              skucode: res.skucode,
              name: res.name,
              price: res.price.toString(),
              qty: res.qty.toString(),
              amount: parseFloat(res.price) * parseInt(res.qty),
            });
          } else if (index != -1) {
            this.productList[index].qty =
              parseInt(this.productList[index].qty) + parseInt(res.qty);
            this.productList[index].amount =
              parseInt(this.productList[index].qty) *
              parseFloat(this.productList[index].price);
          }
        }
      }
      this.dealForm.amount = '';
      var tempAmount = 0;
      this.productList.map((item: any) => {
        tempAmount += parseFloat(item.amount);
      });
      this.dealForm.amount = tempAmount.toString();
    });
  }

  deleteProduct(index: number) {
    var tempTotal = parseFloat(this.dealForm.amount ? this.dealForm.amount : '0')
    var tempAmount = parseFloat(this.productList[index].amount)
    if (tempTotal >= tempAmount) {
      this.dealForm.amount = (tempTotal - tempAmount).toString()
    }
    this.productList.splice(index, 1);
  }
}
