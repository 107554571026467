<h3>{{ isEditing ? "Edit" : "New" }} Attendance QR</h3>
<div class="row">
  <div class="col-md-12">
    <div class="form-group mb-3">
      <label class="form-label" for="name"> Name </label>
      <input type="text" class="form-control" placeholder="Enter Name" id="name" name="name" [(ngModel)]="attForm.name"
        #name="ngModel" [ngClass]="{'is-invalid' : submitted && name.invalid }" required />
      <div class="invalid-feedback" *ngIf="
              name.invalid ||
              ((name.dirty || name.touched) && submitted)
            ">
        <div *ngIf="name.errors?.required">
          {{ allinoneService.input_err_msg }}
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-3">
      <label for="location" class="form-label">Location </label>
      <select class="form-select" aria-label="Select Location" id="location" name="location"
        (change)="locChanged($event)" [disabled]="gettingLocList" [(ngModel)]="attForm.locid" #loc="ngModel"
        [ngClass]="{'is-invalid' : submitted && loc.invalid}" required>
        <option selected value="">Select Location</option>
        <option *ngFor="let loc of locations" [value]="loc.locid">
          {{ loc.name }}
        </option>
      </select>
      <mat-progress-bar mode="indeterminate" *ngIf="gettingLocList"></mat-progress-bar>

      <div class="invalid-feedback" *ngIf="loc.invalid || (loc.dirty || loc.touched) && submitted">
        <div *ngIf="loc.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-3">
      <label for="defaulttype" class="form-label">Default Type </label>
      <select class="form-select" aria-label="Select Default Type" id="defaulttype" name="defaulttype"
        [(ngModel)]="attForm.defaulttype" #defaulttype="ngModel"
        [ngClass]="{'is-invalid' : submitted && defaulttype.invalid}" required>
        <option selected value="">Select Location</option>
        <option *ngFor="let type of typeList" [value]="type.value">
          {{ type.name }}
        </option>
      </select>

      <div class="invalid-feedback" *ngIf="defaulttype.invalid || (defaulttype.dirty || defaulttype.touched) && submitted">
        <div *ngIf="defaulttype.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-3">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="showhistory" name="showhistory"
          [(ngModel)]="attForm.showhistory" />
        <label class="form-check-label" for="showhistory"> Show Scanned Users </label>
      </div>
    </div>
    <div mat-dialog-actions>
      <div class="mb-3 w-100">
        <div class="d-flex align-items-center flex-row flex-wrap justify-content-end">
          <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
          </button>
          <app-button-loader [button_text]="'Save'" (click)="submitForm()" [load_button_text]="'Saving'"
            [isLoading]="saveLoading">
          </app-button-loader>
        </div>
      </div>
    </div>
  </div>
</div>