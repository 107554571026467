import { data } from 'jquery';
import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { CrmCustomerDealComponent } from '../crm-customer-deal/crm-customer-deal.component';
import { CrmServiceFilterbydateComponent } from '../crm-service-filterbydate/crm-service-filterbydate.component';
import { PipelineTotalamountComponent } from '../pipeline-totalamount/pipeline-totalamount.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CrmCustomerAdvansearchComponent } from '../crm-customer-advansearch/crm-customer-advansearch.component';

@Component({
  selector: 'app-crm-pipeline',
  templateUrl: './crm-pipeline.component.html',
  styleUrls: ['./crm-pipeline.component.scss']
})
export class CrmPipelineComponent implements OnInit {

  isTabletView: boolean = false;

  isMobileView: boolean = false;
  @HostListener('window:resize', ['$event'])

  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {
      this.isMobileView = false;
      this.isTabletView = true;
    } else {
      this.isMobileView = false;
      this.isTabletView = false;

    }

  }

  isView: boolean = false;
  gettingData: boolean = true;
  gettingServicData: boolean = false;
  leadAdd: boolean = false;
  customerAdd: boolean = false;
  dealAdd: boolean = false;
  serviceAdd: boolean = false;
  summaryCheck: boolean = false;
  historyshow: boolean = false;
  isFocus: boolean = false;
  gettingStages: boolean = false;

  dataForEdit: any = ''
  mobileViewWidth: number = 426;
  viewWidth: number = 992;
  itemPerPage: number = 100;
  p: number = 1;
  callType: any = '';
  servcietypelist: any = [];
  statusLists: any = [];
  searchStatusLead: any = '';
  searchStatusCustomer: any = '';
  searchstagedeal: any = '';
  searchStatusdeal: any = '';
  priority: any = [];
  filterstartdate: any = '';
  filterenddate: any = '';
  filterstartRdate: any = '';
  checkdate: boolean = false;

  organizationsList: any = [];
  gettingOrgList: boolean = false;
  orgtype: any = '';

  totalopencount: any = '0';
  totalclosedcount: any = '0';


  gettingCustomers: boolean = false;
  selfcustomer: any = [];
  customers: any = [];
  text1: any = '';
  text2: any = '';
  checkcustomer: boolean = false;
  statussearch: any = '';
  servicesearch: any = '';
  statussearchcus: any = '';
  statusListsCustomer = [
    { "code": "0010", "name": "New", 'check': true },
    { "code": "0020", "name": "WIP", 'check': false },
    { "code": "0040", "name": "Completed", 'check': false },
    { "code": "00900", "name": "Closed", 'check': false }
  ]




  // (change)="selectProject($event)"
  leadstatusname: any = 'Status'
  // ChangeLeadStatus1(event: any) {
  //   this.searchStatusLead = event.target.value;
  //   console.log(this.searchStatusLead)

  // }
  ChangeLeadStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.leadstatusname = 'Status';
      this.searchStatusLead = '';
    } else {
      this.searchStatusLead = data.value;
      this.leadstatusname = data.value;
    }
  }

  customerstatusname: any = 'Status';

  // ChangeCustomerStatus(event: any) {
  //   this.searchStatusCustomer = event.target.value;
  //   console.log(this.searchStatusCustomer)
  // }
  customerstatus: any = '';
  ChangeCustomerStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.customerstatusname = 'Status';
      // this.searchStatusCustomer = '';
      this.customerstatus = '';
      this.refreshgetData();
    } else {
      this.customerstatusname = data.value;
      // this.searchStatusCustomer = data.value;
      this.customerstatus = data.key;
      this.refreshgetData();
    }
  }




  // ChangeDealStatus(event: any) {
  //   this.searchStatusdeal = event.target.value;
  //   console.log(this.searchStatusdeal)

  // }
  dealstatusname: any = 'Status';
  ChangeDealStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.dealstatusname = 'Status';
      this.searchStatusdeal = '';
    } else {
      this.dealstatusname = data.value;
      this.searchStatusdeal = data.value;
    }
  }




  dealstagename: any = "Stage"
  // ChangeDealStage(event: any) {
  //   this.searchstagedeal = event.target.value;
  //   console.log(this.searchstagedeal)
  // }
  ChangeDealStage(data: any) {
    console.log(data)
    if (data == 'stage') {
      this.dealstagename = 'Stage';
      this.searchstagedeal = '';
    } else {
      this.dealstagename = data.name;
      this.searchstagedeal = data.name;
    }
  }



  // servicetypes = [
  //   { "type": "System Error", "code": "001" },
  //   { "type": "User Error", "code": "002" },
  //   { "type": "Requirement - New Req.", "code": "003" },
  //   { "type": "Requirement - Change Req.", "code": "004" },
  //   { "type": "Mixed Issue (Gray)", "code": "005" },
  //   { "type": "Data Change/Script", "code": "006" },
  //   { "type": "Service Request", "code": "007" },
  //   { "type": "Hardware Related", "code": "008" },
  //   { "type": "Networking Related", "code": "009" },
  //   { "type": "Performance Issues", "code": "0010" },
  //   { "type": "Enquiry", "code": "0011" },
  //   { "type": "Implementation", "code": "0012" },
  //   { "type": "Training Request", "code": "0013" },
  //   { "type": "License Request", "code": "0014" },
  //   { "type": "New Installation", "code": "0015" },
  //   { "type": "Others", "code": "0016" },
  // ];

  // servicesdata: any = [
  //   // { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "Closed",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "Closed",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  //   //  { "refno": "1234", "description": "test", "type": "1", "status": "New",
  //   //  "assignedto": "test", "reportedby": "nyi", "rporteddate": "28/03/2022", "rportedtime": "3" },
  // ];
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 500,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  types: any[] = [
    {
      id: '001',
      name: 'Leads',
    },
    {
      id: '002',
      name: 'Customers',
    },
    {
      id: '003',
      name: 'Deals',
    },
    {
      id: '004',
      name: 'Pipelines',
    },
    {
      id: '005',
      name: 'Services',
    },
  ];

  //Lead Status
  leadStatus = [
    {
      key: '001',
      value: 'Closed',
    },
    {
      key: '002',
      value: 'Open',
    },
  ];


  //Customer Status
  customerStatus = [
    {
      key: '001',
      value: 'Prospect',
    },
    {
      key: '002',
      value: 'Customer',
    },
  ];

  //Deal Status
  dealStatus = [
    {
      key: '001',
      value: 'Open',
    },
    {
      key: '002',
      value: 'Closed - Won',
    },
    {
      key: '003',
      value: 'Closed - Lost',
    },
  ];

  //Business Type
  businessType = [
    {
      key: '001',
      value: 'Organization',
    },
    {
      key: '002',
      value: 'Person',
    },
  ];

  //Currency Type
  currencyType = [
    {
      key: '001',
      currency: 'Kyat',
      code: 'MMK',
      country: 'Myanmar',
    },
    {
      key: '025',
      currency: 'dollar',
      code: 'USD',
      country: 'United States',
    },
    {
      key: '002',
      currency: 'Singapore dollar',
      code: 'SGD',
      country: 'Singapore',
    },
    {
      key: '003',
      currency: 'đồngr',
      code: 'VND',
      country: 'Vietnam',
    },
    {
      key: '004',
      currency: 'UAE dirham',
      code: 'AED',
      country: 'United Arab Emirates',
    },
    {
      key: '005',
      currency: 'Turkish lira',
      code: 'TRY',
      country: 'Turkey',
    },
    {
      key: '006',
      currency: 'Baht',
      code: 'THB',
      country: 'Thailand',
    },
    {
      key: '007',
      currency: 'Rupiah',
      code: 'IDR',
      country: 'Indonesia',
    },
    {
      key: '008',
      currency: 'New Taiwan dollar',
      code: 'TWD',
      country: 'Taiwan',
    },
    {
      key: '009',
      currency: 'South Korean won',
      code: 'KRW',
      country: 'South Korea',
    },
    {
      key: '010',
      currency: 'Yen',
      code: 'JPY',
      country: 'Japan',
    },
    {
      key: '011',
      currency: 'Russian Ruble',
      code: 'RUB',
      country: 'Russia',
    },
    {
      key: '012',
      currency: 'Philippine peso',
      code: 'PHP',
      country: 'Philippines',
    },
    {
      key: '013',
      currency: 'Pakistani Rupee',
      code: 'PKR',
      country: 'Pakistan',
    },
    {
      key: '014',
      currency: 'Nepalese rupee',
      code: 'NPR',
      country: 'Nepal',
    },
    {
      key: '015',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '016',
      currency: 'Jordanian dinar',
      code: 'JOD',
      country: 'Jordan',
    },
    {
      key: '017',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '018',
      currency: 'Indian Rupee',
      code: 'INR',
      country: 'India',
    },
    {
      key: '019',
      currency: 'Hong Kong dollar',
      code: 'HKD',
      country: 'Hong Kong',
    },
    {
      key: '020',
      currency: 'Renminbi (yuan)',
      code: 'CNY',
      country: 'China',
    },
    {
      key: '021',
      currency: 'Cambodian riel',
      code: 'KHR',
      country: 'Cambodia',
    },
    {
      key: '022',
      currency: 'Brunei dollar',
      code: 'BND',
      country: 'Brunei',
    },
    {
      key: '023',
      currency: 'Bhutanese ngultrum',
      code: 'BTN',
      country: 'Bhutan',
    },
    {
      key: '024',
      currency: 'Taka',
      code: 'BDT',
      country: 'Bangladesh',
    },
  ];

  stages: any[] = [];


  stageID: any = [];
  statusID: any = [];

  formatToShow = 'yyyy-MM-dd';
  now = Date.now();
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() + 30);
  currentEndDate = formatDate(
    this.oneWeekBefore,
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();

  crmForm = {
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    type: '',
    stageid: '',
    status: '',
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
  };

  organizations: any[] = [];
  data: any[] = [];
  @Input() width!: string;

  searchText: any = '';
  searchTOrgext: any = '';
  searchTextService: any = '';
  tabletViewWidth: any = 992;
  statusFilter: any = '';
  serviceFilter: any = '';

  routeType: any = '';
  allrouteType: any = '';
  @Input() t_breadcrumb!: string;
  projectList: any = '';
  gettingProjectList: boolean = false;
  projectid: any = '';
  startdatetime: string = '';
  enddatetime: string = '';
  searchLoading: boolean = false;
  nextDisabled: boolean = false;
  lastPage: any = '';
  currentPage: any = 1;
  total: any = '0';
  sortby: any = '';
  getServiceType: boolean = true;
  getSatatusType: boolean = true;
  statuscode: any = ['0010'];
  statusname: any = "Status";

  statuscodeList: any = [];
  statusnameList: any = ['New'];

  servicecode: string = '';
  viewopenservice: boolean = false;
  servicetype: any = '';
  goCustomer: any = '';
  goCusBol: boolean = false;
  goDealBol: boolean = false;
  gopipBol: boolean = false;
  checkOrgB: boolean = false;
  orgname: any = '';
  routevalueQ: any = '';
  routevalueP: any = '';


  routevalueAll: any = '';
  pagnation: any = [];
  Days: any;
  endDays: any = ''
  productadmin: boolean = false
  filtergetdata: any = [];
  isAdmin: boolean = false;
  customername: any = '';
  awaitDomain: any = '';
  orgList: any = [];
  customercare: boolean = false;
  customernamecare: any = "";
  getCustomercaredata: any = [];
  customeridcare: any = "";
  contactid: any = '';
  contactadmin: boolean = false;
  customerData: any = [];


  private subs = new SubSink();
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  // pagesize:any = 0;
  private dialogRef: any;
  private dialogData: any;
  checkModeal: boolean = false;
  isExcel: boolean = false;
  downloaddata: any = [];
  excelexportLoading: boolean = false;
  permission: any = [];
  leadview: boolean = false;
  leadcreate: boolean = false;
  leadedit: boolean = false;
  leaddelete: boolean = false;

  dealview: boolean = false;
  dealcreate: boolean = false;
  dealedit: boolean = false;
  dealdelete: boolean = false;

  pipelineview: boolean = false;
  pipelinecreate: boolean = false;
  pipelineedit: boolean = false;
  pipelinedelete: boolean = false;

  customerview: boolean = false;
  customercreate: boolean = false;
  customeredit: boolean = false;
  customerdelete: boolean = false;

  view: boolean = false;
  create: boolean = false;
  edit: boolean = false;
  delete: boolean = false;
  customerid: any = '';
  ticketno: any = '';
  customerValue: any = '';
  checkselfservice: boolean = false;
  projectsmid: any = '';
  productsmid: any = '';
  customersmid: any = '';



  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private injector: Injector,

  ) {

    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);

    if (this.dialogData) {
      console.log(this.dialogData);
      this.checkModeal = this.dialogData.check;
      // this.projForm.org = this.dialogData.orgid;
      this.crmForm.type = '002';
      this.crmForm.orgid = this.dialogData.orgid;
      console.log(this.dialogData.picList);
      console.log('Pass Candi>>');
      // this.deleteList = JSON.parse(JSON.stringify(data.deletelist));
      console.log('--AA--');
    } else {

      console.log("Indexindex")
      console.log(this.allinoneService.getindex())

      if (window.innerWidth < this.mobileViewWidth) {
        this.isMobileView = true;
        this.itemPerPage = 20
        this.isTabletView = false;
      } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {
        this.isMobileView = false;
        this.isTabletView = true;
      }
      this.organizations = this.allinoneService.orgsCRm;
      



      // else {
      //   this.router.navigateByUrl('sales')
      // }

    }
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.allinoneService.activeroute = "/sales";

  }


  async ngOnInit(): Promise<void> {
    // this.router.navigateByUrl('service');

    // this.organizations = this.allinoneService.orgsCRm;
    if (this.organizations.length > 0) {
      this.crmForm.orgid = this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid;
    }




    if(this.allinoneService.crmBlocked == 'true'){
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    }else{
    if (this.allinoneService.crmOrgAdmin == 'true') {
      console.log("1")
    } else {
      console.log("1");
      this.permission = this.allinoneService.getCRMpermission();

      if (this.permission.length > 0) {

        var type = this.permission.map((x: any) => x).indexOf('007');
        if (type == -1) {
          this.router.navigateByUrl('sales')
        }

        var view = this.permission.map((x: any) => x).indexOf('0071');
        var create = this.permission.map((x: any) => x).indexOf('0072');
        var edit = this.permission.map((x: any) => x).indexOf('0073');
        var delet = this.permission.map((x: any) => x).indexOf('0074');
        if (view == -1) {
          this.pipelineview = true;
        }
        if (create == -1) {
          this.pipelinecreate = true;
        }

        if (edit == -1) {
          this.pipelineedit = true;
        }

        if (delet == -1) {
          this.pipelinedelete = true;
        }

      } else {
        this.router.navigateByUrl('sales')
      }
    }
  }


    //   if (this.getCustomercaredata != '') {
    //     this.crmForm.orgid = this.getCustomercaredata.orgid;
    //   } else {
    //     this.organizations = this.allinoneService.orgsCRm;
    //     this.crmForm.orgid = this.allinoneService.selectedOrgCRM
    //       ? this.allinoneService.selectedOrgCRM
    //       : this.organizations[0].orgid;
    //   }

    // } else {
    //   if (this.getCustomercaredata != '') {
    //     this.crmForm.orgid = this.getCustomercaredata.orgid;
    //   } else {
    //     this.organizations = this.allinoneService.orgsCRm;
    //     this.crmForm.orgid = this.allinoneService.selectedOrgCRM
    //       ? this.allinoneService.selectedOrgCRM
    //       : this.organizations[0].orgid;
    //   }
    // }




    if (this.pipelineview == true) {
      this.gettingData = false;
    } else {
      this.getData();
      this.getStages();
    }


    this.startdatetime = formatDate(
      new Date(),
      this.formatToShow,
      'en-US'
    ).toString();
    var futureDate = new Date();

    futureDate.setDate(futureDate.getDate() + 7);
    this.enddatetime = formatDate(
      futureDate,
      this.formatToShow,
      'en-US'
    ).toString();
    console.log(this.enddatetime)
    if (this.width == null) {
      this.width = "auto";
    }









  }






  sortData(sort: Sort) {
    const data = this.data;
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        //Pipeline
        case 'pipelinename':
          return this.allinoneService.compare(
            a.customername.toLocaleLowerCase(),
            b.customername.toLocaleLowerCase(),
            isAsc
          );
        case 'pipelinetargetdate':
          return this.allinoneService.compare(
            a.targetdate,
            b.targetdate,
            isAsc
          );
        case 'pipelineclosingdate':
          return this.allinoneService.compare(
            a.closingdate,
            b.closingdate,
            isAsc
          );
        case 'stage':
          return this.allinoneService.compare(
            a.stage,
            b.stage,
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(
            a.status,
            b.status,
            isAsc
          );
        case 'pipelineamount':
          return this.allinoneService.compare(a.amount, b.amount, isAsc);
        case 'pipelinecurrency':
          return this.allinoneService.compare(
            a.currencyname,
            b.currencyname,
            isAsc
          );
        case 'pipelinecreatedby':
          return this.allinoneService.compare(
            a.createdbyname.toLocaleLowerCase(),
            b.createdbyname.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }
    });

  }





  orgChanged(event: any) {
    this.crmForm.orgid = event.target.value;
    if (this.crmForm.orgid == '') {
      this.data = [];
    } else {
      if (this.callType == '300') {
        this.allinoneService.selectedOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedOrg(this.crmForm.orgid);
      } else {
        this.allinoneService.selectedConOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedConOrg(this.crmForm.orgid);
      }
      this.getData();
    }
  }

  typeChanged(event: any) {
    this.crmForm.type = event.target.value;
    if (this.crmForm.orgid) {
      this.getData();
    }
  }

  getStages() {
    this.gettingStages = true;
    const data = {
      orgid: '',
      domainid: this.crmForm.domainid,
    };
    this.kunyekService.getPipelineStage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.stages = res.list;
          // this.crmForm.stageid = this.stages[0].stageid;
          this.gettingStages = false;
        } else {
          this.gettingStages = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStages = false;
      }
    );
  }
  industryList: any = [];

  leadsourceList: any = [];
  getData() {
    this.gettingData = true;
    // this.data = [];
    // this.p = 1;
    this.total = '0';


    // var startdate =
    const data = {
      domainid: this.crmForm.domainid,
      orgid: this.crmForm.orgid,
      startdate: this.crmForm.startdate,
      enddate: this.crmForm.enddate,
      status: this.crmForm.status,
      stageid: this.crmForm.stageid,
      pagesize: this.itemPerPage,
      sortby: this.sortby,
    };
    this.subs.sink = this.kunyekService.getPipelines(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.data = res.list;
          // this.itemPerPage = 100;
          // this.pg[3].count = this.data.length;
          console.log(">>>>dta");
          console.log(res.list);
          res.list.map((item: any) => {
            var statusIndex = this.leadStatus
              .map((x) => x.key)
              .indexOf(item.status);
            var currencyIndex = this.currencyType
              .map((x) => x.key)
              .indexOf(item.currency);
            // item.statusname =
            //   statusIndex > -1 ? this.leadStatus[statusIndex].value : '';
            // item.currencyname =
            //   currencyIndex > -1 ? this.currencyType[currencyIndex].code : '';
            // if () {
            //   item.closingdate = '';
            // }


            this.data.push({
              "amount": item.amount,
              'closingdate': item.status == '001' ? '' : item.closingdate,
              'createdby': item.createdby,
              'createdbyname': item.createdbyname,
              'currency': item.currency,
              'customerid': item.customerid,
              'customername': item.customername,
              'dealid': item.dealid,
              'product': item.product,
              'deallist': item.deallist,
              'remark': item.remark,
              'sort': item.sort,
              'stageid': item.stageid,
              'stagename': item.stagename,
              'salecode':item.salecode,
              'currencyname': currencyIndex > -1 ? this.currencyType[currencyIndex].code : '',
              'status': item.status,
              'targetdate': item.targetdate,
            })
          })
          console.log(this.data);

          this.total = res.total;
          var sort = this.data.length - 1;


          if (parseInt(this.total) == this.data.length) {
            console.log("getTrue")
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.data[sort].sortby;
          } else {
            console.log("getfalse");
            this.sortby = sort > -1 ? this.data[sort].sort : '';
          }
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }



  viewDeal(item: any) {
    console.log("item")
    console.log(item)
    const dialog = this.dialog.open(CrmCustomerDealComponent, {
      width: '700px',
      height: "550px",
      closeOnNavigation: false,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        'data': item,
        'fromPipeline': true,
        'currencyType': this.currencyType,
        'statusList': this.dealStatus,
        'stageList': this.stages,
        'customername': item.customername
      }
    })
  }



  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    if (this.crmForm.type != '005') {
      console.log("okiejfi")
      // this.refreshgetData();
      // this.getData();
    } {
      // this.refresh();
      // this.getServiceData(this.projectid);
    }
  }



  backCustomer() {
    this.router.navigateByUrl('sales/customers')
  }

  cancel() {
    this.dataForEdit = ''
    this.summaryCheck = false;
    this.serviceAdd = false;
    this.historyshow = false;
    this.isExcel = false;
    this.orgtype = this.crmForm.orgid;
  }






  openTotalamountForm() {
    const dialog = this.dialog.open(PipelineTotalamountComponent, {
      width: '500px',
      height: '350px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        form: 'pipeline',
        datalist: this.data
      },
    });
    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    });

  }

  goToSecond() {

  }


  close() {
    const data = {
      'status': true,
    }

    this.dialogRef.close(data);
  }



  refreshgetData() {
    this.subs.unsubscribe();
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';


    if (this.pipelineview == true) {
      this.gettingData = false;
    } else {
      this.getData();
      // this.getStages();
    }


    // this.getData();
  }












  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.lastPage);
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;

        if (event > (this.data.length / this.itemPerPage)) {

          this.getData();
        }
      }

    }
  }


  clear() {

    this.searchbool = false;
    this.searchText = '';
    this.refreshgetData()

  }
  
  stageChange(data: any) {
    if (data != undefined && !Array.isArray(data)) {
      this.crmForm.stageid = data.stageid;
    } else {
      this.crmForm.stageid = '';
    }


  }
  changeStatus(data: any) {
    if (data != undefined && !Array.isArray(data)) {
      this.crmForm.status = data.key;
    } else {
      this.crmForm.status = '';
    }

  }


  onSearch() {
    if (this.searchText != '') {
      this.searchbool = true;
      this.refreshgetData()
    }

  }

  downloadSheetPipeline() {
    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');

    var name = 'Pipeline_' + time + '.xlsx';
    console.log(this.data);
    for (let i = 0; i < this.data.length; i++) {
      var statusIndex = this.currencyType.map((x) => x.key).indexOf(this.data[i].currency);
      if (this.data[i].product.length == 0) {
        data.push({

          'Customer': this.data[i].customername,
          'TargetDate': this.allinoneService.formatDBToShow(this.data[i].targetdate),
          'ClosingData': this.data[i].closingdate != "" ? this.allinoneService.formatDBToShow(this.data[i].closingdate) : "",
          'Stage': this.data[i].stagename,
          'Status': this.data[i].status == '001' ? 'Open' : this.data[i].status == '002' ? 'Closed - Won' : this.data[i].status == '003' ? 'Closed - Lost' : '',
          'Product': '',
          'Price': '',
          'Qty': '',
          'Amount': this.data[i].amount,
          'Currency': this.currencyType[statusIndex].code,
          'Createdby': this.data[i].createdby,
        })
      } else if (this.data[i].product.length > 0) {
        for (let j = 0; j < this.data[i].product.length; j++) {
          data.push({
            'Customer': this.data[i].customername,
            'TargetDate': this.allinoneService.formatDBToShow(this.data[i].targetdate),
            'ClosingData': this.data[i].closingdate != "" ? this.allinoneService.formatDBToShow(this.data[i].closingdate) : "",
            'Stage': this.data[i].stagename,
            'Status': this.data[i].status == '001' ? 'Open' : this.data[i].status == '002' ? 'Closed - Won' : this.data[i].status == '003' ? 'Closed - Lost' : '',
            'Product': this.data[i].product[j].name,
            'Price': this.data[i].product[j].price,
            'Qty': this.data[i].product[j].qty,
            'Amount': this.data[i].amount,
            'Currency': this.currencyType[statusIndex].code,
            'Createdby': this.data[i].createdby,
          })
        }
      }

    }

    this.allinoneService.exportEcecl(data, name);
  }

  itempercount: any = '';


  goCrm() {
    this.router.navigateByUrl('service')
  }



  searchbool: any = false;



  goSummary() {
    this.router.navigateByUrl('service/summary')
  }
  goSummarySelfservice() {
    this.router.navigateByUrl('service')
  }
  backCustomerbreadcrumb() {
    this.router.navigateByUrl('sales/customers')

  }
  backCustomerbreadcrumbedit() {
    const customerid = this.route.snapshot.queryParamMap.get('customerid') || "";
    // ;
    this.router.navigateByUrl(`sales/customers?id=${customerid}`)
  }




}
