<div class="cc-container">
    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="label title" *ngIf="!moduleAddOrNot">Modules</div>
            <div class="label title" *ngIf="moduleAddOrNot">Add Module</div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close [disabled]="saveLoading" (click)="Close()">
                <mat-icon>close</mat-icon>
            </button>
            </div>
        </div>

        <div class="mb-2 d-flex header">
            <div>
                <button *ngIf="!moduleAddOrNot" type="button" class="btn btn-custom" (click)="newmodule()">
                <i class="fa fa-plus me-1"></i>Add
            </button>

                <div *ngIf="moduleAddOrNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                    Cancel
                </button>
                    <app-button-loader (click)="submitmodule()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
                    </app-button-loader>
                </div>
            </div>
        </div>
        <div *ngIf="!moduleAddOrNot" class="None" style="padding: 1px;">
            <div *ngIf="modulelist.length == 0" class="nodata d-flex align-items-center justify-content-center">
                Empty
            </div>
            <div class="d-flex flex-column products-card p-1 my-2" *ngFor="let module of modulelist; let i = index;">
                <div class="d-flex flex-column" (click)="editmodule(module)">
                    <div class="d-flex justify-content-between">
                        <div class="p-1 product-name ">
                            {{module.description}}
                            <!-- wefwef -->
                        </div>
                        <div (click)="delete(i)">
                            <i class="fas fa-times edit-icon"></i>
                        </div>
                    </div>
                    <div class="d-flex p-1 justify-content-between">
                        <div>
                            {{module.picname}}
                        </div>
                        <div class="p-1 product-name ">


                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="moduleAddOrNot">


            <div class="row">
                <div class="mb-3 col-md-12">
                    <label for="des" class="form-label">Description</label>

                    <input type="text" class="form-control" id="des" name="des" [(ngModel)]="cForm.description" required #name="ngModel" [ngClass]="{ 'is-invalid': cFormSubmitted && name.invalid }">

                    <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && cFormSubmitted">
                        <div *ngIf="name.errors?.required">

                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-12">
                    <label for="ref" class="form-label pic">Person in Charge</label>
                    <select class="form-select" aria-label="Select type" id="pic" name="pic" [(ngModel)]="cForm.picid" (change)="selectpic($event)">
                        <option value=''>-</option>
                        <option *ngFor="let pic of piclist;" value="{{pic.picid}}">
                            {{pic.picname == "" ? pic.userid : pic.picname}}</option>
                    </select>

                </div>


            </div>



        </div>
    </perfect-scrollbar>
</div>