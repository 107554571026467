<div class='container-fluid'>
    <div class="content">
        <!-- <ng-container *ngIf="isAdmin">
            <app-team-breadcrumb *ngIf="!isviewornew" s_breadcrumb="Leave Entitlement">
            </app-team-breadcrumb>
            <app-team-breadcrumb *ngIf="isviewornew" s_breadcrumb="Leave Entitlement"
                [t_breadcrumb]="isEditing ? 'Leave Entitlement' : 'New Leave Entitlement'" (emit)="cancel()">
            </app-team-breadcrumb>
        </ng-container>

        <ng-container *ngIf="!isAdmin">
            <app-office-breadcrumb *ngIf="!isviewornew" [s_breadcrumb]="'Leave Entitlement'">
            </app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="isviewornew" [s_breadcrumb]="'Leave Entitlement'"
                [t_breadcrumb]="isEditing ? 'Leave Entitlement' : 'New Leave Entitlement'" (emit)="cancel()">
            </app-office-breadcrumb>
        </ng-container> -->

        <ng-container *ngIf="allinoneService.isAttendanceView">
            <app-hcm-breadcrumb *ngIf="!isviewornew" [s_breadcrumb]="'Leave Entitlement'">
            </app-hcm-breadcrumb>
            <app-hcm-breadcrumb *ngIf="isviewornew" [s_breadcrumb]="'Leave Entitlement'"
                [t_breadcrumb]="isEditing ? 'Leave Entitlement' : 'New Leave Entitlement'" (emit)="cancel()">
            </app-hcm-breadcrumb>
        </ng-container>

        <ng-container *ngIf="!allinoneService.isAttendanceView">
            <app-team-breadcrumb *ngIf="!isviewornew" s_breadcrumb="Leave Entitlement">
            </app-team-breadcrumb>
            <app-team-breadcrumb *ngIf="isviewornew" s_breadcrumb="Leave Entitlement"
                [t_breadcrumb]="isEditing ? 'Leave Entitlement' : 'New Leave Entitlement'" (emit)="cancel()">
            </app-team-breadcrumb>
        </ng-container>

        <div class="close-save-button mb-3" *ngIf="isviewornew">
            <button type="button" class="btn btn-custom" (click)="cancel()" *ngIf="!saveLoading">
                Cancel
            </button>
            <app-button-loader class="ms-2" *ngIf="!isEditing || (isEditing && searchForm.active)"
                (click)="submitEntitle()" [button_text]="'Save'" [load_button_text]="'Saving'"
                [isLoading]="saveLoading">
            </app-button-loader>
            <ng-container *ngIf="isEditing">
                <div class="line"></div>
                <div>
                    <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export To Excel">
                        <img class="excel-icon" src="../../../assets/images/excel.png">
                        Export
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="!isEditing">
                <div class="line"></div>
                <div>
                    <button type="button" class="btn btn-custom" (click)="importFromPrevEntitlement()"
                        title="Import from Previous Entitlement">
                        <img class="excel-icon" src="../../../assets/images/excel.png"> Import Members
                    </button>
                </div>
            </ng-container>
        </div>



        <div class="row row-custom header" *ngIf="!isviewornew">
            <div class="col-md-6 mb-3 d-flex">
                <button class="btn btn-custom" (click)="newLeaveEntitle()">
                    <i class="fa fa-plus me-1"></i> Leave Entitlement
                </button>
                <ng-container *ngIf="searchForm.active">
                    <div class="line"></div>
                    <div>
                        <button type="button" class="btn btn-custom" (click)="calculateBroughtForward()"
                            title="Import from Previous Entitlement">
                            Calculate Brought Forward
                        </button>
                    </div>
                </ng-container>

            </div>


        </div>

        <div *ngIf="isviewornew">
            <!-- <form action=""> -->
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="year" class="form-label">
                        Year
                    </label>
                    <!-- <select class="form-select" id="year" name="year" [(ngModel)]="leaveEntitlementForm.year" required
              #year="ngModel" (change)="calendarChange($event)"
              [ngClass]="{ 'is-invalid': leavEntitleFormSubmitted && year.invalid }">
              <option *ngFor="let yr of yearList;" value="{{yr.calendarid}}"
                [selected]="yr.calendarid == leaveEntitlementForm.year">{{yr.year}}
              </option>
            </select> -->
                    <input class="form-select" [(ngModel)]="leaveEntitlementForm.yearname"
                        [disabled]="calenderListLoading || (isEditing && !searchForm.active)"
                        [matMenuTriggerFor]="calendarMenu" readonly #year="ngModel" (change)="calendarChange($event)"
                        required [ngClass]="{ 'is-invalid': leavEntitleFormSubmitted && year.invalid }">
                    <mat-menu #calendarMenu="matMenu" id="service-menu" xPosition="after" class="input-calendar-menu">
                        <ng-container *ngIf="isEditing">
                            <button mat-menu-item *ngIf="allCalendarList.length == 0">
                                -
                            </button>
                            <ng-container *ngFor="let cal of allCalendarList">
                                <button mat-menu-item *ngIf="cal.calendarid != ''"
                                    [ngClass]="{'text-danger' : !cal.valid}" (click)="changeCalenderforTemp(cal)">
                                    {{ cal.year }}<mat-icon class="selected-year"
                                        *ngIf="cal.calendarid == leaveEntitlementForm.year">
                                        check</mat-icon>
                                </button>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!isEditing">
                            <button mat-menu-item *ngIf="activeCalendarList.length == 0">
                                -
                            </button>
                            <ng-container *ngFor="let cal of activeCalendarList">
                                <button mat-menu-item *ngIf="cal.calendarid != ''"
                                    [ngClass]="{'text-danger' : !cal.valid}" (click)="changeCalenderforTemp(cal)">
                                    {{ cal.year }}<mat-icon class="selected-year"
                                        *ngIf="cal.calendarid == leaveEntitlementForm.year">
                                        check</mat-icon>
                                </button>
                            </ng-container>
                        </ng-container>
                    </mat-menu>
                    <mat-progress-bar mode="indeterminate" *ngIf="calenderListLoading"></mat-progress-bar>

                    <div class="invalid-feedback"
                        *ngIf="year.invalid || (year.dirty || year.touched) && leavEntitleFormSubmitted">
                        <div *ngIf="year.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="template" class="form-label">
                        Template
                    </label>
                    <select class="form-select" id="template" name="template"
                        [(ngModel)]="leaveEntitlementForm.template" required #template="ngModel"
                        (change)="templateChange($event)"
                        [ngClass]="{ 'is-invalid': leavEntitleFormSubmitted && template.invalid }"
                        [disabled]="isEditing && !searchForm.active">
                        <option *ngFor="let tem of templeteList;" value="{{tem.templateid}}"
                            [selected]="tem.templateid == leaveEntitlementForm.template">{{tem.name}}
                        </option>
                    </select>
                    <mat-progress-bar mode="indeterminate" *ngIf="templateListLoading"></mat-progress-bar>

                    <div class="invalid-feedback"
                        *ngIf="template.invalid || (template.dirty || template.touched) && leavEntitleFormSubmitted">
                        <div *ngIf="template.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="mb-3 col-md-12">
                    <div style="display: flex;">
                        <button class="btn btn-custom" (click)="chooseType()">
                            <i class="fas fa-plus-circle me-2"></i> Types
                        </button>
                        <button class="btn btn-custom ms-4" (click)="chooseUser()">
                            <i class="fas fa-plus-circle me-2"></i> Members
                        </button>
                    </div>



                </div> -->
            <!-- </form> -->


            <div class="row mt-3">

                <div class="mb-3 col-md-6">

                    <div class="d-flex aligns-item-center justify-content-between flex-wrap">
                        <div class="me-2">
                            <button class="btn btn-custom mb-3" (click)="chooseUser()"
                                [disabled]="isEditing && !searchForm.active">
                                <i class="fas fa-plus-circle me-2"></i> Members
                            </button>
                        </div>
                        <div class="input-group mb-3" style="max-width: 300px !important; min-width: 200px !important;">
                            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                                (focus)="isMemberSearchFocus = true" (blur)="isMemberSearchFocus = false"
                                [(ngModel)]="memberSearchText"
                                [ngClass]="{'c-search-input-with-clear' : memberSearchText }"
                                style="font-family: FontAwesome,Ubuntu;">
                            <button class="btn btn-outline-secondary " type="button" *ngIf="memberSearchText"
                                (click)="clearMemberSearch()"
                                [ngClass]="{'c-clear' : isMemberSearchFocus, 'c-clear-not-focus': !isMemberSearchFocus}">
                                <mat-icon class="c-icon">close</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="mb-3 accordion" id="accordionContainer">
                        <div class="accordion-item">
                            <h3 class="accordion-header" [id]="'heading' + 'member'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse' + 'member'" aria-expanded="true"
                                    [attr.aria-controls]="'collapse' + 'member'">
                                    Members
                                    <div> Count: {{ showCheckedCount(memberList)}}</div>
                                </button>
                            </h3>
                            <div [id]="'collapse' + 'member'" class="accordion-collapse collapse show"
                                [attr.aria-labelledby]="'heading' + 'member'">
                                <div class="accordion-body">
                                    <ul *ngIf="memberList.length > 0" class="list-group">
                                        <div class="profile-container"
                                            *ngFor="let user of memberList| filter: memberSearchText:'member' ">
                                            <span>
                                                <img class="profile-image me-3"
                                                    [defaultImage]="'../../../assets/images/profile.png'"
                                                    [lazyLoad]="user.photo || '../../../assets/images/profile.png'">
                                            </span>

                                            <label
                                                class="d-flex align-items-center align-items-center-custom justify-content-center">
                                                <div class="profile-item-container">
                                                    <div class="profile-item">
                                                        <!-- <div class="username" style="cursor: pointer;">
                              <b>{{user.username || user.userid}}</b>
                            </div> -->
                                                        <div *ngIf="user.username" class="username"
                                                            style="cursor: pointer;">
                                                            <b>{{user.username }}</b>
                                                        </div>
                                                        <div *ngIf="user.userid && !user.username && !user.newuserid"
                                                            class="username" style="cursor: pointer;">
                                                            <b>{{user.userid }}</b>
                                                        </div>
                                                        <div *ngIf="user.userid && !user.username && user.newuserid"
                                                            class="username" style="cursor: pointer;">
                                                            <b>{{user.userid }}, {{user.newuserid}}</b>
                                                        </div>
                                                        <!-- <div *ngIf="user.newuserid != '' && user.userid != '' && user.username == ''" class="username" style="cursor: pointer;">
                              <b>{{user.userid}}, {{user.newuserid}}</b>
                            </div> -->
                                                        <div *ngIf="user.username != '' && user.newuserid == '' " style="color: grey;
                                                        margin-top: 1px;
                                                        font-size: 0.8rem;">{{user.userid}}
                                                        </div>
                                                        <div *ngIf="user.username != '' && user.newuserid != '' " style="color: grey;
                                                        margin-top: 1px;
                                                        font-size: 0.8rem;">{{user.userid}}, {{user.newuserid}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="checkbox" class="form-check-input" [id]="user.primarykey"
                                                    [(ngModel)]="user.checked" [checked]="user.checked"
                                                    [disabled]="isEditing && !searchForm.active">
                                            </label>

                                        </div>
                                    </ul>

                                    <div class="no-data"
                                        *ngIf="(memberList | filter: memberSearchText:'member').length == 0">
                                        No Data
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="mb-3 col-md-6">
                    <button class="btn btn-custom mb-3" (click)="chooseType()"
                        [disabled]="isEditing && !searchForm.active">
                        <i class="fas fa-plus-circle me-2"></i> Posts
                    </button>

                    <div class="mb-3 accordion" id="accordionContainer">
                        <div class="accordion-item">
                            <h3 class="accordion-header" [id]="'heading' + 'membertype'">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#collapse' + 'membertype'" aria-expanded="true"
                                    [attr.aria-controls]="'collapse' + 'membertype'">
                                    Posts
                                    <div> Count: {{showCheckedCount(memberTypeList)}}</div>
                                </button>
                            </h3>
                            <div [id]="'collapse' + 'membertype'" class="accordion-collapse collapse show"
                                [attr.aria-labelledby]="'heading' + 'membertype'">
                                <div class="accordion-body">
                                    <ul *ngIf="memberTypeList.length > 0" class="list-group">
                                        <div class="profile-container" *ngFor="let typ of memberTypeList">

                                            <label
                                                class="d-flex align-items-center align-items-center-custom justify-content-center">
                                                <div class="profile-item-container">
                                                    <div class="profile-item">
                                                        <div class="username" style="cursor: pointer;">
                                                            &nbsp;<b>{{typ.classname}}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="checkbox" class="form-check-input" [id]="typ.classname"
                                                    [(ngModel)]="typ.checked" [checked]="typ.checked"
                                                    [disabled]="isEditing && !searchForm.active">
                                            </label>

                                        </div>
                                    </ul>

                                    <div class="no-data" *ngIf="memberTypeList.length == 0">
                                        No Data
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isviewornew">
            <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center ">
                <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                    <input class="form-control" (ngModelChange)="filter()" type="text" placeholder="&#xf002; Search"
                        aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{'c-search-input-with-clear' : searchText }"
                        style="font-family: FontAwesome,Ubuntu;">
                    <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>

                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <div c style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">
                                {{page.name}}
                            </label>
                        </li>
                    </ul>
                </div>

                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div class="line "></div>
                <div class="filtter-btn align-items-center" [matMenuTriggerFor]="yearmenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button"
                        id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle
                        style="color: white; min-width: 100px">
                        Year&nbsp;:&nbsp;{{ searchForm.yearname }}
                        <mat-menu #yearmenu="matMenu" xPosition="before">
                            <ng-container *ngIf="allCalendarList.length > 0; else emptyTemplate">
                                <button mat-menu-item *ngFor="let year of allCalendarList"
                                    (click)="searchYearChange(year)">
                                    <span [style.color]="year.active && year.valid ? 'black' : 'red'">
                                        {{ year.year }}
                                    </span>
                                </button>
                            </ng-container>
                            <ng-template #emptyTemplate>
                                <div mat-menu-item>
                                    No Calendar Found.
                                </div>
                            </ng-template>
                        </mat-menu>
                    </button>
                </div>
                <div class="line "></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{leaveEntititleList.length}}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input class="form-control" (ngModelChange)="filter()" type="text" placeholder="&#xf002; Search"
                        aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{'c-search-input-with-clear' : searchText }"
                        style="font-family: FontAwesome,Ubuntu;">
                    <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>

                <pagination-controls id="rule" class="paginate upper-paginate mt-2 ms-auto"
                    (pageChange)="currentPage = $event" *ngIf="leaveEntititleList.length > 0 " previousLabel="Prev"
                    nextLabel="Next">
                </pagination-controls>

            </div>

            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="template">Template</td>
                            <td scope="col" class="col-lg-5 col-md-5">Member Ranks</td>
                            <td scope="col" class="col-lg-5 col-md-5">Member</td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>

                    <tbody *ngIf="leaveEntitleListLoading">
                        <tr>
                            <td colspan="3" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!leaveEntitleListLoading">
                        <tr *ngIf="leaveEntititleList.length == 0">
                            <td colspan="3" class="nodata">Empty</td>
                        </tr>

                        <tr
                            *ngFor="let entitle of leaveEntititleList | filter : searchText:'officeleaveentitle' | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, id : 'rule' }; let index = index">
                            <td class="td-data td-edit" (click)="editLeaveEntitle(entitle)">
                                <span [title]="entitle.templatename">{{entitle.templatename}}</span>
                            </td>

                            <td class="td-data td-edit">
                                <span [title]='entitle.membertype.join(", ")'>{{entitle.membertype.join(", ")}}</span>
                            </td>

                            <td class="td-data">
                                <span [title]='entitle.users.join(", ")'>
                                    {{entitle.users.join(", ")}}
                                </span>
                            </td>

                            <td class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary me-2" (click)="editLeaveEntitle(entitle)">
                                    <i class='fa fa-edit'></i>
                                </button>
                                <button class="btn btn-outline-danger" (click)="delLeaveEntitle(entitle.entitlementid)">
                                    <i class='fa fa-trash-o'></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination-controls id="rule" class="paginate" (pageChange)="currentPage = $event"
                *ngIf="leaveEntititleList.length > 0 " previousLabel="Prev" nextLabel="Next">
            </pagination-controls>

        </div>


    </div>