
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
// import { BaseRouteReuseStrategy } from './base-route-reuse-strategy.service';

export class CurtomRouteReuseStrategy extends RouteReuseStrategy {

  private routeLeftFrom: any;
  private handlers: { [key: string]: DetachedRouteHandle } = {};
  // shouldDetach(route: ActivatedRouteSnapshot): boolean {
  //   if (!route.routeConfig || route.routeConfig.loadChildren) {
  //     return false;
  //   }
  //   let shouldReuse = false;
  //   console.log('checking if this route should be re used or not', route);
  //   if (route.routeConfig.data) {
  //     route.routeConfig.data.reuse ? shouldReuse = true : shouldReuse = false;
  //   }
  //   return shouldReuse;
  // }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // console.debug('CustomReuseStrategy:shouldDetach', route);
    this.routeLeftFrom = route.routeConfig?.path;
    return route.data.shouldReuseRoute || false;
  }

  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    console.log('storing handler' + handler);
    if (handler) {
      this.handlers[this.getUrl(route)] = handler;
    }
  }
  // shouldAttach(route: ActivatedRouteSnapshot): boolean {
  //   console.log('checking if it should be re attached');
  //   return !!this.handlers[this.getUrl(route)];
  // }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {

    var wasRoutePreviouslyDetached = !!this.handlers[route.url.join('/')];
    if (wasRoutePreviouslyDetached) {
      var reuseRouteFromVerified = route.data.reuseRoutesFrom.indexOf(this.routeLeftFrom) > -1;

      if (reuseRouteFromVerified) {

        return true;
      }
    }
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    };
    return this.handlers[this.getUrl(route)];
  }
  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    let reUseUrl = false;
    if (future.routeConfig) {
      if (future.routeConfig.data) {
        reUseUrl = future.routeConfig.data.reuse;
      }
    }
    const defaultReuse = (future.routeConfig === current.routeConfig);
    return reUseUrl || defaultReuse;
  }
  getUrl(route: ActivatedRouteSnapshot): any {
    if (route.routeConfig) {
      const url = route.routeConfig.path;
      console.log('returning url', url);
      return url;
    }
  }

}