import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';

@Component({
  selector: 'app-member-card-registor',
  templateUrl: './member-card-registor.component.html',
  styleUrls: ['./member-card-registor.component.scss']
})
export class MemberCardRegistorComponent implements OnInit {

  @Output('closeFormHandler') closeFormHandler = new EventEmitter();
  @Input() currentMember: any;

  isAlreadyMember: boolean = false;


  getting: boolean = false;
  isEdit = false;
  saveLoading: boolean = false;
  generalDatePickerConfig = this.allinoneService.datePickerConfig;
  memberForm: any = {
    startdate: "",
    enddate: "",
    membercard_id: ""
  }

  profileUrl: string = ''
  showIdCardContainer: boolean = false;
  breadcrumbs: any[] = [];
  attachment: any;

  constructor(
    private allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private router: Router
  ) { }

  setBreadcrumbs() {
    if (this.router.url === '/members/payment') {
      this.breadcrumbs[0] = { name: 'Payment', url: 'payment', isActive: true }
      this.breadcrumbs[1] = { name: '', url: '', isActive: false }
    } else {
      this.breadcrumbs[0] = { name: 'Members', url: '', isActive: true }
      this.breadcrumbs[1] = { name: '', url: '', isActive: false }
    }
  }

  ngOnInit(): void {
    this.setBreadcrumbs();
    this.getInfo();
    if (this.currentMember) {
      this.memberForm.startdate = this.allinoneService.parseDateToShowInEdit(this.currentMember.fromdate);
      this.memberForm.enddate = this.allinoneService.parseDateToShowInEdit(this.currentMember.todate);
      this.breadcrumbs[1].name = this.currentMember.name;
    }
  }

  getInfo() {
    this.kunyekService.getRegisteredMember({ searchName: this.currentMember.syskey }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          const data = res.datalist[0];
          this.isEdit = res.datalist.length > 0;
          this.memberForm.membercard_id = data?.membercard_id;
          if (!this.isEdit) {
            this.generaeteID();
          } else {
            this.isAlreadyMember = true;
            if (data.attachment) {
              this.attachment = JSON.parse(data.attachment) || '';
            }
          }
        }
      }
    });


    this.kunyekService.getMemberInfo({ member_id: this.currentMember.syskey }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          const data = res.data;
          this.currentMember = {
            ...this.currentMember,
            data
          }
          if (this.currentMember.data) {
            let pimage = JSON.parse(this.currentMember.data.profileimage);
            this.profileUrl = pimage ? pimage.fileurl : "";
            this.loadImageToSessionStorage(this.profileUrl, 'profileImage');
            if (this.currentMember.fromdate) {
              this.currentMember.startdate = moment(this.currentMember.fromdate, 'YYYYMMDD').format('DD/MM/YYYY') + " - ";
            } else {
              this.currentMember.startdate = '';
            }

            if (this.currentMember.todate) {
              this.currentMember.enddate = moment(this.currentMember.todate, 'YYYYMMDD').format('DD/MM/YYYY');
            } else {
              this.currentMember.enddate = '';
            }
          } else {
            this.clearProfileImage()
          }
          this.showIdCardContainer = true;

        } else {
          console.log('error')
        }
      }
    });
  }

  generaeteID() {
    this.getting = true;
    this.kunyekService.genereateMemberId({
      member_id: this.currentMember.syskey
    }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.memberForm.membercard_id = res.membercard_id;
        }
        this.getting = false;
      }
    })
  }
  handleRegister() {
    const data = {
      member_id: this.currentMember.syskey,
      fromdate: this.allinoneService.parseDate(this.memberForm.startdate),
      todate: this.allinoneService.parseDate(this.memberForm.enddate),
      membercard_id: this.memberForm.membercard_id,
      isEdit: this.isEdit
    };
    this.saveLoading = true;
    this.kunyekService.registerMember(data).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.close();
        }
        this.saveLoading = false;

      }
    })
  }

  loadImageToSessionStorage(url: string, key: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          sessionStorage.setItem(key, reader.result as string);
        };
        reader.readAsDataURL(blob);
      })
      .catch(error => console.error('Error loading image from S3:', error));
  }

  downloadCard() {
    const idCard = document.getElementById('idCard');
    if (idCard) {
      this.loadImages(idCard).then(() => {
        html2canvas(idCard, {
          useCORS: true,
          allowTaint: false,
          logging: true
        }).then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `${this.currentMember.name}-member-card.png`;
          link.click();
        }).catch(err => {
          console.error('Error capturing the card', err);
        });
      }).catch(err => {
        console.error('Error loading images', err);
      });
    }
  }

  downloadPDF() {
    const idCard = document.getElementById('idCard');
    if (idCard) {
      html2canvas(idCard, {
        useCORS: true,
        allowTaint: false,
        logging: true
      }).then((canvas) => {
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${this.currentMember.name}-member-card.pdf`);

      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
    }
  }

  loadImages(container: HTMLElement): Promise<void> {
    const images = Array.from(container.getElementsByTagName('img'));
    const promises = images.map((img) => {
      return new Promise<void>((resolve, reject) => {
        if (img.complete && img.naturalHeight !== 0) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => reject(`Failed to load image: ${img.src}`);
        }
      });
    });
    return Promise.all(promises).then(() => { });
  }

  getProfileImage(): string {
    return sessionStorage.getItem('profileImage') || '';
  }

  clearProfileImage() {
    sessionStorage.removeItem('profileImage');
  }

  viewAttachment() {
    if (this.attachment && this.attachment.fileurl) {
      window.open(this.attachment.fileurl, '_blank');
    } else {
      console.warn('No attachment URL found.');
    }
  }


  close() {
    this.closeFormHandler.emit();
  }
}
