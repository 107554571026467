<div class="container-fluid">
  <div class="content">
    <ng-container *ngIf="!isAdmin">
      <app-breadcrumb [s_breadcrumb]="'Check In Attendances'"
        t_breadcrumb="{{detailForm.username ? detailForm.username : detailForm.userid}} ( {{allinoneService.formatDBToShow(detailForm.startdate)}} - {{allinoneService.formatDBToShow(detailForm.enddate)}} )"
        (emit)="backToCIA()">
      </app-breadcrumb>
    </ng-container>
    <ng-container *ngIf="isAdmin">
      <app-hcm-breadcrumb [s_breadcrumb]="'Check In Attendances'"
        t_breadcrumb="{{detailForm.username ? detailForm.username : detailForm.userid}} ( {{allinoneService.formatDBToShow(detailForm.startdate)}} - {{allinoneService.formatDBToShow(detailForm.enddate)}} )"
        (emit)="backToCIA()">
      </app-hcm-breadcrumb>
    </ng-container>
    <div class="mt-2 d-flex justify-content-between align-items-center flex-wrap">
      <div class="d-flex align-items-center mb-3">
        <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export Members To Excel">
          <img class="excel-icon" src="../../../assets/images/excel.png">
          Export
        </button>
      </div>
      <div class="d-flex align-items-center mb-3">
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{tempType
            == 'timeinout' ? detailForm.days +' day(s)' : tempType == 'io' ? detailForm.iohours :
            detailForm.hours}}</span>
        </div>
        <div class="line">
        </div>
        <div class="input-group" style="width: 300px !important;">
          <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'search-input' : !searchText, 'search-input-with-clear' : searchText }"
            style="font-family: FontAwesome, Ubuntu;">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'clear' : isFocus, 'clear-not-focus': !isFocus}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="detailForm.type == 'inout'">
      <div class="table-responsive" matSort (matSortChange)="sortInOutData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="typename">Time In/Out</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="date">Date Time</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="loc">Location</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="desc">Description</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingDetails">
            <tr>
              <td colspan="4" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingDetails">
            <tr *ngIf="detailList.length == 0">
              <td colspan="4" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of detailList | filter : searchText: type |paginate: { itemsPerPage: 100, currentPage: p }; let index = index">
              <td class="td-data">
                <span [title]="item.typename">{{item.typename}} </span>
              </td>
              <td class="td-data">
                <span
                  title="{{allinoneService.formatDBToShow(item.date)}} {{item.starttime}}">{{allinoneService.formatDBToShow(item.date)}}
                  {{this.allinoneService.formatTimeToShow(item.starttime)}} </span>
              </td>
              <td class="td-data">
                <ng-container *ngIf="item.location != '' || (item.lat != '' && item.long != '')">
                  <a *ngIf="item.lat && item.long"
                    href="https://www.google.com/maps/search/?api=1&query={{item.lat}},{{item.long}}"
                    target="_blank" [ngClass]="{'red' : item.locationtype != '3'}">
                    <span class="d-flex flex-column loc">
                      <div *ngIf="item.location">
                        <span
                          *ngIf="item.locationtype == '1' || item.locationtype == '2' || item.locationtype=='3' || item.locationtype == '5'">@</span>
                        <span *ngIf="item.locationtype == '4'">#</span>
                        {{item.location}}
                      </div>
                      <div class="td-location">
                        {{item.lat}}, {{item.long}}
                      </div>
                    </span>
                  </a>
                  <span *ngIf="item.lat == '' && item.long == ''" [ngClass]="{'red' : item.locationtype != '3'}">
                    <span
                      *ngIf="item.locationtype == '1' || item.locationtype == '2' || item.locationtype=='3' || item.locationtype == '5'">@</span>
                    <span *ngIf="item.locationtype == '4'">#</span>
                    {{item.location}}
                  </span>
                </ng-container>
                <ng-container *ngIf="item.location == '' && item.lat == '' && item.long == ''">
                  <span class="gray">Unknown Location</span>
                </ng-container>
              </td>
              <td class="td-data">
                <span [title]="item.description">{{item.description}} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="detailList.length > 100"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>
    <div *ngIf="detailForm.type == 'tasks'">
      <div class="table-responsive" matSort (matSortChange)="sortInOutData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="date">Date Time</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="autoid">Task ID</td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="desc">Task Name</td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="hours">Hours</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingDetails">
            <tr>
              <td colspan="4" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!gettingDetails">
            <tr *ngIf="detailList.length == 0">
              <td colspan="4" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of detailList | filter : searchText: type |paginate: { itemsPerPage: 100, currentPage: p }; let index = index">

              <td class="td-data">
                <span
                  title="{{allinoneService.formatDBToShow(item.date)}} {{item.starttime}}">{{allinoneService.formatDBToShow(item.date)}}
                  {{this.allinoneService.formatTimeToShow(item.starttime)}} </span>
              </td>
              <td>
                <span [title]="item.autoid">{{item.autoid}} </span>
              </td>
              <td>
                <span [title]="item.taskdescription">{{item.taskdescription}} </span>
              </td>
              <td>
                <span [title]="item.hours">{{item.hours}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="detailList.length > 100"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </div>
  </div>
</div>