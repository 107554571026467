import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-check-in-project-daterange',
  templateUrl: './check-in-project-daterange.component.html',
  styleUrls: ['./check-in-project-daterange.component.css'],
})
export class CheckInProjectDaterangeComponent implements OnInit {
  formatToShow = 'yyyy-MM-dd';

  //for filter date
  aweekBefore = new Date().setDate(new Date().getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);
  defaultenddate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  defaultstartdate = formatDate(
    new Date(this.aweekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  startdate: any;
  enddate: any;

  constructor(
    private allinoneService: AllInOneService,
    private messageService : MessageService,
    private dialogRef: MatDialogRef<CheckInProjectDaterangeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data.startdate)
    console.log(data.enddate)
      this.startdate = data.startdate ? data.startdate : this.defaultstartdate
      this.enddate = data.enddate ? data.enddate : this.defaultenddate
  }

  ngOnInit(): void {
  }

  onDismiss(){
    this.dialogRef.close()
  }

  search() {
    if(this.startdate > this.enddate){
      this.messageService.openSnackBar('Please select a valid end date.', 'warn')
      return;
    }
    this.dialogRef.close({ startdate: this.startdate, enddate: this.enddate });
  }
}
