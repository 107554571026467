<div class="title-container mb-3 d-flex justify-content-between">
    <div class="title">
        Travel Details
    </div>
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr>

<mat-dialog-content>
    <div class="row mb-2">
        <div class="col-md-6">
            <label for="refno" class="form-label">Ref No:</label>
            <input type="text" class="form-control" id="refno" readonly [value]="data.requestrefno">
        </div>
        <div class="col-md-6">
            <label for="emp" class="form-label">Employee</label>
            <input type="text" class="form-control" id="emp" readonly [value]="data.username">
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md">
            <label for="uid" class="form-label">User ID</label>
            <input type="text" class="form-control" id="uid" readonly [value]="data.userid">
        </div>

    </div>
    <div class="row mb-2">
        <div class="col-md-6">
            <label for="type" class="form-label">Type</label>
            <input type="text" class="form-control" id="type" readonly [value]="showTypeName">
        </div>
        <div class="col-md-6">
            <label for="days" class="form-label">Days</label>
            <input type="text" class="form-control" id="days" readonly [value]="data.duration">
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-3">
            <label for="ddate" class="form-label">Departure Date</label>
            <input type="text" class="form-control" id="ddate" readonly
                [value]="allinoneService.formatDBToShow(data.startdate)">
        </div>
        <div class="col-md-3">
            <label for="dtime" class="form-label">Departure Time</label>
            <input type="text" class="form-control" id="dtime" readonly [value]="data.starttime">
        </div>
        <div class="col-md-3">
            <label for="adate" class="form-label">Arrival Date</label>
            <input type="text" class="form-control" id="adate" readonly
                [value]="allinoneService.formatDBToShow(data.enddate)">
        </div>
        <div class="col-md-3">
            <label for="plan" class="form-label">Planned Return</label>
            <input type="text" class="form-control" id="plan" readonly [value]="data.endtime">
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6">
            <label for="product" class="form-label">Product</label>
            <input type="text" class="form-control" id="product" readonly [value]="data.productname">
        </div>
        <div class="col-md-6">
            <label for="project" class="form-label">Project</label>
            <input type="text" class="form-control" id="project" readonly [value]="data.projectname">
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6">
            <label for="ebudget" class="form-label">Estimated Budget</label>
            <input type="text" class="form-control" id="ebudget" readonly [value]="data.amount">
        </div>
        <div class="col-md-6">
            <label for="currency" class="form-label">Currency</label>
            <input type="text" class="form-control" id="currency" readonly [value]="data.currencyname">
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6">
            <label for="mot" class="form-label">Mode of Travel</label>
            <input type="text" class="form-control" id="mot" readonly [value]="showTravelMode">
        </div>
        <div class="col-md-6">
            <label for="vehicle" class="form-label">Vehicle Use</label>
            <input type="text" class="form-control" id="vehicle" readonly [value]="showVehicle">
        </div>
    </div>

    <div class="row mb-2">
        <div class="col">
            <label for="travelpuropose" class="form-label">Travel Purpose</label>
            <textarea class="form-control" readonly [value]="data.remark"></textarea>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md">
            <label for="eid" class="form-label">Approved By</label>
            <input type="text" class="form-control" id="eid" readonly [value]="data.personname">
        </div>
    </div>

    <ng-container>
        <div class="col-md-12 row" *ngIf="data.image.imgurl.length > 0">
            <label for="name" class="form-label"> Attached </label>
            <div class="img-container col-lg-3 col-md-4 col-sm-4 col-6"
                *ngFor=" let item of data.image.imgurl;let index = index">
                <div class="img-border card" (click)="allinoneService.imageViewer(data.image.imgurl, index, false)">
                    <img [src]="item" />
                </div>
            </div>
        </div>
    </ng-container>

    <div class="row">

        <div class="col-md-6" *ngIf="data.accompanyperson.length > 0">
            <h5 class="mt-3"> Selected Accompany Person ({{ data.accompanyperson.length }})</h5>
            <div class="list-group">
                <div class="profile-container d-flex" *ngFor="let user of data.accompanyperson; let index = index">
                    <span>
                        <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                            [lazyLoad]="'../../../assets/images/profile.png'" />
                    </span>

                    <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                        <div class="profile-item-container">
                            <div class="profile-item">
                                <div class="username" style="cursor: pointer">
                                    <b>{{ user.userid }}</b>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>

        <div class="col-md-6" *ngIf="data.approver.length > 0">
            <h5 class="mt-3"> Selected Approvers ({{ data.approver.length }})</h5>
            <div class="list-group">
                <div class="profile-container d-flex" *ngFor="let user of data.approver; let index = index">
                    <span>
                        <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                            [lazyLoad]="'../../../assets/images/profile.png'" />
                    </span>

                    <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                        <div class="profile-item-container">
                            <div class="profile-item">
                                <div class="username" style="cursor: pointer">
                                    <b>{{ user }}</b>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>

</mat-dialog-content>