<div class="cc-container">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <div class="label title">Delete {{node.username ? node.username : node.userid}} ({{node.employeeid}})
    </div>
    <div class="close-btn">
      <button mat-icon-button [disabled]="saveLoading" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <div class="form-check mb-3">
      <input class="form-check-input" type="radio" name="deletetype" id="delete" value="0"
        [(ngModel)]="hcForm.deletetype">
      <label class="form-check-label" for="delete">
        Delete Children
      </label>
      <div class="mt-2" style="color: grey;">
        This will delete all the related children under this RO.
      </div>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="deletetype" id="move" value="1"
        [(ngModel)]="hcForm.deletetype">
      <label class="form-check-label" for="move">
        Move Children and Delete
      </label>
      <div *ngIf="hcForm.deletetype == '1'" class="mt-3">
        <div class="mb-3">
          <label for="peid" class="form-label">Member
            <span *ngIf="isParentValid">({{selectedPEmployeeid}} - {{selectedPUserName ? selectedPUserName :
              selectedPUserid}})</span>
          </label>
          <input type="text" placeholder="0001, 0002" aria-label="Number" matInput class="form-control"
            (ngModelChange)="peidChange()" [matAutocomplete]="auto1" [(ngModel)]="searchText1" name="peid" id="peid"
            #peid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && peid.invalid }">
          <mat-autocomplete #auto1="matAutocomplete">
            <mat-option *ngFor="let member of filterparentList | filter : searchText1:12 " [value]="member.employeeid"
              (onSelectionChange)="parentChange(member)" (click)="parentChange(member)">
              {{member.employeeid}} - {{member.username}} ({{member.userid}})
            </mat-option>
          </mat-autocomplete>

          <div class="invalid-feedback" *ngIf="peid.invalid || (peid.dirty || peid.touched) && hcFormSubmitted">
            <div *ngIf="peid.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>

        </div>
        <div class="mb-3">
          <label for="pid" class="col-form-label">Position (Post , Department , Division)</label>

          <div>
            <select class="form-select" aria-label="Select Position" id="pid" name="pid" [(ngModel)]="hcForm.pid"
              required #pid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && pid.invalid }">
              <option value="">-</option>
              <option *ngFor="let position of parentPositions; let index = index" [value]="position.positionid"
                [selected]="hcForm.pid == position.positionid">
                <span *ngIf="position.name && position.department && position.division">
                  {{position.name}}, {{position.department}}, {{position.division}}
                </span>
                <span *ngIf="position.name && position.department && !position.division">
                  {{position.name}}, {{position.department}}
                </span>
                <span *ngIf="position.name && !position.department && position.division">
                  {{position.name}}, - , {{position.division}}
                </span>
                <span *ngIf="position.name && !position.department && !position.division">
                  {{position.name}}
                </span>
              </option>
            </select>
          </div>
          <div class="invalid-feedback" *ngIf="pid.invalid || (pid.dirty || pid.touched) && hcFormSubmitted">
            <div *ngIf="pid.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div mat-dialog-actions align="end">
    <button type="button" (click)="close()" class="btn btn-outline-secondary mx-2">
      Cancel
    </button>
    <app-button-loader (click)="onConfirm()" [button_text]="'Ok'" [load_button_text]="'Deleting'"
        [isLoading]="deletingHierarchy"></app-button-loader>

  </div>
</div>