<div class="d-flex justify-content-center main-div">

  <div class="card filter-div">
    <div class="title-holder">

      <mat-icon class="filter-icon">filter_list</mat-icon>

      <div class="filter-title">
        Filter
      </div>
    </div>
    <div class="form-check radio-btn">
      <label class="radio-inline">
        <input class="form-check-input" type="radio" name="flexRadioDefaultMain" id="flexRadioDefaultMain1"
          [(ngModel)]="type" value="1" (click)="formatFilter('1')" checked>All
      </label>
    </div>
    <div *ngIf="isDomainAdmin">
      <div class="form-check radio-btn">
        <label class="radio-inline">
          <input class="form-check-input" type="radio" name="flexRadioDefaultMain" id="flexRadioDefaultMain1"
            [(ngModel)]="type" value="2" (click)="formatFilter('2')">View as Admin
        </label>
      </div>
    </div>
    <div *ngIf="orgList.length > 0">
      <div class="org-title">
        Organizations
      </div>
      <div *ngFor="let org of orgList" class="form-check radio-btn">
        <label class="radio-inline">
          <input class="form-check-input" type="radio" name="flexRadioDefaultMain" id="{{org.orgid}}" [(ngModel)]="type"
            [value]="org.orgid" (click)="formatFilter(org.orgid)">{{org.name}}
          <img class="bluemark" *ngIf="org.admin == 'true'" src="../../../assets/images/bluemark.png">
        </label>
      </div>
    </div>
  </div>

  <div *ngIf="!openedPost" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="onScrollDown()" [scrollWindow]="true">

    <div class="main">
      <div *ngIf="gettingPosts">
        <div *ngFor="let i of [1,2,3,4,5]">
          <div class="card card-body mb-3">
            <div class="profile-container">
              <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                        'height.px': 50,
                        'width.px' : 50
                    }"></ngx-skeleton-loader>
              <div class="profile-item" style="display: flex; flex-direction: column; justify-content: center;">
                <div>
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 25,
                                'width.px' : 300,
                                'margin-bottom' : 0
                            }"></ngx-skeleton-loader>
                </div>
                <div>
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 20,
                                'width.px' : 200,
                                'margin-bottom' : 0
                            }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="body-text">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                        'height.px': 180,
                        width : '100%',
                        'margin-bottom' : 0
                    }"></ngx-skeleton-loader>
            </div>
            <!-- <div class = "body-image">
        
                </div> -->
          </div>
        </div>
      </div>
      <div class="loading-post" *ngIf="gettingNewPosts">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div *ngIf="!gettingPosts">
        <div class="card post-card-body mb-3">
          <div class="my-2 comment-section">
            <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
              [lazyLoad]="profileImg ? profileImg : '../../../assets/images/profile.png'">
            <div class="input-box create-post" (click)="createPost()">
              <!-- <input disabled class="textarea" placeholder="Create post here ..." /> -->
              Create post here . . .
            </div>
            <button mat-icon-button style="margin-left: 10px;" (click)="openFilterMenu() " class="filter-btn"
              *ngIf="isDomainAdmin || orgList.length > 0 ">
              <mat-icon class="filter-icon">filter_list</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="posts.length == 0">
          <div class="card card-body mb-3">
            <div class="profile-container">
              <span>
                <img class="profile-image" src="../../../assets/images/kunyek.png">
              </span>
              <div class="profile-item-container">
                <div class="profile-item">
                  <div class="username">
                    <b>{{allinoneService.appname}}</b>
                  </div>
                  <div class="time">
                    {{currentTime}} test
                  </div>
                </div>
                <div class="post-menu">
                </div>
              </div>
            </div>
            <div class="defaulttpost">
              Welcome to {{allinoneService.appname}}!
            </div>
          </div>
        </div>
        <div *ngFor="let post of posts; let index = index; trackBy: trackByItems">
          <div class="loading-post" *ngIf="post.deletingPost">
            <div class="spinner-border mb-3" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p style="opacity: 0.8; color: gray;">
              Deleting
              <span *ngIf="post.type == '1'">{{post.domaindesc}}</span>
              <span *ngIf="post.type == '2'">{{post.admin ? post.admin: post.adminid}}</span>
              <span *ngIf="post.type == '3'">{{post.orgname}}</span>'s post.
            </p>
          </div>
          <div class="card card-body mb-3" *ngIf="!post.deletingPost">
            <div class="profile-container">
              <span *ngIf="post.type == '1'">
                <img class="profile-image" [defaultImage]="'../../../assets/images/post_admin.png'"
                  [lazyLoad]="post.domainurl ? post.domainurl : '../../../assets/images/post_admin.png'">
              </span>
              <span *ngIf="post.type == '2' || post.type == '4'">
                <img (click)="viewProfile(post.adminid)" class="profile-image"
                  [defaultImage]="'../../../assets/images/profile.png'"
                  [lazyLoad]="post.adminimage ? post.adminimage : '../../../assets/images/profile.png'">
              </span>
              <span *ngIf="post.type == '3'">
                <img class="profile-image" [defaultImage]="'../../../assets/images/post_admin.png'"
                  [lazyLoad]="post.orgimageurl ? post.orgimageurl :'../../../assets/images/post_admin.png'">
              </span>

              <div class="profile-item-container">
                <div class="profile-item">
                  <div class="username" *ngIf="post.type == '1'">
                    <b>{{post.domaindesc}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">
                  </div>
                  <div class="username" *ngIf="post.type == '2' || post.type == '4'">
                    <p class="username-text">
                      <span *ngIf="post.orgtag.length > 0">
                        <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ?
                          post.admin : post.adminid}}</b>

                        &nbsp;is&nbsp;with&nbsp;
                        <b>{{post.orgtag[0].orgname}}</b>
                      </span>
                      <span
                        *ngIf="post.orgtag.length == 0 && (post.tag.length == 0 || post.tag == undefined || post.tag == null)">
                        <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ?
                          post.admin : post.adminid}}</b>

                      </span>
                      <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 1)">
                        <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ?
                          post.admin : post.adminid}}</b>

                        &nbsp;is&nbsp;with&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                          post.tag[0].username : post.tag[0].userid}}</b>


                      </span>
                      <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 2)">
                        <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ?
                          post.admin : post.adminid}}</b>

                        &nbsp;is&nbsp;with&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                          post.tag[0].username : post.tag[0].userid}}</b>

                        &nbsp;and&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[1].userid)">{{post.tag[1].username ?
                          post.tag[1].username : post.tag[1].userid}}</b>

                      </span>
                      <span *ngIf="post.tag.length > 2">
                        <b class="user-id" (click)="viewProfile(post.adminid)">{{post.admin ?
                          post.admin : post.adminid}}</b>

                        &nbsp;is&nbsp;with&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                          post.tag[0].username : post.tag[0].userid}}</b>

                        &nbsp;and&nbsp;
                        <b class="viewOtherTag" (click)="LikeAndTagForm(post.syskey, 'tags', post.tag)">
                          {{post.tag.length - 1}} others
                        </b>
                      </span>
                      <span *ngIf="post.orgname && post.type == '4'">
                        &nbsp;<i class="fas fa-at"></i>&nbsp;
                        <b>{{post.orgname}}</b>
                      </span>
                      <span *ngIf="post.channelid && post.type == '2'">
                        &nbsp;<i class="fas fa-at"></i>&nbsp;
                        <b>{{post.channelname}}</b>
                      </span>
                    </p>
                  </div>
                  <div class="username" *ngIf="post.type == '3'">
                    <p class="username-text">
                      <span *ngIf="post.orgtag.length > 0">
                        <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                        &nbsp;is&nbsp;with&nbsp;
                        <b>{{post.orgtag[0].orgname}}</b>
                      </span>
                      <span
                        *ngIf="post.orgtag.length == 0 && (post.tag.length == 0 || post.tag == undefined || post.tag == null)">
                        <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                      </span>
                      <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 1)">
                        <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                        &nbsp;is&nbsp;with&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                          post.tag[0].username : post.tag[0].userid}}</b>


                      </span>
                      <span *ngIf="post.orgtag.length == 0 && (post.tag.length == 2)">
                        <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                        &nbsp;is&nbsp;with&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                          post.tag[0].username : post.tag[0].userid}}</b>

                        &nbsp;and&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[1].userid)">{{post.tag[1].username ?
                          post.tag[1].username : post.tag[1].userid}}</b>

                      </span>
                      <span *ngIf="post.tag.length > 2">
                        <b>{{post.orgname}}</b> <img class="bluemark" src="../../../assets/images/bluemark.png">

                        &nbsp;is&nbsp;with&nbsp;
                        <b class="user-id" (click)="viewProfile(post.tag[0].userid)">{{post.tag[0].username ?
                          post.tag[0].username : post.tag[0].userid}}</b>

                        &nbsp;and&nbsp;
                        <b class="viewOtherTag" (click)="LikeAndTagForm(post.syskey, 'tags', post.tag)">
                          {{post.tag.length - 1}} others
                        </b>
                      </span>

                    </p>
                  </div>
                  <div class="time d-flex">
                    <!-- {{allinoneService.formatTimeToShow(post.time)}} -->
                    <!-- <div>
                      {{post.date | formatDateToShow}},
                      {{post.time | formatTimeToShow}}
                    </div> -->
                    <div>
                      {{(allinoneService.formatDBToShowInEdit(post.date,
                        '/')
                        +' '+ post.time) | datetimeago}}
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="fa fa-circle" style="margin-left: 5px; font-size: 6px;"></i>
                    </div>
                    <div style="margin-left: 5px;">
                      <i class="fas fa-globe-americas"
                        *ngIf="post.postsetting == '001' && (post.type != '3' && post.type !='4')"></i>
                      <i class="fas fa-globe-americas"
                        *ngIf="post.postsetting == '001' && (post.type == '3' || post.type == '4')"></i>
                      <i class="fas fa-user-friends"
                        *ngIf="(post.postsetting == '002' || post.postsetting == '') && (post.type != '3' && post.type !='4')"></i>
                      <i class="fal fa-user-friends" style="color: #333;"
                        *ngIf="(post.postsetting == '002' || post.postsetting == '') && (post.type == '3' || post.type =='4')"></i>
                      <i class="fa fa-tags" *ngIf="post.postsetting == '003'"></i>
                      <i class="fas fa-lock" *ngIf="post.postsetting == '004'"></i>
                      <i class="fas fa-comment-alt" *ngIf="post.postsetting == '005'"></i>
                    </div>
                  </div>
                </div>
                <div class="post-menu">
                  <button mat-icon-button [matMenuTriggerFor]="menu"
                    *ngIf="(post.type == 1 && post.postDomainAdmin) || ((post.type == 3 || post.type == '4') && (post.postOrgAdmin || post.postAdmin)) || post.type == '2' || post.type == '4'">
                    <mat-icon>more_horiz</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" class="menu" xPosition="before">
                    <button mat-menu-item class="menu-container" (click)="duplicatePost(post)">
                      <div class="menu-icon-holder">
                        <div class="menu-icon">
                          <mat-icon class="ic">content_copy</mat-icon>
                        </div>
                      </div>
                      <div class="menu-text">
                        Duplicate Post
                      </div>
                    </button>
                    <!-- <span
                      *ngIf="((post.type == 2 || post.type == 4) && post.adminid != userid) || ((post.type == 3 || post.type == 4) && !(post.postOrgAdmin || post.postAdmin)) || (post.type == 1 && !post.postDomainAdmin)"> -->
                      <span *ngIf="(post.type == 2 && post.adminid != userid)">
                      <button mat-menu-item class="menu-container" (click)="reportForm(post.syskey)">
                        <div class="menu-icon-holder">
                          <div class="menu-icon">
                            <mat-icon class="ic">report</mat-icon>
                          </div>
                        </div>
                        <div class="menu-text">
                          Report
                          <span>I'm concerned about this post.</span>
                        </div>
                      </button>
                      <button mat-menu-item class="menu-container" (click)="blockUser(post)">
                        <div class="menu-icon-holder">
                          <div class="menu-icon">
                            <mat-icon class="ic">block</mat-icon>
                          </div>
                        </div>
                        <div class="menu-text">
                          Block
                          <span>Stop seeing posts and remove from friends list.</span>
                        </div>
                      </button>
                    </span>
                    <span
                      *ngIf="((post.type == 2 || post.type == 4 ) && post.adminid == userid) || ((post.type == 3 || post.type == '4') && (post.postOrgAdmin || post.postAdmin)) || (post.type == 1 && post.postDomainAdmin)">
                      <button mat-menu-item class="menu-container" (click)="editPost(post)">
                        <div class="menu-icon-holder">
                          <div class="menu-icon">
                            <mat-icon class="ic">edit</mat-icon>
                          </div>
                        </div>
                        <div class="menu-text">
                          Edit Post
                        </div>
                      </button>
                    </span>
                    <span
                      *ngIf="((post.type == 2 || post.type == 4 ) && post.adminid == userid) || ((post.type == 2 || post.type == 4 || post.type == 3) && (post.postOrgAdmin || post.postAdmin)) || (post.type == 1 && post.postDomainAdmin) || domainAdmin">
                      <button mat-menu-item class="menu-container" (click)="deletePost(post.syskey, index)">
                        <div class="menu-icon-holder">
                          <div class="menu-icon">
                            <mat-icon class="ic">delete</mat-icon>
                          </div>
                        </div>
                        <div class="menu-text">
                          Delete Post
                        </div>
                      </button>
                    </span>
                  </mat-menu>
                </div>
              </div>
            </div>
            <div *ngIf="!post.showMore" style="line-height: 1.7;">
              <span [innerHTML]="allinoneService.replaceEnter(trimString(post.note,150))"
                (click)="openDetail(post)"></span>
              <span *ngIf="post.note.length> 150" (click)="post.showMore = !post.showMore"
                class="showMore">{{post.showMore ? '' : 'See More'}}</span>
            </div>
            <span *ngIf="post.showMore" style="line-height: 1.7;" (click)="openDetail(post)"
              [innerHTML]="allinoneService.replaceEnter(post.note)"></span>

            <div
              *ngIf="post.meetingtype == '1'||post.meetingtype == '2'||post.meetingtype == '3'||post.meetingtype == '5' ||post.meetingtype == '6' ||( post.meetinglink!='' && post.meetingtype == '')"
              class="meeting-container">
              <div (click)="openDetail(post)">
                <span class="event-title"
                  *ngIf="post.meetingtype == '1' || ( post.meetinglink!='' && post.meetingtype == '')"
                  style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
                  Meeting
                </span>
                <span class="event-title"
                  *ngIf="post.meetingtype == '6' || ( post.meetinglink!='' && post.meetingtype == '')"
                  style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
                  Class
                </span>
                <span class="event-title" *ngIf="post.meetingtype == '2'"
                  style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
                  Seminar
                </span>
                <span class="event-title" *ngIf="post.meetingtype == '3'"
                  style="font-weight: bold; margin-top: 5px; margin-bottom: 5px;">
                  Conference
                </span>

                <span class="event-title" *ngIf="post.meetingtype == '5'"
                  style="font-weight: bold; margin-top: 5px; margin-bottom: 5px; ">
                  Other
                </span>

              </div>
              <div style="margin-top: 10px ;" (click)="openDetail(post)">
                <span>{{post.venue}}</span>

              </div>

              <div style="margin-top: 10px ;" (click)="openDetail(post)" *ngIf="post.meetingtype != '3'">
                <span>{{post.startdate | formatDateToShow}} </span>
                <span>{{post.starttime | formatTimeToShow}} to
                  {{post.endtime | formatTimeToShow}}</span>
                <span *ngIf="post.meetingappear">
                  <span *ngIf="post.meetingappear == 'Mon'">&nbsp;on Monday</span>
                  <span *ngIf="post.meetingappear == 'Tue'">&nbsp;on Tuesday</span>
                  <span *ngIf="post.meetingappear == 'Wed'">&nbsp;on Wednesday</span>
                  <span *ngIf="post.meetingappear == 'Thu'">&nbsp;on Thursday</span>
                  <span *ngIf="post.meetingappear == 'Fri'">&nbsp;on Friday</span>
                  <span *ngIf="post.meetingappear == 'Sat'">&nbsp;on Saturday</span>
                  <span *ngIf="post.meetingappear == 'Sun'">&nbsp;on Sunday</span>
                  (Weekly)
                </span>
              </div>
              <div style="margin-top: 10px ;" (click)="openDetail(post)" *ngIf="post.meetingtype == '3'">
                <span>{{post.startdate | formatDateToShow}} -
                  {{post.enddate | formatDateToShow}} </span> <br>
                <span>{{post.starttime | formatTimeToShow}} to
                  {{post.endtime | formatTimeToShow}}</span>
                <!-- <span>{{allinoneService.formatTimeToShow(post.starttime)}} to
                    {{allinoneService.formatTimeToShow(post.endtime)}}</span> -->
              </div>
              <div style="margin-top: 10px ;" (click)="openDetail(post)">
                <span style="white-space: pre-line;">{{post.meetingdesc}}</span>

              </div>
              <div *ngIf="post.meetinglink != ''">
                <div *ngIf="post.ics == 'false'">
                  <div class="preview-container" (click)="clickMetting(post.meetinglink)">
                    <div style="padding: 10px 10px 5px 10px;">
                      <span class="join-url">{{post.meetinglink}}</span>
                    </div>
                    <!-- Link Preview... -->
                    <div *ngIf="post.preview" class="preview-footer">
                      <div *ngIf="post.previewImage != ''">
                        <img class="preivew-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                          [lazyLoad]="post.previewImage">
                      </div>
                      <div *ngIf="post.previewImage != ''" style="color: black;font-weight: bold;">
                        {{post.previewTitle}}</div>
                      <div *ngIf="post.previewImage == ''" style="color: black;padding-left: 5px;font-weight: bold;">
                        {{post.previewTitle}}
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="post.ics == 'true'">
                  <div style="padding: 10px 0px 5px 0px;">
                    <span class="join-url" (click)="clickMetting(post.meetinglink)">{{post.meetinglink}}</span>
                  </div>
                  <div class="show-ics" (click)="allinoneService.addtoCalendar(post)">
                    <div style="padding: 10px 10px 10px 10px;display: flex;align-items: center;">
                      <mat-icon>date_range</mat-icon>
                      <span style="margin-left: 10px;">Add to Calendar</span>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div *ngIf="post.meetingtype == '4'" class="meeting-container">
              <div class="d-flex justify-content-between mb-3" (click)="openDetail(post)">
                <div class="d-flex">
                  <span class="voting-title me-2">
                    Voting

                  </span>

                </div>
                <div class="max-vote" *ngIf="post.maxoption>1">
                  Vote Limit: {{post.maxoption}}
                </div>

              </div>
              <div class="voting-note d-flex" (click)="openDetail(post)" style="white-space: pre-line;">
                {{post.meetingdesc}}
              </div>
              <div *ngFor="let poll of post.answer;let i=index;trackBy: trackByItems">

                <div class="poll-container d-flex justify-content-between"
                  [style.background]="getVotePercentage(post.showresult,post.answer,post.totalvote,poll.code)"
                  *ngIf="i<=2">
                  <label for="{{post.syskey}}{{poll.code}}" class="col-10" style="cursor: pointer;">
                    <div class="d-flex justify-content-start flex-wrap">
                      <div class="poll-selected me-2">
                        <!-- <mat-icon>check</mat-icon>-->
                        <input class="form-check-input" type="checkbox" id="{{post.syskey}}{{poll.code}}"
                          [checked]="checkVoted(post.answer,poll.code)"
                          (click)="VoteOnChange($event,poll.name,poll.code,post.syskey)"
                          *ngIf="checkVotingEndtime(post)!='Final result'"
                          [disabled]="(!checkVoted(post.answer,poll.code)&&checkVoteLimit(post.answer)>=post.maxoption )|| !checkVotingStartTime(post.startdate,post.starttime)">
                        <div *ngIf="checkVotingEndtime(post)=='Final result'">
                          <div class="p-2" *ngIf="!checkVoted(post.answer,poll.code)">
                          </div>
                          <mat-icon *ngIf="checkVoted(post.answer,poll.code)" class="check-icon" style="width: 15px;">
                            check</mat-icon>
                        </div>
                      </div>

                      <div class="poll-name">
                        {{poll.name}}
                      </div>
                    </div>

                  </label>


                  <div
                    *ngIf="poll.count >0 && post.showcount=='true' || (post.showresult=='true' && checkVoteResult(post.answer,poll.code)) || post.shownamelist=='true'"
                    class="vote-count">
                    <div *ngIf="poll.count==1">
                      <span *ngIf="post.shownamelist=='false'">
                        {{poll.count}} vote
                      </span>

                      <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                        (click)="VoteListForm(post.syskey,poll.code)">
                        {{poll.count}} vote
                      </span>
                    </div>
                    <div *ngIf="poll.count>1">
                      <span *ngIf="post.shownamelist=='false'">
                        {{poll.count}} votes
                      </span>

                      <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                        (click)="VoteListForm(post.syskey,poll.code)">
                        {{poll.count}} votes
                      </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="i==3" class="poll-container d-flex justify-content-center" (click)="openDetail(post)">
                  <div>
                    {{post.answer.length-i}} More....
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(post.filename.length > 0 && post.fileurl.length > 0) && post.filename.length > 1 "
              class="row pt-1">
              <div *ngFor="let file of post.filename; let i = index;trackBy: trackByItems" class="col-md-6 ">
                <div class="pdf-card">
                  <div class="pdf-name">
                    <i class="far fa-file-pdf"></i>
                    <span style="margin-left: 5px;">{{file}}</span>
                  </div>
                  <div style="margin-top: 3px;">
                    <mat-icon class="pdf-download-icon" (click)="clickDownloadPDF(post, file, i)">
                      file_download</mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(post.filename.length > 0 && post.fileurl.length > 0) && post.filename.length == 1"
              class="row pt-1">
              <div *ngFor="let file of post.filename; let i = index" class="col-md-12 ">
                <div class="pdf-card">
                  <div class="pdf-name">
                    <i class="far fa-file-pdf"></i>
                    <span style="margin-left: 5px;">{{file}}</span>
                  </div>
                  <div style="margin-top: 3px;">
                    <mat-icon class="pdf-download-icon" (click)="clickDownloadPDF(post, file, i)">
                      file_download</mat-icon>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="post.videopost && !post.hidevideo" class="mt-2">
              <!-- allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" -->
              <!-- <iframe [id]="post.syskey" class="yt_player_iframe" width="100%" height="315" [src]="post.videourl+'?enablejsapi=1' | safe" scrolling="no" frameborder="0" rel="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen (click)="handleVideoPlayEvent($event)"></iframe> -->
                <!-- (vmPlay)="handleVideoPlayEvent($event)" -->
              <vm-player #player>
                <vm-youtube cookies="true" [attr.video-id]="post.videourl | getVideoId"></vm-youtube>
                <vm-default-ui> 
                </vm-default-ui>
              </vm-player>
              <!-- <vm-embed embed-src="https://www.youtube-nocookie.com/embed/DyTCOwB0DVw"
                media-title="Agent 327: Operation Barbershop" origin="https://www.youtube-nocookie.com"
                [params]="params" (vmEmbedMessage)="handleVideoPlayEvent($event)"></vm-embed> -->
            </div>

            <div class="body-image mt-2" (click)="openDetail(post)">
              <app-social-posts-images [images]="post.image"></app-social-posts-images>
            </div>

            <div class="like-comment mt-2 mb-2">

              <div>
                <div *ngIf="post.meetingtype=='' && post.totallike > 0">
                  <span class="link-btn" (click)="LikeAndTagForm(post.syskey, 'reacts', post.tag)">
                    <div class="d-flex align-items-center">
                      <div>
                        <div *ngIf="post.totalreact[0].id == '4'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[0].id == '002'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[0].id == '003'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[0].id == '004'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[0].id == '005'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                        </div>
                      </div>
                      <div *ngIf="post.totalreact.length > 1">
                        <div *ngIf="post.totalreact[1].id == '4'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[1].id == '002'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[1].id == '003'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[1].id == '004'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[1].id == '005'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                        </div>
                      </div>
                      <div *ngIf="post.totalreact.length > 2">
                        <div *ngIf="post.totalreact[2].id == '4'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[2].id == '002'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[2].id == '003'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[2].id == '004'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                        </div>
                        <div *ngIf="post.totalreact[2].id == '005'">
                          <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                        </div>
                      </div>

                      <div class="ms-1">
                        {{post.totallike}}
                      </div>
                    </div>
                  </span>
                </div>
                <div *ngIf="post.meetingtype!='' && post.meetingtype=='4' ">
                  <span class="">
                    <span *ngIf="post.totalvote >1">
                      {{post.totalvote}} votes
                    </span>
                    <span *ngIf="post.totalvote == 1">
                      {{post.totalvote}} vote
                    </span>
                    <span [ngClass]="{'showStarttime' : !checkVotingStartTime(post.startdate,post.starttime)}">
                      <span *ngIf="checkVotingEndtime(post)!=''">
                        •
                      </span> {{checkVotingEndtime(post)}}
                    </span>
                    <span
                      *ngIf="post.meetingtype!='' && post.meetingtype=='4' && checkVotingColseOrOpenTime(post) =='Open' "
                      class="">
                      (<span style="color: #63c20a;">Open</span>)

                    </span>
                    <span
                      *ngIf="post.meetingtype!='' && post.meetingtype=='4' && checkVotingColseOrOpenTime(post)=='Closed' "
                      class="">
                      (<span style="color: red;">Closed</span>)

                    </span>

                  </span>
                </div>
                <div *ngIf="post.meetingtype!='' && post.meetingtype!='4'" class="d-flex">
                  <div class="pe-1">
                    <span *ngFor="let result of post.answer;let i=index;">
                      <!-- {{result.count}} {{result.name}} -->
                      <span
                        *ngIf="result.count>0 && (post.showresult=='true' || post.showcount =='true') && post.shownamelist=='false' ">
                        {{result.count}} {{result.name}}
                      </span>
                      <span *ngIf="result.count>0 && (post.shownamelist=='true')" class="link-btn"
                        (click)="ResponseListForm(result,post.syskey)">
                        {{result.count}} {{result.name}}
                      </span>
                      <!-- <span
                        *ngIf=" result.count>0 && post.showresult=='true' && checkVoteResult(post.answer,result.code)">
                        <span class="link-btn" (click)="ResponseListForm(result)">
                          {{result.count}} {{result.name}}
                        </span>
                      </span> -->
                      <span *ngIf="post.answer.length>1 && i!=post.answer.length-1">
                      </span>
                    </span>
                  </div>
                  <div class="pe-1"
                    *ngIf=" post.showcount == 'false'  && post.shownamelist == 'false'  && post.showresult == 'false'">
                    {{post.totalvote}} Responses
                  </div>
                </div>
              </div>
              <div>
                <span class="link-btn" (click)="openDetail(post)">
                  <span *ngIf="post.comment > 1">
                    {{post.comment}} Comments
                  </span>
                  <span *ngIf="post.comment == 1">
                    {{post.comment}} Comment
                  </span>
                </span>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="like-comment-buttons">
              <button mat-button class="like-button" *ngIf="post.meetingtype==''">
                <div class="d-flex justify-content-center" *ngIf="post.like == 'false'" (click)="like(post,'4')">
                  <div><i class="far fa-thumbs-up me-2"></i></div>
                  <div class="ms-2">Like</div>
                </div>
                <div *ngIf="post.like == 'true'">
                  <div class="d-flex justify-content-center" *ngIf="post.react == '4'" (click)="like(post, '')">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: var(--main-color);">Like</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="post.react == '002'" (click)="like(post, '')">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: #ef3453;">Love</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="post.react == '003'" (click)="like(post, '')">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: orange;">Haha</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="post.react == '004'" (click)="like(post, '')">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: orange;">Sad</div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="post.react == '005'" (click)="like(post, '')">
                    <div>
                      <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="15">
                    </div>
                    <div class="ms-2" style="color: #eb640b;">Angry</div>
                  </div>
                </div>
                <div class="react-list">
                  <div class="react" (click)="like(post, '4')">
                    <img style="margin-bottom:2px;" src="../../../assets/images/like.svg" width="25">
                  </div>
                  <div class="react" (click)="like(post, '002')">
                    <img style="margin-bottom:2px;" src="../../../assets/images/love.svg" width="25">
                  </div>
                  <div class="react" (click)="like(post, '003')">
                    <img style="margin-bottom:2px;" src="../../../assets/images/haha.svg" width="25">
                  </div>
                  <div class="react" (click)="like(post, '004')">
                    <img style="margin-bottom:2px;" src="../../../assets/images/sad.svg" width="25">
                  </div>
                  <div class="react" (click)="like(post, '005')">
                    <img style="margin-bottom:2px;" src="../../../assets/images/angry.svg" width="25">
                  </div>
                </div>
              </button>
              <button mat-button class="vote-button" disabled *ngIf="post.meetingtype=='4'"
                [ngClass]="{'liked' : post.like == 'true'}">
                <i class="fas fa-poll mb-1"></i> Vote
              </button>
              <button mat-button class="answer-button" id="dropdownMenuClickableInside"
                *ngIf="post.meetingtype!=''&& post.meetingtype!='4'" [ngClass]="{'liked' : post.vote == 'true'}"
                data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false"
                [disabled]="checkVotingEndtime(post)=='Final result'">
                <i class="fas fa-comments"></i> Response
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuClickableInside">
                <li class="dropdown-item" *ngFor="let poll of post.answer;trackBy: trackByItems">
                  <div class="d-flex ">
                    <!-- <label for="answer{{post.syskey}}{{poll.code}}"> -->
                    <div class="me-2 ">
                      <input class="form-check-input" type="checkbox" id="answer{{post.syskey}}{{poll.code}}"
                        [checked]="checkVoted(post.answer,poll.code)"
                        (click)="VoteOnChange($event,poll.name,poll.code,post.syskey)"
                        *ngIf="checkVotingEndtime(post)!='Final result'"
                        [disabled]="(!checkVoted(post.answer,poll.code) && checkVoteLimit(post.answer)>=post.maxoption) ">
                      <div *ngIf="checkVotingEndtime(post)=='Final result'">
                        <div class="p-2" *ngIf="!checkVoted(post.answer,poll.code)">
                        </div>
                        <mat-icon *ngIf="checkVoted(post.answer,poll.code)" class="check-icon"
                          style="width: 15px;padding-bottom: 10px;">check</mat-icon>
                      </div>
                    </div>
                    <div class="menu-text d-flex justify-content-between flex-row" style="min-width: 150px;">
                      <div class=" mb-1">
                        <label for="answer{{post.syskey}}{{poll.code}}" style="min-width: 120px;cursor: pointer;">

                          {{poll.name}}
                        </label>
                      </div>


                      <div
                        *ngIf="poll.count>0 && post.showcount=='true' || (post.showresult=='true' && checkVoteResult(post.answer,poll.code)) || post.shownamelist=='true'">
                        <div *ngIf="poll.count==1">
                          <span *ngIf="post.shownamelist=='false'">
                            1 vote
                          </span>

                          <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                            (click)="VoteListForm(post.syskey,poll.code)">
                            1 vote
                          </span>
                        </div>
                        <div *ngIf="poll.count>1">

                          <span *ngIf="post.shownamelist=='false'">
                            {{poll.count}} votes
                          </span>

                          <span *ngIf="post.shownamelist=='true'" class="showname-list link-btn"
                            (click)="VoteListForm(post.syskey,poll.code)">
                            {{poll.count}} votes
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- </label> -->


                  </div>
                </li>
              </ul>

              <button mat-button (click)="comment(index)" *ngIf="post.commentsetting=='true'">
                <i class="far fa-comment-alt me-2"></i>Comment
              </button>

              <button mat-button (click)="share(post,index)" *ngIf="post.type == '1'">
                <i class="fal fa-share me-2"></i>Share
              </button>
            </div>
            <div *ngIf="post.commentboxopen">
              <mat-divider></mat-divider>
              <div class="my-2 comment-section">
                <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                  [lazyLoad]="profileImg ? profileImg : '../../../assets/images/profile.png'">
                <div class="input-box">
                  <!-- <textarea class="textarea" autosize [minRows]="1" [(ngModel)]="post.commentText"
                    placeholder="Write a comment ..." (keydown.enter)="submitComment($event, index)">
                                </textarea> -->
                  <quill-editor class="textarea" [modules]="modules" style="width: 100%;" [formats]="formats"
                    format="object" [(ngModel)]="post.commentText" [trimOnValidation]="true"
                    (onSelectionChanged)="focusEditor(post.orgid)">
                  </quill-editor>
                  <div [ngClass]="{'image-box': post.selectedImage }" *ngIf="post.selectedImage">
                    <div class="close" (click)="closeImage(index)">
                      <mat-icon>close</mat-icon>
                    </div>
                    <img [src]="post.selectedImage" alt="">
                  </div>
                  <div class="btns" *ngIf="!post.saveCommentLoading">
                    <input style="display: none" type="file" accept="image/*" (change)="onFileSelect($event, index)"
                      #hiddenfileinput>
                    <button mat-icon-button class="image" (click)="hiddenfileinput.click()">
                      <mat-icon>photo_library</mat-icon>
                    </button>
                    <!-- [disabled]="post.commentText.trim() == '' && !post.selectedImage" -->
                    <button mat-icon-button class="send" (click)="submitComment($event, index)">
                      <mat-icon>send</mat-icon>
                    </button>
                  </div>
                  <div class="btns" *ngIf="post.saveCommentLoading">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="gettingMorePosts">
        <div *ngFor="let i of [1]">
          <div class="card card-body mb-3">
            <div class="profile-container">
              <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                        'height.px': 50,
                        'width.px' : 50
                    }"></ngx-skeleton-loader>
              <div class="profile-item" style="display: flex; flex-direction: column; justify-content: center;">
                <div>
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 25,
                                'width.px' : 300,
                                'margin-bottom' : 0
                            }"></ngx-skeleton-loader>
                </div>
                <div>
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 20,
                                'width.px' : 200,
                                'margin-bottom' : 0
                            }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="body-text">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                        'height.px': 180,
                        width : '100%',
                        'margin-bottom' : 0
                    }"></ngx-skeleton-loader>
            </div>
            <!-- <div class = "body-image">
        
                </div> -->
          </div>
        </div>
      </div>
      <div *ngIf="isAll" class="isall">
        -- No More Posts --
      </div>
    </div>
  </div>
  <div class="contact-div card">
    <div class="d-flex justify-content-between">
      <div class="contact-title">
        Contacts
      </div>
      <div>
        <div (click)="searchContact=!searchContact" style="cursor:pointer;">
          <mat-icon>
            search
          </mat-icon>
        </div>
      </div>
    </div>
    <div class="input-group mb-3" *ngIf="searchContact">
      <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
        style="border-radius: 18px 0px 0px 18px;cursor: pointer;" [(ngModel)]="contactName" name="'contactsearch"
        id="contactsearch" (ngModelChange)="ContactSearchChange()" (keyup.enter)="homesearchContact()">
      <button class="btn  contacts-btn" type="button" id="button-addon2" (click)="homesearchContact()">
        <div *ngIf="!searchingContact && !searchContactCancel">
          Search
        </div>
        <div class="spinner-border contacts-spinner" role="status" *ngIf="searchingContact && !searchContactCancel">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div *ngIf="searchContactCancel && !searchingContact  " style="height: 1rem;margin: -0.6rem 0.3rem 0 0rem;">
          <mat-icon style="margin: 0rem 0.5rem 0 0.7rem;">
            close
          </mat-icon>
        </div>
      </button>
    </div>
    <div>
      <ul class="list-group" *ngIf="gettingContactList">
        <div class="mb-2" *ngFor="let item of chatLoaderArray">
          <div class="d-flex align-items-center">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                    'height.px': 40}"></ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" appearance="line"
              [theme]="{ 'height.px': 20, 'width.px': 180, 'margin.px' : 0}"></ngx-skeleton-loader>
          </div>
        </div>
      </ul>
      <ul class="list-group" *ngIf="!gettingContactList && contactsList.length > 0">
        <div class="profile-container" *ngFor="let user of contactsList | slice:0:12">
          <span (click)="viewProfile(user.userid)">
            <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
              [lazyLoad]="user.photo || '../../../assets/images/profile.png'">
          </span>
          <div class="profile-item-container">
            <div class="profile-item">
              <div class="username" (click)="viewProfile(user.userid)" style="cursor: pointer;">
                <b>{{user.name || user.userid}}</b>
              </div>
            </div>
          </div>
          <div class="msg" (click)="goToMessage(user)">
            <mat-icon>chat</mat-icon>
          </div>
        </div>
      </ul>
      <div *ngIf="!gettingContactList && contactsList.length == 0"
        class="d-flex align-items-center justify-content-center" style="height: 100px; width: 100%;">
        <div>
          No Contacts
        </div>
      </div>
    </div>

  </div>

</div>



<div *ngIf="!openedPost" class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
  <button mat-fab class="custom-buttons" aria-label="Scroll To Top" title="Scroll To Top" aria-pressed="true"
    (click)="scrollToTop()">
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>
</div>

<div *ngIf="!openedPost" class="create">
  <button mat-fab class="custom-buttons" aria-label="Create Post" title="Create Post" aria-pressed="true"
    (click)="createPost()">
    <!-- <mat-icon>add</mat-icon> -->
    <!-- <i class = "far fa-edit"></i> -->
    <img src="../../../assets/images/edit.png" width="20px" height="auto">
  </button>
</div>


<div *ngIf="openedPost">
  <button mat-icon-button (click)="closeDetail()">
    <mat-icon>close</mat-icon>
  </button>
  <app-social-posts-detail></app-social-posts-detail>
</div>