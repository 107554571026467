<div class="container-fluid">
    <div class="content">

        <div>
            <app-crm-breadcrumb *ngIf="!isediting " [t_breadcrumb]="'Customer Information'" s_breadcrumb="Self Service"
                (emit)="goCrm()"></app-crm-breadcrumb>
            <app-crm-breadcrumb *ngIf="isediting " [t_breadcrumb]="'Customer Information'" s_breadcrumb="Self Service"
                [f_breadcrumb]="'Edit'" (emit1)="cancelFunction()" (emit)="goCrm()">
            </app-crm-breadcrumb>

            <!-- <app-crm-breadcrumb *ngIf="customercheck" s_breadcrumb="Self Service" [t_breadcrumb]="'Service Tickets'"  (emit)="goCrm()">
        </app-crm-breadcrumb> -->

            <!-- goCrm() -->
            <!-- <button *ngIf="!isediting && edicheck" type="button" class="btn btn-custom me-2 mb-2" >
            Edit
        </button> -->
        </div>
        <div *ngIf="!isediting">
            <div>
                <div>
                    <div class="d-flex">
                        <div class="title" style="font-size: 18px;">Customer Info</div>
                        <!-- <span class="title" *ngIf="!isediting && edicheck" style="color: var(--main-color);margin-left: 10px;cursor: pointer;" (click)="goEdit()">
                            <mat-icon style="font-size: 18px;margin-top: 4px;">edit</mat-icon>
                        </span> -->
                    </div>
                    <div class="card card-body my-card" [ngClass]="{ 'height-val': gettingData }">
                        <!--  -->
                        <div *ngIf="gettingData" style="text-align: center;
                        justify-content: center;margin-top: auto;
                        margin-bottom: auto;">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <ul class="list-group" *ngIf="!gettingData && !isMobileView">
                            <li class="list-group-item " *ngFor="let i of customerdetail">
                                <div class="d-flex">
                                    <div style="width: 20%;">
                                        {{i.title}}
                                    </div>
                                    <div style="margin-right:20px ;">
                                        -
                                    </div>
                                    <div class=" mr-text">{{i.data}}
                                        <div *ngIf="i.address">{{i.address}}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="list-group" *ngIf="!gettingData && isMobileView">
                            <li class="list-group-item " *ngFor="let i of customerdetail">
                                <div>
                                    <div>
                                        {{i.title}}
                                    </div>
                                    <div class=" mr-text">
                                        <span>-</span> {{i.data}}
                                        <div *ngIf="i.address">{{i.address}}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Branch List -->
                <div>
                    <div class="title" style="font-size: 18px;">Branches</div>


                    <div class="row">

                        <div class="col-lg-4" *ngFor="let i of branchlist" id="first">
                            <!-- <div class="card"> -->
                            <div class="card card-body my-card">

                                <div style="margin: 10px;">
                                    <div class="d-flex">
                                        <div class="me-auto">
                                            {{i.branchname}}
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="row" style="max-height: 60px;">
                                        <div class="col-4" style="color: grey">Phone <br>
                                            <div *ngIf="i.branchmobile" style="color: black">{{i.branchmobile}}</div>
                                            <div *ngIf="!i.branchmobile">-</div>
                                        </div>
                                        <div class="col-8" style="color: grey; text-align: right;">
                                            Email <br>

                                            <div class="d-flex" *ngIf="i.email"
                                                style="color: black; text-align: right;float: right;">
                                                <i class="fa fa-envelope-o" aria-hidden="true"
                                                    style="font-size: 11px;margin-top: 7px;color: gray;margin-left: 4px;"></i>
                                                <span style="font-size: 22px;margin-top: -5px;font-size: 22px;margin-right: 3px;
                                                margin-left: 3px; color: grey;">.</span> {{i.branchemail}}
                                            </div>
                                            <div *ngIf="!i.branchemail">-</div>
                                        </div>
                                    </div>

                                    <div style="margin-top:10px;margin-bottom: 20px;color: grey;">Address <br>
                                        <div *ngIf="i.branchaddress1" style="color: black">{{i.branchaddress1}}</div>
                                        <div *ngIf="!i.branchaddress1">-</div>
                                    </div>

                                </div>
                                <!-- </div> -->

                            </div>

                        </div>

                        <div class="col-lg-6" *ngFor="let i of branchlist" id="second">
                            <div class="card card-body my-card">
                                <div style="margin: 10px;">

                                    <div class="d-flex">
                                        <div class="me-auto">
                                            {{i.branchname}}
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row" style="max-height: 60px;">
                                        <div class="col-4" style="color: grey">Phone <br>
                                            <div *ngIf="i.branchmobile" style="color: black">{{i.branchmobile}}</div>
                                            <div *ngIf="!i.branchmobile">-</div>
                                        </div>
                                        <div class="col-8" style="color: grey; text-align: right;">
                                            Email <br>
                                            <div class="d-flex" *ngIf="i.branchemail"
                                                style="color: black; text-align: right;float: right;">
                                                <i class="fa fa-envelope-o" aria-hidden="true"
                                                    style="font-size: 11px;margin-top: 7px;color: gray;margin-left: 4px;"></i>
                                                <span style="font-size: 22px;margin-top: -5px;font-size: 22px;
                                                margin-left: 3px; color: grey;margin-right: 3px;">.</span>
                                                {{i.branchemail}}
                                            </div>
                                            <!-- <div *ngIf="i.email" style="color: black">{{i.email}}</div> -->
                                            <div *ngIf="!i.branchemail">-</div>
                                        </div>
                                    </div>
                                    <div style="margin-top:10px;margin-bottom: 20px;color: grey;">Address <br>
                                        <div *ngIf="i.branchaddress1" style="color: black">{{i.branchaddress1}}</div>
                                        <div *ngIf="!i.branchaddress1">-</div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="col-lg-12" *ngFor="let i of branchlist" id="third">
                            <div class="card card-body my-card">
                                <div style="margin: 10px;">

                                    <div class="d-flex">
                                        <div class="me-auto">
                                            {{i.branchname}}
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="row" style="max-height: 60px;">
                                        <div class="col-4" style="color: grey">Phone <br>
                                            <div *ngIf="i.branchmobile" style="color: black">{{i.branchmobile}}</div>
                                            <div *ngIf="!i.branchmobile">-</div>
                                        </div>
                                        <div class="col-8" style="color: grey; text-align: right;">
                                            Email <br>

                                            <!-- <div *ngIf="i.email" style="color: black">{{i.email}}</div> -->
                                            <div class="d-flex" *ngIf="i.branchemail  "
                                                style="color: black; text-align: right;float: right;">
                                                <i class="fa fa-envelope-o" aria-hidden="true"
                                                    style="font-size: 11px;margin-top: 7px;color: gray;margin-left: 4px;"></i>
                                                <span
                                                    style="font-size: 22px;margin-top: -5px;font-size: 22px;margin-right: 3px;margin-left: 3px; color: grey;">.</span>
                                                {{i.branchemail}}
                                            </div>
                                            <div *ngIf="!i.branchemail">-</div>
                                        </div>
                                    </div>
                                    <div style="margin-top:10px;margin-bottom: 20px;color: grey;">Address <br>

                                        <div *ngIf="i.branchaddress1" style="color: black">{{i.branchaddress1}}</div>
                                        <div *ngIf="!i.branchaddress1">-</div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- Branch -->

                <div>
                    <div class="d-flex">
                        <div class="title me-3" style="font-size: 18px;">Contacts</div>
                        <div style="cursor: pointer;" class="mt-4">
                            <div class="domain-dd" (click)="$event.stopPropagation();" [matMenuTriggerFor]="contactmenu">
                                <div class="d-flex align-items-center">

                                    <div class="org-name" style="font-size: 11px;">
                                        {{branchname}}
                                    </div>
                                    <i class="fas fa-caret-down" style="color: black;margin-left: 5px;"></i>
                                </div>
                            </div>

                            <mat-menu #contactmenu="matMenu" class="org-menu" xPosition="before">
                                <button mat-menu-item (click)="branchChanges('all')">All</button>
                                <button mat-menu-item *ngFor="let i of branchlist" (click)="branchChanges(i)">{{i.branchname}}</button>
                            </mat-menu>

                        </div>
                    </div>

                    <div class="row">

                        <div class="col-lg-4" *ngFor="let i of contactsList" id="first">
                            <!-- <div class="card"> -->
                            <div class="card card-body my-card">

                                <div style="margin: 10px;">
                                    <div class="d-flex">
                                        <div class="me-auto">
                                            {{i.contactname}}
                                            <span *ngIf="i.role == '300'">
                                                <img class="bluemark" src="../../../assets/images/bluemark.png">
                                            </span>
                                        </div>
                                        <div style="padding-left: 5px;
                                        color: white;
                                        padding-right: 5px;
                                        font-size: 9px;
                                        font-weight: bold;
                                        border-radius: 3px;
                                        box-shadow: inset 0 0 0 1px rgb(100 121 143 / 30%);
                                        background-color: var(--main-color);
                                        letter-spacing: 1px;">
                                            Self Service
                                        </div>
                                    </div>

                                    <div style="color:gray" *ngIf="i.post"> {{i.post}}</div>
                                    <br *ngIf="!i.post">
                                    <hr>
                                    <div class="row" style="max-height: 60px;">
                                        <div class="col-4" style="color: grey">Phone <br>
                                            <div *ngIf="i.mobile" style="color: black">{{i.mobile}}</div>
                                            <div *ngIf="!i.mobile">-</div>
                                        </div>
                                        <div class="col-8" style="color: grey; text-align: right;">
                                            Email <br>

                                            <div class="d-flex" *ngIf="i.email"
                                                style="color: black; text-align: right;float: right;">
                                                <i *ngIf="i.emailaccess == 'true'" class="fa fa-envelope-o"
                                                    aria-hidden="true"
                                                    style="font-size: 11px;margin-top: 7px;color: gray;margin-left: 4px;"></i>
                                                <span *ngIf="i.emailaccess == 'true'" style="font-size: 22px;margin-top: -5px;font-size: 22px;margin-right: 3px;
                                                  margin-left: 3px; color: grey;">.</span> {{i.email}}
                                            </div>
                                            <div *ngIf="!i.email">-</div>
                                        </div>
                                    </div>
                                    <div style="margin-top:10px;margin-bottom: 20px;color: grey;">Address <br>

                                        <div *ngIf="i.address1" style="color: black">{{i.address1}}</div>
                                        <div *ngIf="!i.address1">-</div>
                                    </div>

                                </div>
                                <!-- </div> -->

                            </div>

                        </div>
                        <div class="col-lg-6" *ngFor="let i of contactsList" id="second">
                            <div class="card card-body my-card">
                                <div style="margin: 10px;">

                                    <div class="d-flex">
                                        <div class="me-auto">
                                            {{i.contactname}}
                                            <span *ngIf="i.role == '300'">
                                                <img class="bluemark" src="../../../assets/images/bluemark.png">
                                            </span>
                                        </div>
                                        <div style="padding-left: 5px;
                                        color: white;
                                        padding-right: 5px;
                                        font-size: 9px;
                                        font-weight: bold;
                                        border-radius: 3px;
                                        box-shadow: inset 0 0 0 1px rgb(100 121 143 / 30%);
                                        background-color: var(--main-color);
                                        letter-spacing: 1px;">
                                            Self Service
                                        </div>
                                    </div>
                                    <div style="color:gray" *ngIf="i.post"> {{i.post}}</div>
                                    <br *ngIf="!i.post">
                                    <hr>
                                    <div class="row" style="max-height: 60px;">
                                        <div class="col-4" style="color: grey">Phone <br>
                                            <div *ngIf="i.mobile" style="color: black">{{i.mobile}}</div>
                                            <div *ngIf="!i.mobile">-</div>
                                        </div>
                                        <div class="col-8" style="color: grey; text-align: right;">
                                            Email <br>
                                            <div class="d-flex" *ngIf="i.email"
                                                style="color: black; text-align: right;float: right;">
                                                <i *ngIf="i.emailaccess == 'true'" class="fa fa-envelope-o"
                                                    aria-hidden="true"
                                                    style="font-size: 11px;margin-top: 7px;color: gray;margin-left: 4px;"></i>
                                                <span *ngIf="i.emailaccess == 'true'" style="font-size: 22px;margin-top: -5px;font-size: 22px;
                                                  margin-left: 3px; color: grey;margin-right: 3px;">.</span>
                                                {{i.email}}
                                            </div>
                                            <!-- <div *ngIf="i.email" style="color: black">{{i.email}}</div> -->
                                            <div *ngIf="!i.email">-</div>
                                        </div>
                                    </div>
                                    <div style="margin-top:10px;margin-bottom: 20px;color: grey;">Address <br>

                                        <div *ngIf="i.address1" style="color: black">{{i.address1}}</div>
                                        <div *ngIf="!i.address1">-</div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="col-lg-12" *ngFor="let i of contactsList" id="third">
                            <div class="card card-body my-card">
                                <div style="margin: 10px;">

                                    <div class="d-flex">
                                        <div class="me-auto">
                                            {{i.contactname}}
                                            <span *ngIf="i.role == '300'">
                                                <img class="bluemark" src="../../../assets/images/bluemark.png">
                                            </span>
                                        </div>
                                        <div style="padding-left: 5px;
                                        color: white;
                                        padding-right: 5px;
                                        font-size: 9px;
                                        font-weight: bold;
                                        border-radius: 3px;
                                        box-shadow: inset 0 0 0 1px rgb(100 121 143 / 30%);
                                        background-color: var(--main-color);
                                        letter-spacing: 1px;">
                                            Self Service
                                        </div>
                                    </div>
                                    <div style="color:gray" *ngIf="i.post"> {{i.post}}</div>
                                    <br *ngIf="!i.post">
                                    <hr>
                                    <div class="row" style="max-height: 60px;">
                                        <div class="col-4" style="color: grey">Phone <br>
                                            <div *ngIf="i.mobile" style="color: black">{{i.mobile}}</div>
                                            <div *ngIf="!i.mobile">-</div>
                                        </div>
                                        <div class="col-8" style="color: grey; text-align: right;">
                                            Email <br>

                                            <!-- <div *ngIf="i.email" style="color: black">{{i.email}}</div> -->
                                            <div class="d-flex" *ngIf="i.email"
                                                style="color: black; text-align: right;float: right;">
                                                <i *ngIf="i.emailaccess == 'true'" class="fa fa-envelope-o"
                                                    aria-hidden="true"
                                                    style="font-size: 11px;margin-top: 7px;color: gray;margin-left: 4px;"></i>
                                                <span *ngIf="i.emailaccess == 'true'"
                                                    style="font-size: 22px;margin-top: -5px;font-size: 22px;margin-right: 3px;margin-left: 3px; color: grey;">.</span>
                                                {{i.email}}
                                            </div>
                                            <div *ngIf="!i.email">-</div>
                                        </div>
                                    </div>
                                    <div style="margin-top:10px;margin-bottom: 20px;color: grey;">Address <br>

                                        <div *ngIf="i.address1" style="color: black">{{i.address1}}</div>
                                        <div *ngIf="!i.address1">-</div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- <div class="table-responsive">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-1 col-md-1.5">Name </td>
                                    <td scope="col" class="col-lg-1 col-md-1.5">Post </td>
                                    <td scope="col" class="col-lg-1 col-md-1.5">Phone</td>
                                    <td scope="col" class="col-lg-1 col-md-1.5">Email</td>
                                    <td scope="col" class="col-lg-1 col-md-1.5">Address </td>
                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="6" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngIf="contactsList.length == 0 && !gettingData">
                                    <td colspan="6" id="nodata">Empty</td>
                                </tr>
                                <tr *ngFor="let i of contactsList">
                                    <td>
                                        <span [title]="i.contactname">{{i.contactname}}
                                            <span *ngIf="i.role == '300'">
                                                <img class="bluemark" src="../../../assets/images/bluemark.png">
                                            </span>
                                        </span>
                                    </td>
                                    <td id="td-data">
                                        <span [title]="i.post">{{i.post}}</span>
                                    </td>
                                    <td id="td-data">
                                        <span [title]="i.mobile">{{i.mobile}}</span>
                                    </td>
                                    <td id="td-data">
                                        <span [title]="i.email">{{i.email}}</span>
                                    </td>
                                    <td id="td-data">
                                        <span [title]="i.address1">{{i.address1}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>

        </div>
        <div *ngIf="isediting">

            <div class="close-save-button mb-3">
                <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="submitCustomer()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
                </app-button-loader>

            </div>
            <div class="mb-3">
                <label for="name" class="form-label">Name</label>

                <input type="text" class="form-control" id="name" name="name" [(ngModel)]="cuForm.name" required
                    #name="ngModel" [ngClass]="{ 'is-invalid': cuFormSubmitted && name.invalid }" disabled>

                <div class="invalid-feedback" *ngIf="name.invalid || (name.dirty || name.touched) && cuFormSubmitted">
                    <div *ngIf="name.errors?.required">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mb-3">
                <div class="form-check  me-5" *ngFor="let item of businessTypes">
                    <label class="radio-inline">
                        <input class="form-check-input" type="radio" name="businesstype" [id]="item.key"
                            #cutype="ngModel" [(ngModel)]="cuForm.businesstype" [value]="item.key"
                            [checked]="item.key == cuForm.businesstype" disabled>
                        {{item.value}}
                    </label>
                </div>
            </div>


            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="phone" class="form-label">Phone</label>

                    <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="cuForm.phone" required
                        #phone="ngModel" [ngClass]="{ 'is-invalid': cuFormSubmitted && phone.invalid }">

                    <div class="invalid-feedback"
                        *ngIf="phone.invalid || (phone.dirty || phone.touched) && cuFormSubmitted">
                        <div *ngIf="phone.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="email" class="form-label">Email</label>

                    <input type="text" class="form-control" id="email" name="email" [(ngModel)]="cuForm.email">
                </div>

            </div>

            <div class="row">
                <div class="mb-3  col-md-12">
                    <label for="address1" class="form-label">Address</label>

                    <input type="text" class="form-control" id="address1" name="address1" [(ngModel)]="cuForm.address1">
                </div>
                <div class="mb-3 col-md-12">

                    <input type="text" class="form-control" id="address2" name="address2" [(ngModel)]="cuForm.address2">
                </div>
            </div>


            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="phone" class="form-label">Industry Type</label>

                    <input type="text" class="form-control" id="industrytype" name="industrytype"
                        [(ngModel)]="cuForm.industrytype">

                </div>
                <div class="mb-3 col-md-6">
                    <!-- <div class="mb-3 col-md-6"> -->
                    <label for="status" class="form-label">Status</label>

                    <div>
                        <select class="form-select" aria-label="Select status" id="status" name="status"
                            [(ngModel)]="cuForm.status" disabled>
                            <option *ngFor="let status of customerStatus" value="{{status.key}}">
                                {{status.value}}</option>
                        </select>
                    </div>
                </div>

            </div>

            <div class="d-flex">
                <div class="mb-3  d-flex align-items-end">
                    <button class="btn btn-custom" (click)="openContactForm()">
                        <i class="fas fa-address-book me-1"></i> {{cuForm.contactlist.length}} Contact(s)
                    </button>
                </div>
            </div>

            <div *ngIf="isediting && emailaccessList.length > 0" style="padding-top: 10px;
    padding-bottom: 10px;">
                <p>Email access</p>
                <ul *ngFor="let i of emailaccessList">
                    <li>{{i.email}}</li>
                </ul>
            </div>
            <div class="mb-3" *ngIf="cuForm.businesstype == '002'">
                <label for="pic" class="contacts-label">Person in Charge</label>

                <input type="text" class="form-control" id="pic" name="pic" disabled
                    value="{{this.allinoneService.getKunyekUserName()}}">
            </div>

            <input style="display: none" type="file" (change)="onFileSelect($event)" #hiddenpdffileinput multiple>
            <button class="btn btn-outline-primary custom-btn" (click)="hiddenpdffileinput.click()">
                <span>Choose Attachments</span>
            </button>

            <div class="attachmentList mb-3">
                <div *ngFor="let file of attachmentList; let index = index">
                    <div class="pdf-container">
                        <div class="pdf-filename">
                            {{file.filename}}
                        </div>
                        <div class="pdf-action d-flex align-items-center">
                            <button mat-icon-button class="icon-button" (click)="removeAttachment(index)">
                                <mat-icon class="delete-icon">delete</mat-icon>
                            </button>
                            <button mat-icon-button class="ms-2 icon-button"
                                (click)="downloadFile(file.fileurl, file.filename)" *ngIf="file.fileurl">
                                <mat-icon class="download-icon">file_download</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>