import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { HistoryDetailComponent } from '../history-detail/history-detail.component';

@Component({
  selector: 'app-service-tickets-history',
  templateUrl: './service-tickets-history.component.html',
  styleUrls: ['./service-tickets-history.component.scss'],
})
export class ServiceTicketsHistoryComponent implements OnInit {
  @Input('warranty') isWarranty = false;
  @Input() data?: any;
  @Input() orgid?: string;
  @Output('cancelfun')
  cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output('serviceWarrantyCancel') serviceWarrantyCancel = new EventEmitter();

  pageSize: number = 100;
  formatToShow = 'yyyy-MM-dd';
  enddatetime = formatDate(new Date(), this.formatToShow, 'en-US').toString();

  histories: any = [];
  totalCount: number = 0;
  gettingData: boolean = false;
  assignPeople = [];

  constructor(
    private kunyekService: KunyekService,
    private allInOneService: AllInOneService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    console.log('hello from hsitory');
    this.getServiceHistory();
  }



  getServiceHistory() {
    const data = {
      domainid: this.allInOneService.getDomain().domainid,
      orgid: this.orgid,
      customerview: false,
      pagesize: this.pageSize,
      serviceid: this.data.serviceid,
      sortby: '',
      startdate: '',
      enddate: this.enddatetime,
      newpath: true,
    };
    this.gettingData = true;
    this.kunyekService.getHistory(data).subscribe({
      next: (res) => {
        if (res.returncode === '300') {
          this.gettingData = false;
          this.histories = res.list;
          this.totalCount = res.total;
        } else {
          throw Error('error');
        }
      },
      error: (err) => {
        this.gettingData = false;
        console.log(err);
      },
    });
  }

  getPriorityClass(priority: string): { [key: string]: boolean } {
    return {
      priorityprimary: priority === '00500',
      priorityred: priority === '00900',
    };
  }

  getPriorityLabel(priority: string): string {
    switch (priority) {
      case '0010':
        return 'Low';
      case '00500':
        return 'High';
      default:
        return 'Critical';
    }
  }

  mapingStatus(type: string): string {
    const status: { [key: string]: string } = {
      '0010': 'New',
      '0040': 'Completed',
      '00900': 'Closed',
      '0060': 'UAT',
      '0061': 'Failed',
      '0062': 'Passed',
      '0020': 'WIP',
    };
    return status[type] != undefined ? status[type] : '';
  }

  cancelFunction() {
    this.cancel.emit();
  }

  openHistoryDetail(item: any) {
    const dialogRef = this.dialog.open(HistoryDetailComponent, {
      minWidth: 'calc(100vw - 50px)',
      minHeight: 'calc(100vh - 50px)',
      data: {
        orgid: this.orgid,
        servicedata: item,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('hello colse');
    });
  }

  goToServiceWarranty() {
    console.log('go to wa')
    this.serviceWarrantyCancel.emit();
  }
}
