import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
  selector: 'app-checkin-ticket-details',
  templateUrl: './checkin-ticket-details.component.html',
  styleUrls: ['./checkin-ticket-details.component.scss']
})
export class CheckinTicketDetailsComponent implements OnInit {

  ticketNo:string=""
  gettingTicketDetails:boolean=false
  getdetailsForm={
    "orgid": "",
    "ticketno": "",
}
  ticketdetails={
    "ticketno": "",
    "servicedesc": "",
    "reportedby": "",
    "startdatetime": "",
    "enddatetime": "",
    "userid": "",
    "remark": "",
    "refno": "",
    "reopen": "",
    "reticketno": "",
    "t4": "",
    "createdAt": "",
    "details": "",
    "solution": "",
    "version": "",
    "targetdatetime": "",
    "duedatetime": "",
    "isinternal": "",
    "age": 0,
    "customername": "",
    "productname": "",
    "projectname": "",
    "servicetypename": "",
    "statusname": "",
    "priorityname": ""
}
  constructor(   
    private kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      if (data){
        this.ticketNo=data.ticketno
        this.getdetailsForm.orgid=data.orgid
        this.getdetailsForm.ticketno=data.ticketno

      }
     }

  ngOnInit(): void {
    var param_data = {
      "orgid": this.getdetailsForm.orgid,
      "ticketno": this.getdetailsForm.ticketno,
  }
    this.gettingTicketDetails = true;
    this.kunyekService.getticketdetails(param_data).subscribe((res: any) => {
      if (res.returncode == "300") {
        console.log(this.gettingTicketDetails)
        this.ticketdetails=res.detail

        // // var reslist=res.reactlist;
        // var res = reslist.filter(
        //   (x: any) => x.code == code
        // );
        // console.log(res)
        if (res.length>0){
        // var list=res[0].userlist;
        this.gettingTicketDetails = false;

        }
        this.gettingTicketDetails = false;
      }
      else {
        this.gettingTicketDetails = false;
        this.messageService.openSnackBar(res.message, 'warn');

      }
    },
      err => {
        this.gettingTicketDetails = false;
      }
    )
  

  }

}
