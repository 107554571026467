<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb *ngIf="!addRoster" s_breadcrumb="Roster">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="addRoster" s_breadcrumb="Roster"
        [t_breadcrumb]="isEditing ? 'Edit Roster' : 'New Roster' " (emit)="cancel()"></app-team-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!addRoster" [s_breadcrumb]="'Roster'"></app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addRoster" [s_breadcrumb]="'Roster'"
        [t_breadcrumb]="isEditing ? 'Edit Roster' : 'New Roster'" (emit)="cancel()"></app-office-breadcrumb>
    </ng-container> -->

    <ng-container *ngIf="allinoneService.isAttendanceView">
      <app-hcm-breadcrumb *ngIf="!addRoster" s_breadcrumb="Roster">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="addRoster" s_breadcrumb="Roster"
        [t_breadcrumb]="isEditing ? 'Edit Roster' : 'New Roster' " (emit)="cancel()"></app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!allinoneService.isAttendanceView">
      <app-team-breadcrumb *ngIf="!addRoster" s_breadcrumb="Roster">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="addRoster" s_breadcrumb="Roster"
        [t_breadcrumb]="isEditing ? 'Edit Roster' : 'New Roster' " (emit)="cancel()"></app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="addRoster; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitRoster()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div class="row">
        <div class="mb-3 col-md-6">
          <label for="name" class="form-label">Name</label>
          <input type="text" class="form-control" id="name" [(ngModel)]="rosterForm.name" name="name" #name="ngModel"
            [ngClass]="{ 'is-invalid': rosterFormSubmitted && name.invalid }" required />
          <div class="invalid-feedback" *ngIf="
              name.invalid ||
              ((name.dirty || name.touched) && rosterFormSubmitted)
            ">
            <div *ngIf="name.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="description" class="form-label">Description</label>
          <input class="form-control" id="description" [(ngModel)]="rosterForm.description" name="description" />
        </div>
        <div class="col-md-12 mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="default" name="default"
              [(ngModel)]="rosterForm.default" />
            <label class="form-check-label" for="default"> Default </label>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="monday" class="form-label">Monday</label>
          <select class="form-select" name="monday" id="monday" [(ngModel)]="rosterForm.monday"
            [disabled]="shiftListLoading">
            <option value="">-</option>
            <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
              {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="shiftListLoading"></mat-progress-bar>
        </div>
        <div class="mb-3 col-md-6">
          <label for="tuesday" class="form-label">Tuesday</label>
          <select class="form-select" name="tuesday" id="tuesday" [(ngModel)]="rosterForm.tuesday"
            [disabled]="shiftListLoading">
            <option value="">-</option>
            <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
              {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="shiftListLoading"></mat-progress-bar>
        </div>
        <div class="mb-3 col-md-6">
          <label for="wednesday" class="form-label">Wednesday</label>
          <select class="form-select" name="wednesday" id="wednesday" [(ngModel)]="rosterForm.wednesday"
            [disabled]="shiftListLoading">
            <option value="">-</option>
            <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
              {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="shiftListLoading"></mat-progress-bar>
        </div>
        <div class="mb-3 col-md-6">
          <label for="thursday" class="form-label">Thursday</label>
          <select class="form-select" name="thursday" id="thursday" [(ngModel)]="rosterForm.thursday"
            [disabled]="shiftListLoading">
            <option value="">-</option>
            <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
              {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="shiftListLoading"></mat-progress-bar>
        </div>
        <div class="mb-3 col-md-6">
          <label for="firday" class="form-label">Friday</label>
          <select class="form-select" name="friday" id="friday" [(ngModel)]="rosterForm.friday"
            [disabled]="shiftListLoading">
            <option value="">-</option>
            <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
              {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="shiftListLoading"></mat-progress-bar>
        </div>
        <div class="mb-3 col-md-6">
          <label for="saturday" class="form-label">Saturday</label>
          <select class="form-select" name="saturday" id="saturday" [(ngModel)]="rosterForm.saturday"
            [disabled]="shiftListLoading">
            <option value="">-</option>
            <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
              {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="shiftListLoading"></mat-progress-bar>
        </div>
        <div class="mb-3 col-md-6">
          <label for="sunday" class="form-label">Sunday</label>
          <select class="form-select" name="sunday" id="sunday" [(ngModel)]="rosterForm.sunday"
            [disabled]="shiftListLoading">
            <option value="">-</option>
            <option *ngFor="let shift of shiftList" [value]="shift.shiftid">
              {{ shift.name }} ({{ shift.starttime }} - {{ shift.endtime }})
            </option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="shiftListLoading"></mat-progress-bar>
        </div>
      </div>
    </ng-container>
    <ng-template #listView>
      <div class="d-flex align-items-center mb-3">
        <button class="btn btn-custom me-auto" (click)="newRoster()">
          <i class="fa fa-plus me-1"></i> New
        </button>
      </div>

      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description"
            aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (rosterList | filter : searchText: 'merchant' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="rosterList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>


      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-5" mat-sort-header="name">Name</td>
              <td scope="col" class="col-md-6" mat-sort-header="description">Description</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="rosterListLoading">
            <tr>
              <td colspan="7" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!rosterListLoading">
            <tr *ngIf="rosterList.length == 0">
              <td colspan="7" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let roster of rosterList | filter : searchText: 'merchant' | paginate: { itemsPerPage: itemPerPage , currentPage : currentPage } ; let index = index">
              <td class="td-data td-edit" (click)="editRoster(roster)">
                <span [title]="roster.name">{{ roster.name }}</span>
              </td>
              <td class="td-data">
                <span [title]="roster.description">{{
                  roster.description
                  }}</span>
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editRoster(roster)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deleteRoster(roster.rosterid, index)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="rosterList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>