<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title" style="font-size:18px;">Deals</div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="!dealAdd">

        <div class="mb-3">
            <button type="button" class="btn btn-custom" (click)="dealAdd = true">
                <i class="fa fa-plus me-1"></i>Add Deal
            </button>
        </div>

        <div *ngIf="finalDealList.length == 0" class="nodata d-flex align-items-center justify-content-center">
            Empty
        </div>
        <div class="d-flex flex-column products-card p-1 my-2" *ngFor="let deal of finalDealList; let i = index;">
            <div class="d-flex flex-column" (click)="editDeal(deal)">
                <div class="d-flex justify-content-between">
                    <div class="p-1 product-name ">
                        {{customername}}
                    </div>
                    <div (click)="delete(i)">
                        <i class="fas fa-times delete-icon"></i>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="p-1">
                        {{deal.currencycode}}&nbsp;{{ (deal.amount && deal.amount != '0') ? (deal.amount | number: '2.') : '0'}}
                    </div>
                    <div class="p-1">
                        {{allinoneService.formatDBToShow(allinoneService.formatDate(deal.targetdate))}} ({{deal.stagename}})
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- <div *ngIf="dealAdd" style="height: 475px;overflow-y: scroll;overflow-x: hidden;">
        <app-crm-deal-add [orgid]="customername" [stageList]="stageList" [currencyType]="currencyType" [fromCustomer]="true" [fromPipeline]="fromPipeline" [editData]="dataForEdit" [statusList]="statusList" [customerStatus]="[]" (save)="complete($event)" (crmcancel)="cancel()"></app-crm-deal-add>
    </div> -->
    <div *ngIf="dealAdd" style="height: 475px;overflow-y: auto;overflow-x: hidden !important;">

        <app-crm-deal-add [orgid]="customername" [stageList]="stageList" [currencyType]="currencyType" [fromCustomer]="true" [fromPipeline]="fromPipeline" [editData]="dataForEdit" [statusList]="statusList" [customerStatus]="[]" (save)="complete($event)" (crmcancel)="cancel()"></app-crm-deal-add>

    </div>
</div>