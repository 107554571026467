import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { OfficeTemplateTypeComponent } from '../office-template-type/office-template-type.component';

@Component({
  selector: 'app-office-leave-template',
  templateUrl: './office-leave-template.component.html',
  styleUrls: ['./office-leave-template.component.scss'],
})
export class OfficeLeaveTemplateComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: any = 490;
  itemPerPage: any = 10;
  currentPage: number = 1;

  isFocus: boolean = false;
  isEditing: boolean = false;
  gettingCalendar: boolean = false;
  addTemplate: boolean = false;
  isMobileView: boolean = false;
  leaveTemplateListLoading: boolean = false;
  nextDisabled: boolean = false;
  isLoading: boolean = false;
  formSubmitted: boolean = false;
  isAdmin: boolean = false;

  searchText: string = '';
  end: string = '';
  lastPage: any = '';

  leaveTemplateList: any[] = [];
  calendarList: any[] = [];
  activeCalendarList: any[] = [];
  allCalendarList: any[] = [];
  organizations: any[] = [];
  requestSubTypeList: any[] = [];

  selectedCalendar: any = {
    year: 'All',
    calendarid: '',
  };

  templateForm = {
    templateid: '',
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    templatename: '',
    calendarid: '',
    year: '-',
  };

  subscriptions = new SubSink();

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.allinoneService.isOfficeView = false;
    
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.templateForm.domainid = this.allinoneService.getDomain().domainid;
    if (this.isAdmin) {
      // this.organizations = this.allinoneService.orgs;
      // this.templateForm.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;
      this.organizations = this.allinoneService.orgs;
      this.templateForm.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.templateForm.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }

    // this.getCalendar();
    this.getTemplateList();
  }

  getCalendar() {
    this.gettingCalendar = true;
    const data = {
      domainid: this.templateForm.domainid,
      orgid: this.templateForm.orgid,
      active: '',
      roleid: this.allinoneService.getSelectedRole()
    };
    this.subscriptions.sink = this.kunyekService.getCalendar(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.calendarList = res.calendarlist;
          this.allCalendarList = res.calendarlist;
          this.activeCalendarList = res.calendarlist.filter(
            (item: any) => item.valid && item.active
          );
          this.calendarList.unshift({
            year: 'All',
            calendarid: '',
          });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCalendar = false;
      },
      (err) => {
        this.gettingCalendar = false;
      }
    );
  }

  getTemplateList() {
    this.leaveTemplateListLoading = true;
    let data = {
      orgid: this.templateForm.orgid,
      domainid: this.templateForm.domainid,
      calendarid: this.selectedCalendar.calendarid,
      end: this.end,
      roleid: this.allinoneService.getSelectedRole()
    };
    this.subscriptions.sink = this.kunyekService.getTemplate(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          if (res.templatelist.length > 0) {
            this.nextDisabled = false;
            this.leaveTemplateList.push.apply(
              this.leaveTemplateList,
              res.templatelist
            );
            this.end = res.end;

            if (res.templatelist.length < 10) {
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
            }
          } else {
            this.currentPage = this.currentPage - 1;
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.leaveTemplateListLoading = false;
      },
      (err) => {
        this.leaveTemplateListLoading = false;
      }
    );
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  clear() {
    this.searchText = '';
  }

  refresh() {
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.leaveTemplateList = [];
    this.getTemplateList();
  }

  filter() {}

  sortData(sort: Sort) {
    const data = this.leaveTemplateList;
    if (!sort.active || sort.direction === '') {
      this.leaveTemplateList = data;
      return;
    }
    this.leaveTemplateList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'year':
          return this.allinoneService.compare(a.year, b.year, isAsc);
        default:
          return 0;
      }
    });
  }

  editLeaveTemplate(template: any) {
    this.addTemplate = true;
    this.isEditing = true;
    this.templateForm = {
      templateid: template.templateid,
      domainid: this.templateForm.domainid,
      orgid: this.templateForm.orgid,
      templatename: template.name,
      calendarid: template.calendarid,
      year: template.year,
    };
    this.requestSubTypeList = JSON.parse(JSON.stringify(template.requestsubtypelist));
    console.log(this.requestSubTypeList);
  }

  deleteLeaveTemplate(templateid: string) {}

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;

        if (event > this.leaveTemplateList.length / this.itemPerPage) {
          this.getTemplateList();
        }
      }
    }
  }

  calendarChange(cal: any) {
    this.selectedCalendar = cal;
    this.end = '';
    this.currentPage = 1;
    this.leaveTemplateList = [];
    this.getTemplateList();
  }

  newTemplate() {
    this.addTemplate = true;
    // if (this.activeCalendarList.length > 0) {
    //   this.templateForm.calendarid = this.activeCalendarList[0].calendarid;
    //   this.templateForm.year = this.activeCalendarList[0].year;
    // }
  }

  cancel() {
    this.addTemplate = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.requestSubTypeList = [];
    this.templateForm = {
      templateid: '',
      domainid: this.templateForm.domainid,
      orgid: this.templateForm.orgid,
      templatename: '',
      calendarid: '',
      year: '-',
    };
  }

  changeCalenderforTemp(cal: any) {
    this.templateForm.calendarid = cal.calendarid;
    this.templateForm.year = cal.year;
  }

  addTypes() {
    const dialog = this.dialog.open(OfficeTemplateTypeComponent, {
      maxHeight: '95vh',
      width: '500px',
      maxWidth: '95vw',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        typelist: this.requestSubTypeList,
        orgid: this.templateForm.orgid,
        domainid: this.templateForm.domainid,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.requestSubTypeList = dialogResult.map((item: any) => ({
          ...item,
          noofdays: item.noofdays || '0',
        }));
      }
    });
  }

  submitTemplate() {
    console.log(this.requestSubTypeList);
    // return;
    this.formSubmitted = true;
    if (this.templateForm.templatename == '') {
      return;
    }
    // if (this.templateForm.calendarid == '') {
    //   return this.messageService.openSnackBar('Year cannot be blank.', 'warn');
    // }
    if (this.requestSubTypeList.length == 0) {
      return this.messageService.openSnackBar(
        'Choose at least one type.',
        'warn'
      );
    }
    this.isLoading = true;
    var finalRequestTypeList: any[] = [];
    this.requestSubTypeList.map((item: any) => {
      var days = item.noofdays == null || item.noofdays == '' ? '0' : item.noofdays.toString()
      finalRequestTypeList.push({
        requesttypeid: item.requesttypeid,
        noofdays: days
      });
    });
    var data = {
      templateid: this.templateForm.templateid,
      domainid: this.templateForm.domainid,
      orgid: this.templateForm.orgid,
      requesttypelist: finalRequestTypeList,
      name: this.templateForm.templatename,
      active: true,
      roleid: this.allinoneService.getSelectedRole()
    };
    console.log(data);
    var fun = this.isEditing
      ? this.kunyekService.editLeaveTemplate(data)
      : this.kunyekService.createLeaveTemplate(data);
    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.selectedCalendar = {
            year: 'All',
            calendarid: '',
          };
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
}
