<div *ngIf="status != 'Edit Relative'">
    <div class="me-auto">
        <app-kmember-breadcrumb [s_breadcrumb]="'Approval'">
        </app-kmember-breadcrumb>
    </div>
    <div class="d-flex gap-2 mb-2">
        <app-button-loader (click)="closeForm()" [button_text]="'Cancel'"
            [load_button_text]="'Saving'"></app-button-loader>
        <!-- <button class="btn btn-custom" type="submit" (click)="handleSaveMember()">Save</button> -->
        <!-- <app-button-loader (click)="handleSaveMember()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading">
        </app-button-loader> -->
        <div class="">
            <div class="line"></div>
        </div>

        <!-- <app-button-loader (click)="addRelativeUser()" [button_text]="'မိသားစုအသင်းဝင်ထည့်ရန်'"
            [disabled]="currentMember && currentMember.memberType && currentMember.memberType.membertypeid != 'ASDLFKJ'"
            *ngIf="(userCreated || status == 'Edit')">
        </app-button-loader> -->

        <app-button-loader class="mb-3" *ngIf="currentMember.formstatus != 003" (click)="openApproveMember('003')"
            [button_text]="'Reject'" [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true">
        </app-button-loader>

        <app-button-loader (click)="openApproveMember('002')" [button_text]="'Approve'" [load_button_text]="'Approving'"
            *ngIf="isNotApproved && currentMember" [isLoading]="isApproving">
        </app-button-loader>

        <div class="">
            <div class="line"></div>
        </div>

        <app-button-loader [button_text]="'History'" [load_button_text]="'Approving'" (click)="openHistory()">
        </app-button-loader>


        <div class="d-flex ms-auto align-items-center"
            *ngIf="currentMember && currentMember.formstatus == '002' && currentMember?.approved?.name">
            <span class="status status-green">Approved By: {{ currentMember?.approved?.name }}</span>
        </div>
    </div>

    <form [formGroup]="memberForm">
        <div class="card">
            <div class="card-body m-2">
                <div class="row">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="name" class="form-label">အမည် <font color="red">*</font></label>
                                <input type="text" id="name" class="form-control" formControlName="name" readonly
                                    [ngClass]="{ 'is-invalid': submitted && name.invalid }" />
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="membertype" class="form-label">ကဒ်အမျိုးအစား <font color="red">*</font>
                                </label>
                                <select class="form-select" id="membertypeid" name="membertype" aria-readonly="true"
                                    formControlName="membertypeid" required readonly
                                    [ngClass]="{ 'is-invalid': submitted && membertype.invalid }">
                                    <option value="">-</option>
                                    <option *ngFor="let item of memberTypeList" [value]="item.membertypeid">
                                        {{ item.name }}
                                    </option>
                                </select>
                                <div class="invalid-feedback" *ngIf="
                    membertype.invalid ||
                    ((membertype.dirty || membertype.touched) && submitted)
                  ">
                                    <div *ngIf="submitted && membertype.errors?.required">
                                        {{ requiredMessage }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="subname" class="form-label">အခြားအမည်</label>
                                <input type="text" id="subname" class="form-control" formControlName="subname"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="birthdate" class="form-label">မွေးသက္ကရာဇ်</label>
                                <input type="date" class="form-control" id="startdate" name="startdate" name="birthdate"
                                    placeholder="dd-mm-yyyy" formControlName="birthdate" required max="9999-12-31"
                                    step="1" readonly>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="nrc" class="form-label">နိုင်ငံသားမှတ်ပုံတင်အမှတ် <font color="red">*</font>
                                </label>
                                <div class="g-2">
                                    <div class="row g-2">
                                        <div class="col-2">
                                            <select class="form-select" id="nrcregioncode" name="nrcregioncode"
                                                formControlName="nrcregioncode" required readonly
                                                [ngClass]="{ 'is-invalid': submitted && nrcregioncode.invalid }">
                                                <option value="">-</option>
                                                <option *ngFor="let item of nrcRegionCode" [value]="item">{{item}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <select class="form-select" id="nrctownshipcode" name="nrctownshipcode"
                                                formControlName="nrctownshipcode" readonly
                                                [ngClass]="{ 'is-invalid': submitted && nrctownshipcode.invalid }">
                                                <option *ngFor="let item of nrcTownshipCode"
                                                    [value]="item == '-' ? '' : item">{{item}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-2">
                                            <select class="form-select" id="nrctype" name="nrctype"
                                                formControlName="nrctype" required readonly
                                                [ngClass]="{ 'is-invalid': submitted && nrctype.invalid }">
                                                <option *ngFor="let item of nrcType" [value]="item == '-' ? '' : item">
                                                    {{item}}</option>
                                            </select>
                                        </div>
                                        <div class="col-4">
                                            <input class="form-control" id="nrcnumber" name="nrcnumber"
                                                formControlName="nrcnumber" minlength="6" maxlength="6" required
                                                readonly (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                                                [ngClass]="{ 'is-invalid': submitted && nrcnumber.invalid }" />
                                        </div>
                                    </div>

                                    <div class="invalid"
                                        *ngIf="nrcregioncode.invalid || nrctownshipcode.invalid || nrctype.invalid || nrcnumber.invalid">
                                        <div *ngIf="(nrcregioncode.errors?.required || nrctownshipcode.errors?.required ||
                                    nrctype.errors?.required || nrcnumber.errors?.required) && submitted">{{
                                            requiredMessage }}</div>
                                    </div>


                                </div>

                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="nationality" class="form-label">လူမျိူး</label>
                                <input type="text" class="form-control" name="nationality" formControlName="nationality"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="fathername" class="form-label">အဘမည်</label>
                                <input type="text" class="form-control" name="fathername" formControlName="fathername"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="mothername" class="form-label">အမိအမည်</label>
                                <input type="text" class="form-control" name="mothername" formControlName="mothername"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="religion" class="form-label">ကိုးကွယ်သည့်ဘာသာ</label>
                                <input type="text" class="form-control" name="religion" formControlName="religion"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="education" class="form-label">ပညာအရည်အချင်း</label>
                                <input type="text" class="form-control" name="education" formControlName="education"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="job" class="form-label">အလုပ်အကိုင်</label>
                                <input type="text" class="form-control" name="job" formControlName="job" readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="currentjob" class="form-label">လက်ရှိအလုပ်အကိုင်</label>
                                <input type="text" class="form-control" name="currentjob" formControlName="currentjob"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="retiredjob" class="form-label">အငြိမ်းစားဖြစ်ခဲ့သော်
                                    နောက်ဆုံးလုပ်ကိုင်ခဲ့သည့်
                                    အလုပ်အကိုင်</label>
                                <input type="text" class="form-control" name="retiredjob" formControlName="retiredjob"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="address" class="form-label">နေရပ်လိပ်စာ</label>
                                <input type="text" class="form-control" name="address" formControlName="address"
                                    readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="current_address" class="form-label">လက်ရှိနေရပ်လိပ်စာ</label>
                                <input type="text" class="form-control" name="current_address"
                                    formControlName="current_address" readonly />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="phone" class="form-label">ဖုန်းနံပါတ် <font color="red">*</font></label>
                                <input type="text" class="form-control" name="userid" formControlName="phone"
                                    [ngClass]="{ 'is-invalid': submitted && phone.invalid }" required readonly />
                                <div class="invalid-feedback" *ngIf="
                        phone.invalid || ((phone.dirty || phone.touched) && submitted)">
                                    <div *ngIf="phone.errors?.required">{{ requiredMessage }}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-2">
                        <div class="profile-img-container">
                            <div style="position: relative">
                                <div class="uploadfilecontainer">
                                    <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                                        [lazyLoad]="profileUrl ? profileUrl : '../../../assets/images/uploadphoto.png'" />
                                    <input hidden type="file" #fileInput accept="image/*" readonly />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="mt-3">
                    <input style="display: none; cursor: pointer" type="file" (change)="onAttachmentsSelected($event)"
                        #imageinput multiple />
                    <button class="btn btn-custom" (click)="imageinput.click()">Attachments</button>
                </div> -->
                <div *ngIf="selectedAttachments.length > 0" class="mt-3">
                    <h3>Attachments</h3>
                    <ng-container *ngFor="let attachment of selectedAttachments; let index = index;">
                        <div class="image_card mb-2">
                            <div>
                                <img width="50" height="50" [src]="attachment.fileurl" data-bs-toggle="modal"
                                    data-bs-target="#comment-image-viewer" e="cursor: pointer" (click)="viewImage(attachment.fileurl)" />
                                <span class="ms-3">{{ attachment.filename }}</span>
                            </div>
                            <!-- <button mat-icon-button class="icon-button" (click)="removeAttachMent(index)">
                                <mat-icon class="delete-icon">delete</mat-icon>
                            </button> -->
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </form>

    <div class="mt-4">
        <div class="card">
            <div class="card-body relations-card">
                <!-- <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
                    class="chat-container"
                     #chatPS> -->
                <div class="d-flex gap-3 align-items-center mb-2">
                    <span style="font-size: 18px">မိသားစုအသင်းဝင်များ</span>
                </div>
            </div>
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr>
                        <th>စဥ်</th>
                        <th>အမည်</th>
                        <th>တော်စပ်ပုံ</th>
                        <th>မွေးနေ့</th>
                        <th>အလုပ်အကိုင်</th>
                        <th>ကဒ်အမျိုးအစား</th>
                    </tr>
                    <!-- <tr>
            <th>
                ရက်
            </th>
            <th>
                လ
            </th>
            <th>
                ခုနှစ်
            </th>
        </tr> -->
                </thead>
                <tbody *ngIf="relations.length == 0 ">
                    <tr>
                        <td colspan="6" class="nodata">
                            Empty
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="relations.length > 0">
                    <tr *ngFor="let person of relations; index as i; last as l" style="cursor: pointer;">
                        <td (click)="editBmember(i)">
                            {{ i + 1 }}
                        </td>
                        <td (click)="editBmember(i)">
                            {{ person.name }}
                        </td>
                        <td (click)="editBmember(i)">
                            {{ person.relativeType.name }}
                        </td>
                        <td (click)="editBmember(i)">
                            {{ allInOneservice.formatDBToShow(person.birthdate) }}
                        </td>
                        <td (click)="editBmember(i)">
                            {{ person.currentjob }}
                        </td>
                        <td (click)="editBmember(i)">
                            {{ person.memberType.name }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

<app-b-type-member-register *ngIf="status == 'Edit Relative'" [currentMember]="currentMember"
        [status]="'Edit Relative'"
        (closeFormHandler)="closeByRelativeForm()" [relativeInfo]="bmemberInfoToEdit">
    

</app-b-type-member-register>


<div class="modal" id="comment-image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-more-container">
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close"
                        (click)="downloadImage()">
                        <mat-icon>download</mat-icon>
                    </button>
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div>
                    <img [src]="previewurl" alt="" class="image-view" />
                </div>
            </div>
        </div>
    </div>
</div>