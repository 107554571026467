<div class="container-fluid">
  <div class="content">
    <app-team-breadcrumb s_breadcrumb="Requisition Roles"></app-team-breadcrumb>
    <div>
      <div class="d-flex">
        <app-button-loader (click)="saveTypes()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <ng-container *ngIf="!gettingTypes; else loadingTemplate">
        <div class="row mt-3">
          <div class="mb-3 col-md-6">
            <!-- <button class="btn btn-custom mb-3" (click)="chooseType('approve')">
              <i class="fas fa-plus-circle me-2"></i> Posts
            </button> -->

            <div class="mb-3 accordion" id="accordionContainer">
              <div class="accordion-item">
                <h3 class="accordion-header mb-0" [id]="'heading' + 'approvetype'">

                  <button class="accordion-button" type="button">
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        Approval
                      </div>
                      <div>
                        <button class="btn btn-custom" (click)="chooseType('approve', $event)">
                          <i class="fas fa-plus-circle me-2"></i> Posts
                        </button>
                      </div>
                    </div>
                    <div> Count: {{approveTypeList.length}}</div>
                  </button>

                </h3>
                <div [id]="'collapse' + 'approvetype'" class="accordion-collapse collapse show"
                  [attr.aria-labelledby]="'heading' + 'approvetype'">
                  <div class="accordion-body">
                    <ul *ngIf="approveTypeList.length > 0" class="list-group">
                      <div class="profile-container" *ngFor="let typ of approveTypeList; let index = index;">

                        <label
                          class="d-flex align-items-center align-items-center-custom justify-content-center type-container">
                          <div class="profile-item-container">
                            <div class="profile-item">
                              <div class="username" style="cursor: pointer;">
                                &nbsp;<b>{{typ.name}}</b>
                              </div>
                            </div>
                          </div>
                          <mat-icon class="cursor-pointer remove" (click)="remove(approveTypeList, index)"
                            matTooltip="Remove">close</mat-icon>
                        </label>

                      </div>
                    </ul>

                    <div class="no-data" *ngIf="approveTypeList.length == 0">
                      Empty
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="mb-3 col-md-6">
            <!-- <button class="btn btn-custom mb-3" (click)="chooseType('request')">
              <i class="fas fa-plus-circle me-2"></i> Posts
            </button> -->

            <div class="mb-3 accordion" id="accordionContainer">
              <div class="accordion-item">
                <h3 class="accordion-header mb-0" [id]="'heading' + 'requesttype'">
                  <button class="accordion-button" type="button">
                    <div class="d-flex align-items-center justify-content-between">
                      <div>
                        Request
                      </div>
                      <div>
                        <button class="btn btn-custom" (click)="chooseType('request', $event)">
                          <i class="fas fa-plus-circle me-2"></i> Posts
                        </button>
                      </div>
                    </div>
                    <div> Count: {{requestTypeList.length}}</div>
                  </button>
                </h3>
                <div [id]="'collapse' + 'requesttype'" class="accordion-collapse collapse show"
                  [attr.aria-labelledby]="'heading' + 'requesttype'">
                  <div class="accordion-body">
                    <ul *ngIf="requestTypeList.length > 0" class="list-group">
                      <div class="profile-container" *ngFor="let typ of requestTypeList; let index = index;">

                        <div
                          class="d-flex align-items-center align-items-center-custom justify-content-center type-container">
                          <div class="profile-item-container">
                            <div class="profile-item">
                              <div class="username">
                                &nbsp;<b>{{typ.name}}</b>
                              </div>
                            </div>
                          </div>
                          <mat-icon class="cursor-pointer remove" (click)="remove(requestTypeList, index)"
                            matTooltip="Remove">close</mat-icon>
                        </div>

                      </div>
                    </ul>

                    <div class="no-data" *ngIf="requestTypeList.length == 0">
                      Empty
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <ng-template #loadingTemplate>
        <div class="row mt-3">
          <div class="col-6" *ngFor="let i of [1,2]">
            <ng-container>
              <div class="d-flex align-items-center flex-row">
                <div class="me-3">
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                'width.px' : 80, 
                'height.px': 80}"></ngx-skeleton-loader>
                </div>
                <div class="d-flex flex-column">
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                'width.px' : 150, 
                'height.px': 10}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                  'width.px' : 100, 
                  'height.px': 10}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                    'width.px' : 80, 
                    'height.px': 10}"></ngx-skeleton-loader>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</div>