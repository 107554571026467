import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from '../../shared/services/message.service';
import { CookieService } from 'ngx-cookie-service';
import { KunyekService } from '../../shared/services/kunyek.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  signupForm = this.formBuider.group({
    userid: ['', Validators.required],
    isrememberme: true,
  });

  signupFormSubmitted: boolean = false;

  width: number = 1024;
  retry: boolean = false;

  isStillSigningIn: boolean = false;

  nextroute: string = '';

  constructor(
    private formBuider: FormBuilder,
    private router: Router,
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    if (this.allinoneService.getAtoken()) {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    this.nextroute = this.route.snapshot.queryParamMap.get('next') || '';
  }
  onRememberMeChange(e: any) {
    this.signupForm.value.isrememberme = e.target.checked;
  }
  get signupFormControl() {
    return this.signupForm.controls;
  }

  signUp() {
    this.signupFormSubmitted = true;
    if (this.signupForm.invalid) {
      return;
    } else {
      this.isStillSigningIn = true;

      this.kunyekService
        .signin(
          this.allinoneService.checkUserId(
            this.signupForm.value.userid.toString().trim()
          )
        )
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              // if (res.type == "2") {
              //   this.messageService.openSnackBar("User not found", 'warn');
              //   this.isStillSigningIn = false;
              // }
              // else {
              this.allinoneService.setCookie(
                'userid',
                this.allinoneService.checkUserId(this.signupForm.value.userid.trim())
              );
              this.allinoneService.setCookie(
                'kunyek_userid',
                this.allinoneService.checkUserId(this.signupForm.value.userid.trim())
              );
              this.allinoneService.setSession('session', res.session);
              this.allinoneService.setSession('existsuser', res.type);
              this.allinoneService.setCookie('kunyek_username', res.username);
              this.allinoneService.setSession(
                'rememberme',
                this.signupForm.value.isrememberme ? 'TRUE' : 'FALSE'
              );
              this.isStillSigningIn = false;

              if (this.nextroute) {
                this.router.navigateByUrl(`/verify?next=${this.nextroute}`);
              } else {
                this.router.navigateByUrl(`/verify`);
              }
              // }
            } else {
              this.isStillSigningIn = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.isStillSigningIn = false;
          }
        );
    }
  }

  qrLogin() {
    if (this.nextroute) {
      this.router.navigateByUrl(`/qrlogin?next=${this.nextroute}`);
    } else {
      this.router.navigateByUrl('/qrlogin');
    }
  }


}
