import { AttendancesComponent } from './../attendances/attendances.component';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-events-attendance',
  templateUrl: './events-attendance.component.html',
  styleUrls: ['./events-attendance.component.scss']
})
export class EventsAttendanceComponent implements OnInit {
  event: any;
  attendanceList: any = [];
  gettingAttendance: boolean = false;
  dialog: any;
  constructor(public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    public router: Router,
    public location : Location,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EventsAttendanceComponent>) {
    this.event = data.event;
    console.log(this.event);

  }

  ngOnInit(): void {
    if (event) {
      this.getAttendance();
    }
  }
  getAttendance() {
    this.gettingAttendance = true;
    const param = {
      "orgid" : this.event.orgid,
      "domainid" : this.allinoneService.getDomain().domainid,
      "eventid": this.event.eventid
    }
    this.kunyekService.getAttendance(param).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.attendanceList = res.datalist;
      }
      else {
        this.messageService.openSnackBar(res.message, "warn");
      }
      this.gettingAttendance = false;
    }, err => {

      this.gettingAttendance = false;

    });
  }
  viewAttendance(event: any) {
      this.router.navigateByUrl("event/attendance?event=" + event+"&org="+this.event.orgid+"&channel="+this.event.channelid+"&type=2");
      this.dialogRef.close(); // make sure it only closes if the upper async fn succesfully ran!
      
  }

  textAsProfile(name: string) {
    const splitval = name.split(' ').map(name => name[0]).join('');
    return splitval.length > 2 ? splitval.substr(0, 2) : splitval
  }

  close(){
    this.dialogRef.close();
    this.location.replaceState("/events");
  }
}
