<div class="container-fluid">
    <div class="content">
        <app-transportation-breadcrumb *ngIf="!addcarway && !isEditing" s_breadcrumb="Car Way Schedule">
        </app-transportation-breadcrumb>
        <app-transportation-breadcrumb *ngIf="addcarway || isEditing" s_breadcrumb="Car Way Schedule"
            [t_breadcrumb]=" isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()">
        </app-transportation-breadcrumb>


        <!--  (emit1)="cancels(2)"  -->
        <div *ngIf="!addcarway && !isEditing">

            <div class="d-flex flex-wrap">
                <div class="showbtn me-auto  mb-3">
                    <button class="btn btn-custom" (click)="new()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>
                </div>
            </div>
            <div class=" w-100 d-flex flex-wrap me-auto ">

                <div class="input-group me-2 mb-2" style="width: 300px !important;">
                    <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                        [(ngModel)]="searchText" style="font-family: FontAwesome,Ubuntu;">
                </div>
                <div class="dropdown mb-2 me-2">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" matTooltip="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <div c style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==itemPerPage">
                            <label for="pg{{page.name}}" class="col-10 p-1">
                                {{page.name}}
                            </label>
                        </li>
                    </ul>
                </div>

                <!-- me-2 -->
                <div class="me-2 mb-2 ">
                    <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div class="line mb-2 "></div>
                <div class="mb-2 col-md-2">
                    <input type="date" class="form-control" id="searchdate" [(ngModel)]="searchdate" name="searchdate"
                        (change)="searchdateApi()">
                </div>

                <div class="line mb-2 "></div>
                <div class="mb-2">
                    <button type="button" class="btn btn-custom" (click)="downloadSheet()" matTooltip="Export Vehicles">
                        <img class="excel-icon" src="../../../assets/images/excel.png">Export
                    </button>

                </div>
                <div class="line mb-2 "></div>
                <div class="d-flex align-items-center me-auto mb-2 ">
                    <span class="total-count-text ">Total:&nbsp;</span> <span
                        class="total-count-num ">{{carwayList.length}}</span>
                </div>

                <div>

                </div>
            </div>


            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="name">Name</td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="description">Description
                            </td>
                            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="vehicleno">Vehicle</td>
                            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="drivername">Driver name
                            </td>
                            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="driverphone">Driver phone
                            </td>
                            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="startdate">Start Time
                            </td>
                            <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="enddate">End Time</td>
                            <td scope="col" class="d-flex justify-content-end">Action</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="gettingData">
                        <tr>
                            <td colspan="8" class="nodata mb-3">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="gettingData== false">
                        <tr *ngIf="carwayList.length == 0">
                            <td colspan="8" class="nodata">Empty</td>
                        </tr>
                        <tr *ngFor="let item of carwayList | filter : searchText:'carway'">
                            <td class="td-data" (click)="carwayedit(item)" style="cursor: pointer;">
                                <span [title]="item.name">{{item.name}}</span>
                            </td>
                            <td class="td-data" (click)="carwayedit(item)" style="cursor: pointer;">
                                <span [title]="item.description">{{item.description}}</span>
                            </td>
                            <td class="td-data" (click)="carwayedit(item)" style="cursor: pointer;">
                                <span [title]="item.vehicleno">{{item.vehicletype == '002' ? item.vehiclename :
                                    item.vehicleno}}</span>
                            </td>
                            <td class="td-data" (click)="carwayedit(item)" style="cursor: pointer;">
                                <span [title]="item.drivername">{{item.drivername}}</span>
                            </td>
                            <td class="td-data" (click)="carwayedit(item)" style="cursor: pointer;">
                                <span [title]="item.driverphone">{{item.driverphone}}</span>
                            </td>
                            <td class="td-data" (click)="carwayedit(item)" style="cursor: pointer;">
                                <span [title]="item.starttime">{{item.starttime}}</span>
                            </td>
                            <td class="td-data" (click)="carwayedit(item)" style="cursor: pointer;">
                                <span [title]="item.endtime">{{item.endtime}}</span>
                            </td>

                            <td class="d-flex justify-content-end">
                                <button [title]="'Detail'" (click)="goDetail(item)"
                                    class="btn btn-outline-primary me-2">
                                    <i class='fa fa-list-ul'></i>
                                </button>
                                <button [title]="'Edit'" (click)="carwayedit(item)" class="btn btn-outline-primary me-2">
                                    <i class='fa fa-edit'></i>
                                </button>
                                <button class="btn btn-outline-danger" (click)="deletecarway(item)">
                                    <i class='fa fa-trash-o'></i>
                                  </button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="addcarway || isEditing">

            <div class="close-save-button mb-3">
                <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="submitCarWay()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
                </app-button-loader>
            </div>


            <div class="card card-body my-card mb-3 "
                style="padding-left: 1.5rem !important;padding-right: 1.5rem !important;">


                <div class="info">
                    Car Way Info
                </div>
                <div class="d-flex align-items-center mb-4">
                    <div class="form-check  me-5" *ngFor="let item of typelist">
                        <label class="radio-inline"
                            style="font-size: 15px !important;font-weight: bold;cursor: pointer;">
                            <input class="form-check-input" type="radio" name="businesstype" [id]="item.key"
                                #cutype="ngModel" [(ngModel)]="typerequest" [value]="item.key"
                                [checked]="item.key == typerequest" (change)="changevalue($event)">
                            {{item.value}}
                        </label>
                    </div>
                </div>

                <div class="d-flex" *ngIf="typerequest == '002'">
                    <div class="mb-2 me-4 ">
                        <!-- *ngIf="checkgoing" -->
                        <div class="form-check cus">
                            <input class="form-check-input cus" type="checkbox" id="go" name="gp" checked="checked"
                                [(ngModel)]="checkgoing" (change)="checkGoing($event)" [disabled]="goingdis">
                            <label class="form-check-label cus mb-1 ms-1" for="go">
                                Going
                            </label>
                        </div>
                        <!-- *ngIf="!checkgoing" -->
                        <!-- <div class="form-check cus" >

                            <input class="form-check-input cus" type="checkbox" id="going" name="going"
                                (change)="checkGoing($event)">
                            <label class="form-check-label cus mb-1 ms-1" for="going">
                                Going2
                            </label>
                        </div> -->
                    </div>
                    <div class="mb-2 ">
                        <div class="form-check cus">
                            <input class="form-check-input cus" [(ngModel)]="checkreturn" type="checkbox" id="return1"
                                name="return1" (change)="checkReturn($event)" [disabled]="returndis">
                            <label class="form-check-label cus mb-1 ms-1" for="return1">
                                Return
                            </label>
                        </div>
                        <!-- <div class="form-check cus" *ngIf="!checkreturn">
                            <input class="form-check-input cus" type="checkbox" id="return" name="return"
                                (change)="checkReturn($event)">
                            <label class="form-check-label cus mb-1 ms-1" for="return">
                                Return
                            </label>
                        </div> -->
                    </div>

                </div>
                <div class="row ">
                    <!-- col-md-3 -->
                    <div class="mb-3 col-md-6">
                        <label for="carno" class="form-label">Name</label>
                        <input type="text" class="form-control" id="name" name="name" [(ngModel)]="carwayForm.name"
                            #name="ngModel" [ngClass]="{'is-invalid': carwayFormSave && name.invalid}" required>

                        <div class="invalid-feedback"
                            *ngIf="name.invalid || (name.dirty || name.touched) && carwayFormSave">
                            <div *ngIf="name.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3  col-md-6">
                        <label for="drivername" class="form-label">Description</label>
                        <input type="text" class="form-control" id="description" name="description"
                            [(ngModel)]="carwayForm.description" #des="ngModel"
                            [ngClass]="{'is-invalid': carwayFormSave && des.invalid}" required>

                        <div class="invalid-feedback"
                            *ngIf="des.invalid || (des.dirty || des.touched) && carwayFormSave">
                            <div *ngIf="des.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row ">
                    <div class="mb-2 col-md-12">

                        <label for="drivername" class="form-label">Vehicle</label>
                        <ng-select class="role-select" [items]="vehicleList" bindLabel="carno" bindValue="carid"
                            placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="carwayForm.vehicleid"
                            (change)="vehicleSelect($event)"
                            [ngClass]="{ 'service-select-invalid': !carwayForm.vehicleid  && carwayFormSave }">
                        </ng-select>
                        <mat-progress-bar mode="indeterminate" *ngIf="getvehicle"></mat-progress-bar>

                        <div *ngIf="!carwayForm.vehicleid  && carwayFormSave" style="color: #dc3545;">
                            <div>
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row " *ngIf="checkdriver">
                    <div class="mb-2 col-md-12">
                        <label for="drivername" class="form-label">Driver</label>

                        <select class="form-select" id="type" name="type" [(ngModel)]="carwayForm.driverid"
                            (change)="selectcartype($event)">

                            <option *ngFor="let dr of driverList" [value]="dr.driverid">
                                {{ dr.name }}
                            </option>
                        </select>

                        <div *ngIf="!carwayForm.driverid  && carwayFormSave" style="color: #dc3545;">
                            <div>
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="isEditing">
                    <div class="mb-3 col-md-12">
                        <label for="startdate" class="form-label">Date</label>
                        <input type="date" class="form-control" id="startdate" [(ngModel)]="carwayForm.date"
                            name="startdate" disabled>
                    </div>
                </div>
                <div class="row " *ngIf="!isEditing">
                    <div class="mb-3 col-md-6">
                        <label for="startdate" class="form-label">Start Date</label>
                        <input type="date" class="form-control" id="startdate" [(ngModel)]="carwayForm.startdate"
                            name="startdate" (change)="startdatechange()">
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="enddate" class="form-label">End Date</label>
                        <input type="date" class="form-control" id="enddate" [(ngModel)]="carwayForm.enddate"
                            name="enddate" (change)="enddatechange()" [min]="carwayForm.startdate">


                    </div>

                </div>
                <div class="row ">
                    <div class="mb-3 col-md-6">
                        <label for="startdatetime" class="form-label">Start Time</label>
                        <input type="time" class="form-control" id="startdatetime"
                            [(ngModel)]="carwayForm.startdatetime" name="startdatetime">



                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="enddatetime" class="form-label">End Time</label>
                        <input type="time" class="form-control" id="enddatetime" [(ngModel)]="carwayForm.enddatetime"
                            name="enddatetime">


                    </div>

                </div>
                <div class="row">
                    <div class="mb-3">
                        <label for="remark" class="form-label">Remark</label>

                        <textarea class="form-control" id="remark" name="remark" [(ngModel)]="carwayForm.remark"
                            rows="3"></textarea>
                    </div>

                </div>
            </div>
            <div class="card card-body my-card mb-3">
                <div class="loading-container" *ngIf="getCarwayschedule || getpickuppoint || getttingoutstanding"
                    style="height: 300px;">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div *ngIf="!getCarwayschedule && !getpickuppoint && !getttingoutstanding">

                    <div class="info">
                        Car Way Schedule
                    </div>


                </div>

                <div *ngIf="!getCarwayschedule && !getpickuppoint && !getttingoutstanding">
                    <div class="card card-body my-card mb-3" *ngIf="!checkreturn ||  checkreturn && checkgoing ">

                        <div class="info">
                            Pick up points
                        </div>
                        <div class="mb-3" *ngIf="checkdriver">
                            Passenger {{passengercount}} / {{maxpassenger}}
                        </div>

                        <ng-container *ngFor="let pic of pickuptimelist; let i=index; let first=first">
                            <div class="d-flex gap-3  align-items-end mb-2">
                                <!--  flex-fill-->

                                <div class=" flex-fill me-2">
                                    <label class="form-label ">Point</label>
                                    <!-- <input type="time" [(ngModel)]="pic.time" class="form-control" id="time" name="tme"> -->
                                    <!-- <select class="form-select" id="type" name="type" [(ngModel)]="pic.pickuppoint"
                                    (change)="pickupselect($event,i)">

                                    <option *ngFor="let pickup of pickupList" [value]="pickup.pickuppointid">
                                        {{ pickup.name }}
                                    </option>
                                </select> -->

                                    <input type="text" aria-label="Number" class="form-control" [(ngModel)]="pic.name"
                                        (ngModelChange)="pickupchange(i)" [matAutocomplete]="autopickup">
                                    <mat-autocomplete #autopickup="matAutocomplete">
                                        <mat-option *ngFor="let pickup of pickupList | filter : pic.name:'pickuppoint'"
                                            (click)="pickupselect(pickup,i)">
                                            {{ pickup.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>

                                <div class=" flex-fill me-2">
                                    <label class="form-label">Time</label>
                                    <input type="time" [(ngModel)]="pic.time" class="form-control" id="time" name="tme">

                                </div>

                                <div class="" *ngIf="pickuptimelist.length > 1">
                                    <button class="btn btn-outline-custom remove-input-btn" (click)="removePickup(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>

                                </div>
                            </div>
                            <div class="mb-3" *ngIf="pic.name" (click)="showuserlist(pic.pickuppoint,i,'001')"
                                style="color: var(--main-color);cursor: pointer;text-decoration: underline;font-size: 0.8rem;">
                                Passengers - {{ pic.userlist.length }}
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="!getCarwayschedule && !getpickuppoint && !getttingoutstanding">
                            <div class="col-md-3"> <button class="btn btn-custom mb-3" (click)="addpickupdata()">
                                    <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span> </button> </div>
                        </div>
                    </div>


                </div>
                <div *ngIf="!getCarwayschedule && !getpickuppoint && !getttingoutstanding">

                    <div *ngIf="typerequest == '002' ">

                        <div class="card card-body my-card mb-3" *ngIf="!checkgoing ||  checkreturn && checkgoing ">

                            <!-- <div class="loading-container" *ngIf="getdroppoint">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div> -->


                            <div *ngIf="!getdroppoint">
                                <div class="info">
                                    Drop off points
                                </div>
                                <div class="mb-3" *ngIf="checkdriver">
                                    Passenger {{dropoffpassengercount}} / {{maxpassenger}}
                                </div>

                                <ng-container
                                    *ngFor="let dropoff of dropoffpointtimelist; let i=index; let first=first">
                                    <div class="d-flex gap-3 align-items-end mb-2">
                                        <!-- <div class="d-flex gap-3 align-items-end"> -->
                                        <div class="flex-fill me-2">
                                            <label class="form-label text-end">Point</label>
                                            <!-- <select class="form-select" id="type" name="type" [(ngModel)]="dropoff.dropoffpoint"
                                        (change)="dropOffselect($event,i)">

                                        <option *ngFor="let dropup of dropList" [value]="dropup.dropoffpointid">
                                            {{ dropup.name }}
                                        </option>
                                    </select> -->
                                            <input type="text" aria-label="Number" class="form-control"
                                                [(ngModel)]="dropoff.name" (ngModelChange)="dropoffchanget(i)"
                                                [matAutocomplete]="autopickup">
                                            <mat-autocomplete #autopickup="matAutocomplete">
                                                <mat-option *ngFor="let dropup of dropList"
                                                    (onSelectionChange)="dropOffselect(dropup,i)"
                                                    (click)="dropOffselect(dropup,i)">
                                                    {{ dropup.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </div>
                                        <div class="flex-fill me-2">
                                            <label class="form-label">Time</label>
                                            <input type="time" [(ngModel)]="dropoff.time" class="form-control" id="time"
                                                name="time">
                                        </div>
                                        <div class="" *ngIf="dropoffpointtimelist.length > 1">
                                            <button class="btn  btn-outline-custom  remove-input-btn"
                                                (click)="removedropup(i)"> <mat-icon>delete</mat-icon>
                                            </button>

                                        </div>
                                    </div>
                                    <div class="mb-3" *ngIf="dropoff.name"
                                        (click)="showuserlist(dropoff.dropoffpoint,i,'002')"
                                        style="color: var(--main-color);cursor: pointer;text-decoration: underline;font-size: 0.8rem;">
                                        Passengers - {{ dropoff.userlist.length }}
                                    </div>
                                </ng-container>
                            </div>

                            <div class="row" *ngIf="!getdroppoint">
                                <div class="col-md-3"> <button class="btn btn-custom mb-3"
                                        (click)="adddropoffpointdata()">
                                        <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span> </button> </div>
                            </div>
                        </div>


                    </div>
                </div>



            </div>








        </div>






    </div>
</div>