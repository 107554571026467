<div class="row">
    <div class="mb-3 col-md-6">
        <label for="requestdate" class="form-label">Date</label>
        <input class="form-control" type="date" id="requestdate" [(ngModel)]="submitForm.date" name="requestdate"
            [disabled]="submitForm.readonly" max="9999-12-31" [min]="currentDate" />

    </div>
    <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">OT Date</label>
        <input class="form-control" type="date" id="startdate" [(ngModel)]="submitForm.startdate" name="startdate"
            [disabled]="submitForm.readonly" max="9999-12-31"/>

    </div>
    <div class="mb-3 col-md-6">
        <label for="starttime" class="form-label">Start Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="submitForm.starttime" name="starttime"
            [disabled]="submitForm.readonly" />

    </div>
    <!-- <div class="mb-3 col-md-6">
        <label for="enddate" class="form-label">End Date</label>
        <input class="form-control" type="date" id="enddate" [(ngModel)]="submitForm.enddate" name="enddate"
            [disabled]="submitForm.readonly" max="9999-12-31" [min]="submitForm.startdate" />

    </div> -->

    <div class="mb-3 col-md-6">
        <label for="endtime" class="form-label">End Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="submitForm.endtime" name="endtime"
            [disabled]="submitForm.readonly"/>

    </div>

    <div class="mb-3 col-md-12">
        <label for="date" class="form-label">Reason For Off In Lieu</label>
        <textarea class="form-control" id="remark" [(ngModel)]="submitForm.remark" name="remark"
            [disabled]="submitForm.readonly" rows="3"></textarea>
    </div>
    <div>
        <button class="btn btn-custom me-2" (click)="chooseUser()" [disabled]="submitForm.readonly">
            Select Approvers
        </button>
    </div>
    <ng-container *ngIf="approverList.length > 0">
        <div class="col-md-12 mt-3">
            <h3> Selected Approvers ({{ approverList.length }})</h3>
            <div class="list-group">
                <div class="profile-container d-flex col-md-4" *ngFor="let user of approverList">
                    <span>
                        <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                            [lazyLoad]="
                  user.photo || user.image || '../../../assets/images/profile.png'
                " />
                    </span>

                    <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                        <div class="profile-item-container">
                            <div class="profile-item">
                                <div class="username" style="cursor: pointer">
                                    <b>{{ user.username || user.userid }}</b>
                                </div>
                                <div style="color: grey; margin-top: 1px; font-size: 13.5px"
                                    *ngIf="user.username != ''">
                                    {{ user.userid }}
                                </div>
                            </div>
                        </div>
                        <input type="checkbox" class="form-check-input" [id]="user.primarykey"
                            [(ngModel)]="user.checked" [checked]="user.checked" [disabled]="submitForm.readonly" />
                    </label>
                </div>
            </div>
        </div>
    </ng-container>

</div>