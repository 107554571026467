<div class = "main">
    <div class = "title">
        <div class = "subtitle">
            <img src = "../../../assets/images/kunyek.png"  class = "me-3">Server Maintenance
        </div>
        <span>
            We are sorry, the site is currently down for maintenance. We will be back soon!
        </span>
    </div>
    <div class = "image">
        <img src="../../../assets/images/maintenance.jpg">
    </div>
</div>