<div class="container-fluid">
    <div class="content">


        <div>

            <app-crm-breadcrumb s_breadcrumb="Employee" (emit)="cancelFunction()">
            </app-crm-breadcrumb>



            <!--  (emit1)="cancels(2)"  -->
            <div class="d-flex flex-wrap">

                <div class="me-2  all  mb-3">
                    <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="startdatetime"
                        step="1" style="border-color: #87929d;min-width: 100px;">
                </div>
                <div class=" me-2 all  mb-3">
                    <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddatetime"
                        step="1" style="border-color: #87929d;min-width: 100px;">
                </div>
                <div class="mb-3 me-2 " [matMenuTriggerFor]="branchmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton2" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeBranch()">
                        {{selectbranchname != 'branch' ? 'Branch : '+ selectbranchname : 'Branch' }}
                    </button>
                </div>

                <mat-menu #branchmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search with Employee ID/Name"
                            [(ngModel)]="branchsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeBranch('branch')">-</button>
                    <!-- | crmFilter : branchsearch:text1:text2:25; -->
                    <button class="mat-item"
                        *ngFor="let branch of branchlist  | crmFilter : branchsearch:text1:text2:27;"
                        (click)="changeBranch(branch)">{{branch}}</button>
                </mat-menu>



                <div class="mb-3 me-2 " [matMenuTriggerFor]="projectmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeProJect()">
                        {{projectname != 'project' ? 'Project : ' + projectname : 'Project' }}
                    </button>
                </div>
                <mat-menu #projectmenu="matMenu" xPosition="before" class="menuproject">
                    <div class="input-group cl-project-new  mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search " [(ngModel)]="projectsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeproject('project')">-</button>
                    <button class="mat-item"
                        *ngFor="let proj of projectListdata | crmFilter : projectsearch:text1:text2:14;"
                        (click)="changeproject(proj)">{{proj.name}}</button>
                </mat-menu>
                <div class="mb-3 me-2 " [matMenuTriggerFor]="productmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeProduct()">
                        {{productname != 'product' ? 'Product : '+ productname : 'Product' }}
                    </button>
                </div>

                <mat-menu #productmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="productsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changeproduct('product')">-</button>
                    <button class="mat-item"
                        *ngFor="let proj of productListdata | crmFilter : productsearch:text1:text2:15;"
                        (click)="changeproduct(proj)">{{proj.name}}</button>
                </mat-menu>

                <div class="mb-3 me-2 " [matMenuTriggerFor]="modulemenu" *ngIf="modulecheck">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeProduct()">
                        {{modulename != 'module' ? 'Module : '+ modulename : 'Module' }}
                    </button>
                </div>

                <mat-menu #modulemenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="modulesearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <!-- | crmFilter : modulesearch:text1:text2:15; -->
                    <button class="mat-item" (click)="changemodule('module')">-</button>
                    <button class="mat-item" *ngFor="let module of modulelist"
                        (click)="changemodule(module)">{{module.description}}</button>
                </mat-menu>


                <div class="mb-3 me-2 " [matMenuTriggerFor]="deparmentmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeDeparment()">
                        {{deparmentname != 'deparment' ? 'Deparment : ' + deparmentname : 'Deparment' }}
                    </button>
                </div>

                <mat-menu #deparmentmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="deparmentsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changedeparment('deparment')">-</button>
                    <!-- | crmFilter : deparmentsearch:text1:text2:15; -->
                    <button class="mat-item"
                        *ngFor="let dep of deparmentListdata | crmFilter : deparmentsearch:text1:text2:27;"
                        (click)="changedeparment(dep)">{{dep}}</button>
                </mat-menu>

                <div class="mb-3 me-2 " [matMenuTriggerFor]="divisionmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeDeparment()">
                        {{divisionname != 'division' ? 'Division : ' + divisionname : 'Division' }}
                    </button>
                </div>

                <mat-menu #divisionmenu="matMenu" xPosition="before">
                    <div class="input-group cl-project-new me-2 mb-3" (click)="$event.stopPropagation();">
                        <input class="clas-project" type="text" placeholder="Search" [(ngModel)]="divisionsearch"
                            style="font-family: FontAwesome,Ubuntu;border-radius: none !important;border: none;"
                            autocomplete="off">
                    </div>
                    <button class="mat-item" (click)="changedivision('division')">-</button>
                    <!-- | crmFilter : divisionsearch:text1:text2:15; -->
                    <button class="mat-item"
                        *ngFor="let dep of divisionlistdata | crmFilter : divisionsearch:text1:text2:27;"
                        (click)="changedivision(dep)">{{dep}}</button>
                </mat-menu>

                <div class="mb-3 me-2 " [matMenuTriggerFor]="statusmenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;"
                        (click)="removeDeparment()">
                        {{statusname != 'status' ? 'Status : ' + statusname : 'Status' }}
                    </button>
                </div>

                <mat-menu #statusmenu="matMenu" xPosition="before">

                    <button class="mat-item" (click)="changestatus('all')">All</button>
                    <button class="mat-item" (click)="changestatus('active')">Active</button>
                    <button class="mat-item" (click)="changestatus('inactive')">Inactive</button>
                    <button class="mat-item" (click)="changestatus('blacklist')">Blacklist</button>

                </mat-menu>



            </div>
            <div class=" d-flex flex-wrap ">
                <div class="w-100 d-flex flex-wrap ">


                    <div class=" w-100 d-flex flex-wrap me-auto ">

                        <div class="input-group me-2 mb-3" style="width: 300px !important;">
                            <input class="form-control" type="text" placeholder="&#xf002; Search With Employee ID/Name"
                                aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
                                [(ngModel)]="searchText" [ngClass]="{'c-search-input-with-clear' : searchText }"
                                style="font-family: FontAwesome,Ubuntu;">
                            <button class="btn btn-outline-secondary " type="button" *ngIf="searchText"
                                (click)="clear()" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                                <mat-icon class="c-icon">close</mat-icon>
                            </button>
                        </div>
                        <div class="dropdown mb-3 me-2">
                            <button type="button" class="btn btn-custom" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div c style="text-align: center;">Page size</div>
                                </li>
                                <li>
                                    <hr class="dropdown-divider">
                                </li>
                                <li class="dropdown-item" *ngFor="let page of pg">
                                    <input type="radio" name="pg-count" value="{{page.count}}"
                                        (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                        [checked]="page.count==itemPerPage">
                                    <label for="pg{{page.name}}" class="col-10 p-1">
                                        {{page.name}}
                                    </label>
                                </li>
                            </ul>
                        </div>

                        <!--  -->
                        <div class=" mb-3">
                            <button class="btn btn-custom" (click)="refreshgetData()" title="Refresh">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </div>


                        <div class="line mb-3"></div>
                        <div class="mb-3 me-2">
                            <button type="button" class="btn btn-custom" (click)="downloadSheetLead()"
                                title="Export Leads To Excel">
                                <img class="excel-icon" src="../../../assets/images/excel.png">
                                Export
                            </button>
                        </div>


                        <div class="line mb-3 "></div>
                        <div class="d-flex align-items-center me-auto mb-3">
                            <span class="total-count-text">Total:&nbsp;</span> <span
                                class="total-count-num">{{(detailListdata | crmFilter : searchText:text1:text2:26).length }}</span>
                        </div>

                        <div *ngIf="!gettingData" class="d-flex align-items-center mb-3   ">
                            <pagination-controls class="paginate" (pageChange)="currentPage = $event"
                            *ngIf="(detailListdata | crmFilter : searchText:text1:text2:26).length > itemPerPage "
                            previousLabel="Prev" nextLabel="Next">
                        </pagination-controls>
                        </div>

                        <div>

                        </div>
                    </div>
                </div>

            </div>



            <div>
                <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                    <table class="table table-responsive table-borderless">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="" mat-sort-header="employeeid">Employee ID
                                </td>
                                <td scope="col" class="" mat-sort-header="username">Name</td>
                                <!-- <td scope="col" class="" mat-sort-header="post">Post/Rank</td> -->
                                <td scope="col" class="" mat-sort-header="project">Project</td>
                                <td scope="col" class="" mat-sort-header="product">Product</td>
                                <!-- <td scope="col" class="" mat-sort-header="module">Module</td> -->
                                <td scope="col" class="" mat-sort-header="right">Right
                                </td>
                                <td scope="col" class="" mat-sort-header="deparment">Department
                                </td>
                                <td scope="col" class="" mat-sort-header="division">Division
                                </td>
                                <!-- <td scope="col" class="" mat-sort-header="branch">Branch
                                </td>
                                <td scope="col" class="" mat-sort-header="employmentype">Employment
                                    Type
                                </td> -->

                            </tr>
                        </thead>
                        <tbody *ngIf="gettingData">
                            <tr>
                                <td colspan="11" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="gettingData== false">
                            <tr *ngIf="detailListdata.length == 0">
                                <td colspan="11" class="nodata">Empty</td>
                                <!-- <td style="color: red;font-size: 14px;" colspan="14" class="nodata" *ngIf="leadview">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"
                                        style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}
                                </td> -->
                            </tr>
                            <tr
                                *ngFor="let item of detailListdata | crmFilter : searchText:text1:text2:26 | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index">
                                <td class="td-data td-edit">
                                    <span [title]="item.employeeid">{{item.employeeid}}</span>
                                </td>
                                <td class="td-data">
                                    <span [title]="item.username">{{item.username}}</span>
                                </td>
                                <!-- <td class="td-data td-edit">
                                    <span [title]="item.rank">{{item.rank}}</span>
                                </td> -->
                                <td class="td-data">
                                    <span [title]="item.project">{{item.project}}</span>
                                </td>
                                <td class="td-data td-edit">
                                    <span [title]="item.product">{{item.product}}</span>
                                </td>
                                <!-- <td class="td-data">
                                    <span [title]="item.module">{{item.module}}</span>
                                </td> -->
                                <td class="td-data td-edit">
                                    <span [title]="item.right">{{item.right}}</span>
                                </td>

                                <td class="td-data">
                                    <span [title]="item.department">{{item.department}}</span>
                                </td>
                                <td class="td-data td-edit">
                                    <span [title]="item.division">{{item.division}}</span>
                                </td>
                                <!-- <td class="td-data">
                                    <span [title]="item.branch">{{item.branch}}</span>
                                </td>
                                <td class="td-data td-edit">
                                    <span [title]="item.employmenttype">{{item.employmenttype}}</span>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>



            <div *ngIf="!gettingData  ">
                <pagination-controls class="paginate" (pageChange)="currentPage = $event"
                *ngIf="(detailListdata | crmFilter : searchText:text1:text2:26).length > itemPerPage "
                previousLabel="Prev" nextLabel="Next">
            </pagination-controls>

            </div>
        </div>



    </div>


</div>