<div class="container-fluid">
  <div class="content">
    <app-breadcrumb [s_breadcrumb]="'Logistics'" [t_breadcrumb]="'Order'" (emit)="goToLogistics()">
    </app-breadcrumb>

    <ng-container *ngIf="!isOrderNew">
      <div class="d-flex justify-content-between">
        <div class="mb-3">
          <button class="btn btn-custom me-auto" (click)="newOrder()">
            <i class="fa fa-plus me-1"></i> New
          </button>
        </div>
        <div class="mb-3">
          <div class="align-items-center" [matMenuTriggerFor]="statusmenu">
            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
              mdbDropdownToggle style="
                background-color: #87929d;
                border-color: #87929d;
                color: white;
                min-width: 100px;
              ">
              Status&nbsp;:&nbsp;{{ statusname }}
            </button>
          </div>
          <mat-menu #statusmenu="matMenu" id="service-menu" xPosition="before">
            <button mat-menu-item *ngFor="let stu of statusList" (click)="statusChange(stu)">
              {{ stu.name }}
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex flex-wrap justify-content-start align-items-center flex-wrap">
          <div class="input-group mb-3" style="width: 300px !important">
            <input class="form-control" type="text" placeholder="&#xf002; Search with ID"
              (ngModelChange)="searchTextChange()" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
              [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }"
              style="font-family: FontAwesome, Ubuntu" />
            <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
              [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>
          <div class="ms-2 mb-3">
            <button class="btn btn-custom" (click)="refresh()" title="Refresh">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <div class="mb-3">
            <div class="line"></div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="total-count-text">Total:&nbsp;</span>
            <span class="total-count-num">{{ orders.length }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3">
          <pagination-template *ngIf="orders.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
            <div class="d-flex align-items-center justify-content-center">
              <div [class]="
                  p.isFirstPage()
                    ? 'custom-pagination-disabled'
                    : 'custom-pagination'
                " (click)="p.previous()">
                <span class="me-1">&laquo;</span>&nbsp;Prev
              </div>
              <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                  <span>{{ page.label }}</span>
                </span>
                <div class="active-page" *ngIf="p.getCurrent() === page.value">
                  <span>{{ page.label }}</span>
                </div>
              </div>
              <div [class]="
                  nextDisabled
                    ? 'custom-pagination-disabled'
                    : 'custom-pagination'
                " (click)="p.next()">
                Next&nbsp;<span class="ms-1">&raquo;</span>
              </div>
            </div>
          </pagination-template>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isOrderNew">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()" *ngIf="!isLoading">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitOrder()" [button_text]="'Save'"
          [load_button_text]="'Saving'" [isLoading]="isLoading">
        </app-button-loader>
        <ng-container *ngIf="isEditing && !this.orderForm.assign && this.orderForm.qrdata != ''">
          <div class = "mb-3">
            <div class = "line">
            </div>
          </div>
          <button type="button" class="btn btn-custom mb-3" (click)="viewQR()">
            QR
          </button>
        </ng-container>
      </div>
      <div class="row">
        <div class="mb-3 col-md-12">
          <label for="orderid" class="form-label"> Order ID </label>
          <input type="text" class="form-control" id="orderid" name="orderid" [(ngModel)]="orderForm.orderid" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="desc" class="form-label">Description</label>
          <textarea class="form-control" id="desc" [(ngModel)]="orderForm.desc" name="desc" rows="3" #desc="ngModel"
            [ngClass]="{ 'is-invalid': orderFormSubmitted && desc.invalid }" required>
          </textarea>
          <div class="invalid-feedback" *ngIf="
              desc.invalid ||
              ((desc.dirty || desc.touched) && orderFormSubmitted)
            ">
            <div *ngIf="desc.errors?.required">
              {{ allinoneService.input_err_msg }}
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="remark" class="form-label">Remark</label>
          <textarea class="form-control" id="remark" [(ngModel)]="orderForm.remark" name="remark" rows="3"></textarea>
        </div>
        <div class="mb-3 col-md-6">
          <label for="cname" class="form-label">
            Customer Name (<span class="cc-label" (click)="chooseCustomer('customer')">Choose Customer</span>)</label>
          <input type="text" class="form-control" id="cname" name="cname" [(ngModel)]="orderForm.customername" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="cmobile" class="form-label"> Customer Mobile/Email</label>
          <input type="text" class="form-control" id="cmobile" name="cmobile" [(ngModel)]="orderForm.customermobile" />
        </div>
        <div class="mb-3 col-md-12">
          <input class="form-check-input" type="checkbox" id="same" [(ngModel)]="orderForm.recipentcustomersame"
            name="same">
          <label class="form-check-label ms-2" for="same">
            Same as Customer
          </label>
        </div>
        <ng-container *ngIf="!orderForm.recipentcustomersame">
          <div class="mb-3 col-md-6">
            <label for="rname" class="form-label">
              Recipient Name (<span class="cc-label" (click)="chooseCustomer('recipient')">Choose
                Recipient</span>)</label>
            <input type="text" class="form-control" id="rname" name="rname" [(ngModel)]="orderForm.recipientname" />
          </div>
          <div class="mb-3 col-md-6">
            <label for="rmobile" class="form-label">
              Recipient Mobile/Email</label>
            <input type="text" class="form-control" id="rmobile" name="rmobile"
              [(ngModel)]="orderForm.recipientmobile" />
          </div>
        </ng-container>
        <div class="mb-3 col-md-4">
          <label for="fromaddress" class="form-label"> From (<span class="cc-label" (click)="pickLocation('from')">Pick
              Location</span>)</label>
          <input type="text" placeholder="Address" class="form-control" id="fromaddress" name="fromaddress"
            [(ngModel)]="orderForm.fromaddress" />
        </div>
        <div class="mb-3 col-md-4 col-sm-6 col-6">
          <label for="fromlat" class="form-label" style="height: 15px;" *ngIf="!isMobileView"> </label>
          <input type="number" placeholder="Latitude" class="form-control" id="fromlat" name="fromlat"
            [(ngModel)]="orderForm.fromlat" />
        </div>
        <div class="mb-3 col-md-4 col-sm-6 col-6">
          <label for="fromlng" class="form-label" style="height: 15px;" *ngIf="!isMobileView"> </label>
          <input type="number" placeholder="Longitude" class="form-control" id="fromlng" name="fromlng"
            [(ngModel)]="orderForm.fromlng" />
        </div>
        <div class="mb-3 col-md-4">
          <label for="toaddress" class="form-label"> To (<span class="cc-label" (click)="pickLocation('to')">Pick
              Location</span>)</label>
          <input type="text" placeholder="Address" class="form-control" id="toaddress" name="toaddress"
            [(ngModel)]="orderForm.toaddress" />
        </div>
        <div class="mb-3 col-md-4 col-sm-6 col-6">
          <label for="tolat" class="form-label" style="height: 15px;" *ngIf="!isMobileView"> </label>
          <input type="number" placeholder="Latitude" class="form-control" id="tolat" name="tolat"
            [(ngModel)]="orderForm.tolat" />
        </div>
        <div class="mb-3 col-md-4 col-sm-6 col-6">
          <label for="tolng" class="form-label" style="height: 15px;" *ngIf="!isMobileView"> </label>
          <input type="number" placeholder="Longitude" class="form-control" id="tolng" name="tolng"
            [(ngModel)]="orderForm.tolng" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="startdate" class="form-label"> Start Date </label>
          <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="orderForm.startdate" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="starttime" class="form-label"> Start Time </label>
          <input type="time" class="form-control" id="starttime" name="starttime" [(ngModel)]="orderForm.starttime" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="enddate" class="form-label"> End Date </label>
          <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="orderForm.enddate" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="endtime" class="form-label"> End Time </label>
          <input type="time" class="form-control" id="endtime" name="endtime" [(ngModel)]="orderForm.endtime" />
        </div>
        <div class="mb-3 col-md-12" *ngIf="isEditing">
          <label for="status" class="form-label"> Status </label>
          <select class="form-select" [(ngModel)]="orderForm.status">
            <ng-container *ngFor="let item of statusList">
              <option *ngIf="item.value != ''" [value]="item.value">{{item.name}}</option>
            </ng-container>
          </select>
        </div>
        <div class="mb-3 col-md-12">
          <input hidden type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput multiple>
          <button class="btn btn-custom" (click)="hiddenfileinput.click()">
            <i class="far fa-images me-2"></i> Choose Pictures
          </button>
        </div>
        <div class="col-md-12 row">
          <div class="img-container col-lg-3 col-md-4 col-sm-4 col-6" *ngFor="let item of imageList; let index = index">
            <div class="img-border card">
              <img [src]="item.previewurl">
              <mat-icon class="close" (click)="removeImage(index)">close</mat-icon>
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="advance" class="form-label"> Advance </label>
          <input type="text" class="form-control" id="advance" name="advance" [(ngModel)]="orderForm.advance" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="collection" class="form-label"> Collection </label>
          <input type="text" class="form-control" id="collection" name="collection"
            [(ngModel)]="orderForm.collection" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="fees" class="form-label"> Fees </label>
          <input type="text" class="form-control" id="fees" name="fees" [(ngModel)]="orderForm.fees" />
        </div>
        <div class="mb-3 col-md-6">
          <label for="settlement" class="form-label"> Settlement </label>
          <input type="text" class="form-control" id="settlement" name="settlement"
            [(ngModel)]="orderForm.settlement" />
        </div>
      </div>
    </ng-container>

    <div *ngIf="!isOrderNew">
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" mat-sort-header="image"></td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="id">
                ID
              </td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="description">
                Description
              </td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="customer">
                Customer
              </td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="status">
                Status
              </td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="gettingOrder">
            <tr>
              <td colspan="6" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="gettingOrder == false">
            <tr *ngIf="orders.length == 0">
              <td colspan="6" class="nodata">Empty</td>
            </tr>
            <tr *ngFor="
                let item of orders
                  | paginate
                    : { itemsPerPage: itemPerPage, currentPage: currentPage };
                let index = index
              ">
              <td class="td-data td-edit td-image" (click)="editOrder(item)">
                <div class="position-relative d-flex justify-content-center">
                  <img class="preivew-image" [defaultImage]="'../../../assets/images/order_box.png'" [lazyLoad]="
                      item.image.imgurl.length > 0
                        ? item.image.imgurl[0]
                        : '../../../assets/images/order_box.png'
                    " />
                  <span class="position-absolute top-0 translate-middle bg-danger border border-light rounded-circle"
                    style="padding: 5px; left: 90%" *ngIf="!item.complete">
                  </span>
                  <span class="position-absolute top-0 translate-middle bg-success border border-light rounded-circle"
                    style="padding: 5px; left: 90%" *ngIf="item.complete">
                  </span>
                </div>
              </td>
              <td class="td-data td-edit" (click)="editOrder(item)">
                <span [title]="item.orderno">{{ item.orderno }}</span>
              </td>
              <td class="td-data td-edit" (click)="editOrder(item)">
                <span [title]="item.desc">{{ item.desc }}</span>
              </td>
              <td class="td-data">
                <span [title]="item.cuname">{{ item.cuname || "-" }}</span>
              </td>
              <td class="td-data">
                <ng-container *ngIf="
                    item.status == '001' ||
                    item.status == '005' ||
                    item.status == '006'
                  ">
                  <span [title]="item.statusName" class="status status-green">{{
                    item.statusName
                    }}</span>
                </ng-container>
                <ng-container *ngIf="item.status == '002'">
                  <span [title]="item.statusName" class="status status-gray">{{
                    item.statusName
                    }}</span>
                </ng-container>
                <ng-container *ngIf="item.status == '003' || item.status == '004'">
                  <span [title]="item.statusName" class="status status-main">{{
                    item.statusName
                    }}</span>
                </ng-container>
                <ng-container *ngIf="item.assign">
                  <span title="Assign" class="status status-assign ms-2">Assigned</span>
                </ng-container>
              </td>
              <td>
                <button class="btn btn-outline-primary me-2" (click)="viewQR(item)" title="QR" [disabled]="item.assign || item.qrdata == ''">
                  <i class="fas fa-qrcode"></i>
                </button>
                <button class="btn btn-outline-primary me-2" (click)="editOrder(item)" title="Edit">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deleteOrder(item)" title="Delete">
                  <i class='fa fa-trash-o'></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>