import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() q!:string
  @Input() currentPage!:number
  @Input() totalPages!:number
  @Input() url!:string[]
  @Input() filterState!:any
  previousPage!:number
  nextPage!:number

  constructor(
    private router:Router,
    private route:ActivatedRoute,
  ) { }
  ngOnInit(): void {
  this.previousPage = this.currentPage - 1
  this.nextPage = this.currentPage + 1
  }

  next(){
    this.router.navigate(this.url,{queryParams:{page:this.nextPage,q:this.q,filterState:this.filterState}})
  }
  previous(){
    this.router.navigate(this.url,{queryParams:{page:this.previousPage,q:this.q,filterState:this.filterState}})
  }
}
