<div class="container-fluid">
    <div class="content">


        <app-breadcrumb [s_breadcrumb]="'Check In Attendances'">
        </app-breadcrumb>

        <div class="mt-2 mb-2 d-flex ">
            <div>
                <app-button-loader  [button_text]="'Search'" [load_button_text]="'Searching'"
                    [isLoading]="searchLoading" (click)="search()">
                </app-button-loader>
                
               
            </div>
            <div class = "line"></div>
            <div>
                <button type="button" class="btn btn-custom " (click)="downloadSheet()" [disabled]="sortedArray.length==0"
                >
                <img class="excel-icon" src="../../../assets/images/excel.png">
                Export To Excel
            </button>
            </div>



        </div>
        <div>
            <div class="row d-flex">
                <div class="col-md-3 mb-3">
                    <label for="organization" class="form-label">Organization</label>
                    <select class="form-select" aria-label="Select Organization" id="organization" name="organization"
                        (change)="orgChanged($event)" [(ngModel)]="getcheckinForm.org" required #org="ngModel">
                        <option *ngFor="let org of organizations" [value]="org.orgid">{{org.name}}</option>
                    </select>
                </div>
                <div class="col-md-3 mb-3">
                    <label for="userid" class="form-label">
                        User ID
                    </label>
                    <input type="text" class="form-control" id="userid" name="userid" placeholder="Enter userid"
                        [(ngModel)]="getcheckinForm.userid" required #userid="ngModel">
                </div>

                <div class="col-md-3 mb-3">
                    <label for="startdate" class="form-label">Start Date</label>
                    <input type="date" class="form-control" id="startdate" name="startdate"
                        [(ngModel)]="getcheckinForm.startdate" required #startdate="ngModel" max="9999-12-31" step="1">

                    <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) ">
                        <div *ngIf="startdate.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>

                <div class="col-md-3 mb-3">
                    <label for="enddate" class="form-label">End Date</label>
                    <input type="date" class="form-control" id="enddate" name="enddate"
                        [(ngModel)]="getcheckinForm.enddate" required #enddate="ngModel"
                        [min]="getcheckinForm.startdate" max="9999-12-31" step="1">

                    <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched)">
                        <div *ngIf="enddate.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="sortedArray.length>0">
                <div *ngFor="let user of sortedArray;let j=index;">
                    <div class="d-flex justify-content-between align-items-center user-title" (click)="hideMemberCheckIn(j)" style="cursor: pointer;border-bottom: 1px solid white;">
                        <div>
                            {{user.username}}
                            ({{user.userid}})

                        </div>
                        <div class = "d-flex" style="margin-right: 6px;"> 
                            <mat-icon *ngIf = "!user.show">keyboard_arrow_right</mat-icon>
                            <mat-icon *ngIf = "user.show">keyboard_arrow_down</mat-icon>
                        </div>

                    </div>

                    <div class="d-flex" *ngIf="user.show">
                        <div>
                            <div class="user-waterfall d-flex">
                                <div class="user-waterfall-item ">
                                </div>

                            </div>
                        </div>
                        <div class="flex-fill">
                            <div *ngFor="let x of user.datalist;let i=index;" class=" checkin-box">
                                <div class="waterfall">
                                    <div class="user-waterfall-dot">

                                    </div>
                                </div>

                                <div class="d-flex justify-content-between" style="background-color: #a3a2a2;" (click)="hide(j,i)">

                                    <div class="float-start ms-2 p-1" style="color: white;">
                                        {{x.showdate}}
                                    </div>
                                    <div class="float-end  p-1 me-3">
                                        <i *ngIf="x.show" class="fa fa-caret-down" aria-hidden="true"
                                            style="color:white;cursor: pointer;font-size: 1.3em;"
                                            ></i>
                                        <i *ngIf="!x.show" class="fa fa-caret-right" aria-hidden="true"
                                            style="color:white;cursor: pointer;font-size: 1.3em;"
                                            ></i>

                                    </div>
                                </div>
                                <div *ngIf="x.data.length == 0" class="row">
                                    <div class="nodata emp">Empty</div>
                                </div>
                                <div *ngFor="let y of x.data;">
                                    <div class="p-2 checkinList d-flex justify-content-between" *ngIf="x.show"
                                        style="cursor: pointer;">
                                        <div class="d-flex ">

                                            <!-- <div class="float-start col-1" (click)="showActivity(y,y.iotype)"> -->
                                            <div class="me-3">

                                                <i class="fa fa-circle" aria-hidden="true" style="color: red;"
                                                    *ngIf="y.iotype=='out'"></i>
                                                <i class="fa fa-circle" aria-hidden="true" style="color: green;"
                                                    *ngIf="y.iotype=='in'"></i>
                                                <i class="fa fa-circle" aria-hidden="true" style="color: grey;"
                                                    *ngIf="y.iotype=='checkin'"></i>
                                                <i class="fa fa-qrcode" aria-hidden="true" style="color: black;"
                                                    *ngIf="y.iotype=='event'"></i>
                                                <i class="fa fa-calendar" aria-hidden="true" style="color: black;"
                                                    *ngIf="y.iotype=='activity'"></i>
                                                <div class="waterfall d-flex" *ngIf="y.child.length>0">
                                                    <div class="waterfall-item">
                                                    </div>

                                                </div>
                                            </div>



                                            <div *ngIf="y.iotype=='checkin' || y.iotype=='in' || y.iotype=='out'"
                                                class="">
                                                <div class="d-flex flex-column">
                                                    <div class="d-flex checkin-items">
                                                        <div class="me-4 d-flex justify-content-between checkin-items"
                                                            style="min-width: 40vw;">
                                                            <div class="d-flex flex-column">
                                                                <div>
                                                                    <b>{{allinoneService.formatTimeToShow(y.starttime)}} </b> <small
                                                                        style="color: #9c9c9c;"
                                                                        *ngIf="y.typename!='Check In'">{{y.typename}}</small>

                                                                </div>
                                                                <div *ngIf="y.orgid!=''" class="my-1">
                                                                    <span class="orgname p-1 ">
                                                                        {{y.orgname}}

                                                                    </span>
                                                                </div>
                                                                <div *ngIf="y.taskid!=''">
                                                                    Task ID: {{y.autoid}} - {{y.taskdescription}}
                                                                </div>


                                                            </div>

                                                            <div class="">
                                                                <div style="color: #9c9c9c;" *ngIf="y.location==''">
                                                                    @{{y.lat}} - {{y.long}}
                                                                </div>
                                                                <div *ngIf="y.location!=''">
                                                                    @{{y.location}}<br> <span
                                                                        style="color: #9c9c9c;">({{y.lat}} -
                                                                        {{y.long}})</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="" style="min-width: 15vw;">
                                                            <div
                                                                [innerHTML]="allinoneService.replaceEnter(y.description)">

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="y.child.length>0" class="endbox">
                                                        <div class="connect-waterfall d-flex" *ngIf="y.child.length>0">
                                                            <div class="connect-waterfall-item">
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <b>{{y.child[0].starttime}}</b>
                                                            [
                                                            <small>
                                                                {{calEndTime(y.date,y.starttime,y.child[0].date,y.child[0].starttime)}}

                                                            </small>]
                                                        </div>

                                                    </div>
                                                    <div *ngIf="y.timeoutduration.length>0">

                                                        [
                                                        <small>
                                                            {{calDuration(y.timeoutduration)}}

                                                        </small>]

                                                    </div>

                                                </div>
                                                <!-- class="float-start col-10"> -->


                                            </div>
                                            <div *ngIf="y.iotype=='event'" class="d-flex checkin-items">
                                                <div class="d-flex checkin-items justify-content-between me-4"
                                                    style="min-width: 40vw;">



                                                    <div class="d-flex flex-column">
                                                        <div class="event-date">
                                                            <b>{{y.eventname}}</b><br>
                                                            <b>{{y.eventstartdate | date:"dd/MM/yyyy hh:mm a" }} -
                                                                {{y.eventenddate |
                                                                date:"dd/MM/yyyy hh:mm a" }}</b>

                                                        </div>
                                                        <div *ngIf="y.orgid!=''" class="my-1">
                                                            <span class="orgname p-1 ">
                                                                {{y.orgname}}

                                                            </span>
                                                        </div>
                                                        <div *ngIf="y.taskid!=''">
                                                            Task ID: {{y.autoid}} - {{y.taskdescription}}
                                                        </div>


                                                    </div>


                                                    <div style="min-width: 10.7rem;">
                                                        <div style="color: #9c9c9c;" *ngIf="y.eventlocation==''">
                                                            @{{y.eventlat}} - {{y.eventlong}}
                                                        </div>
                                                        <div *ngIf="y.eventlocation!=''">
                                                            @{{y.eventlocation}} <br>
                                                            <!-- <span style="color: #9c9c9c;">{{y.eventlat}} -
                                                                {{y.eventlong}}</span> -->
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="" style="min-width: 15vw;">
                                                    <div [innerHTML]="allinoneService.replaceEnter(y.eventdescription)">

                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="y.iotype=='activity'" class="d-flex checkin-items">
                                                <div class="d-flex checkin-items " style="min-width: 42.4vw;">
                                                    <div class="d-flex flex-column">
                                                        <div>
                                                            <!-- <b>{{y.eventname}}</b> -->
                                                            <b>{{allinoneService.formatTimeToShow(y.starttime)}} - {{allinoneService.formatTimeToShow(y.endtime)}}</b>

                                                        </div>
                                                        <div *ngIf="y.orgid!=''" class="my-1">
                                                            <span class="orgname p-1 ">
                                                                {{y.orgname}}

                                                            </span>
                                                        </div>
                                                        <div *ngIf="y.taskid!=''">
                                                            Task ID: {{y.autoid}} - {{y.taskdescription}}
                                                        </div>

                                                    </div>
                                                    <!-- <div class="col-7 float-start">
                                                <div style="color: #9c9c9c;" class="ms-3" *ngIf="y.location==''">
                                                    @{{y.lat}} - {{y.long}}
                                                </div>
                                                <div style="color: #9c9c9c;" class="ms-3" *ngIf="y.location!=''">
                                                    @{{y.location}} ({{y.lat}} - {{y.long}})
                                                </div>
                                            </div> -->

                                                </div>
                                                <div class="pe-3">
                                                    {{y.description}}
                                                </div>
                                            </div>

                                        </div>
                                        <!-- <div>
                                            <div class="d-flex justify-content-center edbutton">
                                                <button class="btn btn-outline-primary me-3" *ngIf="y.iotype=='activity'"
                                                    (click)="showActivity(y,y.iotype)">
                                                    <i class='fa fa-edit'></i>
                                                </button>
                                                <button class="btn btn-outline-danger" *ngIf="y.iotype=='activity'"
                                                    (click)="deleteCheckin(y.syskey)">
                                                    <i class='fa fa-trash-o'></i>
                                                </button>
                                            </div>
                                        </div> -->


                                        <!-- <div class="float-end col-1" *ngIf="y.iotype=='activity'"  style="cursor: pointer;"  (click)="showActivity(y,y.iotype)">
                                        <i class="fa fa-chevron-right" aria-hidden="true"></i>
            
                                    </div> -->
                                    </div>

                                </div>


                            </div>
                        </div>


                    </div>



                </div>


            </div>
            <div *ngIf="sortedArray.length==0">
                <hr>
                <div class="d-flex m-auto p-4" style="justify-content: center;">
                    Empty
                </div>
            </div>
        </div>
    </div>
</div>