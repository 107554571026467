<div class="fix">
    <div class="title-container mb-3">
        <!-- <div class="back-btn">
            <button mat-icon-button (click)="closeCustomAnswer()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div> -->
        <div class="back-btn">
            <button mat-icon-button (click)="close()" [disabled]="saveLoading">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="title" *ngIf="!checkEdit">
            Candidates
        </div>
        <div class="title" *ngIf="checkEdit">
            Edit Candidates
        </div>
        <div class="close-btn">
            <button mat-icon-button (click)="finishCustomAnswer()">
                <mat-icon>check</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <div *ngFor="let field of customFields; let i = index">
            <div class="mb-3">
                <div class="cus-input-group">
                    <input type="text" class="form-control" [(ngModel)]="field.name" #name="ngModel" name="field{{i}}"
                        value="{{field}}" placeholder="***" (keydown.enter)="addCusField()"
                        style="border-radius: 5px 0px 0px 5px !important;">
                    <button type="button" class="btn btn-custom" (click)="removeCusField(i)"
                        style="width: 10% !important;border-radius: 0px 5px 5px 0px; background-color: rgb(200, 21, 21) !important;">
                        <mat-icon>remove_circle</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <button class="btn btn-custom btn-remove-ans" (click)="addCusField()">
            Add New <mat-icon style="margin-left: 10px;">add_circle</mat-icon>
        </button>
        <div class="row" style="margin-top: 10px;">
            <label for="max" class="col-md-5 col-form-label">Vote Limit</label>
            <div class="col-md-7">
                <select class="form-select" aria-label="Select Max Choice" id="max" name="max" [(ngModel)]="maxOption">
                    <option *ngFor="let max of maxOptionCount" value="{{max}}">
                        {{max}}</option>
                </select>
            </div>
        </div>
    </perfect-scrollbar>
</div>