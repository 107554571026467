<div class="container-fluid">
    <div class="content">
      <div class="me-auto">
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      </div>
  
      <div class="d-flex justify-content-between">
        <div class="d-flex ">
          <div class="input-group me-2 search-size" style="width: 300px !important">
            <input class="form-control" type="text" placeholder="&#xf002; {{searchText}}" aria-label="Search"
              style="font-family: FontAwesome, Ubuntu" [(ngModel)]="searchName" (keyup.enter)="searchByName()">
            <button class="btn btn-custom me-2" matTooltip="Refresh" (click)="searchByName()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <button class="btn btn-custom btn-size" (click)="newMember()">New</button>
          <div *ngIf="filter" class="align-items-center ms-2" [matMenuTriggerFor]="statusmenu">
            <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button" id="dropdownMenuButton"
              aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
              {{filter.text}}&nbsp;:&nbsp; {{ selectedFilter }}
              <mat-menu #statusmenu="matMenu" xPosition="before">
                <button mat-menu-item *ngFor="let filter of filter.choices " (click)="handleFilterSelected(filter)">
                  {{ filter.name }}
                </button>
              </mat-menu>
            </button>
          </div>
          <div class="line"></div>
          <div class="align-items-center" style="margin-top: 5px;">
              <span class="total-count-text">Total:&nbsp;</span> <span
                  class="total-count-num">{{totalcount}}</span>
          </div>
        </div>
        <pagination [filterState]="filterState" [q]="searchName" [url]="url" [currentPage]="page" [totalPages]="totalPages"></pagination>
      </div>
  
      <table class="table table-responsive table-borderless m-table">
        <thead class="table-header">
          <tr>
            <th *ngFor="let config of configs">{{ config.title }}</th>
          </tr>
        </thead>
        <tbody *ngIf="!gettingList && list.length > 0">
          <tr *ngFor="let member of list" class="py-4" style="cursor: pointer;">
            <td class="td-edit td-data-2" *ngFor="let config of configs" [hidden]="!config.property" (click)="toDetail(member.syskey)">
              <span *ngIf="config.status" class="status {{ getStatusClass(getPropertyValue(member, config.property),config.status.id) }}">
                {{ getStatusText(getPropertyValue(member, config.property), config.status.list) }}
              </span> 
              <span *ngIf="!config.status">
                {{ config.type ? getFormattedProperty(getPropertyValue(member, config.property), config.type) : getPropertyValue(member, config.property) }}
              </span>             
            </td>
            <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: member }"></ng-container>
          </tr>
        </tbody>
        <tbody *ngIf="!gettingList && list.length === 0">
          <tr>
            <td colspan="15" class="nodata">Empty</td>
          </tr>
        </tbody>
        <tbody *ngIf="gettingList">
          <tr>
            <td colspan="15" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      
  
      <!-- Pagination controls -->
      <pagination [url]="url" [currentPage]="page" [totalPages]="totalPages" [q]="searchName" [filterState]="filterState"></pagination>
    </div>
  </div>
  