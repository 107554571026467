import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-office-rooms',
  templateUrl: './office-rooms.component.html',
  styleUrls: ['./office-rooms.component.scss']
})
export class OfficeRoomsComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isAdding : boolean = false;
  isEditing : boolean = false;
  saveLoading: boolean = false;
  subscriptions = new SubSink();
  isFocus : boolean = false;

  listLoading : boolean = false;
  searchText : string = '';
  orgid : string = '';
  formSubmitted : boolean = false;

  roomList : any = [];
  gettingRooms : boolean = false;

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  tableLoading : boolean = false;
  getList!: Subscription;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  submitForm = {
    name : '',
    maxpeople : '',
    roomid : ''
  }

  constructor(
    public allinoneService : AllInOneService,
    private kunyekService : KunyekService,
    private messageService : MessageService,
    private route : ActivatedRoute,
    private router : Router,
    private dialog : MatDialog,
    private filterPipe : FilterPipe
  ) { 
    this.onResize(event);
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
   }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.getRoom();
  }

  ngOnDestroy(): void {
    this.getList && this.getList.unsubscribe();
  }

  getRoom() {
    this.tableLoading = true;
    this.gettingRooms = true;
    const data = {
      orgid : this.orgid,
    }
    this.subscriptions.sink = this.kunyekService.getRooms(data).subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          this.roomList = res.datalist
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingRooms = false;
        this.tableLoading = false;
      },
      (err:any) => {
        this.gettingRooms = false;
        this.tableLoading = false;
        this.messageService.openSnackBar(
          'Server Error',
          'warn'
        );
      }
    )
  }

  submit() {
    this.formSubmitted = true;    
    if(this.submitForm.name == '' || this.submitForm.name == null ) {
      this.messageService.openSnackBar('Name cannot be blank.', 'warn');
      return
    }
    if( Number(this.submitForm.maxpeople) <= 0 ) {      
      this.messageService.openSnackBar('Max People cannot be less than 1.', 'warn');
      return
    }
    if(this.submitForm.maxpeople == '' || this.submitForm.maxpeople == null ) {
      this.messageService.openSnackBar('Max People cannot be blank.', 'warn');
      return
    }
    this.saveLoading = true;
    var data = this.isEditing ? {
      roomid : this.submitForm.roomid,
      orgid : this.orgid,
      name : this.submitForm.name.toString().trim(),
      maxpeople : this.submitForm.maxpeople.toString().trim()
    } : {
      orgid : this.orgid,
      name : this.submitForm.name.toString().trim(),
      maxpeople : this.submitForm.maxpeople.toString().trim()
    }
    var fun = this.isEditing ?
    this.kunyekService.updateRooms(data) :
    this.kunyekService.addRooms(data)

    this.subscriptions.sink = fun.subscribe(
      (res:any) => {
        if(res.returncode == '300') {
          if(this.isEditing) {
            var index = this.roomList.findIndex(
              (x:any) => x.roomid == this.submitForm.roomid
            );
            if(index > -1) {
              this.roomList[index].name = this.submitForm.name;
              this.roomList[index].maxpeople = this.submitForm.maxpeople;
            } else {
              this.refresh();
            }
            this.cancel();
          } else {
            var tempList = {...data, roomid : res.roomid};
            this.roomList.push(tempList);
            this.cancel();
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err:any) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
  }

  editRoom(data:any) {
    this.isEditing = true;
    this.isAdding = true;
    this.submitForm.roomid = data.roomid;
    this.submitForm.name = data.name;
    this.submitForm.maxpeople = data.maxpeople;
  }

  deleteRoom(id: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe(( dialogResult: any ) => {
      if(dialogResult) {
        this.tableLoading = true;
        const data = {
          orgid : this.orgid,
          roomid : id
        }
        this.kunyekService.deleteRooms(data).subscribe(
          (res:any) => {
            if(res.returncode == '300') {
              var i = this.roomList.findIndex(
                (x:any) => x.roomid == id
              );
              this.roomList.splice(i, 1);
              if (
                Math.round(this.roomList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.tableLoading = false;
          },
          (err) => {
            this.messageService.openSnackBar('Server Error', 'warn');
            this.tableLoading = false;
          }
        )
      }
    })
  }

  new() {
    this.isAdding = true;
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  sortData(sort : Sort) {
    const data = this.roomList;
    if (!sort.active || sort.direction === '') {
      this.roomList = data;
      return;
    }
    this.roomList = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      if(sort.active == 'name') {
        return this.allinoneService.compare(a.name, b.name, isAsc);
      } else if(sort.active == 'maxpeople') {
        return this.allinoneService.compare(a.maxpeople, b.maxpeople, isAsc);
      }
      return 0;
    })
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event:any) {

  }

  refresh() {
    this.currentPage = 1;
    this.getRoom();
  }


  downloadSheet() {
    var exdata: any = [];
    var name = 'Rooms.xlsx';
    var filteredData = this.filterPipe.transform(
      this.roomList,
      this.searchText,
      'room'
    );
    filteredData.map((item: any) => {
      var temp = {
        Name: item.name,
        'Max People' : item.maxpeople,
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.submitForm = {
      name : '',
      maxpeople : '',
      roomid : ''
    }

  }

}
