import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { chain, groupBy } from 'lodash';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Workbook } from 'exceljs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-check-in-attendances-details',
  templateUrl: './check-in-attendances-details.component.html',
  styleUrls: ['./check-in-attendances-details.component.css'],
})
export class CheckInAttendancesDetailsComponent implements OnInit, OnDestroy {
  gettingDetails: boolean = false;
  connectSocketLoading: boolean = false;
  isFocus: boolean = false;
  isAdmin: boolean = false;

  socket!: WebSocket;

  domain: any = '';
  searchText: string = '';
  tempType: string = '';

  connectionId: string = '';
  socketConnectionStatus: string = '';
  drsocketfileurl: string = '';
  p: number = 1;
  type: number = 5;
  gtype: number = 11;
  otype: number = 26;
  totalcounts: number = 0;
  other: boolean = false;
  updateCId: boolean = false;

  detailList: any[] = [];
  groupedDetailList: any = [];

  socketReceivedMessage: any;
  socketConnectTime: any;

  detailForm = {
    date: '',
    type: '',
    orgid: '',
    orgname: '',
    department: '',
    division: '',
    teamid: '',
    costcenter: '',
    subdivision: ''
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private filter: FilterPipe,
    private http: HttpClient
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'hradmin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl(
            this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/hxm'
          );
        }
      }
    });
  }
  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.domain = this.allinoneService.getDomain();
    this.tempType = this.route.snapshot.params['type'];
    this.detailForm.orgid = this.route.snapshot.params['orgid'];
    const tempDepartment = this.route.snapshot.params['department'];
    const tempDivision = this.route.snapshot.params['division'];
    const tempTeamid = this.route.snapshot.params['teamid'];
    const tempCostcenter = this.route.snapshot.params['costcenter'];
    const tempSubdivision = this.route.snapshot.params['subdivision'];
    this.detailForm.department = tempDepartment == 'all' ? '' : tempDepartment;
    this.detailForm.division = tempDivision == 'all' ? '' : tempDivision;
    this.detailForm.teamid = tempTeamid == 'all' ? '' : tempTeamid;
    this.detailForm.costcenter = tempCostcenter == 'all' ? '' : tempCostcenter;
    this.detailForm.subdivision = tempSubdivision == 'all' ? '' : tempSubdivision;
    if (
      this.tempType == 'timein' ||
      this.tempType == 'timeout' ||
      this.tempType == 'usertimein' ||
      this.tempType == 'useractive' ||
      this.tempType == 'usertimeout' ||
      this.tempType == 'checkin' ||
      this.tempType == 'usercheckin' ||
      this.tempType == 'activity' ||
      this.tempType == 'useractivity' ||
      this.tempType == 'usernotimein' ||
      this.tempType == 'usernoactivity' ||
      this.tempType == 'leave' ||
      this.tempType == 'userleave'
    ) {
      if (this.tempType == 'timein' || this.tempType == 'usertimein') {
        this.detailForm.type = 'in';
      } else if (this.tempType == 'useractive') {
        this.detailForm.type = 'activeuser';
      } else if (this.tempType == 'timeout' || this.tempType == 'usertimeout') {
        this.detailForm.type = 'out';
      } else if (this.tempType == 'checkin' || this.tempType == 'usercheckin') {
        this.detailForm.type = 'checkin';
      } else if (
        this.tempType == 'activity' ||
        this.tempType == 'useractivity'
      ) {
        this.detailForm.type = 'activity';
      } else if (
        this.tempType == 'usernotimein' ||
        this.tempType == 'usernoactivity'
      ) {
        this.detailForm.type =
          this.tempType == 'usernotimein' ? 'notimein' : 'noactivity';
        this.other = true;
      } else {
        this.detailForm.type = 'leave';
        this.other = true;
      }
    } else {
      console.log('3');
      console.log('temptype' + this.tempType);
      console.warn('first step');
      this.router.navigate(['/home']);
    }

    const index = this.allinoneService.orgs.findIndex(
      (x: any) => x.orgid == this.detailForm.orgid
    );
    if (index == -1) {
      console.warn('second step');
      this.router.navigate(['/home']);
    } else {
      this.detailForm.orgname = this.allinoneService.orgs[index].name;
    }

    this.detailForm.date = this.route.snapshot.params['date'];

    // this.getDetails();
    this.search();
  }

  clear() {
    this.searchText = '';
  }

  downloadSheet() {
    var exdata: any = [];
    var startd = formatDate(
      new Date(
        this.allinoneService.formatDBToShowInEdit(this.detailForm.date, '/')
      ),
      'dd-MM-yyyy',
      'en-US'
    ).toString();
    var temp =
      this.detailForm.type == 'in'
        ? 'Time In'
        : this.detailForm.type == 'activeuser'
        ? 'Active'
        : this.detailForm.type == 'out'
        ? 'Time Out'
        : this.detailForm.type == 'checkin'
        ? 'Check In'
        : this.detailForm.type == 'activity'
        ? 'Activity'
        : this.detailForm.type == 'notimein'
        ? 'Absence'
        : this.detailForm.type == 'leave'
        ? 'Leave'
        : 'No Activity';
    var name = this.detailForm.orgname + ' ' + temp + ' (' + startd + ').xlsx';
    const workBook = new Workbook();
    const workSheet = workBook.addWorksheet('Sheet 1');

    if (
      this.detailForm.type == 'in' ||
      this.detailForm.type == 'out' ||
      this.detailForm.type == 'checkin' ||
      this.detailForm.type == 'activeuser'
    ) {
      var filteredDetailedList = this.filter.transform(
        this.groupedDetailList,
        this.searchText,
        this.gtype
      );
      filteredDetailedList.map((item: any) => {
        item.iolist.map((citem: any) => {
          var temp = {
            UserID: citem.userid,
            UserName: citem.username,
            EmployeeID: citem.employeeid,
            Division: citem.division,
            Department: citem.department,
            TeamID: citem.teamid,
            Date: this.allinoneService.formatDBToShow(citem.date),
            Time: this.allinoneService.formatTimeToShow(citem.starttime),
            Location:
              citem.location == '' && citem.lat == '' && citem.long == ''
                ? 'Unknown Location'
                : citem.location,
            LatLong:
              citem.lat != '' && citem.long != ''
                ? citem.lat + ', ' + citem.long
                : '',
            LocationType: citem.locationtype,
          };
          exdata.push(temp);
        });
      });
      workSheet.addRow([
        'User ID',
        'Name',
        'Employee ID',
        'Department',
        'Division',
        'Team ID',
        'Date',
        'Time',
        'Location',
        'LatLong',
      ]);
      exdata.forEach((item: any) => {
        const row = workSheet.addRow([
          item.UserID,
          item.UserName,
          item.EmployeeID,
          item.Department,
          item.Division,
          item.TeamID,
          item.Date,
          item.Time,
          item.Location,
          item.LatLong,
        ]);
        for (let i = 1; i < 9; i++) {
          const col = row.getCell(i);
          col.font = {
            color: {
              argb: item.LocationType == '3' ? '000000' : 'FF0000',
            },
          };
        }
      });
      workBook.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, name);
      });
    } else if (this.detailForm.type == 'activity') {
      var filteredDetailedList = this.filter.transform(
        this.groupedDetailList,
        this.searchText,
        this.gtype
      );
      filteredDetailedList.map((item: any) => {
        item.iolist.map((citem: any) => {
          var temp = {
            UserID: citem.userid,
            UserName: citem.username,
            EmployeeID: citem.employeeid,
            Division: citem.division,
            Department: citem.department,
            TeamID: citem.teamid,
            Date: this.allinoneService.formatDBToShow(citem.date),
            Time:
              this.allinoneService.formatTimeToShow(citem.starttime) +
              ' - ' +
              this.allinoneService.formatTimeToShow(citem.endtime),
            Description: citem.description,
          };
          exdata.push(temp);
        });
      });
      workSheet.addRow([
        'User ID',
        'Name',
        'Employee ID',
        'Department',
        'Division',
        'Team ID',
        'Date',
        'Time',
        'Description',
      ]);
      exdata.forEach((item: any) => {
        const row = workSheet.addRow([
          item.UserID,
          item.UserName,
          item.EmployeeID,
          item.Department,
          item.Division,
          item.TeamID,
          item.Date,
          item.Time,
          item.Description,
        ]);
      });
      workBook.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, name);
      });
    } else {
      var filteredDetailedList = this.filter.transform(
        this.groupedDetailList,
        this.searchText,
        this.gtype
      );
      filteredDetailedList.map((item: any) => {
        item.iolist.map((citem: any) => {
          var temp = {
            UserID: citem.userid,
            UserName: citem.username,
            EmployeeID: citem.employeeid,
            Department: citem.department,
            Division: citem.division,
            TeamID: citem.teamid,
          };
          exdata.push(temp);
        });
      });
      workSheet.addRow([
        'User ID',
        'Name',
        'Employee ID',
        'Department',
        'Division',
        'Team ID',
      ]);
      exdata.forEach((item: any) => {
        const row = workSheet.addRow([
          item.UserID,
          item.UserName,
          item.EmployeeID,
          item.Department,
          item.Division,
          item.TeamID,
        ]);
      });
      workBook.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, name);
      });
    }
  }

  sortData(sort: Sort) {
    const data = this.detailList;
    if (!sort.active || sort.direction === '') {
      this.detailList = data;
      return;
    }

    this.detailList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'date':
          return this.allinoneService.compare(
            a.date + a.starttime,
            b.date + b.starttime,
            isAsc
          );
        case 'loc':
          return this.allinoneService.compare(a.loc, b.loc, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        case 'department':
          return this.allinoneService.compare(
            a.department,
            b.department,
            isAsc
          );
        case 'teamid':
          return this.allinoneService.compare(a.teamid, b.teamid, isAsc);
        default:
          return 0;
      }
    });
  }

  sortGroupData(sort: Sort) {
    const data = this.groupedDetailList;
    if (!sort.active || sort.direction === '') {
      this.groupedDetailList = data;
      return;
    }

    this.groupedDetailList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(
            a.iolist[0].username,
            b.iolist[0].username,
            isAsc
          );
        case 'employeeid':
          return this.allinoneService.compare(
            a.iolist[0].employeeid,
            b.iolist[0].employeeid,
            isAsc
          );
        case 'division':
          return this.allinoneService.compare(
            a.iolist[0].division,
            b.iolist[0].division,
            isAsc
          );
        case 'department':
          return this.allinoneService.compare(
            a.iolist[0].department,
            b.iolist[0].department,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  backToCIA() {
    this.router.navigate([
      (this.isAdmin
        ? this.allinoneService.isAttendanceView
          ? '/attendance/hxm'
          : '/hxm'
        : '') + '/checkinattendances',
    ]);
  }

  search() {
    this.connectWebSocket();
    this.closeSocketWithTime();
  }

  getDetails() {
    this.gettingDetails = true;
    const data = {
      startdate: this.detailForm.date,
      enddate: this.detailForm.date,
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      orgid: this.detailForm.orgid,
      searchuser: '',
      types: this.detailForm.type,
      department: this.detailForm.department,
      division: this.detailForm.division,
      teamid: this.detailForm.teamid,
      costcenter: this.detailForm.costcenter,
      subdivision: this.detailForm.subdivision,
      connectionid: this.connectionId,
    };

    this.kunyekService.getSummaryDetails(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.totalcounts = res.totalcounts;
          // this.detailList = res.datalist;
          // this.groupedDetailList = chain(this.detailList)
          //   .groupBy('userid')
          //   .map((value, key) => ({ userid: key, iolist: value }))
          //   .value();
          // console.log(this.groupedDetailList);
          // this.gettingDetails = false;
        } else {
          this.gettingDetails = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingDetails = false;
      }
    );
  }

  connectWebSocket() {
    console.log('Calling Websocket');
    this.gettingDetails = true;
    this.connectSocketLoading = true;
    const webSocketURL = this.allinoneService.attendanceSocketUrl;

    this.socket = new WebSocket(webSocketURL);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.connectSocketLoading = false;
      // this.socket?.send('001');
      this.socketConnectionStatus = 'connected';
    });

    this.socket.addEventListener('message', (event) => {
      // console.log('Received message:', event.data);
      this.socketReceivedMessage = event.data;

      var tempData = JSON.parse(event.data);

      if (tempData.connectionId) {
        this.connectionId = tempData.connectionId;

        if (this.updateCId) {
          this.updateConnectionID();
        }
        this.getDetails();
      } else {
        this.drsocketfileurl = tempData.fileurl;

        if (this.drsocketfileurl) {
          this.http.get(this.drsocketfileurl).subscribe((res: any) => {
            this.totalcounts = res.totalcounts;
            this.detailList = res.datalist;
            this.groupedDetailList = chain(this.detailList)
              .groupBy('userid')
              .map((value, key) => ({ userid: key, iolist: value }))
              .value();
            console.log(this.groupedDetailList);
            this.gettingDetails = false;
            clearTimeout(this.socketConnectTime);
          });
        }
      }

      console.log('c id');
      console.log(this.connectionId);
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.socketConnectionStatus = 'error';
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      this.socketConnectionStatus = 'disconnected';
      if (event.code != 3001) {
        this.connectionId = '';
        this.updateCId = true;
        this.connectWebSocket();
      }
    });
  }

  updateConnectionID() {
    var data = {
      connectionid: this.connectionId,
      type: '013',
    };

    this.kunyekService.updateConnectionID(data).subscribe((res: any) => {
      console.log('id updated res');
      this.updateCId = false;
      console.log(res);
    });
  }

  closeSocketWithTime() {
    this.socketConnectTime = setTimeout(() => {
      if (this.socketConnectionStatus != 'disconnected') {
        console.log('Time out');

        this.socket?.close(3001);
        this.connectionId = '';
        this.messageService.openSnackBar('Request Time Out', 'warn');
        this.gettingDetails = false;
      }
    }, this.allinoneService.reportSocketTimeoutMins * 60 * 1000);
  }
}
