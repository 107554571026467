import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {


  userid: any = "";
  loading: boolean = false;
  formSubmitted : boolean = false;

  constructor(public dialogRef: MatDialogRef<AddContactComponent>, public allinoneService: AllInOneService, public kunyekService: KunyekService, public messageService: MessageService, ) { }

  ngOnInit(): void {
  }


  onConfirm(): void {
    this.formSubmitted = true
    if (this.userid != "") {
      this.loading = true

      var userId = this.allinoneService.checkUserId(this.userid)
      console.log(userId);

      const data =
      {

        "domain": this.allinoneService.getDomain().shortcode,
        "recordstatus": "1",
        "contact": [userId],
        "count": 0
      }
      this.kunyekService.addContact(data).subscribe((res: any) => {
        if (res.returncode == "300") {
          this.formSubmitted = false
          this.dialogRef.close(this.userid);

        } else {
          this.formSubmitted = false
          this.messageService.openSnackBar(res.status, 'warn');
          if (res.status == "Contact already exists.") {
            this.dialogRef.close('');
          }else{
            this.formSubmitted = false
            this.loading = false
          }
        }
      },
        err => {
          this.loading = false
          this.messageService.openSnackBar(err, 'warn');
        })

    }


  }

  onDismiss(): void {
    this.dialogRef.close('');
  }

}
