import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { OfficeChooseMembersComponent } from '../office-choose-members/office-choose-members.component';

import { OfficeLeaveOpeningFormComponent } from '../office-leave-opening-form/office-leave-opening-form.component';
import { OfficeLeaveOpeningImportComponent } from '../office-leave-opening-import/office-leave-opening-import.component';

@Component({
  selector: 'app-office-leave-opening',
  templateUrl: './office-leave-opening.component.html',
  styleUrls: ['./office-leave-opening.component.scss'],
})
export class OfficeLeaveOpeningComponent implements OnInit, OnDestroy {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
      this.isTabletView = false;
    } else if (
      window.innerWidth > this.mobileViewWidth &&
      window.innerWidth < this.tabletViewWidth
    ) {
      this.isMobileView = false;
      this.isTabletView = true;
    } else {
      this.isMobileView = false;
      this.isTabletView = false;
    }
  }

  mobileViewWidth: any = 490;
  itemPerPage: any = 100;
  currentPage: number = 1;
  startcount: number = 0;
  endcount: number = 100;

  isMobileView: boolean = false;
  isTabletView: boolean = false;
  isFocus: boolean = false;
  memberListLoading: boolean = false;
  nextDisabled: boolean = false;
  isAdmin: boolean = false;
  calenderListLoading: boolean = false;
  templateListLoading: boolean = false;
  refreshingImportStatus: boolean = false;
  isCalendarActive: boolean = false;

  memberList: any[] = [];
  organizations: any[] = [];
  memberTimeout: any;
  statusInterval: any;
  importData: any;

  domainid = this.allinoneService.getDomain().domainid;
  orgid: string = '';
  searchText: string = '';
  end: string = '';
  lastPage: any = '';
  importStatus: string = '000';
  subscriptions = new SubSink();
  allCalendarList: any[] = [];
  templateList: any[] = [];
  activeCalendarList: any[] = [
    {
      calendarid: '',
      year: '-',
    },
  ];

  memberSubscription!: Subscription;
  selectedYear: any = '';
  selectedYearName: any = '';
  selectedTemplate: any = '';
  tabletViewWidth: any = 992;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;

    this.memberSubscription && this.memberSubscription.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getCalendarList();
    this.getImportStatus();
    this.statusInterval = setInterval(() => {
      if (this.importStatus == '002' || this.importStatus == '003') {
        this.getImportStatus();
      }
    }, 3000);
    // this.getMember();
  }
  yearChanged(calendar: any) {
    // this.selectedYear = event.target.value;
    this.selectedYear = calendar.calendarid;
    this.selectedYearName = calendar.year;

    if (this.selectedYear == '') {
      this.isCalendarActive = false;
      this.selectedTemplate = '';
    } else {
      const temp = this.allCalendarList.find(
        (x: any) => x.calendarid == this.selectedYear
      );
      this.isCalendarActive = temp.valid && temp.active;
    }

    // this.getTemplateList();
    this.FilterFunction();
  }

  // yearChanged2(event: any) {
  //   this.selectedYear = event.target.value;

  //   console.log('year ans');

  //   console.log(this.selectedYear);

  //   if (this.selectedYear == '') {
  //     this.isCalendarActive = false;
  //     this.selectedTemplate = '';
  //   } else {
  //     const temp = this.allCalendarList.find(
  //       (x: any) => x.calendarid == this.selectedYear
  //     );
  //     this.isCalendarActive = temp.valid && temp.active;
  //   }

  //   console.log(this.isCalendarActive);

  //   // this.getTemplateList();
  //   this.FilterFunction();
  // }

  templateChange(event: any) {
    this.selectedTemplate = event.target.value;

    this.FilterFunction();
  }
  getCalendarList() {
    this.calenderListLoading = true;
    let data = {
      orgid: this.orgid,
      domainid: this.domainid,
      active: '',
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subscriptions.sink = this.kunyekService.getCalendar(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.calenderListLoading = false;
          let _yearAll: any = [];
          _yearAll = res['calendarlist'];
          this.allCalendarList = res.calendarlist;

          // console.log(this.allCalendarList)
          // this.activeCalendarList = res.calendarlist.filter(
          //   (item: any) => item.valid && item.active
          // );
          this.activeCalendarList = [
            {
              calendarid: '',
              year: '-',
            },
          ];
          // this.activeCalendarList = res.calendarlist;
          for (var i = 0; i < res.calendarlist.length; i++) {
            this.activeCalendarList.push(res.calendarlist[i]);
          }

          if (this.activeCalendarList.length > 1) {
            this.selectedYear = this.activeCalendarList[1].calendarid;
            this.selectedYearName = this.activeCalendarList[1].year;
            this.isCalendarActive =
              this.activeCalendarList[1].valid &&
              this.activeCalendarList[1].active;
          } else {
            this.selectedYear = '';
            this.selectedYearName = '';
            this.isCalendarActive = false;
          }
          // this.getTemplateList();
          this.FilterFunction();
        } else {
          this.calenderListLoading = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.calenderListLoading = false;
      }
    );
  }
  getTemplateList() {
    this.templateListLoading = true;
    let data = {
      orgid: this.orgid,
      domainid: this.domainid,
      calendarid: this.selectedYear,
      end: '',
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.subscriptions.sink = this.kunyekService.getTemplate(data).subscribe(
      (res) => {
        if (res.returncode == 300) {
          this.templateList = res.templatelist;
          if (this.templateList.length > 0 && this.isCalendarActive) {
            this.selectedTemplate = this.templateList[0].templateid;
          } else {
            this.selectedTemplate = '';
          }
          this.FilterFunction();
          // console.log(this.templateList);

          // if(this.isEditing){
          //   //get leave entitlement
          //   this.getLeaveEntitlement();
          // }
          this.templateListLoading = false;
        } else {
          this.templateListLoading = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.templateListLoading = false;
      }
    );
  }
  // getMember() {
  //   this.memberListLoading = true;
  //   let data = {
  //     orgid: this.orgid,
  //     domainid: this.domainid,
  //     startcount: this.startcount,
  //     endcount: this.endcount,
  //     search: this.searchText,
  //   };
  //   this.memberSubscription && this.memberSubscription.unsubscribe();
  //   this.memberSubscription = this.kunyekService
  //     .getOfficeMember(data)
  //     .subscribe(
  //       (res) => {
  //         if (res.returncode == 300) {
  //           if (res.list.length > 0) {
  //             this.nextDisabled = false;
  //             console.log(this.memberList)
  //             this.memberList.push.apply(this.memberList, res.list);
  //             if (res.list.length < this.itemPerPage) {
  //               this.nextDisabled = true;
  //               this.lastPage = this.currentPage;
  //             }
  //           } else {
  //             this.currentPage = this.currentPage - 1;
  //             this.nextDisabled = true;
  //             this.lastPage = this.currentPage;
  //           }
  //         } else {
  //           this.messageService.openSnackBar(
  //             res.message || res.stutus || 'Server Error',
  //             'warn'
  //           );
  //         }
  //         this.memberListLoading = false;
  //       },
  //       (err) => {
  //         this.memberListLoading = false;
  //       }
  //     );
  // }

  FilterFunction() {
    this.memberListLoading = true;
    let data = {
      calendarid: this.selectedYear,
      templateid: this.selectedTemplate,
      orgid: this.orgid,
      domainid: this.domainid,
      roleid: this.allinoneService.getSelectedRole(),
      viewimport: !this.isCalendarActive,
    };
    this.memberList = [];
    this.memberSubscription && this.memberSubscription.unsubscribe();
    this.memberSubscription = this.kunyekService
      .getOfficeOpening(data)
      .subscribe(
        (res) => {
          if (res.returncode == 300) {
            if (res.requesttypelist.length > 0) {
              this.nextDisabled = false;
              console.log(this.memberList);
              this.memberList = res.requesttypelist;
              // this.memberList.push.apply(this.memberList, res.requesttypelist);
              // if (res.requesttypelist.length < this.itemPerPage) {
              //   this.nextDisabled = true;
              //   this.lastPage = this.currentPage;
              // }
            } else {
              this.memberList = [];

              // this.currentPage = this.currentPage - 1;
              // this.nextDisabled = true;
              // this.lastPage = this.currentPage;
            }
          } else {
            this.messageService.openSnackBar(res.status, 'warn');
          }
          this.memberListLoading = false;
        },
        (err) => {
          this.memberListLoading = false;
        }
      );
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  openLeaveOpeningForm(member: any) {
    // if(!this.isCalendarActive){
    //   return;
    // }

    let tempData = {
      userid: member.userid,
      username: member.username,
      orgid: this.orgid,
      openingid: member.openingid,
      templateid: this.selectedTemplate,
      calendarid: this.selectedYear,
      isnew: false,
    };
    // let user = this.allinoneService.replaceAll(
    //   this.allinoneService.encrypt(JSON.stringify(tempData)),
    //   '/',
    //   '###'
    // );

    let user = encodeURIComponent(this.allinoneService.encrypt(JSON.stringify(tempData)));

    let url = (this.isAdmin ? this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/requestapproval' : '/office') +
    '/leaveopening/form/' + user
    console.log(url);

    this.router.navigateByUrl(url);

    // const dialog = this.dialog.open(OfficeLeaveOpeningFormComponent, {
    //   maxWidth: '95vw',
    //   width: '550px',
    //   maxHeight: '95vh',
    //   panelClass: ['mat-typography'],
    //   closeOnNavigation: true,
    //   autoFocus: false,
    //   data: {
    //     user: member,
    //     domainid: this.domainid,
    //     orgid: this.orgid,
    //     openingid: member.openingid,
    //     templateid: this.selectedTemplate,
    //     calendarid: this.selectedYear,
    //     isnew: false,
    //   },
    // });

    // dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  filter() {
    clearTimeout(this.memberTimeout);
    this.memberTimeout = setTimeout(() => {
      this.startcount = 0;
      this.endcount = this.itemPerPage;
      this.currentPage = 1;
      this.memberList = [];
      this.FilterFunction();
    }, 500);
  }

  clear() {
    this.searchText = '';
    clearTimeout(this.memberTimeout);
    this.startcount = 0;
    this.endcount = this.itemPerPage;
    this.currentPage = 1;
    this.memberList = [];
    this.FilterFunction();
  }

  refresh() {
    this.FilterFunction();
  }

  sortData(sort: Sort) {}

  changePageCount(event: any) {
    this.itemPerPage = parseInt(event.target.value);
    this.currentPage = 1;
    this.startcount = 0;
    this.endcount = this.itemPerPage;
    this.currentPage = 1;
    this.memberList = [];
    this.FilterFunction();
  }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;

        if (event > this.memberList.length / this.itemPerPage) {
          this.startcount = this.endcount;
          this.endcount = this.endcount + this.itemPerPage;
          this.FilterFunction();
        }
      }
    }
  }

  newLeaveOpening() {
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      height: '560px',
      width: '500px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        isLeaveOpening: true,
        domainid: this.domainid,
        orgid: this.orgid,
        templateid: this.selectedTemplate,
        calendarid: this.selectedYear,
        isAdmin: this.isAdmin
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.memberList = dialogResult.data.filter(
          (data: { checked: boolean }) => {
            return data.checked == true;
          }
        );
        console.log(this.memberList);
      }
    });
  }

  importOpenings(isError: boolean = false) {
    clearInterval(this.statusInterval);
    const dialog = this.dialog.open(OfficeLeaveOpeningImportComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      width: '600px',
      closeOnNavigation: true,
      panelClass: ['mat-typography'],
      data: {
        orgid: this.orgid,
        domainid: this.domainid,
        calendarlist: this.allCalendarList,
        errorData: this.importData,
        isError: isError,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.getImportStatus();
        this.statusInterval = setInterval(() => {
          if (this.importStatus == '002' || this.importStatus == '003') {
            this.getImportStatus();
          }
        }, 3000);
      }
    });
  }

  getImportStatus() {
    this.refreshingImportStatus = true;
    var data = {
      orgid: this.orgid,
    };
    this.kunyekService.getLeaveOpeningImportStatus(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            (this.importStatus == '002' || this.importStatus == '003') &&
            res.status != this.importStatus
          ) {
            if (res.status == '001') {
              this.refresh();
            }
            clearInterval(this.statusInterval);
          }
          this.importStatus = res.importstatus;
          this.importData = res;
        } else {
          // this.messageService.openSnackBar(
          //   res.message || res.status || 'Server Error',
          //   'warn'
          // );
        }
        this.refreshingImportStatus = false;
      },
      (err) => {
        this.refreshingImportStatus = false;
      }
    );
  }
  openHistory(member: any){
    let tempData = {
      userid: member.userid,
      username: member.username,
      orgid: this.orgid,
      openingid: member.openingid,
      templateid: this.selectedTemplate,
      calendarid: this.selectedYear,
      isnew: false,
    };

    let user = encodeURIComponent(this.allinoneService.encrypt(JSON.stringify(tempData)));

    let url = (this.isAdmin ? this.allinoneService.isAttendanceView ? '/attendance/hxm' : '/requestapproval' : 'office/hxm') +
    '/leaveopening/history/' + user
    
    this.router.navigateByUrl(url);
  }
}
