import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { ServiceWarrantyAdvancedsearchComponent } from '../service-warranty-advancedsearch/service-warranty-advancedsearch.component';

@Component({
  selector: 'app-service-warranty',
  templateUrl: './service-warranty.component.html',
  styleUrls: ['./service-warranty.component.css'],
})
export class ServiceWarrantyComponent implements OnInit {
  total: number = 0;
  selectedCustomer = ''
  selectedProduct = ''
  products: any = []
  customers: any = []
  serviceWarranties: any = []
  showTickets: boolean = false;
  showData: any = null;
  startdate = '';
  enddate = '';
  isloading = false;
  showdate = '';

  constructor(
    private kunyekService: KunyekService,
    private allInOneService: AllInOneService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getCustomers()
    this.getProducts()
    this.getServiceWarranty()
  }

  openAdvacedSearch() {
    const dialogRef = this.dialog.open(ServiceWarrantyAdvancedsearchComponent, {
      width: '500px',
      height: 'auto',
      data: {
        startdate: this.startdate,
        enddate: this.enddate
      }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      this.startdate = result.startdate
      this.enddate = result.enddate
      if (this.startdate != '' && this.enddate != '') {
        this.showdate = `${this.formateShowDate(this.startdate)} - ${this.formateShowDate(this.enddate)}`
      } else {
        this.showdate = this.formateShowDate(this.startdate);
      }
      this.getServiceWarranty();
    });
  }

  getCustomers() {
    const data = {
      orgid: this.allInOneService.selectedOrg,
    }
    this.kunyekService.getCustomers(data).subscribe({
      next: (res) => {
        if (res.returncode === '300') {
          this.customers = res.list
        }
      }
    })
  }
  getProducts() {
    const data = {
      orgid: this.allInOneService.selectedOrg,
    }
    this.kunyekService.getAllCRMProducts(data).subscribe({
      next: (res) => {
        if (res.returncode === '300') {
          this.products = res.list
        }
      },

    })
  }

  formateDate(value: string) {
    if (value === "") return "";
    const pattern = /(\d{4})(\d{2})(\d{2})/;
    return value.replace(pattern, '$3/$2/$1');
  }

  formateShowDate(value: string) {
    if (value === "") return "";
    let arr = value.split('-');
    return `${arr[0]}/${arr[1]}/${arr[2]}`
  }


  getServiceWarranty() {
    const data = {
      orgid: this.allInOneService.selectedOrg,
      startdate: this.startdate,
      enddate: this.enddate,
      productid: this.selectedProduct,
      customerid: this.selectedCustomer
    };
    this.isloading = true;

    this.kunyekService.getServiceWarranty(data).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.isloading = false
          this.serviceWarranties = res.list
          this.serviceWarranties = this.serviceWarranties.map((w: any) => {
            return {
              ...w,
              warrantyenddate: this.formateDate(w.warrantyenddate)
            }
          })
        } else {
          this.isloading = false;
        }
      },
      error: (err) => {
        this.isloading = false;
      }
    });
  }



  handleShowTickets(data: any) {
    console.log('tor', data)
    this.showTickets = true;
    this.showData = {
      startdate: data.startdatetime,
      enddate: this.enddate,
      customerid: data.customerid,
      productid: data.productid
    };
  }

  closeShowTickets() {
    this.showTickets = false;
  }

  clearDate() {
    this.startdate = '';
    this.enddate = '';
    this.showdate = '';
    this.getServiceWarranty();
  }
}
