import { Component, Inject, OnInit, Optional } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { DatePipe } from '@angular/common'
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-share-post',
  templateUrl: './share-post.component.html',
  styleUrls: ['./share-post.component.scss']
})
export class SharePostComponent implements OnInit {

  postsyskey : any = "";
  channelid  : any = "";

  groupList : any = [];

  isdisabled : boolean = true;
  isLoading: boolean = false;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    public datepipe: DatePipe,
    public dialogRef: MatDialogRef<SharePostComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.groupList = data.groupList;
      this.postsyskey = data.postsyskey;
      console.log("Group List")
      console.log(this.groupList);
    }

  ngOnInit(): void {
    
  }

  cancel() {
    this.dialogRef.close();
  }

  checked(id: any) {
    this.isdisabled = false;
    this.channelid = id;
  }

  share() {
    this.isLoading = true;
    let domain = this.allinoneService.getDomain().shortcode;
    let name = this.allinoneService.getKunyekUserName();
    let date = new Date();
    let latest_date =this.datepipe.transform(date, 'yyyyMMdd');
    let  time = this.datepipe.transform(date, 'hh:mm:ss a');
    if(!name) {
      name = this.allinoneService.getKunyekUserId();
    }
    let data = {
      "domain": domain,
      "contact":"",
      "channelname":"",
      "date": latest_date,
      "time": time,
      "message":"Attachment",
      "notimessage":"",
      "imagename":"",
      "imagedata":"",
      "channelid": this.channelid, 
      "name": name, 
      "mention":[],
      "eventid":"",
      "timeinoutid":"",
      "postid": this.postsyskey 
    }
    this.kunyekService.sharePost(data).subscribe(res => {
      console.log("res ==>");
      console.log(res);
      if(res.returncode == '300') {
        this.isLoading = false;
        this.cancel();
        this.messageService.openSnackBar(
          'Shared successfully.',
          'success'
        );
      } else {
        this.isLoading = false;
      }
    })
  }

}
