import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-leave-policy',
  templateUrl: './office-leave-policy.component.html',
  styleUrls: ['./office-leave-policy.component.scss'],
})
export class OfficeLeavePolicyComponent implements OnInit {
  @Input() isWfhPolicy: boolean = false;
  @Input() wfhData: any = {};

  @Output() cancelEvent = new EventEmitter();

  isAdmin: boolean = false;
  isEditing: boolean = false;
  gettingPolicyList: boolean = false;
  gettingProratedMethods: boolean = false;
  workingDayLeaveTypeLoading: boolean = false;
  reqTypeFormSubmitted: boolean = false;
  saveLoading: boolean = false;

  organizations: any = [];
  policyList: any = [];
  reqleaveList: any = [];
  leaveList: any = [];

  orgID: string = '';

  subscriptions = new SubSink();

  policy: any = {};

  policyname: string = '';

  proratedMethods: any = [];
  roundingMethods: any = [];

  workingDayMonthData = [
    {
      name: 'January',
      id: '001',
      value: '',
      max: 31,
    },
    {
      name: 'February',
      id: '002',
      value: '',
      max: 29,
    },
    {
      name: 'March',
      id: '003',
      value: '',
      max: 31,
    },
    {
      name: 'April',
      id: '004',
      value: '',
      max: 30,
    },
    {
      name: 'May',
      id: '005',
      value: '',
      max: 31,
    },
    {
      name: 'June',
      id: '006',
      value: '',
      max: 30,
    },
    {
      name: 'July',
      id: '007',
      value: '',
      max: 31,
    },
    {
      name: 'Auguest',
      id: '008',
      value: '',
      max: 31,
    },
    {
      name: 'September',
      id: '009',
      value: '',
      max: 30,
    },
    {
      name: 'October',
      id: '010',
      value: '',
      max: 31,
    },
    {
      name: 'November',
      id: '011',
      value: '',
      max: 30,
    },
    {
      name: 'December',
      id: '012',
      value: '',
      max: 31,
    },
  ];

  weekDay = [
    {
      name: 'Gazaetted Holiday',
      value: '1',
    },
    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];

  preSubmitConfig = [
    {
      from: '',
      to: '',
      day: '',
    },
  ];
  postSubmitConfig = [
    {
      from: '',
      to: '',
      day: '',
    },
  ];

  reqTypeForm: any = {
    policyid: '',
    // Leave Policy Data
    showserviceyear: false,
    showmaxleavepermonth: false,
    showmaxleaveperday: false,
    showattachfile: false,
    showunpaidfile: false,
    showencashment: false,
    showreduceserviceyear: false,
    showminleaveperday: false,
    showpreposttodaysubmit: false,
    showpresubmit: false,
    showpostsubmit: false,
    showtodaysubmit: false,
    showprorated: false,
    showroundingpolicy: false,
    serviceyear: '',
    showcarry: false,
    carry: false,
    carryyear: '',
    maxleavepermonth: '',
    maxleaveperday: '',
    minleaveperday: '',
    attachfile: false,
    unpaid: false,
    encashment: false,
    reduceserviceyear: false,
    prorated: false,
    proratedmethod: '',
    roundingpolicy: false,
    roundingpolicylist: '',
    workingday: false,
    workingdayleavetype: [],
    workingdaygazaettedholiday: false,
    workingdaysaturday: false,
    workingdaysunday: false,
    presubmit: false,
    postsubmit: false,
    todaysubmit: false,
    presubmittype: '1',
    precustom: false,
    postcustom: false,
    presubmitday: '',
    presubmitcount: [],
    postsubmittype: '1',
    postsubmitday: '',
    postsubmitcount: [],
    postgazaettedholiday: false,
    postsaturday: false,
    postsunday: false,
    pregazaettedholiday: false,
    presaturday: false,
    presunday: false,
    pairleave: false,
    pairleavetype: '1',
    pairleavecustomtype: [],
    leavecounting: false,
    leaveduration: false,
    leavecountingpreoff: false,
    leavecountingpostoff: false,
    leavedurationgazaettedholiday: false,
    leavedurationsaturday: false,
    leavedurationsunday: false,
    decreaseserviceyear: false,
    leavecount: '',
    serviceyearday: '',
    limitationday: false,
    limitationdaypreoff: false,
    limitationdaypostoff: false,
    limitationdayweekday: [],
    maternity: false,
    maternitybeforeweek: '',
    maternityafterweek: '',
    paternity: false,
    paternitybeforeweek: '',
    paternityafterweek: '',
    compassionate: false,
    compassionateremark: '',
    encashmenton: false,
    remark: '',
    showlimited: false,
    limitedmonth: '',
    limitedday: '',
    showproratedcheckbox: false,
    proratedchecked: false,
  };

  tempPairLeave: any = {};
  tempNotCountedLeave: any = {};

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.isWfhPolicy) {
      this.orgID = this.wfhData.orgid;
      this.editPolicy(this.wfhData);
    } else {
      if (this.isAdmin) {
        this.organizations = this.allinoneService.orgs;
        if (this.orgID == '') {
          this.orgID = this.allinoneService.selectedOrg
            ? this.allinoneService.selectedOrg
            : this.organizations[0].orgid;
        }
      } else {
        this.organizations = this.allinoneService.orgsCRm;
        if (this.orgID == '') {
          this.orgID = this.allinoneService.selected_orgcrm
            ? this.allinoneService.selected_orgcrm.orgid
            : this.organizations[0].orgid;
        }
      }
      this.getPolicyList();
      this.getLeaveList();
      this.getProratedMethods();
    }
  }

  test() {
    // console.log(this.getPolicy());
    console.log(this.reqTypeForm.unpaid);
  }

  getPolicyList() {
    this.gettingPolicyList = true;
    const data = {
      orgid: this.orgID,
    };
    this.subscriptions.sink = this.kunyekService.getLeavePolicy(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.policyList = res.policylist;
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
        }
        this.gettingPolicyList = false;
      },
      (err) => {
        this.gettingPolicyList = false;
      }
    );
  }

  getProratedMethods() {
    this.gettingProratedMethods = true;
    const data = {
      orgid: this.orgID,
    };
    this.subscriptions.sink = this.kunyekService
      .getProratedMethods(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.proratedMethods = res.list;
            this.roundingMethods = res.roundinglist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingProratedMethods = false;
        },
        (err) => {
          this.gettingProratedMethods = false;
        }
      );
  }

  getLeaveList() {
    this.workingDayLeaveTypeLoading = true;
    let data = {
      orgid: this.orgID ? this.orgID : '',
      leavetype: true,
    };

    this.subscriptions.sink = this.kunyekService.getRequestType(data).subscribe(
      (res) => {
        // console.log("Your res");
        // console.log(res);
        if (res.returncode == 300) {
          this.reqleaveList = res.requesttypelist[0].child;
          this.leaveList = [];
          for (var i = 0; i < this.reqleaveList.length; i++) {
            this.leaveList.push({
              id: this.reqleaveList[i]['requesttypeid'],
              name: this.reqleaveList[i]['requesttypename'],
            });
          }
          if (this.isEditing && this.reqTypeForm.pairleave) {
            this.getPairLeave();
          }
        } else {
          this.messageService.openSnackBar(res.status, 'warn');
        }
        this.workingDayLeaveTypeLoading = false;
      },
      (err) => {
        this.workingDayLeaveTypeLoading = false;
        this.messageService.openSnackBar(err.status, 'warn');
      }
    );
  }

  goToReqType() {
    if (this.isAdmin) {
      this.router.navigateByUrl('hxm/requesttype');
    } else {
      this.router.navigateByUrl('office/requesttype');
    }
  }

  editPolicy(policy: any) {
    console.log('Your policy');
    console.log(policy);

    // policy.policylist.map((x: any) => {
    //   Object.assign(this.policy, x);
    // })
    // console.log(this.policy);
    this.policyname = this.isWfhPolicy ? 'Work From Home Policy' : policy.name;
    // this.policy = policy;
    this.isEditing = true;
    this.reqTypeForm = {
      policyid: this.isWfhPolicy ? '' : policy.policyid,
      // Leave Policy Data
      showserviceyear: false,
      showmaxleavepermonth: false,
      showmaxleaveperday: false,
      showattachfile: false,
      showunpaidfile: false,
      showreduceserviceyear: false,
      showencashment: false,
      showminleaveperday: false,
      showpreposttodaysubmit: false,
      showpresubmit: false,
      showpostsubmit: false,
      showtodaysubmit: false,
      serviceyear: '',
      showcarry: false,
      carry: false,
      carryyear: '',
      maxleavepermonth: '',
      maxleaveperday: '',
      minleaveperday: '',
      attachfile: false,
      unpaid: false,
      encashment: false,
      reduceserviceyear: false,
      showprorated: false,
      showroundingpolicy: false,
      prorated: false,
      proratedmethod: '',
      roundingpolicy: false,
      roundingpolicylist: '',
      workingday: false,
      workingdayleavetype: [],
      presubmit: false,
      postsubmit: false,
      todaysubmit: false,
      presubmittype: '1',
      precustom: false,
      postcustom: false,
      presubmitday: '',
      presubmitcount: [],
      postsubmittype: '1',
      postsubmitday: '',
      postsubmitcount: [],
      postgazaettedholiday: false,
      postsaturday: false,
      postsunday: false,
      pregazaettedholiday: false,
      presaturday: false,
      presunday: false,
      pairleave: false,
      pairleavetype: '1',
      pairleavecustomtype: [],
      leavecounting: false,
      leaveduration: false,
      leavecountingpreoff: false,
      leavecountingpostoff: false,
      leavedurationgazaettedholiday: false,
      leavedurationsaturday: false,
      leavedurationsunday: false,
      decreaseserviceyear: false,
      leavecount: '',
      serviceyearday: '',
      limitationday: false,
      limitationdaypreoff: false,
      limitationdaypostoff: false,
      limitationdayweekday: [''],
      maternity: false,
      maternitybeforeweek: '',
      maternityafterweek: '',
      paternity: false,
      paternitybeforeweek: '',
      paternityafterweek: '',
      compassionate: false,
      compassionateremark: '',
      encashmenton: false,
      remark: '',
      showlimited: false,
      limitedmonth: '',
      limitedday: '',
      showproratedcheckbox: false,
      proratedchecked: false,
    };

    var data = policy.policylist;
    for (var i = 0; i < data.length; i++) {
      if (data[i]['001']) {
        this.reqTypeForm.showserviceyear = true;
        this.reqTypeForm.serviceyear = data[i]['001']['value'];
      }
      if (data[i]['002']) {
        this.reqTypeForm.showmaxleavepermonth = true;
        this.reqTypeForm.maxleavepermonth = data[i]['002']['value'];
      }
      if (data[i]['003']) {
        this.reqTypeForm.showmaxleaveperday = true;
        this.reqTypeForm.maxleaveperday = data[i]['003']['value'];
      }
      if (data[i]['004']) {
        this.reqTypeForm.showcarry = true;
        this.reqTypeForm.carry = data[i]['004']['value'] == 'true' ? true : false;
        this.reqTypeForm.carryyear = data[i]['004']['max'];
      }
      if (data[i]['005']) {
        this.reqTypeForm.showattachfile = true;
        if (
          data[i]['005']['value'] == '' ||
          data[i]['005']['value'] == 'false'
        ) {
          this.reqTypeForm.attachfile = false;
        } else {
          this.reqTypeForm.attachfile = true;
        }

        // this.reqTypeForm.attachfile = true;
      }
      // Unpaid
      if (data[i]['019']) {
        this.reqTypeForm.showunpaidfile = true;
        if (
          data[i]['019']['value'] == '' ||
          data[i]['019']['value'] == 'false'
        ) {
          this.reqTypeForm.unpaid = false;
        } else {
          this.reqTypeForm.unpaid = true;
        }
      }
      // Encashment
      if (data[i]['016']) {
        this.reqTypeForm.showencashment = true;
        if (
          data[i]['016']['value'] == '' ||
          data[i]['016']['value'] == 'false'
        ) {
          this.reqTypeForm.encashment = false;
        } else {
          this.reqTypeForm.encashment = true;
        }
      }

      if (data[i]['006']) {
        this.reqTypeForm.showminleaveperday = true;
        this.reqTypeForm.minleaveperday = data[i]['006']['value'];
      }

      // Working Day
      // this.reqTypeForm.workingday = true;
      if (data[i]['007']) {
        this.reqTypeForm.workingday = true;
        for (var x = 0; x < data[i]['007']['day'].length; x++) {
          var id = this.workingDayMonthData[x].id;
          this.workingDayMonthData[x].value = data[i]['007']['day'][x][id];
        }

        data[i]['007']['count'].map((x: any) => {
          if (x == '1') {
            this.reqTypeForm.workingdaygazaettedholiday = true;
          } else if (x == '2') {
            this.reqTypeForm.workingdaysaturday = true;
          } else if (x == '3') {
            this.reqTypeForm.workingdaysunday = true;
          }
        });
        this.tempNotCountedLeave = data[i]['007']['leave'];
        for (let y = 0; y < data[i]['007']['leave'].length; y++) {
          var leave = this.leaveList.filter(function (leave: any) {
            return leave.id == data[i]['007']['leave'][y];
          });
          if (leave.length > 0) {
            this.reqTypeForm.workingdayleavetype.push({
              name: leave[0]['name'],
              id: leave[0]['id'],
            });
          }
        }
      }

      // Pre, Post, Today Submit
      if (data[i]['008']) {
        this.reqTypeForm.showpreposttodaysubmit = true;
        data[i]['008']['list'].map((item: any) => {
          if (item['1'] != undefined) {
            this.reqTypeForm.showpresubmit = true;
            this.reqTypeForm.precustom =
              item['custom'] == 'true' ? true : false;
            if (item['1'].length > 0) {
              this.reqTypeForm.presubmit = true;
              if (
                item['1'].length == 1 &&
                item['1'][0]['from'] == '' &&
                item['1'][0]['to'] == ''
              ) {
                this.reqTypeForm.presubmittype = '1';
                this.reqTypeForm.presubmitday = item['1'][0]['value'];
              } else {
                this.reqTypeForm.presubmittype = '2';
                this.preSubmitConfig = [];
                for (var i = 0; i < item['1'].length; i++) {
                  this.preSubmitConfig.push({
                    from: item['1'][i].from,
                    to: item['1'][i].to,
                    day: item['1'][i].value,
                  });
                }
              }
              for (var i = 0; i < item['count'].length; i++) {
                if (item['count'][i] == '1') {
                  this.reqTypeForm.pregazaettedholiday = true;
                }
                if (item['count'][i] == '2') {
                  this.reqTypeForm.presaturday = true;
                }
                if (item['count'][i] == '3') {
                  this.reqTypeForm.presunday = true;
                }
              }
            }
          }
          if (item['2'] != undefined) {
            this.reqTypeForm.showpostsubmit = true;
            this.reqTypeForm.postcustom =
              item['custom'] == 'true' ? true : false;
            if (item['2'].length > 0) {
              this.reqTypeForm.postsubmit = true;
              if (
                item['2'].length == 1 &&
                item['2'][0]['from'] == '' &&
                item['2'][0]['to'] == ''
              ) {
                this.reqTypeForm.postsubmittype = '1';
                this.reqTypeForm.postsubmitday = item['2'][0]['value'];
              } else {
                this.reqTypeForm.postsubmittype = '2';
                this.postSubmitConfig = [];
                for (var i = 0; i < item['2'].length; i++) {
                  this.postSubmitConfig.push({
                    from: item['2'][i].from,
                    to: item['2'][i].to,
                    day: item['2'][i].value,
                  });
                }
              }
              for (var i = 0; i < item['count'].length; i++) {
                if (item['count'][i] == '1') {
                  this.reqTypeForm.postgazaettedholiday = true;
                }
                if (item['count'][i] == '2') {
                  this.reqTypeForm.postsaturday = true;
                }
                if (item['count'][i] == '3') {
                  this.reqTypeForm.postsunday = true;
                }
              }
            }
          }
          if (item['3'] != undefined) {
            this.reqTypeForm.showtodaysubmit = true;
            // console.warn('today submit')
            if (item['3'] == 'true') {
              this.reqTypeForm.todaysubmit = true;
            } else {
              this.reqTypeForm.todaysubmit = false;
            }
          }
        });
      }

      // Pair leave
      if (data[i]['009']) {
        this.reqTypeForm.pairleave = true;
        if (data[i]['009']['type'][0] == '1') {
          this.reqTypeForm.pairleavetype = '1';
        } else {
          this.reqTypeForm.pairleavetype = '2';
          this.tempPairLeave = data[i]['009']['leave'];
          for (let x = 0; x < data[i]['009']['leave'].length; x++) {
            var leave = this.leaveList.filter(function (leave: any) {
              return leave.id == data[i]['009']['leave'][x];
            });
            if (leave.length > 0) {
              this.reqTypeForm.pairleavecustomtype.push({
                name: leave[0]['name'],
                id: leave[0]['id'],
              });
            }
          }
        }
      }

      // Leave Counting
      if (data[i]['010']) {
        // this.reqTypeForm.leavecounting = true;
        // if (data[i]['010']['type'].includes('1')) {
        //   this.reqTypeForm.leavecountingpreoff = true;
        // }
        // if (data[i]['010']['type'].includes('2')) {
        //   this.reqTypeForm.leavecountingpostoff = true;
        // }
        // if (data[i]['010']['count'].includes('1')) {
        //   this.reqTypeForm.leavedurationgazaettedholiday = true;
        // }
        // if (data[i]['010']['count'].includes('2')) {
        //   this.reqTypeForm.leavedurationsaturday = true;
        // }
        // if (data[i]['010']['count'].includes('3')) {
        //   this.reqTypeForm.leavedurationsunday = true;
        // }
        // leave counting
        if (data[i]['010']['type'] != undefined) {
          this.reqTypeForm.leavecounting = true;
          if (data[i]['010']['type'].length > 0) {
            if (data[i]['010']['type'].includes('1')) {
              this.reqTypeForm.leavecountingpreoff = true;
            }
            if (data[i]['010']['type'].includes('2')) {
              this.reqTypeForm.leavecountingpostoff = true;
            }
            if (data[i]['010']['count'].includes('1')) {
              this.reqTypeForm.leavedurationgazaettedholiday = true;
            }
          }
        }
        // leave duration
        if (data[i]['010']['count'] != undefined) {
          this.reqTypeForm.leaveduration = true;
          if (data[i]['010']['count'].length > 0) {
            if (data[i]['010']['count'].includes('1')) {
              this.reqTypeForm.leavedurationgazaettedholiday = true;
            }
            if (data[i]['010']['count'].includes('2')) {
              this.reqTypeForm.leavedurationsaturday = true;
            }
            if (data[i]['010']['count'].includes('3')) {
              this.reqTypeForm.leavedurationsunday = true;
            }
          }
        }
      }

      // Limitations Day
      if (data[i]['011']) {
        this.reqTypeForm.limitationday = true;
        if (data[i]['011']['type'].includes('1')) {
          this.reqTypeForm.limitationdaypreoff = true;
        }
        if (data[i]['011']['type'].includes('2')) {
          this.reqTypeForm.limitationdaypostoff = true;
        }
        this.reqTypeForm.limitationdayweekday = data[i]['011']['day'];
      }

      // Decrease Service Year
      // Reduce Service Year
      if (data[i]['012']) {
        // this.reqTypeForm.decreaseserviceyear = true;
        this.reqTypeForm.showreduceserviceyear = true;
        this.reqTypeForm.leavecount = data[i]['012']['count'];
        this.reqTypeForm.serviceyearday = data[i]['012']['day'];
        console.log('Here');
        console.log(data[i]['012']['value']);
        if (
          data[i]['012']['value'] == '' ||
          data[i]['012']['value'] == 'false'
        ) {
          this.reqTypeForm.reduceserviceyear = false;
        } else {
          this.reqTypeForm.reduceserviceyear = true;
        }
      }

      // Maternity
      if (data[i]['013']) {
        this.reqTypeForm.maternity = true;
        this.reqTypeForm.maternitybeforeweek = data[i]['013']['before'];
        this.reqTypeForm.maternityafterweek = data[i]['013']['after'];
      }

      // Paternity
      if (data[i]['014']) {
        this.reqTypeForm.paternity = true;
        this.reqTypeForm.paternitybeforeweek = data[i]['014']['before'];
        this.reqTypeForm.paternityafterweek = data[i]['014']['after'];
      }

      // Compassionate
      if (data[i]['015']) {
        this.reqTypeForm.compassionate = true;
        this.reqTypeForm.remark = data[i]['015']['remark'];
      }

      // Encashment On
      if (data[i]['016']) {
        this.reqTypeForm.encashmenton = true;
      }
      // Prorated
      if (data[i]['017']) {
        this.reqTypeForm.showprorated = true;
        this.reqTypeForm.prorated = data[i]['017']['value'] != '';
        this.reqTypeForm.proratedmethod = data[i]['017']['value'];
      }
      // Rounding Policy
      if (data[i]['018']) {
        this.reqTypeForm.showroundingpolicy = true;
        this.reqTypeForm.roundingpolicy = data[i]['018']['value'] != '';
        this.reqTypeForm.roundingpolicylist = data[i]['018']['value'];
      }

      // Limited Month and Limited Day
      if (data[i]['020']) {
        this.reqTypeForm.showlimited = true;
        this.reqTypeForm.limitedmonth = data[i]['020']['month'];
        this.reqTypeForm.limitedday = data[i]['020']['day'];
      }

      // Prorated Check box
      if (data[i]['021']) {
        this.reqTypeForm.showproratedcheckbox = true;
        if (data[i]['021']['value'] == 'true') {
          this.reqTypeForm.proratedchecked = true;
        }
      }
    }
  }

  sortData(sort: Sort) {}

  cancel() {
    if (this.isWfhPolicy) {
      this.cancelEvent.emit({ cancel: true, policy: {} });
    }
    this.tempPairLeave = {};
    this.tempNotCountedLeave = {};
    this.policyname = '';
    this.reqTypeFormSubmitted = false;
    this.policy = {};
    this.reqTypeForm = {
      policyid: '',
      // Leave Policy Data
      showserviceyear: false,
      showmaxleavepermonth: false,
      showmaxleaveperday: false,
      showattachfile: false,
      showunpaidfile: false,
      showreduceserviceyear: false,
      showencashment: false,
      showminleaveperday: false,
      showpreposttodaysubmit: false,
      showpresubmit: false,
      showpostsubmit: false,
      showtodaysubmit: false,
      showprorated: false,
      showroundingpolicy: false,
      serviceyear: '',
      carry: false,
      carryyear: '',
      maxleavepermonth: '',
      maxleaveperday: '',
      minleaveperday: '',
      attachfile: false,
      unpaid: false,
      encashment: false,
      reduceserviceyear: false,
      prorated: false,
      proratedmethod: '',
      roundingpolicy: false,
      roundingpolicylist: '',
      workingday: false,
      workingdayleavetype: [],
      workingdaygazaettedholiday: false,
      workingdaysaturday: false,
      workingdaysunday: false,
      presubmit: false,
      postsubmit: false,
      todaysubmit: false,
      presubmittype: '1',
      precustom: false,
      postcustom: false,
      presubmitday: '',
      presubmitcount: [],
      postsubmittype: '1',
      postsubmitday: '',
      postsubmitcount: [],
      postgazaettedholiday: false,
      postsaturday: false,
      postsunday: false,
      pregazaettedholiday: false,
      presaturday: false,
      presunday: false,
      pairleave: false,
      pairleavetype: '1',
      pairleavecustomtype: [],
      leavecounting: false,
      leaveduration: false,
      leavecountingpreoff: false,
      leavecountingpostoff: false,
      leavedurationgazaettedholiday: false,
      leavedurationsaturday: false,
      leavedurationsunday: false,
      decreaseserviceyear: false,
      leavecount: '',
      serviceyearday: '',
      limitationday: false,
      limitationdaypreoff: false,
      limitationdaypostoff: false,
      limitationdayweekday: [],
      maternity: false,
      maternitybeforeweek: '',
      maternityafterweek: '',
      paternity: false,
      paternitybeforeweek: '',
      paternityafterweek: '',
      compassionate: false,
      compassionateremark: '',
      encashmenton: false,
      remark: '',
      showlimited: false,
      limitedmonth: '',
      limitedday: '',
      showproratedcheckbox: false,
      proratedchecked: false,
    };
    this.isEditing = false;
  }

  submitTypeChange(type: string) {
    if (type == 'pre') {
      this.reqTypeForm.postsubmit = false;
      this.reqTypeForm.todaysubmit = false;
    } else if (type == 'post') {
      this.reqTypeForm.presubmit = false;
      this.reqTypeForm.todaysubmit = false;
    } else {
      this.reqTypeForm.presubmit = false;
      this.reqTypeForm.postsubmit = false;
    }
  }

  addSubmitConfig(type: string) {
    if (type == 'pre') {
      this.preSubmitConfig.push({
        from: '',
        to: '',
        day: '',
      });
    } else {
      this.postSubmitConfig.push({
        from: '',
        to: '',
        day: '',
      });
    }
  }
  removeSubmitConfig(index: number, type: string) {
    if (type == 'pre') {
      if (this.preSubmitConfig.length > 1) {
        this.preSubmitConfig.splice(index, 1);
      }
    } else {
      if (this.postSubmitConfig.length > 1) {
        this.postSubmitConfig.splice(index, 1);
      }
    }
  }

  addLimitations(event: any, value: any) {
    if (event.target.checked == true) {
      this.reqTypeForm.limitationdayweekday.push(value);
    } else {
      if (this.reqTypeForm.limitationdayweekday != 0) {
        for (var i = 0; i < this.reqTypeForm.limitationdayweekday.length; i++) {
          if (this.reqTypeForm.limitationdayweekday[i] == value) {
            this.reqTypeForm.limitationdayweekday.splice(i, 1);
          }
        }
      }
    }
    // console.log(this.reqTypeForm.limitationdayweekday);
  }

  checkMaxForMonth(month: any) {
    if (month.value > month.max) {
      month.value = month.max;
    }
  }

  submitPolicy() {
    if (!this.validateLeaveConfig()) {
      return;
    }

    this.savePolicy();
  }

  savePolicy() {
    this.saveLoading = true;
    const data = {
      orgid: this.orgID,
      policyid: this.isWfhPolicy ? '' : this.reqTypeForm.policyid,
      policylist: this.getPolicy(),
      requesttypeid: this.isWfhPolicy ? this.wfhData.requesttypeid : '',
    };
    // this.saveLoading = false;
    // console.log(data);
    // return;
    this.subscriptions.sink = this.kunyekService
      .editLeavePolicy(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (this.isWfhPolicy) {
              this.cancelEvent.emit({
                cancel: false,
                policy: this.getPolicy(),
              });
            }
            this.getPolicyList();
            this.cancel();
          } else {
            this.messageService.openSnackBar(
              res.status || res.message || 'Server Error',
              'warn'
            );
          }
          this.saveLoading = false;
        },
        (err) => {
          this.saveLoading = false;
        }
      );
  }

  getPolicy() {
    var policy: any = [];

    policy = [];

    // Service Year
    if (this.reqTypeForm.showserviceyear) {
      policy.push({
        // service year
        '001': {
          value:
            this.reqTypeForm.serviceyear == null
              ? ''
              : this.reqTypeForm.serviceyear.toString(),
        },
      });
    }

    if (this.reqTypeForm.showmaxleavepermonth) {
      policy.push({
        // Max leave taken day per month
        '002': {
          value:
            this.reqTypeForm.maxleavepermonth == null
              ? ''
              : this.reqTypeForm.maxleavepermonth.toString(),
        },
      });
    }

    if (this.reqTypeForm.showmaxleaveperday) {
      policy.push({
        // Max leave taken day at once
        '003': {
          value:
            this.reqTypeForm.maxleaveperday == null
              ? ''
              : this.reqTypeForm.maxleaveperday.toString(),
        },
      });
    }

    if (this.reqTypeForm.showminleaveperday) {
      policy.push({
        // Min leave taken day at once
        '006': {
          value:
            this.reqTypeForm.minleaveperday == null
              ? ''
              : this.reqTypeForm.minleaveperday.toString(),
        },
      });
    }

    // Carry
    if (this.reqTypeForm.showcarry) {
      policy.push({
        '004': {
          value: this.reqTypeForm.carry ? 'true' : 'false',
          max:
            this.reqTypeForm.carryyear == null || this.reqTypeForm.carry == false
              ? ''
              : this.reqTypeForm.carryyear.toString(),
        },
      });
    }
    // Attach
    if (this.reqTypeForm.showattachfile) {
      if (this.reqTypeForm.attachfile) {
        policy.push({
          '005': {
            value: 'true',
          },
        });
      } else {
        policy.push({
          '005': {
            value: 'false',
          },
        });
      }
    }
    // Unpaid
    if (this.reqTypeForm.showunpaidfile) {
      if (this.reqTypeForm.unpaid) {
        policy.push({
          '019': {
            value: 'true',
          },
        });
      } else {
        policy.push({
          '019': {
            value: 'false',
          },
        });
      }
    }

    // Encashment
    if (this.reqTypeForm.showencashment) {
      if (this.reqTypeForm.encashment) {
        policy.push({
          '016': {
            value: 'true',
          },
        });
      } else {
        policy.push({
          '016': {
            value: 'false',
          },
        });
      }
    }
    // Working Day
    if (this.reqTypeForm.workingday) {
      console.log(this.reqTypeForm.workingdayleavetype);
      var tempday: any = [];
      this.workingDayMonthData.map((month: any) => {
        tempday.push({
          [month.id]: month.value.toString(),
        });
      });
      var countlist: any = [];
      if (this.reqTypeForm.workingdaygazaettedholiday) {
        countlist.push('1');
      }
      if (this.reqTypeForm.workingdaysaturday) {
        countlist.push('2');
      }
      if (this.reqTypeForm.workingdaysunday) {
        countlist.push('3');
      }
      policy.push({
        '007': {
          day: tempday,
          count: countlist,
          leave:
            this.reqTypeForm.workingdayleavetype.length == 0
              ? []
              : // error
                this.reqTypeForm.workingdayleavetype.map((x: any) => {
                  return x.id;
                }),
          // : this.reqTypeForm.workingdayleavetype
        },
      });
    }

    if (this.reqTypeForm.showpreposttodaysubmit) {
      if (
        this.reqTypeForm.showpresubmit &&
        this.reqTypeForm.showpostsubmit &&
        this.reqTypeForm.showtodaysubmit
      ) {
        var submitData = [];
        if (this.reqTypeForm.presubmit) {
          var preSubmitData: any = [];
          var preSubmitCount: any = [];
          if (this.reqTypeForm.presubmittype == '1') {
            preSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.presubmitday.toString(),
            });
          }
          if (this.reqTypeForm.presubmittype == '2') {
            for (var i = 0; i < this.preSubmitConfig.length; i++) {
              preSubmitData.push({
                from: this.preSubmitConfig[i].from.toString(),
                to: this.preSubmitConfig[i].to.toString(),
                value: this.preSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.pregazaettedholiday) {
            preSubmitCount.push('1');
          }
          if (this.reqTypeForm.presaturday) {
            preSubmitCount.push('2');
          }
          if (this.reqTypeForm.presunday) {
            preSubmitCount.push('3');
          }
          submitData.push({
            '1': preSubmitData,
            count: preSubmitCount,
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.presubmit) {
          submitData.push({
            '1': [],
            count: [],
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        }

        if (this.reqTypeForm.postsubmit) {
          var postSubmitData: any = [];
          var postSubmitCount: any = [];
          if (this.reqTypeForm.postsubmittype == '1') {
            postSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.postsubmitday.toString(),
            });
          }
          if (this.reqTypeForm.postsubmittype == '2') {
            for (var i = 0; i < this.postSubmitConfig.length; i++) {
              postSubmitData.push({
                from: this.postSubmitConfig[i].from.toString(),
                to: this.postSubmitConfig[i].to.toString(),
                value: this.postSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.postgazaettedholiday) {
            postSubmitCount.push('1');
          }
          if (this.reqTypeForm.postsaturday) {
            postSubmitCount.push('2');
          }
          if (this.reqTypeForm.postsunday) {
            postSubmitCount.push('3');
          }
          submitData.push({
            '2': postSubmitData,
            count: postSubmitCount,
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.postsubmit) {
          submitData.push({
            '2': [],
            count: [],
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        }

        submitData.push({
          '3': this.reqTypeForm.todaysubmit ? 'true' : 'false',
        });

        policy.push({
          '008': {
            list: submitData,
          },
        });
      }

      if (
        !this.reqTypeForm.showpresubmit &&
        this.reqTypeForm.showpostsubmit &&
        this.reqTypeForm.showtodaysubmit
      ) {
        var submitData = [];
        if (this.reqTypeForm.postsubmit) {
          var postSubmitData: any = [];
          var postSubmitCount: any = [];
          if (this.reqTypeForm.postsubmittype == '1') {
            postSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.postsubmitday.toString(),
            });
          }
          if (this.reqTypeForm.postsubmittype == '2') {
            for (var i = 0; i < this.postSubmitConfig.length; i++) {
              postSubmitData.push({
                from: this.postSubmitConfig[i].from.toString(),
                to: this.postSubmitConfig[i].to.toString(),
                value: this.postSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.postgazaettedholiday) {
            postSubmitCount.push('1');
          }
          if (this.reqTypeForm.postsaturday) {
            postSubmitCount.push('2');
          }
          if (this.reqTypeForm.postsunday) {
            postSubmitCount.push('3');
          }
          submitData.push({
            '2': postSubmitData,
            count: postSubmitCount,
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.postsubmit) {
          submitData.push({
            '2': [],
            count: [],
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        }

        submitData.push({
          '3': this.reqTypeForm.todaysubmit ? 'true' : 'false',
        });

        policy.push({
          '008': {
            list: submitData,
          },
        });
      }

      if (
        this.reqTypeForm.showpresubmit &&
        !this.reqTypeForm.showpostsubmit &&
        this.reqTypeForm.showtodaysubmit
      ) {
        var submitData = [];
        if (this.reqTypeForm.presubmit) {
          var preSubmitData: any = [];
          var preSubmitCount: any = [];
          if (this.reqTypeForm.presubmittype == '1') {
            preSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.presubmitday.toString(),
            });
          }
          if (this.reqTypeForm.presubmittype == '2') {
            for (var i = 0; i < this.preSubmitConfig.length; i++) {
              preSubmitData.push({
                from: this.preSubmitConfig[i].from.toString(),
                to: this.preSubmitConfig[i].to.toString(),
                value: this.preSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.pregazaettedholiday) {
            preSubmitCount.push('1');
          }
          if (this.reqTypeForm.presaturday) {
            preSubmitCount.push('2');
          }
          if (this.reqTypeForm.presunday) {
            preSubmitCount.push('3');
          }
          submitData.push({
            '1': preSubmitData,
            count: preSubmitCount,
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.presubmit) {
          submitData.push({
            '1': [],
            count: [],
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        }
        submitData.push({
          '3': this.reqTypeForm.todaysubmit ? 'true' : 'false',
        });

        policy.push({
          '008': {
            list: submitData,
          },
        });
      }

      if (
        this.reqTypeForm.showpresubmit &&
        this.reqTypeForm.showpostsubmit &&
        !this.reqTypeForm.showtodaysubmit
      ) {
        submitData = [];
        if (this.reqTypeForm.presubmit) {
          var preSubmitData: any = [];
          var preSubmitCount: any = [];
          if (this.reqTypeForm.presubmittype == '1') {
            preSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.presubmitday.toString(),
            });
          }
          if (this.reqTypeForm.presubmittype == '2') {
            for (var i = 0; i < this.preSubmitConfig.length; i++) {
              preSubmitData.push({
                from: this.preSubmitConfig[i].from.toString(),
                to: this.preSubmitConfig[i].to.toString(),
                value: this.preSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.pregazaettedholiday) {
            preSubmitCount.push('1');
          }
          if (this.reqTypeForm.presaturday) {
            preSubmitCount.push('2');
          }
          if (this.reqTypeForm.presunday) {
            preSubmitCount.push('3');
          }
          submitData.push({
            '1': preSubmitData,
            count: preSubmitCount,
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.presubmit) {
          submitData.push({
            '1': [],
            count: [],
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        }

        if (this.reqTypeForm.postsubmit) {
          var postSubmitData: any = [];
          var postSubmitCount: any = [];
          if (this.reqTypeForm.postsubmittype == '1') {
            postSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.postsubmitday.toString(),
            });
          }
          if (this.reqTypeForm.postsubmittype == '2') {
            for (var i = 0; i < this.postSubmitConfig.length; i++) {
              postSubmitData.push({
                from: this.postSubmitConfig[i].from.toString(),
                to: this.postSubmitConfig[i].to.toString(),
                value: this.postSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.postgazaettedholiday) {
            postSubmitCount.push('1');
          }
          if (this.reqTypeForm.postsaturday) {
            postSubmitCount.push('2');
          }
          if (this.reqTypeForm.postsunday) {
            postSubmitCount.push('3');
          }
          submitData.push({
            '2': postSubmitData,
            count: postSubmitCount,
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.postsubmit) {
          submitData.push({
            '2': [],
            count: [],
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        }
        policy.push({
          '008': {
            list: submitData,
          },
        });
      }

      if (
        this.reqTypeForm.showpresubmit &&
        !this.reqTypeForm.showpostsubmit &&
        !this.reqTypeForm.showtodaysubmit
      ) {
        submitData = [];
        if (this.reqTypeForm.presubmit) {
          var preSubmitData: any = [];
          var preSubmitCount: any = [];
          if (this.reqTypeForm.presubmittype == '1') {
            preSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.presubmitday.toString(),
            });
          }
          if (this.reqTypeForm.presubmittype == '2') {
            for (var i = 0; i < this.preSubmitConfig.length; i++) {
              preSubmitData.push({
                from: this.preSubmitConfig[i].from.toString(),
                to: this.preSubmitConfig[i].to.toString(),
                value: this.preSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.pregazaettedholiday) {
            preSubmitCount.push('1');
          }
          if (this.reqTypeForm.presaturday) {
            preSubmitCount.push('2');
          }
          if (this.reqTypeForm.presunday) {
            preSubmitCount.push('3');
          }
          submitData.push({
            '1': preSubmitData,
            count: preSubmitCount,
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.presubmit) {
          submitData.push({
            '1': [],
            count: [],
            custom: this.reqTypeForm.precustom ? 'true' : 'false',
          });
        }
        policy.push({
          '008': {
            list: submitData,
          },
        });
      }

      if (
        !this.reqTypeForm.showpresubmit &&
        this.reqTypeForm.showpostsubmit &&
        !this.reqTypeForm.showtodaysubmit
      ) {
        submitData = [];
        if (this.reqTypeForm.postsubmit) {
          var postSubmitData: any = [];
          var postSubmitCount: any = [];
          if (this.reqTypeForm.postsubmittype == '1') {
            postSubmitData.push({
              from: '',
              to: '',
              value: this.reqTypeForm.postsubmitday.toString(),
            });
          }
          if (this.reqTypeForm.postsubmittype == '2') {
            for (var i = 0; i < this.postSubmitConfig.length; i++) {
              postSubmitData.push({
                from: this.postSubmitConfig[i].from.toString(),
                to: this.postSubmitConfig[i].to.toString(),
                value: this.postSubmitConfig[i].day.toString(),
              });
            }
          }
          if (this.reqTypeForm.postgazaettedholiday) {
            postSubmitCount.push('1');
          }
          if (this.reqTypeForm.postsaturday) {
            postSubmitCount.push('2');
          }
          if (this.reqTypeForm.postsunday) {
            postSubmitCount.push('3');
          }
          submitData.push({
            '2': postSubmitData,
            count: postSubmitCount,
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        } else if (!this.reqTypeForm.postsubmit) {
          submitData.push({
            '2': [],
            count: [],
            custom: this.reqTypeForm.postcustom ? 'true' : 'false',
          });
        }
        policy.push({
          '008': {
            list: submitData,
          },
        });
      }

      if (
        !this.reqTypeForm.showpresubmit &&
        !this.reqTypeForm.showpostsubmit &&
        this.reqTypeForm.showtodaysubmit
      ) {
        submitData = [];
        submitData.push({
          '3': this.reqTypeForm.todaysubmit ? 'true' : 'false',
        });

        policy.push({
          '008': {
            list: submitData,
          },
        });
      }
    }

    // Pair leave
    if (this.reqTypeForm.pairleave) {
      policy.push({
        '009': {
          type: [this.reqTypeForm.pairleavetype],
          leave:
            this.reqTypeForm.pairleavetype == '1'
              ? []
              : this.reqTypeForm.pairleavecustomtype.map((x: any) =>
                  x.id.toString()
                ),
        },
      });
    }

    // Leave Counting && Duration
    if (this.reqTypeForm.leavecounting && this.reqTypeForm.leaveduration) {
      var leaveCountingType: any = [];
      if (this.reqTypeForm.leavecountingpreoff) {
        leaveCountingType.push('1');
      }
      if (this.reqTypeForm.leavecountingpostoff) {
        leaveCountingType.push('2');
      }
      var leavingCounting: any = [];
      if (this.reqTypeForm.leavedurationgazaettedholiday) {
        leavingCounting.push('1');
      }
      if (this.reqTypeForm.leavedurationsaturday) {
        leavingCounting.push('2');
      }
      if (this.reqTypeForm.leavedurationsunday) {
        leavingCounting.push('3');
      }
      policy.push({
        '010': {
          type: leaveCountingType,
          count: leavingCounting,
        },
      });
    }

    // Leave Counting
    if (this.reqTypeForm.leavecounting && !this.reqTypeForm.leaveduration) {
      var leaveCountingType: any = [];
      if (this.reqTypeForm.leavecountingpreoff) {
        leaveCountingType.push('1');
      }
      if (this.reqTypeForm.leavecountingpostoff) {
        leaveCountingType.push('2');
      }
      policy.push({
        '010': {
          type: leaveCountingType,
        },
      });
    }

    // Leave Duration
    if (this.reqTypeForm.leaveduration && !this.reqTypeForm.leavecounting) {
      var leavingCounting: any = [];
      if (this.reqTypeForm.leavedurationgazaettedholiday) {
        leavingCounting.push('1');
      }
      if (this.reqTypeForm.leavedurationsaturday) {
        leavingCounting.push('2');
      }
      if (this.reqTypeForm.leavedurationsunday) {
        leavingCounting.push('3');
      }
      policy.push({
        '010': {
          count: leavingCounting,
        },
      });
    }

    // Decrease Service Year
    if (this.reqTypeForm.showreduceserviceyear) {
      policy.push({
        '012': {
          count: this.reqTypeForm.leavecount.toString(),
          day: this.reqTypeForm.serviceyearday.toString(),
          value: this.reqTypeForm.reduceserviceyear ? 'true' : 'false',
        },
      });
    }

    // Limitations Day
    if (this.reqTypeForm.limitationday) {
      var limitationDayType = [];
      if (this.reqTypeForm.limitationdaypreoff) {
        limitationDayType.push('1');
      }
      if (this.reqTypeForm.limitationdaypostoff) {
        limitationDayType.push('2');
      }
      policy.push({
        '011': {
          type: limitationDayType,
          day: this.reqTypeForm.limitationdayweekday,
        },
      });
    }

    // Maternity
    if (this.reqTypeForm.maternity) {
      policy.push({
        '013': {
          before: this.reqTypeForm.maternitybeforeweek.toString(),
          after: this.reqTypeForm.maternityafterweek.toString(),
        },
      });
    }

    // Paternity
    if (this.reqTypeForm.paternity) {
      policy.push({
        '014': {
          before: this.reqTypeForm.paternitybeforeweek.toString(),
          after: this.reqTypeForm.paternityafterweek.toString(),
        },
      });
    }

    // Compassionate
    if (this.reqTypeForm.compassionate) {
      policy.push({
        '015': {
          remark: this.reqTypeForm.remark.toString(),
        },
      });
    }

    // Encashment On
    if (this.reqTypeForm.showencashment) {
      policy.push({
        '016': {
          value: this.reqTypeForm.encashment ? 'true' : 'false',
        },
      });
    }

    // Prorated
    if (this.reqTypeForm.showprorated) {
      policy.push({
        '017': {
          value: this.reqTypeForm.prorated
            ? this.reqTypeForm.proratedmethod
            : '',
        },
      });
    }

    // Rounding Policy
    if (this.reqTypeForm.showroundingpolicy) {
      policy.push({
        '018': {
          value: this.reqTypeForm.roundingpolicy
            ? this.reqTypeForm.roundingpolicylist
            : '',
        },
      });
    }

    // Limited Month and Limited Day
    if (this.reqTypeForm.showlimited) {
      policy.push({
        '020': {
          month:
            this.reqTypeForm.limitedmonth == null
              ? ''
              : this.reqTypeForm.limitedmonth.toString(),
          day:
            this.reqTypeForm.limitedday == null
              ? ''
              : this.reqTypeForm.limitedday.toString(),
        },
      });
    }

    // prorated check box
    if (this.reqTypeForm.showproratedcheckbox) {
      policy.push({
        '021': {
          value: this.reqTypeForm.proratedchecked ? 'true' : 'false',
        },
      });
    }

    return policy;
  }

  validateLeaveConfig() {
    this.reqTypeFormSubmitted = true;

    // Below Codes are for the Old Flow of Leave Policy
    // Working day
    if (this.reqTypeForm.workingday == true) {
      for (var i = 0; i < this.workingDayMonthData.length; i++) {
        if (
          this.workingDayMonthData[i].value == '' ||
          this.workingDayMonthData[i].value == null
        ) {
          this.messageService.openSnackBar("Working day can't blank.", 'warn');
          return false;
        }
        if (
          Number(this.workingDayMonthData[i].value) >
          this.workingDayMonthData[i].max
        ) {
          this.messageService.openSnackBar(
            'Invalid value for ' +
              this.workingDayMonthData[i].name +
              ' in Working Day.',
            'warn'
          );
          return false;
        }
      }
    }

    // Carry Year
    if(this.reqTypeForm.carry == true){
      if(this.reqTypeForm.carryyear == '' || this.reqTypeForm.carryyear == null){
        this.messageService.openSnackBar("Carry year can't blank.", 'warn');
        return false;
      }
    }

    // presubmit day
    if (this.reqTypeForm.presubmit) {
      console.log('check pre submit day');

      if (this.reqTypeForm.presubmittype == '1') {
        if (
          this.reqTypeForm.presubmitday === '' ||
          this.reqTypeForm.presubmitday === null
        ) {
          this.messageService.openSnackBar(
            "Pre submit day can't blank.",
            'warn'
          );
          return false;
        }
      }
      if (this.reqTypeForm.presubmittype == '2') {
        for (var i = 0; i < this.preSubmitConfig.length; i++) {
          console.log(this.preSubmitConfig[i]);
          if (
            this.preSubmitConfig[i].from === '' ||
            this.preSubmitConfig[i].from === null ||
            this.preSubmitConfig[i].to === '' ||
            this.preSubmitConfig[i].to === null ||
            this.preSubmitConfig[i].day === '' ||
            this.preSubmitConfig[i].day === null
          ) {
            this.messageService.openSnackBar(
              "Pre submit day can't blank.",
              'warn'
            );
            return false;
          }
        }
      }
    }
    // post submit day
    if (this.reqTypeForm.postsubmit) {
      if (this.reqTypeForm.postsubmittype == '1') {
        if (
          this.reqTypeForm.postsubmitday === '' ||
          this.reqTypeForm.postsubmitday === null
        ) {
          this.messageService.openSnackBar(
            "Post submit day can't blank.",
            'warn'
          );
          return false;
        }
      }
      if (this.reqTypeForm.postsubmittype == '2') {
        for (var i = 0; i < this.postSubmitConfig.length; i++) {
          if (
            this.postSubmitConfig[i].from === '' ||
            this.postSubmitConfig[i].from === null ||
            this.postSubmitConfig[i].to === '' ||
            this.postSubmitConfig[i].to === null ||
            this.postSubmitConfig[i].day === '' ||
            this.postSubmitConfig[i].day === null
          ) {
            this.messageService.openSnackBar(
              "Post submit day can't blank.",
              'warn'
            );
            return false;
          }
        }
      }
    }
    // pair leave
    if (this.reqTypeForm.pairleave) {
      if (this.reqTypeForm.pairleavetype == '2') {
        if (
          this.reqTypeForm.pairleavecustomtype.length == 0 ||
          this.reqTypeForm.pairleavecustomtype == null
        ) {
          this.messageService.openSnackBar("Pair leave can't blank.", 'warn');
          return false;
        }
      }
    }
    // Decrease Service Year
    if (this.reqTypeForm.decreaseserviceyear) {
      if (
        this.reqTypeForm.leavecount == '' ||
        this.reqTypeForm.leavecount == null ||
        this.reqTypeForm.serviceyearday == '' ||
        this.reqTypeForm.serviceyearday == null
      ) {
        this.messageService.openSnackBar(
          "Decrease service year can't blank.",
          'warn'
        );
        return false;
      }
    }
    // Maternity
    if (this.reqTypeForm.maternity) {
      if (
        this.reqTypeForm.maternitybeforeweek == '' ||
        this.reqTypeForm.maternitybeforeweek == null ||
        this.reqTypeForm.maternityafterweek == '' ||
        this.reqTypeForm.maternityafterweek == null
      ) {
        this.messageService.openSnackBar("Maternity can't blank.", 'warn');
        return false;
      }
    }
    //Paternity
    if (this.reqTypeForm.paternity) {
      if (
        this.reqTypeForm.paternitybeforeweek == '' ||
        this.reqTypeForm.paternitybeforeweek == null ||
        this.reqTypeForm.paternityafterweek == '' ||
        this.reqTypeForm.paternityafterweek == null
      ) {
        this.messageService.openSnackBar("Paternity can't blank.", 'warn');
        return false;
      }
    }
    // Compassionate
    if (this.reqTypeForm.compassionate) {
      if (this.reqTypeForm.remark == '' || this.reqTypeForm.remark == null) {
        this.messageService.openSnackBar("Remark can't blank.", 'warn');
        return false;
      }
    }

    // Prorated
    if (this.reqTypeForm.prorated) {
      if (this.reqTypeForm.proratedmethod == '') {
        this.messageService.openSnackBar(
          'Prorated method cannot be blank.',
          'warn'
        );
        return false;
      }
    }

    // Rounding Policy
    if (this.reqTypeForm.roundingpolicy) {
      if (this.reqTypeForm.roundingpolicylist == '') {
        this.messageService.openSnackBar(
          'Rounding Policy cannot be blank.',
          'warn'
        );
        return false;
      }
    }

    return true;
  }

  getPairLeave() {
    if (this.reqTypeForm.pairleavetype == '2') {
      console.log(this.tempPairLeave);
      for (let x = 0; x < this.tempPairLeave.length; x++) {
        var leave = this.leaveList.filter((leave: any) => {
          return leave.id == this.tempPairLeave[x];
        });
        if (leave.length > 0) {
          console.log(leave);
          this.reqTypeForm.pairleavecustomtype.push({
            name: leave[0]['name'],
            id: leave[0]['id'],
          });
        }
      }
    }
    if (this.reqTypeForm.workingday) {
      for (let x = 0; x < this.tempNotCountedLeave.length; x++) {
        var leave = this.leaveList.filter( (leave: any) => {
          return leave.id == this.tempNotCountedLeave[x];
        });
        if (leave.length > 0) {
          this.reqTypeForm.workingdayleavetype.push({
            name: leave[0]['name'],
            id: leave[0]['id'],
          });
        }
      }
    }
  }
}
