import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-hr-requisition-filter',
  templateUrl: './hr-requisition-filter.component.html',
  styleUrls: ['./hr-requisition-filter.component.scss'],
})
export class HrRequisitionFilterComponent implements OnInit {
  gettingMemberPosition: boolean = false;
  getTemplateLoading: boolean = false;

  statusList: any = [];
  positionList: any = [];
  departmentList: any = [];
  divisionList: any = [];
  priorityList: any = [];

  searchForm = {
    status: '',
    startdate: '',
    enddate: '',
    position: '',
    priority: '',
    department: '',
    division: '',
  };

  orgid: string = '';

  subs = new SubSink();

  constructor(
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private dialogRef: MatDialogRef<HrRequisitionFilterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.statusList = data.statusList;
    this.priorityList = data.priorityList;
    this.departmentList = data.departmentList;
    this.divisionList = data.divisionList;
    this.positionList = data.positionList;

    this.orgid = data.orgid;
    this.searchForm = data.searchForm;
    if (this.searchForm.startdate) {
      this.searchForm.startdate = this.allinoneService.formatDBToShowInEdit(
        this.searchForm.startdate
      );
    }

    if (this.searchForm.enddate) {
      this.searchForm.enddate = this.allinoneService.formatDBToShowInEdit(
        this.searchForm.enddate
      );
    }
  }

  ngOnInit(): void {
    if (this.positionList.length == 0) {
      this.getTemplates();
    }
    if (this.departmentList.length == 0 || this.divisionList.length == 0) {
      this.getMemberPosition();
    }
  }

  removeDate() {
    this.searchForm.startdate = '';
    this.searchForm.enddate = '';
  }

  getTemplates() {
    this.getTemplateLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.subs.sink = this.kunyekService.getRequisitionTemplate(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          this.positionList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.getTemplateLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.getTemplateLoading = false;
      }
    );
  }

  getMemberPosition() {
    this.gettingMemberPosition = true;
    const data = {
      orgid: this.orgid,
    };
    this.subs.sink = this.kunyekService.getMemberPosition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.departmentList = res.departmentlist;
          this.divisionList = res.divisionlist;
          this.gettingMemberPosition = false;
        } else {
          this.gettingMemberPosition = false;
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
      },
      (err) => {
        this.gettingMemberPosition = false;
      }
    );
  }

  search() {
    if((this.searchForm.startdate == '' && this.searchForm.enddate != '') || (this.searchForm.startdate != '' && this.searchForm.enddate == '')){
      this.messageService.openSnackBar('Please select both start and end date', 'warn');
      return;

    }
    this.searchForm.startdate = this.allinoneService.formatDate(
      this.searchForm.startdate
    );
    this.searchForm.enddate = this.allinoneService.formatDate(
      this.searchForm.enddate
    );
    this.dialogRef.close({
      searchForm: this.searchForm,
      departmentList: this.departmentList,
      divisionList: this.divisionList,
      positionList: this.positionList,
    });
  }

  close() {
    this.dialogRef.close({
      departmentList: this.departmentList,
      divisionList: this.divisionList,
      positionList: this.positionList,
    });
  }
}
