import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { KunyekService } from '../../shared/services/kunyek.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileEditComponent } from '../user-profile-edit/user-profile-edit.component';
// import { UserProfileComponent } from "../user-profile/user-profile.component";
declare var bootstrap: any;
@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit { 
  showFiller = false;
  sortedRootAdminMenuList: any;
  sortedMenuList: any;
  authenticate: boolean = false;
  addAttr: boolean = false;
  gettingdomain: boolean = false;
  isOpenMenu: boolean = false;
  domains: any = [];
  selected_domain: any;
  apps: any[] = [];
  appsmenu: any[] = [];
  isorgAdmin: boolean = false;
  isHRAdmin: boolean = false;
  isTransportationAdmin: boolean = false;
  orgList: any = [];
  orgListcrm: any = [];
  orgListTran: any = [];
  orgs: any = [];
  selected_org: any;
  selected_orgcrm: any;
  // selected_orghcm: any;
  selected_orgtran: any;
  role: string = '';
  appname: string = this.allinoneService.appname;
  user: any;
  changeText: any;

  settingmenulist: any = [];

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < 992) {
      this.addAttr = true;
    } else {
      this.addAttr = false;
    }
  }
  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private router: Router,
    private dialog: MatDialog
  ) { }
  async ngOnInit(): Promise<void> {
    var popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl);
    });
    this.user = this.allinoneService.profile;
    if (window.innerWidth < 992) {
      this.addAttr = true;
    }
    this.role = this.allinoneService.getRole();
    if (this.allinoneService.menus) {
      // this.apps = this.allinoneService.menus.sidebar;
      this.apps = this.allinoneService.menus;
      this.appsmenu = this.allinoneService.appmenus;
    }
    // this.getCRM();
    if (this.allinoneService.getAtoken()) {
      this.authenticate = true;
      this.domains = this.allinoneService.domains;
      this.getDomain();
      this.orgList = this.allinoneService.appView == "001" ? this.allinoneService.orgsCRm : this.allinoneService.orgs;
      this.orgListTran = this.allinoneService.tranOrgs;
      var orgID = this.allinoneService.selectedOrg;
      this.isorgAdmin = this.allinoneService.isAdminOf('org');
      this.isHRAdmin = this.allinoneService.isAdminOf('hr');
      this.isTransportationAdmin =
        this.allinoneService.isAdminOf('transportation');

      // console.log('error log');
      // console.log(orgID);


      // if (this.isorgAdmin) {

      var getOrgidmobile = this.allinoneService.getOrgMobile();
      // console.log("getorgidmobile" + getOrgidmobile);
      if (orgID) {
        var temp = this.orgList.find((x: any) => x.orgid == orgID);
        if (temp) {
          this.selected_org = temp;
        } else if (this.orgList.length > 0) {
          this.selected_org = this.orgList[0];
          this.allinoneService.isCRMServiceView = false;
          this.allinoneService.selectedOrg = this.orgList[0].orgid;
          this.allinoneService.setSelectedOrg(this.orgList[0].orgid);
        }
      } else if (this.orgList.length > 0) {
        this.selected_org = this.orgList[0];
        this.allinoneService.isCRMServiceView = false;
        this.allinoneService.selectedOrg = this.orgList[0].orgid;
        this.allinoneService.setSelectedOrg(this.orgList[0].orgid);
      }
      // }
      // if (this.isHRAdmin) {
      //   var temporgID = this.allinoneService.getSelectedHrOrg();
      //   if (temporgID) {
      //     var temp = this.orgListHCM.find((x: any) => x.orgid == temporgID);
      //     if (temp) {
      //       this.selected_orghcm = temp;
      //     }
      //   }
      // }
      if (this.isTransportationAdmin) {
        var temporgID = this.allinoneService.getSelectedTranOrg();
        if (temporgID) {
          var temp = this.orgListTran.find((x: any) => x.orgid == temporgID);
          if (temp) {
            this.selected_orgtran = temp;
          }
        }
      }
    } else {
      this.authenticate = false;
    }
    this.getCRM();
    if (
      this.router.url.startsWith('/org/services') ||
      this.router.url.startsWith('/org/crm') ||
      this.router.url.startsWith('/org/service')
    ) {
      // console.log('truetruetrue');
      this.allinoneService.isCRMServiceView = true;
      // var orgID = this.allinoneService.selectedOrgCRM;
      // await this.getPermission(orgID);
      // if (orgID) {
      //   await this.getPermission(orgID);
      // } else {
      //   if (this.orgListcrm.length > 0) {
      //     await this.getPermission(this.orgListcrm[0].orgid);
      //   }
      // }
    }
    if (
      !this.router.url.startsWith('/org/services') &&
      !this.router.url.startsWith('/org/crm') &&
      !this.router.url.startsWith('/org/service')
    ) {
      // console.log('falsefalsefalse');
      var orgID = this.allinoneService.selectedOrg;
      // if (this.isorgAdmin) {
      //   if (orgID) {
      //     var temp = this.orgList.find((x: any) => x.orgid == orgID);
      //     await this.getPermission(orgID);
      //   } else {
      //     await this.getPermission(this.orgList[0].orgid);
      //   }
      // } else {
      //   if (this.orgListcrm.length > 0) {
      //     await this.getPermission(this.orgListcrm[0].orgid);
      //   }
      // }
      this.allinoneService.isCRMServiceView = false;
    }
    // console.log(this.router.url);
    if (this.router.url == '/org/crm') {
      // console.log('remove cookie');
      this.allinoneService.setCookie('customercrm', JSON.stringify(''));
    } else {
    }


    this.settingmenulist = this.allinoneService.settingmenus;


    // console.log('nav selected org');
    // console.log(this.selected_org);

  }

  t() {
    // console.log(this.apps);
    // console.log(this.allinoneService.menus.apps);
    // console.log(this.appsmenu);



  }

  toggleSideBar() {

    this.allinoneService.expandSideBar = this.allinoneService.TeamsPermisson ? !this.allinoneService.expandSideBar : this.allinoneService.expandSideBar;
  }


  toggleKokineSideBar() {
    this.allinoneService.expandSideBar = this.allinoneService.TeamsPermisson ? !this.allinoneService.expandSideBar : this.allinoneService.expandSideBar;
  }

  getCRM() {
    this.orgListcrm = this.allinoneService.orgsCRm;
    var orgID = this.allinoneService.selectedOrgCRM;
    var getOrgidmobile = this.allinoneService.getOrgMobile();
    // console.log(getOrgidmobile);
    if (getOrgidmobile) {
      var type = this.orgListcrm
        .map((x: any) => x.orgid)
        .indexOf(getOrgidmobile);
      this.allinoneService.crmOrgAdmin = this.orgListcrm[type].admin;
      this.allinoneService.crmBlocked = this.orgListcrm[type].crmblocked;
      this.allinoneService.selected_orgcrm = this.orgListcrm[type];
      this.allinoneService.isCRMServiceView = true;
      this.allinoneService.selectedOrgCRM = this.orgListcrm[type].orgid;
      this.allinoneService.setSelectedCRM(this.orgListcrm[type].orgid);
      if (this.orgListcrm[type].admin == 'true') {
        this.selected_org = this.orgList[type];
        this.allinoneService.selectedOrg = this.orgList[type].orgid;
        this.allinoneService.setSelectedOrg(this.orgList[type].orgid);
      }
    } else {
      if (orgID) {
        var temp = this.orgListcrm.find((x: any) => x.orgid == orgID);
        if (temp) {
          // console.log(temp);
          this.allinoneService.selected_orgcrm = temp;
          this.allinoneService.crmOrgAdmin = temp.admin;
          this.allinoneService.crmBlocked = temp.crmblocked;
          this.allinoneService.isCRMServiceView = true;
        } else {
          if (this.orgListcrm.length > 0) {
            this.allinoneService.selected_orgcrm = this.orgListcrm[0];
            this.allinoneService.crmOrgAdmin = this.orgListcrm[0].admin;
            this.allinoneService.crmBlocked = this.orgListcrm[0].crmbolocked;
            this.allinoneService.isCRMServiceView = true;
            this.allinoneService.selectedOrgCRM = this.orgListcrm[0].orgid;
            this.allinoneService.setSelectedCRM(this.orgListcrm[0].orgid);
          }
        }
      } else {
        if (this.orgListcrm.length > 0) {
          this.allinoneService.selected_orgcrm = this.orgListcrm[0];
          this.allinoneService.crmOrgAdmin = this.orgListcrm[0].admin;
          this.allinoneService.crmBlocked = this.orgListcrm[0].crmblocked;
          this.allinoneService.isCRMServiceView = true;
          this.allinoneService.selectedOrgCRM = this.orgListcrm[0].orgid;
          this.allinoneService.setSelectedCRM(this.orgListcrm[0].orgid);
        }
      }
    }
  }
  goToMain() {
    this.router.navigateByUrl('/');
  }
  openMenu() {
    this.isOpenMenu = true;
  }
  closeMenu() {
    if (this.isOpenMenu) {
      this.isOpenMenu = false;
    }
  }
  openNavMenu(item: any) {
    item.isOpen = !item.isOpen;
  }
  logout() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      // console.log(dialogResult);
      if (dialogResult) {
        console.log('2');
        this.allinoneService.logout();
      }
    });
  }
  editProfile() {
    const dialog = this.dialog.open(UserProfileEditComponent, {
      width: '400px',
      height: 'auto',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: this.allinoneService.profile,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // window.location.reload();
      }
    });
  }
  callFromWhere(role: any) {
    sessionStorage.removeItem('callType');
    sessionStorage.setItem('callType', role);
  }
  setpayrollType(item: any) {
    var type = item.name;
    sessionStorage.removeItem('ptype');
    sessionStorage.setItem('ptype', type);
  }
  click(item: any) {
    if (item.open == '2') {
      window.open(item.path, '_blank');
    }
    // console.log('Click is working');
  }
  getDomain() {
    var cookieDomain: any = this.allinoneService.getDomain();
    if (cookieDomain.domainid) {
      //get domain from cookie
      var selected_domain = this.domains.find(
        (el: any) => el.domainid == cookieDomain.domainid
      );
      if (selected_domain) {
        this.selected_domain = selected_domain;
      }
      // var domain = this.domains.find((el: any) => el.domainid == "002");
      // if (domain) {
      //   domain.description = "Town Hall";
      // }
    } else {
      var domain = this.domains.find((el: any) => el.domainid == '002'); // default value - public
      if (domain) {
        // domain.description = "Town Hall";
        this.selected_domain = domain;
        this.allinoneService.setCookie(
          'domain',
          JSON.stringify(domain) || 'undefined'
        );
      } else {
        // first value - domains
        if (this.domains.length > 0) {
          this.selected_domain = this.domains[0];
          this.allinoneService.setCookie(
            'domain',
            JSON.stringify(this.domains[0]) || 'undefined'
          );
        }
      }
    }
  }

  opensetting(d: any) {
    // console.log(this.allinoneService.settingmenus);

    this.router.navigateByUrl(d.path);
  }

  openapp(menu: any) {
    // console.log('this open fun');
    // console.log(menu);
    // console.log(this.selected_domain);

    // console.log(this.allinoneService.getDomain());



    if (menu.open == '1') {
      this.router.navigateByUrl(menu.path);
    } else {
      if (menu.path == '/hxm' || menu.path == '/transportation' || menu.path == '/cs365') {
        window.open(menu.path, '_blank');
      } else {
        const uid = this.allinoneService.profile.userid;
        const url =
          menu.path +
          '/?userid=' +
          (uid.startsWith('+') ? uid.substring(1, uid.length) : uid) +
          '&username=' +
          this.allinoneService.profile.username +
          '&orgid=' +
          this.allinoneService.selectedOrg +
          // '&domain=' +
          // this.allinoneService.getDomain().shortcode + 
          '&domain=' +
          this.allinoneService.selectedOrg +
          '&atoken=' +
          this.allinoneService.getAtoken();

        window.open(url, '_blank');
      }
    }
  }

  chooseDomain(domain: any) {
    if (
      this.selected_domain &&
      this.selected_domain.domainid != domain.domainid
    ) {
      this.selected_domain = domain.description;
      this.allinoneService.setCookie('domain', JSON.stringify(domain) || '{}');
      window.location.reload();
    }
  }

  // async chooseHCMOrg(org: any) {
  //   if (this.selected_orghcm && this.selected_orghcm.orgid != org.orgid) {
  //     this.selected_orghcm = org;
  //     this.allinoneService.setSelectedHrOrg(this.selected_orghcm.orgid);
  //     this.allinoneService.selected_orgcrm = org;
  //     this.allinoneService.isCRMServiceView = false;
  //     this.allinoneService.selectedOrgCRM =
  //       this.allinoneService.selected_orgcrm.orgid;
  //     this.allinoneService.setSelectedCRM(
  //       this.allinoneService.selected_orgcrm.orgid
  //     );
  //     this.allinoneService.crmOrgAdmin = 'true';
  //     if (this.router.url.startsWith('/hxm/checkinattendances')) {
  //       this.router
  //         .navigateByUrl('/', { skipLocationChange: true })
  //         .then(() => this.router.navigate(['hxm/checkinattendances']));
  //     } else if (
  //       this.router.url.startsWith('/attendance/hxm/checkinattendances')
  //     ) {
  //       this.router
  //         .navigateByUrl('/', { skipLocationChange: true })
  //         .then(() =>
  //           this.router.navigate(['/attendance/hxm/checkinattendances'])
  //         );
  //     } else {
  //       if (
  //         this.router.url.startsWith('/hxm') ||
  //         this.router.url.startsWith('/attendance/hxm')
  //       ) {
  //         this.allinoneService.isLoading = true;
  //         await this.getPermission(this.selected_orghcm.orgid);
  //         this.allinoneService.isLoading = false;
  //       }
  //       if (this.allinoneService.isAttendanceView) {
  //         this.router.navigateByUrl('/attendance/hxm');
  //       } else {
  //         this.router.navigateByUrl('/hxm');
  //       }
  //     }
  //   }
  // }

  async chooseTranOrg(org: any) {
    if (this.selected_orgtran && this.selected_orgtran.orgid != org.orgid) {
      // console.log('org id if con');
      this.selected_orgtran = org;
      this.allinoneService.setSelectedTranOrg(this.selected_orgtran.orgid);
      this.allinoneService.selected_orgcrm = org;
      this.allinoneService.isCRMServiceView = false;
      this.allinoneService.selectedOrgCRM =
        this.allinoneService.selected_orgcrm.orgid;
      this.allinoneService.setSelectedCRM(
        this.allinoneService.selected_orgcrm.orgid
      );
      this.allinoneService.crmOrgAdmin = 'true';
      this.router.navigateByUrl(this.router.url);
    } else {
      console.log('org id else con');

    }
  }

  async chooseOrgCRM(org: any) {
    if (this.allinoneService.isOfficeView) {

      this.allinoneService.selected_orgcrm = org;
      this.allinoneService.selectedOrgCRM =
        this.allinoneService.selected_orgcrm.orgid;
      this.allinoneService.setSelectedCRM(
        this.allinoneService.selected_orgcrm.orgid
      );
      this.selected_org = org;
      this.allinoneService.selectedOrg = this.selected_org.orgid;
      this.allinoneService.setSelectedOrg(this.selected_org.orgid);

      if (this.allinoneService.isAttendanceView) {
        this.router.navigateByUrl('/attendance/office');
      } else {
        this.router.navigateByUrl('/office');
      }
      window.location.reload();
      // if (org.admin != 'false') {
      //   this.selected_org = org;
      //   this.allinoneService.selectedOrg = this.selected_org.orgid;
      //   this.allinoneService.setSelectedOrg(this.selected_org.orgid);

      //   if (this.allinoneService.isAttendanceView) {
      //     this.router.navigateByUrl('/attendance/office');
      //   } else {
      //     this.router.navigateByUrl('/office');
      //   }
      //   window.location.reload();
      // } else {
      //   this.allinoneService.isLoading = true;
      //   await this.getPermission(this.allinoneService.selected_orgcrm.orgid);
      //   this.allinoneService.isLoading = false;
      //   if (this.allinoneService.isAttendanceView) {
      //     this.router.navigateByUrl('/attendance/office');
      //   } else {
      //     this.router.navigateByUrl('/office');
      //   }
      // }
    } else {
      if (
        this.allinoneService.selected_orgcrm &&
        this.allinoneService.selected_orgcrm.orgid != org.orgid
      ) {
        this.allinoneService.selected_orgcrm = org;
        // console.log(this.allinoneService.getWebView());
        var customercare = this.allinoneService.getCustomer();
        // console.log(customercare);
        if (customercare != '') {
          console.log('Clean');
          this.allinoneService.setCookie('customercrm', JSON.stringify(''));
          this.allinoneService.crmOrgAdmin = org.admin;
          this.allinoneService.crmBlocked = org.crmblocked;
          this.allinoneService.selectedOrgCRM =
            this.allinoneService.selected_orgcrm.orgid;
          this.allinoneService.isCRMServiceView = true;
          this.allinoneService.setSelectedCRM(
            this.allinoneService.selected_orgcrm.orgid
          );
          if (this.router.url.startsWith('/org/crm')) {
            this.router.navigateByUrl('org/crm');
          } else if (this.router.url.startsWith('/org/service')) {
            this.router.navigateByUrl('org/service');
          }
        } else {
          this.allinoneService.selected_orgcrm = org;
          this.allinoneService.selectedOrgCRM =
            this.allinoneService.selected_orgcrm.orgid;
          this.allinoneService.setSelectedCRM(
            this.allinoneService.selected_orgcrm.orgid
          );
          this.selected_org = org;
          this.allinoneService.selectedOrg = this.selected_org.orgid;
          this.allinoneService.setSelectedOrg(this.selected_org.orgid);
          if (this.router.url.startsWith('/checkinattendances')) {
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['checkinattendances']));
            // this.router.navigateByUrl('redirect');
            window.location.reload();
          } else {
            this.router.navigateByUrl(this.router.url);
            window.location.reload();
          }
          // if (org.admin == 'false' && !this.allinoneService.isQrModule) {
          //   // if (this.router.url.includes('org/crm/services') || this.router.url.includes('org/crm/services/summary')) {
          //   //   this.router.navigateByUrl(this.router.url);
          //   // this.allinoneService.setCookie('customercrm', JSON.stringify(''));
          //   // } else {
          //   //   this.router.navigateByUrl('org/crm')
          //   // }
          //   console.log('adminfalse');
          //   this.allinoneService.crmOrgAdmin = org.admin;
          //   this.allinoneService.crmBlocked = org.crmblocked;
          //   this.allinoneService.selectedOrgCRM =
          //     this.allinoneService.selected_orgcrm.orgid;
          //   this.allinoneService.isCRMServiceView = true;
          //   this.allinoneService.setSelectedCRM(
          //     this.allinoneService.selected_orgcrm.orgid
          //   );
          //   this.allinoneService.isLoading = true;
          //   await this.getPermission(
          //     this.allinoneService.selected_orgcrm.orgid
          //   );
          //   this.allinoneService.isLoading = false;
          //   if (this.router.url.startsWith('/org/crm')) {
          //     this.router.navigateByUrl('org/crm');
          //   } else if (this.router.url.startsWith('/org/service')) {
          //     this.router.navigateByUrl('org/service');
          //   }
          // } else {
          //   this.allinoneService.crmOrgAdmin = org.admin;
          //   this.allinoneService.crmBlocked = org.crmblocked;
          //   this.allinoneService.selectedOrgCRM =
          //     this.allinoneService.selected_orgcrm.orgid;
          //   this.allinoneService.isCRMServiceView = true;
          //   this.allinoneService.setSelectedCRM(
          //     this.allinoneService.selected_orgcrm.orgid
          //   );
          //   console.log('url ---> ');
          //   console.log(this.allinoneService.isCRMServiceView);
          //   this.selected_org = org;
          //   this.allinoneService.selectedOrg = this.selected_org.orgid;
          //   this.allinoneService.setSelectedOrg(this.selected_org.orgid);
          //   // console.log(this.router.routeReuseStrategy)
          //   if (this.router.url.startsWith('/checkinattendances')) {
          //     this.router
          //       .navigateByUrl('/', { skipLocationChange: true })
          //       .then(() => this.router.navigate(['checkinattendances']));
          //     // this.router.navigateByUrl('redirect');
          //     window.location.reload();
          //   } else {
          //     this.router.navigateByUrl(this.router.url);
          //     window.location.reload();
          //   }
          // }
        }
      }
    }
  }


  async chooseOrg(org: any) {
    if (this.selected_org && this.selected_org.orgid != org.orgid) {
      this.selected_org = org;
      this.allinoneService.selectedOrg = this.selected_org.orgid;
      this.allinoneService.setSelectedOrg(this.selected_org.orgid);
      this.allinoneService.selected_orgcrm = org;
      this.allinoneService.isCRMServiceView = false;
      this.allinoneService.selectedOrgCRM =
        this.allinoneService.selected_orgcrm.orgid;
      this.allinoneService.setSelectedCRM(
        this.allinoneService.selected_orgcrm.orgid
      );
      this.allinoneService.crmOrgAdmin = 'true';
      // console.log(this.router.routeReuseStrategy)
      if (this.router.url.startsWith('/checkinattendances')) {
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate(['checkinattendances']));
        window.location.reload();
      } else {
        // this.router.navigateByUrl(this.router.url);
        window.location.reload();
      }
    }
  }

  getPermission(orgid: any) {
    return new Promise((resolve) => {
      const data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: orgid,
      };
      this.kunyekService.getPermissionCRM(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinoneService.setCookie(
              'getPermissionCRM',
              JSON.stringify(res.list) || 'undefined'
            );
            this.allinoneService.officePermissionList = res.officelist;
            this.allinoneService.requisitionList = res.requisition;
            this.allinoneService.hrPolicies = res.hradmin;
            this.allinoneService.officePolicies = res.office;
            resolve('success');
          } else {
            // this.messageService.openSnackBar(res.status, 'warn');
            resolve('err');
          }
        },
        (err) => {
          resolve('err');
        }
      );
    });
  }
}
