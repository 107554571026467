import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const keys  = '123456$#@$^@1ERF'; 

@Injectable({
  providedIn: 'root'
})

export class EncrDecrServiceService {

  constructor() { }

  // encrypt the value
  set(value : any){ 
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()),key,{
      keySize: 128/8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }
// decrypt the value
get(value :any) {
  var key = CryptoJS.enc.Utf8.parse(keys);
  var iv = CryptoJS.enc.Utf8.parse(keys);

  if (value == null) {
    var decrypted : any = null;
  } else if (value != null) {
    var decrypted : any = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  }

  return !value ? null : decrypted.toString(CryptoJS.enc.Utf8);
}
}
