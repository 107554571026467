<section class = "container-fluid">
  <div class="content">
    <app-breadcrumb [s_breadcrumb]="'Logistics'">
    </app-breadcrumb>
    <ng-container>
      <div class = "row g-0">
        <ng-container *ngFor="let item of menu; let index = index">
        <div class = "col-lg-3 col-md-3 col-sm-6 col-12 p-2 ps-0">
            <div class = "card d-flex p-2 custom-card" (click)="goTo(item.path)">
              <div class = "mb-2">
                <img [src]="item.icon" width="30px">
              </div>
              <div class = "name">
                {{item.name}}
              </div>
          </div>
        </div>
      </ng-container>
      </div>
    </ng-container>
  </div>
</section>

