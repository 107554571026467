import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-office-working-hour',
  templateUrl: './office-working-hour.component.html',
  styleUrls: ['./office-working-hour.component.scss'],
})
export class OfficeWorkingHourComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  isAdmin: boolean = false;
  gettingWorkingHour: boolean = false;

  orgid: string = '';
  domainid: string = '';
  showtimeFormat = 'HH:mm';
  apitimeFormat = 'hh:mm:ss aa';
  
  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = '' + this.d.getFullYear() + '/' + this.month+ "/" + this.day;

  whlist: any = [
    { day: 'Sunday', starttime: '', endtime: '', checked: false },
    { day: 'Monday', starttime: '', endtime: '', checked: false },
    { day: 'Tuesday', starttime: '', endtime: '', checked: false },
    { day: 'Wednesday', starttime: '', endtime: '', checked: false },
    { day: 'Thursday', starttime: '', endtime: '', checked: false },
    { day: 'Friday', starttime: '', endtime: '', checked: false },
    { day: 'Saturday', starttime: '', endtime: '', checked: false },
  ];

  organizations: any = [];

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
    
  }

  ngOnInit(): void {
    this.domainid = this.allinoneService.getDomain().domainid;
    if (this.isAdmin) {
      // this.organizations = this.allinoneService.orgs;
      // this.orgid = this.allinoneService.selectedOrg
      //   ? this.allinoneService.selectedOrg
      //   : this.organizations[0].orgid;

      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getWorkingHour();
  }

  gotoOffice() {
    this.router.navigateByUrl(`adminconsole/office`);
  }

  getWorkingHour() {
    this.gettingWorkingHour = true;
    var data = {
      orgid: this.orgid,
      domainid: this.domainid,
      roleid: this.allinoneService.getSelectedRole(),
    };
    this.kunyekService.getWorkingHour(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (res.whlist.length > 0) {
            this.whlist = res.whlist;
          }
          this.whlist.map((x: any) => {
            if (x.starttime == '') {
              x.starttime = '09:00';
            } else {
              x.starttime = formatDate(
                this.todayDate + ' ' + x.starttime,
                this.showtimeFormat,
                'en-US'
              ).toString();
            }
            if (x.endtime == '') {
              x.endtime = '17:30';
            } else {
              x.endtime = formatDate(
                this.todayDate + ' ' + x.endtime,
                this.showtimeFormat,
                'en-US'
              ).toString();
            }
          });
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
        }
        this.gettingWorkingHour = false;
      },
      (err) => {
        this.gettingWorkingHour = false;
      }
    );
  }

  saveWorkingHour() {
    // for (let i = 0; i < this.whlist.length; i++) {
    //   if (
    //     this.whlist[i].checked &&
    //     this.whlist[i].endtime < this.whlist[i].starttime
    //   ) {
    //     return this.messageService.openSnackBar(
    //       'Please choose valid time for all days.',
    //       'warn'
    //     );
    //     break;
    //   }
    // }
    this.isLoading = true;

    this.whlist

    var finalWhList : any[] = [];
    this.whlist.map((x: any) => {
      var temp = {
        ...x,
        starttime : formatDate(
          this.todayDate + ' ' + x.starttime,
          this.apitimeFormat,
          'en-US'
        ).toString(),
        endtime : formatDate(
          this.todayDate + ' ' + x.endtime,
          this.apitimeFormat,
          'en-US'
        ).toString(),
      }
      finalWhList.push(temp);
    })

    
    var data = {
      orgid: this.orgid,
      domainid: this.domainid,
      whlist: finalWhList,
      roleid: this.allinoneService.getSelectedRole(),
    };


    this.kunyekService.saveWorkingHour(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.messageService.openSnackBar('Saved Successfully.', 'success');
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
}
