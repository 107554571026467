import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
@Component({
  selector: 'app-office-choose-type',
  templateUrl: './office-choose-type.component.html',
  styleUrls: ['./office-choose-type.component.scss']
})
export class OfficeChooseTypeComponent implements OnInit {

  @ViewChild('chatPS') chatPS!: ElementRef;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };


  searchingCustomer: boolean = false;
  searchCustomerCancel: boolean = false;
  typeSearch = '';
  domainid: string = '';
  orgid: string = '';
  _contactList: any = [];
  typeList: any = [];
  _selectClass: any = [];
  gettingTypeList: boolean = false;
  isFocus: boolean = false;
  isSelectAll: boolean = false;

  _classList: any = [];

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;
  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<OfficeChooseTypeComponent>,
    private injector: Injector,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this._selectClass = JSON.parse(JSON.stringify(this.dialogData.typelist));
    this.domainid = this.dialogData.domainid;
    this.orgid = this.dialogData.orgid;
  }

  ngOnInit(): void {
    this.homesearchMemberType();
  }

  searchChange() {
    this.chatPS.nativeElement.scrollTop = 0;
    // clearTimeout(this.mytimeout);
    // this.mytimeout = setTimeout(() => {
    //   this.chatPS.nativeElement.scrollTop = 0;
    //   this.homesearchMemberType();
    // }, 500);
  }

  homesearchMemberType() {
    this.typeList = [];
    this.gettingTypeList = true;
    const data = {
      orgid: this.orgid,
      domainid: this.domainid,
      roleid: this.allinoneService.getSelectedRole()
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getClassCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.gettingTypeList = false;
          // var classList = res['list'];
          // for (var i = 0; i < classList.length; i++) {
          //   this._classList.push({ "classname": classList[i], "checked": false });
          // }
          // for (var i = 0; i < this._classList.length; i++) {
          //   for (var j = 0; j < this._selectClass.length; j++) {
          //     if (this._classList[i]['classname'] == this._selectClass[j]['classname']) {
          //       this._classList[i]['checked'] = true;
          //     }
          //   }
          // }

          // this.typeList = this._classList;


          var temp = res.list;
           for (var i = 0; i < temp.length; i++) {
            this._classList.push({ "classname": temp[i]});
          }
          if (this._selectClass.length > 0) {
            for (var i = 0; i < this._classList.length; i++) {
              var res = this._selectClass.filter(
                (x: any) => x.classname == this._classList[i].classname && x.checked
              );
              if (res.length > 0) {
                this._classList[i].checked = true;
              }
            }
          } else {
            this._classList = this._classList.map((item: any) => ({
              ...item,
              checked: false,
            }));
          }
          this.typeList.push.apply(this.typeList, this._classList);


          console.log(this.typeList);

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingTypeList = false;
      },
      (err) => {
        this.gettingTypeList = false;
      }
    );
  }
  selectAll() {
    this.isSelectAll = !this.isSelectAll

    if (this.isSelectAll) {
      this._selectClass = []
      for (var i = 0; i < this.typeList.length; i++) {
        this.typeList[i].checked = true;
        this._selectClass.push(this.typeList[i])
      }
      for (var j = 0; j < this._selectClass.length; j++) {
        this._selectClass[j].checked = true;
      }
    } else {
      this._selectClass = []
      for (var i = 0; i < this.typeList.length; i++) {
        this.typeList[i].checked = false;
      }
    }
    console.log(this._selectClass)
  }


  selectType(event: any, type: any) {

    var index = this.typeList.findIndex((cus: any) => cus.classname == type.classname);
    console.log(index)
    if (index != -1) {
      this.typeList[index].checked = !this.typeList[index].checked;
    }
    if (event.target.checked) {
      var index = this._selectClass.findIndex(
        (seluser: any) => seluser.classname == type.classname
      );
      if (index == -1) {
        type.checked = true;
        this._selectClass.push(type);
      } else {
        this._selectClass[index].checked = true;
      }
    } else {
      var index = this._selectClass.findIndex(
        (seluser: any) => seluser.classname == type.classname
      );
      if (index != -1) {
        this._selectClass[index].checked = false;
      }
    }
  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    let finalList = []
    finalList = this._selectClass.filter((type: { checked: boolean; }) => {
      return type.checked == true;
    });
    this.dialogRef.close({ data: finalList });
    console.log(finalList);
    console.log(finalList);
  }
}

