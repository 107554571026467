import { Component, OnInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { CandidatesComponent } from '../candidates/candidates.component';
import { SigninComponent } from '../signin/signin.component';

export interface votingRoundData {
  roundid: string;
  description: string;
  remark: string;
  status: string;
  voterresult: number;
  votertotal: number;
  voteresult: number;
}

@Component({
  selector: 'app-voting-view',
  templateUrl: './voting-view.component.html',
  styleUrls: ['./voting-view.component.scss'],
})
export class VotingViewComponent implements OnInit {
  cid: string = '';

  voterRoundLoading = false;
  memberForm = false;
  isEditing: boolean = false;
  itemPerPage = 100;
  itemPerPageMember = 100;
  roundorder: string = '0';
  searchText: any = '';
  searchText1: any = '';
  isFocus: boolean = false;
  isFocus1: boolean = false;
  isMobileView: boolean = false;
  gettingMembers: boolean = false;
  isaddingRound: boolean = false;
  isError: boolean = false;
  noCampaignError: boolean = false;
  isAdmin: boolean = false;

  saveLoadingMember: boolean = false;
  memberSearchText: any = '';
  members = [];
  selectedMember: any[] = [];
  removedMember: any[] = [];
  finalRemovedMember: any[] = [];
  finalSelectedMember: any[] = [];

  memberStatus = [
    {
      id: '',
      name: 'All',
    },
    {
      id: '1',
      name: 'Members',
    },
  ];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];
  pgMember = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];
  p: number = 1;
  q: number = 1;
  mobileViewWidth: any = 426;

  votingForm = {
    description: '',
    type: '',
    remark: '',
    status: '',
    memberstatus: '1',
  };

  status_list = [
    {
      id: '001',
      stat: 'Open',
    },
    {
      id: '002',
      stat: 'Close',
    },
  ];
  mem_list = [
    {
      id: '1',
      mem: 'All',
    },
    {
      id: '2',
      mem: 'Sublist',
    },
  ];

  camdesc: string = '';
  roundid: string = '';
  description: string = '';
  remark: string = '';
  status: string = '001';
  subList: string = '1';
  voteLimit: string = '1';
  isSubList: boolean = false;
  saveLoading: boolean = false;
  cadnDintaes: any[] = [];
  sub_List = [];

  votingRound: any[] = [];

  constructor(
    public allinoneService: AllInOneService,
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    const temp_cid = this.route.snapshot.paramMap.get('camid');
    if (temp_cid) {
      this.cid = decodeURIComponent(temp_cid);
    }
    this.allinoneService.navbar = false
  }

  ngOnDestroy(){
    this.allinoneService.navbar = true
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  changePageCountMember(event: any) {
    this.itemPerPageMember = event.target.value;
    this.q = 1;
  }

  clear() {
    if (this.memberForm) {
      this.searchText1 = '';
    } else {
      this.searchText = '';
    }
  }

  ngOnInit(): void {
    this.getVoterList();
    this.getVRound();
  }

  getVRound() {
    this.voterRoundLoading = true;
    const data = {
      camid: this.cid,
    };
    this.kunyekService.getVotingRoundList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.camdesc = res.camdesc;
          this.votingRound = res.list;
          this.voterRoundLoading = false;
          if (res.camid == '') {
            this.isError = true;
            this.noCampaignError = true;
          }
          this.isAdmin = res.admin == "TRUE" ? true: false;
          if(this.isAdmin){
            this.allinoneService.navbar = true
          }
        } else {
          this.isError = true;
          this.voterRoundLoading = false;
          this.messageService.openSnackBar(res.message || res.status, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        this.voterRoundLoading = false;
      }
    );
  }

  editRound(item: any): void {
    if(!this.isAdmin){
      return;
    }
    this.isaddingRound = true;
    this.isEditing = true;
    this.roundid = item.roundid;
    this.description = item.description;
    this.remark = item.remark;
    this.status = item.status;
    this.cadnDintaes = item.candidates;
    this.subList = item.votertype;
    this.voteLimit = item.votelimit;
    this.roundorder = item.order ? item.order : '0';
    this.finalSelectedMember = item.votersublist;
  }

  openSummary(item: any): void {
    const camid =encodeURIComponent(this.cid);
    const round =encodeURIComponent(item.roundid);
    this.router.navigate([
      `/admin/campaign/${camid}/${round}/summary`]
    );
  }

  addMember() {
    this.memberForm = true;
    this.isaddingRound = false;
    this.selectedMember = JSON.parse(JSON.stringify(this.finalSelectedMember));
    this.removedMember = JSON.parse(JSON.stringify(this.finalRemovedMember));
  }

  cancelMember() {
    this.memberForm = false;
    this.isaddingRound = true;
    this.selectedMember = [];
    this.removedMember = [];
  }

  removeMember(index: number) {
    if (this.selectedMember[index].primarykey) {
      this.removedMember.push(this.selectedMember[index].primarykey);
    }
    this.selectedMember.splice(index, 1);
    let temp = this.selectedMember;
    this.selectedMember = temp;
    console.log('SM ==> ');
    console.log(this.selectedMember);
  }

  sortData(sort: Sort) {
    const data = this.votingRound;
    if (!sort.active || sort.direction === '') {
      this.votingRound = data;
      return;
    }

    this.votingRound = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'roundid':
          return this.allinoneService.compare(a.roundid, b.roundid, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        case 'voter':
          return this.allinoneService.compare(
            a.voteresult.toString() +
              '/' +
              a.votertotal.toString() +
              ' + ' +
              a.othervoter.toString(),
            b.voteresult.toString() +
              '/' +
              b.votertotal.toString() +
              ' + ' +
              b.othervoter.toString(),
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  selectMember(member: any, selectMember: any) {

    selectMember.blur()

    var temp = this.selectedMember.filter(
      (x: any) => x.userid == member.userid
    );
    if (temp.length == 0) {
      this.selectedMember.push(member);
    }
    this.pgMember[3].count = this.selectedMember.length;
    if (
      this.itemPerPageMember != 100 &&
      this.itemPerPageMember != 300 &&
      this.itemPerPageMember != 500
    ) {
      this.itemPerPageMember = this.selectedMember.length;
    }
    // trigger.openPanel();
  }

  saveMember() {
    if (this.selectedMember.length == 0) {
      this.messageService.openSnackBar(
        'Please select at least one member.',
        'warn'
      );
      return;
    }
    this.finalSelectedMember = this.selectedMember;
    this.finalRemovedMember = this.removedMember;
    this.memberForm = false;
    this.isaddingRound = true;
    this.selectedMember = [];
  }

  sortMemberData(sort: Sort) {
    const data = this.selectedMember;
    if (!sort.active || sort.direction === '') {
      this.selectedMember = data;
      return;
    }

    this.selectedMember = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        default:
          return 0;
      }
    });
  }

  addRound() {
    this.isaddingRound = true;
  }

  addVoter() {
    const camid =encodeURIComponent(this.cid);
    this.router.navigate([`/admin/campaign/${camid}/voter`]);
  }

  cancelAddRound() {
    this.isaddingRound = false;
    this.isEditing = false;
    this.roundid = '';
    this.description = '';
    this.remark = '';
    this.status = '001';
    this.cadnDintaes = [];
    this.subList = '1';
    this.voteLimit = '1';
    this.roundorder = '0';
    this.finalSelectedMember = [];
    this.finalRemovedMember = [];

    this.memberForm = false;
    this.selectedMember = [];
    this.removedMember = [];
  }

  onStatus(event: any) {
    this.status = event.target.value;
  }

  Member(event: any) {
    this.subList = event.target.value;
  }

  addVotingRound() {
    if (this.cadnDintaes.length == 0) {
      this.messageService.openSnackBar('Candidates cannot be empty.', 'warn');
      return;
    } else if (
      this.cadnDintaes.length > 0 &&
      parseInt(this.voteLimit) > this.cadnDintaes.length
    ) {
      this.messageService.openSnackBar('Candidates cannot be empty.', 'warn');
      return;
    }

    if(this.subList == '2' && this.finalSelectedMember.length == 0){
      this.messageService.openSnackBar('Sub-list cannot be empty.', 'warn');
      return;
    }

    this.saveLoading = true;

    var sublist: any[] = [];
    this.finalSelectedMember.map((item: any) => {
      if (!item.primarykey) {
        const temp = {
          userid: item.userid,
        };
        sublist.push(temp);
      }
    });

    var data = {
      description: this.description,
      remark: this.remark,
      status: this.status,
      candidates: this.cadnDintaes,
      votertype: this.subList,
      votelimit: this.voteLimit,
      sublist: this.subList == '1' ? [] : sublist,
      camid: this.cid,
      order: this.roundorder.toString(),
    };

    if (this.isEditing) {
      data = Object.assign(data, {
        roundid: this.roundid,
        removesublist: this.finalRemovedMember,
      });
    }

    var fun = this.isEditing
      ? this.kunyekService.updateVotingRound(data)
      : this.kunyekService.addVotingRound(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancelAddRound();
          this.saveLoading = false;
          this.getVRound();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.saveLoading = false;
        }
      },
      (err: any) => {
        this.isError = true;
        this.saveLoading = false;
      }
    );
  }

  voterSetup() {
    const dialog = this.dialog.open(CandidatesComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: false,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
      data: {
        candidatesList: JSON.parse(JSON.stringify(this.cadnDintaes)),
        voteLimit: this.voteLimit,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.cadnDintaes = dialogResult[0]['candidates'];
        this.voteLimit = dialogResult[0]['votelimit'];
      }
    });
  }

  reload() {
    window.location.reload();
  }

  getVoterList() {
    this.members = [];
    this.gettingMembers = true;
    const data = {
      camid: this.cid,
    };
    this.kunyekService.getVoterList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.members = res.datalist;
          this.gettingMembers = false;
        } else {
          this.gettingMembers = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        this.gettingMembers = false;
      }
    );
  }

  signin() {
    //open Sign in component
    const dialog = this.dialog.open(SigninComponent, {
      width: '400px',
      height : 'auto',
      closeOnNavigation: false,
      panelClass: ['event-modal', 'createpost-modal', 'mat-typography'],
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.reload();
      }
    });
  }

  logout() {
    this.allinoneService.setCookie('voting_admin_atoken', '');
    this.reload();
  }

  gotohome() {
    this.router.navigateByUrl(`home`);
  }

  gotocampaings() {
    this.router.navigateByUrl(`admin/campaign`);
  }
}

