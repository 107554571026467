import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-leave-summary',
  templateUrl: './office-leave-summary.component.html',
  styleUrls: ['./office-leave-summary.component.scss'],
})
export class OfficeLeaveSummaryComponent implements OnInit, OnDestroy {
  gettingLeaveEntitlement: boolean = false;

  orgid: string = '';
  domainid: string = '';
  memberid: string = '';

  summaryList: any[] = [];
  subscriptions = new SubSink();

  totalLeaveTaken : any = '';

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<OfficeLeaveSummaryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.orgid = data.orgid;
    this.domainid = data.domainid;
    this.memberid = data.memberid;
  }

  ngOnInit(): void {
    this.getLeaveEntitlement();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTotalDay(data:any) {
    var opening = parseFloat(data.opening);
    var broughtforward = parseFloat(data.broughtforward);
    var noofdays = parseFloat(data.noofdays);

    if(opening) {
      return opening + broughtforward;
    } else {
      return noofdays + broughtforward;
    }
  }

  parseFloatFun(d:any) {
    return parseFloat(d);
  }

  getLeaveEntitlement() {
    this.gettingLeaveEntitlement = true;
    let data = {
      orgid: this.orgid,
      domainid: this.domainid,
      memberid: this.memberid,
      roleid: this.allinoneService.getSelectedRole(),
      // fromweb: true
    };
    this.subscriptions.sink = this.kunyekService
      .getUserLeaveInfo(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            console.log(`Leave summary: ${JSON.stringify(res)}`)
            this.summaryList = res.requestsubtypelist;
            this.totalLeaveTaken = res.totaltaken;
            this.summaryList.map((x: any) => {
              const op = x.opening ? parseFloat(x.opening) : 0.0;
              const bf = x.broughtforward ? parseFloat(x.broughtforward) : 0.0;
              const nd = x.noofdays ? parseFloat(x.noofdays) : 0.0;
              const left = parseFloat((op + bf + nd).toFixed(1));
              x.left = op + bf;
              // if(x.opening == ''){
              //   x.left = (x.noofdays ? parseFloat(x.noofdays): 0.0) + (x.broughtforward ? parseFloat(x.broughtforward): 0.0)
              // }
              // else{
              // x.left = (x.opening ? parseFloat(x.opening) : 0.0) +  (x.broughtforward ? parseFloat(x.broughtforward): 0.0)
              // }
            })
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingLeaveEntitlement = false;
        },
        (err) => {
          this.gettingLeaveEntitlement = false;
        }
      );
  }
}
