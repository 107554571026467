import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  saveLoading:boolean=false;
  reportDescription=[
    "Nudity",
    "Violence",
    "Harassment",
    "False Infromation",
    "Hate Speech",
    "Terrorism",
    "Something Else"
  ]
  reportForm={
    "domain": this.allinoneService.getDomain().shortcode,
    "domainid": this.allinoneService.getDomain().domainid,
    "postid": "",
    "date": this.allinoneService.getCurrentDateToDB(),
    "time": this.allinoneService.getCurrentTimeToDB(),
    "description": "Nudity"
    
    }
  
  

  constructor(private kunyekService:KunyekService,
    public messageService: MessageService, 
    public allinoneService : AllInOneService,
    public dialogRef: MatDialogRef<ReportsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) { 
      this.reportForm.postid=data.syskey
      
    }
    

  ngOnInit(): void {
    
  }

  save(){
  this.saveLoading=true;
  this.dialogRef.disableClose = true;

  const data={
  "domain": this.reportForm.domain,
  "domainid": this.reportForm.domainid,
  "postid": this.reportForm.postid,
  "date": this.reportForm.date,
  "time": this.reportForm.time,
  "description": this.reportForm.description

  }
  console.log(data)

    this.kunyekService.Report(data).subscribe((res: any) => {
      console.log(res)
      if (res.returncode == "300") {
        this.dialogRef.disableClose = false;
          this.dialogRef.close(true)
          this.saveLoading=false;
          this.messageService.openSnackBar("Thanks for letting us know.", 'success');
      }
      else {
        this.dialogRef.disableClose = false;
        this.saveLoading=false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err =>{
      this.dialogRef.disableClose = false;
      this.saveLoading = false;
    }
    )

  }
  onItemChange(event:any){
    console.log(this.reportDescription[event.target.value]);
    this.reportForm.description=this.reportDescription[event.target.value];



  }

}
