<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title" style="font-size: 18px;">Total Amount</div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mb-2">
        <label for="userid" class="form-label">User
        </label>
        <select class="form-select" aria-label="Select type" id="userid" name="userid" (change)="userchange($event)" [(ngModel)]="cuserid" required #userid="ngModel">
            <option value="">
                All</option>
            <option *ngFor="let user of userlist" [value]="user.userid">
                {{user.username}}({{user.userid}})</option>
        </select>
    </div>

    <div>
        <small>
            All amount
        </small>
    </div>

    <div *ngIf="totalamount.length == 0" class="nodata d-flex align-items-center justify-content-center">
        Empty
    </div>
    <div class="d-flex flex-column amount-item p-1 " *ngFor="let data of totalamount; let i = index;">

        <div class="d-flex justify-content-between">
            <div class="p-1">
                {{data.total| number:'2.'}}
            </div>
            <div class="p-1">
                {{data.currencyname}}
            </div>
        </div>
    </div>


</div>