<div class="signin-wrapper">

    <div class="mb-3 d-flex justify-content-between align-items-center">
        <div>
            <h3 style="font-weight: 600;" class="mb-1">Sign In to Admin</h3>
        </div>
        <div>
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <form [formGroup]="signinForm" (ngSubmit)="signin()">

        <div class="form-group my-2">
            <div class="mb-3">
                <label for="userid" class="form-label">User ID</label>
                <input type="text" class="form-control" autofocus placeholder="User ID"
                    formControlName="userid" required
                    [ngClass]="{ 'is-invalid': signinFormSubmitted && signinFormControl.userid.errors }">
            </div>

            <div class="mb-3">


                <label for="userid" class="form-label">Password</label>
                <input type="password" class="form-control" autofocus placeholder="Password"
                    formControlName="password" required
                    [ngClass]="{ 'is-invalid': signinFormSubmitted && signinFormControl.password.errors }">
            </div>
            <div *ngIf="signinFormSubmitted && signinFormControl.userid.errors" class="invalid-feedback">
            <div *ngIf="signinFormControl.userid.errors?.required">
                {{allinoneService.input_err_msg}}
            </div>
        </div>
        </div>

        <app-button-loader [isLoading]="isStillSigningIn" [button_text]="'Sign In'"
            [load_button_text]="'Signing in'" [width]="'100'">
        </app-button-loader>
    </form>

</div>