import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { IMyDpOptions } from 'mydatepicker';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-leave-detail',
  templateUrl: './leave-detail.component.html',
  styleUrls: ['./leave-detail.component.scss']
})
export class LeaveDetailComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  id!: string;
  leaveForm!: FormGroup;
  leaveIsApproved = false;
  leaveIsRejected = false;
  isRejecting = false;
  isApproving = false;
  attachment!: any;

  breadcrumbs = [
    { isActive: true, url: 'leave', name: 'Leave' },
    { isActive: true, url: 'members/leavelist', name: 'List' },
    { isActive: false, url: '', name: '' }
  ];
  previewurl!: string;

  constructor(
    private messageSerivce: MessageService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public readonly allInOneService: AllInOneService,
    private kunyek: KunyekService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => {
      this.id = value.get('leaveid') || '';
    });

    this.leaveForm = this.fb.group({
      type: ['', Validators.required],
      from_date: ['', Validators.required],
      to_date: ['', Validators.required],
      reason: ['', Validators.required],
      duration: [''],
      name: [''],
    });

    this.kunyek.leave().getByid({ id: this.id }).subscribe({
      next: res => {
        const data = res.data;
        this.leaveForm.patchValue({
          type: data.type,
          from_date: this.allInOneService.changeStringToDateobject(data.from_date),
          to_date: this.allInOneService.changeStringToDateobject(data.to_date),
          reason: data.reason,
          duration: data.duration,
          name: data.member?.name
        });
        this.breadcrumbs[2].name = data.member?.name;
        if (data.status === 1) {
          this.leaveIsApproved = true;
        } else if (data.status === 2) {
          this.leaveIsRejected = true;
        }
        this.attachment = data.attachment;
      }
    });
  }

  approve() {
    if (!this.leaveIsApproved) {
      this.isApproving = true;
    }
    this.kunyek.leave().approve({ id: this.id, approver_id: this.allInOneService.currentUserid }).subscribe({
      next: res => {
        this.isApproving = false;
        this.leaveIsApproved = true;
        this.leaveIsRejected = false;
      },
      error: err => {
        this.isApproving = false;
        this.messageSerivce.openSnackBar('Failed to approve', 'warn');
      }
    });
  }

  closeForm() {
    this.router.navigate(['/members', 'leavelist']);
  }

  reject() {
    if (!this.leaveIsRejected) {
      this.isRejecting = true;
    }
    this.kunyek.leave().reject({ id: this.id, approver_id: this.allInOneService.currentUserid }).subscribe({
      next: res => {
        this.isRejecting = false;
        this.leaveIsRejected = true;
        this.leaveIsApproved = false;
      },
      error: err => {
        this.isRejecting = false;
        this.messageSerivce.openSnackBar('Failed to reject', 'warn');
      }
    });
  }

  get type() { return this.leaveForm.get('type'); }
  get from_date() { return this.leaveForm.get('from_date'); }
  get to_date() { return this.leaveForm.get('to_date'); }
  get reason() { return this.leaveForm.get('reason'); }
  get duration() { return this.leaveForm.get('duration'); }

  viewAttachment() {
    window.open(this.attachment.fileurl, '_blank');
  }

  downloadAttachment() {
    // console.log('download');
    // const link = document.createElement('a');
    // link.href = this.attachment.fileurl;
    // link.download = this.attachment.filename;
    // link.click();
    this.kunyek.leave().getByid({ id: this.id }).subscribe(res=>{
      console.log(res.data);
      saveAs(res.data.attachment.fileurl)})
      // this.http.get(this.attachment.fileurl, { responseType:'blob'}).subscribe(image=>{saveAs(image,this.attachment.filename||'Leave Attachment')});
  }
}
