import { MessageService } from './../../shared/services/message.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HierarchyAddComponent } from 'src/app/admin/hierarchy-add/hierarchy-add.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
// import { HierarchyAddComponent } from '../hierarchy-add/hierarchy-add.component';

@Component({
  selector: 'app-customer-branch',
  templateUrl: './customer-branch.component.html',
  styleUrls: ['./customer-branch.component.scss']
})
export class CustomerBranchComponent implements OnInit {

  branchAddOrNot: boolean = false;
  saveLoading: boolean = false;
  isEdit: boolean = false;
  branchFormSubmitted: boolean = false;
  savedBranch: any = []
  deleteBranch: any = []
  isAdmin: boolean = false;
  selfservices: boolean = true;
  emailaccess: boolean = true;


  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  branchForm = {
    branchid: "",
    branchname: "",
    branchmobile: "",
    branchemail: "",
    branchaddress1: "",
    branchaddress2: "",
    type: "",
  }
  index: any = '';
  constructor(private dialogRef: MatDialogRef<CustomerBranchComponent>,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.savedBranch = data.branchlist;
    console.log(this.savedBranch);
    console.log("....")
    this.deleteBranch = data.deletebranchlist;
  }

  ngOnInit(): void {
    if (this.savedBranch.length == 0) {
      this.branchAddOrNot = true;
    }
  }


  cancel() {
    this.branchAddOrNot = false;
    this.clean();
  }
  skucodeChange() {
    this.branchForm = {
      branchid: "",
      branchname: "",
      branchmobile: "",
      branchemail: "",
      branchaddress1: "",
      branchaddress2: "",
      type: ""
    };
  }
  clean() {
    this.branchAddOrNot = false;
    this.isEdit = false;
    this.branchForm = {
      branchid: "",
      branchname: "",
      branchmobile: "",
      branchemail: "",
      branchaddress1: "",
      branchaddress2: "",
      type: ""
    }
    this.branchFormSubmitted = false;
  }






  editbranch(branch: any, index: any) {
    console.log(branch);
    this.branchAddOrNot = true;
    this.branchForm.branchid = branch.branchid,
      this.branchForm.branchname = branch.branchname,
      this.branchForm.branchmobile = branch.branchmobile,
      this.branchForm.branchemail = branch.branchemail,
      this.branchForm.branchaddress1 = branch.branchaddress1,
      this.branchForm.branchaddress2 = branch.branchaddress2,
      this.selfservices = branch.selfservice == null || branch.selfservice == '' || branch.selfservice == 'true' ? true : false
    this.emailaccess = branch.emailaccess == null || branch.emailaccess == '' || branch.emailaccess == 'true' ? true : false;
    this.index = index;
    this.branchForm.type = branch.branchindustrytype;
    if (branch.role == "300") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.isEdit = true;
  }
  submitbranch() {

    if (this.branchForm.branchname == '') {
      console.log(this.branchForm);
      return;
    }

    var index = -1;
    if (this.savedBranch.length != 0) {
      index = this.savedBranch
        .map((x: any) => x.branchid)
        .indexOf(this.branchForm.branchid);
    }

    if (this.isEdit) {

      for (let i = 0; i < this.savedBranch.length; i++) {
        if (this.savedBranch[i].branchemail == this.branchForm.branchemail && i != this.index) {
          this.messageService.openSnackBar("Branch already exists.", 'warn');
          return;
        }
      }
      // branchid: 
      // this.savedBranch[index].branchid = this.branchForm.branchid.toString();
      this.savedBranch[index].branchname = this.branchForm.branchname.toString();
      this.savedBranch[index].branchmobile = this.branchForm.branchmobile == '' ? '' : this.allinoneService.checkUserId(this.branchForm.branchmobile.toString().trim());
      this.savedBranch[index].branchemail = this.branchForm.branchemail.toString();
      this.savedBranch[index].branchaddress1 = this.branchForm.branchaddress1.toString();
      this.savedBranch[index].branchaddress2 = this.branchForm.branchaddress2.toString();
      this.savedBranch[index].branchindustrytype = this.branchForm.type.toString();
    } else {

    if (this.branchForm.branchemail != '') {

      var type = this.savedBranch.map((x: any) => x.branchemail).indexOf(this.branchForm.branchemail);
      if (type > -1) {
        this.messageService.openSnackBar("Branch already exists.", 'warn');
        return;
      }
    }
   

    this.savedBranch.push({
      branchid: this.branchForm.branchid,
      branchname: this.branchForm.branchname,
      branchmobile: this.branchForm.branchmobile == '' ? '' : this.allinoneService.checkUserId(this.branchForm.branchmobile.toString().trim()),
      branchemail: this.branchForm.branchemail,
      branchaddress1: this.branchForm.branchaddress1,
      branchaddress2: this.branchForm.branchaddress2,
      branchindustrytype:this.branchForm.type
    });
  }
  

    console.log(this.savedBranch);
    this.clean();

  }

  delete(index: any) {
    if (this.savedBranch[index].branchid) {
      this.deleteBranch.push(this.savedBranch[index])
    }
    this.savedBranch.splice(index, 1)
  }
  Close() {
    this.dialogRef.close({ datalist: this.savedBranch, deletelist: this.deleteBranch });
  }

  changeAdmin(event: any) {

    if (event.target.checked == true) {
      this.isAdmin = true;

    } else {
      this.isAdmin = false;
    }

  }
  selfservice(event: any) {
    // console.log(event.targt.checked)
    if (event.target.checked == true) {
      this.selfservices = true;
    } else {
      this.selfservices = false;
    }

  }
  emailAccess(event: any) {
    if (event.target.checked == true) {
      this.emailaccess = true;
    } else {
      this.emailaccess = false;
    }

  }

  newbranch() {
    console.log("ksjd;flkj;")
    this.branchAddOrNot = true
    this.isAdmin = false;
    this.selfservices = true;
  }
}
