<section>
    <ng-container *ngIf="!isError;else errorPage">
        <div class="container-fluid">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center" *ngIf="gettingSummary">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="content" *ngIf="!gettingSummary">
                <div class="voting-container">
                    <div class="fix-header">
                        <button class="back" mat-icon-button (click)="back()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                        <div class="campaign-name mb-3">{{summary.camdesc}}</div>
                        <div class="campaign-d mb-3" *ngIf="summary.rounddesc">{{summary.rounddesc}}</div>
                        <div class="campaign-d mb-3" *ngIf="summary.remark">{{summary.remark}}</div>
                        <div class="w-100 d-flex justify-content-between align-items-center flex-wrap">
                            <div class="mb-3">
                                <span class="d-flex align-items-center">
                                    <span class="me-2 title">
                                        {{summary.votelimit}} Out of {{summary.votecount}} Candidates
                                    </span> (
                                    <span *ngIf="summary.status == '001'" class="title" style="color: green;">
                                        Open
                                    </span>
                                    <span *ngIf="summary.status == '002'" clas="title" style="color: red;">
                                        Closed
                                    </span>
                                    )
                                </span>

                                <span class="vote-limit" style="cursor: pointer;" *ngIf="isAdmin"
                                    (click)="votersPage()">{{summary.voteresult}}/{{summary.votercount}} +
                                    {{summary.othervoter}} Voters</span>
                                <span *ngIf="!isAdmin" style="color:var(--main-color)">{{summary.voteresult}}/{{summary.votercount}}
                                    +
                                    {{summary.othervoter}} Voters</span>

                            </div>
                            <div class="mb-3 d-flex">
                                <button type="button" class="btn btn-custom me-2" (click)="downloadSheet()"
                                    title="Export Members To Excel">
                                    <img class="excel-icon" src="../../../assets/images/excel.png">
                                    Export
                                </button>
                                <button class="btn btn-custom" (click)="refresh()" title="Refresh"
                                    [disabled]="refreshing">
                                    <mat-icon *ngIf="!refreshing">refresh</mat-icon>
                                    <div *ngIf="refreshing">
                                        <span class="spinner-border spinner-border-sm button-spinner" role="status"
                                            aria-hidden="true"></span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-responsive table-borderless w-100 header-fixed">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-2 col-md-2">No.</td>
                                <td scope="col" class="col-lg-8 col-md-8">Name</td>
                                <td scope="col" class="col-lg-2 col-md-2 text-center">Results</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let candidate of summary.candidatelist; let index = index">
                                <td class="col-lg-2 col-md-2" [ngClass] = "{'bold': index < summary.votelimit}">{{index+1}}</td>
                                <td class="col-lg-8 col-md-8" [ngClass] = "{'bold': index < summary.votelimit}" [title]="candidate.name">{{candidate.name}}</td>
                                <td class="col-lg-2 col-md-2" [ngClass] = "{'bold': index < summary.votelimit}" [title]="candidate.count"
                                    class="d-flex justify-content-center align-items-center">
                                    <strong>{{candidate.count}}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #errorPage>
        <div class="c-container m-5 error" *ngIf="!noRoundError">
            <span class="mb-3">Something went wrong!</span>
            <button class="btn btn-secondary" (click)="reload()">Reload</button>
        </div>
        <div class="c-container m-5 error" *ngIf="noRoundError">
            <span class="noround">This round of voting does not exist.</span>
        </div>
    </ng-template>

</section>