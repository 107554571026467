import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../shared/services/message.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { LogisticsAddbatchComponent } from '../logistics-addbatch/logistics-addbatch.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-logistics-batch',
  templateUrl: './logistics-batch.component.html',
  styleUrls: ['./logistics-batch.component.scss'],
})
export class LogisticsBatchComponent implements OnInit, OnDestroy {
  batchs: any = [];

  batchsListLoading: boolean = false;
  isAddBatch: boolean = false;
  saveLoading: boolean = false;
  isFocus: boolean = false;
  nextDisabled: boolean = false;
  isEditing: boolean = false;

  searchText: string = '';
  end: string = '';
  currentPage: number = 1;
  itemPerPage: number = 10;

  batchTimeout: any;
  lastPage: any;

  subscription!: Subscription;
  subs = new SubSink();

  orgid = this.allinoneService.selectedOrg;
  domain = this.allinoneService.getDomain();
  domainid = this.domain.domainid;

  batchForm: any = {
    id: '', // Batch ID to be edited
    batchid: '',
    orders: [],
  };

  constructor(
    public kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.getBatchsList();
  }
  sortData(sort: Sort) {
    const data = this.batchs;
    if (!sort.active || sort.direction === '') {
      this.batchs = data;
      return;
    }

    this.batchs = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        default:
          return 0;
      }
    });
  }

  addBatch() {
    this.isAddBatch = true;
    this.batchForm.batchid = this.allinoneService.generateRandomID();
  }

  editBatch(id: string, batchid: string, orderlist: any) {
    this.isAddBatch = true;
    this.isEditing = true;
    orderlist = orderlist.map((item: any) => ({
      ...item,
      checked: true,
    }));
    this.batchForm = {
      id: id,
      batchid: batchid,
      orders: orderlist,
    };
  }

  deleteBatch(batch: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.batchsListLoading = true;
        const data = {
          orgid: this.orgid,
          domainid: this.domainid,
          batchid: batch.batchid,
        };
        this.subs.sink = this.kunyekService
          .deleteLogisticsBatch(data)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.messageService.openSnackBar('Successful.', 'success');
                this.refresh();
              } else {
                this.batchsListLoading = false;
                this.messageService.openSnackBar(
                  res.message || res.status || 'Server Error',
                  'warn'
                );
              }
            },
            (err) => {
              this.batchsListLoading = false;
            }
          );
      }
    });
  }

  addOrders() {
    const dialog = this.dialog.open(LogisticsAddbatchComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orderlist: this.batchForm.orders,
        showDetails: false,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult && dialogResult.status) {
        this.batchForm.orders = dialogResult.data;
        // .map((item: any) => ({
        //   ...item,
        //   checked: true,
        // }));
      }
    });
  }

  viewOrder(order: any) {
    const dialog = this.dialog.open(LogisticsAddbatchComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orderlist: [],
        showDetails: true,
        order: order,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  getBatchsList() {
    this.batchsListLoading = true;
    var data = {
      domainid: this.domainid,
      orgid: this.orgid,
      searchbatch: this.searchText,
      end: this.end,
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService
      .getAllBatchs(data)
      .subscribe((res: any) => {
        if (res.returncode == '300') {
          if (res.list.length > 0) {
            this.nextDisabled = false;
            this.batchs.push.apply(this.batchs, res.list);
            this.end = res.end;
            if (res.list.length < 10) {
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
            }
          } else {
            this.currentPage = this.currentPage - 1;
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.batchsListLoading = false;
      });
  }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;

        if (event > this.batchs.length / this.itemPerPage) {
          this.getBatchsList();
        }
      }
    }
  }

  submitBatch() {
    const finalOrderList = this.batchForm.orders.filter(
      (item: any) => item.checked
    );
    if (finalOrderList.length == 0) {
      this.messageService.openSnackBar('Order is empty.', 'warn');
      return;
    }
    this.saveLoading = true;
    var data = {
      domainid: this.domainid,
      batchno: this.batchForm.batchid,
      orderlist: finalOrderList,
      orgid: this.orgid,
    };

    if (this.isEditing) {
      data = Object.assign(data, {
        batchid: this.batchForm.id,
      });
    }
    var fun = this.isEditing
      ? this.kunyekService.editLogisticsBatch(data)
      : this.kunyekService.createLogisticsBatch(data);

    this.subs.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  refresh() {
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.batchs = [];
    this.getBatchsList();
  }

  cancel() {
    this.isAddBatch = false;
    this.isEditing = false;
    this.batchForm = {
      id: '', // Batch ID to be edited
      batchid: '',
      orders: [],
    };
  }

  searchTextChange() {
    clearTimeout(this.batchTimeout);
    this.batchTimeout = setTimeout(() => {
      this.end = '';
      this.currentPage = 1;
      this.batchs = [];
      this.getBatchsList();
    }, 500);
  }

  clear() {
    this.searchText = '';
    this.end = '';
    this.currentPage = 1;
    this.batchs = [];
    this.getBatchsList();
  }

  goToLogistics() {
    this.router.navigateByUrl('/logistics');
  }
}
