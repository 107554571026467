<div class="container-fluid">
    <div class="content">
        <app-breadcrumb [s_breadcrumb]="'Posts'"></app-breadcrumb>
        <div class="mb-3 d-flex justify-content-between header">
            <div>
                <button *ngIf="!newPostOrNot" type="button" class="btn btn-custom" (click)="newPost()">
                    <i class="fa fa-plus me-1"></i>New Post
                </button>

                <div *ngIf="newPostOrNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader *ngIf = "postForm.type == 'Announcement'" (click)="submitPost()" [button_text]="'Save'"
                        [load_button_text]="'Saving'" [isLoading]="saveLoading">
                    </app-button-loader>
                    <button *ngIf="postForm.type == 'Meeting Invitation'" class="btn btn-custom" type="button" disabled>
                        Save
                    </button>
                </div>

            </div>
        </div>
        <form>
            <div>
                <div class = "row">
                    <div class="mb-3 col-md-6">
                        <label for="organization" class="form-label">Organization</label>
                        <select class="form-select" aria-label="Select Organization" id="organization" name="organization"
                            (change)="orgChanged($event)" [(ngModel)]="postForm.org" [disabled]="gettingOrgList" required
                            #org="ngModel" [ngClass]="{ 'is-invalid': postFormSubmitted && org.invalid }">
                            <option selected value="">-</option>
                            <option *ngFor="let org of organizations" [value]="org.orgid">{{org.name}}</option>
                        </select>
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
    
                        <div class="invalid-feedback" *ngIf="org.invalid || (org.dirty || org.touched) && postFormSubmitted">
                            <div *ngIf="org.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="type" class="form-label">Type</label>
                        <select class="form-select" aria-label="Select Type" id="type" name="type"
                            (change)="typeChanged($event)" [(ngModel)]="postForm.type" required
                            #type="ngModel" [ngClass]="{ 'is-invalid': postFormSubmitted && type.invalid }">
                            <!-- <option selected value="">Select Type</option> -->
                            <option *ngFor="let type of types" [value]="type">{{type}}</option>
                        </select>
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
    
                        <div class="invalid-feedback" *ngIf="org.invalid || (org.dirty || org.touched) && postFormSubmitted">
                            <div *ngIf="org.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf = "newPostOrNot">
                    <div *ngIf = "postForm.type == 'Announcement'">
                        <div class = "mb-3">
                            <label for="postnote" class="form-label">Post note</label>
                            <textarea class="form-control" id="postnote" [(ngModel)]="postForm.postnote" name="postnote" rows="3"
                                placeholder="Enter postnote here"></textarea>
                        </div>
                        <div class = "mb-2">
                            <input style="display: none" type="file" accept="image/*"
                                (change)="onFileSelect($event)" #hiddenfileinput multiple>
                            <!-- <button class="btn btn-custom" (click)="hiddenfileinput.click()"><i
                                    class="fa fa-image me-2"></i>Images</button> -->
                            Images
                        </div>
                        <div class = "row">
                            <div class = "img-container" *ngFor="let item of imageList; let index = index">
                                <div class = "img-border card">
                                <!-- <div class = "card card-body"> -->
                                    <img [src]="item.previewurl">
                                    <mat-icon class = "close" (click) = "removeImage(index)">close</mat-icon>
                                </div>
                            </div>
                            <div class = "img-container">
                                <div class = "img-border card addimage" (click)="hiddenfileinput.click()">
                                    <div class = "add-image">
                                        <img src = "../../../assets/images/imageuploadicon.png"> 
                                    </div>
                                    <div class = "add-image-text">
                                        Add Images
                                    </div>
                                </div>
                            </div>
                            <!-- <div class = "noimage" *ngIf = "imageList.length == 0">
                                No images
                            </div> -->
                        </div>
                    </div>
                    <div *ngIf = "postForm.type == 'Meeting Invitation'">
                        Still in progres
                    </div>
                </div>
                <div *ngIf = "!newPostOrNot">
                    <div class="table-responsive" matSort (matSortChange)="sortAnnPostData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="createdby">Created By</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="createddate">Created Date</td>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="postnote">Post Note</td>
                                    <td scope="col" class="col-lg-3 col-md-3">Images</td>
                                    <td scope="col" class="d-flex justify-content-center">Action</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="postListLoading">
                                <tr>
                                    <td colspan="5" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="postListLoading == false">
                                <tr *ngIf="annPosts.length == 0">
                                    <td colspan="5" class="nodata">Empty</td>
                                </tr>
                                <tr
                                    *ngFor="let item of annPosts | paginate: { itemsPerPage: 10, currentPage: p }; let index = index">
                                    <td class="td-data td-edit" (click)="editPost(item)" style="max-width: fit-content;">
                                        <div class = "info-container">
                                            <div class = "username">
                                                {{item.admin}}
                                            </div>
                                            <div class = "userid">
                                                {{item.adminid}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="td-data">
                                        <span>{{allinoneService.formatDBToShow(item.date)}} {{item.time}}</span>
                                    </td>
                                    <td class="td-data">
                                        <span tooltip data-bs-toggle="tooltip" data-bs-placement="right"
                                            [title]="item.note">{{item.note}}</span>
                                    </td>
                                    <td class="td-data">
                                        <div *ngIf = "item.image.length != 0">{{item.image[0]}}</div>
                                        <div class = "toplevel">
                                            <div class = "table-img-container" *ngFor = "let item of tempImageToShowList; let i = index">
                                                <img [src]="item">
                                                <div class = "more-image" *ngIf = "(tempImageToShowList.length < tempImageList.length) && i == 1">
                                                    +{{tempImageList.length - tempImageToShowList.length}}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="d-flex justify-content-center">
                                        <button class="btn btn-outline-primary me-3" (click)="editPost(item)">
                                            <i class='fa fa-edit'></i>
                                        </button>
                                        <button class="btn btn-outline-danger" (click)="deletePost(item.id)"
                                            [disabled]="item.role == '300' && item.userid == allinoneService.getKunyekUserId()">
                                            <i class='fa fa-trash-o'></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>