import { Component, OnInit } from '@angular/core';
import ListConfig from 'src/app/shared/interfaces/listconfig';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss']
})
export class BatchListComponent {
  configs: ListConfig[] = [
    { title: 'Batch No.', property: 'batch_no' },
    { title: 'Start Date', property: 'start_date',type:'date' },
    { title: 'End Date', property: 'end_date',type:'date' },
    { title: 'Time', property: 'time' },
    { title: 'Type', property: 'batch_type' },
    { title: 'Remark', property: 'remark' }
];

  url = ['members/batchlist']
  searchName: string = '';
  searchText = "Search Batch"
  cb = this.kunyekService.getBatch().get.bind(this.kunyekService)
  breadcrumbs: any[] = [
    { name: 'Swim', url: 'swim', isActive: true },
    { name: 'Batch', url: '', isActive: false }
  ];

  constructor(
    private kunyekService: KunyekService,
  ) { }

}
