import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkDateForToday'
})
export class CheckDateForTodayPipe implements PipeTransform {

  transform(date : any){
    var now = new Date(Date.now())
    var year = now.getFullYear();
    var month: any = now.getMonth() + 1;
    month = month.toString().length == 1 ? 0 + "" + month : month;
    var day: any = now.getDate();
    day = day.toString().length == 1 ? 0 + "" + day : day;

    var currentdate = year + "" + month + "" + day

    if (currentdate != date) {
      return this.formatDBToShow(date);
    }
    else {
      return "Today";
    }
  }

  formatDBToShow(date: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return day + "/" + month + "/" + year;
    }
    else {
      return "";
    }

  }

}
