<div class="d-flex gap-2 mb-2">
    <app-button-loader [button_text]="'Cancel'" [load_button_text]="'Saving'"
        (click)="handleCloseForm()"></app-button-loader>
    <div class="">
        <div class="line"></div>
    </div>
    <app-button-loader [button_text]="'Pay'" [isLoading]="payLoading" [load_button_text]="'Submitting'"
        (click)="handlePay()"></app-button-loader>
</div>

<div class="d-flex flex-column align-items-center mt-6" *ngIf="isLoading">
    <div class="spinner-border" entitle="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div class="row d-flex gap-3" *ngIf="!isLoading">
    <div class="col-md-5 col-sm-12">
        <div class="card p-3">
            <div class="mb-3">
                <label for="staffname" class="form-label">Staff Name</label>
                <input type="text" class="form-control" name="staffname" [(ngModel)]="staffname" />
            </div>
            <div class="mb-3">
                <label for="currency" class="form-label">Currency</label>
                <select name="" id="" class="form-select" [(ngModel)]="currency">
                    <option value="">-</option>
                    <option *ngFor="let item of currencyList" [value]="item">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label for="paymentmemthod" class="form-label">Payment Method</label>
                <select name="" id="" class="form-select" [(ngModel)]="paymentmethod">
                    <option value="">-</option>
                    <option *ngFor="let item of paymentMethods" [value]="item">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label for="attachment" class="form-label">Payment Attachment</label>
                <input class="form-control" type="file" (change)="onFileChanged($event)" name="attachment"
                    accept="image/*" id="formFile">
            </div>
            <div class="mb-3">
                <label for="startdate" class="form-label">Start Date</label>
                <input class="form-control" type="date" id="startdate" (ngModelChange)="onStartDateChange($event)"
                    [(ngModel)]="memberForm.startdate" name="startdate" max="9999-12-31" />
            </div>
            <div class="mb-3">
                <label for="enddate" class="form-label">End Date</label>
                <input class="form-control" type="date" id="enddate" (ngModelChange)="onEndDateChange($event)"
                    [(ngModel)]="memberForm.enddate" name="enddate" max="9999-12-31" />
            </div>

            <div>
                <label for="total" class="mt-2 form-label fw-bold">Total Amount</label>
                <div name="total" class="d-flex justify-content-between">
                    <div>
                        1x (A Member) & &nbsp;{{bmembers.length}}x (B members): &nbsp;
                    </div>
                    <div class="fw-bold">{{memberAandBtotalAmount | number}}&nbsp;{{currency}}</div>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-6 col-sm-12">
        <div class="row d-flex flex-column gap-3">
            <div class="col-12 p-3" style="background-color: white; border: solid 1px #ddd; border-radius: 6px;">

                <div>
                    <table style="width: 100%; border-collapse: collapse;">
                        <tbody>
                            <tr>
                                <td style="width: 160px;">Name: </td>
                                <td class="dashed-line">{{ memberinfo?.name }}</td>
                            </tr>
                            <tr>
                                <td style="width: 160px;">Member Type: </td>
                                <td class="dashed-line">{{ memberinfo?.memberType?.name }}</td>
                            </tr>
                            <tr>
                                <td style="width: 160px;">Phone: </td>
                                <td class="dashed-line">{{ memberinfo?.phone }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ng-container *ngFor="let fee of fees">
                    <table style="width: 100%; border-collapse: collapse;">
                        <tbody>
                            <tr *ngFor="let detail of fee.monthly_detail">
                                <td style="width: 160px;">{{ detail.feeType?.description }} : </td>
                                <td class="text-end dashed-line">{{ detail.amount | number }}&nbsp;{{currency}}</td>
                            </tr>
                            <tr>
                                <td>Monthly Fee:</td>
                                <td>
                                    <div class="row">
                                        <div class="col-md-8 text-center">
                                            (&nbsp;{{startDate?.toDateString()}}&nbsp;) &nbsp; to &nbsp;
                                            (&nbsp;{{endDate?.toDateString()}}&nbsp;)
                                        </div>
                                        <div class="col-md-4 d-flex align-items-center justify-content-end">
                                            <span class="text-end dashed-line" style="width: 100%">{{
                                                memberAMonthlyfeeCalculated |
                                                number
                                                }}&nbsp;{{currency}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <hr class="mt-2">
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td class="mb-5 fw-bold">Total Fees</td>
                            <td class="text-end fw-bold">{{ memberATotalFees | number }} &nbsp;{{currency}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- amember fees -->

            <!-- bmember fees -->
            <ng-container *ngIf="bmembers.length > 0">
                <div *ngFor="let b of bmembers" class="col-12 p-3"
                    style="background-color: white; border: solid 1px #ddd; border-radius: 6px;">

                    <div>
                        <table style="width: 100%; border-collapse: collapse;">
                            <tbody>
                                <tr>
                                    <td style="width: 160px;">Name: </td>
                                    <td class="dashed-line">{{ b?.name }}</td>
                                </tr>
                                <tr>
                                    <td style="width: 160px;">Member Type: </td>
                                    <td class="dashed-line">{{ b?.memberType?.name }}</td>
                                </tr>
                                <tr>
                                    <td style="width: 160px;">Phone: </td>
                                    <td class="dashed-line">{{ b?.phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ng-container *ngFor="let fee of bfees">
                        <table style="width: 100%; border-collapse: collapse;">
                            <tbody>
                                <tr *ngFor="let detail of fee.monthly_detail">
                                    <td style="width: 160px;">{{ detail.feeType?.description }} : </td>
                                    <td class="text-end dashed-line">{{ detail.amount | number }}&nbsp;{{currency}}</td>
                                </tr>
                                <tr style="height: 52.85px;">
                                    <td>Monthly Fee:</td>
                                    <td>
                                        <div class="row">
                                            <div class="col-md-8 text-center">
                                                (&nbsp;{{startDate?.toDateString()}}&nbsp;) &nbsp; to &nbsp;
                                                (&nbsp;{{endDate?.toDateString()}}&nbsp;)
                                            </div>
                                            <div class="col-md-4 d-flex align-items-center justify-content-end">
                                                <span class="text-end dashed-line" style="width: 100%">{{
                                                    memberBMonthlyfeeCalculated |
                                                    number
                                                    }}&nbsp;{{currency}}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <hr class="mt-3">
                    <table style="width: 100%; border-collapse: collapse;">
                        <tbody>
                            <tr>
                                <td class="fw-bold">Total Fees</td>
                                <td class="text-end fw-bold">{{ memberBTotalFees | number }} &nbsp;{{currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
        <!-- amember fees -->
        <!-- bmember fees -->
    </div>
</div>