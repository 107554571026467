<div class="fix">
    <div class="title-container mb-3">
        <div class="title">
            Task
        </div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="loading-container" *ngIf="gettingTaskList">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="mt-3" *ngIf="!gettingTaskList">
        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" [scrollWindow]="false" [perfectScrollbar]="config" class="task-container">
            <div *ngFor="let task of taskList; let i = index">
                <div class="card card-body mb-2 {{task.selected ? 'selected' : ''}}" (click)="selectTask(task)">
                    <div *ngIf="task.selected" class="icon-container">
                        <mat-icon class="check-icon">check</mat-icon>
                    </div>
                    <div>
                        Task ID : {{task.autoid}}
                    </div>
                    <div>
                        Description : {{task.description}}
                    </div>
                    <div class="d-flex justify-content-between flex-wrap">

                        <div>
                            Status : {{task.status}}
                        </div>
                        <div>
                            Target : {{allinoneService.formatDBToShow(task.enddate)}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="gettingMoreTaskList">
                <div *ngFor="let i of [1,2]">
                    <div class="card card-body">
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 20}"></ngx-skeleton-loader>
                        </div>
                        <div>
                            <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 'height.px': 20, 'width.px' : 200}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>