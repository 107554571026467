<div>
    <div class="mb-2">
        This record will be converted into
    </div>
    <div class="d-flex align-items-center">
        <div class="form-check  me-5" *ngFor="let item of businessTypeList">
            <label class="radio-inline">
                <input (change)="getCustomerName()" class="form-check-input" type="radio" name="businesstypelist"
                    [id]="item.key" [(ngModel)]="convertForm.leadtype" [value]="item.key"
                    [checked]="convertForm.leadtype == item.key">
                {{item.value}}
            </label>
        </div>
    </div>

    <div class="d-flex">
        <div class="card " style="width: 18rem;margin: 4px;">
            <div class="card-body">
                <h3>Customer</h3>

                <p class="card-title" *ngIf="convertForm.name">Customer : {{convertForm.leadtype == '001' ?
                    convertForm.campany : convertForm.name}}</p>
                <p *ngIf="convertForm.email">Email : {{convertForm.email}}</p>
                <p *ngIf="convertForm.phone">Phone : {{convertForm.phone}}</p>
            </div>
        </div>
        <div class="card " style="width: 18rem;margin: 4px;">
            <div class="card-body">
                <h3>Contact</h3>
                <p class="card-title" *ngIf="convertForm.name">Customer : {{convertForm.leadtype == '001' ?
                    convertForm.campany : convertForm.name}}</p>
                <p *ngIf="convertForm.post">Post : {{convertForm.post}}</p>
                <p *ngIf="convertForm.email">Email : {{convertForm.email}}</p>
                <p *ngIf="convertForm.phone">Phone : {{convertForm.phone}}</p>

            </div>
        </div>
        <div class="card " style="width: 18rem;margin: 4px;">
            <div class="card-body">
                <h3>Deal</h3>
                <p class="card-title" *ngIf="convertForm.name">Customer : {{convertForm.leadtype == '001' ?
                    convertForm.campany : convertForm.name}}</p>
                <p *ngIf="convertForm.email">Email : {{convertForm.email}}</p>
                <p *ngIf="convertForm.phone">Phone : {{convertForm.phone}}</p>
                <p *ngIf="convertForm.amount">Amount : {{convertForm.amount}}</p>
            </div>
        </div>

    </div>
</div>
<div *ngIf="getCustomer">
    <label class="customer-load">getting...</label>
</div>
<div *ngIf="customernameList.length > 0" class="customer">
    <label class="form-label">Customer</label>
    <div class="cust-dropdown">
        <ng-select class="role-select" [items]="customernameList" bindLabel="customername" placeholder="-"
            appendTo="body" [multiple]="false" (change)="selectCustomer($event)">
        </ng-select>
        <mat-progress-bar mode="indeterminate" *ngIf="getCustomer"></mat-progress-bar>
    </div>
</div>



<div mat-dialog-actions align="end">
    <div *ngIf="customernameList.length > 0 && convertForm.customerid == ''">

        <div class="form-check cus me-2" *ngIf="checklead">
            <input class="form-check-input cus" type="checkbox" id="checkbox" name="checkbox" checked="checked"
                (change)="checkLead($event)" >
            <label class="form-check-label cus mb-1 ms-1" for="checkbox"> New customer
                account
            </label>
        </div>
        <div class="form-check cus me-2" *ngIf="!checklead">
            <input class="form-check-input cus" type="checkbox" id="checkbox" name="checkbox"
                (change)="checkLead($event)" >
            <label class="form-check-label cus mb-1 ms-1" for="checkbox"> New customer
                account
            </label>
        </div>
    </div>
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary mx-2">
        Cancel
    </button>

    <button *ngIf="getCustomer" class="btn btn-custom" type="button" disabled>OK</button>

    <app-button-loader *ngIf="getCustomer == false" (click)="onConfirm()" [button_text]="'Ok'"
        [load_button_text]="'Converting'" [isLoading]="saveLoading"></app-button-loader>

</div>