<div class="fix">
    <div class="title-container mb-2">
        <div class="title">
            Similar Customers
        </div>
        <div class="close-btn">
            <button mat-icon-button (click)="search()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true"
        fxFlex="auto" #perfectScroll>
        <!-- -->
        <ul class="list-group " *ngFor="let data of customernameList;" style="margin: 3px;">
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">{{data.customername}} ({{data.customerid}})</div>

                    <div *ngIf="data.customeremail">
                        {{data.customeremail}}
                    </div>
                    <div *ngIf="data.customermobile">
                        {{data.customermobile}}
                    </div>

                </div>
            </li>

        </ul>
    </perfect-scrollbar>
</div>