<div class="container-fluid">
    <div class="content">
        <app-team-breadcrumb [s_breadcrumb]="isFromDomain? 'Domain Push Notification': 'Organization Push Notification'"
            *ngIf="!newNoti">
        </app-team-breadcrumb>

        <app-team-breadcrumb [s_breadcrumb]="isFromDomain? 'Domain Push Notification': 'Organization Push Notification'"
            *ngIf="newNoti" t_breadcrumb="New Notification" (emit)="cancel()">
        </app-team-breadcrumb>


        <div class="row row-custom header">
            <div class="col-md-6 mb-3">
                <button *ngIf="!newNoti" type="button" class="btn btn-custom" (click)="openNewNotiForm()">
                    <i class="fa fa-plus me-1"></i>New Notification
                </button>

                <div *ngIf="newNoti" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader (click)="confirm()" [button_text]="'Send'" [load_button_text]="'Sending'"
                        [isLoading]="saveLoading"></app-button-loader>
                </div>
            </div>
        </div>
        <form>
            <div class="mb-3" *ngIf="newNoti">
                <div class="mb-3">
                    <label for="title" class="form-label">Title</label>
                    <input type="text" class="form-control" [(ngModel)]="notiForm.title" id="title" name="title"
                        placeholder="Enter Title" required #tit="ngModel"
                        [ngClass]="{ 'is-invalid': notiFormSubmitted && tit.invalid }">

                    <div class="invalid-feedback"
                        *ngIf="tit.invalid || (tit.dirty || tit.touched) && notiFormSubmitted">
                        <div *ngIf="tit.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="desc" class="mb-2">Notification Message</label>
                    <textarea type="text" name="desc" class="form-control" id="desc" [(ngModel)]="notiForm.description"
                        placeholder="Enter message for your notification" required #desc="ngModel"
                        [ngClass]="{ 'is-invalid': notiFormSubmitted && desc.invalid }" rows="3"></textarea>
                    <div role="alert" *ngIf="desc.errors?.required && notiFormSubmitted" style="color: red;">
                        {{allinoneService.input_err_msg}}
                    </div>
                </div>
            </div>
        </form>
        <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center" *ngIf="!newNoti">

            <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
                <input class="form-control" type="text" placeholder="&#xf002; Search with Title/Message"
                    aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                    [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
            </div>
            <div class="dropdown" [ngClass]="{'ms-2' : !isMobileView, 'ms-0': isMobileView}">
                <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                    aria-expanded="false" title="Page Size">
                    <mat-icon>filter_list</mat-icon>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <div c style="text-align: center;">Page size</div>
                    </li>
                    <li>
                        <hr class="dropdown-divider">
                    </li>
                    <li class="dropdown-item" *ngFor="let page of pg">
                        <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                            id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
                        <label for="pg{{page.name}}" class="col-10 p-1">
                            {{page.name}}
                        </label>
                    </li>
                </ul>
            </div>
            <div class="ms-2">
                <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <div class="line"></div>
            <div class="d-flex align-items-center">
                <span class="total-count-text">Total:&nbsp;</span> <span
                    class="total-count-num">{{notifications.length}}</span>
            </div>
        </div>
        <div class="input-group mb-2" *ngIf="isMobileView && !newNoti">
            <input class="form-control" type="text" placeholder="&#xf002; Search with Title/Message" aria-label="Search"
                (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
            <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
                [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                <mat-icon class="c-icon">close</mat-icon>
            </button>
        </div>
        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless" *ngIf="newNoti == false">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="title">Title</td>
                        <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="description">Message</td>
                        <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="datetime">Date Time</td>
                        <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="createdby">Created By</td>
                    </tr>
                </thead>
                <tbody *ngIf="gettingNotifications">
                    <tr>
                        <td colspan="4" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!gettingNotifications">
                    <tr *ngIf="(notifications|filter : searchText:25).length == 0">
                        <td colspan="4" class="nodata">Empty</td>
                    </tr>
                    <tr
                        *ngFor="let item of notifications|filter : searchText:25 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
                        <td class="td-data">
                            <span [title]="item.title">{{item.title}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.description">{{item.description}}</span>
                        </td>
                        <td class="td-data">
                            <span
                                [title]="allinoneService.formatDBToShow(item.date) + ' '+item.time">{{allinoneService.formatDBToShow(item.date)}}
                                {{item.time}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.createdby">{{item.createdby}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="p = $event"
            *ngIf="notifications.length > itemPerPage && !newNoti" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
    </div>
</div>