import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { OfficeChooseMembersComponent } from '../office-choose-members/office-choose-members.component';
import { OfficeLeaveSummaryComponent } from '../office-leave-summary/office-leave-summary.component';
import { OfficeUserRequestFilterComponent } from '../office-user-request-filter/office-user-request-filter.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LateEarlyoutRequestComponent } from '../late-earlyout-request/late-earlyout-request.component';
import { request } from 'http';
import { WorkFromHomeRequestComponent } from '../work-from-home-request/work-from-home-request.component';
import { OffInLieuRequestComponent } from '../off-in-lieu-request/off-in-lieu-request.component';
import ImageViewer from 'awesome-image-viewer';
import { SelectTravelComponent } from '../select-travel/select-travel.component';
import { OfficeTravelDetailComponent } from '../office-travel-detail/office-travel-detail.component';

@Component({
  selector: 'app-office-user-request',
  templateUrl: './office-user-request.component.html',
  styleUrls: ['./office-user-request.component.scss'],
})
export class OfficeUserRequestComponent implements OnInit, OnDestroy {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;
  @ViewChild('lateEarlyOut') lateEarlyOut!: LateEarlyoutRequestComponent;
  @ViewChild('workFromHome') workFromHome!: WorkFromHomeRequestComponent;
  @ViewChild('offInLieu') offInLieu!: OffInLieuRequestComponent;

  kunyekusername = this.allinoneService.getKunyekUserName()

  addingRequest: boolean = false;
  saveLoading: boolean = false;
  gettingTemplate: boolean = false;
  gettingRequestList: boolean = false;
  isEditing: boolean = false;
  gettingCurrency: boolean = false;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  nextDisabled: boolean = false;
  leaveForm: boolean = false;
  claimForm: boolean = false;
  lateForm: boolean = false;
  earlyOutForm: boolean = false;
  workFromHomeForm: boolean = false;
  offInLieuForm: boolean = false;
  gettingDuration: boolean = false;
  gettingAvailableRoomAndDesk: boolean = false;
  gettingWorkstation: boolean = false;
  cashAdvancedForm: boolean = false;
  travelRequestForm: boolean = false;
  isTravelClaim: boolean = false;

  gettingtran: boolean = false;
  gettingPickUp: boolean = false;

  timeFormat = 'hh:mm';

  end: string = '';
  tranend: string = '';
  searchText: string = '';
  lastPage: any = '';
  breadcrumb: string = 'Request';
  currentPage: number = 1;
  tranCurrentPage: number = 1;
  itemPerPage: number = 10;
  tranItemPerPage: number = 100;
  traveleditid: string = '';

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  currentTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();
  currentEndTime = formatDate(
    new Date().setHours(new Date().getHours() + 1),
    this.timeFormat,
    'en-US'
  ).toString();

  organizations: any[] = [];
  imageList: any[] = [];
  requestTypeList: any[] = [];
  requestSubTypeList: any[] = [];
  currencyList: any[] = [];
  approverList: any[] = [];
  accompanyPersonList: any[] = [];
  travelList: any[] = [];

  requestList: any[] = [];
  transportaionList: any[] = [];
  pickupList: any[] = [];

  availableRoom: any[] = [];
  availableDesk: any[] = [];
  workstationList: any[] = [];

  projectList: any = [];
  productListdata: any = [];
  getProductLoading: boolean = false;
  gettingProjectList: boolean = false;

  timeList: string[] = ['AM', 'PM'];

  transportationView: boolean = false;
  transportationDetail: boolean = false;

  modeoftravel = [
    { key: '001', value: 'By Air' },
    { key: '002', value: 'By Road' },
    { key: '003', value: 'By Rail' },
    { key: '004', value: 'By Ship' },
  ];

  usevehicle = [
    { key: '001', value: "Office's Vehicle" },
    { key: '002', value: 'Public Transportation' },
    { key: '003', value: 'By Own Vehicle(state reason)' },
  ];

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Approved',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  weekDay = [
    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];

  requestForm: any = {
    requestid: '',
    userid: this.allinoneService.getKunyekUserId(),
    orgid: '',
    requesttype: '',
    requeststatus: '',
    personname: '',
    rejectperson: '',
    requeststatusname: '',
    subtype: '',
    startdate: this.currentDate,
    enddate: this.currentDate,
    starttimeampm: 'AM',
    endtimeampm: 'PM',
    starttime: this.currentTime,
    endtime: this.currentEndTime,
    isgoing: false,
    isreturn: false,
    toplace: '',
    dailyfrom: '',
    dailyto: '',
    arrivetime: this.currentTime,
    returntime: this.currentTime,
    trantype: '001',
    weekday: [],
    amount: '0',
    currency: '',
    duration: '1',
    travelduration: '1',
    overtimehour: '01:00(1.0hrs)',
    remark: '',
    modeoftravel: [],
    vehicleuse: [],
    vehicleusename: [],
    modeoftravelname: [],
    refno: '',
    readonly: false,
    reservationtype: '001',
    roomname: '',
    roomid: '',
    maxpeople: '',
    workstationid: '',
    floorname: '',
    deskid: '',
    reservation: false,
    taxifare: false,
    claimfromplace: '',
    claimtoplace: '',
    date: '',
    pickuppoint: '',
    transportationid: '',
    product: null,
    project: null,
    productname: '',
    projectname: '',
    // HR
    hrrequesttype: '',
    hrrequestdate: this.currentDate
  };

  searchForm = {
    requesttype: '',
    requesttypename: '',
    requeststatus: '',
    requeststatusname: 'All',
    startdate: '',
    enddate: '',
    // transportation
    trequestid: '',
    trequesttype: '',
    trequesttypename: '',
    trequeststatus: '',
    trequeststatusname: 'All',
    tstartdate: '',
    tenddate: '',
  };

  reservationid: string = 'AVASDAFNMVHSJESAM';
  transportationid: string = 'ANVAHSEFAMVAJDAJ';
  claimid: string = 'WOEIEPPLMXNW';
  lateid: string = 'KDIEVNEIADEOHDWJ';
  earlyoutid: string = 'IUEBCHDUWKNSGP';
  workfromhomeid: string = 'EUOMXJSOQNCUWI';
  offinlieuid: string = 'OMCHDFYUEJBAWCQ';
  travelrequestid: string = 'BCNDUWO85SCGQW';
  cashadvanced: string = 'QINDJSUWOMXSA';
  hrid: string = 'OMCHDFAASJBAQQ';

  subscriptions = new SubSink();
  search_subscription!: Subscription;
  template_subscription!: Subscription;
  duration_subscription!: Subscription;
  reservation_subscription!: Subscription;
  tran_sub!: Subscription;

  transubtype: any = '';
  transubtypename: any = '';

  tranrefno: any = '';
  tabletrantype: any = '';
  parentid: string = '';

  // HR
  HRRequestTypeList: any = [];
  gettingHRRequestType: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.allinoneService.isOfficeView = true;
    this.route.data.subscribe((data) => {
      if (data.requesttypeid == 'NCMLEIWHRNVIE') {
        this.searchForm.requesttype = data.requesttypeid;
        this.searchForm.requesttypename = 'Leave';
        this.requestForm.requesttype = data.requesttypeid;
        this.leaveForm = true;
        this.breadcrumb = 'Leave';
      } else if (data.requesttypeid == this.claimid) {
        this.searchForm.requesttype = data.requesttypeid;
        this.searchForm.requesttypename = 'Claim';
        this.requestForm.requesttype = data.requesttypeid;
        this.claimForm = true;
        this.breadcrumb = 'Claim';
      } else if (data.requesttypeid == this.lateid) {
        this.searchForm.requesttype = data.requesttypeid;
        this.searchForm.requesttypename = 'Late';
        this.requestForm.requesttype = data.requesttypeid;
        this.lateForm = true;
        this.breadcrumb = 'Late';
      } else if (data.requesttypeid == this.earlyoutid) {
        this.searchForm.requesttype = data.requesttypeid;
        this.searchForm.requesttypename = 'Early Out';
        this.requestForm.requesttype = data.requesttypeid;
        this.earlyOutForm = true;
        this.breadcrumb = 'Early Out';
      } else if (data.requesttypeid == this.workfromhomeid) {
        this.searchForm.requesttype = data.requesttypeid;
        this.searchForm.requesttypename = 'Work From Home';
        this.requestForm.requesttype = data.requesttypeid;
        this.workFromHomeForm = true;
        this.breadcrumb = 'Work From Home';
      } else if (data.requesttypeid == this.offinlieuid) {
        this.searchForm.requesttype = data.requesttypeid;
        this.searchForm.requesttypename = 'Off In Lieu';
        this.requestForm.requesttype = data.requesttypeid;
        this.offInLieuForm = true;
        this.breadcrumb = 'Off In Lieu';
      } else if (data.requesttypeid == this.cashadvanced) {
        this.searchForm.requesttype = data.requesttypeid;
        this.searchForm.requesttypename = 'Cash Request';
        this.requestForm.requesttype = data.requesttypeid;
        this.cashAdvancedForm = true;
        this.breadcrumb = 'Cash Request';
      }
      else {
        this.leaveForm = false;
        this.claimForm = false;
        this.lateForm = false;
        this.earlyOutForm = false;
        this.cashAdvancedForm = false;
        this.searchForm.requesttype = '';
        this.requestForm.requesttype = '';
      }
    });
  }
  ngOnDestroy(): void {
    this.allinoneService.isOfficeView = false;
    this.subscriptions.unsubscribe();
    this.template_subscription && this.template_subscription.unsubscribe();
    this.search_subscription && this.search_subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgsCRm;
    this.requestForm.orgid = this.allinoneService.selected_orgcrm
      ? this.allinoneService.selected_orgcrm.orgid
      : this.organizations[0].orgid;
    this.getCurrency();
    this.getTemplate();
    this.getWorkstation();
    this.getPickupPoint();
    this.getProduct();
    this.getAllProjects();
    this.getHRRequestType();
  }

  getHRRequestType() {
    this.gettingHRRequestType = true;
    // this.tableLoading = true;
    var data = {
      orgid: this.requestForm.orgid,
    }
    this.kunyekService.getAccessoriesRequestType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.HRRequestTypeList = res.list;
          this.requestForm.hrrequesttype = this.HRRequestTypeList[0].id;
          // this.getHRRequestList();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingHRRequestType = false;
        // this.tableLoading = false;
      },
      (err) => {
        this.gettingHRRequestType = false;
        // this.tableLoading = false;
      }
    )
  }

  addWeekDay(event: any, value: any) {
    if (event.target.checked == true) {
      this.requestForm.weekday.push(value);
    } else {
      if (this.requestForm.weekday != 0) {
        for (var i = 0; i < this.requestForm.weekday.length; i++) {
          if (this.requestForm.weekday[i] == value) {
            this.requestForm.weekday.splice(i, 1);
          }
        }
      }
    }
  }

  getProduct() {
    this.getProductLoading = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.requestForm.orgid,
      "customerid": '',
      "customerview": false
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productListdata = res.list;
          var type = this.productListdata.map((x: any) => x.productid).indexOf(this.requestForm.product);
        } else {
          this.getProductLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getProductLoading = false;
      }
    );
  }

  getAllProjects() {
    this.gettingProjectList = true;
    this.projectList = [];
    const data = {
      orgid: this.requestForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
      customerid: "",
      customerview: false
      // role: this.callType
    };
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var project = [];
          console.log(this.projectList);
          var userid = this.allinoneService.getKunyekUserId();
          console.log(typeof (userid));
          this.projectList = res.data

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  deleteRequest(requestid: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingRequestList = true;
        const data = {
          orgid: this.requestForm.orgid,
          requestid: requestid,
        };
        this.kunyekService.deleteOfficeRequest(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.refresh();
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
              this.gettingRequestList = false;
            }
          },
          (err) => {
            this.gettingRequestList = false;
          }
        );
      }
    });
  }

  test() {
    console.log(this.requestForm.product);
    console.log(this.requestForm.productname);

  }

  tranDeleteRequest(requestid: string, parentid: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingtran = true;
        const data = {
          orgid: this.requestForm.orgid,
          requestid: requestid,
          transportation: true,
          parentid: parentid,
        };
        this.kunyekService.deleteOfficeRequest(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.tranrefresh();
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
              this.gettingtran = false;
            }
          },
          (err) => {
            this.gettingtran = false;
          }
        );
      }
    });
  }

  getCurrency() {
    this.gettingCurrency = true;
    this.subscriptions.sink = this.kunyekService.getCurrency().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.currencyList = res.currencylist;
          if (this.currencyList.length > 0) {
            this.requestForm.currency = this.currencyList[0].currencyid;
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCurrency = false;
      },
      (err) => {
        this.gettingCurrency = false;
      }
    );
  }

  statusChange(status: any) {
    if (this.searchForm.requeststatus != status.value) {
      this.searchForm.requeststatus = status.value;
      this.searchForm.requeststatusname = status.name;
      this.refresh();
    }
  }

  transtatusChange(status: any) {
    if (this.searchForm.trequeststatus != status.value) {
      this.searchForm.trequeststatus = status.value;
      this.searchForm.trequeststatusname = status.name;
      this.tranrefresh();
    }
  }

  getRequestList() {
    this.gettingRequestList = true;
    const data = {
      orgid: this.requestForm.orgid,
      requesttype: this.searchForm.requesttype,
      requeststatus: this.searchForm.requeststatus,
      startdate: this.searchForm.startdate
        ? this.allinoneService.formatDate(this.searchForm.startdate)
        : '',
      enddate: this.searchForm.enddate
        ? this.allinoneService.formatDate(this.searchForm.enddate)
        : '',
      end: this.end,
      parentid: '',
    };
    this.search_subscription && this.search_subscription.unsubscribe();
    this.search_subscription = this.kunyekService
      .getRequestList(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (res.requestlist.length > 0) {
              res.requestlist = res.requestlist.map((item: any) => ({
                ...item,
                isChecked: false,
                // requesttypename: this.requestTypeList.find(
                //   (x: any) => x.requesttypeid == item.requesttype
                // ).requesttypename,
                requesttypename: item.requesttypename,
                requeststatusname: this.statusList.find(
                  (x: any) => x.value == item.requeststatus
                )?.name,
                requestremark:
                  item.requesttype == 'NCMLEIWHRNVIE'
                    ? this.returnLeave(item)
                    : item.requesttype == this.claimid
                      ? this.returnClaim(item)
                      : item.requesttype == 'UEYVNDLOAPQWXQW'
                        ? this.returnOvertime(item)
                        : item.requesttype == 'BCNDUWO85SCGQW'
                          ? this.returnTravel(item)
                          : item.requesttype == 'VMAOWEFAMSVNAOWI'
                            ? 'General submitted'
                            : item.requesttype == this.transportationid
                              ? this.returnTransportation(item)
                              : item.requesttype == this.lateid
                                ? this.returnLateEarlyOut(item, 'Late')
                                : item.requesttype == this.earlyoutid
                                  ? this.returnLateEarlyOut(item, 'Early Out')
                                  : item.requesttype == this.workfromhomeid
                                    ? this.returnWFH(item)
                                    : item.requesttype == this.offinlieuid
                                      ? this.returnLateEarlyOut(item, 'Off In Lieu')
                                      : this.returnFacilites(item),
              }));
              this.nextDisabled = false;
              this.requestList.push.apply(this.requestList, res.requestlist);
              this.end = res.end;

              if (res.requestlist.length < 10) {
                this.nextDisabled = true;
                this.lastPage = this.currentPage;
              }
            } else {
              this.currentPage = this.currentPage - 1;
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
            }
          } else {
            this.messageService.openSnackBar(
              res.error || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingRequestList = false;
        },
        (err) => {
          this.gettingRequestList = false;
        }
      );
  }

  getTranType(id: any) {
    var temp = this.requestSubTypeList.find((x: any) => x.requesttypeid == id);
    if (temp) {
      this.requestForm.trantype = temp.trantype;
      this.requestForm.reservationtype = temp.reservationtype;
      this.requestForm.taxifare = temp.taxifare;
      if (temp.requesttypename == 'Travel') {
        this.isTravelClaim = true;
      } else {
        this.isTravelClaim = false;
        this.travelList = []
      }
    }

    if (this.requestForm.requesttype == this.reservationid) {
      if (this.requestForm.reservationtype == '001') {
        this.getAvailableRoomAndDesk();
      } else {
        if (this.requestForm.workstationid) {
          this.getAvailableRoomAndDesk();
        }
      }
    }
    if (this.isTravelClaim) {
      this.travelList = []
    }
  }

  getTemplate() {
    this.gettingTemplate = true;
    this.gettingRequestList = true;
    const data = {
      orgid: this.requestForm.orgid,
      // memberid: this.requestForm.userid,
    };
    this.template_subscription && this.template_subscription.unsubscribe();
    this.template_subscription = this.kunyekService
      .getAvailableTemplateByUser(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.requestTypeList = res.requesttypelist;
            if (
              this.requestTypeList.length > 0 &&
              !this.leaveForm &&
              !this.claimForm &&
              !this.lateForm &&
              !this.earlyOutForm
            ) {
              this.requestForm.requesttype =
                this.requestTypeList[0].requesttypeid;

              this.requestSubTypeList = this.requestTypeList[0].child;
              if (this.requestSubTypeList.length > 0) {
                this.requestForm.subtype =
                  this.requestSubTypeList[0].requesttypeid;
              }
            }

            this.getRequestList();
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingTemplate = false;
        },
        (err) => {
          this.gettingTemplate = false;
        }
      );
  }

  getWorkstation() {
    this.gettingWorkstation = true;
    const data = {
      orgid: this.requestForm.orgid,
    };
    this.kunyekService.getWorkstationList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.workstationList = res.workstationlist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingWorkstation = false;
      },
      (err) => {
        this.gettingWorkstation = false;
      }
    );
  }

  getPickupPoint() {
    this.gettingPickUp = true;
    this.pickupList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.requestForm.orgid,
      type: '001',
    };
    this.kunyekService.getPickup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.pickupList.push({
              name: item.name,
              user: '',
              userid: item.userid,
              requestdata: item.requestdata,
              usercounts: item.usercounts,
              pickuppointid: item.pickuppointid,
            });
          });

          this.gettingPickUp = false;
        } else {
          this.gettingPickUp = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingPickUp = false;
      }
    );
  }

  getTransportaionsList() {
    this.gettingtran = true;
    const data = {
      orgid: this.requestForm.orgid,
      requesttype: this.searchForm.trequesttype,
      requeststatus: this.searchForm.trequeststatus,
      startdate: this.searchForm.tstartdate
        ? this.allinoneService.formatDate(this.searchForm.tstartdate)
        : '',
      enddate: this.searchForm.tenddate
        ? this.allinoneService.formatDate(this.searchForm.tenddate)
        : '',
      end: this.tranend,
      requestid: this.searchForm.trequestid,
      parentid: this.parentid,
    };
    this.tran_sub && this.tran_sub.unsubscribe();
    this.tran_sub = this.kunyekService.getRequestList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.transportaionList = [];
          var tempList = res.requestlist[0]['child'];
          if (tempList.length > 0) {
            tempList = tempList.map((item: any) => ({
              ...item,
              isChecked: false,
              // requesttypename: this.requestTypeList.find(
              //   (x: any) => x.requesttypeid == item.requesttype
              // ).requesttypename,
              requeststatusname: this.statusList.find(
                (x: any) => x.value == item.requeststatus
              )?.name,
              // requestremark:
              //   item.requesttype == 'NCMLEIWHRNVIE'
              //     ? this.returnLeave(item)
              //     : item.requesttype == this.claimid
              //     ? this.returnClaim(item)
              //     : item.requesttype == 'UEYVNDLOAPQWXQW'
              //     ? this.returnOvertime(item)
              //     : item.requesttype == 'BCNDUWO85SCGQW'
              //     ? this.returnTravel(item)
              //     : item.requesttype == 'VMAOWEFAMSVNAOWI'
              //     ? 'General submitted'
              //     : item.requesttype == this.transportationid
              //     ? this.returnTransportation(item)
              //     : this.returnFacilites(item),
            }));
            this.nextDisabled = false;
            this.transportaionList.push.apply(this.transportaionList, tempList);
            this.tranend = res.end;

            if (tempList.length < 10) {
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
            }
          } else {
            this.currentPage = this.currentPage - 1;
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingtran = false;
      },
      (err) => {
        this.gettingtran = false;
        this.messageService.openSnackBar('Server Error', 'warn');
      }
    );
  }

  cancelRequest(transportaionCancel: boolean = false) {
    this.travelList = []
    if (transportaionCancel && this.isEditing) {
      this.transportationView = true;
      this.transportationDetail = false;
    }

    if (!transportaionCancel || (transportaionCancel && this.addingRequest)) {
      this.requestForm = {
        requestid: '',
        userid: this.allinoneService.getKunyekUserId(),
        orgid: this.requestForm.orgid,
        // requesttype: this.requestForm.requesttype,
        requesttype : this.requestTypeList[0].requesttypeid,
        requeststatus: '',
        personname: '',
        rejectperson: '',
        requeststatusname: '',
        subtype: '',
        startdate: this.currentDate,
        enddate: this.currentDate,
        starttimeampm: 'AM',
        endtimeampm: 'PM',
        starttime: this.currentTime,
        endtime: this.currentEndTime,
        isgoing: false,
        isreturn: false,
        toplace: '',
        dailyfrom: '',
        dailyto: '',
        arrivetime: this.currentTime,
        returntime: this.currentTime,
        trantype: '001',
        reservationtype: '001',
        weekday: [],
        amount: '0',
        currency: '',
        duration: '1',
        travelduration: '1',
        overtimehour: '01:00(1.0hrs)',
        remark: '',
        modeoftravel: [],
        vehicleuse: [],
        vehicleusename: [],
        modeoftravelname: [],
        refno: '',
        readonly: false,
        roomid: '',
        roomname: '',
        maxpeople: '',
        workstationid: '',
        floorname: '',
        deskid: '',
        reservation: false,
        taxifare: false,
        claimfromplace: '',
        claimtoplace: '',
        date: '',
        pickuppoint: '',
        transportationid: '',
        product: null,
        project: null,
        productname: '',
        projectname: '',
        // HR
        hrrequesttype: this.HRRequestTypeList[0].id,
        hrrequestdate: this.currentDate
      };

      this.approverList = [];
      this.accompanyPersonList = [];
      this.imageList = [];
      this.availableRoom = [];
      this.availableDesk = [];
    }

    this.addingRequest = false;
    this.isEditing = false;
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  newRequest() {
    this.addingRequest = true;
    this.isEditing = false;
    this.travelList = [];
    if (this.leaveForm) {
      this.requestForm.requesttype = 'NCMLEIWHRNVIE';
      this.requestTypeChange();
    }
    if (this.claimForm) {
      this.requestForm.requesttype = this.claimid;
      this.requestTypeChange();
    }
    if (this.lateForm) {
      this.requestForm.requesttype = this.lateid;
      this.requestTypeChange();
    }
    if (this.earlyOutForm) {
      this.requestForm.requesttype = this.earlyoutid;
      this.requestTypeChange();
    }
    if (this.workFromHomeForm) {
      this.requestForm.requesttype = this.workfromhomeid;
      this.requestTypeChange();
    }
    if (this.offInLieuForm) {
      this.requestForm.requesttype = this.offinlieuid;
      this.requestTypeChange();
    }
    if (this.cashAdvancedForm) {
      this.requestForm.requesttype = this.cashadvanced;
      this.requestTypeChange()
    }

    if (this.currencyList.length > 0) {
      this.requestForm.currency = this.currencyList[0].currencyid;
    }
    // console.log('s2');

    // console.log(this.transportationDetail);
    // console.log(this.transportationView);
    // console.log(this.addingRequest);
    // console.log(this.isEditing);
    // console.log('e2');
  }

  gotoTransportationView() {
    this.transportationView = true;
    this.transportationDetail = false;
    this.isEditing = false;
    this.addingRequest = false;
  }

  requestTypeChange() {
    var temp = this.requestTypeList.find(
      (x: any) => x.requesttypeid == this.requestForm.requesttype
    );
    this.requestSubTypeList = temp ? temp.child : [];
    var temp = this.requestSubTypeList.find(
      (x: any) => x.requesttypeid == this.requestForm.subtype
    );
    if (!temp) {
      this.requestForm.subtype =
        this.requestSubTypeList.length > 0
          ? this.requestSubTypeList[0].requesttypeid
          : '';

      this.getTranType(this.requestForm.subtype);
    }
  }

  submitRequest() {
    if (
      this.requestForm.requesttype == this.lateid ||
      this.requestForm.requesttype == this.earlyoutid
    ) {
      this.lateEarlyOut.submitRequest();
    } else if (this.requestForm.requesttype == this.workfromhomeid) {
      this.workFromHome.submitData();
    } else if (this.requestForm.requesttype == this.offinlieuid) {
      this.offInLieu.submitData();
    } else {
      if (
        (this.requestForm.requesttype == this.transportationid &&
          this.requestForm.trantype == '001' &&
          this.requestForm.remark.toString().trim() == '') ||
        this.requestForm.remark.toString().trim() == null
      ) {
        this.messageService.openSnackBar('Remark cannot be blank.', 'warn');
        return;
      }

      if (!this.requestForm.product && !this.requestForm.project && this.requestForm.requesttype == this.travelrequestid) {
        this.messageService.openSnackBar('Please select Product or Project.', 'warn');
        return;
      }

      if (
        (this.requestForm.requesttype == 'NCMLEIWHRNVIE' ||
          this.requestForm.requesttype == this.claimid) &&
        this.requestForm.subtype == ''
      ) {
        return this.messageService.openSnackBar(
          'Please choose ' +
          (this.requestForm.requesttype == 'NCMLEIWHRNVIE'
            ? 'leave'
            : 'claim') +
          ' type.',
          'warn'
        );
      }

      if (
        (this.requestForm.requesttype == this.claimid || this.requestForm.requesttype == this.cashadvanced) &&
        (this.requestForm.amount == '0' ||
          this.requestForm.amount == null ||
          this.requestForm.amount < 1)
      ) {
        console.log("hello from aa", this.requestForm.amount == '0' ||
          this.requestForm.amount == null ||
          this.requestForm.amount < 1)
        return this.messageService.openSnackBar(
          'Please fill a valid amount.',
          'warn'
        );
      }

      if (
        this.requestForm.requesttype == this.travelrequestid &&
        (this.requestForm.amount == '0' ||
          this.requestForm.amount == null ||
          this.requestForm.amount < 1)
      ) {
        return this.messageService.openSnackBar(
          'Please fill a valid amount.',
          'warn'
        );
      }

      if (
        this.requestForm.requesttype == this.travelrequestid &&
        (this.requestForm.remark.toString().trim() == '' ||
          this.requestForm.remark.toString().trim() == null)
      ) {
        this.messageService.openSnackBar('Please fill Traval Purpose.', 'warn');
        return;
      }

      if (
        this.requestForm.taxifare &&
        (this.requestForm.claimfromplace == '' ||
          this.requestForm.claimfromplace == null)
      ) {
        return this.messageService.openSnackBar(
          'Please fill From Place.',
          'warn'
        );
      }
      if (
        this.requestForm.taxifare &&
        (this.requestForm.claimtoplace == '' ||
          this.requestForm.claimtoplace == null)
      ) {
        return this.messageService.openSnackBar(
          'Please fill To Place.',
          'warn'
        );
      }
      if (
        this.requestForm.requesttype == 'UEYVNDLOAPQWXQW' &&
        this.requestForm.overtimehour == '00:00(0.00hrs)'
      ) {
        return this.messageService.openSnackBar(
          'Please choose valid date and time.',
          'warn'
        );
      }
      if (this.requestForm.requesttype == 'BCNDUWO85SCGQW') {
        if (this.requestForm.modeoftravel.length == 0) {
          return this.messageService.openSnackBar(
            'Please choose travel mode.',
            'warn'
          );
        }
        if (this.requestForm.vehicleuse.length == 0) {
          return this.messageService.openSnackBar(
            'Please choose vehicle.',
            'warn'
          );
        }
      }

      if (this.requestForm.requesttype == this.transportationid) {
        if (this.requestForm.trantype == '002') {
          if (this.requestForm.toplace == '') {
            this.messageService.openSnackBar(
              'To Place cannot be blank.',
              'warn'
            );
            return;
          }
          if (
            this.requestForm.dailyfrom == '' ||
            this.requestForm.dailyto == ''
          ) {
            this.messageService.openSnackBar(
              'Pick Up Place and Drop Off Place cannot be blank.',
              'warn'
            );
            return;
          }

          var check = this.approverList.filter(
            (x: any) => x.checked && (x.from == '' || x.to == '')
          );
          if (check.length > 0) {
            this.messageService.openSnackBar(
              'Pick Up Place and Drop Off Place for each member cannot be blank.',
              'warn'
            );
            return;
          }
        }
      }

      if (this.requestForm.requesttype == this.reservationid) {
        if (this.requestForm.reservationtype == '001') {
          if (this.requestForm.roomid == '') {
            this.messageService.openSnackBar('Please select a room.', 'warn');
            return;
          }
          if (this.requestForm.starttime >= this.requestForm.endtime) {
            this.messageService.openSnackBar('Invalid Time.', 'warn');
            return;
          }
        }
        if (this.requestForm.reservationtype == '002') {
          var startdate = new Date(
            this.requestForm.startdate + ' ' + this.requestForm.starttime
          );
          var enddate = new Date(
            this.requestForm.enddate + ' ' + this.requestForm.endtime
          );
          if (
            this.requestForm.workstationid == '' ||
            this.requestForm.deskid == ''
          ) {
            this.messageService.openSnackBar(
              'Please select floor and desk.',
              'warn'
            );
            return;
          }
          if (startdate >= enddate) {
            this.messageService.openSnackBar('Invalid Time.', 'warn');
            return;
          }
        }
      }

      this.addRequest();
    }
  }
  async addRequest() {
    this.saveLoading = true;
    var approvers: any = [];
    var accompany: any = [];
    var userfromto: any = [];

    this.approverList.map((x: any) => {
      if (x.checked) {
        approvers.push(x.userid);
        userfromto.push({
          userid: x.userid,
          fromplace: x.from ? x.from : '',
          toplace: x.to ? x.to : '',
        });
      }
    });

    this.accompanyPersonList.map((x: any) => {
      if (x.checked) {
        accompany.push(x.userid);
      }
    });

    const current_date = this.allinoneService.getCurrentDateToDB();
    const current_time = this.allinoneService.getCurrentTimeToDB2();
    var tmpimg = current_date.toString() + current_time.toString();

    // Image Upload
    var filenamelist: any = [];
    var remainImgNameList: any = [];
    var imgToUpload: any = [];
    for (let i = 0; i < this.imageList.length; i++) {
      if (this.imageList[i].file) {
        filenamelist.push(this.imageList[i].filename);

        if (this.imageList[i].file.size >= 307200) {
          this.imageList[i].file = await this.allinoneService.compressImage(
            this.imageList[i].file
          );
          if (this.imageList[i].file == false) {
            this.messageService.openSnackBar(
              'Error while uploading your file.',
              'warn'
            );
            this.saveLoading = false;
            return;
          } else {
            imgToUpload.push(this.imageList[i]);
          }
        } else {
          imgToUpload.push(this.imageList[i]);
        }
      } else {
        remainImgNameList.push(this.imageList[i].filename);
      }
    }

    if (
      imgToUpload.length > 0 &&
      this.requestForm.requesttype != 'UEYVNDLOAPQWXQW'
    ) {
      const result = await this.allinoneService.multipleUploadToAWS(
        imgToUpload,
        'office/request/' +
        (this.isEditing ? this.requestForm.requestid : tmpimg)
      );
      if (!result) {
        this.messageService.openSnackBar(
          'Error while uploading your file.',
          'warn'
        );
        this.saveLoading = false;
        return;
      }
    }

    var data: any;
    console.log(this.requestForm.requesttype, 'reqtype..')
    if (this.requestForm.requesttype == 'NCMLEIWHRNVIE') {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttimeampm,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtimeampm,
        duration: this.requestForm.duration,
        remark: this.requestForm.remark,
        tmpimg: this.isEditing ? '' : tmpimg,
        imagelist: filenamelist,
        deleteimagelist: remainImgNameList,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (
      this.requestForm.requesttype == this.lateid ||
      this.requestForm.requesttype == this.earlyoutid
    ) {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: '',
        endtime: this.requestForm.endtime,
        duration: this.requestForm.duration,
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.workfromhomeid) {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        starttime: '',
        endtime: '',
        duration: this.requestForm.duration,
        remark: this.requestForm.remark,
        lat: this.requestForm.lat,
        long: this.requestForm.long,
        location: this.requestForm.location,
        locationid: this.requestForm.locationid,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.offinlieuid) {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        date: this.allinoneService.formatDate(this.requestForm.date),
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        enddate: '',
        starttime: this.requestForm.starttime,
        endtime: this.requestForm.endtime,
        duration: '',
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.claimid || this.requestForm.requesttype == this.cashadvanced) {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        claimdate: this.allinoneService.formatDate(this.requestForm.startdate),
        amount: this.requestForm.amount.toString(),
        currencyid: this.requestForm.currency,
        remark: this.requestForm.remark,
        tmpimg: this.isEditing ? '' : tmpimg,
        imagelist: filenamelist,
        deleteimagelist: remainImgNameList,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
        fromplace: this.requestForm.taxifare
          ? this.requestForm.claimfromplace
          : '',
        toplace: this.requestForm.taxifare ? this.requestForm.claimtoplace : '',
      };
    } else if (this.requestForm.requesttype == 'UEYVNDLOAPQWXQW') {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtime,
        duration: this.requestForm.overtimehour,
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == 'VMAOWEFAMSVNAOWI') {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.startdate),
        endtime: this.requestForm.starttime,
        duration: '',
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers
      };
    } else if (this.requestForm.requesttype == this.transportationid) {
      data = {
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: '',
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: '',
        weekday: this.requestForm.weekday,
        toplace:
          this.requestForm.trantype == '002' ? this.requestForm.toplace : '',
        going:
          this.requestForm.trantype == '002' ? this.requestForm.isgoing : '',
        return:
          this.requestForm.trantype == '002' ? this.requestForm.isreturn : '',
        arrivetime: this.requestForm.arrivetime,
        returntime: this.requestForm.returntime,
        userlist: [],
        trantype: this.requestForm.trantype,
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        deleteimagelist: [],
        approver: [],
        userfromto: userfromto,
        userfrom: this.requestForm.dailyfrom,
        userto: this.requestForm.dailyto,
        pickuppointid: this.requestForm.pickuppoint,
        parentid: this.parentid,
      };
    } else if (this.requestForm.requesttype == this.reservationid) {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate:
          this.requestForm.reservationtype == '002'
            ? this.allinoneService.formatDate(this.requestForm.enddate)
            : '',
        endtime: this.requestForm.endtime,
        weekday: [],
        toplace: '',
        going: '',
        return: '',
        arrivetime: '',
        returntime: '',
        userlist: [],
        trantype: '',
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        deleteimagelist: [],
        approver: [],
        userfromto: [],
        userfrom: '',
        userto: '',
        reservationtype: this.requestForm.reservationtype,
        roomid:
          this.requestForm.reservationtype == '001'
            ? this.requestForm.roomid
            : '',
        workstationid:
          this.requestForm.reservationtype == '002'
            ? this.requestForm.workstationid
            : '',
        deskid:
          this.requestForm.reservationtype == '002'
            ? this.requestForm.deskid
            : '',
      };
    } else if (this.requestForm.requesttype == this.claimid) {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtime,
        duration: this.requestForm.travelduration,
        modeoftravel: this.requestForm.modeoftravel,
        vehicleuse: this.requestForm.vehicleuse,
        remark: this.requestForm.remark,
        amount: this.requestForm.amount.toString(),
        currencyid: this.requestForm.currency,
        tmpimg: this.isEditing ? '' : tmpimg,
        imagelist: filenamelist,
        deleteimagelist: remainImgNameList,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
        productid: this.requestForm.product ? this.requestForm.product : '',
        projectid: this.requestForm.project ? this.requestForm.project : '',
        accompanyperson: accompany,

      };
      if (this.requestForm.requestsubtypename == 'Travel') {
        data.travelrequestid = this.travelList[0].travelrequestid
      }
    } else if (this.requestForm.requesttype == this.hrid) {
      data = {
        hrrequestid: '',
        orgid: this.requestForm.orgid,
        typeid: this.requestForm.hrrequesttype,
        requestdate: this.allinoneService.formatDate(this.requestForm.hrrequestdate),
        description: this.requestForm.remark,
      }
      this.tempHRAdd(data);
      return;
    }
    else {
      data = {
        memberid: '',
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtime,
        duration: this.requestForm.travelduration,
        modeoftravel: this.requestForm.modeoftravel,
        vehicleuse: this.requestForm.vehicleuse,
        remark: this.requestForm.remark,
        tmpimg: this.isEditing ? '' : tmpimg,
        imagelist: filenamelist,
        deleteimagelist: remainImgNameList,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    }




    if (
      this.isEditing &&
      this.requestForm.requesttype != this.transportationid
    ) {
      data = Object.assign(data, { requestid: this.requestForm.requestid });
    } else if (
      this.isEditing &&
      this.requestForm.requesttype == this.transportationid
    ) {
      data = Object.assign(data, {
        requestid: this.requestForm.transportationid,
        transportation: true,
      });
    }
    if (this.requestForm.requesttype == this.claimid) {
      data = Object.assign(data, {
        travelrequestid: this.travelList[0].requestid
      })
    }
    console.log(data, 'data...............................')
    var fun = this.isEditing
      ? this.kunyekService.updateOfficeRequest(data)
      : this.kunyekService.addOfficeRequest(data);
    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (
            this.requestForm.requesttype == this.transportationid &&
            this.isEditing
          ) {
            this.tranrefresh();
            this.gotoTransportationView();
          } else {
            this.cancelRequest();
            this.refresh();
          }
          this.travelList = [];
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    );
  }

  tempHRAdd(data: any) {
    this.subscriptions.sink = this.kunyekService.createAccessoriesRequest(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.requestForm.userid = '';
          this.cancelRequest();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    )
  }

  openLeaveSummaryForm() {
    const dialog = this.dialog.open(OfficeLeaveSummaryComponent, {
      maxWidth: '95vw',
      width: '550px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        memberid: this.requestForm.userid,
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.requestForm.orgid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => { });
  }

  calculateDuration(sec: boolean = false) {
    if (this.requestForm.startdate > this.requestForm.enddate) {
      this.requestForm.enddate = this.requestForm.startdate;
    }
    if (this.requestForm.startdate == this.requestForm.enddate) {
      this.requestForm.travelduration = '1';
      if (
        this.requestForm.starttimeampm == 'PM' &&
        this.requestForm.endtimeampm == 'AM'
      ) {
        if (sec) {
          this.requestForm.starttimeampm = 'AM';
        } else {
          this.requestForm.endtimeampm = 'PM';
        }
      }
      if (this.requestForm.starttimeampm == this.requestForm.endtimeampm) {
        this.requestForm.duration = '0.5';
      } else {
        this.requestForm.duration = '1';
      }
      if (this.requestForm.starttime > this.requestForm.endtime) {
        if (sec) {
          this.requestForm.starttime = this.requestForm.endtime;
        } else {
          this.requestForm.endtime = this.requestForm.starttime;
        }
      }
      var a = new Date(
        this.requestForm.startdate + ' ' + this.requestForm.starttime
      );
      var b = new Date(
        this.requestForm.enddate + ' ' + this.requestForm.endtime
      );
      var hrDif = this.allinoneService.dateDifference(a, b, true);
      var hours = Math.floor(hrDif / 60);
      var mins = hrDif % 60;
      var hrmin = (hrDif / 60).toFixed(2);
      this.requestForm.overtimehour =
        '' +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (mins < 10 ? '0' + mins : mins) +
        '(' +
        hrmin +
        'hrs)';
    } else {
      var start = new Date(this.requestForm.startdate);
      var end = new Date(this.requestForm.enddate);
      var dif = this.allinoneService.dateDifference(start, end);
      this.requestForm.travelduration = (dif + 1).toString();
      if (this.requestForm.starttimeampm == this.requestForm.endtimeampm) {
        this.requestForm.duration = (dif + 0.5).toString();
      } else {
        if (
          this.requestForm.starttimeampm == 'AM' &&
          this.requestForm.endtimeampm == 'PM'
        ) {
          this.requestForm.duration = (dif + 1).toString();
        } else {
          this.requestForm.duration = dif.toString();
        }
      }
      var a = new Date(
        this.requestForm.startdate + ' ' + this.requestForm.starttime
      );
      var b = new Date(
        this.requestForm.enddate + ' ' + this.requestForm.endtime
      );
      var hrDif = this.allinoneService.dateDifference(a, b, true);
      var hours = Math.floor(hrDif / 60);
      var mins = hrDif % 60;
      var hrmin = (hrDif / 60).toFixed(2);
      this.requestForm.overtimehour =
        '' +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (mins < 10 ? '0' + mins : mins) +
        '(' +
        hrmin +
        'hrs)';
    }
    if (this.requestForm.requesttype == 'NCMLEIWHRNVIE') {
      this.gettingDuration = true;
      const data = {
        requesttype: 'NCMLEIWHRNVIE',
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtimeampm,
        duration: this.requestForm.duration,
        orgid: this.requestForm.orgid,
      };
      this.duration_subscription && this.duration_subscription.unsubscribe();
      this.duration_subscription = this.kunyekService
        .getLeaveCounting(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.requestForm.duration = res.duration;
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || 'Server Error',
                'warn'
              );
            }
            this.gettingDuration = false;
          },
          (err) => {
            this.gettingDuration = false;
          }
        );
    }
  }

  onFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.allinoneService.getFileName(file),
            };
            this.imageList.push(temp);
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }
  removeImage(index: number) {
    if (this.requestForm.readonly) {
      return;
    }
    this.imageList.splice(index, 1);
  }

  productChange() {
    if (this.requestForm.project) {
      this.requestForm.project = null;
    }
  }

  projectChange() {
    if (this.requestForm.product) {
      this.requestForm.product = null;
    }
  }

  chooseUser() {
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.approverList,
        isLeaveOpening: false,
        orgid: this.requestForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const tempApprovalList = dialogResult.data.filter(
          (data: { checked: boolean; userid: string }) => {
            return (
              data.checked == true && data.userid != this.requestForm.userid
            );
          }
        );
        const oldApproverList = JSON.parse(JSON.stringify(this.approverList));
        this.approverList = [];
        tempApprovalList.map((x: any) => {
          const temp = oldApproverList.find(
            (user: any) => user.userid == x.userid
          );
          this.approverList.push({
            userid: x.userid,
            employeeid: x.employeeid,
            username: x.username,
            from: temp ? temp.from : '',
            to: temp ? temp.to : '',
            checked: true,
          });
        });
      }
    });
  }

  chooseTravel() {
    const dialog = this.dialog.open(SelectTravelComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        travellist: this.travelList,
        isLeaveOpening: false,
        orgid: this.requestForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // const tempTravelList = dialogResult.data.filter(
        //   (data: { checked: boolean; requestid: string }) => {
        //     return (
        //       data.checked == true && data.requestid != this.requestForm.requestid
        //     );
        //   }
        // );
        // const oldTravelList = JSON.parse(JSON.stringify(this.travelList));
        // console.log(oldTravelList,'oldtravelList,,,,,,,,,,')
        // console.log(tempTravelList,'...temp.....................')
        // this.travelList = [];
        // tempTravelList.map((x: any) => {
        //   const temp = oldTravelList.find(
        //     (trvl: any) => trvl.requestid == x.requestid
        //   );
        //   this.travelList.push({
        //     requestid: x.requestid,
        //     checked: true,
        //   });
        //   console.log(this.travelList,'travellist,//////////////////////////////')
        // });

        this.travelList = dialogResult.data
      }
    });
  }

  chooseAccompanyPerson() {
    const dialog = this.dialog.open(SelectTravelComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.accompanyPersonList,
        isLeaveOpening: false,
        orgid: this.requestForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const tempList = dialogResult.data.filter(
          (data: { checked: boolean; userid: string }) => {
            return (
              data.checked == true && data.userid != this.requestForm.userid
            );
          }
        );
        const oldList = JSON.parse(JSON.stringify(this.accompanyPersonList));
        this.accompanyPersonList = [];
        tempList.map((x: any) => {
          const temp = oldList.find(
            (user: any) => user.userid == x.userid
          );
          this.accompanyPersonList.push({
            userid: x.userid,
            employeeid: x.employeeid,
            username: x.username,
            from: temp ? temp.from : '',
            to: temp ? temp.to : '',
            checked: true,
          });
        });
      }
    });
  }

  sortData(sort: Sort) {
    const data = this.requestList;
    if (!sort.active || sort.direction === '') {
      this.requestList = data;
      return;
    }
    this.requestList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(
            a.employeeid,
            b.employeeid,
            isAsc
          );

        case 'refno':
          return this.allinoneService.compare(
            parseInt(a.refno),
            parseInt(b.refno),
            isAsc
          );
        case 'date':
          return this.allinoneService.compare(
            a.claimdate ? a.claimdate : a.startdate + a.enddate,
            a.claimdate ? a.claimdate : a.startdate + a.enddate,
            isAsc
          );
        case 'type':
          return this.allinoneService.compare(
            a.requesttypename,
            b.requesttypename,
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(
            a.requeststatusname,
            b.requeststatusname,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  tranSortData(sort: Sort) {
    const data = this.transportaionList;
    if (!sort.active || sort.direction === '') {
      this.transportaionList = data;
      return;
    }
    this.transportaionList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'date':
          return this.allinoneService.compare(a.date, b.date, isAsc);
        case 'status':
          return this.allinoneService.compare(
            a.requeststatusname,
            b.requeststatusname,
            isAsc
          );
        case 'toplace':
          return this.allinoneService.compare(a.toplace, b.toplace, isAsc);
        case 'userfrom':
          return this.allinoneService.compare(a.userfrom, b.userfrom, isAsc);
        case 'userto':
          return this.allinoneService.compare(a.userto, b.userto, isAsc);
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        case 'pickuppointid':
          return this.allinoneService.compare(
            a.pickuppointid,
            b.pickuppointid,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  cancelToRequest() {
    this.transportationDetail = false;
    this.transportationView = false;
    this.isEditing = false;
    this.addingRequest = false;
    this.tranend = '';
    this.requestForm = {
      requestid: '',
      userid: this.allinoneService.getKunyekUserId(),
      orgid: this.requestForm.orgid,
      requesttype: this.requestTypeList[0].requesttypeid,
      requeststatus: '',
      personname: '',
      rejectperson: '',
      requeststatusname: '',
      subtype: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttimeampm: 'AM',
      endtimeampm: 'PM',
      starttime: this.currentTime,
      endtime: this.currentEndTime,
      isgoing: false,
      isreturn: false,
      toplace: '',
      dailyfrom: '',
      dailyto: '',
      arrivetime: this.currentTime,
      returntime: this.currentTime,
      trantype: '001',
      reservationtype: '001',
      weekday: [],
      amount: '0',
      currency: '',
      duration: '1',
      travelduration: '1',
      overtimehour: '01:00(1.0hrs)',
      remark: '',
      modeoftravel: [],
      vehicleuse: [],
      vehicleusename: [],
      modeoftravelname: [],
      refno: '',
      readonly: false,
      roomid: '',
      roomname: '',
      maxpeople: '',
      workstationid: '',
      floorname: '',
      deskid: '',
      reservation: false,
      taxifare: false,
      claimfromplace: '',
      claimtoplace: '',
      date: '',
      pickuppoint: '',
      transportationid: '',
    };
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  transportationcancel() {
    this.transportationView = false;
    this.tranend = '';
    this.requestForm = {
      requestid: '',
      userid: this.allinoneService.getKunyekUserId(),
      orgid: this.requestForm.orgid,
      requesttype: this.requestTypeList[0].requesttypeid,
      requeststatus: '',
      personname: '',
      rejectperson: '',
      requeststatusname: '',
      subtype: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttimeampm: 'AM',
      endtimeampm: 'PM',
      starttime: this.currentTime,
      endtime: this.currentEndTime,
      isgoing: false,
      isreturn: false,
      toplace: '',
      dailyfrom: '',
      dailyto: '',
      arrivetime: this.currentTime,
      returntime: this.currentTime,
      trantype: '001',
      reservationtype: '001',
      weekday: [],
      amount: '0',
      currency: '',
      duration: '1',
      travelduration: '1',
      overtimehour: '01:00(1.0hrs)',
      remark: '',
      modeoftravel: [],
      vehicleuse: [],
      vehicleusename: [],
      modeoftravelname: [],
      refno: '',
      readonly: false,
      roomid: '',
      roomname: '',
      maxpeople: '',
      workstationid: '',
      floorname: '',
      deskid: '',
      reservation: false,
      taxifare: false,
      claimfromplace: '',
      claimtoplace: '',
      date: '',
      pickuppoint: '',
      transportationid: '',
    };
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  transportationcancel2() {
    this.transportationDetail = false;
    this.tranend = '';
    this.isEditing = false;
    this.addingRequest = false;
    this.transportationView = true;
    this.searchForm.trequeststatus = '';
    this.searchForm.trequeststatusname = 'All';
  }

  editRequest(
    request: any,
    transportationView: boolean = false,
    transportationDetail: boolean = false
  ) {
    this.travelList = [];
    if (request.travelrequestid) {
      this.traveleditid = request.travelrequestid
      this.getTravelList();
    }

    if (transportationDetail) {
      this.transportationDetail = true;
      this.requestForm.refno = request.requestrefno;
    }

    if (transportationView) {
      this.transportationView = true;
      this.isEditing = false;
      this.addingRequest = false;
      this.requestForm.refno = request.requestrefno;
      this.searchForm.trequestid = request.requestid;
      this.transubtype = request.requestsubtype;
      this.transubtypename = request.requestsubtypename;
      this.tranrefno = request.requestrefno;
      this.tabletrantype = request.trantype;
      this.parentid = request.olduserid;
      this.getTransportaionsList();
    } else {
      this.isEditing = true;
      for (var i = 0; i < this.requestTypeList.length; i++) {
        if (this.requestTypeList[i]['requesttypeid'] == request.requesttype) {
          this.requestSubTypeList = this.requestTypeList[i].child;
        }
      }

      this.transportationView = false;
      this.addingRequest = true;

      if (transportationDetail) {
        this.requestForm = {
          requestid: '',
          userid: this.requestForm.userid,
          orgid: this.requestForm.orgid,
          requesttype: this.transportationid,
          requeststatus: request.requeststatus,
          personname: '',
          rejectperson: '',
          requeststatusname: '',
          // subtype: this.transubtypename,
          subtype: 'XY7DZ3YRYFAETOP',
          startdate: '',
          enddate: '',
          starttimeampm: '',
          endtimeampm: '',
          starttime: '',
          endtime: '',
          amount: '',
          currency: '',
          duration: '',
          travelduration: '',
          overtimehour: '',
          remark: request.remark,
          modeoftravel: '',
          vehicleuse: '',
          vehicleusename: [],
          modeoftravelname: [],
          refno: this.tranrefno,
          readonly: request.requeststatus != '001',
          weekday: '',
          toplace: request.toplace,
          // fromto in edit
          dailyfrom: request.userfrom,
          dailyto: request.userto,
          isgoing: request.going,
          isreturn: request.return,
          arrivetime: request.arrivetime || this.currentTime,
          returntime: request.returntime || this.currentTime,
          trantype: request.trantype,
          reservationtype: '',
          roomid: '',
          roomname: '',
          floorname: '',
          maxpeople: '',
          workstationid: '',
          deskid: '',
          reservation: '',
          taxifare: false,
          claimfromplace: '',
          claimtoplace: '',
          date: this.allinoneService.formatDBToShowInEdit(request.date),
          pickuppoint: request.pickuppointid,
          transportationid: request.transportationid,
        };
      } else {
        this.requestForm.refno = request.requestrefno;
        this.requestForm = {
          requestid: request.requestid,
          userid: this.requestForm.userid,
          orgid: this.requestForm.orgid,
          requesttype: request.requesttype,
          requeststatus: request.requeststatus,
          personname: request.personname,
          rejectperson: request.rejectperson,
          requeststatusname: request.requeststatusname,
          subtype: request.requestsubtype,
          startdate: request.startdate
            ? this.allinoneService.formatDBToShowInEdit(request.startdate)
            : this.currentDate,
          enddate: request.enddate
            ? this.allinoneService.formatDBToShowInEdit(request.enddate)
            : this.currentDate,
          starttimeampm:
            request.starttime != 'AM' && request.starttime != 'PM'
              ? 'AM'
              : request.starttime,
          endtimeampm:
            request.endtime != 'AM' && request.endtime != 'PM'
              ? 'PM'
              : request.endtime,
          starttime:
            request.starttime == 'AM' || request.starttime == 'PM'
              ? this.currentTime
              : request.starttime,
          endtime:
            request.endtime == 'AM' || request.endtime == 'PM'
              ? this.currentEndTime
              : request.endtime,
          amount: request.amount,
          currency: request.currencyid,
          duration: request.duration,
          travelduration: request.duration,
          overtimehour: request.duration,
          remark: request.remark,
          modeoftravel: request.modeoftravel,
          vehicleuse: request.vehicleuse,
          vehicleusename: [],
          modeoftravelname: [],
          refno: request.requestrefno,
          readonly: request.readonly || request.requeststatus != '001',
          weekday: request.weekday,
          toplace: request.toplace,
          // fromto in edit
          dailyfrom: request.userfrom,
          dailyto: request.userto,
          isgoing: request.going,
          isreturn: request.return,
          arrivetime: request.arrivetime || this.currentTime,
          returntime: request.returntime || this.currentTime,
          trantype: request.trantype,
          reservationtype: request.reservationtype,
          roomid: request.roomid,
          roomname: request.roomname,
          floorname: request.floorname,
          maxpeople: request.maxpeople,
          workstationid: request.workstationid,
          deskid: request.deskid,
          reservation: request.reservation,
          taxifare: false,
          claimfromplace: request.fromplace,
          claimtoplace: request.toplace,
          date: request.date
            ? this.allinoneService.formatDBToShowInEdit(request.date)
            : '',
          pickuppoint: '',
          transportationid: '',
          lat: request.lat,
          long: request.long,
          location: request.location,
          locationid: request.locationid,
          product: request.productid,
          project: request.projectid,
          productname: request.productname,
          projectname: request.projectname,
        };
      }

      console.log('s 2');

      if (this.requestForm.requesttype == this.reservationid) {
        this.getAvailableRoomAndDesk(true);
      }

      if (this.requestForm.requesttype == this.claimid) {
        this.getTranType(this.requestForm.subtype);
      }
      // this.requestSubTypeList =

      if (this.requestForm.requesttype == 'BCNDUWO85SCGQW') {
        for (var i = 0; i < this.requestForm.modeoftravel.length; i++) {
          var modeObj = this.modeoftravel.find(
            (x: any) => x.key == this.requestForm.modeoftravel[i]
          );
          if (modeObj) {
            this.requestForm.modeoftravelname.push(modeObj.value);
          }
        }
        for (var i = 0; i < this.requestForm.vehicleuse.length; i++) {
          var modeObj = this.usevehicle.find(
            (x: any) => x.key == this.requestForm.vehicleuse[i]
          );
          if (modeObj) {
            this.requestForm.vehicleusename.push(modeObj.value);
          }
        }
      }

      if (!transportationDetail) {
        for (let i = 0; i < request.image.imgurl.length; i++) {
          this.imageList.push({
            file: '',
            previewurl: request.image.imgurl[i],
            filename: request.image.imgname[i],
          });
        }
      }

      // request.image.imgurl.map((url: string) => {
      //   this.imageList.push({
      //     file: '',
      //     previewurl: url,
      //     filename: '',
      //   });
      // });

      if (!transportationDetail) {
        this.approverList =
          request.approver.length == 0 ? request.userlist : request.approver;
        this.approverList.map((user: any) => {
          user.checked = true;
        });
        this.accompanyPersonList = request.accompanyperson;
        this.accompanyPersonList.map((user: any) => {
          user.checked = true;
        });
      }

      // if (this.requestForm.requesttype == this.transportationid) {
      //   this.approverList = [];
      //   request.userfromto.map((user: any) => {
      //     this.approverList.push({
      //       userid: user.userid,
      //       username: user.username,
      //       userimage: user.userimage,
      //       from: user.fromplace,
      //       to: user.toplace,
      //       checked: true,
      //     });
      //   });
      // }
    }
    // console.log('s2');

    // console.log(this.transportationDetail);
    // console.log(this.transportationView);
    // console.log(this.addingRequest);
    // console.log(this.isEditing);
    // console.log('e2');
  }

  returnLeave(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value =
        this.splicDuration(data.duration) +
        ' days of ' +
        data.requestsubtypename.toLowerCase() +
        ' leave submitted';
    } else if (data.requeststatus == '002') {
      value =
        this.splicDuration(data.duration) +
        ' days of ' +
        data.requestsubtypename.toLowerCase() +
        ' leave approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        this.splicDuration(data.duration) +
        ' days of ' +
        data.requestsubtypename.toLowerCase() +
        ' leave rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '004') {
      value =
        this.splicDuration(data.duration) +
        ' days of ' +
        data.requestsubtypename.toLowerCase() +
        ' leave completed';
    }
    return value;
  }

  returnClaim(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value =
        data.requestsubtypename +
        ' ( ' +
        data.amount.toString() +
        ' )' +
        data.currencyname +
        ' submitted';
    } else if (data.requeststatus == '002') {
      value =
        data.requestsubtypename +
        ' ( ' +
        data.amount.toString() +
        ' )' +
        data.currencyname +
        ' approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.claimdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        data.requestsubtypename +
        ' ( ' +
        data.amount.toString() +
        ' )' +
        data.currencyname +
        ' rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.claimdate) +
        ')';
    } else if (data.requeststatus == '004') {
      value =
        data.requestsubtypename +
        ' ( ' +
        data.amount.toString() +
        ' )' +
        data.currencyname +
        ' completed ' +
        '(' +
        this.allinoneService.formatDBToShow(data.claimdate) +
        ')';
    }
    return value;
  }

  returnOvertime(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value = data.duration.toString() + ' submitted';
    } else if (data.requeststatus == '002') {
      value =
        data['duration'].toString() +
        ' approved by ' +
        data['personname'] +
        ' (' +
        this.allinoneService.formatDBToShow(data['startdate']) +
        ')';
    } else if (data['requeststatus'] == '003') {
      value =
        data['duration'].toString() +
        ' rejected by ' +
        data['personname'] +
        ' (' +
        this.allinoneService.formatDBToShow(data['startdate']) +
        ')';
    } else if (data['requeststatus'] == '004') {
      value = data['duration'].toString() + ' completed';
    }
    return value;
  }

  returnFacilites(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value = data.requestsubtypename.toLowerCase() + ' reservation submitted';
    } else if (data.requeststatus == '002') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' reservation approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' reservation rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '004') {
      value = data.requestsubtypename.toLowerCase() + ' reservation completed';
    }
    return value;
  }

  returnWFH(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' work from home request submitted';
    } else if (data.requeststatus == '002') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' work from home request approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' work from home request rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '004') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' work from home request completed';
    }
    return value;
  }

  returnLateEarlyOut(data: any, type: string) {
    var value = '';
    if (data.requeststatus == '001') {
      value = type + ' submitted';
    } else if (data.requeststatus == '002') {
      value =
        type +
        ' approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        type +
        ' rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '004') {
      type + ' completed';
    }
    return value;
  }

  returnTransportation(data: any) {
    var value = '';
    if (data.requeststatus == '001') {
      value =
        data.requestsubtypename.toLowerCase() + ' transportation submitted';
    } else if (data.requeststatus == '002') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' transportation approved by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '003') {
      value =
        data.requestsubtypename.toLowerCase() +
        ' transportation rejected by ' +
        data.personname +
        ' (' +
        this.allinoneService.formatDBToShow(data.startdate) +
        ')';
    } else if (data.requeststatus == '004') {
      value =
        data.requestsubtypename.toLowerCase() + ' transportation completed';
    }
    return value;
  }

  returnTravel(data: any) {
    var value = '';
    if (data['requeststatus'] == '001') {
      value =
        data['duration'].toString() +
        ' days of ' +
        data['remark'] +
        ' travel submitted.';
    } else if (data['requeststatus'] == '002') {
      value =
        data['duration'].toString() +
        ' days of ' +
        data['remark'] +
        ' travel approved by ' +
        data['personname'] +
        ' (' +
        this.allinoneService.formatDBToShow(data['startdate']) +
        ')';
    } else if (data['requeststatus'] == '003') {
      value =
        data['duration'].toString() +
        ' days of ' +
        data['remark'] +
        ' travel rejected by ' +
        data['personname'] +
        ' (' +
        this.allinoneService.formatDBToShow(data['startdate']) +
        ')';
    } else if (data['requeststatus'] == '004') {
      value =
        data['duration'].toString() +
        ' days of ' +
        data['remark'] +
        ' travel completed';
    }
    return value;
  }

  splicDuration(data: any) {
    var check = data.indexOf('.');
    var point = data.substring(check + 1, data.length);
    // if (point == "0") {
    //   return data.substring(0, check);
    // } else {
    //   return data;
    // }
    return data;
  }

  clear() {
    this.searchText = '';
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  refresh() {
    // this.count = 0;
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.requestList = [];
    this.getRequestList();
  }

  tranrefresh() {
    // this.count = 0;
    this.tranend = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.transportaionList = [];
    this.getTransportaionsList();
  }

  filter(transportation: boolean = false) {
    const dialog = this.dialog.open(OfficeUserRequestFilterComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        requeststatus: this.searchForm.requeststatus,
        requestList: this.requestTypeList,
        startdate: this.searchForm.startdate,
        enddate: this.searchForm.enddate,
        requesttype: this.searchForm.requesttype,
        istran: transportation,
        hideRequest: this.leaveForm || this.claimForm || this.lateForm || this.earlyOutForm || this.workFromHomeForm || this.offInLieuForm || this.cashAdvancedForm
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      console.log('dialogResult', dialogResult)
      if (dialogResult) {
        // console.log(dialogResult.istran);

        if (dialogResult.istran) {
          this.searchForm.trequeststatus = dialogResult.requeststatus;
          this.searchForm.trequeststatusname =
            this.searchForm.trequeststatus == ''
              ? 'All'
              : this.statusList.find(
                (x: any) => x.value == this.searchForm.trequeststatus
              )!.name;
          this.searchForm.tstartdate = dialogResult.startdate;
          this.searchForm.tenddate = dialogResult.enddate;
          this.searchForm.trequesttype = dialogResult.requesttype;
          this.searchForm.trequesttypename =
            this.searchForm.trequesttype == ''
              ? 'All'
              : this.requestTypeList.find(
                (x: any) => x.requesttypeid == this.searchForm.trequesttype
              ).requesttypename;
          this.tranrefresh();
        } else {
          this.searchForm.requeststatus = dialogResult.requeststatus;
          this.searchForm.requeststatusname =
            this.searchForm.requeststatus == ''
              ? 'All'
              : this.statusList.find(
                (x: any) => x.value == this.searchForm.requeststatus
              )!.name;
          this.searchForm.startdate = dialogResult.startdate;
          this.searchForm.enddate = dialogResult.enddate;
          this.searchForm.requesttype = dialogResult.requesttype;
          this.searchForm.requesttypename =
            this.searchForm.requesttype == ''
              ? 'All'
              : this.requestTypeList.find(
                (x: any) => x.requesttypeid == this.searchForm.requesttype
              ).requesttypename;
          this.refresh();
        }
      }
    });
  }

  pageChange(event: any) {
    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;
        if (event > this.requestList.length / this.itemPerPage) {
          this.getRequestList();
        }
      }
    }
  }

  clearFilter() {
    this.searchForm = {
      startdate: '',
      enddate: '',
      requesttype: '',
      requesttypename: '',
      requeststatus: this.searchForm.requeststatus,
      requeststatusname: this.searchForm.requeststatusname,
      // transportation
      trequestid: '',
      trequesttype: '',
      trequesttypename: '',
      trequeststatus: this.searchForm.trequeststatus,
      trequeststatusname: this.searchForm.trequeststatusname,
      tstartdate: '',
      tenddate: '',
    };
    this.refresh();
  }

  getPickUpPointName(id: any) {
    if (id == '' || id == undefined) {
      return '';
    }
    var temp = this.pickupList.find((x: any) => x.pickuppointid == id);
    return temp.name;
  }

  getAvailableRoomAndDesk(fromedit: boolean = false) {
    if (
      this.requestForm.reservationtype == '002' &&
      this.requestForm.workstationid == ''
    ) {
      return;
    }
    this.gettingAvailableRoomAndDesk = true;
    const data = {
      reservationtype: this.requestForm.reservationtype,
      startdate: this.allinoneService.formatDate(this.requestForm.startdate),
      enddate: this.allinoneService.formatDate(this.requestForm.enddate),
      starttime: this.requestForm.starttime,
      endtime: this.requestForm.endtime,
      requesttype: this.requestForm.requesttype,
      orgid: this.requestForm.orgid,
      workstationid: this.requestForm.workstationid,
      requestid: this.isEditing ? this.requestForm.requestid : '',
    };
    this.reservation_subscription &&
      this.reservation_subscription.unsubscribe();
    this.reservation_subscription = this.kunyekService
      .getAvailableRoomAndDesk(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (this.requestForm.reservationtype == '001') {
              if (!fromedit) {
                this.requestForm.roomid = '';
                this.requestForm.maxpeople = '';
              }
              this.availableRoom = res.availablelist;
            } else {
              if (!fromedit) {
                this.requestForm.deskid = '';
              }
              this.availableDesk = res.availablelist;
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingAvailableRoomAndDesk = false;
        },
        (err) => {
          this.gettingAvailableRoomAndDesk = false;
        }
      );
  }

  roomChange() {
    if (this.requestForm.roomid == '') {
      this.requestForm.maxpeople = '';
    } else {
      var temp = this.availableRoom.find(
        (x: any) => x.roomid == this.requestForm.roomid
      );
      if (temp) {
        this.requestForm.maxpeople = temp.maxpeople;
      }
    }
  }

  workstationChange() {
    if (this.requestForm.workstationid == '') {
      this.availableDesk = [];
    } else {
      this.getAvailableRoomAndDesk();
    }
  }

  submitLateEarlyOut(event: any) {
    const { requestData, approverList } = event;
    this.requestForm.starttime = requestData.starttime;
    this.requestForm.endtime = requestData.endtime;
    this.requestForm.remark = requestData.remark;
    this.requestForm.duration = requestData.duration;
    this.requestForm.startdate = requestData.date;
    this.approverList = approverList;
    this.addRequest();
  }

  submitWorkFromHome(event: any) {
    const { requestData, approverList } = event;
    this.requestForm.remark = requestData.remark;
    this.requestForm.startdate = requestData.startdate;
    this.requestForm.enddate = requestData.enddate;
    this.requestForm.locationid = requestData.locationid;
    this.requestForm.location = requestData.location;
    this.requestForm.lat = requestData.lat;
    this.requestForm.long = requestData.long;
    this.requestForm.duration = requestData.duration;
    this.approverList = approverList;
    this.addRequest();
  }

  submitOffInLieu(event: any) {
    const { requestData, approverList } = event;
    this.requestForm.remark = requestData.remark;
    this.requestForm.startdate = requestData.startdate;
    this.requestForm.starttime = requestData.starttime;
    this.requestForm.endtime = requestData.endtime;
    this.requestForm.date = requestData.date;
    this.approverList = approverList;
    this.addRequest();
  }

  backDateRequest() {
    if (this.lateForm) {
      this.router.navigateByUrl('office/late/backdate');
    } else {
      this.router.navigateByUrl('office/earlyout/backdate');
    }
  }

  clearTravel() {
    this.travelList = []
  }

  openTravelDetail(travel: any) {
    console.log('open travel', travel)
    this.dialog.open(OfficeTravelDetailComponent, {
      data: travel
    });
  }

  getTravelList() {
    const data = {
      requesttype: "BCNDUWO85SCGQW",
      requeststatus: "002",
      startdate: "",
      enddate: "",
      end: "",
      orgid: this.requestForm.orgid
    };

    this.kunyekService.getOfficeRequest(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res)
          this.travelList = res.requestlist
          this.traveleditid = this.traveleditid;
          this.travelList = this.travelList.filter((i: any) =>
            i.requestid == this.traveleditid
          )
          this.travelList.forEach((i) => i.username = this.kunyekusername)
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
      }
    );
  }

}
