import { ChangeDetectorRef, Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EncrDecrServiceService } from 'projects/payroll/src/app/EncrDecrService/encr-decr-service.service';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
// import { KunyekService } from '../shared/services/kunyek.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MessageService } from '../../shared/services/message.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.css']
})
export class CrmStatusFormComponent implements OnInit {
  statusData: any[] = [];
  addtype: boolean = false;
  loading: boolean = false;
  statusType: any = "";
  organizations: any = [];
  serviceFormSubmitted: boolean = false;
  orgid: any = "";

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  private dialogRef: any;
  private dialogData: any;
  checktype: boolean = false;
  permission: any = [];
  statusview: boolean = false;
  statusedit: boolean = false;
  editname: boolean = false;
  editingname: boolean = false;
  desname: any = '';
  indexss: any;
  code: any = '';

  constructor(

    private kunyekService: KunyekService,

    public allinoneService: AllInOneService,

    private messageService: MessageService,

    private changeDetectorRef: ChangeDetectorRef,

    public dialog: MatDialog,

    private injector: Injector,
    private router: Router,

    // @Optional() @Inject(MAT_DIALOG_DATA) public data: any

  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    if (this.dialogRef || this.dialogRef != null) {
      this.checktype = true;
    }

    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
 
    if (this.allinoneService.crmOrgAdmin == 'true') {
      // this.getStatusList();
    } else {
      this.permission = this.allinoneService.getCRMpermission();
      var type = this.permission.map((x: any) => x).indexOf('004');
      if (type == -1) {
        this.router.navigateByUrl('service')
      }

      var type2 = this.permission.map((x: any) => x).indexOf('0041');

      if (type2 == -1) {
        this.statusview = true;
      }
      console.log(this.statusview + "ffff")

      var type1 = this.permission.map((x: any) => x).indexOf('0043');

      if (type1 == -1) {
        this.statusedit = true;
      }
    
  }
    if (this.statusview == true) {
      this.loading = false;
    } else {
      this.getStatusList();
    }

  }

  AddNewStatus() {
    this.addtype = true;
  }

  cancel() {
    this.addtype = false;
  }


  getStatusList() {
    this.loading = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      getall: true,
    }
    this.kunyekService.getStatusType(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {

          this.statusData = res.alllist;
          console.log(this.statusData);


          this.loading = false;
        } else {
          this.loading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  statusLoading: boolean = false;
  indexs: any = '';
  onoffstatus(item: any, check: any) {

    if (this.statusedit == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {

      // ticketcount
      if (item.ticketcount == 0) {

        // alert(check)
        for (let i of this.statusData) {
          if (i.code == item.code) {
            i.serviceon = check;
          }
        }
        this.indexs = item.code;
        this.statusLoading = true;
        var data = {
          code: item.code,
          statuson: check,
          domainid: this.allinoneService.getDomain().domainid,
          orgid: this.orgid
        }
        this.kunyekService.statusOnOff(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.indexs = '';
              for (let i of this.statusData) {
                if (i.code == item.code) {
                  i.serviceon = check;
                }
              }
            } else {
              this.statusLoading = false;
              this.indexs = '';
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            this.indexs = '';
            this.statusLoading = false;

          }
        );
      } else {

      }
    }

  }

  submitService() {
    this.serviceFormSubmitted = true;
    if (this.statusType == '') {
      return
    }
    var data = {
      name: this.statusType,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
    }
    this.kunyekService.createStatusType(data).subscribe(
      (res: any) => {
        console.log(res);

        if (res.returncode == '300') {
          this.statusData.push({ autoid: '', code: '', name: this.statusType });
          this.statusType = "";
          this.addtype = false;

        } else {
          this.addtype = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.addtype = false;
      }
    );
  }

  close() {
    console.log("ok")
    const data = {
      'status': true,
    }
    this.dialogRef.close(data);
  }



  goNameEdit(data: any, index: any) {
    if (this.statusedit == true) {
      this.messageService.openSnackBar('You are not authorised to access.', 'warn');
    } else {
      this.desname = data.name;
      this.code = data.code;
      this.editname = true;
      this.indexss = index.toString();
    }
  }


  updateName() {

  }

  cancelname() {
    this.editname = false;
    this.indexss = '';
  }


  nameFormSummit: boolean = false;

  savename() {
    this.nameFormSummit = true;
    if (this.desname == '') {
      return;
    } else {
      this.editingname = true;

      var data = {
        'domainid': this.allinoneService.getDomain().domainid,
        'orgid': this.orgid,
        'name': this.desname,
        'code': this.code,
      }
      this.kunyekService.createStatusType(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.statusData[this.indexss].name = this.desname;
            this.indexss = '';
            this.editingname = false;

          } else {
            this.editingname = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.statusLoading = false;

        }
      );

    }
  }

}
