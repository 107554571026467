<section>
    <ng-container *ngIf="!isError;else errorPage">
        <div class="c-container m-5" *ngIf="!gettingVR">
            <div class="campaign-name mb-4">{{camdesc}}</div>
            <ul class="list-group list-group-horizontal-md row w-100">
                <li class="list-group-item col-md-6 p-3">
                    <div class="signin mb-3">
                        Sign In
                    </div>
                    <div class="mb-3">
                        <label for="userid" class="form-label">User ID

                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="userid" name="userid" (keyup.enter) = "signIn()"
                                placeholder="john@domain.com, +959********" [(ngModel)]="loginForm.userid" required
                                #userid="ngModel" [ngClass]="{ 'is-invalid': loginFormSubmitted && userid.invalid }">
                        </div>
                        <div role="alert" *ngIf="userid.errors?.required && loginFormSubmitted"
                            style="color: red;padding: 6px;">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="pwd" class="form-label">Password

                        </label>
                        <div class="input-group">
                            <input type="password" class="form-control" id="pwd" name="pwd" placeholder="Enter password" (keyup.enter) = "signIn()"
                                [(ngModel)]="loginForm.password" required #pwd="ngModel"
                                [ngClass]="{ 'is-invalid': loginFormSubmitted && pwd.invalid }">
                        </div>
                        <div role="alert" *ngIf="pwd.errors?.required && loginFormSubmitted"
                            style="color: red;padding: 6px;">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                    <div class="w-100">
                        <app-button-loader (click)="signIn()" [button_text]="'Sign In'" [width]="'100'"
                            [load_button_text]="'Signing In'" [isLoading]="saveLoading"></app-button-loader>
                    </div>
                </li>
                <li class="list-group-item col-md-6 p-3">
                    <div class="qr-container">
                        <div class="center">
                            <div *ngIf="qrLoading && !showRefresh"
                                style="height: 200px;width: 364px; text-align: center;">
                                <div class="spinner-border" style="margin-top: 85px;" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <div *ngIf="!qrLoading && showRefresh"
                                style="height: 200px;width: 364px; text-align: center;" (click)="refresh()">
                                <div style="padding-top: 85px;font-size: 30px; cursor: pointer;" role="status">
                                    <i class="fa fa-refresh"></i>
                                </div>
                            </div>
                            <ngx-qrcode *ngIf="!qrLoading && !showRefresh" qrc-element-type="canvas"
                                [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="value">
                            </ngx-qrcode>
                            <ol class="list">
                                <li>Open {{allinoneService.mobileappname}} in your phone</li>
                                <li>Go to <strong>More > QR Scan</strong></li>
                                <li>Point your phone at this screen to confirm sign in</li>
                            </ol>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div *ngIf="gettingVR">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </ng-container>
    <ng-template #errorPage>
        <div class="c-container m-5 error" *ngIf="!noRoundError">
            <span class="mb-3">Something went wrong!</span>
            <button class="btn btn-secondary" (click)="reload()">Reload</button>
        </div>
        <div class="c-container m-5 error" *ngIf="noRoundError">
            <span class="noround">This campaign does not exist.</span>
        </div>
    </ng-template>
</section>