<div [ngClass]="{'container-fluid':!checktype}">
    <div [ngClass]="{'content':!checktype}">
        <div *ngIf="!checktype">
            <app-crm-breadcrumb s_breadcrumb="Service Status">
            </app-crm-breadcrumb>
        </div>

        <div class="d-flex justify-content-between align-items-center mb-2" *ngIf="checktype">
            <div class="label title" style="font-size:18px;    margin-left: 0.5rem;">Status</div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <!-- <div class="mb-2" style="margin-top: 10px;">
        <button class="btn btn-custom" (click)="AddNewStatus()" *ngIf="!addtype">
            <i class="fa fa-plus me-1"></i>
            New Status
        </button>
    </div> -->
        <perfect-scrollbar *ngIf="checktype" class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
            <div class="table-responsive">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <!--  mat-sort-header="type" -->
                            <td scope="col" class="col-lg-5 col-md-5">Description</td>
                            <td scope="col" class="col-lg-5 col-md-5">Ticket </td>
                            <td scope="col" class="col-lg-1 col-md-1 justify-content-center">Active</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="loading">
                        <tr>
                            <td colspan="3" class="nodata">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!loading">

                        <tr *ngIf="statusData.length == 0">
                            <td colspan="3" class="nodata" *ngIf="!statusview">Empty</td>
                            <td style="color: red;font-size: 14px;" colspan="3" class="nodata" *ngIf="statusview"><i class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 7px;"></i>You are not authorised to view.</td>
                        </tr>
                        <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
                        <tr *ngFor="let item of statusData;let i = index;">

                            <td *ngIf="indexss != i || indexss == ''">
                                <div class="d-flex">
                                    {{item.name}}
                                    <i class="fa fa-pencil statuedit" (click)="goNameEdit(item,i)" aria-hidden="true"></i>
                                </div>
                            </td>
                            <td *ngIf="indexss == i && indexss != ''">
                                <div class="d-flex">
                                    <div>
                                        <input type="text" class="form-control" id="des" name="des" [(ngModel)]="desname" #des="ngModel" [ngClass]="{ 'is-invalid': nameFormSummit && des.invalid }" required>
                                    </div>
                                    <div style="display: flex; align-items: center;" *ngIf="!editingname">
                                        <mat-icon class="close-domain" title="Cancel" (click)="cancelname()">close
                                        </mat-icon>
                                        <mat-icon class="close-domain" *ngIf="desname" title="Save" (click)="savename()">check
                                        </mat-icon>
                                    </div>
                                    <div style="display: flex; align-items: center; margin-left: 10px;" *ngIf="editingname">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </div>
                                </div>
                            </td>
                            <td class="td-data">
                                <span [title]="item.ticketcount">{{item.ticketcount}}</span>
                            </td>
                            <td class="d-flex justify-content-center">
                                <div style="padding: 10px;">
                                    <div class="spinner-borders" role="status" *ngIf="statusLoading  && indexs == item.code ">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <!-- [matTooltip]="item.ticketcount > 0 ? 'ok' : '03'" -->
                                <!-- [matTooltip]="item.ticketcount > 0 ? 'ok' : '03'"  -->

                                <button class="btn btn-primary" (click)="onoffstatus(item ,false)" [disabled]="item.name == 'New'|| item.name == 'Closed' || item.ticketcount > 0" *ngIf="item.serviceon && indexs != item.code">
                                    <i class="fa fa-toggle-on"></i>
                                </button>
                                <button class="btn btn-primary" (click)="onoffstatus(item ,true)" [disabled]="item.name == 'New'|| item.name == 'Closed' " *ngIf="!item.serviceon && indexs != item.code ">
                                    <i class="fa fa-toggle-off"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </perfect-scrollbar>
        <div class="table-responsive" *ngIf="!checktype">
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr>
                        <!--  mat-sort-header="type" -->
                        <td scope="col" class="col-lg-5 col-md-5">Description</td>
                        <td scope="col" class="col-lg-5 col-md-5">Ticket </td>
                        <td scope="col" class="col-lg-1 col-md-1 ">Active</td>
                    </tr>
                </thead>
                <tbody *ngIf="loading">
                    <tr>
                        <td colspan="3" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="!loading">

                    <tr *ngIf="statusData.length == 0">
                        <td colspan="3" class="nodata" *ngIf="!statusview">Empty</td>
                        <td style="color: red;font-size: 14px;" colspan="3" class="nodata" *ngIf="statusview"><i class="fa fa-exclamation-triangle" aria-hidden="true" style="margin-right: 7px;"></i>You are not authorised to view.</td>
                    </tr>
                    <!-- | filter:searchText:17 | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index -->
                    <tr *ngFor="let item of statusData;let i = index;">

                        <!-- <td class="td-data">
                            <span [title]="item.name">{{item.name}}</span>
                        </td> -->
                        <td *ngIf="indexss != i || indexss == ''">
                            <div class="d-flex">
                                <div>{{item.name}}
                                </div>
                                <span class="title span-edit" (click)="goNameEdit(item,i)">
                                    <mat-icon class="iconedit">edit</mat-icon>
                                </span>
                                <!-- <i class="fa fa-pencil statuedit" (click)="goNameEdit(item,i)" aria-hidden="true"></i> -->
                            </div>
                        </td>
                        <td *ngIf="indexss == i && indexss != ''">
                            <div class="d-flex">
                                <div>
                                    <input type="text" class="form-control" id="des" name="des" [(ngModel)]="desname" #des="ngModel" [ngClass]="{ 'is-invalid': nameFormSummit && des.invalid }" (keyup.enter)="savename()" required>
                                </div>
                                <div style="display: flex; align-items: center;" *ngIf="!editingname">
                                    <mat-icon class="close-domain" title="Cancel" (click)="cancelname()">close
                                    </mat-icon>
                                    <mat-icon class="close-domain" *ngIf="desname" title="Save" (click)="savename()">
                                        check
                                    </mat-icon>
                                </div>
                                <div style="display: flex; align-items: center; margin-left: 10px;" *ngIf="editingname">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </div>
                            </div>
                        </td>
                        <td class="td-data">
                            <span [title]="item.ticketcount">{{item.ticketcount}}</span>
                        </td>
                        <td class="d-flex">
                            <div style="padding: 10px;" *ngIf="statusLoading  && indexs == item.code ">
                                <div class="spinner-borders" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            <button class="btn btn-primary" (click)="onoffstatus(item ,false)" [disabled]="item.name == 'New'|| item.name == 'Closed' || item.ticketcount > 0" *ngIf="item.serviceon && indexs != item.code">
                                <i class="fa fa-toggle-on"></i>
                            </button>
                            <button class="btn btn-primary" (click)="onoffstatus(item ,true)" [disabled]="item.name == 'New'|| item.name == 'Closed' " *ngIf="!item.serviceon && indexs != item.code ">
                                <i class="fa fa-toggle-off"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>


<!-- <div class="mb-2 d-flex" *ngIf="addtype" style="margin-top: 10px;">
        <button type="button" class="btn btn-custom me-2" (click)="cancel()">
            Cancel
        </button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" (click)="submitService()">
        </app-button-loader>
    </div>


    <div class="row" *ngIf="addtype">
        <div class="mb-3  col-md-12">
            <label for="name" class="form-label">Status</label>

            <input type="text" class="form-control" id="name" name="name" [(ngModel)]="statusType" placeholder="Enter Status Type" #type="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && type.invalid }" required>

            <div class="invalid-feedback" *ngIf="type.invalid || (type.dirty || type.touched) && serviceFormSubmitted">
                <div *ngIf="type.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
    </div> -->