import { Injectable } from '@angular/core';
import { Observable, fromEvent, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageProcessingService {
  removeMetadata(file: File): Observable<Blob> {
    return new Observable((observer: Observer<Blob>) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx!.drawImage(image, 0, 0);

        canvas.toBlob((blob : any) => {
          observer.next(blob);
          observer.complete();
        }, file.type);
      };
    });
  }
}
