<div class="container-fluid">
  <div class="content" *ngIf="!memberForm">

    <app-team-breadcrumb [s_breadcrumb]="'Jobs'"
      [t_breadcrumb]="jobAddorNot ? isEditing? jobForm.jobname: 'New Job': ''" (emit)="cancel()">
    </app-team-breadcrumb>


    <div class="d-flex justify-content-between header">
      <div class="mb-3">
        <button *ngIf="!jobAddorNot" type="button" class="btn btn-custom" (click)="jobAddorNot = !jobAddorNot">
          <i class="fa fa-plus me-1"></i>New Job
        </button>

        <div *ngIf="jobAddorNot" class="close-save-button">
          <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
          </button>
          <app-button-loader (click)="submitJob()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading"></app-button-loader>
        </div>

      </div>
      <div class="mb-3" *ngIf="!isView && !isMobileView && callType == '300' && !jobAddorNot"
        [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !jobAddorNot && !isMobileView}">
        <label for="project" class="col-form-label"
          [ngClass]="{'pad': !jobAddorNot && !isMobileView && !isView, 'no-pad': !jobAddorNot && !isMobileView && isView}">Project</label>
        <div [ngClass]="{'input-container': !jobAddorNot && !isMobileView}">
          <select class="form-select" aria-label="Select Project" id="project" name="project"
            (change)="projectChanged($event)" [(ngModel)]="jobForm.project" required #project="ngModel"
            [ngClass]="{ 'is-invalid': jobformSubmitted && project.invalid }" [disabled]="gettingProjectList">
            <option selected value=""> {{jobAddorNot ? 'Select Project' : '-'}}</option>
            <option *ngFor="let item of projectList" value="{{item.projectid}}">{{item.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingProjectList"></mat-progress-bar>

          <div class="invalid-feedback"
            *ngIf="project.invalid || (project.dirty || project.touched) && jobformSubmitted">
            <div *ngIf="project.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <form [ngClass]="{'row row-custom': !jobAddorNot && !isMobileView}">
      <div *ngIf="jobAddorNot">
        <div class="mb-3">
          <label for="jobname" class="form-label">Job Name</label>
          <input type="text" class="form-control" id="jobname" name="jobname" placeholder="Enter Job Name"
            [(ngModel)]="jobForm.jobname" required #jobname="ngModel"
            [ngClass]="{ 'is-invalid': jobformSubmitted && jobname.invalid }" (keyup.enter)="submitJob()">

          <div class="invalid-feedback"
            *ngIf="jobname.invalid || (jobname.dirty || jobname.touched) && jobformSubmitted">
            <div *ngIf="jobname.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3" *ngIf="callType == '400'"
        [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !jobAddorNot && !isMobileView}">
        <label for="organization" class="col-form-label"
          [ngClass]="{'no-pad': !jobAddorNot && !isMobileView}">Organization</label>
        <div [ngClass]="{'input-container': !jobAddorNot && !isMobileView}">
          <select class="form-select" aria-label="Select Organization" id="organization" name="organization"
            (change)="orgChanged($event)" [(ngModel)]="jobForm.org" required #org="ngModel"
            [ngClass]="{ 'is-invalid': jobformSubmitted && org.invalid }" [disabled]="gettingOrgList">
            <option selected value="">{{jobAddorNot ? 'Select Organization' : '-'}}</option>
            <option *ngFor="let org of organizations" [value]="org.orgid">{{org.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>


          <div class="invalid-feedback" *ngIf="org.invalid || (org.dirty || org.touched) && jobformSubmitted">
            <div *ngIf="org.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-1"></div> -->

      <div class="mb-3" *ngIf="(((isView || isMobileView) && callType == '300') || (callType == '400') || jobAddorNot)"
        [ngClass]="{'row row-custom col-lg-6 col-md-12 col-sm-12': !jobAddorNot && !isMobileView}">
        <label for="project" class="col-form-label"
          [ngClass]="{'pad': !jobAddorNot && !isMobileView && !isView, 'no-pad': !jobAddorNot && !isMobileView && isView}">Project</label>
        <div [ngClass]="{'input-container': !jobAddorNot && !isMobileView}">
          <select class="form-select" aria-label="Select Project" id="project" name="project"
            (change)="projectChanged($event)" [(ngModel)]="jobForm.project" required #project="ngModel"
            [ngClass]="{ 'is-invalid': jobformSubmitted && project.invalid }" [disabled]="gettingProjectList">
            <option selected value=""> {{jobAddorNot ? 'Select Project' : '-'}}</option>
            <option *ngFor="let item of projectList" value="{{item.projectid}}">{{item.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingProjectList"></mat-progress-bar>

          <div class="invalid-feedback"
            *ngIf="project.invalid || (project.dirty || project.touched) && jobformSubmitted">
            <div *ngIf="project.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>

      <div class="row g-3 mb-3 " *ngIf="jobAddorNot">
        <div class="col-md-12">
          <label for="projdesc" class="form-label">Project Description</label>
          <textarea class="form-control" id="projdesc" name="projdesc" rows="3" [(ngModel)]="selectedProjDesc"
            disabled></textarea>
        </div>
        <div class="col-md-6">
          <label for="startdate" class="form-label">Start Date</label>
          <!-- <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="jobForm.startdate"
            required #startdate="ngModel" max="9999-12-31" [ngClass]="{ 'is-invalid': jobformSubmitted && startdate.invalid }"> -->

          <input type="text" id="startdate" name="startdate" class="form-control datepicker-readonly"
            placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" readonly
            [(ngModel)]="jobForm.startdate" style="min-width: 200px;" required #startdate="ngModel"
            [ngClass]="{ 'is-invalid': jobformSubmitted && startdate.invalid }">

          <div class="invalid-feedback"
            *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) && jobformSubmitted">
            <div *ngIf="startdate.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label for="enddate" class="form-label">End Date</label>
          <!-- <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="jobForm.enddate" required
            #enddate="ngModel" [ngClass]="{ 'is-invalid': jobformSubmitted && enddate.invalid }"
            [min]="jobForm.startdate" max="9999-12-31"> -->

          <input type="text" id="enddate" name="enddate" class="form-control datepicker-readonly"
            placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" readonly
            [(ngModel)]="jobForm.enddate" [minDate]="jobForm.startdate" style="min-width: 200px;" required
            #enddate="ngModel">

          <div class="invalid-feedback"
            *ngIf="enddate.invalid || (enddate.dirty || enddate.touched) && jobformSubmitted">
            <div *ngIf="enddate.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="jobAddorNot">
        <div class="mb-3 col-md-6">
          <label for="status" class="form-label">Status</label>

          <select class="form-select" aria-label="Select Status" id="status" name="status"
            (change)="statusChanged($event)" [(ngModel)]="jobForm.status" required #status="ngModel"
            [ngClass]="{ 'is-invalid': jobformSubmitted && status.invalid }">
            <!-- <option selected value="New"> {{jobAddorNot ? 'Select status' : '-'}}</option> -->
            <option *ngFor="let item of statusList" value="{{item}}">{{item}}</option>
          </select>

          <div class="invalid-feedback" *ngIf="status.invalid || (status.dirty || status.touched) && jobformSubmitted">
            <div *ngIf="status.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>

          <div class="invalid-feedback" *ngIf="status.invalid || (status.dirty || status.touched) && jobformSubmitted">
            <div *ngIf="status.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-6">
          <label for="plannedhours" class="form-label">Planned Hours</label>
          <input type="number" class="form-control" id="plannedhours" name="plannedhours"
            placeholder="Enter planned hours" [(ngModel)]="jobForm.plannedhours" required #plannedhours="ngModel"
            [ngClass]="{ 'is-invalid': jobformSubmitted && plannedhours.invalid }" (keyup.enter)="submitJob()"
            (keypress)="($event.charCode >= 48 && $event.charCode < 58)">

          <div class="invalid-feedback"
            *ngIf="plannedhours.invalid || (plannedhours.dirty || plannedhours.touched) && jobformSubmitted">
            <div *ngIf="plannedhours.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="jobAddorNot">
        <div class="mb-3">
          <label for="memberstatus" class="form-label">Type</label>
          <div class="row">
            <div class="col-md-6">
              <select class="form-select" id="memberstatus" name="memberstatus" [(ngModel)]="jobForm.memberstatus"
                required #ms="ngModel">
                <option *ngFor="let ms of memberStatus" value="{{ms.id}}">
                  {{ms.name}}</option>
              </select>
            </div>
            <div class="d-flex align-items-center select-member col-md-6" (click)="openMember()"
              *ngIf="jobForm.memberstatus == '1'">
              <span>
                Selected Member - {{finalSelectedMember.length}}
              </span>
              <span>
                <mat-icon class="ms-2 member-edit d-flex">edit</mat-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="jobAddorNot">
        <div class="mb-3">
          <label for="locDesc" class="form-label">Description</label>
          <textarea class="form-control" id="locDesc" name="locDesc" rows="5" [(ngModel)]="jobForm.description"
            placeholder="Enter Description for Your Location"></textarea>
        </div>
      </div>
    </form>
    <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center" *ngIf="!jobAddorNot">
      <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Hours/Status" aria-label="Search"
          (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
          [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false" title="Page Size">
          <mat-icon>filter_list</mat-icon>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <div c style="text-align: center;">Page size</div>
          </li>
          <li>
            <hr class="dropdown-divider">
          </li>
          <li class="dropdown-item" *ngFor="let page of pg">
            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
              id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
            <label for="pg{{page.name}}" class="col-10 p-1">
              {{page.name}}
            </label>
          </li>
        </ul>
      </div>
      <div class="ms-2">
        <button class="btn btn-custom" (click)="refresh()" title="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="line"></div>
      <div class="d-flex align-items-center">
        <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{jobListToShow.length}}</span>
      </div>
    </div>
    <div class="input-group mb-2" *ngIf="isMobileView && !jobAddorNot">
      <input class="form-control" type="text" placeholder="&#xf002; Search with Name/Hours/Status" aria-label="Search"
        (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
        [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;">
      <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
        [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
        <mat-icon class="c-icon">close</mat-icon>
      </button>
    </div>
    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-borderless" *ngIf="!jobAddorNot">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="name">Name</td>
            <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="startdate">Start Date</td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="enddate">End Date</td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="plannedhours">Planned Hours</td>

            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="status">Status</td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="desc">Description</td>
            <td scope="col" class="d-flex justify-content-center">Action</td>
          </tr>
        </thead>
        <tbody *ngIf="jobListLoading">
          <tr>
            <td colspan="6" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="jobListLoading == false">
          <tr *ngIf="jobListToShow.length == 0">
            <td colspan="6" class="nodata">Empty</td>
          </tr>
          <tr
            *ngFor="let item of jobListToShow| filter:searchText:type | paginate: { itemsPerPage: itemPerPage, currentPage: p }; let index = index">
            <td class="td-data td-edit" (click)="openEditForm(item)">
              <span [title]="item.name">{{item.name}}</span>
            </td>
            <td class="td-data">
              <span
                [title]="allinoneService.formatDBToShow(item.startdate)">{{allinoneService.formatDBToShow(item.startdate)}}</span>
            </td>
            <td class="td-data">
              <span
                [title]="allinoneService.formatDBToShow(item.enddate)">{{allinoneService.formatDBToShow(item.enddate)}}</span>
            </td>
            <td class="td-data">
              <span [title]="item.plannedhours">{{item.plannedhours}}</span>
            </td>
            <td class="td-data">
              <span [title]="item.status">{{item.status}}</span>
            </td>
            <td class="td-data">
              <span [title]="item.description">{{item.description}}</span>
            </td>
            <td class="d-flex justify-content-center">
              <button class="btn btn-outline-primary" (click)="openEditForm(item)" title="Edit">
                <i class='fa fa-edit'></i>
              </button>
              <!-- <button class="btn btn-outline-danger" (click)="deleteLocation(item.locid)" title="Delete">
                                <i class='fa fa-trash-o'></i>
                            </button> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination-controls class="paginate" (pageChange)="p = $event"
      *ngIf="jobListToShow.length > itemPerPage && !jobAddorNot" previousLabel="Prev" nextLabel="Next">
    </pagination-controls>
  </div>
  <div class="content" *ngIf="memberForm">
    <app-breadcrumb [s_breadcrumb]="'Jobs'" [t_breadcrumb]="isEditing ? jobForm.jobname:  'New Job'"
      [f_breadcrumb]="'Members'" (emit)="cancel()" (emit1)="cancelMember()">
    </app-breadcrumb>
    <div class="close-save-button mb-3">
      <button type="button" class="btn btn-custom me-2" (click)="cancelMember()" *ngIf="!saveLoading">
        Cancel
      </button>
      <app-button-loader (click)="saveMember()" [button_text]="'Save'" [load_button_text]="'Saving'"
        [isLoading]="saveLoading"></app-button-loader>

    </div>
    <div>
      <div class="mb-3">
        <input type="text" placeholder="Select Member" aria-label="Number" matInput class="form-control"
          [matAutocomplete]="auto1" [(ngModel)]="memberSearchText" name="memberselect" id="memberselect"
          [disabled]="gettingMembers" #trigger="matAutocompleteTrigger">
        <mat-progress-bar mode="indeterminate" *ngIf="gettingMembers"></mat-progress-bar>
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngFor="let member of members| filter : memberSearchText:16 "
            (onSelectionChange)="selectMember(member, trigger)">
            <!-- {{member.username}} {{member.userid}} -->
            <!-- {{ member.userid }}, {{member.newuserid}} - {{member.username}} -->
            {{member.userid}}{{ member.newuserid ? ', ' + member.newuserid : '' }}{{member.username ? ' - ' +
            member.username : ''}}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="mb-2 d-flex flex-wrap justify-content-end align-items-center">
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span> <span
            class="total-count-num">{{selectedMember.length}}</span>
        </div>
        <div class="line"></div>
        <div class="input-group" style="width: 300px !important;" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
            (focus)="isFocus1 = true" (blur)="isFocus1 = false" [(ngModel)]="searchText1"
            [ngClass]="{'c-search-input-with-clear' : searchText1 }" style="font-family: FontAwesome,Ubuntu;">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText1" (click)="clear()"
            [ngClass]="{'c-clear' : isFocus1, 'c-clear-not-focus': !isFocus1}">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown ms-2">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div c style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pgMember">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCountMember($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">
                {{page.name}}
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div class="input-group mb-2" *ngIf="isMobileView">
        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
          (focus)="isFocus1 = true" (blur)="isFocus1 = false" [(ngModel)]="searchText1"
          [ngClass]="{'c-search-input-with-clear' : searchText1 }" style="font-family: FontAwesome,Ubuntu;">
        <button class="btn btn-outline-secondary " type="button" *ngIf="searchText1" (click)="clear()"
          [ngClass]="{'c-clear' : isFocus1, 'c-clear-not-focus': !isFocus1}">
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div>
        <div class="table-responsive" matSort (matSortChange)="sortMemberData($event)">
          <table class="table table-responsive table-borderless">
            <thead class="table-header">
              <tr>
                <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="userid">User ID</td>
                <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="username">Name</td>
                <td scope="col" class="d-flex justify-content-center">Action</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="selectedMember.length == 0">
                <td colspan="3" class="nodata">Empty</td>
              </tr>
              <tr
                *ngFor="let member of selectedMember| filter : searchText1:16 | paginate: { itemsPerPage: itemPerPageMember, currentPage: q };let index = index">
                <td>
                  {{member.userid}}
                </td>
                <td>
                  {{member.username}}
                </td>
                <td class="d-flex justify-content-center">
                  <button class="btn btn-outline-danger" (click)="removeMember(index)">
                    <i class='fa fa-trash-o'></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="q = $event"
          *ngIf="selectedMember.length > itemPerPageMember" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
    </div>
  </div>
</div>