import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-unblock-user',
  templateUrl: './unblock-user.component.html',
  styleUrls: ['./unblock-user.component.scss']
})
export class UnblockUserComponent implements OnInit {

  user: any;
  loading : boolean = false

  constructor(
    public kunyekService : KunyekService,
    public messageService : MessageService,
    public allinoneService : AllInOneService,
    private changeDetectorRef : ChangeDetectorRef,
    public dialogRef: MatDialogRef<UnblockUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 
      this.user = data.user
      console.log(this.user)
    }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.dialogRef.disableClose = true
    this.loading = true
    // this.dialogRef.close(true);
    this.changeDetectorRef.detectChanges()
    const data = {
      "blockid" : this.user.blockid
    }
    this.kunyekService.unblockUser(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        var tempName = this.user.username ? this.user.username : this.user.userid
        this.messageService.openSnackBar("Unblocked "+ tempName +" successfully!",  'success')
        this.dialogRef.disableClose = false
        this.loading = false
        this.dialogRef.close(true);
      }
      else{
        this.dialogRef.disableClose = false
        this.loading = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
      this.changeDetectorRef.detectChanges()
    },
      err => {
        this.dialogRef.disableClose = false
        this.loading = false
        this.changeDetectorRef.detectChanges()
      }
    )
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
