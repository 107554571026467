import { Component, HostListener, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { isNull } from 'util';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  campaignAddOrNot: boolean = false;
  camFormSubmitted: boolean = false;
  saveLoading: boolean = false;
  isMobileView: boolean = false;
  isFocus: boolean = false;
  isEditing: boolean = false;
  gettingCampaign: boolean = false;

  organizations: any[] = [];
  campaigns: any[] = [];

  mobileViewWidth: any = 426;
  p: number = 1;
  itemPerPage: number = 100;
  searchText: any = ""
  checked: string = "";
  showDropDown: boolean = false;
  ispublic: boolean = false;
  ismobile: boolean = false;
  domainid: string = "";

  statusList = [
    {
      id: '001',
      value: 'Open',
      name: 'On',
    },
    {
      id: '002',
      value: 'Closed',
      name: 'Off',
    }
  ]

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];



  camForm: any = {
    id: '',
    description: '',
    status: '001',
    summarystatus: '001',
    orgid: '',
    votingaccess: []
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private router: Router
  ) {
    if (!this.allinoneService.isAdminOf('org')) {
      this.router.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.organizations = this.allinoneService.orgs;
    this.camForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.domainid = this.allinoneService.getDomain().domainid;
    this.getCampaigns()
  }

  getCampaigns() {
    this.gettingCampaign = true
    const data = {
      orgid: this.camForm.orgid,
      domainid: this.domainid
    }
    this.kunyekService.getCampaigns(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.campaigns = res.datalist;
        this.pg[3].count = this.campaigns.length;
        this.gettingCampaign = false;
      }
      else {
        this.gettingCampaign = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
    },
      err => {
        this.gettingCampaign = false
      }
    )
  }

  cancel() {
    this.campaignAddOrNot = false;
    this.camFormSubmitted = false;
    this.isEditing = false;
    this.camForm = {
      id: '',
      description: '',
      status: '001',
      summarystatus: '001',
      orgid: this.camForm.orgid,
      votingaccess: [],
    };
  }

  submitCampaign() {
    this.camFormSubmitted = true
    if (this.camForm.description && this.camForm.orgid && this.camForm.status && this.camForm.summarystatus) {
      this.saveCampaign();
    }
  }

  sortData(sort: Sort) {
    const data = this.campaigns;
    if (!sort.active || sort.direction === '') {
      this.campaigns = data;
      return;
    }

    this.campaigns = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        case 'summarystatus':
          return this.allinoneService.compare(a.summarystatus, b.summarystatus, isAsc);
        default:
          return 0;
      }
    });
  }

  saveCampaign() {
    this.saveLoading = true
    var data = {
      domainid: this.domainid,
      orgid: this.camForm.orgid,
      status: this.camForm.status,
      summarystatus: this.camForm.summarystatus,
      description: this.camForm.description,
      votestatus: this.camForm.votingaccess.includes('Public') ? '001' : '002',
      mobilestatus: this.camForm.votingaccess.includes('Mobile') ? '001' : '002'
    }

    if (this.isEditing) {
      data = Object.assign(data, { camid: this.camForm.id });
    }

    var fun = this.isEditing ? this.kunyekService.updateCampaign(data) : this.kunyekService.addCampaign(data)
    fun.subscribe((res: any) => {
      if (res.returncode == "300") {
        this.getCampaigns()
        this.saveLoading = false
        this.cancel();
      }
      else {
        this.saveLoading = false
        this.camFormSubmitted = false
        this.messageService.openSnackBar(res.message, 'warn')
      }
    }, err => {
      this.saveLoading = false
      this.camFormSubmitted = false
    }
    )
  }

  orgChanged(event: any) {
    this.camForm.orgid = event.target.value;
    if (this.camForm.orgid == '') {
      this.campaigns = [];
    } else {
      this.allinoneService.selectedOrg = this.camForm.orgid;
      this.allinoneService.setSelectedOrg(this.camForm.orgid);
      this.getCampaigns()
    }
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  clear() {
    this.searchText = ""
  }

  refresh() {
    this.p = 1
    this.getCampaigns()
  }

  editCampaign(item: any) {
    this.campaignAddOrNot = true
    this.isEditing = true
    this.camForm = {
      id: item.camid,
      description: item.description,
      status: item.status,
      summarystatus: item.summarystatus,
      orgid: this.camForm.orgid,
      votingaccess: [],
    };
    if(item.votestatus == '001'){
      this.camForm.votingaccess.push('Public')
    }
    if(item.mobilestatus == '001'){
      this.camForm.votingaccess.push('Mobile')
    }
  }

  viewRounds(item: any) {
    const camid =encodeURIComponent(item.camid);
    this.router.navigate([`/admin/campaign/${camid}`])
  }

  openQR(item: any) {
    const camid =encodeURIComponent(item.camid);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/voting/${camid}`])
    );

    window.open(url, '_blank');
  }

}
