import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-service-warranty-advancedsearch',
  templateUrl: './service-warranty-advancedsearch.component.html',
  styleUrls: ['./service-warranty-advancedsearch.component.css'],
})
export class ServiceWarrantyAdvancedsearchComponent implements OnInit {

  formatToShow = 'yyyy-MM-dd';
  isDateBetween: boolean = false;
  startdate = ''
  enddate = '';
  today = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();

  constructor(
    private dialogRef: MatDialogRef<ServiceWarrantyAdvancedsearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.startdate = this.data.startdate == '' ? this.today : this.data.startdate,
        this.enddate = this.data.enddate
      this.isDateBetween = this.startdate != '' && this.enddate != '';
    }

  }

  onDismiss() {
    this.dialogRef.close();
  }

  search() {
    if (this.isDateBetween) {
      var sdate = formatDate(this.startdate, this.formatToShow, 'en_US');
      var edate = formatDate(this.enddate, this.formatToShow, 'en_US');
      if (sdate > edate) {
        this.messageService.openSnackBar('Please select a valid Date.', 'warn')
        return;
      }
    }
    this.dialogRef.close({
      startdate: this.startdate,
      enddate: this.enddate
    });
  }

  radioOnChange(value: string) {
    this.isDateBetween = value === 'between';
    this.today = this.today;
    if (this.isDateBetween) {
      this.enddate = this.today;
    } else {
      this.enddate = '';
    }
  }
}
