<section class="text-sm-start">
    <div>
        <div class="center mb-4">
            <div *ngIf="qrLoading && !showRefresh" style="height: 200px;width: 364px;">
                <div class="spinner-border" style="margin-top: 85px;" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div *ngIf="!qrLoading && showRefresh" style="height: 200px;width: 364px;" (click)="getQR()">
                <div style="padding-top: 85px;font-size: 30px; cursor: pointer;" role="status">
                    <!-- <span class="visually-hidden">Loading...</span> -->
                    <!-- <mat-icon>refresh</mat-icon> -->
                    <i class="fa fa-refresh"></i>
                </div>
            </div>
            <ngx-qrcode *ngIf="!qrLoading" qrc-element-type="canvas" [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel" [value]="value"></ngx-qrcode>
        </div>
        <!-- <p>{{ value }}</p> -->
        <div class="center title mb-3">
            Sign in to {{allinoneService.isMemberModule ? 'Member Portal' : allinoneService.appname }} by QR Code
        </div>
        <ol class="list">
            <li>Open {{allinoneService.mobileappname}} in your phone</li>
            <li>Go to <strong>More > QR Scan</strong></li>
            <li>Point your phone at this screen to confirm sign in</li>
        </ol>
        <div class="center mt-5">
            <button type="button" class="btn btn-light logintype" (click)="phLogin()">SIGN IN BY EMAIL OR PHONE
                NUMBER</button>
        </div>
    </div>
</section>