import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { AccessoriesRequestHistoryComponent } from '../accessories-request-history/accessories-request-history.component';
import { AccessoriesFilterComponent } from '../accessories-filter/accessories-filter.component';

@Component({
  selector: 'app-accessories-request',
  templateUrl: './accessories-request.component.html',
  styleUrls: ['./accessories-request.component.scss']
})
export class AccessoriesRequestComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isAdding: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  subscriptions = new SubSink();
  isFocus: boolean = false;

  listLoading: boolean = false;
  searchText: string = '';
  orgid: string = '';
  formSubmitted: boolean = false;

  dataList: any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  tableLoading: boolean = false;

  getListSubscription!: Subscription;

  generalDatePickerConfig = this.allinoneService.datePickerConfig;

  gettingRequestType: boolean = false;

  requestTypeList: any = []

  filterStatus = '001';
  filterStatusName = 'Submit';

  requestDateFilter : any = '';

  imageList: any[] = [];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  statusList = [
    {id: "",name: "All"},
    { id: "001", name: "Submit" },
    {id: "pending", name: "Pending"},
    { id: "004", name: "Approved" },
    { id: "005", name: "Rejected" },
  ]


  tableShowStatus = [
    {id : '001', name : 'Submit'},
    {id : '002', name : 'Pending'},
    {id : '003', name : 'Pending'},
    {id : '004', name : 'Approved'},
    {id : '005', name : 'Rejected'},
  ]

  getTableStatus(d:any) {
    var i = this.tableShowStatus.findIndex((x:any) => x.id == d);
    if(i > -1) {
      return this.tableShowStatus[i].name;
    } else {
      return '-';
    }
  }

  currentDate = new Date(Date.now());
  getRequestTypeSubscription!: Subscription;
  getHRListSubscription!: Subscription;

  isFromOffice: boolean = false;

  filterSD : any = '';
  filterED : any = '';

  submitForm : any = {
    hrrequestid : '',
    requesttype: '001',
    requestdate: this.currentDate,
    description: '',
    status : '',
    end : '',
  }

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.isFromOffice) {
        this.isFromOffice = data.isFromOffice;
      }
    })
    // this.route.data.subscribe((data) => {
    //   if (data.role == 'admin') {
    //     this.isAdmin = true;
    //     if (!this.allinoneService.isAdminOf('hr')) {
    //       this.router.navigateByUrl('/hxm');
    //     }
    //   } else {
    //     this.allinoneService.isOfficeView = true;
    //   }
    // })
  }

  ngOnInit(): void {
    // this.organizations = this.allinoneService.orgsCRm;
    // this.orgid = this.allinoneService.selected_orgcrm
    //   ? this.allinoneService.selected_orgcrm.orgid
    //   : this.organizations[0].orgid;

    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;

    this.getRequestType();
    this.getHRRequestList();
  }

  ngOnDestroy(): void {

  }

  filter() {
    const dialog = this.dialog.open(AccessoriesFilterComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data : {
        isApproval : false,
        status : this.filterStatus,
        startdate : this.filterSD,
        enddate : this.filterED
      }
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if(dialogResult) {
        console.log('your dia result');
        console.log(dialogResult);
        
        this.filterStatus = dialogResult.status;
        this.filterStatusName =  dialogResult.statusname;
        
        this.filterSD = dialogResult.startdate ? dialogResult.startdate : '';
        this.filterED = dialogResult.enddate ? dialogResult.enddate : ''

        this.refresh();
      }
    })
  }


  clearDate() {
    this.filterSD = '';
    this.filterED = '';
    this.refresh();
  }

  getRequestType() {
    this.gettingRequestType = true;
    // this.tableLoading = true;
    var data = {
      orgid: this.orgid,
    }
    this.getRequestTypeSubscription && this.getRequestTypeSubscription.unsubscribe();
    this.getRequestTypeSubscription = this.kunyekService.getAccessoriesRequestType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.requestTypeList = res.list;
          this.submitForm.requesttype = this.requestTypeList[0].id;
          // this.getHRRequestList();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingRequestType = false;
        // this.tableLoading = false;
      },
      (err) => {
        this.gettingRequestType = false;
        // this.tableLoading = false;
      }
    )
  }

  getHRRequestList() {
    this.tableLoading = true;
    var data = {
      orgid: this.orgid,
      status: this.filterStatus,
      end : this.submitForm.end,
      // date : this.requestDateFilter ? this.allinoneService.parseDate(this.requestDateFilter) : '',
      requeststartdate : this.filterSD ? this.allinoneService.parseDate(this.filterSD) : '',
      requestenddate : this.filterED ? this.allinoneService.parseDate(this.filterED) : '',
    }
    this.getHRListSubscription && this.getHRListSubscription.unsubscribe();
    this.getHRListSubscription = this.kunyekService.getAccessoriesRequest(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.dataList = res.list;
          this.submitForm.end = res.end;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.tableLoading = false;
      },
      (err) => {
        this.tableLoading = false;
      }
    )

  }

  submit() {

    this.formSubmitted = true;

    if (!this.submitForm.requesttype) {
      this.messageService.openSnackBar('Invalid Request Type.', 'warn');
      return;
    }

    if (!this.submitForm.requestdate) {
      this.messageService.openSnackBar('Invalid Request Date.', 'warn');
      return;
    }

    this.saveLoading = true;

    var data = {
      hrrequestid : this.isEditing ? this.submitForm.hrrequestid : '',
      orgid: this.orgid,
      typeid: this.submitForm.requesttype,
      requestdate: this.allinoneService.parseDate(this.submitForm.requestdate),
      description: this.submitForm.description
    }
    // console.log(data);

    var fun = this.isEditing
      ? this.kunyekService.updateAccessoriesRequest(data)
      : this.kunyekService.createAccessoriesRequest(data);

    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.isEditing) {
            var index = this.dataList.findIndex(
              (x:any) => x.hrrequestid == data.hrrequestid
            );
            if(index > 1) {
              this.dataList[index].typeid = data.typeid;
              this.dataList[index].requestdate = data.requestdate;
              this.dataList[index].description = data.description;
            } else {
              this.refresh();
            }
          } else {
            var temp = {...data, hrrequestid : res.hrrequestid};
            this.dataList.push(temp);
          }
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
  }

  new() {
    this.isAdding = true;
  }

  searchTextChange() {

  }

  openHistory(data:any) {
    console.log(data);
    const dialog = this.dialog.open(AccessoriesRequestHistoryComponent, {
      maxWidth: '95vw',
      maxHeight: '95vh',
      width: '600px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data : {
        history : data.history,
      }
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {})
  }

  editData(data: any) {
    this.isEditing = true;
    this.isAdding = true;
    this.submitForm = {
      hrrequestid : data.hrrequestid,
      requesttype : data.typeid,
      requestdate : this.allinoneService.parseDateToShowInEdit(data.requestdate),
      description : data.description,
      status : data.status
    }
    for (let i = 0; i < data.filelist.length; i++) {
      this.imageList.push({
        file: '',
        previewurl: data.filelist[i].fileurl,
        filename: data.filelist[i].imagename,
      });
    }
  }

  export() {

  }

  statusChange(status: any) {
    if (this.filterStatus != status.id) {
      this.filterStatus = status.id;
      this.filterStatusName = status.name;
      this.refresh();
    }
  }

  t(data:any) {
    var name = this.requestTypeList.find((x:any) => x.id == data);
    console.log(name.name);

    // var name = this.requestTypeList.filter((x:any) => {
    //   console.log(x.id);
    //   console.log(data);
      
    //   x.id == data
    // });
    // console.log(name);
    
  }

  getRequestTypeName(data:any) {
    var name = this.requestTypeList.find((x:any) => x.id == data);
    return name ? name.name : '';
  }

  sortData(sort: Sort) {
    const data = this.dataList;
    if (!sort.active || sort.direction === '') {
      this.dataList = data;
      return;
    }
    this.dataList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      var art = this.getRequestTypeName(a.typeid);
      var brt = this.getRequestTypeName(b.typeid);
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'requesttype':
          return this.allinoneService.compare(art, brt, isAsc);
        case 'requestdate':
          return this.allinoneService.compare(a.requestdate, b.requestdate, isAsc);
          case 'status':
          return this.allinoneService.compare(a.status, b.status, isAsc);
        case 'description':
          return this.allinoneService.compare(a.description, b.description, isAsc);
        default:
          return 0;
      }
    })
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {

  }

  refresh() {
    this.currentPage = 1;
    this.getHRRequestList();
  }

  cancel() {
    this.isAdding = false;
    this.isEditing = false;
    this.formSubmitted = false;
    this.saveLoading = false;
    this.submitForm = {
      name: '',
      requesttype: this.submitForm.requesttype,
      requestdate: this.currentDate,
      description: '',
      status : '',
    }
    this.imageList = [];
  }

}
