import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { MessageService } from 'src/app/shared/services/message.service';
import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-employeeid-mapping',
  templateUrl: './employeeid-mapping.component.html',
  styleUrls: ['./employeeid-mapping.component.scss']
})
export class EmployeeidMappingComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  isMobileView: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  isFocus: boolean = false;
  searchText: string = '';
  currentPage: number = 1;
  emListLoading: boolean = false;
  isAdmin: boolean = false;
  subscriptions = new SubSink();
  getMemberListSubscription!: Subscription;
  getEmListSubscription! : Subscription;

  memberSelected: boolean = false;
  selectedMemberName: string = '';
  selectedMemberEID: string = '';
  emFormSubmitted: boolean = false;
  historyList: any = [];
  mytimeout: any;
  memberList: any = [];
  gettingMemberList: boolean = false;

  addEm: boolean = false;

  emList: any = [];
  organizations: any = [];
  orgid: string = '';
  pcList: any = [];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  emForm = {
    id : '',
    userid: '',
    attendanceid: '',
  }


  constructor(
    public allinoneService: AllInOneService,
    private dialog: MatDialog,
    private kunyekService: KunyekService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private filterPipe: FilterPipe
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnDestory(): void {
    this.getEmListSubscription && this.getEmListSubscription.unsubscribe();
    this.getMemberListSubscription && this.getMemberListSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getMemberList();
    this.getEm();
  }

  newEm() {
    this.addEm = true;
  }

  submitEm() {
    this.emFormSubmitted = true;
    if(
      this.emForm.userid.toString().trim() == '' ||
      this.emForm.attendanceid.toString().trim() == ''
    ) {
      return;
    }
    if(!this.memberSelected) {
      this.messageService.openSnackBar(
        'Please select user.',
        'warn'
      );
      return;
    }
    const data = {
      id : this.emForm.id,
      orgid : this.orgid,
      attendanceid : this.emForm.attendanceid.toString().trim(),
      edituser : this.emForm.userid.toString().trim(),
    }
    console.log("Your data");
    console.log(data);
    this.saveLoading = true;
    this.kunyekService.addEditDeleteAttendanceid(data).subscribe(
      (res : any) => {
        if(res.returncode === '300') {
          if(this.isEditing) {
            var index = this.emList.findIndex(
              (x: any) => x.userid == this.emForm.userid
            );
            if(index > -1) {
              this.emList[index].attendanceid = data.attendanceid;
            } else {
              this.refresh();
            }
          } else {
            var tempEm = { 
              id: res.id,
              username: this.selectedMemberName,
              employeeid: this.selectedMemberEID,
              userid: this.emForm.userid,
              attendanceid: this.emForm.attendanceid
            };
            this.emList.push(tempEm);
          }
          this.cancel();
        }else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    )
    
  }

  getEm() {
    this.emListLoading = true;
    const data = {
      orgid : this.orgid,
    };
    this.getEmListSubscription && this.getEmListSubscription.unsubscribe();
    this.getEmListSubscription = this.kunyekService.getAttendanceid(data).subscribe(
      (res: any) => {
        console.log(res);
        
        if(res.returncode == '300') {
          this.emList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.emListLoading = false;
        
      },
      (err) => {
        this.emListLoading = false;
      }
    )
  }

  editEm(em: any) {
    this.isEditing = true;
    this.addEm = true;
    this.memberSelected = true;
    this.selectedMemberName = em.username;
    this.selectedMemberEID = em.employeeid;
    this.emForm = {
      id : em.id,
      userid : em.userid,
      attendanceid : em.attendanceid,
    }
  }

  deleteEm(userid: string, index: number, id: string) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult : any) => {
      if(dialogResult) {
        this.emListLoading = true;
        
        const data = {
          id : id,
          orgid : this.orgid,
          attendanceid : "",
          edituser : userid,
        }
        this.kunyekService.addEditDeleteAttendanceid(data).subscribe(
          (res : any) => {
            if(res.returncode == '300') {
              this.emList.splice(index, 1);
              if (
                Math.round(this.emList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.emListLoading = false;
          }, 
          (err) => {
            this.emListLoading = false;
          }
        )
      }
    })
  }

  changeUserid() {
    this.selectedMemberName = '';
    this.selectedMemberEID = '';
    this.memberSelected = false;
    this.historyList = [];
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.memberList = [];
      this.getMemberList();
    }, 500)
  }

  selectMember(member: any) {
    this.selectedMemberName = member.username;
    this.selectedMemberEID = member.employeeid;
    this.memberSelected = true;
    var tempMember = this.pcList.find(
      (x: any) => x.userid == member.userid && x.status == '1'
    )

    if (tempMember) {
      this.emForm.userid = tempMember.userid
    }
    this.historyList = this.pcList.filter(
      (x: any) => x.userid == this.emForm.userid
    );
    this.historyList.sort(function (a: any, b: any) {
      var x = parseInt(a.order);
      var y = parseInt(b.order);
      return y - x;
    })
  }

  getMemberList() {
    this.gettingMemberList = true;
    const data = {
      startcount: 0,
      endcount: 10,
      search: this.emForm.userid,
      orgid: this.orgid,
    };
    this.getMemberListSubscription && this.getMemberListSubscription.unsubscribe();
    this.getMemberListSubscription = this.kunyekService.getOfficeMember(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.memberList = res.list;
          console.log("Your member List");

          console.log(this.memberList);

        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );
  }

  cancel() {
    this.addEm = false;
    this.searchText = '';
    this.isEditing = false;
    this.addEm = false;
    this.memberSelected = false;
    this.emFormSubmitted = false;
    this.selectedMemberName = '';
    this.selectedMemberEID = '';
    this.historyList = [];
    this.emForm = {
      id : '',
      userid: '',
      attendanceid: '',
    }
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  sortData(sort: Sort) {
    const data = this.emList;
    if (!sort.active || sort.direction === '') {
      this.emList = data;
      return;
    }
    this.emList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
        case 'attendanceid':
          return this.allinoneService.compare(a.attendanceid, b.attendanceid, isAsc);
        default:
          return 0;
      }
    })
  }

  clear() {
    this.searchText = '';
  }

  refresh() {
    this.currentPage = 1;
    this.getEm();
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }

  exportSheet() {
    var exdata: any = [];
    var name = 'AttendanceId.xlsx';

    var filteredData = this.filterPipe.transform(
      this.emList,
      this.searchText,
      'attendanceId'
    );

    filteredData.map((item: any) => {
      var temp = {
        'User ID': item.userid,
        'User Name': item.username,
        'Employee ID': item.employeeid,
        'Attendance ID': item.attendanceid,
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

}
