<div style="height: 300px;overflow-y: hidden;">

    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <!-- advansearch -->
        <div class="label title" style="font-size: 17px;padding: 0px 0px 15px 0px;font-weight: 500;">Advanced Search
        </div>
        <div class="col-sm-12">

            <div class="mb-3">
                <div>
                    <label for="proj" class="form-label">Phone</label>

                    <input type="text" aria-label="phone" [(ngModel)]="phone" matInput class="form-control" name="phone" id="phone" autocomplete="off">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="mb-3">
                    <div>
                        <label for="proj" class="form-label">Contact Name</label>

                        <input type="text" aria-label="name" [(ngModel)]="name" matInput class="form-control" name="name" id="name" autocomplete="off">
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-12">
       
                <label for="proj" class="form-label">Product</label>
                <ng-select class="role-select" [items]="products" bindLabel="name" placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="product" (change)="productChange($event)">

                </ng-select>
                <mat-progress-bar mode="indeterminate" *ngIf="getproduct"></mat-progress-bar>
            </div> -->







        </div>














    </perfect-scrollbar>
    <div mat-dialog-actions align="end" style="padding-right: 20px;">
        <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary me-2">
            Cancel
        </button>
        <button type="button" (click)="search()" class="btn btn-custom ">
            Search
        </button>
    </div>
</div>