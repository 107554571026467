import { MessageService } from './../../shared/services/message.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-crm-dashboard',
  templateUrl: './crm-dashboard.component.html',
  styleUrls: ['./crm-dashboard.component.scss']
})
export class CrmDashboardComponent implements OnInit, OnDestroy {


  CrmList = [
    {
      "title": "Sales",
      "card": [
        { "appid": "1", "appname": "Leads", "icon": "assets/images/leads.png", "routname": "sales/leads", "value": "005" },
        { "appid": "2", "appname": "Deals", "icon": "assets/images/deals.png", "routname": "sales/deals", "value": "006" },
        { "appid": "3", "appname": "Pipeline", "icon": "assets/images/pipeline.png", "routname": "sales/pipelines", "value": "007" },
        { "appid": "8", "appname": "Customers", "icon": "assets/images/customers.png", "routname": "sales/customers", "value": "008" },
        { "appid": "8", "appname": "Customer Groups", "icon": "assets/images/customergroup.png", "routname": "sales/customergroup", "value": "00080" },
      ]
    },
    {
      "title": "References",
      "card": [
        { "appid": "6", "appname": "Projects", "icon": this.allinoneService.bucketUrl + "policyicons/projects.png", "routname": "org/projects?q=crm", "value": "009" },
        { "appid": "7", "appname": "Products", "icon": "assets/images/product.png", "routname": "sales/service/products", "value": "0010" },
        { "appid": "9", "appname": "Person in Charge", "icon": this.allinoneService.bucketUrl + "policyicons/members.png", "routname": "sales/service/pic", "value": "0011" },
        { "appid": "10", "appname": "Workflow", "icon": "assets/images/workflow.png", "routname": "sales/service/workflow" },
        { "appid": "10", "appname": "Employee", "icon": "assets/images/employeeteam.png", "routname": "sales/service/detaillist" },
      ]
    },
  ]

  permission: any = []



  customercare: boolean = false;
  customers: any = [];
  loadCustomer: boolean = false;
  customerAry: string = "";
  arrays: any = [];

  private subs = new SubSink();

  // customercarecheck: boolean = false;
  constructor(
    public router: Router,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private ref: ChangeDetectorRef,
    private kunyekService: KunyekService,
    private route: ActivatedRoute,

  ) {
    this.allinoneService.isCRMServiceView = true;
    this.allinoneService.isCustomerCare = false;
  }
  organizations: any = [];
  orgid: any = '';
  orgName: any = '';
  admin: boolean = false;
  orgList: any = []
  checkorg: boolean = false;
  customerdata: any = [];
  customerid: any = "";
  async ngOnInit(): Promise<void> {
    this.allinoneService.activeroute = "/sales";


    // if(window.location.reload()){

    // }
    this.organizations = this.allinoneService.orgsCRm;

    this.customerid = this.route.snapshot.queryParamMap.get('customerid') || "";
    if (this.customerid) {
      if (this.router.url.startsWith('/sales')) {
        this.router.navigateByUrl(`service?customerid=${this.customerid}`)
      }
    }



  }


  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.allinoneService.activeroute = "/adminconsole";

  }
  goRout(app: any, routname: any) {
    console.log(routname)

    // if (this.check == '001' && routname == 'sales/leads') {
    //   this.router.navigateByUrl(`sales/leads`);
    // }
    // else if (this.check == '002' && routname == 'sales/deals') {
    //   this.router.navigateByUrl(`sales/deals`);
    // }
    // else if (this.check == '003' && routname == 'sales/pipelines') {
    //   this.router.navigateByUrl(`sales/pipelines`);

    // }
    // else if (this.check == '004' && routname == 'sales/customers') {
    //   this.router.navigateByUrl(`sales/customers`);
    // }

    // else if (this.check == '005' && routname == 'org/projects?q=crm') {
    //   this.router.navigateByUrl(`org/projects?q=crm`);
    // }
    // else if (this.check == '006' && routname == 'sales/service/products') {
    //   this.router.navigateByUrl(`sales/service/products`);
    // }
    // else if (this.check == '007' && routname == 'sales/service/pic') {
    //   this.router.navigateByUrl(`sales/service/pic`);
    // }
    // else if (routname == 'sales/service/workflow') {
    //   this.router.navigateByUrl(`sales/service/workflow`);
    // }

    if(this.allinoneService.crmBlocked == 'true'){
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    }else{


    if (this.allinoneService.crmOrgAdmin == 'true') {
      this.router.navigateByUrl(`${routname}`);

    } else {
      this.permission = this.allinoneService.getCRMpermission()
      if (this.permission.length > 0) {


        var type = this.permission.map((x: any) => x).indexOf(app.value);
        console.log(type)
        if (type > -1 && routname == 'sales/leads') {
          this.router.navigateByUrl(`sales/leads`);
        }
        else if (type > -1 && routname == 'sales/leads') {
          this.router.navigateByUrl(`sales/leads`);
        }
        else if (type > -1 && routname == 'sales/deals') {
          this.router.navigateByUrl(`sales/deals`);
        }
        else if (type > -1 && routname == 'sales/pipelines') {
          this.router.navigateByUrl(`sales/pipelines`);

        }
        else if (type > -1 && routname == 'sales/customers') {
          this.router.navigateByUrl(`sales/customers`);
        }
        else if (type > -1 && routname == 'sales/customergroup') {
          this.router.navigateByUrl(`sales/customergroup`);
        }

        else if (type > -1 && routname == 'org/projects?q=crm') {
          this.router.navigateByUrl(`org/projects?q=crm`);
        }
        else if (type > -1 && routname == 'sales/service/products') {
          this.router.navigateByUrl(`sales/service/products`);
        }
        else if (type > -1 && routname == 'sales/service/pic') {
          this.router.navigateByUrl(`sales/service/pic`);
        }
        // else if (routname == 'sales/service/workflow') {
        //   this.router.navigateByUrl(`sales/service/workflow`);
        // }

        else {
          // this.messageService.openSnackBar('You do not have the permission required to perform this operation.', 'warn');
          this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');

        }
      }else{
        this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
      }
    }
  }


  }


  // else if (routname == 'sales/service/workflow') {
  //   this.router.navigateByUrl(`sales/service/workflow`);
  // }




}

