import { ActivatedRoute, Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-attendances',
  templateUrl: './attendances.component.html',
  styleUrls: ['./attendances.component.scss'],
})
export class AttendancesComponent implements OnInit {
  eventid: any;
  events: any;
  event: any;
  event_name = '';

  channel: any;
  org: any;
  type: any;
  attendanceList: any = [];
  notAttendList: any = [];
  gettingAttendance: boolean = false;
  attendanceListLoading: boolean = false;
  p: number = 1;
  isFocus: boolean = false;
  searchText: string = '';
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    }
  ];
  gtype: number = 27;
  itemsPerPage: any = 100;

  filterMenuList = [
    {
      name: 'Attend',
      id: '001',
    },
    {
      name: 'Not Attend',
      id: '002',
    },
  ];

  filterType = '001';
  filterTypeName = 'Attend';

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.eventid = this.route.snapshot.queryParamMap.get('event') || '';
    this.org = this.route.snapshot.queryParamMap.get('org') || '';
    this.channel = this.route.snapshot.queryParamMap.get('channel') || '';
    this.type = this.route.snapshot.queryParamMap.get('type') || '';
  }

  changePageCount(event: any) {
    this.itemsPerPage = event.target.value;
    this.p = 1;
  }

  ngOnInit(): void {
    this.getAttendance();
    this.getEventList();
  }

  cancel() {
    this.router.navigateByUrl('/events');
  }

  filterChange(menu: any) {
    this.filterType = menu.id;
    this.filterTypeName = menu.name;
  }

  downloadSheet() {
    var exdata: any = [];
    // var startd = formatDate(new Date(this.allinoneService.formatDBToShowInEdit(this.detailForm.date, '/')), "dd-MM-yyyy", 'en-US').toString()
    // var temp = this.detailForm.type == 'in' ? 'Time In' :  'Time Out';
    if (this.filterType == '001') {
      var name = this.event_name + ' ' + 'Attendance' + '.xlsx';

      this.attendanceList.map((item: any) => {
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
          'Organization Member': item.member ? 'Yes' : 'No'
        };
        exdata.push(temp);
      });

      this.allinoneService.exportEcecl(exdata, name);
    } else {
      var name = this.event_name + ' ' + '(Not Attend)' + '.xlsx';

      this.notAttendList.map((item: any) => {
        var temp = {
          'User ID': item.userid,
          Name: item.username,
          'Employee ID': item.employeeid,
        };
        exdata.push(temp);
      });

      this.allinoneService.exportEcecl(exdata, name);
    }
  }
  getEventList() {
    this.events = [];
    const format = 'dd/MM/yyyy hh:mm aa';
    // this.eventForm.startdatetime = formatDate(new Date(this.eventForm.startdatetime), format,'en-US').toString()
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.org,
      channelid: this.channel,
    };
    this.kunyekService.getEvents(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        res.datalist.map((event: any) => {
          var tempStartDate =
            event.startdate != ''
              ? formatDate(
                  new Date(event.startdate),
                  format,
                  'en-US'
                ).toString()
              : '';
          var tempEndDate =
            event.enddate != ''
              ? formatDate(new Date(event.enddate), format, 'en-US').toString()
              : '';
          this.events.push({
            eventid: event.eventid,
            appid: event.appid,
            createdby: event.createdby,
            createddate: event.createddate,
            description: event.description,
            domainid: event.domainid,
            enddatetime: event.enddate,
            locid: event.locid,
            locname: event.locname,
            name: event.name,
            orgid: event.orgid,
            startdatetime: event.startdate,
            occurrence: event.occurrence,
            startdatetoshow: tempStartDate,
            enddatetoshow: tempEndDate,
          });
        });
        this.event = this.events.filter(
          (x: any) => x.eventid == this.eventid
        )[0];
        this.event_name = this.event.name || '';
      } else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    });
  }

  getAttendance() {
    this.gettingAttendance = true;
    const param = {
      orgid: this.org,
      domainid: this.allinoneService.getDomain().domainid,
      eventid: this.eventid,
    };
    this.kunyekService.getAttendance(param).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.attendanceList = res.datalist;
          this.notAttendList = res.notattended;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingAttendance = false;
      },
      (err) => {
        this.gettingAttendance = false;
      }
    );
  }

  textAsProfile(name: string) {
    const splitval = name
      .split(' ')
      .map((name) => name[0])
      .join('');

    return splitval.length > 2 ? splitval.substr(0, 2) : splitval;
  }
  sortData(sort: Sort) {
    if (this.filterType == '001') {
      const data = this.attendanceList;
      if (!sort.active || sort.direction === '') {
        this.attendanceList = data;
        return;
      }
      this.attendanceList = data.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name':
            return this.allinoneService.compare(a.username, b.username, isAsc);
          case 'userid':
            return this.allinoneService.compare(a.userid, b.userid, isAsc);
          case 'member':
            return this.allinoneService.compare(a.member, b.member, isAsc);
          case 'employeeid':
            return this.allinoneService.compare(
              a.employeeid,
              b.employeeid,
              isAsc
            );
          default:
            return 0;
        }
      });
    } else {
      const data = this.notAttendList;
      if (!sort.active || sort.direction === '') {
        this.notAttendList = data;
        return;
      }
      this.notAttendList = data.sort((a: any, b: any) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name':
            return this.allinoneService.compare(a.username, b.username, isAsc);
          case 'userid':
            return this.allinoneService.compare(a.userid, b.userid, isAsc);
          case 'employeeid':
            return this.allinoneService.compare(
              a.employeeid,
              b.employeeid,
              isAsc
            );
          default:
            return 0;
        }
      });
    }
  }

  goToEventEdit() {
    this.router.navigateByUrl(
      `events?event=${this.eventid}&org=${this.org}&channel=${this.channel}&type=${this.type}`
    );
  }

  clear() {
    this.searchText = '';
  }
}
