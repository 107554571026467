<div class="container-fluid">
  <div class="content">
    <div class="m-4">
      <div class="me-auto">
        <!-- <app-kmember-breadcrumb s_breadcrumb="Summer List"></app-kmember-breadcrumb> -->
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>

      </div>
      <div class="d-flex gap-2 mb-2">
        <button (click)="closeForm()" type="button" class="btn btn-custom">Cancel</button>
        <app-button-loader (click)="onSaved()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="isSaving">
        </app-button-loader>
        <!-- <button type="button" class="btn btn-custom">Submit</button> -->
      </div>
      <div class="card">
        <div class="card-body m-2">
          <form [formGroup]="swimForm" (submit)="onSaved()">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div *ngIf="batchList && batchList.length > 0" class="col-md-6 mb-3">
                    <label for="batch" class="form-label">
                      အပါတ်စဉ်<font color="red">*</font>
                    </label>
                    <select id="batch" class="form-select" formControlName="batch"
                      [ngClass]="{ 'is-invalid': batch?.invalid && saved }">
                      <option value="" disabled>Batch</option>
                      <option *ngFor="let item of batchList" [value]="item.syskey">
                        {{ item.batch_no }} ( {{ allInOneService.formatDBToShow(item.start_date) }} - {{
                        allInOneService.formatDBToShow(item.end_date) }} {{ item.time }} )
                      </option>
                    </select>
                    <div class="invalid-feedback" *ngIf="batch?.invalid || ((batch?.dirty || batch?.touched) && saved)">
                      <div *ngIf="batch?.errors?.required">{{ requiredMessage }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="myanName" class="form-label">အမည်(မြန်မာ)<font color="red">*</font></label>
                    <input type="text" id="myanName" class="form-control" formControlName="myanName"
                      [ngClass]="{ 'is-invalid': myanName?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="myanName?.invalid || ((myanName?.dirty || myanName?.touched) && saved)">
                      <div *ngIf="myanName?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="engName" class="form-label">အမည်(အင်္ဂလိပ်)</label>
                    <input type="text" id="engName" class="form-control" formControlName="engName"
                      [ngClass]="{ 'is-invalid': engName?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="engName?.invalid || ((engName?.dirty || engName?.touched) && saved)">
                      <div *ngIf="engName?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="kscNo" class="form-label">KSC No(အသင်းဝင်ဖြစ်လျှင်)</label>
                    <input type="text" id="kscNo" class="form-control" formControlName="kscNo">
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="dateOfBirth" class="form-label">မွေးသက္ကရာဇ်<font color="red">*</font></label>
                    <my-date-picker [options]="datePickerOptions" class="datepicker" formControlName="dateOfBirth"
                      [ngClass]="{ 'is-invalid': dateOfBirth?.invalid && saved }" placeholder="DD/MM/YYYY">
                    </my-date-picker>
                    <div class="invalid-feedback"
                      *ngIf="dateOfBirth?.invalid || ((dateOfBirth?.dirty || dateOfBirth?.touched) && saved)">
                      <div *ngIf="dateOfBirth?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="height" class="form-label">အရပ်(cm)<font color="red">*</font></label>
                    <input type="text" id="height" class="form-control" formControlName="height"
                      [ngClass]="{ 'is-invalid': height?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="height?.invalid || ((height?.dirty || height?.touched) && saved)">
                      <div *ngIf="height?.errors?.required">{{requiredMessage}}</div>
                      <div *ngIf="height?.errors?.numericString">This field must be a number</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="nrcNo" class="form-label">နိုင်ငံသားမှတ်ပုံတင်အမှတ်<font color="red">*</font></label>
                    <div class="g-2">
                      <div class="row g-2">
                        <div class="col-2">
                          <select class="form-select" id="nrcregioncode" name="nrcregioncode"
                            formControlName="nrcregioncode" required
                            [ngClass]="{ 'is-invalid': saved && nrcregioncode?.invalid }">
                            <option value="">-</option>
                            <option *ngFor="let item of nrcRegionCode" [value]="item">{{item}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="nrctownshipcode" name="nrctownshipcode"
                            formControlName="nrctownshipcode" required
                            [ngClass]="{ 'is-invalid': saved && nrctownshipcode?.invalid }">
                            <option *ngFor="let item of nrcTownshipCode" [value]="item == '-' ? '' : item">{{item}}
                            </option>
                          </select>
                        </div>
                        <div class="col-2">
                          <select class="form-select" id="nrctype" name="nrctype" formControlName="nrctype" required
                            [ngClass]="{ 'is-invalid': saved && nrctype?.invalid }">
                            <option *ngFor="let item of nrcType" [value]="item == '-' ? '' : item">
                              {{item}}</option>
                          </select>
                        </div>
                        <div class="col-4">
                          <input type="text" class="form-control" id="nrcnumber" name="nrcnumber"
                            formControlName="nrcnumber" minlength="6" maxlength="6" required
                            (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                            [ngClass]="{ 'is-invalid': saved && nrcnumber?.invalid }" />
                        </div>
                      </div>

                      <div class="invalid"
                        *ngIf="nrcregioncode?.invalid || nrctownshipcode?.invalid || nrctype?.invalid || nrcnumber?.invalid">
                        <div *ngIf="(nrcregioncode?.errors?.required || nrctownshipcode?.errors?.required ||
                        nrctype?.errors?.required || nrcnumber?.errors?.required) && saved">{{
                          requiredMessage }}</div>
                      </div>


                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="race" class="form-label">လူမျိုး</label>
                    <input type="text" id="race" class="form-control" formControlName="race"
                      [ngClass]="{ 'is-invalid': race?.invalid && saved}">
                    <div class="invalid-feedback" *ngIf="race?.invalid || ((race?.dirty || race?.touched) && saved)">
                      <div *ngIf="race?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="religion" class="form-label">ကိုးကွယ်သည့်ဘာသာ</label>
                    <input type="text" id="religion" class="form-control" formControlName="religion"
                      [ngClass]="{ 'is-invalid': religion?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="religion?.invalid || ((religion?.dirty || religion?.touched) && saved)">
                      <div *ngIf="religion?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="education" class="form-label">ပညာအရည်အချင်း</label>
                    <input type="text" id="education" class="form-control" formControlName="education"
                      [ngClass]="{ 'is-invalid': education?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="education?.invalid || ((education?.dirty || education?.touched) && saved)">
                      <div *ngIf="education?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="occupation" class="form-label">အလုပ်အကိုင်</label>
                    <input type="text" id="occupation" class="form-control" formControlName="occupation"
                      [ngClass]="{ 'is-invalid': occupation?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="occupation?.invalid || ((occupation?.dirty || occupation?.touched) && saved)">
                      <div *ngIf="occupation?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="fatherName" class="form-label">အဖအမည်<font color="red">*</font></label>
                    <input type="text" id="fatherName" class="form-control" formControlName="fatherName"
                      [ngClass]="{ 'is-invalid': fatherName?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="fatherName?.invalid || ((fatherName?.dirty || fatherName?.touched) && saved)">
                      <div *ngIf="fatherName?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="motherName" class="form-label">အမိအမည်<font color="red">*</font></label>
                    <input type="text" id="motherName" class="form-control" formControlName="motherName"
                      [ngClass]="{ 'is-invalid': motherName?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="motherName?.invalid || ((motherName?.dirty || motherName?.touched) && saved)">
                      <div *ngIf="motherName?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="contactNo" class="form-label">ဆက်သွယ်ရန်ဖုန်းနံပါတ်<font color="red">*</font></label>
                    <input type="tel" id="contactNo" class="form-control" formControlName="contactNo"
                      [ngClass]="{ 'is-invalid': contactNo?.invalid && saved}">
                    <div class="invalid-feedback"
                      *ngIf="contactNo?.invalid || ((contactNo?.dirty || contactNo?.touched) && saved)">
                      <div *ngIf="contactNo?.errors?.required">{{requiredMessage}}</div>
                      <div *ngIf="contactNo?.errors?.numericString">This field must be a number</div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="region" class="form-label">ပြည်နယ်/တိုင်းဒေသကြီး</label>
                    <div class="g-2">
                      <div class="row g-2">
                        <div class="col-4">
                          <select class="form-select" id="region" name="region" formControlName="region"
                            (change)="onRegionChange($event)">
                            <option value="" selected disabled>ပြည်နယ်/တိုင်းဒေသကြီး ရွေးပါ</option>
                            <option *ngFor="let regionlist of regionList" [value]="regionlist.region">
                              {{regionlist?.region}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="town" name="town" formControlName="town"
                            (change)="onTownChange($event)">
                            <option value="" selected disabled>မြို့ ရွေးပါ</option>
                            <option *ngFor="let townlist of filteredTowns" [value]="townlist.town">{{townlist?.town}}
                            </option>
                          </select>
                        </div>
                        <div class="col-4">
                          <select class="form-select" id="ward" name="ward" formControlName="ward">
                            <option value="" selected disabled>ရပ်ကွက်/ရွာ ရွေးပါ</option>
                            <option *ngFor="let wardlist of filteredWards" [value]="wardlist.postcode">{{wardlist?.ward}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-3">
                    <label for="address" class="form-label">နေရပ်လိပ်စာ</label>
                    <textarea type="text" id="address" class="form-control" formControlName="address"
                      [ngClass]="{ 'is-invalid': address?.invalid && saved}"></textarea>
                    <div class="invalid-feedback"
                      *ngIf="address?.invalid || ((address?.dirty || address?.touched) && saved)">
                      <div *ngIf="address?.errors?.required">{{requiredMessage}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-2 col-md-4 text-center mt-2">
                <div class="profile-img-container cursor-pointer">
                  <div (click)="onProfileClick()" class="uploadfilecontainer">
                    <img [src]="profileImageSrc" alt="Profile Image" class="drag-image">
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>