import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { OfficeChooseMembersComponent } from '../office-choose-members/office-choose-members.component';
import { OfficeLeaveSummaryComponent } from '../office-leave-summary/office-leave-summary.component';
import { LateEarlyoutRequestComponent } from '../late-earlyout-request/late-earlyout-request.component';
import { WorkFromHomeRequestComponent } from '../work-from-home-request/work-from-home-request.component';
import { OffInLieuRequestComponent } from '../off-in-lieu-request/off-in-lieu-request.component';

@Component({
  selector: 'app-office-request',
  templateUrl: './office-request.component.html',
  styleUrls: ['./office-request.component.scss'],
})
export class OfficeRequestComponent implements OnInit {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;
  @ViewChild('lateEarlyOut') lateEarlyOut!: LateEarlyoutRequestComponent;
  @ViewChild('workFromHome') workFromHome!: WorkFromHomeRequestComponent;
  @ViewChild('offInLieu') offInLieu!: OffInLieuRequestComponent;
  @Input() orgid: any;
  @Input() memberList: any = [];

  @Output('cancel')
  cancel: EventEmitter<any> = new EventEmitter<any>();

  timeFormat = 'hh:mm';

  mytimeout: any;

  gettingTemplate: boolean = false;
  formSubmitted: boolean = false;
  gettingMemberList: boolean = false;
  memberSelected: boolean = false;
  gettingCurrency: boolean = false;
  saveLoading: boolean = false;
  gettingDuration: boolean = false;
  gettingAvailableRoomAndDesk: boolean = false;
  gettingWorkstation: boolean = false;

  searchText: string = '';
  memberSearch: string = '';
  selectedMemberName: string = '';
  selectedMemberEID: string = '';

  duration_subscription!: Subscription;

  gettingPickUp: boolean = false;

  pickupList: any[] = [];

  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );
  currentTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString();
  currentEndTime = formatDate(
    new Date().setHours(new Date().getHours() + 1),
    this.timeFormat,
    'en-US'
  ).toString();

  organizations: any[] = [];
  imageList: any[] = [];
  requestTypeList: any[] = [];
  requestSubTypeList: any[] = [];
  currencyList: any[] = [];
  approverList: any[] = [];
  availableRoom: any[] = [];
  availableDesk: any[] = [];
  workstationList: any[] = [];

  projectList: any = [];
  productListdata: any = [];
  getProductLoading: boolean = false;
  gettingProjectList: boolean = false;

  // HR
  gettingHRRequestType: boolean = false;
  HRRequestTypeList: any = [];

  timeList: string[] = ['AM', 'PM'];

  accompanyPersonList: any[] = [];

  travelrequestid: string = 'BCNDUWO85SCGQW';

  modeoftravel = [
    { key: '001', value: 'By Air' },
    { key: '002', value: 'By Road' },
    { key: '003', value: 'By Rail' },
    { key: '004', value: 'By Ship' },
  ];

  usevehicle = [
    { key: '001', value: "Office's Vehicle" },
    { key: '002', value: 'Public Transportation' },
    { key: '003', value: 'By Own Vehicle(state reason)' },
  ];

  weekDay = [
    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];

  requestForm: any = {
    userid: '',
    orgid: '',
    requesttype: '',
    subtype: '',
    startdate: this.currentDate,
    enddate: this.currentDate,
    starttimeampm: 'AM',
    endtimeampm: 'PM',
    starttime: this.currentTime,
    endtime: this.currentEndTime,
    amount: '0',
    weekday: [],
    currency: '',
    duration: '1',
    trantype: '001',
    isgoing: false,
    isreturn: false,
    toplace: '',
    dailyfrom: '',
    dailyto: '',
    arrivetime: this.currentTime,
    returntime: this.currentTime,
    travelduration: '1',
    overtimehour: '01:00(1.0hrs)',
    remark: '',
    modeoftravel: [],
    vehicleuse: [],
    reservationtype: '001',
    roomname: '',
    roomid: '',
    maxpeople: '',
    workstationid: '',
    floorname: '',
    deskid: '',
    claimfromplace: '',
    claimtoplace: '',
    taxifare: false,
    pickuppoint: '',
    product: null,
    project: null,
    productname: '',
    projectname: '',
    // HR
    hrrequesttype: '',
    hrrequestdate: this.currentDate
  };

  reservationid: string = 'AVASDAFNMVHSJESAM';
  transportationid: string = 'ANVAHSEFAMVAJDAJ';
  claimid: string = 'WOEIEPPLMXNW';
  lateid: string = 'KDIEVNEIADEOHDWJ';
  earlyoutid: string = 'IUEBCHDUWKNSGP';
  workfromhomeid: string = 'EUOMXJSOQNCUWI';
  offinlieuid: string = 'OMCHDFYUEJBAWCQ';
  cashadvancedid: string = 'QINDJSUWOMXSA';
  hrid: string = 'OMCHDFAASJBAQQ';
  overtimeid: string = "UEYVNDLOAPQWXQW"

  claimRoleView: boolean = false;

  subscriptions = new SubSink();
  member_subscription!: Subscription;
  template_subscription!: Subscription;
  reservation_subscription!: Subscription;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.requestForm.orgid = this.orgid;
    this.checkClaimRoleView();
    this.getCurrency();
    this.getWorkstation();
    this.getPickupPoint();
    this.getProduct();
    this.getAllProjects();
    this.getHRRequestType();
    if (this.memberList.length == 0) {
      this.getMemberList();
    }
  }

  t() {
    console.log(this.requestForm);
  }

  getHRRequestType() {
    this.gettingHRRequestType = true;
    // this.tableLoading = true;
    var data = {
      orgid: this.orgid,
    }
    this.kunyekService.getAccessoriesRequestType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.HRRequestTypeList = res.list;
          this.requestForm.hrrequesttype = this.HRRequestTypeList[0].id;
          // this.getHRRequestList();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingHRRequestType = false;
        // this.tableLoading = false;
      },
      (err) => {
        this.gettingHRRequestType = false;
        // this.tableLoading = false;
      }
    )
  }

  checkClaimRoleView() {
    var PList = this.allinoneService.officePermissionList;
    PList.map((x: any) => {
      // if(x.permission[0] == '011') {
      //   this.claimRoleView = true;
      // }
      var claim = x.permission.find((y: any) => y == '011');
      if (claim) {
        this.claimRoleView = true;
      }
    })
  }

  addWeekDay(event: any, value: any) {
    if (event.target.checked == true) {
      this.requestForm.weekday.push(value);
    } else {
      if (this.requestForm.weekday != 0) {
        for (var i = 0; i < this.requestForm.weekday.length; i++) {
          if (this.requestForm.weekday[i] == value) {
            this.requestForm.weekday.splice(i, 1);
          }
        }
      }
    }
  }

  getProduct() {
    this.getProductLoading = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.requestForm.orgid,
      "customerid": '',
      "customerview": false
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productListdata = res.list;
          var type = this.productListdata.map((x: any) => x.productid).indexOf(this.requestForm.product);
        } else {
          this.getProductLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getProductLoading = false;
      }
    );
  }

  getAllProjects() {
    this.gettingProjectList = true;
    this.projectList = [];
    const data = {
      orgid: this.requestForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
      customerid: "",
      customerview: false
      // role: this.callType
    };
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var project = [];
          console.log(this.projectList);
          var userid = this.allinoneService.getKunyekUserId();
          console.log(typeof (userid));
          this.projectList = res.data

        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;
      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  productChange() {
    if (this.requestForm.project) {
      this.requestForm.project = null;
    }
  }

  projectChange() {
    if (this.requestForm.product) {
      this.requestForm.product = null;
    }
  }

  getTranType(id: any) {
    var temp = this.requestSubTypeList.find((x: any) => x.requesttypeid == id);
    if (temp) {
      this.requestForm.trantype = temp.trantype;
      this.requestForm.reservationtype = temp.reservationtype;
      this.requestForm.taxifare = temp.taxifare;
    }
    if (this.requestForm.requesttype == this.reservationid) {
      if (this.requestForm.reservationtype == '001') {
        this.getAvailableRoomAndDesk();
      } else {
        if (this.requestForm.workstationid) {
          this.getAvailableRoomAndDesk();
        }
      }
    }
  }

  getCurrency() {
    this.gettingCurrency = true;
    this.subscriptions.sink = this.kunyekService.getCurrency().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.currencyList = res.currencylist;
          if (this.currencyList.length > 0) {
            this.requestForm.currency = this.currencyList[0].currencyid;
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingCurrency = false;
      },
      (err) => {
        this.gettingCurrency = false;
      }
    );
  }

  changeUserid() {
    this.selectedMemberName = '';
    this.selectedMemberEID = '';
    this.memberSelected = false;
    this.requestTypeList = [];
    this.clear();
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.memberList = [];
      this.getMemberList();
    }, 500);
  }

  getPickupPoint() {
    this.gettingPickUp = true;
    this.pickupList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.requestForm.orgid,
      type: '001',
    };
    this.kunyekService.getPickup(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          res.datalist.map((item: any) => {
            this.pickupList.push({
              name: item.name,
              user: '',
              userid: item.userid,
              requestdata: item.requestdata,
              usercounts: item.usercounts,
              pickuppointid: item.pickuppointid,
            });
          });

          this.gettingPickUp = false;
        } else {
          this.gettingPickUp = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingPickUp = false;
      }
    );
  }

  clear() {
    this.requestForm = {
      userid: this.requestForm.userid,
      orgid: this.requestForm.orgid,
      requesttype: '',
      subtype: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttimeampm: 'AM',
      endtimeampm: 'PM',
      trantype: '',
      isgoing: false,
      isreturn: false,
      toplace: '',
      starttime: this.currentTime,
      endtime: this.currentEndTime,
      weekday: [],
      arrivetime: this.currentTime,
      returntime: this.currentTime,
      amount: '0',
      currency: this.requestForm.currency,
      duration: '1',
      travelduration: '1',
      overtimehour: '01:00(1.0hrs)',
      remark: '',
      pickuppoint: '',
      modeoftravel: [],
      vehicleuse: [],
      // HR
      hrrequesttype: this.requestForm.hrrequesttype,
      hrrequestdate: this.currentDate
    };
    this.imageList = [];
    this.approverList = [];
  }

  selectMember(member: any) {
    this.selectedMemberName = member.username;
    this.selectedMemberEID = member.employeeid;
    this.requestForm.userid = member.userid;
    this.memberSelected = true;
    this.getTemplate();
  }

  getTemplate() {
    this.gettingTemplate = true;
    const data = {
      orgid: this.requestForm.orgid,
      memberid: this.requestForm.userid,
    };
    this.template_subscription && this.template_subscription.unsubscribe();
    this.template_subscription = this.kunyekService
      .getAvailableTemplateByUser(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (this.claimRoleView) {
              res.requesttypelist.map((x: any) => {
                if (x.requesttypeid == this.claimid) {
                  this.requestTypeList.push(x);
                }
              })
            } else {
              this.requestTypeList = res.requesttypelist;
            }

            if (this.requestTypeList.length > 0) {
              this.requestForm.requesttype =
                this.requestTypeList[0].requesttypeid;
              this.requestSubTypeList = this.requestTypeList[0].child;
              if (this.requestSubTypeList.length > 0) {
                this.requestForm.subtype =
                  this.requestSubTypeList[0].requesttypeid;
              }
            }

          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingTemplate = false;
        },
        (err) => {
          this.gettingTemplate = false;
        }
      );
  }

  requestTypeChange() {
    var temp = this.requestTypeList.find(
      (x: any) => x.requesttypeid == this.requestForm.requesttype
    );
    this.requestSubTypeList = temp ? temp.child : [];
    this.requestForm.subtype =
      this.requestSubTypeList.length > 0
        ? this.requestSubTypeList[0].requesttypeid
        : '';
    this.getTranType(this.requestForm.subtype);
  }

  getMemberList() {
    this.gettingMemberList = true;
    const data = {
      startcount: 0,
      endcount: 10,
      search: this.requestForm.userid,
      orgid: this.requestForm.orgid,
    };
    this.member_subscription && this.member_subscription.unsubscribe();
    this.member_subscription = this.kunyekService
      .getOfficeMember(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.memberList = res.list;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingMemberList = false;
        },
        (err) => {
          this.gettingMemberList = false;
        }
      );
  }

  calculateDuration(sec: boolean = false) {
    if (this.requestForm.startdate > this.requestForm.enddate) {
      this.requestForm.enddate = this.requestForm.startdate;
    }
    if (this.requestForm.startdate == this.requestForm.enddate) {
      this.requestForm.travelduration = '1';
      if (
        this.requestForm.starttimeampm == 'PM' &&
        this.requestForm.endtimeampm == 'AM'
      ) {
        if (sec) {
          this.requestForm.starttimeampm = 'AM';
        } else {
          this.requestForm.endtimeampm = 'PM';
        }
      }
      if (this.requestForm.starttimeampm == this.requestForm.endtimeampm) {
        this.requestForm.duration = '0.5';
      } else {
        this.requestForm.duration = '1';
      }
      if (this.requestForm.starttime > this.requestForm.endtime) {
        if (sec) {
          this.requestForm.starttime = this.requestForm.endtime;
        } else {
          this.requestForm.endtime = this.requestForm.starttime;
        }
      }
      var a = new Date(
        this.requestForm.startdate + ' ' + this.requestForm.starttime
      );
      var b = new Date(
        this.requestForm.enddate + ' ' + this.requestForm.endtime
      );
      var hrDif = this.allinoneService.dateDifference(a, b, true);
      var hours = Math.floor(hrDif / 60);
      var mins = hrDif % 60;
      var hrmin = (hrDif / 60).toFixed(2);
      this.requestForm.overtimehour =
        '' +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (mins < 10 ? '0' + mins : mins) +
        '(' +
        hrmin +
        'hrs)';
    } else {
      var start = new Date(this.requestForm.startdate);
      var end = new Date(this.requestForm.enddate);
      var dif = this.allinoneService.dateDifference(start, end);
      this.requestForm.travelduration = (dif + 1).toString();
      if (this.requestForm.starttimeampm == this.requestForm.endtimeampm) {
        this.requestForm.duration = (dif + 0.5).toString();
      } else {
        if (
          this.requestForm.starttimeampm == 'AM' &&
          this.requestForm.endtimeampm == 'PM'
        ) {
          this.requestForm.duration = (dif + 1).toString();
        } else {
          this.requestForm.duration = dif.toString();
        }
      }
      var a = new Date(
        this.requestForm.startdate + ' ' + this.requestForm.starttime
      );
      var b = new Date(
        this.requestForm.enddate + ' ' + this.requestForm.endtime
      );
      var hrDif = this.allinoneService.dateDifference(a, b, true);
      var hours = Math.floor(hrDif / 60);
      var mins = hrDif % 60;
      var hrmin = (hrDif / 60).toFixed(2);
      this.requestForm.overtimehour =
        '' +
        (hours < 10 ? '0' + hours : hours) +
        ':' +
        (mins < 10 ? '0' + mins : mins) +
        '(' +
        hrmin +
        'hrs)';
    }
    if (this.requestForm.requesttype == 'NCMLEIWHRNVIE') {
      this.gettingDuration = true;
      const data = {
        requesttype: 'NCMLEIWHRNVIE',
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtimeampm,
        duration: this.requestForm.duration,
        orgid: this.requestForm.orgid,
      };
      this.duration_subscription && this.duration_subscription.unsubscribe();
      this.duration_subscription = this.kunyekService
        .getLeaveCounting(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.requestForm.duration = res.duration;
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || 'Server Error',
                'warn'
              );
            }
            this.gettingDuration = false;
          },
          (err) => {
            this.gettingDuration = false;
          }
        );
    }
  }

  onFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.allinoneService.getFileName(file),
            };
            this.imageList.push(temp);
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }
  removeImage(index: number) {
    this.imageList.splice(index, 1);
  }

  chooseAccompanyPerson() {
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.accompanyPersonList,
        isLeaveOpening: false,
        orgid: this.requestForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const tempList = dialogResult.data.filter(
          (data: { checked: boolean; userid: string }) => {
            return (
              data.checked == true && data.userid != this.requestForm.userid
            );
          }
        );
        const oldList = JSON.parse(JSON.stringify(this.accompanyPersonList));
        this.accompanyPersonList = [];
        tempList.map((x: any) => {
          const temp = oldList.find(
            (user: any) => user.userid == x.userid
          );
          this.accompanyPersonList.push({
            userid: x.userid,
            employeeid: x.employeeid,
            username: x.username,
            from: temp ? temp.from : '',
            to: temp ? temp.to : '',
            checked: true,
          });
        });
      }
    });
  }

  chooseUser() {
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.approverList,
        isLeaveOpening: false,
        orgid: this.requestForm.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const tempApprovalList = dialogResult.data.filter(
          (data: { checked: boolean; userid: string }) => {
            return (
              data.checked == true && data.userid != this.requestForm.userid
            );
          }
        );
        const oldApproverList = JSON.parse(JSON.stringify(this.approverList));
        this.approverList = [];
        tempApprovalList.map((x: any) => {
          const temp = oldApproverList.find(
            (user: any) => user.userid == x.userid
          );
          this.approverList.push({
            userid: x.userid,
            employeeid: x.employeeid,
            username: x.username,
            from: temp ? temp.from : '',
            to: temp ? temp.to : '',
            checked: true,
          });
        });
        console.log(this.approverList);
      }
    });
  }

  cancelRequest() {
    this.cancel.emit();
  }

  submitRequest() {
    if (
      this.requestForm.requesttype == this.lateid ||
      this.requestForm.requesttype == this.earlyoutid
    ) {
      this.lateEarlyOut.submitRequest();
    } else if (this.requestForm.requesttype == this.workfromhomeid) {
      this.workFromHome.submitData();
    } else if (this.requestForm.requesttype == this.offinlieuid) {
      this.offInLieu.submitData();
    } else {
      if (
        this.requestForm.requesttype == this.transportationid &&
        this.requestForm.trantype == '001' &&
        (this.requestForm.remark.toString().trim() == '' ||
          this.requestForm.remark.toString().trim() == null)
      ) {
        this.messageService.openSnackBar('Remark cannot be blank.', 'warn');
        return;
      }

      if (
        (this.requestForm.requesttype == 'NCMLEIWHRNVIE' ||
          this.requestForm.requesttype == this.claimid) &&
        this.requestForm.subtype == ''
      ) {
        return this.messageService.openSnackBar(
          'Please choose ' +
          (this.requestForm.requesttype == 'NCMLEIWHRNVIE'
            ? 'leave'
            : 'claim') +
          ' type.',
          'warn'
        );
      }

      if (
        (this.requestForm.requesttype == this.claimid || this.requestForm.requesttype == this.cashadvancedid) &&
        (this.requestForm.amount == '0' ||
          this.requestForm.amount == null ||
          this.requestForm.amount < 1)
      ) {
        return this.messageService.openSnackBar(
          'Please fill a valid amount.',
          'warn'
        );
      }
      if (
        this.requestForm.requesttype == 'UEYVNDLOAPQWXQW' &&
        this.requestForm.overtimehour == '00:00(0.00hrs)'
      ) {
        return this.messageService.openSnackBar(
          'Please choose valid date and time.',
          'warn'
        );
      }
      if (this.requestForm.requesttype == this.travelrequestid) {
        if (!this.requestForm.product && !this.requestForm.project && this.requestForm.requesttype == this.travelrequestid) {
          this.messageService.openSnackBar('Please select Product or Project.', 'warn');
          return;
        }

        if (
          this.requestForm.requesttype == this.travelrequestid &&
          (this.requestForm.amount == '0' ||
            this.requestForm.amount == null ||
            this.requestForm.amount < 1)
        ) {
          return this.messageService.openSnackBar(
            'Please fill a valid amount.',
            'warn'
          );
        }

        if (this.requestForm.modeoftravel.length == 0) {
          return this.messageService.openSnackBar(
            'Please choose travel mode.',
            'warn'
          );
        }
        if (this.requestForm.vehicleuse.length == 0) {
          return this.messageService.openSnackBar(
            'Please choose vehicle.',
            'warn'
          );
        }

        if (
          this.requestForm.requesttype == this.travelrequestid &&
          (this.requestForm.remark.toString().trim() == '' ||
            this.requestForm.remark.toString().trim() == null)
        ) {
          this.messageService.openSnackBar('Please fill Traval Purpose.', 'warn');
          return;
        }
      }
      if (this.requestForm.requesttype == this.transportationid) {
        if (this.requestForm.trantype == '002') {
          if (this.requestForm.toplace == '') {
            this.messageService.openSnackBar(
              'To Place cannot be blank.',
              'warn'
            );
            return;
          }
          if (
            this.requestForm.dailyfrom == '' ||
            this.requestForm.dailyto == ''
          ) {
            this.messageService.openSnackBar(
              'Pick Up Place and Drop Off Place cannot be blank.',
              'warn'
            );
            return;
          }

          var check = this.approverList.filter(
            (x: any) => x.checked && (x.from == '' || x.to == '')
          );
          if (check.length > 0) {
            this.messageService.openSnackBar(
              'Pick Up Place and Drop Off Place for each member cannot be blank.',
              'warn'
            );
            return;
          }
        }
      }

      if (this.requestForm.requesttype == this.reservationid) {
        if (this.requestForm.reservationtype == '001') {
          if (this.requestForm.roomid == '') {
            this.messageService.openSnackBar('Please select a room.', 'warn');
            return;
          }
          if (this.requestForm.starttime >= this.requestForm.endtime) {
            this.messageService.openSnackBar('Invalid Time.', 'warn');
            return;
          }
        }
        if (this.requestForm.reservationtype == '002') {
          var startdate = new Date(
            this.requestForm.startdate + ' ' + this.requestForm.starttime
          );
          var enddate = new Date(
            this.requestForm.enddate + ' ' + this.requestForm.endtime
          );
          if (
            this.requestForm.workstationid == '' ||
            this.requestForm.deskid == ''
          ) {
            this.messageService.openSnackBar(
              'Please select floor and desk.',
              'warn'
            );
            return;
          }
          if (startdate >= enddate) {
            this.messageService.openSnackBar('Invalid Time.', 'warn');
            return;
          }
        }
      }
      if (
        this.requestForm.taxifare &&
        (this.requestForm.claimfromplace == '' ||
          this.requestForm.claimfromplace == null)
      ) {
        return this.messageService.openSnackBar(
          'Please fill From Place.',
          'warn'
        );
      }
      if (
        this.requestForm.taxifare &&
        (this.requestForm.claimtoplace == '' ||
          this.requestForm.claimtoplace == null)
      ) {
        return this.messageService.openSnackBar(
          'Please fill To Place.',
          'warn'
        );
      }
      this.addRequest();
    }
  }

  async addRequest() {
    this.saveLoading = true;
    var approvers: any = [];
    var accompany: any = [];
    var userfromto: any = [];
    this.approverList.map((x: any) => {
      if (x.checked) {
        approvers.push(x.userid);
        userfromto.push({
          userid: x.userid,
          fromplace: x.from ? x.from : '',
          toplace: x.to ? x.to : '',
        });
      }
    });

    this.accompanyPersonList.map((x: any) => {
      if (x.checked) {
        accompany.push(x.userid);
      }
    });

    const current_date = this.allinoneService.getCurrentDateToDB();
    const current_time = this.allinoneService.getCurrentTimeToDB2();
    var tmpimg = current_date.toString() + current_time.toString();

    // Image Upload
    var filenamelist: any = [];
    for (let i = 0; i < this.imageList.length; i++) {
      filenamelist.push(this.imageList[i].filename);
      if (this.imageList[i].file.size >= 307200) {
        this.imageList[i].file = await this.allinoneService.compressImage(
          this.imageList[i].file
        );
        if (this.imageList[i].file == false) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.saveLoading = false;
          return;
        }
      }
    }

    if (this.imageList.length > 0) {
      const result = await this.allinoneService.multipleUploadToAWS(
        this.imageList,
        'office/request/' + tmpimg
      );
      if (!result) {
        this.messageService.openSnackBar(
          'Error while uploading your file.',
          'warn'
        );
        this.saveLoading = false;
        return;
      }
    }

    var data: any;
    if (this.requestForm.requesttype == 'NCMLEIWHRNVIE') {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttimeampm,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtimeampm,
        duration: this.requestForm.duration,
        remark: this.requestForm.remark.toString().trim(),
        tmpimg: tmpimg,
        imagelist: filenamelist,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (
      this.requestForm.requesttype == this.lateid ||
      this.requestForm.requesttype == this.earlyoutid
    ) {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: '',
        endtime: this.requestForm.endtime,
        duration: this.requestForm.duration,
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.workfromhomeid) {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        starttime: '',
        endtime: '',
        duration: this.requestForm.duration,
        remark: this.requestForm.remark,
        lat: this.requestForm.lat,
        long: this.requestForm.long,
        location: this.requestForm.location,
        locationid: this.requestForm.locationid,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.offinlieuid) {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        date: this.allinoneService.formatDate(this.requestForm.date),
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        enddate: '',
        starttime: this.requestForm.starttime,
        endtime: this.requestForm.endtime,
        duration: '',
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.claimid) {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        claimdate: this.allinoneService.formatDate(this.requestForm.startdate),
        amount: this.requestForm.amount.toString(),
        currencyid: this.requestForm.currency,
        remark: this.requestForm.remark,
        tmpimg: tmpimg,
        imagelist: filenamelist,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
        fromplace: this.requestForm.taxifare
          ? this.requestForm.claimfromplace
          : '',
        toplace: this.requestForm.taxifare ? this.requestForm.claimtoplace : '',
      };
    } else if (this.requestForm.requesttype == this.cashadvancedid) {
      console.log('your new con');

      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        claimdate: this.allinoneService.formatDate(this.requestForm.startdate),
        amount: this.requestForm.amount.toString(),
        currencyid: this.requestForm.currency,
        remark: this.requestForm.remark,
        tmpimg: tmpimg,
        imagelist: filenamelist,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
        fromplace: this.requestForm.taxifare
          ? this.requestForm.claimfromplace
          : '',
        toplace: this.requestForm.taxifare ? this.requestForm.claimtoplace : '',
      }
    }
    else if (this.requestForm.requesttype == 'UEYVNDLOAPQWXQW') {
      console.log('hereeeeeeeeee');
      console.log(this.requestForm);
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtime,
        duration: this.requestForm.overtimehour,
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.reservationid) {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate:
          this.requestForm.reservationtype == '002'
            ? this.allinoneService.formatDate(this.requestForm.enddate)
            : '',
        endtime: this.requestForm.endtime,
        weekday: [],
        toplace: '',
        going: '',
        return: '',
        arrivetime: '',
        returntime: '',
        userlist: [],
        trantype: '',
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        deleteimagelist: [],
        approver: [],
        userfromto: [],
        userfrom: '',
        userto: '',
        reservationtype: this.requestForm.reservationtype,
        roomid:
          this.requestForm.reservationtype == '001'
            ? this.requestForm.roomid
            : '',
        workstationid:
          this.requestForm.reservationtype == '002'
            ? this.requestForm.workstationid
            : '',
        deskid:
          this.requestForm.reservationtype == '002'
            ? this.requestForm.deskid
            : '',
      };
    } else if (this.requestForm.requesttype == 'VMAOWEFAMSVNAOWI') {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.startdate),
        endtime: this.requestForm.starttime,
        duration: '',
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        deleteimagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    } else if (this.requestForm.requesttype == this.transportationid) {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: this.requestForm.subtype,
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: '',
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: '',
        weekday: this.requestForm.weekday,
        toplace:
          this.requestForm.trantype == '002' ? this.requestForm.toplace : '',
        going:
          this.requestForm.trantype == '002' ? this.requestForm.isgoing : '',
        return:
          this.requestForm.trantype == '002' ? this.requestForm.isreturn : '',
        arrivetime: this.requestForm.arrivetime,
        returntime: this.requestForm.returntime,
        userlist: [],
        trantype: this.requestForm.trantype,
        remark: this.requestForm.remark,
        tmpimg: '',
        imagelist: [],
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        deleteimagelist: [],
        approver: [],
        userfromto: userfromto,
        userfrom: this.requestForm.dailyfrom,
        userto: this.requestForm.dailyto,
        pickuppointid: this.requestForm.pickuppoint,
      };
    } else if (this.requestForm.requesttype == this.travelrequestid) {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtime,
        duration: this.requestForm.travelduration,
        modeoftravel: this.requestForm.modeoftravel,
        vehicleuse: this.requestForm.vehicleuse,
        remark: this.requestForm.remark,
        tmpimg: tmpimg,
        imagelist: filenamelist,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
        productid: this.requestForm.product ? this.requestForm.product : '',
        projectid: this.requestForm.project ? this.requestForm.project : '',
        accompanyperson: accompany,
        amount: this.requestForm.amount.toString(),
        currencyid: this.requestForm.currency,
      };
    } else if (this.requestForm.requesttype == this.hrid) {
      // data = {
      //   memberid: this.requestForm.userid,
      //   requesttype: this.requestForm.requesttype,
      //   requestsubtype: this.requestForm.requestsubtype,
      //   startdate: this.allinoneService.formatDate(this.requestForm.hrrequestdate),
      //   hrrequesttype : this.requestForm.hrrequesttype,
      //   remark: this.requestForm.remark,
      //   requeststatus: '001',
      //   orgid: this.requestForm.orgid,
      // };
      data = {
        hrrequestid: '',
        orgid: this.requestForm.orgid,
        typeid: this.requestForm.hrrequesttype,
        requestdate: this.allinoneService.formatDate(this.requestForm.hrrequestdate),
        description: this.requestForm.remark,
      }
      this.tempHRAdd(data);
      return;
    }
    else {
      data = {
        memberid: this.requestForm.userid,
        requesttype: this.requestForm.requesttype,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.requestForm.startdate),
        starttime: this.requestForm.starttime,
        enddate: this.allinoneService.formatDate(this.requestForm.enddate),
        endtime: this.requestForm.endtime,
        duration: this.requestForm.travelduration,
        modeoftravel: this.requestForm.modeoftravel,
        vehicleuse: this.requestForm.vehicleuse,
        remark: this.requestForm.remark,
        tmpimg: tmpimg,
        imagelist: filenamelist,
        requeststatus: '001',
        orgid: this.requestForm.orgid,
        approver: approvers,
      };
    }
    console.log(data);
    this.subscriptions.sink = this.kunyekService
      .addOfficeRequest(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.requestForm.userid = '';
            this.changeUserid();
            this.messageService.openSnackBar('Successful.', 'success');
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.saveLoading = false;
        },
        (err) => {
          this.saveLoading = false;
        }
      );
  }

  tempHRAdd(data: any) {
    this.subscriptions.sink = this.kunyekService.createAccessoriesRequest(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.requestForm.userid = '';
          this.changeUserid();
          this.messageService.openSnackBar('Successful.', 'success');
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.saveLoading = false;
      }
    )
  }

  openLeaveSummaryForm() {
    const dialog = this.dialog.open(OfficeLeaveSummaryComponent, {
      maxWidth: '95vw',
      width: '550px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        memberid: this.requestForm.userid,
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.requestForm.orgid,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => { });
  }

  getWorkstation() {
    this.gettingWorkstation = true;
    const data = {
      orgid: this.orgid,
    };
    this.kunyekService.getWorkstationList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.workstationList = res.workstationlist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingWorkstation = false;
      },
      (err) => {
        this.gettingWorkstation = false;
      }
    );
  }
  getAvailableRoomAndDesk() {
    this.gettingAvailableRoomAndDesk = true;
    const data = {
      reservationtype: this.requestForm.reservationtype,
      startdate: this.allinoneService.formatDate(this.requestForm.startdate),
      enddate: this.allinoneService.formatDate(this.requestForm.enddate),
      starttime: this.requestForm.starttime,
      endtime: this.requestForm.endtime,
      requesttype: this.requestForm.requesttype,
      orgid: this.requestForm.orgid,
      workstationid: this.requestForm.workstationid,
    };
    this.reservation_subscription &&
      this.reservation_subscription.unsubscribe();
    this.reservation_subscription = this.kunyekService
      .getAvailableRoomAndDesk(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (this.requestForm.reservationtype == '001') {
              this.requestForm.roomid = '';
              this.requestForm.maxpeople = '';
              this.availableRoom = res.availablelist;
            } else {
              this.requestForm.deskid = '';
              this.availableDesk = res.availablelist;
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingAvailableRoomAndDesk = false;
        },
        (err) => {
          this.gettingAvailableRoomAndDesk = false;
        }
      );
  }

  roomChange() {
    if (this.requestForm.roomid == '') {
      this.requestForm.maxpeople = '';
    } else {
      var temp = this.availableRoom.find(
        (x: any) => x.roomid == this.requestForm.roomid
      );
      if (temp) {
        this.requestForm.maxpeople = temp.maxpeople;
      }
    }
  }

  workstationChange() {
    if (this.requestForm.workstationid == '') {
      this.availableDesk = [];
    } else {
      this.getAvailableRoomAndDesk();
    }
  }

  submitLateEarlyOut(event: any) {
    const { requestData, approverList } = event;
    this.requestForm.starttime = requestData.starttime;
    this.requestForm.endtime = requestData.endtime;
    this.requestForm.remark = requestData.remark;
    this.requestForm.duration = requestData.duration;
    this.requestForm.startdate = requestData.date;
    this.approverList = approverList;
    this.addRequest();
  }

  submitWorkFromHome(event: any) {
    const { requestData, approverList } = event;
    this.requestForm.remark = requestData.remark;
    this.requestForm.startdate = requestData.startdate;
    this.requestForm.enddate = requestData.enddate;
    this.requestForm.locationid = requestData.locationid;
    this.requestForm.location = requestData.location;
    this.requestForm.lat = requestData.lat;
    this.requestForm.long = requestData.long;
    this.requestForm.duration = requestData.duration;
    this.approverList = approverList;
    this.addRequest();
  }

  submitOffInLieu(event: any) {
    const { requestData, approverList } = event;
    this.requestForm.remark = requestData.remark;
    this.requestForm.startdate = requestData.startdate;
    this.requestForm.starttime = requestData.starttime;
    this.requestForm.endtime = requestData.endtime;
    this.requestForm.date = requestData.date;
    this.approverList = approverList;
    this.addRequest();
  }
}
