import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-requisition-user-info',
  templateUrl: './requisition-user-info.component.html',
  styleUrls: ['./requisition-user-info.component.scss']
})
export class RequisitionUserInfoComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  isFocus: boolean = false;
  isMobileView: boolean = false;
  mobileViewWidth: number = 426;
  itemPerPage: number = 10;
  currentPage: number = 1;

  searchText: string = '';

  memberList: any = [];
  memberListLoading: boolean = false;
  orgid: string = '';
  requisitionid : string = '';

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private router: Router,
    private dialogRef: MatDialogRef<RequisitionUserInfoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.orgid = data.orgid;
    this.requisitionid = data.requisitionid
  }

  ngOnInit(): void {
    this.getMemberData();
  }

  getMemberData() {
    this.memberListLoading = true;
    this.memberList = [];
    const data = {
      orgid : this.orgid,
      requisitionid : this.requisitionid,
    }
    this.kunyekService.getRequisitionUserInfo(data).subscribe(
      (res : any) => {
        if(res.returncode == '300') {
          this.memberList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.memberListLoading = false;
      },
      (err) => {
        this.memberListLoading = false;
      }
    )
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  sortData(sort: Sort) {
    const data = this.memberList;
    if (!sort.active || sort.direction === '') {
      this.memberList = data;
      return;
    }
    this.memberList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'username':
          return this.allinoneService.compare(a.username, b.username, isAsc);
        case 'employeeid':
          return this.allinoneService.compare(a.employeeid, b.employeeid, isAsc);
        case 'post':
          return this.allinoneService.compare(a.post, b.post, isAsc);
        case 'department':
          return this.allinoneService.compare(a.department, b.department, isAsc);
        case 'division':
          return this.allinoneService.compare(a.division, b.division, isAsc);
        default:
          return 0;
      }
    })

  }

  clear() {
    this.searchText = "";
  }

  goToMemberForm(userid: string){
    this.dialogRef.close();
    this.router.navigateByUrl(`/hxm/recruitment/requisitions/${userid}`)
  }

}
