import { data } from 'jquery';
import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { CrmCustomerDealComponent } from '../crm-customer-deal/crm-customer-deal.component';
import { CrmServiceFilterbydateComponent } from '../crm-service-filterbydate/crm-service-filterbydate.component';
import { PipelineTotalamountComponent } from '../pipeline-totalamount/pipeline-totalamount.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-deal',
  templateUrl: './deal.component.html',
  styleUrls: ['./deal.component.scss']
})
export class DealComponent implements OnInit {

  @HostListener('window:resize', ['$event'])

  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {

      this.isMobileView = true;

      this.isTabletView = false;

    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {

      this.isMobileView = false;

      this.isTabletView = true;

    } else {

      this.isMobileView = false;

      this.isTabletView = false;

    }

  }
  isTabletView: boolean = false;
  isMobileView: boolean = false;
  isView: boolean = false;
  gettingData: boolean = true;
  gettingServicData: boolean = false;
  leadAdd: boolean = false;
  customerAdd: boolean = false;
  dealAdd: boolean = false;
  serviceAdd: boolean = false;
  summaryCheck: boolean = false;
  historyshow: boolean = false;
  isFocus: boolean = false;
  gettingStages: boolean = false;

  dataForEdit: any = ''
  mobileViewWidth: number = 426;
  viewWidth: number = 992;
  itemPerPage: number = 100;
  p: number = 1;
  callType: any = '';
  servcietypelist: any = [];
  statusLists: any = [];
  searchStatusLead: any = '';
  searchStatusCustomer: any = '';
  searchstagedeal: any = '';
  searchStatusdeal: any = '';
  priority: any = [];
  filterstartdate: any = '';
  filterenddate: any = '';
  organizationsList: any = [];
  gettingOrgList: boolean = false;
  orgtype: any = '';
  dealview: boolean = false;
  totalopencount: any = '0';
  totalclosedcount: any = '0';




  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  types: any[] = [
    {
      id: '001',
      name: 'Leads',
    },
    {
      id: '002',
      name: 'Customers',
    },
    {
      id: '003',
      name: 'Deals',
    },
    {
      id: '004',
      name: 'Pipelines',
    },
    {
      id: '005',
      name: 'Services',
    },
  ];

  //Lead Status
  leadStatus = [
    {
      key: '001',
      value: 'Closed',
    },
    {
      key: '002',
      value: 'Open',
    },
  ];


  //Customer Status
  customerStatus = [
    {
      key: '001',
      value: 'Prospect',
    },
    {
      key: '002',
      value: 'Customer',
    },
  ];

  //Deal Status
  dealStatus = [
    {
      key: '001',
      value: 'Open',
    },
    {
      key: '002',
      value: 'Closed - Won',
    },
    {
      key: '003',
      value: 'Closed - Lost',
    },
  ];

  //Business Type
  businessType = [
    {
      key: '001',
      value: 'Organization',
    },
    {
      key: '002',
      value: 'Person',
    },
  ];

  //Currency Type
  currencyType = [
    {
      key: '001',
      currency: 'Kyat',
      code: 'MMK',
      country: 'Myanmar',
    },
    {
      key: '025',
      currency: 'dollar',
      code: 'USD',
      country: 'United States',
    },
    {
      key: '002',
      currency: 'Singapore dollar',
      code: 'SGD',
      country: 'Singapore',
    },
    {
      key: '003',
      currency: 'đồngr',
      code: 'VND',
      country: 'Vietnam',
    },
    {
      key: '004',
      currency: 'UAE dirham',
      code: 'AED',
      country: 'United Arab Emirates',
    },
    {
      key: '005',
      currency: 'Turkish lira',
      code: 'TRY',
      country: 'Turkey',
    },
    {
      key: '006',
      currency: 'Baht',
      code: 'THB',
      country: 'Thailand',
    },
    {
      key: '007',
      currency: 'Rupiah',
      code: 'IDR',
      country: 'Indonesia',
    },
    {
      key: '008',
      currency: 'New Taiwan dollar',
      code: 'TWD',
      country: 'Taiwan',
    },
    {
      key: '009',
      currency: 'South Korean won',
      code: 'KRW',
      country: 'South Korea',
    },
    {
      key: '010',
      currency: 'Yen',
      code: 'JPY',
      country: 'Japan',
    },
    {
      key: '011',
      currency: 'Russian Ruble',
      code: 'RUB',
      country: 'Russia',
    },
    {
      key: '012',
      currency: 'Philippine peso',
      code: 'PHP',
      country: 'Philippines',
    },
    {
      key: '013',
      currency: 'Pakistani Rupee',
      code: 'PKR',
      country: 'Pakistan',
    },
    {
      key: '014',
      currency: 'Nepalese rupee',
      code: 'NPR',
      country: 'Nepal',
    },
    {
      key: '015',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '016',
      currency: 'Jordanian dinar',
      code: 'JOD',
      country: 'Jordan',
    },
    {
      key: '017',
      currency: 'Lao kip',
      code: 'LAK',
      country: 'Laos',
    },
    {
      key: '018',
      currency: 'Indian Rupee',
      code: 'INR',
      country: 'India',
    },
    {
      key: '019',
      currency: 'Hong Kong dollar',
      code: 'HKD',
      country: 'Hong Kong',
    },
    {
      key: '020',
      currency: 'Renminbi (yuan)',
      code: 'CNY',
      country: 'China',
    },
    {
      key: '021',
      currency: 'Cambodian riel',
      code: 'KHR',
      country: 'Cambodia',
    },
    {
      key: '022',
      currency: 'Brunei dollar',
      code: 'BND',
      country: 'Brunei',
    },
    {
      key: '023',
      currency: 'Bhutanese ngultrum',
      code: 'BTN',
      country: 'Bhutan',
    },
    {
      key: '024',
      currency: 'Taka',
      code: 'BDT',
      country: 'Bangladesh',
    },
  ];

  stages: any[] = [];

  formatToShow = 'yyyy-MM-dd';
  now = Date.now();
  oneWeekBefore = new Date(this.now).setDate(new Date(this.now).getDate() - 14);
  currentEndDate = formatDate(
    Date.now(),
    this.formatToShow,
    'en-US'
  ).toString();
  currentStartDate = formatDate(
    new Date(this.oneWeekBefore),
    this.formatToShow,
    'en-US'
  ).toString();

  crmForm = {
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    type: '',
    stageid: '',
    status: '',
    startdate: this.currentStartDate,
    enddate: this.currentEndDate,
  };

  organizations: any[] = [];
  data: any[] = [];
  @Input() width!: string;

  searchText: any = '';
  searchTextService: any = '';
  tabletViewWidth: any = 992;
  statusFilter: any = '';
  serviceFilter: any = '';

  routeType: any = '';
  allrouteType: any = '';
  @Input() t_breadcrumb!: string;
  projectList: any = '';
  gettingProjectList: boolean = false;
  projectid: any = '';
  startdatetime: string = '';
  enddatetime: string = '';
  searchLoading: boolean = false;
  nextDisabled: boolean = false;
  lastPage: any = '';
  currentPage: any = 1;
  total: any = '0';
  sortby: any = '';
  getServiceType: boolean = true;
  getSatatusType: boolean = true;
  statuscode: string = '0010';
  servicecode: string = '';
  viewopenservice: boolean = false;
  servicetype: any = '';
  goCustomer: any = '';
  goCusBol: boolean = false;
  goDealBol: boolean = false;
  gopipBol: boolean = false;
  checkOrgB: boolean = false;
  orgname: any = '';
  routevalueQ: any = '';
  routevalueP: any = '';
  routevalueAll: any = '';
  pagnation: any = [];
  Days: any;
  endDays: any = ''
  productadmin: boolean = false
  filtergetdata: any = [];
  isAdmin: boolean = false;
  customername: any = '';
  permission: any = [];
  dealcreate: boolean = false;
  dealedit: boolean = false;
  dealdelete: boolean = false;



  private subs = new SubSink();
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  // pagesize:any = 0;
  private dialogRef: any;
  private dialogData: any;
  checkModeal: boolean = false;
  isExcel: boolean = false;
  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private injector: Injector,

  ) {
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);


    this.isAdmin = (this.allinoneService.crmOrgAdmin === 'true');


    // this.statuscode = '0010'
  }
  awaitDomain: any = ''

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    console.log(this.data);

    console.log(`SubSinkComponent  destroyed`);
    this.allinoneService.activeroute = "/sales";


  }

  orgList: any = [];
  customercare: boolean = false;
  customernamecare: any = "";
  getCustomercaredata: any = [];
  customeridcare: any = "";
  contactid: any = '';
  contactadmin: boolean = false;
  async ngOnInit(): Promise<void> {
    this.allinoneService.activeroute = "/sales";

    if (window.innerWidth < this.mobileViewWidth) {

      this.isMobileView = true;

      this.isTabletView = false;

    } else if (window.innerWidth > this.mobileViewWidth && window.innerWidth < this.tabletViewWidth) {

      this.isMobileView = false;

      this.isTabletView = true;

    }

    // if (this.crmForm.type == '003') {
    console.log("okok" + this.isAdmin)
    this.permission = this.allinoneService.getCRMpermission();
    console.log(this.permission)
    if(this.allinoneService.crmBlocked == 'true'){
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    }else{
    if (this.isAdmin == false) {

      var type = this.permission.map((x: any) => x).indexOf('006');
      if (type == -1) {
        this.router.navigateByUrl('sales')
      }
      var view = this.permission.map((x: any) => x).indexOf('0061');
      var create = this.permission.map((x: any) => x).indexOf('0062');
      var edit = this.permission.map((x: any) => x).indexOf('0063');
      var delet = this.permission.map((x: any) => x).indexOf('0064');
      console.log(".....lLLLLLl>>>>>>>>")
      console.log(delet)
      if (view == -1) {
        this.dealview = true;
      }
      if (create == -1) {
        this.dealcreate = true;
      }

      if (edit == -1) {
        this.dealedit = true;
      }

      if (delet == -1) {
        this.dealdelete = true;

      }
    }
  }



    // }
    // if (this.callType == '300') {
    //   this.organizations = this.allinoneService.orgs;
    //   this.crmForm.orgid = this.allinoneService.selectedOrg
    //     ? this.allinoneService.selectedOrg
    //     : this.organizations[0].orgid;
    // } else {
    //   this.organizations = this.allinoneService.contributorOrgs;
    //   this.crmForm.orgid = this.allinoneService.selectedConOrg
    //     ? this.allinoneService.selectedConOrg
    //     : this.organizations[0].orgid;
    // }


    // this.allinoneService.isCRMServiceView = true;

    this.organizations = this.allinoneService.orgsCRm;
    this.crmForm.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;

    console.log("LLL>>>>>")


    this.startdatetime = formatDate(
      new Date(),
      this.formatToShow,
      'en-US'
    ).toString();
    var futureDate = new Date();

    futureDate.setDate(futureDate.getDate() + 7);
    this.enddatetime = formatDate(
      futureDate,
      this.formatToShow,
      'en-US'
    ).toString();
    console.log(this.enddatetime)
    if (this.width == null) {
      this.width = "auto";
    }
    // this.crmForm.status = this.dealStatus[0].key;






    //check comeback from other site [Members/locations/etc] - Edit Form

    // if (this.crmForm.type == '005') {
    //   console.log(">>>>>>>>>>>orjkwje;fkj");
    //   console.log(this.awaitDomain);
    //   this.getAllProjects();
    //   this.getStatusList();
    //   console.log(this.crmForm.orgid);
    // } else if (this.crmForm.type != '005') {

    this.getData();
    this.getStages();
    // }

  }




  sortData(sort: Sort) {
    const data = this.data;
    if (!sort.active || sort.direction === '') {
      this.data = data;
      return;
    }

    this.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        //Deal
        case 'dealname':
          return this.allinoneService.compare(
            a.customername.toLocaleLowerCase(),
            b.customername.toLocaleLowerCase(),
            isAsc
          );
        case 'dealstage':
          return this.allinoneService.compare(a.stagename, b.stagename, isAsc);
        case 'dealstatus':
          return this.allinoneService.compare(
            a.statusname,
            b.statusname,
            isAsc
          );
        case 'dealtargetdate':
          return this.allinoneService.compare(
            a.targetdate,
            b.targetdate,
            isAsc
          );
        case 'dealclosingdate':
          return this.allinoneService.compare(
            a.closingdate,
            b.closingdates,
            isAsc
          );
        case 'dealcreatedby':
          return this.allinoneService.compare(
            a.createdbyname.toLocaleLowerCase(),
            b.createdbyname.toLocaleLowerCase(),
            isAsc
          );

        default:
          return 0;
      }
    });

  }

  orgChanged(event: any) {
    this.crmForm.orgid = event.target.value;
    if (this.crmForm.orgid == '') {
      this.data = [];
    } else {
      if (this.callType == '300') {
        this.allinoneService.selectedOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedOrg(this.crmForm.orgid);
      } else {
        this.allinoneService.selectedConOrg = this.crmForm.orgid;
        this.allinoneService.setSelectedConOrg(this.crmForm.orgid);
      }
      this.getData();
    }
  }

  typeChanged(event: any) {
    this.crmForm.type = event.target.value;
    if (this.crmForm.orgid) {
      this.getData();
    }
  }

  getStages() {
    this.gettingStages = true;
    const data = {
      orgid: '',
      domainid: this.crmForm.domainid,
    };
    this.kunyekService.getPipelineStage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.stages = res.list;
          // this.crmForm.stageid = this.stages[0].stageid;
          this.gettingStages = false;
        } else {
          this.gettingStages = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingStages = false;
      }
    );
  }


  getData() {
    this.gettingData = true;
    // this.data = [];
    // this.p = 1;
    this.total = '0';

    //Deals
    const data = {
      domainid: this.crmForm.domainid,
      orgid: this.crmForm.orgid,
      pagesize: this.itemPerPage,
      sortby: this.sortby,
    };
    this.subs.sink = this.kunyekService.getCrmDeals(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          // this.itemPerPage = 100;
          // this.pg[3].count = this.data.length;
          res.list.map((item: any) => {
            var statusIndex = this.dealStatus
              .map((x) => x.key)
              .indexOf(item.status);
            var currencyIndex = this.currencyType
              .map((x) => x.key)
              .indexOf(item.currency);
            // item.statusname =
            //   statusIndex > -1 ? this.dealStatus[statusIndex].value : '';
            // item.currencyname =
            //   currencyIndex > -1 ? this.currencyType[currencyIndex].code : '';
            this.data.push({
              'amount': item.amount,
              'closingdate': item.status == '001' ? '' : item.closingdate,
              'createdby': item.createdby,
              'createdbyname': item.createdbyname,
              'currency': item.currency,
              'currencyname': currencyIndex > -1 ? this.currencyType[currencyIndex].code : '',
              'customerid': item.customerid,
              'customername': item.customername,
              'dealid': item.dealid,
              'opendate': item.opendate,
              'product': item.product,
              'remark': item.remark,
              'salecode': item.salecode,
              'stageid': item.stageid,
              'statusname': statusIndex > -1 ? this.dealStatus[statusIndex].value : '',
              'stagename': item.stagename,
              'status': item.status,
              'targetdate': item.targetdate,
              'sort': item.sort,
            })


          });

          this.total = res.total;
          var sort = this.data.length - 1;


          if (parseInt(this.total) == this.data.length) {
            console.log("getTrue")
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.data[sort].sortby;
          } else {
            console.log("getfalse");
            this.sortby = this.sortby = sort > -1 ? this.data[sort].sort : '';
          }
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );
    //Pipeline

  }



  editDeal(item: any) {
    if (this.dealedit == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      this.dataForEdit = JSON.parse(JSON.stringify(item))
      this.dealAdd = true
    }
  }


  viewDeal(item: any) {
    const dialog = this.dialog.open(CrmCustomerDealComponent, {
      width: '700px',
      height: "550px",
      closeOnNavigation: false,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        'data': item,
        'fromPipeline': true,
        'currencyType': this.currencyType,
        'statusList': this.dealStatus,
        'stageList': this.stages,
        'customername': item.customername
      }
    })
  }

  deleteDeal(id: any) {
    if (this.dealdelete == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        width: "250px",
        panelClass: ['mat-typography'],
        closeOnNavigation: true
      })

      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.gettingData = true;
          const data = {
            "dealid": id,
            "orgid": this.crmForm.orgid,
            "domainid": this.crmForm.domainid
          }
          this.kunyekService.deleteCrmDeal(data).subscribe((res: any) => {
            if (res.returncode == "300") {
              this.refreshgetData();
            }
            else {
              this.gettingData = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
            err => {
              this.gettingData = false;
            }
          )
        }
      });
    }
  }

  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    // this.refreshgetData();
    this.gettingData = true;
    this.getData();
  }

  clear() {
    this.searchText = '';
    this.searchTextService = '';
  }

  complete() {
    this.projectid = '';
    this.orgtype = this.crmForm.orgid;
    // if (this.crmForm.type != '005') {
    this.refreshgetData();

    this.cancel()
  }
  backCustomer() {
    this.router.navigateByUrl('sales/customers')
  }
  cancelservice(data: any) {
    this.dataForEdit = ''
    this.leadAdd = false
    this.customerAdd = false
    this.dealAdd = false;
    this.summaryCheck = false;
    this.serviceAdd = false;
    this.historyshow = false;
    this.orgtype = this.crmForm.orgid;
    console.log(data);
    console.log(">...");
    if (data) {
    }
  }
  cancel() {
    this.dataForEdit = ''
    this.dealAdd = false;
    this.orgtype = this.crmForm.orgid;
  }




  new() {
    if (this.dealcreate == true) {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedViewMessage, 'warn');
    } else {
      this.dealAdd = true
    }
  }

  openTotalamountForm() {
    const dialog = this.dialog.open(PipelineTotalamountComponent, {
      width: '500px',
      height: '350px',
      closeOnNavigation: true,
      panelClass: ['event-modal', 'event-detail', 'mat-typography'],
      data: {
        form: 'pipeline',
        datalist: this.data
      },
    });
    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    });

  }

  goToSecond() {

  }

  getAllProjects() {
    this.gettingProjectList = true;
    this.projectList = [];
    const data = {
      orgid: this.crmForm.orgid,
      domainid: this.allinoneService.getDomain().domainid,
      // role: this.callType,
    };

    console.log(data);
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          var userid = this.allinoneService.getKunyekUserId();
          if (this.allinoneService.crmOrgAdmin == 'false') {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i]['memberstatus'] == '') {
                this.projectList.push(res.data[i]);
              }
              if (res.data[i]['memberstatus'] == '1') {
                for (let j = 0; j < res.data[i]['memberlist'].length; j++) {
                  console.log(res.data[i]['memberlist'][j]['userid'] == userid);
                  if (userid == res.data[i]['memberlist'][j]['userid']) {
                    this.projectList.push(res.data[i])
                  }
                }
              }
            }
          } else {
            this.projectList = res.data;
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingProjectList = false;

      },
      (err) => {
        this.gettingProjectList = false;
      }
    );
  }

  close() {
    const data = {
      'status': true,
    }

    this.dialogRef.close(data);
  }



  closeviewopen() {
    this.viewopenservice = false;

  }
  refreshgetData() {
    this.subs.unsubscribe();
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    this.getData();
  }




  projectChange(event: any) {
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';

    const project = event.target.value;

  }





  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log("true");
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log("false");
      if (event == this.lastPage && this.lastPage != '') {
        console.log("yes");
        console.log(this.lastPage);
        console.log(this.currentPage)
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log("no");
        this.currentPage = event;

        if (event > (this.data.length / this.itemPerPage)) {
          console.log("getServcieData");
          console.log("nnn>>>>>>getData")
          // this.getData();this.
          this.refreshgetData()
        }
      }

    }
  }




  getStatusList() {
    this.getSatatusType = true;
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.crmForm.orgid,
    }
    this.kunyekService.getStatusType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.statusLists = res.list;
          this.getSatatusType = false;
        } else {
          this.getSatatusType = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getSatatusType = false;
      }
    );

  }

  downloadSheetDeal() {
    var datas: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Deals_' + time + '.xlsx';

    // {{item.status == '001' ? 'Open' : item.status == '002' ? 'Closed - Won' : 'Closed - Lost'}}
    console.log(this.data)
    for (let i = 0; i < this.data.length; i++) {
      console.log(">>>.1")
      if (this.data[i].product.length == 0) {
        datas.push({
          'Customer': this.data[i].customername,
          'Stage': this.data[i].stagename,
          'Status': this.data[i].status == '001' ? 'Open' : this.data[i].status == '002' ? 'Closed - Won' : this.data[i].status == '003' ? 'Closed - Lost' : '',
          'Product': '',
          'Price': '',
          'Qty': '',
          'Amount': this.data[i].amount,
          'Target Date': this.allinoneService.formatDBToShow(this.data[i].targetdate),
          'Closing Date': this.data[i].closingdate != '' ? this.allinoneService.formatDBToShow(this.data[i].closingdate) : '',
        })
      } else if (this.data[i].product.length > 0) {

        for (let j = 0; j < this.data[i].product.length; j++) {
          console.log(">>>.2")
          datas.push({
            'Customer': this.data[i].customername,
            'Stage': this.data[i].stagename,
            'Status': this.data[i].status == '001' ? 'Open' : this.data[i].status == '002' ? 'Closed - Won' : this.data[i].status == '003' ? 'Closed - Lost' : '',
            'Product': this.data[i].product[j].name,
            'Price': this.data[i].product[j].price,
            'Qty': this.data[i].product[j].qty,
            'Amount': this.data[i].amount,
            'Target Date': this.allinoneService.formatDBToShow(this.data[i].targetdate),
            'Closing Date': this.data[i].closingdate != '' ? this.allinoneService.formatDBToShow(this.data[i].closingdate) : '',
          })
        }
      }

    }

    console.log(datas);
    this.allinoneService.exportEcecl(datas, name);
  }
  cancelFunction() {
    this.dealAdd = false;
    this.total = 0;
    this.data = [];
    // this.getStages();
    // this.getData();



  }


  dealstagename: any = "Stage"
  // ChangeDealStage(event: any) {
  //   this.searchstagedeal = event.target.value;
  //   console.log(this.searchstagedeal)
  // }
  ChangeDealStage(data: any) {
    console.log(data)
    if (data == 'stage') {
      this.dealstagename = 'Stage';
      this.searchstagedeal = '';
    } else {
      this.dealstagename = data.name;
      this.searchstagedeal = data.name;
    }
  }

  dealstatusname: any = 'Status';
  ChangeDealStatus(data: any) {
    console.log(data);
    if (data == 'status') {
      this.dealstatusname = 'Status';
      this.searchStatusdeal = '';
    } else {
      this.dealstatusname = data.value;
      this.searchStatusdeal = data.value;
    }
  }




}
