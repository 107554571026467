import {
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-choose-member',
  templateUrl: './choose-member.component.html',
  styleUrls: ['./choose-member.component.scss']
})
export class ChooseMemberComponent implements OnInit {

  @ViewChild('chatPS') chatPS!: ElementRef;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  searchingMember: boolean = false;
  searchCustomerCancel: boolean = false;
  isLeaveOpening: boolean = false;
  memberSearch = '';
  memberList: any = [];
  _memberList: any = [];
  selectedMemberList: any = [];
  isAllMembers: boolean = false;
  _isEnd: boolean = false;
  isAdmin: boolean = false;
  gettingMemberList: boolean = true;
  currentMemberId: string = '';

  domainid: string = '';
  orgid: string = '';
  templateid: string = '';
  calendarid: string = '';

  startCount: any = 0;
  endCount: any = 30;

  subscription!: Subscription;
  mytimeout: any;
  private dialogData: any;
  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<ChooseMemberComponent>,
    private injector: Injector,
    // private dialog: MatDialog,
    private router: Router,
  ) {
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.domainid = this.dialogData.domainid;
    this.orgid = this.dialogData.orgid;
    this.currentMemberId = this.dialogData.currentMemberId
    this.selectedMemberList = this.dialogData.relativeList
    this.selectedMemberList.forEach((i: any) => {
      i.checked = true;
    })
    console.log(this.selectedMemberList, 'dial')
  }

  ngOnInit(): void {
    this.homesearchMember()
  }

  // searchChange() {
  //   if (this.memberSearch.trim() == '') {
  //     this.isAllMembers = false;
  //   }
  //   clearTimeout(this.mytimeout);
  //   this.mytimeout = setTimeout(() => {
  //     // if (this.memberSearch.trim() != '') {
  //     this.memberList = [];
  //     this.chatPS.nativeElement.scrollTop = 0;
  //     this.startCount = 0;
  //     this.endCount = 30;
  //     // }
  //     this.homesearchMember();
  //   }, 500);
  // }

  searchChange() {
    this.memberList.filter = this.memberSearch.trim().toLowerCase();
  }


  homesearchMember() {
    this.memberList = [];
    this.subscription = this.kunyekService.getKMembers({ searchName: this.memberSearch }).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.memberList = res.datalist
          var index = this.memberList.findIndex(
            (member: any) => member.syskey == this.currentMemberId
          );
         if(index > -1){
          this.memberList.splice(index, 1);
         }

          this.memberList.map((member: any) => {
            let piamge = JSON.parse(member.profileimage);
          member.profileUrl = piamge ? piamge.fileurl : ""
            this.selectedMemberList.map((sel: any) => {
              if (sel.relative_id == member.syskey) {
                member.checked = sel.checked
              }
            })
          })
          console.log(this.memberList, '....................checked or not after opening')
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingMemberList = false;
      },
      (err) => {
        this.gettingMemberList = false;
      }
    );

    this.gettingMemberList = false
    if (this.selectedMemberList.length > 0) {

      this.memberList.map((item: any) => {        
        var checkexist = this.selectedMemberList.find(
          (x: any) => x.relative_id == item.syskey
        );
        if (checkexist) {
          item.checked = checkexist.checked;
        }
      });
    }
  }

  onScrolledDown() {
    if (!this.isAllMembers) {
      this.startCount = this.startCount + 30;
      this.endCount = this.endCount + 30;
      // this.homesearchMember();
    }
  }

  selectMember(event: any, user: any) {
    // var index = this.memberList.findIndex(
    //   (cus: any) => cus.syskey == user.member_id
    // );
    // console.log(index);
    // if (index != -1) {
    //   this.memberList[index].checked = !this.memberList[index].checked;
    // }
    if (event.target.checked) {
      var index = this.selectedMemberList.findIndex(
        (seluser: any) => seluser.relative_id == user.syskey
      );
      if (index == -1) {
        user.checked = true;
        this.selectedMemberList.push(
          {
            relative_id: user.syskey,
            name: user.name,
            birthdate: user.birthdate,
            currentjob: user.currentjob,
            memberType: user.memberType,
            relativeship: "",
            checked: true,

          }
        );
      } else {

        user.checked = false;
        this.selectedMemberList[index].checked = !this.selectedMemberList[index].checked;
      }
    } else {
      var index ;
      this.selectedMemberList.forEach((i:any)=>{
        if(i.relative_id==user.syskey){
          index = this.selectedMemberList.indexOf(i, 0);
        }
      })
      if (index > -1) {
        this.selectedMemberList.splice(index, 1);
      }
    }
    console.log(this.selectedMemberList, 'dialog selected user;');

  }

  close(res: any) {
    this.dialogRef.close({ data: res });
  }
  done() {
    let finalList = [];
    finalList = this.selectedMemberList.filter((user: any) => user.checked === true
    );
    console.log('fl');
    console.log(finalList);

    this.dialogRef.close({ data: finalList });
  }


  searchByName() {
    this.gettingMemberList = true
    this.homesearchMember();
  }
}
