<div class="container-fluid">
    <div class="content">
        <app-crm-breadcrumb s_breadcrumb="Workflow" *ngIf="!isPermission">
        </app-crm-breadcrumb>
        <app-crm-breadcrumb s_breadcrumb="Workflow" f_breadcrumb="Permission" t_breadcrumb="{{name}}" (emit)="cancelFunction()" (emit1)="cancelFunction()" *ngIf="isPermission">
        </app-crm-breadcrumb>
        <div class="close-save-button mb-3" *ngIf="isPermission">
            <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
                Cancel
            </button>

            <app-button-loader [button_text]="'Save'" (click)="savePermission()" [load_button_text]="'Saving'" [isLoading]="saveLoading">
            </app-button-loader>

        </div>

        <div class="table-responsive" *ngIf="!isPermission">
            <table class="table table-responsive table-borderless">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-5 col-md-5">Role</td>
                        <td scope="col" class="col-lg-5 col-md-5">Description</td>
                        <td scope="col" class="col-lg-2 col-md-2">Action</td>

                    </tr>
                </thead>
                <tbody *ngIf="roleListLoading">
                    <tr>
                        <td colspan="3" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="roleListLoading == false">
                    <tr *ngIf="data.length == 0">
                        <td colspan="3" class="nodata">Empty</td>
                    </tr>
                    <tr *ngFor="let item of data">
                        <td class="td-data " (click)="addpermission(item)">
                            <span>{{item.name}}</span>
                        </td>
                        <td class="td-data " (click)="addpermission(item)">
                            <span>{{item.desc}}</span>
                        </td>
                        <td class="d-flex ">
                            <button title="Edit" class="btn btn-outline-primary" (click)="addpermission(item)">
                                <!-- <i class='fa fa-edit'></i> -->
                                <i class="fas fa-tasks"></i>
                            </button>
                        </td>

                    </tr>

                </tbody>
            </table>
        </div>



        <table class="table table-borderless" *ngIf="isPermission">
            <thead class="table-header">
                <tr>
                    <td scope="col" class="col-lg-2 col-md-2"></td>
                    <td scope="col" class="col-lg-2 col-md-2"></td>
                    <td scope="col" class="col-lg-1 col-md-1">View</td>
                    <td scope="col" class="col-lg-1 col-md-1">Create</td>
                    <td scope="col" class="col-lg-1 col-md-1">Edit</td>
                    <td scope="col" class="col-lg-1 col-md-1">Delete</td>
                </tr>
            </thead>
            <tbody>
                <!-- <div *ngFor="let item of PermissionList"> -->
                <tr *ngFor="let i of list;let j = index;" class="workflow-permission">
                    <td>
                        {{i.title}}
                    </td>
                    <!-- <td>
                        <div class="form-check " *ngIf="i.title">
                            <input class="form-check-input" type="checkbox" id="{{i.title}}" value="i.title" name="check" (click)="checkALL($event,i,j)">
                            <label class="form-check-label mb-1 ms-1" for="{{i.title}}">
                                {{i.title}}
                            </label>
                        </div>
                    </td> -->
                    <td>
                        <div class="form-check " *ngIf="i.value">
                            <input class="form-check-input" type="checkbox" id="check{{j}}" name="check" value="i.value" checked="checked" (click)="checkPermission($event,i,j)">
                            <label class="form-check-label mb-1 ms-1" for="check{{j}}">
                                {{i.appname}}
                            </label>
                        </div>
                        <div class="form-check " *ngIf="!i.value">
                            <input class="form-check-input" type="checkbox" id="check{{j}}" name="check" value="i.value" (click)="checkPermission($event,i,j)">
                            <label class="form-check-label mb-1 ms-1" for="check{{j}}">
                                {{i.appname}}
                            </label>
                        </div>
                    </td>



                    <td>
                        <div class="form-check " *ngIf="i.view != 'null'">
                            <div *ngIf="i.view">
                                <input class="form-check-input" type="checkbox" checked="checked" name="view" value="i.view" (click)="checkNewpermission($event,i,j,'view')">

                            </div>
                            <div *ngIf="!i.view">
                                <input class="form-check-input" type="checkbox" name="view" value="i.view" (click)="checkNewpermission($event,i,j,'view')">
                            </div>
                        </div>

                    </td>
                    <td>
                        <div class="form-check " *ngIf="i.create != 'null'">
                            <div *ngIf="i.create">
                                <input class="form-check-input" type="checkbox" name="check" checked="create" value="i.create" (click)="checkNewpermission($event,i,j,'create')">
                            </div>

                            <div *ngIf="!i.create">
                                <input class="form-check-input" type="checkbox" name="create" value="i.create" (click)="checkNewpermission($event,i,j,'create')">
                            </div>

                        </div>
                    </td>
                    <td>
                        <div class="form-check " *ngIf="i.edit != 'null'">
                            <div *ngIf="i.edit">
                                <input class="form-check-input" type="checkbox" checked="checked" name="edit" value="i.edit" (click)="checkNewpermission($event,i,j,'edit')">

                            </div>
                            <div *ngIf="!i.edit">
                                <input class="form-check-input" type="checkbox" name="edit" value="i.edit" (click)="checkNewpermission($event,i,j,'edit')">
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="form-check " *ngIf="i.delete  != 'null'">
                            <div *ngIf="i.delete">
                                <input class="form-check-input" type="checkbox" checked="checked" name="delete" value="i.delete" (click)="checkNewpermission($event,i,j,'delete')">

                            </div>
                            <div *ngIf="!i.delete">
                                <input class="form-check-input" type="checkbox" name="delete" value="i.delete" (click)="checkNewpermission($event,i,j,'delete')">
                            </div>
                        </div>
                    </td>
                    <!-- <td>
                        <div class="form-check " *ngIf="i.closed != 'null'">
                            <input class="form-check-input" type="checkbox" id="check{{j}}" name="check" value="i.value" (click)="checkPermission($event,i,j)">

                        </div>
                    </td> -->



                </tr>
                <!-- </div> -->


            </tbody>


        </table>

    </div>