<div class="dialog">
  <div class="title-container mb-3">
    <div class="title">Sync History</div>
    <div class="close-btn" (click)="close()">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <hr />

  <mat-dialog-content>
    <div class="d-flex gap-3 mb-3">
      <div class="input-group" style="width: 90%">
        <input
          class="form-control"
          type="text"
          placeholder="&#xf002; Search with User ID/Email/Phone"
          aria-label="Search"
          (focus)="isFocus = true"
          (blur)="isFocus = false"
          [(ngModel)]="searchText"
          [ngClass]="{ 'c-search-input-with-clear': searchText }"
          style="font-family: FontAwesome, Ubuntu"
        />
        <button
          class="btn btn-outline-secondary"
          type="button"
          *ngIf="searchText"
          (click)="clear()"
          [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
        >
          <mat-icon class="c-icon">close</mat-icon>
        </button>
      </div>
      <div class="col-auto">
        <div class="d-flex gap-1">
          <button
            type="button"
            class="btn btn-custom ms-2"
            id="togglefilter"
            (click)="toggleFilter()"
          >
            <mat-icon>{{
              openfilter ? "keyboard_arrow_up" : "keyboard_arrow_down"
            }}</mat-icon>
          </button>
          <button type="button" class="btn btn-custom ms-2" (click)="search()">
            <mat-icon>{{ "search" }}</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="openfilter">
      <div class="mb-3 col-md-4">
        <label for="startdate" class="form-label"> Sync Date </label>
        <div class="input-group">
          <input
            type="text"
            id="startdate"
            name="startdate"
            class="form-control datepicker-readonly"
            placeholder="dd/mm/yyyy"
            bsDatepicker
            [bsConfig]="generalDatePickerConfig"
            placement="right"
            [(ngModel)]="syncDate"
            readonly
            [bsConfig]="{ showClearButton: true }"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            *ngIf="syncDate"
            (click)="clearSyncDate()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          >
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="startdate" class="form-label"> From Date </label>
        <div class="input-group">
          <input
            type="text"
            id="startdate"
            name="startdate"
            class="form-control datepicker-readonly"
            placeholder="dd/mm/yyyy"
            bsDatepicker
            [bsConfig]="generalDatePickerConfig"
            placement="right"
            [(ngModel)]="startDate"
            readonly
            [bsConfig]="{ showClearButton: true }"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            *ngIf="startDate"
            (click)="clearStartDate()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          >
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="enddate" class="form-label"> To Date </label>
        <div class="input-group">
          <input
            type="text"
            id="startdate"
            name="startdate"
            class="form-control datepicker-readonly"
            placeholder="dd/mm/yyyy"
            bsDatepicker
            [bsConfig]="generalDatePickerConfig"
            placement="left"
            [(ngModel)]="endDate"
            readonly
            [bsConfig]="{ showClearButton: true }"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            *ngIf="endDate"
            (click)="clearEndDate()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }"
          >
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive" matSort>
      <table class="table table-responsive table-borderless">
        <thead class="table-header">
          <tr>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="userid">
              User ID
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="syncdate"
            >
              Sync Date
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="startdate"
            >
              From Date
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="enddate">
              To Date
            </td>
          </tr>
        </thead>
        <tbody *ngIf="!synchisloading">
          <tr *ngIf="historyList.length == 0">
            <td colspan="10" class="nodata">Empty</td>
          </tr>
          <tr *ngFor="let item of historyList">
            <td *ngIf="item == null" class="td-data">
              <span>Empty</span>
            </td>
            <td class="td-data">
              <span [title]="item.userid">{{ item.userid }} </span>
            </td>
            <td class="td-data">
              <span [title]="allinoneService.formatDBToShow(item.syncdate)"
                >{{ allinoneService.formatDBToShow(item.syncdate) }}
              </span>
            </td>
            <td class="td-data">
              <span [title]="allinoneService.formatDBToShow(item.startdate)">{{
                allinoneService.formatDBToShow(item.startdate)
              }}</span>
            </td>
            <td class="td-data">
              <span [title]="allinoneService.formatDBToShow(item.enddate)">{{
                allinoneService.formatDBToShow(item.enddate)
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>
</div>
