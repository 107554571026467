import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-member-dashboard',
  templateUrl: './member-dashboard.component.html',
  styleUrls: ['./member-dashboard.component.scss'],
})
export class MemberDashboardComponent implements OnInit {
  orgid: string = '';
  isAdmin: boolean = false;
  isHrAdmin: boolean = false;
  memberMenus: any[] = [];
  memberSubMenus: any[] = [];
  menuTitle: string = '';
  menuReferenceTitle: string = 'References';

  constructor(
    private router: Router,
    private allinoneService: AllInOneService,
    private kunyekService: KunyekService
  ) {
    var a: string = 'false';
    var hra: string = 'false';
    var d = this.allinoneService.orgsCRm.findIndex((x: any) => x.orgid == this.allinoneService.selectedOrg);
    if (d > -1) {
      a = this.allinoneService.orgsCRm[d].admin;
      hra = this.allinoneService.orgsCRm[d].hradmin;
    }
    this.isAdmin = a == 'true';
    this.isHrAdmin = hra == 'true';
  }

  ngOnInit(): void {
    this.kunyekService.getRoutePolicy({ isAdmin: this.isAdmin }).subscribe(
      (res: any) => {
        const allMenus = res.datalist;
        const currentUrl = this.router.url;
        if (this.isAdmin && this.isHrAdmin) {
          switch (currentUrl) {
            case '/members':
              this.menuTitle = "Member";
              this.memberMenus = allMenus.filter((menu: any, index: number) => [0, 1, 2].includes(index));
              break;
            case '/payment':
              this.menuTitle = "Payment";
              this.memberMenus = allMenus.filter((menu: any, index: number) => [3, 4].includes(index));
              this.memberSubMenus = allMenus.filter((menu: any, index: number) => [5].includes(index));
              break;
            case '/swim':
              this.menuTitle = "Swim";
              this.memberMenus = allMenus.filter((menu: any, index: number) => [6, 7, 8, 9].includes(index));
              this.memberSubMenus = allMenus.filter((menu: any, index: number) => [10].includes(index));
              break;
            case '/gym':
              this.menuTitle = "Gym";
              this.memberMenus = allMenus.filter((menu: any, index: number) => index === 11);
              break;
            case '/leave':
              this.menuTitle = "Leave";
              this.memberMenus = allMenus.filter((menu: any, index: number) => index === 12);
              break;
            default:
              this.memberMenus = [];
              break;
          }
        } else {
          this.menuTitle = "Member";
          this.memberMenus = allMenus;
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  goRoute(i: any, path: string): void {
    this.router.navigateByUrl(path);
  }
}
