import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { QrSetupComponent } from '../qr-setup/qr-setup.component';
import { ViewQrComponent } from '../view-qr/view-qr.component';

@Component({
  selector: 'app-qr-list-view',
  templateUrl: './qr-list-view.component.html',
  styleUrls: ['./qr-list-view.component.scss'],
})
export class QrListViewComponent implements OnInit, OnDestroy {
  isMobileView: boolean = false;
  isFocus: boolean = false;
  qrListLoading: boolean = false;

  subscription = new SubSink();

  qrList: any[] = [];
  organizations: any[] = [];

  orgid: string = '';
  searchText: string = '';
  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  p: number = 1;

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
    {
      name: 'All items',
      count: 0,
    },
  ];

  typeList = [
    {
      name: 'Card',
      id: '001',
    },
    {
      name: 'Menu',
      id: '002',
    },
    {
      name: 'Documents',
      id: '003',
    },
    {
      name: 'Data',
      id: '004',
    },
    {
      name: 'Digital Assets',
      id: '005',
    },
    {
      name: 'Digital ID',
      id: '006',
    },
  ];

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
    if(this.allinoneService.isQrModule){
      this.allinoneService.isCRMServiceView = true;
    }
    // if (
    //   !this.allinoneService.isAdminOf('org')
    // ) {
    //   this.router.navigateByUrl('/home');
    // }
    // this.allinoneService.isCRMServiceView = true;
  }
  ngOnDestroy(): void {
    // this.allinoneService.isCRMServiceView = false;
  }

  ngOnInit(): void {
  
    if (this.allinoneService.isQrModule) {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid;
    } else {

      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    }
    console.warn(this.allinoneService.hash(this.orgid));
    this.getQrList();
  }

  getQrList() {
    this.qrListLoading = true;
    this.subscription.sink = this.kunyekService
      .getPersonalQR(this.orgid, this.allinoneService.isQrModule)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.qrList = res.datalist.map((item: any) => ({
              ...item,
              // typetodisplay: item.type == '001' ? 'Card' : 'Menu',
              typetodisplay: this.typeList.find((i) => i.id == item.type)?.name,
              filetype: item.filename.split('.').pop(),
            }));
            this.pg[3].count = this.qrList.length;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.qrListLoading = false;
        },
        (err) => {
          this.qrListLoading = false;
        }
      );
  }

  openSetupForm(data?: any) {
    const dialog = this.dialog.open(QrSetupComponent, {
      width: '700px',
      maxHeight: '95vh',
      maxWidth: '95vw',
      closeOnNavigation: true,
      autoFocus: false,
      panelClass: ['mat-typography'],
      data: {
        orgid: this.orgid,
        isEditing: data ? true : false,
        qrdata: data,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (data) {
          data.filename = dialogResult.filename;
          data.path = dialogResult.path;
          data.description = dialogResult.description;
          data.qrurl = dialogResult.qrurl;
          data.accessuserid = dialogResult.accessuserid;
          data.contactfile = dialogResult.contactfile;
          data.profileimage = dialogResult.profileimage;
          data.profileimageurl = dialogResult.profileimageurl;
          data.orgname = dialogResult.orgname;
          data.cardtype = dialogResult.cardtype;
          data.name = dialogResult.name;
          data.post = dialogResult.post;
          data.mobile = dialogResult.mobile;
          data.workphone = dialogResult.workphone;
          data.email = dialogResult.email;
          data.workemail = dialogResult.workemail;
          data.company = dialogResult.company;
          data.department = dialogResult.department;
          data.website = dialogResult.website;
          data.location = dialogResult.location;
          data.googlemap = dialogResult.googlemap;
          data.linkedin = dialogResult.linkedin;
          data.facebook = dialogResult.facebook;
          data.messenger = dialogResult.messenger;
          data.whatsapp = dialogResult.whatsapp;
          data.instagram = dialogResult.instagram;
          data.viber = dialogResult.viber;
          data.telegram = dialogResult.telegram;
          data.template = dialogResult.template;
          data.uploadfilename = dialogResult.uploadfilename;
        } else {
          this.qrList.push(dialogResult);
          this.pg[3].count = this.qrList.length;
        }
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.p = 1;
  }

  sortData(sort: Sort) {
    const data = this.qrList;
    if (!sort.active || sort.direction === '') {
      this.qrList = data;
      return;
    }

    this.qrList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'type':
          return this.allinoneService.compare(
            a.typetodisplay,
            b.typetodisplay,
            isAsc
          );
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'userid':
          return this.allinoneService.compare(a.userid, b.userid, isAsc);
        case 'organization':
          return this.allinoneService.compare(
            a.organization,
            b.organization,
            isAsc
          );
        case 'contact':
          return this.allinoneService.compare(a.contact, b.contact, isAsc);
        default:
          return 0;
      }
    });
  }

  openQR(qrurl: string) {
    const dialog = this.dialog.open(ViewQrComponent, {
      width: '350px',
      maxWidth: '95vw',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        qrurl: qrurl,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  deleteQr(id: string, index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.qrListLoading = true;
        const data = {
          id: id,
          orgid: this.orgid,
        };
        this.kunyekService.deletePersonalQR(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.qrList.splice(index, 1);
              this.pg[3].count -= 1;
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.qrListLoading = false;
          },
          (err) => {
            this.qrListLoading = false;
          }
        );
      }
    });
  }
}
