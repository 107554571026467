import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { CustomerContactsComponent } from '../customer-contacts/customer-contacts.component';
import { MessageService } from './../../shared/services/message.service';

@Component({
  selector: 'app-crm-customer-info',
  templateUrl: './crm-customer-info.component.html',
  styleUrls: ['./crm-customer-info.component.css']
})
export class CrmCustomerInfoComponent implements OnInit {
  isMobileView: boolean = false;
  mobileViewWidth: number = 426;

  @HostListener('window:resize', ['$event'])

  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;

    }

  }
  @ViewChild('hiddenpdffileinput') hiddenpdffileinput: any;
  customerdetail: any = [];
  contactsList: any = [];
  orgcontactList:any= [];
  isediting: boolean = false;

  saveLoading: boolean = false;
  cuFormSubmitted: boolean = false;
  attachmentList: any[] = [];
  deleteFileList: any[] = [];
  emailaccessList: any = [];
  customerlist: any = [];
  branchlist: any = [];
  orgid: any;
  branchname: any = "All";
  businessTypes = [
    {
      key: '001',
      value: 'Organization',
    },
    {
      key: '002',
      value: 'Person',
    },
  ];

  customerStatus = [
    {
      key: '001',
      value: 'Prospect',
    },
    {
      key: '002',
      value: 'Customer',
    },
  ];

  currencyType: any = [];

  statusList: any = [];
  stageList: any = [];
  editData: any;
  gettingData: boolean = false;
  edicheck: boolean = false;
  customercheck: boolean = false;

  cuForm = {
    "id": "",
    "name": "",
    "businesstype": "001",
    "phone": "",
    "email": "",
    "address1": "",
    "address2": "",
    "status": "001",
    "orgid": "",
    "contactlist": [],
    "deletecontactlist": [],
    "deletefilename": [],
    "deletedeallist": [],
    "industrytype": '',
  }
  constructor(public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    }
    var customerdata = this.allinoneService.getCustomer();
    console.log("l...");
    console.log(customerdata)
    console.log(">>>>>>>>> cust data")
    if (customerdata != '') {
      this.customercheck = true;
      if (customerdata.contacts.length > 0) {
        if (customerdata.contacts[0].role == '300') {
          this.edicheck = true;
        }
      }
      // this.emailaccessList = customerdata.contacts;

    } else {
      this.router.navigateByUrl('service');
    }


    this.getCustomersDetail();
  }

  getCustomersDetail() {
    this.gettingData = true;

    const getCustomercaredata = this.allinoneService.getCustomer();
    var custoid = ""
    if (getCustomercaredata != '') {
      // this.customercare  = true;
      custoid = getCustomercaredata.customerid

    }

    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      customerid: custoid

    };
    this.kunyekService.getCrmCustomerDetail(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          if (res.list.length > 0) {
            this.customerlist = res.list[0];
            this.gettingData = false;

          }
          //           contactaddress1: "MaharBandula Road, Yangon"
          // contactaddress2: ""
          // contactemail: "syt@mit.com.mm"
          // contactid: "3172611737"
          // contactmobile: "09976797936"
          // contactname: "U Soe Moe Thu"
          // contactpost: "Director"
          // emailaccess: "false"
          // role: null
          // selfservice: null
          for (let i of res.list) {
            this.customerdetail.push({
              title: 'Name',
              data: i.customername
            },
              {
                title: 'Email',
                data: i.email
              }
              , {
                title: 'Phone',
                data: i.mobile
              },
              {
                title: "Type",
                data: i.customertype == "001" ? "Organization" : "Person"

              },
              {
                title: "Industry Type"
                , data: i.industrytype
              },
              {
                title: 'Address',
                data: i.address1,
                address: i.address2
              }
            );
            this.contactsList = i.contacts;
            this.orgcontactList = i.contacts;
            this.branchlist = i.branchlist;
            this.cuForm.contactlist = i.contacts;
            for (let i = 0; i < this.cuForm.contactlist.length; i++) {
              if (this.cuForm.contactlist[i]['emailaccess'] == 'true') {
                this.emailaccessList.push(this.cuForm.contactlist[i]);
              }
            }
          }

          console.log(this.cuForm.contactlist);


        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  goCrm() {
    this.router.navigateByUrl('service')
  }

  submitCustomer() {
    this.cuFormSubmitted = true;
    if (this.cuForm.name && this.cuForm.phone) {
      this.saveCustomer();
    }
  }

  async saveCustomer() {

    this.saveLoading = true;

    const tempFileName = formatDate(
      new Date(),
      'yyyyMMddHH:mm:ss',
      'en-US'
    ).toString();

    var tempFileList = this.attachmentList
    this.attachmentList = []
    tempFileList.map((item: any) => {
      if (!item.fileurl) {
        this.attachmentList.push(item)
      }
    })

    var filepath = 'crm/' + (this.cuForm.id).toString()
    console.log(filepath)
    if (this.attachmentList.length > 0) {
      const upload_aws = await this.allinoneService.multipleUploadToAWS(
        this.attachmentList,
        filepath
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn'
        );
        return;
      }
    }

    const filenamelist: any = [];
    tempFileList.map((item: any) => {
      filenamelist.push(item.filename);
    });

    var data = {
      customername: this.cuForm.name,
      customertype: this.cuForm.businesstype,
      customermobile: this.cuForm.phone,
      customeremail: this.cuForm.email,
      customeraddress1: this.cuForm.address1,
      customeraddress2: this.cuForm.address2,
      status: this.cuForm.status,
      orgid: this.cuForm.orgid,
      filename: filenamelist,
      tmpfilename: tempFileName,
      domainid: this.allinoneService.getDomain().domainid,
      contactlist: this.cuForm.contactlist,
      deallist: [],
      deletecontactlist: this.cuForm.deletecontactlist,
      deletefilename: this.deleteFileList,
      industrytype: this.cuForm.industrytype,
      customerid: this.cuForm.id,
      deletedeallist: this.cuForm.deletedeallist
    };

    var fun = this.kunyekService.editCrmCustomer(data)


    console.log(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.isediting = false;
          this.saveLoading = false;
          this.customerdetail = [];
          this.contactsList = [];
          this.branchlist = [];
          // this.
          this.getCustomersDetail();
        } else {
          this.saveLoading = false;
          this.isediting = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => {
        this.isediting = false;
        this.saveLoading = false;
      }
    );
  }
  cancelFunction() {
    this.isediting = false;
    // this.cancel.emit();
  }
  onFileSelect(event: any) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        // if (file.type == 'application/pdf') {
        var alreadySelect = false;
        this.attachmentList.map((item: any) => {
          if (item.filename == file.name) {
            alreadySelect = true;
          }
        });
        if (!alreadySelect) {
          var temp = {
            file: file,
            filename: file.name,
          };
          this.attachmentList.push(temp);
        }

      }
      this.hiddenpdffileinput.nativeElement.value = '';
    }
  }
  removeAttachment(index: number) {
    console.log(index);
    console.log("L>>Ll")
    if (this.attachmentList[index].fileurl) {
      this.deleteFileList.push(this.attachmentList[index].filename)
    }
    this.attachmentList.splice(index, 1);
    console.log(this.deleteFileList)
  }



  downloadFile(fileurl: any, filename: any) {
    window.open(fileurl,filename);
    this.http
      .get(fileurl, { responseType: 'blob' })
      .subscribe((response: any) => {
        saveAs(response, filename);
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }



  openContactForm() {
    const dialog = this.dialog.open(CustomerContactsComponent, {
      // width: '700px',
      // height: "550px",
      // closeOnNavigation: true,
      // disableClose: true,
      // // panelClass: ['event-modal', 'event-detail','mat-typography'],
      // panelClass: ['mat-typography', 'dialog-container-advansearch', 'crm-service-status-modal'],
      width: '700px',
      height: "550px",
      closeOnNavigation: true,
      disableClose: true,
      // panelClass: ['event-modal', 'event-detail','mat-typography'],
      panelClass: ['mat-typography', 'dialog-container-contact',],
      data: {
        'contactlist': this.cuForm.contactlist,
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      this.cuForm.contactlist = dialogResult.datalist
      this.cuForm.deletecontactlist = dialogResult.deletelist;
      this.emailaccessList = [];
      for (let i = 0; i < this.cuForm.contactlist.length; i++) {
        if (this.cuForm.contactlist[i]['emailaccess'] == 'true') {
          this.emailaccessList.push(this.cuForm.contactlist[i]);
        }
      }


    });
  }


  contactlist: any = []
  goEdit() {
    console.log(this.customerlist)
    if (this.customerlist) {
      this.contactlist = [];

      for (let i = 0; i < this.customerlist.contacts.length; i++) {
        this.contactlist.push({
          contactaddress1: this.customerlist.contacts[i].address1,
          contactaddress2: this.customerlist.contacts[i].address2,
          contactemail: this.customerlist.contacts[i].email,
          contactid: this.customerlist.contacts[i].contactid,
          contactmobile: this.customerlist.contacts[i].mobile,
          contactname: this.customerlist.contacts[i].contactname,
          contactpost: this.customerlist.contacts[i].post,
          emailaccess: this.customerlist.contacts[i].emailaccess,
          role: this.customerlist.contacts[i].role,
          selfservice: this.customerlist.contacts[i].selfservice,
        })
      }

      this.cuForm = {
        id: this.customerlist.customerid,
        orgid: this.customerlist.orgid,
        name: this.customerlist.customername,
        businesstype: this.customerlist.customertype,
        phone: this.customerlist.mobile,
        email: this.customerlist.email,
        address1: this.customerlist.address1,
        address2: this.customerlist.address2,
        status: this.customerlist.status,
        contactlist: this.contactlist,
        deletecontactlist: [],
        deletefilename: [],
        deletedeallist: [],
        industrytype: this.customerlist.industrytype,

      };
    }

    console.log(this.cuForm)
    this.attachmentList = this.customerlist.filelist;


    console.log(this.cuForm)
    console.log(this.attachmentList)

    this.isediting = true;
  }
  branchChanges(data: any) {
    if (data == 'all') {
      this.branchname = "All";
      this.contactsList = this.orgcontactList;
    } else {
      this.branchname = data.branchname;
      
      this.contactsList = this.orgcontactList.filter((x: any) => x.branchid == data.branchid);
      
      // for (let i = 0; i < this.contactsList.length; i++) {
      //   if (this.contactsList[i]['branchid'] == data.branchid) {
      //     contact.push({
      //       address1: this.contactsList[i]['address1'],
      //       address2: this.contactsList[i]['address2'],
      //       contactid: this.contactsList[i]['contactid'],
      //       contactname: this.contactsList[i]['contactname'],
      //       customerid: this.contactsList[i]['customerid'],
      //       email: this.contactsList[i]['email'],
      //       emailaccess: this.contactsList[i]['emailaccess'],
      //       mobile: this.contactsList[i]['mobile'],
      //       orgid:this.contactsList[i]['orgid'],
      //       post:this.contactsList[i]['post'],
      //       role:this.contactsList[i]['role'],
      //       selfservice:this.contactsList[i]['selfservice'],
      //       sortby:this.contactsList[i]['sortby']
      //     })
      //   }
      // }
    }
  }
}
