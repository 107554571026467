import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'crmFilter',
  pure: false,
})
export class CrmFilterPipe implements PipeTransform {

  transform(
    items: any[],
    searchTextService: string,
    searchStatus: string,
    searchType: string,
    type: any
  ) {
    if (!items) {
      return [];
    }

    // searchTextService = searchTextService.toLocaleLowerCase();
    switch (type) {
      case 1:
        if (!searchTextService) {
          searchStatus = searchStatus.toLocaleLowerCase();
          searchType = searchType.toLocaleUpperCase();

          returnItems = items.filter((it) => {
            return ((searchStatus == '' ? true : it.statusname.toString().toLocaleLowerCase() == searchStatus)
            );
          });
          if (returnItems) {
            return returnItems;
          } else {
            return [];
          }


        } else {
          console.log('2')

          searchTextService = searchTextService.toLocaleLowerCase();
          searchStatus = searchStatus.toLocaleLowerCase();
          searchType = searchType.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.name.toString().toLocaleLowerCase().includes(searchTextService) ||
              it.mobile.toString().toLocaleLowerCase().includes(searchTextService) ||
              it.statusname.toString().toLocaleLowerCase().includes(searchTextService) ||
              (it.createdbyname ? it.createdbyname : it.createdby)
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) &&
              (searchStatus == '' ? true : it.statusname.toString().toLocaleLowerCase() == searchStatus)

            );
          });
          return returnItems;
        }
      case 2:

        if (!searchTextService) {

          searchStatus = searchStatus.toLocaleLowerCase();
          searchType = searchType.toLocaleLowerCase();
          returnItems = items.filter((it) => {
            return (
              (searchStatus == 'service'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'project'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'priority'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'status'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'product'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'assignee'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true)

            );


          });
          if (returnItems) {
            return returnItems;
          } else {
            return [];
          }


        } else {


          searchTextService = searchTextService.toLocaleLowerCase();
          searchStatus = searchStatus.toLocaleLowerCase();
          searchType = searchType.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              (it.reportedbyname.toString().toLocaleLowerCase().includes(searchTextService)) &&

              (searchStatus == 'service'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'project'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'priority'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'status'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true) &&
              (searchStatus == 'assignee'
                ? it.type?.toString().toLocaleLowerCase() == searchStatus : true)
            );
          });
          return returnItems;
        }
      case 3:
        if (!searchTextService) {

          searchStatus = searchStatus.toLocaleLowerCase();
          searchType = searchType.toLocaleLowerCase();

          returnItems = items.filter((it) => {
            console.log('3')
            return ((searchStatus == '' ? true : it.statusname.toString().toLocaleLowerCase() == searchStatus) &&
              (searchType == '' ? true : it.customerid.toString().toLocaleLowerCase() == searchType));
          });
          if (returnItems) {
            return returnItems;
          } else {
            return [];
          }


        } else {
          console.log('4')

          searchTextService = searchTextService.toLocaleLowerCase();
          searchStatus = searchStatus.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {

            return (
              it.customername
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.customermobile
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.typename.toString().toLocaleLowerCase().includes(searchTextService) ||
              it.statusname.toString().toLocaleLowerCase().includes(searchTextService) ||
              (it.createdbyname ? it.createdbyname : it.createdby)
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) &&
              (searchStatus == '' ? true : it.statusname.toString().toLocaleLowerCase() == searchStatus) &&
              (searchType == '' ? true : it.customerid.toString().toLocaleLowerCase() == searchType)

            );
          });
          return returnItems;
        }
      case 4:
        if (!searchTextService) {

          searchStatus = searchStatus.toLocaleLowerCase();
          searchType = searchType.toLocaleLowerCase()

          returnItems = items.filter((it) => {
            return ((searchStatus == '' ? true : it.statusname.toString().toLocaleLowerCase() == searchStatus) &&
              (searchType == '' ? true : it.stagename.toString().toLocaleLowerCase() == searchType));
          });
          if (returnItems) {
            return returnItems;
          } else {
            return [];
          }


        } else {

          searchTextService = searchTextService.toLocaleLowerCase();
          searchStatus = searchStatus.toLocaleLowerCase();
          searchType = searchType.toLocaleLowerCase()
          var returnItems: any[];
          returnItems = items.filter((it) => {
            console.log('6')
            return (
              it.customername
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.stagename.toString().toLocaleLowerCase().includes(searchTextService) ||
              it.statusname.toString().toLocaleLowerCase().includes(searchTextService) ||
              it.targetdate.toString().toLocaleLowerCase().includes(searchTextService) ||
              it.closingdate
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              (it.createdbyname ? it.createdbyname : it.createdby)
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) &&
              (searchStatus == '' ? true : it.statusname.toString().toLocaleLowerCase() == searchStatus) &&
              (searchType == '' ? true : it.stagename.toString().toLocaleLowerCase() == searchType)

            );
          });
          return returnItems;
        }
      case 5:
        searchTextService = searchTextService.toLocaleLowerCase();
        var returnItems: any[];
        returnItems = items.filter((it) => {
          console.log('6')
          return (
            it.name
              .toString()
              .toLocaleLowerCase()
              .includes(searchTextService) ||
            it.servicedesc
              .toString()
              .toLocaleLowerCase()
              .includes(searchTextService) ||
            it.ticketno.toString().toLocaleLowerCase().includes(searchTextService) ||
            it.assignedtoname.toString().toLocaleLowerCase().includes(searchTextService) ||
            it.reportedbyname.toString().toLocaleLowerCase().includes(searchTextService)
          );
        });
        return returnItems;
      case 6:

        if (!searchTextService) {


          searchStatus = searchStatus.toLocaleLowerCase();
          return items;
        } else {

          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            console.log('6')
            return (
              it.skucode
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
              ||
              it.picname.toString()
                .toLocaleLowerCase()
                .includes(searchTextService)


            );
          });
          return returnItems;

        }
      case 7:

        if (!searchTextService) {


          searchType = searchType.toLocaleLowerCase();
          returnItems = items.filter((it) => {

            return ((searchType == '' ? true : it.picid.toString().toLocaleLowerCase() == searchType))
          });
          if (returnItems) {
            return returnItems;
          } else {
            return [];
          }



        } else {

          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {

            return (
              it.userid
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.picname
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems;

        }
      case 8:
        searchTextService = searchTextService.toLocaleLowerCase();
        var returnItems: any[];
        returnItems = items.filter((it) => {
          return (
            // it.userid
            //   .toString()
            //   .toLocaleLowerCase()
            //   .includes(searchTextService) ||
            it.name.toString().toLocaleLowerCase().includes(searchTextService)

          );
        });
        return returnItems
      case 9:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {

            return (

              it.name.toString().toLocaleLowerCase().includes(searchTextService)

            );
          });
          return returnItems
        } else {
          return items
        }
      case 10:

        searchTextService = searchTextService.toLocaleLowerCase();
        var returnItems: any[];
        returnItems = items.filter((it) => {
          return (
            it.userid
              .toString()
              .toLocaleLowerCase()
              .includes(searchTextService) ||
            it.name.toString().toLocaleLowerCase().includes(searchTextService)

          );
        });
        return returnItems
      case 11:
        searchTextService = searchTextService.toLocaleLowerCase();
        var returnItems: any[];
        returnItems = items.filter((it) => {

          return (
            it.customermobile.toString().toLocaleLowerCase().includes(searchTextService) ||
            it.customername.toString().toLocaleLowerCase().includes(searchTextService) ||
            it.customeremail.toString().toLocaleLowerCase().includes(searchTextService)
          );
        });
        return returnItems


      case 12:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.contactmobile
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.contactname.toString().toLocaleLowerCase().includes(searchTextService) ||
              it.contactemail.toString().toLocaleLowerCase().includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 13:

        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();

          var returnItems: any[];

          returnItems = items.filter((it) => {
            // console.log(it)
            return (
              it.username
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
              ||
              it.userid.toString().toLocaleLowerCase().includes(searchTextService)

            );
          });
          return returnItems
        } else {
          return items

        }
      case 14:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          return items
        }
      case 15:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          return items
        }
      case 16:
        if (searchTextService != undefined) {

          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.customername
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 17:
        if (searchTextService != undefined) {

          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {

            return (
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 18:
        if (searchTextService != undefined) {

          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 19:
        if (searchTextService != undefined) {

          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 20:
        if (searchTextService != undefined) {

          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }

      case 21:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {

            return (
              it.skucode
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.picname
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)

            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }

      case 22:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }

      case 23:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (

              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)


            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 24:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (

              it.name
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.cusotmernames
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)



            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 25:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.branchname
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 26:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.employeeid
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService) ||
              it.username
                .toString()
                .toLocaleLowerCase()
                .includes(searchTextService)
            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
      case 27:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
            return (
              it.toString()
                .toLocaleLowerCase()
                .includes(searchTextService)

            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }
        case 28:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
          console.log(it);

            return (
              it.skucode.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.name.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.picname.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.createdbyname.toString().toLocaleLowerCase()
                .includes(searchTextService)

            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }

      case 29:
        if (searchTextService != undefined) {
          searchTextService = searchTextService.toLocaleLowerCase();
          var returnItems: any[];
          returnItems = items.filter((it) => {
          console.log(it);

            return (
              it.customerid.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.customername.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.customermobile.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.customeremail.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.createdbyname.toString().toLocaleLowerCase()
                .includes(searchTextService) ||
              it.statusname.toString().toLocaleLowerCase()
                  .includes(searchTextService)

                

            );
          });
          return returnItems
        } else {
          console.log("2")
          return items
        }



      // 

      //Simple name search
      default:
        returnItems = items.filter((it) => {
          return it.name.toString().toLocaleLowerCase().includes(searchTextService);
        });
        return returnItems;

      // assignedto
    }




  }


}