import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IMyDpOptions } from 'mydatepicker';
import { KMemberSelectComponent } from 'src/app/shared/components/k-member-select/k-member-select.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-guest-detail',
  templateUrl: './guest-detail.component.html',
  styleUrls: ['./guest-detail.component.scss']
})
export class GuestDetailComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  isSaving: boolean = false;
  guestInitialForm!: FormGroup;
  guestDialogForm!: FormGroup;
  mainFormSaved = false;
  dialogFormSaved = false;
  requiredMessage = 'This field must not be empty';
  breadcrumbs: any[] = [
    {name:'Swim',url:'swim',isActive:true},
    {name:'Guest',url:'members/guestlist',isActive:true},
    {name:'New',url:'',isActive: false},
  ];
  editGuestIndex: number | null = null;
  id!:string
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private allInOneService: AllInOneService,
    private router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(value => this.id = value.get('id') || '');
    this.initializeForm();
    this.initializeGuestDialogForm();
    this.getOneGuest()
  }
  

  initializeForm() {
    this.guestInitialForm = this.fb.group({
      syskey: ['',Validators.required],
      member: [{ value: '', disabled: true }, Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      member_syskey: ['', Validators.required],
      id: [''],
      guests: this.fb.array([])
    });
  }
  getOneGuest() {
    this.kunyek.guestMember().get({ id: this.id }).subscribe({
      next: (res) => {
        const guestData = res.data;
        if (guestData) {
          this.guestInitialForm.patchValue({
            syskey: guestData.syskey,
            member_syskey: guestData.member_syskey,
            member:guestData.member.name,
            date:this.allInOneService.changeStringToDateobject(guestData.date),
            time: guestData.time
          })
          guestData.guests.map((g: any)=>this.addGuest({name:g.name,contactNo:g.contact_no}))
          this.breadcrumbs[2].name = guestData.member.name;
        }
      },
      error: (err) => {
        this.messageService.openSnackBar('Failed to fetch gym member details', 'warn');
      }
    });
  }

  initializeGuestDialogForm() {
    this.guestDialogForm = this.fb.group({
      name: ['', Validators.required],
      contactNo: ['', Validators.required]
    });
  }

  

  openGuestDialog(index: number | null = null) {
    this.editGuestIndex = index;
    if (index !== null) {
      const guest = this.guests.at(index) as FormGroup;
      this.guestDialogForm.patchValue(guest.value);
    } else {
      this.guestDialogForm.reset();
    }
    this.dialogFormSaved = false;
    const guestDialog: HTMLDialogElement | null = document.querySelector('#guestDialog');
    guestDialog?.showModal();
  }

  closeGuestDialog() {
    const guestDialog: HTMLDialogElement | null = document.querySelector('#guestDialog');
    guestDialog?.close();
  }

  saveGuest() {
    this.dialogFormSaved = true;
    if (this.guestDialogForm.valid) {
      if (this.editGuestIndex !== null) {
        this.guests.at(this.editGuestIndex).patchValue(this.guestDialogForm.value);
      } else {
        this.addGuest(this.guestDialogForm.value)
      }
      this.closeGuestDialog();
    }
  }
  addGuest(guest:any) {
    this.guests.push(this.fb.group(guest));

  }

  deleteGuest(index: number) {
    this.guests.removeAt(index);
  }

  onSaved() {
    this.isSaving = true;
    this.mainFormSaved = true;
    if (this.guestInitialForm.valid) {
      const formattedDate = this.date?.value.formatted || this.date?.value
      const formData = {
        syskey:this.syskey?.value,
        guests: this.guests?.value || undefined,
        member_syskey: this.member_syskey?.value,
        date: this.allInOneService.convertDate(formattedDate),
        time: this.time?.value,
      };
      this.kunyek.guestMember().update(formData).subscribe({
        next: (res) => {
          switch (res.returncode) {
            case "301" || "300":
              this.isSaving = false
              this.messageService.openSnackBar(res.message, 'warn')
              break;
            default:
              this.isSaving = false;
              this.messageService.openSnackBar('Success!', 'success')
              this.closeForm()

          }

        },
        error: (err) => {
          this.messageService.openSnackBar('Failed to save Guest', 'warn');
          this.isSaving = false;
        }
      });
   
   
    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false;
    }
  }

  closeForm() {
    // Close form logic
    if(this.router.url === '/members/guest-form') {
      this.router.navigate(['/members']);

    }else{
      this.router.navigate(['/members', 'guestlist']);
    }
  }
  
  
openMemberModal() {
  const dialog = this.dialog.open(KMemberSelectComponent, {
    height: '606px',
    width: '500px',
    closeOnNavigation: true,
    panelClass: ['user-profile-modal', 'mat-typography'],
    data: {
      field: 'member'
    },
  });
  dialog.afterClosed().subscribe((dialogResult: any) => {
    if (dialogResult) {
      this.guestInitialForm.patchValue({ member: dialogResult.data[0].name, member_syskey: dialogResult.data[0].syskey, id: dialogResult.data[0].userid });
      this.guestInitialForm.get('member')?.enable();
    }
  });
}

checkAndOpenMemberModal() {
  this.openMemberModal();
}

  get member() { return this.guestInitialForm.get('member'); }
  get date() { return this.guestInitialForm.get('date'); }
  get time() { return this.guestInitialForm.get('time'); }
  get name() { return this.guestDialogForm.get('name'); }
  get contactNo() { return this.guestDialogForm.get('contactNo'); }
  get member_syskey() { return this.guestInitialForm.get('member_syskey'); }
  get syskey() {return  this.guestInitialForm.get('syskey')}
  get guests(): FormArray {
    return this.guestInitialForm.get('guests') as FormArray;
  }
}
