<div class="cc-container">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <div class="label title">Add Relationship
      <span class="subtitle" *ngIf="insertType == 1">(Add Above)</span>
      <span class="subtitle" *ngIf="insertType == 2">(Add Between)</span>
      <!-- <span class="subtitle" *ngIf="insertType == 3">(Add Below)</span> -->
    </div>
    <div class="close-btn">
      <button mat-icon-button [disabled]="saveLoading" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <div *ngIf="!firstTime && hcForm.type == '1' && insertType != 1">
      <div class="mb-2">
        <label for="peid" class="form-label">Member
          <span *ngIf="isParentValid">({{selectedPEmployeeid}} - {{selectedPUserName ? selectedPUserName :
            selectedPUserid}})</span>
        </label>
        <input type="text" placeholder="0001, 0002" aria-label="Number" matInput class="form-control"
          (ngModelChange)="peidChange()" [matAutocomplete]="auto1" [(ngModel)]="searchText1" name="peid" id="peid"
          #peid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && peid.invalid }"
          [disabled]="disableParent || (gettingHierarchy && hcForm.type == '1')">
        <mat-progress-bar mode="indeterminate" *ngIf="gettingHierarchy && hcForm.type == '1'"></mat-progress-bar>
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngFor="let member of parentList | filter : searchText1:12 " [value]="member.employeeid"
            (onSelectionChange)="parentChange(member)" (click)="parentChange(member)">
            {{member.employeeid}} - {{member.username}} ({{member.userid}}{{member.newuserid ? ", " + member.newuserid : ''}})
          </mat-option>
        </mat-autocomplete>

        <div class="invalid-feedback" *ngIf="peid.invalid || (peid.dirty || peid.touched) && hcFormSubmitted">
          <div *ngIf="peid.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>

      </div>
      <div class="mb-2">
        <label for="pid" class="col-form-label">Position (Post , Department , Division)</label>

        <div>
          <select class="form-select" aria-label="Select Position" id="pid" name="pid" [(ngModel)]="hcForm.pid" required
            #pid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && pid.invalid }" [disabled]="disableParent">
            <option value="">-</option>
            <option *ngFor="let position of parentPositions; let index = index" [value]="position.positionid"
              [selected]="hcForm.pid == position.positionid">
              <span *ngIf="position.name && position.department && position.division">
                {{position.name}}, {{position.department}}, {{position.division}}
              </span>
              <span *ngIf="position.name && position.department && !position.division">
                {{position.name}}, {{position.department}}
              </span>
              <span *ngIf="position.name && !position.department && position.division">
                {{position.name}}, - , {{position.division}}
              </span>
              <span *ngIf="position.name && !position.department && !position.division">
                {{position.name}}
              </span>
            </option>
          </select>
        </div>
        <div class="invalid-feedback" *ngIf="pid.invalid || (pid.dirty || pid.touched) && hcFormSubmitted">
          <div *ngIf="pid.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mb-2"
        *ngIf="((firstTime && hcForm.type =='1')  || insertType == 1) || hcForm.type == '50' || hcForm.type == '51' || hcForm.type == '52' || hcForm.type == '53'">
        <label for="peid" class="form-label">Member
          <span *ngIf="isParentValid">({{selectedPEmployeeid}} - {{selectedPUserName ? selectedPUserName :
            selectedPUserid}})</span>
        </label>
        <input type="text" placeholder="0001, 0002" aria-label="Number" matInput class="form-control"
          (ngModelChange)="peidChange()" [matAutocomplete]="auto1" [(ngModel)]="searchText1" name="peid" id="peid"
          #peid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && peid.invalid }"
          [disabled]="disableParent || (gettingHierarchy && hcForm.type == '1')">
        <mat-progress-bar mode="indeterminate" *ngIf="gettingHierarchy && hcForm.type == '1'"></mat-progress-bar>
        <mat-autocomplete #auto1="matAutocomplete">
          <mat-option *ngFor="let member of members | filter : searchText1:12 " [value]="member.employeeid"
            (onSelectionChange)="parentChange(member)" (click)="parentChange(member)">
            {{member.employeeid}} - {{member.username}} ({{member.userid}}{{member.newuserid ? ", " + member.newuserid : ''}})
          </mat-option>
        </mat-autocomplete>

        <div class="invalid-feedback" *ngIf="peid.invalid || (peid.dirty || peid.touched) && hcFormSubmitted">
          <div *ngIf="peid.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>

      </div>
      <div class="mb-2" *ngIf="(firstTime && hcForm.type == '1') || insertType == 1">
        <label for="pid" class="col-form-label">Position (Post , Department , Division)</label>

        <div>
          <select class="form-select" aria-label="Select Position" id="pid" name="pid" [(ngModel)]="hcForm.pindex"
            required #pid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && pid.invalid }"
            [disabled]="disableParent">
            <option value="">-</option>
            <option *ngFor="let position of positions; let index = index" [value]="index.toString()"
              [selected]="hcForm.pindex == index.toString()">
              <span *ngIf="position.name && position.department && position.division">
                {{position.name}}, {{position.department}}, {{position.division}}
              </span>
              <span *ngIf="position.name && position.department && !position.division">
                {{position.name}}, {{position.department}}
              </span>
              <span *ngIf="position.name && !position.department && position.division">
                {{position.name}}, - , {{position.division}}
              </span>
              <span *ngIf="position.name && !position.department && !position.division">
                {{position.name}}
              </span>
            </option>
          </select>
        </div>
        <div class="invalid-feedback" *ngIf="pid.invalid || (pid.dirty || pid.touched) && hcFormSubmitted">
          <div *ngIf="pid.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div class="mb-2" *ngIf="insertType == 1 && this.hcForm.type == '1'">
        <label for="order" class="form-label">Order</label>
        <input type="number" class="form-control" id="order" name="order" placeholder="Enter order"
          [(ngModel)]="hcForm.order" required #order="ngModel"
          [ngClass]="{ 'is-invalid': hcFormSubmitted && order.invalid }"
          (keypress)="($event.charCode >= 48 && $event.charCode < 58)">

        <div class="invalid-feedback" *ngIf="order.invalid || (order.dirty || order.touched) && hcFormSubmitted">
          <div *ngIf="order.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
    </div>
    <div class="my-3 d-flex align-items-center">
      <div>is</div>
      <div class="mx-4">
        <select class="form-select" aria-label="Select type" id="type" name="type" (change)="typeChange($event)"
          [(ngModel)]="hcForm.type" required #type="ngModel" [disabled]="disableParent || disabledChild">
          <option *ngFor="let type of hierarchyTypes" [value]="type.id">
            {{type.name}}</option>
        </select>
      </div>
      <div>
        of
      </div>
    </div>
    <div class="form-check mb-3" *ngIf="hcForm.type == '1' || hcForm.type == '50' || hcForm.type == '51'">
      <input class="form-check-input td-edit" type="checkbox" id="primary" [(ngModel)]="hcForm.primary">
      <label class="form-check-label td-edit" for="primary">
        Primary
      </label>
    </div>
    <div class="mb-2">
      <label for="userid" class="form-label">Member
        <span *ngIf="isUserValid">({{selectedEmployeeid}} - {{selectedUserName ? selectedUserName :
          selectedUserid}})</span>
      </label>
      <input type="text" placeholder="0001, 0002" aria-label="Number" matInput class="form-control"
        (ngModelChange)="useridChange()" [matAutocomplete]="auto" [(ngModel)]="searchText" name="member" id="members"
        #userid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && userid.invalid }" [disabled]="disabledChild">

      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let member of members | filter : searchText:12 " [value]="member.employeeid"
          (onSelectionChange)="userChange(member)" (click)="userChange(member)">
          {{member.employeeid}} - {{member.username}} ({{member.userid}}{{member.newuserid ? ", " + member.newuserid : ''}})


        </mat-option>
      </mat-autocomplete>

      <div class="invalid-feedback" *ngIf="userid.invalid || (userid.dirty || userid.touched) && hcFormSubmitted">
        <div *ngIf="userid.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>

    </div>
    <div class="mb-2">
      <label for="cid" class="col-form-label">Position (Post , Department , Division)</label>

      <div>
        <select class="form-select" aria-label="Select Position" id="cid" name="cid" [(ngModel)]="hcForm.cindex"
          required #cid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && cid.invalid }"
          [disabled]="gettingPositionList || disabledChild">
          <option value="">-</option>
          <option *ngFor="let position of positions; let index = index" value="{{index}}"
            [selected]="hcForm.cindex == index.toString()">
            <span *ngIf="position.name && position.department && position.division">
              {{position.name}}, {{position.department}}, {{position.division}}
            </span>
            <span *ngIf="position.name && position.department && !position.division">
              {{position.name}}, {{position.department}}
            </span>
            <span *ngIf="position.name && !position.department && position.division">
              {{position.name}}, - , {{position.division}}
            </span>
            <span *ngIf="position.name && !position.department && !position.division">
              {{position.name}}
            </span>
          </option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingPositionList"></mat-progress-bar>

      </div>
      <div class="invalid-feedback" *ngIf="cid.invalid || (cid.dirty || cid.touched) && hcFormSubmitted">
        <div *ngIf="cid.errors?.required">
          {{allinoneService.input_err_msg}}
        </div>
      </div>
    </div>
  </div>
  <div class="mb-2" *ngIf="insertType != 1 && this.hcForm.type == '1'">
    <label for="order" class="form-label">Order</label>
    <input type="number" class="form-control" id="order" name="order" placeholder="Enter order"
      [(ngModel)]="hcForm.order" required #order="ngModel"
      [ngClass]="{ 'is-invalid': hcFormSubmitted && order.invalid }"
      (keypress)="($event.charCode >= 48 && $event.charCode < 58)">

    <div class="invalid-feedback" *ngIf="order.invalid || (order.dirty || order.touched) && hcFormSubmitted">
      <div *ngIf="order.errors?.required">
        {{allinoneService.input_err_msg}}
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center" *ngIf="insertType == 2">
    <div>
      <p>
        <span>Below member(s) - </span>
        <span><b>{{children.length}}</b></span>
      </p>
    </div>
    <button mat-stroked-button (click)="selectChildren()">Select member</button>
  </div>
</div>

<div class="btn-footer">
  <button class="btn btn-primary my-btn" type="button" (click)="submitHC()"
    [disabled]="!isUserValid || !isParentValid || saveLoading">
    <mat-icon *ngIf="!saveLoading">check</mat-icon>
    <div *ngIf="saveLoading" class="spinner-border" style="width: 25px; height: 25px;color: white;" role="status">
    </div>
  </button>
</div>