import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
// import { Label, Color } from 'ng2-charts';
import { range } from 'rxjs';
import { SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color } from 'ng2-charts';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { SubSink } from 'subsink/dist/subsink';
// import * as Chart from 'chart.js';
import { Chart } from 'chart.js';
import { CrmSummaryAdvansearchComponent } from '../../crm-summary-advansearch/crm-summary-advansearch.component';





@Component({
  selector: 'app-crm-summary',
  templateUrl: './crm-summary.component.html',
  styleUrls: ['./crm-summary.component.scss']
})
export class CrmSummaryComponent implements OnInit {
  @Input() orgid: any;
  @Output('crmcancel')
  cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output('summary')
  change: EventEmitter<any> = new EventEmitter<any>();
  @Output('statussummary')
  statuschange: EventEmitter<any> = new EventEmitter<any>();

  @Input() checkOrg: any;
  isAdmin: boolean = false;
  total: any = 0
  totalopen: any = 0
  new: any = 0
  close: any = 0
  projects: any = [];
  gettingSummary: any = true;
  projectsID: any = "";
  serviceTypeCard: any = [];
  productid: any = ''
  customers: any = [];
  branchlist: any = [];
  searchCus: any;
  customerid: any = '';
  branchid: any = '';
  custobranchid: any = "";
  customergroupid: any = "";
  totalary: any = [];
  enddate: any;
  startdate: any;
  balancestartdate: any;
  balanceenddate: any;
  showTableData: any = [];
  formatToShow = 'yyyy-MM-dd';
  formatToShowServcie = "MM/dd/yyyy"
  typevalue: any = '001';
  productListdata: any = [];
  productadmin: boolean = false;
  loadProject: boolean = false;
  loadProduct: boolean = false;
  customerview: boolean = false;
  contactadmin: boolean = false;
  contactid: any = "";
  getttingCustomer: boolean = false;
  projectsearch: any = '';
  productsearch: any = '';
  prioritysearch: any = "";
  customersearch: any = '';
  branchsearch: any = '';
  checkinternal: boolean = false;
  internalvalue: any = '';
  selectthree: any = 'Year';
  dateragetype: any = '001';
  selectcustomergroupname: any = "customergroup";
  customergroupsearch: any = "";

  text1: any = '';
  text2: any = '';


  isMobileView: boolean = false;
  mobileViewWidth: number = 426;
  private subs = new SubSink();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    } else {
      this.isMobileView = false;

    }
    console.log(window.innerWidth)
    console.log(this.mobileViewWidth)
    console.log(this.isMobileView + "okokoko")

  }



  typelist = [
    { "name": "Service Type", "code": "001" },
    { "name": "Status", "code": "002" },
  ]



  public barChartPlugins = [
    // pluginDataLabels

  ];

  // for chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        // stacked: true
      }], yAxes: [{
        // stacked: true
      }]
    },
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //         maxTicksLimit: 5,
    //       },
    //     },
    //   ],
    // },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 5,
        }
      }
    },
    animation: {
      // onComplete:function(){
      // const { ctx } = this.chart;
      // chart.data.datasets[0].data.forEach((datapoint: any, index: any) => {
      //   const datsetArray: any[] = [];
      // ctx.fillStyle = "black";
      // ctx.textAlign = "center";
      // ctx.textBaseline = "bottom";
      // chart.data.datasets.forEach(function(dataset:any) {
      //   for (var i = 0; i < dataset.data.length; i++) {
      //     for (var key in dataset._meta) {
      //       var model = dataset._meta[key].data[i]._model;
      //       ctx.fillText(dataset.data[i], model.x, model.y - 5);
      //     }
      //   }
      // });
      // }
      // }
    },
    legend: {
      // position: 'right',
      // labels: {
      //   fontSize: 13,
      //   fontFamily: 'Ubuntu',
      //   padding: 20,
      //   usePointStyle: true,
      // },
    },
  };

  // public barChartLabels: Label[] = [''];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  // public barChartPlugins = [];
  checkCustomer: boolean = false;
  checkBranch: boolean = false;
  showVal: any = "completedplus";
  checkdaterange: boolean = false;
  // public pieChartLabels: Label[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [
  ];
  public pieChartData: SingleDataSet = [];
  public pieChartColors: Array<any> = [];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };
  public pieChartColorsFirst: Array<any> =
    [
      '#cf0505', 'green', 'pink', 'teal', '#69F0AE'
      , 'lime', '#d1d105', 'indigo', 'amber', 'purple'
      , '#FF8D85',
      , 'orange',
      , '#B8255F',
      , '#4DD091',
      , '#95A5A6',
      , '#800000',
      , '#4DD0E1',
      , '#607D8B',
      , '#FF6E40',
      , '#AEEA00',
      , '#00695C',
      , '#F57F17'
    ];
  public barChartData: any = [];
  public barChartLabel: any = [];
  public barChartColors: Color[] = []
  public barChartColorsFirst: Color[] = [
    { backgroundColor: 'red' },
    { backgroundColor: '#2e86c1' },
    { backgroundColor: 'grey' },
    { backgroundColor: 'teal' },
    { backgroundColor: '#69F0AE' },
    { backgroundColor: 'lime' },
    { backgroundColor: 'yellow' },
    { backgroundColor: 'indigo' },
    { backgroundColor: 'amber' },
    { backgroundColor: 'purple' },
    { backgroundColor: '#FF8D85' },
    { backgroundColor: 'orange' },
    { backgroundColor: '#B8255F' },
    { backgroundColor: '#4DD091' },
    { backgroundColor: '#95A5A6' },
    { backgroundColor: '#800000' },
    { backgroundColor: '#4DD0E1' },
    { backgroundColor: '#607D8B' },
    { backgroundColor: '#FF6E40' },
    { backgroundColor: '#AEEA00' },
    { backgroundColor: '#00695C' },
    { backgroundColor: '#F57F17' }
  ];
  // public barChartCountColors: Color[] = [
  //   { backgroundColor: 'green' },
  //   { backgroundColor: 'red' },
  // ];

  getcheckinForm = {

    domain: this.allinoneService.getDomain().shortcode,
    domainid: this.allinoneService.getDomain().domainid,
    orgid: '',
    orgname: '',
    projindex: '',
    jobindex: '',
    subtype: 'Employee',
    userid: '',
  };
  productsmid: any = '';
  projectsmid: any = '';
  servicetypelist: any = [];
  serviceSearch: any = '';
  servicename: any = 'Type';
  servicesmid: any = '';
  priorityid: any = "";
  priority: any = [];
  customergrouplist: any = [];


  t: any = '';


  chartdata: any;


  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,

  ) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else if (window.innerWidth > this.mobileViewWidth) {
      this.isMobileView = false;
    }


    // var todayDate: Date = new Date();
    var d = new Date(new Date().getFullYear(), 0, 1);
    // var lastDate = moment(d).subtract(1, 'year').format('YYYY-MM-DD').toString();
    this.balancestartdate = formatDate(
      d,

      this.formatToShow,
      'en-US'
    ).toString();
    this.balanceenddate = formatDate(
      new Date(),

      this.formatToShow,
      'en-US'
    ).toString();

    this.isAdmin = (this.allinoneService.crmOrgAdmin === 'true');
    this.productid = this.route.snapshot.queryParamMap.get('productid') || "";
    this.projectsID = this.route.snapshot.queryParamMap.get('projectid') || "";
    this.priorityid = this.route.snapshot.queryParamMap.get('priorityid') || "";
    this.servicesmid = this.route.snapshot.queryParamMap.get('serviceid') || "";
    this.servicename = this.route.snapshot.queryParamMap.get('servicename') || "";
    this.customerid = this.route.snapshot.queryParamMap.get('customersmid') || "";
    this.branchid = this.route.snapshot.queryParamMap.get('branchid') || "";
    this.customergroupid = this.route.snapshot.queryParamMap.get('customergroupsmid') || "";

    this.searchData.startdate = this.route.snapshot.queryParamMap.get('startdate') || "";
    this.searchData.enddate = this.route.snapshot.queryParamMap.get('enddate') || "";


    // this.filtergetdata.productName = this.route.snapshot.queryParamMap.get('pname') || "";
    var productname = this.route.snapshot.queryParamMap.get('pname') || "";
    // this.filtergetdata.projectName = this.route.snapshot.queryParamMap.get('pjname') || "";
    var projectname = this.route.snapshot.queryParamMap.get('pjname') || "";
    // this.filtergetdata.customerName = this.route.snapshot.queryParamMap.get('cname') || "";
    var customername = this.route.snapshot.queryParamMap.get('cname') || "";
    var desc = this.route.snapshot.queryParamMap.get('description') || "";
    var recurring = this.route.snapshot.queryParamMap.get('recurring') || "";


    var status = this.route.snapshot.queryParamMap.get('status') || "";
    var bytype = this.route.snapshot.queryParamMap.get('bytype') || "";

    var daterange = this.route.snapshot.queryParamMap.get('daterange') || "";
    // daterange

    if (this.servicesmid == '') {

      this.servicename = 'Type'
    }


    if (daterange == '001') {
      // var todayDate: Date = new Date();
      var d = new Date(new Date().getFullYear(), 0, 1);
      // var lastDate = moment(d).subtract(1, 'year').format('YYYY-MM-DD').toString();
      this.balancestartdate = formatDate(
        d,
        this.formatToShow,
        'en-US'
      ).toString();
      this.selectthree = 'Year';
      this.dateragetype = '001';
    } else if (daterange == '002') {

      var threemonthdate = new Date();

      threemonthdate.setMonth(threemonthdate.getMonth() - 2);

      this.balancestartdate = formatDate(
        threemonthdate,

        this.formatToShow,
        'en-US'
      ).toString();
      this.selectthree = 'Quarterly';
      this.dateragetype = '002';
    } else if (daterange == '003') {
      this.selectthree = 'Monthly'
      this.dateragetype = '003';
      var monthdate = new Date(this.balanceenddate);
      var firstDay = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);
      console.log(firstDay)
      this.balancestartdate = formatDate(
        firstDay,
        this.formatToShow,
        'en-US'
      ).toString();

    }


    if (bytype == '001') {
      this.bytypename = 'By Types'
      this.viewType = '001';
    } else if (bytype == '002') {
      this.bytypename = 'By Products'
      this.viewType = '002';
    } else if (bytype == '003') {
      this.bytypename = 'By Projects'
      this.viewType = '003';
    } else if (bytype == "004") {
      this.bytypename = 'By Priority';
      this.viewType = "004";
    } else if (bytype == "005") {
      this.bytypename = 'By Customers';
      this.viewType = "005";
    }




    if (status == '') {
      // this.router.navigate([], {
      //   relativeTo: this.route,
      //   queryParams: {
      //     'status': '001',
      //   },
      //   queryParamsHandling: 'merge',
      // });
      this.showVal = 'wipplus';
      this.checkdaterange = false;
    } else {
      if (status == '003') {
        this.showVal = 'table';
        this.checkdaterange = true;

      } else if (status == '004') {
        this.showVal = 'chart';
        this.checkdaterange = false;
      } else if (status == '001') {
        this.showVal = 'completedplus';
        this.checkdaterange = false;
      } else {
        this.showVal = 'wipplus';
        this.checkdaterange = false;
      }
    }

    var checkinternal = this.route.snapshot.queryParamMap.get('checkinternal') || "";
    if (checkinternal) {
      if (checkinternal == '001') {
        console.log("1111111111..........")
        this.internalvalue = '001';
        this.internalname = "Internal";
        this.checkinternal = true;
        this.excludeinternal = false;
      } else if (checkinternal == '002') {
        console.log("222222222222222..........")
        this.internalvalue = '002';
        this.internalname = "Customers";
        this.checkinternal = true;
        this.excludeinternal = false;

      } else {
        this.internalvalue = '';
        this.internalname = "All";
        this.checkinternal = false;
        this.excludeinternal = false;
      }
    }




    if (recurring != 'undefined') {
      if (recurring) {
        console.log("22222222222")
        if (recurring == 'false') {
          console.log("333333333333")
          recurring = '';
        } else {
          console.log("4444444444")
          recurring = 'true';
        }
      }

    }
    var reb = this.route.snapshot.queryParamMap.get('reportedby') || "";
    var rebid = this.route.snapshot.queryParamMap.get('reportedbyid') || "";
    if (reb != 'undefined') {
      reb = reb
      rebid = rebid

    } else {
      reb = ''
      rebid = ''
    }
    var deta = this.route.snapshot.queryParamMap.get('detail') || "";

    if (deta != 'undefined') {
      deta = deta

    } else {
      deta = '';
    }

    var assignedtome = this.route.snapshot.queryParamMap.get('assignedtome') || "";
    if (assignedtome != 'false' && assignedtome != 'undefined') {
      assignedtome = assignedtome
    } else {
      assignedtome = ''
    }
    var picname = this.route.snapshot.queryParamMap.get('picName') || "";
    if (picname != 'undefined') {
      picname = picname
    } else {
      picname = ''
    }

    var picid = this.route.snapshot.queryParamMap.get('picid') || "";
    if (picid != 'undefined') {
      picid = picid
    } else {
      picid = ''
    }


    // this.searchData.description = '';
    // this.searchData.recurring = '';
    // this.searchData.detail = '';
    // this.searchData.reportedby = '';
    // this.searchData.reportedbyid = '';

    // this.searchData.picName = '';
    // this.searchData.picid = '';
    // this.searchData.assignedtome = false;
    this.searchData = {
      'description': desc,
      'productid': this.productid,
      'projectID': this.projectsID,
      'customerid': this.customerid,
      'productName': productname,
      'projectName': projectname,
      'customerName': customername,
      'recurring': recurring == 'true' ? true : '',
      'reportedby': reb,
      'reportedbyid': rebid,
      'detail': deta,
      'assignedtome': assignedtome ? true : false,
      'picName': picname,
      'picid': picid,
      "customergroupid": this.customergroupid,
      "branchid": this.branchid



    }
    console.log(this.searchData + "okokoko")


  }

  customername: any = '';
  chart: any;
  permission: any = [];
  async ngOnInit() {





    // console.log(datass);
    console.log("datatata.........jejfiwjlll>>>>>>>>>>");
    var customerdata = this.allinoneService.getCustomer();


    if (customerdata.customerid) {
      this.customerid = customerdata.customerid;
      this.branchid = customerdata.branchid;
      this.custobranchid = customerdata.branchid;
      this.customername = customerdata.customername;
      this.branchlist = customerdata.branchlist;
      var branchmid = this.route.snapshot.queryParamMap.get('branchid') || "";
      if (branchmid) {
        var type = this.branchlist.map((x: any) => x.branchid).indexOf(branchmid);
        this.branchid = this.branchlist[type].branchid;
        this.selectbranchname = this.branchlist[type].branchname;
      }
      console.log(this.customername + ">>>.")
      if (customerdata.contacts.length > 0) {
        console.log("***2")
        // console.log(this.customerdata.contacts[0].role);
        if (customerdata.contacts[0].role == '300') {
          console.log("***3")
          this.contactadmin = true;
        }
        this.contactid = customerdata.contacts[0].contactid
      }
      this.checkCustomer = true;
      this.customerview = true;


    }
    console.log(customerdata);


    if (customerdata == '') {

      if (this.allinoneService.crmOrgAdmin == 'true') {
      } else {
        this.permission = this.allinoneService.getCRMpermission();
        var type = this.permission.map((x: any) => x).indexOf('002');
        if (type == -1) {
          this.router.navigateByUrl('service')
        }
      }

    }

    // if(this.getCustomercaredata.contacts.length > 0){
    //    if(this.getCustomercaredata.contacts[0].selfservice == 'false'){
    //     this.router.navigateByUrl('sales')
    //    }
    // }



    this.enddate = formatDate(
      new Date(),
      this.formatToShow,
      'en-US'
    ).toString();
    var futureDate = new Date();
    console.log(">>>>>>");
    console.log(this.enddate);

    futureDate.setDate(futureDate.getDate() - 7);
    this.startdate = formatDate(
      new Date(),

      this.formatToShow,
      'en-US'
    ).toString();

    // balancestartdate

    // this.startdate = this.startdate;
    console.log(this.orgid);
    // this.getcheckinForm.orgid = this.orgid;

    this.organizations = this.allinoneService.orgsCRm;
    this.allinoneService.isCRMServiceView = true;
    if (!customerdata && customerdata == '') {
      this.getCustomers();
    }

    this.getProjects();
    this.getProduct();
    this.getServiceTypes();
    this.getSummary();
    await this.getPriorityList();
    this.getGroupCustomer();
    // this.getChart();




  }


  myChart: any;
  myChart1: any;



  dynamicColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ")";
    // var letters = '0123456789ABCDEF'.split('');
    // var color = '#';
    // for (var i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    //   console.log(color);
    // }
    // return color;
  }
  poolColors(a: any) {
    var pool = [];
    for (let i = 0; i < a; i++) {
      pool.push(this.dynamicColors());
    }
    return pool;
  }
  getChart() {
    console.log("label")
    console.log(this.barChartLabel);
    console.log(this.barChartData);
    const datass = {
      labels: this.barChartLabel,
      datasets: [{
        label: '',
        data: this.barChartData,
        backgroundColor: this.poolColors(this.barChartData.length),
        // backgroundColor: [
        //   // this.barChartColors
        //   // 'red',
        //   // '#2e86c1',
        //   // 'grey',
        //   // 'teal',
        //   // '#69F0AE',
        //   // 'lime',
        //   // 'yellow',
        //   // 'indigo',
        //   // 'amber',
        //   // 'purple',
        //   // '#FF8D85',
        //   // 'orange',
        //   // '#B8255F',
        //   // '#4DD091',
        //   // '#95A5A6',
        //   // '#800000',
        //   // '#4DD0E1',
        //   // '#607D8B',
        //   // '#FF6E40',
        //   // '#AEEA00',
        //   // '#00695C',
        //   // '#F57F17',


        // ],
        borderWidth: 1
      }]
    };

    if (this.myChart) {
      console.log(this.myChart.clear());
      this.myChart.destroy();
      console.log(this.myChart);

    }
    console.log("destroy1111");

    this.myChart = new Chart(
      'myChart',
      {
        type: 'horizontalBar',
        data: datass,
        options: {
          // maintainAspectRatio
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                fontSize: 16
              }
              ,
            },]
          },
          plugins: [
            // topLabels
          ]
        }
      }


    );

    if (this.myChart1) {
      console.log("destroy");
      console.log(this.myChart1.clear());
      this.myChart1.destroy();
      console.log(this.myChart1);

    }

    this.myChart1 = new Chart(
      'myChart1',
      {
        type: 'horizontalBar',
        data: datass,
        options: {
          // maintainAspectRatio
          maintainAspectRatio: false,
          legend: { display: false },
          scales: {
            xAxes: [{
              scaleLabel: {
                display: true,
                fontSize: 16
              }
              ,
            },]
          },
          plugins: [
            // topLabels
          ]
        }
      }

    );
    // chart.update();


    // subox.style.height = 300px;
  }
  organizations: any[] = [];

  customerSelect() {
    for (let i of this.customers) {
      if (this.customerid == i.customerid) {
        this.searchCus = i.customername;
        this.branchlist = i.branchlist;
      }
    }
  }

  getCustomers() {
    this.getttingCustomer = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,

      orgid: this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,
    };
    this.kunyekService.getCrmCustomers(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {

          this.customers = res.list;
          var customersmid = this.route.snapshot.queryParamMap.get('customersmid') || "";
          if (customersmid) {
            var type = this.customers.map((x: any) => x.customerid).indexOf(customersmid);
            console.log(type)
            console.log(this.customers)
            console.log(">>>>>>>>>>>>>>>>>>. customer")
            this.customerid = this.customers[type].customerid;
            this.selectcustomername = this.customers[type].customername;
            this.branchlist = this.customers[type].branchlist;
          }
          console.log("branchlist>>>>>>>");
          console.log(this.branchlist);
          var branchmid = this.route.snapshot.queryParamMap.get('branchid') || "";
          if (branchmid) {
            var type = this.branchlist.map((x: any) => x.branchid).indexOf(branchmid);
            this.branchid = this.branchlist[type].branchid;
            this.selectbranchname = this.branchlist[type].branchname;
          }
          this.getttingCustomer = false;

        } else {
          this.getttingCustomer = false;
        }
      },
      (err) => {
        this.getttingCustomer = false;
      }
    );
  }


  getPriorityList() {
    return new Promise((resolve) => {
      var data = {
        domainid: this.allinoneService.getDomain().domainid,
        orgid: this.allinoneService.selectedOrgCRM
          ? this.allinoneService.selectedOrgCRM
          : this.organizations[0].orgid,

      }
      this.kunyekService.getPriority(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {

            this.priority = res.list;
            var priorityid = this.route.snapshot.queryParamMap.get('priorityid') || "";
            if (priorityid) {
              var type = this.priority.map((x: any) => x.code).indexOf(priorityid);

              console.log(this.projects)
              this.priorityid = this.priority[type].code;
              this.priorityname = this.priority[type].name;
            }
            resolve('true')

          } else {
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('false')

          }
        },
        (err) => {
          resolve('false')
        }
      );
    });

  }
  customerChange() {
    this.customerid = '';
    this.searchCus = '';
  }

  goCrm() {
    this.router.navigateByUrl('service')
  }
  search() {

    if (this.startdate == '') {
      this.messageService.openSnackBar('Please select a valid Date.', 'warn')
      return;
    }
    if (this.enddate == '') {
      this.messageService.openSnackBar('Please select a valid Date.', 'warn')
      return;
    }
    if (this.balancestartdate == '') {
      this.messageService.openSnackBar('Please select a valid Date.', 'warn')
      return;
    }
    if (this.balanceenddate == '') {
      this.messageService.openSnackBar('Please select a valid Date.', 'warn')
      return;
    }

  

    if (this.searchCus == "") {
      this.customerid = "";
    }

    if (this.searchCus != "" && this.searchCus != undefined) {
      console.log(this.customerid);
      if (this.customerid == '' || this.customerid == undefined) {
        this.messageService.openSnackBar('Please select customer.', 'warn');
        return;
      }

    }

    if (this.isAdmin == false) {
      for (let i = 0; i < this.productListdata.length; i++) {
        if (this.productListdata[i].productid == this.productid) {
          if (this.productListdata[i].productadmins.length > 0) {
            this.productadmin = true;
          } else {
            this.productadmin = false;
          }
        }

      }
    }

    this.gettingSummary = true;
    this.getSummary();

  }


  barData: any = [];
  barDatastatus: any = [];
  // get summary data to show
  getSummary() {

    var status = this.route.snapshot.queryParamMap.get('status') || "";
    if (status == "") {
      status = "002";
    }


    this.total = 0;
    this.new = 0;
    this.close = 0;
    // this.barChartData = [];
    this.pieChartData = [];
    // this.pieChartLabels = [];
    this.gettingSummary = true;
    this.showTableData = [];


    var data = {
      startdate: this.showVal == 'table' ? this.balancestartdate : this.checkdate == true ? this.startdate : "",
      enddate: this.showVal == 'table' ? this.balanceenddate : this.enddate ? this.enddate : "",
      domainid: this.allinoneService.getDomain().domainid,
      customerid: this.customerid ? this.customerid : "",
      orgid: this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,
      admin: this.isAdmin == true ? true : false,
      porojectid: this.projectsID,
      productid: this.productid,
      productadmin: this.productadmin,
      customerview: this.customerview == true ? true : false,
      contactadmin: this.contactadmin == true ? true : false,
      contactid: this.contactid ? this.contactid : '',
      description: this.searchData.description ? this.searchData.description : '',
      detail: this.searchData.detail ? this.searchData.detail : "",
      reportedby: this.searchData.reportedbyid ? this.searchData.reportedbyid : '',
      recurring: this.searchData.recurring ? this.searchData.recurring : false,
      picid: this.searchData.picid ? this.searchData.picid : '',
      assignedtome: this.searchData.assignedtome ? this.searchData.assignedtome : '',
      viewinternalstatus: this.internalvalue,
      viewby: this.viewType,
      type: this.servicesmid,
      viewdaterangetype: this.dateragetype,
      priority: this.priorityid,
      viewtype: status,
      customergroupid: this.searchData.customergroupid ? this.searchData.customergroupid : '',
      branchid: this.branchid ? this.branchid : ""



    };
    // if (status == '') {
    //   // this.router.navigate([], {
    //   //   relativeTo: this.route,
    //   //   queryParams: {
    //   //     'status': '001',
    //   //   },
    //   //   queryParamsHandling: 'merge',
    //   // });
    //   this.showVal = 'wipplus';
    //   this.checkdaterange = false;
    // } else {
    //   if (status == '003') {
    //     this.showVal = 'table';
    //     this.checkdaterange = true;

    //  this.checkdaterange = false;
    //   } else if (status == '001') {
    //     this.showVal = 'completedplus';
    //     this.checkdaterange = false;
    //   } else {
    //     this.showVal = 'wipplus';
    //     this.checkdaterange = false;
    //   }
    // }

    this.subs.sink = this.kunyekService.getSummery(data).subscribe(
      (res: any) => {
        console.log(res);

        if (res.returncode == '300') {
          if (res.list.length > 0) {
            this.showTableData = [];
            if (this.viewType == '001') {
              console.log("11111111111");

              res.list.map((sum: any) => {
                this.showTableData.push({
                  "closed": sum.closed,
                  "closedcount": sum.closedcount,
                  "code": sum.code,
                  "completed": sum.completed,
                  "completedclosed": sum.completedclosed,
                  "completedcount": sum.completedcount,
                  "completedplus": sum.completedplus,
                  "new": sum.new,
                  "notcompleted": sum.notcompleted,
                  "others": sum.others,
                  "pending": sum.pending,
                  "percentvalue": sum.percentvalue,
                  "total": sum.total,
                  "totalforchart": sum.totalforchart,
                  "name": sum.typename,
                  "customerid": sum.customerid,
                  "typeshortcode": sum.typeshortcode,
                  "wip": sum.wip,
                  "open": sum.open ? sum.open + '@' + sum.average : "",
                  "critical": sum.critical,
                  "openpercent": sum.openpercent,
                  "wipcompleted": sum.wipcompleted,
                  "wipplus": sum.wipplus,
                  "wippluspercent": sum.wippluspercent,
                  "netnew": sum.netnew,
                  "openingbalance": sum.openingbalance,
                  "netnewpercent": sum.netnewpercent,
                  "openingbalancepercent": sum.openingbalancepercent,

                })
              });

            } else if (this.viewType == '002') {
              console.log("22222222222");
              res.list.map((sum: any) => {
                this.showTableData.push({
                  "closed": sum.closed,
                  "closedcount": sum.closedcount,
                  "code": sum.code,
                  "completed": sum.completed,
                  "completedclosed": sum.completedclosed,
                  "completedcount": sum.completedcount,
                  "completedplus": sum.completedplus,
                  "new": sum.new,
                  "notcompleted": sum.notcompleted,
                  "others": sum.others,
                  "pending": sum.pending,
                  "percentvalue": sum.percentvalue,
                  "total": sum.total,
                  "totalforchart": sum.totalforchart,
                  "name": sum.productname,
                  "productid": sum.productid,
                  "customerid": sum.customerid,
                  "typeshortcode": sum.typeshortcode,
                  "wip": sum.wip,
                  "open": sum.open ? sum.open + '@' + sum.average : "",
                  "critical": sum.critical,
                  "openpercent": sum.openpercent,
                  "wipcompleted": sum.wipcompleted,
                  "wipplus": sum.wipplus,
                  "wippluspercent": sum.wippluspercent,
                  "netnew": sum.netnew,
                  "openingbalance": sum.openingbalance,
                  "netnewpercent": sum.netnewpercent,
                  "openingbalancepercent": sum.openingbalancepercent,
                })
              });

            } else if (this.viewType == '003') {
              console.log("3333333333333")
              res.list.map((sum: any) => {
                this.showTableData.push({
                  "closed": sum.closed,
                  "closedcount": sum.closedcount,
                  "code": sum.code,
                  "completed": sum.completed,
                  "completedclosed": sum.completedclosed,
                  "completedcount": sum.completedcount,
                  "completedplus": sum.completedplus,
                  "new": sum.new,
                  "notcompleted": sum.notcompleted,
                  "others": sum.others,
                  "pending": sum.pending,
                  "percentvalue": sum.percentvalue,
                  "total": sum.total,
                  "totalforchart": sum.totalforchart,
                  "name": sum.projectname,
                  "customerid": sum.customerid,
                  "projectid": sum.projectid,
                  "typeshortcode": sum.typeshortcode,
                  "wip": sum.wip,
                  "open": sum.open ? sum.open + '@' + sum.average : "",
                  "critical": sum.critical,
                  "openpercent": sum.openpercent,
                  "wipcompleted": sum.wipcompleted,
                  "wipplus": sum.wipplus,
                  "wippluspercent": sum.wippluspercent,
                  "netnew": sum.netnew,
                  "openingbalance": sum.openingbalance,
                  "netnewpercent": sum.netnewpercent,
                  "openingbalancepercent": sum.openingbalancepercent,
                })
              });

            } else if (this.viewType == '004') {
              console.log("444444444444444")
              res.list.map((sum: any) => {
                this.showTableData.push({
                  "closed": sum.closed,
                  "closedcount": sum.closedcount,
                  "code": sum.code,
                  "completed": sum.completed,
                  "completedclosed": sum.completedclosed,
                  "completedcount": sum.completedcount,
                  "completedplus": sum.completedplus,
                  "new": sum.new,
                  "notcompleted": sum.notcompleted,
                  "others": sum.others,
                  "pending": sum.pending,
                  "percentvalue": sum.percentvalue,
                  "total": sum.total,
                  "totalforchart": sum.totalforchart,
                  "name": sum.priorityname,
                  "customerid": sum.customerid,
                  "priorityname": sum.priorityname,
                  "projectid": sum.projectid,
                  "typeshortcode": sum.typeshortcode,
                  "wip": sum.wip,
                  "open": sum.open ? sum.open + '@' + sum.average : "",
                  "critical": sum.critical,
                  "openpercent": sum.openpercent,
                  "wipcompleted": sum.wipcompleted,
                  "wipplus": sum.wipplus,
                  "wippluspercent": sum.wippluspercent,
                  "netnew": sum.netnew,
                  "openingbalance": sum.openingbalance,
                  "netnewpercent": sum.netnewpercent,
                  "openingbalancepercent": sum.openingbalancepercent,
                })
              });

            } else if (this.viewType == "005") {
              console.log("5555555555555555")
              res.list.map((sum: any) => {
                this.showTableData.push({
                  "closed": sum.closed,
                  "closedcount": sum.closedcount,
                  "code": sum.code,
                  "completed": sum.completed,
                  "completedclosed": sum.completedclosed,
                  "completedcount": sum.completedcount,
                  "completedplus": sum.completedplus,
                  "new": sum.new,
                  "notcompleted": sum.notcompleted,
                  "others": sum.others,
                  "pending": sum.pending,
                  "percentvalue": sum.percentvalue,
                  "total": sum.total,
                  "totalforchart": sum.totalforchart,
                  "name": sum.customername,
                  "customerid": sum.customerid,
                  "productid": sum.productid,
                  "typeshortcode": sum.typeshortcode,
                  "wip": sum.wip,
                  "open": sum.open ? sum.open + '@' + sum.average : "",
                  "critical": sum.critical,
                  "openpercent": sum.openpercent,
                  "wipcompleted": sum.wipcompleted,
                  "wipplus": sum.wipplus,
                  "wippluspercent": sum.wippluspercent,
                  "netnew": sum.netnew,
                  "openingbalance": sum.openingbalance,
                  "netnewpercent": sum.netnewpercent,
                  "openingbalancepercent": sum.openingbalancepercent,
                })
              });
            }
            this.showTableData.push({
              "name": "Total",
              "new": res.totalnew,
              "wip": res.totalwip,
              "pending": res.totalpending,
              // "others": res.totalothers,
              "wipplus": res.totalwipplus,
              "wippluspercent": res.totalwipluspercent,
              "completed": res.totalcompleted,
              "completedcount": res.totalcompletedcount,
              "closedcount": res.totalclosedcount,
              // "completedclosed":res.totalcompleted,
              "closed": res.totalclosed,
              "open": res.totalopen ? res.totalopen + '@' + res.totalaverage : "",
              "critical": res.totalcritical,
              "openpercent": res.totalopenpercent,
              "percentvalue": res.totalcompletedplus,
              "completedclosed": res.totalcompletedclosed,
              "total": res.total,
              "typeshortcode": "Total",
              "netnew": res.totalnetnew,
              "openingbalance": res.totalopeningbalance,
              "netnewpercent": res.totalnetnewpercent,
              "openingbalancepercent": res.totalopeningbalancepercent,
            })
            this.barData = [];
            this.barData = this.showTableData;
            this.barData = JSON.parse(JSON.stringify(this.barData));
            this.barData.splice(this.barData.length - 1, 1)
            console.log(this.barData);
            this.barChartData = [];
            this.barChartLabel = [];
            for (let data of this.barData) {
              this.barChartData.push(data.totalforchart);
              this.barChartLabel.push(data.name);
              // this.pieChartData.push(data.totalforchart);
              // this.pieChartLabels.push(data.name);
            }

            // else if (this.typevalue == '002') {
            //   this.barDatastatus = this.showTableData;
            //   this.barDatastatus = JSON.parse(JSON.stringify(this.barDatastatus))
            //   this.barDatastatus.splice(this.barDatastatus.length - 1, 1)
            //   for (let data of this.barDatastatus) {

            //     this.barChartData.push({ data: [data.totalforchart], label: data.statusname },);
            //     this.pieChartData.push(data.totalforchart);
            //     this.pieChartLabels.push(data.typename);
            //   }
            // }


            let pieColorData = [];
            for (let i = 0; i < this.pieChartColorsFirst.length; i++) {
              if (this.pieChartData.length > i) {
                pieColorData.push(this.pieChartColorsFirst[i]);
              }
            }

            // let setdata: any =
            //   { backgroundColor: '#2e86c1' };
            // this.barChartColors[this.barChartColors.length - 1] = setdata

            // let piesetdata: any = '#2e86c1';
            // pieColorData[pieColorData.length - 1] = piesetdata;
            this.pieChartColors.push({ backgroundColor: pieColorData });

          }


          console.log("okokokookokkokokkchchchch");

          // }
          this.gettingSummary = false;
          this.getChart();

        } else {

          this.gettingSummary = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingSummary = false;
      }
    );
  }


  summaryBackStatus(event: any) {

  }

  summaryBack(event: any) {

  }

  // get projects
  getProjects() {
    this.loadProject = true;


    return new Promise((resolve: any) => {
      this.projects = [];
      const data = {
        orgid: this.allinoneService.selectedOrgCRM
          ? this.allinoneService.selectedOrgCRM
          : this.organizations[0].orgid,
        domainid: this.allinoneService.getDomain().domainid,
        customerview: this.customerview ? true : false,
        customerid: this.customerid,
      };
      this.kunyekService.getAllCRMproject(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {

            // check by id
            var userid = this.allinoneService.getKunyekUserId();
            // if (this.isAdmin == false) {
            //   for (let i = 0; i < res.data.length; i++) {
            //     if (res.data[i]['memberstatus'] == '') {
            //       this.projects.push(res.data[i]);
            //       this.loadProject = false;
            //     }
            //     if (res.data[i]['memberstatus'] == '1') {
            //       for (let j = 0; j < res.data[i]['memberlist'].length; j++) {
            //         console.log(res.data[i]['memberlist'][j]['userid'] == userid);
            //         if (userid == res.data[i]['memberlist'][j]['userid']) {
            //           this.projects.push(res.data[i]);
            //         }
            //       }
            //     }
            //   }

            //   // if(this.projectsmid){
            //   //   var type = this.projects.map((x: any) => x.projectid).indexOf(this.projectsmid);

            //   //   console.log(this.productListdata)
            //   //   this.projectsID = this.projects[type].projectid;
            //   //   this.projectname = this.projects[type].name;
            //   // }

            //   this.projects = this.projects
            // } else {

            this.projects = res.data;
            this.loadProject = false;

            // }
            // this.getProjectId();
            var projectsID = this.route.snapshot.queryParamMap.get('projectid') || "";
            if (projectsID) {
              var type = this.projects.map((x: any) => x.projectid).indexOf(projectsID);

              this.projectsID = this.projects[type].projectid;
              this.projectname = this.projects[type].name;
            }

            this.loadProject = false;
          } else {
            this.loadProject = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          this.loadProject = false;
          resolve(false);
        }
      );
    });
  }

  getProduct() {

    this.loadProduct = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,

      "customerid": this.customerid ? this.customerid : '',
      "customerview": this.customerview == true ? true : false

    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.productListdata = res.list;
          var productid = this.route.snapshot.queryParamMap.get('productid') || "";
          if (productid) {
            var type = this.productListdata.map((x: any) => x.productid).indexOf(productid);

            this.productid = this.productListdata[type].productid;
            this.productname = this.productListdata[type].name;

          }


          this.loadProduct = false
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.loadProduct = false

        }
      },
      (err) => {
        this.loadProduct = false
      }
    );

  }


  // get summary data by projid
  OpenStatus(data: any, status: any) {
    console.log(data);
    var type = this.customers.map((x: any) => x.customerid).indexOf(this.customerid);
    var customer = '';

    if (type >= 0) {
      this.customers[type].customername;
    }
    let param = {
      "status": status,
      "list": data,
      "projectid": this.projectsID == '' ? '' : this.projectsID,
      "customer": customer,
    }
    console.log(param)
    this.statuschange.emit(param);


  }
  start: any = ""
  end: any = "";

  Open(data: any, status: any, typename?: any) {
    var type = "";
    var typecode = ""
    console.log(status);
    console.log(typename);


    var statuscode = ""
    console.log(data)
    if (data.projectid) {
      this.projectsID = data.projectid;
      this.projectname = data.name;
    }

    if (data.productid) {
      this.productid = data.productid;
      this.productname = data.name;
    }
    var priorityid = "";
    if (this.priorityid == "") {
      var prioirty = this.priority.map((x: any) => x.name).indexOf(data.priorityname);
      if (prioirty != -1) {
        priorityid = this.priority[prioirty]['code'];

      }
    } else {
      priorityid = this.priorityid
    }



    if (this.checkdate == false) {
      this.startdate = '';
    }
    if (this.startdate != "") {
      this.start = formatDate(
        this.startdate,
        this.formatToShowServcie,
        'en-US'
      ).toString();
    }

    if (this.enddate != "") {
      this.end = formatDate(
        this.enddate,
        this.formatToShowServcie,
        'en-US'
      ).toString();
    }

    console.log("startdate");
    console.log(this.startdate);

    var pname = '';
    if (this.productname == 'product') {
      pname = ''
    } else {
      pname = this.productname
    }
    var pjname = '';
    if (this.projectname == 'project') {
      pjname = '';

    } else {
      pjname = this.projectname
    }

    var cusname = '';

    if (this.selectcustomername == 'customer') {
      // if(data.customerid != ""){
      //   cusname = data.name;
      // }else{
      cusname = "";
      // }

    } else {
      cusname = this.selectcustomername;
    }


    var cusgroupname = "";
    if (this.selectcustomergroupname == "customergroup") {
      cusgroupname = "";
    } else {
      cusgroupname = this.selectcustomergroupname;
    }

    var cusbranchname = "";
    if (this.selectbranchname == "branch") {
      cusbranchname = "";
    } else {
      cusbranchname = this.selectbranchname;
    }




    var internal = '';
    console.log("ioooko" + this.checkinternal)


    var checkinternal = this.route.snapshot.queryParamMap.get('checkinternal') || "";
    if (checkinternal == '001') {
      internal = '001'
    } else if (checkinternal == '002') {
      internal = '002'

    } else {
      internal = ''
    }

    if (status == '1') {
      if (data.openingbalance != 0) {
        if (this.servicesmid) {
          typecode = this.servicesmid;
        } else {
          typecode = data.code
        }
        if (this.customerid) {
          this.customerid = this.customerid
        } else {
          cusname = data.customerid ? data.name : "";
          this.customerid = data.customerid ? data.customerid : "";
        }
        if (this.showVal == 'table') {

          if (this.balancestartdate) {

            this.start = formatDate(
              this.balancestartdate,
              this.formatToShowServcie,
              'en-US'
            ).toString();
            this.end = formatDate(
              this.balanceenddate,
              this.formatToShowServcie,
              'en-US'
            ).toString();
          }
        }

        statuscode = 'openingbalance'
        if (typecode == undefined) {
          this.router.navigateByUrl(`service/service-tickets?p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);

          // this.router.navigateByUrl(`sales/services?p=${statuscode}&productid=${this.productid}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}`);
          console.log("1")
        } else {
          console.log("2")

          this.router.navigateByUrl(`service/service-tickets?q=${typecode}&p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        }
      }
    }
    else if (status == '2') {
      console.log("test 2");
      console.log(this.start);
      console.log(this.end);
      if (this.customerid) {
        this.customerid = this.customerid
      } else {
        cusname = data.customerid ? data.name : "";
        this.customerid = data.customerid ? data.customerid : "";
      }
      if (this.showVal == 'table') {

        if (this.balancestartdate) {

          this.start = formatDate(
            this.balancestartdate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
          this.end = formatDate(
            this.balanceenddate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
        }
      }
      if (data.open != 0) {

        statuscode = 'wipplus'
        if (this.servicesmid) {
          typecode = this.servicesmid;
        } else {
          typecode = data.code
        }
        if (typecode == undefined) {
          this.router.navigateByUrl(`service/service-tickets?p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        } else {
          this.router.navigateByUrl(`service/service-tickets?q=${typecode}&p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        }
      }

    }
    else if (status == '3') {
      console.log("test 3");
      console.log(this.start);
      console.log(this.end);
      if (this.customerid) {
        this.customerid = this.customerid
      } else {
        cusname = data.customerid ? data.name : "";
        this.customerid = data.customerid ? data.customerid : "";
      }
      if (data.netnew != 0) {

        if (this.servicesmid) {
          typecode = this.servicesmid;
        } else {
          typecode = data.code
        }

        if (this.balancestartdate) {

          var monthdate = new Date(this.balanceenddate);
          var firstDay = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);

          this.start = formatDate(
            firstDay,
            this.formatToShow,
            'en-US'
          ).toString();
          // this.start = formatDate(
          //   this.balancestartdate,
          //   this.formatToShowServcie,
          //   'en-US'
          // ).toString();
          this.end = formatDate(
            this.balanceenddate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
        }
        statuscode = 'netnew'

        if (typecode == undefined) {
          this.router.navigateByUrl(`service/service-tickets?p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        } else {
          this.router.navigateByUrl(`service/service-tickets?q=${typecode}&p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);

        }
      }

    } else if (status == '5') {
      if (this.customerid) {
        this.customerid = this.customerid
      } else {
        cusname = data.customerid ? data.name : "";
        this.customerid = data.customerid ? data.customerid : "";
      }
      if (this.showVal == 'table') {

        if (this.balancestartdate) {

          this.start = formatDate(
            this.balancestartdate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
          this.end = formatDate(
            this.balanceenddate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
        }
      }
      if (data.closed != 0) {
        if (this.servicesmid) {
          typecode = this.servicesmid;
        } else {
          typecode = data.code
        }
        statuscode = 'closed'
        if (typecode == undefined) {
          this.router.navigateByUrl(`service/service-tickets?p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        } else {


          this.router.navigateByUrl(`service/service-tickets?q=${typecode}&p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);


        }
      }
    }
    else if (status == '6') {
      if (this.customerid) {
        this.customerid = this.customerid
      } else {
        cusname = data.customerid ? data.name : "";
        this.customerid = data.customerid ? data.customerid : "";
      }
      if (this.showVal == 'table') {

        if (this.balancestartdate) {

          this.start = formatDate(
            this.balancestartdate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
          this.end = formatDate(
            this.balanceenddate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
        }
      }
      if (data.total != 0) {
        console.log(data)
        console.log("....>>>>>>>>>.")
        if (this.servicesmid) {
          typecode = this.servicesmid
        } else {
          typecode = data.code
        }
        console.log(typecode);


        statuscode = 'total'

        if (typename == "Total") {
          console.log("123432true")
          this.router.navigateByUrl(`service/service-tickets?type=all&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);

        } else {

          if (typecode != undefined) {
            this.router.navigateByUrl(`service/service-tickets?type=all&q=${typecode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
          } else {
            this.router.navigateByUrl(`service/service-tickets?type=all&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
          }


        }
      }
    }
    else if (status == '7') {
      if (this.showVal == 'table') {

        if (this.balancestartdate) {

          this.start = formatDate(
            this.balancestartdate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
          this.end = formatDate(
            this.balanceenddate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
        }
      }
      if (data.completed != 0) {
        if (this.showVal == 'table') {

          if (this.balancestartdate) {

            this.start = formatDate(
              this.balancestartdate,
              this.formatToShowServcie,
              'en-US'
            ).toString();
            this.end = formatDate(
              this.balanceenddate,
              this.formatToShowServcie,
              'en-US'
            ).toString();
          }
        }
        statuscode = 'Completed'
        if (this.servicesmid) {
          typecode = this.servicesmid;
        } else {
          typecode = data.code
        }
        if (this.customerid) {
          this.customerid = this.customerid
        } else {
          cusname = data.customerid ? data.name : "";
          this.customerid = data.customerid ? data.customerid : "";
        }
        if (typecode == undefined) {
          this.router.navigateByUrl(`service/service-tickets?p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        } else {

          this.router.navigateByUrl(`service/service-tickets?q=${typecode}&p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);

        }
      }


    }
    else if (status == '8') {

      if (this.showVal == 'table') {

        if (this.balancestartdate) {

          this.start = formatDate(
            this.balancestartdate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
          this.end = formatDate(
            this.balanceenddate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
        }
      }

      statuscode = 'completedplus'
      if (this.servicesmid) {
        typecode = this.servicesmid;
      } else {
        typecode = data.code
      }
      if (this.customerid) {
        this.customerid = this.customerid
      } else {
        cusname = data.customerid ? data.name : "";
        this.customerid = data.customerid ? data.customerid : "";
      }
      if (typecode == undefined) {
        this.router.navigateByUrl(`service/service-tickets?p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
      } else {

        this.router.navigateByUrl(`service/service-tickets?q=${typecode}&p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);

      }



    } else if (status == '9') {
      if (this.showVal == 'table') {

        if (this.balancestartdate) {

          this.start = formatDate(
            this.balancestartdate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
          this.end = formatDate(
            this.balanceenddate,
            this.formatToShowServcie,
            'en-US'
          ).toString();
        }
      }
      if (data.open != 0) {

        statuscode = 'open'
        if (this.servicesmid) {
          typecode = this.servicesmid;
        } else {
          typecode = data.code
        }
        if (this.customerid) {
          this.customerid = this.customerid
        } else {
          cusname = data.customerid ? data.name : "";
          this.customerid = data.customerid ? data.customerid : "";
        }
        if (typecode == undefined) {
          this.router.navigateByUrl(`service/service-tickets?p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        } else {
          this.router.navigateByUrl(`service/service-tickets?q=${typecode}&p=${statuscode}&priorityid=${priorityid}&productid=${this.productid}&pname=${pname}&projectid=${this.projectsID}&pjname=${pjname}&customersmid=${this.customerid}&cname=${cusname}&startdate=${this.start}&enddate=${this.end}&description=${this.searchData.description}&recurring=${this.searchData.recurring}&detail=${this.searchData.detail}&reportedby=${this.searchData.reportedby}&reportedbyid=${this.searchData.reportedbyid}&assignedtome=${this.searchData.assignedtome}&picName=${this.searchData.picName}&picid=${this.searchData.picid}&checkinternal=${internal}&viewby=${this.viewType}&customergroupsmid=${this.customergroupid}&cgroupname=${cusgroupname}&customerbranchid=${this.branchid}&cbranchname=${cusbranchname}`);
        }
      }
    }


    console.log(data);

  }

  typeChanged() {


  }
  cancelFunction() {

    this.cancel.emit();
  }

  selected(data: any) {
    // this.typevalue = data;
    console.log(data.target.value);

    this.showVal = data.target.value;
    if (this.showVal == 'table') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'status': '003',
        },
        queryParamsHandling: 'merge',
      });
    } else if (this.showVal == 'chart') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'status': '004',
        },
        queryParamsHandling: 'merge',
      });
    } else if (this.showVal == 'completedplus') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'status': '001',
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'status': '002',
        },
        queryParamsHandling: 'merge',
      });
    }



    // this.getSummary();
    //

  }
  checkdate: boolean = false;
  // checkChange1(event: any) {
  //   if (event.target.checked == true) {
  //     this.checkdate = false;
  //   }
  // }
  datevalue: any = '1';
  checkChange2(event: any) {

    if (event.target.value == '1') {
      this.checkdate = false;
      this.datevalue = '1'
    } else if (event.target.value == '2') {
      this.checkdate = true;
      this.datevalue = '2'
    }

  }
  
  projectname: any = 'project';
  changeproject(data: any) {
    console.log(data);
    if (data == 'project') {
      this.projectsID = '';
      this.projectname = 'project';
      // this.router.navigate([], {
      //   relativeTo: this.route,
      //   // { queryParams: { projectid: this.projectsID } }
      //   { queryParams: { projectid: this.projectsID } }

      // );

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'projectid': this.projectsID,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();


    } else {
      this.projectsID = data.projectid;
      this.projectname = data.name;



      // this.router.navigate(
      //   ['/service/summary'],
      //   // { queryParams: { projectid: this.projectsID } }
      //   { queryParams: { projectid: this.projectsID, productid: this.productid } }

      // );
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'projectid': this.projectsID,
        },
        queryParamsHandling: 'merge',
      });

      this.getSummary();
    }



  }

  productname: any = 'product'
  changeproduct(data: any) {
    if (data == 'product') {
      this.productid = '';
      this.productname = 'product';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'productid': this.productid,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();
    } else {
      this.productid = data.productid
      this.productsmid = data.productid

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'productid': this.productid,
        },
        queryParamsHandling: 'merge',
      });
      this.productname = data.name;
      this.getSummary();
    }
  }
  priorityname: any = "priority";
  changepriority(data: any) {
    console.log("LLLLLLLLLLLLLLLLl");
    console.log(data);
    if (data == 'priority') {
      this.priorityid = '';


      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'priorityid': this.priorityid,
        },
        queryParamsHandling: 'merge',
      });
      this.priorityname = 'priority';

      this.getSummary();


    } else {
      this.priorityid = data.code
      this.priorityname = data.name;

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'priorityid': this.priorityid,
        },
        queryParamsHandling: 'merge',
      });
      this.productname = data.name;
      this.getSummary();
    }
  }



  selectcustomername: any = 'customer'
  changecustomer(data: any) {
    if (data == 'customer') {
      this.customerid = '';
      this.selectcustomername = 'customer';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'customersmid': this.customerid,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();
    } else {
      console.log(data);
      this.customerid = data.customerid;
      this.selectcustomername = data.customername;
      // this.customersmid = data.customerid

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'customersmid': this.customerid,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();
    }
  }

  selectbranchname: any = 'branch';
  changeBranch(data: any) {
    if (data == 'branch') {
      this.branchid = '';
      this.selectbranchname = 'branch';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'branchid': this.branchid,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();
    } else {
      console.log(data);
      this.branchid = data.branchid;
      this.selectbranchname = data.branchname;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'branchid': this.branchid,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();
    }
  }

  changecustomergroup(data: any) {
    if (data == 'customergroup') {
      this.customergroupid = '';
      this.selectcustomergroupname = 'customergroup';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'customergroupsmid': this.customergroupid,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();
    } else {
      console.log(data);
      this.customergroupid = data.groupid;
      this.selectcustomergroupname = data.name;

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'customergroupsmid': this.customergroupid,
        },
        queryParamsHandling: 'merge',
      });
      this.getSummary();
    }


  }

  datename: any = 'Date';
  changedate(data: any) {

    if (data == 'date') {
      this.checkdate = false;
      this.datevalue = '1';
      this.datename = "Date"

    } else if (data == 'between') {
      this.checkdate = true;
      this.datevalue = '2';
      this.datename = "Between"
    }


  }



  changeThree(data: any) {
    if (data == "year") {


      console.log("okokk")
      console.log(this.balancestartdate)
      // var todayDate: Date = new Date();
      // var lastDate = moment(todayDate).subtract(1, 'year').format('YYYY-MM-DD').toString();
      // var dateok = formatDate(
      //   d,
      //   this.formatToShow,
      //   'en-US'
      // ).toString();
      // console.log(dateok)
      // this.selectthree = 'Year';
      // this.dateragetype = '001';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'daterange': '001',
        },
        queryParamsHandling: 'merge',
      });

    } else if (data == "quarterly") {
      // var threemonthdate = new Date();

      // threemonthdate.setMonth(threemonthdate.getMonth() - 2);

      // this.balancestartdate = formatDate(
      //   threemonthdate,

      //   this.formatToShow,
      //   'en-US'
      // ).toString();
      // this.selectthree = 'Quarterly';
      // this.dateragetype = '002';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'daterange': '002',
        },
        queryParamsHandling: 'merge',
      });

    }
    else if (data == "monthly") {
      // this.selectthree = 'Monthly'
      // this.dateragetype = '003';
      // var monthdate = new Date(this.balanceenddate);
      // var firstDay = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);
      // console.log(firstDay)
      // this.balancestartdate = formatDate(
      //   firstDay,
      //   this.formatToShow,
      //   'en-US'
      // ).toString();
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'daterange': '003',
        },
        queryParamsHandling: 'merge',
      });
    }

    this.getSummary();



  }

  startdatechange() {
    if (this.balancestartdate != "") {

      if (this.dateragetype == '001') {


        // console.log(currentYear)
        // Date(year, 11, 31)
        const currentYear = new Date(this.balancestartdate).getFullYear();
        const lastDay = new Date(currentYear, 11, 31);
        // var todayDate = new Date();
        // todayDate.setMonth(todayDate.getMonth() + 12);
        this.balanceenddate = formatDate(
          lastDay,
          this.formatToShow,
          'en-US'
        ).toString();

      } else if (this.dateragetype == '002') {

        var threemonthdate = new Date(this.balancestartdate);
        threemonthdate.setMonth(threemonthdate.getMonth() + 2);
        this.balanceenddate = formatDate(
          threemonthdate,
          this.formatToShow,
          'en-US'
        ).toString();



      }
      else if (this.dateragetype == '003') {
        var dateObj = new Date(this.balancestartdate);
        var month = dateObj.getMonth() + 1;
        var year = dateObj.getFullYear();
        const getLastDay = moment().year(year).month(month - 1).daysInMonth();
        var sumdate = year + '-' + month + '-' + getLastDay
        this.balanceenddate = formatDate(
          sumdate,

          this.formatToShow,
          'en-US'
        ).toString();

      }
    }
  }
  enddatechange() {

    if (this.balanceenddate != "") {
      if (this.dateragetype == '001') {
        var todayDate = new Date(this.balanceenddate);
        var d = new Date(new Date(todayDate).getFullYear(), 0, 1);
        // var lastDate = moment(d).subtract(1, 'year').format('YYYY-MM-DD').toString();
        this.balancestartdate = formatDate(
          d,
          this.formatToShow,
          'en-US'
        ).toString();
        // var lastDate = moment(todayDate).subtract(1, 'year').format('YYYY-MM-DD').toString();
        // this.balancestartdate = formatDate(
        //   lastDate,
        //   this.formatToShow,
        //   'en-US'
        // ).toString();
      } else if (this.dateragetype == '002') {
        var threemonthdate = new Date(this.balanceenddate);
        threemonthdate.setMonth(threemonthdate.getMonth() - 2);
        this.balancestartdate = formatDate(
          threemonthdate,
          this.formatToShow,
          'en-US'
        ).toString();
      } if (this.dateragetype == '003') {
        var monthdate = new Date(this.balanceenddate);
        var firstDay = new Date(monthdate.getFullYear(), monthdate.getMonth(), 1);

        this.balancestartdate = formatDate(
          firstDay,
          this.formatToShow,
          'en-US'
        ).toString();
      }
    }

  }

  removeCust() {
    this.customersearch = '';
  }

  removeBranch() {
    this.branchsearch = '';
  }

  removeCustgroup() {
    this.customergroupsearch = ""
  }

  removeProJect() {
    this.projectsearch = '';

  }
  removeProduct() {

    this.productsearch = '';
  }

  removePriority() {
    this.prioritysearch = '';
  }

  searchData: any = [];
  AdvandSearchDate() {

    const dialog = this.dialog.open(CrmSummaryAdvansearchComponent, {
      height: 'auto',
      width: '400px',
      // maxWidth: this.isMobileView ? '250px' : "850",
      // minWidth: this.isMobileView ? '50px' : "850",
      closeOnNavigation: true,
      panelClass: ['mat-typography', 'dialog-container-advansearch'],
      data: {
        checkdate: this.checkdate,
        data: this.searchData
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(".>>>>>>>>");
        console.log(dialogResult)
        // q=0010&p=new&
        // &=&enddate=
        // description=undefined&
        // recurring=undefined&
        // detail=undefined&
        // reportedby=undefined&
        // reportedbyid=undefined&
        // assignedtome=undefined&
        // picName=undefined&
        // picid=undefined
        this.searchData = dialogResult.data;
        console.log(this.searchData)

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            'reportedbyid': this.searchData.reportedbyid,
            'detail': this.searchData.detail,
            'assignedtome': this.searchData.assignedtome,
            'picid': this.searchData.picid,
            'recurring': this.searchData.recurring,
            'description': this.searchData.description,
            'startdate': this.searchData.startdate,
            'enddate': this.searchData.enddate,
            'picName': this.searchData.picName,
            'reportedby': this.searchData.reportedby,
          },
          queryParamsHandling: 'merge',
        });


        this.getSummary();

      }
    });


  }

  closeAdvanSearch(data: any) {
    if (data == 1) {
      this.searchData.description = '';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'description': '',
        },
        queryParamsHandling: 'merge',
      });

    } else if (data == 2) {
      this.searchData.recurring = '';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'recurring': '',
        },
        queryParamsHandling: 'merge',
      });
    } else if (data == 3) {
      this.searchData.detail = '';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'detail': '',
        },
        queryParamsHandling: 'merge',
      });
    } else if (data == 4) {
      this.searchData.reportedby = '';
      this.searchData.reportedbyid = '';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'reportedbyid': '',
          'reportedby': ''

        },
        queryParamsHandling: 'merge',
      });
    } else if (data == 5) {
      this.searchData.picid = '';
      this.searchData.picName = '';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'picid': '',
          'picName': ''

        },
        queryParamsHandling: 'merge',
      });
    } else if (data == 6) {
      this.searchData.assignedtome = false;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'assignedtome': '',

        },
        queryParamsHandling: 'merge',
      });
    }

    this.getSummary();
  }
  removeSearch() {
    this.searchData.description = '';
    this.searchData.recurring = '';
    this.searchData.detail = '';
    this.searchData.reportedby = '';
    this.searchData.reportedbyid = '';
    this.searchData.picName = '';
    this.searchData.picid = '';
    this.searchData.assignedtome = false;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        'reportedbyid': '',
        'detail': '',
        'assignedtome': '',
        'picid': '',
        'recurring': '',
        'description': '',
        'startdate': '',
        'enddate': '',
        'picName': '',
      },

      queryParamsHandling: 'merge',
    });
    this.getSummary();
  }

  excludeinternal: boolean = false;
  internalname: any = "All";
  checkInternal(data: any) {

    if (data == 'all') {

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'checkinternal': '',
        },
        queryParamsHandling: 'merge',
      });


      this.checkinternal = false;
      this.excludeinternal = false;
    }
    else if (data == 'internal') {

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'checkinternal': '001',
        },
        queryParamsHandling: 'merge',
      });

      this.checkinternal = true;
      this.excludeinternal = false;
      this.internalname = 'Internal';
    } else if (data == 'exclude') {


      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'checkinternal': '002',
        },
        queryParamsHandling: 'merge',
      });
      this.checkinternal = false;
      this.excludeinternal = false;


    }

  }

  // checkExcludeInternal(data: any) {
  //   console.log(data.target.checked)
  //   if (data.target.checked == true) {
  //     this.checkinternal = false;
  //     this.excludeinternal = true;

  //     this.router.navigate([], {
  //       relativeTo: this.route,
  //       queryParams: {
  //         'excludeinternal': 'true',
  //         'checkinternal': '',
  //       },
  //       queryParamsHandling: 'merge',
  //     });


  //   } else {

  //     this.router.navigate([], {
  //       relativeTo: this.route,
  //       queryParams: {
  //         'excludeinternal': '',

  //       },
  //       queryParamsHandling: 'merge',
  //     });
  //     this.checkinternal = false;
  //     this.excludeinternal = false;

  //   }
  // }

  bytypename: any = 'By Types';
  viewType: any = '001';

  bytype(data: any) {
    if (data == 'type') {
      this.viewType = '001';
      this.bytypename = 'By Types';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'bytype': '001',
          'serviceid': '',
        },
        queryParamsHandling: 'merge',
      });
    } else if (data == 'product') {
      this.viewType = '002';

      this.bytypename = 'By Products';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'bytype': '002',
          'productid': '',
        },
        queryParamsHandling: 'merge',
      });
    } else if (data == 'project') {
      this.viewType = '003';
      this.bytypename = 'By Projects';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'bytype': '003',
          'projectid': ''
        },
        queryParamsHandling: 'merge',
      });
    }
    else if (data == 'priority') {
      this.viewType = '004';
      this.bytypename = 'By Priority';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'bytype': '004',
          'priorityid': ''
        },
        queryParamsHandling: 'merge',
      });
    } else if (data == 'customer') {
      this.viewType = "005";
      this.bytypename = 'By Customers';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'bytype': '005',
          'priorityid': ''
        },
        queryParamsHandling: 'merge',
      });
    }

    this.getSummary();

  }
  getServiceTypes() {
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,
    }
    this.kunyekService.getServiceType(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.servicetypelist = res.list;
          var serviceid = this.route.snapshot.queryParamMap.get('serviceid') || "";
          if (serviceid) {
            var type = this.servicetypelist.map((x: any) => x.code).indexOf(serviceid);

            console.log(this.productListdata)
            this.servicesmid = this.servicetypelist[type].code;
            this.servicename = this.servicetypelist[type].name;

          }

        } else {

          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {

      }
    );
  }
  changeservicetype(data: any) {

    if (data == 'Type') {
      this.servicename = 'Type';



      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'serviceid': '',
        },
        queryParamsHandling: 'merge',
      });

      this.getSummary();


    } else {
      this.servicesmid = data.code;

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'serviceid': this.servicesmid,
        },
        queryParamsHandling: 'merge',
      });
      this.servicename = data.name;


      this.getSummary();
    }
  }
  getGroupCustomer() {
    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.allinoneService.selectedOrgCRM
        ? this.allinoneService.selectedOrgCRM
        : this.organizations[0].orgid,
    };
    this.kunyekService.getcustomergroup(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.customergrouplist = [];
          res.list.map((item: any) => {
            this.customergrouplist.push({
              "groupid": item.groupid,
              "name": item.name == "" ? item.customerlist : item.name,
              "customerlist": item.customerlist,
              "cusotmernames": item.cusotmernames,

            })
          });
          var customergroupsmid = this.route.snapshot.queryParamMap.get('customergroupsmid') || "";
          if (customergroupsmid) {

            var type = this.customergrouplist.map((x: any) => x.groupid).indexOf(customergroupsmid);
            console.log(type)
            console.log(this.customers)
            this.customergroupid = this.customergrouplist[type].groupid;
            this.selectcustomergroupname = this.customergrouplist[type].name;
          }
        } else {

          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }
}




