import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-logistics-customer',
  templateUrl: './logistics-customer.component.html',
  styleUrls: ['./logistics-customer.component.scss'],
})
export class LogisticsCustomerComponent implements OnInit,OnDestroy {
  searchingCustomer: boolean = false;
  searchCustomerCancel: boolean = false;
  customerSearch = '';
  customerList: any = [];
  gettingCustomerList: boolean = false;

  subscription!: Subscription;
  mytimeout: any;

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<LogisticsCustomerComponent>
  ) {}
  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.homesearchCustomer();
  }

  searchChange(){
    clearTimeout(this.mytimeout);
    this.mytimeout = setTimeout(() => {
      this.homesearchCustomer();
    }, 500);
  }

  homesearchCustomer() {
    this.customerList = [];
    this.gettingCustomerList = true;
    const data = {
      newdesign: true,
      contact: [],
      count: 25,
      search: this.customerSearch,
      orgid: this.allinoneService.selectedOrg,
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
    };
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.kunyekService.getContactList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.customerList = res.list;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.gettingCustomerList = false;
      },
      (err) => {
        this.gettingCustomerList = false;
      }
    );
  }
  close(res: any) {
    this.dialogRef.close({ data: res });
  }
}
