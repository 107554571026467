<div class="row">
    <div class="mb-3 col-md-4">
        <label for="date" class="form-label">Date</label>
        <input class="form-control" type="date" id="date" [(ngModel)]="requestForm.date" name="date"
            [disabled]="requestData.readonly || backdateRequest" max="9999-12-31" [min]="currentDate"
            (ngModelChange)="getShift()" />

    </div>

    <div class="mb-3 col-md-4">
        <label for="starttime" class="form-label">Start Time</label>
        <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime" name="starttime"
            [disabled]="requestData.readonly || !isEarlyout" (ngModelChange)="calculateDuration()" />
        <mat-progress-bar *ngIf="!isEarlyout && gettingShift" mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="mb-3 col-md-4">
        <label for="endtime" class="form-label">End Time</label>
        <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
            [disabled]="requestData.readonly || isEarlyout"
            (ngModelChange)="calculateDuration(false)" />
        <mat-progress-bar *ngIf="isEarlyout && gettingShift" mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="mb-3 col-md-12">
        <label for="duration" class="form-label">Duration (Mins)</label>
        <input class="form-control" type="number" id="duration" [(ngModel)]="requestForm.duration" name="duration"
            disabled />
    </div>

    <div class="mb-3 col-md-12">
        <label for="date" class="form-label">Remark</label>
        <textarea class="form-control" id="remark" [(ngModel)]="requestForm.remark" name="remark"
            [disabled]="requestData.readonly" rows="3"></textarea>
    </div>
    <div>
        <button class="btn btn-custom me-2" (click)="chooseUser()" [disabled]="requestData.readonly">
            Select Approvers
        </button>
    </div>
    <ng-container *ngIf="approverList.length > 0">
        <div class="col-md-12 mt-3">
            <h3> Selected Approvers ({{ approverList.length }})</h3>
            <div class="list-group">
                <div class="profile-container d-flex col-md-4" *ngFor="let user of approverList">
                    <span>
                        <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                            [lazyLoad]="
                  user.photo || user.image || '../../../assets/images/profile.png'
                " />
                    </span>

                    <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                        <div class="profile-item-container">
                            <div class="profile-item">
                                <div class="username" style="cursor: pointer">
                                    <b>{{ user.username || user.userid }}</b>
                                </div>
                                <div style="color: grey; margin-top: 1px; font-size: 13.5px"
                                    *ngIf="user.username != ''">
                                    {{ user.userid }}
                                </div>
                            </div>
                        </div>
                        <input type="checkbox" class="form-check-input" [id]="user.primarykey"
                            [(ngModel)]="user.checked" [checked]="user.checked" [disabled]="requestData.readonly" />
                    </label>
                </div>
            </div>
        </div>
    </ng-container>
</div>