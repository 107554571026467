import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MonthlyfeedetailComponent } from '../monthlyfeedetail/monthlyfeedetail.component';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/shared/services/message.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-monthlyfeesetup',
  templateUrl: './monthlyfeesetup.component.html',
  styleUrls: ['./monthlyfeesetup.component.scss'],
})
export class MonthlyfeesetupComponent implements OnInit {

  @Output('closeFormHandler') closeFormHandler = new EventEmitter()
  @Input() isEdit: boolean = false;
  @Input() selectedMFT: any

  feetypes: Array<any> = [];


  monthlyFeeForm!: FormGroup;
  memberTypeList: Array<any> = [];
  monthlyFeesTypeList: any[] = []
  monthlyFeesTypeDetailList: any[] = []
  subscription? = new Subscription

  selectedMonthlyFeesTypeID: string = ''

  requiredMessage: string = 'This field may not be blank'

  submitted: boolean = false;

  createMonthlyFeesTypeLoading: boolean = false;

  requestBodyData: any

  currentMonthlyFees: any

  tempMonthlyFeesList: any;

  selectedMFTNotexists: boolean = true;

  breadcrumbs: any[] = [
    { name: 'Payment', url: 'payment', isActive: true },
    { name: 'Monthly Fees', url: '', isActive: false }
  ];


  constructor(
    private fb: FormBuilder,
    private kunyekService: KunyekService,
    private dialog: MatDialog,
    private messageService: MessageService,
  ) {
    this.monthlyFeeForm = this.fb.group({
      shortcode: ['', Validators.required],
      description: ['', Validators.required],
      membertype_id: ['', Validators.required],
    });
  }

  get monthlyfeesname() {
    return this.monthlyFeeForm.get("description")!
  }

  ngOnInit(): void {
    this.getMemberTypes();
    this.getMonthlyFeesTypeList();
    this.getFeeTypes();

    if (this.isEdit) {
      this.selectedMFTNotexists = false;
      this.loadMFT()
    } else {
      this.selectedMFT = null
    }
  }

  getMemberTypes() {
    this.kunyekService.getKMemberTypesList({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == '300') {
          this.memberTypeList = res.datalist;
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  getMonthlyFeesTypeList() {
    this.kunyekService.getMonthlyFeeTypes({}).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          console.log(res, 'res....')
          this.monthlyFeesTypeList = res.datalist
          this.monthlyFeesTypeList.forEach((i: any) => {
            if (i.monthlyfee_id == this.selectedMonthlyFeesTypeID) {
              this.selectedMFT = i
            }

            console.log(this.monthlyFeesTypeList, 'mtflist')
            console.log(this.selectedMFT, 'lllllll')
            if (this.selectedMFT && this.selectedMFT.monthly_detail.length > 0) {
              this.monthlyFeesTypeDetailList = this.selectedMFT.monthly_detail
            } else {
              this.monthlyFeesTypeDetailList = []
            }
          })
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        // this.gettingFeesTypeList = false;
      },
      (err) => {
        // this.gettingFeesTypeList = false;
      }
    );
  }


  openMonthlyFeeDetailForm() {
    const form = this.dialog.open(MonthlyfeedetailComponent, {
      height: '406px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: this.selectedMonthlyFeesTypeID
    })

    form.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult, 'knfkjef')
        if (dialogResult.success == true) {
          this.subscription?.unsubscribe()
          this.getMonthlyFeesTypeList()
          this.loadMFT()
          console.log(dialogResult.newmtfd, 'new mftd.....................')
        }

      }
      // }
    });
  }

  closeForm() {
    this.monthlyFeeForm.reset();
    this.submitted = false;
    this.closeFormHandler.emit();
    this.monthlyFeeForm.reset();
  }

  saveMonthlyFees() {
    this.submitted = true;
    this.requestBodyData = this.monthlyFeeForm.value
    if (this.monthlyFeeForm.valid) {
      this.createMonthlyFeesTypeLoading = true;
      // this.requestBodyData = {...this.requestBodyData, feetype_id: this.selectedFeesType.feetype_id}
      console.log('valid.')
      console.log(this.isEdit, '//////////')
      if (!this.isEdit) {
        this.kunyekService.createMonthlyFeeTypes(this.requestBodyData).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.messageService.openSnackBar(res.message, 'success');
              console.log(res, 'res....')
              this.submitted = false;
              this.selectedMonthlyFeesTypeID = res.monthlyfee_id
              console.log(this.requestBodyData)
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.selectedMFTNotexists = false;
            this.createMonthlyFeesTypeLoading = false;
          },
          (err) => {
            this.createMonthlyFeesTypeLoading = false;
            this.messageService.openSnackBar(err, 'warn');
          },
        );
      } else {
        this.requestBodyData.monthlyfee_id = this.selectedMFT.monthlyfee_id
        this.kunyekService.editMonthlyFees(this.requestBodyData).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.messageService.openSnackBar(res.message, 'success');
              console.log(res, 'res....')
              this.submitted = false;
              console.log(this.requestBodyData)
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.selectedMFTNotexists = false;
            this.createMonthlyFeesTypeLoading = false;
          },
          (err) => {
            this.createMonthlyFeesTypeLoading = false;
            this.messageService.openSnackBar(err, 'warn');
          },
        );
      }
    } else {
      this.createMonthlyFeesTypeLoading = false
    }
    if (this.selectedMonthlyFeesTypeID) {
    }
  }

  loadNewMFT(mftId: any) {
    if (mftId) {
      this.selectedMFTNotexists = false;
      console.log(mftId)
      this.getMonthlyFeesTypeList()
      console.log(this.monthlyFeesTypeList)
      this.monthlyFeesTypeList.forEach((i: any) => {
        if (i.monthlyfee_id == mftId) {
          this.selectedMFT = i
        }
        console.log(this.selectedMFT)
        this.loadMFT()
      })
    } else {
      console.log('error')
    }
  }

  loadMFT() {
    this.monthlyFeeForm.setValue({
      shortcode: this.selectedMFT.shortcode,
      description: this.selectedMFT.description,
      membertype_id: this.selectedMFT.membertype_id
    })

    if (this.selectedMFT.monthly_detail.length > 0) {
      this.monthlyFeesTypeDetailList = this.selectedMFT.monthly_detail
    } else {
      this.monthlyFeesTypeDetailList = []
    }

    this.selectedMonthlyFeesTypeID = this.selectedMFT.monthlyfee_id
    console.log(this.selectedMFT)
    console.log(this.selectedMonthlyFeesTypeID, 'iddddddd')

  }

  // handleSave() {
  //   this.kunyekService.createMonthlySetup(this.monthlyFeeForm.value).subscribe({
  //     next: (res) => {
  //       if (res.returncode == '300') {
  //         this.isEditing = true;
  //         this.monthlyfeeid = res.monthlyfee_id;
  //       }
  //     },
  //   });
  // }
  getFeeTypes() {
    this.kunyekService.getFeeTypes({ searchName: '' }).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.feetypes = res.datalist;
        }
      },
      error: (err) => {
        console.log(err)
      }
    })
  }



  // addNewSubFees(){
  //   this.monthlyFeesTypeDetailList.push(
  //     {
  //       payperiod_id: "",
  //       feetypes: "",
  //       momonthlyfee_id: "",
  //       amount: "",
  //       monthlyfee_detail_id: ""
  //     }
  //   )
  // }

  // removeNewSubFees(index: number){
  //   this.monthlyFeesTypeDetailList.splice(index,1)
  // }

  deleteMFTD(id: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      console.log(dialogResult)
      if (dialogResult == true) {
        console.log('aaaaaaaaaaaaa')
        this.kunyekService.deleteMontlyFeeDetail({ monthlyfee_detail_id: id }).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.messageService.openSnackBar(res.message, 'success')
              let index = this.monthlyFeesTypeDetailList.findIndex((x: any) => x.monthlyfee_detail_id = id)
              console.log(index, this.monthlyFeesTypeDetailList)
              this.monthlyFeesTypeDetailList.slice(index, 1)
              this.getMonthlyFeesTypeList()
            } else {
              this.messageService.openSnackBar('Error', 'warn')
            }
          },
          (err: any) => {
            this.messageService.openSnackBar(err, 'warn')
          }
        )
      }
      // this.kunyekService.deleteOfficeRequest(data).subscribe(
      //   (res: any) => {
      //     if (res.returncode == '300') {
      //       this.refresh();
      //     } else {
      //       this.messageService.openSnackBar(
      //         res.message || res.status || 'Server Error',
      //         'warn'
      //       );
      //       this.gettingRequestList = false;
      //     }
      //   },
      //   (err) => {
      //     this.gettingRequestList = false;
      //   }
      // );
    }
    )
  }

}