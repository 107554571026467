<div class='container-fluid'>
    <div class="content">
        <ng-container *ngIf="!isWfhPolicy">
            <ng-container *ngIf="isAdmin">
                <app-hcm-breadcrumb *ngIf="!isEditing" s_breadcrumb="Request Type" t_breadcrumb="Leave Policy"
                    (emit)="goToReqType()">
                </app-hcm-breadcrumb>
                <app-hcm-breadcrumb *ngIf="isEditing" s_breadcrumb="Request Type" t_breadcrumb="Leave Policy"
                    [f_breadcrumb]="policyname" (emit)="goToReqType()" (emit1)="cancel()">
                </app-hcm-breadcrumb>
            </ng-container>

            <ng-container *ngIf="!isAdmin">
                <app-office-breadcrumb *ngIf="!isEditing" [s_breadcrumb]="'Request Type'" t_breadcrumb="Leave Policy"
                    (emit)="goToReqType()">
                </app-office-breadcrumb>
                <app-office-breadcrumb *ngIf="isEditing" [s_breadcrumb]="'Request Type'" t_breadcrumb="Leave Policy"
                    [f_breadcrumb]="policyname" (emit)="goToReqType()" (emit1)="cancel()">
                </app-office-breadcrumb>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isWfhPolicy">
            <app-office-breadcrumb [s_breadcrumb]="'Request Type'" t_breadcrumb="Work From Home Policy"
                (emit)="cancel()">
            </app-office-breadcrumb>
        </ng-container>

        <ng-container *ngIf="isEditing || isWfhPolicy;else listView">
            <div class="close-save-button mb-3">
                <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                    Cancel
                </button>
                <app-button-loader (click)="submitPolicy()" [button_text]="'Save'" [load_button_text]="'Saving'"
                    [isLoading]="saveLoading">
                </app-button-loader>
            </div>
            <div class="card card-body my-card">
                <div class="row">
                    <!-- <div class="d-flex gap-3 flex-wrap"> -->
                    <ng-container *ngIf="reqTypeForm.showserviceyear">
                        <div class="col-md-6 mb-3">
                            <label for="serviceyear" class="form-label">Service Year (Monthly)</label>
                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                id="serviceyear" name="serviceyear" [(ngModel)]="reqTypeForm.serviceyear"
                                #serviceyear="ngModel" min="0">
                        </div>
                    </ng-container>
                    <!-- <div class="col-md-6 mb-3" *ngIf="reqTypeForm.carry">
                        <label for="carryyear" class="form-label">Carry Year</label>
                        <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="carryyear"
                            name="carryyear" [(ngModel)]="reqTypeForm.carryyear" #carryyear="ngModel" min="0">
                    </div> -->
                    <!-- </div> -->

                    <ng-container>
                        <div class=" d-flex flex-wrap">
                            <div class="form-check me-5" *ngIf="reqTypeForm.showcarry">
                                <input class="form-check-input" type="checkbox" id="carry"
                                    [(ngModel)]="reqTypeForm.carry" name="carry">
                                <label class="form-check-label ms-1" for="carry"> Carry
                                </label>
                            </div>
                            <div class="form-check me-5" *ngIf="reqTypeForm.showunpaidfile">
                                <input class="form-check-input" type="checkbox" id="unpaid"
                                    [(ngModel)]="reqTypeForm.unpaid" name="unpaid">
                                <label class="form-check-label ms-1" for="unpaid"> Unpaid
                                </label>
                            </div>
                            <div class="form-check me-5" *ngIf="reqTypeForm.showattachfile">
                                <input class="form-check-input" type="checkbox" id="attachfile"
                                    [(ngModel)]="reqTypeForm.attachfile" name="attachfile">
                                <label class="form-check-label ms-1" for="attachfile"> Attach File
                                </label>
                            </div>
                            <div class="form-check me-5" *ngIf="reqTypeForm.showreduceserviceyear">
                                <input class="form-check-input" type="checkbox" id="reduceserviceyear"
                                    [(ngModel)]="reqTypeForm.reduceserviceyear" name="reduceserviceyear">
                                <label class="form-check-label ms-1" for="reduceserviceyear"> Reduce Service Year
                                </label>
                            </div>
                            <div class="form-check me-5" *ngIf="reqTypeForm.showencashment">
                                <input class="form-check-input" type="checkbox" id="encashment"
                                    [(ngModel)]="reqTypeForm.encashment" name="encashment">
                                <label class="form-check-label ms-1" for="encashment"> Encashment
                                </label>
                            </div>
                            <div class="form-check me-5" *ngIf="reqTypeForm.showprorated">
                                <input class="form-check-input" type="checkbox" id="prorated"
                                    [(ngModel)]="reqTypeForm.prorated" name="prorated"> <label
                                    class="form-check-label ms-1" for="prorated"> Use Prorated Calculation
                                </label>
                            </div>
                            <div class="form-check me-5" *ngIf="reqTypeForm.showroundingpolicy">
                                <input class="form-check-input" type="checkbox" id="roundingpolicy"
                                    [(ngModel)]="reqTypeForm.roundingpolicy" name="roundingpolicy">
                                <label class="form-check-label ms-1" for="roundingpolicy">
                                    Use Rounding Policy
                                </label>
                            </div>
                            <ng-container *ngIf="reqTypeForm.showpreposttodaysubmit">
                                <!-- <div class=" d-flex"> -->
                                <div class="form-check me-5" *ngIf="reqTypeForm.showpresubmit">
                                    <input class="form-check-input" type="checkbox" id="presubmit"
                                        [(ngModel)]="reqTypeForm.presubmit" name="presubmit"
                                        (ngModelChange)="submitTypeChange('pre')" #presubmit="ngModel">
                                    <label class="form-check-label ms-1" for="presubmit"> Pre Submit
                                    </label>
                                </div>
                                <div class="form-check me-5" *ngIf="reqTypeForm.showpostsubmit">
                                    <input class="form-check-input" type="checkbox" id="postsubmit"
                                        [(ngModel)]="reqTypeForm.postsubmit" name="postsubmit"
                                        (ngModelChange)="submitTypeChange('post')" #postsubmit="ngModel">
                                    <label class="form-check-label ms-1" for="postsubmit"> Post Submit
                                    </label>
                                </div>
                                <div class="form-check me-5" *ngIf="reqTypeForm.showtodaysubmit">
                                    <input class="form-check-input" type="checkbox" id="todaysubmit"
                                        [(ngModel)]="reqTypeForm.todaysubmit" name="todaysubmit"
                                        (ngModelChange)="submitTypeChange('today')" #todaysubmit="ngModel">
                                    <label class="form-check-label ms-1" for="todaysubmit"> Today Submit
                                    </label>
                                </div>
                                <!-- </div> -->
                            </ng-container>
                        </div>
                    </ng-container>

                    <div class="d-flex flex-column">
                        <ng-container *ngIf="reqTypeForm.carry">
                            <div class="mt-3">
                                <div class="card card-body my-card">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="carryyear" class="form-label">Maximum Carry Day</label>
                                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                                id="carryyear" name="carryyear" [(ngModel)]="reqTypeForm.carryyear"
                                                #carryyear="ngModel" min="0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.presubmit">
                            <div class="mt-3">
                                <div class="card card-body my-card">
                                    <!-- <div class="info mb-3">Pre Submit</div> -->
                                    <div class="d-flex align-items-center" *ngIf="reqTypeForm.precustom">

                                        <div class="form-check mb-3 me-5"> <input class="form-check-input" type="radio"
                                                name="presubmit" id="presubmitfix" value="1"
                                                [(ngModel)]="reqTypeForm.presubmittype"> <label class="form-check-label"
                                                for="presubmitfix"> Fix
                                            </label> </div>
                                        <div class="form-check mb-3"> <input class="form-check-input" type="radio"
                                                name="presubmit" id="presubmitcustom" value="2"
                                                [(ngModel)]="reqTypeForm.presubmittype"> <label class="form-check-label"
                                                for="presubmitcustom"> Custom
                                            </label> </div>

                                    </div>
                                    <div class="row" *ngIf="reqTypeForm.presubmittype == '1'">
                                        <div class="col-md-6">
                                            <label for="presubmitday" class="form-label">Pre Submit Day</label>
                                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                                id="presubmitday" name="presubmitday"
                                                [(ngModel)]="reqTypeForm.presubmitday" min="0"
                                                [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.presubmitday === '' || reqTypeForm.presubmitday === null) }">
                                        </div>
                                    </div>
                                    <div *ngIf="reqTypeForm.presubmittype == '2'">
                                        <ng-container *ngFor="let pre of preSubmitConfig; let i=index; let first=first">
                                            <div class="d-flex gap-3 align-items-end">
                                                <div class="mb-3 flex-fill">
                                                    <label *ngIf="first" [attr.for]="'takenday'+i"
                                                        class="form-label">Taken
                                                        day</label>
                                                    <input type="number" (keypress)="$event.charCode != 45"
                                                        class="form-control" [attr.id]="'takenday'+i"
                                                        [attr.name]="'takenday'+i" [(ngModel)]="pre.from"
                                                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (preSubmitConfig[i].from === '' || preSubmitConfig[i].from === null) }"
                                                        min="0">
                                                </div>
                                                <div class="mb-3 flex-fill">
                                                    <label *ngIf="first" [attr.for]="'to'+i"
                                                        class="form-label">To</label>
                                                    <input type="number" (keypress)="$event.charCode != 45"
                                                        class="form-control" [attr.id]="'to'+i" [attr.name]="'to'+i"
                                                        [(ngModel)]="pre.to"
                                                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (preSubmitConfig[i].to === '' || preSubmitConfig[i].to === null) }"
                                                        min="0">
                                                </div>
                                                <div class="mb-3 flex-fill">
                                                    <label *ngIf="first" [attr.for]="'presubmitday'+i"
                                                        class="form-label">Pre submit day</label>
                                                    <input type="number" (keypress)="$event.charCode != 45"
                                                        class="form-control" [attr.id]="'presubmitday'+i"
                                                        [attr.name]="'presubmitday'+i" [(ngModel)]="pre.day"
                                                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (preSubmitConfig[i].day === '' || preSubmitConfig[i].day === null) }"
                                                        min="0">
                                                </div>
                                                <div class="mb-3">
                                                    <!-- <button class="btn btn-custom prize-btn" style="width:50px !important;"
                                                    (click)="removeSubmitConfig(i, 'pre')">
                                                    <mat-icon>remove_circle</mat-icon>
                                                </button> -->
                                                    <button class="btn btn-outline-custom remove-input-btn"
                                                        (click)="removeSubmitConfig(i, 'pre')">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="row">
                                            <div class="">
                                                <button class="btn btn-custom" (click)="addSubmitConfig('pre')">
                                                    <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.postsubmit">
                            <div class="mt-3">
                                <div class="card card-body my-card">
                                    <!-- <div class="info mb-3">Post Submit</div> -->
                                    <div class="d-flex align-items-center" *ngIf="reqTypeForm.postcustom">
                                        <div class="form-check mb-3 me-5">
                                            <input class="form-check-input" type="radio" name="postsubmit"
                                                id="postsubmitfix" value="1" [(ngModel)]="reqTypeForm.postsubmittype">
                                            <label class="form-check-label" for="postsubmitfix"> Fix
                                            </label>
                                        </div>
                                        <div class="form-check mb-3">
                                            <input class="form-check-input" type="radio" name="postsubmit"
                                                id="postsubmitcustom" value="2"
                                                [(ngModel)]="reqTypeForm.postsubmittype">
                                            <label class="form-check-label" for="postsubmitcustom"> Custom
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="reqTypeForm.postsubmittype == '1'">
                                        <div class="col-md-6">
                                            <label for="postsubmitday" class="form-label">
                                                Post Submit Day
                                            </label>
                                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                                id="postsubmitday" name="postsubmitday"
                                                [(ngModel)]="reqTypeForm.postsubmitday"
                                                [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.postsubmitday == '' || reqTypeForm.postsubmitday == null) }"
                                                min="0">
                                        </div>
                                    </div>
                                    <div *ngIf="reqTypeForm.postsubmittype == '2'"> <ng-container
                                            *ngFor="let post of postSubmitConfig; let i=index; let first=first">
                                            <div class="d-flex gap-3 align-items-end">
                                                <div class="mb-3 flex-fill"> <label *ngIf="first"
                                                        [attr.for]="'takenday'+i" class="form-label text-end">Taken
                                                        day</label> <input type="number"
                                                        (keypress)="$event.charCode != 45" class="form-control"
                                                        [attr.id]="'takenday'+i" [attr.name]="'takenday'+i"
                                                        [(ngModel)]="post.from"
                                                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (postSubmitConfig[i].from === '' || postSubmitConfig[i].from === null) }"
                                                        min="0"> </div>
                                                <div class="mb-3 flex-fill"> <label *ngIf="first" [attr.for]="'to'+i"
                                                        class="form-label">To</label> <input type="number"
                                                        (keypress)="$event.charCode != 45" class="form-control"
                                                        [attr.id]="'to'+i" [attr.name]="'to'+i" [(ngModel)]="post.to"
                                                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (postSubmitConfig[i].to === '' || postSubmitConfig[i].to === null) }"
                                                        min="0"> </div>
                                                <div class="mb-3 flex-fill"> <label *ngIf="first"
                                                        [attr.for]="'postsubmitday'+i" class="form-label">Post submit
                                                        day</label> <input type="number"
                                                        (keypress)="$event.charCode != 45" class="form-control"
                                                        [attr.id]="'postsubmitday'+i" [attr.name]="'postsubmitday'+i"
                                                        [(ngModel)]="post.day"
                                                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (postSubmitConfig[i].day === '' || postSubmitConfig[i].day === null) }"
                                                        min="0"> </div>
                                                <div class="mb-3"> <button class="btn btn-custom prize-btn"
                                                        style="width:50px !important; "
                                                        (click)="removeSubmitConfig(i, 'post')">
                                                        <mat-icon>remove_circle</mat-icon> </button> </div>
                                            </div>
                                        </ng-container>
                                        <div class="row">
                                            <div class=""> <button class="btn btn-custom"
                                                    (click)="addSubmitConfig('post')">
                                                    <mat-icon>add_circle</mat-icon> <span class="ms-2">Add</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.maternity">
                            <div class="mt-3">
                                <div class="card card-body my-card ">
                                    <!-- <div class="info mb-3">Maternity</div> -->
                                    <!-- <div class="d-flex gap-3 flex-wrap"> -->
                                    <div class="row">
                                        <div class="col-md-6 mb-3">
                                            <label for="maternitybeforeweek" class="form-label">Before
                                                by day</label>
                                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                                id="maternitybeforeweek" name="maternitybeforeweek"
                                                [(ngModel)]="reqTypeForm.maternitybeforeweek"
                                                [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.maternitybeforeweek == '' || reqTypeForm.maternitybeforeweek == null) }"
                                                min="0">
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="maternityafterweek" class="form-label">After
                                                by day</label>
                                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                                id="maternityafterweek" name="maternityafterweek"
                                                [(ngModel)]="reqTypeForm.maternityafterweek"
                                                [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.maternityafterweek == '' || reqTypeForm.maternityafterweek == null) }"
                                                min="0">
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.paternity">
                            <div class="mt-3">
                                <div class="card card-body my-card ">
                                    <!-- <div class="info mb-3">Paternity</div> -->
                                    <div class="d-flex gap-3 flex-wrap">
                                        <div class="">
                                            <label for="paternitybeforeweek" class="form-label">Before
                                                Week</label>
                                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                                id="paternitybeforeweek" name="paternitybeforeweek"
                                                [(ngModel)]="reqTypeForm.paternitybeforeweek"
                                                [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.paternitybeforeweek == '' || reqTypeForm.paternitybeforeweek == null) }"
                                                min="0">
                                        </div>
                                        <div class="">
                                            <label for="paternityafterweek" class="form-label">After
                                                Week</label>
                                            <input type="number" (keypress)="$event.charCode != 45" class="form-control"
                                                id="paternityafterweek" name="paternityafterweek"
                                                [(ngModel)]="reqTypeForm.paternityafterweek"
                                                [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.paternityafterweek == '' || reqTypeForm.paternityafterweek == null) }"
                                                min="0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.prorated || reqTypeForm.roundingpolicy">
                            <div class="card card-body my-card mt-3">
                                <div class="row">
                                    <ng-container *ngIf="reqTypeForm.prorated">
                                        <div class="col-md-6">
                                            <div class="">
                                                <label class="form-label" for="proratedmethod">
                                                    Prorated Method
                                                </label>
                                                <select class="form-select" name="proratedmethod" id="proratedmethod"
                                                    [(ngModel)]="reqTypeForm.proratedmethod"
                                                    [disabled]="gettingProratedMethods"
                                                    [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && reqTypeForm.proratedmethod == ''}">
                                                    <option value="" [selected]="reqTypeForm.proratedmethod == ''">-
                                                    </option>
                                                    <option *ngFor="let method of proratedMethods" [value]="method.id"
                                                        [selected]="reqTypeForm.proratedmethod == method.id">
                                                        {{method.name}}
                                                    </option>
                                                </select>
                                                <mat-progress-bar mode="indeterminate"
                                                    *ngIf="gettingProratedMethods"></mat-progress-bar>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="reqTypeForm.roundingpolicy">
                                        <div class="col-md-6">
                                            <div class="">
                                                <label class="form-label" for="proratedmethod">
                                                    Rounding Policy
                                                </label>
                                                <select class="form-select" name="roundingpolicylist"
                                                    id="roundingpolicylist" [(ngModel)]="reqTypeForm.roundingpolicylist"
                                                    [disabled]="gettingProratedMethods"
                                                    [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && reqTypeForm.roundingpolicylist == ''}">
                                                    <option value="" [selected]="reqTypeForm.roundingpolicylist == ''">-
                                                    </option>
                                                    <option *ngFor="let method of roundingMethods" [value]="method.id"
                                                        [selected]="reqTypeForm.roundingpolicylist == method.id">
                                                        {{method.name}}
                                                    </option>
                                                </select>
                                                <mat-progress-bar mode="indeterminate"
                                                    *ngIf="gettingProratedMethods"></mat-progress-bar>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="reqTypeForm.showmaxleavepermonth || reqTypeForm.showmaxleaveperday || reqTypeForm.showminleaveperday">
                            <div class="mt-3">
                                <div class="card card-body my-card my-card-fix-width">
                                    <!-- <div class="d-flex gap-3 flex-wrap"> -->
                                    <div class="mb-3" *ngIf="this.reqTypeForm.showproratedcheckbox">
                                        <div class="form-check col-12">
                                            <input class="form-check-input" type="checkbox" name="proratedchecked"
                                                id="proratedchecked" [(ngModel)]="reqTypeForm.proratedchecked">
                                            <label class="form-check-label" for="proratedchecked">
                                                Prorated
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <ng-container *ngIf="reqTypeForm.showmaxleavepermonth">
                                            <div class="col-md-4">
                                                <label for="maxleavepermonth" class="form-label">Max Leave Taken Per
                                                    Month</label>
                                                <input type="number" (keypress)="$event.charCode != 45"
                                                    class="form-control" id="maxleavepermonth" name="maxleavepermonth"
                                                    [(ngModel)]="reqTypeForm.maxleavepermonth"
                                                    #maxleavepermonth="ngModel" min="0"
                                                    [disabled]="reqTypeForm.proratedchecked">
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="reqTypeForm.showmaxleaveperday">
                                            <div class="col-md-4">
                                                <label for="maxleaveperday" class="form-label">Max Leave Taken Day At
                                                    Once</label>
                                                <input type="number" (keypress)="$event.charCode != 45"
                                                    class="form-control" id="maxleaveperday" name="maxleaveperday"
                                                    [(ngModel)]="reqTypeForm.maxleaveperday" #maxleaveperday="ngModel"
                                                    min="0" [disabled]="reqTypeForm.proratedchecked">
                                            </div>
                                        </ng-container>


                                        <ng-container *ngIf="reqTypeForm.showminleaveperday">
                                            <div class="col-md-4">
                                                <label for="minleaveperday" class="form-label">Min Leave Taken Day At
                                                    Once</label>
                                                <input type="number" (keypress)="$event.charCode != 45"
                                                    class="form-control" id="minleaveperday" name="minleaveperday"
                                                    [(ngModel)]="reqTypeForm.minleaveperday" #minleaveperday="ngModel"
                                                    min="0" [disabled]="reqTypeForm.proratedchecked">
                                            </div>
                                        </ng-container>
                                    </div>
                                    <!-- </div> -->

                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="reqTypeForm.leaveduration">
                            <div class="mt-3">
                                <div class="card card-body my-card my-card-fix-width">
                                    <!-- <label class="form-label">Leave Duration</label> -->
                                    <div class="info mb-3">Leave Duration</div>
                                    <div class="d-flex align-items-center">
                                        <div class="row w-100">
                                            <div class="col-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="leavedurationgazaettedholiday"
                                                        id="leavedurationgazaettedholiday"
                                                        [(ngModel)]="reqTypeForm.leavedurationgazaettedholiday">
                                                    <label class="form-check-label" for="leavedurationgazaettedholiday">
                                                        Gazaetted
                                                        Holiday
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="leavedurationsaturday" id="leavedurationsaturday"
                                                        [(ngModel)]="reqTypeForm.leavedurationsaturday">
                                                    <label class="form-check-label" for="leavedurationsaturday">
                                                        Saturday
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-check"> <input class="form-check-input" type="checkbox"
                                                        name="leavedurationsunday" id="leavedurationsunday"
                                                        [(ngModel)]="reqTypeForm.leavedurationsunday">
                                                    <label class="form-check-label" for="leavedurationsunday"> Sunday
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.leavecounting">
                            <div class="mt-3">
                                <div class="card card-body my-card my-card-fix-width">
                                    <div class="info mb-3">Leave Counting</div>
                                    <div class="d-flex align-items-center">
                                        <div class="row w-100">
                                            <div class="col-4">
                                                <div class="form-check"> <input class="form-check-input" type="checkbox"
                                                        name="leavecountingpreoff" id="leavecountingpreoff"
                                                        [(ngModel)]="reqTypeForm.leavecountingpreoff">
                                                    <label class="form-check-label" for="leavecountingpreoff"> Pre Off
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-check"> <input class="form-check-input" type="checkbox"
                                                        name="leavecountingpostoff" id="leavecountingpostoff"
                                                        [(ngModel)]="reqTypeForm.leavecountingpostoff"> <label
                                                        class="form-check-label" for="leavecountingpostoff"> Post Off
                                                    </label> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.limitationday">
                            <div class="mt-3">
                                <div class="card card-body my-card my-card-fix-width">
                                    <div class="info mb-3">Leave Limitations</div>
                                    <div class="d-flex align-items-center">
                                        <div class="row w-100">
                                            <div class="col-4">
                                                <div class="form-check"> <input class="form-check-input" type="checkbox"
                                                        name="limitationdaypreoff" id="limitationdaypreoff"
                                                        [(ngModel)]="reqTypeForm.limitationdaypreoff">
                                                    <label class="form-check-label" for="limitationdaypreoff"> Before
                                                        the
                                                        day
                                                        off
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-check"> <input class="form-check-input" type="checkbox"
                                                        name="limitationdaypostoff" id="limitationdaypostoff"
                                                        [(ngModel)]="reqTypeForm.limitationdaypostoff"> <label
                                                        class="form-check-label" for="limitationdaypostoff"> After the
                                                        day
                                                        off
                                                    </label> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="d-flex flex-wrap align-items-center"> <ng-container
                                                *ngFor="let day of weekDay;let i = index; let first = first">
                                                <div class="form-check mb-3 me-5" [ngClass]="{'' : first}">
                                                    <input class="form-check-input" type="checkbox" [attr.name]="day.name"
                                                        [attr.id]="day.name" (change)="addLimitations($event,day.value)"
                                                        [checked]="reqTypeForm.limitationdayweekday?.includes(day.value)">
                                                    <label class="form-check-label" [attr.for]="day.name"> {{day.name}}</label>
                                                </div>
                                            </ng-container> </div>
                                    </div> -->
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.showlimited">
                            <div class="mt-3">
                                <div class="card card-body my-card my-card-fix-width">
                                    <!-- <div class="d-flex gap-3 flex-wrap"> -->
                                    <div class="row">
                                        <ng-container>
                                            <div class="col-md-6">
                                                <label for="limitedmonth" class="form-label">Limited Month</label>
                                                <input type="number" (keypress)="$event.charCode != 45"
                                                    class="form-control" id="limitedmonth" name="limitedmonth"
                                                    [(ngModel)]="reqTypeForm.limitedmonth" #limitedmonth="ngModel"
                                                    min="0">
                                            </div>
                                        </ng-container>

                                        <ng-container>
                                            <div class="col-md-6">
                                                <label for="limitedday" class="form-label">Limited Day</label>
                                                <input type="number" (keypress)="$event.charCode != 45"
                                                    class="form-control" id="limitedday" name="limitedday"
                                                    [(ngModel)]="reqTypeForm.limitedday" #limitedday="ngModel" min="0">
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>
                        </ng-container>

                        <ng-container *ngIf="reqTypeForm.pairleave">
                            <div class="mt-3">
                                <div class="card card-body my-card " style="min-width: 228px;">
                                    <div class="info mb-3">Pair Leave</div>
                                    <div class="d-flex align-items-center">
                                        <div class="form-check me-5"> <input class="form-check-input" type="radio"
                                                name="pairleavetype" id="pairleavetypeall" value="1"
                                                [(ngModel)]="reqTypeForm.pairleavetype"> <label class="form-check-label"
                                                for="pairleavetypeall"> All
                                            </label> </div>
                                        <div class="form-check"> <input class="form-check-input" type="radio"
                                                name="pairleavetype" id="pairleavetypecustom" value="2"
                                                [(ngModel)]="reqTypeForm.pairleavetype"> <label class="form-check-label"
                                                for="pairleavetypecustom"> Custom
                                            </label> </div>
                                    </div>
                                    <div class="mt-3" *ngIf="reqTypeForm.pairleavetype == '2'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <ng-select class="role-select" *ngIf="workingDayLeaveTypeLoading">
                                                </ng-select>
                                                <ng-select class="role-select" [items]="leaveList" bindLabel="name"
                                                    placeholder="Select Type" appendTo="body" [multiple]="true"
                                                    [(ngModel)]="reqTypeForm.pairleavecustomtype"
                                                    [ngClass]="{ 'role-select-invalid': reqTypeFormSubmitted && (reqTypeForm.pairleavecustomtype.length == 0 || reqTypeForm.pairleavecustomtype == null) }"
                                                    *ngIf="!workingDayLeaveTypeLoading">
                                                </ng-select>
                                                <mat-progress-bar mode="indeterminate"
                                                    *ngIf="workingDayLeaveTypeLoading"></mat-progress-bar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="reqTypeForm.workingday">
                            <div class="mt-3">
                                <div class="card card-body my-card my-card-fix-width">
                                    <div class="info mb-3"> Working Day
                                    </div>
                                    <!-- <div class="working-day-container"> -->
                                    <div class="row">
                                        <ng-container *ngFor="let month of workingDayMonthData">
                                            <div class="col-md-4 mb-3">
                                                <label [attr.for]="month.name" class="form-label">{{month.name}}</label>
                                                <!-- [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (month.value === '' || month.value === null || monthname.errors?.max) }" -->
                                                <input type="number" required (keypress)="$event.charCode != 45"
                                                    class="form-control" [attr.id]="month.name" [attr.name]="month.name"
                                                    [(ngModel)]="month.value" min="0"
                                                    [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (month.value === '' || month.value === null || monthname.errors?.max) }"
                                                    [max]="month.max" #monthname="ngModel">
                                                <div>
                                                    {{monthname?.errors?.max}}
                                                </div>
                                            </div>

                                        </ng-container>
                                    </div>
                                    <!-- </div> -->
                                    <div class="d-flex align-items-center">
                                        <div class="form-check me-5"> <input class="form-check-input" type="checkbox"
                                                name="workingdaygazaettedholiday" id="workingdaygazaettedholiday"
                                                [(ngModel)]="reqTypeForm.workingdaygazaettedholiday"> <label
                                                class="form-check-label" for="workingdaygazaettedholiday"> Gazaetted
                                                Holiday
                                            </label> </div>
                                        <div class="form-check me-5"> <input class="form-check-input" type="checkbox"
                                                name="workingdaysaturday" id="workingdaysaturday"
                                                [(ngModel)]="reqTypeForm.workingdaysaturday">
                                            <label class="form-check-label" for="workingdaysaturday"> Saturday
                                            </label>
                                        </div>
                                        <div class="form-check"> <input class="form-check-input" type="checkbox"
                                                name="workingdaysunday" id="workingdaysunday"
                                                [(ngModel)]="reqTypeForm.workingdaysunday">
                                            <label class="form-check-label" for="workingdaysunday"> Sunday
                                            </label>
                                        </div>
                                    </div>
                                    <div class="my-3">
                                        <label class="form-label"> Not Counted Leave</label>
                                        <ng-select *ngIf="workingDayLeaveTypeLoading">
                                        </ng-select>
                                        <ng-select [items]="leaveList" bindLabel="name" placeholder="Select Type"
                                            appendTo="body" [multiple]="true"
                                            [(ngModel)]="reqTypeForm.workingdayleavetype"
                                            *ngIf="!workingDayLeaveTypeLoading">
                                        </ng-select>
                                        <mat-progress-bar mode="indeterminate"
                                            *ngIf="workingDayLeaveTypeLoading"></mat-progress-bar>
                                    </div>

                                </div>
                            </div>
                        </ng-container>
                        <div class="d-flex gap-3 flex-wrap">
                            <ng-container *ngIf="reqTypeForm.compassionate">
                                <div class=" mb-3">
                                    <label class="form-label" for="remark"> Remark
                                    </label>
                                    <textarea class="form-control" name="remark" id="remark"
                                        [(ngModel)]="reqTypeForm.remark"
                                        [ngClass]="{ 'is-invalid': reqTypeFormSubmitted && (reqTypeForm.remark == '' || reqTypeForm.remark == null) }"
                                        rows="4">
                                    </textarea>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #listView>
            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-lg-12 col-md-12" mat-sort-header="year">
                                Name
                            </td>
                            <td scope="col" class="d-flex justify-content-center">Action</td>
                        </tr>
                    </thead>

                    <tbody *ngIf="gettingPolicyList">
                        <tr>
                            <td colspan="5" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="!gettingPolicyList">
                        <tr *ngIf="policyList.length == 0">
                            <td colspan="5" class="nodata">Empty</td>
                        </tr>

                        <tr *ngFor="let policy of policyList;let index = index">
                            <td class="td-data td-edit" (click)="editPolicy(policy)">
                                <span [title]="policy.name">
                                    {{policy.name }}</span>
                            </td>

                            <td class="d-flex justify-content-center align-items-center">
                                <button class="btn btn-outline-primary" (click)="editPolicy(policy)">
                                    <i class="fa fa-edit"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-template>

    </div>
</div>