import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-member-position-history',
  templateUrl: './member-position-history.component.html',
  styleUrls: ['./member-position-history.component.scss'],
})
export class MemberPositionHistoryComponent implements OnInit, OnDestroy {
  fromOrg: boolean = false;
  pcListLoading: boolean = false;

  organizations: any = [];
  historyList: any = [];

  orgid: string = '';
  searchUserid: string = '';

  allsub = new SubSink();

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<MemberPositionHistoryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.fromOrg = data.fromOrg;
    this.searchUserid = data.userid;
    if (this.fromOrg) {
      this.organizations.push(data.org);
      this.orgid = data.org.orgid;
    }
    else{
      this.orgid = data.orgid;
    }
  }
  ngOnDestroy(): void {
    this.allsub.unsubscribe();
  }

  ngOnInit(): void {
    // if (!this.fromOrg) {
    //   this.organizations = this.allinoneService.orgs;
    //   this.orgid = this.allinoneService.selectedOrg
    //     ? this.allinoneService.selectedOrg
    //     : this.organizations[0].orgid;
    // }
    this.getMemberHistory();
  }

  getMemberHistory() {
    this.pcListLoading = true;
    const data = {
      orgid: this.orgid,
      searchuser: this.searchUserid,
    };
    this.allsub.sink = this.kunyekService.getMemberHistory(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.historyList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.pcListLoading = false;
      },
      (err) => {
        this.pcListLoading = false;
      }
    );
  }
}
