import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.scss']
})
export class ProxyComponent implements OnInit {

  customFields: any = [];
  customFieldsOrigin: any = [];
  customFieldsFinal: any = [];
  saveLoading: boolean = false;
  cusFieldCount: string = "";
  maxOption: any = 1;
  maxOptionCount: any[] = [];
  checkEdit: boolean = false;
  deleteList: any[] = [];
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ProxyComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.orgListForTag = data.orgList ? data.orgList : [];
    // this.orgListForTag.map((item: any) => {
    //   if (item.admin == 'true') {
    //     this.postAsList.push(item)
    //   }
    // })
    // this.post = data.post;
    // if (data.post) {
    //   this.editLoading = true;
    // }
    // if (this.checkEdit) {

    this.customFieldsOrigin = data.proxyList;
    console.log(data.proxyList);
    console.log('Pass Candi>>');
    console.log(this.customFieldsOrigin);
    this.cusFieldCount = "";
    // this.customFieldsOrigin = this.candidatesList;
    this.maxOptionCount = [];
    this.customFields = JSON.parse(JSON.stringify(this.customFieldsOrigin));
    this.deleteList = JSON.parse(JSON.stringify(data.deletelist));
    console.log('--AA--');
    console.log(this.customFields);

    if (this.customFieldsOrigin.length == 0) {
      console.log("aa");
      this.addCusField();
    } else {
      this.checkEdit = true;
      for (let i = 0; i < this.customFields.length; i++) {
        this.maxOptionCount.push(i + 1);
      }
    }

  }
  async ngOnInit(): Promise<void> {
    // this.openCustomAnswer();
  }
  //////////
  finishCustomAnswer() {
    console.log('custom fields');
    console.log(this.customFields);
    console.log('custom fields origin');
    console.log(this.customFieldsOrigin);
    console.log(this.maxOption);
    console.log("deleteList");
    console.log(this.deleteList)
    var list: any = [];
    for (let i = 0; i < this.customFields.length; i++) {
      if (this.customFields[i].proxyname.toString().trim() == "") {
        this.messageService.openSnackBar(
          'Please fill all field.',
          'warn'
        );
        return;
      }
    }
    const status = this.customFields.some((user: { name: any; }) => {
      let counter = 0;
      for (const iterator of this.customFields) {
        if (iterator.proxyname === user.name) {

          counter += 1;
        }
      }
      return counter > 1;
    });
    if (status) {
      this.messageService.openSnackBar(
        'Names cannot be duplicated.',
        'warn'
      );
      return;

    }

    if (
      this.maxOption == '' ||
      this.maxOption == undefined ||
      this.maxOption < 1
    ) {
      this.messageService.openSnackBar(
        'Max selection should be at least 1!',
        'warn'
      );
    }
    else {
      var tempList = [];
      for (let i = 0; i < this.customFields.length; i++) {
        // this.cusFieldCount = this.cusFieldCount + 1;
        tempList.push({
          proxyid: this.customFields[i].proxyid == undefined ? '' : this.customFields[i].proxyid,
          proxyname: this.customFields[i].proxyname.toString().trim(),
        });
        // this.customFieldsOrigin.push({ "candidateid": this.cusFieldCount.toString(), "name": this.customFields[i].name })
      }

      this.customFieldsOrigin = tempList;

      this.customFieldsFinal = JSON.parse(
        JSON.stringify(this.customFieldsOrigin)
      );

      console.log('FINAL>>');
      console.log(this.customFieldsOrigin);
      console.log('--Removed--');
      var ret = [
        {
          proxylist: this.customFieldsOrigin,
          deletelist: this.deleteList,
        },
      ];
      this.dialogRef.close(ret);
      // }
    }
  }
  close() {
    this.dialogRef.close(false);
  }
  removeCusField(idx: any) {
    console.log(idx);
      // var id = this.customFields[idx].id;
      if (this.customFields[idx].proxyid != "") {
        this.deleteList.push(this.customFields[idx].proxyid);
        console.log("1");
      }
      console.log(this.deleteList);

      this.customFields.splice(idx, 1);
      this.maxOptionCount.pop();
  }
  addCusField() {
    this.customFields.push({ proxyid: '', proxyname: '' });
    this.maxOptionCount.push(this.customFields.length);
  }

}
