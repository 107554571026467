import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-crm-ticket-history',
  templateUrl: './crm-ticket-history.component.html',
  styleUrls: ['./crm-ticket-history.component.scss'],
})
export class CrmTicketHistoryComponent implements OnInit {
  @Input() newpath?: boolean;
  @Input() orgid: any;
  @Input() statuslist: any = [];
  @Input() productlist: any = [];
  @Input() historydata: any;
  @Input() customercare: any;
  gettingProjectList: boolean = false;
  historytype: string = '';
  serviceFilter: string = '';
  lastPage: any = '';
  currentPage: any = 1;
  ispaginate: boolean = true;
  itemPerPage: any = 100;
  nextDisabled: boolean = false;
  typename: any = 'Type';
  @Output('save')
  save: EventEmitter<any> = new EventEmitter<any>();

  @Output('crmcancel')
  cancel: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('window:resize', ['$event'])
  gettingData: boolean = true;
  total: any = 0;
  sortby: any = '';
  lastIndex: any = [];

  data: any[] = [];
  historyData: any = [];
  saveLoading: boolean = false;
  isView: boolean = false;
  searchTextService: string = '';
  startdatetime: string = '';
  enddatetime: string = '';
  historyList: any;
  serviceid: string = '';
  // p: number = 1;
  callType: any = '';
  ticketno: any = '';
  mobileViewWidth: number = 426;
  viewWidth: number = 992;
  isFocus: boolean = false;
  searchLoading: boolean = false;
  @Input() t_breadcrumb!: string;
  @Input() f_breadcrumb!: string;
  status = [
    { status: 'New', code: '001' },
    { status: 'Closed', code: '002' },
  ];

  priority = [
    { name: 'High', code: '001' },
    { name: 'Medium', code: '002' },
    { name: 'Normal', code: '003' },
  ];

  typeofhistory = [
    { value: 'service', name: 'Service Type' },
    { value: 'project', name: 'Project' },
    { value: 'product', name: 'Product' },
    { value: 'priority', name: 'Priority' },
    { value: 'status', name: 'Status' },
    { value: 'assignee', name: 'Assignee' },
  ];
  typeofhistoryCustomer = [
    { value: 'service', name: 'Service Type' },
    { value: 'project', name: 'Project' },
    { value: 'product', name: 'Product' },
    { value: 'priority', name: 'Priority' },
    { value: 'status', name: 'Status' },
  ];
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (window.innerWidth < this.viewWidth) {
      this.isView = true;
    } else {
      this.isView = false;
    }
  }
  isMobileView: boolean = false;
  routeType: any = '';
  private subs = new SubSink();
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
  ) {
    this.routeType = this.activatedRoute.snapshot.params['status'];

    this.activatedRoute.data.subscribe((data) => {
      if (data.type == 'org') {
        this.callType = '300';
        // if (!this.allinoneService.isAdminOf('org')) {
        //   this.router.navigateByUrl('/home');
        // }s
      } else {
        // if (!this.allinoneService.isAdminOf('contributor')) {
        //   this.router.navigateByUrl('/home');
        // }
        this.callType = '400';
      }
    });
  }
  formatToShow = 'yyyy-MM-dd';
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async ngOnInit() {
    console.log(this.statuslist);
    console.log(this.historydata);
    if (this.historydata) {
      this.serviceid = this.historydata.serviceid;
      this.ticketno = this.historydata.ticketno;
    }

    this.enddatetime = formatDate(
      new Date(),
      this.formatToShow,
      'en-US',
    ).toString();
    var futureDate = new Date();

    futureDate.setDate(futureDate.getDate() - 7);
    this.startdatetime = formatDate(
      futureDate,
      this.formatToShow,
      'en-US',
    ).toString();
    console.log(this.enddatetime);

    // this.startdatetime = this.startdatetime;

    // await this.getAllProjects();
    if (this.serviceid) {
      this.getHistory();
    }
  }

  sortData(event: any) {}

  ssave() {
    this.save.emit();
  }
  // getPaginationList() {
  //   this.showTableData = []
  //   const data = {
  //     domainid: this.allinoneService.getDomain().domainid,
  //     orgid: this.orgid,
  //     serviceid: this.serviceid,
  //     enddate: this.enddatetime,
  //     startdate: this.startdatetime,
  //     sortby: this.sortby != "" ? this.sortby : ""
  //   };
  //   this.kunyekService.getHistory(data).subscribe(
  //     (res: any) => {
  //       console.log(res);
  //       this.showTableData = res.list;
  //       if (res.total == '') {
  //         this.total = this.total;
  //       } else {
  //         this.total = res.total;
  //       }

  //     });
  // }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      console.log('true');
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      console.log('false');
      if (event == this.lastPage && this.lastPage != '') {
        console.log('yes');
        console.log(this.lastPage);
        console.log(this.currentPage);
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        console.log('no');
        this.currentPage = event;

        if (event > this.data.length / this.itemPerPage) {
          this.getHistory();
        }
      }
    }
  }

  filter() {
    if (this.lastPage > 1) {
      this.nextDisabled = false;
    }
    this.currentPage = 1;
  }
  downloadSheet() {
    var hisdata: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');

    var name = 'Service_History_of' + this.ticketno + time + '.xlsx';

    this.data.map((his: any) => {
      hisdata.push({
        'Ticket No': this.ticketno,
        'Reported By': his.reportedbyname,
        'Start Date': his.startdatetime,
        'End Date': his.enddatetime,

        Type: his.type,
        Version: his.version,
        From: his.from,
        To: his.to,
        Remark: his.remark,
      });
    });
    console.log(hisdata);

    this.allinoneService.exportEcecl(hisdata, name);
  }

  getHistory() {
    // this.data = [];
    this.gettingData = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      serviceid: this.serviceid,
      enddate: this.enddatetime,
      startdate: '',
      pagesize: this.itemPerPage,
      sortby: this.sortby != '' ? this.sortby : '',
      customerview: this.customercare,
    };
    this.subs.sink = this.kunyekService.getHistory(data).subscribe(
      (res: any) => {
        // this.sortby = lastIndex.sortby.toString();
        var from = '';
        var to = '';
        if (res.returncode == '300') {
          console.log(res.list);
          const format = 'dd/MM/yyyy hh:mm aa';
          for (let i = 0; i < res.list.length; i++) {
            var tempStartDate =
              res.list[i].startdatetime != ''
                ? formatDate(
                    new Date(res.list[i].startdatetime),
                    format,
                    'en-US',
                  ).toString()
                : '';
            var Datetime =
              res.list[i].createdAt != ''
                ? formatDate(
                    new Date(res.list[i].createdAt),
                    format,
                    'en-US',
                  ).toString()
                : '';
            var tempEndDate =
              res.list[i].enddatetime != ''
                ? formatDate(
                    new Date(res.list[i].enddatetime),
                    format,
                    'en-US',
                  ).toString()
                : '';

            if (res.list[i].type == 'Service') {
              console.log('Service');
              from = res.list[i].servicetypefrom.name;
              to = res.list[i].servicetypeto.name;
            } else if (res.list[i].type == 'Priority') {
              // const resfrom = this.priority.filter((o: { code: any; }) => o.code == res.list[i].from);
              // const resto = this.priority.filter((o: { code: any; }) => o.code == res.list[i].to);
              // console.log(resfrom);
              // from = resfrom.length > 0 ? resfrom[0].name : '';
              // to = resto.length > 0 ? resto[0].name : '';
              from = res.list[i].servicepriorityfrom.name;
              to = res.list[i].servicepriorityto.name;
            } else if (res.list[i].type == 'Status') {
              from = res.list[i].statusfrom.name;
              to = res.list[i].statusto.name;
            } else if (res.list[i].type == 'Assignee') {
              from = res.list[i].assignedtonamefrom;
              to = res.list[i].assignedtonameto;
            } else if (res.list[i].type == 'Project') {
              // const resfrom = this.projectList.filter((o: { projectid: any; }) => o.projectid == res.list[i].from);
              // const resto = this.projectList.filter((o: { projectid: any; }) => o.projectid == res.list[i].to);
              // console.log(resfrom);
              // from = resfrom.length > 0 ? resfrom[0].name : '';
              // to = resto.length > 0 ? resto[0].name : '';
              from = res.list[i].projectfrom;
              to = res.list[i].projectto;
            } else if (res.list[i].type == 'Product') {
              const resfrom = this.productlist.filter(
                (o: { productid: any }) => o.productid == res.list[i].from,
              );
              const resto = this.productlist.filter(
                (o: { productid: any }) => o.productid == res.list[i].to,
              );
              console.log(resfrom + '>>>>');
              if (resfrom == '') {
                from = res.list[i].from;
              } else {
                from = resfrom.length > 0 ? resfrom[0].name : '';
                to = resto.length > 0 ? resto[0].name : '';
              }
              if (resto == '') {
                to = res.list[i].to;
              } else {
                to = resto.length > 0 ? resto[0].name : '';
              }
            } else {
              from = res.list[i].from;
              to = res.list[i].to;
            }

            const ageformat = 'MM/dd/yyyy';

            // var Time = agedatend.getTime() - agedayst.getTime();
            // var Days = Time / (1000 * 3600 * 24);
            console.log(res.list);
            this.data.push({
              assignedtoname: res.list[i].assignedtoname,
              startdatetime: tempStartDate,
              startdatetoshow: res.list[i].startdatetime,
              datetime: Datetime,
              enddatetime: tempEndDate,
              enddatetoshow: res.list[i].enddatetime,
              priority: res.list[i].priority,
              remark: res.list[i].remark,
              reportedby: res.list[i].reportedby,
              reportedbyname: res.list[i].reportedbyname,
              assignedto: res.list[i].assignedto,
              from: from,
              to: to,
              type:
                res.list[i].type == 'Service'
                  ? 'Service Type'
                  : res.list[i].type,
              serviceid: this.serviceid,
              sortby: res.list[i].sortby,
              version: res.list[i].version,
              age: res.list[i].age,
            });
            this.historyData = this.data;
          }

          var sort = this.data.length - 1;

          this.total = res.total;

          if (this.total == this.data.length) {
            console.log('getTrue');
            this.nextDisabled = true;
            this.lastPage = this.currentPage;
            // this.sortby =   this.sortby = this.data[sort].sortby;
          } else {
            console.log('getfalse');
            console.log(this.data[sort]);
            console.log(this.data[sort].sortby);
            this.sortby = this.data[sort].sortby;
            console.log(this.sortby);
          }

          console.log(this.data);

          this.gettingData = false;
          this.searchLoading = false;
        } else {
          this.gettingData = false;
          this.searchLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
          // resolve(false);
        }
      },
      (err) => {
        // resolve(false);
        this.searchLoading = false;
        this.gettingData = false;
      },
    );
  }

  refresh() {
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    this.getHistory();
    // this.getServiceData(this.projectid);
  }

  cancelFunction() {
    this.cancel.emit();
  }

  projectList: any = [];
  getAllProjects() {
    return new Promise((resolve) => {
      this.gettingData = true;
      this.projectList = [];
      const data = {
        orgid: this.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      };
      this.kunyekService.getAllCRMproject(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.projectList = res.data;
            console.log(this.projectList);
            resolve('true');
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
            resolve('false');
          }
        },
        (err) => {
          resolve('false');
        },
      );
    });
  }

  typeChange(event: any) {
    this.historytype = event.target.value;
  }
  clear() {}
  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    // this.p = 1;
    this.currentPage = 1;
  }

  searchDate() {
    var now = new Date();
    var daysOfYear = [];
    console.log(this.startdatetime);
    console.log(this.enddatetime);

    // for (var d = this.startdatetime; d <this.enddatetime;d++ ) {

    //   daysOfYear.push(new Date(d));
    // }
  }

  search() {
    this.subs.unsubscribe();
    this.total = 0;
    this.data = [];
    this.sortby = '';
    this.nextDisabled = false;
    this.currentPage = 1;
    this.lastPage = '';
    this.searchLoading = true;
    this.getHistory();
  }

  bytype(data: any) {
    console.log(data.value);
    if (data == 'none') {
      this.historytype = '';
      this.typename = 'Type';
    } else {
      this.historytype = data.value;
      this.typename = data.name;
    }
  }
}
