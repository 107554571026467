<div class="container-fluid">
  <div class="content">
    <div class="m-4">
      <div class="me-auto">
        <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      </div>
      <div class="d-flex gap-2 mb-2">
        <button type="button" (click)="closeForm()" class="btn btn-custom">Cancel</button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="isSaving"
          (click)="onSaved()"></app-button-loader>
        <button type="button" class="btn btn-custom" (click)="openGuestDialog()">Add Guest</button>
      </div>
      <div class="card">
        <div class="card-body m-2">
          <form [formGroup]="guestInitialForm" (ngSubmit)="onSaved()">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="member" class="form-label">Member<font color="red">*</font></label>
                <div class="input-group">
                  <input type="text" id="member" [readonly]="true" class="form-control" formControlName="member"
                    [ngClass]="{ 'is-invalid': member?.invalid && mainFormSaved }">
                  <div class="btn btn-custom me-2" (click)="checkAndOpenMemberModal()">
                    <mat-icon>add</mat-icon>
                  </div>
                </div>
                <div class="invalid-feedback"
                  *ngIf="member?.invalid || ((member?.dirty || member?.touched) && mainFormSaved)">
                  <div *ngIf="member?.errors?.required">{{requiredMessage}}</div>
                </div>
              </div>
              <div></div>
              <div class="col-md-6 mb-3">
                <label for="time" class="form-label">Time <font color="red">*</font></label>
                <input class="form-control" type="time" formControlName="time"
                  [ngClass]="{ 'is-invalid': time?.invalid && mainFormSaved }">
                <div class="invalid-feedback" *ngIf="time?.invalid && mainFormSaved">
                  <div *ngIf="time?.errors?.required">{{requiredMessage}}</div>
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="date" class="form-label">Date <font color="red">*</font></label>
                <my-date-picker [options]="datePickerOptions" formControlName="date"
                  [ngClass]="{ 'is-invalid': date?.invalid && mainFormSaved }" placeholder="DD/MM/YYYY">
                </my-date-picker>
                <div class="invalid-feedback" *ngIf="date?.invalid && mainFormSaved">
                  <div *ngIf="date?.errors?.required">{{requiredMessage}}</div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <table class="table table-responsive table-borderless mt-4 container-fluid">
        <thead class="table-header">
          <tr>
            <th>Name</th>
            <th>Contact No</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let guest of guests.controls;let i = index;" class="py-4" style="cursor: pointer;">
            <td>{{guest.value?.name}}</td>
            <td>{{guest.value?.contactNo}}</td>
            <td class=" d-flex">

              <button (click)="openGuestDialog(i)" class="btn btn-outline-primary me-2">
                <i class="fa fa-edit"></i>
              </button>

              <button (click)="deleteGuest(i)" class="btn btn-outline-primary me-2">
                <i class="fa fa-times"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Guest Dialog -->
<dialog id="guestDialog" class="card-body" style="border-radius: 8px; box-shadow: 0 2px 10px rgba(0,0,0,0.1);">
  <h2>Guest</h2>
  <form [formGroup]="guestDialogForm" (ngSubmit)="saveGuest()">
    <div class="mb-3">
      <label for="name" class="form-label">Name <font color="red">*</font></label>
      <input type="text" id="name" class="form-control" formControlName="name"
        [ngClass]="{ 'is-invalid': name?.invalid && dialogFormSaved }">
      <div class="invalid-feedback" *ngIf="name?.invalid && dialogFormSaved">
        <div *ngIf="name?.errors?.required">{{requiredMessage}}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="contactNo" class="form-label">Contact No <font color="red">*</font></label>
      <input type="text" id="contactNo" class="form-control" formControlName="contactNo"
        [ngClass]="{ 'is-invalid': contactNo?.invalid && dialogFormSaved }">
      <div class="invalid-feedback" *ngIf="contactNo?.invalid && dialogFormSaved">
        <div *ngIf="contactNo?.errors?.required">{{requiredMessage}}</div>
      </div>
    </div>
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn btn-custom" (click)="closeGuestDialog()">Close</button>
      <button type="submit" class="btn btn-custom">Save</button>
    </div>
  </form>
</dialog>