import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-office-shift',
  templateUrl: './office-shift.component.html',
  styleUrls: ['./office-shift.component.scss'],
})
export class OfficeShiftComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }
  isAdmin: boolean = false;
  addShift: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  shiftFormSubmitted: boolean = false;
  shiftListLoading: boolean = false;
  isFocus: boolean = false;
  isMobileView: boolean = false;

  searchText: string = '';
  orgid: string = '';

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  subscriptions = new SubSink();
  getListSubscription!: Subscription;

  shiftList: any = [];
  organizations: any = [];

  timeList: any = [];
  ampm: any = ['AM', 'PM'];

  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  workinghours = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
  ];

  workingminutes = [
    '00',
    '05',
    '10',
    '15',
    '20',
    '25',
    '30',
    '35',
    '40',
    '45',
    '50',
    '55',
  ];

  shiftForm = {
    shiftid: '',
    name: '',
    description: '',
    starthrmin: '09:00',
    starttime: 'AM',
    endhrmin: '05:30',
    endtime: 'PM',
    breakhours: '00',
    breakhoursmin: '00',
    workinghours: '08',
    workinghoursmin: '30',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    this.onResize(event);
    this.route.data.subscribe((data) => {
      if (data.role == 'admin') {
        this.isAdmin = true;
        if (!this.allinoneService.isAdminOf('hr')) {
          this.router.navigateByUrl('/hxm');
        }
      } else {
        this.allinoneService.isOfficeView = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.allinoneService.isOfficeView = false;
  }

  ngOnInit(): void {
    for (let i = 0; i <= 12; i++) {
      for (let j = 0; j <= 55; j += 5) {
        var hr = i < 10 ? '0' + i.toString() : i.toString();
        var min = j < 10 ? '0' + j.toString() : j.toString();
        this.timeList.push(hr + ':' + min);
      }
    }
    if (this.isAdmin) {
      this.organizations = this.allinoneService.orgs;
      this.orgid = this.allinoneService.selectedOrg
        ? this.allinoneService.selectedOrg
        : this.organizations[0].orgid;
    } else {
      this.organizations = this.allinoneService.orgsCRm;
      this.orgid = this.allinoneService.selected_orgcrm
        ? this.allinoneService.selected_orgcrm.orgid
        : this.organizations[0].orgid;
    }
    this.getShiftList();
  }

  getShiftList() {
    this.shiftListLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getListSubscription && this.getListSubscription.unsubscribe();
    this.getListSubscription = this.kunyekService.getShift(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.shiftList = res.datalist;
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.shiftListLoading = false;
      },
      (err) => {
        this.shiftListLoading = false;
      }
    );
  }

  export() {
    var exdata: any = [];
    var name = 'Shift.xlsx';

    var filteredData = this.filterPipe.transform(
      this.shiftList,
      this.searchText,
      'shiftList'
    );

    filteredData.map((item: any) => {
      var temp = {
        Name: item.name,
        Description: item.description,
        'Start Time': item.starttime,
        'End Time': item.endtime,
        'Break Hours': item.breakhours,
        'Working Hours': item.workinghours,
      };
      exdata.push(temp);
    });

    this.allinoneService.exportEcecl(exdata, name);
  }

  newShift() {
    this.addShift = true;
  }

  timeChange(type: string) {
    let tempStartTime =
      this.shiftForm.starthrmin + ' ' + this.shiftForm.starttime;
    let tempEndTime = this.shiftForm.endhrmin + ' ' + this.shiftForm.endtime;
    let now = this.allinoneService.formatDBToShowInEdit(
      this.allinoneService.getCurrentDateToDB(), '/'
    );
    let tempStartDate = new Date(now + ' ' + tempStartTime);
    let tempEndDate = new Date(now + ' ' + tempEndTime);
    let checkEndDate = tempStartDate < tempEndDate;

    if (
      (this.shiftForm.starttime == this.shiftForm.endtime && !checkEndDate) ||
      (this.shiftForm.starttime == 'PM' && this.shiftForm.endtime == 'AM')
    ) {
      tempEndDate.setDate(tempEndDate.getDate() + 1);
    }

    let workingHour = Number(this.shiftForm.workinghours);
    let workingMin = Number(this.shiftForm.workinghoursmin);

    if (type == 'start') {
      tempEndDate.setHours(tempStartDate.getHours() + workingHour);
      tempEndDate.setMinutes(tempStartDate.getMinutes() + workingMin);
      let finalEndHour = tempEndDate.getHours();
      let finalEndMin = tempEndDate.getMinutes();
      if (finalEndHour > 12) {
        let ctime = finalEndHour - 12;
        this.shiftForm.endhrmin =
          (ctime < 10 ? '0' + ctime.toString() : ctime.toString()) +
          ':' +
          (finalEndMin < 10
            ? '0' + finalEndMin.toString()
            : finalEndMin.toString());
        this.shiftForm.endtime = 'PM';
      } else {
        let ctime = finalEndHour;
        this.shiftForm.endhrmin =
          (ctime < 10 ? '0' + ctime.toString() : ctime.toString()) +
          ':' +
          (finalEndMin < 10
            ? '0' + finalEndMin.toString()
            : finalEndMin.toString());
        this.shiftForm.endtime = 'AM';
      }
    } else if (type == 'end') {
      let diffDate = Math.abs(tempEndDate.getTime() - tempStartDate.getTime());
      let diffHrs = Math.floor(diffDate / 3600000);
      let diffMins = Math.round(((diffDate % 86400000) % 3600000) / 60000);
      this.shiftForm.workinghours =
        diffHrs < 10 ? '0' + diffHrs.toString() : diffHrs.toString();
      this.shiftForm.workinghoursmin =
        diffMins < 10 ? '0' + diffMins.toString() : diffMins.toString();
    } else {
      tempEndDate.setHours(tempStartDate.getHours() + workingHour);
      tempEndDate.setMinutes(tempStartDate.getMinutes() + workingMin);
      let finalEndHour = tempEndDate.getHours();
      let finalEndMin = tempEndDate.getMinutes();
      if (finalEndHour > 12) {
        let ctime = finalEndHour - 12;
        this.shiftForm.endhrmin =
          (ctime < 10 ? '0' + ctime.toString() : ctime.toString()) +
          ':' +
          (finalEndMin < 10
            ? '0' + finalEndMin.toString()
            : finalEndMin.toString());
        this.shiftForm.endtime = 'PM';
      } else {
        let ctime = finalEndHour;
        this.shiftForm.endhrmin =
          (ctime < 10 ? '0' + ctime.toString() : ctime.toString()) +
          ':' +
          (finalEndMin < 10
            ? '0' + finalEndMin.toString()
            : finalEndMin.toString());
        this.shiftForm.endtime = 'AM';
      }
    }

    if (tempStartDate > tempEndDate) {
      this.shiftForm.endhrmin = this.shiftForm.starthrmin;
      this.shiftForm.endtime = this.shiftForm.endtime;
    }
  }

  cancel() {
    this.addShift = false;
    this.shiftFormSubmitted = false;
    this.isEditing = false;
    this.shiftForm = {
      shiftid: '',
      name: '',
      description: '',
      starthrmin: '09:00',
      starttime: 'AM',
      endhrmin: '05:30',
      endtime: 'PM',
      breakhours: '00',
      breakhoursmin: '00',
      workinghours: '08',
      workinghoursmin: '30',
    };
  }

  submitShift() {
    this.shiftFormSubmitted = true;
    if (this.shiftForm.name == '') {
      return;
    }

    if(this.shiftForm.workinghours == "00" && this.shiftForm.workinghoursmin == "00"){
      this.messageService.openSnackBar(
        "Invalid Working Hours",
        'warn'
      );
      return;
    }
    this.saveShift();
  }

  saveShift() {
    this.saveLoading = true;
    const data = {
      orgid: this.orgid,
      shiftid: this.shiftForm.shiftid,
      name: this.shiftForm.name.toString().trim(),
      description: this.shiftForm.description.toString().trim(),
      starttime: this.shiftForm.starthrmin + ' ' + this.shiftForm.starttime,
      endtime: this.shiftForm.endhrmin + ' ' + this.shiftForm.endtime,
      workinghours:
        this.shiftForm.workinghours + ':' + this.shiftForm.workinghoursmin,
      breakhours:
        this.shiftForm.breakhours + ':' + this.shiftForm.breakhoursmin,
    };

    console.log(`Data: ${JSON.stringify(data)}`);
    var fun = this.isEditing
      ? this.kunyekService.updateShift(data)
      : this.kunyekService.createShift(data);
    this.subscriptions.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (this.isEditing) {
            var index = this.shiftList.findIndex(
              (x: any) => x.shiftid == this.shiftForm.shiftid
            );
            if (index > -1) {
              this.shiftList[index].name = data.name;
              this.shiftList[index].description = data.description;
              this.shiftList[index].starttime = data.starttime;
              this.shiftList[index].endtime = data.endtime;
              this.shiftList[index].workinghours = data.workinghours;
              this.shiftList[index].breakhours = data.breakhours;
            } else {
              this.refresh();
            }
          } else {
            var tempShift = { ...data, shiftid: res.shiftid };
            this.shiftList.push(tempShift);
          }
          this.cancel();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    );
  }

  sortData(sort: Sort) {
    const data = this.shiftList;
    if (!sort.active || sort.direction === '') {
      this.shiftList = data;
      return;
    }
    this.shiftList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.allinoneService.compare(a.name, b.name, isAsc);
        case 'description':
          return this.allinoneService.compare(
            a.description,
            b.description,
            isAsc
          );
        case 'starttime':
          return this.allinoneService.compare(a.starttime, b.starttime, isAsc);
        case 'endtime':
          return this.allinoneService.compare(a.endtime, b.endtime, isAsc);
        case 'breakhours':
          return this.allinoneService.compare(
            a.breakhours,
            b.breakhours,
            isAsc
          );
        case 'workinghours':
          return this.allinoneService.compare(
            a.workinghours,
            b.workinghours,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  editShift(shift: any) {
    this.isEditing = true;
    this.addShift = true;
    this.shiftForm = {
      shiftid: shift.shiftid,
      name: shift.name,
      description: shift.description,
      starthrmin: shift.starttime.split(' ')[0],
      starttime: shift.starttime.split(' ')[1],
      endhrmin: shift.endtime.split(' ')[0],
      endtime: shift.endtime.split(' ')[1],
      breakhours: shift.breakhours.split(':')[0],
      breakhoursmin: shift.breakhours.split(':')[1],
      workinghours: shift.workinghours.split(':')[0],
      workinghoursmin: shift.workinghours.split(':')[1],
    };
  }

  deleteShift(shiftid: string, index: number) {
    //don't use index
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // Delete Here
        this.shiftListLoading = true;
        const data = {
          orgid: this.orgid,
          shiftid: shiftid,
        };
        this.kunyekService.deleteShift(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              var i = this.shiftList.findIndex(
                (x:any) => x.shiftid == shiftid
              );
              this.shiftList.splice(i, 1);
              // this.shiftList.splice(index, 1);
              
              if (
                Math.round(this.shiftList.length / this.itemPerPage) <
                this.currentPage
              ) {
                this.currentPage = this.currentPage - 1;
              }
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.shiftListLoading = false;
          },
          (err) => {
            this.shiftListLoading = false;
          }
        );
      }
    });
  }

  refresh() {
    this.currentPage = 1;
    this.getShiftList();
  }

  searchTextChange() {
    this.currentPage = 1;
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {
    this.itemPerPage = event.target.value;
    this.currentPage = 1;
  }
}
