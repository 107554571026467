<!-- <button (click)="test()">test</button> -->
<div class="container-fluid">
  <div class="content">
    <!-- breadcrumb -->
    <ng-container *ngIf="!lateForm && !earlyOutForm">
      <app-office-breadcrumb *ngIf="!addingRequest && !transportationView && !transportationDetail "
        [s_breadcrumb]="breadcrumb">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addingRequest && !transportationView && !transportationDetail "
        [s_breadcrumb]="breadcrumb " [t_breadcrumb]="isEditing ? 'Ref No. ' + requestForm.refno : 'New Request'"
        (emit)="cancelRequest()">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="transportationView && !transportationDetail" [s_breadcrumb]="breadcrumb"
        [t_breadcrumb]="'Ref No. ' + requestForm.refno" (emit)="transportationcancel()"></app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="!transportationView && transportationDetail" [s_breadcrumb]="breadcrumb"
        [t_breadcrumb]="'Ref No. ' + tranrefno" [f_breadcrumb]="'Details'" (emit)="cancelToRequest()"
        (emit1)="transportationcancel2()" (emit2)="transportationcancel2()"></app-office-breadcrumb>
    </ng-container>
    <ng-container *ngIf="lateForm">
      <app-office-breadcrumb *ngIf="!addingRequest" [s_breadcrumb]="'Late Request'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addingRequest" [s_breadcrumb]="'Late Request'"
        [t_breadcrumb]="isEditing ? 'Ref No. ' + requestForm.refno : 'New Request'" (emit)="cancelRequest()">
      </app-office-breadcrumb>
    </ng-container>

    <ng-container *ngIf="earlyOutForm">
      <app-office-breadcrumb *ngIf="!addingRequest" [s_breadcrumb]="'Early Out Request'">
      </app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="addingRequest" [s_breadcrumb]="'Early Out Request'"
        [t_breadcrumb]="isEditing ? 'Ref No. ' + requestForm.refno : 'New Request'" (emit)="cancelRequest()">
      </app-office-breadcrumb>
    </ng-container>
    <!-- request table view -->
    <ng-container *ngIf="!addingRequest && !transportationView">
      <div class="d-flex align-items-center">
        <button return="button" class="btn btn-custom mb-3 me-2" (click)="newRequest()" [disabled]="gettingTemplate">
          New Request
        </button>
        <!-- <button return="button" class="btn btn-custom mb-3 me-2" (click)="backDateRequest()"
          *ngIf="lateForm || earlyOutForm">
          {{lateForm ? 'Late List' : 'Early Out List'}}
        </button> -->
        <div class="align-items-center mb-3 me-2" [matMenuTriggerFor]="statusmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" return="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ searchForm.requeststatusname }}
            <mat-menu #statusmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                {{ status.name }}
              </button>
            </mat-menu>
          </button>
        </div>
        <ng-container *ngIf="searchForm.startdate && searchForm.enddate">
          <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group">
            <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
              Date: {{ searchForm.startdate | date : "dd/MM/yyyy" }} -
              {{ searchForm.enddate | date : "dd/MM/yyyy" }}
            </button>
            <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
              <img src="assets/images/icon.png" alt="" style="width: 11px; height: 11px; margin-top: -3px" />
            </button>
          </div>
          <div class="me-2 btn-group mb-3 d-flex align-items-center" role="group" *ngIf="!leaveForm">
            <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
              Request Type: {{ searchForm.requesttypename }}
            </button>
            <button (click)="clearFilter()" type="button" class="btn btn-secondary advanced-filter-btn">
              <img src="assets/images/icon.png" alt="" style="width: 11px; height: 11px; margin-top: -3px" />
            </button>
          </div>
        </ng-container>
      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Ref No./Type/Status"
            aria-label="Search by name" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            (ngModelChange)="searchTextChange()" [ngClass]="{ 'c-search-input-with-clear': searchText }"
            style="font-family: FontAwesome, Ubuntu" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="filter()" title="Filter" [disabled]="gettingTemplate">
            <i class="fa fa-filter"></i>
          </button>
        </div>

        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (requestList | filter : searchText : 'requestList').length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Ref No./Type/Status"
            aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <!-- <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
            *ngIf="requestList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls> -->
        <pagination-template class="ms-auto" *ngIf="requestList.length > 0" #p="paginationApi"
          (pageChange)="pageChange($event)">
          <div class="d-flex align-items-center justify-content-center">
            <div [class]="
          p.isFirstPage()
            ? 'custom-pagination-disabled'
            : 'custom-pagination'
        " (click)="p.previous()">
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div [class]="
          nextDisabled
            ? 'custom-pagination-disabled'
            : 'custom-pagination'
        " (click)="p.next()">
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="refno">
                Ref No.
              </td>
              <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="date">
                Date
              </td>
              <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="type">
                Type
              </td>
              <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="status">
                Status
              </td>
              <td scope="col" class="col-lg-4 col-md-4"></td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>

          <tbody *ngIf="gettingRequestList">
            <tr>
              <td colspan="8" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!gettingRequestList">
            <tr *ngIf="requestList.length == 0">
              <td colspan="8" class="nodata">Empty</td>
            </tr>

            <tr *ngFor="
                let request of requestList
                  | filter : searchText : 'requestList'
                  | paginate
                    : { itemsPerPage: itemPerPage, currentPage: currentPage };
                let index = index
              ">
              <td class="td-data td-edit" (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                <span [title]="request.requestrefno">{{
                  request.requestrefno
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                <span
                  *ngIf="request.requesttype != this.reservationid  && request.requesttype != this.lateid && request.requesttype != this.earlyoutid && request.requesttype != this.offinlieuid"
                  [title]="
                request.claimdate ? allinoneService.formatDBToShow(request.claimdate) : request.requesttype == 'VMAOWEFAMSVNAOWI' ?  allinoneService.formatDBToShow(request.startdate) :
                allinoneService.formatDBToShow(request.startdate) + ' - '
                +allinoneService.formatDBToShow(request.enddate)">
                  {{ request.claimdate ? allinoneService.formatDBToShow(request.claimdate) : request.requesttype ==
                  'VMAOWEFAMSVNAOWI' ? allinoneService.formatDBToShow(request.startdate) :
                  allinoneService.formatDBToShow(request.startdate) + " - "
                  +allinoneService.formatDBToShow(request.enddate)}}</span>
                <span *ngIf="request.requesttype == this.reservationid && request.reservationtype == '001'">
                  {{allinoneService.formatDBToShow(request.startdate)}}
                </span>
                <span *ngIf="request.requesttype == this.reservationid && request.reservationtype == '002'">
                  {{allinoneService.formatDBToShow(request.startdate)}} -
                  {{allinoneService.formatDBToShow(request.enddate)}}
                </span>
                <span
                  *ngIf="request.requesttype == this.lateid || request.requesttype == this.earlyoutid || request.requesttype == this.offinlieuid">
                  {{allinoneService.formatDBToShow(request.startdate)}}
                </span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                <span [title]="request.requesttypename" style="color: var(--main-color)">{{ request.requesttypename
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                <!-- <span [title]="request.requeststatusname" class="status" [ngClass]="{
                    'status-green': request.requeststatus == '002' && request.requesttype != 'ANVAHSEFAMVAJDAJ',
                    'status-red': request.requeststatus == '003' && request.requesttype != 'ANVAHSEFAMVAJDAJ',
                    'status-gray': request.requeststatus == '001' && request.requesttype != 'ANVAHSEFAMVAJDAJ',
                    'status-main': request.requeststatus == '004' && request.requesttype != 'ANVAHSEFAMVAJDAJ'
                  }">{{ request.requesttype == 'ANVAHSEFAMVAJDAJ' ? '-' : request.requeststatusname }}</span> -->
                <span [title]="request.requeststatusname" class="status"
                  *ngIf="request.requesttype != 'ANVAHSEFAMVAJDAJ' " [ngClass]="{
                    'status-green': request.requeststatus == '002',
                    'status-red': request.requeststatus == '003',
                    'status-gray': request.requeststatus == '001', 
                    'status-main': request.requeststatus == '004' 
                  }">{{ request.requeststatusname }}</span>
                <span *ngIf="request.requesttype == 'ANVAHSEFAMVAJDAJ' ">-</span>
                <!-- <span class="status ms-2" *ngIf="
                    request.requeststatus == '002' &&
                    request.requesttype == this.claimid
                  " [ngClass]="{
                    'status-green': request.paymentstatus,
                    'status-red': !request.paymentstatus
                  }">{{ request.paymentstatus ? "Paid" : "Unpaid" }}</span> -->
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                <span [title]="request.requestremark">{{
                  request.requestremark
                  }}</span>
              </td>
              <td class="d-flex td-data justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2"
                  (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ')">
                  <i class="fa fa-edit"></i>
                </button>
                <button *ngIf=" request.requesttype != 'AVASDAFNMVHSJESAM'" class="btn btn-outline-danger"
                  (click)="deleteRequest(request.requestid)"
                  [disabled]="request.requesttype == 'ANVAHSEFAMVAJDAJ' || request.requeststatus != '001' ">
                  <i class="fa fa-trash-o"></i>
                </button>
                <button *ngIf=" request.requesttype == 'AVASDAFNMVHSJESAM'" class="btn btn-outline-danger"
                  (click)="deleteRequest(request.requestid)" [disabled]="!request.reservation">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <!-- request add form -->
    <ng-container *ngIf="addingRequest && !transportationView">
      <div class="d-flex align-items-center mb-3">
        <button type="button" class="btn btn-custom me-2"
          (click)="cancelRequest(requestForm.requesttype == transportationid )" *ngIf="!saveLoading">
          Cancel
        </button>
        <app-button-loader (click)="submitRequest()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading" *ngIf="!requestForm.readonly || requestForm.reservation">
        </app-button-loader>

        <button class="btn btn-custom ms-auto" type="button" (click)="openLeaveSummaryForm()"
          *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE' && !isEditing">
          <i class="fas fa-clipboard-list me-2"></i>Leave Summary
        </button>
        <div class="d-flex align-items-center ms-auto"
          *ngIf="isEditing && requestForm.requesttype != this.reservationid && requestForm.requesttype != transportationid ">
          <span [title]="requestForm.requeststatusname" class="status" [ngClass]="{
                'status-green': requestForm.requeststatus == '002',
                'status-red': requestForm.requeststatus == '003',
                'status-gray': requestForm.requeststatus == '001',
                'status-main': requestForm.requeststatus == '004'}">
            <span *ngIf="
                  (requestForm.requeststatus == '002' ||
                  requestForm.requeststatus == '003')">
              {{ requestForm.requeststatusname }} by:
              {{ requestForm.personname || requestForm.rejectperson }}
            </span>
            <span *ngIf=" requestForm.requeststatus != '002' &&
                  requestForm.requeststatus != '003'">
              {{ requestForm.requeststatusname }}
            </span>
          </span>
        </div>
      </div>
      <div class="row">
        <ng-container>
          <div class="mb-3 col-md-12"
            *ngIf="!leaveForm && !claimForm && !lateForm && !earlyOutForm && !workFromHomeForm && !offInLieuForm && !cashAdvancedForm">
            <label class="form-label" for="requesttype"> Type </label>
            <select class="form-select" id="requesttype" name="requesttype" [(ngModel)]="requestForm.requesttype"
              [disabled]="gettingTemplate || requestTypeList.length == 0 || (requestForm.readonly && !requestForm.reservation) || (requestForm.requesttype == transportationid && isEditing )"
              (ngModelChange)="requestTypeChange()">
              <option *ngFor="let type of requestTypeList" [value]="type.requesttypeid">
                {{ type.requesttypename }}
              </option>
            </select>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingTemplate"></mat-progress-bar>
          </div>
        </ng-container>
        <ng-container
          *ngIf="requestForm.requesttype == lateid || requestForm.requesttype == earlyoutid || requestForm.requesttype == workfromhomeid || requestForm.requesttype == offinlieuid; else otherTemplate">
          <ng-container *ngIf="requestForm.requesttype == lateid || requestForm.requesttype == earlyoutid">
            <app-late-earlyout-request #lateEarlyOut [requestData]=" requestForm" [isEditing]="isEditing"
              [userid]="requestForm.userid" [orgid]="requestForm.orgid"
              [isEarlyout]="requestForm.requesttype == earlyoutid" [approverList]="approverList"
              (save)="submitLateEarlyOut($event)"></app-late-earlyout-request>
          </ng-container>

          <ng-container *ngIf="requestForm.requesttype == workfromhomeid">
            <app-work-from-home-request #workFromHome [requestData]="requestForm" [isEditing]="isEditing"
              [userid]="requestForm.userid" [orgid]="requestForm.orgid" [approverList]="approverList"
              (save)="submitWorkFromHome($event)"></app-work-from-home-request>
          </ng-container>
          <ng-container *ngIf="requestForm.requesttype == offinlieuid">
            <app-off-in-lieu-request #offInLieu [requestData]="requestForm" [isEditing]="isEditing"
              [userid]="requestForm.userid" [orgid]="requestForm.orgid" [approverList]="approverList"
              (save)="submitOffInLieu($event)"></app-off-in-lieu-request>
          </ng-container>

        </ng-container>

        <!-- <ng-container *ngIf="requestForm.requesttype == workfromhomeid;else otherTemplate">
          <app-work-from-home-request [requestData]="requestForm" [isEditing]="isEditing" [userid]="requestForm.userid"
          [orgid]="requestForm.orgid" [approverList]="approverList"
          (save)="submitLateEarlyOut($event)" ></app-work-from-home-request>
        </ng-container> -->

        <ng-template #otherTemplate>
          <div class="mb-3 col-md-12" *ngIf="
          requestForm.requesttype == 'NCMLEIWHRNVIE' ||
          requestForm.requesttype == this.claimid ||
          requestForm.requesttype == this.reservationid ||
          requestForm.requesttype == this.hrid ||
          requestForm.requesttype == this.transportationid && !isEditing">
            <label class="form-label" for="subtype">
              {{ requestForm.requesttype == "NCMLEIWHRNVIE" ? "Leave" : requestForm.requesttype == this.claimid ?
              "Claim" : requestForm.requesttype == this.transportationid ? "Transportation" : requestForm.requesttype == hrid ? 'HR' : "Reservation"}}
              Type
            </label>
            <select class="form-select" id="subtype" name="subtype" [(ngModel)]="requestForm.subtype"
              [disabled]="requestSubTypeList.length == 0 || (requestForm.readonly && !requestForm.reservation) || (requestForm.requesttype == transportationid && isEditing )"
              (ngModelChange)="getTranType(requestForm.subtype)">
              <option *ngFor="let type of requestSubTypeList" [value]="type.requesttypeid">
                {{ type.requesttypename }}
              </option>
            </select>
          </div>
          <div class="mb-3 col-md-12" *ngIf="
          requestForm.requesttype == this.transportationid && isEditing">
            <label class="form-label" for="subtype">
              {{ requestForm.requesttype == "NCMLEIWHRNVIE" ? "Leave" : requestForm.requesttype == this.claimid ?
              "Claim" : requestForm.requesttype == this.transportationid ? "Transportation" : "Reservation"}}
              Type
            </label>
            <input class="form-control" type="text" id="subtype" [(ngModel)]="transubtypename" name="subtype"
              disabled />
          </div>
          <ng-container *ngIf="requestForm.requesttype == 'VMAOWEFAMSVNAOWI'">
            <div class="mb-3 col-md-6">
              <label for="startdate" class="form-label">Date</label>
              <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate"
                name="startdate" [disabled]="requestForm.readonly" max="9999-12-31" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="starttime" class="form-label">Time</label>
              <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime"
                name="starttime" [disabled]="requestForm.readonly" />
            </div>
          </ng-container>
          <ng-container *ngIf="(requestForm.requesttype == this.reservationid && requestForm.reservationtype == '001')">
            <div class="mb-3 col-md-6">
              <label for="startdate" class="form-label">Date</label>
              <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate"
                name="startdate" [disabled]="(requestForm.readonly && !requestForm.reservation)" max="9999-12-31"
                (ngModelChange)="getAvailableRoomAndDesk()" />
            </div>
            <div class="mb-3 col-md-3">
              <label for="starttime" class="form-label">Start Time</label>
              <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime"
                name="starttime" [disabled]="(requestForm.readonly && !requestForm.reservation)"
                (ngModelChange)="getAvailableRoomAndDesk()" />
            </div>
            <div class="mb-3 col-md-3">
              <label for="endtime" class="form-label">End Time</label>
              <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
                [disabled]="(requestForm.readonly && !requestForm.reservation)"
                (ngModelChange)="getAvailableRoomAndDesk()" />
            </div>
            <div class="mb-3 col-md-6" *ngIf="(!requestForm.readonly || requestForm.reservation)">
              <label for="room" class="form-label">Room</label>
              <select [(ngModel)]="requestForm.roomid" class="form-select" name="room" id="room"
                [disabled]="gettingAvailableRoomAndDesk" (ngModelChange)="roomChange()">
                <option value="">-</option>
                <option *ngFor="let item of availableRoom" [value]="item.roomid">
                  {{ item.name }}
                </option>
              </select>
              <mat-progress-bar mode="indeterminate" color="primary"
                *ngIf="gettingAvailableRoomAndDesk"></mat-progress-bar>
            </div>
            <div class="mb-3 col-md-6" *ngIf="(requestForm.readonly && !requestForm.reservation)">
              <label for="room" class="form-label">Room</label>
              <input class="form-control" type="text" id="room" [(ngModel)]="requestForm.roomname" name="room"
                disabled />
            </div>
            <div class="mb-3 col-md-6">
              <label for="maxpeople" class="form-label">Max No. of People</label>
              <input class="form-control" type="text" id="maxpeople" [(ngModel)]="requestForm.maxpeople"
                name="maxpeople" disabled />
            </div>
          </ng-container>
          <ng-container *ngIf="(requestForm.requesttype == this.reservationid && requestForm.reservationtype == '002')">
            <div class="mb-3 col-md-3">
              <label for="startdate" class="form-label">Start Date</label>
              <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate"
                name="startdate" [disabled]="(requestForm.readonly && !requestForm.reservation)" max="9999-12-31"
                (ngModelChange)="getAvailableRoomAndDesk()" />
            </div>
            <div class="mb-3 col-md-3">
              <label for="starttime" class="form-label">Start Time</label>
              <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime"
                name="starttime" [disabled]="(requestForm.readonly && !requestForm.reservation)"
                (ngModelChange)="getAvailableRoomAndDesk()" />
            </div>
            <div class="mb-3 col-md-3">
              <label for="enddate" class="form-label">End Date</label>
              <input class="form-control" type="date" id="enddate" [(ngModel)]="requestForm.enddate" name="enddate"
                [disabled]="(requestForm.readonly && !requestForm.reservation)" max="9999-12-31"
                (ngModelChange)="getAvailableRoomAndDesk()" />
            </div>
            <div class="mb-3 col-md-3">
              <label for="endtime" class="form-label">End Time</label>
              <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
                [disabled]="(requestForm.readonly && !requestForm.reservation)"
                (ngModelChange)="getAvailableRoomAndDesk()" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="room" class="form-label">Floor</label>
              <select [(ngModel)]="requestForm.workstationid" class="form-select" name="workstation" id="workstation"
                [disabled]="(requestForm.readonly && !requestForm.reservation) || gettingWorkstation"
                (ngModelChange)="workstationChange()">
                <option value="">-</option>
                <option *ngFor="let item of workstationList" [value]="item.workstationid">
                  {{ item.floorname }}
                </option>
              </select>
              <mat-progress-bar mode="indeterminate" color="primary" *ngIf="gettingWorkstation"></mat-progress-bar>
            </div>
            <div class="mb-3 col-md-6" *ngIf="(!requestForm.readonly || requestForm.reservation)">
              <label for="desk" class="form-label">Desk</label>
              <select [(ngModel)]="requestForm.deskid" class="form-select" name="desk" id="desk"
                [disabled]="gettingAvailableRoomAndDesk">
                <option value="">-</option>
                <option *ngFor="let item of availableDesk" [value]="item" [selected]="requestForm.deskid == item">
                  {{ item }}
                </option>
              </select>
              <mat-progress-bar mode="indeterminate" color="primary"
                *ngIf="gettingAvailableRoomAndDesk"></mat-progress-bar>
            </div>
            <div class="mb-3 col-md-6" *ngIf="(requestForm.readonly && !requestForm.reservation)">
              <label for="desk" class="form-label">Desk</label>
              <input class="form-control" type="text" id="desk" [(ngModel)]="requestForm.deskid" name="desk" disabled />
            </div>
          </ng-container>
          <ng-container *ngIf="
            requestForm.requesttype == 'NCMLEIWHRNVIE' ||
            requestForm.requesttype == 'UEYVNDLOAPQWXQW' || 
            requestForm.requesttype == this.transportationid
            
          ">
            <div *ngIf="(requestForm.requesttype == this.transportationid && isEditing)" class="mb-3 col-md-12">
              <label for="startdate" class="form-label">Date</label>
              <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.date" name="startdate"
                max="9999-12-31" disabled />
            </div>
            <div class="mb-3 col-md-12"
              *ngIf="requestForm.requesttype == transportationid && requestForm.trantype== '001'">
              <label class="form-label" for="pickuppoint">Pick Up Points
              </label>
              <select class="form-select" id="pickuppoint" name="pickuppoint" [(ngModel)]="requestForm.pickuppoint"
                [disabled]="gettingPickUp || requestForm.readonly">
                <option value="" selected>-</option>
                <option *ngFor="let type of pickupList" [value]="type.pickuppointid">
                  {{ type.name }}
                </option>
              </select>
              <mat-progress-bar mode="indeterminate" *ngIf="gettingPickUp"></mat-progress-bar>
            </div>

            <div
              *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE' || requestForm.requesttype == 'UEYVNDLOAPQWXQW' || (requestForm.requesttype == this.transportationid && !isEditing)"
              class="mb-3" [ngClass]="{
              'col-md-4': requestForm.requesttype == 'NCMLEIWHRNVIE',
              'col-md-3': requestForm.requesttype == 'UEYVNDLOAPQWXQW',
              'col-md-6' : requestForm.requesttype == this.transportationid
            }">
              <label for="startdate" class="form-label">Start Date</label>
              <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate"
                name="startdate" (ngModelChange)="calculateDuration()" max="9999-12-31"
                [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-2" *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE'">
              <label for="starttime" class="form-label">Start Time</label>
              <select [(ngModel)]="requestForm.starttimeampm" [disabled]="requestForm.readonly" class="form-select"
                name="starttime" id="starttime" (ngModelChange)="calculateDuration()">
                <option *ngFor="let item of timeList" [value]="item">
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="mb-3 col-md-3" *ngIf="requestForm.requesttype == 'UEYVNDLOAPQWXQW' ">
              <label for="starttime" class="form-label">Start Time</label>
              <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime"
                name="starttime" (ngModelChange)="calculateDuration()" [disabled]="requestForm.readonly" />
            </div>
            <div
              *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE' || requestForm.requesttype == 'UEYVNDLOAPQWXQW' || (requestForm.requesttype == this.transportationid && !isEditing)"
              class="mb-3" [ngClass]="{
              'col-md-4': requestForm.requesttype == 'NCMLEIWHRNVIE',
              'col-md-3': requestForm.requesttype == 'UEYVNDLOAPQWXQW',
              'col-md-6' : requestForm.requesttype == this.transportationid
            }">
              <label for="enddate" class="form-label">End Date</label>
              <input class="form-control" type="date" id="enddate" [min]="requestForm.startdate"
                [(ngModel)]="requestForm.enddate" max="9999-12-31" name="enddate" (ngModelChange)="calculateDuration()"
                [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-2" *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE'">
              <label for="startdate" class="form-label">End Time</label>
              <select [(ngModel)]="requestForm.endtimeampm" class="form-select" [disabled]="requestForm.readonly"
                (ngModelChange)="calculateDuration(true)">
                <option *ngFor="let item of timeList" [value]="item">
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="mb-3 col-md-3" *ngIf="requestForm.requesttype == 'UEYVNDLOAPQWXQW'">
              <label for="endtime" class="form-label">End Time</label>
              <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
                (ngModelChange)="calculateDuration(true)" [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-12" *ngIf="requestForm.requesttype == 'NCMLEIWHRNVIE'">
              <label for="duration" class="form-label">Duration</label>
              <input class="form-control" type="text" id="duration" [(ngModel)]="requestForm.duration" name="duration"
                disabled />
              <mat-progress-bar mode="indeterminate" color="primary" *ngIf="gettingDuration"></mat-progress-bar>
            </div>
            <div class="mb-3 col-md-12" *ngIf="requestForm.requesttype == 'UEYVNDLOAPQWXQW'">
              <label for="overtimehour" class="form-label">Hour</label>
              <input class="form-control" type="text" id="overtimehour" [(ngModel)]="requestForm.overtimehour"
                name="overtimehour" disabled />
            </div>
          </ng-container>
          <ng-container *ngIf="requestForm.requesttype == this.claimid || requestForm.requesttype == cashadvanced ">
            <div class="mb-3 col-md-6">
              <label for="startdate" class="form-label">Date</label>
              <input class="form-control" type="date" id="startdate" max="9999-12-31"
                [(ngModel)]="requestForm.startdate" name="startdate" [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-4">
              <label for="amount" class="form-label">Amount</label>
              <input class="form-control" name="amount" id="amount" type="number" [(ngModel)]="requestForm.amount"
                [min]="0" (keypress)="($event.charCode != 45)" [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-2">
              <label for="currency" class="form-label">Currency</label>
              <select [(ngModel)]="requestForm.currency" class="form-select" name="currency" id="currency"
                [disabled]="requestForm.readonly">
                <option *ngFor="let item of currencyList" [value]="item.currencyid">
                  {{ item.currencyname }}
                </option>
              </select>
            </div>
            <ng-container *ngIf="requestForm.taxifare">
              <div class="mb-3 col-md-6">
                <label for="claimfrom" class="form-label">From Place</label>
                <input class="form-control" type="text" id="claimfrom" name="claimfrom"
                  [(ngModel)]="requestForm.claimfromplace" [disabled]="requestForm.readonly" />
              </div>
              <div class="mb-3 col-md-6">
                <label for="claimto" class="form-label">To Place</label>
                <input class="form-control" type="text" id="claimto" name="claimto"
                  [(ngModel)]="requestForm.claimtoplace" [disabled]="requestForm.readonly" />
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="requestForm.requesttype == travelrequestid">
            <div class="mb-3 col-md-3">
              <!-- <label for="startdate" class="form-label">Planned Departure Date</label> -->
              <label for="startdate" class="form-label">Departure Date</label>
              <input class="form-control" type="date" id="startdate" [(ngModel)]="requestForm.startdate"
                name="startdate" (ngModelChange)="calculateDuration()" max="9999-12-31"
                [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-3">
              <label for="starttime" class="form-label">Departure Time</label>
              <input class="form-control" type="time" id="starttime" [(ngModel)]="requestForm.starttime"
                name="starttime" [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-3">
              <!-- <label for="enddate" class="form-label">Planned Return Date</label> -->
              <label for="enddate" class="form-label">Arrival Date</label>
              <input class="form-control" type="date" max="9999-12-31" id="enddate" [min]="requestForm.startdate"
                [(ngModel)]="requestForm.enddate" name="enddate" (ngModelChange)="calculateDuration()"
                [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-3">
              <!-- <label for="endtime" class="form-label">Return Time</label> -->
              <label for="endtime" class="form-label">Planned Return</label>
              <input class="form-control" type="time" id="endtime" [(ngModel)]="requestForm.endtime" name="endtime"
                [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-12">
              <!-- <label for="travelduration" class="form-label">Anticipated total No. of Days</label> -->
              <label for="travelduration" class="form-label">Days</label>
              <input class="form-control" type="text" id="duration" [(ngModel)]="requestForm.travelduration"
                name="travelduration" disabled />
            </div>
            <div class="mb-3 col-md-6">
              <ng-container *ngIf="!requestForm.readonly">
                <label for="product" class="form-label">Product</label>
                <ng-select class="role-select" [items]="productListdata" bindLabel="name" bindValue="productid"
                  placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="requestForm.product"
                  (ngModelChange)="productChange()">
                </ng-select>
              </ng-container>
              <ng-container *ngIf="requestForm.readonly">
                <label for="product" class="form-label">Product</label>
                <input class="form-control" type="text" id="product" [(ngModel)]='requestForm.productname'
                  name="product" disabled />
              </ng-container>
            </div>
            <div class="mb-3 col-md-6">
              <ng-container *ngIf="!requestForm.readonly">
                <label for="Project" class="form-label">Project</label>
                <ng-select class="role-select" [items]="projectList" bindLabel="name" bindValue="projectid"
                  placeholder="-" appendTo="body" [multiple]="false" [(ngModel)]="requestForm.project"
                  (ngModelChange)="projectChange()">
                </ng-select>
                <mat-progress-bar mode="indeterminate" *ngIf="gettingProjectList"></mat-progress-bar>
              </ng-container>
              <ng-container *ngIf="requestForm.readonly">
                <label for="project" class="form-label">Project</label>
                <input class="form-control" type="text" id="project" [(ngModel)]='requestForm.projectname'
                  name="project" disabled />
              </ng-container>
            </div>
            <div class="mb-3 col-md-6">
              <label for="amount" class="form-label">Estimated Budget</label>
              <input class="form-control" name="amount" id="amount" type="number" [(ngModel)]="requestForm.amount"
                [min]="0" (keypress)="($event.charCode != 45)" [disabled]="requestForm.readonly" />
            </div>
            <div class="mb-3 col-md-6">
              <label for="currency" class="form-label">Currency</label>
              <select [(ngModel)]="requestForm.currency" class="form-select" name="currency" id="currency"
                [disabled]="requestForm.readonly">
                <option *ngFor="let item of currencyList" [value]="item.currencyid">
                  {{ item.currencyname }}
                </option>
              </select>
            </div>
            <ng-container *ngIf="!requestForm.readonly">
              <div class="mb-3 col-md-6">
                <label for="modeoftravel" class="form-label">Mode of Travel</label>
                <ng-select class="role-select" [items]="modeoftravel" bindLabel="value" bindValue="key" appendTo="body"
                  [multiple]="true" [(ngModel)]="requestForm.modeoftravel">
                </ng-select>
              </div>
              <div class="mb-3 col-md-6">
                <label for="vehicleuse" class="form-label">Vehicle Use</label>
                <ng-select class="role-select" [items]="usevehicle" bindLabel="value" bindValue="key" appendTo="body"
                  [multiple]="true" [(ngModel)]="requestForm.vehicleuse">
                </ng-select>
              </div>
            </ng-container>
            <ng-container *ngIf="requestForm.readonly">
              <div class="mb-3 col-md-6">
                <label for="modeoftravel" class="form-label">Mode of Travel</label>
                <input type="text" class="form-control" id="modeoftravel" name="modeoftravel"
                  placeholder="Mode of Travel" [value]="requestForm.modeoftravelname.join(', ')" disabled />
              </div>
              <div class="mb-3 col-md-6">
                <label for="vehicleuse" class="form-label">Vehicle Use</label>
                <input type="text" class="form-control" id="vehicleuse" name="vehicleuse" placeholder="Vehicle Use"
                  [value]="requestForm.vehicleusename.join(', ')" disabled />
              </div>
            </ng-container>
          </ng-container>
          <!-- Transportation Start -->
          <ng-container *ngIf="requestForm.requesttype == this.transportationid">
            <div class="row">
              <div class="d-flex flex-wrap align-items-center">
                <!-- #now -->
                <ng-container *ngIf="!isEditing">
                  <ng-container *ngFor="let day of weekDay;let i = index;">
                    <div class="form-check mb-3 me-5"
                      [ngClass]="{'red-checkbox': day.name == 'Sunday' || day.name == 'Saturday'}">
                      <input class="form-check-input" type="checkbox" [attr.name]="day.name" [attr.id]="day.name"
                        [disabled]="requestForm.readonly" (change)="addWeekDay($event, day.value)"
                        [checked]="requestForm.weekday?.includes(day.value)">
                      <label class="form-check-label" [attr.for]="day.name"> {{day.name}}</label>
                    </div>
                  </ng-container>
                </ng-container>

              </div>
            </div>
            <ng-container *ngIf="requestForm.trantype== '002'">
              <div class="mb-3 col-md-12">
                <label for="toplace" class="form-label">To Place</label>
                <input class="form-control" type="text" id="toplace" name="toplace" [(ngModel)]="requestForm.toplace"
                  [disabled]="requestForm.readonly" />
              </div>
              <div class="d-flex align-items-center align-items-center-custom justify-content-start w-100">
                <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="going"
                    name="going" [(ngModel)]="requestForm.isgoing" [disabled]="requestForm.readonly"> <label
                    class="form-check-label ms-1" for="going"> Going
                  </label> </div>
                <div class="form-check mb-3 me-5"> <input class="form-check-input" type="checkbox" id="return"
                    name="return" [(ngModel)]="requestForm.isreturn" [disabled]="requestForm.readonly"> <label
                    class="form-check-label ms-1" for="return">
                    Return
                  </label> </div>
              </div>
              <ng-container *ngIf="requestForm.isgoing">
                <div class="col-md-6 mb-3" [ngClass]="{
                'col-md-12' : !requestForm.isgoing || !requestForm.isreturn
              }">
                  <label for="arrivetime" class="form-label">Arrive Time</label>
                  <input class="form-control" type="time" id="arrivetime" name="arrivetime"
                    [(ngModel)]="requestForm.arrivetime" [disabled]="requestForm.readonly" />
                </div>
              </ng-container>
              <ng-container *ngIf="requestForm.isreturn">
                <div class="col-md-6 mb-3" [ngClass]="{
                'col-md-12' : !requestForm.isgoing || !requestForm.isreturn
              }">
                  <label for="returntime" class="form-label">Return Time</label>
                  <input class="form-control" type="time" id="returntime" name="returntime"
                    [(ngModel)]="requestForm.returntime" [disabled]="requestForm.readonly" />
                </div>
              </ng-container>
              <div class="mb-3 col-md-6">
                <label for="dailyfrom" class="form-label">Pick Up Place</label>
                <input class="form-control" type="text" id="dailyfrom" name="dailyfrom"
                  [(ngModel)]="requestForm.dailyfrom" [disabled]="requestForm.readonly" />
              </div>
              <div class="mb-3 col-md-6">
                <label for="dailyto" class="form-label">Drop Off Place</label>
                <input class="form-control" type="text" id="dailyto" name="dailyto" [(ngModel)]="requestForm.dailyto"
                  [disabled]="requestForm.readonly" />
              </div>
            </ng-container>

          </ng-container>
          <!-- Transportation End -->

          <!-- HR Start -->
          <ng-container *ngIf="requestForm.requesttype == this.hrid">
            <div class="mb-3 col-md-6">
              <label for="hrrequesttype" class="form-label">Request Type</label>
              <select class="form-select" name="hrrequesttype" id="hrrequesttype" [(ngModel)]="requestForm.hrrequesttype"
                >
                <option *ngFor="let d of HRRequestTypeList" [value]="d.id">
                  {{ d.name }}
                </option>
              </select>
              <mat-progress-bar mode="indeterminate" *ngIf="gettingHRRequestType"></mat-progress-bar>
            </div>
      
            <div class="mb-3 col-md-6">
              <label for="hrrequestdate" class="form-label">
                Request Date
              </label>
              <input class="form-control" type="date" id="hrrequestdate" [(ngModel)]="requestForm.hrrequestdate"
                name="hrrequestdate" max="9999-12-31" />
            </div>
          </ng-container>
          <!-- HR End -->


          <ng-container *ngIf="requestForm.requesttype">
            <div *ngIf="requestForm.requesttype != this.workfromhomeid" class="mb-3 col-md-12">
              <label for="remark" class="form-label">{{requestForm.requesttype == travelrequestid ? 'Travel Purpose'
                : requestForm.requesttype == cashadvanced ? 'Description':'Remark'}}</label>
              <textarea class="form-control" [(ngModel)]="requestForm.remark" rows="3"
                [disabled]="requestForm.readonly && !requestForm.reservation"></textarea>
            </div>
            <div class="mb-3 col-md-12 d-flex">
              <button *ngIf="requestForm.requesttype == travelrequestid" class="btn btn-custom me-2"
                (click)="chooseAccompanyPerson()" [disabled]="requestForm.readonly">
                Accompany Person
              </button>
              <ng-container *ngIf="requestForm.requesttype != 'UEYVNDLOAPQWXQW' && requestForm.requesttype != 'VMAOWEFAMSVNAOWI' && requestForm.requesttype != this.reservationid &&
              requestForm.requesttype != this.transportationid && requestForm.requesttype != this.workfromhomeid && requestForm.requesttype != this.hrid 
              ">
                <input hidden type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput multiple
                  [disabled]="requestForm.readonly" />
                <button class="btn btn-custom me-2" (click)="hiddenfileinput.click()" [disabled]="requestForm.readonly">
                  <i class="fas fa-paperclip me-2"></i> Attachments
                </button>
              </ng-container>

              <!-- <ng-container >
              <button class="btn btn-custom me-2" (click)="chooseUser()" [disabled]="requestForm.readonly"
              *ngIf="(requestForm.requesttype == this.transportationid && requestForm.trantype == '002') || (requestForm.requesttype != this.transportationid && requestForm.requesttype != this.reservationid)">
              {{ requestForm.requesttype != this.transportationid ? 'Select Approvers' : 'Select Members' }}
            </button>
            </ng-container> -->

              <button class="btn btn-custom me-2" (click)="chooseUser()" [disabled]="requestForm.readonly"
                *ngIf="!(requestForm.requesttype == this.transportationid && requestForm.trantype == '001' && !isEditing) && requestForm.requesttype != cashadvanced && requestForm.requesttype != hrid">
                {{ requestForm.requesttype != this.transportationid ? 'Select Approvers' : 'Select Members' }}
              </button>

              <button class="btn btn-custom me-2" (click)="chooseTravel()" [disabled]="requestForm.readonly"
                *ngIf="isTravelClaim">
                Select Travel
              </button>

              <button class="btn btn-custom me-2" (click)="clearTravel()" [disabled]="requestForm.readonly"
                *ngIf="isTravelClaim && travelList.length > 0">
                Clear
              </button>

            </div>

            <ng-container *ngIf="travelList.length > 0 && requestForm.requesttype != this.transportationid">
              <!-- <div class="col-md-12"> -->
              <div class="col-md-6">
                <h3> Selected Travel ({{ travelList.length }})</h3>
                <div class="travel-card-container" *ngFor="let travel of travelList">
                  <div class=" d-flex justify-content-between" (click)="openTravelDetail(travel)">
                    <div class="d-flex gap-2 align-items-start">
                      <div class="approve-status"></div>
                      <div style="max-width: 96%;">
                        <div class="travel-detail">
                          <div class="request-person-name"> &nbsp;{{travel.username}} - &nbsp;</div>
                          <div class="ref-no">Ref No: {{travel.requestrefno}}</div>
                        </div>
                        <div class="default-text">
                          {{allinoneService.formatDBToShow(travel.startdate)}} -
                          {{allinoneService.formatDBToShow(travel.enddate)}}
                        </div>

                        <div *ngIf="travel.requeststatus=='001'" class="default-text">
                          {{travel.duration}} days of {{travel.remark}} travel submitted
                        </div>
                        <div *ngIf="travel.requeststatus=='002'" class="default-text">
                          {{travel.duration}} days of {{travel.remark}} travel approved by {{travel.personname}}
                        </div>
                      </div>
                      <!-- <div *ngIf="travel.requeststatus"=="003">
                              
                          </div>
                          <div *ngIf="travel.requeststatus"=="004">
                              
                          </div> -->
                      <!-- <input type="checkbox" class="form-check-input" [id]="travel.requestid"
                          [checked]="travel.checked" (change)="selectTravel($event,travel)"> -->
                    </div>

                    <div class="text-lg justify-content-between">
                      <div>{{travel.requesttypename}}</div>
                      <div class="approved-text">{{travel.requeststatus == '002'? "Approved": ""}}</div>
                    </div>

                  </div>
                </div>
                <hr>
              </div>
            </ng-container>

            <div class="col-md-12 row" *ngIf="requestForm.requesttype != 'UEYVNDLOAPQWXQW'">
              <div class="img-container col-lg-3 col-md-4 col-sm-4 col-6"
                *ngFor="let item of imageList; let index = index">
                <div class="img-border card">
                  <img [src]="item.previewurl" (click)="allinoneService.imageViewer(imageList, index)" />
                  <mat-icon class="close" (click)="removeImage(index)">close</mat-icon>
                </div>
              </div>
            </div>

            <ng-container *ngIf="accompanyPersonList.length > 0 && requestForm.requesttype == this.travelrequestid">
              <!-- <div class="col-md-12 mb-3"> -->
              <div class="col-md-6 mb-3">
                <h3> Selected Accompany Person ({{ accompanyPersonList.length }})</h3>
                <div class="list-group">
                  <!-- <div class="profile-container d-flex col-md-4" *ngFor="let user of accompanyPersonList"> -->
                  <div class="profile-container d-flex" *ngFor="let user of accompanyPersonList">
                    <span>
                      <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="
                        user.photo || user.image || '../../../assets/images/profile.png'
                      " />
                    </span>

                    <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                      <div class="profile-item-container">
                        <div class="profile-item">
                          <div class="username" style="cursor: pointer">
                            <b>{{ user.username || user.userid }}</b>
                          </div>
                          <div style="color: grey; margin-top: 1px; font-size: 13.5px" *ngIf="user.username != ''">
                            {{ user.userid }}
                          </div>
                        </div>
                      </div>
                      <input type="checkbox" class="form-check-input" [id]="user.primarykey" [(ngModel)]="user.checked"
                        [checked]="user.checked" [disabled]="requestForm.readonly" />
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="approverList.length > 0 && requestForm.requesttype != this.transportationid && requestForm.requesttype != hrid ">
              <!-- <div class="col-md-12"> -->
              <div class="col-md-6">
                <h3> Selected Approvers ({{ approverList.length }})</h3>
                <div class="list-group">
                  <!-- <div class="profile-container d-flex col-md-4" *ngFor="let user of approverList"> -->
                  <div class="profile-container d-flex" *ngFor="let user of approverList">
                    <span>
                      <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'" [lazyLoad]="
                        user.photo || user.image || '../../../assets/images/profile.png'
                      " />
                    </span>

                    <label class="d-flex align-items-center align-items-center-custom justify-content-center w-100">
                      <div class="profile-item-container">
                        <div class="profile-item">
                          <div class="username" style="cursor: pointer">
                            <b>{{ user.username || user.userid }}</b>
                          </div>
                          <div style="color: grey; margin-top: 1px; font-size: 13.5px" *ngIf="user.username != ''">
                            {{ user.userid }}
                          </div>
                        </div>
                      </div>
                      <input type="checkbox" class="form-check-input" [id]="user.primarykey" [(ngModel)]="user.checked"
                        [checked]="user.checked" [disabled]="requestForm.readonly" />
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>




            <ng-container *ngIf=" requestForm.requesttype == transportationid && !isEditing ">
              <ng-container *ngIf="approverList.length > 0">
                <div class="col-md-12">
                  <h3> Selected Members ({{ approverList.length }})</h3>
                  <div class="row">
                    <!-- <div class="list-group"> -->
                    <ng-container *ngFor="let user of approverList">
                      <div class="col-md-4">
                        <div class="card p-3 my-card">
                          <div class="d-flex align-items-center">
                            <span>
                              <img class="profile-image me-3" [defaultImage]="'../../../assets/images/profile.png'"
                                [lazyLoad]="
                              user.photo || user.image || '../../../assets/images/profile.png'
                            " />
                            </span>

                            <label class="d-flex align-items-center justify-content-between w-100">
                              <div class="profile-item-container">
                                <div class="profile-item">
                                  <div class="username" style="cursor: pointer">
                                    <b>{{ user.username || user.userid }}</b>
                                  </div>
                                  <div style="color: grey; margin-top: 1px; font-size: 13.5px"
                                    *ngIf="user.username != ''">
                                    {{ user.userid }}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <input type="checkbox" class="form-check-input" [id]="user.primarykey"
                                  [(ngModel)]="user.checked" [checked]="user.checked"
                                  [disabled]="requestForm.readonly" />
                              </div>
                            </label>
                          </div>
                          <div class="row mt-2">
                            <div class="col-md-6">
                              <input class="form-control" type="text" [id]="'from-'+user.userid"
                                [name]="'from-'+user.userid" [(ngModel)]="user.from" placeholder="Pick Up Place" />
                            </div>
                            <div class="col-md-6">
                              <input class="form-control" type="text" [id]="'to-'+user.userid"
                                [name]="'to-'+user.userid" [(ngModel)]="user.to" placeholder="Drop Off Place" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>

          </ng-container>
        </ng-template>

      </div>
    </ng-container>
    <!-- transportation view -->
    <ng-container *ngIf="transportationView">
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Type/Status"
            aria-label="Search by name" (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            (ngModelChange)="searchTextChange()" [ngClass]="{ 'c-search-input-with-clear': searchText }"
            style="font-family: FontAwesome, Ubuntu" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <!-- filter button <div class="ms-2">
          <button class="btn btn-custom" (click)="filter(true)" title="Filter" [disabled]="gettingTemplate">
            <i class="fa fa-filter"></i>
          </button>
        </div> -->

        <div class="ms-2">
          <button class="btn btn-custom" (click)="tranrefresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <!-- <div class="line"></div> -->
        <div class="align-items-center ms-2" [matMenuTriggerFor]="transtatusmenu">
          <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" return="button" id="dropdownMenuButton"
            aria-expanded="false" mdbDropdownToggle style="color: white; min-width: 100px">
            Status&nbsp;:&nbsp;{{ searchForm.trequeststatusname }}
            <mat-menu #transtatusmenu="matMenu" xPosition="before">
              <button mat-menu-item *ngFor="let status of statusList" (click)="transtatusChange(status)">
                {{ status.name }}
              </button>
            </mat-menu>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ (transportaionList | filter : searchText : 'requestList').length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input class="form-control" type="text" placeholder="&#xf002; Search by Type/Status" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()" />
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>

        <pagination-controls class="ms-auto paginate" (pageChange)="tranCurrentPage = $event"
          *ngIf="transportaionList.length > tranItemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="tranSortData($event)">
        <table *ngIf="tabletrantype == '001'" class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <!-- <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="refno">
                Ref No.
              </td> -->
              <td scope="col" class="col-md-1" mat-sort-header="userid">
                User ID
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="username">
                User Name
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="date">
                Date
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="type">
                Type
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="status">
                Status
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="pickuppointid">
                Pick Up Point
              </td>
              <td scope="col" class="col-md-4" mat-sort-header="remark">Remark</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>

          <tbody *ngIf="gettingtran">
            <tr>
              <td colspan="8" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <!-- *** -->
          <tbody *ngIf="!gettingtran">
            <tr *ngIf="transportaionList.length == 0">
              <td colspan="8" class="nodata">Empty</td>
            </tr>

            <tr *ngFor="
                let request of transportaionList
                  | filter : searchText : 'requestList'
                  | paginate
                    : { itemsPerPage: tranItemPerPage, currentPage: tranCurrentPage };
                let index = index
              ">
              <!-- <td class="td-data td-edit" (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                <span [title]="request.requestrefno">{{
                  request.requestrefno
                  }}</span>
              </td> -->
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.userid">{{
                  request.userid
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.username">{{
                  request.username
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span
                  [title]="allinoneService.formatDBToShow(request.date)">{{allinoneService.formatDBToShow(request.date)}}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span style="color: var(--main-color)">Transportation</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.requeststatusname" class="status" [ngClass]="{
                    'status-green': request.requeststatus == '002',
                    'status-red': request.requeststatus == '003',
                    'status-gray': request.requeststatus == '001', 
                    'status-main': request.requeststatus == '004' 
                  }">{{ request.requeststatusname }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="getPickUpPointName(request.pickuppointid)">{{
                  getPickUpPointName(request.pickuppointid)
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.remark">{{
                  request.remark
                  }}</span>
              </td>
              <td class="d-flex td-data justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editRequest(request, false, true)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger"
                  (click)="tranDeleteRequest(request.transportationid, request.olduserid)"
                  [disabled]="request.requeststatus != '001' ">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="tabletrantype == '002'" class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <!-- <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="refno">
                Ref No.
              </td> -->
              <td scope="col" class="col-md-1" mat-sort-header="userid">
                User ID
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="username">
                User Name
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="date">
                Date
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="type">
                Type
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="status">
                Status
              </td>
              <td scope="col" class="col-md-1" mat-sort-header="toplace">
                To Place
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="userfrom">
                Pick Up Place
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="userto">
                Drop off Place
              </td>
              <td scope="col" class="col-md-2" mat-sort-header="remark">Remark</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>

          <tbody *ngIf="gettingtran">
            <tr>
              <td colspan="9" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>

          <!-- *** -->
          <tbody *ngIf="!gettingtran">
            <tr *ngIf="transportaionList.length == 0">
              <td colspan="9" class="nodata">Empty</td>
            </tr>

            <tr *ngFor="
                let request of transportaionList
                  | filter : searchText : 'requestList'
                  | paginate
                    : { itemsPerPage: tranItemPerPage, currentPage: tranCurrentPage };
                let index = index
              ">
              <!-- <td class="td-data td-edit" (click)="editRequest(request, request.requesttype == 'ANVAHSEFAMVAJDAJ' )">
                <span [title]="request.requestrefno">{{
                  request.requestrefno
                  }}</span>
              </td> -->
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.userid">{{
                  request.userid
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.username">{{
                  request.username
                  }}</span>
              </td>

              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span
                  [title]="allinoneService.formatDBToShow(request.date)">{{allinoneService.formatDBToShow(request.date)}}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span style="color: var(--main-color)">Transportation</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.requeststatusname" class="status" [ngClass]="{
                    'status-green': request.requeststatus == '002',
                    'status-red': request.requeststatus == '003',
                    'status-gray': request.requeststatus == '001', 
                    'status-main': request.requeststatus == '004' 
                  }">{{ request.requeststatusname }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.toplace">{{
                  request.toplace
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.userfrom">{{
                  request.userfrom
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.userto">{{
                  request.userto
                  }}</span>
              </td>
              <td class="td-data td-edit" (click)="editRequest(request, false, true)">
                <span [title]="request.remark">{{
                  request.remark
                  }}</span>
              </td>
              <td class="d-flex td-data justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editRequest(request, false, true)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger"
                  (click)="tranDeleteRequest(request.transportationid, request.olduserid)"
                  [disabled]="request.requeststatus != '001' ">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>