import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/shared/services/message.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { DatePipe, formatDate } from '@angular/common';
import { CustomerContactsComponent } from '../customer-contacts/customer-contacts.component';
import { MatDialog } from '@angular/material/dialog';
import { CrmCustomerDealComponent } from '../crm-customer-deal/crm-customer-deal.component';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import * as XLSX from 'xlsx';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AnyAaaaRecord } from 'dns';
import { CustomerNameComponent } from '../customer-name/customer-name.component';
import { CustomerBranchComponent } from '../customer-branch/customer-branch.component';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  @ViewChild('hiddenpdffileinput') hiddenpdffileinput: any;

  @Input() orgid: any;
  @Input() businessType: any[] = [];
  @Input() currencyType: any = [];
  @Input() customerStatus: any = [];
  @Input() statusList: any = [];
  @Input() stageList: any = [];
  @Input() editData: any;
  @Input() checkModeal: any;
  // @Input() isExcel: any;
  @Input() isExcel: any;

  @Output('save')
  save: EventEmitter<any> = new EventEmitter<any>();

  @Output('crmcancel')
  cancel: EventEmitter<any> = new EventEmitter<any>();


  isEditing: boolean = false;
  productSaveLoading: boolean = false;
  projectSaveLoading: boolean = false;

  saveLoading: boolean = false;
  cuFormSubmitted: boolean = false;
  attachmentList: any[] = [];
  deleteFileList: any[] = [];
  emailaccessList: any[] = [];
  product: boolean = false;
  isproject: boolean = false;
  addproduct: boolean = false;
  addproject: boolean = false;

  productListCustomer: any = [];
  projectListCustomer: any = [];
  deleteproductlist: any = [];
  deleteprojectlist: any = [];
  addproductlist: any = [];
  addprojectlist: any = [];

  contactlist: any = []
  checkservice: boolean = false;

  productList: any = [];
  projectlist: any = [];
  excelexportLoading: boolean = false;
  customernameList: any = [];
  getCustomer: boolean = false;

  gettingData: boolean = false;
  customerCheck: boolean = false;
  searchcustomer: any = '';
  searchproduct: any = '';
  text1: any = '';
  text2: any = '';
  saveCustomerCheck: boolean = false;


  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };


  cuForm = {
    "id": "",
    "name": "",
    "shortcode": "",
    "businesstype": "001",
    "phone": "",
    "email": "",
    "address1": "",
    "address2": "",
    "status": "002",
    "orgid": "",
    "contactlist": [],
    "deallist": [],
    "deletecontactlist": [],
    "deletefilename": [],
    "deletedeallist": [],
    "industrytype": '',
    "post": '',
    "branchlist":[],
    "deletebranchlist":[]
  }

  deleteprojectcheck:boolean = false;
  deleteproductcheck:boolean = false;
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public datepipe: DatePipe,

  ) {

    console.log("checkmodeal");
    console.log(this.checkModeal)

    if (
      // this.router.url.startsWith('/org/crm')
      this.router.url.startsWith('/sales')
    ) {

      this.checkservice = true;
      // var orgID = this.allinoneService.selectedOrgCRM;
      // await this.getPermission(orgID);
      // if (orgID) {
      //   await this.getPermission(orgID);
      // } else {
      //   if (this.orgListcrm.length > 0) {
      //     await this.getPermission(this.orgListcrm[0].orgid);
      //   }
      // }
    }

  }
  ngOnInit(): void {


    this.cuForm.orgid = this.orgid;
    console.log(this.editData)
    if (this.editData) {
      this.isEditing = true;
      this.saveCustomerCheck = true;
      console.log(this.isEditing);
      console.log(this.product)
      console.log(this.isExcel)
      console.log("ojeifji");
      
      if (this.editData.customerstatus == '002') {
        this.customerCheck = true;
      }
      this.cuForm = {
        id: this.editData.customerid,
        orgid: this.orgid,
        name: this.editData.customername,
        shortcode: this.editData.customershortcode,
        businesstype: this.editData.customertype,
        phone: this.editData.customermobile,
        email: this.editData.customeremail,
        address1: this.editData.customeraddress1,
        address2: this.editData.customeraddress2,
        status: this.editData.customerstatus,
        contactlist: this.editData.contactlist,
        deallist: this.editData.deallist,
        deletecontactlist: [],
        deletefilename: [],
        deletedeallist: [],
        industrytype: this.editData.industrytype,
        post: this.editData.post,
        branchlist:this.editData.branchlist,
        deletebranchlist:[]
        


      };
      // cuForm.branchlist.length
      this.contactlist = this.editData.contactlist;
      this.attachmentList = this.editData.filelist;

      for (let i = 0; i < this.editData.contactlist.length; i++) {
        if (this.editData.contactlist[i]['emailaccess'] == 'true') {
          this.emailaccessList.push(this.editData.contactlist[i]);
        }
      }
    }
    console.log(this.editData.contactlist)
    console.log(this.cuForm);
  }

  submitCustomer() {
    this.cuFormSubmitted = true;

    // if (this.cuForm.phone != '') {
    //   if ((/([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g).test(this.allinoneService.checkUserId(this.cuForm.phone.toString().trim())) == false) {
    //     //  if(this.leadForm.phone == '' || this.leadForm.phone.substring(0, 2) != '09' || this.leadForm.phone.substring(0, 4) != '+959' || this.leadForm.phone.toString().length > 12){
    //     this.messageService.openSnackBar("Please enter valid phone number.", 'warn');
    //     return;
    //   }
    // }
    if (this.cuForm.name) {
      this.saveCustomer();
    }
  }

  async saveCustomer() {

    if (this.cuForm.name.length > 255 || this.cuForm.phone.length > 255 ||
      this.cuForm.email.length > 255 || this.cuForm.address1.length > 255 || this.cuForm.address2.length > 255 || this.cuForm.shortcode.length < 3 ||
      this.cuForm.shortcode.length > 5) {
      return;

    }


    this.saveLoading = true;


    const tempFileName = formatDate(
      new Date(),
      'yyyyMMddHH:mm:ss',
      'en-US'
    ).toString();

    var tempFileList = this.attachmentList
    this.attachmentList = []
    tempFileList.map((item: any) => {
      if (!item.fileurl) {
        this.attachmentList.push(item)
      }
    })

    var filepath = 'crm/' + (this.isEditing ? this.cuForm.id : tempFileName).toString()
    console.log(filepath)
    if (this.attachmentList.length > 0) {
      const upload_aws = await this.allinoneService.multipleUploadToAWS(
        this.attachmentList,
        filepath
      );
      if (!upload_aws) {
        this.messageService.openSnackBar(
          'Unknown error occured while uploading your files.',
          'warn'
        );
        return;
      }
    }

    const filenamelist: any = [];
    tempFileList.map((item: any) => {
      filenamelist.push(item.filename);
    });
    console.log(this.cuForm.deallist)
    console.log("...")

    // this.cuForm.deallist.map((item: any) => {
    //   console.log(item.closingdate)
    //   item.opendate = this.allinoneService.formatDBToShowInEdit(item.opendate)
    //   item.closingdate = this.allinoneService.formatDBToShowInEdit(item.closingdate)
    //   item.targetdate = this.allinoneService.formatDBToShowInEdit(item.targetdate)
    // })
    console.log(this.cuForm.deallist)
    var data = {
      customername: this.cuForm.name,
      customertype: this.cuForm.businesstype,
      customermobile: this.cuForm.phone == '' ? '' : this.allinoneService.checkUserId(this.cuForm.phone.toString().trim()),
      customeremail: this.cuForm.email,
      customeraddress1: this.cuForm.address1,
      customeraddress2: this.cuForm.address2,
      status: this.cuForm.status,

      filename: filenamelist,
      tmpfilename: tempFileName,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.cuForm.orgid,
      contactlist: this.cuForm.contactlist,
      deallist: this.cuForm.deallist,
      deletecontactlist: this.cuForm.deletecontactlist,
      deletefilename: this.cuForm.deletefilename,
      industrytype: this.cuForm.industrytype,
      customershortcode: this.cuForm.shortcode,
      branchlist:this.cuForm.branchlist,
      // post:this.cuForm.post,

    };
    if (this.isEditing) {
      data = Object.assign(data,
        {
          customerid: this.cuForm.id,
          deletedeallist: this.cuForm.deletedeallist
        }
      );
    }
    var fun = this.isEditing
      ? this.kunyekService.editCrmCustomer(data)
      : this.kunyekService.createCrmCustomer(data);

    console.log(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.saveLoading = false;
          this.save.emit();
        } else {
          this.saveLoading = false;
          this.messageService.openSnackBar(res.status, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }
  cancelFunction() {

    this.cancel.emit();
  }
  onFileSelect(event: any) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        // if (file.type == 'application/pdf') {
        var alreadySelect = false;
        this.attachmentList.map((item: any) => {
          if (item.filename == file.name) {
            alreadySelect = true;
          }
        });
        if (!alreadySelect) {
          var temp = {
            file: file,
            filename: file.name,
          };
          this.attachmentList.push(temp);
        }

      }
      this.hiddenpdffileinput.nativeElement.value = '';
    }
  }
  removeAttachment(index: number) {
    if (this.attachmentList[index].fileurl) {
      this.deleteFileList.push(this.attachmentList[index].filename)
    }
    this.attachmentList.splice(index, 1);
  }
  downloadFile(fileurl: any, filename: any) {
    window.open(fileurl,filename);
    this.http
      .get(fileurl, { responseType: 'blob' })
      .subscribe((response: any) => {
        saveAs(response, filename);
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  openDealForm() {
    const dialog = this.dialog.open(CrmCustomerDealComponent, {
      width: '700px',
      height: "550px",
      closeOnNavigation: false,
      // panelClass: ['event-modal', 'event-detail','mat-typography'],
      panelClass: ['mat-typography', 'crm-service-status-modal'],
      data: {
        'deals': this.cuForm.deallist,
        'customername': this.cuForm.name,
        'currencyType': this.currencyType,
        'statusList': this.statusList,
        'stageList': this.stageList,
        'fromPipeline': false,
      }
    })

    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
        this.cuForm.deallist = res.finaldeallist
        this.cuForm.deletedeallist = res.deletedeallist
        console.log("finaldeal")
        console.log(this.cuForm.deallist)
      }
      console.log(this.cuForm)
    });
  }

  openContactForm() {
    const dialog = this.dialog.open(CustomerContactsComponent, {
      width: '700px',
      height: "550px",
      closeOnNavigation: true,
      disableClose: true,
      // panelClass: ['event-modal', 'event-detail','mat-typography'],
      panelClass: ['mat-typography', 'dialog-container-contact',],
      data: {
        'contactlist': this.cuForm.contactlist,
        'deletelist': this.cuForm.deletecontactlist,
        'branchlist': this.cuForm.branchlist,
        'orgid':this.cuForm.orgid,
        'customerid':this.cuForm.id
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.cuForm.contactlist = dialogResult.datalist;
        this.cuForm.deletecontactlist = dialogResult.deletelist;
        this.emailaccessList = [];
        for (let i = 0; i < this.cuForm.contactlist.length; i++) {
          if (this.cuForm.contactlist[i]['emailaccess'] == 'true') {
            this.emailaccessList.push(this.cuForm.contactlist[i]);
          }
        }




      }

      console.log(dialogResult);
      console.log("contactform")


    });
  }
  openBranchForm() {
    const dialog = this.dialog.open(CustomerBranchComponent, {
      width: '700px',
      height: "550px",
      closeOnNavigation: true,
      disableClose: true,
      // panelClass: ['event-modal', 'event-detail','mat-typography'],
      panelClass: ['mat-typography', 'dialog-container-contact',],
      data: {
        'branchlist': this.cuForm.branchlist,
        'deletebranchlist': this.cuForm.deletebranchlist,
        
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.cuForm.branchlist = dialogResult.datalist;
        this.cuForm.deletebranchlist = dialogResult.deletelist;
      }
    });
  }


  goServiceTicket() {
    this.router.navigateByUrl(`service/service-tickets?customerid=${this.cuForm.id}&customername=${this.cuForm.name}`)
    // this.router.navigateByUrl(`org/service/service-tickets?customerid=${this.cuForm.id}&customername=${this.cuForm.name}`)
  }
  goCustomer() {

  }
  errorList: any = []
  // excel:boolean = false;
  checkingExcelFile: boolean = false;
  allok: boolean = false;
  duplicateList: any = [];
  warningList: any = [];
  excelerror: boolean = false;
  addcustomer: boolean = false;
  finalMemberList: any = [];
  useridList: any = [];
  newcclist: any = [];
  uidList: any = [];
  excel: any = '';
  rawMemberList: any[] = [];
  @ViewChild('excelFile') excelFile: any;
  Start: any = 0;
  End: any = 30;
  mobileList: any = [];
  emailList: any = [];
  nameList: any = [];
  downloadSampleFile(): any {
    this.http
      .get(
        this.allinoneService.bucketUrl + 'templates/CRMCustomerSampleTemplate.xlsx',
        { responseType: 'blob' }
      )
      .subscribe((response: any) => {
        saveAs(response, 'CRMCustomerSampleTemplate.xlsx');
      }),
      (error: any) => {
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }


  removeExcel() {
    this.excel = '';
    this.excelFile.nativeElement.value = '';
    this.errorList = [];
    this.warningList = [];
    this.excelerror = false;
    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.newcclist = [];
    this.uidList = [];
    this.allok = false;
    this.errorList = [];
    this.mobileList = [];
    this.nameList = [];
    this.emailList = [];

  }

  onExcelFileSelect(event: any) {
    this.excelerror = false;
    if (
      event.target.files[0].type ==
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      this.excel = event.target.files[0];
      const target: DataTransfer = <DataTransfer>event.target;
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.rawMemberList = XLSX.utils.sheet_to_json(ws, { header: 1 });
        console.log(this.rawMemberList);
        this.checkExcelFile();
      };

      reader.readAsBinaryString(target.files[0]);
    } else {
      this.removeExcel();
      this.messageService.openSnackBar('File type is not supported!', 'warn');
    }
  }


  checkExcelFile() {
    this.checkingExcelFile = true;

    this.duplicateList = [];
    this.finalMemberList = [];
    this.useridList = [];
    this.uidList = [];

    const tempCcList: any = [];
    for (let i = 1; i < this.rawMemberList.length; i++) {
      const raw = this.rawMemberList[i];
      if (
        (raw[0] == null || raw[0] == undefined || raw[0] == '') &&
        (raw[1] == null || raw[1] == undefined || raw[1] == '') &&
        (raw[2] == null || raw[2] == undefined || raw[2] == '') &&
        (raw[3] == null || raw[3] == undefined || raw[3] == '') &&
        (raw[4] == null || raw[4] == undefined || raw[4] == '') &&
        (raw[5] == null || raw[5] == undefined || raw[5] == '') &&
        (raw[6] == null || raw[6] == undefined || raw[6] == '') &&
        (raw[7] == null || raw[7] == undefined || raw[7] == '')
      ) {
      } else {
        if (raw[0] == null || raw[0] == undefined || raw[0] == '') {
          const tempError = {
            line: (i + 1).toString(),
            error: "'Name' is empty.",
          };
          this.errorList.push(tempError);
        } else {
          const einde = this.nameList.findIndex((x: any) =>
            x.name.trim() == raw[0] ? raw[0] : ''.toString().trim()
          );
          console.log(">>>>")
          console.log(einde)
          if (einde > -1) {
            const tempError = {
              line: (i + 1).toString(),
              error:
                "Name '" +
                raw[0].toString().trim() +
                "' is duplicated.",
            };
            this.errorList.push(tempError);
          } else {
            if (raw[1] ? raw[1] : ''.toString().trim() != '') {
              var tempEId = {
                line: (i + 1).toString(),
                name: raw[0].toString().trim(),
              };
              this.nameList.push(tempEId);
            }


            const name = raw[0] ? raw[0].toString() : '';
            const mobile = raw[1] ? raw[1].toString() : '';
            const email = raw[2] ? raw[2].toString() : '';
            const address = raw[3] ? raw[3].toString() : '';
            const industrytype = raw[4] ? raw[4].toString() : '';
            const status = raw[5] ? raw[5].toString() : '';
            const type = raw[6] ? raw[6].toString() : '';


            const tempMember = {
              customername: name,
              customermobile: this.allinoneService.checkUserId(mobile),
              customeremail: email,
              customeraddress: address,
              industrytype: industrytype,
              status: status == 1 ? '002' : '001',
              customertype: type == 'Organization' ? '001' : '002',
              // contacts: contactss
            };
            this.finalMemberList.push(tempMember);
            this.checkingExcelFile = false;
            this.allok = true;
          }
          // }
          // }
        }

      }
    }
    console.log('err ====> ');
    console.log(this.errorList);
    console.log('duplicate ====> ');
    console.log(this.duplicateList);
    console.log('final ====> ');
    console.log(this.finalMemberList);
    if (
      this.errorList.length == 0 &&
      this.newcclist.length == 0 &&
      this.finalMemberList.length > 0
    ) {
      // this.checkEidList()
    } else {
      this.checkingExcelFile = false;
    }
  }

  submitExcelCustomer() {
    console.log("11")

    if (this.finalMemberList.length > 0) {
      if (this.End > this.finalMemberList.length) {
        this.End = this.finalMemberList.length;
      }
      this.AddExeclCustomer();
    }
    else {
      this.messageService.openSnackBar('Your excel file is empty', 'warn')
    }
  }

  AddExeclCustomer() {

    console.log(22)


    var tempcuostmer: any[] = [];
    for (let i = this.Start; i < this.End; i++) {
      tempcuostmer.push(this.finalMemberList[i]);
    }
    this.addcustomer = true;
    this.excelerror = false;
    const data = {
      customerlist: tempcuostmer,
      createtype: 'array',
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.cuForm.orgid,
      customername: '',
      customertype: '',
      customermobile: '',
      customeremail: '',
      customeraddress1: '',
      customeraddress2: '',
      status: '',
      filename: '',
      tmpfilename: '',
      contactlist: '',
      deallist: '',
      deletecontactlist: '',
      deletefilename: '',
      industrytype: '',
    };
    console.log(data);

    this.kunyekService.createCrmCustomer(data).subscribe((res: any) => {
      if (res.returncode == '300') {
        if (this.End == this.finalMemberList.length) {
          this.addcustomer = false;


          this.isExcel = false;
          this.excel = false;
          this.allok = false;
          // this.removeExcel();
          this.Start = 0;
          this.End = 30;
          this.save.emit();

        } else {
          this.Start = this.End;
          this.End = this.End + 30;
          if (this.End > this.finalMemberList.length) {
            this.End = this.finalMemberList.length;
          }
          this.AddExeclCustomer();
        }
      } else {
        this.addcustomer = false;
        this.messageService.openSnackBar(res.status, 'warn');
      }
    });

  }

  goProduct() {
    this.product = true;
    this.isExcel = false;
    this.addproduct = false;
    this.getCustomerProduct();

  }

  goProject() {
    this.isproject = true;
    this.addproject = false;
    this.product = false;
    this.isExcel = false;
    this.addproduct = false;
    this.getCustomerProject();
  }

  cancels(data: any) {
    if (data == 2) {
      this.isEditing = true;
      this.product = false;
      this.addproduct = false;
      this.deleteproductlist = [];
      this.isproject = false;
      this.addproject = false;
    } else if (data == 1) {
      this.goProduct();
    } else if (data == 3) {
      this.isEditing = true;
      this.product = false;
      this.addproduct = false;
      this.isproject = false;
      this.addproject = false;
      // this
    } else if (data == 4) {
      this.isEditing = false;
      this.product = false;
      this.addproduct = false;
      this.isproject = true;
      this.addproject = false;
      // this
    }

  }

  addProjectcus() {
    this.addproduct = false;
    this.isproject = false;
    this.addproject = true;
    this.product = false;
    this.addprojectlist = []
    this.isExcel = false;
    this.getProject();
  }
  addproductcus() {
    this.addproject = false;
    this.addproduct = true;
    this.isproject = false;
    this.product = false;
    this.addproductlist = []

    this.isExcel = false;
    this.getProduct();
  }

  // cancelAddproduct(){
  //   this.addproduct = false;
  //   this.product = true;
  // }
  addprojectcancel() {



    console.log("okok");
    this.deleteprojectlist = [];

    for (let i = 0; i < this.projectListCustomer.length; i++) {
      this.projectListCustomer[i].check = false;
    }
    this.isproject = true;
    this.addproduct = false;

    this.product = false;;
    this.addproject = false;
    this.isExcel = false;
  }


  addproductcancel() {
    this.deleteproductlist = [];

    for (let i = 0; i < this.productListCustomer.length; i++) {
      this.productListCustomer[i].check = false;
    }
    this.isproject = false;
    this.addproduct = false;
    this.product = true;
    this.addproduct = false;
    this.isExcel = false;
  }


  allcheck(data: any) {

    if (data.target.checked == true) {
      this.addproductlist = [];
      for (let i = 0; i < this.productList.length; i++) {
        this.addproductlist.push(
          {
            "productid": this.productList[i].productid,
            'contactid': this.productList[i].contactid
          }
        );
        this.productList[i].check = true;
      }

    } else {
      this.addproductlist = [];
      for (let i = 0; i < this.productList.length; i++) {
        this.productList[i].check = false;
      }

    }
    console.log(this.addproductlist)
  }
  allcheckproject(data: any) {
    console.log(data);

    if (data.target.checked == true) {
      this.addprojectlist = [];
      for (let i = 0; i < this.projectlist.length; i++) {
        this.addprojectlist.push(
          {
            'projectid': this.projectlist[i].projectid,
            'contactid': this.projectlist[i].contactid
          }
        );
        this.projectlist[i].check = true;
      }

    } else {
      this.addprojectlist = [];
      for (let i = 0; i < this.projectlist.length; i++) {
        this.projectlist[i].check = false;
      }

    }
    console.log(this.addprojectlist)
  }


  checkProject(data: any, item: any, index: any) {
    console.log(item)
    if (data.target.checked == true) {
      this.addprojectlist.push({
        'projectid': item.projectid,
        'contactid': item.contactid == undefined ? "" : item.contactid,
      })
      this.projectlist[index].check = true;

    } else if (data.target.checked == false) {

      var i = this.projectlist.map((x: any) => x.projectid).indexOf(item.projectid);
      console.log(i);
      console.log(this.projectlist)
      this.projectlist[i].check = false;
      this.addprojectlist.splice(i, 1)
    }
    console.log(this.addprojectlist)



  }


  checkProduct(data: any, item: any, index: any) {
    console.log(item)
    if (data.target.checked == true) {
      this.addproductlist.push({
        'productid': item.productid,
        'contactid': item.contactid
      })
      this.productList[index].check = true;

    } else if (data.target.checked == false) {

      var i = this.productList.map((x: any) => x.productid).indexOf(item.productid);
      console.log(i);
      console.log(this.productList)
      this.productList[i].check = false;
      this.addproductlist.splice(i, 1)
    }
    console.log(this.addproductlist)



  }

  deleteallcheckproject(data: any) {
    console.log(data.target.checked)
    if (data.target.checked == true) {
      // if(this.deleteproductlist.length == 0)
      this.deleteprojectcheck = true;
      this.deleteprojectlist = [];
      for (let i = 0; i < this.projectListCustomer.length; i++) {
        this.deleteprojectlist.push(this.projectListCustomer[i].projectid);
        this.projectListCustomer[i].check = true;
      }
      

    } else {
      this.deleteprojectcheck = false;
      this.deleteprojectlist = [];
      for (let i = 0; i < this.projectListCustomer.length; i++) {
        this.projectListCustomer[i].check = false;
      }

    }
    console.log(this.deleteprojectlist)
  }
  allcheckProduct(data: any) {
    console.log(data.target.checked)
    if (data.target.checked == true) {
      // if(this.deleteproductlist.length == 0)
      this.deleteproductcheck = true;
      this.deleteproductlist = [];
      for (let i = 0; i < this.productListCustomer.length; i++) {
        this.deleteproductlist.push(this.productListCustomer[i].productid);
        this.productListCustomer[i].check = true;
      }

    } else {
      this.deleteproductcheck = false;
      this.deleteproductlist = [];
      for (let i = 0; i < this.productListCustomer.length; i++) {
        this.productListCustomer[i].check = false;
      }

    }
    console.log(this.deleteproductlist)
  }
  checkDeleteProduct(data: any, item: any, index: any) {
    console.log(item)
    if (data.target.checked == true) {
      this.deleteproductlist.push(item.productid)
      this.productListCustomer[index].check = true;

    } else if (data.target.checked == false) {
      var i = this.deleteproductlist.map((x: any) => x).indexOf(item.productid);
      this.productListCustomer[i].check = false;
      this.deleteproductlist.splice(i, 1)
    }
    console.log(this.deleteproductlist)



  }
  checkDeleteProject(data: any, item: any, index: any) {
    console.log(item)
    if (data.target.checked == true) {
      this.deleteprojectlist.push(item.projectid)
      this.projectListCustomer[index].check = true;

    } else if (data.target.checked == false) {
      var i = this.deleteprojectlist.map((x: any) => x).indexOf(item.projectid);
      this.projectListCustomer[i].check = false;
      this.deleteprojectlist.splice(i, 1)
    }
    console.log(this.deleteprojectlist)



  }
  getCustomerProject() {
    this.gettingData = true;
    this.projectListCustomer = [];

    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid": this.cuForm.id,
      "customerview": true,

    }
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.projectListCustomer = res.list;

          res.data.map((project: any) => {

            this.projectListCustomer.push({
              "name": project.name,
              "projectid": project.projectid,
              "contactperson": project.contactperson,

            });
          });


          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }

  getCustomerProduct() {
    this.gettingData = true;
    this.productListCustomer = [];
    console.log("okoko")

    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid": this.cuForm.id,
      "customerview": true,
      "showallproduct": false,
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.productListCustomer = res.list;

          res.list.map((product: any) => {
            var picname = "";
            var picid = "";
            var picuserid = ""
            if (product.piclist.length > 0) {
              if (product.piclist[0].picuser != null) {
                picname = product.piclist[0].picuser.picname
                picid = product.piclist[0].picuser.picid
                picuserid = product.piclist[0].picuser.userid
              }
            }
            this.productListCustomer.push({
              "autoid": product.autoid,
              "skucode": product.skucode,
              "name": product.name,
              "picname": picname,
              "picuserid": picuserid,
              "picid": picid,
              "productid": product.productid,
              "sortby": product.sortby,
              "modulelist": product.modulelist,
              "contactperson": product.contactperson,
              "check": false
            });
          });


          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }

  getProduct() {
    this.gettingData = true;

    this.productList = [];


    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "sortby": "",
      "pagesize": "",
    }
    this.kunyekService.getAllCRMProducts(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          res.list.map((product: any) => {
            var picname = "";
            var picid = "";
            var picuserid = ""
            if (product.piclist.length > 0) {
              if (product.piclist[0].picuser != null) {
                picname = product.piclist[0].picuser.picname
                picid = product.piclist[0].picuser.picid
                picuserid = product.piclist[0].picuser.userid
              }
            }

            console.log(picname)
            const checkproduct = this.productListCustomer.filter((el: any) => el.productid == product.productid).length;
            if (checkproduct == 0) {

              this.productList.push({
                "autoid": product.autoid,
                "skucode": product.skucode,
                "name": product.name,
                "picname": picname,
                "picuserid": picuserid,
                "picid": picid,
                "productid": product.productid,
                "sortby": product.sortby,
                "modulelist": product.modulelist,
                "contactperson": product.contactperson,
                "check": false,
                "contactid": ''
              });
            }

          });
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }

  getProject() {
    this.gettingData = true;

    this.projectlist = [];


    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "sortby": "",
      "pagesize": "",
    }
    this.kunyekService.getAllCRMproject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          res.data.map((project: any) => {

            const checkproject = this.productListCustomer.filter((el: any) => el.projectid == project.projectid).length;
            if (checkproject == 0) {
              this.projectlist.push({
                "name": project.name,
                "projectid": project.projectid,
                "contactid": '',
                "contactperson": "",
                "check": false,
              });
            }
          }
          );


          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
      }
    );

  }
  submitProduct() {
    this.productSaveLoading = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid": this.cuForm.id,
      "productlist": this.addproductlist

    }
    this.kunyekService.addcrmProduct(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          this.goProduct();

          this.productSaveLoading = false;
        } else {
          this.productSaveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.productSaveLoading = false;
      }
    );

  }


  submitProject() {
    this.projectSaveLoading = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "customerid": this.cuForm.id,
      "projectlist": this.addprojectlist

    }
    this.kunyekService.addcrmProject(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {

          this.goProject();

          this.projectSaveLoading = false;
        } else {
          this.productSaveLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.projectSaveLoading = false;
      }
    );

  }
  deleteProject() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingData = true;
        const data = {
          "domainid": this.allinoneService.getDomain().domainid,
          "orgid": this.orgid,
          "customerid": this.cuForm.id,
          "projectlist": [],
          "deleteprojectlist": this.deleteprojectlist

        }
        this.kunyekService.addcrmProject(data).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.gettingData = false;
            
            this.getCustomerProject();
            this.deleteprojectcheck = false;
          }
          else {
            this.gettingData = false;
            this.messageService.openSnackBar(res.status, 'warn');
          }
        },
          err => {
            this.gettingData = false;
          }
        )
      }
    });
  }


  deleteProduct() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingData = true;
        const data = {
          "domainid": this.allinoneService.getDomain().domainid,
          "orgid": this.orgid,
          "customerid": this.cuForm.id,
          "productlist": [],
          "deleteproductlist": this.deleteproductlist

        }
        this.kunyekService.addcrmProduct(data).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.gettingData = false;
            this.deleteproductcheck = false;
            this.getCustomerProduct();

          }
          else {
            this.gettingData = false;
            this.messageService.openSnackBar(res.status, 'warn');
          }
        },
          err => {
            this.gettingData = false;
          }
        )
      }
    });

  }

  downloadSheetRelatedProduct() {

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Products_of_' + this.cuForm.name + time + '.xlsx';
    console.log(this.productListCustomer)
    this.productListCustomer.map((product: any) => {
      data.push({
        "SKU": product.skucode,
        "Description": product.name,
        "Person in Charge": product.picname,
        "Contact person": product.contactperson,
      });
    });

    this.allinoneService.exportEcecl(data, name);
  }

  downloadSheetRelatedProject() {

    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Projects_of_' + this.cuForm.name + time + '.xlsx';
    console.log(this.productListCustomer)
    this.projectListCustomer.map((project: any) => {
      data.push({
        "Project Name": project.name,
        "Description": project.description,
        "Contact person": project.contactperson,
      });
    });

    this.allinoneService.exportEcecl(data, name);
  }


  contactid: any = '';

  chooseContact(data: any, index: any, product: any) {
    console.log(data);
    console.log(data.target.value);
    console.log(index);
    console.log(this.addproductlist);
    var i = this.addproductlist.map((x: any) => x.productid).indexOf(product.productid);
    console.log(i);
    if (i > -1) {
      this.addproductlist[i].contactid = data.target.value;
    } else {
      this.productList[index].contactid = data.target.value;

    }

    console.log(this.addproductlist)

  }

  chooseProjectContact(data: any, index: any, project: any) {
    console.log(data);
    console.log(data.target.value);
    console.log(index);
    console.log(this.addprojectlist);
    var i = this.addprojectlist.map((x: any) => x.projectid).indexOf(project.projectid);
    console.log(i);
    console.log(this.addprojectlist);
    if (i > -1) {
      this.addprojectlist[i].contactid = data.target.value;
    } else {
      this.projectlist[index].contactid = data.target.value;
    }
    console.log(this.addprojectlist)

  }

  generateShortCode() {
    this.customernameList = [];
    this.onSearch();
    this.cuForm.shortcode = '';
    let cu_name = this.cuForm.name;
    if (cu_name == '') {
      this.cuForm.shortcode = '';
      return;
    }
    let pj_name_arr = cu_name.split(' ');
    console.log(pj_name_arr);
    for (var i = 0; i < pj_name_arr.length; i++) {
      if (pj_name_arr[i].length > 0) {
        if (this.isLetter(pj_name_arr[i][0])) {
          this.cuForm.shortcode += pj_name_arr[i][0].toUpperCase();
        } else if (
          pj_name_arr[i].length > 1 &&
          this.isLetter(pj_name_arr[i][1])
        ) {
          this.cuForm.shortcode += pj_name_arr[i][1].toUpperCase();
        } else {
          continue;
        }
      } else {
        continue;
      }
    }
    if (this.cuForm.shortcode.length > 5) {
      this.cuForm.shortcode = this.cuForm.shortcode.slice(0, 5)
    }
  }
  isLetter(c: string) {
    return c.toString().toLowerCase() != c.toString().toUpperCase();
  }

  onSearch() {
    if (this.cuForm.name) {
      this.getCustomerName();

    }
  }

  popupName() {

    const dialog = this.dialog.open(CustomerNameComponent, {
      width: '500px',
      height: 'auto',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        "customernamelist": this.customernameList,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
      }
    });


  }

  getCustomerName() {
    this.getCustomer = true;

    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "searchcustomername": this.cuForm.name,

    }
    this.kunyekService.getCustomerName(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.productListCustomer = res.list;
          this.customernameList = res.list;
          console.log(this.customernameList);
          this.saveCustomerCheck = true;


          this.getCustomer = false;
        } else {
          this.getCustomer = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getCustomer = false;
      }
    );
  }

  keyPress(data: any) {
    this.customernameList = [];
    console.log("333333333333")
    // this.getCustomerName();



  }

  keyUp(data: any) {
    this.customernameList = [];
  }
  onKeyenter(data: any) {
    if (this.cuForm.name) {
      this.getCustomerName();

    }

  }

}

