import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { CheckinTicketDetailsComponent } from '../checkin-ticket-details/checkin-ticket-details.component';

@Component({
  selector: 'app-checkin-event-modal',
  templateUrl: './checkin-event-modal.component.html',
  styleUrls: ['./checkin-event-modal.component.scss']
})
export class CheckinEventModalComponent implements OnInit {

  msg : any ;
  locationUrl : any ;
  latlng : string = "" ;
  loading: boolean = false;
  checkin : any;

  constructor(
    public allinoneService : AllInOneService,
    private messageService : MessageService,
    private kunyekService: KunyekService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CheckinEventModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.msg = data.msg
    if(this.msg.type == 'checkin'){
      this.latlng = this.msg.lat +"," + this.msg.long + ""
    }
    else{
      this.latlng = this.msg.eventlat +"," + this.msg.eventlong + ""
    }
    this.locationUrl = "https://www.google.com/maps/search/?api=1&query="+ this.latlng
  }

  ngOnInit(): void {
    if(this.msg.type == 'checkin' || this.msg.type == 'activity'){
      this.getCheckIn();
    }
  }

  getCheckIn(){
    const domain = this.allinoneService.getDomain();
    this.loading = true;
    const data = {
      "syskey": this.msg.timeinoutid,
      "usersk": this.allinoneService.profile.syskey,
      "startdate": "",
      "enddate": "",
      "domain": domain.shortcode,
      "domainid": domain.domainid,
      "msguserid": this.msg.userid,
    }
    this.kunyekService.getCheckInList(data).subscribe((res: any) => {
      if(res.returncode == "300"){
        this.checkin = res.list[0];
        // this.checkin.ticketno = ['09762', '09763', '09764', '09765']
      }
      else{
        this.messageService.openSnackBar(res.message || res.error || res.status, 'warn');
      }
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }

  dismiss(){
    this.dialogRef.close();
  }

  openTicketDetails(ticket: any) {
    const dialog = this.dialog.open(CheckinTicketDetailsComponent, {
      width: '700px',
      height: '600px',
      maxHeight:'95vw',
      maxWidth:'95vw',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        orgid: this.checkin.orgid,
        ticketno: ticket,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }
}
