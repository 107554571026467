<div class="container-fluid">
  <div class="content">
    <app-breadcrumb [s_breadcrumb]="'Logistics'" [t_breadcrumb]="'Batch'" (emit)="goToLogistics()">
    </app-breadcrumb>


    <div class="mb-3 d-flex justify-content-between header">
      <div>
        <button type="button" class="btn btn-custom" (click)="addBatch()" *ngIf="!isAddBatch">
          <i class="fa fa-plus me-1"></i>Add Batch
        </button>

        <div *ngIf="isAddBatch" class="close-save-button">
          <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
            Cancel
          </button>
          <app-button-loader (click)="submitBatch()" [button_text]="'Save'" [load_button_text]="'Saving'"
            [isLoading]="saveLoading"></app-button-loader>
        </div>

      </div>
    </div>
    <ng-container *ngIf="!isAddBatch">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex flex-wrap justify-content-start align-items-center flex-wrap">
          <div class="input-group mb-3" style="width: 300px !important">
            <input class="form-control" type="text" placeholder="&#xf002; Search with ID"
              (ngModelChange)="searchTextChange()" aria-label="Search" (focus)="isFocus = true" (blur)="isFocus = false"
              [(ngModel)]="searchText" [ngClass]="{ 'c-search-input-with-clear': searchText }"
              style="font-family: FontAwesome, Ubuntu" />
            <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
              [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
              <mat-icon class="c-icon">close</mat-icon>
            </button>
          </div>
          <div class="ms-2 mb-3">
            <button class="btn btn-custom" (click)="refresh()" title="Refresh">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
          <div class="mb-3">
            <div class="line"></div>
          </div>
          <div class="d-flex align-items-center mb-3">
            <span class="total-count-text">Total:&nbsp;</span>
            <span class="total-count-num">{{ batchs.length }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3">
          <pagination-template *ngIf="batchs.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
            <div class="d-flex align-items-center justify-content-center">
              <div [class]="
                      p.isFirstPage()
                        ? 'custom-pagination-disabled'
                        : 'custom-pagination'
                    " (click)="p.previous()">
                <span class="me-1">&laquo;</span>&nbsp;Prev
              </div>
              <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                  <span>{{ page.label }}</span>
                </span>
                <div class="active-page" *ngIf="p.getCurrent() === page.value">
                  <span>{{ page.label }}</span>
                </div>
              </div>
              <div [class]="
                      nextDisabled
                        ? 'custom-pagination-disabled'
                        : 'custom-pagination'
                    " (click)="p.next()">
                Next&nbsp;<span class="ms-1">&raquo;</span>
              </div>
            </div>
          </pagination-template>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isAddBatch">
      <div class="row">
        <div class="mb-3 col-md-12">
          <label for="batchid" class="form-label"> Batch ID </label>
          <input type="text" class="form-control" id="batchid" name="batchid" [(ngModel)]="batchForm.batchid" />
        </div>
        <div class="mb-3 col-md-12">
          <button class="btn btn-custom" (click)="addOrders()">
            <i class="fas fa-plus-circle me-2"></i> Orders
          </button>
        </div>
        <div class="mb-3 col-md-6 col-sm-12 col-12" *ngFor="let od of batchForm.orders;">
          <div class="d-flex  p-2 justify-content-between" style="background-color: rgb(229, 229, 229);">
            <div class="order-image m-2 td-edit" (click)="viewOrder(od)">
              <div *ngIf="od.image.imgurl.length>0">
                <img [src]="od.image.imgurl[0]" alt="Order Image">
              </div>
              <div *ngIf="od.image.imgurl.length==0">
                <img src="../../../assets/images/order_box.png" alt="Order Image">
              </div>
            </div>
            <div class="d-flex flex-column justify-content-center flex-fill td-edit" (click)="viewOrder(od)">
              <div class="mb-1">
                ID : <span style="color: rgb(50, 120, 232);">
                  {{od.orderno}}
                </span>
              </div>
              <div class="mb-1">
                <strong>
                  {{od.desc}}
                </strong>
              </div>
              <div class="mb-1">
                Customer: {{od.cuname || "-"}}
              </div>

            </div>
            <label class="d-flex align-items-center justify-content-center" style="width: 80px;">
              <input type="checkbox" class="form-check-input" [id]="od.orderno" [checked]="od.checked"
                [(ngModel)]="od.checked">
            </label>

          </div>

        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="!isAddBatch">
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-lg-5 col-md-5" mat-sort-header="userid">ID</td>
              <td scope="col" class="col-lg-6 col-md-6" mat-sort-header="username">Order Count</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="batchsListLoading">
            <tr>
              <td colspan="3" class="nodata">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="batchsListLoading == false">
            <tr *ngIf="batchs.length == 0">
              <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let item of batchs | paginate: { itemsPerPage: 10, currentPage: currentPage }; let index = index">
              <td class="td-data td-edit" style="max-width: fit-content;"
                (click)="editBatch(item.batchid, item.batchno,item.orderlist)">
                <span tooltip data-bs-toggle="tooltip" data-bs-placement="right" [title]="item.batchno">
                  {{item.batchno}}
                </span>
              </td>
              <td class="td-data">
                <span tooltip data-bs-toggle="tooltip" data-bs-placement="right"
                  [title]="item.orderlist.length">{{item.orderlist.length}} </span>
              </td>
              <td class="d-flex justify-content-center">
                <button class="btn btn-outline-primary me-2"
                  (click)="editBatch(item.batchid, item.batchno,item.orderlist)">
                  <i class='fa fa-edit'></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deleteBatch(item)">
                  <i class='fa fa-trash-o'></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>