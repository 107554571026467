<div mat-dialog-content>
    <p>Unblock <b>{{user.username ? user.username : user.userid}}</b>?</p>
</div>

<div mat-dialog-actions align="end">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary mx-2" [disabled] = "loading">
        Cancel
    </button>
    <button type="button" (click)="onConfirm()" class="btn btn-custom" cdkFocusInitial [disabled] = "loading">
        <span *ngIf = "!loading">
            Ok
        </span>
        <span *ngIf = "loading">
            <span class="spinner-border spinner-border-sm button-spinner" role="status" aria-hidden="true"></span>
        </span>
    </button>
</div>