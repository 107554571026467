import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimeToShow'
})
export class FormatTimeToShowPipe implements PipeTransform {

  transform(time: any){
    var hour = time.substring(0, 2);
    if (hour.startsWith("0")) {
      hour = hour.substring(1);
    }

    const minute = time.substring(2, 5);
    const ampm = time.substring(9, 11);
    return hour + minute + " " + ampm;
  }

}
