<!-- <perfect-scrollbar [ngClass]="{'event-container':  checkModeal}" [config]="config" [scrollIndicators]="true"
    fxFlex="auto" #perfectScroll> -->

<div class="container-fluid" [ngClass]="{'scroll':checkModeal}">
    <div class="content">
        <div class="d-flex justify-content-between align-items-center mb-2 " *ngIf="checkModeal">
            <div class="label title" *ngIf="!customerAdd" style="font-size: 17px;">Customers</div>
            <div class="label title" *ngIf="customerAdd" style="font-size: 17px;">Add Customer</div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div *ngIf="!customerAdd ">
            <app-crm-breadcrumb *ngIf="!checkModeal" s_breadcrumb="Customers" (emit)="cancelFunction()">
            </app-crm-breadcrumb>


            <!--  (emit1)="cancels(2)"  -->
            <!-- isMoblie -->

            <!-- comment status -->


            <div class="d-flex flex-wrap">
                <div class="showbtn me-auto  mb-2">
                    <button class="btn btn-custom " (click)="new()">
                        <i class="fa fa-plus me-1"></i> New
                    </button>
                </div>
                <div class="mb-2 me-2" *ngIf="checkModeal">
                    <input  class="form-control" type="text" placeholder="&#xf002; Search"
                        aria-label="Search" [(ngModel)]="searchpipe"  style="font-family: FontAwesome,Ubuntu;">
                </div>



                <div *ngIf="customerData.phone && !isMobileView && !checkModeal "
                    class="me-2 btn-group mb-2 d-flex  align-items-center" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="advanSearchCustomer()">Phone:
                        {{customerData.phone}}</button>
                    <button (click)="closeAdvanSearchCusotemr(1)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">

                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="customerData.name && !isMobileView && !checkModeal"
                    class="me-2 btn-group mb-2 d-flex  align-items-center" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="advanSearchCustomer()">Contact
                        Name: {{customerData.name}}</button>
                    <button (click)="closeAdvanSearchCusotemr(2)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">

                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>
                <div *ngIf="customerData.product && !isMobileView && !checkModeal "
                    class="me-2 btn-group mb-2 d-flex  align-items-center" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d" (click)="advanSearchCustomer()">Product:
                        {{customerData.product}}</button>
                    <button (click)="closeAdvanSearchCusotemr(3)" type="button" class="btn btn-secondary"
                        style="background-color: #87929d;border-color:#87929d">

                        <img src="assets/images/icon.png" alt="" style="width: 11px;height: 11px;margin-top: -3px;">
                    </button>
                </div>

                <div class="mb-2 me-2 d-flex flex-wrap align-items-center" [matMenuTriggerFor]="cusotemerMenu">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false"
                        mdbDropdownToggle
                        style="background-color: #87929d;border-color: #87929d;color: white;min-width: 100px;">
                        {{customerstatusname != 'Status' ? 'Status : ' : '' }} {{customerstatusname}}
                    </button>
                </div>
                <mat-menu #cusotemerMenu="matMenu" id="service-menu" xPosition="before">
                    <button class="mat-item" (click)="ChangeCustomerStatus('status')">-</button>
                    <button class="mat-item" *ngFor="let item of customerStatus"
                        (click)="ChangeCustomerStatus(item)">{{item.value}}</button>
                </mat-menu>


                <!-- <div class="mb-3 " *ngIf="crmForm.type == '002' && !checkModeal" [ngClass]="{'col-lg-3 col-md-12 col-sm-12':  !isMobileView}">
                        <select class="form-select" aria-label="Select statua" id="status" name="status" [(ngModel)]="searchStatusCustomer" (change)="ChangeCustomerStatus($event)">
                            <option selected value=""> ( Status ) </option>
                            <option *ngFor="let item of customerStatus" value="{{item.value}}">{{item.value}}</option>
                        </select>
                    </div> -->





            </div>


            <div class=" d-flex flex-wrap " *ngIf=" !checkModeal">
                <div class="w-100 d-flex flex-wrap ">


                    <div class=" w-100 d-flex flex-wrap me-auto ">

                        <div class="input-group me-2 mb-2" style="width: 300px !important;" *ngIf="!isMobileView ">
                            <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                                (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                                [ngClass]="{'c-search-input-with-clear' : searchText }"
                                style="font-family: FontAwesome,Ubuntu;" (keyup.enter)="onSearch()">

                            <button class="btn btn-outline-secondary " type="button" *ngIf="searchText"
                                (click)="clear(2)" [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                                <mat-icon class="c-icon">close</mat-icon>
                            </button>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" (click)="onSearch()">
                                <mat-icon class="c-icon">search</mat-icon>
                            </button>

                        </div>
                        <div class="dropdown mb-2 me-2">
                            <button type="button" class="btn btn-custom" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false" matTooltip="Page Size">
                                <mat-icon>filter_list</mat-icon>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <div c style="text-align: center;">Page size</div>
                                </li>
                                <li>
                                    <hr class="dropdown-divider">
                                </li>
                                <li class="dropdown-item" *ngFor="let page of pg">
                                    <input type="radio" name="pg-count" value="{{page.count}}"
                                        (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                        [checked]="page.count==itemPerPage">
                                    <label for="pg{{page.name}}" class="col-10 p-1">
                                        {{page.name}}
                                    </label>
                                </li>
                            </ul>
                        </div>

                        <!-- me-2 -->
                        <div class="me-2 mb-2 ">
                            <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </div>
                        <div class="me-2 mb-2">
                            <div class="btn btn-custom " (click)="advanSearchCustomer()" matTooltip="Advanced Search">
                                <i class="fa fa-filter" aria-hidden="true"></i>
                            </div>
                        </div>


                        <div class="line mb-2"></div>

                        <div class="mb-2 me-3" *ngIf=" !checkModeal ">
                            <button type="button " class="btn btn-custom " (click)="downloadSheetCustomer() "
                                matTooltip="Export Customer To Excel " [disabled]="excelexportLoading ">
                                <img *ngIf="!excelexportLoading " class="excel-icon "
                                    src="../../../assets/images/excel.png ">
                                <span *ngIf="excelexportLoading " style="margin-right: 10px; "
                                    class="spinner-border spinner-border-sm button-spinner " role="status "
                                    aria-hidden="true "></span>
                                Export
                            </button>
                        </div>

                        <div class="mb-2 " *ngIf=" !checkModeal ">
                            <button type="button " class="btn btn-custom " (click)="importCustomer() "
                                matTooltip="Bulk Upload ">
                                <img class="excel-icon " src="../../../assets/images/excel.png ">
                                Import
                            </button>
                        </div>


                        <div class="line mb-2 "></div>
                        <div class="d-flex align-items-center me-auto mb-2 ">
                            <span class="total-count-text ">Total:&nbsp;</span> <span
                                class="total-count-num ">{{total}}</span>
                        </div>

                        <div *ngIf="!gettingData  && !checkModeal " class="d-flex align-items-center mb-2 ">
                            <pagination-template *ngIf="data.length> 0 " #p="paginationApi"
                                (pageChange)="pageChange($event)">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'"
                                        (click)="p.previous()">
                                        <span class="me-1">&laquo;</span>&nbsp;Prev
                                    </div>
                                    <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                                        <span class="page" (click)="p.setCurrent(page.value)"
                                            *ngIf="p.getCurrent() !== page.value">
                                            <span>{{ page.label }}</span>
                                        </span>
                                        <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                            <span>{{ page.label }}</span>
                                        </div>
                                    </div>
                                    <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'"
                                        (click)="p.next()">
                                        Next&nbsp;<span class="ms-1">&raquo;</span>
                                    </div>
                                </div>
                            </pagination-template>
                        </div>

                        <div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="input-group me-2 mb-2" *ngIf="isMobileView ">
                <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                    (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                    [ngClass]="{'c-search-input-with-clear' : searchText }" style="font-family: FontAwesome,Ubuntu;"
                    (keyup.enter)="onSearch()">
                <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear(2)"
                    [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                    <mat-icon class="c-icon">close</mat-icon>
                </button>
                <button class="input-group-text btn btn-custom" id="btnGroupAddon2" (click)="onSearch()">
                    <mat-icon class="c-icon">search</mat-icon>
                </button>

            </div>


            <div>
                <!--  -->
                <!-- style="overflow-x: auto;
                    white-space: nowrap;
                    height: calc(100vh - 232px);" -->
                <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                    <table class="table table-responsive table-borderless">
                        <thead class="table-header">
                            <tr>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="customerid">ID
                                </td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="customername">Name
                                </td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="customerphone">Phone
                                </td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="customeremail">Email
                                </td>
                                <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="deal">Deal(s)
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="customertype">Type</td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="customerstatus">Status
                                </td>
                                <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="customercreatedby">
                                    Created by
                                </td>
                                <td scope="col" class="d-flex justify-content-center">Action</td>
                            </tr>
                        </thead>
                        <tbody *ngIf="gettingData">
                            <tr>
                                <td colspan="8" class="nodata">
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="gettingData== false">
                            <tr *ngIf="data.length == 0">
                                <td colspan="8" class="nodata" *ngIf="!customerview">Empty</td>
                                <td style="color: red;font-size: 14px;" colspan="8" class="nodata" *ngIf="customerview">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"
                                        style="margin-right: 7px;"></i>{{allinoneService.unauthorizedViewMessage}}
                                </td>
                            </tr>
                            <!-- | crmFilter :searchTOrgext:searchStatusCustomer:goCustomer:3 -->
                            <tr
                                *ngFor="let item of data | crmFilter :searchpipe:searchStatusCustomer:goCustomer:29 | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage }; let index = index">
                                <td class="td-data " (click)="editCustomer(item)">
                                    <span [title]="item.customerid">{{item.customerid}}</span>
                                </td>
                                <td class="td-data td-edit" (click)="editCustomer(item)">
                                    <span [title]="item.customername">{{item.customername}}</span>
                                </td>
                                <td class="td-data" (click)="editCustomer(item)">
                                    <span [title]="item.customermobile">{{item.customermobile}}</span>
                                </td>
                                <td class="td-data" (click)="editCustomer(item)">
                                    <span [title]="item.customermobile">{{item.customeremail}}</span>
                                </td>
                                <td class="td-data" (click)="editCustomer(item)">
                                    <span [title]="item.customermobile">{{item.deallist.length}}</span>
                                </td>
                                <td class="td-data" (click)="editCustomer(item)">
                                    <span [title]="item.typename">{{item.typename}}</span>
                                </td>
                                <td class="td-data" (click)="editCustomer(item)">
                                    <span class="open-status"
                                        [ngClass]="{'closed-status-deal': item.customerstatus == '001' }"
                                        [title]="item.statusname">{{item.statusname}}</span>
                                </td>
                                <td class="td-data" (click)="editCustomer(item)">
                                    <span
                                        [title]="item.createdbyname ? item.createdbyname : item.createdby">{{item.createdbyname
                                        ? item.createdbyname : item.createdby}}</span>
                                </td>
                                <td class="d-flex justify-content-center">
                                    <button title="Edit" class="btn btn-outline-primary" (click)="editCustomer(item)">
                                        <i class='fa fa-edit'></i>
                                    </button>
                                    <button title="Delete" class="ms-2 btn btn-outline-danger"
                                        (click)="deleteCustomer(item.customerid)">
                                        <i class='fa fa-trash-o'></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- </perfect-scrollbar> -->


            <div *ngIf="!gettingData  && !checkModeal ">
                <pagination-template *ngIf="data.length > 0 " #p="paginationApi" (pageChange)="pageChange($event)">
                    <div class="d-flex align-items-center justify-content-center">
                        <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.previous()">
                            <span class="me-1">&laquo;</span>&nbsp;Prev
                        </div>
                        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </span>
                            <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.next()">
                            Next&nbsp;<span class="ms-1">&raquo;</span>
                        </div>
                    </div>
                </pagination-template>

            </div>
        </div>




        <div *ngIf="customerAdd">
            <app-customers [orgid]="crmForm.orgid" [checkModeal]="checkModeal" [isExcel]="isExcel"
                [businessType]="businessType" [currencyType]="currencyType" [editData]="dataForEdit"
                [customerStatus]="customerStatus" [statusList]="dealStatus" [stageList]="stages" (save)="complete()"
                (crmcancel)="cancel()">
            </app-customers>
        </div>


    </div>


</div>
<!-- </perfect-scrollbar> -->