import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  name: string = '';
  fileurl: string = '';

  constructor(
    public dialogRef: MatDialogRef<FileViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.name = data.name;
    this.fileurl = data.fileurl;
  }

  ngOnInit(): void {
  }

  onDismiss() {
    this.dialogRef.close();
  }

}
