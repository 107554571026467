import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';

@Component({
  selector: 'app-invalid-permission',
  templateUrl: './invalid-permission.component.html',
  styleUrls: ['./invalid-permission.component.scss']
})
export class InvalidPermissionComponent implements OnInit {

  constructor(
    public allinoneService: AllInOneService,

  ) { }

  ngOnInit(): void {
  }

}
