<div>
  <div class="label title" style="font-size: 17px; padding: 0px 0px 15px 0px; font-weight: 500">
    Advanced Search
  </div>

  <div class="row">
    <div class="col-sm-4 d-flex gap-4">
      <div class="form-check">
        <input type="radio" class="form-check-input" [checked]="!isDateBetween" (change)="radioOnChange('date')">
        <label for="date" class="form-check-label">Date</label>
      </div>
      <div class="form-check">
        <input type="radio" class="form-check-input" [checked]="isDateBetween" (change)="radioOnChange('between')">
        <label for="between" class="form-check-label">Between</label>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm">
      <div class="mb-3">
        <input type="date" class="form-control" id="startdate" name="stardate" step="1" style="min-width: 200px;"
          [(ngModel)]="startdate" />
      </div>
    </div>
    <div class="col-sm">
      <div class="mb-3" *ngIf="isDateBetween">
        <input type="date" class="form-control" id="enddate" name="enddate" step="1" style="min-width: 200px;"
          [(ngModel)]="enddate" />
      </div>
    </div>
  </div>

  <!--Footer-->
  <div mat-dialog-actions align="end" style="padding-right: 20px">
    <button type="button" (click)="onDismiss()" class="btn btn-outline-secondary me-2">
      Cancel
    </button>
    <button type="button" (click)="search()" class="btn btn-custom">
      Search
    </button>
  </div>
  <!--Footer-->
</div>