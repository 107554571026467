<div class="container-fluid">
  <div class="content">

    <div class="mt-2 mb-2 d-flex">
      <div class="d-flex align-items-center">
        <button *ngIf="!checkinAddorNot" type="button" class="btn btn-custom m-0 float-start me-1" (click)="add()">
          <i class="fa fa-plus me-1"></i>Check In
        </button>
        <app-button-loader class="float-start " [button_text]="'Search'" [load_button_text]="'Searching'"
          [isLoading]="searchLoading" (click)="search()" *ngIf="!checkinAddorNot">
        </app-button-loader>
        <!-- <app-button-loader class="float-start " [button_text]="'T'" [load_button_text]="'Searching'"
          [isLoading]="searchLoading" (click)="t()" *ngIf="!checkinAddorNot">
        </app-button-loader> -->
        <div class="line desktopfilter" *ngIf="!checkinAddorNot">

        </div>
        <div *ngIf="!checkinAddorNot" class="me-3 desktopfilter">
          <!-- <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="getcheckinForm.startdate"
            required #startdate="ngModel" step="1" max="9999-12-31" style="min-width: 200px;"> -->

          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="getcheckinForm.startdate"
            style="min-width: 200px;">

        </div>
        <div *ngIf="!checkinAddorNot" class="desktopfilter">
          <!-- <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="getcheckinForm.enddate"
            required #enddate="ngModel" [min]="getcheckinForm.startdate" max="9999-12-31" step="1"
            style="min-width: 200px;"> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" [bsValue]="getcheckinForm.enddate" [minDate]="getcheckinForm.startdate"
            [(ngModel)]="getcheckinForm.enddate" style="min-width: 200px;" readonly>
        </div>
        <div *ngIf="checkinAddorNot" class="d-flex align-items-center">
          <button type="button" class="btn btn-custom close-save-button me-1" (click)="cancel()"
            [disabled]="saveLoading">
            Cancel
          </button>
          <app-button-loader class="float-start" (click)="submitActivity()" [button_text]="'Save'"
            [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>
          <div class="line" *ngIf="activityView">
          </div>
          <button type="button" class="btn btn-custom close-save-button" (click)="share(atForm)"
            [disabled]="saveLoading || gettingGroupList" *ngIf="activityView">
            <i class="fas fa-share-alt me-2"></i>Share
          </button>

        </div>

      </div>



    </div>
    <div *ngIf="!checkinAddorNot">

      <div class="row d-flex g-3 mb-3 d-flex" class="mobiledatefilter">
        <div class="col-md-6 mb-2">
          <label for="startdate" class="form-label">Start Date</label>
          <!-- <input type="date" class="form-control" id="startdate" name="startdate" [(ngModel)]="getcheckinForm.startdate"
            required #startdate="ngModel" max="9999-12-31" step="1"> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="getcheckinForm.startdate"
            style="min-width: 200px;" required #startdate="ngModel">

          <div class="invalid-feedback" *ngIf="startdate.invalid || (startdate.dirty || startdate.touched) ">
            <div *ngIf="startdate.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label for="enddate" class="form-label">End Date</label>
          <!-- <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="getcheckinForm.enddate"
            required #enddate="ngModel" [min]="getcheckinForm.startdate" max="9999-12-31" step="1"> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="getcheckinForm.enddate" style="min-width: 200px;"
            required #enddate="ngModel">

          <div class="invalid-feedback" *ngIf="enddate.invalid || (enddate.dirty || enddate.touched)">
            <div *ngIf="enddate.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>

      </div>
      <div>

        <div *ngFor="let x of sortedArray;let i=index;" class="p-2">
          <div class="d-flex justify-content-between mt-1 date-header px-2 pe-3 p-1" [class.redbackground]="x.isoffday"
            (click)="hide(i)">
            <div class="d-flex align-items-center" style="color: white;">
              <span> {{x.showdate}} </span>
              <span *ngIf="x.holiday" class="ms-1">( {{ x.holiday }} )</span>
              <i class="fas fa-calendar-times ms-2" *ngIf="x.isoffday"></i>
            </div>
            <div class="">
              <i *ngIf="x.show" class="fa fa-caret-down" aria-hidden="true"
                style="color:white;cursor: pointer;font-size: 1.3em;"></i>
              <i *ngIf="!x.show" class="fa fa-caret-right" aria-hidden="true"
                style="color:white;cursor: pointer;font-size: 1.3em;"></i>

            </div>
          </div>
          <div *ngIf="x.data.length == 0 && x.show" class="row">
            <div class="nodata emp">Empty</div>
          </div>
          <div *ngFor="let y of x.data;">
            <div class="p-2 checkinList d-flex justify-content-between" *ngIf="x.show" style="cursor: pointer;">
              <div class="d-flex ">

                <!-- <div class="float-start col-1" (click)="showActivity(y,y.iotype)"> -->
                <div class="me-3" (click)="showActivity(y,y.iotype)">
                  <ng-container *ngIf="!y.backdate">
                    <i class="far fa-circle" aria-hidden="true" style="color: red;" *ngIf="y.iotype=='out'"></i>
                    <i class="far fa-circle" aria-hidden="true" style="color: green;" *ngIf="y.iotype=='in'"></i>
                    <i class="fa fa-circle" aria-hidden="true" style="color: grey; font-size: 16px;"
                      *ngIf="y.iotype=='checkin'"></i>
                  </ng-container>
                  <ng-container *ngIf="y.backdate">
                    <mat-icon class="mat-checkin-icon" aria-hidden="true" style="color: red;"
                      *ngIf="y.iotype=='out'">timelapse</mat-icon>
                    <mat-icon class="mat-checkin-icon" aria-hidden="true" style="color: green;"
                      *ngIf="y.iotype=='in'">timelapse</mat-icon>
                    <mat-icon class="mat-checkin-icon" aria-hidden="true" style="color: grey;"
                      *ngIf="y.iotype=='checkin'">timelapse</mat-icon>
                  </ng-container>
                  <i class="fa fa-qrcode" aria-hidden="true" style="color: black;" *ngIf="y.iotype=='event'"></i>
                  <i class="fa fa-calendar" aria-hidden="true" style="color: black;" *ngIf="y.iotype=='activity'"></i>
                  <div class="waterfall d-flex" *ngIf="y.child.length>0">
                    <div class="waterfall-item">
                    </div>

                  </div>
                </div>



                <div *ngIf="y.iotype=='checkin' || y.iotype=='in' || y.iotype=='out'" (click)="showActivity(y,y.iotype)"
                  class="">
                  <div class="d-flex flex-column">
                    <div class="d-flex checkin-items">
                      <div class="me-4 d-flex justify-content-between checkin-items" style="min-width: 40vw;">
                        <div class="d-flex flex-column">
                          <div class="d-flex">
                            <b>{{allinoneService.formatTimeToShow(y.starttime)}} </b>
                            <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '001'"
                              class="d-flex ms-2 align-items-center">
                              <img src="../../../assets/images/checkin/scan_facedevice.png" width="13px">
                            </span>
                            <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '002'"
                              class="d-flex ms-2 align-items-center">
                              <img src="../../../assets/images/checkin/scan_fingerprint.png" width="13px">
                            </span>
                            <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '003'"
                              class="d-flex ms-2 align-items-center">
                              <img src="../../../assets/images/checkin/scan_faceai.png" width="13px">
                            </span>
                            <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '004'"
                              class="d-flex ms-2 align-items-center">
                              <img src="../../../assets/images/checkin/scan_qr.png" width="13px">
                            </span>
                            <!-- <span aria-hidden="true" *ngIf="y.fromscan && y.scantype == '002'"
                              class="d-flex ms-2 align-items-center">
                              <img src="../../../assets/images/checkin/fingerprint.png" width="13px">
                            </span> -->
                            <small class="ms-2" style="color: gray;" *ngIf="y.iotype!='checkin'">{{y.iotype == 'in' ?
                              'Time In' : "Time Out"}}</small>
                            <div *ngIf="y.backdatecount != '' && y.backdatecount != '0'" class="ms-3 backdatecount">
                              -&nbsp;{{y.backdatecount}}&nbsp;{{y.backdatecount == '1' ? 'day' : 'days'}}
                            </div>
                          </div>
                          <div *ngIf="y.orgid != ''  && y.orgid != currentOrgid" class="my-1">
                            <span class="orgname p-1 ">
                              {{y.orgid == '0' ? "Private": y.orgname }}
                            </span>
                          </div>
                          <div *ngIf="y.taskid!=''">
                            Task ID: {{y.autoid}} - {{y.taskdescription}}
                          </div>
                          <!-- ### -->
                          <div *ngIf="y.fromscan && (y.scantype != '002' && y.scantype != '')"
                            class="d-flex align-items-center">
                            <div *ngIf="y.fromscan && (y.scantype != '004' && y.scantype != '')">
                              <div *ngIf="!y.temperaturestatus" class="temp-status redbackground me-1">
                                Fever
                              </div>
                              <div class="temp-status me-2" [ngClass]="{'redbackground' : !y.temperaturestatus}">
                                <img src="../../../assets/images/checkin/thermometer.png" width="15px"
                                  class="me-1">{{y.temperature}} °C
                              </div>
                              <div class="d-flex align-items-center">
                                <img *ngIf="y.markstatus" src="../../../assets/images/checkin/mask_check.png"
                                  width="15px">
                                <img *ngIf="!y.markstatus" src="../../../assets/images/checkin/mask_cancel.png"
                                  width="15px">
                              </div>
                            </div>

                          </div>


                        </div>

                        <div class="">

                          <ng-container *ngIf="y.location!= '' && y.lat == '' && y.long == ''; else otherTemplate">
                            <span class="red-text">
                              {{y.location}}
                            </span>
                          </ng-container>
                          <ng-template #otherTemplate>
                            <span *ngIf="y.loctype == '1' || y.loctype == '2' || y.loctype == '3' || y.loctype == '5'">
                              @
                            </span>
                            <span *ngIf="y.loctype == '4'">
                              #
                            </span>
                            <span style="color: #9c9c9c;" *ngIf="y.location==''">
                              {{y.lat}} - {{y.long}}
                            </span>
                            <span *ngIf="y.location!='' && y.lat && y.long">
                              {{y.location}}<br> <span style="color: #9c9c9c;">({{y.lat}} -
                                {{y.long}})</span>
                            </span>
                          </ng-template>
                        </div>

                      </div>
                      <div class="" style="min-width: 15vw;">
                        <div [innerHTML]="allinoneService.replaceEnter(y.description)">

                        </div>
                      </div>
                    </div>

                    <div *ngIf="y.child.length>0" class="endbox">
                      <div class="connect-waterfall d-flex" *ngIf="y.child.length>0">
                        <div class="connect-waterfall-item">
                        </div>

                      </div>
                      <div>
                        <b>{{allinoneService.formatTimeToShow(y.child[0].starttime)}}</b>
                        [
                        <small>
                          {{calEndTime(y.date,y.starttime,y.child[0].date,y.child[0].starttime)}}

                        </small>]
                      </div>

                    </div>
                    <div *ngIf="y.timeoutduration.length>0">

                      [
                      <small>
                        {{calDuration(y.timeoutduration)}}

                      </small>]

                    </div>

                  </div>
                  <!-- class="float-start col-10"> -->


                </div>
                <div *ngIf="y.iotype=='event'" class="d-flex checkin-items" (click)="showActivity(y,y.iotype)">
                  <div class="d-flex checkin-items justify-content-between me-4" style="min-width: 40vw;">



                    <div class="d-flex flex-column">
                      <div class="event-date">
                        <b>{{y.eventname}}</b><br>
                        <b>{{y.eventstartdate | date:"dd/MM/yyyy hh:mm a" }} - {{y.eventenddate |
                          date:"dd/MM/yyyy hh:mm a" }}</b>

                      </div>
                      <div *ngIf="y.orgid != ''  && y.orgid != currentOrgid" class="my-1">
                        <span class="orgname p-1 ">
                          {{y.orgid == '0' ? "Private": y.orgname }}

                        </span>
                      </div>
                      <div *ngIf="y.taskid!=''">
                        Task ID: {{y.autoid}} - {{y.taskdescription}}
                      </div>


                    </div>


                    <div style="min-width: 10.7rem;">
                      <div style="color: #9c9c9c;" *ngIf="y.eventlocation==''">
                        @{{y.eventlat}} - {{y.eventlong}}
                      </div>
                      <div *ngIf="y.eventlocation!=''">
                        @{{y.eventlocation}} <br>
                        <!-- <span style="color: #9c9c9c;">{{y.eventlat}} -
                                                    {{y.eventlong}}</span> -->
                      </div>
                    </div>

                  </div>
                  <div class="" style="min-width: 15vw;">
                    <div [innerHTML]="allinoneService.replaceEnter(y.eventdescription)">

                    </div>
                  </div>
                </div>
                <div *ngIf="y.iotype=='activity'" class="d-flex checkin-items">
                  <div class="d-flex checkin-items " style="min-width: 42.4vw;">
                    <div class="d-flex flex-column">
                      <div class="d-flex" (click)="showActivity(y,y.iotype)">
                        <div>
                          <b>{{allinoneService.formatTimeToShow(y.starttime)}} -
                            {{allinoneService.formatTimeToShow(y.endtime)}}</b><span *ngIf="y.enddate != y.date"
                            class="ms-2">({{allinoneService.formatDBToShow(y.enddate)}})</span>
                        </div>
                        <div *ngIf="y.backdatecount != '' && y.backdatecount != '0'" class="ms-3 backdatecount">
                          -&nbsp;{{y.backdatecount}}&nbsp;{{y.backdatecount == '1' ? 'day' : 'days'}}
                        </div>
                      </div>

                      <div *ngIf="y.orgid != ''  && y.orgid != currentOrgid" class="my-1"
                        (click)="showActivity(y,y.iotype)">
                        <span class="orgname p-1 ">
                          {{y.orgid == '0' ? "Private": y.orgname }}
                        </span>
                      </div>
                      <div *ngIf="y.taskid!=''" (click)="showActivity(y,y.iotype)">
                        Task ID: {{y.autoid}} - {{y.taskdescription}}
                      </div>
                      <div *ngIf="y.ticketno?.length > 0" class="my-1 d-flex flex-wrap" style="row-gap: 5px;">
                        <div *ngFor="let ticket of y.ticketno" class="me-2 priority"
                          (click)="openTicketDetails(ticket)">
                          #{{ticket}}
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="pe-3" (click)="showActivity(y,y.iotype)" style="word-break: break-all">
                    {{y.description}}
                  </div>
                </div>

              </div>
              <div>
                <div class="d-flex justify-content-center edbutton">
                  <button class="btn btn-outline-info me-2" *ngIf="y.iotype=='activity' && !gettingGroupList"
                    (click)="share(y)">
                    <i class='fas fa-share-alt'></i>
                  </button>
                  <button class="btn btn-outline-primary me-2" *ngIf="y.iotype=='activity'"
                    (click)="showActivity(y,y.iotype)">
                    <i class='fa fa-edit'></i>
                  </button>
                  <button class="btn btn-outline-danger" *ngIf="y.iotype=='activity'" (click)="deleteCheckin(y.syskey)">
                    <i class='fa fa-trash-o'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div *ngIf="checkinAddorNot">
      <div class="mb-3 col-md-12 ">
        <label for="activity" class="form-label">Activity</label>
        <select class="form-select" aria-label="Select Activity" id="activity" name="activity"
          (change)="changeType($event)" [disabled]="gettingType" [(ngModel)]="atForm.typesk" #activity="ngModel"
          [ngClass]="{ 'is-invalid': atFormSubmitted && activity.invalid }">
          <!-- <option selected value="">Select Organization</option> -->
          <option *ngFor="let t of typelist;" [value]="t.syskey" [selected]="t.syskey==selectedSyskey">
            {{t.name}}</option>
        </select>
        <mat-progress-bar mode="indeterminate" *ngIf="gettingType"></mat-progress-bar>

        <div class="invalid-feedback"
          *ngIf="activity.invalid || (activity.dirty || activity.touched) && atFormSubmitted">
          <div *ngIf="activity.errors?.required">
            {{allinoneService.input_err_msg}}
          </div>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6 mb-3">
          <label for="date" class="form-label">Date</label>
          <!-- <input type="date" class="form-control" id="date" name="date" [(ngModel)]="atForm.date" required
            #date="ngModel" step="1" max="9999-12-31" [ngClass]="{ 'is-invalid': atFormSubmitted && date.invalid }"> -->
          <input type="text" class="form-control datepicker-readonly" placeholder="dd/mm/yyyy" bsDatepicker
            [bsConfig]="generalDatePickerConfig" readonly [(ngModel)]="atForm.date" style="min-width: 200px;" required
            #date="ngModel" [ngClass]="{ 'is-invalid': atFormSubmitted && date.invalid }">

          <div class="invalid-feedback" *ngIf="date.invalid || (date.dirty || date.touched) && atFormSubmitted">
            <div *ngIf="date.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <!-- <div class="col-md-6 mb-3">
          <label for="date" class="form-label">Date</label>
          <input type="date" class="form-control" id="date" name="date" [(ngModel)]="atForm.date" required
            #date="ngModel" step="1" max="9999-12-31" [ngClass]="{ 'is-invalid': atFormSubmitted && date.invalid }">

          <div class="invalid-feedback" *ngIf="date.invalid || (date.dirty || date.touched) && atFormSubmitted">
            <div *ngIf="date.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div> -->
        <!-- <div class="col-md-6 mb-3">
          <label for="date" class="form-label">End Date</label>
          <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="atForm.enddate" required
            #enddate="ngModel" step="1" [ngClass]="{ 'is-invalid': atFormSubmitted && enddate.invalid }"
            [min]="atForm.date">

          <div class="invalid-feedback"
            *ngIf="enddate.invalid || (enddate.dirty || enddate.touched) && atFormSubmitted">
            <div *ngIf="enddate.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div> -->
        <div class="mb-3 col-md-6">
          <label for="activitytime" class="form-label">Time</label>
          <select class="form-select" aria-label="Select Activity Time" id="activitytime" name="activitytime"
            (change)="changeActivityTime($event)" [(ngModel)]="atForm.activitytime">
            <option selected value="">Select Time</option>
            <option *ngFor="let atime of activityTimeArray;" [value]="atime.id">
              {{atime.name}}</option>
          </select>
        </div>
      </div>

      <div class="row g-3 mb-3 ">
        <div class="col-md-6">
          <label for="starttime" class="form-label">Start Time</label>
          <input type="time" class="form-control" id="starttime" name="starttime" [(ngModel)]="atForm.starttime"
            required #starttime="ngModel" step="1" [ngClass]="{ 'is-invalid': atFormSubmitted && starttime.invalid }"
            [disabled]="atForm.activitytime != '' && atForm.typesk == '6106406749'">

          <div class="invalid-feedback"
            *ngIf="starttime.invalid || (starttime.dirty || starttime.touched) && atFormSubmitted">
            <div *ngIf="starttime.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label for="endtime" class="form-label">End Time</label>
          <input type="time" class="form-control" id="endtime" name="endtime" [(ngModel)]="atForm.endtime" required
            #endtime="ngModel" [ngClass]="{ 'is-invalid': atFormSubmitted && endtime.invalid }" [min]="atForm.starttime"
            step="1" [disabled]="atForm.activitytime != '' && atForm.typesk == '6106406749'">

          <div class="invalid-feedback"
            *ngIf="endtime.invalid || (endtime.dirty || endtime.touched) && atFormSubmitted">
            <div *ngIf="endtime.errors?.required">
              {{allinoneService.input_err_msg}}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label for="atdesc" class="form-label">Description</label>
        <textarea class="form-control" id="atdesc" name="atdesc" rows="5" [(ngModel)]="atForm.desc"
          placeholder="Enter Description for Your Event"></textarea>
      </div>
      <div class="row g-3">
        <div class="mb-3 col-md-6">
          <label for="org" class="form-label">Organization</label>
          <select class="form-select" aria-label="Select Organization" id="org" name="org" (change)="changeOrg($event)"
            [disabled]="gettingOrgList" [(ngModel)]="atForm.orgid">
            <option selected value="0">-</option>
            <option *ngFor="let org of orgList;" [value]="org.orgid">
              {{org.name}}</option>
          </select>
          <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>
        </div>
        <div class="mb-3 col-md-6">
          <label for="taskid" class="form-label">Task ID</label>
          <div class="custom-input-form" id="taskid" name="taskid">
            <div (click)="selectTask()" style="cursor: pointer;">
              <div *ngIf="!atForm.taskautoid" (click)="selectTask()">
                -
              </div>
              <div *ngIf="atForm.taskid">
                {{atForm.taskautoid}}
              </div>
            </div>
            <div class="d-flex align-items-center">
              <mat-icon *ngIf="!atForm.taskid" class="edit-icon" (click)="selectTask()">edit</mat-icon>
              <mat-icon *ngIf="atForm.taskid" class="remove-icon" (click)="removeTask()">close</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mb-3 col-md-6" *ngIf="atForm.taskid">
          <label for="taskid" class="form-label">Task Description</label>
          <div class="custom-input-form" id="taskid" name="taskid">
            {{atForm.taskdescription}}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-6">
          <button class="btn btn-custom" (click)="chooseTickets()">Tickets</button>
        </div>
      </div>
      <div *ngIf="atForm.ticketno.length > 0" class="my-1 d-flex">
        <div *ngFor="let ticket of atForm.ticketno" class="me-2 priority" (click)="openTicketDetails(ticket)">
          #{{ticket}}
        </div>
      </div>
    </div>

  </div>
</div>