import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-voting-round-summary',
  templateUrl: './voting-round-summary.component.html',
  styleUrls: ['./voting-round-summary.component.scss'],
})
export class VotingRoundSummaryComponent implements OnInit {
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    clearInterval(this.summaryInterval);
  }

  roundid: string = '';
  cid: string = '';
  summary: any = '';
  summaryInterval: any;

  gettingSummary: boolean = false;
  noRoundError: boolean = false;
  isError: boolean = false;
  refreshing: boolean = false;
  isAdmin: boolean = false;

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const temp_cid = this.route.snapshot.paramMap.get('camid');
    if (temp_cid) {
      this.cid = decodeURIComponent(temp_cid);
    }

    const temp_vrid = this.route.snapshot.paramMap.get('roundid');
    if (temp_vrid) {
      this.roundid = decodeURIComponent(temp_vrid);
    } else {
      this.back();
    }
    if (this.allinoneService.getAtoken()) {
      this.isAdmin = true
    }
    this.allinoneService.navbar = false
  }

  ngOnDestroy(){
    this.allinoneService.navbar = true
    clearInterval(this.summaryInterval);
  }

  ngOnInit(): void {
    this.getRoundSummary();

    this.summaryInterval = setInterval(() => {
      this.getRoundSummaryInterval();
      console.log('getting summary');
    }, 10000);
  }

  back() {
    clearInterval(this.summaryInterval);
    const camid = encodeURIComponent(this.cid)
    this.router.navigate([`admin/campaign/${camid}`]);
  }

  goToVoter() {
    clearInterval(this.summaryInterval);
    const camid = encodeURIComponent(this.cid)
    const roundid = encodeURIComponent(this.roundid)
    this.router.navigateByUrl(
      `/admin/campaign/${camid}/${roundid}/summary/voters`
    );
  }

  getRoundSummary() {
    this.gettingSummary = true;
    const data = {
      roundid: this.roundid,
      camid: this.cid,
    };
    this.kunyekService.getVotingRoundSummary(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.summary = res.list[0];
          this.gettingSummary = false;
        } else {
          clearInterval(this.summaryInterval);
          this.isError = true;
          if (res.returncode == '240') {
            this.noRoundError = true;
          }
          this.gettingSummary = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        this.gettingSummary = false;
        clearInterval(this.summaryInterval);
      }
    );
  }

  refresh() {
    this.refreshing = true;
    clearInterval(this.summaryInterval);
    const data = {
      roundid: this.roundid,
      camid: this.cid,
    };
    this.kunyekService.getVotingRoundSummary(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.summary = res.list[0];
          this.summaryInterval = setInterval(() => {
            this.getRoundSummaryInterval();
            console.log('getting summary');
          }, 10000);
          this.refreshing = false;
        } else {
          clearInterval(this.summaryInterval);
          this.isError = true;
          if (res.returncode == '240') {
            this.noRoundError = true;
          }
          this.refreshing = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        this.refreshing = false;
        clearInterval(this.summaryInterval);
      }
    );
  }

  votersPage(){
    clearInterval(this.summaryInterval)
    const camid = encodeURIComponent(this.cid)
    const roundid = encodeURIComponent(this.roundid)
    this.router.navigateByUrl(
      `/admin/campaign/${camid}/${roundid}/summary/voters`
    );
  }

  getRoundSummaryInterval() {
    const data = {
      roundid: this.roundid,
      camid: this.cid,
    };
    this.kunyekService.getVotingRoundSummary(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.summary = res.list[0];
          this.summary.votelimit = parseInt(this.summary.votelimit)
        } else {
          clearInterval(this.summaryInterval);
          this.isError = true;
          if (res.returncode == '240') {
            this.noRoundError = true;
          }

          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.isError = true;
        clearInterval(this.summaryInterval);
      }
    );
  }

  downloadSheet() {
    var data: any = [];
    var name = this.summary.camdesc + ' (' + this.summary.rounddesc + ').xlsx';
    this.summary.candidatelist.map((candidate: any) => {
      data.push({
        Name: candidate.name,
        Result: candidate.count,
      });
    });
    this.allinoneService.exportEcecl(data, name);
  }

  reload() {
    window.location.reload();
  }
}
