<button
  [matMenuTriggerFor]="matMenu"
  class="btn dropdown-toggle btnGray"
  (click)="clearSearch()"
>
  {{ label }} : {{ name }}
</button>
<mat-menu #matMenu="matMenu">
  <div
    class="input-group cl-project-new me-3 mb-3"
    (click)="$event.stopPropagation()"
  >
    <input
      type="text"
      class="clas-project"
      placeholder="Search"
      [(ngModel)]="searchVal"
    />
  </div>
  <button class="mat-item" (click)="handleSelect('')">All</button>
  <button
    class="mat-item"
    *ngFor="let item of items | arrayFilter: searchVal : bindLabel"
    (click)="handleSelect(item)"
  >
    {{ item[bindLabel] }}
  </button>
</mat-menu>
