<div class="d-flex align-items-center justify-content-between mb-3">
    <div>
        <h3 class="mb-0">Filter</h3>
    </div>
    <div>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="row">
    <div class="mb-3 col-md-6">
        <label for="status" class="form-label">Status</label>
        <select class="form-select" [(ngModel)]="searchForm.status" id="status" name="status">
            <option *ngFor="let item of statusList" [value]="item.value">
                {{item.name}}
            </option>
        </select>
    </div>
    <div class="mb-3 col-md-6">
        <label for="urgency" class="form-label">Urgency</label>
        <select class="form-select" [(ngModel)]="searchForm.priority" id="urgency" name="urgency">
            <option *ngFor="let item of priorityList" [value]="item.id">
                {{item.name}}
            </option>
        </select>
    </div>

    <div class="mb-3 col-md-12">
        <label for="position" class="form-label">Position</label>
        <select class="form-select" [(ngModel)]="searchForm.position" id="position" name="position"
            [disabled]="getTemplateLoading">
            <option value="">All</option>
            <option *ngFor="let item of positionList" [value]="item.reqtempid">
                {{item.name}}
            </option>
        </select>
        <mat-progress-bar *ngIf="getTemplateLoading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="mb-3 col-md-6">
        <label for="position" class="form-label">Department</label>

        <select class="form-select" [(ngModel)]="searchForm.department" id="position" name="position"
            [disabled]="gettingMemberPosition">
            <option value="">All</option>
            <option *ngFor="let item of departmentList" [value]="item">
                {{item}}
            </option>
        </select>
        <mat-progress-bar *ngIf="gettingMemberPosition" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="mb-3 col-md-6">
        <label for="division" class="form-label">Division</label>
        <select class="form-select" [(ngModel)]="searchForm.division" id="division" name="division"
            [disabled]="gettingMemberPosition">
            <option value="">All</option>
            <option *ngFor="let item of divisionList" [value]="item">
                {{item}}
            </option>
        </select>
        <mat-progress-bar *ngIf="gettingMemberPosition" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">
            Requisition Start Date
        </label>
        <div class="input-group">
            <input class="form-control" type="date" id='startdate' name='startdate' [(ngModel)]="searchForm.startdate"
                required max="9999-12-31">
            <button class="btn btn-custom" type="button" id="startdate-add-on" (click)="removeDate()"><i
                    class="fa fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">
            Requisition End Date
        </label>
        <div class="input-group">
            <input class="form-control" type="date" id='enddate' name='enddate' [(ngModel)]="searchForm.enddate"
                required max="9999-12-31" [min]="searchForm.startdate">
            <button class="btn btn-custom" type="button" id="enddate-add-on" (click)="removeDate()"><i
                    class="fa fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="btn btn-outline-secondary me-2" (click)="close()">
        Cancel
    </button>
    <app-button-loader (click)="search()" [button_text]=" 'Search'" [load_button_text]=" ''"></app-button-loader>
</div>