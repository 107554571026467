<div class="cc-container">
    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="label title" *ngIf="!branchAddOrNot">Branch</div>
            <div class="label title" *ngIf="branchAddOrNot">Add Branch</div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close [disabled]="saveLoading" (click)="Close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div class="mb-2 d-flex header">
            <div>
                <button *ngIf="!branchAddOrNot" type="button" class="btn btn-custom" (click)="newbranch()">
                    <i class="fa fa-plus me-1"></i>Add
                </button>

                <div *ngIf="branchAddOrNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader (click)="submitbranch()" [button_text]="'Save'" [load_button_text]="'Saving'"
                        [isLoading]="saveLoading">
                    </app-button-loader>
                </div>
            </div>
        </div>
        <div *ngIf="!branchAddOrNot" class="None" style="padding: 1px;">
            <div *ngIf="savedBranch.length == 0" class="nodata d-flex align-items-center justify-content-center">
                Empty
            </div>
            <div class="d-flex flex-column products-card p-1 my-2" *ngFor="let branch of savedBranch; let i = index;">
                <div class="d-flex flex-column" (click)="editbranch(branch,i)">
                    <div class="d-flex justify-content-between">
                        <div class="p-1 product-name ">
                            {{branch.branchname}}<span style="font-size: 11px;margin-left:9px"><i class="fa fa-pencil"
                                    aria-hidden="true"></i></span>
                        </div>
                        <div (click)="delete(i)">
                            <i class="fas fa-times edit-icon"></i>
                        </div>
                    </div>
                    <div class="d-flex p-1 justify-content-between">
                        <div class="d-flex">
                            {{branch.branchemail != '' ? branch.branchemail : branch.branchmobile != '' ?
                            branch.branchmobile : '' }}
                            <span *ngIf="branch.emailaccess == 'true'" style="font-size: 22px;margin-top: -5px;font-size: 22px;
                            margin-left: 3px; color: grey;">.</span><i *ngIf="branch.emailaccess == 'true'"
                                class="fa fa-envelope-o" aria-hidden="true" style="font-size: 11px;
    margin-top: 7px;
    color: gray;
    margin-left: 4px;"></i>

                        </div>
                        <div class="p-1 product-name ">
                            {{branch.branchpost}}

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="branchAddOrNot">


            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="name" class="form-label">Name</label>

                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="branchForm.branchname"
                        required #name="ngModel" [ngClass]="{ 'is-invalid': branchFormSubmitted && name.invalid }">
                    <div class="invalid-feedback-length" *ngIf="branchForm.branchname.length > 255">
                        {{allinoneService.lengthCheck("Name",255)}}
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="name.invalid || (name.dirty || name.touched) && branchFormSubmitted">
                        <div *ngIf="name.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="phone" class="form-label">Phone</label>
                    <input type="text" class="form-control" id="phone" name="phone"
                        [(ngModel)]="branchForm.branchmobile">
                    <div class="invalid-feedback-length" *ngIf="branchForm.branchmobile.length > 255">
                        {{allinoneService.lengthCheck("Phone",255)}}
                    </div>

                </div>

            </div>

            <div class="row">

                <div class="mb-3 col-md-6">
                    <label for="email" class="form-label">Email</label>

                    <input type="text" class="form-control" id="email" name="email"
                        [(ngModel)]="branchForm.branchemail">
                    <div class="invalid-feedback-length" *ngIf="branchForm.branchemail.length > 255">
                        {{allinoneService.lengthCheck("Email",255)}}
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="email" class="form-label">Industry Type</label>

                    <input type="text" class="form-control" id="email" name="email" [(ngModel)]="branchForm.type">
                    <div *ngIf="branchForm.type != ''">

                        <div class="invalid-feedback-length" *ngIf="branchForm.type.length > 255">
                            {{allinoneService.lengthCheck("Industry Type",255)}}
                        </div>
                    </div>

                    </div>

                </div>

                <div class="row">
                    <div class="mb-3  col-md-12">
                        <label for="address1" class="form-label">Address</label>
                        <input type="text" class="form-control" id="address1" name="address1"
                            [(ngModel)]="branchForm.branchaddress1">
                    </div>
                    <div class="mb-3 col-md-12">
                        <input type="text" class="form-control" id="address2" name="address2"
                            [(ngModel)]="branchForm.branchaddress2">
                    </div>
                    <div class="invalid-feedback-length"
                        *ngIf="branchForm.branchaddress1.length > 255 || branchForm.branchaddress2.length > 255">
                        {{allinoneService.lengthCheck("Address",255)}}
                    </div>
                </div>
            </div>
    </perfect-scrollbar>
</div>