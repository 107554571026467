import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getChatTime'
})
export class GetChatTimePipe implements PipeTransform {

  transform(date : any){
    var year, month, day, hour, min, amorpm;
    year = date.substring(0, 4);
    month = date.substring(4, 6);
    day = date.substring(6, 8);
    hour = date.substring(8, 10);
    min = date.substring(10, 12);
    var msgDate = new Date(year, Number(month) - 1, day);
    var date2 = new Date(Date.now())
    var Time = date2.getTime() - msgDate.getTime();
    var totDays = Math.floor(Time / (1000 * 3600 * 24));
    if (totDays == 0) {
      hour = Number(hour)
      if (hour > 12) {
        hour = hour - 12;
        if (hour == 0) {
          amorpm = "AM";
        } else {
          amorpm = "PM";
        }
      } else {
        if (hour == 12) {
          amorpm = "PM";
        } else {
          amorpm = "AM";
        }
      }
      return hour.toString() + ':' + min + ' ' + amorpm;
    } else if (totDays == 1) {
      return "Yesterday";
    } else {
      return month + "-" + day;
    }
  }

}
