<div>
  <app-cs365-breadcrumb
    *ngIf="newpath"
    [s_breadcrumb]="'Service Tickets'"
    [t_breadcrumb]="'History (#' + ticketno + ')'"
    (emit)="cancelFunction()"
  >
  </app-cs365-breadcrumb>

  <app-crm-breadcrumb
    *ngIf="!newpath"
    [s_breadcrumb]="'Service Tickets'"
    [t_breadcrumb]="'History (#' + ticketno + ')'"
    (emit)="cancelFunction()"
  >
  </app-crm-breadcrumb>

  <div>
    <div class="d-flex flex-wrap mb-3">
      <!-- <label for="enddate" class="form-label">Date
                    </label> -->
      <div class="all me-2">
        <input
          type="date"
          class="form-control"
          id="enddate"
          name="enddate"
          [(ngModel)]="enddatetime"
          step="1"
          style="border-color: #87929d; min-width: 100px"
          required
          #enddate="ngModel"
          step="1"
          (ngModelChange)="searchDate()"
        />
      </div>
      <!-- <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddatetime"  > -->

      <div class="styled-select">
        <!-- <select class="form-select" *ngIf="!customercare" aria-label="Select type" id="type" name="type" [(ngModel)]="historytype" (change)="typeChange($event)">

                            <option class="service-small" selected value=""> ( Type ) </option>
                            <option class="service-small" *ngFor="let type of typeofhistory" value="{{type.value}}">
                                {{type.name}}</option>
                        </select> -->
        <div
          *ngIf="!customercare"
          class="me-2"
          [matMenuTriggerFor]="bytypemenu"
        >
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            aria-expanded="false"
            mdbDropdownToggle
            style="
              background-color: #87929d;
              border-color: #87929d;
              color: white;
              min-width: 100px;
            "
          >
            {{ typename }}
          </button>
        </div>
        <mat-menu #bytypemenu="matMenu" xPosition="before" class="menuproject">
          <button class="mat-item" (click)="bytype('none')">Type</button>
          <button
            class="mat-item"
            *ngFor="let type of typeofhistory"
            (click)="bytype(type)"
          >
            {{ type.name }}
          </button>
        </mat-menu>
        <div
          *ngIf="customercare"
          class="me-2"
          [matMenuTriggerFor]="bytypemenu1"
        >
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            aria-expanded="false"
            mdbDropdownToggle
            style="
              background-color: #87929d;
              border-color: #87929d;
              color: white;
              min-width: 100px;
            "
          >
            {{ typename }}
          </button>
        </div>
        <mat-menu #bytypemenu1="matMenu" xPosition="before" class="menuproject">
          <button class="mat-item" (click)="bytype('none')">( Type )</button>
          <button
            class="mat-item"
            *ngFor="let type of typeofhistoryCustomer"
            (click)="bytype(type)"
          >
            {{ type.name }}
          </button>
        </mat-menu>
        <!-- <select class="form-select" *ngIf="customercare" aria-label="Select type" id="type" name="type"
                        [(ngModel)]="historytype" (change)="typeChange($event)">
                        <option class="service-small" selected value=""> ( Type ) </option>
                        <option class="service-small" *ngFor="let type of typeofhistoryCustomer" value="{{type.value}}">
                            {{type.name}}</option>
                    </select> -->
      </div>

      <div class="dropdown ms-2">
        <button
          type="button"
          class="btn btn-custom"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          title="Page Size"
        >
          <mat-icon>filter_list</mat-icon>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <div c style="text-align: center">Page size</div>
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li class="dropdown-item" *ngFor="let page of pg">
            <input
              type="radio"
              name="pg-count"
              value="{{ page.count }}"
              (change)="changePageCount($event)"
              id="pg{{ page.name }}"
              class="p-1"
              [checked]="page.count == itemPerPage"
            />
            <label for="pg{{ page.name }}" class="col-10 p-1">
              {{ page.name }}
            </label>
          </li>
        </ul>
      </div>
      <div class="ms-2">
        <button class="btn btn-custom" (click)="refresh()" title="Refresh">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="ms-2">
        <button
          type="button"
          class="btn btn-custom"
          (click)="downloadSheet()"
          title="Export Members To Excel"
        >
          <img class="excel-icon" src="../../../assets/images/excel.png" />
          Export
        </button>
      </div>
      <div class="line"></div>
      <div class="d-flex align-items-center me-auto">
        <span class="total-count-text">Total:&nbsp;</span>
        <span class="total-count-num">{{ total }}</span>
      </div>
      <div *ngIf="!gettingData">
        <pagination-template
          *ngIf="data.length > 0"
          #p="paginationApi"
          (pageChange)="pageChange($event)"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div
              [class]="
                p.isFirstPage()
                  ? 'custom-pagination-disabled'
                  : 'custom-pagination'
              "
              (click)="p.previous()"
            >
              <span class="me-1">&laquo;</span>&nbsp;Prev
            </div>
            <div
              *ngFor="let page of p.pages"
              [class.current]="p.getCurrent() === page.value"
            >
              <span
                class="page"
                (click)="p.setCurrent(page.value)"
                *ngIf="p.getCurrent() !== page.value"
              >
                <span>{{ page.label }}</span>
              </span>
              <div class="active-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>
            <div
              [class]="
                nextDisabled
                  ? 'custom-pagination-disabled'
                  : 'custom-pagination'
              "
              (click)="p.next()"
            >
              Next&nbsp;<span class="ms-1">&raquo;</span>
            </div>
          </div>
        </pagination-template>
      </div>
    </div>

    <!-- <div class="row d-flex flex-wrap justify-content-start align-items-center">

            <div class="mb-3 " [ngClass]="{'row row-custom col-lg-4  col-md-12 col-sm-12':  !isMobileView}" style="flex: 0 0 auto;">
                <label for="enddate" class="form-label">Date
                </label>
                <input type="date" class="form-control" id="enddate" name="enddate" [(ngModel)]="enddatetime" required #enddate="ngModel" step="1" (ngModelChange)="searchDate()">
            </div>

        </div> -->

    <!-- <div class="mb-2 d-flex flex-wrap justify-content-start align-items-center">
            <div class=" d-flex flex-wrap me-auto">
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView}">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <div c style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1" [checked]="page.count==itemPerPage">
                            <label for="pg{{page.name}}" class="col-10 p-1">
                                {{page.name}}
                            </label>
                        </li>
                    </ul>
                </div>
                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div class=" ms-2">
                    <button type="button" class="btn btn-custom" (click)="downloadSheet()" title="Export Members To Excel">
                            <img class="excel-icon" src="../../../assets/images/excel.png">
                            Export
                        </button>
                </div>
                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span> <span class="total-count-num">{{total}}</span>
                </div>
            </div>

        </div> -->
    <!-- <div class="input-group mb-2" *ngIf="isMobileView">
            <input class="form-control" type="text" placeholder="&#xf002; Search " aria-label="Search"
                (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchTextService"
                [ngClass]="{'c-search-input-with-clear' : searchTextService }" style="font-family: FontAwesome,Ubuntu;">
            <button class="btn btn-outline-secondary " type="button" *ngIf="searchTextService" (click)="clear()"
                [ngClass]="{'c-clear' : isFocus, 'c-clear-not-focus': !isFocus}">
                <mat-icon class="c-icon">close</mat-icon>
            </button>
        </div> -->

    <!-- <div class="d-flex mb-2">

            <div class="d-flex me-auto">

                <label class="me-auto" for="" style="font-size: 15px;
            padding-bottom: 16px;"></label>
            </div>


            <div *ngIf="!gettingData">

                <pagination-template *ngIf="data.length > 0" #p="paginationApi" (pageChange)="pageChange($event)">
                    <div class="d-flex align-items-center justify-content-center">
                        <div [class]="p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.previous()">
                            <span class="me-1">&laquo;</span>&nbsp;Prev
                        </div>
                        <div *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                            <span class="page" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </span>
                            <div class="active-page" *ngIf="p.getCurrent() === page.value">
                                <span>{{ page.label }}</span>
                            </div>
                        </div>
                        <div [class]="nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'"
                            (click)="p.next()">
                            Next&nbsp;<span class="ms-1">&raquo;</span>
                        </div>
                    </div>
                </pagination-template>
            </div>
        </div> -->

    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
      <table class="table table-responsive table-borderless">
        <thead class="table-header">
          <tr>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="reportedby"
            >
              Reported by
            </td>
            <td
              scope="col"
              class="col-lg-2 col-md-2"
              mat-sort-header="startdate"
            >
              Date
            </td>
            <!-- <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="enddate">End Date
                        </td> -->

            <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="type">
              Type
            </td>
            <td scope="col" class="col-lg-1 col-md-1" mat-sort-header="version">
              Version
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="from">
              From
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="to">
              To
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="age">
              Age
            </td>
            <td scope="col" class="col-lg-2 col-md-2" mat-sort-header="remark">
              Remark
            </td>
          </tr>
        </thead>
        <tbody *ngIf="gettingData">
          <tr>
            <td colspan="8" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <!--  -->
        <tbody *ngIf="gettingData == false">
          <tr *ngIf="data.length == 0">
            <td colspan="8" class="nodata">Empty</td>
          </tr>
          <!--  -->
          <!--  -->
          <tr
            *ngFor="
              let item of data
                | crmFilter: searchTextService : historytype : serviceFilter : 2
                | paginate
                  : { itemsPerPage: itemPerPage, currentPage: currentPage };
              let index = index
            "
          >
            <td class="td-data">
              <span>{{ item.reportedbyname }}</span>
            </td>
            <td class="td-data">
              <span>{{ item.datetime }}</span>
            </td>
            <!-- <td class="td-data"><span>{{item.enddatetime}}</span></td> -->
            <td class="td-data">
              <span>{{ item.type }}</span>
            </td>
            <td class="td-data">
              <span>{{ item.version }}</span>
            </td>
            <td class="td-data">
              <span>{{ item.from }}</span>
            </td>
            <td class="td-data">
              <span>{{ item.to }}</span>
            </td>
            <td class="td-data">
              <span>{{ item.age }}</span>
            </td>

            <td class="td-data">
              <span>{{ item.remark }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div *ngIf="!gettingData">
    <pagination-template
      *ngIf="data.length > 0"
      #p="paginationApi"
      (pageChange)="pageChange($event)"
    >
      <div class="d-flex align-items-center justify-content-center">
        <div
          [class]="
            p.isFirstPage() ? 'custom-pagination-disabled' : 'custom-pagination'
          "
          (click)="p.previous()"
        >
          <span class="me-1">&laquo;</span>&nbsp;Prev
        </div>
        <div
          *ngFor="let page of p.pages"
          [class.current]="p.getCurrent() === page.value"
        >
          <span
            class="page"
            (click)="p.setCurrent(page.value)"
            *ngIf="p.getCurrent() !== page.value"
          >
            <span>{{ page.label }}</span>
          </span>
          <div class="active-page" *ngIf="p.getCurrent() === page.value">
            <span>{{ page.label }}</span>
          </div>
        </div>
        <div
          [class]="
            nextDisabled ? 'custom-pagination-disabled' : 'custom-pagination'
          "
          (click)="p.next()"
        >
          Next&nbsp;<span class="ms-1">&raquo;</span>
        </div>
      </div>
    </pagination-template>
  </div>

  <!-- </pagination-template> -->
  <!-- <pagination-controls class="paginate" (pageChange)="p = $event" *ngIf="data.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
    </pagination-controls> -->
</div>
