<section [ngClass]="{'loading-h' : gettingVR}">
    <ng-container *ngIf="!isError;else errorPage">
        <div class="c-container m-5" *ngIf="!gettingVR">
            <div class="fix-header">
                <div class="campaign-name mb-3">{{camdesc}}</div>
                <div class="campaign-d mb-3" *ngIf="roundData.rounddesc">{{roundData.rounddesc}}</div>
                <div class="campaign-d mb-3" *ngIf="roundData.remark">{{roundData.remark}}</div>
                <div *ngIf="!isProxy" class="campaign-d mb-3">{{username}}</div>
                <div *ngIf="isProxy" class="container d-flex justify-content-center mb-3 proxymenu">
                    <div mat-raised-button [matMenuTriggerFor]="colors_menu" class="" type="button" id="dropdownMenu2" aria-expanded="false">
                        {{selectedProxy.proxyname}}
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>

                    <mat-menu #colors_menu="matMenu">
                        <button mat-menu-item *ngFor="let proxy of proxyList" (click)="changeVal($event,proxy)">
                          {{proxy.proxyname}}
                        </button>
                    </mat-menu>
                </div>
                <div class="w-100 d-flex justify-content-between align-items-center flex-wrap">
                    <div class="mb-3">
                        <span class="title">{{votelimit}} out of {{candidateList.length}} Candidates</span>
                    </div>
                    <div class="mb-3">
                        <button type="button" (click)="back()" class="btn btn-outline-secondary me-2 cancel-btn">
                            Cancel
                        </button>
                        <button class="btn vote-btn" [disabled]="currentVote < votelimit|| savingVotes || isFinish" (click)="confirmDialog()">
                            <span *ngIf="!savingVotes">
                                Confirm
                            </span>
                            <span *ngIf="savingVotes" class="d-flex justify-content-center align-items-center">
                                <span class="spinner-border spinner-border-sm button-spinner" role="status"
                                    aria-hidden="true"></span>
                                <span class="ms-1">Saving</span>
                            </span>
                        </button>
                    </div>
                </div>

            </div>
            <div class="voting-container">
                <div *ngFor="let candidate of candidateList; let index = index">
                    <div class="vote shadow-sm" (click)="vote(index)">
                        <mat-checkbox class="w-100" labelPosition="before" [(ngModel)]="candidate.checked">
                            {{candidate.name}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="mb-2 d-flex flex-wrap justify-content-center align-items-center">
                <div class="mb-3 inside-btn">
                    <button type="button" class="btn btn-custom mini-previous-btn" (click)="previousRound()" title="Previous" [disabled]="curRound == 0">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <button type="button" class="btn btn-custom mini-next-btn" (click)="nextRound()" title="Next" [disabled]="curRound == roundList.length - 1">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                    <!-- <button type="button" (click)="previousRound()" class="btn btn-outline-secondary me-2 cancel-btn">
                   << Previous
                </button>
                <button type="button" (click)="nextRound()" class="btn btn-outline-secondary me-2 cancel-btn">
                    Next >>
                </button> -->
                </div>
            </div>
        </div>
        <div *ngIf="gettingVR">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <!-- <button type="button" (click)="previousRound()" class="btn btn-outline-secondary me-2 previous-btn">
            << Previous
         </button> -->
        <!-- <button type="button" (click)="nextRound()" class="btn btn-outline-secondary me-2 next-btn">
             Next >>
         </button> -->
        <div class="outside-btn">
            <button class="btn btn-custom previous-btn" (click)="previousRound()" title="Previous" [disabled]="curRound == 0">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button class="btn btn-custom next-btn" (click)="nextRound()" title="Next" [disabled]="curRound == roundList.length - 1">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </div>

    </ng-container>
    <ng-template #errorPage>
        <div class="c-container m-5 error" *ngIf="!noRoundError">
            <span class="mb-3">Something went wrong!</span>
            <button class="btn btn-secondary" (click)="reload()">Reload</button>
        </div>
        <div class="c-container m-5 error" *ngIf="noRoundError">
            <span class="noround">This round of voting does not exist.</span>
        </div>
    </ng-template>
</section>