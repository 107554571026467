<div class="container-fluid">
  <div class="content">
    <!-- <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb *ngIf="!isAdding" s_breadcrumb="Work Policy Configuration">
      </app-team-breadcrumb>
      <app-hcm-breadcrumb *ngIf="isAdding" s_breadcrumb="Work Policy Configuration"
        [t_breadcrumb]="isEditing ? 'Edit' : 'New' " (emit)="cancel()"></app-hcm-breadcrumb>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb *ngIf="!isAdding" [s_breadcrumb]="'Work Policy Configuration'"></app-office-breadcrumb>
      <app-office-breadcrumb *ngIf="isAdding" [s_breadcrumb]="'Work Policy Configuration'"
        [t_breadcrumb]="isEditing ? 'Edit' : 'New'" (emit)="cancel()"></app-office-breadcrumb>
    </ng-container> -->
    <ng-container *ngIf="allinoneService.isAttendanceView">
      <app-hcm-breadcrumb *ngIf="!isAdding" s_breadcrumb="Work Policy Configuration">
      </app-hcm-breadcrumb>
      <app-hcm-breadcrumb *ngIf="isAdding" s_breadcrumb="Work Policy Configuration"
        [t_breadcrumb]="isEditing ? 'Edit' : 'New' " (emit)="cancel()"></app-hcm-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!allinoneService.isAttendanceView">
      <app-team-breadcrumb *ngIf="!isAdding" s_breadcrumb="Work Policy Configuration">
      </app-team-breadcrumb>
      <app-team-breadcrumb *ngIf="isAdding" s_breadcrumb="Work Policy Configuration"
        [t_breadcrumb]="isEditing ? 'Edit' : 'New' " (emit)="cancel()"></app-team-breadcrumb>
    </ng-container>
    
    <ng-container *ngIf="isAdding; else listView">
      <div class="d-flex align-items-center">
        <button type="button" class="btn btn-custom me-2 mb-3" (click)="cancel()">
          Cancel
        </button>
        <app-button-loader class="mb-3" (click)="submitForm()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="saveLoading">
        </app-button-loader>
      </div>
      <div style="max-width: max-content;">
        <div class="card card-body my-card mb-3 w-100">
          <div class="d-flex flex-wrap align-items-start">
            <div class="mb-3 flex-fill">
              <label for="name" class="form-label">Name</label>
              <input type="text" class="form-control" id="name" [(ngModel)]="wpConForm.name" name="name" #name="ngModel"
                [ngClass]="{ 'is-invalid': formSubmitted && name.invalid }" required />
              <div class="invalid-feedback" *ngIf="
                        name.invalid ||
                        ((name.dirty || name.touched) && formSubmitted)
                      ">
                <div *ngIf="name.errors?.required">
                  {{ allinoneService.input_err_msg }}
                </div>
              </div>
            </div>
            <div class="mb-3 flex-fill">
              <label for="description" class="form-label">Description</label>
              <input class="form-control" id="description" [(ngModel)]="wpConForm.description" name="description"
                type="text" />
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="default" name="default"
              [(ngModel)]="wpConForm.default" />
            <label class="form-check-label" for="default"> Default </label>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap" style="column-gap: 1rem;">
          <div class="card card-body my-card mb-3" style="max-width: max-content;">
            <div class="info mb-3 me-5">Late</div>

            <div class="mb-3">
              <label for="latetime" class="form-label">Late
                Time (Mins)</label>
              <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="latetime" name="latetime"
                [(ngModel)]="wpConForm.latetime" #latetime="ngModel"
                [ngClass]="{'is-invalid' : formSubmitted && wpConForm.uselate && latetime.invalid }" required min="0">
              <div class="invalid-feedback" *ngIf="
                          latetime.invalid ||
                          ((latetime.dirty || latetime.touched) && formSubmitted)
                        ">
                <div *ngIf="latetime.errors?.required && wpConForm.uselate">
                  {{ allinoneService.input_err_msg }}
                </div>
              </div>
            </div>
            <div class="">
              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" id="late" [(ngModel)]="wpConForm.uselate" name="late">
                <label class="form-check-label ms-1" for="late"> Calculate Late Time
                </label>
              </div>
            </div>
          </div>
          <div class="card card-body my-card mb-3" style="max-width: max-content;">
            <div class="info mb-3 me-5">Early Out</div>

            <div class="mb-3">
              <label for="earlyouttime" class="form-label">Early
                Out (Mins)</label>
              <input type="number" (keypress)="$event.charCode != 45" class="form-control" id="earlyouttime"
                name="earlyouttime" [(ngModel)]="wpConForm.earlyouttime" #earlyout='ngModel'
                [ngClass]="{'is-invalid' : formSubmitted && wpConForm.useearlyout && earlyout.invalid }" min="0"
                required>
              <div class="invalid-feedback" *ngIf="
                          earlyout.invalid ||
                          ((earlyout.dirty || earlyout.touched) && formSubmitted)
                        ">
                <div *ngIf="earlyout.errors?.required && wpConForm.useearlyout">
                  {{ allinoneService.input_err_msg }}
                </div>
              </div>
            </div>
            <div class="">
              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" id="earlyout" [(ngModel)]="wpConForm.useearlyout"
                  name="earlyout">
                <label class="form-check-label ms-1" for="earlyout"> Calculate Early Out Time
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
    <ng-template #listView>
      <div class="d-flex align-items-center mb-3">
        <button class="btn btn-custom me-auto" (click)="newWpConfig()">
          <i class="fa fa-plus me-1"></i> New
        </button>
      </div>
      <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
        <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description"
            aria-label="Search by Name/Description" (focus)="isFocus = true" (blur)="isFocus = false"
            (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
          <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false" title="Page Size">
            <mat-icon>filter_list</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
            <li>
              <div style="text-align: center;">Page size</div>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li class="dropdown-item" *ngFor="let page of pg">
              <input type="radio" name="pg-count" value="{{page.count}}" (change)="changePageCount($event)"
                id="pg{{page.name}}" class="p-1" [checked]="page.count==100">
              <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
            </li>
          </ul>
        </div>
        <div class="ms-2">
          <button class="btn btn-custom" (click)="refresh()" title="Refresh">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
        <div class="line"></div>
        <div class="d-flex align-items-center">
          <span class="total-count-text">Total:&nbsp;</span>
          <span class="total-count-num">{{ ( wpConList | filter : searchText: 'lateandearlyout' ).length }}</span>
        </div>

        <div class="input-group mt-2" *ngIf="isMobileView">
          <input type="text" class="form-control" placeholder="&#xf002; Search by Name/Description" aria-label="Search"
            (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
            (ngModelChange)="searchTextChange()">
          <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
            <mat-icon class="c-icon">close</mat-icon>
          </button>
        </div>
        <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
          *ngIf="wpConList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
      </div>
      <div class="table-responsive" matSort (matSortChange)="sortData($event)">
        <table class="table table-responsive table-borderless">
          <thead class="table-header">
            <tr>
              <td scope="col" class="col-md-4" mat-sort-header="name">Name</td>
              <td scope="col" class="col-md-4" mat-sort-header="description">Description</td>
              <td scope="col" class="col-md-4" mat-sort-header="default">Default Work Policy</td>
              <td scope="col" class="d-flex justify-content-center">Action</td>
            </tr>
          </thead>
          <tbody *ngIf="wpConLoading">
            <tr>
              <td colspan="3" class="nodata">
                <div class="spinner-border" entitle="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="!wpConLoading">
            <tr *ngIf="wpConList.length == 0">
              <td colspan="3" class="nodata">Empty</td>
            </tr>
            <tr
              *ngFor="let wp of wpConList | filter : searchText: 'lateandearlyout' | paginate: { itemsPerPage: itemPerPage , currentPage : currentPage } ; let index = index">
              <td class="td-data td-edit" (click)="editWpCon(wp)">
                <span [title]="wp.name">{{ wp.name }}</span>
              </td>
              <td class="td-data">
                <span [title]="wp.description">
                  {{wp.description}}
                </span>
              </td>
              <td class="td-data">
                <span>
                  {{wp.default ? "Yes" : "No"}}
                </span>
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <button class="btn btn-outline-primary me-2" (click)="editWpCon(wp)">
                  <i class="fa fa-edit"></i>
                </button>
                <button class="btn btn-outline-danger" (click)="deleteWpCon(wp.wpid)">
                  <i class="fa fa-trash-o"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls class="paginate" (pageChange)="currentPage = $event" *ngIf="wpConList.length > itemPerPage"
        previousLabel="Prev" nextLabel="Next">
      </pagination-controls>
    </ng-template>
  </div>
</div>