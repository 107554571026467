<div class="container-fluid" *ngIf="!isEditting">
  <div class="content">
    <app-cs365-breadcrumb s_breadcrumb="Warranty"></app-cs365-breadcrumb>

    <div class="mt-4 mb-4">
      <button class="btn btn-custom" (click)="handleNewWarranty()">
        <i class="fa fa-plus me-1"></i> Warranty
      </button>
    </div>

    <div class="table-responsive">
      <table class="table table-responsive table-borderless">
        <thead class="table-header">
          <tr scope="row">
            <td scope="col">Code</td>
            <td scope="col">Month</td>
            <td scope="col">Remark</td>
          </tr>
        </thead>
        <tbody *ngIf="isLoading">
          <tr>
            <td colspan="4" class="nodata">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!isLoading">
          <ng-container *ngFor="let warranty of warranties">
            <tr style="cursor: pointer" (click)="handleEditData(warranty)">
              <td class="py-2">{{ warranty.warrantycode }}</td>

              <td class="py-2">{{ warranty.month }}</td>
              <td class="py-2">{{ warranty.remark }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div *ngIf="isEditting">
  <app-warranty-form
    (closeForm)="closeEdit()"
    [editData]="editData"
  ></app-warranty-form>
</div>
