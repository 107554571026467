import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit {
  isloading = false;
  currentIndex = 0;
  total = 0;
  question: any = null;
  answer: any = [];
  answers: any = [];
  previewurl = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.computePage();
  }

  computePage() {
    if (!this.data) return;
    this.isloading = true;
    console.log('data', this.data);
    this.initAnswer();
    console.log('this ans', this.answers);
    this.total = this.data.questions.length;
    this.question = this.data.questions[this.currentIndex];
    this.answer = this.answers.find(
      (x: any) => x.questionid === this.question.questionid,
    );
    this.isloading = false;
  }

  initAnswer() {
    if (this.data.answers.length === 0) {
      this.answers = this.data.questions.map((question: any) => {
        return {
          questionid: question.questionid,
          description: '',
          file: [],
        };
      });
      return;
    }
    this.answers = this.data.answers;
  }

  onNext() {
    this.currentIndex++;
    this.question = this.data.questions[this.currentIndex];
    this.answer = this.answers.find(
      (x: any) => x.questionid === this.question.questionid,
    );
    if (this.answer == undefined) {
      this.answer = {
        questionid: this.question.questionid,
        description: '',
        file: [],
      };
    }
    console.log('next', this.answer, this.answers);
  }

  onPrevious() {
    this.currentIndex--;
    this.question = this.data.questions[this.currentIndex];
    this.answer = this.answers.find(
      (x: any) => x.questionid === this.question.questionid,
    );
    if (this.answer == undefined) {
      this.answer = {
        questionid: this.question.questionid,
        description: '',
        file: [],
      };
    }
    console.log('previous', this.answer, this.answers);
  }

  viewImage(fileurl: string) {
    this.previewurl = fileurl;
  }

  onFileSelect(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const date = Date.now();
        const fileName = date + files[i].name;
        const reader = new FileReader();
        reader.onload = () => {
          this.answer.file.push({
            isUpload: false,
            file: files[i],
            fileurl: reader.result,
            filename: fileName,
          });
          const index = this.answers.findIndex(
            (x: any) => x.questionid == this.question.questionid,
          );
          this.answers[index] = this.answer;
        };
        reader.readAsDataURL(files[i]);
      }
    }
    console.log(this.answers);
  }

  handleRemove(filename: string) {
    this.answer.file = this.answer.file.filter(
      (file: any) => file.filename !== filename,
    );
    this.answer = { ...this.answer, deletefilename: filename };
    const index = this.answers.findIndex(
      (x: any) => x.questionid == this.question.questionid,
    );
    this.answers[index] = this.answer;
  }
  downloadImage(filename: string) {
    window.open(filename, '_blank');
  }
}
