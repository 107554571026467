import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-crm-lead-convert',
  templateUrl: './crm-lead-convert.component.html',
  styleUrls: ['./crm-lead-convert.component.css'],
})
export class CrmLeadConvertComponent implements OnInit {
  businessTypeList: any[] = []; 

  saveLoading: boolean = false;
  getCustomer: boolean = false;
  checklead: boolean = false;
  customernameList: any = [];

  convertForm = {
    domain: '',
    domainid: '',
    orgid: '',
    leadid: '',
    leadtype: '',
    organization: '',
    name: '',
    phone: '',
    email: '',
    post: '',
    amount: '',
    campany: '',
    customerid: '',
  };

  constructor(
    private allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CrmLeadConvertComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.businessTypeList = JSON.parse(JSON.stringify(data.businessType))
    this.convertForm = {
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      orgid: data.orgid,
      leadid: data.leadid,
      leadtype: data.leadtype,
      organization: data.org,
      name: data.name,
      email: data.email,
      post: data.post,
      phone: data.phone,
      amount: data.amount,
      campany: data.campany,
      customerid: "",
    };
  }

  ngOnInit(): void {
    this.convertForm = JSON.parse(JSON.stringify(this.convertForm))
    console.log(this.convertForm)
    console.log("Form");
    this.getCustomerName();
  }

  getCustomerName() {
    this.customernameList = [];
    this.getCustomer = true;
    const data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.convertForm.orgid,
      "searchcustomername": this.convertForm.leadtype == '001' ? this.convertForm.campany : this.convertForm.name,
    }
    this.kunyekService.getCustomerName(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.productListCustomer = res.list;
          for (var i = 0; i < res.list.length; i++) {
            this.customernameList.push({
              "customername": res.list[i]["customername"] + "(" + res.list[i]["customerid"] + ")",
              "customerid": res.list[i]["customerid"],
            });
          }
          console.log(this.customernameList);
          this.getCustomer = false;
        } else {
          this.getCustomer = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.getCustomer = false;
      }
    );
  }

  selectCustomer(data: any) {
    if (data != undefined) {
      this.convertForm.customerid = data.customerid;
      this.checklead = false;
    } else {
      this.convertForm.customerid = "";
      this.checklead = true;
    }
  }

  onConfirm(): void {

    if (this.checklead == false && this.convertForm.customerid == "" && this.customernameList.length > 0) {
      this.messageService.openSnackBar('If you want to create a new customer account, you need to tick "New customer account"', 'warn', 5000);
      return;
    } else {


      this.dialogRef.disableClose = true;
      this.saveLoading = true;
      this.kunyekService.convertLead(this.convertForm).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.dialogRef.close(true);
            this.messageService.openSnackBar(
              'Converted successfully.',
              'success'
            );
          } else {
            this.dialogRef.disableClose = false;
            this.saveLoading = false;
            this.messageService.openSnackBar(res.status, 'success');
          }
        },
        (err) => {
          this.dialogRef.disableClose = false;
          this.saveLoading = false;
        }
      );
    }

  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  checkLead(data: any) {
    if (data.target.checked) {
      this.checklead = true;

    } else {
      this.checklead = false;
    }
  }
}
