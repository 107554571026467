<div class="cc-container">
    <perfect-scrollbar class="event-container" [config]="config" [scrollIndicators]="true" fxFlex="auto" #perfectScroll>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="label title" *ngIf="!contactAddOrNot">Contact</div>
            <div class="label title" *ngIf="contactAddOrNot">Add Contact</div>
            <div class="close-btn">
                <button mat-icon-button mat-dialog-close [disabled]="saveLoading" (click)="Close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div class="mb-2 d-flex header">
            <div>
                <button *ngIf="!contactAddOrNot" type="button" class="btn btn-custom" (click)="newcontact()">
                    <i class="fa fa-plus me-1"></i>Add
                </button>

                <div *ngIf="contactAddOrNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader (click)="submitContact()" [button_text]="'Save'" [load_button_text]="'Saving'"
                        [isLoading]="saveLoading">
                    </app-button-loader>
                </div>
            </div>
        </div>
        <div *ngIf="!contactAddOrNot" class="None" style="padding: 1px;">
            <div *ngIf="savedContact.length == 0" class="nodata d-flex align-items-center justify-content-center">
                Empty
            </div>
            <div class="d-flex flex-column products-card p-1 my-2" *ngFor="let contact of savedContact; let i = index;">
                <div class="d-flex flex-column" (click)="editContact(contact,i)">
                    <div class="d-flex justify-content-between">
                        <div class="p-1 product-name ">
                            {{contact.contactname}}<span style="font-size: 11px;margin-left:9px"><i class="fa fa-pencil"
                                    aria-hidden="true"></i></span>
                        </div>
                        <div (click)="delete(i)">
                            <i class="fas fa-times edit-icon"></i>
                        </div>
                    </div>
                    <div class="d-flex p-1 justify-content-between">
                        <div class="d-flex">
                            <!-- this.data[i].status == '001' ? 'Open' : this.data[i].status == '002' ? 'Closed - Won' : this.data[i].status == '003' ? 'Closed - Lost' : '', -->
                            {{contact.contactemail != '' ? contact.contactemail : contact.contactmobile != '' ?
                            contact.contactmobile : '' }}
                            <span *ngIf="contact.emailaccess == 'true'" style="font-size: 22px;margin-top: -5px;font-size: 22px;
                            margin-left: 3px; color: grey;">.</span><i *ngIf="contact.emailaccess == 'true'"
                                class="fa fa-envelope-o" aria-hidden="true" style="font-size: 11px;
    margin-top: 7px;
    color: gray;
    margin-left: 4px;"></i>

                        </div>
                        <div class="p-1 product-name ">
                            {{contact.contactpost}}

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div *ngIf="contactAddOrNot">


            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="name" class="form-label">Name</label>

                    <input type="text" class="form-control" id="name" name="name" [(ngModel)]="cForm.contactname"
                        required #name="ngModel" [ngClass]="{ 'is-invalid': cFormSubmitted && name.invalid }">
                    <div class="invalid-feedback-length" *ngIf="cForm.contactname.length > 255">
                        {{allinoneService.lengthCheck("Name",255)}}
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="name.invalid || (name.dirty || name.touched) && cFormSubmitted">
                        <div *ngIf="name.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label for="post" class="form-label">Post</label>

                    <input type="text" class="form-control" id="post" name="post" [(ngModel)]="cForm.contactpost"
                        #post="ngModel">
                    <div class="invalid-feedback-length" *ngIf="cForm.contactpost.length > 255">
                        {{allinoneService.lengthCheck("Post",255)}}
                    </div>

                    <!-- <div class="invalid-feedback" *ngIf="post.invalid || (post.dirty || post.touched) && cFormSubmitted">
                        <div *ngIf="post.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div> -->
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex">
                        <div class=" mb-3 ">
                            <div class="form-check me-3">
                                <input class="form-check-input" type="checkbox" id="remember" name="_remember"
                                    [(ngModel)]="isAdmin" (change)="changeAdmin($event)">
                                <label class="form-check-label mb-1 ms-1" for="remember">
                                    Admin
                                </label>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <div class="form-check me-3 ">
                                <input class="form-check-input" type="checkbox" id="selfservice" name="selfservice"
                                    [(ngModel)]="selfservices" (change)="selfservice($event)">
                                <label class="form-check-label mb-1 ms-1" for="selfservice">
                                    Self Service
                                </label>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <div class="form-check ">
                                <input class="form-check-input" type="checkbox" id="emailaccess" name="emailaccess"
                                    [(ngModel)]="emailaccess" (change)="emailAccess($event)">
                                <label class="form-check-label mb-1 ms-1" for="emailaccess">
                                    Email
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div class="row">
                <div class="mb-3 col-md-6">
                    <label for="phone" class="form-label">Phone</label>
                    <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="cForm.contactmobile">
                    <div class="invalid-feedback-length" *ngIf="cForm.contactmobile.length > 255">
                        {{allinoneService.lengthCheck("Phone",255)}}
                    </div>

                </div>
                <div class="mb-3 col-md-6">
                    <label for="email" class="form-label">Email</label>

                    <input type="text" class="form-control" id="email" name="email" [(ngModel)]="cForm.contactemail">
                    <div class="invalid-feedback-length" *ngIf="cForm.contactemail.length > 255">
                        {{allinoneService.lengthCheck("Phone",255)}}
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="mb-3  col-md-12">
                    <label for="address1" class="form-label">Address</label>
                    <input type="text" class="form-control" id="address1" name="address1"
                        [(ngModel)]="cForm.contactaddress1">
                </div>
                <div class="mb-3 col-md-12">
                    <input type="text" class="form-control" id="address2" name="address2"
                        [(ngModel)]="cForm.contactaddress2">
                </div>
                <div class="invalid-feedback-length"
                    *ngIf="cForm.contactaddress1.length > 255 || cForm.contactaddress2.length > 255">
                    {{allinoneService.lengthCheck("Address",255)}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label for="phone" class="form-label">Branch</label>
                    <ng-select class="role-select" [items]="branchlist" bindLabel="branchname" placeholder="-"
                        appendTo="body" [multiple]="false" [(ngModel)]="ngbranchlist"
                        (change)="customerbranchSelect($event)">
                    </ng-select>

                </div>
                <div class="col-md-6">
                    <label for="phone" class="form-label">Product</label>
                    <ng-select class="role-select" [items]="productList" bindLabel="name" placeholder="-"
                        appendTo="body" [multiple]="true" [(ngModel)]="ngproductlist"
                        (change)="customerproductselect($event)">
                    </ng-select>

                </div>
            </div>

        </div>
    </perfect-scrollbar>
</div>