import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss']
})
export class ButtonLoaderComponent implements OnInit {
  @Input() button_text!: string;
  @Input() load_button_text!: string;
  @Input() width!: string;
  @Input() outlinedanger: boolean = false;

  @Input() isLoading!: boolean;

  @Input() disabled : boolean = false;
  
  constructor() { }

  ngOnInit(): void {
    if (this.width == null) {
      this.width = "auto";
    }
  }

}
