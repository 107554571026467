<div>
    <app-crm-breadcrumb s_breadcrumb="Deals" [t_breadcrumb]="isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()" *ngIf="!fromCustomer && !fromPipeline">
    </app-crm-breadcrumb>
    <div class="close-save-button mb-3" *ngIf="!fromPipeline">
        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()" *ngIf="!saveLoading">
            Cancel
        </button>
        <app-button-loader (click)="submitDeal()" [button_text]="'Save'" [load_button_text]="'Saving'" [isLoading]="saveLoading">
        </app-button-loader>
    </div>
    <div class="accordion mb-3" id="dealHeader">
        <div class="accordion-item">

            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dealForm" aria-expanded="false" aria-controls="dealForm">
                <div class = "d-flex flex-column" style="width: 100%; margin-right: 15px;">
                    <div class = "d-flex justify-content-between align-items-center mb-2">
                        <div>
                            {{dealForm.customername ? dealForm.customername : '-'}}
                        </div>
                        <div>
                            {{dealForm.stagename}}
                        </div>
                    </div>
                    <div class = "d-flex justify-content-between align-items-center">
                        <div>
                            {{dealForm.currencycode ? dealForm.currencycode+' ' : ''}}{{(dealForm.amount && dealForm.amount != '0') ? (dealForm.amount | number:'2.') : '0' }}
                        </div>
                        <div>
                            {{allinoneService.formatDBToShow(allinoneService.formatDate(dealForm.targetdate))}}
                        </div> 
                    </div>
                </div>
            </button>

            <div id="dealForm" class="accordion-collapse collapse" aria-labelledby="dealHeader" data-bs-parent="#dealHeader">
                <div class="accordion-body">
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="salecode" class="form-label">Sale Code</label>

                            <input type="text" class="form-control" id="salecode" name="salecode" [(ngModel)]="dealForm.salecode" [disabled]="fromPipeline">
                            <div class="invalid-feedback-length" *ngIf="dealForm.salecode.length > 50">
                                {{allinoneService.lengthCheck("Sale Code",50)}}
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="customer" class="form-label">Customer</label>
                            <input type="text" aria-label="Number" matInput class="form-control" (ngModelChange)="customerChange()" [matAutocomplete]="auto" [(ngModel)]="searchText" name="customer" id="customer" #customer="ngModel" [ngClass]="{ 'is-invalid': dealFormSubmitted && customer.invalid }"
                                [disabled]="gettingCustomers || fromCustomer || fromPipeline || isEditing" required>
                            <mat-progress-bar mode="indeterminate" *ngIf="gettingCustomers"></mat-progress-bar>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let cus of customers | filter : searchText:22" [value]="cus.name" (onSelectionChange)="customerSelect(cus)" (click)="customerSelect(cus)">
                                    {{cus.customername}} - {{cus.customermobile}} ({{cus.statusname}})
                                </mat-option>
                            </mat-autocomplete>

                            <div class="invalid-feedback" *ngIf="customer.invalid || (customer.dirty || customer.touched) && dealFormSubmitted">
                                <div *ngIf="customer.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="currencycode" class="form-label">Currency Code</label>

                            <div>
                                <select class="form-select" aria-label="Select Currency" id="currencycode" name="currencycode" [(ngModel)]="dealForm.currency" required (change)="currencyChange($event)" [disabled]="fromPipeline">
                                    <option *ngFor="let currency of currencyType" value="{{currency.key}}">
                                        {{currency.code}} ({{currency.country}})</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="amount" class="form-label">Amount</label>
                            <input type="number" class="form-control" id="amount" name="amount" [(ngModel)]="dealForm.amount" [disabled]="fromPipeline">
                            <div class="invalid-feedback-length" *ngIf="dealForm.amount.length > 50">
                                {{allinoneService.lengthCheck("Amount",50)}}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="opendate" class="form-label">Open Date</label>
                            <input type="date" class="form-control" id="opendate" name="opendate" [(ngModel)]="dealForm.opendate" required #opendate="ngModel" step="1" [ngClass]="{ 'is-invalid': dealFormSubmitted && opendate.invalid }" [disabled]="fromPipeline">

                            <div class="invalid-feedback" *ngIf="opendate.invalid || (opendate.dirty || opendate.touched) ">
                                <div *ngIf="opendate.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="targetdate" class="form-label">Target Date</label>
                            <input type="date" class="form-control" id="targetdate" name="targetdate" [(ngModel)]="dealForm.targetdate" required #targetdate="ngModel" [min]="dealForm.opendate" step="1" [ngClass]="{ 'is-invalid': dealFormSubmitted && targetdate.invalid }" [disabled]="fromPipeline">

                            <div class="invalid-feedback" *ngIf="targetdate.invalid || (targetdate.dirty || targetdate.touched)">
                                <div *ngIf="targetdate.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-3 col-md-6">
                            <label for="status" class="form-label">Deal Status</label>

                            <div>
                                <select class="form-select" aria-label="Select Status" id="status" name="status" [(ngModel)]="dealForm.status" required [disabled]="fromPipeline" (change)="onChange($event)">
                                    <option *ngFor="let status of statusList" value="{{status.key}}">
                                        {{status.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-3 col-md-6">
                            <label for="stageid" class="form-label">Deal Stage</label>

                            <div>
                                <select class="form-select" aria-label="Select Stage" id="stageid" name="stageid" [(ngModel)]="dealForm.stageid" required (change)="dealStageChange($event)" [disabled]="fromPipeline" [disabled]="gettingStages">
                                    <option *ngFor="let stage of stageSList" value="{{stage.stageid}}">
                                        {{stage.name}}</option>
                                </select>
                                <mat-progress-bar mode="indeterminate" *ngIf="gettingStages"></mat-progress-bar>
                                <!-- gettingStages -->
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="checkclosingdate">
                        <div class="mb-3 col-md-12">
                            <label for="closingdate" class="form-label">Closing Date</label>
                            <input type="date" class="form-control" id="closingdate" name="closingdate" [(ngModel)]="closingdate" required #targetdate="ngModel" step="1" [ngClass]="{ 'is-invalid': dealFormSubmitted && targetdate.invalid }" [disabled]="fromPipeline">


                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="remark" class="form-label">Remark</label>

                        <textarea class="form-control" id="remark" name="remark" [(ngModel)]="dealForm.remark" rows="3" [disabled]="fromPipeline"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mb-3" *ngIf="!fromPipeline">
        <button class="btn btn-custom" (click)="addItem()"> 
            <i class="fa fa-plus me-1"></i> Add Item
        </button>
    </div>
    <div class="row">
        <div class="col-md-6" *ngFor="let product of productList; let i = index;">
            <div class="products-card p-1 my-2">
                <div class="d-flex flex-column" (click)="addItem(product)">
                    <div class="d-flex justify-content-between">
                        <div class="p-1 product-name ">
                            {{product.skucode}} {{product.name}}
                        </div>
                        <div (click)="deleteProduct(i)" *ngIf="!fromPipeline">
                            <i class="fas fa-times delete-icon"></i>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="p-1">
                            {{product.price | number:'2.'}} x {{product.qty | number:'2.'}}
                        </div>
                        <div class="p-1">
                            Amount: {{product.amount | number:'2.'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>