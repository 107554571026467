import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { differenceBy } from 'lodash';

@Component({
  selector: 'app-hierarchy-edit',
  templateUrl: './hierarchy-edit.component.html',
  styleUrls: ['./hierarchy-edit.component.css'],
})
export class HierarchyEditComponent implements OnInit {
  node: any;

  isEdited: boolean = false;
  isParentValid: boolean = false;
  isUserValid: boolean = false;
  hcFormSubmitted: boolean = false;
  saveLoading: boolean = false;
  changeType: boolean = false;
  gettingHierarchy: boolean = false;

  hcForm = {
    pid: '',
    peid: '',
    cid: '',
    ceid: '',
    type: '',
    orgid: '',
    order: 0,
    primary: false,
  };

  parentList: any[] = [];
  roParentList: any[] = [];
  members: any[] = [];
  positions: any[] = [];
  parentPositions: any[] = [];
  hierarchyTypes: any[] = [];
  childrenList: any = [];
  filterparentList: any = [];
  selectedUserid: any = '';
  selectedUserName: any = '';
  selectedEmployeeid: any = '';
  selectedPUserid: any = '';
  selectedPUserName: any = '';
  selectedPEmployeeid: any = '';
  searchText: any = '';
  searchText1: any = '';
  childPosition: any = '';
  originalType: string = '';

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<HierarchyEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.hcForm.orgid = data.orgid;
    this.filterparentList = data.parentList;
    this.parentList = data.parentList;
    this.roParentList = data.roParentList;
    this.members = data.members;
    this.changeType = data.changetype;

    this.hcForm.type = data.selectedType;
    this.originalType = data.selectedType;
    this.hierarchyTypes = data.type;
    this.node = data.node;
    this.hcForm.cid = this.node.positionid;
    this.hcForm.ceid = this.node.employeeid;
    this.hcForm.order = this.node.order;
    this.hcForm.primary = this.node.primary == '1';
    if (this.node.department && this.node.division) {
      this.childPosition =
        this.node.positionname +
        ', ' +
        this.node.department +
        ', ' +
        this.node.division;
    } else if (!this.node.department && this.node.division) {
      this.childPosition =
        this.node.positionname + ', - , ' + this.node.division;
    } else if (this.node.department && !this.node.division) {
      this.childPosition = this.node.positionname + ', ' + this.node.department;
    } else {
      this.childPosition = this.node.positionname;
    }
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    if (this.roParentList.length == 0) {
      this.getHierarchy();
    }
    this.getChildrenList(this.node);

    if (this.changeType) {
      this.filterparentList = this.members;
      const index = this.filterparentList.findIndex(
        (x: any) => x.employeeid == this.node.peid
      );
      this.parentChange(this.filterparentList[index]);
    } else {
      this.filterparentList = this.roParentList;
      this.filterparentList = differenceBy(
        this.filterparentList,
        this.childrenList,
        'id'
      );
      const index = this.filterparentList.findIndex(
        (x: any) =>
          x.employeeid == this.node.peid && x.positionid == this.node.pid
      );
      this.parentChange(this.filterparentList[index]);
    }
  }

  getHierarchy() {
    this.gettingHierarchy = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.hcForm.orgid,
      domain: this.allinoneService.getDomain().shortcode,
      type: '1',
    };

    this.kunyekService.getHierarchy(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.gettingHierarchy = false;
          this.roParentList = res.parentlist;
          if (this.hcForm.type == '1') {
            this.filterparentList = this.roParentList;
            this.getChildrenList(this.node);
            this.filterparentList = differenceBy(
              this.filterparentList,
              this.childrenList,
              'id'
            );
            const index = this.filterparentList.findIndex(
              (x: any) =>
                x.employeeid == this.node.peid && x.positionid == this.node.pid
            );
            this.parentChange(this.filterparentList[index]);
          }
        } else {
          this.gettingHierarchy = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingHierarchy = false;
      }
    );
  }

  close() {
    this.dialogRef.close({
      isEdited: this.isEdited,
      parentList: this.parentList,
      roParentList: this.roParentList,
    });
  }

  typeChange(event: any) {
    this.hcForm.type = event.target.value;
    if (this.hcForm.type == '1') {
      this.filterparentList = this.roParentList;
      const index = this.filterparentList.findIndex(
        (x: any) => x.employeeid == this.node.peid
      );
      console.log(index);
      if (index > -1) {
        console.log('-------1');
        this.parentChange(this.filterparentList[index]);
      } else {
        console.log('-------2');
        this.searchText1 = '';
        this.parentPositions = [];
        this.peidChange();
      }
    } else {
      this.filterparentList = this.members;
    }
  }

  parentChange(data: any) {
    console.log(data);
    if (data != undefined) {
      console.log('here');
      this.isParentValid = true;
      this.selectedPUserName = data.username;
      this.selectedPUserid = data.userid;
      this.selectedPEmployeeid = data.employeeid;
      this.parentPositions = [];
      var temp1 = {
        appid: '',
        createdby: '',
        department: data.department.toString().trim(),
        description: '',
        division: data.division.toString().trim(),
        domainid: '',
        id: '',
        name:
          this.hcForm.type == '1' ? data.positionname.toString().trim() : '',
        orgid: '',
        positionid: data.positionid,
      };
      this.parentPositions.push(temp1);
      this.hcForm.pid = data.positionid;
      this.hcForm.peid = data.employeeid;
      this.searchText1 = this.hcForm.peid;
    }
  }

  peidChange() {
    this.selectedPEmployeeid = '';
    this.selectedPUserName = '';
    this.selectedPUserid = '';
    this.hcForm.pid = '';
    this.hcForm.peid = '';
    this.isParentValid = false;
  }

  getChildrenList(node: any) {
    const temp = {
      id: node.id,
      userid: node.userid,
      positionid: node.positionid,
      peid: node.peid,
      pid: node.pid,
      employeeid: node.employeeid,
      parentuserid: node.parentuserid,
    };
    this.childrenList.push(temp);
    if (node.children && node.children.length > 0) {
      for (var i = 0; i < node.children.length; i++) {
        var item = node.children[i];
        this.getChildrenList(item);
      }
    }
  }

  submitHC() {
    this.hcFormSubmitted = true;
    if (
      ((this.hcForm.type == '1' && this.hcForm.pid) ||
        this.hcForm.type != '1') &&
      this.hcForm.peid &&
      this.hcForm.cid &&
      this.hcForm.ceid &&
      this.hcForm.type &&
      ((this.hcForm.type == '1' &&
        parseInt(this.hcForm.order.toString()) >= 0) ||
        this.hcForm.type != '1')
    ) {
      this.addHierarchy();
    }
  }

  addHierarchy() {
    this.saveLoading = true;
    const data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.hcForm.orgid,
      cid: this.hcForm.cid,
      ceid: this.hcForm.ceid,
      pid: this.hcForm.type == '1' ? this.hcForm.pid : '',
      peid: this.hcForm.peid,
      type: this.hcForm.type,
      order: this.hcForm.type == '1' ? this.hcForm.order : 0,
      inserttype: 'update',
      children: [],
      primary: this.hcForm.primary ? '1' : '0',
      id: this.node.id,
    };
    var fun = this.changeType
      ? this.kunyekService.changeHierarchyType(data)
      : this.kunyekService.addHierarchy(data);
    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.hcFormSubmitted = false;
          this.isEdited = true;

          var index = this.parentList
            .map((x) => x.positionid + ',' + x.employeeid)
            .indexOf(data.cid + ',' + data.ceid);
          console.log(this.parentList);
          var parentRes: any = this.parentList.filter(
            (x: any) =>
              x.positionid == this.parentList[index].pid &&
              x.employeeid == this.parentList[index].peid
          );
          if (parentRes.length > 0) {
            var parentpositionname = parentRes[0].positionname;
            var parentdepartment = parentRes[0].department;
            var parentdivision = parentRes[0].division;
            var pusername = parentRes[0].username;
            var puserid = parentRes[0].userid;
          }
          this.parentList[index].pid = data.pid;
          this.parentList[index].peid = data.peid;
          this.parentList[index].parentpositionname = parentpositionname;
          this.parentList[index].parentdepartment = parentdepartment;
          this.parentList[index].parentdivision = parentdivision;
          this.parentList[index].parentusername = pusername;
          this.parentList[index].parentuserid = puserid;
          this.parentList[index].primary = this.hcForm.primary ? '1' : '0';
          this.dialogRef.close({
            isEdited: this.isEdited,
            parentList: this.parentList,
            roParentList: this.roParentList,
          });
        } else {
          this.saveLoading = false;
          this.hcFormSubmitted = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.saveLoading = false;
        this.hcFormSubmitted = false;
      }
    );
  }
}
