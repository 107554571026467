<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title" style="font-size:18px">Priority</div>
        <div class="close-btn">
            <button mat-icon-button mat-dialog-close (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <!-- <div class="mb-2" style="margin-top: 10px;">
        <button class="btn btn-custom" *ngIf="!addtype" (click)="AddNewpriority()">
            <i class="fa fa-plus me-1"></i>
            New Priority
        </button>
    </div> -->
    <div class="table-responsive" *ngIf="!addtype">
        <table class="table table-responsive table-borderless">
            <thead class="table-header">
                <tr>
                    <!--  mat-sort-header="type" -->
                    <td scope="col" class="col-lg-12 col-md-10">Description</td>
                    <td scope="col" class="col-lg-12 col-md-12">Action</td>

                </tr>
            </thead>
            <tbody *ngIf="loading">
                <tr>
                    <td colspan="5" class="nodata">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="!loading">

                <tr *ngIf="priorityData.length == 0">
                    <td colspan="2" class="nodata">Empty</td>
                </tr>
                <tr *ngFor="let item of priorityData;let index = index;">

                    <td class="td-data ">
                        <span [title]="item.name">{{item.name}}</span>
                    </td>
                    <td class="d-flex justify-content-center">
                        <button class="btn btn-primary">
                            <i class="fa fa-toggle-on"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>


    <div class="mb-2 d-flex" *ngIf="addtype" style="margin-top: 10px;">
        <button type="button" *ngIf="!saveLoading" class="btn btn-custom me-2" (click)="cancel()">
            Cancel
        </button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" (click)="submitService()" [isLoading]="saveLoading">
        </app-button-loader>
    </div>


    <div class="row" *ngIf="addtype">
        <div class="mb-3  col-md-12">
            <label for="name" class="form-label">Priority</label>

            <input type="text" class="form-control" id="name" name="name" [(ngModel)]="priorityType" #type="ngModel" [ngClass]="{ 'is-invalid': serviceFormSubmitted && type.invalid }" required>

            <div class="invalid-feedback" *ngIf="type.invalid || (type.dirty || type.touched) && serviceFormSubmitted">
                <div *ngIf="type.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
    </div>
</div>