import { DatePipe, ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-qr-setup',
  templateUrl: './qr-setup.component.html',
  styleUrls: ['./qr-setup.component.scss'],
})
export class QrSetupComponent implements OnInit, AfterViewInit {
  @ViewChild('choosefile') choosefile: any;
  @ViewChild('contactfile') contactfile: any;
  @ViewChild('pfFileInput') pfFileInput: any;

  saveLoading: boolean = false;
  submitted: boolean = false;
  isEditing: boolean = false;
  access: boolean = true;

  pfImgURL: any;

  idToEdit: string = '';
  orgid: string = '';
  hashedOrgid: string = '';
  olduserid: string = '';

  typeList = [
    {
      name: 'Card',
      id: '001',
      folder: 'card',
    },
    {
      name: 'Menu',
      id: '002',
      folder: 'menu',
    },
    {
      name: 'Documents',
      id: '003',
      folder: 'doc',
    },
    {
      name: 'Data',
      id: '004',
      folder: 'data',
    },
    {
      name: 'Digital Assets',
      id: '005',
      folder: 'digital',
    },
    {
      name: 'Digital ID',
      id: '006',
      folder: 'did',
    },
  ];

  filetypeList: any = {
    pdf: '90',
    jpg: '80',
    jpeg: '81',
    png: '82',
  };

  // allowedFileType: string = '';

  fileSelected: any = '';
  contactFileSelected: any = '';
  pfFileSelected: any = '';
  isContactFileSelected: boolean = false;

  form = this.formBuider.group({
    name: ['', Validators.required],
    orgname: [''],
    userid: ['', Validators.required],
    // password: ['', [Validators.required, Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+={}\[\]|\\:;"'<,>.?/])[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;"'<,>.?/]{7,}$/)]],
    password : ['', [Validators.required,Validators.minLength(7)]],
    confirmpassword: ['', Validators.required],
    filename: [''],
    uploadfilename: [''],
    contactname: ['', Validators.required],
    post: [''],
    mobile: ['', Validators.required],
    workphone: [''],
    email: [''],
    workemail : [''],
    company: [''],
    department: [''],
    website: [''],
    location: [''],
    googlemap: [''],
    linkedin: [''],
    facebook: [''],
    messenger : [''],
    whatsapp : [''],
    instagram : [''],
    telegram : [''],
    viber : [''],
    filepath: ['', Validators.required],
    filetype: [''],
    type: ['001', Validators.required],
    contactfilename: [''],
    pffilename: [''],
    template : ['002']
  });

  get formControl() {
    return this.form.controls;
  }

  test() {
    console.log(this.formControl.password.invalid);
  }

  constructor(
    public dialogRef: MatDialogRef<QrSetupComponent>,
    private formBuider: FormBuilder,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private datepipe: DatePipe,
    private viewportScroller: ViewportScroller,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isEditing = data.isEditing;
    this.orgid = data.orgid;
    this.hashedOrgid = this.allinoneService.hash(this.orgid);
    if (this.isEditing) {
      var temp = data.qrdata;
      console.log('Your temp');

      console.log(temp);

      this.idToEdit = temp.id;
      this.olduserid = temp.accessuserid;
      // this.allowedFileType = temp.filename.split('.').pop();
      this.form.setValue({
        name: temp.description,
        password: '',
        confirmpassword: '',
        filename: temp.filename,
        uploadfilename: temp.uploadfilename,
        filepath: temp.path,
        filetype: temp.filename.split('.').pop(),
        type: temp.type,
        orgname: temp.orgname,
        userid: temp.accessuserid,
        contactfilename: temp.contactfile,
        pffilename: temp.profileimage,
        contactname: temp.name,
        post: temp.post,
        mobile: temp.mobile,
        workphone: temp.workphone,
        email: temp.email,
        workemail : temp.workemail || '',
        company: temp.company,
        department: temp.department,
        website: temp.website,
        location: temp.location,
        googlemap: temp.googlemap ? temp.googlemap : '',
        linkedin: temp.linkedin,
        facebook: temp.facebook,
        messenger : temp.messenger,
        whatsapp : temp.whatsapp || '',
        instagram : temp.instagram,
        viber : temp.viber,
        telegram: temp.telegram || '',
        template : temp.template == "" ? '002' : temp.template,
      });
      console.log('Your ans');
      console.log(temp.profileimageurl);

      this.pfImgURL = temp.profileimageurl;
      this.access = temp.access;
      if (temp.contactfile) {
        this.isContactFileSelected = true;
      }
    }
  }
  ngAfterViewInit(): void {
    // this.contactfile.nativeElement.value = 'asdfasdf';
  }

  ngOnInit(): void {
    if (this.isEditing) {
      this.formControl.type.disable();
      if (!this.access) {
        this.formControl.userid.disable();
      }
    }
    else{
      this.form.patchValue({
        filename:"",
        filepath: this.getUploadFileName(),
        filetype: "",
      });
    }
  }

  checkFile(event: any) {
    if (!this.isEditing) {
      this.submitted = false;
      this.fileSelected = '';
      this.form.patchValue({
        filename: '',
        filepath: '',
        filetype: '',
      });
    }
  }

  uploadFile(event: any) {
    var filesize = event[0].size / 1000000;
    if (filesize > 200) {
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    var filetype = event[0].name.split('.').pop();

    if (this.filetypeList[filetype] == undefined) {
      this.messageService.openSnackBar('Not supported file type.', 'warn');
      return;
    }

    // if (this.isEditing && filetype != this.allowedFileType) {
    //   this.messageService.openSnackBar(
    //     'Updating to different file type is not allowed.',
    //     'warn'
    //   );
    //   this.choosefile.nativeElement.value = '';
    //   return;
    // }
    const isFiletypesame = this.formControl.filetype.value == filetype;
    this.fileSelected = event[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      filepath: this.isEditing
        ? this.formControl.filepath.value
        : this.getUploadFileName(),
      filetype: filetype,
    });
  }

  onFileSelected(event: any) {
    var filesize = event.target.files[0].size / 1000000;
    if (filesize > 200) {
      this.messageService.openSnackBar('Maximum file size is 200 mb.', 'warn');
      return;
    }
    var filetype = event.target.files[0].name.split('.').pop();
    if (this.filetypeList[filetype] == undefined) {
      this.messageService.openSnackBar('Not supported file type.', 'warn');
      return;
    }
    // if (this.isEditing && filetype != this.allowedFileType) {
    //   this.messageService.openSnackBar(
    //     'Updating to different file type is not allowed.',
    //     'warn'
    //   );
    //   this.choosefile.nativeElement.value = '';
    //   return;
    // }
    const isFiletypesame = this.formControl.filetype.value == filetype;
    this.fileSelected = event.target.files[0];
    this.form.patchValue({
      filename: this.fileSelected.name,
      filepath: this.isEditing
        ? this.formControl.filepath.value
        : this.getUploadFileName(),
      filetype: filetype,
    });
    this.choosefile.nativeElement.value = '';
  }

  cancel() {
    this.dialogRef.close();
  }

  removeContact() {
    this.contactFileSelected = '';
    // this.contactfile.nativeElement.value = '';
    this.isContactFileSelected = false;
    this.form.patchValue({
      contactfilename: '',
    });
  }
  contactFileSelect(event: any) {
    console.log(event.target.files);
    if (event.target.files.length != 0) {
      var filetype = event.target.files[0].name.split('.').pop();
      if (filetype != 'vcf') {
        this.messageService.openSnackBar('Not supported file type.', 'warn');
        return;
      }
      this.contactFileSelected = event.target.files[0];
      this.form.patchValue({
        contactfilename: this.contactFileSelected.name,
      });
      this.isContactFileSelected = true;
      console.log(this.form);
    } else if (event.target.files.length == 0) {
      this.contactFileSelected = '';
    }
  }

  getFileName(file: any) {
    var name = file.name.trim();
    console.log(name);
    var splitName = name.split('.');
    console.log(splitName);
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  pfImgUpload(event: any) {
    this.pfFileSelected = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.pfFileSelected);
    reader.onload = (_event) => {
      this.pfImgURL = reader.result;
    };
    this.form.patchValue({
      pffilename: this.getFileName(this.pfFileSelected),
    });
  }

  removePfImage() {
    this.form.patchValue({
      pffilename: '',
    });
    this.pfFileSelected = '';
    this.pfImgURL = '';
    this.pfFileInput.nativeElement.value = '';
    console.log(this.pfImgURL);
  }

  async submitForm() {
    this.submitted = true;

    if (
      this.formControl.filepath.invalid &&
      this.formControl.type.value != '001'
    ) {
      return this.messageService.openSnackBar('Please choose a file.', 'warn');
    }
    if (this.formControl.name.invalid) {
      return this.messageService.openSnackBar('Please enter Name.', 'warn');
    }

    if (
      (this.formControl.post.value != '' &&
        this.formControl.contactname.value == '') ||
      (this.formControl.company.value != '' &&
        this.formControl.contactname.value == '') ||
      (this.formControl.department.value != '' &&
        this.formControl.contactname.value == '') ||
      (this.formControl.mobile.value != '' &&
        this.formControl.contactname.value == '') ||
      (this.formControl.workphone.value != '' &&
        this.formControl.contactname.value == '')
    ) {
      return this.messageService.openSnackBar(
        'Please enter Contact Name.',
        'warn'
      );
    }

    // if(!this.isEditing){
    //   if(!this.formControl.userid.invalid){
    //     if(this.formControl.type.value == '001'){
    //       if(this.formControl.mobile.invalid && (this.formControl.password.invalid || this.formControl.confirmpassword.invalid)){
    //         this.scrollToTop();
    //         return;
    //       }

    //     }
    //     else{
    //       if(this.formControl.password.invalid || this.formControl.confirmpassword.invalid){
    //         return;
    //       }
    //     }
    //   }
    // }
    if(!this.isEditing){
      if(!this.formControl.userid.invalid){
        if(this.formControl.type.value == '001'){
          if(this.formControl.password.invalid || this.formControl.confirmpassword.invalid){
            this.scrollToTop();
            return;
          }

        }
        else{
          if(this.formControl.password.invalid || this.formControl.confirmpassword.invalid){
            return;
          }
        }
      }
    }

    // if(this.isEditing){
    //   if(!this.formControl.userid.invalid && this.formControl.userid.value != this.olduserid){
    //     if(this.formControl.type.value == '001'){
    //       if(this.formControl.mobile.invalid && (this.formControl.password.invalid || this.formControl.confirmpassword.invalid)){
    //         this.scrollToTop();
    //         return;
    //       }
    //     }
    //     else{
    //       if(this.formControl.password.invalid || this.formControl.confirmpassword.invalid){
    //         return;
    //       }
    //     }
    //   }
    // }
    if(this.isEditing){
      if(!this.formControl.userid.invalid && this.formControl.userid.value != this.olduserid){
        if(this.formControl.type.value == '001'){
          if(this.formControl.password.invalid || this.formControl.confirmpassword.invalid){
            this.scrollToTop();
            return;
          }
        }
        else{
          if(this.formControl.password.invalid || this.formControl.confirmpassword.invalid){
            return;
          }
        }
      }
    }

    this.saveLoading = true;
    this.dialogRef.disableClose = true;
    const type = this.formControl.type.value.toString().trim();
    const name = this.formControl.name.value.toString().trim();
    const path = this.formControl.filepath.value.toString().trim();
    const filename = this.formControl.filename.value.toString().trim();
    const filetype = this.formControl.filetype.value.toString().trim();
    const typename = this.typeList.find((i) => i.id == type)?.folder;
    const orgname = this.formControl.orgname.value.toString().trim();
    const userid = this.allinoneService.checkUserId(
      this.formControl.userid.value.toString().trim()
    );
    const contactfilename = this.formControl.contactfilename.value
      .toString()
      .trim();

    const profilename = this.formControl.pffilename.value.toString().trim();

    const contactname = this.formControl.contactname.value.toString().trim();
    const post = this.formControl.post.value.toString().trim();
    const mobile = this.allinoneService.checkUserId(
      this.formControl.mobile.value.toString().trim()
    );
    const workphone = this.allinoneService.checkUserId(
      this.formControl.workphone.value.toString().trim()
    );
    const email = this.formControl.email.value.toString().trim();
    const workemail = this.formControl.workemail.value.toString().trim();
    const company = this.formControl.company.value.toString().trim();
    const department = this.formControl.department.value.toString().trim();
    const website = this.formControl.website.value.toString().trim();
    const location = this.formControl.location.value.toString().trim();
    const linkedin = this.formControl.linkedin.value.toString().trim();
    const facebook = this.formControl.facebook.value.toString().trim();
    const messenger = this.formControl.messenger.value.toString().trim();
    const whatsapp = this.allinoneService.checkUserId(this.formControl.whatsapp.value.toString().trim());
    const viber = this.allinoneService.checkUserId(this.formControl.viber.value.toString().trim());
    const instagram = this.formControl.instagram.value.toString().trim();
    const telegram = this.formControl.telegram.value.toString().trim();
    const template = this.formControl.template.value.toString().trim();
    const googlemap = this.formControl.googlemap.value.toString().trim();
    const uploadfilename = this.formControl.uploadfilename.value.toString().trim();
    // const password = mobile ? mobile : this.formControl.password.value.toString().trim();
    const password = this.formControl.password.value.toString().trim();
    const confirmpassword = this.formControl.confirmpassword.value.toString().trim();

    console.log(typename);

    if (this.fileSelected) {
      const result = await this.allinoneService.newfileUploadToS3(
        this.fileSelected,
        typename + '.' + filetype,
        this.hashedOrgid +
          '/' +
          type +          
          '/' +
          path +
          '/'
      );
      if (!result) {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        return this.messageService.openSnackBar(
          'There was an error uploading your file.',
          'warn'
        );
      }
    }

    if (this.contactFileSelected) {
      const result = await this.allinoneService.newfileUploadToS3(
        this.contactFileSelected,
        contactfilename,
        this.hashedOrgid +
          '/' +
          type +
          '/' +
          path +
          '/'
      );
      if (!result) {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        return this.messageService.openSnackBar(
          'There was an error uploading your file.',
          'warn'
        );
      }
    }

    if (this.pfFileSelected) {
      if (this.pfFileSelected.size >= 102400) {
        this.pfFileSelected = await this.allinoneService.compressImage(
          this.pfFileSelected
        );
        if (this.pfFileSelected == false) {
          this.messageService.openSnackBar(
            'Error while uploading your file.',
            'warn'
          );
          this.saveLoading = false;
          return;
        }
      }
      const result = await this.allinoneService.newfileUploadToS3(
        this.pfFileSelected,
        profilename,
        this.hashedOrgid +
          '/' +
          type +
          '/' +
          path +
          '/'
      );
      if (!result) {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
        return this.messageService.openSnackBar(
          'There was an error uploading your file.',
          'warn'
        );
      }
    }

    const data =
      type == '001'
        ? {
            path: path,
            filename: filename,
            id: this.idToEdit,
            type: type,
            description: name,
            orgname: orgname,
            accessuserid: userid,
            name: contactname,
            post: post,
            mobile: mobile,
            workphone: workphone,
            email: email,
            workemail : workemail,
            company: company,
            department: department,
            website: website,
            location: location,
            googlemap: googlemap,
            linkedin: linkedin,
            facebook: facebook,
            messenger : messenger,
            whatsapp : whatsapp,
            instagram : instagram,
            telegram: telegram,
            viber : viber,
            template : template,
            contactfile: contactfilename,
            profileimage: profilename,
            profileimageurl: this.pfFileSelected ? '' : this.pfImgURL,
            orgid: this.orgid,
            uploadfilename: this.isEditing ? (this.fileSelected ? typename + '.' + filetype : uploadfilename): (this.fileSelected ? typename + '.' + filetype : ''),
            password: password,
          }
        : {
            id: this.idToEdit,
            type: type,
            description: name,
            orgname: orgname,
            accessuserid: userid,
            path: path,
            filename: filename,
            contactfile: contactfilename,
            orgid: this.orgid,
            template : template,
            uploadfilename: typename + '.' + filetype,
            password: password,
          };
    console.log(data);
    // this.saveLoading = false;
    // return;

    var fun = this.isEditing
      ? this.kunyekService.updatePersonalQR(data)
      : this.kunyekService.addPersonalQR(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var temp = {
            id: res.id,
            type: type,
            description: name,
            path: path,
            filename: filename,
            typetodisplay: this.typeList.find((i) => i.id == type)?.name,
            filetype: filename.split('.').pop(),
            qrurl: res.qrurl,
            contactfile: contactfilename,
            profileimage: profilename,
            profileimageurl: res.profileimageurl,
            accessuserid: userid,
            orgname: orgname,
            access: this.isEditing ? this.access : true,
            name: contactname,
            post: post,
            mobile: mobile,
            workphone: workphone,
            email: email,
            workemail : workemail,
            company: company,
            department: department,
            website: website,
            location: location,
            googlemap: googlemap,
            linkedin: linkedin,
            facebook: facebook,
            messenger : messenger,
            whatsapp : whatsapp,
            instagram : instagram,
            telegram: telegram,
            template : template,
            viber : viber,
            uploadfilename: type == '001' ? (this.isEditing ? (this.fileSelected ? typename + '.' + filetype : uploadfilename): (this.fileSelected ? typename + '.' + filetype : '')) : typename + '.' + filetype,
          };
          this.dialogRef.close(temp);
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      },
      (err) => {
        this.saveLoading = false;
        this.dialogRef.disableClose = false;
      }
    );
  }

  getUploadFileName() {
    // var filetype = file.name.split('.').pop();
    var currentdatetime = this.datepipe.transform(new Date(), 'yyyyMMddhhmmss');
    var shuffled = currentdatetime!
      .split('')
      .sort(function () {
        return 0.5 - Math.random();
      })
      .join('');
    return this.allinoneService.hash(shuffled);
  }

  removeAttachment(){
    this.form.patchValue({
      filename: '',
      filetype: '',
      uploadfilename : ''
    });
    this.fileSelected = '';   
  }

  scrollToTop() {
    console.log('Scrolling');
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor('password');
    }, 100)
  }
}
