<div class="container-fluid">
    <div class="content">
        <!-- <app-breadcrumb [s_breadcrumb]="'Domains'"
            [t_breadcrumb]="domainAddorNot ? isEditing ? domainForm.shortcode:  'New Domain': ''" (emit)="cancel()">
        </app-breadcrumb> -->
        <app-team-breadcrumb [s_breadcrumb]="'Domains'"
            [t_breadcrumb]="domainAddorNot ? isEditing ? domainForm.shortcode:  'New Domain': ''" (emit)="cancel()">
        </app-team-breadcrumb>

        <div class="mb-3 d-flex justify-content-between header">
            <div>
                <button *ngIf="!domainAddorNot" type="button" class="btn btn-custom" (click)="addDomain()">
                    <i class="fa fa-plus me-1"></i>New Domain
                </button>
                <div *ngIf="domainAddorNot" class="close-save-button">
                    <button type="button" class="btn btn-custom me-2 my-1" (click)="cancel()" *ngIf="!saveLoading">
                        Cancel
                    </button>
                    <app-button-loader (click)="submitDomain()" [button_text]="'Save'" class="my-1"
                        [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>

                    <div class="line" *ngIf="isEditing"></div>

                    <button *ngIf="isEditing" type="button" class="btn btn-custom me-2 my-1" (click)="viewAdmins()"
                        title="View Admins">
                        Admins
                    </button>
                </div>
            </div>
        </div>

        <form class="mb-4">
            <div *ngIf="domainAddorNot == true">
                <div>
                    <div>
                        Domain Image
                    </div>
                    <div style="position: relative;">
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                            (onFileDropped)="uploadFile($event)">
                            <!-- <span>Choose Image</span> -->
                            <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="imgURL ? imgURL : '../../../assets/images/uploadphoto.png'">
                            <!-- <mat-icon class="remove-image">remove_circle</mat-icon> -->

                            <input hidden type="file" #fileInput accept='image/*' (change)="uploadFile1($event)">
                        </div>
                        <button mat-icon-button class="remove-drag-image" *ngIf="imgURL" (click)="removeImage()">
                            <mat-icon>remove_circle</mat-icon>
                        </button>
                    </div>
                    <!-- <img [src]="imgURL" height="200" *ngIf="imgURL"> -->
                </div>
                <div class="row">
                    <div class=" mb-3 col-md-6">
                        <label for="domainid" class="form-label">Domain ID</label>
                        <input type="text" class="form-control" [(ngModel)]="domainForm.domainid" id="domainid"
                            name="domainid" placeholder="001,002" required #domainid="ngModel"
                            [ngClass]="{ 'is-invalid': domainFormSubmitted && domainid.invalid }"
                            (keyup.enter)="submitDomain()" [disabled]="isEditing">

                        <div class="invalid-feedback"
                            *ngIf="domainid.invalid || (domainid.dirty || domainid.touched) && domainFormSubmitted">
                            <div *ngIf="domainid.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="shortcode" class="form-label">Short Code</label>
                        <input type="text" class="form-control" [(ngModel)]="domainForm.shortcode" id="shortcode"
                            name="shortcode" placeholder="MIT, Public, Demo" required #shortcode="ngModel"
                            [ngClass]="{ 'is-invalid': domainFormSubmitted && shortcode.invalid }"
                            (keyup.enter)="submitDomain()" [disabled]="isEditing">

                        <div class="invalid-feedback"
                            *ngIf="shortcode.invalid || (shortcode.dirty || shortcode.touched) && domainFormSubmitted">
                            <div *ngIf="shortcode.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="description" class="form-label">Description</label>
                    <input type="text" class="form-control" [(ngModel)]="domainForm.description" id="description"
                        name="description" placeholder="Enter Description" required #description="ngModel"
                        [ngClass]="{ 'is-invalid': domainFormSubmitted && description.invalid }"
                        (keyup.enter)="submitDomain()">

                    <div class="invalid-feedback"
                        *ngIf="description.invalid || (description.dirty || description.touched) && domainFormSubmitted">
                        <div *ngIf="description.errors?.required">
                            {{allinoneService.input_err_msg}}
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="table-responsive" matSort (matSortChange)="sortData($event)">
            <table class="table table-responsive table-borderless" *ngIf="domainAddorNot == false">
                <thead class="table-header">
                    <tr>
                        <td scope="col" class="col-lg-1 col-md-1">Image</td>
                        <td scope="col" class="col-lg-4 col-md-4" mat-sort-header="des">Description</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="id">Domain ID</td>
                        <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="shortcode">Short Code</td>
                        <td scope="col" class="d-flex justify-content-center">Action</td>
                    </tr>
                </thead>
                <tbody *ngIf="domainListLoading">
                    <tr>
                        <td colspan="4" class="nodata">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="domainListLoading == false">
                    <tr *ngIf="domainListToShow.length == 0">
                        <td colspan="4" class="nodata">Empty</td>
                    </tr>
                    <tr
                        *ngFor="let item of domainListToShow | paginate: { itemsPerPage: 10, currentPage: p }; let index = index">
                        <td class="td-edit" (click)="editDomain(item)">
                            <!-- <span >{{item.description}}</span> -->
                            <div>
                                <img class="preivew-image" [defaultImage]="'../../../assets/images/bg.jpg'"
                                    [lazyLoad]="item.imageurl ? item.imageurl : '../../../assets/images/post_admin.png'">
                            </div>
                        </td>
                        <td class="td-data td-edit" (click)="editDomain(item)">
                            <span [title]="item.description">{{item.description}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.domainid">{{item.domainid}}</span>
                        </td>
                        <td class="td-data">
                            <span [title]="item.shortcode">{{item.shortcode}}</span>
                        </td>
                        <td class="d-flex justify-content-center">
                            <button class="btn btn-outline-primary" title="Edit" (click)="editDomain(item)">
                                <i class='fa fa-edit'></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <pagination-controls class="paginate" (pageChange)="p = $event"
            *ngIf="domainListToShow.length > 10 && !domainAddorNot" previousLabel="Prev" nextLabel="Next">
        </pagination-controls>
    </div>
</div>