import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';

import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { iif } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-service-monthly-report',
  templateUrl: './service-monthly-report.component.html',
  styleUrls: ['./service-monthly-report.component.css']
})
export class ServiceMonthlyReportComponent implements OnInit {
  serviceMonthlyList: any = [

  ];
  monthnamelist: any = [

  ];
  productid: any = "";
  formatToShowServcie = "MM/dd/yyyy";
  gettingData: boolean = false;
  enddate: any = "";
  startdate: any = "";
  formatToShow = 'yyyy-MM-dd';
  orgid: any = "";
  organizations: any = [];
  customerid: any = "";
  checkcustomer: boolean = false;
  gettingDataEarlier: boolean = false;
  typeList: any = [
    {
      "name": "Monthly Report",
      "key": "1",
    },
    {
      "name": "Current-Earlier Month Report",
      "key": "2"
    }

  ]
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,

    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe,
    private route: ActivatedRoute,
  ) {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;

    var customerdata = this.allinoneService.getCustomer();
    if (customerdata.customerid) {
      this.customerid = customerdata.customerid;
      this.checkcustomer = true;
    }
  }

  monthname: any = "";
  typereport: any = "1";
  ngOnInit(): void {
    var threemonthdate = new Date();

    threemonthdate.setMonth(threemonthdate.getMonth() - 2);
    this.startdate = formatDate(threemonthdate, this.formatToShow, 'en-US').toString();

    this.enddate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    var status = this.route.snapshot.queryParamMap.get('status') || "";
    if (status == '001') {
      this.typereport = "1";
    } else if (status == '002') {
      this.typereport = "2";
    }
    const d = new Date();
    this.monthname = monthNames[d.getMonth()];
    if (this.typereport == "1") {
      this.getServiceMonth();

    } else {
      this.getEarlierReport();
    }
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    var start = formatDate(
      firstDay,
      this.formatToShowServcie,
      'en-US'
    ).toString();
    var end = formatDate(
      lastDay,
      this.formatToShowServcie,
      'en-US'
    ).toString();

    console.log(end);
    console.log(start)
  }

  refreshgetData() {
    if (this.typereport == "1") {
      this.getServiceMonth();

    } else {
      this.getEarlierReport();
    }

  }

  getServiceMonth() {
    this.gettingData = true;
    this.monthnamelist = [];
    this.serviceMonthlyList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      startdate: this.startdate,
      enddate: this.enddate,
      customerid: this.customerid,
      type: "001"

    };
    console.log(data)
    this.kunyekService.getServiceMonth(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.monthnamelist = res.monthnamelist;
          this.serviceMonthlyList = res.list;
          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }


  downloadSheet() {



    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Service_Monthly_Report' + time + '.xlsx';
    var searchdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();


    for (let j = 0; j < this.serviceMonthlyList.length; j++) {
      const tmpdata: any = {
        "Product Name": this.serviceMonthlyList[j]['productname']
      };
      for (let k = 0; k < this.serviceMonthlyList[j]['monthcountlist'].length; k++) {
        console.log(this.serviceMonthlyList[j]['monthcountlist'][k]);
        const count = this.serviceMonthlyList[j]['monthcountlist'][k]['count'];
        const monthName = this.serviceMonthlyList[j]['monthcountlist'][k]['monthname'];

        tmpdata[`${monthName}`] = count;

      }
      data.push(tmpdata)
    }

    console.log(data);
    // this.serviceMonthlyList.map((car: any) => {
    //   data.push({
    //     "Product Name": car.productname,
    //     "Product": car.count,
    //   });
    // });

    this.allinoneService.exportEcecl(data, name);

  }



  downloadSheet1() {


    var data: any = [];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = 'Service_Earlier_Report' + time + '.xlsx';

    this.earlierreportList.map((ear: any) => {
      data.push({
        "Product Name": ear.productname,
        [this.monthname]: ear.currentmonthcount,
        "Earlier Month": ear.earilermonthcount,
        "Total Tickets": ear.total,
      });
    });

    this.allinoneService.exportEcecl(data, name);

  }

  Open(data: any, productid: any) {
    this.productid = productid;

    var count = data.count;
    console.log(data.count);
    if (data.count > 0) {
      if (data.total == true) {
        var start = formatDate(
          this.startdate,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        var end = formatDate(
          this.enddate,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        console.log(start);
        console.log(end)
        this.router.navigateByUrl(`service/service-tickets?productid=${this.productid}&startdate=${start}&enddate=${end}&checkservice=true`);
      } else {
        var start = formatDate(
          data.startdate,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        var end = formatDate(
          data.enddate,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        this.router.navigateByUrl(`service/service-tickets?productid=${this.productid}&startdate=${start}&enddate=${end}&checkservice=true`);
      }


    }


  }

  goCrm() {
    this.router.navigateByUrl('service')
  }


  earlierreportList: any = [];
  getEarlierReport() {
    this.gettingDataEarlier = true;
    this.earlierreportList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      type: "002",
      customerid: this.customerid,
    };
    console.log(data)
    this.kunyekService.getServiceMonth(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.earlierreportList = res.list;
          this.gettingDataEarlier = false;
        } else {
          this.gettingDataEarlier = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingDataEarlier = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }

  selected(data: any) {
    // this.typereport = data.target.value;
    console.log(this.typereport)
    if (this.typereport == '1') {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'status': '001',
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'status': '002',
        },
        queryParamsHandling: 'merge',
      });
    }

  }


  Open1(data: any, productid: any, total: any) {
    this.productid = productid;
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    console.log(firstDay);
    console.log(lastDay);
    console.log(total)
    console.log(data);
    console.log(productid);

    if (total == 'total') {
      if (data.total > 0) {
        this.router.navigateByUrl(`service/service-tickets?productid=${this.productid}&viewearilermonth=false&checkservice=true`);
      }
    }
    else if (total == 'earlier') {
      if (data.earilermonthcount > 0) {

        var start = formatDate(
          firstDay,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        var end = formatDate(
          lastDay,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        console.log(start);
        console.log(end)
        this.router.navigateByUrl(`service/service-tickets?productid=${this.productid}&startdate=${start}&enddate=${end}&viewearilermonth=true&checkservice=true`);
      }

    } else {
      if (data.currentmonthcount > 0) {
        var start = formatDate(
          firstDay,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        var end = formatDate(
          lastDay,
          this.formatToShowServcie,
          'en-US'
        ).toString();
        this.router.navigateByUrl(`service/service-tickets?productid=${this.productid}&startdate=${start}&enddate=${end}&viewearilermonth=false&checkservice=true`);
      }
    }




  }

}

