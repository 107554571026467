<div class="m-4">
    <div class="me-auto">
        <app-kmember-breadcrumb [s_breadcrumb]="status">
        </app-kmember-breadcrumb>
    </div>
    <div class="m-2" *ngIf="status !== 'Add Relative' && status !== 'Edit Relative'">
        <div class="d-flex gap-2 mb-2">
            <app-button-loader (click)="closeForm()" [button_text]="'Cancel'"
                [load_button_text]="'Saving'"></app-button-loader>
            <app-button-loader (click)="handleSaveMember()" [button_text]="status=='Edit'? 'Save': 'Register'"
                [load_button_text]="'Saving'" [disabled]="registerSubmitted" [ngClass]="{'disabled': registerSubmitted}"
                [isLoading]="saveLoading">
            </app-button-loader>
            <div class="" *ngIf="currentMember">
                <div class="line"></div>
            </div>

            <app-button-loader (click)="addRelativeUser()" [button_text]="'မိသားစုအသင်းဝင်ထည့်ရန်'"
                [disabled]="currentMember && currentMember.memberType && currentMember.memberType.membertypeid == 'ASDLFKJ' && registerSubmitted"
                [ngClass]="{'disabled': registerSubmitted}" *ngIf="(userCreated || status == 'Edit')">
            </app-button-loader>

            <app-button-loader (click)="submitRegister()" [button_text]="'Submit'"
                [disabled]="currentMember && currentMember.memberType && currentMember.memberType.membertypeid == 'ASDLFKJ' && registerSubmitted"
                [ngClass]="{'disabled': registerSubmitted}" [isLoading]="submitLoading"
                [load_button_text]="'Submitting'" *ngIf="(userCreated || status == 'Edit')">
            </app-button-loader>

            <div class="d-flex btn ms-auto status-gray" *ngIf="currentMember && currentMember.formstatus == '001'">
                Submitted
            </div>

            <div class="d-flex btn ms-auto status-green" *ngIf="currentMember && currentMember.formstatus == '002'">
                Approved
            </div>
        </div>

        <div class="card">
            <div class="card-body m-2">
                <form [formGroup]="memberForm">

                    <div class="row">
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="name" class="form-label">အမည် <font color="red">*</font></label>
                                    <input type="text" id="name" class="form-control" formControlName="name" required
                                        [ngClass]="{ 'is-invalid': submitted && name.invalid }" />
                                    <div class="invalid-feedback" *ngIf="
                                        name.invalid || ((name.dirty || name.touched) && submitted)">
                                        <div *ngIf="name.errors?.required">{{ requiredMessage }}</div>
                                    </div>
                                    <!-- <div class="invalid-feedback" *ngIf="
                            name.invalid || ((name.dirty || name.touched) && submitted)">
                                        <div *ngIf="memberForm.get('name')?.invalid && memberForm.get('name')?.touched">{{ requiredMessage }}</div>
                                    </div> -->
                                </div>
                                <!-- <div class="col-md-6 mb-3">
                                    <label for="membertype" class="form-label">ကဒ်အမျိုးအစား</label>
                                    <input type="text" id="membertype" class="form-control" formControlName="membertype"
                                        required [ngClass]="{ 'is-invalid': submitted && membertype.invalid }" />
                                    <div class="invalid-feedback" *ngIf="
                            membertype.invalid || ((membertype.dirty || membertype.touched) && submitted)">
                                        <div *ngIf="membertype.errors?.required">{{ requiredMessage }}</div>
                                    </div>
                                </div> -->
                                <div class="col-md-6 mb-3">
                                    <label for="membertype" class="form-label">ကဒ်အမျိုးအစား <font color="red">*</font>
                                    </label>
                                    <!-- <ng-select [items]="memberTypeList" bindValue="membertypeid" class="form-control"
                                        formControlName="membertypeid" bindLabel="name" [clearable]="false"
                                        [ngClass]="{ 'is-invalid': submitted && membertype.invalid }"
                                        class="from-control"></ng-select>
                                    <div class="invalid-feedback" *ngIf="
                                        membertype.invalid || ((membertype.dirty || membertype.touched) && submitted)">
                                        <div *ngIf="submitted && membertype.errors?.required">{{ requiredMessage }}</div>
                                    </div> -->
                                    <select class="form-select" id="membertypeid" name="membertype" aria-readonly="true"
                                        formControlName="membertypeid" required
                                        [ngClass]="{ 'is-invalid': submitted && membertype.invalid }">
                                        <option value="">-</option>
                                        <option *ngFor="let item of memberTypeList" [value]="item.membertypeid">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                    <div class="invalid-feedback" *ngIf="
                        membertype.invalid ||
                        ((membertype.dirty || membertype.touched) && submitted)
                      ">
                                        <div *ngIf="submitted && membertype.errors?.required">
                                            {{ requiredMessage }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="subname" class="form-label">အခြားအမည်</label>
                                    <input type="text" id="subname" class="form-control" formControlName="subname" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="birthdate" class="form-label">မွေးသက္ကရာဇ် <font color="red">*</font>
                                    </label>
                                    <my-date-picker [options]="datePickerOptions" formControlName="birthdate"
                                        [ngClass]="{ 'is-invalid': birthdate.invalid && submitted }"
                                        placeholder="DD/MM/YYYY">
                                    </my-date-picker>
                                    <div class="invalid-feedback"
                                        *ngIf="birthdate.invalid || ((birthdate.dirty || birthdate.touched) && submitted)">
                                        <div *ngIf="submitted && birthdate.errors?.required">
                                            {{ requiredMessage }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="nrc" class="form-label">နိုင်ငံသားမှတ်ပုံတင်အမှတ် <font color="red">*
                                        </font>
                                    </label>
                                    <div class="g-2">
                                        <div class="row g-2">
                                            <div class="col-2">
                                                <select class="form-select" id="nrcregioncode" name="nrcregioncode"
                                                    formControlName="nrcregioncode" required
                                                    [ngClass]="{ 'is-invalid': submitted && nrcregioncode.invalid }">
                                                    <option value="">-</option>
                                                    <option *ngFor="let item of nrcRegionCode" [value]="item">{{item}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select class="form-select" id="nrctownshipcode" name="nrctownshipcode"
                                                    formControlName="nrctownshipcode" required
                                                    [ngClass]="{ 'is-invalid': submitted && nrctownshipcode.invalid }">
                                                    <option *ngFor="let item of nrcTownshipCode"
                                                        [value]="item == '-' ? '' : item">{{item}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-2">
                                                <select class="form-select" id="nrctype" name="nrctype"
                                                    formControlName="nrctype" required
                                                    [ngClass]="{ 'is-invalid': submitted && nrctype.invalid }">
                                                    <option *ngFor="let item of nrcType"
                                                        [value]="item == '-' ? '' : item">
                                                        {{item}}</option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <input class="form-control" id="nrcnumber" name="nrcnumber"
                                                    formControlName="nrcnumber" minlength="6" maxlength="6" required
                                                    (keypress)="($event.charCode >= 48 && $event.charCode < 58)"
                                                    [ngClass]="{ 'is-invalid': submitted && nrcnumber.invalid }" />
                                            </div>
                                        </div>

                                        <div class="invalid"
                                            *ngIf="nrcregioncode.invalid || nrctownshipcode.invalid || nrctype.invalid || nrcnumber.invalid">
                                            <div *ngIf="(nrcregioncode.errors?.required || nrctownshipcode.errors?.required ||
                                        nrctype.errors?.required || nrcnumber.errors?.required) && submitted">{{
                                                requiredMessage }}</div>
                                        </div>


                                    </div>

                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="nationality" class="form-label">လူမျိူး</label>
                                    <input type="text" class="form-control" name="nationality"
                                        formControlName="nationality" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="fathername" class="form-label">အဘမည်</label>
                                    <input type="text" class="form-control" name="fathername"
                                        formControlName="fathername" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="mothername" class="form-label">အမိအမည်</label>
                                    <input type="text" class="form-control" name="mothername"
                                        formControlName="mothername" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="religion" class="form-label">ကိုးကွယ်သည့်ဘာသာ</label>
                                    <input type="text" class="form-control" name="religion"
                                        formControlName="religion" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="education" class="form-label">ပညာအရည်အချင်း</label>
                                    <input type="text" class="form-control" name="education"
                                        formControlName="education" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="job" class="form-label">အလုပ်အကိုင်</label>
                                    <input type="text" class="form-control" name="job" formControlName="job" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="currentjob" class="form-label">လက်ရှိအလုပ်အကိုင်</label>
                                    <input type="text" class="form-control" name="currentjob"
                                        formControlName="currentjob" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="retiredjob" class="form-label">အငြိမ်းစားဖြစ်ခဲ့သော်
                                        နောက်ဆုံးလုပ်ကိုင်ခဲ့သည့်
                                        အလုပ်အကိုင်</label>
                                    <input type="text" class="form-control" name="retiredjob"
                                        formControlName="retiredjob" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="phone" class="form-label">ဖုန်းနံပါတ် <font color="red">*</font></label>
                                    <input type="text" class="form-control" name="userid" formControlName="phone"
                                        [ngClass]="{ 'is-invalid': submitted && phone.invalid }" required />
                                    <div class="invalid-feedback" *ngIf="
                            phone.invalid || ((phone.dirty || phone.touched) && submitted)">
                                        <div *ngIf="phone.errors?.required">{{ requiredMessage }}</div>
                                    </div>
                                </div>

                                <div class="col-md-12 mb-3">
                                    <label for="region" class="form-label">ပြည်နယ်/တိုင်းဒေသကြီး</label>
                                    <div class="g-2">
                                        <div class="row g-2">
                                            <div class="col-4">
                                                <select class="form-select" id="region" name="region"
                                                    formControlName="region" (change)="onRegionChange($event)">
                                                    <option value="" selected disabled>ပြည်နယ်/တိုင်းဒေသကြီး ရွေးပါ
                                                    </option>
                                                    <option *ngFor="let regionlist of regionList"
                                                        [value]="regionlist.region">
                                                        {{regionlist.region}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select class="form-select" id="town" name="town" formControlName="town"
                                                    (change)="onTownChange($event)">
                                                    <option value="" selected disabled>မြို့ ရွေးပါ</option>
                                                    <option *ngFor="let townlist of filteredTowns"
                                                        [value]="townlist.town">{{townlist.town}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <select class="form-select" id="ward" name="ward"
                                                    formControlName="ward">
                                                    <option value="" selected disabled>ရပ်ကွက်/ရွာ ရွေးပါ</option>
                                                    <option *ngFor="let wardlist of filteredWards"
                                                        [value]="wardlist.postcode">{{wardlist.ward}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="address" class="form-label">နေရပ်လိပ်စာ</label>
                                    <textarea rows="2" class="form-control" name="address"
                                        formControlName="address"></textarea>
                                    <!-- <input type="text" class="form-control" name="address" formControlName="address" /> -->
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="current_address" class="form-label">လက်ရှိနေရပ်လိပ်စာ</label>
                                    <textarea rows="2" class="form-control" name="current_address"
                                        formControlName="current_address"></textarea>
                                    <!-- <input type="text" class="form-control" name="current_address"
                                        formControlName="current_address" /> -->
                                </div>

                            </div>

                        </div>
                        <div class="col-md-2">
                            <div class="profile-img-container">
                                <div style="position: relative">
                                    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop>
                                        <img class="drag-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                                            [lazyLoad]="profileUrl ? profileUrl : '../../../assets/images/uploadphoto.png'" />
                                        <input hidden type="file" #fileInput accept="image/*"
                                            (change)="onFileSelected($event)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>

                <div class="col-md-10 mb-3" *ngIf="(status=='Edit'&&currentMember && currentMember.memberType && currentMember.memberType.membertypeid == 'ASDLFKJ') 
                || (status=='Register')">
                    <label for="birthdate" class="form-label">Referrer 1
                    </label>
                    <div class="form-control" (click)="openMemberModal(1)" style="height: 32.05px">{{referrer1_name}}
                    </div>
                </div>

                <div class="col-md-10 mb-3" *ngIf="(status=='Edit'&&currentMember && currentMember.memberType && currentMember.memberType.membertypeid == 'ASDLFKJ') 
                || (status=='Register')">
                    <label for="birthdate" class="form-label">Referrer 2
                    </label>
                    <div class="form-control" (click)="openMemberModal(2)" style="height: 32.05px">{{referrer2_name}}
                    </div>
                </div>

                <div
                    *ngIf="(status=='Edit'&&currentMember && currentMember.memberType && currentMember.memberType.membertypeid == 'ASDLFKJ') || (status=='Register')">
                    <div class="col-md-10 mb-4">
                        <label for="attachment1" class="form-label">Marriage Certification</label>
                        <div class="input-group" *ngIf="!isAttachment1Editing">
                            <input class="form-control" type="file" (change)="onAttachment1Selected($event)"
                                name="attachment1" accept="image/*,.docx,application/pdf,application/vnd.ms-powerpoint"
                                id="formFile" #fileInput1
                                [ngClass]="{ 'is-invalid': isAttachment1Required && !attachment1Selected}">
                            <!-- <button *ngIf="attachment1Selected" class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="removeAttachement(1)">Remove</button> -->
                            <!-- <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                          *ngIf="isFileChange && isEditing && !noFile" (click)="isFileChange = false">Cancel</button> -->
                        </div>
                        <div class="input-group" *ngIf="isAttachment1Editing">
                            <div class="form-control" readonly aria-describedby="btnGroupAddon2">
                                {{attachement1filename}}
                            </div>
                            <!-- <button class="input-group-text btn btn-custom" id="btnGroupAddon2">Change</button> -->
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="attachement1filename.split('.').pop() != 'jpg' &&
                                    attachement1filename.split('.').pop() !='png' &&
                                    attachement1filename.split('.').pop() !='jpeg'" style="border-right: 3px solid;">
                                <span *ngIf="!att1downloading" (click)="downloadAttachment(0)">View</span>

                            </button>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="isAttachment1Editing = false;removeAttachement(0)">Remove</button>
                        </div>

                        <div *ngIf="isAttachment1Editing && (attachement1filename.split('.').pop() == 'jpg' ||
                        attachement1filename.split('.').pop() =='png' ||
                        attachement1filename.split('.').pop() =='jpeg')">
                            <img width="50" height="50" [src]="selectedAttachments[0].fileurl" data-bs-toggle="modal"
                                data-bs-target="#comment-image-viewer"
                                (click)="viewImage(selectedAttachments[0].fileurl)" style="cursor: pointer" />
                        </div>

                        <div *ngIf="isAttachment1Required && !attachment1Selected" class="text-danger">
                            Please upload a file for Marriage Certification.
                        </div>

                    </div>

                    <div class="col-md-10 mb-4">
                        <label for="attachment2" class="form-label">Birth Certification</label>
                        <div class="input-group" *ngIf="!isAttachment2Editing">
                            <input class="form-control" type="file" (change)="onAttachment2Selected($event)"
                                name="attachment2" accept="image/*,.docx,application/pdf,application/vnd.ms-powerpoint"
                                id="formFile" #fileInput2
                                [ngClass]="{ 'is-invalid': isAttachment2Required && !attachment2Selected}">
                            <!-- <button *ngIf="attachment2Selected" class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="removeAttachment2()">Remove</button> -->
                            <!-- <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                          *ngIf="isFileChange && isEditing && !noFile" (click)="isFileChange = false">Cancel</button> -->
                        </div>

                        <div class="input-group" *ngIf="isAttachment2Editing">
                            <div class="form-control" readonly aria-describedby="btnGroupAddon2">
                                {{attachement2filename}}
                            </div>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="attachement2filename.split('.').pop() != 'jpg' &&
                                    attachement2filename.split('.').pop() !='png' &&
                                    attachement2filename.split('.').pop() !='jpeg'" style="border-right: 3px solid;">
                                <span *ngIf="!att2downloading" (click)="downloadAttachment(1)">View</span>

                            </button>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="isAttachment2Editing = false;removeAttachement(1)">Remove</button>
                        </div>
                        <div *ngIf="isAttachment2Editing && (attachement2filename.split('.').pop() == 'jpg' ||
                        attachement2filename.split('.').pop() =='png' ||
                        attachement2filename.split('.').pop() =='jpeg')">
                            <img width="50" height="50" [src]="selectedAttachments[1].fileurl" data-bs-toggle="modal"
                                data-bs-target="#comment-image-viewer"
                                (click)="viewImage(selectedAttachments[1].fileurl)" style="cursor: pointer" />
                        </div>
                        <div *ngIf="isAttachment2Required && !attachment2Selected" class="text-danger">
                            Please upload a file for Birth Certification.
                        </div>
                    </div>

                    <div class="col-md-10 mb-4">
                        <label for="attachment3" class="form-label">Family Census</label>
                        <div class="input-group" *ngIf="!isAttachment3Editing">
                            <input class="form-control" type="file" (change)="onAttachment3Selected($event)"
                                name="attachment3" accept="image/*,.docx,application/pdf,application/vnd.ms-powerpoint"
                                id="formFile" #fileInput3
                                [ngClass]="{ 'is-invalid': isAttachment3Required && !attachment3Selected}">
                            <!-- <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                          *ngIf="isFileChange && isEditing && !noFile" (click)="isFileChange = false">Cancel</button> -->
                        </div>
                        <div class="input-group" *ngIf="isAttachment3Editing">
                            <div class="form-control" readonly aria-describedby="btnGroupAddon2">
                                {{attachement3filename}}
                            </div>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="attachement3filename.split('.').pop() != 'jpg' &&
                                    attachement3filename.split('.').pop() !='png' &&
                                    attachement3filename.split('.').pop() !='jpeg'" style="border-right: 3px solid;">
                                <span *ngIf="!att3downloading" (click)="downloadAttachment(2)">View</span>

                            </button>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="isAttachment3Editing = false; removeAttachement(2)">Remove</button>
                        </div>
                        <div *ngIf="isAttachment3Editing && (attachement3filename.split('.').pop() == 'jpg' ||
                        attachement3filename.split('.').pop() =='png' ||
                        attachement3filename.split('.').pop() =='jpeg')">
                            <img width="50" height="50" [src]="selectedAttachments[2].fileurl" data-bs-toggle="modal"
                                data-bs-target="#comment-image-viewer"
                                (click)="viewImage(selectedAttachments[2].fileurl)" style="cursor: pointer" />
                        </div>
                        <div *ngIf="isAttachment3Required && !attachment3Selected" class="text-danger">
                            Please upload a file for Family Census.
                        </div>
                    </div>

                    <div class="col-md-10 mb-4">
                        <label for="attachment4" class="form-label">Photo1</label>
                        <div class="input-group" *ngIf="!isAttachment4Editing">
                            <input class="form-control" type="file" (change)="onAttachment4Selected($event)"
                                name="attachment4" accept="image/*,.docx,application/pdf,application/vnd.ms-powerpoint"
                                id="formFile" #fileInput4
                                [ngClass]="{ 'is-invalid': isAttachment4Required && !attachment4Selected}">
                            <!-- <button *ngIf="attachment4Selected" class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="removeAttachment4()">Remove</button> -->
                            <!-- <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                          *ngIf="isFileChange && isEditing && !noFile" (click)="isFileChange = false">Cancel</button> -->
                        </div>
                        <div class="input-group" *ngIf="isAttachment4Editing">
                            <div class="form-control" readonly aria-describedby="btnGroupAddon2">
                                {{attachement4filename}}
                            </div>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="attachement4filename.split('.').pop() != 'jpg' &&
                                    attachement4filename.split('.').pop() !='png' &&
                                    attachement4filename.split('.').pop() !='jpeg'" style="border-right: 3px solid;">
                                <span *ngIf="!att4downloading" (click)="downloadAttachment(3)">View</span>

                            </button>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="isAttachment4Editing = false; removeAttachement(3)">Remove</button>
                        </div>

                        <div *ngIf="isAttachment4Editing && (attachement4filename.split('.').pop() == 'jpg' ||
                        attachement4filename.split('.').pop() =='png' ||
                        attachement4filename.split('.').pop() =='jpeg')">
                            <img width="50" height="50" [src]="selectedAttachments[3].fileurl" data-bs-toggle="modal"
                                data-bs-target="#comment-image-viewer"
                                (click)="viewImage(selectedAttachments[3].fileurl)" style="cursor: pointer" />
                        </div>
                        <div *ngIf="isAttachment4Required && !attachment4Selected" class="text-danger">
                            Please upload a file for Photo 1.
                        </div>
                    </div>

                    <div class="col-md-10 mb-4">
                        <label for="attachment5" class="form-label">Photo2</label>
                        <div class="input-group" *ngIf="!isAttachment5Editing">
                            <input class="form-control" type="file" (change)="onAttachment5Selected($event)"
                                name="attachment5" accept="image/*,.docx,application/pdf,application/vnd.ms-powerpoint"
                                id="formFile" #fileInput5
                                [ngClass]="{ 'is-invalid': isAttachment5Required && !attachment5Selected}">
                            <!-- <button *ngIf="attachment5Selected" class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="removeAttachment5()">Remove</button> -->
                            <!-- <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                          *ngIf="isFileChange && isEditing && !noFile" (click)="isFileChange = false">Cancel</button> -->
                        </div>
                        <div class="input-group" *ngIf="isAttachment5Editing">
                            <div class="form-control" readonly aria-describedby="btnGroupAddon2">
                                {{attachement5filename}}
                            </div>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2" *ngIf="attachement5filename.split('.').pop() != 'jpg' &&
                                    attachement5filename.split('.').pop() !='png' &&
                                    attachement5filename.split('.').pop() !='jpeg'" style="border-right: 3px solid;">
                                <span *ngIf="!att5downloading" (click)="downloadAttachment(4)">View</span>

                            </button>
                            <button class="input-group-text btn btn-custom" id="btnGroupAddon2"
                                (click)="isAttachment5Editing = false; removeAttachement(4)">Remove</button>
                        </div>
                        <div *ngIf="isAttachment5Editing && (attachement5filename.split('.').pop() == 'jpg' ||
                        attachement5filename.split('.').pop() =='png' ||
                        attachement5filename.split('.').pop() =='jpeg')">
                            <img width="50" height="50" [src]="selectedAttachments[4].fileurl" data-bs-toggle="modal"
                                data-bs-target="#comment-image-viewer"
                                (click)="viewImage(selectedAttachments[4].fileurl)" style="cursor: pointer" />
                        </div>
                        <div *ngIf="isAttachment5Required && !attachment5Selected" class="text-danger">
                            Please upload a file for Photo 2.
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="mt-4">
            <div class="card">
                <div class="card-body relations-card">
                    <!-- <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
                        class="chat-container"
                         #chatPS> -->
                    <div class="d-flex gap-3 align-items-center mb-2">
                        <span style="font-size: 18px">မိသားစုအသင်းဝင်များ</span>
                    </div>
                </div>
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <th>စဥ်</th>
                            <th>အမည်</th>
                            <th>တော်စပ်ပုံ</th>
                            <th>မွေးနေ့</th>
                            <th>အလုပ်အကိုင်</th>
                            <th>ကဒ်အမျိုးအစား</th>
                        </tr>
                        <!-- <tr>
                <th>
                    ရက်
                </th>
                <th>
                    လ
                </th>
                <th>
                    ခုနှစ်
                </th>
            </tr> -->
                    </thead>
                    <tbody *ngIf="relations.length == 0 ">
                        <tr>
                            <td colspan="6" class="nodata">
                                Empty
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="relations.length > 0">
                        <tr *ngFor="let person of relations; index as i; last as l" style="cursor: pointer;">
                            <td (click)="editBmember(i)">
                                {{ i + 1 }}
                            </td>
                            <td (click)="editBmember(i)">
                                {{ person.name }}
                            </td>
                            <td (click)="editBmember(i)">
                                {{ person.relativeType.name }}
                            </td>
                            <td (click)="editBmember(i)">
                                {{ allInOneservice.formatDBToShow(person.birthdate) }}
                            </td>
                            <td (click)="editBmember(i)">
                                {{ person.currentjob }}
                            </td>
                            <td (click)="editBmember(i)">
                                {{ person.memberType.name }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <app-b-type-member-register *ngIf="status == 'Add Relative'" [currentMember]="currentMember"
        [status]="'Add Relative'" (closeFormHandler)="closeByRelativeForm()">

    </app-b-type-member-register>

    <app-b-type-member-register *ngIf="status == 'Edit Relative'" [currentMember]="currentMember"
        [status]="'Edit Relative'" (closeFormHandler)="closeByRelativeForm()" [relativeInfo]="bmemberInfoToEdit">

    </app-b-type-member-register>
</div>

<div class="modal" id="comment-image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-more-container">
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close"
                        (click)="downloadImage()">
                        <mat-icon>download</mat-icon>
                    </button>
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="modal-body">
                <div>
                    <img [src]="previewurl" alt="" class="image-view" />
                </div>
            </div>
        </div>
    </div>
</div>