<div class='container-fluid'>
  <div class="content">

    <!-- <ng-container *ngIf="isAdmin">
      <app-team-breadcrumb s_breadcrumb="Working Hour">
      </app-team-breadcrumb>
    </ng-container>

    <ng-container *ngIf="!isAdmin">
      <app-office-breadcrumb [s_breadcrumb]="'Working Hour'">
      </app-office-breadcrumb>
    </ng-container> -->

    <app-team-breadcrumb s_breadcrumb="Working Hour">
    </app-team-breadcrumb>

    <ng-container *ngIf="gettingWorkingHour">
      <!-- <div class="nodata">
        <div class="spinner-border" entitle="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div> -->
      <div *ngFor="let i of [1,2,3,4,5,6,7]">
        <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
          'height.px': 60}"></ngx-skeleton-loader>
      </div>
    </ng-container>

    <ng-container *ngIf="!gettingWorkingHour">
      <div class="d-flex">
        <app-button-loader (click)="saveWorkingHour()" [button_text]="'Save'" [load_button_text]="'Saving'"
          [isLoading]="isLoading">
        </app-button-loader>
      </div>
      <div class="row mt-3">
        <ng-container *ngFor="let wh of whlist">
          <div class="col-md-12 mb-3">
            <div class="day-container">
              <div class="row w-100">
                <div class="col-md-3" [ngClass]="{'red-checkbox': wh.day == 'Sunday' || wh.day == 'Saturday'}">
                  <div class="form-check ">
                    <input class="form-check-input" type="checkbox" [id]="wh.day" [name]="wh.day"
                      [(ngModel)]="wh.checked">
                    <label class="form-check-label" [for]="wh.day">
                      {{wh.day}}
                    </label>
                  </div>
                </div>
                <ng-container *ngIf="wh.checked">
                  <div class="col-md-3">
                    <label class="form-label">Start Time</label>
                    <input type="time" class="form-control" [(ngModel)]="wh.starttime" step="1" />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">End Time</label>
                    <input type="time" class="form-control" [(ngModel)]="wh.endtime" step="1" />
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </ng-container>
  </div>
</div>