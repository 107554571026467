// import { AllInOneService } from './../../shared/services/all-in-one.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { KunyekService } from './../../shared/services/kunyek.service';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MessageService } from '../../shared/services/message.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
declare var $: any;

@Component({
  selector: 'app-like-tag-list',
  templateUrl: './like-tag-list.component.html',
  styleUrls: ['./like-tag-list.component.scss']
})
export class LikeTagListComponent implements OnInit {
  scrollableTabs: any[] = Array.from({ length: 50 }, (_, i) => ({ title: `Tab ${i + 1}`, content: `Tab ${i + 1} Content` }));
  gettingReact: boolean = false;
  userList: any = [];
  react: boolean = false;
  user: any;
  reactForm = {
    "domain": this.allinoneService.getDomain().shortcode,
    "domainid": this.allinoneService.getDomain().domainid,
    "postsyskey": "",
    "commentsyskey":"",
    "imagename": "",
  }
  
  isFromMessage: boolean = false
  isFromVote: boolean = false
  imagename: any;

  isFromResponse: boolean = false
  _isFromMessage: boolean = false
  _isFromVote: boolean = false
  _isFromResponse: boolean = false
  _react:boolean =false
  _commentreact:boolean=false

  isFromMessageSeen: boolean = false
  _isFromMessageSeen: boolean = false
  
  title:string="";

  commentreact=false;

  error: boolean = false
  viewType: any = "0"
  uid: any
  isBlocking: boolean = false
  userlistAll:any=[];
  isviewProfile : boolean = false;
  constructor(
    private kunyekService: KunyekService,
    public messageService: MessageService,
    public allinoneService: AllInOneService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserProfileComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.isFromMessage == true) {
      this.isFromMessage = true
      this._isFromMessage = true

      console.log("Data");
      console.log(data.list);
      data.list.map((user: any) => {
        this.userList.push({
          imagename: user.imagename == "" ? '../../../assets/images/profile.png' : user.imagename,
          username: user.username,
          userid: user.userid,
          type: user.type

        })
      })
    
    }
    else if ( data.type == "commentreacts") {
      this.reactForm.postsyskey = data.syskey;
      this.reactForm.commentsyskey = data.commentsyskey;



      this.commentreact = data.type == "commentreacts" ? true : false;
      this._commentreact = data.type == "commentreacts" ? true : false;

      this.GetCommentReact();

    }
    else if (data.isFromVote == true) {
      this.isFromVote = true
      this._isFromVote = true
      const code=data.code;


      var param_data = {
        "domain": this.reactForm.domain,
        "domainid": this.reactForm.domainid,
        "postsyskey": data.syskey
      }
      this.gettingReact = true;
      this.kunyekService.getReacts(param_data).subscribe((res: any) => {
        if (res.returncode == "300") {
          console.log(this.gettingReact)
          var reactlist=res.reactlist;
          var res = reactlist.filter(
            (x: any) => x.code == code
          );
          console.log(res)
          if (res.length>0){
            var list=res[0].userlist;
           list.map((user: any) => {
              this.userList.push({
                imagename: user.imagename == "" ? '../../../assets/images/profile.png' : user.imagename,
                username: user.username,
                userid: user.userid,
                type: ""
      
              })
            })
          this.gettingReact = false;

          }
          this.gettingReact = false;
        }
        else {
          this.gettingReact = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
        err => {
          this.gettingReact = false;
        }
      )
    }
    else if (data.isFromResponse == true) {
      this.title=data.name;
      const code=data.code;

      this.isFromResponse = true
      this._isFromResponse = true


      var param_data = {
        "domain": this.reactForm.domain,
        "domainid": this.reactForm.domainid,
        "postsyskey": data.syskey
      }
      this.gettingReact = true;
      this.kunyekService.getReacts(param_data).subscribe((res: any) => {
        if (res.returncode == "300") {
          console.log(this.gettingReact)
          var reactlist=res.reactlist;
          var res = reactlist.filter(
            (x: any) => x.code == code
          );
          console.log(res)
          if (res.length>0){
            var list=res[0].userlist;
           list.map((user: any) => {
              this.userList.push({
                imagename: user.imagename == "" ? '../../../assets/images/profile.png' : user.imagename,
                username: user.username,
                userid: user.userid,
                type: ""
      
              })
            })
          this.gettingReact = false;

          }
          this.gettingReact = false;
        }
        else {
          this.gettingReact = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
        err => {
          this.gettingReact = false;
        }
      )

    
    }
    
    else if (data.isFromMessageSeen == true) {
      this.isFromMessageSeen = true
      this._isFromMessageSeen = true

      console.log("Data");
      console.log(data.list);
      this.userList=data.list;
      // data.list.map((user: any) => {
      //   this.userList.push({
      //     imagename: user.imagename == "" ? '../../../assets/images/profile.png' : user.imagename,
      //     username: user.username,
      //     userid: user.userid,
      //     type: user.type

      //   })
      // })
    
    }
    else {
      this.reactForm.postsyskey = data.syskey;
      if(data.imagename){
        this.reactForm.imagename = data.imagename;
      }
      // this.react=data.type;
      this.react = data.type == "reacts" ? true : false;
      this._react = data.type == "reacts" ? true : false;

      console.log(this.data)
      if (!this.react) {
        data.list.map((user: any) => {
          this.userList.push({
            imagename: user.userimage == "" ? '../../../assets/images/profile.png' : user.userimage,
            username: user.username,
            userid: user.userid,
            type: ""

          })
        })
      }
    }
  }

  ngOnInit(): void {
    if (this.react) {
      this.GetReact();

    }


  }

  GetReact() {
    var data = {
      "domain": this.reactForm.domain,
      "domainid": this.reactForm.domainid,
      "postsyskey": this.reactForm.postsyskey,
      "imagename" : this.reactForm.imagename
    }
    this.gettingReact = true;
    this.kunyekService.getReacts(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.userList=res.reactlist;
        for(var i =0;i<this.userList.length;i++){
          this.userList[i].userlist.map((user:any)=>{
            this.userlistAll.push({
                  imagename: user.imagename == "" ? '../../../assets/images/profile.png' : user.imagename,
                  username: user.username,
                  userid: user.userid,
                  id: this.userList[i].id
      
                })

          })
        }

        this.gettingReact = false;
      }
      else {
        this.gettingReact = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    },
      err => {
        this.gettingReact = false;
      }
    )
  }


  
  GetCommentReact() {
    var data = this.reactForm
    this.gettingReact = true;
    this.kunyekService.getReacts(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.userList=res.reactlist;
        console.log(this.userList)
        for(var i =0;i<this.userList.length;i++){
          this.userList[i].userlist.map((user:any)=>{
            this.userlistAll.push({
                  imagename: user.imagename == "" ? '../../../assets/images/profile.png' : user.imagename,
                  username: user.username,
                  userid: user.userid,
                  id: this.userList[i].id
      
                })

          })
        }

        this.gettingReact = false;
      }
      else {
        this.gettingReact = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    },
      err => {
        this.gettingReact = false;
      }
    )
  }

  goBack() {
    this.viewType = "0"
    if(this._isFromMessage==true){
      this.isFromMessage=true;
    }else if(this._isFromResponse==true){
      this.isFromResponse=true;
    }else if(this._isFromVote==true){
      this.isFromVote=true;
    }else if(this._react==true){
      this.react = true
      this.gettingReact = false
    } 
    else if(this._commentreact==true){
      this.react = true
      this.gettingReact = false
    } 
    else if(this._isFromMessageSeen==true){
      this.isFromMessageSeen = true
    } 
  }

  viewProfile(uid: any) {
    console.log("GC>>");
    this.react = false
    this.commentreact = false

    this.uid = uid;
    this.viewType = '1'
    this.isviewProfile = true;
    this.gettingReact = true

    const data = {
      "contact": [],
      "count": 10,
      "start": 0,
      "end": 10,
      "search": "",
      "domain": this.allinoneService.getDomain().shortcode,
      "domainid": this.allinoneService.getDomain().domainid,
      "contactid": uid 
    }
    this.kunyekService.getContactList(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.gettingReact = false
        this.user = res;
        console.log(res);

      }
      else {
        // this.gettingContactList = false
        // this.gettingMoreContact = false; 
        this.gettingReact = false
        this.error = true

        this.messageService.openSnackBar(res.message, 'warn');
      }

    },
      err => {
        this.gettingReact = false
        this.error = true
        // this.gettingContactList = false
        // this.gettingMoreContact = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    )
  }

  blockUser() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.isBlocking = true
        this.dialogRef.disableClose = true
        const data = {
          "domain": this.allinoneService.getDomain().shortcode,
          "domainid": this.allinoneService.getDomain().domainid,
          "date": this.allinoneService.getCurrentDateToDB(),
          "time": this.allinoneService.getCurrentTimeToDB(),
          "contact": this.user.userid
        }
        this.kunyekService.blockUser(data).subscribe((res: any) => {
          if (res.returncode == "300") {
            // this.dialogRef.disableClose = false
            this.isBlocking = false
            this.dialogRef.close(true)
          }
          else {
            this.messageService.openSnackBar(res.message, 'warn')
            this.isBlocking = false
          }
        },
          err => {
            this.isBlocking = false
          }
        )
      }
    });
  }

  addContact() {
    this.allinoneService.isLoading = true
    const data = {
      "domain": this.allinoneService.getDomain().shortcode,
      "domainid": this.allinoneService.getDomain().domainid,
      "recordstatus": "1",
      "contact": [this.user.userid],
      "count": 0
    }

    this.kunyekService.addContact(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        // this.getMemberList()
        this.user.friend = 'true'
        this.allinoneService.isLoading = false
      }
      else {
        this.allinoneService.isLoading = true
        this.messageService.openSnackBar(res.message, 'warn');
      }
    },
      err => {
        this.allinoneService.isLoading = true
      }
    )
  }
  imageViewer(user:any) {
   
    console.log('ok')
    if(user.image!=""){  
      this.imagename=user.imagename;
      $('#profile-image-viewer').modal('show');
    }
  }
  CloseImageViewer(){

    this.imagename=""
    $('#profile-image-viewer').modal('hide');



  }
  downloadImage() {
    window.open(this.imagename, '_blank');
  }
  getImgSrc(htmlString: any) {
    var element = <HTMLImageElement>htmlString;
    return element.src;
  }

}
