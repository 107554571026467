import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getReactCount',
  pure:false
})
export class GetReactCountPipe implements PipeTransform {

  transform(type: string, reactlist : any){
    var count = 0
    reactlist.map((item: any) => {
      if (item.type == type) {
        count++
      }
    })
    return count;
  }

}
