<div class="d-flex align-items-center justify-content-between mb-3">
    <div>
        <h3 class="mb-0">Filter</h3>
    </div>
    <div>
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div class="row">
    <div *ngIf="isApproval" class="mb-3 col-md-12">
        <label for="requeststatus" class="form-label">Status</label>
        <select class="form-select" [(ngModel)]="searchForm.requeststatus" id="requeststatus" name="requeststatus">
            <option *ngFor="let item of approvalStatusList" [value]="item.id">
                {{item.name}}
            </option>
        </select>
    </div>
    <div *ngIf="!isApproval" class="mb-3 col-md-12">
        <label for="requeststatus" class="form-label">Status</label>
        
        <select class="form-select" [(ngModel)]="searchForm.requeststatus" id="requeststatus" name="requeststatus">
            <option *ngFor="let item of requestStatusList" [value]="item.id">
                {{item.name}}
            </option>
        </select>
    </div>
    <div class="mb-3 col-md-6">
        <label for="startdate" class="form-label">
            Start Date
        </label>
        <div class="input-group">
            <input type="text" id='startdate' name='startdate' class="form-control datepicker-readonly"
                placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" placement="right"
                [bsValue]="searchForm.startdate" [(ngModel)]="searchForm.startdate" readonly
                [bsConfig]="{showClearButton: true}">
        </div>
    </div>
    <div class="mb-3 col-md-6">
        <label for="enddate" class="form-label">
            End Date
        </label>
        <div class="input-group">
            <input type="text" id='startdate' name='startdate' class="form-control datepicker-readonly"
                placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" placement="left"
                [bsValue]="searchForm.enddate" [(ngModel)]="searchForm.enddate" readonly
                [bsConfig]="{showClearButton: true}" [minDate]="searchForm.startdate">
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button type="button" class="btn btn-outline-secondary me-2" mat-dialog-close>
      Cancel
    </button>
    <app-button-loader (click)="search()" [button_text]=" 'Search'" [load_button_text]=" ''"
      ></app-button-loader>
  </div>