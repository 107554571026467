import { KunyekService } from './../../shared/services/kunyek.service';
import { AllInOneService } from './../../shared/services/all-in-one.service';
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-check-in-members',
  templateUrl: './check-in-members.component.html',
  styleUrls: ['./check-in-members.component.scss']
})
export class CheckInMembersComponent implements OnInit {

  dateArray = [""];
  dayNames = [
    "Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"
  ]
  dataList: any;
  atFormSubmitted = false;
  searchLoading = false;

  sortedArray: any = [];
  organizations: any = []


  d = new Date();
  month = (this.d.getMonth() + 1).toString().padStart(2, '0');
  day = this.d.getDate().toString().padStart(2, '0');
  todayDate = "" + this.d.getFullYear() + this.month + this.day;
  //for filter date
  aweekBefore = new Date(this.d).setDate(new Date(this.d).getDate() - 7);
  aweekBeforeDate = new Date(this.aweekBefore);
  checkInStartDate = this.aweekBeforeDate.getFullYear() + (this.aweekBeforeDate.getMonth() + 1).toString().padStart(2, '0') + this.aweekBeforeDate.getDate().toString().padStart(2, '0') + ""
  checkInEndDate = this.d.getFullYear() + this.month + this.day + "";
  //show today date
  showTodayDate = this.day + "/" + this.month + "/" + this.d.getFullYear() + " " + this.dayNames[this.d.getDay()];

  formatToShow = 'yyyy-MM-dd'
  dateFormat = 'yyyyMMdd';
  timeFormat = 'hh:mm:ss';
  apitimeFormat = 'hh:mm:ss aa';


  currentEndDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString()
  currentStartDate = formatDate(new Date(this.aweekBefore), this.formatToShow, 'en-US').toString()

  atFormDate = formatDate(Date.now(), this.formatToShow, 'en-US').toString()
  atFormStartTime = formatDate(Date.now(), this.timeFormat, 'en-US').toString()
  atFormEndTime = formatDate(new Date(this.d).setHours(new Date(this.d).getHours() + 1), this.timeFormat, 'en-US').toString()

  getcheckinForm = {
    "syskey": "",
    "usersk": this.allinoneService.getProfile().syskey,
    "startdate": this.currentStartDate,
    "enddate": this.currentEndDate,
    "domain": this.allinoneService.getDomain().shortcode,
    "domainid": this.allinoneService.getDomain().domainid,
    "org": "",
    "userid": "",
    "atoken": "",
    "appid": "kunyek"
  }

  selectedSyskey = "";

  constructor(private service: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.getcheckinForm.org = this.allinoneService.selectedOrg ? this.allinoneService.selectedOrg : this.organizations[0].orgid
  }

  orgChanged(event: any) {
    this.getcheckinForm.org = event.target.value
    this.allinoneService.selectedOrg = this.getcheckinForm.org
  }

  hide(j:any,x: any) {
    var index = x;
    this.sortedArray[j].datalist[index].show = this.sortedArray[j].datalist[index].show ? false : true;

  }

  hideMemberCheckIn(j : any){
    this.sortedArray[j].show = !this.sortedArray[j].show
    if(this.sortedArray[j].show == false){
      this.sortedArray[j].datalist.map((item: any) => {
        item.show = false
      })
    }
  }

  getCheckInList() {
    this.searchLoading = true

    var domain = this.allinoneService.getDomain()

    var startd = formatDate(new Date(this.getcheckinForm.startdate), this.dateFormat, 'en-US').toString()
    var endd = formatDate(new Date(this.getcheckinForm.enddate), this.dateFormat, 'en-US').toString()
    if (new Date(this.getcheckinForm.startdate) > new Date(this.getcheckinForm.enddate)) {
      this.messageService.openSnackBar("Please select the valid end date!", 'warn');
      this.searchLoading = false;
      return
    }
    var data = { 
     "startdate": startd, 
     "enddate": endd, 
     "domain": domain.shortcode,
    "domainid": domain.domainid,
    "searchuser":this.getcheckinForm.userid != "" ? this.allinoneService.checkUserId(this.getcheckinForm.userid) : "",
    "orgid":this.getcheckinForm.org

    }
    this.service.getAdminCheckInList(data).subscribe((res) => {
    this.sortedArray = [];

      var d: any = res;
      console.log(d);
      if (d.returncode == "300") {
        this.dataList = d.list.reverse();
        if (this.dataList.length == 0) {
          this.searchLoading = false;
        }
        var userList:any=[];
        for (var user = 0; user < this.dataList.length; user++) {
          var checkUserData: any = userList.filter((a: any) => a.userid == this.dataList[user].userid)

          if(checkUserData.length==0){
            userList.push({
              "userid":this.dataList[user].userid,
              "username":this.dataList[user].username,
            })




          }
        }
        userList=userList.sort();
        for(var x=0;x<userList.length;x++){
        var namedCheckInarray=[];
        this.dateArray = [];


          var userCheckInDataList=this.dataList.filter((a: any) => a.userid == userList[x].userid)

          for (var i = 0; i < userCheckInDataList.length; i++) {
            var resc: any = this.dateArray.filter((x) => x == userCheckInDataList[i].date);
            console.log(resc.length)
  
            if (resc.length == 0) {
              this.dateArray.push(userCheckInDataList[i].date);
              var ddate = userCheckInDataList[i].date;
              var y = ddate.slice(0, 4);
              var m = ddate.slice(4, 6);
              var day = ddate.slice(6, 9);
              var sd = new Date(y + "-" + m + "-" + day);
              var cdata: any = userCheckInDataList.filter((a: any) => a.date == userCheckInDataList[i].date)
              var obj = {
                "date": userCheckInDataList[i].date,
                "showdate": day + "/" + m + "/" + y + " " + this.dayNames[sd.getDay()],
                "data": cdata,
                "show": true
              }
              namedCheckInarray.push(obj)
              namedCheckInarray.sort(
                // (a:any,b:any)=>parseInt(a.date)<parseInt(b.date)
                (a: any, b: any) => {
                  { let value = parseInt(b.date) - parseInt(a.date); return value }
                }
              );
            }
  
          }

          this.sortedArray.push({
            "userid":userList[x].userid,
            "username":userList[x].username,
            "datalist":namedCheckInarray,
            "show" : true
          })


          this.searchLoading = false;

        }




        
        console.log(this.sortedArray)
        // alert(this.dataList+"");
      } else {
        this.messageService.openSnackBar(res.message, 'warn');
        this.searchLoading = false;
        this.atFormSubmitted = false;
      }
    }, err => {
      this.searchLoading = false;
      this.atFormSubmitted = false;
    }
    )
  }

  downloadSheet() {

    var exdata: any = [];
    var name = "MembersSheet.xlsx"
    var startd = formatDate(new Date(this.getcheckinForm.startdate), this.formatToShow, 'en-US').toString()
    var endd = formatDate(new Date(this.getcheckinForm.enddate), this.formatToShow, 'en-US').toString()
    this.sortedArray.map((user: any) => {
    user.datalist.map((date: any) => {
      date.data.map((cidata: any) => {
        exdata.push({
          "Date Time":date.showdate+" "+cidata.starttime,
          "UserID": user.userid,
          "UseName": user.username,
          "Type":cidata.iotype=="in"?"Time In":cidata.iotype=="out"? "Time Out":cidata.iotype=="checkin"?"Check In":cidata.iotype=="activity"?"Activity":"Event" ,
          "Location Name": cidata.location,
          "Location LatLong":cidata.lat+","+cidata.long,
          "Hours": cidata.iotype=="out" && cidata.timeoutduration!="" ?this.calDuration(cidata.timeoutduration):cidata.child.length>0?this.calEndTime(cidata.date,cidata.starttime,cidata.child[0].date,cidata.child[0].starttime):"",
          "Description": cidata.description,
          "TaskID":cidata.autoid,
          "Task Descripton": cidata.taskdescription
        })
        name=cidata.orgname+"("+ startd+" - "+endd +").xlsx"  
       

      })
    })
    
    });
    this.allinoneService.exportEcecl(exdata, name)
  }
  search() {
    this.searchLoading = true;
    this.getCheckInList();

  }

  changeFormat(timeStr: any) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes, second] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}:${second}`;
  };
  

  calEndTime(date: any, starttime: any, childdate: any, childstarttime: any) {
    var format = "yyyy/MM/dd hh:mm:ss a";
    var datetime = date
    var yy = datetime.slice(0, 4)
    var mm = datetime.slice(4, 6)
    var dd = datetime.slice(6, 8)
    var checkinD = yy + "/" + mm + "/" + dd + " " + starttime;

    var cdatetime = childdate
    var cyy = cdatetime.slice(0, 4)
    var cmm = cdatetime.slice(4, 6)
    var cdd = cdatetime.slice(6, 8)
    var childD = cyy + "/" + cmm + "/" + cdd + " " + childstarttime;



    let date1 = new Date(checkinD);
    let date2 = new Date(childD);
    var diffMin = this.diff_minutes(date1, date2);
    return this.transformMinute(diffMin);
  }

  calDuration(datetime: any) {
    var durationArray = datetime.split("-")
    var day = parseInt(durationArray[0])
    var hr = parseInt(durationArray[1])
    var min = parseInt(durationArray[2])


    if (day == 0) {
      if (hr == 0) {
        if (min == 0) {
          return '1 mins ';

        }
        return min + ' mins ';
      }
      if (hr == 1) {
        if (min == 1) {
          return durationArray[1] + ' hr ' + durationArray[2] + ' min ';

        }
        return durationArray[1] + ' hr ' + durationArray[2] + ' mins ';

      }
      return durationArray[1] + ' hrs ' + durationArray[2] + ' mins ';


    } else {
      if (hr == 0) {
        if (day == 1) {
          if (min == 1) {
            return durationArray[0] + " day " + durationArray[2] + ' min ';

          }
          return durationArray[0] + " day " + durationArray[2] + ' mins ';

        }
        return durationArray[0] + " days " + durationArray[2] + ' mins ';

      } else if (hr == 1) {
        if (day == 1) {
          if (min == 1) {
            return durationArray[0] + " day " + durationArray[1] + ' hr ' + durationArray[2] + ' min';

          }
          return durationArray[0] + " day " + durationArray[1] + ' hr ' + durationArray[2] + ' mins ';

        }
        return durationArray[0] + " days " + durationArray[1] + ' hr ' + durationArray[2] + ' mins ';


      }

      return durationArray[0] + " day " + durationArray[1] + ' hr ' + durationArray[2] + ' mins ';

    }


  }

  diff_minutes(dt2: any, dt1: any) {

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

  }
  transformMinute(value: number): string {
    var hours = Math.floor(value / 60);
    let minutes = Math.floor(value % 60);
    var day = 0;
    if (hours == 0) {
      return minutes + ' mins';

    }
    else if (hours == 1) {
      return hours + ' hr ' + minutes + ' mins ';

    }
    else if (hours == 24) {
      day = Math.floor(hours / 24)
      hours = Math.floor(hours % 24)
      if (hours == 0) {
        return day + ' days ' + minutes + ' mins ';

      }
      return day + " day " + hours + ' hr ' + minutes + ' mins ';


    }
    else if (hours > 24) {
      day = Math.floor(hours / 24)
      hours = Math.floor(hours % 24)
      if (hours == 0) {
        return day + ' days ' + minutes + ' mins ';

      }
      return day + ' days ' + hours + ' hr ' + minutes + ' mins ';
    }

    else {
      return hours + ' hrs ' + minutes + ' mins ';

    }
  }

  deleteCheckin(id: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      width: "250px",
      panelClass: ['mat-typography'],
      closeOnNavigation: true
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {

        var data = {
          "syskey": id,
          "domain": this.allinoneService.getDomain().shortcode
        }
        this.searchLoading = true;

        // this.memberListLoading = true;
        this.service.deleteCheckin(data).subscribe((res: any) => {
          if (res.returncode == "300") {
            this.getCheckInList();
          }
          else {
            this.searchLoading = false;
            // this.memberListLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
          err => {
            this.searchLoading = false;
          })
      }
    });
  }
}
