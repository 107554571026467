import { Component, Input, OnInit } from '@angular/core';


type BreadCrumb = {name:string,url:string,isActive:boolean}
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs:BreadCrumb[] = []
  constructor() { }

  ngOnInit(): void {
  }

}
