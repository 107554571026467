import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  NgZone,
  ChangeDetectorRef,
  ElementRef,
  Renderer2,
  RendererStyleFlags2,
  OnDestroy,
} from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field/autosize';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarDirective,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { KunyekService } from '../../shared/services/kunyek.service';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { MessageService } from '../../shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CheckinEventModalComponent } from '../checkin-event-modal/checkin-event-modal.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { LikeTagListComponent } from '../like-tag-list/like-tag-list.component';
import { HttpClient } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { ChatEditComponent } from '../chat-edit/chat-edit.component';
import { Location } from '@angular/common';
import { MeetingTitleComponent } from '../meeting-title/meeting-title.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AlertBoxComponent } from 'src/app/shared/components/alert-box/alert-box.component';

declare var $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('animate', [
      // ...
      state(
        'endnew',
        style({
          opacity: 1,
          background: 'linear-gradient(120deg, #e4e6eb, #e4e6eb)',
        })
      ),
      state(
        'endpersonal',
        style({
          opacity: 1,
          background: 'linear-gradient(120deg, var(--main-color), #257287)',
        })
      ),
      state(
        'startnew',
        style({
          opacity: 1,
          background: 'linear-gradient(120deg, #e4e6eb, #e4e6eb)',
        })
      ),
      state(
        'startpersonal',
        style({
          opacity: 1,
          background: 'linear-gradient(120deg, #fffff, #257287)',
        })
      ),
      transition('startnew => endnew', [
        animate(1000, style({ background: 'grey', opacity: 1 })),
      ]),
      transition('startpersonal => endpersonal', [
        animate(1000, style({ background: 'grey', opacity: 1 })),
      ]),
    ]),
  ],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(QuillEditorComponent, { static: false })
  editor!: QuillEditorComponent;

  private chatListSocket: WebSocket | undefined;
  private chatMessageSocket: WebSocket | undefined;

  chatListConnectionid: string = '';
  chatMessageConnectionid: string = '';

  quill: any;

  subscriptions = new SubSink();

  atValues = [
    { id: 1, value: 'Fredrik Sundqvist' },
    { id: 2, value: 'Patrik Sjölin' },
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' },
  ];

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    // 'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'mention',
  ];

  isMentioning: boolean = false;

  appname: string = this.allinoneService.appname;

  modules = {
    keyboard: {
      bindings: {
        ['list autofill']: {
          prefix: /^\s*?(\d+\.|\*|\[ ?\]|\[x\])$/,
        },
      },
    },
    mention: {
      onOpen: () => {
        this.isMentioning = true;
      },
      onClose: () => {
        setTimeout(() => {
          this.isMentioning = false;
        }, 300);
      },
      defaultMenuOrientation: 'top',
      renderItem: (item: any) => {
        const img = item.img ? item.img : '../../../assets/images/profile.png';
        const check = item.username == '';
        if (check) {
          var rf =
            `
          <div class = "m-row">
            <div>
                <img class="m-profile-image"
                    src="` +
            img +
            `">
            </div>
            <div class="m-rightside">
               ` +
            item.userid +
            `
            </div>
          </div>
        `;
        } else {
          var rf =
            `
                <div class = "m-row">
                <div>
                    <img class="m-profile-image"
                        src="` +
            img +
            `">
                </div>
                <div class="m-rightside">
                    <div class="m-name">` +
            item.username +
            `</div>
                    <div class="m-id">` +
            item.userid +
            `</div>
                </div>
                </div>
              `;
        }
        return rf;
      },
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      onSelect: (item: any, insertItem: any) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
        console.log(item);
      },
      source: (searchTerm: any, renderList: any) => {
        const values = this.mentionableList;

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches: any[] = [];

          values.forEach((entry) => {
            if (
              entry.username.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1 ||
              entry.userid.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    },
    toolbar: [],
  };

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    //this.changeDetectorRef.detectChanges()
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    clearInterval(this.getChatInterval);
    clearInterval(this.getChatMsgInterval);
  }

  mentionedList: any[] = [];
  mentionableList: any[] = [];

  getChatInterval: any;

  getChatMsgInterval: any;
  openedChat: any;
  createWithThisMember: any;
  searchText = '';

  editText = '';
  editName = '';
  viewprofile: any = '';
  lastrecord: string = '';
  typeReact: boolean = false;
  reactType: any;

  chatlist: any = [];
  chatListToShow: any = [];
  membersAlreadyInGroup: any = [];

  chatLoaderArray = [1, 2, 3, 4, 5, 6, 7];
  chatLoaderArray2 = [1, 2];

  mobileViewWidth = 768;
  currentCount = 15;
  currentChatCount = 20;

  type = 1;

  isMobileView: boolean = false;
  uploading: boolean = false;

  isInfo: boolean = false;
  isContacts: boolean = false;
  gettingChatList: boolean = false;
  gettingChat: boolean = false;
  isLoadmore: boolean = false;
  gettingMoreChatList: boolean = false;
  gettingMoreChat: boolean = false;
  isFocus: boolean = false;
  editLoading: boolean = false;
  msgEditing: boolean = false;

  isFirstTime: boolean = true;
  sending: boolean = false;
  leavingGroup: boolean = false;
  isFirstTimeChat: boolean = true;
  isGroupChatCreate: boolean = false;
  isCreatingGroup: boolean = false;
  createGroupLoading: boolean = false;
  isGroupCreateFromInfo: boolean = false;
  isAddMember: boolean = false;
  isIdle: boolean = false;

  isfinishsubscribeChatlist: boolean = false;
  isfinishsubscribeChat: boolean = false;
  subscribeChat: any;

  fileSelected: any;
  attachFileSelected: any = '';
  attachFile: any;
  finLst: any;
  attachFileIcon: any;
  selectedImageList: any = [];
  fileIconList = [
    'fa fa-file-o', //file
    'fa fa-file-archive-o', //zip
    'fa fa-file-pdf-o', //pdf
    'fa fa-file-image-o', //image
  ];

  message: any = '';
  chatBoxHeight: string = '0';
  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
  };

  public config2: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  groupmessages: any = [];
  imageData: any;
  messageError: string = '';
  areMembersChosen: boolean = false;
  editGroupName: boolean = false;
  editingGroupName: boolean = false;

  getChatMsgSub!: Subscription;

  reply_username: any;
  reply_m: any;
  reply_userid: any;
  reply_filename: any;

  syskey: any;
  check_reply: boolean = false;
  reply_image: any;
  tab_index: any;
  imageName: any;

  count = 1;
  numberOfSeconds = 120;
  user = {
    userid: '',
    username: '',
  };

  @ViewChild(PerfectScrollbarComponent, { static: false })
  componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false })
  directiveRef?: PerfectScrollbarDirective;
  @ViewChild('chatPS') chatPS!: ElementRef;
  @ViewChild('hiddenfileinput') hiddenfileinput: any;
  @ViewChild('hiddenattachfileinput') hiddenattachfileinput: any;
  @ViewChild('chatbox') chatbox!: ElementRef;

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public changeDetectorRef: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public imageCompress: NgxImageCompressService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _idle: Idle
  ) {
    this.allinoneService.isCRMServiceView = false;
    this.user.userid = this.allinoneService.getKunyekUserId();
    this.user.username = this.allinoneService.getKunyekUserName();
  }

  ngAfterViewChecked() {
    var dia = this.dialog;
    if (document.querySelectorAll('#mmessage>.mentioned-user')) {
      var x = document.querySelectorAll('#mmessage>.mentioned-user');
      var viewProfile = (event: any, dialog = dia) => {
        return this.viewProfile(event, dialog);
      };
      for (var i = 0; i < x.length; i++) {
        if (this.viewprofile == '') {
          x[i].addEventListener('click', viewProfile, false);
        }
      }
    }
  }

  ngOnInit(): void {
    this.allinoneService.activeroute = '/message';

    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }

    // this._idle.setIdle(this.numberOfSeconds);
    // this._idle.setTimeout(this.numberOfSeconds);
    // this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    const dataFromPosts = this.route.snapshot.queryParamMap.get('d') || '';
    if (dataFromPosts) {
      const temp = localStorage.getItem('user');
      const tempdata = temp ? JSON.parse(temp) : {};
      this.openChat(tempdata);
      this.location.replaceState('/message');
      localStorage.removeItem('user');
    }

    this.getChatList();
    this.connectChatListSocket();
    // this.getChatInterval = setInterval(() => {
    //   if (
    //     !this.isfinishsubscribeChatlist &&
    //     !this.gettingMoreChatList &&
    //     !this.msgEditing
    //   ) {
    //     this.getChatListWithInterval();
    //   }
    // }, 5 * 1000);

    // this.getChatMsgInterval = setInterval(() => {
    //   if (
    //     this.openedChat &&
    //     !this.isFirstTimeChat &&
    //     !this.isfinishsubscribeChat &&
    //     !this.msgEditing
    //   ) {
    //     this.getChatMessageWithInterval(
    //       this.openedChat.channelid,
    //       this.openedChat.userid
    //     );
    //   }
    // }, 5 * 1000);

    // this._idle.onIdleStart.subscribe(() => {
    //   if (!this.isIdle) {
    //     // show the modal
    //     clearInterval(this.getChatInterval);
    //     clearInterval(this.getChatMsgInterval);
    //     this.isIdle = true;
    //     const dialog = this.dialog.open(AlertBoxComponent, {
    //       width: '400px',
    //       panelClass: ['mat-typography'],
    //       closeOnNavigation: true,
    //       data: {
    //         text: 'Are you still there?',
    //         buttonAction: () => {
    //           dialog.close();
    //         },
    //         buttonName: 'Ok',
    //       },
    //     });

    //     dialog.afterClosed().subscribe((dialogResult: any) => {
    //       this.isIdle = false;
    //       this.getChatInterval = setInterval(() => {
    //         if (
    //           !this.isfinishsubscribeChatlist &&
    //           !this.gettingMoreChatList &&
    //           !this.msgEditing
    //         ) {
    //           this.getChatListWithInterval();
    //         }
    //       }, 5 * 1000);

    //       this.getChatMsgInterval = setInterval(() => {
    //         if (
    //           this.openedChat &&
    //           !this.isFirstTimeChat &&
    //           !this.isfinishsubscribeChat &&
    //           !this.msgEditing
    //         ) {
    //           this.getChatMessageWithInterval(
    //             this.openedChat.channelid,
    //             this.openedChat.userid
    //           );
    //         }
    //       }, 5 * 1000);
    //     });
    //   }
    // });
    // this._idle.watch();
  }

  connectChatListSocket() {
    const webSocketURL = this.allinoneService.socketurl;

    this.chatListSocket = new WebSocket(webSocketURL);

    this.chatListSocket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.chatListSocket?.send('001');
    });

    this.chatListSocket.addEventListener('message', (event) => {
      console.log('Chat List Received message ----> ', event.data);
      var tempData = JSON.parse(event.data);

      if (tempData.message == '001' || tempData.message == 'Forbidden') {
        console.log('con 1');
        this.chatListConnectionid = tempData.connectionId;
        this.connectSocket(this.chatListConnectionid);
      } else {
        this.getChatListWithInterval();
      }
    });

    this.chatListSocket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
    });

    this.chatListSocket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      if (event.code != 3001) {
        this.connectChatListSocket();
      }
    });
  }

  connectChatMessageSocket() {
    const webSocketURL = this.allinoneService.socketurl;

    this.chatMessageSocket = new WebSocket(webSocketURL);

    this.chatMessageSocket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened!');
      this.chatMessageSocket?.send('001');
    });

    this.chatMessageSocket.addEventListener('message', (event) => {
      console.log('Message List Received message ------> ', event.data);
      var tempData = JSON.parse(event.data);

      if (tempData.message == '001' || tempData.message == 'Forbidden') {
        console.log('con 1');
        this.chatMessageConnectionid = tempData.connectionId;
        this.connectSocket(
          this.chatMessageConnectionid,
          '002',
          this.openedChat.channelid
        );
      } else {
        if(tempData.userid != this.allinoneService.profile.userid || (tempData.syskey != this.groupmessages[this.groupmessages.length - 1]?.syskey)){
          this.groupmessages.push(tempData);
          this.prepareChatToShow();
          this.scrollToBottom();
        }
        // this.getChatMessageWithInterval(
        //   this.openedChat.channelid,
        //   this.openedChat.userid
        // );
      }
    });

    this.chatMessageSocket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
    });

    this.chatMessageSocket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed!');
      console.log(event);
      if (event.code != 3001) {
        this.connectChatMessageSocket();
      }
    });
  }

  connectSocket(
    connectionid: string,
    type: string = '003',
    channelid: string = ''
  ) {
    const data = {
      channelid: channelid,
      connectionId: connectionid,
      type: type,
      uuid: '',
    };
    this.subscriptions.sink = this.kunyekService
      .connectChatSocket(data)
      .subscribe((res: any) => {});
  }

  viewProfile(event: any, dia: any) {
    console.log(this.count);
    this.count++;
    var userid = event.target.id;
    if (this.viewprofile != userid) {
      this.viewprofile = userid;
      const dialog = dia.open(UserProfileComponent, {
        height: '400px',
        width: '400px',
        closeOnNavigation: true,
        panelClass: ['user-profile-modal', 'mat-typography'],
        data: {
          user: [],
          userid: userid,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        this.viewprofile = '';
        console.log(this.viewprofile);
      });
    } else {
      return;
    }
  }
  showSeenUserList(seenusers: any) {
    var tempList: any[] = [];
    this.openedChat.userlist.map((user: any) => {
      seenusers.map((ruser: any) => {
        if (
          ruser == user.userid &&
          ruser != this.allinoneService.getKunyekUserId()
        ) {
          tempList.push({
            imagename: user.imagename,
            userid: user.userid,
            username: user.username,
            usersyskey: user.usersyskey,
            type: '',
          });
        }
      });
    });
    console.log('TempList =>>');
    console.log(tempList);
    //this.changeDetectorRef.detectChanges()
    //console.log(tempList)
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        isFromMessageSeen: true,
        list: tempList,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
      }
    });
  }

  ngOnDestroy() {
    this.chatListSocket?.close(3001);
    this.chatMessageSocket?.close(3001);
    this.subscriptions.unsubscribe();
    this.getChatMsgSub && this.getChatMsgSub.unsubscribe();
    this.subscribeChat && this.subscribeChat.unsubscribe();
    this.allinoneService.activeroute = '/adminconsole';
    console.log(this.getChatInterval);
    clearInterval(this.getChatInterval);
    clearInterval(this.getChatMsgInterval);
    this._idle.stop();
  }

  reply(data: any) {
    this.check_reply = true;
    this.reply_username = this.getChatProfile(data.userid).username
      ? this.getChatProfile(data.userid).username
      : this.getChatProfile(data.userid).userid;
    for (let m = 0; m < this.groupmessages.length; m++) {
      this.groupmessages[m].reply = false;
    }
    this.reply_m = data.notimessage;
    this.reply_image = data.imagelist[0] ? data.imagelist[0].imageurl : '';
    this.reply_userid = data.userid;
    this.reply_filename = data.filename;
    this.syskey = data.syskey;

    this.keyPress();
  }

  clear_reply() {
    this.check_reply = false;
    this.message = '';
    for (let m = 0; m < this.groupmessages.length; m++) {
      this.groupmessages[m].reply = false;
    }

    this.keyPress();
  }

  ReplyMessage(msg: any) {
    this.tab_index = '';
    for (let m = 0; m < this.groupmessages.length; m++) {
      if (msg.replysyskey == this.groupmessages[m].syskey) {
        this.tab_index = m++;
        setTimeout(() => {
          document.getElementById(this.tab_index)?.focus();
          //this.changeDetectorRef.detectChanges();
          setTimeout(() => {
            this.tab_index = null;
            //this.changeDetectorRef.detectChanges();
          }, 2000);
        }, 100);
      }
    }
  }
  ToReplyMessage() {
    this.tab_index = '';
    for (let m = 0; m < this.groupmessages.length; m++) {
      if (this.syskey == this.groupmessages[m].syskey) {
        this.tab_index = m++;
        setTimeout(() => {
          document.getElementById(this.tab_index)?.focus();
          //this.changeDetectorRef.detectChanges();
          setTimeout(() => {
            this.tab_index = null;
            //this.changeDetectorRef.detectChanges();
          }, 2000);
        }, 100);
      }
    }
    //this.changeDetectorRef.detectChanges();
  }

  goToContacts(type: any) {
    if (type == 'private') {
      this.isGroupChatCreate = false;
      this.areMembersChosen = false;
      this.createWithThisMember = '';
      //this.changeDetectorRef.detectChanges();
    } else {
      this.isGroupChatCreate = true;
      this.areMembersChosen = false;
      this.isAddMember = false;
      this.membersAlreadyInGroup = [];
      this.createWithThisMember = '';
      //this.changeDetectorRef.detectChanges();
    }
    this.isContacts = true;
    //this.changeDetectorRef.detectChanges();
  }

  getChatProfile(userid: string) {
    // console.warn('Profile - ' + userid)
    var user: any;
    if (this.openedChat.type != '3') {
      const index = this.openedChat.userlist.findIndex(
        (u: any) => u.userid == userid
      );
      if (index == -1) {
        user = {
          userid: '',
          username: 'Unknown',
          imagename: '',
          usersyskey: '',
          position: '',
          department: '',
        };
      } else {
        user = this.openedChat.userlist[index];
      }
    } else {
      user = this.openedChat;
    }

    return user;
  }

  keyPress() {
    setTimeout(() => {
      if (this.chatBoxHeight != this.chatbox.nativeElement.clientHeight) {
        this.chatBoxHeight = this.chatbox.nativeElement.clientHeight;
        const height = this.chatbox.nativeElement.clientHeight + 'px';
        this.renderer.setStyle(
          this.chatPS.nativeElement,
          'height',
          `calc((100vh - (115px + ${height})))`,
          RendererStyleFlags2.Important
        );
      }
      this.messageError = '';
    }, 500);
    //this.changeDetectorRef.detectChanges()
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      this.chatPS.nativeElement.scrollTop =
        this.chatPS.nativeElement.scrollHeight;
    }, 500);
  }

  onScrollEvent(event: any) {}

  onScrollDown() {
    // if (this.chatlist.length == this.currentCount) {
    this.currentCount = this.currentCount + 10;
    this.getMoreChatList(this.currentCount);
    // }
  }

  showDate(msg: any) {
    if (!msg.isGroupDate) {
      msg.showDate = !msg.showDate;
    }
  }

  openChatInfo() {
    this.cancelGroupNameEdit();
    this.isInfo = true;
    //this.changeDetectorRef.detectChanges();
  }

  openChat(item: any) {
    this.chatMessageSocket?.close(3001);
    this.searchText = '';
    this.mentionableList = [];
    this.message = '';
    this.cancelGroupNameEdit();
    item.seen = 'True';
    this.openedChat = item;
    this.isInfo = false;
    this.isContacts = false;
    this.isFirstTimeChat = true;
    this.check_reply = false;
    this.currentChatCount = 20;
    this.groupmessages = [];
    this.selectedImageList = [];
    this.attachFileSelected = '';
    this.lastrecord = '';
    this.attachFile = '';
    //this.changeDetectorRef.detectChanges();
    this.keyPress();
    if (this.subscribeChat) {
      this.subscribeChat.unsubscribe();
    }
    if (item.type == '3') {
      this.openedChat.userlist = [
        this.allinoneService.profile.userid,
        item.userid,
      ];
      this.getChat(item.channelid, item.userid, item.type);
    } else {
      this.getChat(item.channelid, item.userlist[0].userid, item.type);
      this.connectChatMessageSocket();
    }

    if (this.openedChat.type == '2') {
      this.openedChat.userlist.map((user: any) => {
        if (
          user.userid != this.allinoneService.getKunyekUserId() &&
          user.block != 'true'
        ) {
          const temp = {
            id: user.userid,
            value: user.username ? user.username : user.userid,
            userid: user.userid,
            username: user.username,
            img: user.imagename,
          };
          this.mentionableList.push(temp);
        }
      });
    }

    // this.message.insertItem(this.mentionableList[0],true)

    if (this.chatPS) {
      this.chatPS.nativeElement.scrollTop = 0;
    }
    console.log('opened chat id => ');
    console.log(this.openedChat.channelid);
    console.log('opened chat id => ', this.openedChat.userlist);
    //this.changeDetectorRef.detectChanges();
  }

  openContact() {
    this.isGroupChatCreate = false;
    this.isContacts = true;
    //this.changeDetectorRef.detectChanges();
  }
  creatingGroup() {
    this.isCreatingGroup = true;
    this.areMembersChosen = true;
    //this.changeDetectorRef.detectChanges();
  }

  leftBack() {
    if (this.isCreatingGroup) {
      this.isCreatingGroup = false;
      this.areMembersChosen = false;
      this.isContacts = true;
      //this.changeDetectorRef.detectChanges();
    } else {
      this.isGroupChatCreate = false;
      this.areMembersChosen = false;
      this.isGroupCreateFromInfo = false;
      this.isContacts = false;
      this.isAddMember = false;
      this.membersAlreadyInGroup = [];
      //this.changeDetectorRef.detectChanges();
    }
  }

  back() {
    if (this.isInfo) {
      this.isInfo = false;
      //this.changeDetectorRef.detectChanges();
    } else {
      this.openedChat = '';
      //this.changeDetectorRef.detectChanges();
    }
  }

  createGroupDone(channelid: string) {
    this.createGroupLoading = true;
    const data = {
      contact: '',
      count: this.currentCount,
      channelid: '',
      domain: this.allinoneService.getDomain().shortcode,
    };
    this.subscriptions.sink = this.kunyekService.getChatList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.chatlist = res.list.reverse();
          this.chatListToShow = this.chatlist;
          this.isContacts = false;
          this.isCreatingGroup = false;
          this.isGroupChatCreate = false;
          this.createGroupLoading = false;
          for (let i = 0; i < this.chatListToShow.length; i++) {
            if (this.chatListToShow[i].channelid == channelid) {
              this.openChat(this.chatListToShow[i]);
            }
          }
        } else {
          this.isContacts = false;
          this.isCreatingGroup = false;
          this.isGroupChatCreate = false;
          this.createGroupLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.isContacts = false;
        this.isCreatingGroup = false;
        this.isGroupChatCreate = false;
        this.createGroupLoading = false;
      }
    );
  }

  clear() {
    this.searchText = '';
    this.chatListToShow = this.chatlist;
    //this.changeDetectorRef.detectChanges();
  }

  getChatList() {
    if (this.isFirstTime || this.leavingGroup) {
      this.gettingChatList = true;
      this.chatlist = [];
    }
    const data = {
      contact: '',
      count: this.currentCount,
      channelid: '',
      domain: this.allinoneService.getDomain().shortcode,
    };
    this.subscriptions.sink = this.kunyekService
      .getChatList(data, true)
      .subscribe(
        (res: any) => {
          console.log('your res');
          console.log(res);
          if (res.returncode == '300') {
            this.chatlist = res.list.reverse();
            this.chatListToShow = this.chatlist;
            this.gettingChatList = false;
            if (
              this.isFirstTime &&
              this.chatListToShow.length > 0 &&
              !this.openedChat
            ) {
              this.openChat(this.chatListToShow[0]);
            }
            this.isFirstTime = false;
            this.leavingGroup = false;
          } else {
            this.leavingGroup = false;
            this.gettingChatList = false;
            // this.messageService.openSnackBar(res.message, 'warn');
          }
          //this.changeDetectorRef.detectChanges()
        },
        (err) => {
          this.leavingGroup = false;
          this.gettingChatList = false;
        }
      );
  }

  getChatListWithInterval() {
    this.isfinishsubscribeChatlist = true;
    if (this.isFirstTime || this.leavingGroup) {
      this.gettingChatList = true;
      this.chatlist = [];
    }
    const data = {
      contact: '',
      count: this.currentCount,
      channelid: '',
      domain: this.allinoneService.getDomain().shortcode,
    };
    this.subscriptions.sink = this.kunyekService
      .getChatList(data, true)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            var tempChat = res.list;
            var tempNewChat: any[] = [];

            tempChat.map((tchat: any) => {
              //new chat
              const checknewchat = this.chatlist.filter(
                (chat: any) => chat.channelid == tchat.channelid
              );
              if (checknewchat.length == 0) {
                tempNewChat.push(tchat);
              }
            });

            this.chatlist.map((chat: any) => {
              //old chat
              tempChat
                .filter((tchat: any) => tchat.channelid == chat.channelid)
                .map((val: any) => {
                  chat.block = val.block;
                  if (chat.sort != val.sort) {
                    chat.message = val.message;
                    chat.sort = val.sort;
                    chat.sentby = val.sentby;
                    chat.admin = val.admin;
                    chat.adminlist = val.adminlist;
                    chat.block = val.block;
                    chat.channelid = val.channelid;
                    chat.channelname = val.channelname;
                    chat.class = val.chat;
                    chat.domain = val.domain;
                    chat.hidden = val.hidden;
                    chat.imagename = val.imagename;
                    chat.lat = val.lat;
                    chat.location = val.location;
                    chat.long = val.long;
                    chat.orgid = val.orgid;
                    chat.orgname = val.orgname;
                    chat.payroll = val.payroll;
                    chat.seen = val.seen;
                    chat.type = val.type;
                    chat.userlist = val.userlist;
                    this.chatlist.splice(this.chatlist.indexOf(chat), 1);
                    this.chatlist.unshift(chat);
                  }
                });
            });

            tempNewChat.map((newChat: any) => {
              this.chatlist.unshift(newChat);
            });

            this.chatListToShow = this.chatlist;
            this.gettingChatList = false;
            this.isFirstTime = false;
            this.leavingGroup = false;
          } else {
            this.leavingGroup = false;
            // this.messageService.openSnackBar(res.message, 'warn');
          }
          this.isfinishsubscribeChatlist = false;
          //this.changeDetectorRef.detectChanges();
        },
        (err) => {
          this.leavingGroup = false;
          this.gettingChatList = false;
          this.isfinishsubscribeChatlist = false;

          // this.messageService.openSnackBar(err, 'warn');
        }
      );
  }

  getChat(
    channelid: string,
    contact: string,
    type: string,
    firsttime?: boolean
  ) {
    this.gettingChat = this.isFirstTimeChat ? true : false;

    if (channelid === undefined) {
      var data = {
        contact: contact,
        count: '',
        channelid: '',
        domain: this.allinoneService.getDomain().shortcode,
        lastrecord: this.lastrecord,
      };
    } else {
      if (type == '1') {
        var data = {
          contact: contact,
          count: '',
          channelid: '',
          domain: this.allinoneService.getDomain().shortcode,
          lastrecord: this.lastrecord,
        };
      } else {
        var data = {
          contact: '',
          count: '',
          channelid: channelid,
          domain: this.allinoneService.getDomain().shortcode,
          lastrecord: this.lastrecord,
        };
      }
    }
    //console.log(data);
    //this.changeDetectorRef.detectChanges();
    this.subscribeChat = this.kunyekService.getChat(data).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          this.lastrecord = res.lastrecord;
          var tempChat = res.list;
          if (res.list.length > 0 && type == '3') {
            this.openedChat.channelid = res.list[0].channelid;
            this.connectChatMessageSocket();
            this.getChatListWithInterval();
            this.chatListSocket?.close(3001);
            this.connectChatListSocket();
          }

          if (firsttime) {
            this.groupmessages = [];
          }
          // var startIndex = this.groupmessages.length;
          var startIndex = 0;
          for (let i = startIndex; i < tempChat.length; i++) {
            this.groupmessages.unshift(tempChat[i]);
          }
          const awaitPrepare = await this.prepareChatToShow();

          this.gettingChat = false;
          this.gettingMoreChat = false;

          if (this.isFirstTimeChat) {
            this.scrollToBottom();
          }
          this.isFirstTimeChat = false;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
          this.gettingChat = false;
          this.gettingMoreChat = false;
        }
        //this.changeDetectorRef.detectChanges();
      },
      (err) => {
        this.gettingChat = false;
        this.gettingMoreChat = false;
        //this.changeDetectorRef.detectChanges();
      }
    );
  }

  async getChatMessageWithInterval(channelid: string, contact: string) {
    this.isFirstTime = false;
    this.isfinishsubscribeChat = true;
    //this.changeDetectorRef.detectChanges();
    if (channelid === undefined) {
      var data = {
        contact: contact,
        count: this.groupmessages.length > 0 ? 10 : 10,
        channelid: '',
        domain: this.allinoneService.getDomain().shortcode,
        start:
          this.groupmessages.length > 0
            ? this.groupmessages[this.groupmessages.length - 1].sort
            : '',
        end:
          this.groupmessages.length > 0
            ? this.allinoneService.generateID()
            : '',
      };
    } else {
      var data = {
        contact: '',
        count: this.groupmessages.length > 0 ? 10 : 10,
        channelid: channelid,
        domain: this.allinoneService.getDomain().shortcode,
        start:
          this.groupmessages.length > 0
            ? this.groupmessages[this.groupmessages.length - 1].sort
            : '',
        end:
          this.groupmessages.length > 0
            ? this.allinoneService.generateID()
            : '',
      };
    }
    //console.log(data);
    // return;

    this.getChatMsgSub = this.kunyekService.getChat(data, true).subscribe(
      async (res: any) => {
        if (res.returncode == '300') {
          var tempChat = res.list.reverse();
          const groumsg_lastitem =
            this.groupmessages[this.groupmessages.length - 1];
          const tempChat_lastitem = tempChat[tempChat.length - 1];
          //console.log(groumsg_lastitem, tempChat_lastitem);

          if (groumsg_lastitem && tempChat_lastitem) {
            if (groumsg_lastitem.syskey != tempChat_lastitem.syskey) {
              const d = tempChat.find(
                (el: any) => el.syskey == groumsg_lastitem.syskey
              );
              if (d) {
                var startIndex = tempChat.indexOf(d);
                //console.log(startIndex);

                for (let i = startIndex + 1; i < tempChat.length; i++) {
                  //console.log(JSON.stringify(tempChat[i]));

                  if (tempChat[i]) {
                    this.groupmessages.push(tempChat[i]);
                  }
                }
              }
            }
          }
          const awaitPrepare = await this.prepareChatToShow();
          if (groumsg_lastitem.syskey != tempChat_lastitem.syskey) {
            // New Message Arrive
          }
          this.gettingChat = false;
          this.gettingMoreChat = false;

          this.isFirstTimeChat = false;
        } else {
          this.messageService.openSnackBar(res.message || res.error, 'warn');

          this.gettingChat = false;
          this.gettingMoreChat = false;
        }
        this.isfinishsubscribeChat = false;
        //this.changeDetectorRef.detectChanges();
      },
      (err) => {
        this.gettingChat = false;
        this.gettingMoreChat = false;
        this.isfinishsubscribeChat = false;
        //this.changeDetectorRef.detectChanges();
      }
    );
  }

  prepareChatToShow() {
    return new Promise((resolve) => {
      try {
        var unique_dates: any = [];
        Array.from(new Set(this.groupmessages.map((s: any) => s.date))).map(
          (date) => {
            return unique_dates.push(date);
          }
        );

        unique_dates.map((date: any) => {
          this.groupmessages
            .filter((el: any) => el.date == date)
            .map((data: any, index: any) => {
              if (index == 0) {
                data.isGroupDate = true;
                data.showDate = true;
              } else {
                data.isGroupDate = false;
                data.showDate = false;
              }
            });
        });

        //this.changeDetectorRef.detectChanges();
        //console.log(this.groupmessages);
        resolve('success');
      } catch {
        resolve('error');
      }
    });
  }

  getMoreChatList(count: any) {
    this.gettingMoreChatList = true;
    const data = {
      contact: '',
      count: count,
      channelid: '',
      domain: this.allinoneService.getDomain().shortcode,
    };
    //this.changeDetectorRef.detectChanges()
    this.subscriptions.sink = this.kunyekService.getChatList(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var tempChatList = res.list.reverse();
          var startIndex = this.chatlist.length;
          for (let i = startIndex; i < tempChatList.length; i++) {
            this.chatlist.push(tempChatList[i]);
            this.chatListToShow = this.chatlist;
          }
          this.gettingMoreChatList = false;
        } else {
          this.gettingMoreChatList = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.gettingMoreChatList = false;
        //this.changeDetectorRef.detectChanges();
      }
    );
  }

  onMultipleFileSelect(event: any) {
    let files = event.target.files;
    var filetypelist = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files) {
      for (let file of files) {
        if (filetypelist.indexOf(file.type) > -1) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var temp = {
              file: file,
              previewurl: e.target.result,
              filename: this.getFileName(file),
            };
            this.selectedImageList.push(temp);
            //this.changeDetectorRef.detectChanges()
            this.keyPress();
          };
          reader.readAsDataURL(file);
        } else {
          this.messageService.openSnackBar(
            "File type '" + file.type + "' is not supported!",
            'warn'
          );
        }
      }
      this.hiddenfileinput.nativeElement.value = '';
    }
  }
  getFileName(file: any) {
    console.log(file);
    var name = file.name.trim();
    var splitName = name.split('.');
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName =
          finalName +
          '_' +
          this.allinoneService.generateID() +
          '.' +
          splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }

  onFileSelect(event: any) {
    this.getBase64(event.target.files[0]).then((data: any) => {
      this.fileSelected = data;
      this.hiddenfileinput.nativeElement.value = '';
      //this.changeDetectorRef.detectChanges();
    });
  }

  onAttchFileSelect(event: any) {
    var tmp = event.target.files[0]
    var filename: string = tmp.name;
    var filetype : string = tmp.name.split('.').pop();
    if(!this.allinoneService.allowedFileType.includes(filetype)){
      this.hiddenattachfileinput.nativeElement.value = '';
      return this.messageService.openSnackBar('File type not allowed', 'warn');
    }
    if(tmp.size > this.allinoneService.allowedFileSize){
      this.hiddenattachfileinput.nativeElement.value = '';
      return this.messageService.openSnackBar('File size exceeded', 'warn');
    }
    this.attachFile = event.target.files[0];
    //console.log(event)
    this.attachFileSelected = this.attachFile.name;

    if (filename.endsWith('.pdf')) {
      this.attachFileIcon = this.fileIconList[2];
    } else if (filename.endsWith('.png') || filename.endsWith('.jpg')) {
      this.attachFileIcon = this.fileIconList[3];
      this.attachFileSelected = '';

      this.onMultipleFileSelect(event);
    } else if (filename.endsWith('.zip') || filename.endsWith('.rar')) {
      this.attachFileIcon = this.fileIconList[1];
    } else {
      this.attachFileIcon = this.fileIconList[0];
      this.hiddenattachfileinput.nativeElement.value = '';
    }

    //this.changeDetectorRef.detectChanges();
    // this.getBase64(event.target.files[0]).then((data: any) => {
    //   this.fileSelected = data;
    //   this.hiddenfileinput.nativeElement.value = '';
    // });
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  closeImage(index: any) {
    this.selectedImageList.splice(index, 1);
    this.keyPress();
    //this.changeDetectorRef.detectChanges()
  }

  closeAttachedFile() {
    this.attachFileSelected = '';
    this.attachFile = '';
    //this.changeDetectorRef.detectChanges()
  }

  onKeydown(event: any) {
    event.preventDefault();
    if (!this.isMentioning) {
      this.sendMessage();
    }
  }
  async sendMessage() {
    const tempText = this.message.ops;
    // console.log('message list => ');
    // console.log(tempText);
    // return;
    var finalText = '';
    var finalNotiMessage = '';
    var mentionList: any[] = [];
    if (
      tempText == undefined ||
      tempText == null ||
      (tempText.length == 1 &&
        (tempText[0].insert == '\n' || tempText[0].insert == '\n\n'))
    ) {
      this.message = '';
    } else {
      console.log(tempText);
      tempText.map((item: any) => {
        if (item.insert.mention) {
          // var tempIndex = this.mentionableList[item.insert.mention.id];
          mentionList.push(item.insert.mention.id.toString());
          var temp = '@[__' + item.insert.mention.id.toString() + '__]';
          var temp2 = item.insert.mention.value
            ? item.insert.mention.value
            : item.insert.mention.id;

          temp += '(__' + temp2.toString() + '__)';

          finalText += temp;
          finalNotiMessage += temp2;
        } else {
          finalNotiMessage += item.insert.toString();
          finalText += item.insert.toString();
        }
      });

      this.message = finalText.substring(0, finalText.length - 1);
      finalNotiMessage = finalNotiMessage.substring(
        0,
        finalNotiMessage.length - 1
      );
    }

    if (this.message.endsWith('\n')) {
      this.message = this.message.substring(0, this.message.length - 1);
    }
    if (finalNotiMessage.endsWith('\n')) {
      finalNotiMessage = finalNotiMessage.substring(
        0,
        finalNotiMessage.length - 1
      );
    }
    if (
      this.message.trim() != '' ||
      this.selectedImageList.length > 0 ||
      this.attachFileSelected != ''
    ) {
      this.messageError = '';
      const current_date = this.allinoneService.getCurrentDateToDB();
      const current_time = this.allinoneService.getCurrentTimeToDB();
      const current_time2 = this.allinoneService.getCurrentTimeToDB2();
      var date = '';
      // var imagename = "", imagedata = "";
      date = current_date.toString() + current_time2.toString();
      var imagelist: any[] = [];
      var tempImageList: any[] = [];

      const finalSelectedImageList = this.selectedImageList;
      finalSelectedImageList.map((item: any) => {
        imagelist.push(item.filename);
        var temp = {
          imagename: item.filename,
          imageurl: item.previewurl,
        };
        tempImageList.push(temp);
      });

      const data = {
        domain: this.allinoneService.getDomain().shortcode,
        domainid: this.allinoneService.getDomain().domainid,
        contact: this.openedChat.userid ? this.openedChat.userid : '',
        channelname: '',
        date: current_date,
        time: current_time,
        message: this.message,
        imagename: '',
        imagedata: '',
        imagelist: imagelist,
        tmpimage: date,
        channelid: this.openedChat.channelid
          ? this.openedChat.channelid != ''
            ? this.openedChat.channelid
            : '0'
          : '0',
        name:
          this.allinoneService.getKunyekUserName() != ''
            ? this.allinoneService.getKunyekUserName()
            : this.allinoneService.getKunyekUserId(),
        mention: mentionList,
        eventid: '',
        replysyskey: this.check_reply ? this.syskey : '',
        filename: this.attachFileSelected,
        notimessage: finalNotiMessage,
      };
      //this.changeDetectorRef.detectChanges();

      const chatindex = await this.addToChat(
        current_date,
        current_time,
        tempImageList,
        this.attachFileSelected,
        finalNotiMessage,
        this.selectedImageList,
        this.attachFile
      );

      if (finalSelectedImageList && finalSelectedImageList.length > 0) {
        for (let i = 0; i < finalSelectedImageList.length; i++) {
          if (finalSelectedImageList[i].file.size >= 307200) {
            finalSelectedImageList[i].file =
              await this.allinoneService.compressImage(
                finalSelectedImageList[i].file
              );
          }
        }
        const result = await this.allinoneService.multipleUploadToAWS(
          finalSelectedImageList,
          this.openedChat.channelid ? this.openedChat.channelid : date
        );
        if (!result) {
          this.groupmessages[chatindex].isSending = false;
          this.groupmessages[chatindex].retry = true;
          return;
        }
      }

      // Clear Subscription and remove interval
      this.getChatMsgSub && this.getChatMsgSub.unsubscribe();
      clearInterval(this.getChatMsgInterval);

      this.kunyekService.sendMessage(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.selectedImageList = [];
            this.fileSelected = '';
            this.attachFileSelected = '';
            if (res.channelid) {
              this.openedChat.channelid = res.channelid;
              this.chatMessageSocket?.close(3001);
              if (this.openedChat.type == '3') {
                this.getChat(
                  this.openedChat.channelid,
                  this.openedChat.userid,
                  this.openedChat.type,
                  true
                );
              } else {
                this.getChat(
                  this.openedChat.channelid,
                  this.openedChat.userlist[0].userid,
                  this.openedChat.type,
                  true
                );
              }
            } else {
              this.groupmessages[chatindex].channelid = res.list[0].channelid;
              this.groupmessages[chatindex].checkintime =
                res.list[0].checkintime;
              this.groupmessages[chatindex].count = res.list[0].count;
              this.groupmessages[chatindex].description =
                res.list[0].description;
              this.groupmessages[chatindex].domain = res.list[0].domain;
              this.groupmessages[chatindex].eventdescription =
                res.list[0].eventdescription;
              this.groupmessages[chatindex].eventenddate =
                res.list[0].eventenddate;
              this.groupmessages[chatindex].eventlat = res.list[0].eventlat;
              this.groupmessages[chatindex].eventlocation =
                res.list[0].eventlocation;
              this.groupmessages[chatindex].eventlocationid =
                res.list[0].eventlocationid;
              this.groupmessages[chatindex].eventlong = res.list[0].eventlong;
              this.groupmessages[chatindex].eventname = res.list[0].eventname;
              this.groupmessages[chatindex].eventstartdate =
                res.list[0].eventstartdate;
              this.groupmessages[chatindex].filelink = res.list[0].filelink;
              this.groupmessages[chatindex].filename = res.list[0].filename;
              this.groupmessages[chatindex].imagename = res.list[0].imagename;
              this.groupmessages[chatindex].tempImageList =
                res.list[0].imagelist;
              this.groupmessages[chatindex].lat = res.list[0].lat;
              this.groupmessages[chatindex].location = res.list[0].location;
              this.groupmessages[chatindex].long = res.list[0].long;
              this.groupmessages[chatindex].message = res.list[0].message;
              this.groupmessages[chatindex].reactlist = res.list[0].reactlist;
              this.groupmessages[chatindex].replyimagename =
                res.list[0].replyimagename;
              this.groupmessages[chatindex].replymessage =
                res.list[0].replymessage;
              this.groupmessages[chatindex].replysort = res.list[0].replysort;
              this.groupmessages[chatindex].replysyskey =
                res.list[0].replysyskey;
              this.groupmessages[chatindex].replyuserid =
                res.list[0].replyuserid;
              // this.groupmessages[chatindex].replynotimessage = res.list[0].replynotimessage
              // this.groupmessages[chatindex].seen = res.list[0].seen;
              this.groupmessages[chatindex].sort = res.list[0].sort;
              this.groupmessages[chatindex].syskey = res.list[0].syskey;
              this.groupmessages[chatindex].time = res.list[0].time;
              this.groupmessages[chatindex].type = res.list[0].type;
              this.groupmessages[chatindex].userid = res.list[0].userid;
              this.groupmessages[chatindex].postid = '';
              this.groupmessages[chatindex].isSending = false;
              this.groupmessages[chatindex].seen = [
                this.allinoneService.getKunyekUserId(),
              ];
            }
            // this.getChatMsgInterval = setInterval(() => {
            //   if (
            //     this.openedChat &&
            //     !this.isFirstTimeChat &&
            //     !this.isfinishsubscribeChat
            //   ) {
            //     this.getChatMessageWithInterval(
            //       this.openedChat.channelid,
            //       this.openedChat.userid
            //     );
            //   }
            // }, 5 * 1000);
            //this.changeDetectorRef.detectChanges();
          } else {
            this.groupmessages[chatindex].isSending = false;
            this.groupmessages[chatindex].retry = true;
            this.attachFileSelected = '';
            //this.changeDetectorRef.detectChanges();
          }
        },
        (err) => {
          this.groupmessages[chatindex].isSending = false;
          this.groupmessages[chatindex].retry = true;
        }
      );
    } else {
      this.messageService.openSnackBar('Please type a message!', 'warn');
      // this.messageError = "Please type a message";
    }
  }

  async compressFile() {
    console.warn(
      'Size in bytes was:',
      this.imageCompress.byteCount(this.fileSelected)
    );
    await this.imageCompress
      .compressFile(this.fileSelected, 1, 50, 50)
      .then((result: any) => {
        this.fileSelected = result;
        console.warn(
          'Size in bytes is now:',
          this.imageCompress.byteCount(this.fileSelected)
        );
        if (this.imageCompress.byteCount(result) > 1048576) {
          this.compressFile();
        }
      });
  }

  async addToChat(
    current_date: string,
    current_time: string,
    imagelist: any[],
    filename: string,
    replynoti: string,
    selectedImageList: any[],
    attachFile: any
  ) {
    const findDate = this.groupmessages.find(
      (data: any) => data.date == current_date
    );
    const chatindex = this.groupmessages.length;

    //assign reply message
    const chat_reply = {
      replyimagename: this.reply_image,
      replymessage: this.reply_m,
      replyuserid: this.reply_userid,
    };

    var chat: any = {
      chattype: '',
      date: current_date,
      message: this.message,
      imagename: '',
      imagelist: imagelist,
      userid: this.allinoneService.getKunyekUserId(),
      time: current_time,
      isGroupDate: findDate ? false : true,
      showDate: findDate ? false : true,
      isSending: true,
      sort: this.allinoneService.generateID(),
      replysyskey: this.check_reply ? this.syskey : '',
      reactlist: [],
      edit: false,
      channelid: '',
      checkintime: '',
      count: '',
      description: '',
      domain: '',
      eventdescription: '',
      eventenddate: '',
      eventlat: '',
      eventlocation: '',
      eventlocationid: '',
      eventlong: '',
      eventname: '',
      eventstartdate: '',
      filelink: filename,
      filename: filename,
      lat: '',
      location: '',
      long: '',
      replysort: '',
      seen: '',
      syskey: '',
      type: '',
      replynotimessage: this.reply_m,
      notimessage: replynoti,
      postid: '',
      selectedImageList: selectedImageList,
      attachFile: attachFile,
      meetingtitle: '',
    };

    if (this.check_reply) {
      chat = Object.assign(chat, chat_reply);
    }

    //console.log(chat);

    this.groupmessages.push(chat);

    if (filename != '') {
      this.attachFileSelected = '';
      const await_file =
        await this.allinoneService.attachfileMultiUploadToS3WithLoop(
          this.attachFile,
          this.groupmessages.length > 0
            ? this.groupmessages[0].channelid
            : this.openedChat.channelid
            ? this.openedChat.channelid
            : '0'
        );
    }

    this.message = '';
    this.fileSelected = '';
    this.selectedImageList = [];
    this.attachFile = '';
    this.check_reply = false;
    this.keyPress();
    this.scrollToBottom();
    //this.changeDetectorRef.detectChanges();
    return chatindex;
  }

  chatscrolledUp() {
    if (
      // this.groupmessages.length == this.currentChatCount &&
      !this.isFirstTimeChat &&
      !this.gettingMoreChat
    ) {
      this.gettingMoreChat = true;
      this.currentChatCount = this.currentChatCount + 10;
      if (this.openedChat.type == '3') {
        this.getChat(
          this.openedChat.channelid,
          this.openedChat.userid,
          this.openedChat.type
        );
      } else {
        this.getChat(
          this.openedChat.channelid,
          this.openedChat.userlist[0].userid,
          this.openedChat.type
        );
      }
    }
  }

  imageViewer(msg: any, index: number) {
    this.imageData = JSON.parse(JSON.stringify(msg));
    this.imageData.img = this.imageData.imagelist[index].imageurl;
    console.log('image data ====> ');
    this.imageName = index;
    console.log(msg);
    $('#image-viewer').appendTo('body').modal('show');
    //this.changeDetectorRef.detectChanges();
  }

  closeImageViewer() {
    this.imageData = '';
  }
  backPhoto() {
    this.imageName--;
    if (this.imageName < 0) {
      this.imageName = this.imageData.imagelist.length - 1;
    }
    //this.changeDetectorRef.detectChanges();
  }
  forwardPhoto() {
    this.imageName++;
    if (parseInt(this.imageData.imagelist.length) == parseInt(this.imageName)) {
      this.imageName = 0;
    }
    //this.changeDetectorRef.detectChanges();
  }

  downloadImage() {
    window.open(this.imageData.imagelist[this.imageName].imageurl, '_blank');
  }

  getImgSrc(htmlString: any) {
    var element = <HTMLImageElement>htmlString;
    return element.src;
  }

  leaveGroup() {
    this.leavingGroup = true;
    this.isInfo = false;
    this.openedChat = '';
    //this.changeDetectorRef.detectChanges();
    this.getChatList();
  }

  deleteChat() {
    console.log('Deleted Successfully ---> ');
    this.isInfo = false;
    var index = this.chatlist.findIndex(
      (x: any) => x.channelid == this.openedChat.channelid
    );
    this.chatlist.splice(index, 1);
    this.openedChat = '';
  }

  reloadChat() {
    this.getChatList();
  }

  openDetails(msg: any) {
    msg.username = this.openedChat.userlist.find(
      (user: any) => user.userid == msg.userid
    )?.username;
    const dialog = this.dialog.open(CheckinEventModalComponent, {
      minHeight: '265px',
      height: 'auto',
      width: '500px',
      closeOnNavigation: true,
      autoFocus: false,
      panelClass: ['checkin-event-modal', 'mat-typography'],
      data: {
        msg: msg,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }

  createGroupWithSomeone(userid: any) {
    this.createWithThisMember = userid;
    this.isGroupCreateFromInfo = true;
    this.isContacts = true;
    this.isGroupChatCreate = true;
    //this.changeDetectorRef.detectChanges()
  }

  addMember(userlist: any) {
    this.membersAlreadyInGroup = userlist;
    this.isAddMember = true;
    this.isGroupCreateFromInfo = true;
    this.isContacts = true;
    this.isGroupChatCreate = true;
    this.areMembersChosen = false;
    //this.changeDetectorRef.detectChanges()
  }
  addMemberDone() {
    this.getMemberList();
    this.isAddMember = false;
    this.membersAlreadyInGroup = [];
    this.isContacts = false;
    this.isGroupCreateFromInfo = false;
    this.isGroupChatCreate = false;
    this.isInfo = false;
    //this.changeDetectorRef.detectChanges()
  }

  alreadyLiked(reactlist: any) {
    if (reactlist) {
      var type =
        reactlist.find(
          (item: any) => item.userid == this.allinoneService.getKunyekUserId()
        )?.type || '';
      return type;
    } else {
      return '';
    }
  }

  // likeMessage(msg: any) {
  //   var check = this.alreadyLiked(msg.reactlist)
  //   if (check) {
  //     var index = msg.reactlist.findIndex((x: any) => x.userid == this.allinoneService.getKunyekUserId());
  //     msg.reactlist.splice(index, 1)
  //   }
  //   else {
  //     msg.reactlist.push({
  //       'type': 'like',
  //       'userid': this.allinoneService.getKunyekUserId()
  //     })
  //   }
  //   //this.changeDetectorRef.detectChanges()
  //   const data = {
  //     "domain": this.allinoneService.getDomain().shortcode,
  //     "syskey": msg.syskey,
  //     "type": "like"
  //   }
  //   this.kunyekService.likeMessage(data).subscribe((res: any) => {
  //     if (res.returncode == "300") {

  //     }
  //     else {
  //       this.messageService.openSnackBar(res.message, 'warn')
  //     }
  //   },
  //     err => {

  //     }
  //   )
  // }

  openEditMessage(msg: any) {
    this.changeDetectorRef.detach();
    this.msgEditing = true;
    const dialog = this.dialog.open(ChatEditComponent, {
      height: 'auto',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['mat-typography', 'edit-message'],
      data: {
        msg: msg,
        mentionableList: this.mentionableList,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        msg.message = dialogResult.message;
        msg.notimessage = dialogResult.notimessage;
        msg.imagelist = dialogResult.imagelist;
      }
      this.changeDetectorRef.reattach();
      this.msgEditing = false;
    });
  }

  closeEditMessage(index: number) {
    this.groupmessages[index].edit = false;
    this.editText = '';
    //this.changeDetectorRef.detectChanges()
  }

  submitEditMessage(msg: any) {
    const tempText = this.quill.getContents().ops;
    var finalText = '';
    var finalNotiMessage = '';
    console.log(tempText);
    tempText.map((item: any) => {
      if (item.insert.mention) {
        var tempIndex = this.mentionableList[item.insert.mention.id];
        var temp = '@[__' + tempIndex.userid.toString() + '__]';
        var temp2 = tempIndex.username ? tempIndex.username : tempIndex.userid;
        if (tempIndex.username) {
          temp += '(__' + tempIndex.username.toString() + '__)';
        } else {
          temp += '(__' + tempIndex.userid.toString() + '__)';
        }
        finalText += temp;
        finalNotiMessage += temp2;
      } else {
        finalNotiMessage += item.insert.toString();
        finalText += item.insert.toString();
      }
    });
    this.editText = finalText.substring(0, finalText.length - 1);
    finalNotiMessage = finalNotiMessage.substring(
      0,
      finalNotiMessage.length - 1
    );
    if (this.editText.endsWith('\n')) {
      this.editText = this.editText.substring(0, this.editText.length - 1);
    }
    if (finalNotiMessage.endsWith('\n')) {
      finalNotiMessage = finalNotiMessage.substring(
        0,
        finalNotiMessage.length - 1
      );
    }
    if (msg.imagename == '') {
      if (this.editText.trim() == '') {
        this.messageService.openSnackBar('Message cannot be blank!', 'warn');
      } else {
        this.saveEditMessage(msg, finalNotiMessage);
      }
    } else {
      this.saveEditMessage(msg, finalNotiMessage);
    }
  }

  saveEditMessage(msg: any, notimessage: string) {
    this.editLoading = true;
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      syskey: msg.syskey,
      message: this.editText,
      notimessage: notimessage,
    };
    //this.changeDetectorRef.detectChanges();
    this.subscriptions.sink = this.kunyekService.editMessage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.editLoading = false;
          msg.message = this.editText;
          this.editText = '';
          msg.edit = false;
          msg.notimessage = notimessage;
        } else {
          this.editLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.editLoading = false;
        //this.changeDetectorRef.detectChanges();
      }
    );
  }

  deleteMessageConfirm(msg: any, index: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.deleteMessage(msg, index);
      }
    });
  }

  deleteMessage(msg: any, index: number) {
    this.allinoneService.isLoading = true;
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      syskey: msg.syskey,
    };
    //this.changeDetectorRef.detectChanges();
    this.kunyekService.deleteMessage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.groupmessages.splice(index, 1);
          this.allinoneService.isLoading = false;
        } else {
          this.allinoneService.isLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
        //this.changeDetectorRef.detectChanges()
      },
      (err) => {
        this.allinoneService.isLoading = false;
        //this.changeDetectorRef.detectChanges();
      }
    );
  }

  LikeAndTagForm(reactlists: any) {
    var tempList: any[] = [];
    this.openedChat.userlist.map((user: any) => {
      reactlists.map((ruser: any) => {
        if (ruser.userid == user.userid) {
          tempList.push({
            block: user.block,
            department: user.department,
            friend: user.friend,
            imagename: user.imagename,
            position: user.position,
            userid: user.userid,
            username: user.username,
            usersyskey: user.usersyskey,
            type: ruser.type,
          });
        }
      });
    });
    console.log('TempList =>>');
    console.log(tempList);
    //this.changeDetectorRef.detectChanges()
    //console.log(tempList)
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        isFromMessage: true,
        list: tempList,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        // this.scrollToTop()
      }
    });
  }

  clickDownloadFile(url: any, filename: any) {
    //console.log("CLICKED DOWNLOAD>>>" + url.toString());

    this.downloadFile(url).subscribe((response: any) => {
      saveAs(response, filename);
      // this.downloading = false
    }),
      (error: any) => {
        // this.downloading = false
        this.messageService.openSnackBar('Download failed.', 'warn');
      };
  }

  downloadFile(url: any): any {
    return this.http.get(url, { responseType: 'blob' });
  }

  groupNameEdit() {
    this.editGroupName = true;
    this.editName = this.openedChat.channelname;
    //this.changeDetectorRef.detectChanges()
  }

  cancelGroupNameEdit() {
    this.editGroupName = false;
    this.editName = '';
    //this.changeDetectorRef.detectChanges()
  }

  updateGroupName() {
    this.editingGroupName = true;
    //this.changeDetectorRef.detectChanges()
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      recordstatus: '2',
      contact: [],
      channelname: this.editName,
      date: '',
      sort: '',
      channelid: this.openedChat.channelid,
      admin: '',
      removeadmin: '',
      custom: this.openedChat.custom,
      support: this.openedChat.support,
      hierarchy: this.openedChat.hierarchy,
      payroll: this.openedChat.payroll,
      class: this.openedChat.class,
      hidden: this.openedChat.hidden,
      orgid: this.openedChat.orgid,
      lat: this.openedChat.lat,
      long: this.openedChat.long,
      location: this.openedChat.location,
    };
    //console.log(data)
    this.subscriptions.sink = this.kunyekService
      .updateGroupInfo(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.openedChat.channelname = this.editName;
            this.editingGroupName = false;
            this.cancelGroupNameEdit();
          } else {
            this.editingGroupName = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
          //this.changeDetectorRef.detectChanges()
        },
        (err) => {
          this.editingGroupName = false;
          //this.changeDetectorRef.detectChanges();
        }
      );
  }

  afterBlock(oChat: any) {
    this.isInfo = false;
    if (oChat.type == '3') {
      this.chatListToShow.map((chat: any) => {
        if (chat.userlist[0].userid == oChat.userid) {
          chat.block = 'true';
        }
      });
    } else {
      this.chatListToShow.map((chat: any) => {
        if (chat.channelid == oChat.channelid) {
          chat.block = 'true';
        }
      });
    }
    //this.changeDetectorRef.detectChanges()
  }

  async onPaste(e: any) {
    var temMsg = this.message;
    //this.changeDetectorRef.detectChanges()
    console.log(e);
    this.message += e.clipboardData.getData('text').toString();
    const items = (e.clipboardData || e.originalEvent.clipboardData).files;
    console.log(items);
    let blob = null;
    for (const item of items) {
      if (
        item.type == 'image/jpeg' ||
        item.type == 'image/jpg' ||
        item.type == 'image/png'
      ) {
        // blob = item.getAsFile();
        blob = item;

        var temp = {
          file: blob,
          previewurl: '',
          filename: this.getFileName(blob),
        };

        await this.getBase64(blob).then((data: any) => {
          temp.previewurl = data;
          this.selectedImageList.push(temp);
          timer(20).subscribe((x) => {
            this.message = temMsg;
          });
          //this.changeDetectorRef.detectChanges()
        });
      } else {
        // blob = item.getAsFile();
        blob = item;
        this.onAttchFileSelect1(blob);
        timer(20).subscribe((x) => {
          this.message = temMsg;
        });
        //this.changeDetectorRef.detectChanges()
      }
    }
    this.message = temMsg;
    this.keyPress();
  }

  onAttchFileSelect1(data: any) {
    // this.attachFile = event.target.files[0];
    // return new Promise((resolve, reject) => {
    this.attachFile = data;

    if (this.attachFile != null) {
      var filename: string = this.attachFile.name;
      this.attachFileSelected = this.attachFile.name;

      if (filename.endsWith('.pdf')) {
        this.attachFileIcon = this.fileIconList[2];
      } else if (filename.endsWith('.png') || filename.endsWith('.jpg')) {
        this.attachFileIcon = this.fileIconList[3];
        this.attachFileSelected = '';

        this.onMultipleFileSelect(data);
      } else if (filename.endsWith('.zip') || filename.endsWith('.rar')) {
        this.attachFileIcon = this.fileIconList[1];
      } else {
        this.attachFileIcon = this.fileIconList[0];
        this.hiddenattachfileinput.nativeElement.value = '';
      }
    }
    //this.changeDetectorRef.detectChanges()
    // });
  }

  trackByItems(index: any, item: any) {
    return item.syskey;
  }

  trackBySort(index: any, item: any) {
    return item.sort;
  }

  getUserName(userid: string) {
    const index = this.openedChat.userlist.findIndex(
      (x: any) => x.userid == userid
    );
    if (index == -1) {
      return 'Unknown User';
    } else {
      return this.openedChat.userlist[index].username
        ? this.openedChat.userlist[index].username
        : this.openedChat.userlist[index].userid;
    }
  }

  getMemberList() {
    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      contact: [],
      channelname: '',
      date: '',
      sort: '',
      channelid: this.openedChat.channelid,
    };
    this.subscriptions.sink = this.kunyekService
      .getChannelMembers(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinoneService.isLoading = false;
            this.openedChat.userlist = res.list;
            this.openedChat.adminlist = res.adminlist;
            // //this.changeDetectorRef.detectChanges()
          } else {
            this.allinoneService.isLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
          //this.changeDetectorRef.detectChanges()
        },
        (err) => {
          this.allinoneService.isLoading = false;
          this.messageService.openSnackBar(err, 'warn');
          //this.changeDetectorRef.detectChanges()
        }
      );
  }
  viewpostdetails(id: any) {
    this.router.navigateByUrl('/posts?id=' + id);
  }

  // likeMessage(msg: any, type: any) {

  //   let isliked = false;
  //   let count = 0;
  //   let userid = this.allinoneService.getKunyekUserId();
  //   for (let a = 0; a < msg.reactlist.length; a++) {
  //     if (msg.reactlist[a].userid == userid) {
  //       if (msg.reactlist[a].type == type || msg.reactlist[a].type == 'like') {
  //         isliked = true;
  //       }
  //       if (msg.reactlist[a].type != type) {
  //         isliked = false;
  //       }
  //     }
  //   }
  //   //this.changeDetectorRef.detectChanges()
  //   console.log(isliked);
  //   console.log(msg.reactlist);
  //   if (isliked) {

  //     var index = msg.reactlist.findIndex((x: any) => x.userid == this.allinoneService.getKunyekUserId());
  //     msg.reactlist.splice(index, 1);
  //     console.log(msg.reactlist);
  //     const data = {
  //       "domain": this.allinoneService.getDomain().shortcode,
  //       "syskey": msg.syskey,
  //       "type": ''
  //     }
  //     console.log(data);
  //     this.kunyekService.likeMessage(data).subscribe((res: any) => {
  //       if (res.returncode == "300") {
  //         //this.changeDetectorRef.detectChanges();
  //       }
  //       else {
  //         this.messageService.openSnackBar(res.message, 'warn')
  //       }
  //     },
  //       err => {
  //       }
  //     )
  //     //this.changeDetectorRef.detectChanges();
  //   }
  //   else {
  //     var index = msg.reactlist.findIndex((x: any) => x.userid == this.allinoneService.getKunyekUserId());
  //     index > -1 && msg.reactlist.splice(index, 1);

  //     if (type == "like") {
  //       msg.reactlist.push({
  //         'type': 'like',
  //         'userid': this.allinoneService.getKunyekUserId()
  //       })
  //       //this.changeDetectorRef.detectChanges();
  //     }
  //     if (type == "002") {
  //       msg.reactlist.push({
  //         'type': '002',
  //         'userid': this.allinoneService.getKunyekUserId()
  //       })
  //       //this.changeDetectorRef.detectChanges();
  //     }
  //     if (type == "003") {
  //       msg.reactlist.push({
  //         'type': '003',
  //         'userid': this.allinoneService.getKunyekUserId()
  //       })
  //       //this.changeDetectorRef.detectChanges();
  //     }
  //     if (type == "004") {
  //       msg.reactlist.push({
  //         'type': '004',
  //         'userid': this.allinoneService.getKunyekUserId()
  //       })
  //       //this.changeDetectorRef.detectChanges();
  //     }
  //     if (type == "005") {
  //       msg.reactlist.push({
  //         'type': '005',
  //         'userid': this.allinoneService.getKunyekUserId()
  //       })
  //       //this.changeDetectorRef.detectChanges();
  //     }

  //     if (msg.reactlist.length > 0) {

  //       const data = {
  //         "domain": this.allinoneService.getDomain().shortcode,
  //         "syskey": msg.syskey,
  //         "type": msg.reactlist[msg.reactlist.length - 1]['type']
  //       }
  //       console.log(data);
  //       console.log(msg.reactlist);
  //       this.kunyekService.likeMessage(data).subscribe((res: any) => {
  //         if (res.returncode == "300") {
  //           //this.changeDetectorRef.detectChanges();
  //         }
  //         else {
  //           this.messageService.openSnackBar(res.message, 'warn')
  //         }
  //       },
  //         err => {
  //         }
  //       )
  //     }
  //     //this.changeDetectorRef.detectChanges();
  //   }
  // }

  mouseOver(index: any) {
    let userid = this.allinoneService.getKunyekUserId();
    for (let i = 0; i < this.groupmessages[index].reactlist.length; i++) {
      if (this.groupmessages[index].reactlist[i].userid == userid) {
        this.reactType = this.groupmessages[index].reactlist[i].type;
        this.typeReact = true;
        break;
      }
    }
  }
  likeMessage(index: any, msg: any, type: any) {
    console.log('Sar' + index);
    //this.changeDetectorRef.detectChanges()
    let isliked = false;
    let count = 0;
    let userid = this.allinoneService.getKunyekUserId();
    for (let a = 0; a < msg.reactlist.length; a++) {
      if (msg.reactlist[a].userid == userid) {
        if (msg.reactlist[a].type == type || msg.reactlist[a].type == 'like') {
          isliked = true;
        }
        if (msg.reactlist[a].type != type) {
          isliked = false;
        }
      }
    }
    this.mouseOver(index);
    if (isliked) {
      //this.changeDetectorRef.detectChanges();
      var index = msg.reactlist.findIndex(
        (x: any) => x.userid == this.allinoneService.getKunyekUserId()
      );
      msg.reactlist.splice(index, 1);
      console.log(msg.reactlist);
      const data = {
        domain: this.allinoneService.getDomain().shortcode,
        syskey: msg.syskey,
        type: '',
      };
      console.log(data);
      this.typeReact = false;
      this.subscriptions.sink = this.kunyekService.likeMessage(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            //this.changeDetectorRef.detectChanges();
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {}
      );
    } else {
      var index = msg.reactlist.findIndex(
        (x: any) => x.userid == this.allinoneService.getKunyekUserId()
      );
      index > -1 && msg.reactlist.splice(index, 1);
      //this.changeDetectorRef.detectChanges();
      if (type == 'like') {
        msg.reactlist.push({
          type: 'like',
          userid: this.allinoneService.getKunyekUserId(),
        });
      }
      if (type == '002') {
        msg.reactlist.push({
          type: '002',
          userid: this.allinoneService.getKunyekUserId(),
        });
      }
      if (type == '003') {
        msg.reactlist.push({
          type: '003',
          userid: this.allinoneService.getKunyekUserId(),
        });
      }
      if (type == '004') {
        msg.reactlist.push({
          type: '004',
          userid: this.allinoneService.getKunyekUserId(),
        });
      }
      if (type == '005') {
        msg.reactlist.push({
          type: '005',
          userid: this.allinoneService.getKunyekUserId(),
        });
      }
      //this.changeDetectorRef.detectChanges();
      if (msg.reactlist.length > 0) {
        //this.changeDetectorRef.detectChanges();
        const data = {
          domain: this.allinoneService.getDomain().shortcode,
          syskey: msg.syskey,
          type: msg.reactlist[msg.reactlist.length - 1]['type'],
        };
        console.log(data);
        console.log(msg.reactlist);
        this.subscriptions.sink = this.kunyekService
          .likeMessage(data)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                //this.changeDetectorRef.detectChanges();
              } else {
                this.messageService.openSnackBar(res.message, 'warn');
              }
            },
            (err) => {}
          );
      }
    }
  }

  async retry(msg: any) {
    msg.isSending = true;
    this.messageError = '';
    const current_date = this.allinoneService.getCurrentDateToDB();
    const current_time = this.allinoneService.getCurrentTimeToDB();
    const current_time2 = this.allinoneService.getCurrentTimeToDB2();
    var date = '';
    // var imagename = "", imagedata = "";
    date = current_date.toString() + current_time2.toString();
    var imagelist: any[] = [];
    var tempImageList: any[] = [];

    const finalSelectedImageList = msg.selectedImageList;
    finalSelectedImageList.map((item: any) => {
      imagelist.push(item.filename);
      var temp = {
        imagename: item.filename,
        imageurl: item.previewurl,
      };
      tempImageList.push(temp);
    });

    if (finalSelectedImageList && finalSelectedImageList.length > 0) {
      const result = await this.allinoneService.multipleUploadToAWS(
        finalSelectedImageList,
        this.openedChat.channelid ? this.openedChat.channelid : date
      );
    }

    if (msg.attachFile) {
      const await_attach =
        await this.allinoneService.attachfileMultiUploadToS3WithLoop(
          msg.attachFile,
          this.groupmessages.length > 0
            ? this.groupmessages[0].channelid
            : this.openedChat.channelid
            ? this.openedChat.channelid
            : '0'
        );
    }

    const data = {
      domain: this.allinoneService.getDomain().shortcode,
      domainid: this.allinoneService.getDomain().domainid,
      contact: this.openedChat.userid ? this.openedChat.userid : '',
      channelname: '',
      date: current_date,
      time: current_time,
      message: msg.message,
      imagename: '',
      imagedata: '',
      imagelist: imagelist,
      tmpimage: date,
      channelid: this.openedChat.channelid
        ? this.openedChat.channelid != ''
          ? this.openedChat.channelid
          : '0'
        : '0',
      name:
        this.allinoneService.getKunyekUserName() != ''
          ? this.allinoneService.getKunyekUserName()
          : this.allinoneService.getKunyekUserId(),
      mention: [],
      eventid: '',
      replysyskey: this.check_reply ? this.syskey : '',
      filename: msg.filename,
      notimessage: msg.notimessage,
    };

    // Clear Subscription and remove interval
    this.getChatMsgSub && this.getChatMsgSub.unsubscribe();
    clearInterval(this.getChatMsgInterval);

    this.kunyekService.sendMessage(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (res.channelid) {
            this.openedChat.channelid = res.channelid;
            if (this.openedChat.type == '3') {
              this.getChat(
                this.openedChat.channelid,
                this.openedChat.userid,
                this.openedChat.type,
                true
              );
            } else {
              this.getChat(
                this.openedChat.channelid,
                this.openedChat.userlist[0].userid,
                this.openedChat.type,
                true
              );
            }
          } else {
            msg.channelid = res.list[0].channelid;
            msg.checkintime = res.list[0].checkintime;
            msg.count = res.list[0].count;
            msg.description = res.list[0].description;
            msg.domain = res.list[0].domain;
            msg.eventdescription = res.list[0].eventdescription;
            msg.eventenddate = res.list[0].eventenddate;
            msg.eventlat = res.list[0].eventlat;
            msg.eventlocation = res.list[0].eventlocation;
            msg.eventlocationid = res.list[0].eventlocationid;
            msg.eventlong = res.list[0].eventlong;
            msg.eventname = res.list[0].eventname;
            msg.eventstartdate = res.list[0].eventstartdate;
            msg.filelink = res.list[0].filelink;
            msg.filename = res.list[0].filename;
            msg.imagename = res.list[0].imagename;
            msg.tempImageList = res.list[0].imagelist;
            msg.lat = res.list[0].lat;
            msg.location = res.list[0].location;
            msg.long = res.list[0].long;
            msg.message = res.list[0].message;
            msg.reactlist = res.list[0].reactlist;
            msg.replyimagename = res.list[0].replyimagename;
            msg.replymessage = res.list[0].replymessage;
            msg.replysort = res.list[0].replysort;
            msg.replysyskey = res.list[0].replysyskey;
            msg.replyuserid = res.list[0].replyuserid;
            msg.sort = res.list[0].sort;
            msg.syskey = res.list[0].syskey;
            msg.time = res.list[0].time;
            msg.type = res.list[0].type;
            msg.userid = res.list[0].userid;
            msg.postid = '';
            msg.isSending = false;
            msg.seen = [this.allinoneService.getKunyekUserId()];
            msg.retry = false;
            msg.attachFile = '';
            msg.selectedImageList = '';
          }
          // this.getChatMsgInterval = setInterval(() => {
          //   if (
          //     this.openedChat &&
          //     !this.isFirstTimeChat &&
          //     !this.isfinishsubscribeChat
          //   ) {
          //     this.getChatMessageWithInterval(
          //       this.openedChat.channelid,
          //       this.openedChat.userid
          //     );
          //   }
          // }, 5 * 1000);
        } else {
          msg.isSending = false;
          msg.retry = true;
        }
      },
      (err) => {
        msg.isSending = false;
        msg.retry = true;
      }
    );
  }

  createMeeting() {
    this.changeDetectorRef.detach();
    const dialog = this.dialog.open(MeetingTitleComponent, {
      maxWidth: '500px',
      width: '400px',
      closeOnNavigation: true,
      data: {
        chat: JSON.parse(JSON.stringify(this.openedChat)),
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult);
        if (typeof dialogResult === 'string') {
          this.openedChat.channelid = dialogResult;
          if (this.openedChat.type == '3') {
            this.getChat(
              this.openedChat.channelid,
              this.openedChat.userid,
              this.openedChat.type,
              true
            );
          } else {
            this.getChat(
              this.openedChat.channelid,
              this.openedChat.userlist[0].userid,
              this.openedChat.type,
              true
            );
          }
        } else {
          dialogResult.chattype = '';
          this.groupmessages.push(JSON.parse(JSON.stringify(dialogResult)));
          console.log(this.groupmessages);
        }
      }
      this.changeDetectorRef.reattach();
    });
  }

  openMeeting(url: string) {
    window.open(url, '_blank');
  }

  viewOtherProfile(userid: string) {
    const dialog = this.dialog.open(UserProfileComponent, {
      height: '400px',
      width: '400px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        user: [],
        userid: userid,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.openChat(this.openedChat);
      }
    });
  }
}
