
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AllInOneService } from '../../shared/services/all-in-one.service';

@Pipe({
  name: 'replaceEnterForChat'
})
export class ReplaceEnterForChatPipe implements PipeTransform {


  constructor(
  public sanitizer:DomSanitizer,
    public allinoneService: AllInOneService

  ) {

  }

  transform(msg: any, check: boolean, i: any) : SafeHtml {
    if (msg == "" || msg == undefined || msg == null) {
      return ""
    }
    else {
      let match = msg.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
      let mention = msg.match(/(@\[__(.*?)__\]\(__(.*?)__\))/ig);
      let final = msg;
      if ((match == undefined || match == null) && (mention == undefined || mention == null)) {
        return this.replaceAll(final, "\n", "<br>")
      }
      else if (match != undefined || match != null) {
        match.map((url: any) => {
          if (check) {
            final = final.replace(url, "<a class = 'a-color' href=\"" + url + "\" target=\"_BLANK\">" + url + "</a>")
          }
          else {
            final = final.replace(url, "<a href=\"" + url + "\" target=\"_BLANK\">" + url + "</a>")
          }
        })
        return this.replaceAll(final, "\n", "<br>")
      } else if (mention != undefined || mention != null) {
        mention.map((user: any,index:any) => {
          var newUser = user.split("__")
          if (this.allinoneService.getKunyekUserId() == newUser[1]) {
            final = final.replace(user, "<b class = 'mentioned-you'> " + newUser[3] + "</b>")
          } else {
            final = final.replace(user, `<b class = 'mentioned-user' id="`+newUser[1]+`"  (click)="viewProfile('`+newUser[1]+`')" > ` + newUser[3] + `</b>`)
            // final=final.replace(user,'<button type="button" id="submitButton" (click)="openWindow()">Submit</buttn>')
          }
        })
        return this.replaceAll(final, "\n", "<br>")
      } else {
        return this.replaceAll(final, "\n", "<br>")
      }
    }
  }
  replaceAll(str: string, find: string, replace: string) {
    var escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return this.sanitizer.bypassSecurityTrustHtml(str.replace(new RegExp(escapedFind, 'g'), replace));
  } 

}