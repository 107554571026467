<div class="container-fluid">
    <div class="content">
        <div *ngIf="isFromOffice">
            <app-office-breadcrumb *ngIf="!isDetailView" [s_breadcrumb]="'HR Approval'"></app-office-breadcrumb>
            <app-office-breadcrumb *ngIf="isDetailView" [s_breadcrumb]="'HR Approval'" [t_breadcrumb]="'Edit'"
                (emit)="cancel()"></app-office-breadcrumb>
        </div>
        <div *ngIf="!isFromOffice">
            <app-team-breadcrumb *ngIf="!isDetailView" [s_breadcrumb]="'HR Approval'"></app-team-breadcrumb>
            <app-team-breadcrumb *ngIf="isDetailView" [s_breadcrumb]="'HR Approval'" [t_breadcrumb]="'Edit'"
                (emit)="cancel()"></app-team-breadcrumb>
        </div>

        <!-- Details View -->
        <ng-container *ngIf="isDetailView; else listView">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="d-flex align-items-center">
                    <button type="button" class="btn btn-custom" (click)="cancel()">
                        Cancel
                    </button>
    
                    <div class="" *ngIf="selectedRequest.status != '004' && selectedRequest.status != '005'">
                        <div class="line"></div>
                    </div>
                    <app-button-loader class="me-2" (click)="approveReject(true)" [button_text]="'Reject'"
                        [load_button_text]="'Rejecting'" [isLoading]="isRejecting" [outlinedanger]="true"
                        *ngIf="!isApproving && selectedRequest.status != '004' && selectedRequest.status != '005'">
                    </app-button-loader>
                    <app-button-loader class="" (click)="approveReject(false)" [button_text]="'Approve'"
                        [load_button_text]="'Approving'" [isLoading]="isApproving"
                        *ngIf="!isRejecting && selectedRequest.status != '004' && selectedRequest.status != '005'">
                    </app-button-loader>
    
    
                </div>
                <div>
                        <span [title]="selectedRequest.status" class="status" [ngClass]="{
                            'status-gray': selectedRequest.status == '001',
                            'status-main': selectedRequest.status == '002' || selectedRequest.status == '003', 
                            'status-green': selectedRequest.status == '004',
                            'status-red': selectedRequest.status == '005'
                          }">{{
                            getTableStatus(selectedRequest.status)
                            }}</span>
                    
                </div>
            </div>
            <div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label for="userid" class="form-label">User ID</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="userid" name="userid"
                                [value]="selectedRequest.userid" disabled="true" />
                        </div>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="requesttype" class="form-label">Request Type</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="requesttype" name="requesttype"
                                [value]="getRequestTypeName(selectedRequest.typeid)" disabled="true" />
                        </div>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label for="requestdate" class="form-label">Request Date</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="requestdate" name="requestdate"
                                [value]="allinoneService.formatDBToShow(selectedRequest.requestdate)" disabled="true" />
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label for="description" class="form-label">Description</label>
                        <div class="input-group">
                            <textarea class="form-control" id="description" [value]="selectedRequest.description"
                                name="description" rows="3" placeholder="Remark" disabled="true"></textarea>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="isAdmin && selectedRequest.status != '004' && selectedRequest.status != '005' && !isFromOffice">
                    <input hidden type="file" accept="image/*" (change)="onFileSelect($event)" #hiddenfileinput
                        multiple />
                    <button class="btn btn-custom mt-3 me-2" (click)="hiddenfileinput.click()">
                        <i class="fas fa-paperclip me-2"></i> Attachments
                    </button>
                </ng-container>
                <div class="col-md-12 row mt-3">
                    <div class="img-container col-lg-3 col-md-4 col-sm-4 col-6"
                        *ngFor="let item of imageList; let index = index">
                        <div class="img-border card">
                            <img [src]="item.previewurl" (click)="allinoneService.imageViewer(imageList, index)" />
                            <mat-icon *ngIf="isAdmin && selectedRequest.status != '004' && selectedRequest.status != '005'" class="close" (click)="removeImage(index)">close</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- List View -->
        <ng-template #listView>
            <div class="mb-3 d-flex flex-wrap justify-content-start align-items-center">
                <div class="input-group" style="width: 300px !important" *ngIf="!isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search By User ID/User Name"
                        aria-label="Search By User ID/User Name" (focus)="isFocus = true" (blur)="isFocus = false"
                        (ngModelChange)="searchTextChange()" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }"
                        style="font-family: FontAwesome, Ubuntu">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <div class="dropdown" [ngClass]="{'ms-0' : isMobileView , 'ms-2' : !isMobileView }">
                    <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false" title="Page Size">
                        <mat-icon>filter_list</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-label="dropdownMenuButton1">
                        <li>
                            <div style="text-align: center;">Page size</div>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item" *ngFor="let page of pg">
                            <input type="radio" name="pg-count" value="{{page.count}}"
                                (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                [checked]="page.count==100">
                            <label for="pg{{page.name}}" class="col-10 p-1">{{ page.name }}</label>
                        </li>
                    </ul>
                </div>

                <div class="ms-2">
                    <button class="btn btn-custom" (click)="refresh()" title="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
                <div class="line"></div>
                <div class="" [matMenuTriggerFor]="statusmenu">
                    <button class="btn btn-secondary dropdown-toggle advanced-filter-btn" type="button"
                        id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle
                        style="color: white; min-width: 100px">
                        Status&nbsp;:&nbsp;{{ filterStatusName }}
                        <mat-menu #statusmenu="matMenu" xPosition="before">
                            <button mat-menu-item *ngFor="let status of statusList" (click)="statusChange(status)">
                                {{ status.name }}
                            </button>
                        </mat-menu>
                    </button>
                </div>

                <div *ngIf="filterSD && filterED"
                            class="ms-2 btn-group d-flex align-items-center" role="group">
                            <button type="button" class="btn btn-secondary advanced-filter-btn" (click)="filter()">
                                Date: {{ filterSD | date : "dd/MM/yyyy" }} -
                                {{ filterED | date : "dd/MM/yyyy" }}
                            </button>
                            <button (click)="clearDate()" type="button" class="btn btn-secondary advanced-filter-btn">
                                <img src="assets/images/icon.png" alt=""
                                    style="width: 11px; height: 11px; margin-top: -3px" />
                            </button>
                        </div>

                <div class="ms-2">
                    <button class="btn btn-custom" (click)="filter()" title="Filter"
                        [disabled]="gettingRequestType">
                        <i class="fa fa-filter"></i>
                    </button>
                </div>

                <!-- <input style="width: 200px;" class="form-control datepicker-readonly ms-3" type="text" id='requestdatefilter' name='requestdatefilter'
          placeholder="dd/mm/yyyy" bsDatepicker [bsConfig]="generalDatePickerConfig" [bsConfig]="{showClearButton: true}" [matTooltip]="'Request Date Filter'"
          [(ngModel)]="requestDateFilter" (ngModelChange)="refresh()" readonly> -->

                <div class="line"></div>
                <!-- <button (click)="t()">T</button> -->
                <button type="button" class="btn btn-custom" (click)="export()" title="Export To Excel"
                    [disabled]="dataList.length == 0">
                    <img class="excel-icon" src="../../../assets/images/excel.png" />
                    Export
                </button>
                <div class="line"></div>
                <div class="d-flex align-items-center">
                    <span class="total-count-text">Total:&nbsp;</span>
                    <span class="total-count-num">{{ (dataList | filter : searchText: 'hrapproval' ).length
                        }}</span>
                </div>

                <div class="input-group mt-2" *ngIf="isMobileView">
                    <input type="text" class="form-control" placeholder="&#xf002; Search By User ID/User Name" aria-label="Search"
                        (focus)="isFocus = true" (blur)="isFocus = false" [(ngModel)]="searchText"
                        [ngClass]="{ 'c-search-input-with-clear': searchText }" style="font-family: FontAwesome, Ubuntu"
                        (ngModelChange)="searchTextChange()">
                    <button class="btn btn-outline-secondary" type="button" *ngIf="searchText" (click)="clear()"
                        [ngClass]="{ 'c-clear': isFocus, 'c-clear-not-focus': !isFocus }">
                        <mat-icon class="c-icon">close</mat-icon>
                    </button>
                </div>
                <pagination-controls class="ms-auto paginate" (pageChange)="currentPage = $event"
                    *ngIf="dataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
                </pagination-controls>
            </div>

            <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                <table class="table table-responsive table-borderless">
                    <thead class="table-header">
                        <tr>
                            <td scope="col" class="col-md-2" mat-sort-header="userid">User ID</td>
                            <td scope="col" class="col-md-2" mat-sort-header="username">User Name</td>
                            <!-- <td scope="col" class="col-md-3" mat-sort-header="requesttype">Request Type</td> -->
                            <td scope="col" class="col-md-2" mat-sort-header="requesttype">Request Type</td>
                            <td scope="col" class="col-md-2" mat-sort-header="requestdate">Request Date</td>
                            <td scope="col" class="col-md-2" mat-sort-header="status">Status</td>
                            <td scope="col" class="col-md-2" mat-sort-header="description">Description</td>
                        </tr>
                    </thead>
                    <tbody *ngIf="tableLoading">
                        <tr>
                            <td colspan="11" class="nodata">
                                <div class="spinner-border" entitle="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!tableLoading">
                        <tr *ngIf="dataList.length == 0">
                            <td colspan="11" class="nodata">Empty</td>
                        </tr>
                        <tr
                            *ngFor="let data of dataList| filter : searchText: 'hrapproval' | paginate : { itemsPerPage: itemPerPage , currentPage : currentPage };let index = index">
                            <td class="td-data td-edit" (click)="viewApproval(data)">
                                <span [title]="data.userid">{{ data.userid }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="data.username">{{ data.username }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="getRequestTypeName(data.typeid)">{{ getRequestTypeName(data.typeid)
                                    }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="allinoneService.formatDBToShow(data.requestdate)">{{
                                    allinoneService.formatDBToShow(data.requestdate)
                                    }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="data.status" class="status" [ngClass]="{
                                    'status-gray': data.status == '001',
                                    'status-main': data.status == '002' || data.status == '003', 
                                    'status-green': data.status == '004',
                                    'status-red': data.status == '005'
                                  }">{{
                                    getTableStatus(data.status)
                                    }}</span>
                            </td>
                            <td class="td-data">
                                <span [title]="data.description">{{
                                    data.description
                                    }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination-controls class="paginate" (pageChange)="currentPage = $event"
                *ngIf="dataList.length > itemPerPage" previousLabel="Prev" nextLabel="Next">
            </pagination-controls>

        </ng-template>

    </div>
</div>