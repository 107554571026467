import { Component, OnInit, Optional, Inject } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';
import { data } from 'jquery';
import { MapComponent } from '../map/map.component'; '@angular/core';

@Component({
  selector: 'app-locations-new',
  templateUrl: './locations-new.component.html',
  styleUrls: ['./locations-new.component.scss']
})
export class LocationsNewComponent implements OnInit {

  role: any;
  locTypeList: any = [];
  locAddorNot = false;
  locListLoading = false;
  userid: any;
  // userid = "+959966680686"

  saveLoading: boolean = false;
  locformSubmitted: boolean = false;
  gettingDomainList: boolean = false;
  gettingOrgList: boolean = false;


  domains: any = []
  organizations: any = []
  locListToShow: any = []


  locForm = {
    "locid": "",
    "loctype": "",
    "domain": "",
    "org": "",
    "locname": '',
    "lat": '',
    "long": '',
    "range": '100',
    "description": ''
  }


  rootLocTypeList = [
    {
      "name": "Global",
      "value": "1"
    },
    {
      "name": "Domain",
      "value": "2"
    },
    {
      "name": "Organization",
      "value": "3"
    },
    {
      "name": "Personal",
      "value": "4"
    },
  ]

  domainLocTypeList = [
    {
      "name": "Domain",
      "value": "2"
    },
    {
      "name": "Organization",
      "value": "3"
    },
    {
      "name": "Personal",
      "value": "4"
    }
  ]

  orgLocTypeList = [
    {
      "name": "Organization",
      "value": "3"
    },
    {
      "name": "Personal",
      "value": "4"
    }
  ]

  constructor(public allinoneService: AllInOneService,
    private dialog: MatDialog,
    public kunyekService: KunyekService,
    public allInOneService: AllInOneService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<LocationsNewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {


    if (data.org != "") {
      this.locForm.org = data.org;
    }

    this.organizations = this.allinoneService.orgs;


    // this.role = this.allinoneService.getRole()
    // this.userid = this.allInOneService.getKunyekUserId()
    // if (this.role == "100") {
    //   this.locTypeList = this.rootLocTypeList
    //   var tempDomain: any = this.allinoneService.getDomain()
    //   this.locForm.domain = tempDomain.domainid
    // }
    // else if (this.role == "200") {
    //   var tempDomain: any = this.allinoneService.getDomain()
    //   this.locForm.domain = tempDomain.domainid
    //   this.locTypeList = this.domainLocTypeList
    // }
    // else if (this.role == "300") {
    //   var tempOrg: any = this.allinoneService.getOrganization()
    //   this.locForm.org = tempOrg.orgid
    //   this.locTypeList = this.orgLocTypeList
    // }
  }

  ngOnInit(): void {

  }

  orgChanged(event: any) {
    this.locForm.org = event.target.value;
    // const data = {
    //   "userid": "",
    //   "domainid": "",
    //   "orgid": this.locForm.org,
    //   "global": "",
    // }
    // this.getLocation(data)
  }


  locTypeChanged(event: any) {
    this.locForm.loctype = event.target.value;
    if (this.locForm.loctype == "1" || this.locForm.loctype == "4") {
      if (this.role == "100" || this.role == "200") {
        this.locForm.org = ""
      }

      if (this.locForm.loctype == "1") {
        const data = {
          "userid": "",
          "domainid": "",
          "orgid": "",
          "global": "1",
        }
        this.getLocation(data)
      }
      else if (this.locForm.loctype == "4") {
        const data = {
          "userid": this.userid,
          "domainid": "",
          "orgid": "",
          "global": "",
        }
        this.getLocation(data)
      }
    }
    else if (this.locForm.loctype == "2") {
      this.locForm.org = ""
      this.locListToShow = []
      const data = {
        "userid": "",
        "domainid": this.locForm.domain,
        "orgid": "",
        "global": "",
      }
      this.getLocation(data)
    }
    else if (this.locForm.loctype == "3") {
      this.locListToShow = []
      if (this.role == "300") {
        const data = {
          "userid": "",
          "domainid": "",
          "orgid": this.locForm.org,
          "global": "",
        }
        this.getLocation(data)
      } else {
        this.getOrganization()
      }
    }
  }


  getOrganization() {
    this.gettingOrgList = true;
    this.organizations = []
    this.kunyekService.getOrgByDomainId(this.locForm.domain).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((org: any) => {
          this.organizations.push({
            orgid: org.orgid,
            appid: org.appid,
            createdby: org.createdby,
            createddate: org.createddate,
            domainid: org.domainid,
            name: org.name,
            description: org.description
          })
        }
        )
        this.gettingOrgList = false;
      }
      else {
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }
    )
  }

  getLocation(data: any) {
    this.locListToShow = []
    this.locListLoading = true
    this.kunyekService.getLocations(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        res.datalist.map((loc: any) => {
          this.locListToShow.push({
            locid: loc.locid,
            lat: loc.lat,
            lng: loc.lng,
            name: loc.name,
            range: loc.range,
            type: loc.type,
            typeid: loc.typeid,
            description: loc.description
          })
        }
        )
        this.locListLoading = false;
      }
      else {
        this.locListLoading = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }
  map() {
    const dialog = this.dialog.open(MapComponent, {
      height: '400px',
      width: '65vw',
      closeOnNavigation: true,
      panelClass: ['map-modal','mat-typography'],
      data: {
        lat: this.locForm.lat,
        long: this.locForm.long
      }
    })

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.locForm.lat = dialogResult.lat;
        this.locForm.long = dialogResult.long;
      }
    });
  }




  submitLocation() {
    this.locformSubmitted = true;
    if (this.locForm.org == "") {
      return;
    }
    else if (this.locForm.locname != '' && this.locForm.lat != "" && this.locForm.long != "" && this.locForm.range != "") {
      var reg = new RegExp('^[0-9]+(\.[0-9]+)*$')
      var templat = this.locForm.lat;
      var templong = this.locForm.long;
      if (templat.toString().includes('-')) {
        templat = templat.toString().substring(1);
      }

      if (templong.toString().includes('-')) {
        templong = templong.toString().substring(1);
      }

      console.log(templat + "," + templong);

      if (reg.test(templat) && reg.test(templong) && reg.test(this.locForm.range)) {
        this.saveLoading = true;
        this.saveLocation();
      }
    }
    else {
      return;
    }
  }

  saveLocation() {
    var data;

    data = {
      "name": this.locForm.locname,
      "lat": this.locForm.lat.toString(),
      "lng": this.locForm.long.toString(),
      "range": this.locForm.range.toString(),
      "description": this.locForm.description,
      "typeid": this.locForm.org.toString(),
      "type": "3",
      "domainid" : this.allInOneService.getDomain().domainid,
      "orgid": this.allinoneService.selectedOrg
    }

    this.kunyekService.addLocation(data).subscribe((res: any) => {
      if (res.returncode == "300") {
        this.locAddorNot = !this.locAddorNot;
        this.saveLoading = false;
        this.locformSubmitted = false;

        this.locForm = {
          "locid": "",
          "loctype": this.locForm.loctype,
          "domain": this.locForm.domain,
          "org": this.locForm.org,
          "locname": '',
          "lat": '',
          "long": '',
          "range": '100',
          "description": ''
        }
        this.messageService.openSnackBar(res.message, 'success');
        this.dialogRef.close(true);
      }
      else {
        this.saveLoading = false;
        this.locformSubmitted = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    }, err => {
      this.saveLoading = false;
      this.locformSubmitted = false;
    })
  }

}
