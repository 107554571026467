import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar/lib/perfect-scrollbar.interfaces';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-customer-name',
  templateUrl: './customer-name.component.html',
  styleUrls: ['./customer-name.component.scss']
})
export class CustomerNameComponent implements OnInit {
  customernameList: any = [];
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };
  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<CustomerNameComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log(data);
    this.customernameList  = data.customernamelist;
  }


  ngOnInit(): void {

   
  }

  search() {
    var data = {
      "customernamelist": this.customernameList
    }
    this.dialogRef.close({
      data: data,
    });
  }


}
