import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-member-payment-history',
  templateUrl: './member-payment-history.component.html',
  styleUrls: ['./member-payment-history.component.scss']
})
export class MemberPaymentHistoryComponent implements OnInit {

  @Input('currentMember') currentMember: any;
  @Output('closeForm') closeForm = new EventEmitter();
  history: Array<any> = [];
  getting: boolean = true;
  amember: any;
  bmembers: Array<any> = [];
  selectedHisotry: any;
  isOpenDetail: boolean = false;
  totalAmount = 0;
  currency: string = "";
  cashby: string = "";
  invoiceid: string = "";
  invoicedate: string = "";

  breadcrumbs: any[] = [
    { name: 'Payment', url: '/payment', isActive: true },
    { name: 'Member Payment', url: 'members/payment', isActive: true },
    { name: 'History', url: '', isActive: false }
  ];


  constructor(
    private kunyekService: KunyekService,
    private allInOneService: AllInOneService
  ) { }

  ngOnInit(): void {
    this.getHistory()
  }

  printPage() {
    const printContent = document.getElementById('printableCard');
    if (printContent) {
      const printClone = printContent.cloneNode(true);
      const printContainer = document.createElement('div');
      printContainer.style.position = 'absolute';
      printContainer.style.left = '-9999px';
      printContainer.appendChild(printClone);
      document.body.appendChild(printContainer);
      const style = document.createElement('style');
      style.innerHTML = `
        @media print {
          .me-auto, .navbar, .side-nav, .side-nav-expand, .btn-action {
            display: none !important;
          }
          #print-container, #print-container * {
            visibility: visible;
          }
          #print-container {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      `;
      document.head.appendChild(style);
      printContainer.id = 'print-container';
      window.print();
      document.body.removeChild(printContainer);
      document.head.removeChild(style);
    } else {
      console.error('printableCard element not found');
    }
  }



  exportPDF() {
    const name = this.amember?.member.name + "-" || '';
    const data = document.getElementById('printableCard');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        let position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${name}payment-history.pdf`);

      }).catch(error => {
        console.error('Error generating PDF:', error);
      });
    } else {
      console.error('printableCard element not found');
    }
  }


  handleClose() {
    this.history = [];
    this.closeForm.emit();
  }

  handleSelect(history: any) {
    this.isOpenDetail = true;
    this.selectedHisotry = history;
    this.currency = history.currency;
    this.cashby = history.cashby;
    this.invoiceid = history.autoid;
    this.invoicedate = this.formateDate(history.datetime);
    this.totalAmount = history.total_amount;
    let amember = history.details?.find((item: any) => item.member_id == this.currentMember.syskey);
    amember = {
      ...amember,
      fees: JSON.parse(amember.fees)
    }
    const bmembers = history.details
      .filter((item: any) => item.member_id != this.currentMember.syskey)
      .map((item: any) => ({
        ...item,
        fees: JSON.parse(item.fees)
      }));

    this.amember = amember;
    this.bmembers = bmembers;
  }

  getHistory() {
    const data = {
      member_id: this.currentMember.syskey
    }
    this.getting = true;
    this.kunyekService.getPyamentHistory(data).subscribe({
      next: (res) => {
        if (res.returncode == "300") {
          this.history = res.datalist;

        }
        this.getting = false;
      }
    })
  }

  formateDate(date: string) {
    if (date == '') return '';
    const pattern = 'dd-MM-yyyy';
    const date1 = formatDate(new Date(date), pattern, 'en-US');
    return date1;
  }

}
