import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { RequisitionReasonComponent } from '../requisition-reason/requisition-reason.component';
import { ReasonComponent } from '../reason/reason.component';
import { RequisitionUserInfoComponent } from '../requisition-user-info/requisition-user-info.component';
import { HrRequisitionFilterComponent } from '../hr-requisition-filter/hr-requisition-filter.component';

@Component({
  selector: 'app-hr-requisition',
  templateUrl: './hr-requisition.component.html',
  styleUrls: ['./hr-requisition.component.scss'],
})
export class HrRequisitionComponent implements OnInit {
  isAdmin: boolean = false;
  isMobileView: boolean = false;
  isDetailView: boolean = false;
  isEditing: boolean = false;
  saveLoading: boolean = false;
  isApproving: boolean = false;
  isRejecting: boolean = false;
  completeLoading: boolean = false;
  subscriptions!: Subscription;
  getTemplateSubscription!: Subscription;
  isFocus: boolean = false;

  listLoading: boolean = false;
  searchText: string = '';
  orgid: string = '';
  formSubmitted: boolean = false;

  approvalList: any = [];

  mobileViewWidth: number = 426;
  itemPerPage: number = 100;
  currentPage: number = 1;

  organizations: any = [];
  templateList: any = [];
  departmentList: any = [];
  divisionList: any = [];
  positionList: any = [];
  gettingApprovals: boolean = false;
  gettingPositions: boolean = false;
  isFiltering: boolean = false;

  selectedRequest: any = '';
  searchPriorityName: string = '';
  searchPositionName: string = '';

  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Submitted',
      value: '001',
    },
    {
      name: 'Processed',
      value: '002',
    },
    {
      name: 'Rejected',
      value: '003',
    },
    {
      name: 'Completed',
      value: '004',
    },
  ];

  priorityList = [
    { id: '', name: 'All' },
    { id: '0010', name: 'Low' },
    { id: '00500', name: 'Medium' },
    { id: '00900', name: 'High' },
  ];

  searchForm = {
    status: '',
    startdate: '',
    enddate: '',
    position: '',
    priority: '',
    department: '',
    division: '',
  };


  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  submitForm = {
    name: '',
    people: '',
    hrremark: '',
    requeststatus: '001',
    requeststatusname: 'Submitted',
  };

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private filterPipe: FilterPipe
  ) {
    if (!this.allinoneService.isAdminOf('hr')) {
      this.router.navigateByUrl('/hxm');
    }
  }

  ngOnInit(): void {
    this.organizations = this.allinoneService.orgs;
    this.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.getTemplates();
    this.getApprovalList();
  }

  openMemberInfo(data: any) {
    console.log(data);
    var id = data.requisitionid;
    const dialog = this.dialog.open(RequisitionUserInfoComponent, {
      maxWidth: '95vw',
      width: '1200px',
      height: '565px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        orgid: this.orgid,
        requisitionid: id,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
      }
    });
  }

  submit() {
    this.formSubmitted = true;
    if (this.submitForm.people == '' || this.submitForm.people == null) {
      this.messageService.openSnackBar('No. of people cannot blank.', 'warn');
      return;
    }
    if (Number(this.submitForm.people) < 1) {
      this.messageService.openSnackBar(
        'No. of people cannot less than 1.',
        'warn'
      );
      return;
    }
    this.saveLoading = true;
    var data = {
      orgid: this.orgid,
      people: this.submitForm.people.toString().trim(),
      updatepeople: true,
      requisitionid: this.selectedRequest.requisitionid,
      requisitiondate: '',
      positions: '',
      remark: '',
      priority: '',
      product: '',
      project: '',
      department: '',
      division: '',
      position: '',
      targetdate: '',
      requirements: '',
      descriptions: '',
      role: '700',
    };
    this.kunyekService.updateRequisitionRequest(data).subscribe(
      (res: any) => {
        console.log(res);
        if (res.returncode == '300') {
          var index = this.approvalList.findIndex(
            (x: any) => x.requisitionid == data.requisitionid
          );
          if (index > -1) {
            this.approvalList[index].people = this.submitForm.people;
            this.messageService.openSnackBar('Successful.', 'success');
            // this.isDetailView = false;
          } else {
            this.cancel();
            this.refresh();
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.saveLoading = false;
      },
      (err: any) => {
        this.messageService.openSnackBar('Server Error', 'warn');
        this.saveLoading = false;
      }
    );
  }

  getTemplates() {
    this.listLoading = true;
    const data = {
      orgid: this.orgid,
    };
    this.getTemplateSubscription && this.getTemplateSubscription.unsubscribe();
    this.getTemplateSubscription = this.kunyekService
      .getRequisitionTemplate(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.templateList = res.datalist;
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.listLoading = false;
        },
        (err) => {
          this.listLoading = false;
        }
      );
  }

  rejectReason() {
    const dialog = this.dialog.open(RequisitionReasonComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        orgid: this.orgid,
        requisitionid: this.selectedRequest.requisitionid,
        people: this.submitForm.people.toString().trim(),
        hrremark: this.submitForm.hrremark.toString().trim(),
        role: '700',
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.cancel();
        this.refresh();
      }
    });
  }

  getApprovalList() {
    this.gettingApprovals = true;
    const data = {
      orgid: this.orgid,
      status: this.submitForm.requeststatus,
      startdate: this.searchForm.startdate,
      enddate: this.searchForm.enddate,
      position: this.searchForm.position,
      priority: this.searchForm.priority,
      department: this.searchForm.department,
      division: this.searchForm.division,
    };
    this.subscriptions && this.subscriptions.unsubscribe();
    this.subscriptions = this.kunyekService.getHrRequisition(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.approvalList = [];
          res.datalist.map((x: any) => {
            var positions = '';
            x.positions.map((p: any, index: number) => {
              if (index == 0) {
                positions += p.name;
              } else {
                positions += ', ' + p.name;
              }
            });
            var temp = {
              ...x,
              positionlists: positions,
              statusname: this.statusList.find(
                (z: any) => z.value == x.hrstatus
              )?.name,
            };
            this.approvalList.push(temp);
          });
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.gettingApprovals = false;
      },
      (err) => {
        this.gettingApprovals = false;
      }
    );
  }

  statusChange(status: any, refresh: boolean = false) {
    let shouldRefresh = refresh;
    if (this.submitForm.requeststatus != status.value) {
      this.submitForm.requeststatus = status.value;
      this.submitForm.requeststatusname = status.name;
      shouldRefresh = true;
    }
    if(shouldRefresh){
      this.refresh();
    }
  }

  searchTextChange() {}

  viewApproval(data: any) {
    this.isDetailView = true;
    this.selectedRequest = data;
    this.submitForm.people = this.selectedRequest.people;
    this.submitForm.hrremark = this.selectedRequest.hrremark;
  }

  sortData(sort: Sort) {
    const data = this.approvalList;
    if (!sort.active || sort.direction === '') {
      this.approvalList = data;
      return;
    }
    this.approvalList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'refno':
          return this.allinoneService.compare(a.refno, b.refno, isAsc);
        case 'positions':
          return this.allinoneService.compare(
            a.positionname,
            b.positionname,
            isAsc
          );
        case 'approved':
          return this.allinoneService.compare(
            // data.approvedbyname || data.approvedby
            a.approvedbyname || a.approvedby,
            b.approvedbyname || b.approvedby,
            isAsc
          );
        case 'people':
          return this.allinoneService.compare(
            a.people * 1,
            b.people * 1,
            isAsc
          );
        case 'priority':
          return this.allinoneService.compare(a.priority, b.priority, isAsc);
        case 'requisitiondate':
          return this.allinoneService.compare(
            a.requisitiondate,
            b.requisitiondate,
            isAsc
          );
        case 'targetdate':
          return this.allinoneService.compare(
            a.targetdate,
            b.targetdate,
            isAsc
          );
        case 'requestedby':
          return this.allinoneService.compare(
            a.createdbyname || a.createdby,
            b.createdbyname || b.createdby,
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(a.remark, b.remark, isAsc);
        case 'hrstatus':
          return this.allinoneService.compare(a.hrstatus, b.hrstatus, isAsc);
        case 'completeby':
          return this.allinoneService.compare(
            // a.completeby,
            // b.completeby,
            // data.completedbyname || data.completedby
            a.completedbyname || a.completedby,
            b.completedbyname || b.completedby,
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  submitApproval(status: string) {
    this.formSubmitted = true;
    if (status == '002') {
      if (this.submitForm.people == '' || this.submitForm.people == null) {
        this.messageService.openSnackBar('No. of people cannot blank.', 'warn');
        return;
      }
      if (Number(this.submitForm.people) < 1) {
        this.messageService.openSnackBar(
          'No. of people cannot less than 1.',
          'warn'
        );
        return;
      }
    }

    if (status == '004') {
      if (this.submitForm.hrremark == '' || this.submitForm.hrremark == null) {
        this.scrollToBottom();
        this.messageService.openSnackBar('Comment cannot blank.', 'warn');
        return;
      }
    }

    var tempmessage = '';
    if (status == '002') {
      tempmessage = 'process';
    } else if (status == '004') {
      tempmessage = 'complete';
    } else {
      tempmessage = 'reject';
    }
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      data: {
        cmessage:
          'Are you sure to ' +
          // (status == '002' ? 'process' : 'reject') +
          tempmessage +
          ' Ref No. ' +
          this.selectedRequest.refno +
          '?',
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (status == '002') {
          this.isApproving = true;
        } else if (status == '004') {
          this.completeLoading = true;
        } else {
          this.isRejecting = true;
        }
        const data = {
          orgid: this.orgid,
          requisitionid: this.selectedRequest.requisitionid,
          status: status,
          people: this.submitForm.people.toString().trim(),
          role: '700',
          reason: '',
          hrremark: this.submitForm.hrremark,
        };

        this.kunyekService.approveRequisition(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.messageService.openSnackBar('Successful.', 'success');
              this.refresh();
              this.cancel();
            } else {
              this.messageService.openSnackBar(
                res.message || res.status || 'Server Error',
                'warn'
              );
            }
            this.completeLoading = false;
            this.isApproving = false;
            this.isRejecting = false;
          },
          (err) => {
            this.completeLoading = false;
            this.isApproving = false;
            this.isRejecting = false;
          }
        );
      }
    });
  }

  clear() {
    this.searchText = '';
  }

  changePageCount(event: any) {}

  refresh() {
    this.currentPage = 1;
    this.getApprovalList();
  }

  cancel() {
    this.isDetailView = false;
    this.submitForm = {
      name: '',
      people: '',
      hrremark: '',
      requeststatus: this.submitForm.requeststatus,
      requeststatusname: this.submitForm.requeststatusname,
    };
  }

  goToRecruitment() {
    this.router.navigate(['/teams/recruitment']);
  }

  scrollToBottom() {
    let commentBox = document.getElementById('hrremark');
    commentBox?.scrollIntoView();
  }

  export() {
    var exdata: any = [];

    var name = 'Requisitions.xlsx';

    this.approvalList.map((item: any) => {
      var temp = {
        'Ref No.': item.refno,
        Position: item.positionname,
        'No. of Opening': item.people,
        Priority:
          item.priority == '0010'
            ? 'Low'
            : item.priority == '00500'
            ? 'Medium'
            : 'High',
        'Requisition Date': this.allinoneService.formatDBToShow(
          item.requisitiondate
        ),
        'Recruitment Date': this.allinoneService.formatDBToShow(
          item.targetdate
        ),
        Status: item.statusname,
        'Requested By': item.createdbyname
          ? item.createdbyname
          : item.createdby,
        'Approved By': item.approvedbyname
          ? item.approvedbyname
          : item.approvedby,
        Remark: item.remark,
      };
      exdata.push(temp);
    });
    this.allinoneService.exportEcecl(exdata, name);
  }

  filter() {
    const dialog = this.dialog.open(HrRequisitionFilterComponent, {
      maxWidth: '95vw',
      width: '600px',
      maxHeight: '95vh',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
      autoFocus: false,
      data: {
        orgid: this.orgid,
        statusList: this.statusList,
        priorityList: this.priorityList,
        departmentList: this.departmentList,
        divisionList: this.divisionList,
        positionList: this.positionList,
        searchForm: this.searchForm,
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult && dialogResult.departmentList) {
        this.departmentList = dialogResult.departmentList;
        this.divisionList = dialogResult.divisionList;
        this.positionList = dialogResult.positionList;
      }
      if (dialogResult && dialogResult.searchForm) {
        this.searchForm = dialogResult.searchForm;
        this.isFiltering = true;
        this.searchPriorityName = this.priorityList.find((x:any) => x.id === this.searchForm.priority)?.name || 'All';
        this.searchPositionName = this.positionList.find((x:any) => x.reqtempid === this.searchForm.position)?.name || 'All';
        const status = this.statusList.find((x: any) => x.value == this.searchForm.status);
        this.statusChange(status, true);
      }
      
    });
  }

  clearFilter() {
    this.searchForm = {
      status: '',
      startdate: '',
      enddate: '',
      position: '',
      priority: '',
      department: '',
      division: '',
    };
    this.isFiltering = false;
  }
}
