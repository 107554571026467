import { Component, OnInit } from '@angular/core';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';

@Component({
  selector: 'app-warranty',
  templateUrl: './warranty.component.html',
  styleUrls: ['./warranty.component.css'],
})
export class WarrantyComponent implements OnInit {
  isEditting: boolean = false;
  editData: any;
  isLoading: boolean = false;
  warranties: any = [];

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
  ) {}

  ngOnInit(): void {
    this.getWarranty();
  }

  getWarranty() {
    const data = {
      orgid: this.allinoneService.selectedOrg,
      userid: this.allinoneService.getUserId(),
      domainid: this.allinoneService.getDomain(),
    };
    this.isLoading = true;
    this.kunyekService.getWarranty(data).subscribe({
      next: (res) => {
        if (res.returncode == '300') {
          this.isLoading = false;
          this.warranties = res.list;
        } else {
          throw res.message;
        }
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err);
      },
    });
  }

  handleEditData(data: any) {
    this.editData = data;
    this.isEditting = true;
  }

  handleNewWarranty() {
    this.editData = null;
    this.isEditting = true;
  }

  closeEdit() {
    this.isEditting = false;
    this.warranties = [];
    this.getWarranty();
  }
}
