<section class="container-fluid">
    <ng-container *ngIf="isHRAdmin;else noAccessTemplate">
      <div class="content">
        <!-- <app-hcm-breadcrumb [s_breadcrumb]="'Recruitment'">
        </app-hcm-breadcrumb> -->
        <app-team-breadcrumb [s_breadcrumb]="'Recruitment'">
        </app-team-breadcrumb>
        <ng-container *ngFor="let admin of menus">
          <div class="admin-title mt-3" *ngIf="admin.list">
            {{admin.name}}
          </div>
          <div class="row g-0">
    
            <ng-container *ngFor="let item of admin.list; let index = index">
              <!-- <div
                *ngIf="((allinoneService.removeContactTracing && item.path != '/contacttracing' && item.path != '/campaigns' && item.path != '/admin/campaign' ) || !allinoneService.removeContactTracing) && !isArrayFun(item)"
                class="p-2 ps-0"> -->
                <!-- col-lg-2 col-md-2 col-sm-4 col-6  -->
                <!-- [routerLink]="item.path"  -->
                <div class="me-2 mb-2 card d-flex flex-row justify-content-between p-2 dashboard-custom-card col-sm-4 col-6" (click)="goTo(item)">
                  <div class='d-flex flex-column'>
                    <div class="mb-2">
                      <img [src]="item.icon" width="30px">
                    </div>
                    <div class="name">
                      {{item.name}}
                    </div>
                  </div>
                  <div *ngIf="requisitionCount > 0 && item.name == 'Requsitions'">
                    <div class="bg-danger custom-badge">
                      {{requisitionCount}}
                    </div>
                  </div>
                </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noAccessTemplate>
      <div class="no-access">
        <div>
          You don't have permission to access Employee Central.
        </div>
      </div>
    </ng-template>
</section>