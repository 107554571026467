import { data } from 'jquery';
import { DatePipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { CrmCustomerDealComponent } from '../crm-customer-deal/crm-customer-deal.component';
import { CrmServiceFilterbydateComponent } from '../crm-service-filterbydate/crm-service-filterbydate.component';
import { PipelineTotalamountComponent } from '../pipeline-totalamount/pipeline-totalamount.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { iif } from 'rxjs';

@Component({
  selector: 'app-crm-work-flow',
  templateUrl: './crm-work-flow.component.html',
  styleUrls: ['./crm-work-flow.component.css']
})
export class CrmWorkFlowComponent implements OnInit {

  data: any = [
    // { "name": "HR", "code": "001" },
    // { "name": "Manger", "code": "003" }

  ];

  alllist: any = [
    {
      "title": "Service",
      "appid": "4",
      "appname": "Service Tickets",
      "icon": "assets/images/service.png",
      "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "null",
    },
    {
      "title": "",
      "appid": "5",
      "appname": "Service Summary",
      "icon": "assets/images/servicesummary.png",
      "value": "",
      "view": "",
      "create": "null",
      "edit": "null",
      "delete": "null",
    },
    {
      "title": "", "appid": "10", "appname": "Service Type", "icon": "assets/images/servicetype.png", "value": "",
      "view": "",
      "create": "null",
      "edit": "",
      "delete": "null",
    },
    {
      "title": "", "appid": "11", "appname": "Service Status", "icon": "assets/images/servicestatus.png", "value": "",
      "view": "",
      "create": "null",
      "edit": "",
      "delete": "null",
    },
    {
      "title": "Sales", "appid": "1", "appname": "Leads", "icon": "assets/images/leads.png", "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    {
      "title": "", "appid": "2", "appname": "Deals", "icon": "assets/images/deals.png", "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    {
      "title": "", "appid": "3", "appname": "Pipeline", "icon": "assets/images/pipeline.png", "value": "",
      "view": "",
      "create": "null",
      "edit": "null",
      "delete": "null",
    },
    {
      "title": "", "appid": "8", "appname": "Customers", "icon": "assets/images/customers.png", "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    // { "title": "References", "appid": "6", "appname": "Projects", "icon": this.allinoneService.bucketUrl + "policyicons/projects.png", "value": "" },
    {
      "title": "References", "appid": "7", "appname": "Products", "icon": "assets/images/product.png", "value": "", "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    {
      "title": "", "appid": "9", "appname": "Person in Charge", "icon": this.allinoneService.bucketUrl + "policyicons/members.png", "value": "", "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
  ]
  list: any = [
    {
      "title": "Service",
      "appid": "4",
      "appname": "Service Tickets",
      "icon": "assets/images/service.png",
      "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "null",
    },
    {
      "title": "",
      "appid": "5",
      "appname": "Service Summary",
      "icon": "assets/images/servicesummary.png",
      "value": "",
      "view": "",
      "create": "null",
      "edit": "null",
      "delete": "null",
    },
    {
      "title": "",
      "appid": "10",
      "appname": "Service Type",
      "icon": "assets/images/servicetype.png", "value": "",
      "view": "",
      "create": "null",
      "edit": "",
      "delete": "null",
    },
    {
      "title": "",
      "appid": "11",
      "appname": "Service Status",
      "icon": "assets/images/servicestatus.png", "value": "",
      "view": "",
      "create": "null",
      "edit": "",
      "delete": "null",
    },
    {
      "title": "Sales",
      "appid": "1",
      "appname": "Leads",
      "icon": "assets/images/leads.png", "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    {
      "title": "",
      "appid": "2",
      "appname": "Deals",
      "icon": "assets/images/deals.png",
      "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    {
      "title": "",
      "appid": "3",
      "appname": "Pipeline",
      "icon": "assets/images/pipeline.png",
      "value": "",
      "view": "",
      "create": "null",
      "edit": "null",
      "delete": "null",
    },
    {
      "title": "",
      "appid": "8",
      "appname": "Customers",
      "icon": "assets/images/customers.png",
      "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    
    // { "title": "References", "appid": "6", "appname": "Projects", "icon": this.allinoneService.bucketUrl + "policyicons/projects.png", "value": "" },
    {
      "title": "References", "appid": "7", "appname": "Products", "icon": "assets/images/product.png", "value": "", "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    {
      "title": "", "appid": "9", "appname": "Person in Charge", "icon": this.allinoneService.bucketUrl + "policyicons/members.png", "value": "", "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
    {
      "title": "",
      "appid": "10",
      "appname": "Customer Groups",
      "icon": "assets/images/customers.png",
      "value": "",
      "view": "",
      "create": "",
      "edit": "",
      "delete": "",
    },
  ]
  gettingData: boolean = false;
  isPermission: boolean = false;
  roleListLoading: boolean = false;
  organizations: any = [];
  saveLoading: boolean = false;
  name: any = '';
  desc: any = '';
  orgid: any = '';
  check: any = '';
  permission: any = [];
  roleid: any = '';

  constructor(
    public allinoneService: AllInOneService,
    private kunyekService: KunyekService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private injector: Injector,
  ) {
    this.organizations = this.allinoneService.orgsCRm;
    this.orgid = this.allinoneService.selectedOrgCRM
      ? this.allinoneService.selectedOrgCRM
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    if (this.allinoneService.crmBlocked == 'true') {
      this.messageService.openSnackBar(this.allinoneService.unauthorizedAccessMessage, 'warn');
    } else {
      if (this.allinoneService.crmOrgAdmin == 'true') {
      } else {
        this.router.navigateByUrl(`sales`);
      }
    }
    this.getRoleList();
  }

  cancelFunction() {
    this.isPermission = false;
    this.permission = [];
    console.log(this.list);
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].value = '';
      if (this.list[i].view != 'null') {
        this.list[i].view = '';
      }
      if (this.list[i].create != 'null') {
        this.list[i].create = '';
      }

      if (this.list[i].edit != 'null') {
        this.list[i].edit = '';
      }
      if (this.list[i].delete != 'null') {
        this.list[i].delete = '';
      }
    }
    console.log(this.list);
  }


  addpermission(data: any) {
    this.isPermission = true;
    console.log(data);
    this.name = data.name;
    this.desc = data.desc;

    this.permission = JSON.parse(JSON.stringify(data.permission));
    this.roleid = data.roleid;

    for (let i = 0; i < this.list.length; i++) {
      if (!this.list[i].value) {
        var type = this.permission.map((x: any) => x).indexOf('001');

        if (type > -1) {
          this.list[0].value = '001';
        }
        var type1 = this.permission.map((x: any) => x).indexOf('002');

        if (type1 > -1) {
          this.list[1].value = '002';
        }
        var type2 = this.permission.map((x: any) => x).indexOf('003');

        if (type2 > -1) {
          this.list[2].value = '003';
        }
        var type3 = this.permission.map((x: any) => x).indexOf('004');

        if (type3 > -1) {
          this.list[3].value = '004';
        }
        var type4 = this.permission.map((x: any) => x).indexOf('005');

        if (type4 > -1) {
          this.list[4].value = '005';
        }

        var type5 = this.permission.map((x: any) => x).indexOf('006');

        if (type5 > -1) {
          this.list[5].value = '006';
        }
        var type6 = this.permission.map((x: any) => x).indexOf('007');

        if (type6 > -1) {
          this.list[6].value = '007';
        }

        var type7 = this.permission.map((x: any) => x).indexOf('008');

        if (type7 > -1) {
          this.list[7].value = '008';
        }

        // var type8 = this.permission.map((x: any) => x).indexOf('009');

        // if (type8 > -1) {
        //   this.list[8].value = '009';
        // }
        var type9 = this.permission.map((x: any) => x).indexOf('0010');

        if (type9 > -1) {
          this.list[8].value = '0010';
        }
        var type10 = this.permission.map((x: any) => x).indexOf('0011');

        if (type10 > -1) {
          this.list[9].value = '0011';
        }

        var type11 = this.permission.map((x: any) => x).indexOf('00080');

        if (type11 > -1) {
          this.list[10].value = '00080';
        }

      }    // service ticket
      var s1 = this.permission.map((x: any) => x).indexOf('011');

      if (s1 > -1) {
        this.list[0].view = '011';
      }
      var s2 = this.permission.map((x: any) => x).indexOf('0012');

      if (s2 > -1) {
        this.list[0].create = '0012';
      }
      var s3 = this.permission.map((x: any) => x).indexOf('0013');

      if (s3 > -1) {
        this.list[0].edit = '0013';
      }
      var s4 = this.permission.map((x: any) => x).indexOf('0014');

      if (s4 > -1) {
        this.list[0].delete = '0014';
      }

      // service summary 
      var sm1 = this.permission.map((x: any) => x).indexOf('0021');

      if (sm1 > -1) {
        this.list[1].view = '0021';
      }
      var sm2 = this.permission.map((x: any) => x).indexOf('0022');

      if (sm2 > -1) {
        this.list[1].create = '0022';
      }
      var sm3 = this.permission.map((x: any) => x).indexOf('0023');

      if (sm3 > -1) {
        this.list[1].edit = '0023';
      }
      var sm4 = this.permission.map((x: any) => x).indexOf('0024');

      if (sm4 > -1) {
        this.list[1].delete = '0024';
      }

      // service type
      var st1 = this.permission.map((x: any) => x).indexOf('0031');

      if (st1 > -1) {
        this.list[2].view = '0031';
      }
      var st2 = this.permission.map((x: any) => x).indexOf('0032');

      if (st2 > -1) {
        this.list[2].create = '0032';
      }
      var st3 = this.permission.map((x: any) => x).indexOf('0033');

      if (st3 > -1) {
        this.list[2].edit = '0033';
      }
      var st4 = this.permission.map((x: any) => x).indexOf('0034');

      if (st4 > -1) {
        this.list[2].delete = '0034';
      }

      // service status 
      var ss1 = this.permission.map((x: any) => x).indexOf('0041');

      if (ss1 > -1) {
        this.list[3].view = '0041';
      }
      var ss2 = this.permission.map((x: any) => x).indexOf('0042');

      if (ss2 > -1) {
        this.list[3].create = '0042';
      }
      var ss3 = this.permission.map((x: any) => x).indexOf('0043');

      if (ss3 > -1) {
        this.list[3].edit = '0043';
      }
      var ss4 = this.permission.map((x: any) => x).indexOf('0044');

      if (ss4 > -1) {
        this.list[3].delete = '0044';
      }
      // leads 
      var l1 = this.permission.map((x: any) => x).indexOf('0051');

      if (l1 > -1) {
        this.list[4].view = '0051';
      }
      var l2 = this.permission.map((x: any) => x).indexOf('0052');

      if (l2 > -1) {
        this.list[4].create = '0052';
      }
      var l3 = this.permission.map((x: any) => x).indexOf('0053');

      if (l3 > -1) {
        this.list[4].edit = '0053';
      }
      var l4 = this.permission.map((x: any) => x).indexOf('0054');

      if (l4 > -1) {
        this.list[4].delete = '0054';
      }

      // deals
      var d1 = this.permission.map((x: any) => x).indexOf('0061');

      if (d1 > -1) {
        this.list[5].view = '0061';
      }
      var d2 = this.permission.map((x: any) => x).indexOf('0062');

      if (d2 > -1) {
        this.list[5].create = '0062';
      }
      var d3 = this.permission.map((x: any) => x).indexOf('0063');

      if (d3 > -1) {
        this.list[5].edit = '0063';
      }
      var d4 = this.permission.map((x: any) => x).indexOf('0064');

      if (d4 > -1) {
        this.list[5].delete = '0064';
      }

      // pipeline 
      var p1 = this.permission.map((x: any) => x).indexOf('0071');

      if (p1 > -1) {
        this.list[6].view = '0071';
      }
      var p2 = this.permission.map((x: any) => x).indexOf('0072');

      if (p2 > -1) {
        this.list[6].create = '0072';
      }
      var p3 = this.permission.map((x: any) => x).indexOf('0073');

      if (p3 > -1) {
        this.list[6].edit = '0073';
      }
      var p4 = this.permission.map((x: any) => x).indexOf('0074');

      if (p4 > -1) {
        this.list[6].delete = '0074';
      }

      // customers
      var c1 = this.permission.map((x: any) => x).indexOf('0081');

      if (c1 > -1) {
        this.list[7].view = '0081';
      }
      var c2 = this.permission.map((x: any) => x).indexOf('0082');

      if (c2 > -1) {
        this.list[7].create = '0082';
      }
      var c3 = this.permission.map((x: any) => x).indexOf('0083');

      if (c3 > -1) {
        this.list[7].edit = '0083';
      }
      var c4 = this.permission.map((x: any) => x).indexOf('0084');

      if (c4 > -1) {
        this.list[7].delete = '0084';
      }

      // product
      var pd1 = this.permission.map((x: any) => x).indexOf('00101');

      if (pd1 > -1) {
        this.list[8].view = '00101';
      }
      var pd2 = this.permission.map((x: any) => x).indexOf('00102');

      if (pd2 > -1) {
        this.list[8].create = '00102';
      }
      var pd3 = this.permission.map((x: any) => x).indexOf('00103');

      if (pd3 > -1) {
        this.list[8].edit = '00103';
      }
      var pd4 = this.permission.map((x: any) => x).indexOf('00104');

      if (pd4 > -1) {
        this.list[8].delete = '00104';
      }

      // PIC
      var pi1 = this.permission.map((x: any) => x).indexOf('00111');

      if (pi1 > -1) {
        this.list[9].view = '00111';
      }
      var pi2 = this.permission.map((x: any) => x).indexOf('00112');

      if (pi2 > -1) {
        this.list[9].create = '00112';
      }
      var pi3 = this.permission.map((x: any) => x).indexOf('00113');

      if (pi3 > -1) {
        this.list[9].edit = '00113';
      }
      var pi4 = this.permission.map((x: any) => x).indexOf('00114');

      if (pi4 > -1) {
        this.list[9].delete = '00114';
      }

      // customer group
      var custg = this.permission.map((x: any) => x).indexOf('00081');

      if (custg > -1) {
        this.list[10].view = '00081';
      }
      var custg = this.permission.map((x: any) => x).indexOf('00082');

      if (custg > -1) {
        this.list[10].create = '00082';
      }
      var custg = this.permission.map((x: any) => x).indexOf('00083');

      if (custg > -1) {
        this.list[10].edit = '00083';
      }
      var custg = this.permission.map((x: any) => x).indexOf('00084');

      if (custg > -1) {
        this.list[10].delete = '00084';
      }
      // for (let j = 0; j < this.permission; j++) {


      // }
    }
    console.log(this.data);
    console.log(this.list)
  }

  getRoleList() {

    this.roleListLoading = true;
    let data = {
      "orgid": this.orgid,
      "domainid": this.allinoneService.getDomain().domainid
    }

    this.kunyekService.getRoleList(data).subscribe(res => {
      if (res.returncode == 300) {
        this.data = [];
        res.datalist.map((role: any) => {
          if (role.type == '002') {
            this.data.push({
              desc: role.desc,
              name: role.name,
              orgid: role.orgid,
              permission: role.permission,
              roleid: role.roleid,
              type: role.type,
            })
          }

        });
        this.roleListLoading = false;
      } else {
        this.roleListLoading = false;
        this.messageService.openSnackBar(
          res.message,
          'warn'
        )
      }
    }, err => {
      this.roleListLoading = false;
      this.messageService.openSnackBar(
        err.message,
        'warn'
      )
    })
  }

  checkNewpermission(event: any, data: any, index: any, show: any) {

    var view = this.permission.map((x: any) => x).indexOf(data.view);
    var create = this.permission.map((x: any) => x).indexOf(data.create);
    var edit = this.permission.map((x: any) => x).indexOf(data.edit);
    var delet = this.permission.map((x: any) => x).indexOf(data.delete);
    console.log(view);
    console.log(create);
    console.log(edit);
    console.log(delet);
    console.log(index);
    if (event.target.checked == true) {

      if (index == 0) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('011')
            this.list[index].view = '011';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0012')
            this.list[index].create = '0012';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0013')
            this.list[index].edit = '0013';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0014')
            this.list[index].delete = '0014';
          }
        }
      }
      if (index == 1) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0021')
            this.list[index].view = '0021';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0022')
            this.list[index].create = '0022';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0023')
            this.list[index].edit = '0023';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0024')
            this.list[index].delete = '0024';
          }
        }
      }
      if (index == 2) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0031')
            this.list[index].view = '0031';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0032')
            this.list[index].create = '0032';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0033')
            this.list[index].edit = '0033';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0034')
            this.list[index].delete = '0034';
          }
        }
      }
      if (index == 3) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0041')
            this.list[index].view = '0041';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0042')
            this.list[index].create = '0042';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0043')
            this.list[index].edit = '0043';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0044')
            this.list[index].delete = '0044';
          }
        }
      }
      if (index == 4) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0051')
            this.list[index].view = '0051';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0052')
            this.list[index].create = '0052';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0053')
            this.list[index].edit = '0053';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0054')
            this.list[index].delete = '0054';
          }
        }
      }
      if (index == 5) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0061')
            this.list[index].view = '0061';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0062')
            this.list[index].create = '0062';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0063')
            this.list[index].edit = '0063';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0064')
            this.list[index].delete = '0064';
          }
        }
      }
      if (index == 6) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0071')
            this.list[index].view = '0071';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0072')
            this.list[index].create = '0072';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0073')
            this.list[index].edit = '0073';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0074')
            this.list[index].delete = '0074';
          }
        }
      }
      if (index == 7) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('0081')
            this.list[index].view = '0081';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('0082')
            this.list[index].create = '0082';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('0083')
            this.list[index].edit = '0083';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('0084')
            this.list[index].delete = '0084';
          }
        }
      }
      if (index == 8) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('00101')
            this.list[index].view = '00101';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('00102')
            this.list[index].create = '00102';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('00103')
            this.list[index].edit = '00103';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('00104')
            this.list[index].delete = '00104';
          }
        }
      }
      if (index == 9) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('00111')
            this.list[index].view = '00111';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('00112')
            this.list[index].create = '00112';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('00113')
            this.list[index].edit = '00113';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('00114')
            this.list[index].delete = '00114';
          }
        }
      }
      if (index == 10) {
        this.checkPermission(event, data, index);
        if (show == 'view') {
          if (view == -1) {
            this.permission.push('00081')
            this.list[index].view = '00081';
          }
        }
        if (show == 'create') {
          if (create == -1) {
            this.permission.push('00082')
            this.list[index].create = '00082';
          }
        }
        if (show == 'edit') {
          if (edit == -1) {
            this.permission.push('00083')
            this.list[index].edit = '00083';
          }
        }
        if (show == 'delete') {
          if (delet == -1) {
            this.permission.push('00084')
            this.list[index].delete = '00084';
          }
        }
      }

    } else if (event.target.checked == false) {
      if (show == 'view') {
        console.log("1")
        if (view > -1) {
          this.list[index].view = '';
          this.permission.splice(view, 1)
        }
      }
      if (show == 'create') {
        if (create > -1) {
          this.list[index].create = '';
          this.permission.splice(create, 1)
        }
      }
      if (show == 'edit') {
        if (edit > -1) {
          this.list[index].edit = '';
          this.permission.splice(edit, 1)
        }
      }
      if (show == 'delete') {

        if (delet > -1) {
          this.list[index].delete = '';
          this.permission.splice(delet, 1)
        }
      }




    }

    console.log(this.permission);
    console.log(this.list);

  }


  checkPermission(event: any, data: any, index: any) {
    console.log(data)
    var type = this.permission.map((x: any) => x).indexOf(data.value);
    console.log(data.value);
    if (event.target.checked == true) {
      if (type > -1) {

      } else {
        if (index == 0) {
          this.permission.push('001')
          this.list[index].value = '001';
        } else if (index == 1) {
          this.permission.push('002')
          this.list[index].value = '002';
        }
        else if (index == 2) {
          this.permission.push('003')
          this.list[index].value = '003';
        }
        else if (index == 3) {
          this.permission.push('004')
          this.list[index].value = '004';
        }
        else if (index == 4) {
          this.permission.push('005')
          this.list[index].value = '005';
        }
        else if (index == 5) {
          this.permission.push('006')
          this.list[index].value = '006';
        }
        else if (index == 6) {
          this.permission.push('007')
          this.list[index].value = '007';
        }
        else if (index == 7) {
          this.permission.push('008')
          this.list[index].value = '008';
        }
        else if (index == 8) {
          this.permission.push('0010')
          this.list[index].value = '0010';
        }
        else if (index == 9) {
          this.permission.push('0011')
          this.list[index].value = '0011';
        }
        else if (index == 10) {
          this.permission.push('00080')
          this.list[index].value = '00080';
        }
      }
    } else if (event.target.checked == false) {
      console.log(type);
      if (type > -1) {
        this.list[index].value = '';
        this.permission.splice(type, 1);

      }

      var type1 = this.permission.map((x: any) => x).indexOf(data.view);
      if (type1 > -1) {
        this.list[index].view = '';
        this.permission.splice(type1, 1);
      }

      var type2 = this.permission.map((x: any) => x).indexOf(data.create);
      if (type2 > -1) {
        this.list[index].create = '';
        this.permission.splice(type2, 1);
      }

      var type3 = this.permission.map((x: any) => x).indexOf(data.edit);
      if (type3 > -1) {
        this.list[index].edit = '';
        this.permission.splice(type, 1);
      }


      var type4 = this.permission.map((x: any) => x).indexOf(data.delete);
      if (type4 > -1) {
        this.list[index].delete = '';
        this.permission.splice(type, 1);
      }

    }
    console.log(this.permission);

    console.log(this.data)

  }

  savePermission() {
    this.saveLoading = true;
    let data = {
      "orgid": this.orgid,
      "domainid": this.allinoneService.getDomain().domainid,
      "name": this.name,
      "desc": this.desc,
      "type": '002',
      "permission": this.permission,
      "roleid": this.roleid
    }
    console.log(data)

    this.kunyekService.updateRole(data).subscribe(res => {
      if (res.returncode == 300) {
        this.isPermission = false;
        this.saveLoading = false;
        this.permission = [];
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].value = '';
          if (this.list[i].view != 'null') {
            this.list[i].view = '';
          }
          if (this.list[i].create != 'null') {
            this.list[i].create = '';
          }

          if (this.list[i].edit != 'null') {
            this.list[i].edit = '';
          }
          if (this.list[i].delete != 'null') {
            this.list[i].delete = '';
          }
        }
        this.getRoleList();
      } else {
        this.saveLoading = false;
        this.messageService.openSnackBar(
          res.message,
          'warn'
        )
      }
    }, err => {
      this.saveLoading = false;

      this.messageService.openSnackBar(
        err.message,
        'warn'
      )
    })


  }

}




