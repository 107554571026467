import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoCarwayComponent } from '../user-info-carway/user-info-carway.component';
import { Sort } from '@angular/material/sort';
import { FilterPipe } from 'src/app/shared/filter.pipe';

@Component({
  selector: 'app-car-way-schedule-detail',
  templateUrl: './car-way-schedule-detail.component.html',
  styleUrls: ['./car-way-schedule-detail.component.scss']
})


export class CarWayScheduleDetailComponent implements OnInit {

  carwayForm = {
    name: '',
    description: '',
    vehicle: '',
    startdate: '',
    enddate: '',
    startdatetime: '',
    enddatetime: '',
    carwayid: '',
    drivername: '',
    driverphone: '',
    carwaytype: '',
    type: '',
    date:'',
  };

  checkgoing: boolean = false;
  checkreturn: boolean = false;
  gettingData: boolean = true;
  orgid: any = '';
  organizations: any = [];
  carwayid: any = '';
  carwaydataList: any = [
  ]
  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private filter: FilterPipe
  ) {
    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;
    // this.carwayid = this.route.snapshot.queryParamMap.get() || "";
    this.carwayid = this.route.snapshot.paramMap.get('carwayid');
  }

  ngOnInit(): void {
    this.getCarWayDetail();
  }

  cancelFunction() {
    this.router.navigateByUrl('transportation/carwayschedules');
  }
  sortData(data: any) {

  }

  getCarWayDetail() {
    this.gettingData = true;
    this.carwaydataList = [];

    var data = {
      domainid: this.allinoneService.getDomain().domainid,
      orgid: this.orgid,
      carwayid: this.carwayid,


    };
    console.log("okok");
    console.log(data)
    this.kunyekService.getcarwaydetail(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.carwayForm.description = res.description;
          this.carwayForm.startdate = this.allinoneService.formatDBToShow(res.startdate);
          this.carwayForm.enddate = this.allinoneService.formatDBToShow(res.enddate);
          this.carwayForm.enddatetime = res.endtime;
          this.carwayForm.startdatetime = res.starttime;
          this.carwayForm.name = res.name;
          this.carwayForm.drivername = res.drivername;
          this.carwayForm.driverphone = res.driverphone;
          this.carwayForm.vehicle = res.vehicleno == "" ? res.vehiclename : res.vehicleno;
          this.carwayForm.carwaytype = res.trantype;
          this.carwayForm.type = res.vehicletype;
          this.checkgoing = res.going;
          this.checkreturn = res.return;
          this.carwayForm.date = this.allinoneService.formatDBToShowInEdit(res.date);

          this.carwaydataList = [];
          if (this.carwayForm.carwaytype == "001") {
            for (let j = 0; j < res.pickuppointlist.length; j++) {
              for (let i = 0; i < res.pickuppointlist[j]['userdetailslist'].length; i++) {
                this.carwaydataList.push({
                  "userid": res.pickuppointlist[j]['userdetailslist'][i]['userid'],
                  "username": res.pickuppointlist[j]['userdetailslist'][i]['username'],
                  "employeeid": res.pickuppointlist[j]['userdetailslist'][i]['employeeid'],
                  "pickuppointid": res.pickuppointlist[j]['pickuppointid'],
                  "pickuppoint": res.pickuppointlist[j]['pickuppoint'],
                  "pickuptime": res.pickuppointlist[j]['pickuptime'],
                });
              }
            }
          } else if (this.carwayForm.carwaytype == "002") {
            for (let j = 0; j < res.schedulelist.length; j++) {
              this.carwaydataList.push({
                "userid": res.schedulelist[j]['userid'],
                "username": res.schedulelist[j]['username'],
                "employeeid": res.schedulelist[j]['employeeid'],
                "pickuppointid": res.schedulelist[j]['pickuppointid'],
                "pickuppoint": res.schedulelist[j]['pickuppoint'],
                "pickuptime": res.schedulelist[j]['pickuptime'],
                "dropoffpoint": res.schedulelist[j]['dropoffpoint'],
                "dropofftime": res.schedulelist[j]['dropofftime'],
              });
            }
          }

          this.gettingData = false;
        } else {
          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingData = false;
        this.messageService.openSnackBar(err, 'warn');
      }
    );
  }




  downloadSheet() {
    var data: any = [
      {
        "main": [
          {
            "name": "Driver",
            "value": this.carwayForm.drivername,
          },
          {
            "name": "Vehicle",
            "value": this.carwayForm.type == '001' ? this.carwayForm.vehicle +' ( Car ) ' : this.carwayForm.vehicle  +' ( Taxi )',
          },
          {
            "name": "Contact",
            "value": this.carwayForm.driverphone,
          },
          {
            "name": "Date",
            "value": this.carwayForm.date,
          },
        ],
        "data": [

        ]
      }


    ];
    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = this.carwayForm.name + time + '.xlsx';

    if (this.carwayForm.carwaytype == "001") {
      this.carwaydataList.map((car: any) => {
        
        data[0]['data'].push({
          "User ID": car.userid,
          "User Name": car.username,
          "Pickup Point": car.pickuppoint,
          "Pickup Time": car.pickuptime,
        });
      });
    } else {
      this.carwaydataList.map((car: any) => {
        data[0]['data'].push({
          "User ID": car.userid,
          "User Name": car.username,
          "Pickup Point": car.pickuppoint,
          "Pickup Time": car.pickuptime,
          "Drop off Point": car.dropoffpoint,
          "Drop off Time": car.dropofftime,
        });
      });
    }

  


    this.allinoneService.exportExeclCarDetail(data, name);

  }
}
