<app-loading *ngIf="createGroupLoading"></app-loading>
<div class="main">

  <div class="left-column"
    *ngIf="(isMobileView && !openedChat) || (isMobileView && isGroupCreateFromInfo) || (!isMobileView)" fxFlex="auto">
    <ul class="list-group">
      <li class="sidebar-header list-group-item">
        <div *ngIf="!isContacts">
          <button type="button" class="btn btn-light" data-bs-toggle="dropdown" id="leftdropdown"
            aria-expanded="true"><i class="fa fa-bars"></i></button>
          <ul class="dropdown-menu" aria-labelledby="leftdropdown">
            <button mat-menu-item (click)="goToContacts('private')">
              <mat-icon class="menu-item" style="color: var(--main-color) !important;">person</mat-icon>
              <span>Contacts</span>
            </button>
            <button mat-menu-item (click)="goToContacts('group')">
              <mat-icon class="menu-item" style="color: var(--main-color) !important;">supervised_user_circle
              </mat-icon>
              <span>New Group</span>
            </button>
          </ul>
        </div>


        <!-- contacts -->
        <button *ngIf="isContacts" type="button" class="btn btn-light" (click)="leftBack()">
          <mat-icon>arrow_back</mat-icon>
        </button>

        <div class="input-group" *ngIf="!isCreatingGroup">
          <input class="form-control" type="text" placeholder="Search" aria-label="Search" (focus)="isFocus = true"
            (blur)="isFocus = false" [(ngModel)]="searchText"
            [ngClass]="{'search-input' : !searchText, 'search-input-with-clear' : searchText }">
          <button class="btn btn-outline-secondary " type="button" *ngIf="searchText" (click)="clear()"
            [ngClass]="{'clear' : isFocus, 'clear-not-focus': !isFocus}">
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <div class="input-group create-group" *ngIf="isCreatingGroup">
          Create Group
        </div>

      </li>
    </ul>
    <div class="sidebar-content" *ngIf="!isContacts" infinite-scroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" [scrollWindow]="false" [perfectScrollbar]="config">
      <!-- <perfect-scrollbar class="sidebar-content" [config]="config" [scrollIndicators]="true"> -->
      <ul class="list-group" *ngIf="!isContacts && gettingChatList">
        <li class="list-group-item" *ngFor="let item of chatLoaderArray">
          <div class="profile-item">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                            'height.px': 40}"></ngx-skeleton-loader>
            <div class="profile-container" style="margin-top: auto;">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 30}"></ngx-skeleton-loader>
            </div>
          </div>
        </li>
      </ul>
      <ul class="list-group" *ngIf="!isContacts && !gettingChatList">
        <li class="list-group-item"
          *ngFor="let chat of chatListToShow | filter : searchText: type ; let index = index; trackBy: trackBySort"
          [ngClass]="{'noborder': index == 0 , 'active-chat' : (chat.channelid == openedChat?.channelid && openedChat.type != '3') || (chat.userlist[0].userid == openedChat?.userid && chat.type == '1' && openedChat.type == '3')}"
          (click)="openChat(chat)">
          <div class="profile-item" *ngIf="chat.type == '1' && chat.block == 'true'">
            <div class="me-3">
              <div class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="1">
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <img class="conversation-summary-avatar-group-member-wrapper"
                    src="../../../assets/images/profile.png">
                </div>
              </div>
            </div>

            <div class="profile-container">
              <div class="nametime">
                <font class="name block-name">
                  Blocked User
                </font>
                <span class="time">{{chat.sort | getChatTime}}</span>
              </div>

              <span *ngIf="chat.sentby == user.userid">
                You: {{chat.meetingtitle ? chat.meetingtitle: chat.message ? chat.message : chat.imagename ? "Image" :
                chat.filename ?
                chat.filename : "No Message"}}</span>
              <span *ngIf="chat.sentby != user.userid">{{chat.meetingtitle ? chat.meetingtitle: chat.message ?
                chat.message
                :
                chat.imagename ? "Image" : chat.filename ? chat.filename : "No Message"}}</span>
            </div>
          </div>
          <div class="profile-item" *ngIf="chat.type == '1' && chat.block == 'false'">
            <div class="me-3">
              <div class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="1">
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <img class="conversation-summary-avatar-group-member-wrapper"
                    [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="chat.userlist[0].imagename ? chat.userlist[0].imagename : '../../../assets/images/profile.png' ">
                </div>
              </div>
            </div>

            <div class="profile-container">
              <div class="nametime">
                <font class="name">
                  {{chat.userlist[0].username ? chat.userlist[0].username : chat.userlist[0].userid}}
                </font>
                <span class="time">{{chat.sort | getChatTime}}</span>
              </div>
              <div class="unseen">
                <span class="" *ngIf="chat.sentby == user.userid" [ngClass]="{'boldtext': chat.seen == 'False'}">You:
                  {{chat.meetingtitle ? chat.meetingtitle: chat.message ? chat.message : chat.imagename ? "Image" :
                  chat.filename ?
                  chat.filename : "No Message"}}</span>
                <span class="" *ngIf="chat.sentby != user.userid"
                  [ngClass]="{'boldtext': chat.seen == 'False'}">{{chat.meetingtitle ? chat.meetingtitle: chat.message ?
                  chat.message :
                  chat.imagename ? "Image" : chat.filename ? chat.filename : "No Message"}}</span>
                <span *ngIf="chat.seen == 'False'">
                  <i class="fa fa-circle noti"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="profile-item" *ngIf="chat.type == '2'">
            <div class="me-3">
              <div *ngIf="chat.channelimage.imageurl">
                <div
                  class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="1">
                  <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member-wrapper"
                      [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="chat.channelimage.imageurl ? chat.channelimage.imageurl : '../../../assets/images/profile.png' ">
                  </div>
                </div>
              </div>
              <div *ngIf="!chat.channelimage.imageurl">
                <div *ngIf="chat.userlist.length > 2"
                  class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="3">
                  <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member-wrapper"
                      [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="chat.userlist[0].imagename ? chat.userlist[0].imagename : '../../../assets/images/profile.png' ">
                  </div>

                  <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                      [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="chat.userlist[1].imagename ? chat.userlist[1].imagename : '../../../assets/images/profile.png' ">
                  </div>

                  <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                      [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="chat.userlist[2].imagename ? chat.userlist[2].imagename : '../../../assets/images/profile.png' ">
                  </div>
                </div>
                <div *ngIf="chat.userlist.length == 2"
                  class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="2">
                  <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member-wrapper"
                      [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="chat.userlist[0].imagename ? chat.userlist[0].imagename : '../../../assets/images/profile.png' ">
                  </div>

                  <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                      [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="chat.userlist[1].imagename ? chat.userlist[1].imagename : '../../../assets/images/profile.png' ">
                  </div>
                </div>
                <div *ngIf="chat.userlist.length == 1"
                  class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="1">
                  <div class="conversation-summary-avatar-group-member-wrapper">
                    <img class="conversation-summary-avatar-group-member-wrapper"
                      [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="chat.userlist[0].imagename ? chat.userlist[0].imagename : '../../../assets/images/profile.png' ">
                  </div>
                </div>
              </div>
            </div>
            <div class="profile-container">
              <div class="nametime">
                <font class="name">{{chat.channelname}} </font>
                <span class="time">{{chat.sort | getChatTime}}</span>
              </div>
              <div class="unseen">
                <span *ngIf="chat.sentby == user.userid" [ngClass]="{'boldtext': chat.seen == 'False'}">You:
                  {{chat.meetingtitle ? chat.meetingtitle: chat.message ? chat.message : chat.imagename ? "Image" :
                  chat.filename ?
                  chat.filename : "No Message"}}</span>
                <span *ngIf="chat.sentby != user.userid"
                  [ngClass]="{'boldtext': chat.seen == 'False'}">{{chat.meetingtitle ? chat.meetingtitle: chat.message ?
                  chat.message :
                  chat.imagename ? "Image" : chat.filename ? chat.filename : "No Message"}}</span>
                <span *ngIf="chat.seen == 'False'">
                  <i class="fa fa-circle noti"></i>
                </span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="list-group" *ngIf="!isContacts && gettingMoreChatList">
        <li class="list-group-item" *ngFor="let item of chatLoaderArray2">
          <div class="profile-item">
            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ 
                            'height.px': 40}"></ngx-skeleton-loader>
            <div class="profile-container" style="margin-top: auto;">
              <ngx-skeleton-loader count="1" appearance="line" [theme]="{ 
                                'height.px': 30}"></ngx-skeleton-loader>
            </div>
          </div>
        </li>
      </ul>
      <div class="d-flex align-items-center justify-content-center w-100"
        *ngIf="!isContacts && !gettingChatList && chatListToShow.length == 0" style="height: 300px;">
        Empty
      </div>
      <!-- </perfect-scrollbar> -->
    </div>

    <app-chat-contacts class="sidebar-content" *ngIf="isContacts" [isGroupChatCreate]="isGroupChatCreate"
      [searchData]="searchText" [createWithThisMember]="createWithThisMember" [areMembersChosen]="areMembersChosen"
      [isAddMember]="isAddMember" [membersAlreadyInGroup]="membersAlreadyInGroup" [channelid]="openedChat?.channelid"
      (createGroupDone)="createGroupDone($event)" (clickChat)="openChat($event)" (addMemberFinish)="addMemberDone()"
      (next)="creatingGroup()" (clearSearch)="searchText=''">
    </app-chat-contacts>
  </div>
  <div class="right-column">
    <div *ngIf="(isMobileView &&openedChat && !isGroupCreateFromInfo) || (!isMobileView && openedChat)">
      <!-- <ul class = "list-group"> -->
      <div class="right-header p-2">
        <div class="chatheader"
          *ngIf="(openedChat.type == '1' && openedChat.block == 'true')||(openedChat.type == '3' && openedChat.block != '')">
          <!-- <i class="fa fa-arrow-left me-3" (click)="back()" *ngIf="isMobileView || isInfo"></i> -->
          <mat-icon class="me-3" (click)="back()" *ngIf="isMobileView || isInfo">arrow_back</mat-icon>
          <div class="conversation-summary-avatar conversation-summary-avatar-group me-2" data-number-of-thumbnails="1">
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member-wrapper" src="../../../assets/images/profile.png">
            </div>
          </div>
          <div class="chatname block-name">
            Blocked User
          </div>
        </div>
        <div class="chatheader" *ngIf="openedChat.type == '1' && openedChat.block == 'false'">
          <!-- <i class="fa fa-arrow-left me-3" (click)="back()" *ngIf="isMobileView || isInfo"></i> -->
          <mat-icon class="me-3" (click)="back()" *ngIf="isMobileView || isInfo">arrow_back</mat-icon>
          <div class="conversation-summary-avatar conversation-summary-avatar-group me-2" data-number-of-thumbnails="1">
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member-wrapper"
                [defaultImage]="'../../../assets/images/bg.jpg'"
                [lazyLoad]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png' ">
            </div>
          </div>
          <div class="chatname">
            {{openedChat.userlist[0].username ? openedChat.userlist[0].username : openedChat.userlist[0].userid }}
          </div>
        </div>
        <div class="chatheader" *ngIf="openedChat.type == '3' && openedChat.block == '' ">
          <!-- <i class="fa fa-arrow-left me-3" (click)="back()" *ngIf="isMobileView || isInfo"></i> -->
          <mat-icon class="me-3" (click)="back()" *ngIf="isMobileView || isInfo">arrow_back</mat-icon>
          <div class="conversation-summary-avatar conversation-summary-avatar-group me-2" data-number-of-thumbnails="1">
            <div class="conversation-summary-avatar-group-member-wrapper">
              <img class="conversation-summary-avatar-group-member-wrapper"
                [src]="openedChat.imagename ? openedChat.imagename : '../../../assets/images/profile.png' ">
              <!-- <img class="conversation-summary-avatar-group-member-wrapper"
                              [defaultImage]="'../../../assets/images/bg.jpg'"
                              [lazyLoad]="openedChat.imagename ? openedChat.imagename : '../../../assets/images/profile.png' "> -->
            </div>
          </div>
          <div class="chatname">
            {{openedChat.username ? openedChat.username : openedChat.userid }}
          </div>
        </div>
        <div class="chatheader" *ngIf="openedChat.type == '2'">
          <!-- <i class="fa fa-arrow-left me-3" (click)="back()" *ngIf="isMobileView || isInfo"></i> -->
          <mat-icon class="me-3" (click)="back()" *ngIf="isMobileView || isInfo">arrow_back</mat-icon>
          <div class="me-3">
            <div *ngIf="openedChat.channelimage.imageurl">
              <div
                class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="1">
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <img class="conversation-summary-avatar-group-member-wrapper"
                    [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="openedChat.channelimage.imageurl ? openedChat.channelimage.imageurl : '../../../assets/images/profile.png' ">
                </div>
              </div>
            </div>
            <div *ngIf="!openedChat.channelimage.imageurl">
              <div *ngIf="openedChat.userlist.length > 2"
                class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="3">
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                    [src]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png' ">
                  <!-- <img class="conversation-summary-avatar-group-member-wrapper"
                                    [defaultImage]="'../../../assets/images/bg.jpg'" 
                                    [lazyLoad]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png'  "> -->
                </div>
  
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <!-- <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                                    [defaultImage]="'../../../assets/images/bg.jpg'" 
                                    [lazyLoad]="openedChat.userlist[1].imagename ? openedChat.userlist[1].imagename : '../../../assets/images/profile.png' "> -->
                  <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                    [src]="openedChat.userlist[1].imagename ? openedChat.userlist[1].imagename : '../../../assets/images/profile.png' ">
                </div>
  
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                    [src]="openedChat.userlist[2].imagename ? openedChat.userlist[2].imagename : '../../../assets/images/profile.png' ">
                  <!-- <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                                    [defaultImage]="'../../../assets/images/bg.jpg'" 
                                    [lazyLoad]="openedChat.userlist[2].imagename ? openedChat.userlist[2].imagename : '../../../assets/images/profile.png' "> -->
                </div>
              </div>
              <div *ngIf="openedChat.userlist.length == 2"
                class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="2">
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                    [src]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png' ">
                  <!-- <img class="conversation-summary-avatar-group-member-wrapper"
                                    [defaultImage]="'../../../assets/images/bg.jpg'" 
                                    [lazyLoad]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png'  "> -->
                </div>
  
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <!-- <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                                    [defaultImage]="'../../../assets/images/bg.jpg'" 
                                    [lazyLoad]="openedChat.userlist[1].imagename ? openedChat.userlist[1].imagename : '../../../assets/images/profile.png' "> -->
                  <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                    [src]="openedChat.userlist[1].imagename ? openedChat.userlist[1].imagename : '../../../assets/images/profile.png' ">
                </div>
              </div>
              <div *ngIf="openedChat.userlist.length == 1"
                class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="1">
                <div class="conversation-summary-avatar-group-member-wrapper">
                  <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                    [src]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png' ">
                  <!-- <img class="conversation-summary-avatar-group-member-wrapper"
                                    [defaultImage]="'../../../assets/images/bg.jpg'" 
                                    [lazyLoad]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png'  "> -->
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!editGroupName" style="display: flex;">
            <div class="chatname">
              {{openedChat.channelname}}
            </div>
            <div
              *ngIf="!isInfo && (openedChat.type == '2' && (allinoneService.isAdmin(user.userid, openedChat.adminlist) || user.userid == openedChat.admin))">
              <mat-icon class="edit-icon" (click)="groupNameEdit()" title="Edit Group Name">edit
              </mat-icon>
            </div>
          </div>
          <div *ngIf="editGroupName" style="display: flex;">
            <div>
              <input type="text" class="form-control" required [(ngModel)]="editName" id="channelname"
                name="channelname" (keyup.enter)="updateGroupName()" #cname="ngModel"
                [ngClass]="{ 'is-invalid': cname.invalid }" [disabled]="editingGroupName">
            </div>
            <div style="display: flex; align-items: center;" *ngIf="!editingGroupName">
              <mat-icon class="close-domain" (click)="cancelGroupNameEdit()" title="Cancel">close
              </mat-icon>
              <mat-icon *ngIf="editName.trim() != ''" class="close-domain" (click)="updateGroupName()" title="Save">
                check</mat-icon>
            </div>
            <div style="display: flex; align-items: center; margin-left: 10px;" *ngIf="editingGroupName">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>
          </div>
        </div>
        <div class="info"
          *ngIf="((openedChat.type == '1' && openedChat.block != 'true')||(openedChat.type == '3' && openedChat.block == '') || openedChat.type == '2') && !isInfo ">
          <i class="fa fa-video-camera me-3" matTooltip="Conference Call" (click)="createMeeting()"></i>
          <mat-icon (click)="openChatInfo()" matTooltip="Info">info</mat-icon>
        </div>
      </div>
      <!-- </ul> -->
      <div class="right-content" *ngIf="isInfo" style="padding-bottom: 0 !important;">
        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false"
          [perfectScrollbar]="config" class="chat-container" style="height: calc(100vh - 145px) !important;">
          <app-chat-info class="info" [openedChat]="openedChat" (leave)="leaveGroup()" (reload)="reloadChat()"
            (block)="afterBlock($event)" (createGroupWith)="createGroupWithSomeone($event)"
            (addMember)="addMember($event)" (delete)="deleteChat()">
          </app-chat-info>
        </div>
      </div>
      <div class="right-content" *ngIf="!isInfo">
        <div infinite-scroll [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="250"
          (scrolledUp)="chatscrolledUp()" [scrollWindow]="false" [perfectScrollbar]="config"
          [ngClass]="{'chat-container':!check_reply,'chat-container-rp':check_reply}" #chatPS>
          <div class="{{check_reply ? 'chat-container-rp' : 'chat-container'}}" #chatPS>
            <!-- <div class="chat"> -->

            <!-- Loading Skeleton -->
            <div class="chat-message messages" *ngIf="gettingChat || gettingMoreChat">
              <div class="messages-content" *ngFor="let msg of [0,1,2,3,4];let i = index;">
                <div>
                  <div>
                    <div class="message-container"
                      [ngClass]="{'message-container-new' : i % 2 == 0 , 'message-container-personal message-container-personal-loading' : i % 2 == 1}">
                      <div class="message-info">
                        <ngx-skeleton-loader *ngIf="i % 2 == 0" class="card-profile" style="width: auto;"
                          appearance="circle" [theme]="{ height: '40px',width: '40px'}">
                        </ngx-skeleton-loader>
                        <div class="message">
                          <ngx-skeleton-loader class="skeleton-chat-loader" [theme]="{ height: '10px',width: '70px'}">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader class="skeleton-chat-loader" [theme]="{ height: '10px',width: '130px'}">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div class="no-message" *ngIf="!gettingChat && groupmessages.length == 0">
              No Message
            </div>
            <div class="chat-message messages" *ngIf="!gettingChat && groupmessages.length != 0">
              <div class="messages-content" *ngFor="let msg of groupmessages;let i=index ; trackBy: trackByItems">
                <div class="message chat-date my-4" *ngIf="msg.chattype != ''"
                  style="margin: 0 !important;padding:0 !important">
                  <div *ngFor="let au of msg.actionuser; let i = index" style="padding: 10px;">
                    <div class="user-add-remove  d-flex ">
                      <div class="hr-line flex-fill">
                      </div>
                      <div class="px-1">
                        <span *ngIf="user.userid == msg.userid">You</span>
                        <span *ngIf="user.userid != msg.userid">{{getUserName(msg.userid)}}</span>
                        &nbsp;{{msg.chattype == 'add' ? 'added': 'removed'}}&nbsp;
                        <span>{{au.username ? au.username : au.userid}} {{msg.chattype == 'add'
                          ? 'to the group.': 'from the group.'}}</span>
                      </div>
                      <div class="hr-line flex-fill">
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="msg.chattype == ''">

                  <span class="message chat-date" *ngIf="msg.showDate">{{msg.date |
                    checkDateForToday}}
                    {{msg.time | formatTimeToShow}}</span>
                  <div>
                    <div
                      class="message-container {{msg.userid == user.userid ? 'message-container-personal' : 'message-container-new'}}">

                      <div class="message-info">
                        <img *ngIf="msg.userid != user.userid " [defaultImage]="'../../../assets/images/profile.png'"
                          [lazyLoad]="getChatProfile(msg.userid).imagename" class="chat-profile" [alt]="appname"
                          (click)="viewOtherProfile(msg.userid)">
                        <!-- [src]="getChatProfile(msg.userid).imagename ? getChatProfile(msg.userid).imagename: '../../../assets/images/profile.png' " -->

                        <div class="d-flex align-items-center">
                          <div *ngIf="msg.retry && !msg.isSending" class="retry-btn" (click)="retry(msg)">
                            Retry
                          </div>
                          <div id="{{i}}" tabindex="{{tab_index}}" class="message"
                            [ngClass]="{'mentioned':msg.message?.includes('@[__'+this.user.userid+'__](__'+user.username+'__)')|| msg.message?.includes('@(__'+user.username+'__)')}"
                            attr.data-is-edit="{{((openedChat.type == '1' && openedChat.block == 'true')||(openedChat.type == '3' && openedChat.block != '') ? '1' : msg.edit ? '1': msg.isSending? '1' : msg.retry ? '1' : msg.meetingtitle ? '1' : '0')}}">

                            <div class="float-box" (mouseover)="mouseOver(i)">
                              <div class=" like-iconbtn p-1" (click)="likeMessage(i,msg,'like')">
                                <img id="react-img" style="margin-bottom:2px;" src="../../../assets/images/like.svg"
                                  width="15">
                                <!-- <div *ngIf=" typeReact &&reactType == 'like' " id="circle"></div> -->
                                <div *ngIf="alreadyLiked(msg.reactlist) == 'like'" id="circle"></div>
                              </div>
                              <div class=" like-iconbtn p-1" (click)="likeMessage(i,msg,'002')">
                                <img id="react-img" style="margin-bottom:2px;" src="../../../assets/images/love.svg"
                                  width="15">
                                <!-- <div *ngIf=" typeReact && reactType == '002' " id="circle"></div> -->
                                <div *ngIf="alreadyLiked(msg.reactlist) == '002'" id="circle"></div>
                              </div>
                              <div class=" like-iconbtn p-1" (click)="likeMessage(i,msg,'003')">
                                <img id="react-img" style="margin-bottom:2px;" src="../../../assets/images/haha.svg"
                                  width="15">
                                <!-- <div *ngIf=" typeReact && reactType == '003' " id="circle"></div> -->
                                <div *ngIf="alreadyLiked(msg.reactlist) == '003'" id="circle"></div>
                              </div>
                              <div class=" like-iconbtn p-1" (click)="likeMessage(i,msg,'004')">
                                <img id="react-img" style="margin-bottom:2px;" src="../../../assets/images/sad.svg"
                                  width="15">
                                <!-- <div *ngIf=" typeReact && reactType == '004' " id="circle"></div> -->
                                <div *ngIf="alreadyLiked(msg.reactlist) == '004'" id="circle"></div>
                              </div>
                              <div class=" like-iconbtn p-1" (click)="likeMessage(i,msg,'005')">
                                <img id="react-img" style="margin-bottom:2px;" src="../../../assets/images/angry.svg"
                                  width="15">
                                <!-- <div *ngIf=" typeReact && reactType == '005' " id="circle"></div> -->
                                <div *ngIf="alreadyLiked(msg.reactlist) == '005'" id="circle"></div>
                              </div>

                              <div class="ver-line"
                                *ngIf="(msg.postid && msg.userid == user.userid) || msg.postid == ''">
                              </div>
                              <div class="msg-menu-dropdown"
                                *ngIf="(msg.postid && msg.userid == user.userid) || msg.postid == ''">
                                <div class="more-iconbtn  p-1" id="dropdownMenuButton1" aria-expanded="false"
                                  data-bs-toggle="dropdown">
                                  <i class="fa fa-ellipsis-h"></i>
                                </div>
                                <div class="dropdown-menu msg-menu dropdown-menu-end"
                                  aria-labelledby="dropdownMenuButton1">
                                  <button mat-menu-item (click)="reply(msg)" *ngIf="msg.postid == ''">
                                    <mat-icon>reply</mat-icon>Reply
                                  </button>
                                  <button mat-menu-item (click)="clickDownloadFile(msg.filelink,msg.filename)"
                                    *ngIf="msg.filelink!='' && msg.postid == ''">
                                    <mat-icon>file_download</mat-icon>Download
                                  </button>
                                  <button mat-menu-item
                                    *ngIf="(msg.message != '' || msg.imagelist) && msg.userid == user.userid && msg.postid == '' && msg.type==''"
                                    (click)="openEditMessage(msg)">
                                    <mat-icon>edit</mat-icon>Edit Message
                                  </button>


                                  <button mat-menu-item *ngIf="msg.userid == user.userid"
                                    (click)="deleteMessageConfirm(msg, i)">
                                    <mat-icon>delete</mat-icon>Delete Message
                                  </button>

                                  <button mat-menu-item
                                    *ngIf="msg.message != '' && msg.userid == user.userid && msg.postid == '' && openedChat.type == '2'"
                                    (click)="showSeenUserList(msg.seen)" [disabled]="msg.seen.length <= 1">
                                    <mat-icon>remove_red_eye</mat-icon>
                                    <span>Read by {{msg.seen.length - 1}} of {{openedChat.userlist.length - 1}}</span>
                                  </button>

                                  <button mat-menu-item
                                    *ngIf="msg.message != '' && msg.userid == user.userid && msg.postid == '' && openedChat.type != '2' && msg.seen.length == 2">
                                    <mat-icon>remove_red_eye</mat-icon>
                                    <span>Seen</span>
                                  </button>

                                </div>
                              </div>

                            </div>
                            <div *ngIf="msg.edit">
                              <div class="edit-input-box mb-1">
                                <div id="editor-container-{{msg.syskey}}" class="edit-textarea">

                                </div>
                                <!-- <textarea class="edit-textarea" autosize [minRows]="1"
                                                              [(ngModel)]="editText">
                                                          </textarea> -->
                              </div>
                              <div style="display: flex; justify-content: flex-end;">
                                <button mat-icon-button (click)="closeEditMessage(i)" [disabled]="editLoading">
                                  <mat-icon>close</mat-icon>
                                </button>
                                <button *ngIf="!editLoading" mat-icon-button (click)="submitEditMessage(msg)">
                                  <mat-icon>check</mat-icon>
                                </button>
                                <div style="display: flex; align-items: center;">
                                  <span *ngIf="editLoading" class="spinner-border spinner-border-sm button-spinner"
                                    role="status" aria-hidden="true"></span>
                                </div>
                              </div>
                            </div>
                            <div class="message-header" *ngIf="!msg.edit">
                              <div *ngIf="msg.userid != user.userid">
                                <span
                                  class="{{(tab_index==i && msg.userid != user.userid) ? 'message-header-name-reply' : ''}} {{(tab_index!=i && msg.userid != user.userid) ? 'message-header-name' : ''}}">
                                  <!-- {{ msg.userid | getuserpofile:openedChat}} -->
                                  {{getChatProfile(msg.userid).username
                                  ? getChatProfile(msg.userid).username:
                                  getChatProfile(msg.userid).userid}}
                                </span>
                              </div>

                              <div *ngIf="msg.replyuserid && msg.userid==user.userid">
                                <span class="{{tab_index ? 'spannew' : 'span'}}">
                                  {{msg.time | formatTimeToShow}}</span>
                              </div>
                              <div *ngIf="(!msg.replyuserid || msg.userid!=user.userid )">
                                <span
                                  class="{{ (tab_index && tab_index==i) ? 'spannew' : ''}} {{(!tab_index && tab_index!=i) ? 'span' : ''}}">
                                  {{msg.time | formatTimeToShow}}</span>
                                <span *ngIf="msg.reactlist.length > 0 && msg.userid != user.userid">
                                  <span *ngIf="('like' | getReactCount:msg.reactlist) > 0 "
                                    (click)="LikeAndTagForm(msg.reactlist)">
                                    <img id="react-img" class=" ms-2 me-1" src="../../../assets/images/like.svg"
                                      width="15">{{
                                    ('like' | getReactCount:msg.reactlist)}}
                                  </span>
                                  <span *ngIf="('002' | getReactCount:msg.reactlist) > 0 "
                                    (click)="LikeAndTagForm(msg.reactlist)">
                                    <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/love.svg"
                                      width="15">{{'002' |
                                    getReactCount:msg.reactlist}}
                                  </span>
                                  <span *ngIf="('003' | getReactCount:msg.reactlist) > 0"
                                    (click)="LikeAndTagForm(msg.reactlist)">
                                    <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/haha.svg"
                                      width="15">{{'003' |
                                    getReactCount:msg.reactlist}}
                                  </span>
                                  <span *ngIf="('004' | getReactCount:msg.reactlist) > 0 "
                                    (click)="LikeAndTagForm(msg.reactlist)">
                                    <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/sad.svg"
                                      width="15">{{'004' |
                                    getReactCount:msg.reactlist}}
                                  </span>
                                  <span *ngIf="('005' | getReactCount:msg.reactlist) > 0 "
                                    (click)="LikeAndTagForm(msg.reactlist)">
                                    <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/angry.svg"
                                      width="15">{{'005' |
                                    getReactCount:msg.reactlist}}
                                  </span>
                                </span>
                              </div>
                              <div *ngIf="msg.reactlist.length > 0 && msg.userid == user.userid">
                                <span *ngIf="('like' | getReactCount:msg.reactlist) > 0"
                                  (click)="LikeAndTagForm(msg.reactlist)">
                                  <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/like.svg"
                                    width="15">{{'like' |
                                  getReactCount:msg.reactlist}}
                                </span>
                                <span *ngIf="('002' | getReactCount:msg.reactlist) > 0"
                                  (click)="LikeAndTagForm(msg.reactlist)">
                                  <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/love.svg"
                                    width="15">{{'002' |
                                  getReactCount:msg.reactlist}}
                                </span>
                                <span *ngIf="('003' | getReactCount:msg.reactlist) > 0"
                                  (click)="LikeAndTagForm(msg.reactlist)">
                                  <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/haha.svg"
                                    width="15">{{'003' |
                                  getReactCount:msg.reactlist}}
                                </span>
                                <span *ngIf="('004' | getReactCount:msg.reactlist) > 0"
                                  (click)="LikeAndTagForm(msg.reactlist)">
                                  <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/sad.svg"
                                    width="15">{{'004' |
                                  getReactCount:msg.reactlist}}
                                </span>
                                <span *ngIf="('005' | getReactCount:msg.reactlist) > 0"
                                  (click)="LikeAndTagForm(msg.reactlist)">
                                  <img id="react-img" class="  ms-2 me-1" src="../../../assets/images/angry.svg"
                                    width="15">{{'005' |
                                  getReactCount:msg.reactlist}}
                                </span>
                              </div>
                              <div *ngIf="msg.isSending && msg.userid == user.userid">
                                <span class="sending">
                                  <mat-icon>schedule</mat-icon>
                                </span>
                              </div>
                            </div>


                            <div class="reply_box" (click)="ReplyMessage(msg)"
                              *ngIf="msg.replysyskey != '' && !msg.edit">
                              <div class="reply_wrapper">
                                <div class="reply-img">
                                  <div style="position: relative;">
                                    <img class="image" *ngIf="msg.replyimagename != ''"
                                      [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="msg.replyimagename"
                                      [alt]="appname">
                                  </div>
                                </div>
                                <div class="msg-username">
                                  <p *ngIf="msg.replyimagename==''" class="username">
                                    {{getChatProfile(msg.replyuserid).username ?
                                    getChatProfile(msg.replyuserid).username:
                                    getChatProfile(msg.replyuserid).userid}}
                                    <!-- {{ msg.replyuserid | getuserpofile:openedChat}} -->
                                  </p>
                                  <p *ngIf="msg.replyimagename!=''" class="username-img">
                                    {{getChatProfile(msg.replyuserid).username ?
                                    getChatProfile(msg.replyuserid).username:
                                    getChatProfile(msg.replyuserid).userid}}
                                    <!-- {{ msg.replyuserid | getuserpofile:openedChat}} -->
                                  </p>
                                  <p *ngIf="msg.replynotimessage!=''" class="msg">
                                    {{(msg.replynotimessage).substr(0, 20)}}<span
                                      *ngIf="msg.replynotimessage.length>=20">
                                      ...</span></p>

                                  <p *ngIf="msg.replynotimessage==''" style="color: grey;margin: 0">Image
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="msg.postusername=='' && msg.postid && msg.orgname==''">
                              <div class="card p-2" style="color: black;">
                                Attachment Unavailable.
                              </div>

                            </div>
                            <div *ngIf="msg.postid && msg.postusername!=''" class="card p-2"
                              (click)="viewpostdetails(msg.postid)">

                              <div class="d-flex" style="font-weight: bold;font-size: 0.9rem;color: black;">
                                <div>
                                  {{msg.postusername}}

                                </div>
                                <div *ngIf="msg.orgname!=''" class="ms-1" style="color: black;">
                                  ▶ {{msg.orgname}}

                                </div>
                              </div>
                              <div style="font-size:0.8rem;color: black;">
                                {{msg.postdesc}}
                              </div>

                              <img *ngIf="msg.postimage != '' && !msg.edit"
                                [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="msg.postimage"
                                [alt]="appname">
                              <img *ngIf="msg.postimage == '' && !msg.edit && msg.postuserurl!=''"
                                [defaultImage]="'../../../assets/images/bg.jpg'" [lazyLoad]="msg.postuserurl"
                                [alt]="appname">




                            </div>


                            <div *ngIf="msg.imagelist.length != 0 && !msg.edit && msg.postid==''">
                              <img *ngFor="let img of msg.imagelist;index as i;" (click)="allinoneService.imageViewer(msg, i)"
                                class="m-2 img-list-items" [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="img.imageurl" [alt]="appname">
                            </div>

                            <div *ngIf="msg.filename != '' && msg.filelink != '' && !msg.edit && msg.postid==''"
                              class="message-file">
                              <i class="fa fa-file-o">

                              </i>
                              <div class="attachFileName">
                                {{msg.filename}}
                              </div>

                            </div>

                            <span
                              class="{{(tab_index && tab_index==i && msg.userid != user.userid) ? 'message-color-new' : ''}} {{(!tab_index && tab_index!=i && msg.userid != user.userid) ? 'message-color' : ''}}"
                              *ngIf="(msg.type == '' || !msg.type)&& (!msg.meetingtitle || msg.meetingtitle == '') && !msg.edit && msg.postid==''"
                              id="mmessage"
                              [innerHTML]="msg.message | replaceEnterForChat:(msg.userid == user.userid):i">
                            </span>
                            <span *ngIf="msg.meetingtitle" (click)="openMeeting(msg.message)">
                              <div class="meeting-room d-flex me-2 ">
                                <div class="meeting-icon me-3">
                                  <i class="fas fa-video"></i>
                                </div>
                                <div class="d-flex flex-column justify-content-center">
                                  <div class="meeting-title">
                                    {{msg.meetingtitle}}
                                  </div>
                                  <div class="meeting-desc">
                                    Tap to join
                                  </div>
                                </div>
                              </div>
                            </span>
                            <span *ngIf="msg.type=='activity'" (click)="openDetails(msg)">
                              <span class="activity">
                                <i
                                  class="fas fa-calendar-check me-2"></i><span>{{msg.message}}</span>&nbsp;#{{msg.checkintime
                                | slice:0:5}}&nbsp;{{msg.checkintime
                                | slice:-2}}&nbsp;to&nbsp;{{msg.endtime | slice:0:5}}&nbsp;{{msg.endtime
                                | slice:-2}}&nbsp;{{msg.date | formatDateToShow}}
                              </span>
                              <div style="white-space: pre-line;">
                                {{msg.description}}
                              </div>
                            </span>
                            <span class="activity" *ngIf="msg.type == 'checkin'" (click)="openDetails(msg)">
                              <i class="fa fa-circle me-2" aria-hidden="true" style="color: red;"
                                *ngIf="msg.message=='Time Out'"></i>
                              <i class="fa fa-circle me-2" aria-hidden="true" style="color: lime;"
                                *ngIf="msg.message=='Time In'"></i>
                              <i class="fa fa-circle me-2" aria-hidden="true" style="color: darkgray;"
                                *ngIf="msg.message=='Check In'"></i>
                              <span>{{msg.message}}</span> &nbsp; <span class="text-decoration-underline"
                                *ngIf="msg.location">@{{msg.location}}</span>
                              <span class="text-decoration-underline"
                                *ngIf="!msg.location">@{{msg.lat}},{{msg.long}}</span> &nbsp; #{{msg.checkintime |
                              formatTimeToShow}} &nbsp; {{msg.date | formatDateToShow}}
                            </span>
                            <span class="activity" *ngIf="msg.type == 'eventqr'" (click)="openDetails(msg)">
                              <i class="fas fa-calendar-alt me-2"></i>
                              {{msg.eventname}}&nbsp;
                              <span class="text-decoration-underline"
                                *ngIf="msg.eventlocation">@{{msg.eventlocation}}</span>
                              <span class="text-decoration-underline"
                                *ngIf="!msg.eventlocation">@{{msg.eventlat}},{{msg.eventlong}}</span>
                            </span>
                          </div>
                        </div>
                        <span class="user_reply" (click)="reply(msg)" *ngIf="msg.reply && msg.userid != user.userid">
                          <i class="fa fa-reply" style="color: rgb(134, 123, 123);"></i>&nbsp;
                          Reply</span>
                        <div *ngIf="msg.retry && !msg.isSending">
                          <mat-icon class="msg-error">
                            info</mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <!-- </div> -->
          </div>

          <div class="chat-footer-box" #chatbox>
            <div *ngIf="check_reply" class="reply_box" (click)="ToReplyMessage()">
              <div class="reply_wrapper">
                <div class="reply-img">
                  <div class="image-box">
                    <img class="image" *ngIf="reply_image != ''" [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="reply_image" [alt]="appname">
                  </div>
                </div>
                <div class="msg-username">

                  <p *ngIf="reply_image==''" class="username">{{reply_username}}</p>
                  <p *ngIf="reply_image!=''" class="username-img">{{reply_username}}</p>
                  <p *ngIf="reply_m!=''" class="msg">{{(reply_m).substr(0, 20)}}<span *ngIf="reply_m.length>=20">
                      ...</span></p>

                  <p *ngIf="reply_m=='' && reply_image!=''" style="color: grey;margin: 0">Image</p>
                  <p *ngIf="reply_filename!=''" style="margin: 0"><i class="fa fa-file-o me-1"></i>{{reply_filename}}
                  </p>

                </div>

              </div>
              <div class="d-flex align-items-center">
                <mat-icon class="close-reply" (click)="clear_reply()">close</mat-icon>
              </div>
            </div>
            <div *ngIf="openedChat.block != 'true' && openedChat.block != 'enable'">
              <div class="btn-group dropup me-2 left-footer">
                <!-- <i class="fas fa-paperclip" data-bs-toggle="dropdown" aria-expanded="false"></i> -->
                <mat-icon data-bs-toggle="dropdown" aria-expanded="false">add_circle</mat-icon>
                <ul class="dropdown-menu">
                  <li>
                    <input style="display: none" type="file" accept="image/png, image/jpeg"
                      (change)="onMultipleFileSelect($event)" #hiddenfileinput multiple="multiple">
                    <a class="dropdown-item" (click)="hiddenfileinput.click()"><i class="fa fa-image me-2"></i>Image</a>
                  </li>
                  <li>
                    <input style="display: none" type="file" (change)="onAttchFileSelect($event)"
                      #hiddenattachfileinput>
                    <a class="dropdown-item" (click)="hiddenattachfileinput.click()"><i
                        class="fa fa-paperclip me-2"></i>Attach</a>
                  </li>
                </ul>
              </div>
              <div class="img-textarea" style="width: 100%;">
                <div *ngIf="selectedImageList.length != 0" style="overflow: auto;width: 100%;">
                  <div class="image-box" *ngFor="let image of selectedImageList;let i = index;"
                    style="display: inline-block;">
                    <div style="position: relative; margin-right: 10px;">
                      <div>
                        <img [src]="image.previewurl" alt="" style="width: auto;height: 100px;object-fit: cover;">
                      </div>
                      <div class="close" (click)="closeImage(i)">
                        <mat-icon>close</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div [ngClass]="{'file-box': attachFileSelected }" *ngIf="attachFileSelected"> -->
                <div class="file-box" *ngIf="attachFileSelected">
                  <i class="{{attachFileIcon}} p-1"></i>
                  <div class="p-1 attachFileName">
                    {{attachFileSelected}}
                  </div>
                  <div class="close" (click)="closeAttachedFile()">
                    <mat-icon>close</mat-icon>
                  </div>
                  <!-- <img [src]="attachFileIcon" alt=""> -->
                </div>
                <div class="d-flex align-items-center" style="width: 100%; margin-top: 10px;">
                  <quill-editor [modules]="modules" (paste)="onPaste($event)" style="width: 100%;" [(ngModel)]="message"
                    [formats]="formats" (keydown.enter)="onKeydown($event)" format="object">
                  </quill-editor>
                </div>
              </div>
              <mat-icon class="ms-2 send" (click)="sendMessage()">send</mat-icon>

            </div>

            <span *ngIf="openedChat.block == 'true' || openedChat.block == 'enable'">You can't reply or
              message.
            </span>
          </div>


          <div class="modal" id="image-viewer" aria-hidden="true" aria-labelledby="imageviewerLabel" tabindex="-1">
            <div class="modal-dialog modal-fullscreen">
              <div class="modal-content" *ngIf="imageData">
                <div class="modal-header">
                  <div class="modal-profile-container">
                    <img [defaultImage]="'../../../assets/images/bg.jpg'"
                      [lazyLoad]="getChatProfile(imageData.userid).imagename ? getChatProfile(imageData.userid).imagename: '../../../assets/images/profile.png' "
                      class="chat-image" [alt]="appname">
                    <div class="modal-profile-info" id="exampleModalToggleLabel">
                      <h5 class="modal-title">
                        {{getChatProfile(imageData.userid).username ? getChatProfile(imageData.userid).username:
                        getChatProfile(imageData.userid).userid}}
                        <!-- {{ imageData.replyuserid | getuserpofile:openedChat}} -->
                      </h5>
                      <span style="text-align: right;">{{imageData.date | checkDateForToday}}
                        {{imageData.time | formatTimeToShow}}</span>
                    </div>
                  </div>

                  <div class="modal-more-container">
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close"
                      (click)="downloadImage()">
                      <mat-icon>download</mat-icon>
                    </button>
                    <button type="button" class="btn-icon" data-bs-dismiss="modal" aria-label="Close"
                      (click)="closeImageViewer()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="d-flex flex-row" style="justify-content: space-between;">

                    <i (click)="backPhoto()" class="fa fa-chevron-left" style="
                                        top: 50% !important;
                                        max-width: calc(100vw - 16px);
                                        height: 100%;
                                        padding-right: 5%;
                                        position: relative;cursor: pointer;"></i>

                    <span *ngFor="let image of imageData.imagelist;index as i">
                      <img *ngIf="imageName == i" [src]="image.imageurl || ''" alt="" class="image-viewer">
                    </span>


                    <i (click)="forwardPhoto()" class="fa fa-chevron-right" style="top: 50% !important;
                                        max-width: calc(100vw - 16px);
                                        height: 100%;
                                        padding-left: 5%;
                                        position: relative;cursor: pointer;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>