import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MapComponent } from '../../map/map.component';
import { OfficeChooseMembersComponent } from '../office-choose-members/office-choose-members.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-work-from-home-request',
  templateUrl: './work-from-home-request.component.html',
  styleUrls: ['./work-from-home-request.component.scss'],
})
export class WorkFromHomeRequestComponent implements OnInit {
  @Input() requestData: any;
  @Input() isEditing: boolean = false;
  @Input() orgid: string = '';
  @Input() userid: string = '';
  @Input() approverList: any = [];

  @Output() save = new EventEmitter<any>();

  dlDataList: any = [];
  dlList: any = [];

  locListLoading: boolean = false;
  gettingDuration: boolean = false;
  locList: any = [];

  duration_subscription!: Subscription;

  workfromhomeid: string = 'EUOMXJSOQNCUWI';

  // current date with format as yyyy-MM-dd
  currentDate = this.allinoneService.formatDBToShowInEdit(
    this.allinoneService.getCurrentDateToDB()
  );

  submitForm = {
    startdate: this.currentDate,
    enddate: this.currentDate,
    duration: '1',
    location: '',
    locationid: '',
    lat: '',
    long: '',
    remark: '',
    readonly: false,
  };

  constructor(
    private kunyekService: KunyekService,
    private allinoneService: AllInOneService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // this.getCurrentLocation();
    this.getLocation();
    if (this.isEditing) {
      this.submitForm = {
        startdate: this.requestData.startdate,
        enddate: this.requestData.enddate,
        duration: this.requestData.duration,
        location: this.requestData.location,
        locationid: this.requestData.locationid,
        lat: this.requestData.lat,
        long: this.requestData.long,
        remark: this.requestData.remark,
        readonly: false,
      };
    }

    this.calculateDuration();
  }

  map() {
    const dialog = this.dialog.open(MapComponent, {
      height: '400px',
      width: '65vw',
      closeOnNavigation: true,
      panelClass: ['map-modal', 'mat-typography'],
      data: {
        lat: this.submitForm.lat,
        long: this.submitForm.long,
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.submitForm.lat = dialogResult.lat;
        this.submitForm.long = dialogResult.long;
        //this.changeDetectorRef.detectChanges()
      }
    });
  }

  selectLocation(loc: any) {
    this.submitForm.location = loc.name;
    this.submitForm.lat = loc.lat;
    this.submitForm.long = loc.lng;
    this.submitForm.locationid = loc.locid;
  }

  removeLocation() {
    this.submitForm.lat = '';
    this.submitForm.long = '';
    this.submitForm.locationid = '';
  }

  submitData() {
    if (this.submitForm.location == '') {
      return this.messageService.openSnackBar(
        'Please select location.',
        'warn'
      );
    } else if (this.submitForm.remark == '' || this.submitForm.remark == '-' || this.submitForm.remark == '.') {
      return this.messageService.openSnackBar('Please Fill Work From Home Reason.', 'warn');
    }
    const emitData = {
      requestData: this.submitForm,
      approverList: this.approverList,
    };
    this.save.emit(emitData);
  }

  chooseUser() {
    const dialog = this.dialog.open(OfficeChooseMembersComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.approverList,
        isLeaveOpening: false,
        orgid: this.orgid,
        domainid: this.allinoneService.getDomain().domainid,
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const tempApprovalList = dialogResult.data.filter(
          (data: { checked: boolean; userid: string }) => {
            return data.checked == true && data.userid != this.userid;
          }
        );
        const oldApproverList = JSON.parse(JSON.stringify(this.approverList));
        this.approverList = [];
        tempApprovalList.map((x: any) => {
          const temp = oldApproverList.find(
            (user: any) => user.userid == x.userid
          );
          this.approverList.push({
            userid: x.userid,
            employeeid: x.employeeid,
            username: x.username,
            from: temp ? temp.from : '',
            to: temp ? temp.to : '',
            checked: true,
          });
        });
      }
    });
  }

  // get user current location lat long and name
  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          this.kunyekService.getAddress(position.coords.latitude, position.coords.longitude).subscribe();
          // this.submitForm.lat = position.coords.latitude;
          // this.submitForm.long = position.coords.longitude;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  getLocation() {
    this.locList = [];
    this.locListLoading = true;
    const data = {
      personal: this.userid,
      domainid: '',
      orgid: [],
      global: '',
    };
    this.kunyekService.getLocations(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.locList = res.datalist;
          if (this.submitForm.locationid) {
            var checkLocation = this.locList.find(
              (x: any) => x.locid == this.submitForm.locationid
            );
            if (checkLocation) {
              this.submitForm.location = checkLocation.name;
              this.submitForm.lat = checkLocation.lat;
              this.submitForm.long = checkLocation.lng;
            }
          }
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.locListLoading = false;
      },
      (err) => {
        this.locListLoading = false;
      }
    );
  }

  calculateDuration() {
    if (this.submitForm.startdate != '' && this.submitForm.enddate != '') {
      if (this.submitForm.startdate > this.submitForm.enddate) {
        this.submitForm.enddate = this.submitForm.startdate;
      }
      const start = new Date(this.submitForm.startdate);
      const end = new Date(this.submitForm.enddate);
      const diff = Math.abs(end.getTime() - start.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      this.submitForm.duration = (diffDays + 1).toString();
      this.gettingDuration = true;
      const data = {
        requesttype: this.workfromhomeid,
        requestsubtype: '',
        startdate: this.allinoneService.formatDate(this.submitForm.startdate),
        enddate: this.allinoneService.formatDate(this.submitForm.enddate),
        endtime: '',
        duration: this.submitForm.duration,
        orgid: this.orgid,
      };
      this.duration_subscription && this.duration_subscription.unsubscribe();
      this.duration_subscription = this.kunyekService
        .getLeaveCounting(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.submitForm.duration = res.duration;
            } else {
              this.messageService.openSnackBar(
                res.status || res.message || 'Server Error',
                'warn'
              );
            }
            this.gettingDuration = false;
          },
          (err) => {
            this.gettingDuration = false;
          }
        );
    }
  }

  goToMap(lat: string, long: string) {
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
    window.open(url, "_blank");
  }
}
