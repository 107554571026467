import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { IMyDpOptions } from 'mydatepicker';
import { KMemberSelectComponent } from 'src/app/shared/components/k-member-select/k-member-select.component';
import { MatDialog } from '@angular/material/dialog';

function numericStringValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const isValid = typeof value === 'string' && /^[0-9]*$/.test(value);
    return isValid ? null : { numericString: { value: control.value } };
  };
}

@Component({
  selector: 'app-gym-form',
  templateUrl: './gym-form.component.html',
  styleUrls: ['./gym-form.component.scss']
})
export class GymFormComponent implements OnInit {
  datePickerOptions: IMyDpOptions = {
    dateFormat: 'dd/mm/yyyy',
  };
  profileImageSrc: string = '../../../assets/images/uploadphoto.png';
  gymForm!: FormGroup;
  isSaving: boolean = false;
  requiredMessage: string = 'This field must not be empty';
  saved = false
  gettingNrcCode = false
  breadcrumbs: any = []
  nrcRegionCode: any = [];
  nrcTownshipCode: any = [];
  nrcType: any = [];
  regionList: any = [];
  filteredTowns: any[] = [];
  filteredWards: any[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private kunyek: KunyekService,
    private messageService: MessageService,
    private allInOneService: AllInOneService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.setBreadcrumbs();
    this.getRegion();
  }

  setBreadcrumbs() {
    if (this.router.url === '/members/gymlist/new') {
      this.breadcrumbs[0] = { name: 'Gym', url: 'gym', isActive: true }
      this.breadcrumbs[1] = { name: 'Gym Member', url: 'members/gymlist', isActive: true }
      this.breadcrumbs[2] = { name: 'New', url: '', isActive: false }
    } else {
      this.breadcrumbs[0] = { name: 'Members', url: '', isActive: true }
      this.breadcrumbs[1] = { name: 'Gym', url: '', isActive: false }
    }
  }

  initializeForm() {
    this.gymForm = this.fb.group({
      member: ['', Validators.required],
      syskey: ['', Validators.required],
      id: [''],
      name: ['', Validators.required],
      fatherName: [''],
      nrcregioncode: ["", Validators.required],
      nrctownshipcode: ["", Validators.required],
      nrctype: ["", Validators.required],
      nrcnumber: ["", Validators.required],
      race: [''],
      religion: [''],
      length: [''],
      weight: [''],
      contactNo: ['', [Validators.required, numericStringValidator()]],
      occupation: [''],
      accountNo: [''],
      accountNoFC: [''],
      cardCode: [''],
      voucherNo: [''],
      joinDate: [''],
      lastPayDate: [''],
      memberStatus: ['', Validators.required],
      statusDate: [''],
      address: [''],
      remark: [''],
      region: ['',],
      town: ['',],
      ward: ['',]
    });
    this.getNrcCode()
  }

  getRegion() {
    const data = {};
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.regionList = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRegionChange(region: any) {
    const data = {
      region: region.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredTowns = res.datalist;
          this.filteredWards = [];
          this.resetDropdowns('town');
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  resetDropdowns(type: string) {
    if (type === 'town') {
      this.filteredWards = [];
    }
  }

  onTownChange(town: any) {
    const data = {
      town: town.target.value
    };
    this.kunyek.getDirectory().get(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.filteredWards = res.datalist;
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  openMemberModal() {
    const dialog = this.dialog.open(KMemberSelectComponent, {
      height: '606px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        field: 'member'
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        const memberData = dialogResult.data[0]
        this.gymForm.patchValue({
          member: memberData.name,
          name: memberData.name,
          syskey: memberData.syskey,
          id: memberData.userid,
          fatherName: memberData.fathername,
          nrcregioncode: memberData.nrcregioncode,
          nrctownshipcode: memberData.nrctownshipcode,
          nrctype: memberData.nrctype,
          nrcnumber: memberData.nrcnumber,
          race: memberData.nationality,
          religion: memberData.religion,
          occupation: memberData.currentjob,
          address: memberData.address,
        });
        this.gymForm.get('member')?.enable();
      }
    });
  }

  checkAndOpenMemberModal() {
    this.openMemberModal();
  }

  get name() { return this.gymForm.get('name'); }
  get fatherName() { return this.gymForm.get('fatherName'); }
  get nrcNo() { return this.gymForm.get('nrcNo'); }
  get race() { return this.gymForm.get('race'); }
  get religion() { return this.gymForm.get('religion'); }
  get length() { return this.gymForm.get('length'); }
  get weight() { return this.gymForm.get('weight'); }
  get contactNo() { return this.gymForm.get('contactNo'); }
  get occupation() { return this.gymForm.get('occupation'); }
  get accountNo() { return this.gymForm.get('accountNo'); }
  get accountNoFC() { return this.gymForm.get('accountNoFC'); }
  get cardCode() { return this.gymForm.get('cardCode'); }
  get voucherNo() { return this.gymForm.get('voucherNo'); }
  get joinDate() { return this.gymForm.get('joinDate'); }
  get lastPayDate() { return this.gymForm.get('lastPayDate'); }
  get memberStatus() { return this.gymForm.get('memberStatus'); }
  get statusDate() { return this.gymForm.get('statusDate'); }
  get address() { return this.gymForm.get('address'); }
  get nrcregioncode() { return this.gymForm.get('nrcregioncode'); }
  get nrctownshipcode() { return this.gymForm.get('nrctownshipcode'); }
  get nrctype() { return this.gymForm.get('nrctype'); }
  get nrcnumber() { return this.gymForm.get('nrcnumber'); }
  get remark() { return this.gymForm.get('remark'); }
  get member() { return this.gymForm.get('member'); }
  get syskey() { return this.gymForm.get('syskey') }
  get id() { return this.gymForm.get('id') }
  get region() { return this.gymForm.get('region'); }
  get town() { return this.gymForm.get('town'); }
  get ward() { return this.gymForm.get('ward'); }

  getNrcCode() {
    this.gettingNrcCode = true;
    const data = {
    };
    this.kunyek.getNrcCode(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.nrcRegionCode = res.datalist.regioncode;
          this.nrcTownshipCode = res.datalist.shortcode;
          this.nrcType = res.datalist.nrctype;
          this.gettingNrcCode = false;
        } else {
          this.gettingNrcCode = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.gettingNrcCode = false;
      }
    );
  }

  onSave() {
    this.isSaving = true;
    this.saved = true;
    if (this.gymForm.valid) {
      const formattedJoinDate = this.joinDate?.value?.formatted;
      const formattedLastPayDate = this.lastPayDate?.value?.formatted;
      const formattedMemberStatusDate = this.statusDate?.value?.formatted;
      const formData = {
        name: this.name?.value,
        member_syskey: this.syskey?.value,
        fathername: this.fatherName?.value,
        nrcregioncode: this.nrcregioncode?.value,
        nrctype: this.nrctype?.value,
        nrctownshipcode: this.nrctownshipcode?.value,
        nrcnumber: this.nrcnumber?.value,
        race: this.race?.value,
        religion: this.religion?.value,
        length: this.length?.value + 'cm',
        weight: this.weight?.value + 'kg',
        contactno: this.contactNo?.value,
        occupation: this.occupation?.value,
        accountno: this.accountNo?.value,
        accountnofc: "FC" + this.accountNoFC?.value,
        cardcode: this.cardCode?.value,
        voucherno: this.voucherNo?.value,
        joindate: this.allInOneService.convertDate(formattedJoinDate),
        lastpaydate: this.allInOneService.convertDate(formattedLastPayDate),
        memberstatus: this.memberStatus?.value,
        memberstatusdate: this.allInOneService.convertDate(formattedMemberStatusDate),
        address: this.address?.value,
        nrc: this.nrcregioncode?.value + '/' + this.nrctownshipcode?.value +
          '(' + this.nrctype?.value + ')' +
          this.nrcnumber?.value,
        remark: this.remark?.value,
        postcode: this.ward?.value,
      };
      this.kunyek.gymMember().register(formData).subscribe({
        next: (res) => {
          switch (res.returncode) {
            case "301" || "300":
              this.isSaving = false
              this.messageService.openSnackBar(res.message, 'warn')
              break;
            default:
              this.isSaving = false;
              this.messageService.openSnackBar('Success!', 'success')
              this.closeForm()

          }

        },
        error: (err) => {
          this.messageService.openSnackBar('Failed to save member', 'warn');
          this.isSaving = false;
        }
      });
    } else {
      this.messageService.openSnackBar('Invalid Form', 'warn');
      this.isSaving = false;
    }
  }

  onProfileClick() {
    const input = document.createElement('input');
    input.hidden = true;
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', (event: Event) => {
      this.onImageChange(event);
    });
    input.click();
  }

  private onImageChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = e => this.profileImageSrc = reader.result as string;
      reader.readAsDataURL(file);
    }
  }

  closeForm() {
    if (this.router.url === '/members/gym-form') {
      this.router.navigate(['/members']);

    } else {
      this.router.navigate(['/members', 'gymlist']);
    }
  }
}
