import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { LikeTagListComponent } from '../like-tag-list/like-tag-list.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';

declare var $: any;

@Component({
  selector: 'app-social-posts-image-detail',
  templateUrl: './social-posts-image-detail.component.html',
  styleUrls: ['./social-posts-image-detail.component.scss']
})
export class SocialPostsImageDetailComponent implements OnInit {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;
  @ViewChild(QuillEditorComponent, { static: false })
  editor!: QuillEditorComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  appname: string = this.allinoneService.appname;

  post: any;
  domain: any;
  quill: any;
  editText: any;
  currentIndex: number = 0;
  mobileViewWidth: number = 768;

  domaindesc: string = '';
  userid: string = '';
  username: string = '';
  profileimg: string = '';
  commentSyskey: string = '';
  imagename: string = '';
  imageIndex: any = 0;

  comments: any[] = [];

  isMobileView: boolean = false;
  domainAdmin: boolean = false;
  postDomainAdmin: boolean = false;
  orgAdmin: boolean = false;
  block: boolean = false;
  gettingMentionList: boolean = false;
  gettingComments: boolean = false;
  editLoading: boolean = false;
  gettingMoreComments: boolean = false;

  getContactSubscription!: Subscription;
  getCommentSubscription!: Subscription;

  returnFormat = {
    block: false,
    delete: false,
  };

  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    suppressScrollY: false,
    useBothWheelAxes: false,
  };

  mentionableList: any[] = [];

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    // 'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'mention',
  ];
  modules = {
    keyboard: {
      bindings: {
        ['list autofill']: {
          prefix: /^\s*?(\d+\.|\*|\[ ?\]|\[x\])$/,
        },
      },
    },
    mention: {
      onOpen: () => {
        // this.isMentioning = true;
      },
      onClose: () => {
        setTimeout(() => {
          // this.isMentioning = false;
        }, 300);
      },
      // defaultMenuOrientation: 'top',
      renderItem: (item: any) => {
        const img = item.img ? item.img : '../../../assets/images/profile.png';
        const check = item.username == '';
        if (check) {
          var rf =
            `
          <div class = "m-row">
            <div>
                <img class="m-profile-image"
                    src="` +
            img +
            `">
            </div>
            <div class="m-rightside">
               ` +
            item.userid +
            `
            </div>
          </div>
        `;
        } else {
          var rf =
            `
                <div class = "m-row">
                <div>
                    <img class="m-profile-image"
                        src="` +
            img +
            `">
                </div>
                <div class="m-rightside">
                    <div class="m-name">` +
            item.username +
            `</div>
                    <div class="m-id">` +
            item.userid +
            `</div>
                </div>
                </div>
              `;
        }
        return rf;
      },
      renderLoading: () => {
        const x = `
        
          <i class = "fa fa-spinner fa-spin">
        
    `;
        return x;
      },
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      onSelect: (item: any, insertItem: any) => {
        const editor = this.editor.quillEditor;
        insertItem(item);
        // necessary because quill-mention triggers changes as 'api' instead of 'user'
        editor.insertText(editor.getLength() - 1, '', 'user');
        console.log(item);
      },
      source: async (searchTerm: any, renderList: any) => {
        // const values = this.mentionableList;

        // Get List
        var values: any[] = [];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          values = this.mentionableList;
          var matches: any[] = [];
          values.forEach((entry) => {
            if (
              entry.username.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1 ||
              entry.userid.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                -1
            ) {
              matches.push(entry);
            }
          });
          if (values.length == 0 || matches.length == 0) {
            var temp = await this.getMentionList(searchTerm.toLowerCase());
            values = this.mentionableList;
            values.forEach((entry) => {
              if (
                entry.username
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) !== -1 ||
                entry.userid.toLowerCase().indexOf(searchTerm.toLowerCase()) !==
                  -1
              ) {
                matches.push(entry);
              }
            });
          }
          renderList(matches, searchTerm);
        }
      },
    },
    toolbar: [],
  };

  constructor(
    public kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    public messageService: MessageService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<SocialPostsImageDetailComponent>,
    public datepipe: DatePipe,
    public imageCompress: NgxImageCompressService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.post = data.post;
    this.currentIndex = data.index;
    this.onResize(event);
    this.dialogRef.disableClose = true;
    this.userid = this.allinoneService.getKunyekUserId();
    this.username = this.allinoneService.getKunyekUserName();
    this.profileimg = this.allinoneService.getProfileImage();
    this.domainAdmin = this.allinoneService.isAdminOf('domain');
    this.domain = this.allinoneService.getDomain();
  }

  ngOnInit(): void {
    // this.postid = this.route.snapshot.queryParams.id; 
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }

    this.post = this.allinoneService.openedPost;
    this.orgAdmin = this.allinoneService.checkOrgIsInAdminOrgList(
      this.post.orgid
    );
    this.postDomainAdmin = this.allinoneService.checkDomainIsInAdminDomainList(
      this.post.domain
    );
    this.domaindesc = this.allinoneService.getDomainDesc(this.post.domain);

    if (this.post == undefined || this.post == '') {
      console.log('Nothing>>>');
    } else {
      // if (this.post.image.length == 0 || this.post.image.length == 1) {
      //   this.bothDisable = true;
      // }
      // console.log(this.post);
    }
    // this.getGroupData();
    this.getComments();
  }

  viewProfile(userid: any) {

    const dialog = this.dialog.open(UserProfileComponent, {
      height: '400px',
      width: '400px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        user: [],
        userid: userid,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        if (userid == this.post.adminid) {
          this.returnFormat.block = true;
          this.returnFormat.delete = false;
          this.dialogRef.close(this.returnFormat);
        } else {
          this.returnFormat.block = true;
        }
      }
    });
  }

  LikeAndTagForm(syskey: any, type: any, list: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        syskey: syskey,
        type: type,
        list: list,
        imagename: this.post.imagelist[this.currentIndex].imagename
      },
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.block = true;
      }
    });
  }

  back() {
    this.dialogRef.close(this.post);
  }


  slideImage(side: string){
    if(side == 'left'){
      if(this.currentIndex > 0){
        this.currentIndex = this.currentIndex - 1;
      }
      else{
        this.currentIndex = this.post.imagelist.length - 1;
      }
    }
    else{
      if(this.currentIndex == this.post.imagelist.length - 1){
        this.currentIndex = 0;
      }
      else{
        this.currentIndex = this.currentIndex + 1;
      }
    }
    if(this.post.imagelist[this.currentIndex].comments == undefined || this.post.imagelist[this.currentIndex].comments == null || this.post.imagelist[this.currentIndex].comments.length == 0){
      this.getComments();
    }
  }


  like(type: any) {
    if (type == '') {
      this.post.imagelist[this.currentIndex].like = false;
      this.post.imagelist[this.currentIndex].totallike -= 1;
      var index = this.post.imagelist[this.currentIndex].totalreact
        .map((x: any) => x.id)
        .indexOf(this.post.imagelist[this.currentIndex].react);
      this.post.imagelist[this.currentIndex].totalreact[index].count -= 1;
      if (this.post.imagelist[this.currentIndex].totalreact[index].count == 0) {
        this.post.imagelist[this.currentIndex].totalreact.splice(index, 1);
      }
    } else {
      if (this.post.imagelist[this.currentIndex].like) {
        this.post.imagelist[this.currentIndex].like = true;
        var index = this.post.imagelist[this.currentIndex].totalreact
          .map((x: any) => x.id)
          .indexOf(this.post.imagelist[this.currentIndex].react);
        this.post.imagelist[this.currentIndex].totalreact[index].count -= 1;
        if (this.post.imagelist[this.currentIndex].totalreact[index].count <= 0) {
          this.post.imagelist[this.currentIndex].totalreact.splice(index, 1);
        }
        this.post.imagelist[this.currentIndex].react = type;
        var index = this.post.imagelist[this.currentIndex].totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          this.post.imagelist[this.currentIndex].totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          this.post.imagelist[this.currentIndex].totalreact.push(temp);
        }
      } else {
        this.post.imagelist[this.currentIndex].like = true;
        this.post.imagelist[this.currentIndex].react = type;
        this.post.imagelist[this.currentIndex].totallike += 1;
        var index = this.post.imagelist[this.currentIndex].totalreact.map((x: any) => x.id).indexOf(type);
        if (index > -1) {
          this.post.imagelist[this.currentIndex].totalreact[index].count += 1;
        } else {
          var temp = {
            id: type,
            count: 1,
          };
          this.post.imagelist[this.currentIndex].totalreact.push(temp);
        }
      }
    }
    this.likePost(type);
  }

  likePost(type: any) {
    const data = {
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      date: this.allinoneService.getCurrentDateToDB(),
      time: this.allinoneService.getCurrentTimeToDB(),
      postsyskey: this.post.syskey,
      commentsyskey: '',
      type: type,
      posttype: this.post.meetingtype == '' ? '1' : '',
      action: '',
      imagename: this.post.imagelist[this.currentIndex].imagename
    };
    this.kunyekService.likePost(data).subscribe(
      (res: any) => {
        if (res.returncode == 300) {
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }

  getMentionList(search: string) {
    return new Promise((resolve) => {
      this.gettingMentionList = true;
      const data = {
        contact: [],
        count: 0,
        start: 0,
        end: 10,
        search: search,
        domain: this.domain.shortcode,
        domainid: this.domain.domainid,
        orgid: this.post.orgid,
      };
      this.getContactSubscription && this.getContactSubscription.unsubscribe();
      this.getContactSubscription = this.kunyekService
        .getContactList(data)
        .subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.mentionableList = res.list;
              this.mentionableList = this.mentionableList.map(
                (item, index) => ({
                  ...item,
                  id: item.userid,
                  value: item.username ? item.username : item.userid,
                  userid: item.userid,
                  username: item.username,
                  img: item.imagename,
                })
              );
              this.mentionableList.sort((a: any, b: any) => {
                let fa = a.username.toLowerCase(),
                  fb = b.username.toLowerCase();

                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              });
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.gettingMentionList = false;
            resolve(true);
          },
          (err) => {
            this.gettingMentionList = false;
            resolve(false);
          }
        );
    });
  }
  onFileSelect(event: any) {
    console.log(event.target.files[0]);
    var filetypelist = ['image/jpeg', 'image/png', 'image/jpg'];
    if (filetypelist.indexOf(event.target.files[0].type) > -1) {
      this.post.imagetype = event.target.files[0].type
        .toString()
        .split('/')
        .pop();
      this.getBase64(event.target.files[0]).then((data: any) => {
        var filename = event.target.files[0].name;
        this.post.imagelist[this.currentIndex].selectedImage = data;
        this.post.imagelist[this.currentIndex].scImage = event.target.files[0];

        let name = filename.replace(/\s/g, '');
        name = filename.substring(0, filename.lastIndexOf('.'));
        name = filename.substring(0, filename.lastIndexOf('.'));
        let filetype = filename.split('.').pop();
        var date = new Date();
        var millisecond = date.getMilliseconds();
        var time = this.datepipe.transform(
          date,
          'yyyyMMddhhmmss' + millisecond
        );

        this.post.imagelist[this.currentIndex].uploadimage = name + '_' + time + '.' + filetype;
        console.log(this.post.uploadimage);

        this.hiddenfileinput.nativeElement.value = '';
      });
    } else {
      this.hiddenfileinput.nativeElement.value = '';
      this.messageService.openSnackBar(
        'This filetype is not supported.',
        'warn'
      );
    }
  }

  getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  closeImage(comment?: any) {
    if (comment) {
      comment.selectedImage = '';
    } else {
      this.post.imagelist[this.currentIndex].selectedImage = '';
    }
  }

  submitComment(event: any) {
    // event.preventDefault();

    var tempText = this.post.imagelist[this.currentIndex].commentText?.ops;
    if (
      tempText == undefined ||
      tempText == null ||
      (tempText.length == 1 &&
        (tempText[0].insert == '\n' || tempText[0].insert == '\n\n') &&
        !this.post.imagelist[this.currentIndex].selectedImage)
    ) {
      this.messageService.openSnackBar('Comment cannot be blank!', 'warn');
      return;
    } else {
      this.saveComment();
    }
  }

  async saveComment() {
    this.post.imagelist[this.currentIndex].saveCommentLoading = true;

    var tempText = this.post.imagelist[this.currentIndex].commentText.ops;
    var mentionList: any[] = [];
    var finalText: string = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        mentionList.push(item.insert.mention.id.toString());
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        if (item.insert.mention.value) {
          temp += '(__' + item.insert.mention.value.toString() + '__)';
        } else {
          temp += '(__' + item.insert.mention.id.toString() + '__)';
        }
        finalText += temp;
      } else {
        finalText += item.insert.toString();
      }
    });

    finalText = finalText.substring(0, finalText.length - 1);
    var imagename = '',
      imagedata = '';
    var data;
    if (this.post.imagelist[this.currentIndex].selectedImage && this.post.imagelist[this.currentIndex].scImage) {
      if (this.post.imagelist[this.currentIndex].scImage.size >= 307200) {
        this.post.imagelist[this.currentIndex].scImage = await this.allinoneService.compressImage(
          this.post.imagelist[this.currentIndex].scImage
        );
        if (this.post.imagelist[this.currentIndex].scImage == false) {
          this.messageService.openSnackBar(
            'Error while uploading your image.',
            'warn'
          );
          this.post.imagelist[this.currentIndex].saveCommentLoading = false;
          return;
        }
      }
      imagename = this.post.imagelist[this.currentIndex].uploadimage;
      imagedata = this.post.imagelist[this.currentIndex].selectedImage;
      const result = await this.allinoneService.newfileUploadToS3(
        this.post.imagelist[this.currentIndex].scImage,
        this.post.imagelist[this.currentIndex].uploadimage,
        'post/' + this.post.syskey.toString() + '/'
      );
      if (result) {
        data = {
          type: '1',
          postsyskey: this.post.syskey,
          domain: this.domain.shortcode,
          date: this.allinoneService.getCurrentDateToDB(),
          time: this.allinoneService.getCurrentTimeToDB(),
          replysyskey: '',
          comment: finalText,
          mention: mentionList,
          imagename: imagename,
          imagedata: '',
          name: this.username || this.userid,
          imageid: this.post.imagelist[this.currentIndex].imagename
        };
        this.kunyekService.commentPost(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              this.post.imagelist[this.currentIndex].comments.unshift(res.data);
              this.post.imagelist[this.currentIndex].commentText = '';
              this.post.imagelist[this.currentIndex].selectedImage = '';
              this.post.imagelist[this.currentIndex].imagetype = '';
              this.post.imagelist[this.currentIndex].comment += 1;
            } else {
              this.messageService.openSnackBar(res.message, 'warn');
            }
            this.post.imagelist[this.currentIndex].saveCommentLoading = false;
          },
          (err) => {
            this.post.imagelist[this.currentIndex].saveCommentLoading = false;
          }
        );
      } else {
        this.messageService.openSnackBar(
          'Error while uploading image.',
          'warn'
        );
      }
    } else {
      data = {
        type: '1',
        postsyskey: this.post.syskey,
        domain: this.domain.shortcode,
        date: this.allinoneService.getCurrentDateToDB(),
        time: this.allinoneService.getCurrentTimeToDB(),
        replysyskey: '',
        comment: finalText,
        mention: mentionList,
        imagename: imagename,
        imagedata:
          imagedata != ''
            ? imagedata.toString().replace(/^data:image\/\w+;base64,/, '')
            : '',
        name: this.username || this.userid,
        imageid: this.post.imagelist[this.currentIndex].imagename
      };
      this.kunyekService.commentPost(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.post.imagelist[this.currentIndex].comments.unshift(res.data);
            this.post.imagelist[this.currentIndex].commentText = '';
            this.post.imagelist[this.currentIndex].selectedImage = '';
            this.post.imagelist[this.currentIndex].imagetype = '';
            this.post.imagelist[this.currentIndex].comment += 1;
          } else {
            this.messageService.openSnackBar(res.message, 'warn');
          }
          this.post.imagelist[this.currentIndex].saveCommentLoading = false;
        },
        (err) => {
          this.post.imagelist[this.currentIndex].saveCommentLoading = false;
        }
      );
    }
  }

  getComments() {
    this.post.imagelist[this.currentIndex].comments = [];
    this.post.imagelist[this.currentIndex].gettingComments = true;

    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
      imageid: this.post.imagelist[this.currentIndex].imagename
    };
    this.getCommentSubscription && this.getCommentSubscription.unsubscribe();
    this.getCommentSubscription = this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.post.imagelist[this.currentIndex].comments = res.list;
          // this.post.comment = this.post.imagelist[this.currentIndex].comments.length;
          this.post.imagelist[this.currentIndex].end = res.end;
          this.post.imagelist[this.currentIndex].gettingComments = false;
          this.post.imagelist[this.currentIndex].viewmorecomments = res.count == 10;
        } else {
          this.post.imagelist[this.currentIndex].gettingComments = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.post.imagelist[this.currentIndex].gettingComments = false;
      }
    );
  }  

  async compressFile() {
    if (this.imageCompress.byteCount( this.post.imagelist[this.currentIndex].selectedImage) > 1048576) {
      await this.imageCompress
        .compressFile( this.post.imagelist[this.currentIndex].selectedImage, 1, 50, 50)
        .then((result: any) => {
          this.post.imagelist[this.currentIndex].selectedImage = result;
          console.warn(
            'Size in bytes is now:',
            this.imageCompress.byteCount( this.post.imagelist[this.currentIndex].selectedImage)
          );
          if (this.imageCompress.byteCount(result) > 1048576) {
            this.post.imagelist[this.currentIndex].compressFile();
          }
        });
    }
  }

  editCommetConfirm(comment: any, idx: any) {
    var finLst: any[] = [];
    this.post.imagelist[this.currentIndex].comments.map((item: any) => {
      if (item.syskey == comment.syskey) {
        item.edit = true;
        this.editText = item.comment;
        finLst = this.getEditFormat(this.editText);
        setTimeout(() => {
          this.quill = new Quill('#editor-container', {
            modules: {
              mention: {
                // defaultMenuOrientation: 'top',
                spaceAfterInsert: false,
                renderItem: (item: any) => {
                  const img = item.img
                    ? item.img
                    : '../../../assets/images/profile.png';
                  const check = item.username == '';
                  if (check) {
                    var rf =
                      `
                <div class = "m-row">
                  <div>
                      <img class="m-profile-image" 
                          src="` +
                      img +
                      `">
                  </div>
                  <div class="m-rightside">
                     ` +
                      item.userid +
                      `
                  </div>
                </div>
              `;
                  } else {
                    var rf =
                      `
                      <div class = "m-row">
                      <div>
                          <img class="m-profile-image" 
                              src="` +
                      img +
                      `">
                      </div>
                      <div class="m-rightside">
                          <div class="m-name">` +
                      item.username +
                      `</div>
                          <div class="m-id">` +
                      item.userid +
                      `</div>
                      </div>
                      </div>
                    `;
                  }
                  return rf;
                },
                renderLoading: () => {
                  const x = `
                  
                    <i class = "fa fa-spinner fa-spin">
                  
              `;
                  return x;
                },
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                onSelect: (item: any, insertItem: any) => {
                  insertItem(item);
                  // necessary because quill-mention triggers changes as 'api' instead of 'user'
                  this.quill.insertText(this.quill.getLength() - 1, '', 'user');
                  console.log(item);
                },
                source: async (searchTerm: any, renderList: any) => {
                  // const values = this.mentionableList;

                  // Get List
                  var values: any[] = [];

                  if (searchTerm.length === 0) {
                    renderList(values, searchTerm);
                  } else {
                    values = this.mentionableList;
                    var matches: any[] = [];
                    values.forEach((entry) => {
                      if (
                        entry.username
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase()) !== -1 ||
                        entry.userid
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase()) !== -1
                      ) {
                        matches.push(entry);
                      }
                    });
                    if (values.length == 0 || matches.length == 0) {
                      var temp = await this.getMentionList(
                        searchTerm.toLowerCase()
                      );
                      values = this.mentionableList;
                      values.forEach((entry) => {
                        if (
                          entry.username
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase()) !== -1 ||
                          entry.userid
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase()) !== -1
                        ) {
                          matches.push(entry);
                        }
                      });
                    }
                    renderList(matches, searchTerm);
                  }
                },
              },
              toolbar: [],
            },
            formats: this.formats,
          });
          const mentionModule = this.quill.getModule('mention');
          finLst.map((item: any) => {
            if (item.userid.toString().trim() == '') {
              this.quill.insertText(this.quill.getLength() - 1, item.text);
            } else {
              var temp = {
                denotationChar: '@',
                index: item.index,
                id: item.userid,
                value: item.text,
              };
              mentionModule.cursorPos = this.quill.getLength() - 1;
              mentionModule.insertItem(temp, true);
            }
          });
        }, 10);
      } else {
        item.edit = false;
        item.children.map((child: any) => {
          if (child.syskey == comment.syskey) {
            child.edit = true;
            this.editText = child.comment;
            finLst = this.getEditFormat(this.editText);
            setTimeout(() => {
              this.quill = new Quill('#editor-container', {
                modules: {
                  mention: {
                    // defaultMenuOrientation: 'top',
                    spaceAfterInsert: false,
                    renderItem: (item: any) => {
                      const img = item.img
                        ? item.img
                        : '../../../assets/images/profile.png';
                      const check = item.username == '';
                      if (check) {
                        var rf =
                          `
                <div class = "m-row">
                  <div>
                      <img class="m-profile-image" 
                          src="` +
                          img +
                          `">
                  </div>
                  <div class="m-rightside">
                     ` +
                          item.userid +
                          `
                  </div>
                </div>
              `;
                      } else {
                        var rf =
                          `
                      <div class = "m-row">
                      <div>
                          <img class="m-profile-image" 
                              src="` +
                          img +
                          `">
                      </div>
                      <div class="m-rightside">
                          <div class="m-name">` +
                          item.username +
                          `</div>
                          <div class="m-id">` +
                          item.userid +
                          `</div>
                      </div>
                      </div>
                    `;
                      }
                      return rf;
                    },
                    renderLoading: () => {
                      const x = `
                      
                        <i class = "fa fa-spinner fa-spin">
                      
                  `;
                      return x;
                    },
                    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    onSelect: (item: any, insertItem: any) => {
                      insertItem(item);
                      // necessary because quill-mention triggers changes as 'api' instead of 'user'
                      this.quill.insertText(
                        this.quill.getLength() - 1,
                        '',
                        'user'
                      );
                      console.log(item);
                    },
                    source: async (searchTerm: any, renderList: any) => {
                      // const values = this.mentionableList;

                      // Get List
                      var values: any[] = [];

                      if (searchTerm.length === 0) {
                        renderList(values, searchTerm);
                      } else {
                        values = this.mentionableList;
                        var matches: any[] = [];
                        values.forEach((entry) => {
                          if (
                            entry.username
                              .toLowerCase()
                              .indexOf(searchTerm.toLowerCase()) !== -1 ||
                            entry.userid
                              .toLowerCase()
                              .indexOf(searchTerm.toLowerCase()) !== -1
                          ) {
                            matches.push(entry);
                          }
                        });
                        if (values.length == 0 || matches.length == 0) {
                          var temp = await this.getMentionList(
                            searchTerm.toLowerCase()
                          );
                          values = this.mentionableList;
                          values.forEach((entry) => {
                            if (
                              entry.username
                                .toLowerCase()
                                .indexOf(searchTerm.toLowerCase()) !== -1 ||
                              entry.userid
                                .toLowerCase()
                                .indexOf(searchTerm.toLowerCase()) !== -1
                            ) {
                              matches.push(entry);
                            }
                          });
                        }
                        renderList(matches, searchTerm);
                      }
                    },
                  },
                  toolbar: [],
                },
                formats: this.formats,
              });
              const mentionModule = this.quill.getModule('mention');
              finLst.map((item: any) => {
                if (item.userid.toString().trim() == '') {
                  this.quill.insertText(this.quill.getLength() - 1, item.text);
                } else {
                  var temp = {
                    denotationChar: '@',
                    index: item.index,
                    id: item.userid,
                    value: item.text,
                  };
                  mentionModule.cursorPos = this.quill.getLength() - 1;
                  mentionModule.insertItem(temp, true);
                }
              });
            }, 10);
          } else {
            child.edit = false;
          }
        });
      }
    });
  }

  submitEditComment(comment: any) {
    if (comment.imagename == '') {
      var tempText = this.quill.getContents().ops;
      console.log(tempText);
      if (
        tempText == undefined ||
        tempText == null ||
        (tempText.length == 1 &&
          (tempText[0].insert == '\n' || tempText[0].insert == '\n\n'))
      ) {
        this.messageService.openSnackBar('Comment cannot be blank!', 'warn');
      } else {
        this.saveEditComment(comment);
      }
    } else {
      this.saveEditComment(comment);
    }
  }

  closeEditComment(index: number, replyindex?: number) {
    console.log(index, replyindex);
    if (replyindex != undefined) {
      console.log('here');
      this.post.imagelist[this.currentIndex].comments[index].children[replyindex].edit = false;
    } else {
      console.log('a mharrrr');
      this.post.imagelist[this.currentIndex].comments[index].edit = false;
    }
    this.editText = '';
  }

  saveEditComment(comment: any) {
    this.editLoading = true;

    var tempText = this.quill.getContents().ops;
    var mentionList: any[] = [];
    var finalText: string = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        mentionList.push(item.insert.mention.id.toString());
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        if (item.insert.mention.value) {
          temp += '(__' + item.insert.mention.value.toString() + '__)';
        } else {
          temp += '(__' + item.insert.mention.id.toString() + '__)';
        }
        finalText += temp;
      } else {
        finalText += item.insert.toString();
      }
    });

    finalText = finalText.substring(0, finalText.length - 1);
    const data = {
      domain: this.domain.shortcode,
      syskey: comment.syskey,
      comment: finalText,
    };
    this.kunyekService.editComment(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.editLoading = false;
          comment.comment = finalText;
          this.editText = '';
          comment.edit = false;
        } else {
          this.editLoading = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.editLoading = false;
      }
    );
  }

  getEditFormat(text: string) {
    var splitarr = text.split('@[');
    var final_arr = [];
    for (var i = 0; i < splitarr.length; i++) {
      var res = splitarr[i].match(/(\__(.*?)__\]\(__(.*?)__\))/gi);
      if (res) {
        var tem = splitarr[i].split(res[0]);
        let mention = res[0].split('__');
        var temObj = {
          userid: mention[1],
          text: mention[3],
        };
        final_arr.push(temObj);
        const tempObj2 = {
          userid: '',
          text: tem[1],
        };
        final_arr.push(tempObj2);
      } else {
        const tempObj2 = {
          userid: '',
          text: splitarr[i],
        };
        final_arr.push(tempObj2);
      }
    }
    return final_arr;
  }

  deleteCommetConfirm(syskey: any, commentindex: number, replyindex?: number) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.commentSyskey = syskey;
        this.deleteComment(commentindex, replyindex);
      }
    });
  }
  deleteComment(commentindex: number, replyindex?: number) {
    var data = {
      domain: this.domain.shortcode,
      syskey: this.commentSyskey,
    };
    this.kunyekService.deleteComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.commentSyskey = '';

          this.post.imagelist[this.currentIndex].comment -= 1;
          this.messageService.openSnackBar(
            'Comment deleted successfully',
            'success'
          );
          // this.getComments();
          if (replyindex != undefined) {
            this.post.imagelist[this.currentIndex].comments[commentindex].children.splice(replyindex, 1);
            if (this.post.imagelist[this.currentIndex].comments[commentindex].children.length == 0) {
              this.post.imagelist[this.currentIndex].comments[commentindex].childlist = [];
            }
          } else {
            this.post.imagelist[this.currentIndex].comments.splice(commentindex, 1);
          }
        } else {
          this.commentSyskey = '';

          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
  }

  clickcmt(event: any) {
    const userid = event.target.id;
    if (userid) {
      this.viewProfile(userid);
    }
  }

  imageViewer(msg: any, i: any) {
    this.imageIndex = i;

    this.imagename = msg;
    $('#comment-image-viewer').appendTo('body').modal('show');
    console.log(msg);
  }

  submitReplyComment(event: any, index: any) {
    event.preventDefault();
    var tempText = this.post.imagelist[this.currentIndex].comments[index].commentText.ops;
    if (
      tempText == undefined ||
      tempText == null ||
      (tempText.length == 1 &&
        (tempText[0].insert == '\n' || tempText[0].insert == '\n\n') &&
        !this.post.imagelist[this.currentIndex].comments[index].selectedImage)
    ) {
      this.messageService.openSnackBar('Comment cannot be blank!', 'warn');
      return;
    } else {
      this.saveReplyComment(index);
    }
  }

  async saveReplyComment(index: any) {
    var parentComment = this.post.imagelist[this.currentIndex].comments[index];
    parentComment.saveCommentLoading = true;

    var tempText = parentComment.commentText.ops;
    var mentionList: any[] = [];
    var finalText: string = '';
    tempText.map((item: any) => {
      if (item.insert.mention) {
        mentionList.push(item.insert.mention.id.toString());
        var temp = '@[__' + item.insert.mention.id.toString() + '__]';
        if (item.insert.mention.value) {
          temp += '(__' + item.insert.mention.value.toString() + '__)';
        } else {
          temp += '(__' + item.insert.mention.id.toString() + '__)';
        }
        finalText += temp;
      } else {
        finalText += item.insert.toString();
      }
    });

    finalText = finalText.substring(0, finalText.length - 1);
    var imagename = '',
      imagedata = '';
    var data;
    if (parentComment.selectedImage && parentComment.scImage) {
      if (parentComment.scImage.size >= 307200) {
        parentComment.scImage = await this.allinoneService.compressImage(
          parentComment.scImage
        );
        if (parentComment.scImage == false) {
          this.messageService.openSnackBar(
            'Error while uploading your image.',
            'warn'
          );
          parentComment.saveCommentLoading = false;
          return;
        }
      }
      imagename = parentComment.uploadimage;
      imagedata = parentComment.selectedImage;
      const result = await this.allinoneService.newfileUploadToS3(
        parentComment.scImage,
        parentComment.uploadimage,
        'post/' + this.post.syskey.toString() + '/'
      );
      if (result) {
        data = {
          type: '1',
          postsyskey: this.post.syskey,
          domain: this.domain.shortcode,
          date: this.allinoneService.getCurrentDateToDB(),
          time: this.allinoneService.getCurrentTimeToDB(),
          replysyskey: this.post.imagelist[this.currentIndex].comments[index].syskey,
          comment: finalText,
          mention: mentionList,
          imagename: imagename,
          imagedata: '',
          name: this.username || this.userid,
          imageid: this.post.imagelist[this.currentIndex].imagename
        };
        this.kunyekService.commentPost(data).subscribe(
          (res: any) => {
            if (res.returncode == '300') {
              if (
                parentComment.children.length > 0 ||
                (parentComment.children.length == 0 &&
                  parentComment.childlist == 0)
              ) {
                parentComment.children.push(res.data);
              } else {
                this.getReply(parentComment.syskey, index, res.data);
              }
              parentComment.saveCommentLoading = false;
              parentComment.commentText = '';
              parentComment.selectedImage = '';
              parentComment.imagetype = '';
              this.post.imagelist[this.currentIndex].comment += 1;
              parentComment.replyboxopen = false;
            } else {
              parentComment.saveCommentLoading = false;
              this.messageService.openSnackBar(res.message, 'warn');
            }
          },
          (err) => {
            parentComment.saveCommentLoading = false;
          }
        );
      } else {
        this.messageService.openSnackBar(
          'Error while uploading image.',
          'warn'
        );
      }
    } else {
      data = {
        type: '1',
        postsyskey: this.post.syskey,
        domain: this.domain.shortcode,
        date: this.allinoneService.getCurrentDateToDB(),
        time: this.allinoneService.getCurrentTimeToDB(),
        replysyskey: parentComment.syskey,
        comment: finalText,
        mention: mentionList,
        imagename: imagename,
        imagedata:
          imagedata != ''
            ? imagedata.toString().replace(/^data:image\/\w+;base64,/, '')
            : '',
        name: this.username || this.userid,
        imageid: this.post.imagelist[this.currentIndex].imagename
      };
      this.kunyekService.commentPost(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (
              parentComment.children.length > 0 ||
              (parentComment.children.length == 0 &&
                parentComment.childlist == 0)
            ) {
              parentComment.children.push(res.data);
            } else {
              this.getReply(parentComment.syskey, index, res.data);
            }
            parentComment.saveCommentLoading = false;
            parentComment.commentText = '';
            parentComment.selectedImage = '';
            parentComment.imagetype = '';
            parentComment.replyboxopen = false;
            this.post.imagelist[this.currentIndex].comment += 1;
          } else {
            parentComment.saveCommentLoading = false;
            this.messageService.openSnackBar(res.message, 'warn');
          }
        },
        (err) => {
          parentComment.saveCommentLoading = false;
        }
      );
    }
  }

  getReply(syskey: any, index: any, newcomment?: any) {
    this.post.imagelist[this.currentIndex].comments[index].gettingreply = true;
    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
      parentid: syskey,
      imageid: this.post.imagelist[this.currentIndex].imagename
    };
    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.post.imagelist[this.currentIndex].comments[index].children = res.list;
          if (newcomment) {
            var temp = this.post.imagelist[this.currentIndex].comments[index].children.find(
              (x: any) => x.syskey == newcomment.syskey
            );
            if (!temp) {
              this.post.imagelist[this.currentIndex].comments[index].children.push(newcomment);
            }
          }
          this.post.imagelist[this.currentIndex].comments[index].gettingreply = false;
          this.post.imagelist[this.currentIndex].comments[index].end = res.end;
          this.post.imagelist[this.currentIndex].comments[index].viewmorecomments = res.count == 10;
        } else {
          this.post.imagelist[this.currentIndex].comments[index].gettingreply = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.post.imagelist[this.currentIndex].comments[index].gettingreply = false;
      }
    );
  }

  CommentReactForm(syskey: any, commentsyskey: any, type: any, list: any) {
    const dialog = this.dialog.open(LikeTagListComponent, {
      height: '500px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['report-modal', 'mat-typography'],
      data: {
        syskey: syskey,
        commentsyskey: commentsyskey,
        type: type,
        list: list,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.block = true;
      }
    });
  }

  getTotalReactCount(comment: any) {
    let total = 0;
    for (var i = 0; i < comment.totalreact.length; i++) {
      total += comment.totalreact[i].count;
    }
    return total;
  }

  reactComment(reacttype: any, commentsyskey: any, index: any) {
    const reactbox = document.getElementById('reactbox' + index);
    reactbox?.setAttribute('style', 'opacity: 0');
    if (this.post.imagelist[this.currentIndex].comments[index].userreact == '') {
      var resCheck = this.post.imagelist[this.currentIndex].comments[index].totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count += 1;
      } else {
        this.post.imagelist[this.currentIndex].comments[index].totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      this.post.imagelist[this.currentIndex].comments[index].userreact = reacttype;
    } else if (
      this.post.imagelist[this.currentIndex].comments[index].userreact != '' &&
      this.post.imagelist[this.currentIndex].comments[index].userreact != reacttype
    ) {
      var resCheck = this.post.imagelist[this.currentIndex].comments[index].totalreact.findIndex(
        (x: any) => x.id == this.post.imagelist[this.currentIndex].comments[index].userreact
      );
      if (
        resCheck != -1 &&
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count > 1
      ) {
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count == 1
      ) {
        this.post.imagelist[this.currentIndex].comments[index].totalreact.splice(resCheck, 1);
      }
      this.post.imagelist[this.currentIndex].comments[index].userreact = '';

      var resCheck = this.post.imagelist[this.currentIndex].comments[index].totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count += 1;
      } else {
        this.post.imagelist[this.currentIndex].comments[index].totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      this.post.imagelist[this.currentIndex].comments[index].userreact = reacttype;
    } else {
      var resCheck = this.post.imagelist[this.currentIndex].comments[index].totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (
        resCheck != -1 &&
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count > 1
      ) {
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        this.post.imagelist[this.currentIndex].comments[index].totalreact[resCheck].count == 1
      ) {
        this.post.imagelist[this.currentIndex].comments[index].totalreact.splice(resCheck, 1);
      }
      this.post.imagelist[this.currentIndex].comments[index].userreact = '';
    }
    var data = {
      postsyskey: this.post.syskey,
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      date: '',
      time: '',
      commentsyskey: commentsyskey,
      type: reacttype,
      posttype: '',
      action: '',
      imagename: this.post.imagelist[this.currentIndex].imagename
    };
    this.kunyekService.reactComment(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // this.refreshComment();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
    setTimeout(() => {
      reactbox?.removeAttribute('style');
    }, 1000);
  }

  onReplyFileSelect(event: any, index: any) {
    var parentComment = this.post.imagelist[this.currentIndex].comments[index];
    console.log(event.target.files[0]);
    var filetypelist = ['image/jpeg', 'image/png', 'image/jpg'];
    if (filetypelist.indexOf(event.target.files[0].type) > -1) {
      parentComment.imagetype = event.target.files[0].type
        .toString()
        .split('/')
        .pop();
      this.getBase64(event.target.files[0]).then((data: any) => {
        var filename = event.target.files[0].name;
        parentComment.selectedImage = data;
        parentComment.scImage = event.target.files[0];
        let name = filename.replace(/\s/g, '');
        name = filename.substring(0, filename.lastIndexOf('.'));
        name = filename.substring(0, filename.lastIndexOf('.'));
        let filetype = filename.split('.').pop();
        var date = new Date();
        var millisecond = date.getMilliseconds();
        var time = this.datepipe.transform(
          date,
          'yyyyMMddhhmmss' + millisecond
        );
        parentComment.uploadimage = name + '_' + time + '.' + filetype;
        console.log(parentComment.uploadimage);
        this.hiddenfileinput.nativeElement.value = '';
      });
    } else {
      this.hiddenfileinput.nativeElement.value = '';
      this.messageService.openSnackBar(
        'This filetype is not supported.',
        'warn'
      );
    }
  }

  reactReplyComment(
    reacttype: any,
    commentsyskey: any,
    index: any,
    replyindex: any
  ) {
    var replycomment = this.post.imagelist[this.currentIndex].comments[index].children[replyindex];
    const reactbox = document.getElementById('replyreactbox' + index);
    reactbox?.setAttribute('style', 'opacity: 0');
    if (replycomment.userreact == '') {
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        replycomment.totalreact[resCheck].count += 1;
      } else {
        replycomment.totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      replycomment.userreact = reacttype;
    } else if (
      replycomment.userreact != '' &&
      replycomment.userreact != reacttype
    ) {
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == replycomment.userreact
      );
      if (resCheck != -1 && replycomment.totalreact[resCheck].count > 1) {
        replycomment.totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        replycomment.totalreact[resCheck].count == 1
      ) {
        replycomment.totalreact.splice(resCheck, 1);
      }
      replycomment.userreact = '';
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1) {
        replycomment.totalreact[resCheck].count += 1;
      } else {
        replycomment.totalreact.push({
          id: reacttype,
          count: 1,
        });
      }
      replycomment.userreact = reacttype;
    } else {
      var resCheck = replycomment.totalreact.findIndex(
        (x: any) => x.id == reacttype
      );
      if (resCheck != -1 && replycomment.totalreact[resCheck].count > 1) {
        replycomment.totalreact[resCheck].count -= 1;
      } else if (
        resCheck != -1 &&
        replycomment.totalreact[resCheck].count == 1
      ) {
        replycomment.totalreact.splice(resCheck, 1);
      }
      replycomment.userreact = '';
    }
    var data = {
      postsyskey: this.post.syskey,
      domain: this.domain.shortcode,
      domainid: this.domain.domainid,
      date: '',
      time: '',
      commentsyskey: commentsyskey,
      type: reacttype,
      posttype: '',
      action: '',
      imagename: this.post.imagelist[this.currentIndex].imagename
    };
    this.kunyekService.reactComment(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.refreshComment();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {}
    );
    setTimeout(() => {
      reactbox?.removeAttribute('style');
    }, 1000);
  }

  refreshComment() {
    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
    };

    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          var temp = res.list.reverse();
          this.post.imagelist[this.currentIndex].comment = temp.length;
          var tempNewPosts: any[] = [];

          temp.map((tcmt: any) => {
            //new chat
            const checknewchat = this.post.imagelist[this.currentIndex].comments.filter(
              (cmt: any) => cmt.sort == tcmt.sort
            );
            if (checknewchat.length == 0) {
              tempNewPosts.push(tcmt);
            }
          });

          tempNewPosts.map((newChat: any) => {
            this.post.imagelist[this.currentIndex].comments.push(newChat);
          });
          this.post.imagelist[this.currentIndex].commentText = '';
          this.post.imagelist[this.currentIndex].selectedImage = '';
          this.post.imagelist[this.currentIndex].imagetype = '';
          // this.rightSideScrollbar.scrollToBottom();
        } else {
          this.messageService.openSnackBar(res.message, 'warn');
        }
        this.post.imagelist[this.currentIndex].saveCommentLoading = false;
      },
      (err) => {}
    );
  }

  getMoreReply(syskey: any, index: any) {
    this.post.imagelist[this.currentIndex].comments[index].gettingmorereply = true;
    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
      parentid: syskey,
      end: this.post.imagelist[this.currentIndex].comments[index].end,
      imageid: this.post.imagelist[this.currentIndex].imagename
    };
    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          // Check the previous data
          var newComments: any[] = [];
          console.log(res.list);
          res.list.map((cmt: any) => {
            console.log(cmt);
            var check = this.post.imagelist[this.currentIndex].comments[index].children.find(
              (x: any) => x.syskey == cmt.syskey
            );
            console.log(check);
            if (!check) {
              newComments.push(cmt);
            }
          });
          this.post.imagelist[this.currentIndex].comments[index].end = res.end;
          this.post.imagelist[this.currentIndex].comments[index].children.push(...newComments);
          this.post.imagelist[this.currentIndex].comments[index].gettingmorereply = false;
          this.post.imagelist[this.currentIndex].comments[index].end = res.end;
          this.post.imagelist[this.currentIndex].comments[index].viewmorecomments = res.count == 10;
        } else {
          this.post.imagelist[this.currentIndex].comments[index].gettingmorereply = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.post.imagelist[this.currentIndex].comments[index].gettingmorereply = false;
      }
    );
  }

  getMoreComments() {
    this.post.imagelist[this.currentIndex].gettingMoreComments = true;

    const data = {
      domain: this.domain.shortcode,
      postsyskey: this.post.syskey,
      end: this.post.imagelist[this.currentIndex].end,
      imageid: this.post.imagelist[this.currentIndex].imagename
    };

    this.kunyekService.getComments(data).subscribe(
      (res: any) => {
        // console.log(res);
        if (res.returncode == '300') {
          this.post.imagelist[this.currentIndex].comments.push(...res.list);
          this.post.imagelist[this.currentIndex].end = res.end;
          this.post.imagelist[this.currentIndex].gettingMoreComments = false;
          this.post.imagelist[this.currentIndex].viewmorecomments = res.count == 10;
        } else {
          this.post.imagelist[this.currentIndex].gettingMoreComments = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.post.imagelist[this.currentIndex].gettingMoreComments = false;
      }
    );
  }

  downloadImage() {
    window.open(this.imagename, '_blank');
  }

}
