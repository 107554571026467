<div class="me-auto px-5 mt-3">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
</div>

<div class="d-flex flex-column align-items-center gap-2 mb-5">
    <div class="d-flex ms-auto btn-action">
        <button class="btn btn-custom me-2" (click)="printPage()">Print</button>
        <button class="btn btn-custom" (click)="exportPDF()">Export PDF</button>
    </div>
    <div class="card px-5 py-3" id="printableCard" style="width: 780px;">
        <div class="d-flex flex-column gap-1 align-items-center mt-3">
            <h2>ကုက္ကိုင်းရေကူးအသင်း</h2>
            <h2>နွေရာသီရေကူးသင်တန်း လျှောက်လွှာပုံစံ</h2>
        </div>
        <div class="p-3 mb-3" style=" background-color: white; border: solid 1px #ddd; border-radius: 6px;">
            <div>
                <table style="width: 100%; border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td></td>
                            <td class="text-end">
                                <img [src]="summerswimmer?.profileimage ? summerswimmer?.profileimage : '../../../assets/images/member.png'"
                                    class="img-fluid border border-primary" alt="summerswimmer image" width="120px">
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အပါတ်စဉ်</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.batch?.batch_no }} ( {{
                                allInOneService.formatDBToShow(summerswimmer?.batch?.start_date)}} - {{
                                allInOneService.formatDBToShow(summerswimmer?.batch?.end_date)}} {{
                                summerswimmer?.batch.time }} )</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အမည် (မြန်မာ)</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.nameinburmese }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အမည် (အင်္ဂလိပ်)</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.nameinenglish }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">KSC No (အသင်းဝင်ဖြစ်လျှင်)</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.kscno ? summerswimmer?.kscno : '-' }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">မွေးသက္ကရာဇ်</td>
                            <td class="me-5 dashed-line">{{ allInOneService.formatDBToShow(summerswimmer?.birthdate)
                                }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အရပ်</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.height }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">နိုင်ငံသားမှတ်ပုံတင်အမှတ်</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.nrc }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">လူမျိုး</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.race }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">ကိုးကွယ်သည့်ဘာသာ</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.religion }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">ပညာအရည်အချင်း</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.education }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အလုပ်အကိုင်</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.occupation }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အဖအမည်</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.fathername }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">အမိအမည်</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.mothername }}</td>
                        </tr>
                        <tr *ngIf="summerswimmer?.directory">
                            <td style="width: 200px;">တိုင်းဒေသကြီး/ပြည်နယ်</td>
                            <td class="me-5 dashed-line">{{
                                summerswimmer?.directory?.ward + '၊' }} {{
                                summerswimmer?.directory?.town + '၊' }} {{ summerswimmer?.directory?.region + '၊၊'}}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">ဆက်သွယ်ရန်လိပ်စာ</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.address }}</td>
                        </tr>
                        <tr>
                            <td style="width: 200px;">ဆက်သွယ်ရန်ဖုန်းနံပါတ်</td>
                            <td class="me-5 dashed-line">{{ summerswimmer?.phone }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-2 mt-2">
            <div>
                <span> * ကျွန်တော်/ကျွန်မ ၏ သား/သမီး ဖြစ်သူ <b>{{ summerswimmer?.nameinenglish}}</b> သည်
                    ကျန်းမာရေးကောင်းမွန်ပြီး ကုက္ကိုင်းရေကူး အသင်းမှ ချမှတ်ထားသော
                    စည်းကမ်းများကို လိုက်နာစေရန် တာဝန်ယူမည်ဟု ကတိပြုပါသည်။
                </span>
            </div>
        </div>
    </div>
</div>