<div class="title-container mb-3">
    <div class="title">
        Report
    </div>
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<hr>
<div class="list-group">
    <div class="list-group-item d-flex justify-content-between">
        <label for="nudity">
            Nudity
          </label>
          <input class="form-radio-input me-1" type="radio" value="0" name="reportRaido" id="nudity" (change)="onItemChange($event)" checked>

    </div>
    <div class="list-group-item d-flex justify-content-between">
        <label for="violence">
            Violence
          </label>
          <input class="form-radio-input me-1" type="radio" value="1" name="reportRaido" id="violence" (change)="onItemChange($event)">

    </div>
    <div class="list-group-item d-flex justify-content-between">
        <label for="harassment">
            Harassment
          </label>
          <input class="form-radio-input me-1" type="radio" value="2" name="reportRaido" id="harassment" (change)="onItemChange($event)">

    </div>
    <div class="list-group-item d-flex justify-content-between">
        <label for="finformation">
        False Information
          </label>
          <input class="form-radio-input me-1" type="radio" value="3" name="reportRaido" id="finformation" (change)="onItemChange($event)">

    </div>
    <div class="list-group-item d-flex justify-content-between">
        <label for="hatespeech">
        Hate Speech
          </label>
          <input class="form-radio-input me-1" type="radio" value="4" name="reportRaido" id="hatespeech" (change)="onItemChange($event)">

    </div>
    <div class="list-group-item d-flex justify-content-between">
        <label for="terrorism">
        Terrorism
          </label>
          <input class="form-radio-input me-1" type="radio" value="5" name="reportRaido" id="terrorism" (change)="onItemChange($event)">

    </div>
    <div class="list-group-item d-flex justify-content-between">
        <label for="somethingelse">
        Something Else
          </label>
          <input class="form-radio-input me-1" type="radio" value="6" name="reportRaido" id="somethingelse" (change)="onItemChange($event)">

    </div>
   
 
  </div>
  <div class="mt-3" >
    <app-button-loader (click)="save()" [button_text]="'Submit'"
        [load_button_text]="'Submitting'" [isLoading]="saveLoading" [width]="'100'">
    </app-button-loader>
  </div>


