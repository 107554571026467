import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KunyekService } from '../../shared/services/kunyek.service';
import { MessageService } from '../../shared/services/message.service';

@Component({
  selector: 'app-events-detail',
  templateUrl: './events-detail.component.html',
  styleUrls: ['./events-detail.component.scss']
})
export class EventsDetailComponent implements OnInit {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  value : any = "";
  event: any;

  element!: HTMLImageElement;

  qrLoading : boolean = false;
  fromEditForm : boolean = false;


  constructor(
    public allinoneService: AllInOneService,
    public kunyekService : KunyekService,
    public messageService: MessageService,
    private dialogRef: MatDialogRef<EventsDetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    ) { 
      this.event = data.event
      this.fromEditForm=data.fromEditForm
    }

  ngOnInit(): void {
    this.getQR()
  }

  getQR(){
    this.qrLoading = true;
    const data = {
      eventid: this.event.eventid,
      orgid: this.event.orgid,
      domainid: this.allinoneService.getDomain().domainid
    }
    this.kunyekService.getQrString(data).subscribe((res:any) =>{
      if(res.returncode == "300"){
        this.qrLoading = false;
        this.value = res.qrurl
      }
      else {
        this.qrLoading = false;
        this.messageService.openSnackBar(res.message, 'warn');
      }
    })
  }

  downloadQRCode() {
    const fileNameToDownload = this.event.name;
    const htmlstring = document.getElementsByClassName('bshadow')[0].children[0];

    const base64Img = this.getImgSrc(htmlstring)

    fetch(base64Img)
       .then(res => res.blob())
       .then((blob) => {
          // IE
          if (window.navigator && window.navigator.msSaveOrOpenBlob){
             window.navigator.msSaveOrOpenBlob(blob,fileNameToDownload);
          } else { // Chrome
             const url = window.URL.createObjectURL(blob);
             const link = document.createElement('a');
             link.href = url;
             link.download = fileNameToDownload;
             link.click();
          }
       })
 }

 getImgSrc(htmlString: any){
  this.element = <HTMLImageElement>htmlString;
  return this.element.src;
}
}
