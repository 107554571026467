<div class="title-container mb-3">
    <div class="title">
        Sub Fees
    </div>
    <div class="close-btn">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<form [formGroup]="monthlyFeedDetailForm">
    <div class="row mb-3">
        <div class="col">
            <label for="feetype" class="form-label">Pay Period</label>
            <select class="form-select" id="feetype" name="feetype" formControlName="payperiod_id" required>
                <option value="">-</option>
                <option *ngFor="let item of paymentPeriodsList" [value]="item.syskey">
                    {{item.description}}</option>
            </select>
        </div>
    
    </div>

    <div class="row mb-3">
        <div class="col">
            <label for="feetype" class="form-label">Fee Type</label>
            <select class="form-select" id="feetype" name="feetype" formControlName="feetype_id" required>
                <option value="">-</option>
                <option *ngFor="let item of feetypes" [value]="item.feetype_id">
                    {{item.description}}</option>
            </select>
        </div>
    
    </div>
    
    <div class="row">
        <div class="col">
            <label for="" class="form-label">Amount</label>
            <input type="text" class="form-control" formControlName="amount">
        </div>
    </div>
    <div class="close-save-button justify-content-end mt-3">
        <button type="button" class="btn btn-custom me-2" (click)="close()">
            Cancel
        </button>
        <app-button-loader [button_text]="'Save'" [load_button_text]="'Saving'" (click)="addMonthlyFeesDetail()" [isLoading]="createDetailLoading" type="submit"></app-button-loader>
    </div>
</form>

