
import { DatePipe, formatDate, } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,

} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { RequestPickupComponent } from '../request-pickup/request-pickup.component';
import { Sort } from '@angular/material/sort';
import { CrmFilterPipe } from 'src/app/crm/crm/crm-filter.pipe';
import { FilterPipe } from 'src/app/shared/filter.pipe';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-ferry-request',
  templateUrl: './ferry-request.component.html',
  styleUrls: ['./ferry-request.component.scss']
})
export class FerryRequestComponent implements OnInit {

  gettingData: boolean = false;
  ferrryRequestList: any = [];
  addrequest: boolean = false;
  saveLoading: boolean = false;
  gettingVehicle: boolean = false;
  carFormSave: boolean = false;
  isEditing: boolean = false;
  orgid: any = "";
  organizations: any = [];
  searchText: any = "";

  itemPerPage: any = "";
  currentPage: any = 1;
  text1: any = "";
  text2: any = "";
  oldpickuppointid: any = "";
  startdate: any = "";
  allFerry: boolean = false;

  gettingDataAll: boolean = false;
  allferrryRequestList: any = [];
  weekDay = [
    {
      name: 'Monday',
      value: 'Monday',
    },
    {
      name: 'Tuesday',
      value: 'Tuesday',
    },
    {
      name: 'Wednesday',
      value: 'Wednesday',
    },
    {
      name: 'Thursday',
      value: 'Thursday',
    },
    {
      name: 'Friday',
      value: 'Friday',
    },
    {
      name: 'Saturday',
      value: 'Saturday',
    },
    {
      name: 'Sunday',
      value: 'Sunday',
    },
  ];
  pg = [
    {
      name: '100 items',
      count: 100,
    },
    {
      name: '300 items',
      count: 300,
    },
    {
      name: '500 items',
      count: 500,
    },
  ];

  ferryRequest: any = [];
  requeststatus: any = "";
  requestid: any = "";
  statusList = [
    { "name": "All", "code": "" },
    { "name": "Submitted", "code": "001" },
    { "name": "Approved", "code": "002" },
    { "name": "Rejected", "code": "003" },
    { "name": "Completed", "code": "004" }
  ];


  requestData: any = [];
  istransoprationAdmin: boolean = false;
  formatToShow = 'yyyy-MM-dd';
  startdateall: any = "";
  username: any = "";
  currentPageAllSelected: boolean = false;



  selectedRequests = 0;
  approveCount = 0;
  rejectCount = 0;
  checkCount = 0;


  approveLoading: boolean = false;
  rejectLoading: boolean = false;

  isApproving: boolean = false;
  isRejecting: boolean = false;

  tranparentid: string = '';



  constructor(
    public allinoneService: AllInOneService,
    private http: HttpClient,
    private messageService: MessageService,
    private kunyekService: KunyekService,
    private activatedRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private filter: FilterPipe
  ) {
    this.istransoprationAdmin = this.allinoneService.isAdminOf("transportation");
    if (this.istransoprationAdmin == false) {
      this.router.navigateByUrl(`transportation`);
    }
    this.organizations = this.allinoneService.tranOrgs;
    this.orgid = this.allinoneService.selectedTranOrg
      ? this.allinoneService.selectedTranOrg
      : this.organizations[0].orgid;
  }

  ngOnInit(): void {
    this.startdate = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.startdateall = formatDate(Date.now(), this.formatToShow, 'en-US').toString();
    this.getFerrryRequestList();
  }



  cancelFunction() {
    this.addrequest = false;
    this.isEditing = false;
    this.allFerry = false;

  }

  cancelFunction1() {
    this.addrequest = false;
    this.isEditing = false;
    this.allFerry = true;
  }


  getFerrryRequestList() {
    this.gettingData = true;
    this.ferrryRequestList = [];
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "requesttype": "002",
      "trantype": "001",
      "requeststatus": this.requeststatus,
      "date": formatDate(
        new Date(this.startdate),
        'yyyyMMdd',
        'en-US'
      ).toString()
      // "date":this.allinoneService.formatDBToShow(this.startdate)
    };
    this.kunyekService.getRequest(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          this.ferrryRequestList = res.datalist;

          this.gettingData = false;
        } else {

          this.gettingData = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingData = false;
      }
    );
  }
  getFerrryRequestListAll() {

    this.gettingDataAll = true;
    this.allferrryRequestList = [];
    var data = {
      "domainid": this.allinoneService.getDomain().domainid,
      "orgid": this.orgid,
      "requesttype": "002",
      "trantype": "001",
      "requeststatus": this.requeststatus,
      "startdate": "",
      "enddate": "",
      "requestid": this.requestid,
      "byadmin": true,
      "end": ""
    };
    this.kunyekService.getRequestAll(data).subscribe(
      (res: any) => {

        if (res.returncode == '300') {
          // this.allferrryRequestList = res.requestlist;
          if (res.approvallist.length > 0) {
            this.tranparentid = res.approvallist[0].olduserid ? res.approvallist[0].olduserid : res.approvallist[0].userid;

            var requestchild = res.approvallist[0]["child"];
            for (var i = 0; i < requestchild.length; i++) {
              requestchild[i]["requestsubtype"] = res.approvallist[0]["requestsubtype"];
              this.allferrryRequestList.push(requestchild[i])
            }
          }
          this.gettingDataAll = false;
        } else {

          this.gettingDataAll = false;
          this.messageService.openSnackBar(res.message, 'warn');
        }
      },
      (err: any) => {
        this.messageService.openSnackBar(err, 'warn');
        this.gettingDataAll = false;
      }
    );
  }

  customerSelect(data: any) {
    console.log(data);


  }


  goAll(data: any, index: any) {
    // this.messageService.openSnackBar("Not available", 'warn');

    this.allFerry = true;
    this.isEditing = false;
    this.requestid = data.requestid;

    console.log(data);
    // this.requestData = data;
    this.ferryRequest.startdate = data.startdate;
    this.ferryRequest.enddate = data.enddate;
    this.ferryRequest.weekday = data.weekday;
    this.ferryRequest.remark = data.remark;
    this.ferryRequest.refno = data.requestrefno;
    this.oldpickuppointid = data.pickuppointid;
    this.ferryRequest.index = index;

    console.log(this.oldpickuppointid);
    this.getFerrryRequestListAll();
  }

  async EmployeeEdit(data: any, index: any) {
    console.log(data);
    // this.requestData = data;
    this.isEditing = true;
    this.allFerry = false;
    var refno= this.ferryRequest.refno;
    this.ferryRequest =  JSON.parse(JSON.stringify(data));
    this.ferryRequest.refno = refno;
    this.ferryRequest.date = this.allinoneService.formatDBToShow(this.allinoneService.formatDate(data.date));

    this.username = data.username;
    this.ferryRequest.name = data.username;
    this.ferryRequest.employeeid = data.employeeid;
    this.ferryRequest.requestid = data.requestid;
    this.ferryRequest.userid = data.userid;
    this.ferryRequest.status = data.requeststatus;
    this.ferryRequest.statusname = data.requeststatus == '001' ? 'Submitted' : data.requeststatus == '002' ? 'Approved' : data.requeststatus == '003' ? 'Rejected' : data.requeststatus == '004' ? 'Completed' : '';


    // this.ferryRequest.name = data.username;
    // this.ferryRequest.employeeid = data.employeeid;
    // this.ferryRequest.startdate = data.startdate;
    // this.ferryRequest.enddate = data.enddate;  
    // this.ferryRequest.date = this.allinoneService.formatDBToShow(this.allinoneService.formatDate(data.startdate)) + "-" + this.allinoneService.formatDBToShow(this.allinoneService.formatDate(data.enddate));

    // this.ferryRequest.userid = data.userid;
    // this.ferryRequest.weekday = data.weekday;
    // this.ferryRequest.requestid = data.requestid;
    // this.ferryRequest.remark = data.remark;
    // // this.ferryRequest.refno = data.requestrefno;
    // this.oldpickuppointid = data.pickuppointid;

    // this.ferryRequest.index = index;
    // this.ferryRequest.status = "001"
    console.log(this.oldpickuppointid);

  }

  refreshgetData() {
    if (this.allFerry) {
      this.isEditing = false;
      this.currentPageAllSelected = false;
      this.approveCount = 0;
      this.rejectCount = 0;
      this.checkCount = 0;
      this.getFerrryRequestListAll();
    } else {
      this.getFerrryRequestList();

    }

  }


  changePageCount(event: any) {
    console.log(this.itemPerPage)
    this.itemPerPage = event.target.value;
    console.log(this.itemPerPage)
    this.currentPage = 1;
    this.refreshgetData();
  }
  showpickup() {
    const dialog = this.dialog.open(RequestPickupComponent, {
      height: '470px',
      width: '470px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orgid: this.orgid,
        list: this.requestData,
        oldpickuppointid: this.oldpickuppointid,
        type: "001"
      },
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.oldpickuppointid = dialogResult.data['newpickupointid'];
        this.ferrryRequestList[this.ferryRequest.index].pickuppointid = dialogResult.data['newpickupointid'];

      }
    });
  }



  sortData(sort: Sort) {
    const data = this.ferrryRequestList;
    if (!sort.active || sort.direction === '') {
      this.ferrryRequestList = data;
    }

    this.ferrryRequestList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(
            a.username.toLocaleLowerCase(),
            b.username.toLocaleLowerCase(),
            isAsc
          );
        case 'employeeid':
          return this.allinoneService.compare(
            parseInt(a.employeeid),
            parseInt(b.employeeid),
            isAsc
          );
        case 'requestrefno':
          return this.allinoneService.compare(
            parseInt(a.requestrefno),
            parseInt(b.requestrefno),
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdate.toLocaleLowerCase(),
            b.startdate.toLocaleLowerCase(),
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(
            a.status.toLocaleLowerCase(),
            b.status.toLocaleLowerCase(),
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(
            a.remark.toLocaleLowerCase(),
            b.remark.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }

    });

  }
  sortDataall(sort: Sort) {
    const data = this.ferrryRequestList;
    if (!sort.active || sort.direction === '') {
      this.ferrryRequestList = data;
    }

    this.ferrryRequestList = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'username':
          return this.allinoneService.compare(
            a.username.toLocaleLowerCase(),
            b.username.toLocaleLowerCase(),
            isAsc
          );
        case 'employeeid':
          return this.allinoneService.compare(
            parseInt(a.employeeid),
            parseInt(b.employeeid),
            isAsc
          );
        case 'requestrefno':
          return this.allinoneService.compare(
            parseInt(a.requestrefno),
            parseInt(b.requestrefno),
            isAsc
          );
        case 'startdate':
          return this.allinoneService.compare(
            a.startdate.toLocaleLowerCase(),
            b.startdate.toLocaleLowerCase(),
            isAsc
          );
        case 'status':
          return this.allinoneService.compare(
            a.status.toLocaleLowerCase(),
            b.status.toLocaleLowerCase(),
            isAsc
          );
        case 'remark':
          return this.allinoneService.compare(
            a.remark.toLocaleLowerCase(),
            b.remark.toLocaleLowerCase(),
            isAsc
          );
        default:
          return 0;
      }

    });

  }



  downloadSheet() {
    var data: any = [];

    if (this.allFerry) {
      var ferryExport = this.filter.transform(
        this.allferrryRequestList,
        this.searchText,
        "allferryrequest"
      );
      console.log(ferryExport);
      ferryExport.map((car: any) => {
        data.push({
          "User ID": car.userid,
          "Name": car.username,
          "Date": this.allinoneService.formatDBToShow(this.allinoneService.formatDate(car.date)),
          "Status": car.requeststatus == '001' ? 'Submitted' :
            car.requeststatus == '002' ? 'Approved' : car.requeststatus == '003' ?
              'Rejected' : car.requeststatus == '004' ? 'Completed' : '',
          "Pick Up Point": car.pickuppointname,
          "Remark": car.remark,
        });
      });
    } else {
      var ferryExport = this.filter.transform(
        this.ferrryRequestList,
        this.searchText,
        "ferryrequest"
      );
      ferryExport.map((car: any) => {
        data.push({
          "Ref No.": car.requestrefno,
          "Employee ID": car.employeeid,
          "Name": car.username,
          "Date": this.allinoneService.formatDBToShow(this.allinoneService.formatDate(car.startdate)) + " - " + this.allinoneService.formatDBToShow(this.allinoneService.formatDate(car.enddate)),
          "Remark": car.remark,
          "Week Day": car.weekday.join(','),
        });
      });
    }


    var date = new Date();
    var time = this.datepipe.transform(date, 'yyyy/MM/dd');
    var name = ""
    if (this.allFerry) {
      name = 'Ferry_Requests_' + '_' + time + '.xlsx';
    } else {
      name = 'Ferry_Requests_' + '_' + time + '.xlsx';

    }

    this.allinoneService.exportEcecl(data, name);

  }
  searchdateApi() {
    this.refreshgetData();
  }


  statusname: any = "All";
  statusChange(status: any) {
    if (status.code) {
      this.requeststatus = status.code;
      this.statusname = status.name;
      this.getFerrryRequestListAll();
    } else {
      this.statusname = "All";
      this.requeststatus = "";
      this.getFerrryRequestListAll();
    }
  }



  submitApprove() {
    if (!this.rejectLoading) {
      this.isApproving = true;
      var message =
        'Are you sure to approve selected ' + this.approveCount + ' records?';
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        // width: '3256x',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          cmessage: message,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.approveLoading = true;
          var tempApproveList = [];
          var datalist = this.allferrryRequestList
          for (var i = 0; i < datalist.length; i++) {
            if (
              datalist[i].isChecked &&
              (datalist[i].requeststatus == '001' ||
                datalist[i].requeststatus == '003')
            ) {
              console.log(datalist[i]);
              // tempApproveList.push(this.approvalList[i]);
              tempApproveList.push({
                parentid: this.tranparentid,
                date: datalist[i].date,
                memberid: datalist[i].olduserid,
                requestid: datalist[i].transportationid,
                requestsubtype: datalist[i].requestsubtype,
                approver: datalist[i].approver ? datalist[i].approver : [],
              });
            }
          }
          // console.log(tempApproveList.length);
          var start = 0;
          var end = 100;
          if (end > tempApproveList.length) {
            end = tempApproveList.length;
          }

          tempApproveList = tempApproveList.sort((a: any, b: any) => {
            return this.allinoneService.compare(a.date, b.date, false);
          });

          this.updatePayment(tempApproveList, start, end, '002');
        }
      });
    }
  }


  submitRejected() {
    if (!this.approveLoading) {
      var message =
        'Are you sure to reject selected ' + this.rejectCount + ' records?';
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: '400px',
        // width: '285px',
        panelClass: ['mat-typography'],
        closeOnNavigation: true,
        data: {
          cmessage: message,
        },
      });
      dialog.afterClosed().subscribe((dialogResult: any) => {
        if (dialogResult) {
          this.rejectLoading = true;
          var tempRejectList = [];
          var datalist = this.allferrryRequestList;
          for (var i = 0; i < datalist.length; i++) {
            if (
              datalist[i].isChecked &&
              (datalist[i].requeststatus == '001' ||
                datalist[i].requeststatus == '002')
            ) {
              // tempRejectList.push(this.approvalList[i]);
              tempRejectList.push({
                parentid: this.tranparentid,
                date: datalist[i].date,
                memberid: datalist[i].olduserid,
                requestid: datalist[i].transportationid,
                requestsubtype: datalist[i].requestsubtype,
                approver: datalist[i].approver ? datalist[i].approver : [],
              });
            }
          }
          // console.log(tempRejectList.length);
          var start = 0;
          var end = 100;
          if (end > tempRejectList.length) {
            end = tempRejectList.length;
          }

          tempRejectList = tempRejectList.sort((a: any, b: any) => {
            return this.allinoneService.compare(a.date, b.date, false);
          });

          this.updatePayment(tempRejectList, start, end, '003');
        }
      });
    }
  }





  updatePayment(list: any, start: number, end: number, type: string) {
    var flist: any = [];
    for (var i = start; i < end; i++) {
      flist.push(list[i]);
    }

    var startdate = flist[flist.length - 1].date;
    var enddate = flist[0].date;
    var data: any;
    var fun;
    data = {
      orgid: this.orgid,
      requestlist: flist,
      transportation: true,
      byadmin: true,
      startdate: startdate,
      enddate: enddate,
      requeststatus: type,
    };


    console.log(data);

    fun = this.kunyekService.updateMultipleRequest(data);

    fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          if (end == list.length) {
            this.refreshgetData();

            // this.multipleLoading = false;
            if (type == '002') {
              this.approveLoading = false;
            } else if (type == '003') {
              this.rejectLoading = false;
            }
          } else {
            start = end;
            end = end + 100;
            if (end > list.length) {
              end = list.length;
            }
            this.updatePayment(list, start, end, type);
          }
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
          // this.multipleLoading = false;
          if (type == '002') {
            this.approveLoading = false;
          } else if (type == '003') {
            this.rejectLoading = false;
          }
        }
      },
      (err) => {
        // this.multipleLoading = false;
        if (type == '002') {
          this.approveLoading = false;
        } else if (type == '003') {
          this.rejectLoading = false;
        }
      }
    );
  }


  selectRequest(request: any) {
    if (request.isChecked) {
      if (request.requeststatus == '001' || request.requeststatus == '003') {
        this.approveCount += 1;
      }
      if (request.requeststatus == '001' || request.requeststatus == '002') {
        this.rejectCount += 1;
      }

    } else {
      if (request.requeststatus == '001' || request.requeststatus == '003') {
        this.approveCount -= 1;
      }
      if (request.requeststatus == '001' || request.requeststatus == '002') {
        this.rejectCount -= 1;
      }
    }
  }

  currentPageSelectAll() {
    if (this.currentPageAllSelected) {
      this.approveCount = 0;
      this.rejectCount = 0;
      for (var i = 0; i < this.allferrryRequestList.length; i++) {
        this.allferrryRequestList[i].isChecked = true;
        if (
          this.allferrryRequestList[i].requeststatus == '001' ||
          this.allferrryRequestList[i].requeststatus == '003'
        ) {
          this.approveCount += 1;
        }

        if (
          this.allferrryRequestList[i].requeststatus == '001' ||
          this.allferrryRequestList[i].requeststatus == '002'
        ) {
          this.rejectCount += 1;
        }
      }
    } else {
      for (var i = 0; i < this.allferrryRequestList.length; i++) {
        if (
          this.allferrryRequestList[i].isChecked &&
          (this.allferrryRequestList[i].requeststatus == '001' ||
            this.allferrryRequestList[i].requeststatus == '003')
        ) {
          this.approveCount -= 1;
        }
        if (
          this.allferrryRequestList[i].isChecked &&
          (this.allferrryRequestList[i].requeststatus == '001' ||
            this.allferrryRequestList[i].requeststatus == '002')
        ) {
          this.rejectCount -= 1;
        }

        this.allferrryRequestList[i].isChecked = false;
      }
    }
  }

  submitApproval(
    type: string,
  ) {
    if (type == '002') {
      this.isApproving = true;
    } else {
      this.isRejecting = true;
    }
    var data;
    data = {
      orgid: this.orgid,
      requeststatus: type,
      roleid: this.allinoneService.getSelectedRole(),
      byadmin: true,
      transportation: true,
      startdate: this.allinoneService.formatImportDate(this.ferryRequest.date),
      enddate: this.allinoneService.formatImportDate(this.ferryRequest.date),
      requestlist: [
        {
          parentid: this.tranparentid,
          date: this.allinoneService.formatImportDate(this.ferryRequest.date),
          memberid: this.ferryRequest.olduserid ? this.ferryRequest.olduserid : this.ferryRequest.userid,
          requestid: this.ferryRequest.transportationid,
          requestsubtype: this.ferryRequest.requestsubtype,
          approver: [],
        },
      ],
    };
    this.kunyekService.updateMultipleRequest(data).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.ferryRequest.requeststatus = type;
          this.ferryRequest.requeststatusname = this.statusList.find(
            (x: any) => x.value == type
          )?.name;
          this.ferryRequest.rejectperson =
            this.allinoneService.getKunyekUserId();
          this.ferryRequest.personname =
            this.allinoneService.getKunyekUserName();
          this.allFerry = true;
          this.refreshgetData();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isApproving = false;
        this.isRejecting = false;
      },
      (err) => {
        this.isApproving = false;
        this.isRejecting = false;
      }
    );

  }

}
