<!-- <perfect-scrollbar [config]="config" [scrollIndicators]="true"> -->
<!-- <div class="close">
        <button type ="button" class = "btn btn-close" mat-dialog-close>
        </button>
        <i class = "fas fa-arrow-left" mat-dialog-close></i>
    </div> -->
<app-loading *ngIf="loading"></app-loading>
<div class="profile">
    <div class="chatheader" *ngIf="openedChat.type == '1' && openedChat.block == 'false'">
        <div class="conversation-summary-avatar conversation-summary-avatar-group me-2" data-number-of-thumbnails="1">
            <div class="conversation-summary-avatar-group-member-wrapper">
                <img class="conversation-summary-avatar-group-member-wrapper"
                    [src]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png' ">
            </div>
        </div>
        <div class="chatname">
            {{openedChat.userlist[0].username ? openedChat.userlist[0].username : openedChat.userlist[0].userid }}
        </div>
    </div>
    <div class="chatheader"
        *ngIf="openedChat.type == '3' && (openedChat.block == '' || openedChat.block == 'disable') ">
        <div class="conversation-summary-avatar conversation-summary-avatar-group me-2" data-number-of-thumbnails="1">
            <div class="conversation-summary-avatar-group-member-wrapper">
                <img class="conversation-summary-avatar-group-member-wrapper"
                    [defaultImage]="'../../../assets/images/bg.jpg'"
                    [lazyLoad]="openedChat.imagename ? openedChat.imagename : '../../../assets/images/profile.png' ">
            </div>
        </div>
        <div class="chatname">
            {{openedChat.username ? openedChat.username : openedChat.userid }}
        </div>
    </div>
    <div class="chatheader" *ngIf="openedChat.type == '2'">
        <div>
            <div *ngIf="openedChat.channelimage.imageurl">
                <div class="pf-image-container">
                    <div class="conversation-summary-avatar conversation-summary-avatar-group"
                        data-number-of-thumbnails="1">
                        <div class="conversation-summary-avatar-group-member-wrapper">
                            <img class="conversation-summary-avatar-group-member-wrapper"
                                [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="openedChat.channelimage.imageurl ? openedChat.channelimage.imageurl : '../../../assets/images/profile.png' ">
                        </div>
                    </div>
                    <div *ngIf="isAdmin" class="image-edit-button" (click)="pfImageEdit(openedChat.channelimage.imageurl ? openedChat.channelimage.imageurl : '')" >
                        <i class="fa fa-pencil" style="color: #343a40;"></i>
                    </div>
                </div> 
            </div>
            <div *ngIf="!openedChat.channelimage.imageurl">
                <div class="pf-image-container">
                    <div *ngIf="openedChat.userlist.length > 2"
                        class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="3">
                        <div class="conversation-summary-avatar-group-member-wrapper">
                            <img class="conversation-summary-avatar-group-member-wrapper"
                                [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png'  ">
                        </div>
    
                        <div class="conversation-summary-avatar-group-member-wrapper">
                            <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                                [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="openedChat.userlist[1].imagename ? openedChat.userlist[1].imagename : '../../../assets/images/profile.png' ">
                        </div>
    
                        <div class="conversation-summary-avatar-group-member-wrapper">
                            <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                                [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="openedChat.userlist[2].imagename ? openedChat.userlist[2].imagename : '../../../assets/images/profile.png' ">
                        </div>
                    </div>
                    <div *ngIf="openedChat.userlist.length == 2"
                        class="conversation-summary-avatar conversation-summary-avatar-group" data-number-of-thumbnails="2">
                        <div class="conversation-summary-avatar-group-member-wrapper">
                            <img class="conversation-summary-avatar-group-member-wrapper"
                                [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="openedChat.userlist[0].imagename ? openedChat.userlist[0].imagename : '../../../assets/images/profile.png'  ">
                        </div>
    
                        <div class="conversation-summary-avatar-group-member-wrapper">
                            <img class="conversation-summary-avatar-group-member avatar--should-fall-back-to-initial"
                                [defaultImage]="'../../../assets/images/bg.jpg'"
                                [lazyLoad]="openedChat.userlist[1].imagename ? openedChat.userlist[1].imagename : '../../../assets/images/profile.png' ">
                        </div>
                    </div>
                    <div *ngIf="isAdmin" class="image-edit-button" (click)="pfImageEdit(openedChat.channelimage.imageurl ? openedChat.channelimage.imageurl : '')" >
                        <i class="fa fa-pencil" style="color: #343a40;"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="chatname">
            {{openedChat.channelname}}
        </div>
    </div>
    <div *ngIf="openedChat.type == '1'">
        <mat-list>
            <mat-list-item class="list-item" *ngIf="allinoneService.isPhone(openedChat.userlist[0].userid)">
                <mat-icon mat-list-icon>phone</mat-icon>
                <div mat-line>{{openedChat.userlist[0].userid}}</div>
            </mat-list-item>
            <mat-list-item class="list-item" *ngIf="!allinoneService.isPhone(openedChat.userlist[0].userid)">
                <mat-icon mat-list-icon>email</mat-icon>
                <div mat-line>{{openedChat.userlist[0].userid}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item class="list-item" (click)="emitCreateGroupWith(openedChat.userlist[0].userid)">
                <mat-icon mat-list-icon>group</mat-icon>
                <div mat-line>Create Group With {{name}}</div>
            </mat-list-item>
            <mat-list-item class="list-item block" (click)="blockUser(openedChat.userlist[0].userid)">
                <mat-icon mat-list-icon>block</mat-icon>
                <div mat-line class="text">Block this contact</div>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="openedChat.type == '3'">
        <mat-list>
            <mat-list-item class="list-item" *ngIf="allinoneService.isPhone(openedChat.userid)">
                <mat-icon mat-list-icon>phone</mat-icon>
                <div mat-line>{{openedChat.userid}}</div>
            </mat-list-item>
            <mat-list-item class="list-item" *ngIf="!allinoneService.isPhone(openedChat.userid)">
                <mat-icon mat-list-icon>email</mat-icon>
                <div mat-line>{{openedChat.userid}}</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item class="list-item" (click)="emitCreateGroupWith(openedChat.userid)">
                <mat-icon mat-list-icon>group</mat-icon>
                <div mat-line>Create Group With {{openedChat.username ? openedChat.username : openedChat.userid}}</div>
            </mat-list-item>
            <mat-list-item class="list-item block" (click)="blockUser(openedChat.userid)">
                <mat-icon mat-list-icon>block</mat-icon>
                <div mat-line class="text">Block this contact</div>
            </mat-list-item>
        </mat-list>
    </div>
    <div *ngIf="openedChat.type == '2'">
        <mat-list>
            <mat-list-item class="list-item" data-bs-toggle="collapse" data-bs-target="#group-info"
                aria-expanded="false" aria-controls="group-info" (click)="focus()">
                <mat-icon mat-list-icon>info</mat-icon>
                <div mat-line>Group Information</div>
            </mat-list-item>
            <div class="collapse" id="group-info">
                <div class="card card-body nodata nodata-2" *ngIf="gettingInfo">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="card card-body" *ngIf="!gettingInfo">
                    <div class="mb-3">
                        <label for="groupname" class="form-label">Group Name</label>
                        <input type="text" class="form-control" id="groupname" name="groupname"
                            placeholder="Enter Group Name" [(ngModel)]="giForm.groupname" required #groupname="ngModel"
                            [ngClass]="{ 'is-invalid': giFormSubmitted && groupname.invalid }" #gname
                            (keyup.enter)="submitGroupinfo()" [disabled]="!isAdmin">

                        <div class="invalid-feedback"
                            *ngIf="groupname.invalid || (groupname.dirty || groupname.touched) && giFormSubmitted">
                            <div *ngIf="groupname.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                    </div>

                    <div class="mb-3 ">
                        <label for="organization" class="form-label">Organization</label>
                        <select class="form-select" aria-label="Select Organization" id="organization"
                            name="organization" (change)="orgChanged($event)" [(ngModel)]="giForm.orgid"
                            [disabled]="gettingOrgList || !isAdmin" required #org="ngModel">
                            <option selected value="">-</option>
                            <option *ngFor="let org of organizations" [value]="org.orgid"
                                [selected]="org.orgid==giForm.orgid">{{org.name}}</option>
                        </select>
                        <mat-progress-bar mode="indeterminate" *ngIf="gettingOrgList"></mat-progress-bar>


                    </div>
                    <div class="d-flex flex-wrap mb-3">
                        <div class="form-check me-3">
                            <input class="form-check-input" type="checkbox" id="customgp" name="customgp"
                                [(ngModel)]="giForm.custom" [disabled]="!isAdmin">
                            <label class="form-check-label mb-1 ms-1" for="customgp">
                                Custom
                            </label>
                        </div>
                        <div class="form-check me-3">
                            <input class="form-check-input" type="checkbox" id="support" name="support"
                                [(ngModel)]="giForm.support" [disabled]="!isAdmin">
                            <label class="form-check-label mb-1 ms-1" for="support">
                                Support
                            </label>
                        </div>
                        <div class="form-check me-3">
                            <input class="form-check-input" type="checkbox" id="class" name="class"
                                [(ngModel)]="giForm.class" (change)="checkClass($event)" [disabled]="!isAdmin">
                            <label class="form-check-label mb-1 ms-1" for="class">
                                Class
                            </label>
                        </div>
                        <div class="form-check me-3">
                            <input class="form-check-input" type="checkbox" id="payroll" name="payroll"
                                [(ngModel)]="giForm.payroll" [disabled]="!isAdmin">
                            <label class="form-check-label mb-1 ms-1" for="payroll">
                                Pay Group
                            </label>
                        </div>
                        <div class="form-check me-3">
                            <input class="form-check-input" type="checkbox" id="hidden" name="hidden"
                                [(ngModel)]="giForm.hidden" [disabled]="!isAdmin">
                            <label class="form-check-label mb-1 ms-1" for="hidden">
                                Hidden
                            </label>
                        </div>
                    </div>
                    <div class=" mb-3" *ngIf="giForm.class">
                        <label for="location" class="form-label">Location</label>
                        <input type="text" class="form-control" id="location" name="location"
                            placeholder="Enter location" [(ngModel)]="giForm.location" required #loc="ngModel"
                            [ngClass]="{ 'is-invalid': giFormSubmitted && loc.invalid }" [disabled]="!isAdmin">

                        <div class="invalid-feedback"
                            *ngIf="loc.invalid || (loc.dirty || loc.touched) && giFormSubmitted">
                            <div *ngIf="loc.errors?.required">
                                {{allinoneService.input_err_msg}}
                            </div>
                        </div>
                        <div class="map-wrapper ">
                            <a (click)="map()" class="btn-link">{{this.giForm.lat}} - {{this.giForm.long}} <mat-icon
                                    style="font-size: 0.7rem;">location_on</mat-icon> </a>
                        </div>



                    </div>

                    <div class="saveButton" *ngIf="isAdmin">
                        <app-button-loader (click)="submitGroupinfo()" [button_text]="'Save'"
                            [load_button_text]="'Saving'" [isLoading]="saveLoading"></app-button-loader>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-list-item class="list-item" data-bs-toggle="collapse" data-bs-target="#group-qr" aria-expanded="false"
                aria-controls="group-qr">
                <mat-icon mat-list-icon>qr_code</mat-icon>
                <div mat-line>Group QR</div>
            </mat-list-item>
            <div class="collapse" id="group-qr">
                <div class="card card-body qr">
                    <ngx-qrcode qrc-element-type="canvas" [elementType]="elementType"
                        [errorCorrectionLevel]="correctionLevel" [value]="value" cssClass="bshadow"></ngx-qrcode>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-list-item class="list-item" data-bs-toggle="collapse" data-bs-target="#group-members"
                aria-expanded="false" aria-controls="group-members">
                <mat-icon mat-list-icon>group</mat-icon>
                <div mat-line>Members&nbsp;<span *ngIf="!isMemberListLoading">({{tempUserList.length}})</span></div>
            </mat-list-item>
            <div class="collapse" id="group-members">
                <div class="card card-body nodata nodata-2" *ngIf="this.isMemberListLoading">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div class="card card-body" *ngIf="!this.isMemberListLoading">
                    <div *ngFor="let user of tempUserList">
                        <div class="profile-item" *ngIf="user.block != 'true'">
                            <div class="profile-item-2">
                                <img class="chat-image me-3" [defaultImage]="'../../../assets/images/bg.jpg'"
                                    [lazyLoad]="user.imagename ? user.imagename : '../../../assets/images/profile.png' ">

                                <div class="profile-container">
                                    {{user.username ? user.username : user.userid}}
                                    <img class="bluemark" *ngIf="user.admin == 'true'"
                                        src="../../../assets/images/bluemark.png">
                                </div>
                            </div>
                            <!-- <div *ngIf = "user.friend == 'false'" (click) = "addContact(user.userid)" title = "Add Contact">
                                    <mat-icon>person_add</mat-icon>
                                </div> -->

                            <div class="member-menu">
                                <button mat-icon-button aria-expanded="false" data-bs-toggle="dropdown"
                                    *ngIf="user.userid != allinoneService.getKunyekUserId()">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <button mat-menu-item (click)="viewProfile(user)">
                                        <mat-icon class="member-menu-item">person</mat-icon>
                                        <span>View Profile</span>
                                    </button>
                                    <button mat-menu-item *ngIf="user.friend == 'false'"
                                        (click)="addContact(user.userid)">
                                        <mat-icon class="member-menu-item">person_add</mat-icon>
                                        <span>Add Friend</span>
                                    </button>
                                    <button mat-menu-item *ngIf="isAdmin && user.admin == 'false'"
                                        (click)="makeAdmin(user, 1)">
                                        <mat-icon class="member-menu-item">verified_user</mat-icon>
                                        <span>Make Admin</span>
                                    </button>
                                    <button mat-menu-item *ngIf="isAdmin && user.admin == 'true'"
                                        (click)="makeAdmin(user, 2)">
                                        <mat-icon class="member-menu-item">remove_circle_outline</mat-icon>
                                        <span>Remove Admin</span>
                                    </button>
                                    <button mat-menu-item *ngIf="isAdmin" (click)="removeFromGroup(user.userid)">
                                        <i class="fas fa-user-minus member-menu-item"></i>
                                        <span>Remove From Group</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="profile-item" *ngIf="user.block == 'true'">
                            <div class="profile-item-2">
                                <img class="chat-image me-3" src="../../../assets/images/profile.png">

                                <div class="profile-container">
                                    <font color="red">Blocked User</font>
                                    <img class="bluemark" *ngIf="isAdmin" src="../../../assets/images/bluemark.png">
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-list-item *ngIf="!this.isMemberListLoading" class="list-item" (click)="emitAddMember()">
                <mat-icon mat-list-icon>person_add</mat-icon>
                <div mat-line>Add Member</div>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item class="list-item leave-group" *ngIf="!isAdmin" (click)="leaveGroupConfirm()">
                <mat-icon mat-list-icon>logout</mat-icon>
                <div mat-line>Leave Group</div>
            </mat-list-item>
            <mat-list-item class="list-item leave-group" *ngIf="isAdmin" (click)="deleteChatConfirm()">
                <mat-icon mat-list-icon>delete</mat-icon>
                <div mat-line>Delete</div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<!-- </perfect-scrollbar> -->