<div class="cc-container">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="label title">Edit Relationship
        </div>
        <div class="close-btn">
            <button mat-icon-button [disabled]="saveLoading" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <div class="mb-3">
            <label for="peid" class="form-label">Member
                <span *ngIf="isParentValid">({{selectedPEmployeeid}} - {{selectedPUserName ? selectedPUserName :
                    selectedPUserid}})</span>
            </label>
            <input type="text" placeholder="0001, 0002" aria-label="Number" matInput class="form-control"
                (ngModelChange)="peidChange()" [matAutocomplete]="auto1" [(ngModel)]="searchText1" name="peid"
                id="peid" #peid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && peid.invalid }" [disabled]="gettingHierarchy">
            <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let member of filterparentList | filter : searchText1:12 " [value]="member.employeeid"
                    (onSelectionChange)="parentChange(member)" (click)="parentChange(member)">
                    {{member.employeeid}} - {{member.username}} ({{member.userid}})
                </mat-option>
            </mat-autocomplete>
            <mat-progress-bar mode="indeterminate" *ngIf="gettingHierarchy"></mat-progress-bar>

            <div class="invalid-feedback" *ngIf="peid.invalid || (peid.dirty || peid.touched) && hcFormSubmitted">
                <div *ngIf="peid.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>

        </div>
        <div class="mb-3" *ngIf="hcForm.type == '1'">
            <label for="pid" class="col-form-label">Position (Post , Department , Division)</label>

            <div>
                <select class="form-select" aria-label="Select Position" id="pid" name="pid" [(ngModel)]="hcForm.pid"
                    required #pid="ngModel" [ngClass]="{ 'is-invalid': hcFormSubmitted && pid.invalid }"
                    >
                    <option value="">-</option>
                    <option *ngFor="let position of parentPositions; let index = index" [value]="position.positionid"
                        [selected]="hcForm.pid == position.positionid">
                    <span *ngIf = "position.name && position.department && position.division">
                        {{position.name}}, {{position.department}}, {{position.division}}
                    </span>
                    <span *ngIf = "position.name && position.department && !position.division">
                        {{position.name}}, {{position.department}}
                    </span>
                    <span *ngIf = "position.name && !position.department && position.division">
                        {{position.name}}, - , {{position.division}}
                    </span>
                    <span *ngIf = "position.name && !position.department && !position.division">
                        {{position.name}}
                    </span>
                    </option>
                </select>
            </div>
            <div class="invalid-feedback" *ngIf="pid.invalid || (pid.dirty || pid.touched) && hcFormSubmitted">
                <div *ngIf="pid.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
    </div>
    <div class = "my-3 d-flex align-items-center">
        <div>is</div>
        <div class = "mx-4">
            <select class="form-select" aria-label="Select type" id="type" name="type" (change)="typeChange($event)"
                [(ngModel)]="hcForm.type" required #type="ngModel" [disabled]="(changeType && originalType == '1') || !changeType">
                <option *ngFor="let type of hierarchyTypes" [value]="type.id" [selected]="hcForm.type == type.id">
                    {{type.name}}</option>
            </select>
        </div>
        <div>
            of
        </div>
    </div>
    <div class="form-check mb-3" *ngIf="hcForm.type == '1' || hcForm.type == '50' || hcForm.type == '51'">
      <input class="form-check-input td-edit" type="checkbox" id="primary" [(ngModel)]="hcForm.primary">
      <label class="form-check-label td-edit" for="primary">
        Primary
      </label>
    </div>
    <div>
        <div class = "mb-3">
            <label for="ceid" class="form-label">Member ({{node.employeeid}} - {{node.username? node.username : node.userid}})</label>
            <input type="text" placeholder="0001, 0002" aria-label="Number" class="form-control"
                [(ngModel)]="node.employeeid" name="ceid" disabled
                id="ceid">
        </div>
        <div class = "mb-3">
            <label for="cid" class="form-label">Position (Post , Department , Division)</label>
            <input type="text" class="form-control"
                [(ngModel)]="childPosition" name="cid" disabled
                id="cid">
        </div>
        <div class ="mb-2" *ngIf="hcForm.type == '1'">
            <label for="order" class="form-label">Order</label>
            <input type="number" class="form-control" id="order" name="order" placeholder="Enter order"
                [(ngModel)]="hcForm.order" required #order="ngModel"
                [ngClass]="{ 'is-invalid': hcFormSubmitted && order.invalid }"
                (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
    
            <div class="invalid-feedback" *ngIf="order.invalid || (order.dirty || order.touched) && hcFormSubmitted">
                <div *ngIf="order.errors?.required">
                    {{allinoneService.input_err_msg}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="btn-footer">
    <button class="btn btn-primary my-btn" type="button" (click)="submitHC()" [disabled]= "!isParentValid || saveLoading">
        <mat-icon *ngIf="!saveLoading">check</mat-icon>
        <div *ngIf="saveLoading" class="spinner-border" style="width: 25px; height: 25px;color: white;" role="status">
        </div>
    </button>
</div>