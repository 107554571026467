<div class="container-fluid">
    <div class="content">


        <div>

            <app-transportation-breadcrumb *ngIf="!adddropoff && !isEditing" s_breadcrumb="Drop off points">
            </app-transportation-breadcrumb>
            <app-transportation-breadcrumb *ngIf="adddropoff || isEditing" s_breadcrumb="Drop off points"
                [t_breadcrumb]=" isEditing ? 'Edit' : 'New'" (emit)="cancelFunction()">
            </app-transportation-breadcrumb>



            <!--  (emit1)="cancels(2)"  -->
            <div *ngIf="!adddropoff && !isEditing">

                <div class="d-flex flex-wrap">
                    <div class="showbtn me-auto  mb-3">
                        <button class="btn btn-custom" (click)="new()">
                            <i class="fa fa-plus me-1"></i> New
                        </button>
                    </div>
                </div>
                <div class=" w-100 d-flex flex-wrap me-auto ">

                    <div class="input-group me-2 mb-2" style="width: 300px !important;">
                        <input class="form-control" type="text" placeholder="&#xf002; Search" aria-label="Search"
                            [(ngModel)]="searchText" style="font-family: FontAwesome,Ubuntu;">
                    </div>
                    <div class="dropdown mb-2 me-2">
                        <button type="button" class="btn btn-custom" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false" matTooltip="Page Size">
                            <mat-icon>filter_list</mat-icon>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <div c style="text-align: center;">Page size</div>
                            </li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li class="dropdown-item" *ngFor="let page of pg">
                                <input type="radio" name="pg-count" value="{{page.count}}"
                                    (change)="changePageCount($event)" id="pg{{page.name}}" class="p-1"
                                    [checked]="page.count==itemPerPage">
                                <label for="pg{{page.name}}" class="col-10 p-1">
                                    {{page.name}}
                                </label>
                            </li>
                        </ul>
                    </div>

                    <!-- me-2 -->
                    <div class="me-2 mb-2 ">
                        <button class="btn btn-custom" (click)="refreshgetData()" matTooltip="Refresh">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>

                    <div class="line mb-2 "></div>
                    <div class=" mb-2">
                        <button type="button" class="btn btn-custom" (click)="downloadSheet()"
                            matTooltip="Export Pickup Points">
                            <img class="excel-icon" src="../../../assets/images/excel.png">Export
                        </button>

                    </div>
                    <div class="line mb-2 "></div>
                    <div class="d-flex align-items-center me-auto mb-2 ">
                        <span class="total-count-text ">Total:&nbsp;</span> <span
                            class="total-count-num ">{{dropoffList.length}}</span>
                    </div>

                    <div>

                    </div>
                </div>


                <div>
                    <div class="table-responsive" matSort (matSortChange)="sortData($event)">
                        <table class="table table-responsive table-borderless">
                            <thead class="table-header">
                                <tr>
                                    <td scope="col" class="col-lg-3 col-md-3" mat-sort-header="name">Name</td>
                                    <!-- <td scope="col" class="col-lg-3 col-md-3"  mat-sort-header="usercounts">Passenger</td> -->
                                    <td scope="col" class="d-flex justify-content-end">Action</td>
                                </tr>
                            </thead>
                            <tbody *ngIf="gettingData">
                                <tr>
                                    <td colspan="4" class="nodata">
                                        <div class="spinner-border" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="gettingData== false">
                                <tr *ngIf="dropoffList.length == 0">
                                    <td colspan="4" class="nodata">Empty</td>
                                </tr>
                                <tr *ngFor="let item of dropoffList | filter : searchText:'pickup'">
                                    <td class="td-data" (click)="dropoffedit(item)" style="cursor: pointer;">
                                        <span [title]="item.name">{{item.name}}</span>
                                    </td>
                                    <!-- <td class="td-data" (click)="pickupedit(item)" style="cursor: pointer;">
                                        <span [title]="item.usercounts">{{item.usercounts}}</span>
                                    </td> -->
                                    <!--  -->

                                    <td class="d-flex justify-content-end">
                                        <button matTooltip="Edit" (click)="dropoffedit(item)"
                                            class="btn btn-outline-primary">
                                            <i class='fa fa-edit'></i>
                                        </button>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="adddropoff || isEditing">

                <div>
                    <div class="close-save-button mb-3">
                        <button type="button" class="btn btn-custom me-2" (click)="cancelFunction()"
                            *ngIf="!saveLoading">
                            Cancel
                        </button>
                        <app-button-loader (click)="submitDropoffForm()" [button_text]="'Save'"
                            [load_button_text]="'Saving'" [isLoading]="saveLoading">
                        </app-button-loader>
                    </div>

                </div>

                <div class="card card-body my-card mb-3" >
                    <div class="row">


                        <div class="mb-3 col-md-12">
                            <label for="name" class="form-label">Name</label>
                            <input type="text" class="form-control" id="name" name="name" [(ngModel)]="dropoffForm.name"
                                #name="ngModel" [ngClass]="{'is-invalid': dropoffFormSave && name.invalid}" required>

                            <div class="invalid-feedback"
                                *ngIf="name.invalid || (name.dirty || name.touched) && dropoffFormSave">
                                <div *ngIf="name.errors?.required">
                                    {{allinoneService.input_err_msg}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <button class="btn btn-custom me-2 mb-3" (click)="choosePassenger()">
                    Select Passengers
                </button> -->
                <!-- <div class="list-group">
                    <div class="col-md-4 profile-container card card-body" *ngFor="let data of passengerList;let i = index;"
                        style="margin-bottom: 3px;">
                        <div class="row">
                            <div class="d-flex flex-wrap">
                                <div class="flex-row word me-auto">
                                    {{data.username}}({{data.userid}})
                                </div>
                                <div (click)="delete(i)" style="color: red;font-size: 1.3rem Im !important;cursor: pointer;">
                                    <mat-icon class="delete-icon">delete</mat-icon>
                                </div>
                            </div>
                            <div>
                                {{data.trantype == "001" ? "Ferry" : "Daily"}}
                            </div>
                            <div>
                                {{data.userlist.toString()}}
                            </div>

                            <div>
                                {{allinoneService.formatDBToShow(allinoneService.formatDate(data.startdate))}} -
                                {{allinoneService.formatDBToShow(allinoneService.formatDate(data.enddate))}}
                            </div>
                            <div>
                                {{data.weekday}}
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

        </div>




    </div>