import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { DatePipe, formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { encode } from 'querystring';
import { Subscription } from 'rxjs';
import { MapComponent } from 'src/app/admin/map/map.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { AllInOneService } from 'src/app/shared/services/all-in-one.service';
import { KunyekService } from 'src/app/shared/services/kunyek.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { SubSink } from 'subsink';
import { LogisticOrdersBatchesComponent } from '../logistic-orders-batches/logistic-orders-batches.component';
import { LogisticsUsersComponent } from '../logistics-users/logistics-users.component';

@Component({
  selector: 'app-logistics-assign',
  templateUrl: './logistics-assign.component.html',
  styleUrls: ['./logistics-assign.component.scss'],
})
export class LogisticsAssignComponent implements OnInit,OnDestroy {
  @ViewChild('hiddenfileinput') hiddenfileinput: any;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
  }

  gettingAssigment: boolean = false;
  isLoading: boolean = false;
  isMobileView: boolean = false;
  isAssignNew: boolean = false;
  isFocus: boolean = false;
  nextDisabled: boolean = false;
  assignFormSubmitted: boolean = false;
  isEditing: boolean = false;

  assignSubscription!: Subscription;
  subs = new SubSink();
  assignTimeout: any;
  statusList = [
    {
      name: 'All',
      value: '',
    },
    {
      name: 'Valid',
      value: '001',
    },
    {
      name: 'Expired',
      value: '002',
    },
    {
      name: 'Void',
      value: '003',
    },
  ];

  assigns: any[] = [];
  organizations: any[] = [];
  userList: any = [{ phno: '' }];
  orderList: any = [];
  batchList: any = [];

  status: string = '';
  statusname: any = 'All';
  end: string = '';
  searchText: string = '';
  lastPage: any = '';

  itemPerPage: number = 10;
  currentPage: number = 1;
  mobileViewWidth: number = 768;

  formatDateToShow = 'yyyy-MM-dd';
  formatTimeToShow = 'HH:mm:ss';

  now = Date.now();

  oneHourPlus = new Date(
    new Date(this.now).setHours(new Date(this.now).getHours() + 1)
  );

  currentDate = formatDate(
    Date.now(),
    this.formatDateToShow,
    'en-US'
  ).toString();
  currentStartTime = formatDate(
    Date.now(),
    this.formatTimeToShow,
    'en-US'
  ).toString();
  currentEndTime = formatDate(
    new Date(this.oneHourPlus),
    this.formatTimeToShow,
    'en-US'
  ).toString();

  assignForm = {
    id: '', // primary key for order
    assignid: '',
    domainid: '',
    orgid: '',
    status: '',
    startdate: this.currentDate,
    enddate: this.currentDate,
    starttime: this.currentStartTime,
    endtime: this.currentEndTime,
    users: this.userList,
    orders: this.orderList,
    batches: this.batchList,
  };

  constructor(
    private kunyekService: KunyekService,
    public allinoneService: AllInOneService,
    private messageService: MessageService,
    private router: Router,
    private datepipe: DatePipe,
    private dialog: MatDialog
  ) {
    if (!this.allinoneService.isAdminOf('org')) {
      this.router.navigateByUrl('/');
    }
  }
  ngOnDestroy(): void {
    this.assignSubscription && this.assignSubscription.unsubscribe();
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    if (window.innerWidth < this.mobileViewWidth) {
      this.isMobileView = true;
    }
    this.assignForm.domainid = this.allinoneService.getDomain().domainid;
    this.organizations = this.allinoneService.orgs;
    this.assignForm.orgid = this.allinoneService.selectedOrg
      ? this.allinoneService.selectedOrg
      : this.organizations[0].orgid;
    this.getAssigment();
  }

  getAssigment() {
    this.gettingAssigment = true;
    const data = {
      domainid: this.assignForm.domainid,
      orgid: this.assignForm.orgid,
      status: this.status,
      search: this.searchText,
      end: this.end,
    };
    this.assignSubscription && this.assignSubscription.unsubscribe();
    this.assignSubscription = this.kunyekService
      .getLogisticsAssigment(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            if (res.list.length > 0) {
              this.nextDisabled = false;
              this.assigns.push.apply(this.assigns, res.list);
              this.end = res.end;
              this.assigns = this.assigns.map((item) => ({
                ...item,
                statusName: this.statusList.find(
                  (x: any) => x.value == item.status
                )?.name,
              }));
              if (res.list.length < 10) {
                this.nextDisabled = true;
                this.lastPage = this.currentPage;
              }
            } else {
              this.currentPage = this.currentPage - 1;
              this.nextDisabled = true;
              this.lastPage = this.currentPage;
            }
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
          }
          this.gettingAssigment = false;
        },
        (err) => {
          this.gettingAssigment = false;
        }
      );
  }

  sortData(event: any) {}

  goToLogistics() {
    this.router.navigateByUrl('/logistics');
  }

  newAssign() {
    window.scrollTo(0, 0);
    this.isAssignNew = true;
    this.assignForm.assignid = this.allinoneService.generateRandomID();
  }
  cancel() {
    this.isAssignNew = false;
    this.isEditing = false;
    this.userList = [{ phno: '' }];
    this.orderList = [];
    this.batchList = [];
    this.assignForm = {
      id: '', // primary key for order
      assignid: '',
      domainid: this.assignForm.domainid,
      orgid: this.assignForm.orgid,
      status: '',
      startdate: this.currentDate,
      enddate: this.currentDate,
      starttime: this.currentStartTime,
      endtime: this.currentEndTime,
      users: this.userList,
      orders: this.orderList,
      batches: this.batchList,
    };
  }

  editAssign(order: any) {
    this.userList = [];
    this.orderList = [];
    this.batchList = [];

    let _userList: any[] = [];
    let _orderList: any[] = [];
    let _batchList: any[] = [];
    window.scrollTo(0, 0);
    this.isAssignNew = true;
    this.isEditing = true;

    for (let u = 0; u < order.userlist.length; u++) {
      _userList.push({
        phno: order.userlist[u],
      });
    }
    this.userList = _userList;

    this.gettingAssigment = true;
    const data = {
      domainid: this.assignForm.domainid,
      orgid: this.assignForm.orgid,
      status: this.status,
      search: this.searchText,
      end: this.end,
      assignmentid: order.assignmentid,
    };
    this.assignSubscription && this.assignSubscription.unsubscribe();
    this.assignSubscription = this.kunyekService
      .getLogisticsAssigment(data)
      .subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            console.log(res.list);
            _orderList = res.list[0].orderlist;
            this.orderList = res.list[0].orderlist;
            _batchList = res.list[0].batchlist;
            this.batchList = res.list[0].batchlist;
            this.batchList = this.batchList.map((item: any) => ({
              ...item,
              checked: true,
            }));
            this.orderList = this.orderList.map((item: any) => ({
              ...item,
              checked: true,
            }));
          } else {
            this.messageService.openSnackBar(
              res.message || res.status || 'Server Error',
              'warn'
            );
            this.isEditing = false;
          }
          this.gettingAssigment = false;
        },
        (err) => {
          this.gettingAssigment = false;
          this.isEditing = false;
        }
      );

    this.assignForm = {
      id: order.assignmentid, // primary key for order
      assignid: order.assignmentno,
      domainid: this.assignForm.domainid,
      orgid: this.assignForm.orgid,
      status: order.status,
      startdate: this.allinoneService.formatDBToShowInEdit(order.startdate),
      enddate: this.allinoneService.formatDBToShowInEdit(order.enddate),
      starttime: order.starttime,
      endtime: order.endtime,
      users: _userList,
      orders: _orderList,
      batches: _batchList,
    };
  }

  async submitAssignment() {
    this.assignFormSubmitted = true;
    this.isLoading = true;
    let userIdList: any[] = [];
    let orderIdList: any[] = [];
    let batchIdList: any[] = [];

    for (var u = 0; u < this.assignForm.users.length; u++) {
      if (this.assignForm.users[u]['phno'] != '') {
        userIdList.push(this.assignForm.users[u]['phno']);
      }
    }
    for (var o = 0; o < this.orderList.length; o++) {
      if (
        this.orderList[o]['orderid'] != '' &&
        this.orderList[o]['checked'] == true
      ) {
        orderIdList.push(this.orderList[o]['orderid']);
      }
    }
    for (var b = 0; b < this.batchList.length; b++) {
      if (
        this.batchList[b]['batchid'] != '' &&
        this.batchList[b]['checked'] == true
      ) {
        batchIdList.push(this.batchList[b]['batchid']);
      }
    }

    var data = {
      assignmentno: this.assignForm.assignid,
      domainid: this.assignForm.domainid,
      orgid: this.assignForm.orgid,
      userlist: userIdList,
      orderlist: orderIdList,
      batchlist: batchIdList,
      startdate: this.allinoneService.formatDate(this.assignForm.startdate),
      starttime: this.assignForm.starttime,
      enddate: this.allinoneService.formatDate(this.assignForm.enddate),
      endtime: this.assignForm.endtime,
    };
    if (this.isEditing) {
      data = Object.assign(data, {
        assignmentid: this.assignForm.id,
        status: this.assignForm.status,
      });
    }
    var fun = this.isEditing
      ? this.kunyekService.editLogisticsAssigment(data)
      : this.kunyekService.createLogisticsAssigment(data);
    this.subs.sink = fun.subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.cancel();
          this.refresh();
        } else {
          this.messageService.openSnackBar(
            res.message || res.status || 'Server Error',
            'warn'
          );
        }
        this.isLoading = false;
        this.assignFormSubmitted = false;
      },
      (err) => {
        this.isLoading = false;
        this.assignFormSubmitted = false;
      }
    );
  }

  statusChange(status: any) {
    this.status = status.value;
    this.statusname = status.name;
    this.end = '';
    this.currentPage = 1;
    this.assigns = [];
    this.getAssigment();
  }

  searchTextChange() {
    clearTimeout(this.assignTimeout);
    this.assignTimeout = setTimeout(() => {
      this.end = '';
      this.currentPage = 1;
      this.assigns = [];
      this.getAssigment();
    }, 500);
  }

  clear() {
    this.searchText = '';
    this.end = '';
    this.currentPage = 1;
    this.assigns = [];
    this.getAssigment();
  }

  pageChange(event: any) {
    console.log(event);

    if (this.nextDisabled == true) {
      if (event < this.lastPage) {
        this.currentPage = event;
        if (event < 1) {
        } else {
          this.nextDisabled = false;
        }
      }
    } else {
      if (event == this.lastPage && this.lastPage != '') {
        this.currentPage = event;
        this.nextDisabled = true;
      } else {
        this.currentPage = event;

        if (event > this.assigns.length / this.itemPerPage) {
          this.getAssigment();
        }
      }
    }
  }

  refresh() {
    this.end = '';
    this.currentPage = 1;
    this.lastPage = '';
    this.assigns = [];
    this.getAssigment();
  }

  deleteAssign(order: any) {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      width: '250px',
      panelClass: ['mat-typography'],
      closeOnNavigation: true,
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.gettingAssigment = true;
        const data = {
          orgid: this.assignForm.orgid,
          domainid: this.assignForm.domainid,
          assignmentid: order.assignmentid,
        };
        this.subs.sink = this.kunyekService
          .deleteLogisticsAssigment(data)
          .subscribe(
            (res: any) => {
              if (res.returncode == '300') {
                this.messageService.openSnackBar('Successful.', 'success');
                this.refresh();
              } else {
                this.gettingAssigment = false;
                this.messageService.openSnackBar(res.message, 'warn');
              }
            },
            (err) => {
              this.gettingAssigment = false;
            }
          );
      }
    });
  }

  chooseUser() {
    const dialog = this.dialog.open(LogisticsUsersComponent, {
      height: '550px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        userlist: this.assignForm.users,
      },
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult) {
        this.userList = dialogResult.data;
        this.assignForm.users = this.userList;
      }
    });
  }

  addBatchAndOrders() {
    const dialog = this.dialog.open(LogisticOrdersBatchesComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orderlist: this.orderList,
        batchlist: this.batchList,
        showDetails: false,
        curTab: 'orders',
      },
      // scrollStrategy: new NoopScrollStrategy(),
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult && dialogResult.status) {
        this.assignForm.orders = dialogResult.data[0].orders;
        this.assignForm.batches = dialogResult.data[0].batches;
        this.orderList = dialogResult.data[0].orders;
        this.batchList = dialogResult.data[0].batches;
        console.log(dialogResult.data[0]);
      }
    });
  }

  viewOrder(order: any) {
    const dialog = this.dialog.open(LogisticOrdersBatchesComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orderlist: [],
        batchlist: [],
        showDetails: true,
        order: order,
        curTab: 'orders',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  viewOrderDeailsInBatch(order: any) {
    const dialog = this.dialog.open(LogisticOrdersBatchesComponent, {
      height: '600px',
      width: '500px',
      closeOnNavigation: true,
      panelClass: ['user-profile-modal', 'mat-typography'],
      data: {
        orderlist: [],
        batchlist: [],
        showDetails: true,
        order: order,
        curTab: 'batches',
      },
    });

    dialog.afterClosed().subscribe((dialogResult: any) => {});
  }

  getCount(orderlist: any, batchlist: any) {
    let count = orderlist.length + batchlist.length;
    return count.toString();
  }

  showDate(startdate: any, enddate: any) {
    let date = this.allinoneService.formatDBToShow(startdate);
    if (startdate != enddate) {
      date =
        this.allinoneService.formatDBToShow(startdate) +
        ' - ' +
        this.allinoneService.formatDBToShow(enddate);
    }
    return date.toString();
  }

  removeUser(index: any) {
    if (this.assignForm.users.length > 1) {
      this.userList.splice(index, 1);
      // this.assignForm.users.splice(index, 1);
    }
  }

  addUser() {
    this.userList.push({ phno: '' });
    // this.assignForm.users.push({ "phno": "" })
  }
}
